import { getSanitizedQuantConfig } from './quantConfig/index';
import { getDefaultOrderType } from './default/index';

const onLoadSanitizer = (runConfig) => {
  const { quantConfig, currentUser, orderType } = runConfig;

  return {
    ...runConfig,
    quantConfig: getSanitizedQuantConfig(quantConfig),
    orderType: getDefaultOrderType(orderType, currentUser)
  };
};

export default onLoadSanitizer;
