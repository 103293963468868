import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  IntentLabelConfig, getDefaultIdentifier, getDefaultIntent
} from 'v2/modules/withRunForm/configs/index';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import getPipeIntentDependencies from 'v2/modules/withRunForm/hooks/useCase/pipeIntentDependencies';
import Intents from 'v2/modules/withRunForm/components/Intents/index';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  pipeConfig: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  pipeIndex: PropTypes.number,
};

const defaultProps = {
  pipeIndex: undefined,
};

const PipeIntents = ({
  name, value: intents, onUpdatePipeConfig, pipeConfig, errors, control, setError, clearErrors, pipeIndex
}) => {
  const cases = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.cases'));
  const pipeName = useMemo(() => pipeConfig.name, [pipeConfig]);
  const pipeRef = useRef(pipeName);
  const pipeIntentDependencies = useMemo(
    () => {
      return getPipeIntentDependencies(pipeConfig, cases);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  );

  useEffect(() => {
    if (intents.length === 0) {
      onUpdatePipeConfig(name, [{
        ...getDefaultIntent(),
        identifier: getDefaultIdentifier(pipeName),
      }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (intents?.length !== 0) {
        IntentLabelConfig.setConfigNameAndValue(pipeRef.current, '');
        pipeRef.current = pipeName;
        const lastIntent = intents[intents.length - 1];
        const label = lastIntent?.identifier?.label || '';
        IntentLabelConfig.setConfigNameAndValue(pipeRef.current, label);
      }
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intents.length, pipeName]);

  return (
    <Intents
      pipeName={pipeName}
      intents={intents}
      pipeIndex={pipeIndex}
      errors={errors}
      control={control}
      isPipeIntent
      setError={setError}
      clearErrors={clearErrors}
      intentDependencies={pipeIntentDependencies}
      onUpdateIntents={(newIntents) => onUpdatePipeConfig(name, newIntents)}
    />
  );
};

PipeIntents.propTypes = propTypes;
PipeIntents.defaultProps = defaultProps;

export default PipeIntents;
