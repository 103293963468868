import {
  INTENT_TYPES, SEGMENT_CONFIG
} from 'v2/common/constants/index';
import {
  isIndexInstrumentPresentInInstrumentGroup
} from '../components/InstrumentDetails/indexInstrumentsChecker';
import { getQuantModes } from '../configs/index';

const getSanitizedIntents = (intents, instrumentGroups, maxInstrumentInGroup, isSimpleMode) => {
  const newIntents = intents.map((intent) => {
    const newIntent = _.cloneDeep(intent);
    if (newIntent.type !== INTENT_TYPES.OpenLeg) {
      return newIntent;
    }

    if (isSimpleMode && newIntent?.instrument?.strike?.multiplier) {
      newIntent.instrument.strike.multiplier = 0;
    }

    // if changed pair to single, then change instrument index to 0
    if (maxInstrumentInGroup === 1 && newIntent?.instrument?.instrumentIndex === 1) {
      newIntent.instrument.instrumentIndex = 0;
    }

    const { instrument: { instrumentIndex } } = newIntent;
    const isIndexInstrumentPresent = isIndexInstrumentPresentInInstrumentGroup(
      instrumentGroups, instrumentIndex
    );

    // index related stuffs
    if (isIndexInstrumentPresent) {
      const isEquityPresent = newIntent.instrument.type === SEGMENT_CONFIG.equity;
      if (isEquityPresent) {
        newIntent.instrument.type = SEGMENT_CONFIG.future;
      }

      return newIntent;
    }

    return newIntent;
  });

  return newIntents;
};

const getSanitizedCases = (cases, instrumentGroups, maxInstrumentInGroup, isSimpleMode) => {
  const newCases = isSimpleMode ? [_.get(cases, '0')] : cases;

  return newCases.map((caseConfig) => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    const { entry: { intents }, adjustments } = newCaseConfig;
    newCaseConfig.entry.intents = getSanitizedIntents(
      intents, instrumentGroups, maxInstrumentInGroup, isSimpleMode
    );

    const newAdjustments = adjustments.map((adjustment) => {
      const newAdjustment = _.cloneDeep(adjustment);
      const { intents: adjustmentIntents } = newAdjustment;
      newAdjustment.intents = getSanitizedIntents(
        adjustmentIntents, instrumentGroups, maxInstrumentInGroup, isSimpleMode
      );
      return newAdjustment;
    });

    newCaseConfig.adjustments = newAdjustments;
    return newCaseConfig;
  });
};

export const onChangeInstrumentGroups = ({
  instrumentGroups,
  cases,
  maxInstrumentsInGroup,
  uiConfigs
}) => {
  const simple = getQuantModes.simple.value;
  const isSimpleMode = _.get(uiConfigs, 'mode', simple) === simple;

  return {
    cases: getSanitizedCases(cases, instrumentGroups, maxInstrumentsInGroup, isSimpleMode),
    instrumentGroups,
    maxInstrumentsInGroup,
    uiConfigs
  };
};
