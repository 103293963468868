import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { validateCustomScript } from 'common/api/quantConfig';
import AceEditor from 'react-ace';

import { pipeConfigPropTypes, pipeConfigsPropTypes } from 'common/propTypes';
import ErrorMesssage from 'common/components/ErrorMesssage';

const OUTPUT_SCHEMA_VALIDATION = {
  required: {
    value: true,
    message: 'script is required'
  },
  validate: (value) => {
    try {
      JSON.parse(value);
      return null;
    } catch (e) {
      return 'Invalid JSON';
    }
  }
};

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  onUpdate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  trigger: PropTypes.func.isRequired,
};
const defaultProps = {};

const renderScriptError = (scriptError) => {
  if (!scriptError) {
    return null;
  }
  return (
    <div className="card border-danger">
      <pre className="card-body text-danger">
        {scriptError.message}

      </pre>
    </div>
  );
};

const CustomScriptConfigForm = ({
  pipeConfig, pipeConfigs, onUpdate, errors, control, trigger
}) => {
  const previousPipeConfigs = (() => {
    const index = pipeConfigs.findIndex((config) => config.name === pipeConfig.name);
    return pipeConfigs.slice(0, index === -1 ? pipeConfigs.length : index);
  })();
  const { config: { script, outputSchema } } = pipeConfig;
  const onConfigItemUpdate = useCallback((key, value) => {
    onUpdate({
      ...pipeConfig,
      config: {
        ...pipeConfig.config,
        [key]: value,
      }
    });
  }, [pipeConfig, onUpdate]);

  const scriptValidation = useMemo(() => ({
    required: {
      value: true,
      message: 'script is required',
    },
    validate: async () => {
      const result = await validateCustomScript(script, previousPipeConfigs);
      const { success, schema, error } = result;
      if (success) {
        onConfigItemUpdate('outputSchema', JSON.stringify(schema, null, 2));
      }
      return error && error.join ? error.join('\n') : error;
    }
  }), [onConfigItemUpdate, script]);

  useEffect(() => {
    const keydownHandler = (e) => {
      if (e.key === 'Enter' && e.ctrlKey) {
        trigger(['script']);
      }
    };
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  return (
    <>
      <Form.Group>
        <Form.Label className="text-nowrap col-form-label col-form-label-sm">Script</Form.Label>
        <Controller
          render={({ onChange, ref, value }) => (
            <AceEditor
              height="200px"
              width="100%"
              id="script"
              name="script"
              onChange={(code) => {
                onConfigItemUpdate('script', code);
                onChange(code);
              }}
              value={value}
              ref={ref}
              mode="scala"
              theme="eclipse"
              fontSize={14}
              showPrintMargin
              showGutter
              highlightActiveLine
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          )}
          name="script"
          defaultValue={script}
          control={control}
          rules={scriptValidation}
        />
        {renderScriptError(errors.script)}
      </Form.Group>
      <Form.Group className="hidden-for-future">
        <Form.Label className="text-nowrap col-form-label col-form-label-sm">
          OutputSchema(Will be autofilled)
        </Form.Label>
        <Controller
          render={({ onChange, ref, value }) => (
            <AceEditor
              height="100px"
              width="100%"
              id="outputSchema"
              name="outputSchema"
              onChange={(code) => {
                onConfigItemUpdate('outputSchema', code);
                onChange(code);
              }}
              value={value}
              ref={ref}
              mode="json"
              theme="eclipse"
              fontSize={14}
              showPrintMargin
              showGutter
              highlightActiveLine
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          )}
          name="outputSchema"
          defaultValue={outputSchema}
          control={control}
          rules={OUTPUT_SCHEMA_VALIDATION}
        />
        <ErrorMesssage error={errors.outputSchema} />
      </Form.Group>
    </>
  );
};

CustomScriptConfigForm.propTypes = propTypes;
CustomScriptConfigForm.defaultProps = defaultProps;

export default CustomScriptConfigForm;
