import React from 'react';

import { pipeConfigPropTypes } from 'common/propTypes';
import ReactHtmlParser from 'react-html-parser';
import { getLabeledHashFromArray, isValueMatchingWithSchema } from 'common/utils/displayNames';

const propTypes = pipeConfigPropTypes.isRequired;
const defaultProps = {};

const getLabelContent = (value, outputSchema) => {
  const labelArray = getLabeledHashFromArray(isValueMatchingWithSchema(outputSchema, value));
  if (_.isEmpty(labelArray)) return ReactHtmlParser(value);

  const labelString = _.map(labelArray, ({ label, labelHtml }) => { return labelHtml || label; }).join(',');

  return ReactHtmlParser(labelString);
};

const renderConfig = (value, key, outputSchema) => {
  const valuePathsLabel = _.isArray(value)
    ? _.map(_.compact(value), (valuePath) => getLabelContent(valuePath, outputSchema))
    : getLabelContent(value, outputSchema);

  let fieldContent;
  let valueContent;
  if (key !== 'valuePaths') {
    fieldContent = _.startCase(key);
    valueContent = (key === 'name') ? <b>{value}</b> : value;
  } else {
    fieldContent = 'Field';
    valueContent = valuePathsLabel;
  }

  return (
    <div className="badge badge-light border font-weight-normal" key={key}>
      <span className="text-muted">{fieldContent}</span>
      &nbsp;:&nbsp;
      {valueContent}
    </div>
  );
};

const ViewPipeConfig = ({
  type, config, name, outputSchema
}) => {
  let renderableConfig = config;
  if (type === 'customScript') {
    renderableConfig = {
      ...config,
      script: '...',
      outputSchema: '...'
    };
  }
  return [renderConfig(name, 'name', outputSchema)].concat(
    _.map(renderableConfig, (value, key) => renderConfig(value, key, outputSchema))
  );
};

ViewPipeConfig.propTypes = propTypes;
ViewPipeConfig.defaultProps = defaultProps;

export default ViewPipeConfig;
