import moment from 'moment';
import React, { Component } from 'react';

import {
  getWebPushSubscriptions,
  deleteWebPushSubscription
} from 'common/api/webPushSubscriptions';

const propTypes = {};
const defaultProps = {};

class WebPushSubscriptionsList extends Component {
  constructor(props) {
    super(props);

    this.state = { subscriptions: [] };
  }

  componentDidMount() {
    getWebPushSubscriptions()
      .then((subscriptions) => this.setState({ subscriptions }));
  }

  onDeleteSubscription = (subscriptionIdToDelete) => {
    const { subscriptions } = this.state;

    deleteWebPushSubscription(subscriptionIdToDelete);

    this.setState({
      subscriptions: _.filter(subscriptions, (subscription) => subscription.id !== subscriptionIdToDelete)
    });
  }

  renderSubscriptions = () => {
    const { subscriptions } = this.state;
    if (_.isEmpty(subscriptions)) {
      return (
        <tr>
          <td colSpan="5" className="text-center text-muted">No Subscriptions...</td>
        </tr>
      );
    }
    return _.map(subscriptions, this.renderSubscription);
  }

  renderSubscription = (subscription) => {
    return (
      <tr className="subscription" key={subscription.id}>
        <td>{_.get(subscription, 'user_agent.browser')}</td>
        <td>{_.get(subscription, 'user_agent.device')}</td>
        <td>{_.get(subscription, 'user_agent.platform')}</td>
        <td>{moment(subscription.created_at).fromNow()}</td>
        <td>
          <button
            type="button"
            className="delete-btn align-self-end mb-2 mb-xm-0"
            onClick={() => this.onDeleteSubscription(subscription.id)}
          >
            <i className="material-icons-outlined">delete_outline</i>
          </button>
        </td>
      </tr>
    );
  }

  render() {
    return (
      <>
        <h4 className="mt-5">Browser Notification Subscriptions</h4>
        <div className="card mt-3">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>Browser</th>
                  <th>Device</th>
                  <th>OS</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.renderSubscriptions()}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

WebPushSubscriptionsList.propTypes = propTypes;
WebPushSubscriptionsList.defaultProps = defaultProps;
export default WebPushSubscriptionsList;
