import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CANDLE_INTERVALS } from 'v2/common/quantConfig/index';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  value: PropTypes.string.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  validator: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  clearErrors: PropTypes.func.isRequired,
  pipeConfig: PropTypes.shape({}).isRequired,
};

const defaultProps = {};

const PipeVolumeInterval = ({
  value, onUpdatePipeConfig, name: propsName, validator, control, errors, clearErrors, pipeConfig
}) => {
  const chartType = useMemo(() => _.get(pipeConfig, 'chartConfig.type', ''), [pipeConfig]);
  const candleInterval = useMemo(() => _.get(pipeConfig, 'config.candleInterval', ''), [pipeConfig]);
  const isVolumeChartType = useMemo(() => chartType === 'volumeCandle', [chartType]);
  const uniqueId = useMemo(() => _.uniqueId('pipe_volume_interval_'), []);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  const candleIntervals = useMemo(() => {
    return _.chain(CANDLE_INTERVALS)
      .findIndex({ value: candleInterval })
      .thru((index) => {
        return _.take(CANDLE_INTERVALS, index + 1);
      })
      .value();
  }, [candleInterval]);

  const intervalOptions = useMemo(() => _.map(
    candleIntervals,
    ({ displayValue, value: val }, idx) => <option key={idx} value={val}>{displayValue}</option>
  ), [candleIntervals]);

  useEffect(() => {
    if (!isVolumeChartType) clearErrors(uniqueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVolumeChartType]);

  useEffect(() => {
    onUpdatePipeConfig(propsName, candleInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candleInterval]);

  const rulesValidator = useMemo(() => {
    if (_.isEmpty(validator)) return null;

    return {
      ...validator,
      validate: () => {
        if (!value) {
          return 'Volume Interval is required';
        }

        return null;
      }
    };
  }, [value, validator]);

  if (!isVolumeChartType) return null;

  return (
    <ErrorMesssage message={errorMessage}>
      <Controller
        render={({ onChange, name, ref }) => (
          <Form.Control
            className=""
            as="select"
            size="sm"
            name={name}
            ref={ref}
            value={value}
            onChange={(event) => {
              onUpdatePipeConfig(propsName, event.target.value);
              onChange(event.target.value);
            }}
          >
            {intervalOptions}
          </Form.Control>
        )}
        control={control}
        defaultValue={value}
        name={uniqueId}
        rules={rulesValidator}
      />
    </ErrorMesssage>
  );
};

PipeVolumeInterval.propTypes = propTypes;
PipeVolumeInterval.defaultProps = defaultProps;

export default memo(PipeVolumeInterval);
