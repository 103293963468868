import React from 'react';
import PropTypes from 'prop-types';
import { QUANT_CONFIG_ORDER_TYPES } from 'v2/common/constants/index';

const propTypes = {
  selectedOrderType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const OrderTypeSelector = ({ selectedOrderType, onChange }) => {
  const renderOptions = (orderType) => {
    const { isStopLossLimitOrderEnabled } = window;
    if (orderType.value === 'sl-l' && !isStopLossLimitOrderEnabled) return null;

    return <option key={orderType.value} value={orderType.value}>{orderType.label}</option>;
  };

  return (
    <select
      className="form-control form-control-sm"
      onChange={(event) => onChange(event.target.value)}
      value={selectedOrderType}
    >
      {_.map(QUANT_CONFIG_ORDER_TYPES, renderOptions)}
    </select>
  );
};

OrderTypeSelector.propTypes = propTypes;

export default OrderTypeSelector;
