import { INTENT_TYPES, SEGMENT_CONFIG } from 'v2/common/constants/index';
import { getBeforeSubmitSanitizedAdjustments } from './adjustment/index';
import { getBasketOpenLegIntentsBasedOnIntents } from './basket';
import { getSanitizedStopLoss } from './stopLoss';

const getPositionReferenceForBaseIntent = (intent, intentIndex, caseIndex) => {
  const { identifier } = intent;
  const instrumentConfig = _.get(intent, 'instrument.subSelection') || _.get(intent, 'instrument', {});
  const segment = instrumentConfig?.type;
  const isOption = segment === SEGMENT_CONFIG.option;

  return {
    ...intent,
    identifier: {
      ...identifier,
      label: `${identifier?.label}`,
      type: 'Legacy',
      segment: instrumentConfig?.type,
      caseIndex,
      adjustmentConfigIndex: null,
      instrumentIndex: instrumentConfig?.instrumentIndex,
      optionConfigIndex: isOption ? intentIndex : null
    },
  };
};

const getSanitizedIntents = (intents) => {
  return _.map(intents, (intent) => {
    const isTargetEmpty = !(intent?.target?.label);
    const isOmitStopLoss = !(intent?.stopLoss?.value);
    const isOmitStopGain = !(intent?.stopGain?.value);
    const newIntent = { ...intent, stopLoss: getSanitizedStopLoss(intent?.stopLoss) };

    return _.omit(newIntent, [
      isTargetEmpty ? 'target' : null,
      isOmitStopLoss ? 'stopLoss' : null,
      isOmitStopGain ? 'stopGain' : null
    ]);
  });
};

export const getSanitizedCases = (cases, { isReEntry, isReExecute }, pipeConfigs) => _.chain(cases)
  .map((caseConfig, caseIndex) => {
    const {
      entry,
      entry: { intents },
      uiConfigs: { isMoveToCost },
      adjustments,
    } = caseConfig;

    const newBaseIntents = _(intents)
      .thru((newIntents) => getBasketOpenLegIntentsBasedOnIntents(newIntents, pipeConfigs))
      .map((intent, intentIndex) => getPositionReferenceForBaseIntent(intent, intentIndex, caseIndex))
      .value();

    const newAdjustments = getBeforeSubmitSanitizedAdjustments(
      adjustments,
      _.reject(newBaseIntents, { type: INTENT_TYPES.OpenBasket }),
      caseIndex,
      isMoveToCost,
      isReEntry,
      isReExecute,
      pipeConfigs
    );

    return {
      ...caseConfig,
      adjustments: _.map(newAdjustments, (adjustmentConfig) => ({
        ...adjustmentConfig,
        intents: getSanitizedIntents(adjustmentConfig.intents),
      })),
      entry: { ...entry, intents: getSanitizedIntents(newBaseIntents) },
    };
  })
  .value();
