import { pipeInputSchemaTypes } from 'v2/common/quantConfig/pipeDefinitions/config';

const configuration = {
  type: 'pivotPoints',
  defaults: {
    type: 'pivotPoints',
    name: 'pivotPoints',
    config: {
      timeFrame: 'day',
      category: 'traditional',
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'pivotPoints'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {
      category: [
        'traditional',
        'fibonacci',
        'woodie',
        'classic',
        'demarks',
        'camarilla'
      ]
    }
  },
  inputSchema: [
    {
      name: 'timeFrame',
      type: pipeInputSchemaTypes.select,
      label: 'Time Frame',
      options: ['day', 'week']
    },
    {
      name: 'category',
      type: pipeInputSchemaTypes.select,
      label: 'Category',
      options: [
        'traditional',
        'fibonacci',
        'woodie',
        'classic',
        'demarks',
        'camarilla'
      ]
    }
  ],
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      'pivotPoint',
      'r1',
      'r2',
      'r3',
      'r4',
      'r5',
      's1',
      's2',
      's3',
      's4',
      's5'
    ];

    return _.map(properties, (property) => {
      return {
        indicatorType: type,
        name: `${name}.${property}`,
        type: 'number',
        category: 'indicator',
        label: name === property ? name : `${name} ${property}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
