import React, { useEffect, useState } from 'react';
import { getUrlParams } from 'common/utils/urlUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/localstorage';

const propTypes = {};

const defaultProps = {};

const PrivateGroupMarketPlaceTab = () => {
  const urlParams = getUrlParams();
  const pg = _.get(urlParams, 'pg', '');
  const privateMarketPlaceLocalStorageKey = 'privateMarketPlace';
  const [lsValue, setLsValue] = useState('');

  useEffect(() => {
    const storageValue = getLocalStorage(privateMarketPlaceLocalStorageKey);
    if (pg) { setLocalStorage(privateMarketPlaceLocalStorageKey, pg); }

    if (storageValue || pg) { setLsValue(storageValue || pg); }
  }, [urlParams]);

  if (_.isEmpty(lsValue)) return null;

  const navigateUrl = `/strategies?locale=en&pg=${lsValue}`;

  return (
    <a
      href={navigateUrl}
      className="nav-link track"
      data-track-category="Nav-Bar"
      data-track-action="Private Group"
      data-track-label="Private Group"
    >
      {_.capitalize(lsValue)}
      {' '}
      MarketPlace
    </a>
  );
};

PrivateGroupMarketPlaceTab.propTypes = propTypes;
PrivateGroupMarketPlaceTab.defaultProps = defaultProps;

export default PrivateGroupMarketPlaceTab;
