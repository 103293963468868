import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'centralPivotRange',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0
    },
    type: 'centralPivotRange',
    name: 'cpr',
    config: {
      timeFrame: 'day',
      category: 'traditional',
      narrowRange: 0.2,
      moderatelyRange: 0.5,
      wideRange: 0.5,
      valuePaths: [
        'item0.equity'
      ]
    }
  },
  searchTerms: [
    'CPR',
    'centralPivotRange'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {
      category: [
        'traditional',
        'fibonacci',
        'woodie',
        'classic',
        'demarks',
        'camarilla'
      ]
    },
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  inputSchema: [
    {
      name: 'timeFrame',
      type: pipeInputSchemaTypes.select,
      label: 'Time Frame',
      options: ['day', 'week']
    },
    {
      name: 'category',
      type: pipeInputSchemaTypes.select,
      label: 'Category',
      options: [
        'traditional',
        'fibonacci',
        'woodie',
        'classic',
        'demarks',
        'camarilla'
      ]
    }
  ],
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const numericProperties = [
      'pivot',
      'BC',
      'TC',
      'R1',
      'R2',
      'R3',
      'R4',
      'R5',
      'S1',
      'S2',
      'S3',
      'S4',
      'S5'
    ];
    const booleanProperties = [
      'isNarrow',
      'isModerately',
      'isWide',
      'isInsideValue',
      'isOutsideValue',
      'isOverlappingHigherValue',
      'isOverlappingLowerValue'
    ];
    const properties = [
      ...numericProperties,
      ...booleanProperties
    ];

    return _.map(properties, (property) => {
      const schema = {
        indicatorType: type,
        name: `${name}.${property}`,
        type: schemaTypes.number,
        category: 'indicator',
        label: `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };

      if (_.includes(booleanProperties, property)) {
        schema.type = schemaTypes.boolean;
      }

      return schema;
    });
  },
  isEnabled: true
};
export default configuration;
