import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const Zebull = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              Please login zebull
              <b>
                <a
                  href="https://go.mynt.in/#/"
                  target="blank"
                  title="Zebull"
                >
                  - https://go.mynt.in/#/
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>client code on top right corner</b>
            </p>
            <img
              src="/images/broker_setup_guide/zebu/step_2.png"
              className="w-100"
              alt="Click profile"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Click the
              {oneIndentation}
              <b>API key</b>
              {oneIndentation}
              button next to the logout put for generate.
            </p>
            <img
              src="/images/broker_setup_guide/zebu/step_3.png"
              alt="Click Settings"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Select the
              {oneIndentation}
              <b>API key Months</b>
            </p>
            <img
              src="/images/broker_setup_guide/zebu/step_4.png"
              className="w-100"
              alt="generate api key"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <p className="stepper-desc">
              Generate the API Key
            </p>
            <img
              src="/images/broker_setup_guide/zebu/step_5.png"
              className="w-100"
              alt="generate api key"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 7</h3>
            <p className="stepper-desc">
              Now, you can copy the
              {oneIndentation}
              <b>APP Key and paste it in appropriate field.</b>
            </p>
            <img
              src="/images/broker_setup_guide/zebu/step_6.png"
              className="w-100"
              alt="generate api key"
            />
          </div>
        </li>

      </ol>
    </div>
  </>
);

Zebull.propTypes = propTypes;
Zebull.defaultProps = defaultProps;

export default Zebull;
