import React, { useEffect, useState } from 'react';

function TelegramPopUp() {
  const [showModal, setShowModal] = useState(false);
  const today = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    const lastModalDate = localStorage.getItem('telegramPopupLastModalDate');

    const storedDate = new Date(lastModalDate);
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

    if (_.isEmpty(lastModalDate) || storedDate < lastWeek) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('telegramPopupLastModalDate', today);
    setShowModal(false);
  };

  return (
    showModal && (
    <div className="popup">
      <button type="button" className="btn btn-link close">
        <span className="material-icons-outlined" onClick={handleCloseModal}>cancel</span>
      </button>
      <div className="d-flex flex-column align-items-center gap-20">
        <img src="/telegram.svg" alt="telegram" className="telegram-img" />
        <h5 className="text-uppercase fw-bold">Join us on telegram</h5>
        <a href="https://t.me/quantman1" className="telegram-btn" onClick={handleCloseModal}>Join Now</a>
      </div>
    </div>
    )
  );
}

export default TelegramPopUp;
