import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { setAdjustmentOptions } from 'modules/QuantBuilder/actions';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import ErrorMesssage from 'common/components/ErrorMesssage';

const EXECUTION_TYPES = {
  immediate: 'Immediate',
  nextMinute: 'Next Minute Start',
  delayBy: 'Delay By'
};

const AdvancedOptions = () => {
  const adjustmentExecutionTypeAdvanceAccess = _.get(
    window,
    'userPermissions.adjustmentExecutionTypeAdvanceAccess.isEnabled',
    false
  );
  const isInitiaload = useRef(true);

  const defaultExecutionType = adjustmentExecutionTypeAdvanceAccess ? 'delayBy' : 'nextMinute';

  const { quantConfig } = useSelector((state) => state);
  const adjustmentOptions = _.get(quantConfig, 'adjustmentOptions', {});

  const executionType = _.get(adjustmentOptions, 'executionType', defaultExecutionType);
  const isDelaySecondsAvailable = _.includes(executionType, 'seconds');
  const isDelayBy = executionType === 'delayBy' || isDelaySecondsAvailable;
  const delay = isDelaySecondsAvailable ? _.parseInt(executionType) : 5;
  const dispatch = useDispatch();

  const [executionTypeErrorId] = useState(_.uniqueId('executionTypeErrorId-'));
  const {
    setError, formState, trigger, errors
  } = useFormContext();

  const onExecutionTypeChange = async (newExecutionType) => {
    dispatch(setAdjustmentOptions(
      {
        ...adjustmentOptions,
        executionType: newExecutionType === 'delayBy' ? `${delay}seconds` : newExecutionType
      }
    ));
    if (formState.isSubmitted) await trigger();
  };

  const onChangeDelay = async (newDelay) => {
    dispatch(setAdjustmentOptions(
      { ...adjustmentOptions, executionType: `${parseInt(newDelay, 10)}seconds` }
    ));
    if (formState.isSubmitted) await trigger();
  };

  useEffect(() => {
    if (isInitiaload.current && _.isEmpty(adjustmentOptions)) {
      setTimeout(() => {
        dispatch(setAdjustmentOptions(
          {
            ...adjustmentOptions,
            executionType: defaultExecutionType === 'delayBy' ? `${delay}seconds` : defaultExecutionType
          }
        ));
        isInitiaload.current = false;
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (formState.isSubmitted) {
      if (executionType === '') {
        setError(executionTypeErrorId, {
          type: 'manual',
          message: 'Execution Type is mandatory.',
        });
      } else if (isDelayBy && (_.isNaN(delay) || delay <= 0)) {
        setError(executionTypeErrorId, {
          type: 'manual',
          message: 'seconds is mandatory when execution type is "Delay By".',
        });
      }
    }
  }, [formState.isSubmitted, executionType, delay, setError, executionTypeErrorId]);

  const renderToggleButton = (value, label) => (
    <ToggleButton
      bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
      value={value}
      key={value}
      data-track-category="Trade Type Configs"
      data-track-action={value}
      data-track-label="Trade Type Configs"
      disabled={_.includes(['immediate', 'delayBy'], value) && !adjustmentExecutionTypeAdvanceAccess}
    >
      {label}
    </ToggleButton>
  );

  return (
    <>
      <h6 className="sub-head mb-3">Advanced Options</h6>
      <div className="row mb-3 align-items-center">
        <div className="col-md-2">
          <label className="col-form-label col-form-label-sm">
            Adjustment / ReEntry / ReExecute / OpenNewlegs
          </label>
        </div>
        <div className="col-md-10">
          <div className="d-flex align-items-center">
            <div className="col-md-4">
              <ToggleButtonGroup
                name="tradeTypeSelector"
                bsPrefix="btn-group btn-group-sm btn-block btn-group-toggle"
                type="radio"
                value={isDelayBy ? 'delayBy' : executionType}
                onChange={onExecutionTypeChange}
              >
                {_.map(EXECUTION_TYPES, (value, key) => renderToggleButton(key, value))}
              </ToggleButtonGroup>
            </div>

            <div className="col-md-4 d-flex">
              {isDelayBy && (
              <>
                <div className="input-group input-group-sm">
                  <div className="input-group-prepend">
                    <span className="input-group-text">After</span>
                  </div>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    value={delay}
                    min="1"
                    onChange={(event) => onChangeDelay(event.target.value)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">seconds</span>
                  </div>
                </div>
              </>
              )}
            </div>
          </div>

          <ErrorMesssage error={errors[executionTypeErrorId]} />
        </div>
      </div>
    </>
  );
};

export default AdvancedOptions;
