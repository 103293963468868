import moment from 'moment';

export const renderDateInPrettyFormat = (dateStr) => {
  const dateMmt = moment(dateStr);
  if (!dateMmt.isValid() || _.isEmpty(dateStr)) return 'N/A';

  return dateMmt.format('dddd, MMMM Do YYYY');
};

export const getActualPlanEnddate = (dateStr) => {
  return moment(dateStr);
};
