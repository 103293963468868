import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TRANSACTION_TYPES } from 'v2/common/constants/index';

const propTypes = {
  side: PropTypes.string.isRequired,
  onChangeSide: PropTypes.func.isRequired,
};
const defaultProps = {};

const BuyOrSellSelector = ({ side, onChangeSide }) => {
  const { buy, sell } = TRANSACTION_TYPES;
  const isBuy = side === buy;
  const divClassName = classNames('', {
    'bg-success p-1': isBuy,
    'bg-danger p-1': !isBuy
  });

  return (
    <div
      className={divClassName}
      onClick={() => onChangeSide(isBuy ? sell : buy)}
    >
      <label className="text-white">
        {_.startCase(side)}
      </label>
    </div>
  );
};

BuyOrSellSelector.propTypes = propTypes;
BuyOrSellSelector.defaultProps = defaultProps;

export default memo(BuyOrSellSelector);
