import _ from 'lodash';
import { INTENT_TYPES, SEGMENT_CONFIG } from 'v2/common/constants/index';
import { getDefaultIdentifier } from 'v2/modules/withRunForm/configs/index';

export const reEntryBasedAdjustmentAdder = (intent, lastAdjustmentConfigIndex) => {
  const { reEntryCount, identifier, instrument: { instrumentIndex } } = intent;
  let currentAdjustmentConfigIndex = lastAdjustmentConfigIndex;

  let previousIdentifier = identifier;

  return _.map(_.range(reEntryCount), () => {
    const { segment, adjustmentConfigIndex, optionConfigIndex } = previousIdentifier;
    const isOption = segment === SEGMENT_CONFIG.option;

    let newLeftOperand = '';
    let newSegment = segment;
    if (optionConfigIndex !== null) newSegment += `_${optionConfigIndex}`;
    if (adjustmentConfigIndex !== null) newSegment += `_${adjustmentConfigIndex}`;

    // TODO: Use `buildLegProperty` from ./config.js
    newLeftOperand += `ordersExtra.${instrumentIndex}.${newSegment}.isPriceCrossedEntryPriceAfterStopLoss`;

    const newIndentifer = {
      ...getDefaultIdentifier(),
      segment,
      type: 'Legacy',
      caseIndex: identifier.caseIndex,
      adjustmentConfigIndex: currentAdjustmentConfigIndex,
      instrumentIndex,
      optionConfigIndex: isOption ? 0 : null,
    };

    const result = {
      uiConfigs: {
        isAutoAdjustment: true,
        adjustmentMode: 'reEntry'
      },
      signal: [
        {
          conditionOperator: '',
          leftOperand: newLeftOperand,
          operator: 'equalTo',
          rightOperand: '1',
        },
        {
          conditionOperator: 'and',
          leftOperand: _.replace(newLeftOperand, 'isPriceCrossedEntryPriceAfterStopLoss', 'slUpdated'),
          operator: 'equalTo',
          rightOperand: '0',
        }
      ],
      intents: [{
        type: INTENT_TYPES.ReEntry,
        target: identifier,
        identifier: newIndentifer,
      }]
    };
    previousIdentifier = newIndentifer;
    currentAdjustmentConfigIndex += 1;
    return result;
  });
};
