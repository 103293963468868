import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import {
  schemaShouldShowConfigTypes,
  schemaTypes,
} from '../outputSchema/config';

const configuration = {
  type: 'fisherTransform',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'fisherTransform',
    name: 'ft',
    config: {
      noOfCandles: 9,
      candleInterval: '3 minutes',
      valuePaths: ['item0.equity'],
    },
  },
  searchTerms: ['fisherTransform', 'ft'],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future],
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = ['fisher', 'trigger'];

    return _.map(properties, (property) => ({
      name: `${name}.${property}`,
      type: schemaTypes.number,
      indicatorType: type,
      category: 'indicator',
      label: `${name} ${property}`,
      shouldShowConfigs: [
        schemaShouldShowConfigTypes.adjustmentLeftOperand,
        schemaShouldShowConfigTypes.adjustmentRightOperand,
        schemaShouldShowConfigTypes.entryLeftOperand,
        schemaShouldShowConfigTypes.entryRightOperand,
        schemaShouldShowConfigTypes.exitLeftOperand,
        schemaShouldShowConfigTypes.exitRightOperand,
        schemaShouldShowConfigTypes.intentValuePath,
      ],
      priority: 70,
      schemaName: name,
    }));
  },
  isEnabled: true,
};
export default configuration;
