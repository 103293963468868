import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ORDER_TYPES, RUN_TYPES } from 'common/constants/index';
import BuySellTimeAndStopLossDiff from '../BuySellTimeAndStopLossDiff/index';
import StopsBuilder from '../StopsBuilder/index';
import ProductType from '../ProductType/index';
import OrderType from '../OrderType/index';
import { removeKeyValues } from '../../actions';
import OptionsEntryBuyOrders from '../OptionsEntryBuyOrders/index';
import SlOrderBasedOnPrice from '../SlOrderBasedOnPrice/index';
import LimitOrderBufferPercentage from '../LimitOrderBufferPercentage/index';
import PlaceStoplossOrderToBroker from '../PlaceStoplossOrderToBroker/index';
import SquareOffEntireTransactionIfEntryFailed from '../SquareOffEntireTransactionIfEntryFailed/index';

const propTypes = {
  runType: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  dispatchRemoveKeyValues: PropTypes.func.isRequired,
  entryOrderType: PropTypes.string.isRequired,
  exitOrderType: PropTypes.string.isRequired
};

const defaultProps = {};

const ProfitAndOrdersComponents = ({
  runType, orderType, dispatchRemoveKeyValues, entryOrderType, exitOrderType
}) => {
  const isLive = runType === RUN_TYPES.live;
  const { paper: { value: paper } } = ORDER_TYPES;
  // const title = !isLive ? 'Profit' : 'Profit/ Order';
  const isActualOrder = orderType !== paper;

  useEffect(() => {
    if (!isActualOrder || runType === RUN_TYPES.historic) {
      dispatchRemoveKeyValues([
        'entryOrderModification',
        'exitOrderModification',
        'isPlaceOptionsEntryBuyOrdersFirst',
        'slOrderPlaceBasedOnPrice',
        'isPlaceStoplossOrderToBroker',
        'squareOffTransactionIfEntryFailed'
      ]);
    }
  }, [runType, orderType]);

  useEffect(() => { // newly implemented, old runs don't have those config so i attached to quantConfig.
    if (entryOrderType === 'market') {
      dispatchRemoveKeyValues([
        'entryOrderModification'
      ]);
    }
    if (exitOrderType === 'market') {
      dispatchRemoveKeyValues([
        'exitOrderModification'
      ]);
    }
  }, [entryOrderType, exitOrderType]);

  return (
    <>
      <h6 className="sub-head mb-3">Strategy Stops</h6>
      <div className="mb-2">
        <StopsBuilder />
      </div>
      {(isLive && isActualOrder) && (
        <>
          <div className="profit-and-orders">
            <ProductType />
            <OrderType />
            <BuySellTimeAndStopLossDiff />
            <LimitOrderBufferPercentage />
            {isActualOrder && <OptionsEntryBuyOrders />}
            {isActualOrder && <SlOrderBasedOnPrice />}
            {isActualOrder && <SquareOffEntireTransactionIfEntryFailed />}
            {isActualOrder && <PlaceStoplossOrderToBroker />}
          </div>
        </>
      )}
    </>
  );
};

ProfitAndOrdersComponents.propTypes = propTypes;
ProfitAndOrdersComponents.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  runType: _.get(state, 'runType'),
  orderType: _.get(state, 'orderType'),
  entryOrderType: _.get(state, 'quantConfig.entryOrderType'),
  exitOrderType: _.get(state, 'quantConfig.exitOrderType'),
  orderConfigs: _.get(state, 'quantConfig.orderConfigs')
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRemoveKeyValues: (keyValues) => dispatch(removeKeyValues(keyValues))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfitAndOrdersComponents);
