import React from 'react';
import Paginate from 'react-paginate';
import PropTypes from 'prop-types';

const propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};
const defaultProps = {};

const PAGE_RANGE = 6;
const MARGIN_PAGES = 1;

const Pagination = (props) => {
  const { totalPages, onPageChange, currentPageIndex } = props;

  return (
    <Paginate
      pageCount={totalPages}
      pageRangeDisplayed={PAGE_RANGE}
      marginPagesDisplayed={MARGIN_PAGES}
      forcePage={currentPageIndex}
      nextLabel={(
        <div>
          <span>NEXT</span>
          <i className="material-icons-outlined next-label">chevron_right</i>
        </div>
      )}
      previousLabel={(
        <div>
          <span>PREV</span>
          <i className="material-icons-outlined pre-label">chevron_left</i>
        </div>
      )}
      onPageChange={onPageChange}
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination mb-0"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      activeClassName="active"
    />
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
