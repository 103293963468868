import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getIntentsError } from './getIntentsError';
import { getSelectorBasedStoreValue } from '../../redux/selector';

const useIntentsError = (
  intents,
  control,
  errors,
  setError,
  clearErrors
) => {
  const uniqueId = useMemo(() => _.uniqueId('intent_expiry_error'), []);
  const instrumentGroups = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.instrumentGroups')
  );

  useEffect(() => {
    const { errorMessage, errorConfigs } = getIntentsError(intents, instrumentGroups);

    if (errorMessage) {
      return setError(uniqueId, { type: 'custom', message: errorMessage, errorConfigs });
    }
    clearErrors(uniqueId);

    return () => clearErrors(uniqueId);
  }, [clearErrors, intents, setError, uniqueId, instrumentGroups]);

  return {
    errorMessage: _.get(errors, `${uniqueId}.message`, ''),
    errorConfigs: _.get(errors, `${uniqueId}.errorConfigs`, {})
  };
};

export default useIntentsError;
