import { getAlteredEmptyQuantConfig } from 'modules/QuantBuilder/config/index';
import { getStopGainPercent, getStopGainPrice } from '../../config';

export const RELATIVE_STRENGTH_INDEX = 'relativeStrengthIndex';

export const rsiDefaultConfig = {
  comparisonType: 'crossesAbove',
  period: 10,
  actiontype: 'buy',
  optionType: 'CE',
  strikeIndex: 0,
  stopLoss: 0,
  stopGain: 0,
  transaction: 'future',
  quantity: 1,
  stopLossType: 'percent',
  stopGainType: 'percent',
};

export const quantConfigConversionRsi = (config) => {
  const {
    comparisonType,
    period,
    actiontype,
    optionType,
    strikeIndex,
    stopLoss,
    stopGain,
    transaction,
    instruments,
    quantity,
    stopLossType,
    stopGainType,
    segment
  } = config;

  const pipeConfig = [{
    type: RELATIVE_STRENGTH_INDEX,
    name: `rsi${period}`,
    config: {
      noOfCandles: Number(period),
      candleInterval: '1 minutes',
      valuePaths: ['item0.month1Price'],
    },
  }];
  const emptyConfig = getAlteredEmptyQuantConfig();
  const { orderConfigs, pipeConfigs } = emptyConfig;
  const { transactionConfigs } = _.head(orderConfigs);
  const { optionConfigs, futureConfig, mode } = _.head(transactionConfigs);
  const optionConfigObj = _.head(optionConfigs);
  const setOptionConfig = {
    ...optionConfigObj,
    entryType: actiontype,
    optionType,
    stopGainPrice: getStopGainPrice(stopGainType, stopGain),
    stopLossPrice: getStopGainPrice(stopLossType, stopLoss),
    stopGain: getStopGainPercent(stopGainType, stopGain),
    stopLoss: getStopGainPercent(stopLossType, stopLoss),
    strikeIndex: Number(strikeIndex),
    quantity: getQuantityObject(Number(quantity)),
    sortId: 0
  };
  const setFutureConfig = {
    ...futureConfig,
    entryType: actiontype,
    stopGainPrice: getStopGainPrice(stopGainType, stopGain),
    stopLossPrice: getStopGainPrice(stopLossType, stopLoss),
    stopGain: getStopGainPercent(stopGainType, stopGain),
    stopLoss: getStopGainPercent(stopLossType, stopLoss),
    quantityExpression: `${quantity}`,
    sortId: 1
  };
  const isTransactionOption = transaction === 'option';
  const modifiedOrderConfigs = [{
    ..._.head(orderConfigs),
    entrySignalConfigs: [{
      conditionOperator: '',
      leftOperand: `rsi${period}`,
      operator: comparisonType,
      rightOperand: '55',
    }],
    transactionConfigs: [
      {
        ..._.head(transactionConfigs),
        mode: {
          ...mode,
          transaction
        },
        futureConfig: isTransactionOption ? futureConfig : setFutureConfig,
        optionConfigs: isTransactionOption ? [
          {
            ...optionConfigObj,
            ...setOptionConfig,
          }
        ] : []
      }
    ]
  }];

  return {
    ...emptyConfig,
    segment,
    instrumentGroups: instruments,
    pipeConfigs: [...pipeConfigs, ...pipeConfig],
    orderConfigs: modifiedOrderConfigs,
  };
};
