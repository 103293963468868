import React from 'react';
import PropTypes from 'prop-types';
import { tagsconfig } from 'ui/Strategies/config';

const propTypes = {
  filterConfigs: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onUpdateFilterConfigs: PropTypes.func.isRequired,
};

const defaultProps = {};

const TagFilter = ({ filterConfigs, onUpdateFilterConfigs }) => {
  const { tags } = filterConfigs;
  const options = _.keys(tagsconfig);

  const renderTagOptions = (tag, idx) => {
    const onHandleInstrument = () => {
      if (_.includes(tags, tag)) {
        return _.filter(tags, (value) => value !== tag);
      }

      return [...tags, tag];
    };

    return (
      <div className="form-check form-check-sm" key={idx}>
        <input
          className="form-check-input track"
          type="checkbox"
          data-track-category="Market Place Filter"
          data-track-action={tag}
          data-track-label="Instrument Filter"
          value={tag}
          id={tag}
          checked={_.includes(tags, tag)}
          onChange={() => { }}
          onClick={() => onUpdateFilterConfigs({
            ...filterConfigs,
            tags: onHandleInstrument()
          })}
        />
        <label className="form-check-label" htmlFor={tag}>{_.startCase(tag)}</label>
      </div>
    );
  };
  return _.isEmpty(options) ? null : (
    <div className="mp-filter">
      <h6 className="mp-filter-title">Category</h6>
      <div className="mp-filter-list">
        {_.map(options, renderTagOptions)}
      </div>
    </div>
  );
};

TagFilter.propTypes = propTypes;
TagFilter.defaultProps = defaultProps;

export default TagFilter;
