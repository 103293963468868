import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  isShowcased: PropTypes.bool.isRequired,
  isRunBelongsToUser: PropTypes.bool.isRequired,
};
const defaultProps = {};

const ShowcasedRunTag = ({ isShowcased, isRunBelongsToUser }) => {
  if (isRunBelongsToUser) return null;

  return (
    <div className="run-type-show alert alert-sm alert-info mb-0 py-1 px-2 text-nowrap">
      {isShowcased ? 'ShowCased Run' : 'Public Run'}
    </div>
  );
};

ShowcasedRunTag.propTypes = propTypes;
ShowcasedRunTag.defaultProps = defaultProps;

export default ShowcasedRunTag;
