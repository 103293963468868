import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { QUANT_CONFIG_ORDER_TYPES } from 'v2/common/constants/index';

const propTypes = {
  title: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
const defaultProps = {};

const renderOptions = (key, value) => {
  const { isStopLossLimitOrderEnabled } = window;
  if (key === 'sl-l' && !isStopLossLimitOrderEnabled) return null;

  return <option key={value} value={value}>{_.startCase(value)}</option>;
};

const OrderTypeInput = ({ title, orderType, onChange }) => (
  <div className="quant-config-order-type input-group-sm">
    <div className="input-group-prepend">
      <div className="input-group-text">{title}</div>
    </div>
    <Form.Control
      as="select"
      size="sm"
      value={orderType}
      onChange={(event) => onChange(event.target.value)}
    >
      {_.map(QUANT_CONFIG_ORDER_TYPES, renderOptions)}
    </Form.Control>
  </div>
);

OrderTypeInput.propTypes = propTypes;
OrderTypeInput.defaultProps = defaultProps;

export default OrderTypeInput;
