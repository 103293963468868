import React from 'react';
import PropTypes from 'prop-types';

import { outputSchemaPropTypes, signalConfigPropTypes } from 'common/propTypes';
import { getSelectedOperandLabelForSignal } from 'common/utils/displayNames';
import ReactHtmlParser from 'react-html-parser';
import { labelForEnumPattern } from 'common/utils/signalConfigUtils';
import withErrorBoundary from './withErrorBoundary/index';

const propTypes = {
  signalConfigs: PropTypes.arrayOf(signalConfigPropTypes),
  outputSchema: outputSchemaPropTypes.isRequired
};
const defaultProps = {
  signalConfigs: []
};

const getCustomizedOperator = (operator, rightOperand) => {
  const isConditionMatched = (rightOperand === true
    || rightOperand === 'false' || rightOperand === 'true' || rightOperand === false
  ) && operator === 'equalTo';

  if (isConditionMatched) return labelForEnumPattern;

  return operator;
};

const renderSignalConfig = (signalConfig, key, outputSchema) => {
  const {
    conditionOperator, leftOperand, operator, rightOperand
  } = signalConfig;
  const sentence = [];

  const leftOperandConfig = getSelectedOperandLabelForSignal(outputSchema, leftOperand);
  const rightOperandConfig = getSelectedOperandLabelForSignal(
    outputSchema, leftOperand, rightOperand
  );
  const leftOperandLabel = _.map(leftOperandConfig, (
    { label, labelHtml }
  ) => { return labelHtml || label; }).join(',');
  const rightOperandLabel = _.map(rightOperandConfig, ({ label, labelHtml }) => {
    return labelHtml || label;
  }).join(',');

  const customizedOperator = getCustomizedOperator(operator, rightOperandLabel);

  sentence.push(
    conditionOperator ? (
      <span className="text-uppercase font-weight-bold mx-1" key={`${key}-condition`}>
        {conditionOperator}
      </span>
    ) : ''
  );
  sentence.push(
    <span className="left-operand-label" key={`${key}-leftOperand`}>
      {ReactHtmlParser(leftOperandLabel || '')}
    </span>
  );
  sentence.push(<u key={`${key}-operator`}>{_.startCase(customizedOperator || '')}</u>);
  sentence.push(
    <span className="right-operand-label" key={`${key}-rightOperand`}>
      {ReactHtmlParser(rightOperandLabel || '')}
    </span>
  );

  return sentence;
};

const SignalSentence = ({ signalConfigs, outputSchema = [] }) => {
  if (_.isEmpty(signalConfigs)) return (<samp>(None)</samp>);

  const signalSentence = _.map(signalConfigs, (
    signalConfig, key
  ) => renderSignalConfig(signalConfig, key, outputSchema));

  return (<span className="signal-sentance">{signalSentence}</span>);
};

SignalSentence.propTypes = propTypes;
SignalSentence.defaultProps = defaultProps;

export default withErrorBoundary(SignalSentence, '', false);
