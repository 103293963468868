import React from 'react';
import AdjustmentOptions from './AdjustmentOptions/index';

const AdvancedOptions = () => {
  return (
    <>
      <div className="advanced_options_wrap py-3 px-3 px-sm-4 py-sm-5">
        <div className="mb-2 mb-md-5 font-weight-medium tx-15">Advanced Options</div>
        <AdjustmentOptions />
      </div>
    </>
  );
};

export default AdvancedOptions;
