import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import OptionTypeSelector from 'v2/common/components/OptionTypeSelector/index';
import ExpirySelector from 'v2/common/components/ExpirySelector/index';
import StrikeBasedSelector from 'v2/common/components/StrikeBasedSelector/index';
import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import InstrumentIndexSelector from 'v2/common/components/InstrumentIndexSelector/index';
import classNames from 'classnames';
import { IntentsContext } from 'v2/modules/withRunForm/hoc/withIntentsContext';

const propTypes = {
  intentConfigs: PropTypes.shape({
    segment: PropTypes.string,
    expiry: PropTypes.shape({}),
    strike: PropTypes.shape({}).isRequired,
    optionType: PropTypes.string.isRequired,
    instrumentIndex: PropTypes.number,
    onChangeExpiry: PropTypes.func.isRequired,
    onChangeStrike: PropTypes.func.isRequired,
    onChangeOptionType: PropTypes.func.isRequired,
    onChangeInstrumentIndex: PropTypes.func.isRequired,
    isShowInstrumentIndex: PropTypes.bool,
    isIndexInstrumentIntent: PropTypes.bool,
    isIndexInstrumentWithWeeklyExpiry: PropTypes.bool,
    maxInstrumentsInGroup: PropTypes.number,
    isAdvancedMode: PropTypes.bool,
    isShowMultiplier: PropTypes.bool,
    onChangeMultiplier: PropTypes.func,
    multiplier: PropTypes.number,
  }).isRequired,
};

const defaultProps = {};

const multiplierOptions = [10, 20, 50, 100, 500];

const renderMultiplier = (intentConfigs) => {
  if (!intentConfigs?.isAdvancedMode) {
    return null;
  }
  if (!intentConfigs?.isShowMultiplier) {
    return (
      <div className="multiplier" />
    );
  }
  return (
    <div className="multiplier">
      <select
        value={intentConfigs?.multiplier}
        onChange={(event) => intentConfigs.onChangeMultiplier(Number(event.target.value))}
      >
        <option value="" />
        {multiplierOptions.map((value) => {
          return (
            <option value={value} key={value}>
              *
              {value}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const InstrumentBasedDetails = ({ intentConfigs }) => {
  const isOption = intentConfigs.segment === SEGMENT_CONFIG.option;
  const {
    errors,
    setError,
    clearErrors,
    control,
    errorConfigs
  } = useContext(IntentsContext);
  const intentId = _.get(intentConfigs, 'intentId', '');

  const instrumentClassNames = classNames('transaction_details-items', {
    instrument: !intentConfigs?.isShowInstrumentIndex,
    with_advanced_mode: intentConfigs?.isAdvancedMode,
    pair_instrument: intentConfigs?.isShowInstrumentIndex,
  });

  return (
    <div className={instrumentClassNames}>
      {isOption && (
        <ExpirySelector
          isDisabled={!isOption}
          errorConfig={_.get(errorConfigs, intentId, {})}
          expiry={intentConfigs?.expiry}
          onChangeExpiry={intentConfigs.onChangeExpiry}
        />
      )}
      {isOption && (
        <StrikeBasedSelector
          strike={intentConfigs?.strike}
          errors={errors}
          clearErrors={clearErrors}
          setError={setError}
          control={control}
          isDisabled={!isOption}
          optionType={intentConfigs?.optionType}
          onChangeStrike={intentConfigs?.onChangeStrike}
          isIndexInstrumentBased={intentConfigs?.isIndexInstrumentIntent}
        />
      )}
      {renderMultiplier(intentConfigs)}
      {isOption && (
        <OptionTypeSelector
          optionType={intentConfigs?.optionType}
          isDisabled={!isOption}
          onChangeOptionType={intentConfigs.onChangeOptionType}
        />
      )}
      {intentConfigs?.isShowInstrumentIndex && (
        <InstrumentIndexSelector
          instrumentIndex={intentConfigs?.instrumentIndex}
          onChangeInstrumentIndex={intentConfigs?.onChangeInstrumentIndex}
          maxInstrumentsInGroup={intentConfigs?.maxInstrumentsInGroup}
        />
      )}
    </div>
  );
};

InstrumentBasedDetails.propTypes = propTypes;
InstrumentBasedDetails.defaultProps = defaultProps;

export default InstrumentBasedDetails;
