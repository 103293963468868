import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { EXTERNAL_SIGNALS_VENDOR_TYPES } from 'v2/common/constants/index';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired
};

const VendorField = ({
  name: propsName, value, onUpdatePipeConfig
}) => {
  const renderOptions = useCallback((vendorType) => {
    return (
      <ToggleButton
        key={vendorType}
        bsPrefix="btn btn-sm btn-outline-primary btn-lightness text-white"
        className={`btn-lightness text-white track ${value === vendorType ? 'active' : ''}`}
        onChange={() => onUpdatePipeConfig(propsName, vendorType)}
      >
        {vendorType}
      </ToggleButton>

    );
  }, [onUpdatePipeConfig, propsName, value]);

  return (
    <div className="external-vendor-type">
      <ToggleButtonGroup
        className="btn-group btn-group-sm btn-group-toggle"
        type="radio"
        size="sm"
        name="options"
      >
        {_.map(EXTERNAL_SIGNALS_VENDOR_TYPES, renderOptions)}
      </ToggleButtonGroup>
    </div>
  );
};

VendorField.propTypes = propTypes;

export default VendorField;
