import React from 'react';
import PropTypes from 'prop-types';

import SingleBarChart from 'common/components/SingleBarChart/index';
import Money from 'common/components/MoneyDisplay';

const propTypes = {
  analyticsData: PropTypes.shape({
    hitPercent: PropTypes.number,
    missPercent: PropTypes.number,
    hit: PropTypes.number,
    miss: PropTypes.number,
    profit: PropTypes.number,
    profitPercent: PropTypes.number,
    loss: PropTypes.number,
  }).isRequired,
  title: PropTypes.string.isRequired
};
const defaultProps = {};

const BarChart = ({ analyticsData, title }) => {
  const {
    hitPercent, miss, hit, profit, profitPercent, loss
  } = analyticsData;

  return (
    <div className="barchart-wrapper">
      <div className="barchart-label">
        {_.startCase(title)}
      </div>
      <SingleBarChart
        fillPercent={hitPercent}
        rightValue={`<div><span>Hit</span> ${hit}</div>`}
        leftValue={`<div><span>Miss</span> ${miss}</div>`}
        chartType="hit-miss"
      />
      <SingleBarChart
        fillPercent={profitPercent}
        isMoney
        rightValue={<Money>{profit}</Money>}
        leftValue={<Money>{loss}</Money>}
        chartType="profit-loss"
      />
    </div>
  );
};

BarChart.propTypes = propTypes;
BarChart.defaultProps = defaultProps;

export default BarChart;
