import qs from 'qs';

export const updateUrlQueryParams = (key, value, isReplaceState = false) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, value);

  // Replace current querystring with the new one.
  if (isReplaceState) {
    window.history.replaceState(null, null, `?${queryParams.toString()}`);
  } else {
    window.history.pushState(null, null, `?${queryParams.toString()}`);
  }
};

export const getUrlParams = () => {
  const queryString = window.location.search;
  const parsedQueryString = qs.parse(queryString, { parseBooleans: true });
  const removedSpecialCharacters = _.reduce(parsedQueryString, (result, value, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key.replace(/[^a-zA-Z0-9 ]/g, '')] = value;
    return result;
  }, {});

  return removedSpecialCharacters;
};
