import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  textCount: PropTypes.number
};
const defaultProps = {
  children: null,
  textCount: 10
};

const ReadMore = ({ children, textCount }) => {
  if (!children) return null;

  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const isShowReadMore = _.size(text) > textCount;

  return (
    <>
      {isReadMore ? text.slice(0, textCount) : text}
      {isShowReadMore && (
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? '... Read more' : ' Show less'}
      </span>
      )}
    </>
  );
};

ReadMore.propTypes = propTypes;
ReadMore.defaultProps = defaultProps;

export default ReadMore;
