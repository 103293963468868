import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const propTypes = {
  fillPercent: PropTypes.number.isRequired,
  rightValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  leftValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  chartType: PropTypes.string.isRequired,
  isMoney: PropTypes.bool
};
const defaultProps = {
  isMoney: false
};

const SingleBarChart = ({
  fillPercent, rightValue, leftValue, chartType, isMoney
}) => (
  <div className={`single-bar-chart ${chartType}`}>
    <div className="single-bar-value">
      <div>{isMoney ? rightValue : ReactHtmlParser(rightValue)}</div>
      <div>{isMoney ? leftValue : ReactHtmlParser(leftValue)}</div>
    </div>
    <div className="progress">
      <div
        className={`single-bar bg-color pw-${_.round(fillPercent)}`}
        role="progressbar"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </div>
);

SingleBarChart.propTypes = propTypes;
SingleBarChart.defaultProps = defaultProps;

export default SingleBarChart;
