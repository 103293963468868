import { outputSchemaPropTypes, signalsPropTypes } from 'common/propTypes';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useSignalsForm from './useSignalsForm';
import Signal from './Signal/index';

const propTypes = {
  signals: signalsPropTypes.isRequired,
  signalType: PropTypes.string.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onUpdateSignals: PropTypes.func.isRequired,
};
const defaultProps = {};

const Main = ({
  signals: propsSignals, signalType, outputSchema, control, errors, onUpdateSignals
}) => {
  const {
    signals, onAddNewSignal, onUpdateSignal, onDeleteSignal
  } = useSignalsForm(propsSignals, onUpdateSignals);

  const renderSignal = (signal, idx) => {
    return (
      <Signal
        signal={signal}
        key={idx}
        control={control}
        errors={errors}
        isShowDeleteBtn={signals.length > 1}
        outputSchema={outputSchema}
        signalType={signalType}
        onUpdateSignal={(newSignal) => onUpdateSignal(newSignal, idx)}
        shouldShowConditionalOperator={idx !== 0}
        onDeleteSignal={() => onDeleteSignal(idx)}
      />
    );
  };

  return (
    <div>
      {_.map(signals, renderSignal)}
      <div className="py-2 d-flex align-items-center justify-content-center">
        <button
          className="btn btn-secondary"
          type="button"
          onClick={onAddNewSignal}
        >
          Add Condition
        </button>
      </div>
    </div>
  );
};

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;

export default memo(Main);
