import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import _ from 'lodash';
import { setPartialQuantConfig, setPipeConfigs } from 'v2/modules/withRunForm/redux/actions';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import {
  useCallback, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getPipesDependencies from './pipesDependencies';
import useOutputSchema from '../useOutputSchema';
import { onChangePipeName } from '../../events/pipeName';
import { onChangeGlobalSegment } from '../../events/globalSegment';

const usePipeConfigs = () => {
  const pipeConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.pipeConfigs'));
  const cases = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.cases'));
  const outputSchema = useOutputSchema();
  const dispatch = useDispatch();
  const uiConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs'));
  const isHiddenSignal = useSelector((state) => getSelectorBasedStoreValue(state, 'isHiddenSignal'));

  const [pipeFormConfigs, setPipeFormConfigs] = useState({
    shouldRenderPipeForm: false,
    currentPipeConfig: null,
    editIndex: null
  });

  const pipeDependencies = useMemo(() => getPipesDependencies(pipeConfigs, cases), [cases, pipeConfigs]);

  const existingPipeNames = useMemo(() => {
    const pipeNames = _.map(pipeConfigs, 'name');
    if (pipeFormConfigs?.editIndex === null) return pipeNames;

    return _.filter(pipeNames, (name, idx) => idx !== pipeFormConfigs?.editIndex);
  }, [pipeConfigs, pipeFormConfigs.editIndex]);

  const getPipeUpdatedNameIfAlreadyPresent = useCallback((pipeConfig) => {
    if (_.includes(existingPipeNames, pipeConfig?.name)) {
      return { ...pipeConfig, name: _.uniqueId(pipeConfig?.name) };
    }
    return pipeConfig;
  }, [existingPipeNames]);

  const onUpdatePipeFormConfigs = useCallback((partialPipeFormConfigs) => {
    setPipeFormConfigs((prev) => ({ ...prev, ...partialPipeFormConfigs }));
  }, []);

  const onUpdatePipeConfigsToStore = useCallback((pipeConfig) => {
    if (pipeFormConfigs?.editIndex === null) {
      const newPipeConfigs = [...pipeConfigs, pipeConfig];
      return dispatch(setPipeConfigs(newPipeConfigs));
    }
    const newPipeConfigs = _.cloneDeep(pipeConfigs);
    const oldPipeConfig = newPipeConfigs[pipeFormConfigs?.editIndex];
    newPipeConfigs[pipeFormConfigs?.editIndex] = pipeConfig;

    const oldPipeName = oldPipeConfig?.name;
    const newPipeName = pipeConfig?.name;

    if (oldPipeName !== newPipeName) {
      return dispatch(
        setPartialQuantConfig(
          onChangePipeName({
            cases,
            oldPipeName,
            newPipeName,
            pipeConfigs: newPipeConfigs
          })
        )
      );
    }

    return dispatch(setPipeConfigs(newPipeConfigs));
  }, [cases, dispatch, pipeConfigs, pipeFormConfigs.editIndex]);

  const onDeletePipeConfig = useCallback((idx) => {
    const newPipeConfigs = _.filter(pipeConfigs, (config, index) => index !== idx);
    return dispatch(setPipeConfigs(newPipeConfigs));
  }, [dispatch, pipeConfigs]);

  const onEditPipeConfig = useCallback((idx) => {
    const currentPipeConfig = pipeConfigs[idx];
    setPipeFormConfigs((prev) => ({
      ...prev,
      shouldRenderPipeForm: true,
      currentPipeConfig,
      editIndex: idx
    }));
  }, [pipeConfigs]);

  const onAddIndicator = useCallback(() => {
    const { pipeConfigs: newPipeConfigs } = onChangeGlobalSegment({
      pipeConfigs: [PipeDefinitionUtility.getPipeConfigDefaults('simpleMovingAverage')],
      uiConfigs
    });

    setPipeFormConfigs((prev) => ({
      ...prev,
      shouldRenderPipeForm: true,
      currentPipeConfig: getPipeUpdatedNameIfAlreadyPresent(newPipeConfigs[0]),
    }));
  }, [getPipeUpdatedNameIfAlreadyPresent, uiConfigs]);

  return {
    pipeConfigs,
    ...pipeFormConfigs,
    onUpdatePipeFormConfigs,
    outputSchema,
    pipeDependencies,
    existingPipeNames,
    onAddIndicator,
    onDeletePipeConfig,
    getPipeUpdatedNameIfAlreadyPresent,
    onUpdatePipeConfigsToStore,
    onEditPipeConfig,
    isHiddenSignal
  };
};

export default usePipeConfigs;
