import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { STOPS_VALUE_TYPES, stopsConfig } from 'modules/TransactionsBuilder/configs';
import StopsValueTypeToggleBtn from 'common/components/StopsValueTypeToggleBtn';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';

const propTypes = {
  stopsType: PropTypes.string.isRequired,
  stopsValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onStopsValueChange: PropTypes.func.isRequired,
  valueType: PropTypes.string.isRequired
};
const defaultProps = { stopsValue: undefined };

const StopsInput = ({
  stopsType, stopsValue, valueType: propsValueType, onStopsValueChange
}) => {
  const { PERCENT, PRICE } = STOPS_VALUE_TYPES;
  const [valueType, setValueType] = useState(propsValueType);
  const emptyStopsValue = _.get(stopsConfig, [stopsType, valueType, 'config'], {});
  const [value, setValue] = useState(stopsValue);

  useEffect(() => { setValue(stopsValue); }, [stopsValue]);

  const onValueTypeChange = () => {
    setValueType(valueType === PRICE ? PERCENT : PRICE);
    onStopsValueChange({ ...emptyStopsValue, });
  };

  const onChange = (event) => {
    const newStopsType = valueType === PERCENT ? stopsType : `${stopsType}Price`;
    onStopsValueChange({
      ...emptyStopsValue, [newStopsType]: onRegexOnlyNumberAndFloat(event.target.value, false)
    });
  };

  return (
    <div className="input-group overall-stops">
      <StopsValueTypeToggleBtn
        valueType={valueType}
        onValueTypeChange={onValueTypeChange}
      />

      <input
        type="text"
        className="form-control form-control-sm"
        name="stopsInput"
        data-stoploss-field-name={`${stopsType}-${valueType}`}
        onBlur={(event) => onChange(event, false)}
        value={value === undefined ? '' : value.toString()}
        onChange={(event) => { setValue(onRegexOnlyNumberAndFloat(event.target.value, true)); }}
      />
    </div>
  );
};

StopsInput.propTypes = propTypes;
StopsInput.defaultProps = defaultProps;

export default StopsInput;
