import React, { useState } from 'react';
import { userPropTypes } from 'common/propTypes';
import PhoneNumberVerification from 'ui/PhoneNumberVerification/index';

const propTypes = {
  currentUserDetails: userPropTypes.isRequired
};

const defaultProps = {};

const MobileNumberDetails = ({ currentUserDetails }) => {
  const { phone_number: phoneNumber } = currentUserDetails;
  const [isUpdateMobileNumber, setIsUpdateMobileNumber] = useState(false);

  return (
    <div className="form-group col-md-3">
      <label className="col-form-label col-form-label-sm" htmlFor="phoneNumber">Phone Number:</label>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Mobile Number"
          aria-label="Mobile Number"
          id="phoneNumber"
          disabled
          value={phoneNumber}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            onClick={() => { setIsUpdateMobileNumber(true); }}
            type="button"
          >
            Verify with otp
          </button>
        </div>
      </div>

      {/* hided for future upload image
    <div className="media align-items-center hidden-for-future">
    <div className="avatar avatar-image mx-2 mr-3">
      <img src="/no-profile-img.png" className="rounded-circle" alt="" />
    </div>
    <div className="mr-4">
      <p className="tx-12 mb-0 text-muted">
        Recommended Dimensions:
        <br />
        120x120 Max fil size: 5MB
      </p>
    </div>
    <div>
      <button className="btn btn-outline-secondary" type="button">Upload</button>
    </div>
  </div>
  <hr className="hidden-for-future" /> for future photo upload... */}

      {isUpdateMobileNumber && (
        <PhoneNumberVerification
          isSessionActive={false}
          onCloseModalPresent
          onCloseModal={() => {
            setIsUpdateMobileNumber(false);
          }}
          routePath="settings_path"
        />
      )}
    </div>
  );
};

MobileNumberDetails.propTypes = propTypes;
MobileNumberDetails.defaultProps = defaultProps;

export default MobileNumberDetails;
