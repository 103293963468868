import {
  INTENT_TYPES, SL_TRAIL_TYPES, STOPS_VALUE_TYPES
} from 'v2/common/constants/index';
import { defaultLinearTrail } from 'v2/modules/withRunForm/configs/index';

const AUTO_TRANSACTION_TSL_PIPE_NAME = 'AUTO_TRANSACTION_TSL_PIPE_NAME';

const autoTransactionTSLPipeConfig = (slType, slValue, trail) => ({
  type: 'transactionStopLoss',
  name: AUTO_TRANSACTION_TSL_PIPE_NAME,
  uiConfigs: { isAutoPipe: true },
  config: {
    stopLoss: {
      type: slType,
      value: slValue,
      trail
    }
  }
});

const autoTSLAdjustment = () => ({
  uiConfigs: {
    isAutoAdjustment: true,
    adjustmentMode: 'transactionTSL',
  },
  signal: [{
    conditionOperator: '',
    leftOperand: AUTO_TRANSACTION_TSL_PIPE_NAME,
    operator: 'equalTo',
    rightOperand: '1',
  }],
  intents: [{ type: INTENT_TYPES.ExitTransaction }]
});

export const addOverallTransactionTSL = (quantConfig) => {
  const {
    value: slValue,
    type: slType,
    uiConfigs = {}
  } = quantConfig?.bounds?.stopLoss || {};
  const isValidSlType = _.values(STOPS_VALUE_TYPES).includes(slType);
  if (!slValue || !isValidSlType) {
    return quantConfig;
  }

  const { trail = {} } = uiConfigs;
  const isValidTrail = _.values(SL_TRAIL_TYPES).includes(trail.type);
  if (_.isEmpty(trail) || _.isEqual(defaultLinearTrail) || !isValidTrail) {
    return quantConfig;
  }

  const newQuantConfig = {
    ...quantConfig,
    pipeConfigs: [
      ...quantConfig.pipeConfigs,
      autoTransactionTSLPipeConfig(slType, slValue, trail)
    ],
    cases: _.map(quantConfig.cases, (kase) => ({
      ...kase,
      adjustments: [...kase.adjustments, autoTSLAdjustment()]
    }))
  };

  return newQuantConfig;
};
