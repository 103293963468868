import SubmitButton from 'v2/modules/withRunForm/components/SubmitButton/index';
import RunFormHeader from 'v2/modules/withRunForm/components/RunFormHeader/index';
import withRunForm from 'v2/modules/withRunForm/index';
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import QuantBuilder from './QuantBuilder';
import RunCreditDetails from './components/RunCreditDetails/index';

const Main = () => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <div className="v2-form-container">
        <RunFormHeader />
        <QuantBuilder />
        <RunCreditDetails />
        <div className="my-4 d-flex justify-content-end align-items-center"><SubmitButton /></div>
      </div>
    </FormProvider>
  );
};

Main.propTypes = {};

export default withRunForm(Main);
