import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { pipeConfigPropTypes } from 'common/propTypes';
import Legacy from './Legacy/index';
import CustomScript from './CustomScript/index';
import Snippet from './Snippet/index';

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  trigger: PropTypes.func.isRequired,
  validator: PropTypes.shape({}).isRequired,
  pipeIndex: PropTypes.number,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  existingPipeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const defaultProps = {
  pipeIndex: undefined
};

const PipeInput = ({
  pipeConfig,
  onUpdatePipeConfig: propsOnUpdatePipeConfig,
  control,
  errors,
  trigger,
  validator,
  pipeIndex,
  setError,
  clearErrors,
  existingPipeNames
}) => {
  const { category, config, chartConfig } = pipeConfig;
  const inputChartKeys = useMemo(
    () => (_.includes(_.keys(chartConfig), 'volumeInterval') ? ['volumeInterval'] : []),
    [chartConfig]
  );
  const inputConfigKeys = useMemo(() => _.keys(config), [config]);
  const inputKeys = useMemo(() => [
    ...inputChartKeys, ...inputConfigKeys
  ], [inputChartKeys, inputConfigKeys]);

  const onUpdatePipeConfig = useCallback((keyOrPartialConfig, value) => {
    const updateConfig = (newConfig) => {
      propsOnUpdatePipeConfig({ config: { ...config, ...newConfig } });
    };

    if (_.isObject(keyOrPartialConfig)) {
      updateConfig(keyOrPartialConfig);
    } else if (_.includes(inputChartKeys, keyOrPartialConfig)) {
      propsOnUpdatePipeConfig({ chartConfig: { ...chartConfig, [keyOrPartialConfig]: value } });
    } else {
      updateConfig({ [keyOrPartialConfig]: value });
    }
  }, [propsOnUpdatePipeConfig, config, inputChartKeys, chartConfig]);

  switch (category) {
    case 'customScript':
      return (
        <CustomScript
          pipeConfig={pipeConfig}
          onUpdatePipeConfig={onUpdatePipeConfig}
          control={control}
          errors={errors}
          trigger={trigger}
          validator={validator}
        />
      );
    case 'snippet':
      return (
        <Snippet
          pipeConfig={pipeConfig}
          onUpdatePipeConfig={onUpdatePipeConfig}
          control={control}
          errors={errors}
          validator={validator}
        />
      );
    default:
      return (
        <Legacy
          pipeConfig={pipeConfig}
          onUpdatePipeConfig={onUpdatePipeConfig}
          control={control}
          errors={errors}
          inputKeys={inputKeys}
          trigger={trigger}
          setError={setError}
          clearErrors={clearErrors}
          existingPipeNames={existingPipeNames}
          pipeIndex={pipeIndex}
          validator={validator}
        />
      );
  }
};

PipeInput.propTypes = propTypes;
PipeInput.defaultProps = defaultProps;

export default PipeInput;
