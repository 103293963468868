import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';

const configuration = {
  type: 'currentCandle',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'currentCandle',
    name: 'current',
    config: {
      candleInterval: '3 minutes',
      valuePaths: [
        'item0.equity'
      ]
    }
  },
  searchTerms: [
    'currentCandle'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  buildOutputSchema: (pipe) => {
    const { name, type, config } = pipe;
    const { valuePaths = [] } = config;
    const [valuePath] = valuePaths;
    const isVolume = _.includes(valuePath, '.volume');
    let properties = [
      'close',
      'open',
      'high',
      'low',
      'openInterest',
      'volume'
    ];

    if (isVolume) {
      properties = [properties[0]]; // remove OHL
    }

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: schemaTypes.number,
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };
    });
  },
  isEnabled: true
};
export default configuration;
