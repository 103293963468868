import React from 'react';

const ContactSection = () => (
  <section className="section contact-section" id="contact">
    <h2 className="text-center mb-3 mb-md-5">
      Have Question? Get in Touch
    </h2>
    <div className="container">
      <div className="contact-info m-auto">
        <div className="contact-wrap text-center justify-content-center">
          <div>
            <div className="contact-label">Email us</div>
            <h5>
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=support@quantman.in"
                target="_blank"
                rel="noreferrer"
              >
                support@quantman.in
              </a>
            </h5>
          </div>
        </div>
      </div>

      {/* <div className="contact-info text-center">
        <div className="contact-label">Office Address</div>
        <h5>
          {' '}
          9/28 Ramalakshmi nagar, Lakshmipuram, Peelamedu,
          <br />
          Coimbatore-641004
        </h5>
      </div> */}

      {/* <div className="d-flex flex-wrap align-items-center justify-content-center gap-30">
        <div className="contact-info">
          <div className="contact-wrap">
            <div className="alert-icons mb-0 tx-20 icon-call alert-primary" />
            <div>
              <div className="contact-label">Call us</div>
              <h5>
                <a href="tel:+919751597579">+91 97515-97579</a>
              </h5>
            </div>
          </div>
        </div>

        <div className="contact-info">
          <div className="contact-wrap">
            <div className="alert-icons mb-0 tx-20 icon-email alert-primary" />
            <div>
              <div className="contact-label">Email us</div>
              <h5>
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=pandiarajan.m@quantman.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  support@quantman.in
                </a>
              </h5>
            </div>
          </div>
        </div>

        <div className="contact-info">
          <div className="contact-wrap">
            <div className="alert-icons mb-0 tx-20 icon-telegram alert-primary" />
            <div>
              <div className="contact-label">Telegram</div>
              <h5>
                <a
                  href="https://telegram.me/quantman_in"
                  target="_blank"
                  rel="noreferrer"
                >
                  @quantman_in
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </section>
);

export default ContactSection;
