import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';

const propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amount: PropTypes.number,
  onUpdateConfig: PropTypes.func.isRequired
};

const defaultProps = { quantity: 0, amount: 0 };

const EquityQuantityOrAmount = ({
  quantity: propsQuantity, amount: propsAmount, onUpdateConfig
}) => {
  const validator = { required: { value: true, message: 'value should be included' } };

  const [config, setConfig] = useState({ quantity: propsQuantity, amount: propsAmount });
  const { quantity, amount } = config;
  const [activeType, setActiveType] = useState(quantity ? 'no' : 'amount');
  const isQuantity = activeType === 'no';
  const [inputId] = useState(_.uniqueId('equityQuantityOrAmount-'));
  const { errors, register } = useFormContext();

  const inputBoxStyle = classNames('form-control form-control-sm', { 'is-invalid': !!errors[inputId] });

  const inputValue = isQuantity ? quantity : amount;
  const isInitialLoad = useRef(false);

  const onAllowOnlyNumber = (value) => {
    if (!value) return 0;

    return onRegexOnlyNumberAndFloat(value);
  };

  const onHandleChange = (value) => {
    setConfig({
      quantity: isQuantity ? onAllowOnlyNumber(value) : 0,
      amount: isQuantity ? 0 : onAllowOnlyNumber(value)
    });
  };

  useEffect(() => {
    if (isInitialLoad.current) {
      setConfig({ quantity: 0, amount: 0 });
    } else { isInitialLoad.current = true; }
  }, [activeType]);

  const renderToggleBtn = () => {
    return (
      <ToggleButtonGroup
        bsPrefix="btn-group btn-group-sm btn-group-toggle"
        type="radio"
        name="group-selector"
        value={activeType}
        onChange={(value) => { setActiveType(value); }}
      >

        <ToggleButton bsPrefix="btn btn-sm btn-outline-primary btn-lightness" value="no">
          <span>Qty</span>
        </ToggleButton>

        <ToggleButton bsPrefix="btn btn-sm btn-outline-primary btn-lightness" value="amount">
          <span>&#8377;</span>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  return (
    <div className="input-group overall-stops">
      {renderToggleBtn()}
      <input
        type="number"
        className={inputBoxStyle}
        ref={register(validator)}
        name={inputId}
        onBlur={() => onUpdateConfig(config)}
        value={inputValue ? inputValue.toString() : ''}
        onChange={(event) => { onHandleChange(event.target.value); }}
      />
    </div>
  );
};

EquityQuantityOrAmount.propTypes = propTypes;
EquityQuantityOrAmount.defaultProps = defaultProps;

export default EquityQuantityOrAmount;
