// vendor imports
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// project imports
import { setRepeatOrder } from 'modules/QuantBuilder/actions';

const propTypes = {
  repeatOrder: PropTypes.bool.isRequired,
  dispatchSetRepeatOrder: PropTypes.func.isRequired,
};
const defaultProps = {};
const I18N_SCOPE = { scope: 'runs.form' };

const RepeatOrder = (props) => {
  const { repeatOrder, dispatchSetRepeatOrder } = props;

  return (
    <div className="mt-3">
      <input
        id="repeat-order"
        type="checkbox"
        className="mr-3"
        onChange={() => dispatchSetRepeatOrder(!repeatOrder)}
        defaultChecked={repeatOrder}
      />
      <label className="form-check-label" htmlFor="repeat-order">
        {I18n.t('repeat_order_title', I18N_SCOPE)}
      </label>
    </div>
  );
};

const mapStateToProps = (state) => ({
  repeatOrder: _.get(state, 'quantConfig.repeatOrder')
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetRepeatOrder: (newRepeatOrder) => dispatch(setRepeatOrder(newRepeatOrder))
});

RepeatOrder.propTypes = propTypes;
RepeatOrder.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(RepeatOrder);
