import React from 'react';
import PropTypes from 'prop-types';

import HoverMessage from 'common/components/HoverMessage';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { ORDER_TYPES } from 'common/constants/index';

const propTypes = {
  onOrderTypeChange: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  orderTypes: PropTypes.shape({
    paper: PropTypes.shape({ value: PropTypes.string }),
    automatic: PropTypes.shape({ value: PropTypes.string }),
    oneClick: PropTypes.shape({ value: PropTypes.string })
  }),
  isShowOrderTypeLabel: PropTypes.bool,
  orderTypeParentClassName: PropTypes.string
};

const defaultProps = {
  orderTypes: ORDER_TYPES,
  isShowOrderTypeLabel: true,
  orderTypeParentClassName: 'd-flex gap-15 mt-2 flex-wrap'
};

const I18N_SCOPE = { scope: 'runs.form' };

const QuantOrderType = ({
  orderType: currentOrderType, onOrderTypeChange, orderTypes, isShowOrderTypeLabel, orderTypeParentClassName
}) => {
  const {
    paper: { value: paper },
    automatic: { value: automatic },
    oneClick: { value: oneclick },
  } = ORDER_TYPES;

  const { automaticOrder, oneClickOrder, paperOrder } = _.get(
    window,
    'userPermissions',
    {}
  );

  const getOrderTypePermissions = (orderType) => {
    if (orderType.value === paper && paperOrder.isDisabled) {
      return { isDisabled: true, disabledMessage: paperOrder.disabledReason };
    }
    if (orderType.value === oneclick && oneClickOrder.isDisabled) {
      return {
        isDisabled: true,
        disabledMessage: oneClickOrder.disabledReason,
      };
    }
    if (orderType.value === automatic && automaticOrder.isDisabled) {
      return {
        isDisabled: true,
        disabledMessage: automaticOrder.disabledReason,
      };
    }

    return { isDisabled: false, disabledMessage: '' };
  };

  const renderOrderType = (orderType, idx) => {
    const { isDisabled, disabledMessage } = getOrderTypePermissions(orderType);

    const options = (
      <div className="form-check form-check-sm" key={idx}>
        <input
          name="orderType"
          type="radio"
          value={orderType.value}
          checked={currentOrderType === orderType.value}
          onChange={(event) => onOrderTypeChange(event.target.value)}
          disabled={isDisabled}
          className="form-check-input track"
          data-track-category="Quant Order Type"
          data-track-action={orderType.displayName}
          data-track-label="Quant Form track"
          id={orderType.value}
        />
        <label className="form-check-label" htmlFor={orderType.value}>
          {orderType.displayName}
        </label>
      </div>
    );

    if (isDisabled) {
      return <HoverMessage title="" key={idx} message={disabledMessage}>{options}</HoverMessage>;
    }

    return options;
  };

  return (
    <div className="order-type">
      {isShowOrderTypeLabel
        && <label className="col-form-label-sm d-block pt-0">{I18n.t('order_type', I18N_SCOPE)}</label>}
      <div className={orderTypeParentClassName}>{_.map(orderTypes, renderOrderType)}</div>
    </div>
  );
};

export default withErrorBoundary(QuantOrderType);

QuantOrderType.propTypes = propTypes;
QuantOrderType.defaultProps = defaultProps;
