import { getUrlParams } from 'common/utils/urlUtils';

export const deepLinkFilter = () => {
  const urlParams = getUrlParams();
  const queryStringParams = _.keys(urlParams);

  if (_.includes(queryStringParams, ('selectedFilter'))) { return urlParams.selectedFilter || 'all'; }

  return 'all';
};
