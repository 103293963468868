import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { buildOutputSchemaFromJsonSchema } from './utilities/buildOutputSchemaFromJsonSchema';

const configuration = {
  type: 'customScript',
  defaults: {
    type: 'customScript',
    name: 'customScript',
    category: 'customScript',
    config: {
      script: '100L',
      outputJsonSchema: { type: 'number' }
    }
  },
  searchTerms: [
    'customScript'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  buildOutputSchema: (pipe) => {
    const { name, config: { outputJsonSchema } } = pipe;
    const schema = outputJsonSchema;
    return buildOutputSchemaFromJsonSchema(schema, name);
  },
  isEnabled: true
};
export default configuration;
