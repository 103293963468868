import React, { useState } from 'react';
import { V2JobsAction } from 'common/api/job';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const V2JobActions = () => {
  const [jobIds, setJobIds] = useState('');

  const onAction = async (action) => {
    const jobIdsArray = jobIds.split(',').map((jobId) => jobId.trim());

    try {
      const result = await V2JobsAction(jobIdsArray, action);
      reactToastify('Requested', TOASTIFY_TYPES.SUCCESS);
      console.log('Requested', result);
    } catch (error) {
      reactToastify('Unable to process your request', TOASTIFY_TYPES.ERROR);
      console.error('Unable to process your request:', error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-4">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Job Actions</h5>
              <form id="live-ticks-form">
                <div className="form-group">
                  <label htmlFor="jobIds">Job IDs</label>
                  <input
                    type="text"
                    className="form-control"
                    id="jobIds"
                    name="jobIds"
                    placeholder="1,2,3"
                    onChange={(event) => {
                      const { target: { value } } = event;
                      setJobIds(value);
                    }}
                    value={jobIds}
                  />
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={() => onAction('Stop')}
                  >
                    Stop (Dequeue)
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={() => onAction('Start')}
                  >
                    Start (Enqueue)
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onAction('Restart')}
                  >
                    Restart
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default V2JobActions;
