import { POSITION_VALUE_TYPES, INTENT_TYPES } from 'v2/common/constants/index';

const getUpdatedIntents = (intents, newQuantity) => {
  return _.map(intents, (intent) => {
    if (INTENT_TYPES.OpenBasket === intent?.type) return intent;
    if (
      !intent?.quantity
      || !_.isObject(intent.quantity)
      || intent.quantity.type === POSITION_VALUE_TYPES.Amount
    ) {
      return {
        ...intent,
        quantity: {
          type: intent?.quantity?.type ?? POSITION_VALUE_TYPES.Lot,
          value: _.isObject(intent.quantity) ? intent.quantity.value : newQuantity,
        },
      };
    }

    return {
      ...intent,
      quantity: {
        ...intent.quantity,
        type: intent?.quantity?.type ?? POSITION_VALUE_TYPES.Lot,
        value: newQuantity
      },
    };
  });
};

export const onResetQuantity = (cases, pipeConfigs, quantityValue) => {
  const updatedCases = _.map(cases, (caseConfig) => ({
    ...caseConfig,
    entry: {
      ...caseConfig.entry,
      intents:
        getUpdatedIntents(caseConfig?.entry?.intents, quantityValue) || [],
    },
    adjustments: _.map(caseConfig?.adjustments, (adjustment) => ({
      ...adjustment,
      intents: getUpdatedIntents(adjustment?.intents, quantityValue) || [],
    })),
  }));

  const updatedPipeConfigs = _.map(pipeConfigs, (pipeConfig) => {
    if (_.isEmpty(pipeConfig?.config?.legs)) return pipeConfig;

    return {
      ...pipeConfig,
      config: {
        ...pipeConfig.config,
        legs: getUpdatedIntents(pipeConfig?.config?.legs, quantityValue) || [],
      },
    };
  });

  return { updatedCases, updatedPipeConfigs };
};
