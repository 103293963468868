import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onTransactionsPerPageChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const TransactionsPerPage = ({
  onTransactionsPerPageChange
}) => {
  return (
    <div className="align-self-center">
      <div className="text-nowrap">
        <label className="mb-0">
          Show
          <select
            className="custom-select custom-select-sm mx-2 w-auto d-inline"
            onChange={(event) => onTransactionsPerPageChange(parseInt(event.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
          orders
        </label>
      </div>
    </div>
  );
};

TransactionsPerPage.propTypes = propTypes;
TransactionsPerPage.defaultProps = defaultProps;
export default TransactionsPerPage;
