import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import HoverMessage from 'common/components/HoverMessage';
import withErrorBoundary from 'common/components/withErrorBoundary/index';

const propTypes = {
  bufferPercentage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string.isRequired,
};
const defaultProps = {};

const BufferPercentage = ({
  bufferPercentage,
  onChange, labelClassName,
  buttonClassName,
}) => {
  const [percentage, setPercentage] = useState(bufferPercentage);

  useEffect(() => {
    setPercentage(bufferPercentage);
  }, [bufferPercentage]);

  const oneIndentation = ' ';

  const content = (
    <div>
      Assume, Signal price is Rs 100 and Buffer is 5%.
      <br />
      <br />
      If
      {oneIndentation}
      <b>buy</b>
      {oneIndentation}
      order, we place limit order with Rs. 105
      <br />
      If
      {oneIndentation}
      <b>sell</b>
      {oneIndentation}
      order, we place limit order with Rs. 95
    </div>
  );

  return (
    <>
      <div className={labelClassName}>
        <label className="mb-1 mb-lg-0 col-form-label col-form-label-sm">
          Limit Order Buffer Percentage
          {oneIndentation}
          <HoverMessage
            title=""
            message={content}
          >
            <span className="material-icons-outlined text-muted tx-16 align-middle">
              help_outline
            </span>
          </HoverMessage>
        </label>
      </div>
      <div className={buttonClassName}>
        <div className="input-group overall-stops">
          <div
            role="group"
            className="btn-group btn-group-sm
          btn-group-toggle btn-group btn-group-toggle-toggle"
          >
            <label
              className="btn btn-sm btn-outline-primary btn-lightness
              active"
            >
              <input
                name="group-selector"
                type="radio"
                autoComplete="off"
                value="percent"
                checked=""
                onChange={() => { }}
              />
              %
            </label>
          </div>
          <input
            type="text"
            className="form-control form-control-sm"
            name="limitOrderBufferPercantage"
            onBlur={(event) => onChange(event.target.value === ''
              ? 0 : onRegexOnlyNumberAndFloat(event.target.value, false))}
            value={(percentage === undefined || percentage === null) ? '' : percentage.toString()}
            onChange={(event) => setPercentage(onRegexOnlyNumberAndFloat(event.target.value, true))}
          />
        </div>
      </div>
    </>
  );
};

BufferPercentage.propTypes = propTypes;
BufferPercentage.defaultProps = defaultProps;

export default withErrorBoundary(BufferPercentage);
