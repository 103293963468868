import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  segment: PropTypes.string.isRequired,
  onChangeSegment: PropTypes.func.isRequired,
  segmentOptionConfigs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
const defaultProps = {};

const SegmentSelector = ({ segment, onChangeSegment, segmentOptionConfigs }) => {
  const renderSegmentOption = useCallback(({ label, value }) => {
    return <option value={value} key={value}>{_.startCase(label)}</option>;
  }, []);

  return (
    <div className="segment-selector">
      <select value={segment} onChange={(event) => onChangeSegment(event.target.value)}>
        {_.map(segmentOptionConfigs, renderSegmentOption)}
      </select>
    </div>
  );
};

SegmentSelector.propTypes = propTypes;
SegmentSelector.defaultProps = defaultProps;

export default memo(SegmentSelector);
