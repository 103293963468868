import React, { useEffect, useState } from 'react';
// import MoneyDisplay from 'common/components/MoneyDisplay';
import { strategyPropTypes } from 'common/propTypes';
import OrderStore from 'ui/run/RunShow/OrderStore/index';
import RunSummary from 'modules/RunSummary/index';
import TransactionsAnalytics from 'modules/TransactionsAnalytics/index';
import { RUN_TYPES } from 'common/constants/index';
import LoadingSpinner from 'common/components/LoadingSpinner';
import classNames from 'classnames';
import { onCreateStrategyAccess, onUpdateStrategyAccess } from 'common/api/strategyAccess';
// import ProfitableValues from '../StrategyCard/ProfitableValues';

const propTypes = { strategy: strategyPropTypes.isRequired };

const defaultProps = {};

const StrategyBacktestRunDetails = ({ strategy }) => {
  const strategyAccess = _.get(strategy, 'strategy_access', {});
  const [shouldShowBacktest, setShouldShowBacktest] = useState(
    _.get(strategyAccess, 'is_show_backtest', false)
  );
  const [isLoading, setIsLoading] = useState(false);

  const onCreateOrUpdateStrategyAccess = _.isEmpty(strategyAccess) ? onCreateStrategyAccess
    : onUpdateStrategyAccess;

  const { backtestJobs, id } = strategy;
  const strategyAccessId = _.get(strategyAccess, 'id', '');
  const oneIndentation = ' ';
  const backtestJobIds = _.map(backtestJobs, 'id').sort();
  const backtestRunId = _.get(backtestJobs, '[0].run_id');

  // const backtestProfit = _.get(strategy, 'backtest_run.summary.profit', 0);

  // const renderBacktestProfit = () => {
  //   if (backtestProfit === 0) return null;

  //   return (
  //     <div className="backtest-pl">
  //       <div className="d-inline-flex rounded align-items-center gap-10">
  //         <div className="alert-icons mb-0 icon-currency bg-info text-white tx-25" />
  //         <div>
  //           <div className="value-name"> Backtest Profit & Loss </div>
  //           <div className="tx-18 font-weight-bold text-nowrap">
  //             <MoneyDisplay shouldColor shouldAvoidDecimals>{backtestProfit}</MoneyDisplay>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  useEffect(() => { if (isLoading) { setTimeout(() => { setIsLoading(false); }, 5000); } }, [isLoading]);

  const onHandleClick = () => {
    onCreateOrUpdateStrategyAccess({
      id: strategyAccessId,
      strategyParams: { strategy_id: id, is_show_backtest: true }
    }).then(() => {
      setIsLoading(true);
      setShouldShowBacktest(true);
    }).catch(() => { });
  };

  const renderViewBacktestButton = () => {
    return (
      <div className="status-wrapper">
        <div className="info-msg">
          <img src="../runbacktest.png" className="small" alt="Run Backtest" />
          <div>
            <p>Backtest this strategy on your own</p>
            <button
              className="common-icon-btn btn btn-sm btn-primary m-auto btn-sm"
              type="button"
              onClick={() => onHandleClick()}
              data-toggle="tooltip"
            >
              <i className="icons-history mr-1" />
              {oneIndentation}
              Run Backtest
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderBacktest = () => {
    if (!shouldShowBacktest) return renderViewBacktestButton();

    const strategyClassName = classNames('strategy-order-store', { 'mt-10': isLoading });

    return (
      <LoadingSpinner isLoading={isLoading}>
        <div className={strategyClassName}>
          {!isLoading && (
            <OrderStore
              runIds={[backtestRunId]}
              jobIds={[backtestJobIds]}
              runType={RUN_TYPES.historic}
            >
              <div>
                <RunSummary />
                <TransactionsAnalytics tradingType="" />
              </div>
            </OrderStore>
          )}
        </div>
      </LoadingSpinner>
    );
  };

  return (
    <div>
      {/* <div className="backtest-info-warpper">
        {renderBacktestProfit()}
        <div className="d-flex flex-wrap gap-20">
          <ProfitableValues strategy={strategy} isShowDates />
        </div>
      </div> */}
      {renderBacktest()}
      {(shouldShowBacktest && !isLoading) && (
        <div className="alert alert-info mb-2">
          <strong>Information : </strong>
          {oneIndentation}
          The past backtesting performance of the strategy is not necessarily indicative of future
          performance of the strategy and trading is subject to market risks. Backtest is based
          on minute by minute data(open / high / low / close), whereas in live deployments are based on
          second by second data. So there will be minor variation. Backtest results does not
          include
          {oneIndentation}
          <b>brokerage charges/slippages</b>
          {oneIndentation}
          that occur in actual order execution. It is just an
          indication about the performance of the strategy.
        </div>
      )}

    </div>
  );
};

StrategyBacktestRunDetails.propTypes = propTypes;
StrategyBacktestRunDetails.defaultProps = defaultProps;

export default StrategyBacktestRunDetails;
