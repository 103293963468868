import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withErrorBoundary from './withErrorBoundary/index';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  shouldColor: PropTypes.bool,
  color: PropTypes.string,
  shouldAvoidDecimals: PropTypes.bool,
  isAttachCurrency: PropTypes.bool,
  isAddPercentage: PropTypes.bool
};
const defaultProps = {
  children: null,
  shouldColor: false,
  color: '',
  shouldAvoidDecimals: false,
  isAttachCurrency: true,
  isAddPercentage: false
};

const getFormattedMoney = (money, shouldAvoidDecimals) => {
  if (!shouldAvoidDecimals) return money;

  return money.toString().replace(/\D00(?=\D*$)/, '');
};

const Money = ({
  children, shouldColor, color, shouldAvoidDecimals, isAttachCurrency, isAddPercentage
}) => {
  const intlFormatter = new Intl.NumberFormat('en-IN');

  const money = getFormattedMoney(children, shouldAvoidDecimals);
  const moneyStyle = classNames('default', {
    'text-success': shouldColor && children > 0,
    'text-danger d-inline-flex': (shouldColor && (color === 'red' || children <= 0)),
  });

  return (
    <span className={moneyStyle}>
      {children < 0 && '-'}
      {isAttachCurrency
      && <span className="material-icons-outlined font-size-inherit align-bottom">currency_rupee</span>}
      {intlFormatter.format(Math.abs(Number(money)))}
      {isAddPercentage && '%'}
    </span>
  );
};

export default withErrorBoundary(Money, 'Errored', false);

Money.propTypes = propTypes;
Money.defaultProps = defaultProps;
