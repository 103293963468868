import React from 'react';
import { orderTransactionPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';
import { ORDER_TYPES } from 'common/constants/index';
import TransactionType from 'common/components/TransactionType';
import { STATUS_STYLE_CONFIG } from './config';
import { formattedDateAndTime, renderOrderStatus } from './helper';

const propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape(orderTransactionPropTypes.isRequired)).isRequired,
  orderType: PropTypes.string
};
const defaultProps = { orderType: '' };

const statusTextUpdate = (status, statusText, failureMessage) => {
  const isValidStatusToShowMessage = status === 'created' || status === 'failed';
  if ((isValidStatusToShowMessage) && failureMessage) return `${statusText}(${failureMessage})`;

  return statusText;
};

const renderPriceAndTime = (price, time) => {
  const oneIndentation = ' ';
  return (
    <div>
      {price || '( - )'}
      {oneIndentation}
      /
      {oneIndentation}
      {oneIndentation}
      <span className="time">{time}</span>
    </div>
  );
};

const renderOrderRow = (order, isPaperTrade) => {
  const {
    signal_time: signalTime, signal_price: signalPrice, traded_time: tradedTime, traded_price: tradedPrice,
    status, failure_message: failureMessage, is_waiting: isWaiting, modified_order_type: modifiedOrderType,
    placed_time: placedTime
  } = order;
  const statusConfig = _.get(STATUS_STYLE_CONFIG, status);
  const statusText = _.get(statusConfig, 'statusText', status);
  const statusTextClassName = _.get(statusConfig, 'statusTextClassName', 'text-muted');
  const orderType = `${renderOrderStatus(order)} / ${_.upperFirst(modifiedOrderType || order.order_type)}`;

  const alteredStatusText = isWaiting && status === 'confirmed' ? 'waiting' : statusText;
  // need to show waiting status when confirmed orders waiting for options entry buy executed status

  return (
    <tr key={order.id} className="table-row">
      <td data-column="Trading Symbol" className="table-row-td">{order.trading_symbol}</td>
      <td data-column="Type" className="table-row-td">
        <TransactionType type={order.transaction_type} />
      </td>
      <td data-column="Signal / Order Type" className="table-row-td">{orderType}</td>
      <td data-column="Qty" className="table-row-td">{order.quantity}</td>
      <td data-column="Signal Price / Time" className="table-row-td">
        {renderPriceAndTime(signalPrice, formattedDateAndTime(signalTime))}
      </td>
      {!isPaperTrade && (
      <>
        <td data-column="Placed Time" className="table-row-td">
          <span className="time">{formattedDateAndTime(placedTime)}</span>
        </td>
        <td data-column="Traded Price / Time" className="table-row-td">
          {renderPriceAndTime(tradedPrice, formattedDateAndTime(tradedTime))}
        </td>
        <td data-column="Status" className="table-row-td">
          <span className={statusTextClassName}>
            {statusTextUpdate(status, alteredStatusText, failureMessage)}
          </span>
        </td>
      </>
      )}
    </tr>
  );
};

const OrderDetails = ({ orders, orderType }) => {
  const { paper: { value: paper } } = ORDER_TYPES;
  const isPaperTrade = orderType === paper;

  return (
    <div className="table-responsive live-order-details">
      <table className="table table-striped table-th-border-none mb-0">
        <thead className="table-thead">
          <tr>
            <th scope="col">Trading Symbol</th>
            <th scope="col">Type</th>
            <th scope="col">Signal / Order Type</th>
            <th scope="col">Qty</th>
            <th scope="col">Signal Price / Time</th>
            {!isPaperTrade && (
            <>
              <th scope="col">Placed Time</th>
              <th scope="col">Traded Price / Time</th>
              <th scope="col">Status</th>
            </>
            )}
          </tr>
        </thead>
        <tbody className="table-body">
          {orders.length
            ? _.map(orders, (order) => renderOrderRow(order, isPaperTrade))
            : <tr><td colSpan="10" className="text-center">No Orders...</td></tr>}
        </tbody>
      </table>
    </div>
  );
};

OrderDetails.propTypes = propTypes;
OrderDetails.defaultProps = defaultProps;
export default OrderDetails;
