import React, { useContext, useEffect, useState } from 'react';
import { getTradeType, getTradeTypeData } from 'ui/run/RunShow/OrderStore/analytics/tradeTypeAnalytics';

import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import BarChart from './chart/BarChart';

const propTypes = {};
const defaultProps = {};

const TradeTypeAnalytics = () => {
  const { completedTransactions } = useContext(OrderContext);
  const [analyticsData, setAnalyticsData] = useState([]);

  useEffect(() => {
    const sanitizedAnalyticsData = _.chain(completedTransactions).map(({ pairedOrders }) => {
      return _.map(pairedOrders, ({ orders, orderProfit }) => {
        const { symbolCategory, tradeType } = getTradeType(orders);

        return { overallProfit: orderProfit, symbolCategory, tradeType };
      });
    }).flattenDeep().value();

    setAnalyticsData(sanitizedAnalyticsData);
  }, [completedTransactions]);

  const tradeTypeAnalyticsData = getTradeTypeData(analyticsData);

  const renderChart = (tradeTypeData, tradeType) => (
    <BarChart key={tradeType} analyticsData={tradeTypeData} title={tradeType} />
  );

  return (
    <div className="col-md-6">
      <h6>Trade Type</h6>
      {_.map(tradeTypeAnalyticsData, renderChart)}
    </div>
  );
};

TradeTypeAnalytics.propTypes = propTypes;
TradeTypeAnalytics.defaultProps = defaultProps;

export default TradeTypeAnalytics;
