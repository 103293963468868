const configuration = {
  type: 'superTrend',
  defaults: {
    type: 'superTrend',
    name: 'superTrend',
    config: {
      noOfCandles: 10,
      candleInterval: '1 minutes',
      multiplier: 3,
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'superTrend'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    return [
      {
        indicatorType: type,
        name,
        type: 'number',
        category: 'indicator',
        label: name
      }
    ];
  },
  isEnabled: true
};
export default configuration;
