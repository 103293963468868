import React, { useEffect, useState } from 'react';
import { strategyPropTypes } from 'common/propTypes';
import {
  fetchStrategyTransactions, fetchTransactionUsers, fetchTransactionLegsForUser
} from 'common/api/strategyAnalytics';
import moment from 'moment';
import ReactDataTable from 'common/components/ReactDataTable';
import BreadCrumb from './BreadCrumb';
import {
  strategyTransactionHeaders,
  particularTransactionDetailsForAllUsersHeaders,
  individualUserTransactionLegsHeaders
} from './TableHeaderConfigs';

const propTypes = { strategy: strategyPropTypes.isRequired };

const defaultProps = {};

const renderTable = (data, columns) => {
  return <ReactDataTable data={data} columns={columns} />;
};

const StrategyAnalytics = ({ strategy }) => {
  const [strategyTransactions, setStrategyTransactions] = useState({
    data: [],
    headers: [],
  });

  const [
    particularTransactionDetailsForAllUsers,
    setParticularTransactionDetailsForAllUsers,
  ] = useState({
    data: [],
    headers: [],
  });
  const [individualUserTransactionLegs, setIndividualUserTransactionLegs] = useState({
    data: [],
    headers: [],
  });

  const [fetchData, setFetchData] = useState(false);

  const isParticularTransactionDetailsForAllUsers = !_.isEmpty(
    particularTransactionDetailsForAllUsers.data
  );

  const isIndividualUserTransactionLegs = !_.isEmpty(
    individualUserTransactionLegs.data
  );

  const handleTransactionsClick = () => {
    setParticularTransactionDetailsForAllUsers({ data: [], headers: [] });
    setIndividualUserTransactionLegs({ data: [], headers: [] });
  };

  const handleUsersClick = () => {
    setIndividualUserTransactionLegs({ data: [], headers: [] });
  };

  const handleLegsClick = () => {};

  const formatEmptyvalues = (value, filler = '-') => {
    return (!_.isEmpty(value) || _.isNumber(value)) ? value : filler;
  };

  const formatdate = (date) => {
    if (_.isEmpty(date)) {
      return 'N/A';
    }
    return formatEmptyvalues(moment(date).format('DD/MM/YY HH:mm:ss'), 'N/A');
  };

  const formatPrice = (price) => {
    if (!_.isNumber(price)) {
      return '-';
    }

    return parseFloat(price).toFixed(2);
  };

  const formatSec = (seconds) => {
    if (!_.isNumber(seconds)) {
      return '-';
    }

    return `${parseFloat(seconds).toFixed(1)} s`;
  };

  const statusIcon = {
    completed: { className: 'material-icons-outlined text-success', icon: 'check_circle' },
    incompleted: { className: 'material-icons-outlined text-warning', icon: 'do_disturb_on' },
    failed: { className: 'material-icons-outlined text-danger', icon: 'dangerous' },
  };

  const formatOrderStatusByIcon = (status) => {
    const { className, icon } = _.get(statusIcon, status);
    if (className) {
      return <span className={className} title={status}>{icon}</span>;
    }

    return status;
  };

  const renderUserTransactionLeg = (args) => {
    (fetchTransactionLegsForUser({ ...args, strategyId: strategy.id })).then((res) => {
      const alteredData = _.map(res.data, (row) => {
        const isPaperRun = (row.run_order_type === 'paper');

        return {
          transaction_id: row.transaction_id,
          user_id: row.user_id,
          broker: formatEmptyvalues(row.broker),
          run_order_type: formatEmptyvalues(row.run_order_type),
          trading_symbol: formatEmptyvalues(row.trading_symbol),
          entry_quantity: formatEmptyvalues(row.entry_quantity),
          entry_signal_price: formatPrice(row.entry_signal_price),
          entry_traded_price: formatPrice(row.entry_traded_price),
          entry_signal_time: formatdate(row.entry_signal_time),
          entry_placed_time: !isPaperRun ? formatdate(row.entry_placed_time) : 'N/A',
          entry_traded_time: !isPaperRun ? formatdate(row.entry_traded_time) : 'N/A',
          sl_quantity: formatEmptyvalues(row.stop_loss_quantity),
          sl_signal_price: formatPrice(row.sl_signal_price),
          sl_traded_price: formatPrice(row.sl_traded_price),
          sl_signal_time: formatdate(row.sl_signal_time),
          sl_placed_time: !isPaperRun ? formatdate(row.sl_placed_time) : 'N/A',
          sl_traded_time: !isPaperRun ? formatdate(row.sl_traded_time) : 'N/A',
          exit_quantity: formatEmptyvalues(row.exit_quantity),
          exit_signal_price: formatPrice(row.exit_signal_price),
          exit_traded_price: formatPrice(row.exit_traded_price),
          exit_signal_time: formatdate(row.exit_signal_time),
          exit_placed_time: !isPaperRun ? formatdate(row.exit_placed_time) : 'N/A',
          exit_traded_time: !isPaperRun ? formatdate(row.exit_traded_time) : 'N/A',
          signal_profit: (
            <span className={`text-${row.signal_profit >= 0 ? 'success' : 'danger'}`}>
              {formatPrice(row.signal_profit)}
            </span>
          ),
          traded_profit: (
            !isPaperRun ? (
              <span className={`text-${row.traded_profit >= 0 ? 'success' : 'danger'}`}>
                {formatPrice(row.traded_profit)}
              </span>
            )
              : '-')
        };
      });

      setFetchData(false);
      setIndividualUserTransactionLegs({
        data: alteredData,
        headers: individualUserTransactionLegsHeaders()
      });
    });
  };

  const getTransactionUser = (args) => {
    (fetchTransactionUsers({ ...args, strategyId: strategy.id })).then((res) => {
      const alteredData = _.map(res.data, (row) => {
        const minQuantmanBrokerDelay = _.min([
          row.min_entry_signal_placed_diff_in_secs,
          row.min_sl_signal_placed_diff_in_secs,
          row.min_exit_signal_placed_diff_in_secs
        ]);
        const maxQuantmanBrokerDelay = _.max([
          row.max_entry_signal_placed_diff_in_secs,
          row.max_sl_signal_placed_diff_in_secs,
          row.max_exit_signal_placed_diff_in_secs
        ]);
        const avgQuantmanBrokerDelay = _.max([
          row.avg_entry_signal_placed_diff_in_secs,
          row.avg_sl_signal_placed_diff_in_secs,
          row.avg_exit_signal_placed_diff_in_secs
        ]);

        const minBrokerMarketDelay = _.min([
          row.min_entry_placed_traded_diff_in_secs,
          row.min_sl_placed_traded_diff_in_secs,
          row.min_exit_placed_traded_diff_in_secs
        ]);
        const maxBrokerMarketDelay = _.max([
          row.max_entry_placed_traded_diff_in_secs,
          row.max_sl_placed_traded_diff_in_secs,
          row.max_exit_placed_traded_diff_in_secs
        ]);
        const avgBrokerMarketDelay = _.max([
          row.avg_entry_placed_traded_diff_in_secs,
          row.avg_sl_placed_traded_diff_in_secs,
          row.avg_exit_placed_traded_diff_in_secs
        ]);
        const maxSlippage = _.max([
          row.max_entry_slippage,
          row.max_sl_slippage,
          row.max_exit_slippage
        ]);

        return {
          transaction_id: row.transaction_id,
          user_id: row.user_id,
          broker: row.broker,
          symbol: formatEmptyvalues(row.symbol),
          run_order_type: formatEmptyvalues(row.run_order_type),

          transaction_start_time: formatdate(row.first_trade_time),
          transaction_end_time: formatdate(row.last_trade_time),

          entry_status: formatOrderStatusByIcon(formatEmptyvalues(row.entry_status)),
          sl_status: formatOrderStatusByIcon(formatEmptyvalues(row.sl_status)),
          exit_status: formatOrderStatusByIcon(formatEmptyvalues(row.exit_status)),

          signal_profit: formatPrice(row.signal_profit),
          traded_profit: formatPrice(row.traded_profit),
          slippage: maxSlippage ? Math.abs(formatPrice(maxSlippage)) : '-',

          min_quantman_broker_delay: formatSec(minQuantmanBrokerDelay),
          max_quantman_broker_delay: formatSec(maxQuantmanBrokerDelay),
          avg_quantman_broker_delay: formatSec(avgQuantmanBrokerDelay),

          min_broker_market_delay: formatSec(minBrokerMarketDelay),
          max_broker_market_delay: formatSec(maxBrokerMarketDelay),
          avg_broker_market_delay: formatSec(avgBrokerMarketDelay),

          show_more: (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                renderUserTransactionLeg({ transactionId: row.transaction_id, userId: row.user_id });
              }}
            >
              View Details
            </button>
          )
        };
      });

      setFetchData(false);
      setParticularTransactionDetailsForAllUsers({
        data: alteredData,
        headers: particularTransactionDetailsForAllUsersHeaders()
      });
    });
  };

  const getStrategyTransactions = () => {
    (fetchStrategyTransactions({ strategyId: strategy.id })).then((res) => {
      const alteredData = _.map(res.data, (row) => {
        return {
          transaction_id: formatEmptyvalues(row.transaction_id),
          instrument: formatEmptyvalues(row.instrument),
          transaction_start_time: formatdate(row.transaction_first_trade_time),
          transaction_end_time: formatdate(row.transaction_last_trade_time),

          paper_runs_count: formatEmptyvalues(row.paper_runs_count),
          automatic_runs_count: formatEmptyvalues(row.automatic_runs_count),
          users_count: formatEmptyvalues(row.transaction_users_count),
          // eslint-disable-next-line max-len
          incomplete_automatic_transaction_count: formatEmptyvalues(row.automatic_transaction_users_incompleted),
          completed_automatic_transaction_count: formatEmptyvalues(row.automatic_transaction_users_completed),
          failed_automatic_transaction_count: formatEmptyvalues(row.automatic_transaction_users_failed),

          min_actual_profit: formatPrice(row.min_traded_profit),
          max_actual_profit: formatPrice(row.max_traded_profit),
          avg_actual_profit: formatPrice(row.avg_traded_profit),

          min_quantman_broker_delay: formatSec(row.min_signal_placed_diff_in_secs),
          max_quantman_broker_delay: formatSec(row.max_signal_placed_diff_in_secs),
          avg_quantman_broker_delay: formatSec(row.max_avg_signal_placed_diff_in_secs),

          min_broker_market_delay: formatSec(row.min_placed_traded_diff_in_secs),
          max_broker_market_delay: formatSec(row.max_placed_traded_diff_in_secs),
          avg_broker_market_delay: formatSec(row.max_avg_placed_traded_diff_in_secs),

          show_more: (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                getTransactionUser({ transactionId: row.transaction_id });
              }}
            >
              View Details
            </button>
          ),
        };
      });

      setFetchData(false);
      setStrategyTransactions({
        data: alteredData,
        headers: strategyTransactionHeaders(),
      });
    });
  };

  useEffect(() => {
    getStrategyTransactions();
  }, []);

  const renderActiveTable = () => {
    setFetchData(true);
    if (isIndividualUserTransactionLegs) {
      const data = individualUserTransactionLegs.data[0];
      return renderUserTransactionLeg({ transactionId: data.transaction_id, userId: data.user_id });
    }

    if (isParticularTransactionDetailsForAllUsers && !isIndividualUserTransactionLegs) {
      return getTransactionUser(
        { transactionId: particularTransactionDetailsForAllUsers.data[0].transaction_id }
      );
    }

    if (!isParticularTransactionDetailsForAllUsers && !isIndividualUserTransactionLegs) {
      return getStrategyTransactions();
    }

    return null;
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-primary btn-sm d-flex"
        onClick={() => {
          renderActiveTable();
        }}
      >
        <span className="material-icons-outlined sm">refresh</span>
        <span>
          {fetchData ? 'Refreshing...' : 'Refresh'}
        </span>
      </button>
      <BreadCrumb
        particularTransactionDetailsForAllUsers={particularTransactionDetailsForAllUsers}
        individualUserTransactionLegs={individualUserTransactionLegs}
        onTransactionsClick={handleTransactionsClick}
        onUsersClick={handleUsersClick}
        onLegsClick={handleLegsClick}
      />
      {isIndividualUserTransactionLegs && (
        renderTable(individualUserTransactionLegs?.data, individualUserTransactionLegs?.headers)
      )}
      {(isParticularTransactionDetailsForAllUsers && !isIndividualUserTransactionLegs) && (
        renderTable(
          particularTransactionDetailsForAllUsers?.data,
          particularTransactionDetailsForAllUsers?.headers
        )
      )}
      {!isParticularTransactionDetailsForAllUsers && !isIndividualUserTransactionLegs && (
        renderTable(strategyTransactions?.data, strategyTransactions?.headers)
      )}
    </>
  );
};

StrategyAnalytics.propTypes = propTypes;
StrategyAnalytics.defaultProps = defaultProps;

export default StrategyAnalytics;
