import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  validator: PropTypes.shape({}).isRequired
};

const FloatFormField = ({
  name: propsName, value, control, errors, onUpdatePipeConfig, validator
}) => {
  const uniqueId = useMemo(() => _.uniqueId(`pipe_${propsName}_`), [propsName]);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');
  const [localValue, setLocalValue] = useState(value === undefined ? '' : (value?.toString() || ''));

  const rulesValidator = useMemo(() => {
    if (!_.isEmpty(validator)) return validator;

    return {
      validate: () => {
        if (Number(value) === 0) {
          return 'Please enter the valid value';
        }

        return null;
      }
    };
  }, [value, validator]);

  const onBlur = (event) => {
    onUpdatePipeConfig(propsName, (onRegexOnlyNumberAndFloat(event.target.value) || 0));
  };

  return (
    <ErrorMesssage message={errorMessage}>
      <Controller
        render={({ onChange, ref, name }) => (
          <Form.Control
            type="text"
            id={name}
            size="sm"
            name={uniqueId}
            ref={ref}
            value={localValue === undefined ? '' : (localValue?.toString() || '')}
            onBlur={(event) => onBlur(event)}
            onChange={(event) => {
              setLocalValue(onRegexOnlyNumberAndFloat(event.target.value, true));
              onChange(event.target.value);
            }}
          />
        )}
        control={control}
        defaultValue={value}
        name={uniqueId}
        rules={rulesValidator}
      />
    </ErrorMesssage>
  );
};

FloatFormField.propTypes = propTypes;

export default FloatFormField;
