import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import TimeSelector from 'common/components/TimeSelector';
import { rightOperandPropTypes } from 'common/propTypes';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { getTimeValidatorsV2 } from 'common/utils/validators';

const propTypes = {
  rightOperand: rightOperandPropTypes.isRequired,
  onChangeRightOperand: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};

const defaultProps = { };
const Time = (props) => {
  const {
    onChangeRightOperand,
    rightOperand,
    control, errors
  } = props;
  const [timePickerName] = useState(_.uniqueId('timePicker-'));

  const onTimeChange = (newTime, onChange) => {
    onChangeRightOperand(newTime);
    onChange(newTime);
  };

  return (
    <>
      <Controller
        render={({ onChange }) => (
          <TimeSelector
            onTimeChange={(newTime) => onTimeChange(newTime, onChange)}
            selectedTime={rightOperand}
            format="HH:mm:ss"
          />
        )}
        control={control}
        defaultValue={rightOperand}
        name={timePickerName}
        rules={getTimeValidatorsV2()}
      />
      <ErrorMesssage message={errors[timePickerName]?.message || ''} />
    </>
  );
};

export default memo(Time);

Time.propTypes = propTypes;
Time.defaultProps = defaultProps;
