import PropTypes from 'prop-types';
import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from 'react-redux';

import { instrumentGroupsPropTypes, validatorProptypes } from 'common/propTypes';
import { setInstrumentGroups, setMaxInstrumentsInGroup } from 'modules/QuantBuilder/actions';
import { getValidationError } from 'common/utils/validators';
import { QUANT_CONFIG_FIELDS, groupingOptions } from 'modules/QuantBuilder/config';
import InstrumentGroupBuilder from 'common/components/InstrumentGroupBuilder';
import ValidationError from 'common/components/ValidationError';

const I18N_SCOPE = { scope: 'runs.form' };

const propTypes = {
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  dispatchSetInstrumentGroups: PropTypes.func.isRequired,
  dispatchSetMaxInstrumentsInGroup: PropTypes.func.isRequired,
  validatorErrors: validatorProptypes.isRequired,
  maxInstrumentsInGroup: PropTypes.number.isRequired,
  segment: PropTypes.string.isRequired,
  onChangeSegment: PropTypes.func.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string).isRequired
};
const defaultProps = {};

const InstrumentGroupInput = (props) => {
  const {
    instrumentGroups,
    dispatchSetInstrumentGroups,
    dispatchSetMaxInstrumentsInGroup,
    validatorErrors,
    maxInstrumentsInGroup,
    segment,
    onChangeSegment,
    segments
  } = props;
  const instrumentGroupsErrors = getValidationError(
    validatorErrors, QUANT_CONFIG_FIELDS.instrumentGroups
  );

  const onAddInstrumentGroup = (newInstrumentGroup) => {
    dispatchSetInstrumentGroups([...instrumentGroups, newInstrumentGroup]);
  };

  const onRemoveInstrumentGroup = (indexToRemove) => {
    const newInstrumentGroups = _.filter(instrumentGroups,
      (_instrumentGroup, index) => index !== indexToRemove);
    dispatchSetInstrumentGroups(newInstrumentGroups);
  };

  const onMaxInstrumentsInGroupChange = (newGroupingOption) => {
    dispatchSetMaxInstrumentsInGroup(newGroupingOption);
    dispatchSetInstrumentGroups([]);
  };

  const toggleBtns = _.map(groupingOptions, ({ type, noOfInstruments }) => (
    <ToggleButton
      data-track-category="Group Type"
      data-track-action={type}
      data-track-label="Quant Instrument Group Type"
      variant="outline-primary"
      className="btn-lightness track"
      value={noOfInstruments}
      key={type}
      size="sm"
    >
      {I18n.t(type, I18N_SCOPE)}
    </ToggleButton>
  ));

  return (
    <>
      <div className="instrument-group-type-container row align-items-center mb-3">
        <div className="col-md-2">
          <label className="col-form-label col-form-label-sm mb-1 mb-md-0">Group Type</label>
        </div>

        <div className="col-md-10">
          <ToggleButtonGroup
            type="radio"
            size="sm"
            name="options"
            value={maxInstrumentsInGroup}
            onChange={onMaxInstrumentsInGroupChange}
          >
            {toggleBtns}
          </ToggleButtonGroup>
        </div>
      </div>

      <div className="instrument-group-builder row align-items-center">
        <InstrumentGroupBuilder
          isInValid={!_.isEmpty(instrumentGroupsErrors)}
          instrumentGroups={instrumentGroups}
          maxInstrumentsInGroup={maxInstrumentsInGroup}
          onAddInstrumentGroup={(newInstrumentGroup) => onAddInstrumentGroup(newInstrumentGroup)}
          onRemoveInstrumentGroup={(indexToRemove) => onRemoveInstrumentGroup(indexToRemove)}
          segment={segment}
          onChangeSegment={onChangeSegment}
          segments={segments}
        />
        <div className="col-md-10 offset-md-2">
          <ValidationError validatorErrors={instrumentGroupsErrors} />
        </div>
      </div>
    </>
  );
};

InstrumentGroupInput.propTypes = propTypes;
InstrumentGroupInput.defaultProp = defaultProps;

const mapStateToProps = (state) => {
  const noOfInstrumentsInGroup = _.get(state, 'quantConfig.instrumentGroups.0', []).length;

  return ({
    instrumentGroups: _.get(state, 'quantConfig.instrumentGroups'),
    validatorErrors: _.get(state, 'validatorErrors'),
    maxInstrumentsInGroup: _.get(state, 'quantConfig.maxInstrumentsInGroup', noOfInstrumentsInGroup),
    onChangeSegment: _.get(state, 'onChangeSegment'),
    segment: _.get(state, 'segment'),
    segments: _.get(state, 'segments', [])
  });
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSetInstrumentGroups: (newInstrumentGroups) => dispatch(setInstrumentGroups(newInstrumentGroups)),
  dispatchSetMaxInstrumentsInGroup: (newMaxInstrumentsInGroup) => (
    dispatch(setMaxInstrumentsInGroup(newMaxInstrumentsInGroup))
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentGroupInput);
