import { validate } from 'jsonschema';
import moment from 'moment';
import { signalConditionOptions } from 'modules/QuantBuilder/config/signalConfigs';

const getPatternRegex = () => ({
  number: /[-+]?([0-9]*\.[0-9]+|[0-9]+)/,
  string: /\w/,
  integer: /^[-+]?\d*$/
});

export const getValidationError = (errors, configName) => {
  return _.filter(errors, ({ property }) => _.includes(property, configName));
};

export const getMandatoryFieldsValidation = (quantConfig, validationSchemas) => {
  const { instrumentGroups, pipeConfigs, orderConfigs } = quantConfig;

  if (_.isEmpty(instrumentGroups)) {
    return [{
      property: 'instance.instrumentGroups',
      message: 'atleast one InstrumentGroup should be added'
    }];
  }

  if (!_.isEmpty(orderConfigs)) {
    const { errors } = validate(orderConfigs, validationSchemas.orderConfigSchema);
    return _.map(errors, ({ message, property }) => ({ message, property }));
  }

  if (!_.isEmpty(pipeConfigs)) {
    const { errors } = validate(pipeConfigs, validationSchemas.pipesSchema);
    return _.map(errors, ({ message, property }) => ({ message, property }));
  }

  return [];
};

const getConfigValidator = (config) => {
  const { properties, required } = config;
  const configValidators = {};

  _.each(properties, (property, key) => {
    const { type } = property;

    if (key === 'valuePaths') {
      const { minItems, maxItems, items } = property;
      configValidators[key] = {
        required: {
          value: _.includes(required, key),
          message: 'Value is required',
        },
        pattern: {
          value: getPatternRegex()[items.type],
          message: `value should be a ${items.type}`
        },
        minLength: {
          value: minItems,
          message: `should have atleast ${minItems} items`
        },
        maxLength: {
          value: maxItems,
          message: `should have at most ${maxItems} items`
        }
      };

      return;
    }

    configValidators[key] = {
      required: {
        value: _.includes(required, key),
        message: 'value is required'
      },
      pattern: {
        value: getPatternRegex()[type],
        message: `value must be a ${type}`
      }
    };
  });

  return configValidators;
};

const getPipeFormValidators = (pipesSchema) => {
  const pipesValidators = {};
  _.each(pipesSchema, (pipeSchema, pipeType) => {
    const { properties, required } = pipeSchema;
    const { type, config } = properties;

    pipesValidators[pipeType] = {
      name: {
        required: {
          value: _.includes(required, 'name'),
          message: 'Name is required'
        },
        pattern: {
          value: /^[^\\.]+$/,
          message: 'Character "." (dot) not allowed in name.'
        }
      },
      type: {
        required: {
          value: _.includes(required, 'type'),
          message: 'indicator is required'
        },
        pattern: {
          value: getPatternRegex()[type],
          message: 'Alphabetical characters only'
        }
      },
      config: getConfigValidator(config)
    };
  });

  return pipesValidators;
};

const getEquityConfigValidators = (equityConfigSchema) => {
  const { properties, required } = equityConfigSchema;
  const equityConfigValidators = {};

  _.each(properties, (property, key) => {
    equityConfigValidators[key] = {
      required: {
        value: _.includes(required, key),
        message: 'value is required'
      },
      pattern: {
        value: getPatternRegex()[property.type],
        message: `value must be a ${property.type}`
      },
      validate: (value) => {
        if (!property.enum) return null;

        return _.includes(property.enum, value)
          ? null
          : `value should be one among ${property.enum}`;
      }
    };
  });

  return equityConfigValidators;
};

const getFutureConfigValidators = (futureConfigSchema) => {
  const { properties, required } = futureConfigSchema;
  const futureConfigValidators = {};

  _.each(properties, (property, key) => {
    futureConfigValidators[key] = {
      required: {
        value: _.includes(required, key),
        message: 'value is required'
      },
      pattern: {
        value: getPatternRegex()[property.type],
        message: `value must be a ${property.type}`
      },
      validate: (value) => {
        if (!property.enum) return null;

        return _.includes(property.enum, value)
          ? null
          : `value should be one among ${property.enum}`;
      }
    };
  });

  return futureConfigValidators;
};

const getOptionConfigValidators = (optionConfigSchemas) => {
  const { properties, required } = optionConfigSchemas.items;
  const optionConfigValidators = {};
  _.each(properties, (property, key) => {
    optionConfigValidators[key] = {
      required: {
        value: _.includes(required, key),
        message: 'value is required'
      },
      pattern: {
        value: getPatternRegex()[property.type],
        message: `value must be a ${property.type}`
      },
      validate: (value) => {
        if (!property.enum) return null;

        const parsedValue = _.isNaN(parseInt(value, 10))
          ? value
          : parseInt(value, 10);

        return (_.includes(property.enum, parsedValue)
          ? null
          : `value should be one among ${property.enum}`
        );
      }
    };
  });

  return optionConfigValidators;
};

const getTransactionsValidators = (transanconfigsSchema) => {
  return ({
    optionConfigsValidators: getOptionConfigValidators(transanconfigsSchema.optionConfigs),
    futureConfigValidators: getFutureConfigValidators(transanconfigsSchema.futureConfig),
    equityConfigValidators: getEquityConfigValidators(transanconfigsSchema.equityConfig)
  });
};

const getSignalValidator = (signalSchema) => {
  const { properties, required } = signalSchema;
  const signalConfigValidator = {};

  _.each(properties, (property, key) => {
    signalConfigValidator[key] = {
      required: {
        value: _.includes(required, key),
        message: 'value is required'
      },
      pattern: {
        value: getPatternRegex()[property.type],
        message: `value must be a ${property.type}`
      },
      validate: (value) => {
        // Here instead of the actual value, we get the dropdown list item option object
        // with id and label, if so, then we make sure if it is required and is not empty.
        if (_.includes(required, key) && value && value.length === 1 && _.isEmpty(value[0].id)) {
          return 'value is required';
        }

        if (!property.enum) return null;

        return (_.includes(property.enum, value)
          ? null
          : `value should be one among ${property.enum}`
        );
      },
      minLength: {
        value: property.minLength,
        message: `should have atleast ${property.minLength} items`
      },
    };
  });

  return signalConfigValidator;
};

const getOrderConfigsValidators = (orderConfigSchema) => {
  const {
    entrySignalConfigs, exitSignalConfigs, transactionConfigs
  } = _.get(orderConfigSchema, ['items', 'properties']);

  return {
    entrySignalConfigs: getSignalValidator(entrySignalConfigs.items),
    exitSignalConfigs: getSignalValidator(exitSignalConfigs.items),
    transactionConfigsValidator: getTransactionsValidators(
      _.get(transactionConfigs, ['items', 'properties'])
    )
  };
};

export const getQuantConfigValidators = ({ pipesSchema, orderConfigSchema }) => {
  return {
    pipesValidators: getPipeFormValidators(pipesSchema),
    orderConfigsValidators: getOrderConfigsValidators(orderConfigSchema)
  };
};

const NO_OF_CANDLES = 'noOfCandles';
export const getConfigAndPeriodValidator = (validator, configKey, candleInterval) => {
  if (configKey !== NO_OF_CANDLES) return validator;

  const maxPeriodAllowed = {
    '1 minutes': 7500,
    '3 minutes': 2500,
    '5 minutes': 1500,
    '15 minutes': 500,
    '30 minutes': 260,
    '1 hours': 140,
  };

  return {
    ...validator,
    validate: (value) => (value > maxPeriodAllowed[candleInterval]
      ? `Max period allowed for ${candleInterval} candle intervel is ${maxPeriodAllowed[candleInterval]}`
      : null
    )
  };
};

export const serverErrorsToObject = (serverErrors) => {
  const errorObj = {};

  _.map(serverErrors, ({ property, message }) => {
    const propertyPath = _.trimStart(property, 'instance.');
    return _.set(errorObj, propertyPath, message);
  });

  return errorObj;
};

export const toReadableErrorMessage = (errorMessage) => {
  const errors = _.split(errorMessage, ':');

  if (errors.length > 1) return errors[1];

  return errorMessage;
};

export const getDayOfWeekValidation = () => {
  const { dayOfWeek: { rightOperandOptions: weekDays } } = signalConditionOptions();

  return ({
    required: {
      value: true,
      message: 'please select a weekday'
    },
    validate: (days) => {
      const hasInvalidDay = _.some(
        _.map(days, (day) => !_.includes(weekDays, day))
      );

      return hasInvalidDay ? 'should be a weekday' : null;
    }
  });
};

const MARKET_START_TIME = moment('9:14', 'h:mm k');
const MARKET_END_TIME = moment('15:29', 'h:mm k');
export const getTimeValidators = (args) => {
  const minTime = moment(_.get(args, 'minTime', MARKET_START_TIME), 'h:mm k');
  const isRequired = _.get(args, 'isRequired', false);

  return ({
    validate: (newTime) => {
      if (!isRequired && !newTime) return null;

      const format = newTime.split(':').length === 3 ? 'h:mm:ss k' : 'h:mm k';
      // handle for seconds and minutes

      if (moment(newTime, format).isBetween(minTime, MARKET_END_TIME)) return null;

      return `please select a time between
        ${moment(minTime, 'h:m: k').add(1, 'minutes').format('HH:mm')} to 15:28`;
    }
  });
};

export const getTimeValidatorsV2 = () => {
  return ({
    validate: (newTime) => {
      if (!newTime) return 'Please select a time';
      const isMarketTime = moment(newTime, 'HH:mm').isBetween(MARKET_START_TIME, MARKET_END_TIME);
      return isMarketTime ? null : 'Please select a time between 9:15 to 15:28';
    }
  });
};
