import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { deleteMultipleRuns } from 'common/api/run';
import ModalComponent from 'common/components/ModalComponent';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  runs: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const defaultProps = {};

const DeleteMultipleRunsModal = ({ onClose, runs }) => {
  const [deleteableRuns, setDeletableRuns] = useState(runs);

  const onSave = () => {
    deleteMultipleRuns({ runIds: _.map(deleteableRuns, 'id') });
    return null;
  };

  const modalProps = {
    title: 'Delete Confirmation',
    size: 'md',
    shouldShow: true,
    onClose: () => { onClose(); },
    okBtnTitle: 'Delete Deployment',
    onSave: () => { onSave(); },
  };

  const onRemoveRun = (ignoreRun) => {
    const alteredRuns = _.filter(deleteableRuns, (run) => run.id !== ignoreRun.id);
    if (_.size(alteredRuns) === 0) { onClose(); }
    setDeletableRuns(alteredRuns);
    return null;
  };

  const renderRun = (run) => {
    const runName = run.name || run.id;
    const iconClassName = `icons-${run.run_type === 'historic' ? 'history' : 'live'}`;
    return (
      <div className="bg-light border rounded d-flex delete-run" key={run.id}>
        <i className={iconClassName} alt={run.run_type} title={run.run_type} />
        <div className="delete-run-name">
          {runName}
        </div>
        <span className="material-icons-outlined remove-btn" onClick={() => { onRemoveRun(run); }}>
          close
        </span>
      </div>
    );
  };

  const renderDeletableRuns = () => {
    return (
      <div className="mb-3">
        <b>Deployments to delete</b>
        <div className="d-flex flex-column gap-15 shadow-sm mt-2">
          {_.map(deleteableRuns, (run) => (
            renderRun(run)
          ))}
        </div>
      </div>
    );
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="text-center">
        <img src="/delete-icon.png" alt="Delete" className="alert-modal-img mb-4" />
        {renderDeletableRuns()}
        <p>
          Once you delete the deployment, it cannot be reverted. Please make sure to delete the deployment
        </p>
      </div>
    </ModalComponent>
  );
};

DeleteMultipleRunsModal.propTypes = propTypes;
DeleteMultipleRunsModal.defaultProps = defaultProps;

export default DeleteMultipleRunsModal;
