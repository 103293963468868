import React from 'react';
import PropTypes from 'prop-types';
import {
  setOptionsExpiryCycle, setOptionStrikeCalculationBasedOn, setOrderConfigs
} from 'modules/QuantBuilder/actions';
import { connect, useSelector } from 'react-redux';
import {
  instrumentGroupsPropTypes,
  orderConfigsPropTypes,
  outputSchemaPropTypes,
  transactionConfigPropTypes
} from 'common/propTypes';
import { SIGNAL_CONFIG } from 'modules/QuantBuilder/config/signalConfigs';
import NewTransactionBuilder from 'modules/TransactionsBuilder/NewTransactionBuilder/index';
import { transactionConfigToTransactionBuilderConfig } from
  'modules/TransactionsBuilder/NewTransactionBuilder/configAdopter/transactionConfigToBuilderConfig';
import { RUN_TYPES } from 'common/constants/index';
import ExistingLegConfigs from './ExistingLegConfigs/index';

const propTypes = {
  orderConfigs: orderConfigsPropTypes.isRequired,
  dispatchSetOrderConfigs: PropTypes.func.isRequired,
  transactionConfigsValidator: PropTypes.shape({}),
  adjustmentActionConfig: PropTypes.arrayOf(transactionConfigPropTypes).isRequired,
  adjustmentActionConfigIndex: PropTypes.number.isRequired,
  orderConfigIndex: PropTypes.number.isRequired,
  transactionConfigs: PropTypes.arrayOf(transactionConfigPropTypes).isRequired,
  strikeBasedOn: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date),
  onStrikeBasedOnChange: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  segment: PropTypes.string.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string).isRequired,
  onOptionsExpiryCycleChange: PropTypes.func.isRequired,
  optionsExpiryCycle: PropTypes.string.isRequired,
  customPropConfigs: PropTypes.arrayOf(PropTypes.shape({}))
};

const defaultProps = { transactionConfigsValidator: {}, startDate: '', customPropConfigs: [] };

const I18N_SCOPE = { scope: 'basic_quant_builder.index' };

const AdjustmentActionConfig = (props) => {
  const {
    orderConfigs, dispatchSetOrderConfigs, adjustmentActionConfig, adjustmentActionConfigIndex,
    orderConfigIndex, transactionConfigs,
    strikeBasedOn, onStrikeBasedOnChange, outputSchema,
    transactionConfigsValidator, instrumentGroups, segment, segments, optionsExpiryCycle,
    onOptionsExpiryCycleChange, startDate, customPropConfigs
  } = props;

  const { runType } = useSelector((state) => state);
  const isBacktest = runType === RUN_TYPES.historic;

  const sanitizedOutputSchema = _.flattenDeep([outputSchema,
    _.chain(customPropConfigs).map(({ outputSchema: cpcOutputSchema }) => {
      try { return JSON.parse(cpcOutputSchema); } catch (err) { return null; }
    }).compact().value()
  ]);

  const onUpdateAdjustmentActionConfigs = (newAdjustmentConfig) => {
    const newOrderConfigs = _.map(orderConfigs, (orderConfig, orderConfigId) => {
      if (orderConfigId === orderConfigIndex) {
        const { adjustmentActionConfigs: configs } = orderConfig;
        const updatedAdjustmentActionConfigs = _.map(configs,
          (adjustmentActioncg, adjustmentId) => {
            if (adjustmentId === adjustmentActionConfigIndex) return newAdjustmentConfig;

            return adjustmentActioncg;
          });

        return { ...orderConfig, [SIGNAL_CONFIG.ADJUSTMENT_ACTION_CONFIGS]: updatedAdjustmentActionConfigs };
      }
      return orderConfig;
    });

    dispatchSetOrderConfigs(newOrderConfigs);
  };

  const onUpdateConfig = (newConfig, actionConfigIndex) => {
    const newActionConfig = adjustmentActionConfig;
    newActionConfig[actionConfigIndex] = newConfig;

    onUpdateAdjustmentActionConfigs(newActionConfig);
  };

  const renderActionConfig = (actionConfig, actionConfigIndex) => {
    const transactionConfig = _.get(transactionConfigs, [actionConfigIndex], {});
    const isShowWarning = !_.isEmpty(transactionConfigToTransactionBuilderConfig(
      actionConfig, strikeBasedOn
    ));
    const isDisabledModifyBtn = isShowWarning && isBacktest;

    return (
      <div className="card" key={actionConfigIndex}>
        <div className="card-body transaction-Builder-body">
          <NewTransactionBuilder
            shouldDisableExpiryCycle={false}
            transactionConfig={actionConfig}
            orderConfigIndex={orderConfigIndex}
            transactionConfigIdx={actionConfigIndex}
            transactionConfigErrors={_.get([], [actionConfigIndex], {})}
            onTransactionConfigChange={(newConfig) => onUpdateConfig(newConfig, actionConfigIndex)}
            isAdjustment
            startDate={startDate}
            optionsExpiryCycle={optionsExpiryCycle}
            onOptionsExpiryCycleChange={onOptionsExpiryCycleChange}
            strikeBasedOn={strikeBasedOn}
            onStrikeBasedOnChange={onStrikeBasedOnChange}
            outputSchema={sanitizedOutputSchema}
            transactionConfigsValidator={transactionConfigsValidator}
            instrumentGroups={instrumentGroups}
            segment={segment}
            segments={segments}
            customPropConfigs={customPropConfigs}
          />
          {_.size(actionConfig) !== 0 && (
            <ExistingLegConfigs
              actionConfig={actionConfig}
              onUpdateAdjustmentActionConfigs={(newConfig) => onUpdateConfig(newConfig, actionConfigIndex)}
              transactionConfig={transactionConfig}
              isDisabledModifyBtn={isDisabledModifyBtn}
              isShowWarning={isShowWarning}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="transaction-config">
      <div className="bg-light p-2 d-flex align-items-center">
        <span className="material-icons-outlined tx-18 mr-3">settings_input_composite</span>
        <span className="tx-14 font-weight-medium">{I18n.t('transaction_details', I18N_SCOPE)}</span>
      </div>
      <div className="transaction-config-builder">
        {_.map(adjustmentActionConfig, renderActionConfig)}
        {/* array of config */}
      </div>
    </div>
  );
};

AdjustmentActionConfig.propTypes = propTypes;
AdjustmentActionConfig.defaultProps = defaultProps;

const mapStatetoProps = (state) => {
  return {
    orderConfigs: _.get(state, 'quantConfig.orderConfigs'),
    segment: _.get(state, 'segment'),
    transactionConfigsValidator: _.get(state, [
      'quantConfigValidators', 'orderConfigsValidators', 'transactionConfigsValidator'
    ]),
    instrumentGroups: _.get(state, 'quantConfig.instrumentGroups'),
    optionsExpiryCycle: _.get(state, 'quantConfig.optionsExpiryCycle'),
    startDate: _.get(state, 'startDate'),
    outputSchema: _.get(state, 'outputSchema'),
    segments: _.get(state, 'segments', []),
    strikeBasedOn: _.get(state, 'quantConfig.optionStrikeCalculationBasedOn', 'strikeIndex'),
    customPropConfigs: _.get(state, 'quantConfig.customPropConfigs', [])
  };
};

const mapDispatchToProp = (dispatch) => ({
  dispatchSetOrderConfigs: (newOrderConfigs) => dispatch(setOrderConfigs(newOrderConfigs)),
  onOptionsExpiryCycleChange: (newOptionsExpiryCycle) => {
    dispatch(setOptionsExpiryCycle(newOptionsExpiryCycle));
  },
  onStrikeBasedOnChange: (newOptionStrikeCalculationBasedOn) => (
    dispatch(setOptionStrikeCalculationBasedOn(newOptionStrikeCalculationBasedOn))
  )
});

export default connect(mapStatetoProps, mapDispatchToProp)(AdjustmentActionConfig);
