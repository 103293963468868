import React from 'react';
import PropTypes from 'prop-types';
import { getInstrumentOptions } from 'ui/Strategies/helper';

const propTypes = {
  filterConfigs: PropTypes.shape({
    instruments: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onUpdateFilterConfigs: PropTypes.func.isRequired,
  strategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const defaultProps = {};

const InstrumentFilter = ({ strategies, filterConfigs, onUpdateFilterConfigs }) => {
  const { instruments } = filterConfigs;
  const instrumentOptions = getInstrumentOptions(strategies);

  const renderInstrumentOption = (instrument, idx) => {
    const onHandleInstrument = () => {
      if (_.includes(instruments, instrument)) {
        return _.filter(instruments, (value) => value !== instrument);
      }

      return [...instruments, instrument];
    };

    return (
      <div className="form-check form-check-sm" key={idx}>
        <input
          className="form-check-input track"
          type="checkbox"
          data-track-category="Market Place Filter"
          data-track-action={instrument}
          data-track-label="Instrument Filter"
          value={instrument}
          id={instrument}
          checked={_.includes(instruments, instrument)}
          onChange={() => { }}
          onClick={() => onUpdateFilterConfigs({
            ...filterConfigs,
            instruments: onHandleInstrument()
          })}
        />
        <label className="form-check-label" htmlFor={instrument}>
          {instrument}
        </label>
      </div>
    );
  };
  return _.isEmpty(instrumentOptions) ? null : (
    <div className="mp-filter">
      <h6 className="mp-filter-title">Instruments</h6>
      <div className="mp-filter-list">
        {_.map(instrumentOptions, renderInstrumentOption)}
      </div>
    </div>
  );
};

InstrumentFilter.propTypes = propTypes;
InstrumentFilter.defaultProps = defaultProps;

export default InstrumentFilter;
