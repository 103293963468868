import { getDefaultIdentifier } from 'v2/modules/withRunForm/configs/index';
import { SEGMENT_CONFIG } from 'v2/common/constants/index';

export const reExecuteBasedAdjutmentAdder = (intent, lastAdjustmentConfigIndex) => {
  const {
    reExecuteCount, identifier, instrument: { instrumentIndex }
  } = intent;
  let previousIdentifier = identifier;
  let currentAdjustmentConfigIndex = lastAdjustmentConfigIndex;

  return _.map(_.range(reExecuteCount), () => {
    let newLeftOperand = '';
    const { segment, adjustmentConfigIndex, optionConfigIndex } = previousIdentifier;
    const isOption = segment === SEGMENT_CONFIG.option;

    let newSegment = segment;
    if (optionConfigIndex !== null) newSegment += `_${optionConfigIndex}`;
    if (adjustmentConfigIndex !== null) newSegment += `_${adjustmentConfigIndex}`;

    // TODO: Use `buildLegProperty` from ./config.js
    newLeftOperand += `individualStop.${instrumentIndex}.${newSegment}.hasExitedInLoss`;

    const newIndentifer = {
      ...getDefaultIdentifier(),
      segment,
      type: 'Legacy',
      caseIndex: identifier.caseIndex,
      adjustmentConfigIndex: currentAdjustmentConfigIndex,
      instrumentIndex,
      optionConfigIndex: isOption ? 0 : null,
    };

    const result = {
      uiConfigs: {
        isAutoAdjustment: true,
        adjustmentMode: 'reExecute'
      },
      signal: [
        {
          conditionOperator: '',
          leftOperand: newLeftOperand,
          operator: 'equalTo',
          rightOperand: '1',
        },
        {
          conditionOperator: 'and',
          leftOperand: _.replace(newLeftOperand, 'hasExitedInLoss', 'slUpdated'),
          operator: 'equalTo',
          rightOperand: '0',
        }
      ],
      intents: [{
        ...intent,
        type: 'OpenLeg',
        identifier: newIndentifer,
        target: previousIdentifier,
        waitUntilPremiumToIncreaseInPercentage: 0,
        waitUntilPremiumToDecreaseInPercentage: 0,
        waitAndTradeType: 'waitUntilPremiumToIncreaseInPercentage',
        reEntryCount: 0,
        reExecuteCount: 0,
      }]
    };
    previousIdentifier = newIndentifer;
    currentAdjustmentConfigIndex += 1;
    return result;
  });
};
