import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string
  })
};
const defaultProps = {
  error: null
};

const ErrorMesssage = ({ error }) => (
  <div className="invalid-feedback d-block">
    {_.get(error, 'message', null)}
  </div>
);

export default ErrorMesssage;

ErrorMesssage.propTypes = propTypes;
ErrorMesssage.defaultProps = defaultProps;
