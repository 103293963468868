// vendor imports
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// project imports
import { folderPropTypes } from 'common/propTypes';
import ArrowAccordion from 'common/components/ArrowAccordion';
import InlineEdit from 'common/components/InlineEdit';
import LoadingSpinner from 'common/components/LoadingSpinner';
import RunList from 'ui/FolderList/RunList';
import { getRuns, deleteRun, updateRun } from 'common/api/run';
import { RUN_TYPES } from 'common/constants/index';
import FolderDeleteConfirmation from './FolderDeleteConfirmation';

const propTypes = {
  folder: folderPropTypes.isRequired,
  defaultOpen: PropTypes.bool,
  onDeleteFolder: PropTypes.func,
  onEditFolderName: PropTypes.func,
  isUpdatingFolderName: PropTypes.bool,
  onChangeCurrentlyOpenFolderId: PropTypes.func,
  scrollToSearch: PropTypes.bool,
};

const defaultProps = {
  defaultOpen: false,
  isUpdatingFolderName: false,
  onDeleteFolder: _.noop,
  onEditFolderName: _.noop,
  onChangeCurrentlyOpenFolderId: _.noop,
  scrollToSearch: false,
};

const HEADER_OFFSET = 100;
const SCROLLING_TIME = 500;

const I18N_SCOPE = { scope: 'notifications' };
export default class Folder extends React.Component {
  constructor(props) {
    super(props);
    const {
      folder,
      folder: { live_runs_count: liveRunsCount, historic_runs_count: historicRunsCount }
    } = props;

    this.state = {
      folder,
      runs: [],
      liveRunsCount,
      historicRunsCount,
      isFetchingRuns: false,
      isLoading: false,
      isLoadingMore: false
    };
  }

  onDeleteRun = (toDeleteRun) => {
    const { runs, liveRunsCount, historicRunsCount } = this.state;
    const { run_type: runType, id: toDeleteRunId } = toDeleteRun;
    const newRunCount = runType === RUN_TYPES.live
      ? { liveRunsCount: liveRunsCount - 1 }
      : { historicRunsCount: historicRunsCount - 1 };

    this.setState({ isLoading: true });

    const successCallBack = () => {
      this.setState({ runs: _.filter(runs, (run) => run.id !== toDeleteRunId), ...newRunCount });
    };

    deleteRun({ runId: toDeleteRunId, successCallBack });
    this.setState({ isLoading: false });
  }

  onUpdateRun = (toUpdateRun, key, value) => {
    const { runs } = this.state;

    const newRuns = _.map(runs, (run) => {
      return (run.id === toUpdateRun.id) ? { ...toUpdateRun, [key]: value } : { ...run };
    });

    updateRun({ runId: toUpdateRun.id, run: { [key]: value } })
      .then(() => this.setState({ runs: newRuns }));
  }

  onEditFolderName = (folderId, updatedName) => {
    const { onEditFolderName } = this.props;
    const { folder } = this.state;
    const udpatedFolder = _.clone({ ...folder, name: updatedName });

    onEditFolderName(folderId, updatedName);
    this.setState({
      folder: udpatedFolder,
    });
  }

  loadMore = () => {
    const { runs, folder: { id } } = this.state;
    const offsetRunId = _.last(runs).id;

    this.setState({ isLoadingMore: true });
    getRuns({ params: { folder_id: id, offsetRunId, locale: I18n.locale } })
      .then((olderRuns) => {
        this.setState({ runs: runs.concat(olderRuns), isLoadingMore: false });
      });
  }

  renderLoadMore = () => {
    const {
      runs, isLoadingMore, liveRunsCount, historicRunsCount
    } = this.state;
    const totalCount = liveRunsCount + historicRunsCount;

    if (runs.length === 0 || runs.length >= totalCount) {
      return null;
    }

    return (
      <div className="load-more-container">
        {isLoadingMore
          ? <div className="spinner-border text-primary" />
          : (
            <button
              className="btn btn-light track"
              type="button"
              data-track-category="Folder"
              data-track-action="Load More Run"
              data-track-label="Folder Trackings"
              onClick={() => this.loadMore()}
            >
              {I18n.t('load_more', I18N_SCOPE)}
            </button>
          )}
      </div>
    );
  }

  onAccordionToggle = (isOpen) => {
    const { onChangeCurrentlyOpenFolderId } = this.props;
    const { folder: { id } } = this.state;

    if (!isOpen) {
      return;
    }

    onChangeCurrentlyOpenFolderId(id);
    this.setState({ isFetchingRuns: true });
    getRuns({ params: { folder_id: id, offsetRunId: 0, locale: I18n.locale } })
      .then((runs) => {
        this.setState({ runs, isFetchingRuns: false });
      });
  }

  scrollToFolder = (scrollToSelector) => {
    const container = $('html,body');
    const scrollTo = $(scrollToSelector);

    container.animate({
      scrollTop: scrollTo.offset().top - HEADER_OFFSET,
      scrollLeft: 0
    }, SCROLLING_TIME);
  }

  componentDidMount = () => {
    const { defaultOpen, scrollToSearch } = this.props;
    const { folder: { id } } = this.state;

    if (!defaultOpen) return;

    this.onAccordionToggle(defaultOpen);

    const scrollToSelector = scrollToSearch ? '.search-filter' : `.folder-view-${id}`;
    this.scrollToFolder(scrollToSelector);
  }

  renderAccordionTitle = () => {
    const { folder, isUpdatingFolderName } = this.props;
    let title;
    let iconClassNames;

    if (folder.is_default) {
      iconClassNames = classNames('folder');
      title = folder.name;
    } else {
      iconClassNames = classNames('source');
      title = (
        <InlineEdit
          text={folder.name}
          onUpdate={(updatedName) => this.onEditFolderName(folder.id, updatedName)}
          isUpdating={isUpdatingFolderName}
          tooltip="Edit Folder name"
        />
      );
    }

    return (
      <div className="folder-heading d-flex align-items-center">
        <i className="material-icons-outlined folder-icon mr-2">{iconClassNames}</i>
        <div className="folder-title font-weight-semi align-self-center flex-grow-1 mr-2">
          {title}
        </div>
      </div>
    );
  }

  renderQuantDetailsAndActionButtons = () => {
    const { liveRunsCount, historicRunsCount } = this.state;

    return (
      <div className="d-flex c-default">
        <div className="count">
          Historic
          <span>{historicRunsCount}</span>
        </div>

        <div className="count ml-2">
          Live
          <span className="font-weight-semi ml-1">{liveRunsCount}</span>
        </div>
      </div>
    );
  }

  renderDeleteQuantButton() {
    const { folder, onDeleteFolder } = this.props;

    if (folder.is_default) { return null; }

    return <FolderDeleteConfirmation onDeleteFolder={onDeleteFolder} />;
  }

  render() {
    const {
      folder, isFetchingRuns, runs, isLoading
    } = this.state;
    const { defaultOpen, isUpdatingFolderName } = this.props;

    return (
      <div className={`folder-view-${folder.id}`}>
        <LoadingSpinner isLoading={isUpdatingFolderName || isFetchingRuns || isLoading}>
          <ArrowAccordion
            defaultOpen={defaultOpen}
            title={this.renderAccordionTitle()}
            headerRightSectionContent={this.renderQuantDetailsAndActionButtons()}
            onToggle={this.onAccordionToggle}
            headerRightIconSection={this.renderDeleteQuantButton()}
          >
            <>
              <RunList
                folder={folder}
                runs={runs}
                runListClassName="runs-list-or-grid"
                onDeleteRun={this.onDeleteRun}
                onUpdateRun={this.onUpdateRun}
              />
              {this.renderLoadMore()}
            </>
          </ArrowAccordion>
        </LoadingSpinner>
      </div>
    );
  }
}

Folder.propTypes = propTypes;
Folder.defaultProps = defaultProps;
