import React, { useEffect, useState, useCallback } from 'react';
import { runPropTypes } from 'common/propTypes';
import { onRunForceExit } from 'common/api/run';
import { formatOrdersBasedOnJobs } from 'common/api/orders';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import subscribeChannel from 'common/api/subscribeChannel';
import ForceExitConfirmation from 'common/components/ForceExitConfirmation/index';
import { groupByTransactionFormat, getOrderData } from '../../../../common/utils/transactionProfitHelper';
import PairedOrderData from './PairedOrderData';
import OverallTransactionProfit from './OverallTransactionProfit';
import PairedOrderTable from './PairedOrderTable';

const propTypes = { run: runPropTypes };

const defaultProps = { run: null };

const LiveRunCard = ({ run }) => {
  if (_.isEmpty(run)) return null;

  const {
    id, order_type: orderType, run_type: runType, display_name: displayName, jobs
  } = run;
  const [orders, setOrders] = useState(_.get(run, 'orders', []));
  const isMarketplaceAuthorRun = _.get(run, 'is_strategy_author', false);

  const oneIndentation = ' ';
  const collapseId = `collapse${id}`;

  const { innerWidth } = window;
  const isMobileView = innerWidth < 775;

  const jobId = _.get(jobs, '[0].id', null);
  const [newOrder, setNewOrder] = useState({});
  const [pairedOrders, setPairedOrders] = useState([]);
  const isMaualExitPresent = _.some(pairedOrders, ({ isManualExitShow }) => isManualExitShow);
  const isComplete = !isMaualExitPresent && _.some(pairedOrders, ({ isCompleted }) => isCompleted);
  const isShowIndividualSquareOff = pairedOrders.length > 1;

  const overAllProfit = _.reduce(pairedOrders, (result, pairedOrder) => {
    const { orderProfit } = pairedOrder;
    // eslint-disable-next-line no-param-reassign
    result += orderProfit || 0;
    return result;
  }, 0);

  useEffect(() => {
    const transaction = formatOrdersBasedOnJobs([orders], id, orderType, runType);
    const groupedByTradingSymbol = _.groupBy(_.flattenDeep(transaction), groupByTransactionFormat);
    const updatedPairedOrders = _.map(groupedByTradingSymbol, (groupedBy) => getOrderData(
      groupedBy, runType, orderType, _.get(run, 'ignore_incomplete_orders_until', null),
      isMarketplaceAuthorRun
    ));
    setPairedOrders(updatedPairedOrders);
  }, [orders]);

  const updateOrCreateOrder = useCallback(() => {
    if (_.isEmpty(newOrder)) return null;

    const { id: newOrUpdateorderId } = newOrder;

    const isOrderPresent = _.some(
      orders, ({ id: individualOrderId }) => individualOrderId === newOrUpdateorderId
    );
    const updatedLastTransaction = !isOrderPresent ? [...orders, newOrder]
      : _.map(orders, (order) => {
        if (order.id === newOrUpdateorderId) return newOrder;
        return order;
      });

    const groupedByTransactions = _.groupBy(updatedLastTransaction, 'transaction_id');

    return setOrders(Object.values(groupedByTransactions).pop() || []);
  }, [newOrder]);

  useEffect(() => {
    const socketConnection = {
      channelName: 'NotificationsChannel',
      roomId: `job_${jobId}`,
      onDataReceive: (order) => { setNewOrder(order); }
    };

    subscribeChannel(socketConnection);
  }, []);

  useEffect(() => { updateOrCreateOrder(); }, [newOrder]);

  const onForceExit = () => {
    onRunForceExit(id).then(() => {
      reactToastify('Manual exit completed successfully', TOASTIFY_TYPES.SUCCESS);
    }).catch(() => {
      reactToastify('Something went wrong, please try again', TOASTIFY_TYPES.ERROR);
    });
  };

  const renderManualExitButton = () => {
    if (!isMaualExitPresent) return null;

    return (
      <ForceExitConfirmation
        onForceExit={onForceExit}
        divClassName="square-off-btn"
        iconClassName="material-icons-outlined"
      />
    );
  };

  const emptyPairedOrdersRender = () => {
    return (
      <div className="text-muted text-center tx-12 mt-3 mb-2">
        <span className="material-icons-outlined tx-25 mb-1 font-weight-bold d-block">
          history_toggle_off
        </span>
        Waiting for transaction
      </div>
    );
  };

  const onUpdatePairedOrder = (updatedPairedOrder, idx) => {
    setPairedOrders(_.map(pairedOrders, (pairedOrder, index) => {
      if (index === idx) return updatedPairedOrder;
      return pairedOrder;
    }));
  };

  const renderLiveBtn = (
    <div className="d-flex justify-content-end mt-2 mr-3">
      <a href={`/runs/${id}`} className="btn btn-sm btn-primary btn-pill">Show Strategy</a>
    </div>
  );

  const renderConditionalRenderer = () => {
    if (_.isEmpty(pairedOrders)) return emptyPairedOrdersRender();

    if (!isMobileView) {
      return (
        <>
          <PairedOrderTable
            pairedOrders={pairedOrders}
            onUpdatePairedOrder={onUpdatePairedOrder}
            isShowIndividualSquareOff={isShowIndividualSquareOff}
          />
          {renderLiveBtn}
        </>
      );
    }

    return (
      <div className="live-transactions-mobile d-block pb-2">
        {_.map(pairedOrders, (pairedOrder, key) => {
          return (
            <PairedOrderData
              key={key}
              pairedOrder={pairedOrder}
              isCardView
              onUpdatePairedOrder={(updatedPairedOrder) => {
                onUpdatePairedOrder(updatedPairedOrder, key);
              }}
              isShowIndividualSquareOff={isShowIndividualSquareOff}
            />
          );
        })}
        {renderLiveBtn}
      </div>
    );
  };

  const renderSignalText = () => {
    if (_.isEmpty(pairedOrders)) return null;
    if (isComplete) {
      return (
        <span className="signal-text waiting">
          {oneIndentation}
          <span className="bubble d-inline-block" />
          {oneIndentation}
          Waiting for Next Entry Signal
        </span>
      );
    }
    if (isMaualExitPresent) {
      return (
        <span className="signal-text entered">
          {oneIndentation}
          <span className="bubble d-inline-block" />
          {oneIndentation}
          Signal Entered
        </span>
      );
    }

    return null;
  };

  const renderRunHeader = () => {
    return (
      <div
        className="card-header"
        data-toggle="collapse"
        data-target={`#${collapseId}`}
        aria-expanded="false"
        aria-controls={collapseId}
      >
        <div className="arrow-accordion-title">
          <div className="text-truncate">
            <a className="card-title strategies-title" href="#run">{displayName || id}</a>
            <div className="d-flex flex-wrap tx-10">
              <div className="mr-2">
                <span className="text-muted">Order type : </span>
                <span className="font-weight-medium">{_.startCase(orderType)}</span>
              </div>
              {renderSignalText()}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center ml-0 ml-md-2">
          <OverallTransactionProfit overAllProfit={overAllProfit} />
          {renderManualExitButton()}
        </div>
      </div>
    );
  };

  return (
    <div className="live-run-toggle">
      <div className="card live-run-card">
        {renderRunHeader()}
        <div className="card-body live-run-body py-0 py-md-3 collapse" id={collapseId}>
          {renderConditionalRenderer()}
        </div>
      </div>
    </div>
  );
};

LiveRunCard.propTypes = propTypes;
LiveRunCard.defaultProps = defaultProps;

export default LiveRunCard;
