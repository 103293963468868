import React, { useContext, useState } from 'react';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import { getRunProfits } from 'ui/run/RunShow/OrderStore/analytics/index';
import classNames from 'classnames';
import RunSummaryCandleChart from './RunSummaryCandleChart';
import RunSummaryChart from './RunSummaryChart';
import MetricsPanel from './MetricsPanel';

const propTypes = {};

const defaultProps = {};

const I18N_SCOPE = { scope: 'runs.show' };

const RunShowSummary = () => {
  const { isLoading, metrics, transactions } = useContext(OrderContext);
  const [chartType, setChartType] = useState('line');
  const { isAdmin } = window;

  const runProfits = !isLoading ? getRunProfits(transactions) : [];
  const orders = _.get(_.head(transactions), 'orders', []);
  const firstOrder = _.get(orders, '0', {});
  const maxPrice = _.get(firstOrder, 'max_price', null);

  const isEmptyRunProfits = _.isEmpty(runProfits);
  const runSummaryClassName = classNames('run-summary-inner', { 'text-center': isEmptyRunProfits });

  const renderMetrics = () => <MetricsPanel metrics={metrics} />;
  const chartTypes = [
    { label: 'CandleStick', value: 'candlestick' },
    { label: 'Cumulative Line', value: 'line' }
  ];

  const renderChartType = (chartConfig, idx) => {
    const spanClassName = classNames('mr-2 cursor-pointer', {
      'text-primary': chartConfig.value === chartType
    });
    return (
      <span
        key={idx}
        onClick={() => setChartType(chartConfig.value)}
        className={spanClassName}
      >
        {chartConfig.label}
      </span>
    );
  };

  return (
    <div className="run-summary">
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            &nbsp;
            {I18n.t('run_summary_title', I18N_SCOPE)}
            &nbsp;
          </div>
          {(isAdmin && maxPrice) && <div>{_.map(chartTypes, renderChartType)}</div>}
        </div>
        <div className="card-body">
          <LoadingSpinner isLoading={isLoading}>
            <div className={runSummaryClassName}>
              {chartType === 'line'
                ? <RunSummaryChart isLoading={isLoading} profits={runProfits} />
                : <RunSummaryCandleChart />}
            </div>
          </LoadingSpinner>
        </div>
        <div className="card-footer bg-transparent">{renderMetrics()}</div>
      </div>
    </div>
  );
};

RunShowSummary.propTypes = propTypes;
RunShowSummary.defaultProps = defaultProps;

export default RunShowSummary;
