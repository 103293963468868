import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import ExpirySelector from './ExpirySelector';
import { setPartialQuantConfig } from '../../redux/actions';
import MaxTransactionsPerDay from './MaxTransactionsPerDay';
import Intraday from './Intraday';
import Positional from './Positional';
import { getDefaultExpiryHandling, getDefaultTimeBasedSignal } from '../../configs/index';

const propTypes = {};

const defaultProps = { };

const ExpiryDetails = () => {
  const tradingType = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.tradingType'));
  const dispatch = useDispatch();
  const isIntraday = useMemo(() => tradingType === 'intraday', [tradingType]);
  const bounds = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.bounds')
  );
  const { timeBasedSignal, expiryHandling } = bounds;

  const onChangeTradingType = useCallback((newTradingType) => {
    const isNewTradingTypeIntraday = newTradingType === 'intraday';
    const newTimeBasedSignal = isNewTradingTypeIntraday ? getDefaultTimeBasedSignal : {};
    const newExpiryHandling = !isNewTradingTypeIntraday ? getDefaultExpiryHandling : { };

    dispatch(
      setPartialQuantConfig({
        tradingType: newTradingType,
        bounds: {
          ...(!isNewTradingTypeIntraday
            ? _.omit(bounds, ['dayStopLoss', 'dayStopGain'])
            : bounds
          ),
          timeBasedSignal: newTimeBasedSignal,
          expiryHandling: newExpiryHandling
        }
      })
    );
  }, [bounds, dispatch]);

  const onChangeExpiryHandling = useCallback((newExpiryHandling) => {
    dispatch(setPartialQuantConfig({ bounds: { ...bounds, expiryHandling: newExpiryHandling } }));
  }, [dispatch, bounds]);

  const onChangeTimeBasedSignal = useCallback((newTimeBasedSignal) => {
    dispatch(setPartialQuantConfig({ bounds: { ...bounds, timeBasedSignal: newTimeBasedSignal } }));
  }, [dispatch, bounds]);

  return (
    <div className="expiry-handler new py-3 px-3 px-sm-4 py-sm-5">
      <div className="mb-2 mb-md-5 font-weight-medium tx-15">Expiry</div>
      <ExpirySelector tradingType={tradingType} onChangeTradingType={onChangeTradingType} />
      {isIntraday ? (
        <Intraday
          timeBasedSignal={timeBasedSignal}
          onChangeTimeBasedSignal={onChangeTimeBasedSignal}
        />
      ) : <Positional expiryHandling={expiryHandling} onChangeExpiryHandling={onChangeExpiryHandling} />}
      <MaxTransactionsPerDay />
    </div>
  );
};

export default ExpiryDetails;

ExpiryDetails.propTypes = propTypes;
ExpiryDetails.defaultProps = defaultProps;
