import * as superTrend from './SuperTrend/index';
import * as movingAverageClosePrice from './MovingAverageClosePrice/index';
import * as intradyStraddle from './IntradyStraddle/index';
import * as rsi from './RSI/index';
import * as ironFly from './IronFly/index';
import * as superTrendAndRsi from './SuperTrendAndRSI/index';

export default [
  superTrend,
  movingAverageClosePrice,
  intradyStraddle,
  ironFly,
  rsi,
  superTrendAndRsi,
];
