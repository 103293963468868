import React from 'react';
import PropTypes from 'prop-types';

import ReactHtmlParser from 'react-html-parser';

const propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
};
const defaultProps = {};

const MessageToast = ({ title, message, onClose }) => {
  return (
    <div className="message-toast">
      <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          <strong className="mr-auto">{_.startCase(title)}</strong>
          <button
            type="button"
            className="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            onClick={onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="toast-body text-center">
          {ReactHtmlParser(message)}
        </div>
      </div>
    </div>
  );
};

export default MessageToast;
MessageToast.defaultProps = defaultProps;
MessageToast.propTypes = propTypes;
