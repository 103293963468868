import React from 'react';
import SmallInfoCard from 'common/components/SmallInfoCard';
import { userPropTypes } from 'common/propTypes';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';
import HoverMessage from 'common/components/HoverMessage';
import { getActualPlanEnddate } from 'common/utils/dateUtils';
import { getBrokerEnabledContent } from './config';
import ExpiringSoonPurchasedItems from './ExpiringSoon';

const propTypes = {
  currentUserDetails: userPropTypes,
};

const defaultProps = { currentUserDetails: null };

let nonBrokerTooltipMessage = '<b>For broker integration:</b> <br />';
nonBrokerTooltipMessage += '1. Login via a broker (instead of email or facebook)<br />';
nonBrokerTooltipMessage += '2. First week free trial or Purchase any Paid Plan. No other setup required.';

const AccountDetails = ({ currentUserDetails }) => {
  if (!currentUserDetails) return null;
  const oneIndentation = ' ';

  const {
    broker,
    addon_consumed_historic_runs: addonConsumedHistoricRuns,
    addon_consumed_live_runs: addonConsumedLiveRuns,
    addon_max_historic_runs: addonMaxHistoricRuns,
    addon_max_live_runs: addonMaxLiveRuns,
    quota_consumed_historic_runs: quotaConsumedHistoricRuns,
    quota_consumed_live_runs: quotaConsumedLiveRuns,
    quota_max_historic_runs: quotaMaxHistoriRuns,
    quota_max_live_runs: quotaMaxLiveRuns,
    plan_active_till: planActiveTill,
    free_trial_till: freeTrialTill
  } = currentUserDetails;
  const periodHeader = planActiveTill ? 'Plan Active Till' : 'Free Active Till';

  const renderBacktestCreditDetails = () => {
    return (
      <span>
        {_.add(addonConsumedHistoricRuns, quotaConsumedHistoricRuns)}
        /
        {_.add(addonMaxHistoricRuns, quotaMaxHistoriRuns)}
      </span>
    );
  };

  const renderLiveCreditDetails = () => {
    return (
      <span>
        {_.add(addonConsumedLiveRuns, quotaConsumedLiveRuns)}
        /
        {_.add(addonMaxLiveRuns, quotaMaxLiveRuns)}
      </span>
    );
  };

  const renderPlanActiveTill = () => {
    let planActiveTillContent = '-';
    if (planActiveTill && moment().format('YYYY-MM-DD') <= planActiveTill) {
      planActiveTillContent = `${getActualPlanEnddate(planActiveTill).format('MMM Do, YYYY')}`;
    } else if (planActiveTill && moment().format('YYYY-MM-DD') > planActiveTill) {
      planActiveTillContent = 'Plan Expired';
    } else if (freeTrialTill && moment().format('YYYY-MM-DD') <= freeTrialTill) {
      planActiveTillContent = `${getActualPlanEnddate(freeTrialTill).format('MMM Do, YYYY')}`;
    } else if (freeTrialTill && moment().format('YYYY-MM-DD') > freeTrialTill) {
      planActiveTillContent = 'Free Trial Expired';
    }

    return planActiveTillContent;
  };

  const renderBrokerDetails = () => {
    const brokerBasedIcon = broker ? 'check_circle' : 'do_disturb_on';
    const brokerContent = broker ? ReactHtmlParser(
      getBrokerEnabledContent(currentUserDetails)
    ) : 'No';

    const iconClassName = classnames('material-icons-outlined tx-16 align-middle', {
      'text-success': broker,
      'text-warning': !broker
    });

    return (
      <span>
        <i className={iconClassName}>{brokerBasedIcon}</i>
        {oneIndentation}
        {brokerContent}
        {!broker && (
          <HoverMessage title="" message={ReactHtmlParser(nonBrokerTooltipMessage)}>
            <span className="material-icons-outlined text-muted tx-16 align-middle ml-2">
              help_outline
            </span>
          </HoverMessage>

        )}
      </span>
    );
  };

  const structuredData = [
    {
      title: 'Backtest Credits',
      icon: <i className="icons-history" key="historicIcon" />,
      value: renderBacktestCreditDetails(),
      link: '/en/folders?selectedFilter=historic'
    },
    {
      title: 'Live Credits',
      icon: <i className="icons-live" key="liveIcon" />,
      value: renderLiveCreditDetails(),
      link: '/en/folders?selectedFilter=running_live_runs'
    },
    {
      title: 'Broker Integration',
      icon: <span className="material-icons-outlined">people_alt</span>,
      value: renderBrokerDetails(),
      link: '/settings?locale=en&tab=profile'
    },
    {
      title: periodHeader,
      icon: <span className="material-icons-outlined">account_balance_wallet</span>,
      value: renderPlanActiveTill(),
      link: '/settings?locale=en&tab=profile'
    }
  ];

  const renderStructuredData = (data, id) => {
    const {
      title, icon, value, link
    } = data;

    return (
      <a key={id} href={link} className="text-body" target="_blank" rel="noreferrer">
        <SmallInfoCard
          leftHeader={title}
          leftValue={<>{value}</>}
          infoAvatar={(<div className="info-avatar-custom">{icon}</div>)}
        />
      </a>
    );
  };

  return (
    <div className="my-3 mt-sm-0">
      <div className="run-info account-details-card">
        {_.map(structuredData, renderStructuredData)}
      </div>
      <ExpiringSoonPurchasedItems currentUserDetails={currentUserDetails} />
    </div>
  );
};

AccountDetails.propTypes = propTypes;
AccountDetails.defaultProps = defaultProps;

export default AccountDetails;
