import React from 'react';
import PropTypes from 'prop-types';
import ModifyOrderSelector from './ModifyOrderSelector';

const propTypes = {
  action: PropTypes.string.isRequired,
  actionTimeInSeconds: PropTypes.string.isRequired,
  isHideCancel: PropTypes.bool.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  orderConfig: PropTypes.shape({}).isRequired,
  actionError: PropTypes.shape({}).isRequired,
  actionsInSecondsError: PropTypes.shape({}).isRequired
};

const defaultProps = {};

const ModifyOrders = ({
  action, actionTimeInSeconds, isHideCancel, onHandleChange, orderConfig,
  actionError, actionsInSecondsError
}) => {
  const actionErrorMessage = _.get(actionError, 'message', null);
  const actionInSecondsMessage = _.get(actionsInSecondsError, 'message', null);

  const renderSelector = (
    <ModifyOrderSelector
      optionType={action}
      isHideCancel={isHideCancel}
      onOptionTypeChange={(value) => onHandleChange({ ...orderConfig, action: value })}
    />
  );

  const renderSeconds = (
    <input
      type="number"
      className="form-control seconds-input"
      name="stopsInput"
      value={actionTimeInSeconds}
      onChange={(event) => onHandleChange({
        ...orderConfig,
        actionTimeInSeconds: Number((event.target.value))
      })}
    />
  );
  return (
    <div className="modify-orders">
      <div className="position-relative">
        <div className="d-flex align-items-center flex-wrap gap-5">
          <label className="mb-0 text-nowrap col-form-label col-form-label-sm">
            If order not executed in
          </label>
          <div className="position-relative d-flex gap-5 align-items-center flex-warp">
            {renderSeconds}
          </div>
          <label className="mb-0 col-form-label col-form-label-sm">seconds</label>
        </div>
        {actionInSecondsMessage && (
          <div className="invalid-feedback tx-12  mt-0 d-block">
            {actionInSecondsMessage}
          </div>
        )}
      </div>
      <div className="position-relative">
        {renderSelector}
        {actionErrorMessage && (
          <div className="invalid-feedback tx-12  mt-0 d-block">
            {actionErrorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

ModifyOrders.propTypes = propTypes;
ModifyOrders.defaultProps = defaultProps;

export default ModifyOrders;
