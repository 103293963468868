import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { videoLanguages } from '../config';

const propTypes = {
  activeLanguage: PropTypes.string.isRequired,
  onChangeLanguage: PropTypes.func.isRequired
};
const defaultProps = {};

const LanguageTab = ({ activeLanguage, onChangeLanguage }) => {
  const renderLanguage = (language, key) => {
    const { id, label } = language;
    const navLinkClassName = classNames('nav-link', { active: activeLanguage === id });

    return (
      <li className="nav-item" key={key}>
        <a
          className={navLinkClassName}
          id={id}
          href={`#${id}`}
          data-toggle="pill"
          role="tab"
          aria-controls={`#${id}`}
          aria-selected="true"
          onClick={() => onChangeLanguage(id)}
        >
          {label}
        </a>
      </li>
    );
  };

  return (
    <div className="col-md-12 mb-3">
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        {_.map(videoLanguages, renderLanguage)}
      </ul>
    </div>
  );
};

LanguageTab.propTypes = propTypes;
LanguageTab.defaultProps = defaultProps;

export default LanguageTab;
