import { useDispatch, useSelector } from 'react-redux';
import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { TOASTIFY_TYPES, reactToastify } from 'common/utils/reactToastify';
import { MAX_CASES_IN_QUANT } from 'v2/common/constants/index';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import { setCases } from '../../redux/actions';
import { getDefaultCase, getQuantModes } from '../../configs/index';
import getIntentDependencies from '../useCase/intentDependencies';
import { getClonedCase } from './clone';
import useCasesError from './useCasesError';

const useCases = () => {
  const cases = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.cases'));
  const { errorConfigs, errorMessage } = useCasesError(cases);
  const pipeConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.pipeConfigs'));
  const intentDependencies = useMemo(() => getIntentDependencies(cases, pipeConfigs), [cases, pipeConfigs]);
  const dispatch = useDispatch();
  const [activeCaseIndex, setActiveCaseIndex] = useState(0);
  const isMaximumCasesExceeded = cases.length >= MAX_CASES_IN_QUANT;
  const isMultipleCasesEnabled = _.get(window, 'SA_FEATURE_FLAGS.isMultipleCasesEnabled', false)
    || window?.isAdmin || false;
  const [isShowAddCaseHoverMessage, setIsShowAddCaseHoverMessage] = useState(false);
  const mode = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs.mode'));
  const isHiddenSignal = useSelector((state) => getSelectorBasedStoreValue(state, 'isHiddenSignal'));
  const isSimpleMode = mode === getQuantModes.simple.value;

  useEffect(() => {
    if (isShowAddCaseHoverMessage) { setTimeout(() => { setIsShowAddCaseHoverMessage(false); }, 5000); }
  }, [isShowAddCaseHoverMessage]);

  useEffect(() => setActiveCaseIndex(0), [isSimpleMode]);

  const onAddCase = useCallback(() => {
    if (!isMultipleCasesEnabled) {
      setIsShowAddCaseHoverMessage(true);
      return;
    }
    if (isMaximumCasesExceeded) {
      reactToastify('You have reached the maximum cases.', TOASTIFY_TYPES.ERROR);
      return;
    }
    dispatch(setCases([...cases, getDefaultCase()]));
    setActiveCaseIndex(cases.length);
  }, [cases, dispatch, isMaximumCasesExceeded, isMultipleCasesEnabled]);

  const onDeleteCase = useCallback((idx) => {
    const newCases = _.cloneDeep(cases);
    newCases.splice(idx, 1);
    dispatch(setCases(newCases));
  }, [cases, dispatch]);

  const onCloneCase = useCallback((idx) => {
    if (!isMultipleCasesEnabled) {
      reactToastify('Please purchase a premium plan to use this feature', TOASTIFY_TYPES.ERROR);
      return;
    }

    if (isMaximumCasesExceeded) {
      reactToastify('You have reached the maximum cases.', TOASTIFY_TYPES.ERROR);
      return;
    }
    const newClonedCases = getClonedCase(cases, idx);
    dispatch(setCases([...cases, newClonedCases]));
  }, [cases, dispatch, isMaximumCasesExceeded, isMultipleCasesEnabled]);

  return {
    cases,
    activeCaseIndex,
    onChangeActiveCaseIndex: setActiveCaseIndex,
    onAddCase,
    onDeleteCase,
    intentDependencies,
    onCloneCase,
    isShowAddCaseHoverMessage,
    isSimpleMode,
    errorConfigs,
    errorMessage,
    isHiddenSignal
  };
};

export default useCases;
