import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import moment from 'moment';
import classNames from 'classnames';

import { INSTAOPTIONS_MODE } from 'v2/common/constants/index';
import { onlyForBetaUsers } from 'common/conditionalHOC';

const propTypes = {
  additionalClassNames: PropTypes.string,
  page: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  queryParams: PropTypes.shape({
    expiryDate: PropTypes.string,
    userRunType: PropTypes.string,
  }),
};
const defaultProps = {
  additionalClassNames: '',
  queryParams: {},
  children: null
};

const CUT_OFF_DATE = moment().subtract(90, 'days').format('YYYY-MM-DD');

// Based on current broker login, we need to redirect to different brokers URLs
const LinkToIO = ({
  additionalClassNames, page, queryParams, children
}) => {
  const { instaOptionsUrl } = window.SA_CONFIGS.currentBrokerDetails;
  const { expiryDate, userRunType } = queryParams;
  if (userRunType === INSTAOPTIONS_MODE.historical && expiryDate < CUT_OFF_DATE) {
    return null;
  }

  const href = `${instaOptionsUrl}/${page}#${qs.stringify(queryParams)}`;
  const fullClassNames = classNames('link-to-io', 'btn  btn-sm', additionalClassNames);
  return (
    <a
      className={fullClassNames}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

LinkToIO.propTypes = propTypes;
LinkToIO.defaultProps = defaultProps;

export default onlyForBetaUsers(LinkToIO);
