import { emptyQuantConfig, getEmptyOrderConfig } from 'modules/QuantBuilder/config';
import { getEmptyTransactionConfig } from 'modules/TransactionsBuilder/configs';

import {
  SET_INSTRUMENT_GROUPS,
  SET_MAX_INSTRUMENTS_IN_GROUP,
  SET_PIPE_CONFIGS,
  SET_REPEAT_ORDER,
  SET_ORDER_CONFIGS,
  SET_OUTPUT_SCHEMA,
  SET_CHART_CONFIG,
  SET_TRADE_TYPE,
  SET_TIME_BASED_SIGNAL,
  SET_EXPIRY_HANDLING,
  SET_OPTIONS_EXPIRY_CYCLE,
  SET_QUANT_CONFIG,
  SET_LIMIT_TRANSACTIONS,
  SET_PRODUCT_TYPE,
  SET_ENTRY_ORDER_TYPE,
  SET_EXIT_ORDER_TYPE,
  SET_OPTION_STRIKE_CALCULATION_BASED_ON,
  SET_GAP_BETWEEN_BUY_AND_SELL_ORDERS_IN_SECONDS,
  STOP_AND_LIMIT_PRICE_DIFFERENCE_IN_PERCENTAGE,
  SET_COMBINED_PREMIUM_CONFIGS,
  SET_ENTRY_ORDER_MODIFICATION,
  SET_EXIT_ORDER_MODIFICATION,
  OMIT_KEY_VALUES,
  SET_IS_PLACE_OPTIONS_ENTRY_BUY_ORDERS_FIRST,
  SET_CUSTOM_PROP_CONFIGS,
  SET_SL_ORDER_PLACE_BASED_ON_PRICE,
  SET_LIMIT_ORDER_BUFFER_PERCENTAGE,
  SET_IS_PLACE_STOPLOSS_ORDER_TO_BROKER,
  SET_IS_SQUARE_OFF_TRANSACTION_IF_ENTRY_FAILED,
  SET_ADJUSTMENT_OPTIONS
} from './actions';

export default function (state = { quantConfig: emptyQuantConfig() }, action) {
  switch (action.type) {
    case SET_INSTRUMENT_GROUPS:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          instrumentGroups: _.get(action, 'instrumentGroups'),
        },
      };

    case SET_MAX_INSTRUMENTS_IN_GROUP:
      const orderConfigs = _.get(state, 'quantConfig.orderConfigs');
      const maxInstrumentsInGroup = _.get(action, 'maxInstrumentsInGroup');
      let newOrderConfigs = [].concat(getEmptyOrderConfig(maxInstrumentsInGroup));

      if (!_.isEmpty(orderConfigs)) {
        newOrderConfigs = _.flatMap(orderConfigs, (orderConfig) => {
          const { transactionConfigs } = orderConfig;

          if (maxInstrumentsInGroup === transactionConfigs.length) {
            return orderConfig;
          }

          return {
            ...orderConfig,
            transactionConfigs: _.times(maxInstrumentsInGroup, getEmptyTransactionConfig)
          };
        });
      }

      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          maxInstrumentsInGroup,
          orderConfigs: newOrderConfigs
        },
      };
    case SET_CHART_CONFIG:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          chartConfig: _.get(action, 'chartConfig'),
        }
      };
    case SET_PIPE_CONFIGS:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          pipeConfigs: _.get(action, 'pipeConfigs'),
        },
      };
    case SET_REPEAT_ORDER:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          repeatOrder: _.get(action, 'repeatOrder'),
        },
      };
    case SET_ORDER_CONFIGS:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          orderConfigs: _.get(action, 'orderConfigs'),
        },
      };
    case SET_OUTPUT_SCHEMA:
      return {
        ...state,
        outputSchema: _.get(action, 'outputSchema'),
      };
    case SET_TIME_BASED_SIGNAL:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          timeBasedSignal: _.get(action, 'timeBasedSignal')
        }
      };
    case SET_EXPIRY_HANDLING:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          expiryHandling: _.get(action, 'expiryHandling')
        }
      };
    case SET_TRADE_TYPE:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          tradingType: _.get(action, 'tradingType'),
        },
      };
    case SET_OPTIONS_EXPIRY_CYCLE:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          optionsExpiryCycle: _.get(action, 'optionsExpiryCycle'),
        }
      };

    case SET_QUANT_CONFIG:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          ...(_.get(action, 'partialQuantConfig')),
        }
      };

    case SET_LIMIT_TRANSACTIONS:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          maxTransactionsPerDay: _.get(action, 'maxTransactionsPerDay'),
        }
      };

    case SET_ENTRY_ORDER_TYPE:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          entryOrderType: _.get(action, 'entryOrderType'),
        }
      };

    case SET_EXIT_ORDER_TYPE:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          exitOrderType: _.get(action, 'exitOrderType'),
        }
      };

    case SET_PRODUCT_TYPE:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          product: _.get(action, 'product'),
        }
      };
    case SET_OPTION_STRIKE_CALCULATION_BASED_ON:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          optionStrikeCalculationBasedOn: _.get(action, 'optionStrikeCalculationBasedOn'),
        }
      };
    case SET_GAP_BETWEEN_BUY_AND_SELL_ORDERS_IN_SECONDS:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          gapBetweenBuyAndSellOrdersInSeconds:
            Number(_.get(action, 'gapBetweenBuyAndSellOrdersInSeconds'))
        }
      };
    case STOP_AND_LIMIT_PRICE_DIFFERENCE_IN_PERCENTAGE:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          stopAndLimitPriceDifferenceInPercentage:
            Number(_.get(action, 'stopAndLimitPriceDifferenceInPercentage'))
        }
      };
    case SET_COMBINED_PREMIUM_CONFIGS:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, combinedPremiumConfigs: _.get(action, 'combinedPremiumConfigs') }
      };
    case SET_ENTRY_ORDER_MODIFICATION:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, entryOrderModification: _.get(action, 'entryOrderModification') }
      };
    case SET_EXIT_ORDER_MODIFICATION:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, exitOrderModification: _.get(action, 'exitOrderModification') }
      };
    case SET_IS_PLACE_OPTIONS_ENTRY_BUY_ORDERS_FIRST:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          isPlaceOptionsEntryBuyOrdersFirst: _.get(action, 'isPlaceOptionsEntryBuyOrdersFirst')
        }
      };
    case SET_CUSTOM_PROP_CONFIGS:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, customPropConfigs: _.get(action, 'customPropConfigs') }
      };
    case SET_SL_ORDER_PLACE_BASED_ON_PRICE:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          slOrderPlaceBasedOnPrice: _.get(action, 'slOrderPlaceBasedOnPrice')
        }
      };
    case SET_IS_PLACE_STOPLOSS_ORDER_TO_BROKER:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          isPlaceStoplossOrderToBroker: _.get(action, 'isPlaceStoplossOrderToBroker')
        }
      };
    case SET_IS_SQUARE_OFF_TRANSACTION_IF_ENTRY_FAILED:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          isSquareOffTransactionIfEntryFailed: _.get(action, 'isSquareOffTransactionIfEntryFailed')
        }
      };
    case OMIT_KEY_VALUES:
      return {
        ...state,
        quantConfig: _.omit(state.quantConfig, [..._.get(action, 'omitKeyValues')])
      };
    case SET_LIMIT_ORDER_BUFFER_PERCENTAGE:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          limitOrderBufferPercentage:
            Number(_.get(action, 'limitOrderBufferPercentage'))
        }
      };
    case SET_ADJUSTMENT_OPTIONS:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          adjustmentOptions: _.get(action, 'adjustmentOptions')
        }
      };
    default:
      return state;
  }
}
