import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TransactionOrderDetails from './TransactionOrderDetails';

const propTypes = {
  ordersPair: PropTypes.shape({}).isRequired,
  isLive: PropTypes.bool.isRequired,
  onHandleModalInParent: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  runStatus: PropTypes.string.isRequired,
};
const defaultProps = {};

const TransactionMoreDetails = ({
  ordersPair, isLive, onHandleModalInParent, orderType, runStatus
}) => {
  if (!isLive) return null;

  const [shouldShowModal, setShouldShowModal] = useState(false);

  return (
    <>
      <div
        className="more ignore-expand-collapse"
        onClick={() => {
          setShouldShowModal(!shouldShowModal);
          onHandleModalInParent();
        }}
      >
        <button className="btn btn-primary btn-sm" type="button">More</button>
      </div>
      <TransactionOrderDetails
        orderType={orderType}
        runStatus={runStatus}
        shouldShowModal={shouldShowModal}
        onHandleModal={() => {
          onHandleModalInParent();
          setShouldShowModal(!shouldShowModal);
        }}
        ordersPair={ordersPair}
      />
    </>
  );
};

TransactionMoreDetails.propTypes = propTypes;
TransactionMoreDetails.defaultProps = defaultProps;

export default TransactionMoreDetails;
