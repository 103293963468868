import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PairedOrderData from './PairedOrderData';

const propTypes = {
  pairedOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdatePairedOrder: PropTypes.func.isRequired,
  isShowIndividualSquareOff: PropTypes.bool.isRequired
};

const defaultProps = {};

const PairedOrderTable = ({ pairedOrders, onUpdatePairedOrder, isShowIndividualSquareOff }) => {
  const renderPairedOrders = (pairedOrder, key) => {
    return (
      <PairedOrderData
        pairedOrder={pairedOrder}
        key={key}
        onUpdatePairedOrder={(updatedPairedOrder) => { onUpdatePairedOrder(updatedPairedOrder, key); }}
        isShowIndividualSquareOff={isShowIndividualSquareOff}
      />
    );
  };

  return (
    <div className="table-responsive d-none d-md-block">
      <table className="table table-sm table-striped mb-0">
        <thead>
          <tr>
            <th align="center">Trading Symbol</th>
            <th align="center">Qty</th>
            <th align="center">Entry</th>
            <th align="center">Exit</th>
            <th align="center">LTP</th>
            <th align="center">Realized Profit</th>
          </tr>
        </thead>
        <tbody>{_.map(pairedOrders, renderPairedOrders)}</tbody>
      </table>
    </div>
  );
};

PairedOrderTable.propTypes = propTypes;
PairedOrderTable.defaultProps = defaultProps;

export default memo(PairedOrderTable);
