import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.string.isRequired,
  maxCharacter: PropTypes.number,
};
const defaultProps = {
  maxCharacter: 0,
};

const TruncateText = ({ text }) => {
  const textRef = useRef();
  const [tooltipProps, setTooltipProps] = useState({});

  useEffect(() => {
    const {
      offsetWidth,
      parentNode: { offsetWidth: parentWidth }
    } = textRef.current;

    if (offsetWidth > parentWidth) {
      setTooltipProps({
        'data-toggle': 'tooltip',
        title: text
      });
    }
  }, []);

  return (
    <div className="text-truncate label-inline-edit mr-1" {...tooltipProps}>
      <span
        ref={textRef}
      >
        {text}
      </span>
    </div>
  );
};

TruncateText.propTypes = propTypes;
TruncateText.defaultProps = defaultProps;

export default TruncateText;
