import PropTypes from 'prop-types';

export const webAndMobileRootPropTypes = {
  planCycleType: PropTypes.string,
  setPlanCycleType: PropTypes.func,
  activePlanId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activePlanName: PropTypes.string,
  addons: PropTypes.array,
  couponCode: PropTypes.string,
  setCouponCode: PropTypes.func,
  isApplied: PropTypes.bool,
  appliedCouponConfig: PropTypes.object,
  creditBalance: PropTypes.number,
  setCreditBalance: PropTypes.func,
  isCreditApplied: PropTypes.bool,
  setIsCreditApplied: PropTypes.func,
  message: PropTypes.string,
  currentCyclicPeriodPlans: PropTypes.array,
  plansGroupedByCyclicMonths: PropTypes.object,
  shouldShowAddons: PropTypes.bool,
  isSubscriptionActive: PropTypes.bool,
  shouldShowCoupon: PropTypes.bool,
  onCouponUpdate: PropTypes.func
};

export const webAndMobileDefaultProps = {
  planCycleType: 'monthly',
  setPlanCycleType: () => {},
  activePlanId: null,
  activePlanName: '',
  addons: [],
  couponCode: '',
  setCouponCode: () => {},
  isApplied: false,
  appliedCouponConfig: {},
  creditBalance: 0,
  setCreditBalance: () => {},
  isCreditApplied: false,
  setIsCreditApplied: () => {},
  message: '',
  currentCyclicPeriodPlans: [],
  plansGroupedByCyclicMonths: {},
  shouldShowAddons: false,
  isSubscriptionActive: false,
  shouldShowCoupon: false,
  onCouponUpdate: () => {}
};
