import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const Iifl = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              To start click on this link
              <b>
                <a
                  href="https://ttblaze.iifl.com/dashboard#!/login"
                  target="blank"
                  title="IIFL XTS"
                >
                  - https://ttblaze.iifl.com/dashboard#!/login
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>Create an Account</b>
            </p>
            <img
              src="/images/broker_setup_guide/iifl/IIFL-Step-1.png"
              className="w-100"
              alt="IIFL XTS login"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Create profile by entering details. Field marked with
              {oneIndentation}
              <b>*</b>
              {oneIndentation}
              are mandatory
            </p>
            <img
              src="/images/broker_setup_guide/iifl/IIFL-Step-2.png"
              className="w-100"
              alt="IIFL XTS signup"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Verification mail will be send on your email id.
            </p>
            <img
              src="/images/broker_setup_guide/iifl/IIFL-Step-3.png"
              alt="IIFL Email verification"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <p className="stepper-desc">
              Post successful email verification, You can login with credentials which shared over email.
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 6</h3>
            <p className="stepper-desc">
              After successful login, click CREATE NEW APPLICATION
            </p>
            <img
              src="/images/broker_setup_guide/iifl/IIFL-Step-4.png"
              className="w-100"
              alt="IIFL Create new application"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 7</h3>
            <p className="stepper-desc">
              Click validate and Enter broker client id and api login password.
            </p>
            <img
              src="/images/broker_setup_guide/iifl/IIFL-Step-5.png"
              className="w-100"
              alt="Broker "
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 8</h3>
            <p className="stepper-desc">
              Go to API Catalogue and Click subscribe
              {oneIndentation}
              <b>Interactive Order API</b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 9</h3>
            <p className="stepper-desc">
              Fill below details in appropriate fields and click CREATE NEW APPLICATION.
            </p>

            <div className="stepper-desc">
              <p>
                <b>App Name:</b>
                {oneIndentation}
                IIFLMARKET
                <br />
                <b>App Description:</b>
                {oneIndentation}
                IIFL MARKET
                <br />
                <b>API Package:</b>
                {oneIndentation}
                Interactive Order API
                <br />
                <b>Company Name:</b>
                {oneIndentation}
                IIFL1
                <br />
                <b>Redirect URL:</b>
                {oneIndentation}
                https://ttblaze.iifl.com/interactive
              </p>
            </div>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 10</h3>
            <p className="stepper-desc">
              After successfully create api, you should wait till api activation from iifl team.
            </p>
            <p className="stepper-desc">
              Generally, API Activation activity is scheduled daily i.e. @ 9:00 am, 12:30 pm and 5:30 pm.
              If not activated within a day contact IIFL support.
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 11</h3>
            <p className="stepper-desc">
              Once api activated, copy and paste api details in quantman and continue your login in quantman.
            </p>
          </div>
        </li>
      </ol>
    </div>
  </>
);

Iifl.propTypes = propTypes;
Iifl.defaultProps = defaultProps;

export default Iifl;
