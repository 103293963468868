import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import Input from './Input';
import {
  actionTypesTemplates, handleErrors, optionTypesTemplates,
  priceAndPercentTemplate, standardDeviationTemplate,
  strikeIndexes,
} from '../config';

const numberOrStringPropType = PropTypes.shape({
  key: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
});

const propTypes = {
  optionType: numberOrStringPropType.isRequired,
  strikeIndex: numberOrStringPropType.isRequired,
  actiontype: numberOrStringPropType.isRequired,
  stopLoss: numberOrStringPropType.isRequired,
  stopGain: numberOrStringPropType.isRequired,
  handleStrategyChange: PropTypes.func.isRequired,
  isStandardDeviation: PropTypes.bool,
  transaction: PropTypes.string,
  quantity: numberOrStringPropType.isRequired,
  stopGainType: numberOrStringPropType.isRequired,
  stopLossType: numberOrStringPropType.isRequired,
  instruments: PropTypes.arrayOf(PropTypes.array)
};

const defaultProps = {
  isStandardDeviation: false,
  transaction: 'option',
  instruments: [],
};

const TemplateSentence = ({
  optionType,
  handleStrategyChange,
  strikeIndex,
  actiontype,
  stopLoss,
  stopGain,
  isStandardDeviation,
  transaction,
  quantity,
  stopGainType,
  stopLossType,
  instruments
}) => {
  const optionItemsForStrikeIndex = () => {
    if (isStandardDeviation) return standardDeviationTemplate;
    return strikeIndexes(optionType.value, instruments);
  };

  return (
    <div className="transction-sentance">
      <div className="actiontype">
        <Select
          value={actiontype.value}
          onChange={(event) => handleStrategyChange(actiontype.key, event.target.value)}
          optionItems={actionTypesTemplates}
        />
      </div>

      <div className="quantity-inner">
        <Input
          className="form-control"
          value={quantity.value}
          onChange={(event) => handleStrategyChange(quantity.key, handleErrors(event.target.value))}
        />
        <span>Lot</span>
      </div>

      <div className="optiontype">
        <Select
          value={optionType.value}
          onChange={(event) => handleStrategyChange(optionType.key, event.target.value)}
          optionItems={optionTypesTemplates}
          disabled={transaction !== 'option'}
        />
      </div>

      <div className="strikeindex">
        <Select
          value={strikeIndex.value}
          onChange={(event) => handleStrategyChange(strikeIndex.key, event.target.value)}
          optionItems={optionItemsForStrikeIndex()}
          disabled={transaction !== 'option'}
        />
      </div>

      <div className="stop-loss">
        <span className="text-nowrap">with SL</span>
        <div className="d-flex">
          <Select
            value={stopLossType.value}
            onChange={(event) => handleStrategyChange(stopLossType.key, event.target.value)}
            optionItems={priceAndPercentTemplate}
          />
          <Input
            value={stopLoss.value}
            onChange={(event) => handleStrategyChange(stopLoss.key, event.target.value)}
          />
        </div>
      </div>

      <div className="stop-gain">
        <span className="text-nowrap">with SG</span>
        <div className="d-flex">
          <Select
            value={stopGainType.value}
            onChange={(event) => handleStrategyChange(stopGainType.key, event.target.value)}
            optionItems={priceAndPercentTemplate}
          />
          <Input
            value={stopGain.value}
            onChange={(event) => handleStrategyChange(stopGain.key, event.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

TemplateSentence.propTypes = propTypes;
TemplateSentence.defaultProps = defaultProps;

export default TemplateSentence;
