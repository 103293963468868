import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool,
  popClassName: PropTypes.string
};
const defaultProps = {
  isOpen: false,
  message: null,
  popClassName: '',
  title: ''
};

const getPopover = (title, message, popClassName) => {
  return (
    <Popover id="popover-basic" className={popClassName}>
      <Popover.Title as="h3">{_.startCase(title)}</Popover.Title>
      <Popover.Content>
        {message}
      </Popover.Content>
    </Popover>
  );
};

const HoverMessage = (props) => {
  const {
    title, message, isOpen, children, popClassName
  } = props;
  const getIsShow = isOpen ? { show: true } : {};

  return (
    <OverlayTrigger
      placement="top"
      {...getIsShow}
      delay={{ show: 250, hide: 400 }}
      overlay={getPopover(title, message, popClassName)}
    >
      {children}
    </OverlayTrigger>
  );
};

HoverMessage.propTypes = propTypes;
HoverMessage.defaultProps = defaultProps;

export default HoverMessage;
