import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import getFormattedDate from 'common/utils/dateFormat';
import { runPropTypes } from 'common/propTypes';
import { onRunViewClick } from 'common/utils/runListHelper';
import MoneyDisplay from 'common/components/MoneyDisplay';

const propTypes = {
  runs: PropTypes.arrayOf(runPropTypes).isRequired,
};
const defaultProps = {};

const I18N_SCOPE = { scope: 'folders.show' };

const renderTableBody = (run) => {
  const {
    id,
    name,
    run_type: runType,
    created_at: createdAt,
    start_date: startDate,
    end_date: endDate,
    summary
  } = run;
  const profit = _.get(summary, 'profit', 0);

  return (
    <tr className="run-list-details" key={id} onClick={(e) => onRunViewClick(e, id)}>
      <td>
        <div className="qd-head">
          <div className="w-100">
            <h6 className="text-truncate pr-5">{name || `Run-${id}`}</h6>
            <small>
              {I18n.t(runType, I18N_SCOPE)}
              &nbsp;
              (
              {getFormattedDate(startDate)}
              &nbsp;
              -
              &nbsp;
              {getFormattedDate(endDate)}
              )
            </small>
          </div>
        </div>
      </td>

      <td>
        <h6 className="d-flex"><MoneyDisplay shouldColor>{profit}</MoneyDisplay></h6>
      </td>
      <td>
        <h6 className="text-muted font-weight-normal">{moment(createdAt).fromNow()}</h6>
      </td>
    </tr>
  );
};

const RunsTableBody = ({ runs }) => {
  return (
    <tbody>{_.map(runs, renderTableBody)}</tbody>
  );
};

RunsTableBody.propTypes = propTypes;
RunsTableBody.defaultProps = defaultProps;

export default RunsTableBody;
