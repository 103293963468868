import { RUN_TYPES } from 'common/constants/index';
import { getQuotaConsumption } from 'common/utils/InstrumentUtils';
import moment from 'moment';

export const getUserAccountDetails = (
  currentUser, runParams, instrumentGroups = []
) => {
  const {
    addon_consumed_historic_runs: addonConsumedHistoricRuns,
    addon_consumed_live_runs: addonConsumedLiveRuns,
    addon_max_historic_runs: addonMaxHistoricRuns,
    addon_max_live_runs: addonMaxLiveRuns,
    quota_consumed_historic_runs: quotaConsumedHistoricRuns,
    quota_consumed_live_runs: quotaConsumedLiveRuns,
    quota_max_historic_runs: quotaMaxHistoriRuns,
    quota_max_live_runs: quotaMaxLiveRuns,
    plan_active_till: planActiveTill,
    free_trial_till: freeTrialTill
  } = currentUser;

  const startDate = _.get(runParams, 'start_date', '');
  const endDate = _.get(runParams, 'end_date', '');
  const runType = _.get(runParams, 'run_type', '');
  const quantConfig = _.get(runParams, 'quant_config', {});
  const granularity = _.get(runParams, 'granularity', '');

  const { requiredQuota } = getQuotaConsumption(
    runType, startDate, endDate, granularity, instrumentGroups || [], _.get(quantConfig, 'cases', [])
  );

  const availableHistoricRuns = _.add(addonMaxHistoricRuns, quotaMaxHistoriRuns)
    - _.add(addonConsumedHistoricRuns, quotaConsumedHistoricRuns);

  const availableLiveRuns = _.add(addonMaxLiveRuns, quotaMaxLiveRuns)
    - _.add(addonConsumedLiveRuns, quotaConsumedLiveRuns);

  const isPaidActiveUser = (planActiveTill && moment().format('YYYY-MM-DD') <= planActiveTill);
  const backtestCredits = (runType === RUN_TYPES.historic && (startDate && endDate))
    ? requiredQuota : 0;

  const isBacktestEligibleToRun = runType === RUN_TYPES.historic
    && ((startDate && endDate) && (!availableHistoricRuns
      ? false : backtestCredits <= availableHistoricRuns));

  const isShowAddon = runType === RUN_TYPES.historic && ((startDate && endDate) && !isBacktestEligibleToRun);
  const isLiveEligible = (isPaidActiveUser
    || (freeTrialTill && moment().format('YYYY-MM-DD') <= freeTrialTill));
  const isShowLiveRun = runType === RUN_TYPES.live && !availableLiveRuns && isLiveEligible;

  return {
    isShowPlan: !isLiveEligible && !isShowAddon && !isShowLiveRun && !isBacktestEligibleToRun,
    isShowAddon,
    isShowLiveRun: isShowLiveRun || false,
    backtestCredits
  };
};

export const modalConditionBasedRender = (isShowPlan, isShowAddon, isShowLiveRun, strategy) => {
  return {
    isStrategyInitialPayment: !_.isEmpty(strategy) && !isShowPlan,
    isCallBackFuncCall: !isShowPlan && !isShowAddon && !isShowLiveRun,
    isRenderRunningLiveRuns: isShowLiveRun && _.isEmpty(strategy),
  };
};
