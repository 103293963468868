import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getQuantModes } from '../../configs/index';

const propTypes = {
  mode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const ModeSelector = ({ onChange, mode }) => {
  const modeStyle = classNames(
    'option-type',
    'align-items-center',
    'gap-10',
    'mt-1',
    'd-flex',
    'selector-radio-btn'
  );
  const isAdvancedMode = mode === getQuantModes.advanced.value;

  const onChangeOrClick = () => {
    if (isAdvancedMode) onChange(getQuantModes.simple.value);
    else onChange(getQuantModes.advanced.value);
  };

  const modeRadioBtn = () => {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="mode"
          id="mode"
          value={mode}
          tabIndex="-100"
          checked={isAdvancedMode}
          onChange={onChangeOrClick}
          onClick={onChangeOrClick}
        />
        <label
          className="form-check-label"
          htmlFor="mode"
        >
          {getQuantModes.advanced.label}
        </label>
      </div>
    );
  };

  return (
    <div className={modeStyle}>{modeRadioBtn()}</div>
  );
};

ModeSelector.propTypes = propTypes;
export default ModeSelector;
