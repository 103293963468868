import wrappedFetch from './base';

const fetchUserSubscriptions = () => {
  return wrappedFetch('/subscriptions.json')
    .then((response) => response.json());
};

export const cancelSubscription = (subscriptionId) => {
  const config = { method: 'PUT' };
  return wrappedFetch(`/subscriptions/${subscriptionId}/cancel_subscription.json`, config);
};

export default fetchUserSubscriptions;
