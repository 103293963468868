import React from 'react';
import loginInModal from 'v2/modules/withRunForm/utils/loginInModal';

// import { BROKERS } from 'common/constants';

const propTypes = {};
const defaultProps = {};

const AlicBlueLogin = () => {
  const oneIndentation = ' ';

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with aliceblue Smart API.
            <br />
            <br />
            <button
              type="button"
              className="btn btn-outline-primary broker"
              onClick={() => loginInModal('/auth/aliceblue')}
            >
              Login via
              {oneIndentation}
              <b>Aliceblue</b>
            </button>
          </p>
          {/* <a href="/auth/aliceblue" className="btn btn-light broker">
            <img src={BROKERS.aliceblue.image} alt={BROKERS.aliceblue.name} className="broker-logo" />
          </a> */}
        </div>
      </div>
    </div>
  );
};

AlicBlueLogin.propTypes = propTypes;
AlicBlueLogin.defaultProps = defaultProps;

export default AlicBlueLogin;
