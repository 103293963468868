export const createGradient = (svgDef, className, color, opacity) => {
  const gradient = svgDef
    .append('linearGradient')
    .attr('class', className)
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '0%')
    .attr('y2', '100%')
    .attr('id', _.uniqueId('gradient'));

  gradient.append('stop')
    .attr('class', 'stop-0')
    .attr('offset', '0%')
    .attr('stop-color', color[0])
    .attr('stop-opacity', opacity[0]);
  gradient.append('stop')
    .attr('class', 'stop-1')
    .attr('offset', '100%')
    .attr('stop-color', color[1])
    .attr('stop-opacity', opacity[1]);
  gradient.append('stop')
    .attr('class', 'stop-2')
    .attr('offset', '100%')
    .attr('stop-color', color[2])
    .attr('stop-opacity', opacity[2]);
  gradient.append('stop')
    .attr('class', 'stop-3')
    .attr('offset', '100%')
    .attr('stop-color', color[3])
    .attr('stop-opacity', opacity[3]);

  return gradient;
};
