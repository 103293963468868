import React from 'react';
import PropTypes from 'prop-types';
import { getUpcomingEvent } from 'ui/HelpLine/config';
import { getMeetStatus } from 'ui/Meeting/helper';
import withErrorBoundary from 'common/components/withErrorBoundary/index';

const propTypes = {
  googleMeetConfig: PropTypes.arrayOf(PropTypes.shape({}))
};
const defaultProps = {
  googleMeetConfig: []
};

const MeetBanner = ({ googleMeetConfig }) => {
  const upComingEvent = getUpcomingEvent(googleMeetConfig);
  const {
    date, startTime, endTime, meetLink, meetingType
  } = upComingEvent;
  const title = _.get(upComingEvent, 'title', '');
  const oneIndentation = ' ';
  const { status, label } = getMeetStatus(date, startTime, endTime);
  const isLive = status === 'Live';
  const isToday = status === 'Today';
  const isHideMeeting = _.keys(upComingEvent).length === 0;

  if (isHideMeeting) return null;

  const renderLiveBubble = () => {
    if (isLive) return <div className="live-session-bubble live-streaming"><span className="bubble" /></div>;

    return null;
  };

  const renderSessionBubble = () => {
    if (isToday && !isHideMeeting) {
      return <div className="live-session-bubble"><span className="bubble" /></div>;
    }

    return null;
  };

  const renderIcon = () => {
    switch (meetingType) {
      case 'googleMeet':
        return (
          <img
            src="/google_meet_icon.png"
            alt="google meet"
            className="alert-icons mb-0 alert-primary google_meet_icon"
          />
        );
      case 'youtube':
        return (
          <img
            src="/youtube.png"
            alt="youtube"
            className="alert-icons mb-0 alert-primary google_meet_icon"
          />
        );
      case 'zoom':
        return (
          <img
            src="/zoom.png"
            alt="zoom meet"
            className="alert-icons mb-0 alert-primary google_meet_icon"
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="live-session-wrapper">
        <div className="live-session mt-3">
          {renderLiveBubble()}
          {renderSessionBubble()}
          <div>
            <a
              href={meetLink}
              target="_blank"
              rel="noreferrer"
              className="meet-link-btn d-flex align-items-center gap-5"
            >
              {renderIcon()}
              <div className="d-flex align-items-center flex-wrap gap-5">
                <div className="livesession-title">
                  Join
                  {oneIndentation}
                  {!isLive && status}
                  {oneIndentation}
                  Livesession
                  <span className="font-weight-bold">
                    {oneIndentation}
                    {label}
                  </span>
                  {oneIndentation}
                  {title && <span className="badge badge-info">{title}</span>}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

MeetBanner.propTypes = propTypes;
MeetBanner.defaultProps = defaultProps;

export default withErrorBoundary(MeetBanner, '', false);
