import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller } from 'react-hook-form';
import useOutputSchema from 'v2/modules/withRunForm/hooks/useOutputSchema';
import { useSelector } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import ReactHtmlParser from 'react-html-parser';
import { OutputSchemaUtility, TypeheadSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import { schemaShouldShowConfigTypes } from 'v2/common/quantConfig/outputSchema/config';

const propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  validator: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  pipeIndex: PropTypes.number
};

const defaultProps = {
  pipeIndex: undefined
};

const mandatoryMultipleInstrumentForPipes = () => [
  PipeDefinitionUtility.getPipeConfigDefaults('ratio').type,
];

const isInvalidForInstrument = (type, valuePaths, maxInstrumentsInGroup) => {
  const hasDefaultValuePaths = _.filter(
    _.get(PipeDefinitionUtility.getPipeConfigDefaults(type),
      'config.valuePaths',
      []),
    (valuePath) => {
      return _.includes(valuePaths, valuePath);
    }
  ).length === valuePaths.length;

  return _.includes(mandatoryMultipleInstrumentForPipes(), type)
    && (maxInstrumentsInGroup === 1 && hasDefaultValuePaths);
};

const ValuePathsFormField = ({
  value, type, onUpdatePipeConfig, validator, control, errors, name: propsName, pipeIndex
}) => {
  const uniqueId = useMemo(() => _.uniqueId('pipe_value_paths_'), []);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');
  const maxInstrumentsInGroup = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.maxInstrumentsInGroup')
  );
  const outputSchema = useOutputSchema();
  const isInvalid = useMemo(
    () => isInvalidForInstrument(type, value, maxInstrumentsInGroup),
    [maxInstrumentsInGroup, type, value]
  );

  const pipeConfigOutputSchema = useMemo(
    () => _.chain(outputSchema)
      .thru((schema) => OutputSchemaUtility.filterByTypes(
        schema,
        [schemaShouldShowConfigTypes.pipeValuePath]
      ))
      .thru((schema) => OutputSchemaUtility.filterSchemaPriorToPipeIndex(schema, pipeIndex))
      .value(),
    [outputSchema, pipeIndex]
  );

  const isNonEditableValuePaths = useMemo(
    () => _.includes(PipeDefinitionUtility.getIndicatorsWithNonEditableValuePaths(), type), [type]
  );

  const options = useMemo(
    () => _.map(
      pipeConfigOutputSchema,
      (schema) => TypeheadSchemaUtility.getOption(schema)
    ),
    [pipeConfigOutputSchema]
  );

  const selected = useMemo(
    () => TypeheadSchemaUtility.getSelectedOption(
      pipeConfigOutputSchema,
      value
    ), [value, pipeConfigOutputSchema]
  );

  const onValuePathChange = useCallback((val, onChange) => {
    const newValuePaths = _.map(val, 'id');

    onUpdatePipeConfig(propsName, newValuePaths);
    onChange(newValuePaths);
  }, [onUpdatePipeConfig, propsName]);

  const valuePathValidator = useMemo(() => {
    const minLength = _.get(validator, 'minLength');
    const maxLength = _.get(validator, 'maxLength');

    return {
      ...validator,
      validate: (val) => {
        if (_.isEmpty(val)) return 'Please add a value Field';
        if (maxLength && _.size(selected) > maxLength.value) return maxLength.message;
        if (minLength && _.size(selected) < minLength.value) return minLength.message;
        if (isInvalid) return 'should have pair trade';

        return null;
      }
    };
  }, [isInvalid, selected, validator]);

  return (
    <ErrorMesssage message={errorMessage}>
      <Controller
        render={({ onChange, ref, name }) => (
          <Typeahead
            className="custom-form-control"
            multiple={_.get(validator, 'minLength.value', 1) > 1}
            disabled={isNonEditableValuePaths}
            ref={ref}
            isInvalid={!!errorMessage}
            id={name}
            options={options}
            labelKey="searchLabel"
            renderMenuItemChildren={(option) => ReactHtmlParser(option.label)}
            filterBy={(
              option, filterProps
            ) => TypeheadSchemaUtility.getOptionFilteredBySearchText(option, filterProps)}
            selected={selected}
            clearButton={!_.isEmpty(selected)}
            onChange={(val) => onValuePathChange(val, onChange)}
          />
        )}
        control={control}
        defaultValue={selected}
        name={uniqueId}
        rules={valuePathValidator}
      />
    </ErrorMesssage>
  );
};

ValuePathsFormField.propTypes = propTypes;
ValuePathsFormField.defaultProps = defaultProps;

export default memo(ValuePathsFormField);
