import Modal from 'common/components/ModalComponent';
import { ADJUST_PERCENTAGE, TRAILING_PERCENTAGE } from 'modules/TransactionsBuilder/configs';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import HoverMessage from 'common/components/HoverMessage';
import ReactHtmlParser from 'react-html-parser';
import { transactionBuilderConfigPropTypes } from 'common/propTypes';
import { advancedFeatureConfigs } from 'modules/QuantBuilder/config/signalConfigs';
import { useSelector } from 'react-redux';
import TrailingStopLossInputs from './TrailingStopLossInputs';

const propTypes = {
  transactionBuilderConfig: transactionBuilderConfigPropTypes.isRequired,
  onTransactionBuilderConfigChange: PropTypes.func.isRequired,
  orderConfigIndex: PropTypes.number.isRequired
};

const defaultProps = {};

const TrailingStopLoss = ({
  onTransactionBuilderConfigChange, transactionBuilderConfig, orderConfigIndex
}) => {
  const globalReduxState = useSelector((state) => state);
  const orderConfigs = _.get(globalReduxState, 'quantConfig.orderConfigs', []);
  const currentOrderConfig = _.get(orderConfigs, orderConfigIndex);
  const advancedFeatures = _.get(currentOrderConfig, 'advancedFeatureConfigs', advancedFeatureConfigs());
  const { isTrailingStopLoss } = advancedFeatures;

  const {
    adjustPercentage, trailingPercentage, stopLoss, tradeType
  } = transactionBuilderConfig;
  const isOption = tradeType === 'optionConfigs';
  const isStopLossValuePresent = !!stopLoss;

  const methods = useForm();
  const isInitialLoad = useRef(false);

  const oneIndentation = ' ';
  const [isShowTrailingStopLossModal, setIsShowTrailingStopLossModal] = useState(false);
  const [isShowHoverMessage, setIsShowHoverMessage] = useState(false);
  const [trailingStopLossConfig, setTrailingStopLossConfig] = useState({
    [ADJUST_PERCENTAGE]: adjustPercentage,
    [TRAILING_PERCENTAGE]: trailingPercentage
  });

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setTrailingStopLossConfig({
      [ADJUST_PERCENTAGE]: adjustPercentage,
      [TRAILING_PERCENTAGE]: trailingPercentage
    });
  }, [trailingPercentage, adjustPercentage]);

  useEffect(() => {
    const isResetTrailingStopLoss = isInitialLoad.current
      && !isStopLossValuePresent && adjustPercentage && adjustPercentage;
    if (isResetTrailingStopLoss) {
      onTransactionBuilderConfigChange({
        [TRAILING_PERCENTAGE]: undefined, [ADJUST_PERCENTAGE]: undefined, reExecuteCount: 0, reEntryCount: 0
      });
    }
    isInitialLoad.current = true;
  }, [isStopLossValuePresent]);

  const onValidate = () => {
    const errorConfig = [];

    if (trailingStopLossConfig[ADJUST_PERCENTAGE] === undefined
      && trailingStopLossConfig[TRAILING_PERCENTAGE] === undefined) {
      return errorConfig;
    }

    if (trailingStopLossConfig[TRAILING_PERCENTAGE] !== undefined
      && trailingStopLossConfig[TRAILING_PERCENTAGE] < 5 && isOption) {
      errorConfig.push('* For Option, the X percentage is Minimum 5');
    }

    if ((trailingStopLossConfig[TRAILING_PERCENTAGE] !== undefined
      && trailingStopLossConfig[ADJUST_PERCENTAGE] === undefined)
      || (trailingStopLossConfig[TRAILING_PERCENTAGE] === undefined
        && trailingStopLossConfig[ADJUST_PERCENTAGE] !== undefined)) {
      errorConfig.push('* Both Value Should be Required');
    }

    if ((trailingStopLossConfig[TRAILING_PERCENTAGE] && trailingStopLossConfig[ADJUST_PERCENTAGE])
      && (trailingStopLossConfig[TRAILING_PERCENTAGE] < trailingStopLossConfig[ADJUST_PERCENTAGE])) {
      errorConfig.push('* X should be Greater than or Equal to Y');
    }

    return errorConfig;
  };

  const onSubmit = () => {
    const errorConfig = onValidate();
    if (_.isEmpty(errorConfig)) {
      onTransactionBuilderConfigChange({
        [ADJUST_PERCENTAGE]: trailingStopLossConfig[ADJUST_PERCENTAGE],
        [TRAILING_PERCENTAGE]: trailingStopLossConfig[TRAILING_PERCENTAGE]
      });
      setIsShowTrailingStopLossModal(false);
      setErrors([]);
    } else {
      setErrors(errorConfig);
    }
  };

  const renderTrailingStopLossValues = () => {
    return (
      <div className="tsl-result" onClick={() => { setIsShowTrailingStopLossModal(true); }}>
        <div>
          <span className="text-muted">X : </span>
          <span>{trailingPercentage}</span>
        </div>
        <div>
          <span className="text-muted">Y : </span>
          <span>{adjustPercentage}</span>
        </div>
        <span className="material-icons-outlined tx-15">edit</span>
      </div>
    );
  };

  useEffect(() => {
    if (isShowHoverMessage) { setTimeout(() => { setIsShowHoverMessage(false); }, 5000); }
  }, [isShowHoverMessage]);

  const renderAddButton = () => {
    const addButton = (
      <button
        className="btn btn-link btn-block btn-sm add-tsl-btn track"
        onClick={() => {
          if (!isStopLossValuePresent) setIsShowHoverMessage(true);
          else setIsShowTrailingStopLossModal(true);
        }}
        type="button"
      >
        <span className="material-icons-outlined tx-22 mr-1">add_circle</span>
        {oneIndentation}
        Add
      </button>
    );

    if (isStopLossValuePresent || !isShowHoverMessage) return addButton;

    return (
      <HoverMessage
        title="Trailing Stop Loss"
        message={ReactHtmlParser('<b>Stop Loss Percentage</b> is Required For Trailing Stop Loss')}
        isOpen={isShowHoverMessage}
      >
        {addButton}
      </HoverMessage>
    );
  };

  const renderTrailingStopLossView = () => {
    return (
      <div>
        {(trailingPercentage && adjustPercentage)
          ? renderTrailingStopLossValues() : renderAddButton()}
      </div>
    );
  };

  if (!isTrailingStopLoss) return null;

  return (
    <td>
      {renderTrailingStopLossView()}
      <Modal
        onSave={methods.handleSubmit(() => onSubmit())}
        shouldShow={isShowTrailingStopLossModal}
        onClose={() => setIsShowTrailingStopLossModal(false)}
        title="Trailing Stop Loss"
        size="md"
        okBtnTitle="Submit"
      >
        <div>
          <div className="tsl-wrapper">
            If Instument Moves
            {oneIndentation}
            <b>X</b>
            <TrailingStopLossInputs
              trailingInput={TRAILING_PERCENTAGE}
              trailingValue={trailingStopLossConfig[TRAILING_PERCENTAGE]}
              onTrailingValueChange={(partiallyUpdatedConfig) => {
                setTrailingStopLossConfig({ ...trailingStopLossConfig, ...partiallyUpdatedConfig });
              }}
              isOption={isOption}
            />
            Then Move Stoploss By
            {oneIndentation}
            <b>Y</b>
            <TrailingStopLossInputs
              trailingInput={ADJUST_PERCENTAGE}
              trailingValue={trailingStopLossConfig[ADJUST_PERCENTAGE]}
              onTrailingValueChange={(partiallyUpdatedConfig) => {
                setTrailingStopLossConfig({ ...trailingStopLossConfig, ...partiallyUpdatedConfig });
              }}
              isOption={isOption}
            />
          </div>

          <p className="mt-4 text-center text-muted">
            Every time instrument moves X % in the profit
            direction then move stop loss Y % in same direction.
          </p>

          {!_.isEmpty(errors)
            && (
              <div className="invalid-feedback d-block">
                {_.map(errors, (error, idx) => { return <div key={idx}>{error}</div>; })}
              </div>
            )}
        </div>
      </Modal>
    </td>
  );
};

TrailingStopLoss.propTypes = propTypes;
TrailingStopLoss.defaultProps = defaultProps;

export default TrailingStopLoss;
