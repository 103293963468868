export const SET_PARTIAL_QUANT_CONFIG = 'SET_PARTIAL_QUANT_CONFIG';
export const setPartialQuantConfig = (partialQuantConfig) => ({
  type: SET_PARTIAL_QUANT_CONFIG,
  partialQuantConfig,
});

export const SET_INSTRUMENT_GROUPS = 'SET_INSTRUMENT_GROUPS';
export const setInstrumentGroups = (instrumentGroups) => ({
  type: SET_INSTRUMENT_GROUPS,
  instrumentGroups,
});

export const SET_RUN_FORM_ROOT_VALUE = 'SET_ROOT_VALUE';
export const setRootValue = (runFormConfig) => ({
  type: SET_RUN_FORM_ROOT_VALUE,
  runFormConfig,
});

export const SET_PIPE_CONFIGS = 'SET_PIPE_CONFIGS';
export const setPipeConfigs = (pipeConfigs) => ({
  type: SET_PIPE_CONFIGS,
  pipeConfigs,
});

export const SET_ADJUSTMENT_OPTIONS = 'SET_ADJUSTMENT_OPTIONS';
export const setAdjustmentOptions = (adjustmentOptions) => ({
  type: SET_ADJUSTMENT_OPTIONS,
  adjustmentOptions,
});

export const SET_BOUNDS = 'SET_BOUNDS';
export const setBounds = (bounds) => ({
  type: SET_BOUNDS,
  bounds,
});

export const SET_PARTIAL_BOUNDS = 'SET_PARTIAL_BOUNDS';
export const setPartialBounds = (partialBounds) => ({
  type: SET_PARTIAL_BOUNDS,
  partialBounds,
});

export const SET_CASES = 'SET_CASES';
export const setCases = (cases) => ({
  type: SET_CASES,
  cases,
});

export const SET_CASES_INDEX_BASED = 'SET_CASES_INDEX_BASED';
export const setCasesIndexBased = (caseConfig, idx) => ({
  type: SET_CASES_INDEX_BASED,
  caseConfig,
  idx,
});

export const SET_UI_BEHAVIOUR_FLAGS = 'SET_UI_BEHAVIOUR_FLAGS';
export const setUiBehaviourFlags = (uiBehaviourFlags) => ({
  type: SET_UI_BEHAVIOUR_FLAGS,
  uiBehaviourFlags,
});
