import React, { useState } from 'react';

import { BROKERS } from 'common/constants';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};
const defaultProps = {};

const InvestRightLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with HDFC InvestRight.
          </p>

          <div
            onClick={() => { setCurrentBroker(BROKERS.invest_right.id); }}
            className="btn btn-outline-primary broker"
          >
            <div className="d-flex align-items-center gap-10">
              <span>Login via HDFC Invest Right</span>
            </div>
          </div>
        </div>
        <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
      </div>
    </div>
  );
};

InvestRightLogin.propTypes = propTypes;
InvestRightLogin.defaultProps = defaultProps;

export default InvestRightLogin;
