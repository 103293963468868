import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { strategyPropTypes } from 'common/propTypes';

const propTypes = {
  strategyConfig: strategyPropTypes.isRequired,
  onUpdateStrategyConfig: PropTypes.func.isRequired
};

const defaultProps = {};

const StrategyFormDescription = ({ strategyConfig, onUpdateStrategyConfig }) => {
  const { description } = strategyConfig;
  const [descriptionId] = useState(_.uniqueId('descriptionId-'));
  const { errors, register } = useFormContext();

  const validatorConfig = {
    validate: (value) => {
      if (!value) return 'Please type an description *';

      return null;
    }
  };
  const validatorProps = { ref: register(validatorConfig) };

  const textAreaClassName = classNames('form-control form-control-sm', {
    'border-danger': errors[`${descriptionId}`]
  });

  return (
    <div className="form-group">
      <label className="col-form-label col-form-label-sm" htmlFor="">Strategy Description</label>
      <textarea
        {...validatorProps}
        className={textAreaClassName}
        id="text-area"
        name={descriptionId}
        rows="3"
        value={description}
        onChange={(event) => {
          const { target: { value } } = event;
          onUpdateStrategyConfig({ ...strategyConfig, description: value });
        }}
      />
    </div>
  );
};

StrategyFormDescription.propTypes = propTypes;
StrategyFormDescription.defaultProps = defaultProps;

export default StrategyFormDescription;
