import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { STOPS_VALUE_TYPES } from 'common/constants/index';

const propTypes = {
  valueType: PropTypes.string.isRequired,
  onValueTypeChange: PropTypes.func.isRequired,
  isShowPrice: PropTypes.bool,
  isShowPoints: PropTypes.bool,
  isShowPercent: PropTypes.bool
};

const defaultProps = { isShowPrice: true, isShowPoints: true, isShowPercent: true };

const ValueTypeToggleBtn = ({
  valueType, onValueTypeChange, isShowPrice, isShowPoints, isShowPercent
}) => {
  const { Percent, Amount, Points } = STOPS_VALUE_TYPES;

  return (
    <ToggleButtonGroup
      bsPrefix="btn-group btn-group-sm btn-group-toggle"
      type="radio"
      name="group-selector"
      value={valueType}
      onChange={(value) => onValueTypeChange(value)}
    >
      {isShowPercent && (
        <ToggleButton bsPrefix="btn btn-sm btn-outline-primary btn-lightness" value={Percent}>
          %
        </ToggleButton>
      )}
      {isShowPrice && (
        <ToggleButton bsPrefix="btn btn-sm btn-outline-primary btn-lightness" value={Amount}>
          <span>&#8377;</span>
        </ToggleButton>
      )}
      {isShowPoints && (
        <ToggleButton bsPrefix="btn btn-sm btn-outline-primary btn-lightness" value={Points}>
          Pts
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

ValueTypeToggleBtn.propTypes = propTypes;
ValueTypeToggleBtn.defaultProps = defaultProps;

export default ValueTypeToggleBtn;
