import fetchStrategies from 'common/api/strategies';
import ArrowAccordion from 'common/components/ArrowAccordion';
import { runStatusColorCode } from 'common/configs/runStatus';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import StrategyAccessDetailsAdminPanel from './StrategyAccessDetailsAdminPanel';

const propTypes = {};
const defaultProps = {};

const StrategyAdminPanel = () => {
  const [strategies, setStrategies] = useState([]);
  const oneIndentation = ' ';

  useEffect(() => {
    fetchStrategies({ params: { offsetStrategyId: 0 } })
      .then((results) => {
        setStrategies(_.orderBy(results, 'deployed_user_count', 'desc'));
      });
  }, []);

  const renderTitle = (strategy) => {
    const { title } = strategy;

    return title;
  };

  const renderHeaderRightSectionContent = (strategy) => {
    const { live_run: liveRun } = strategy;
    const { status: liveStatus, updated_at: updatedAt } = liveRun;
    const { statusText, statusClassName } = runStatusColorCode(liveStatus);

    return (
      <div className="run-status-details">
        <h6>
          (
          {moment(updatedAt).format('YYYY-MM-DD hh:mm A')}
          )
          {oneIndentation}
          {oneIndentation}
          {liveStatus && <span className={statusClassName} />}
          {statusText}
        </h6>
      </div>
    );
  };

  const renderStrategies = (strategy, key) => {
    return (
      <ArrowAccordion
        defaultOpen={false}
        key={key}
        title={renderTitle(strategy)}
        headerRightSectionContent={renderHeaderRightSectionContent(strategy)}
        onToggle={() => { }}
      >
        <StrategyAccessDetailsAdminPanel strategy={strategy} />
      </ArrowAccordion>
    );
  };

  return (
    <>
      <h5 className="mb-4">
        Strategy Details
      </h5>
      {_.map(strategies, renderStrategies)}
    </>
  );
};

StrategyAdminPanel.propTypes = propTypes;
StrategyAdminPanel.defaultProps = defaultProps;

export default StrategyAdminPanel;
