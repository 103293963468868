import React from 'react';
import PropTypes from 'prop-types';

import { pipeConfigsPropTypes } from 'common/propTypes';
import BgBlink from 'common/components/BgBlink';
import {
  indicatorsTypes, DOUBLE_COL_SPAN, NO_COL_SPAN, SUB_TITLE
} from 'ui/run/RunShow/config';
import { getFormattedIndicatorsData } from '../helper';

const propTypes = {
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  tockGroup: PropTypes.shape({}).isRequired,
  instrumentGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
  indicatorsType: PropTypes.string.isRequired
};
const defaultProps = {};

const getIndicatorDataTdProps = (subIndicatorKey, indicatorsType) => {
  if (indicatorsType === indicatorsTypes.pair) {
    return {
      className: subIndicatorKey === SUB_TITLE ? 'text-right' : 'text-center',
      colSpan: subIndicatorKey === SUB_TITLE ? NO_COL_SPAN : DOUBLE_COL_SPAN
    };
  }

  return { className: 'text-right' };
};

const IndicatorsDataTable = (props) => {
  const { indicatorsType } = props;
  const indicatorsData = getFormattedIndicatorsData(props);

  if (_.isEmpty(indicatorsData)) return null;

  return _.map(indicatorsData, (data) => {
    const { title, indicatorData } = data;
    const titleRowSpan = indicatorData.length;
    const titleColSpan = indicatorData.length === 1 ? DOUBLE_COL_SPAN : NO_COL_SPAN;

    return _.map(indicatorData, (subIndicatorsData, idx) => (
      <tr key={idx}>
        {!idx && <td rowSpan={titleRowSpan} colSpan={titleColSpan}>{title}</td>}
        {_.map(subIndicatorsData, (subIndicatorData, key) => (
          <td key={key} {...getIndicatorDataTdProps(key, indicatorsType)}>
            <BgBlink>
              {_.isNumber(subIndicatorData) ? _.round(subIndicatorData, 2) : subIndicatorData}
            </BgBlink>
          </td>
        ))}
      </tr>
    ));
  });
};

IndicatorsDataTable.propTypes = propTypes;
IndicatorsDataTable.defaultProps = defaultProps;

export default IndicatorsDataTable;
