export const extractIdsFromLabel = (string) => {
  const config = {
    basketId: null,
    intentId: null,
  };
  try {
    const pattern = /(\d+)(?:\(([A-Za-z])\))?/;
    const match = string.match(pattern);

    if (match) {
      const basketId = match[1] ? match[2] : null; // Set basketId to null if there are no parentheses
      const intentId = match[1] ? match[1] : match[2]; // Use basketId as intentId if intentId is not present

      return {
        basketId,
        intentId,
      };
    }
    return config;
  } catch (error) {
    return config;
  }
};

export const extractIndividualSchemaField = (string) => {
  const [position, label, field] = _.split(string, '.');
  const { basketId, intentId } = extractIdsFromLabel(label);

  return {
    position,
    field,
    intentId,
    label,
    basketId,
  };
};
