import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  isHiddenSignal: PropTypes.bool,
};
const defaultProps = {
  isHiddenSignal: false,
};

const withHiddenSignal = (WrappedComponent, isHiddenSignal) => {
  return (props) => {
    if (isHiddenSignal) {
      return (
        <div className="d-flex align-items-center">
          <u className="font-weight-medium tx-12 mx-2 underline-operator">Signal is Hidden</u>
        </div>
      );
    }
    return <WrappedComponent {...props} />;
  };
};

withHiddenSignal.propTypes = propTypes;
withHiddenSignal.defaultProps = defaultProps;

export default withHiddenSignal;
