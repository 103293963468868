import moment from 'moment';
import { getSymbolType } from 'v2/common/utils/tradingSymbol';

const getInstrumentType = (tradeType, symbol) => {
  const { indexInstruments } = window;

  const isIndexInstrument = _.includes(indexInstruments, symbol);
  if (tradeType === 'CE' || tradeType === 'PE') { // options
    return `OPT${isIndexInstrument ? 'IDX' : 'STK'}`;
  }
  if (tradeType === 'FUT') { // future
    return `FUT${isIndexInstrument ? 'IDX' : 'STK'}`;
  }

  return 'EQUITY';
};

const getMarketDataType = (instrumentType, symbol, tradingSymbol) => {
  const startIndexOfDate = tradingSymbol.search(/\d/);
  const endIndexOfDate = startIndexOfDate + 7;
  const date = tradingSymbol.substring(startIndexOfDate, endIndexOfDate);
  const formatedDate = moment(date, 'DDMMMYY').format('DD-MM-YYYY');

  if (_.includes(instrumentType, 'FUT')) {
    return `${symbol}${instrumentType}${formatedDate}`;
  }

  const strikePrice = tradingSymbol.substring(endIndexOfDate, _.size(tradingSymbol) - 2);
  const optionType = tradingSymbol.slice(-3).replace(/[0-9]/g, '');

  return `${symbol}${instrumentType}${formatedDate}${strikePrice}.00${optionType}`;
};

export const mergeBestPriceWithOrders = (newOrders, liveData) => _.map(newOrders, (newOrder) => {
  const { trading_symbol: tradingSymbol } = newOrder;
  const { marketData } = liveData;
  const tradeType = getSymbolType(tradingSymbol);
  const symbol = tradingSymbol.substring(0, tradingSymbol.search(/\d/));
  const instrumentType = getInstrumentType(tradeType, symbol);
  const marketDataType = instrumentType === 'EQUITY'
    ? tradingSymbol : getMarketDataType(instrumentType, symbol, tradingSymbol);

  if (instrumentType === 'EQUITY') {
    return ({
      ...newOrder,
      bbPrice: _.get(marketData, [instrumentType, marketDataType, 'bbPrice'], 0),
      bsPrice: _.get(marketData, [instrumentType, marketDataType, 'bsPrice'], 0)
    });
  }

  return ({
    ...newOrder,
    bbPrice: _.get(marketData, [instrumentType, symbol, marketDataType, 'bbPrice'], 0),
    bsPrice: _.get(marketData, [instrumentType, symbol, marketDataType, 'bsPrice'], 0)
  });
});
