import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import CopyTextButton from 'v2/common/components/CopyTextButton/index';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const Fyers = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              To start click on this link
              <b>
                <a
                  href="https://myapi.fyers.in/dashboard"
                  target="blank"
                  title="Fyers API Login"
                >
                  - https://myapi.fyers.in/dashboard
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>Create App</b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Fill the below details
            </p>

            <img
              src="/images/broker_setup_guide/fyers/fyers_app.png"
              alt="Quantman App details"
            />

            <div className="stepper-desc">
              <p>
                <b>App Name:</b>
                {oneIndentation}
                <CopyTextButton text="QUANTMAN" />
                <br />
                <b>Redirect URL:</b>
                {oneIndentation}
                <CopyTextButton text="https://quantman.in/auth/fyers/callback" />
                <br />
                <b>Description:</b>
                {oneIndentation}
                <CopyTextButton text="QUANTMAN" />
                <br />
                <b>Webhook URL:</b>
                {oneIndentation}
                <CopyTextButton text="https://www.quantman.in/fyers/postback" />
                <br />
                <b>Webhook Secret:</b>
                {oneIndentation}
                <CopyTextButton text="quantman" />
                <br />
                <b>Webhook Permissions: </b>
                {oneIndentation}
                Pending,Rejected,Cancelled,Traded
                <br />
                (Choose the mentioned option in select box. Hint: Last option)
                <br />
                <b>App Permissions:</b>
                {oneIndentation}
                Profile Details, Transaction Info, Order Placement
                <br />
                (Check the mentioned App permissions)
              </p>
            </div>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Please
              {oneIndentation}
              <b>Check Agree</b>
              {oneIndentation}
              and
              {oneIndentation}
              click on
              {oneIndentation}
              <b>Create App</b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <img src="/images/broker_setup_guide/fyers/fyers_app_1.png" alt="Copy and paste api details" />
            <p className="stepper-desc">
              Now, you can Copy and paste api details in appropriate fields below.
            </p>
          </div>
        </li>
      </ol>
    </div>
  </>
);

Fyers.propTypes = propTypes;
Fyers.defaultProps = defaultProps;

export default Fyers;
