import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'chandelierExit',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'chandelierExit',
    name: 'chandelierExit',
    config: {
      atrPeriod: 22,
      candleInterval: '3 minutes',
      atrMultiplier: 3.0,
      useClosePrice: true,
      valuePaths: [
        'item0.equity'
      ]
    }
  },
  inputSchema: [
    {
      name: 'useClosePrice',
      type: pipeInputSchemaTypes.select,
      label: 'Use Close Price',
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false }
      ]
    },
  ],
  searchTerms: [
    'chandelierExit'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      { name: 'buySignal', type: schemaTypes.boolean },
      { name: 'sellSignal', type: schemaTypes.boolean },
      { name: 'longStop', type: schemaTypes.number },
      { name: 'shortStop', type: schemaTypes.number },
    ];

    return _.map(properties, ({ name: property, type: propertyType }) => {
      return {
        name: `${name}.${property}`,
        type: propertyType,
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };
    });
  },
  isEnabled: true
};
export default configuration;
