import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const isBasketEnabled = _.get(window, 'SA_FEATURE_FLAGS.isBasketEnabled', false);

const configuration = {
  type: 'basket',
  defaults: {
    type: 'basket',
    name: 'basket',
    config: {
      legs: [],
      when: [
        {
          leftOperand: '',
          operator: '',
          rightOperand: '',
          conditionOperator: 'and'
        }
      ],
    }
  },
  inputSchema: [
    {
      name: 'legs',
      type: pipeInputSchemaTypes.intents,
      label: 'Legs',
    },
    {
      name: 'when',
      type: pipeInputSchemaTypes.signal,
      label: 'When',
    }
  ],
  searchTerms: [
    'basket',
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipeConfig) => {
    const { name, type } = pipeConfig;

    const properties = [
      'close',
      'open',
      'high',
      'low'
    ];

    return [
      {
        name: `${type}.${name}.combinedPremium`,
        type: schemaTypes.number,
        tagConfig: { tag: 'Combined Premium', color: '#FFA500' },
        indicatorType: type,
        category: 'indicator',
        label: name,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.pipeValuePath,
        ],
        priority: 70,
        schemaName: name
      },
      {
        name: `${type}.${name}.openInterest`,
        type: schemaTypes.number,
        tagConfig: { tag: 'Open Interest', color: '#FFA500' },
        indicatorType: type,
        category: 'indicator',
        label: `${name}`,
        shouldShowConfigs: [
          // schemaShouldShowConfigTypes.pipeValuePath,
        ],
        priority: 70,
        schemaName: name
      },
      ..._.map(properties, (property) => {
        return {
          name: `${name}.combinedPremium.${property}`,
          type: schemaTypes.number,
          tagConfig: { tag: 'Combined Premium', color: '#FFA500' },
          indicatorType: type,
          category: 'indicator',
          label: `${name} ${property}`,
          shouldShowConfigs: [
            schemaShouldShowConfigTypes.entryLeftOperand,
            schemaShouldShowConfigTypes.entryRightOperand,
            schemaShouldShowConfigTypes.exitLeftOperand,
            schemaShouldShowConfigTypes.exitRightOperand,
            schemaShouldShowConfigTypes.adjustmentLeftOperand,
            schemaShouldShowConfigTypes.adjustmentRightOperand,
          ],
          priority: 70,
          schemaName: name
        };
      })
    ];
  },
  isEnabled: isBasketEnabled
};
export default configuration;
