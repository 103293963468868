import moment from 'moment';
import {
  getEmptyTransactionConfig
} from 'modules/TransactionsBuilder/configs';

export const WEEKLY_OPTION_AVAILABLE_FROM = moment.utc('11-03-2019', 'DD-MM-YYYY');

export const additionalLeftOperandsConfig = () => ({
  entrySignalConfigs: [
    { name: 'dayOfWeek', label: 'Day Of Week' },
    { name: 'timeOfDay', label: 'Time Of Day' },
    { name: 'daysToExpire', label: 'Days To Expire' }
  ],
  exitSignalConfigs: [
    { name: 'timeOfDay', label: 'Time Of Day' },
    { name: 'numberOfMinutesFromEntry', label: 'Number Of Minutes From Entry ' }
  ],
  stopLoss: { name: 'stopLoss', label: 'Stop Loss Percentage' },
  stopGain: { name: 'stopGain', label: 'Stop Gain Percentage' },
});

export const adminOnlyLeftOperandsConfig = () => ({
  entrySignalConfigs: [
    { name: 'externalEntrySignal', label: 'External Entry Signal' }
  ],
  exitSignalConfigs: [
    { name: 'externalExitSignal', label: 'External Exit Signal' },
  ]
});

export const signalConditionOptions = () => ({
  dayOfWeek: {
    rightOperandOptions: [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ],
    operatorOptions: ['contains']
  },
  timeOfDay: {
    operatorOptions: [
      'equalTo',
      'isAbove',
      'isBelow',
    ]
  },
  daysToExpire: {
    rightOperandOptions: _.map(_.range(0, 31), (day) => day),
    operatorOptions: [
      'equalTo',
      'isBelow',
    ]
  },
  numberOfMinutesFromEntry: {
    rightOperandOptions: _.map(_.range(0, 61), (minute) => minute),
    operatorOptions: [
      'equalTo',
      'isAbove',
      'isBelow',
    ]
  },
  trailingStopLoss: {
    rightOperandOptions: [],
    operatorOptions: [
      'isAbove',
      'isBelow',
    ]
  },
  externalEntrySignal: {
    rightOperandOptions: [1],
    operatorOptions: [
      'equalTo',
    ]
  },
  externalExitSignal: {
    rightOperandOptions: [1],
    operatorOptions: [
      'equalTo',
    ]
  },
  stopGain: {
    rightOperandOptions: [],
    operatorOptions: [
      'equalOrAbove'
    ]
  },
  stopLoss: {
    rightOperandOptions: [],
    operatorOptions: [
      'equalOrAbove'
    ]
  },
});

export const defaultSignalConfigs = () => ({
  dayOfWeek: {
    conditionOperator: undefined,
    operator: 'equalTo',
    rightOperand: ''
  },
  timeOfDay: {
    conditionOperator: undefined,
    operator: 'equalTo',
    rightOperand: ''
  }
});

export const CONDITION_OPERATORS = ['and', 'or'];

export const getEmptySignalConfig = () => {
  return [
    {
      conditionOperator: '',
      leftOperand: '',
      operator: '',
      rightOperand: '',
    },
  ];
};

export const COMPARISION_TYPE_OPERATORS = [
  'equalTo',
  'isAbove',
  'isBelow',
  'crossesAbove',
  'crossesBelow'
];

export const SIGNAL_CONFIG = {
  ENTRY: 'entrySignalConfigs',
  EXIT: 'exitSignalConfigs',
  TRANSACTION: 'transactionConfigs',
  ADJUSTMENT_SIGNAL_CONFIGS: 'adjustmentSignalConfigs',
  ADJUSTMENT_ACTION_CONFIGS: 'adjustmentActionConfigs'
};

export const advancedFeatureConfigs = () => ({
  isMoveToCost: false,
  isReEntry: false,
  isReExecute: false,
  isTrailingStopLoss: true,
  isWaitAndTrade: false
});

export const getEmptyOrderConfig = (maxInstrumentsInGroup) => {
  return {
    transactionConfigs: _.times(maxInstrumentsInGroup, () => getEmptyTransactionConfig()),
    entrySignalConfigs: [],
    exitSignalConfigs: [],
    adjustmentSignalConfigs: [],
    adjustmentActionConfigs: [],
    advancedFeatureConfigs: advancedFeatureConfigs()
  };
};
