import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  type: PropTypes.string
};
const defaultProps = {
  type: ''
};

const TransactionType = ({ type }) => {
  const transactionTypeClassName = classNames('entry-exit-type', {
    buy: type === 'buy',
    sell: type === 'sell'
  });

  return (
    <div className={transactionTypeClassName} id={type}>{type}</div>
  );
};

TransactionType.propTypes = propTypes;
TransactionType.defaultProps = defaultProps;

export default TransactionType;
