import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  optionType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const OPTION_TYPES = [
  { label: 'Traded Price', value: 'traded_price' },
  { label: 'Signal Price', value: 'signal_price' }
];

const PriceSelector = ({ onChange, optionType: propsOptionType }) => {
  const [optionTypeBtnName] = useState(_.uniqueId('optionSlOrderBasedOnPrice-'));
  const optionTypeInputId = _.uniqueId('optionTypeSelector');

  const optionTypeStyle = classNames(
    'option-type',
    'align-items-center',
    'gap-10',
    'mt-1',
    'd-flex',
    'selector-radio-btn'
  );

  const renderRadioBtn = (optionType) => {
    return (
      <div className="form-check" key={optionType.value}>
        <input
          className="form-check-input"
          type="radio"
          name={optionTypeBtnName}
          id={`${optionTypeInputId}-${optionType.value}`}
          value={optionType.value}
          tabIndex="-100"
          checked={optionType.value === propsOptionType}
          onChange={() => onChange(optionType.value)}
        />
        <label className="form-check-label" htmlFor={`${optionTypeInputId}-${optionType.value}`}>
          {_.startCase(optionType.label)}
        </label>
      </div>
    );
  };

  const optionTypeRadioBtns = _.map(OPTION_TYPES, renderRadioBtn);

  return (
    <div className={optionTypeStyle}>
      {optionTypeRadioBtns}
    </div>
  );
};

PriceSelector.propTypes = propTypes;
export default PriceSelector;
