import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ModalComponent from 'v2/common/components/ModalComponent';
import { SL_TRAIL_TYPES } from 'v2/common/constants/index';
import TrailingFields from './TrailingFields';
import AddTrailing from './AddTrailing';
import { getTrailingStopLossValidationErrors } from './errorConfig';

const propTypes = {
  onUpdateTrailingConfigs: PropTypes.func.isRequired,
  trailingConfigs: PropTypes.shape({
    type: PropTypes.string,
    adjustValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    trailingValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bookProfitOn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bookProfit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  isStopLossPresent: PropTypes.bool.isRequired,
  stopLossType: PropTypes.string.isRequired,
  addTrailingLabel: PropTypes.string,
};

const defaultProps = {
  addTrailingLabel: 'Add'
};

const TrailingStopLoss = ({
  addTrailingLabel,
  onUpdateTrailingConfigs,
  trailingConfigs,
  isStopLossPresent,
  stopLossType,
}) => {
  const {
    adjustValue, trailingValue, bookProfitOn, bookProfit, type: trailType
  } = trailingConfigs;
  const methods = useForm();

  const [isShowTrailingStopLossModal, setIsShowTrailingStopLossModal] = useState(false);
  const [isShowHoverMessage, setIsShowHoverMessage] = useState(false);
  const [trailingStopLossConfig, setTrailingStopLossConfig] = useState(trailingConfigs);

  const [errors, setErrors] = useState([]);

  const onSubmit = () => {
    const errorConfig = getTrailingStopLossValidationErrors(trailingStopLossConfig);
    if (_.isEmpty(errorConfig)) {
      if (trailingStopLossConfig.type === SL_TRAIL_TYPES.Linear) {
        delete trailingStopLossConfig.bookProfitOn;
        delete trailingStopLossConfig.bookProfit;
      }
      onUpdateTrailingConfigs(trailingStopLossConfig);
      setIsShowTrailingStopLossModal(false);
      setErrors([]);
    } else {
      setErrors(errorConfig);
    }
  };

  const renderTrailingStopLossValues = () => {
    return (
      <div className="tsl-result " onClick={() => { setIsShowTrailingStopLossModal(true); }}>
        {trailType === SL_TRAIL_TYPES.BookAndLinear && (
          <>
            <div>
              <span className="text-muted">P1 : </span>
              <span>{bookProfitOn}</span>
            </div>
            <div className="ml-1">
              <span className="text-muted">P2: </span>
              <span>{bookProfit}</span>
            </div>
          </>
        )}
        <div className="ml-1">
          <span className="text-muted">X : </span>
          <span>{trailingValue}</span>
        </div>
        <div className="ml-1">
          <span className="text-muted">Y : </span>
          <span>{adjustValue}</span>
        </div>
        <span className="material-icons-outlined tx-15">edit</span>
      </div>
    );
  };

  useEffect(() => {
    if (isShowHoverMessage) { setTimeout(() => { setIsShowHoverMessage(false); }, 5000); }
  }, [isShowHoverMessage]);

  const renderAddButton = () => {
    return (
      <AddTrailing
        label={addTrailingLabel}
        isStopLossPresent={isStopLossPresent}
        onShowTrailingStopLoss={setIsShowTrailingStopLossModal}
      />
    );
  };

  const renderTrailingStopLossView = () => {
    return (
      <div className="trailing-stop-loss-component">
        {(trailingValue && adjustValue)
          ? renderTrailingStopLossValues() : renderAddButton()}
      </div>
    );
  };

  return (
    <>
      {renderTrailingStopLossView()}
      <ModalComponent
        onSave={methods.handleSubmit(() => onSubmit())}
        shouldShow={isShowTrailingStopLossModal}
        onClose={() => setIsShowTrailingStopLossModal(false)}
        title="Trailing Stop Loss"
        size="md"
        okBtnTitle="Submit"
        className="trailing-stop-modal"
      >
        <>
          <TrailingFields
            trailingStopLossConfig={trailingStopLossConfig}
            setTrailingStopLossConfig={setTrailingStopLossConfig}
            stopLossType={stopLossType}
          />

          {!_.isEmpty(errors) && (
            <div className="invalid-feedback d-block">
              {_.map(errors, (error, idx) => {
                return <div key={idx}>{error}</div>;
              })}
            </div>
          )}
        </>
      </ModalComponent>
    </>
  );
};

TrailingStopLoss.propTypes = propTypes;
TrailingStopLoss.defaultProps = defaultProps;

export default TrailingStopLoss;
