import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import fetchAddons from 'common/api/addons';
import ModalComponent from 'common/components/ModalComponent';
import { fetchCoupon } from 'common/api/coupons';
import fetchPlans from 'common/api/plans';
import { paymentInitialize } from 'common/razorpay/paymentInitialize';
import { cyclicFilter, getCouponConfig, initCurrentNetPrice } from 'ui/Plans/helper';
import PurchaseSet from './PurchaseSet';
import Header from './Header';
import QuotaConfirmation from './QuotaConfirmation';
import StrategyConfirmation from './StrategyConfirmation';
import BillingCycleFilter from './BillingCycleFilter';

const propTypes = {
  callbackFunc: PropTypes.func.isRequired,
  isShowPlan: PropTypes.bool,
  backtestCredits: PropTypes.number,
  strategy: PropTypes.shape({ id: PropTypes.number })
};

const defaultProps = { isShowPlan: true, backtestCredits: 0, strategy: {} };

const QuotaPurchase = ({
  callbackFunc, isShowPlan, backtestCredits, strategy
}) => {
  const [addons, setaddons] = useState([]);
  const [planCycleType, setPlanCycleType] = useState('yearly');
  const [planGroups, setPlanGroups] = useState({});
  const [isShowModal, setIsShowModal] = useState(true);
  const [couponCode, setCouponCode] = useState('');
  const [isApplied, setIsApplied] = useState(false);
  const [appliedCouponConfig, setAppliedCouponConfig] = useState({});
  const [message, setMessage] = useState('');
  const [selectedPurchase, setSelectedPurchase] = useState({});
  const sendCouponCode = _.get(appliedCouponConfig, 'code', '');
  const currentCyclicPeriodPlans = _.flattenDeep(_.map(planGroups, (planGroup) => {
    return _.filter(planGroup, (plan) => cyclicFilter(planCycleType, plan));
  }));
  const [step, setStep] = useState(_.isEmpty(strategy) ? 2 : 1);
  const [creditBalance, setCreditBalance] = useState(_.get(window, 'creditBalance', 0));
  const [isCreditApplied, setIsCreditApplied] = useState(true);

  const purchaseList = isShowPlan ? currentCyclicPeriodPlans : addons;
  const oneIndentation = ' ';

  useEffect(() => {
    fetchPlans()
      .then((results) => { setPlanGroups(_.groupBy(initCurrentNetPrice(results), 'plan_name')); }); // Plans
    fetchAddons()
      .then((results) => {
        const filteredResults = results.filter((result) => result.live_run_limit <= 0);
        return filteredResults;
      })
      .then((filteredResults) => {
        setaddons(initCurrentNetPrice(filteredResults));
      });
  }, []);

  const onUpdateCouponPricesInPayments = (config) => {
    const {
      alteredCouponCode, alteredIsApplied, alteredAddons, alteredPlanGroups
    } = getCouponConfig(config, addons, planGroups);
    setCouponCode(alteredCouponCode);
    setIsApplied(alteredIsApplied);
    setaddons(alteredAddons);
    setAppliedCouponConfig(config);
    setPlanGroups(alteredPlanGroups);
  };

  useEffect(() => {
    if (!_.isEmpty(selectedPurchase)) {
      const updatedSelectedPurchase = _.find(purchaseList, ({ id }) => id === selectedPurchase.id);
      if (updatedSelectedPurchase) setSelectedPurchase(updatedSelectedPurchase);
    }
  }, [addons, planGroups]);

  const onCouponUpdate = () => {
    if (!isApplied) {
      fetchCoupon({ code: couponCode, preapplied: false })
        .then((result) => {
          const { status, coupon } = result;
          if (status) {
            setMessage(`${coupon.discount_percentage}% discount applied`);
            setAppliedCouponConfig(coupon);
            return onUpdateCouponPricesInPayments(coupon);
          }
          return setMessage('Invalid Coupon');
        });
    }

    setMessage('');
    onUpdateCouponPricesInPayments({});
    return null;
  };

  const immediateCallBackFunc = () => {
    callbackFunc();
    setIsShowModal(!isShowModal);
  };

  const okPay = () => {
    const purchaseTypeName = isShowPlan ? 'plan_id' : 'addon_id';
    const purchaseType = isShowPlan ? 'payment' : 'addon';
    const { id } = selectedPurchase;
    const isStrategyPresent = !_.isEmpty(strategy);
    let args = {
      [purchaseTypeName]: id,
      purchase_type: purchaseType,
      coupon_code: sendCouponCode,
      credits_applied: isCreditApplied
    };

    if (isStrategyPresent) { args = { ...args, strategy_id: strategy.id, is_combine_payment: true }; }
    paymentInitialize(args, immediateCallBackFunc);
  };

  if (!isShowModal) return <></>;
  const isStepOne = step === 1;
  const isStepTwo = step === 2;

  const okBtnTitle = () => {
    if (!_.isEmpty(strategy) && isStepTwo) return 'Purchase the strategy and plan';
    return 'Purchase and Deploy the Strategy';
  };

  let modalProps = {
    size: 'sm',
    onClose: () => setIsShowModal(!isShowModal)
  };
  if (!isStepOne) {
    modalProps = {
      ...modalProps,
      size: 'lg',
      onSave: okPay,
      okBtnTitle: okBtnTitle(),
      isOkBtnDisabled: _.isEmpty(selectedPurchase),
      onCancel: () => setIsShowModal(!isShowModal),
      cancelBtnTitle: 'Cancel'
    };
  }

  const renderModalComponents = () => {
    if (isStepOne) return <StrategyConfirmation strategy={strategy} onContinue={() => setStep(2)} />;

    const renderBacktestCreditDetails = (
      <div className="alert alert-info credit-details">
        <i className="font-weight-bold icons-history" />
        {oneIndentation}
        Credits required :
        {oneIndentation}
        {backtestCredits}
      </div>
    );

    return (
      <>
        {backtestCredits ? renderBacktestCreditDetails : null}
        <BillingCycleFilter
          planCycleType={planCycleType}
          handlePlanCycleType={setPlanCycleType}
          isShowPlan={isShowPlan}
          isStepOne={isStepOne}
        />
        <div className="row">
          <div className="plans-details col-12 col-md-5">
            {/* {isShowPlan ? 'Plan Required For ' : ''} */}
            <PurchaseSet
              purchaseList={purchaseList}
              isShowPlan={isShowPlan}
              selectedPurchase={selectedPurchase}
              couponCode={sendCouponCode}
              handleSelectedPurchase={(newPurchase) => setSelectedPurchase(newPurchase)}
            />
          </div>
          <QuotaConfirmation
            selectedPurchase={selectedPurchase}
            isShowPlan={isShowPlan}
            strategy={strategy}
            couponCode={couponCode}
            onChangeCouponCode={setCouponCode}
            isApplied={isApplied}
            onCouponUpdate={onCouponUpdate}
            message={message}
            creditBalance={creditBalance}
            isCreditApplied={isCreditApplied}
            onUpdateCredits={
              (newCreditBalance, isCreditUsed) => {
                setCreditBalance(newCreditBalance);
                setIsCreditApplied(isCreditUsed);
              }
            }
          />
        </div>
      </>
    );
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="quota-verification">
        <Header
          planCycleType={planCycleType}
          handlePlanCycleType={setPlanCycleType}
          isShowPlan={isShowPlan}
          isStepOne={isStepOne}
          backtestCredits={backtestCredits}
          strategy={strategy}
        />
        {renderModalComponents()}
      </div>
    </ModalComponent>
  );
};

QuotaPurchase.propTypes = propTypes;
QuotaPurchase.defaultProps = defaultProps;

export default QuotaPurchase;
