export const setSessionStorage = (key, value) => {
  let sessionStorage = null;
  try { sessionStorage = _.get(window, 'sessionStorage', null); } catch (e) { sessionStorage = null; }

  if (!sessionStorage) return;

  sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key) => {
  let sessionStorage = null;
  try { sessionStorage = _.get(window, 'sessionStorage', null); } catch (e) { sessionStorage = null; }

  if (!sessionStorage) return '';

  return sessionStorage.getItem(`${key}`);
};
