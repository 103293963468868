import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SEGMENT_CONFIG } from 'common/constants/index';

import { parseTradingSymbol } from 'v2/common/utils/tradingSymbol';
import { INSTAOPTIONS_MODE } from 'v2/common/constants/index';
import HoverMessage from 'common/components/HoverMessage';
import LinkToIO from '../LinkToIO/index';

const propTypes = {
  tradingSymbol: PropTypes.string.isRequired,
  time: PropTypes.string,
  additionalClassNames: PropTypes.string,
};
const defaultProps = {
  time: null,
  additionalClassNames: '',
};

const LinkToIOOptionChain = ({ tradingSymbol, time, additionalClassNames }) => {
  if (!time) {
    return null;
  }
  const { instrument, expiryDate, segment } = parseTradingSymbol(tradingSymbol);

  if (segment !== SEGMENT_CONFIG.option) {
    return null;
  }

  const ioHistoricalTime = time.replace(/:\d\d$/, '').replace('T', ' ');
  const queryParams = {
    instrument,
    expiryDate: moment(expiryDate, 'DDMMMYY').format('YYYY-MM-DD'),
    userRunType: INSTAOPTIONS_MODE.historical,
    historicalDateAndTime: ioHistoricalTime,
    currentTab: 'option_chain',
  };

  return (
    <LinkToIO
      page="instruments"
      queryParams={queryParams}
      additionalClassNames={additionalClassNames}
    >
      <HoverMessage title="Option Chain" message="View OptionChain at this time in InstaOptions">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="19px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0091ae"
        >
          <path d="m296-80-56-56 276-277 140 140 207-207 57 57-264
          263-140-140L296-80Zm-136-40q-33 0-56.5-23.5T80-200v-560q0-33
          23.5-56.5T160-840h560q33 0 56.5 23.5T800-760v168H160v472Zm0-552h560v-88H160v88Zm0
          0v-88 88Z"
          />
        </svg>
      </HoverMessage>
    </LinkToIO>
  );
};

LinkToIOOptionChain.propTypes = propTypes;
LinkToIOOptionChain.defaultProps = defaultProps;

export default LinkToIOOptionChain;
