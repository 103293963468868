import React, { useState } from 'react';
import Modal from 'common/components/ModalComponent';
import PropTypes from 'prop-types';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

import { signOut, updateEmailId } from 'common/api/user';
import EmailIdForm from './EmailIdForm';

const propTypes = { redirectPath: PropTypes.string.isRequired };
const defaultProps = {};

const MandatoryEmailId = ({ redirectPath }) => {
  const [emailId, setEmailId] = useState('');

  const onEmailIdSubmit = () => {
    updateEmailId(emailId)
      .then((res) => {
        if (res.status) {
          reactToastify('Email Id updated successfully', TOASTIFY_TYPES.SUCCESS);
          setTimeout(() => {
            window.location.href = redirectPath;
          }, 1000);
        } else { reactToastify(res.message || 'Email Id updation failed', TOASTIFY_TYPES.ERROR); }
      });
  };

  return (
    <Modal
      size="md"
      onClose={signOut}
      className="email-verification"
    >
      <EmailIdForm
        emailId={emailId}
        onEmailIdChange={setEmailId}
        onEmailIdSubmit={onEmailIdSubmit}
      />
    </Modal>
  );
};

MandatoryEmailId.propTypes = propTypes;
MandatoryEmailId.defaultProps = defaultProps;

export default MandatoryEmailId;
