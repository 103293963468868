import React from 'react';
import PropTypes from 'prop-types';
import RunGridViewBody from './RunLGridViewBody';

const propTypes = {
  runs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDeleteRun: PropTypes.func.isRequired,
  onUpdateRun: PropTypes.func.isRequired,
  onSelectRuns: PropTypes.func.isRequired,
  isFilteredRuns: PropTypes.bool.isRequired
};
const defaultProps = {};

const RunGridView = ({
  runs, onDeleteRun, onUpdateRun, onSelectRuns, isFilteredRuns
}) => {
  const renderRun = (run, idx) => {
    return (
      <RunGridViewBody
        run={run}
        key={idx}
        onDeleteRun={onDeleteRun}
        onUpdateRun={onUpdateRun}
        onSelectRuns={onSelectRuns}
        isFilteredRuns={isFilteredRuns}
      />
    );
  };

  return (
    <div className="grid-view row flex-wrap">
      {_.map(runs, renderRun)}
    </div>
  );
};

RunGridView.propTypes = propTypes;
RunGridView.defaultProps = defaultProps;

export default RunGridView;
