import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import {
  getStrikeBasedOn, getEligibleIndicatorForStrike, STRIKE_KEYS, getSegmentStrikeBasedOn
} from 'modules/TransactionsBuilder/configs';
import { outputSchemaPropTypes } from 'common/propTypes';
import HoverMessage from 'common/components/HoverMessage';

const propTypes = {
  strikeBasedOn: PropTypes.string.isRequired,
  onStrikeBasedOnChange: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  shouldHideLabel: PropTypes.bool,
  segment: PropTypes.string.isRequired,
  customPropConfigs: PropTypes.arrayOf(PropTypes.shape({}))
};
const defaultProps = {
  shouldHideLabel: false,
  customPropConfigs: []
};
const DISAPPEAR_WAINING_TIME = 5000;

const StrikeBasedOnSelector = ({
  strikeBasedOn, onStrikeBasedOnChange, outputSchema, shouldHideLabel, segment, customPropConfigs
}) => {
  const { isAdvancedStrikeSelectionEnabled, isAdmin } = window;
  if (!isAdvancedStrikeSelectionEnabled) return null;

  const shouldDisableIndicatorValues = _.isEmpty(getEligibleIndicatorForStrike(outputSchema));
  const {
    indicatorValue: INDICATOR_VALUE
  } = STRIKE_KEYS;
  const [shouldShowWarningMessage, setshouldShowWarningMessage] = useState(false);
  const [hoverMessageConfig, setHoverMessageConfig] = useState({ title: '', message: '' });
  const { title, message } = hoverMessageConfig;

  const filteredStrikeBasedOn = _.filter(getStrikeBasedOn(),
    (strike) => (isAdmin || strike.shouldShow));

  const segmentBasedStrikeBasedOn = isAdmin ? filteredStrikeBasedOn
    : getSegmentStrikeBasedOn(segment, filteredStrikeBasedOn);
  // premium and stike index released.. for admin to show all strikes

  const onStrikeBasedOnUpdate = (event) => {
    const newStrikeBasedOn = event.target.value;

    if ((shouldDisableIndicatorValues && newStrikeBasedOn === INDICATOR_VALUE)) {
      if (isAdmin && !_.isEmpty(customPropConfigs)) return onStrikeBasedOnChange(newStrikeBasedOn);
      // have to make better

      setshouldShowWarningMessage(true);
      setHoverMessageConfig({
        title: 'indicator value not available',
        message: 'To use indicator value please add Pivot point or Today candle or Super trend Indicator'
      });
      setTimeout(() => setshouldShowWarningMessage(false), DISAPPEAR_WAINING_TIME);
      return null;
    }

    return onStrikeBasedOnChange(newStrikeBasedOn);
  };

  const strikeBasedOnOptions = _.map(segmentBasedStrikeBasedOn, ({ value, displayName }) => (
    <option
      key={displayName}
      value={value}
    >
      {_.startCase(displayName)}
    </option>
  ));

  return (
    <div className="strike-base">
      {!shouldHideLabel && <label className="col-form-label col-form-label-sm">Strike Based On</label>}
      <Form.Control
        as="select"
        className="custom-select custom-select-sm track"
        name="strikeBasedOn"
        value={strikeBasedOn}
        data-track-category="Strike Based On"
        data-track-action={strikeBasedOn}
        data-track-label="Strike based On"
        onChange={onStrikeBasedOnUpdate}
      >
        {strikeBasedOnOptions}
      </Form.Control>
      <HoverMessage
        isOpen={shouldShowWarningMessage}
        title={title}
        message={message}
      >
        <span />
      </HoverMessage>
      <div className="option-quantity" />
    </div>
  );
};

StrikeBasedOnSelector.propTypes = propTypes;
StrikeBasedOnSelector.defaultProps = defaultProps;

export default StrikeBasedOnSelector;
