import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'externalSignal',
  defaults: {
    type: 'externalSignal',
    name: 'externalSignal',
    config: {
      vendor: 'TradingView',
      externalSignalId: '',
      token: '',
    },
  },
  inputSchema: [
    {
      name: 'vendor',
      type: pipeInputSchemaTypes.vendor,
      label: 'Vendor',
    },
    {
      name: 'externalSignalId',
      type: pipeInputSchemaTypes.externalSignalId,
      label: 'External Signal ID',
    },
    {
      name: 'token',
      isDisplay: false,
    }
  ],
  searchTerms: [
    'externalSignal',
    'tradingview',
    'chartink'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    return [
      {
        name,
        type: schemaTypes.boolean,
        indicatorType: type,
        category: 'indicator',
        label: name,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      }
    ];
  },
  isEnabled: true,
};

export default configuration;
