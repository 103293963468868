import { STOPS_VALUE_TYPES } from 'common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';

const configuration = {
  type: 'transactionStopLoss',
  defaults: {
    type: 'transactionStopLoss',
    name: 'transactionStopLoss',
    config: {
      stopLoss: {
        type: STOPS_VALUE_TYPES.Percent,
        value: 0,
        trail: {
          type: 'Linear',
          adjustValue: 0,
          trailingValue: 0
        }
      }
    }
  },
  searchTerms: [
    'transactionStopLoss'
  ],
  configFormOptions: {
    valuePathEditable: false,
    configFieldOptions: {},
    valuePathSupportedSegments: []
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    return [
      {
        indicatorType: type,
        name,
        type: schemaTypes.boolean,
        category: 'indicator',
        label: name,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 69,
        schemaName: name
      }
    ];
  },
  isEnabled: true
};
export default configuration;
