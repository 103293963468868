import PropTypes from 'prop-types';

export const pairedPropTypes = PropTypes.shape({
  entryType: PropTypes.string,
  exitType: PropTypes.string,
  entryTime: PropTypes.string,
  exitTime: PropTypes.string,
  orderProfit: PropTypes.number,
  quantity: PropTypes.number,
  entryPrice: PropTypes.number,
  exitPrice: PropTypes.number,
  hasIlliquid: PropTypes.bool,
  tradingSymbol: PropTypes.string,
  isManualExitShow: PropTypes.bool,
  entryOrder: PropTypes.shape({}),
  exitOrder: PropTypes.shape({})
});
