import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setProductType } from 'modules/QuantBuilder/actions';
import ProductTypeSelector from 'common/components/ProductType';

const propTypes = {
  product: PropTypes.string.isRequired,
  dispatchSetProductType: PropTypes.func.isRequired,
  tradingType: PropTypes.string.isRequired
};
const defaultProps = {};

const ProductType = (props) => {
  const { product, dispatchSetProductType, tradingType } = props;

  return (
    <div className="quant-config-order-type row align-items-center">
      <div className="col-md-2">
        <label className="col-form-label col-form-label-sm">Product Type </label>
      </div>
      <div className="col-md-10">
        <ProductTypeSelector
          product={product}
          tradingType={tradingType}
          onChangeProduct={(newProduct) => dispatchSetProductType(newProduct)}
        />
      </div>
    </div>
  );
};

ProductType.propTypes = propTypes;
ProductType.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  product: _.get(state, 'quantConfig.product'),
  tradingType: _.get(state, 'quantConfig.tradingType')
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetProductType: (newProductType) => dispatch(setProductType(newProductType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductType);
