import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const Paytm = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              To start click on this link
              <b>
                <a
                  href="https://developer.paytmmoney.com/"
                  target="blank"
                  title="PaytmMoney Developer Login"
                >
                  - https://developer.paytmmoney.com/
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">Use PaytmMoney credentials to sign-In and go to My Apps</p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Click on
              {oneIndentation}
              <b>Create New App</b>
            </p>
            <img
              src="/images/broker_setup_guide/paytmmoney/paytmmoney_step_3.png"
              className="w-100"
              alt="Create New App"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>Proceed</b>
            </p>
            <img
              src="/images/broker_setup_guide/paytmmoney/paytmmoney_step_4.png"
              className="w-100"
              alt="Click proceed"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <p className="stepper-desc">
              Fill below details and Proceed.
            </p>

            <div className="stepper-desc">
              <p>
                <b>App Name:</b>
                {oneIndentation}
                Quantman
                <br />
                <b>Product Type:</b>
                {oneIndentation}
                Rule Based Trading Platform
                <br />
                <b>Redirect URL:</b>
                {oneIndentation}
                https://www.quantman.in/auth/paytm/callback
                <br />
                <b>Description:</b>
                {oneIndentation}
                QuantMan
              </p>
            </div>

            <img
              src="/images/broker_setup_guide/paytmmoney/paytmmoney_step_5.png"
              alt="Fill and proceed"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 6</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>Go to Homepage</b>
            </p>
            <img
              src="/images/broker_setup_guide/paytmmoney/paytmmoney_step_6.png"
              className="w-100"
              alt="Go to Homepage"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 7</h3>
            <p className="stepper-desc">
              Now, you can Copy and paste api details in appropriate fields below.
            </p>
            <img
              src="/images/broker_setup_guide/paytmmoney/paytmmoney_step_7.png"
              className="w-100"
              alt="API details"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <p className="stepper-desc">
              Source:
              {oneIndentation}
              <a
                href="https://www.paytmmoney.com/blog/how-to-create-api-key-and-secret/"
                target="blank"
                title="PaytmMoney source blog"
              >
                https://www.paytmmoney.com/blog/how-to-create-api-key-and-secret/
              </a>
            </p>
          </div>
        </li>
      </ol>
    </div>
  </>
);

Paytm.propTypes = propTypes;
Paytm.defaultProps = defaultProps;

export default Paytm;
