import moment from 'moment';
import { computePLAndHM } from './common';

const getMaxPlAndHM = (monthWiseOrders) => ({
  hit: _.max(_.map(monthWiseOrders, 'hit')),
  miss: _.max(_.map(monthWiseOrders, 'miss')),
  profit: _.max(_.map(monthWiseOrders, ({ profit, loss }) => loss + profit)),
  loss: _.min(_.map(monthWiseOrders, ({ profit, loss }) => loss + profit))
});

const heatMapData = (PLOrHMValue, maxValue, PLHM) => {
  const getExactRange = d3.scaleLinear()
    .domain([0, maxValue])
    .range([0, 100])
    .interpolate(d3.interpolateRound);

  const getRange = (value) => Math.round((getExactRange(value)) / 25) * 25;

  return {
    PLOrHMValue,
    color: `${PLHM}${Math.abs(getRange(PLOrHMValue))}`
  };
};

const getMonthWiseAnalyticsData = (monthWisePLAndHM) => {
  const maxValues = getMaxPlAndHM(monthWisePLAndHM);
  return _.reduce(monthWisePLAndHM, (monthWiseData, monthPLAndHM, yearAndMonth) => {
    const [year, month] = _.split(yearAndMonth, '.');
    const currentMonthProfitLossValue = monthPLAndHM.loss + monthPLAndHM.profit;

    const PLHMType = {
      hitMiss: monthPLAndHM.hit > monthPLAndHM.miss ? 'hit' : 'miss',
      profitLoss: currentMonthProfitLossValue > 0 ? 'profit' : 'loss'
    };

    _.each(['profitLoss', 'hitMiss'], (PLOrHM) => {
      const type = PLHMType[PLOrHM];
      const value = _.includes(['hit', 'miss'], type) ? monthPLAndHM[type] : currentMonthProfitLossValue;

      _.set(
        monthWiseData[PLOrHM],
        yearAndMonth,
        {
          year,
          month,
          ...heatMapData(value, maxValues[type], type)
        }
      );
    });

    return monthWiseData;
  }, { hitMiss: {}, profitLoss: {} });
};

export const getMonthWiseData = (orders) => {
  const monthWisePLAndHM = _.chain(orders)
    .groupBy((symbolOrders) => {
      const { order_time: orderTime } = _.find(
        symbolOrders.orders, (({ is_entry: isEntry }) => isEntry === true || isEntry === 'true')
      );
      return moment(orderTime).format('YYYY.MMMM');
    })
    .reduce((hash, symbolOrder, yearAndMonth) => {
      // eslint-disable-next-line no-param-reassign
      hash[yearAndMonth] = computePLAndHM(symbolOrder);

      return hash;
    }, {})
    .value();

  return getMonthWiseAnalyticsData(monthWisePLAndHM);
};
