// vendor import
import React from 'react';
import PropTypes from 'prop-types';

import { onRunForceExit, onStopQuant } from 'common/api/run';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { runPropTypes } from 'common/propTypes';
import ForceExitBtn from 'ui/run/RunShow/components/RunTitleAndActions/ForceExitBtn';
import StopRunbtn from 'ui/run/RunShow/components/RunTitleAndActions/StopRunbtn';

const propTypes = {
  liveRun: runPropTypes.isRequired,
  isRunning: PropTypes.bool.isRequired
};

const defaultProps = {};

const StrategyRunDetails = ({ liveRun, isRunning }) => {
  const onStopRun = () => {
    onStopQuant(liveRun.id, () => {
      reactToastify('Stopped Run Successfully', TOASTIFY_TYPES.SUCCESS);
      setTimeout(() => window.location.reload(), 1000);
    });
  };

  const onForceExit = () => {
    onRunForceExit(liveRun.id).then(() => {
      reactToastify('Position Square Off Completed successfully', TOASTIFY_TYPES.SUCCESS);
      setTimeout(() => window.location.reload(), 1000);
    }).catch(() => {
      reactToastify('Something went wrong, please try again', TOASTIFY_TYPES.ERROR);
    });
  };

  return (
    <div className="start-transaction">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h6 className="font-weight-bold">{isRunning ? 'Strategy Transaction' : 'Start Transaction'}</h6>
        <div className="d-flex align-items-center">
          <ForceExitBtn
            isLiveRun
            status={liveRun.status}
            onForceExit={onForceExit}
            isRunBelongsToUser
          />
          <div className="ml-1">
            <StopRunbtn
              isLiveRun
              status={liveRun.status}
              onStopRun={onStopRun}
              isRunBelongsToUser
            />
          </div>
        </div>
      </div>
      {isRunning && (
        <div className="alert alert-success alert-sm">
          <i className="material-icons-outlined mr-1 tx-18 align-top">info</i>
          {' '}
          Strategy/Transaction still running. Please check and stop it before starting a new transaction.
        </div>
      )}
    </div>
  );
};

StrategyRunDetails.propTypes = propTypes;
StrategyRunDetails.defaultProps = defaultProps;

export default StrategyRunDetails;
