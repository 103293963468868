import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  infoAvatar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  leftHeader: PropTypes.string,
  leftValue: PropTypes.element,
  rightHeader: PropTypes.string,
  rightValue: PropTypes.element,
};
const defaultProps = {
  infoAvatar: '',
  leftHeader: '',
  leftValue: null,
  rightHeader: '',
  rightValue: null,
};

const SmallInfoCard = ({
  leftHeader, leftValue, rightHeader, rightValue, infoAvatar
}) => (
  <div className="small-info-card">
    <div className="card info">
      <div className="info-wrapper">
        <div className="card-details d-flex align-items-center justify-content-between">
          <div className="info-avatar">
            {infoAvatar}
          </div>

          <div className="left-side">
            <div className="sub-head">{leftHeader}</div>
            <div className="main-head">{leftValue}</div>
          </div>
        </div>

        <div className="text-right">
          <div className="sub-head">{rightHeader}</div>
          <div className="main-head">{rightValue}</div>
        </div>
      </div>
    </div>
  </div>
);

SmallInfoCard.defaultProps = defaultProps;
SmallInfoCard.propTypes = propTypes;

export default SmallInfoCard;
