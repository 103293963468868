import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { runPropTypes } from 'common/propTypes';
import TransactionTable from 'modules/TransactionTable';
import TransactionsAnalytics from 'modules/TransactionsAnalytics';
import { RUN_TYPES } from 'common/constants/index';
import { RUN_UNFINISHED_STATUSES } from 'common/configs/runStatus';
import { OrderContext } from '../../OrderStore/index';

const propTypes = {
  run: runPropTypes.isRequired,
  tradingType: PropTypes.string,
  isAccordianOpen: PropTypes.bool
};
const defaultProps = { tradingType: '', isAccordianOpen: true };
// tradingType is mainly for dayOfWeek seperate render

const TransactionDetail = ({ run, tradingType, isAccordianOpen }) => {
  const { transactions, isLoading } = useContext(OrderContext);
  const { run_type: runType, status } = run;
  const oneIndentation = ' ';
  const isMarketPlaceSubscriberRun = _.get(run, 'is_strategy_subscriber', false);

  const isEmptyTransactions = _.isEmpty(transactions);
  const isInCompleteStatus = _.includes(RUN_UNFINISHED_STATUSES, status);

  const renderInCompleteContent = () => {
    return (
      <div className="status-wrapper">
        <div className="info-msg">
          <img src="../signal-start.svg" className="large" alt="Strategy is running" />
          <div>
            <h6>Strategy is running</h6>
            it has not taken any orders till now.
            <br />
            It will take new orders as when the strategy generates new signal.
          </div>
        </div>
      </div>
    );
  };

  const renderEmptyTransactionContents = () => {
    if (isLoading) return null;

    if (!isMarketPlaceSubscriberRun) {
      return (
        <div className="card">
          <div className="text-center card-body">
            <img className="no-data-found" src="/no-data.svg" alt="No Data Found" />
          </div>
        </div>
      );
    }

    if (isInCompleteStatus) return renderInCompleteContent();

    return (
      <div className="status-wrapper">
        <div className="info-msg">
          <img src="/signal-stop.svg" className="large" alt="Strategy is stopped" />
          <div>
            <h6>
              Your Strategy has been
              {oneIndentation}
              {status}
            </h6>
            .
            {oneIndentation}
            While it was running, no signals were generated from the strategy.
            {oneIndentation}
            <br />
            So no orders have been taken.
          </div>
        </div>
      </div>
    );
  };

  const renderTransactionView = () => {
    if (isEmptyTransactions) return renderEmptyTransactionContents();

    return (
      <div className="transaction-detail-card my-3 new">
        <div className="card mt-2"><TransactionTable run={run} isAccordianOpen={isAccordianOpen} /></div>
      </div>
    );
  };

  return (
    <>
      {runType === RUN_TYPES.historic && <TransactionsAnalytics tradingType={tradingType} />}
      {renderTransactionView()}
    </>
  );
};

TransactionDetail.defaultProps = defaultProps;
TransactionDetail.propTypes = propTypes;

export default TransactionDetail;
