import moment from 'moment';
import { INTENT_TYPES } from 'v2/common/constants/index';

const instrumentsWithStartedConfigs = {
  'NFO:SBILIFE': {
    startDate: '2020-05-04',
    expiryCycles: ['monthly', 'weekly'],
  },
  'NFO:AARTIIND': {
    startDate: '2021-01-01',
    expiryCycles: ['monthly', 'weekly'],
  },
  'NFO:BAJAJFINSV': {
    startDate: '2017-04-03',
    expiryCycles: ['monthly', 'weekly'],
  },
  'NFO:FINNIFTY': {
    startDate: '2022-04-01',
    expiryCycles: ['monthly', 'weekly'],
  },
  'NFO:MIDCPNIFTY': {
    startDate: '2023-07-01',
    expiryCycles: ['monthly', 'weekly'],
  },
  'NFO:NIFTY': {
    startDate: '2019-03-11',
    expiryCycles: ['weekly'],
  },
  'BFO:SENSEX': {
    startDate: '2023-08-01',
    expiryCycles: ['weekly'],
  },
  'BFO:BANKEX': {
    startDate: '2023-08-01',
    expiryCycles: ['weekly'],
  },
};

const isPresentExpiryCycle = (
  instrumentGroups, instrumentName, expiryCycle, cases, pipeConfigs
) => {
  let instrumentIndex = -1;
  _.each(instrumentGroups, (instrumentGroup, index) => {
    _.each(instrumentGroup, (instrument) => {
      if (instrument === instrumentName) {
        instrumentIndex = index;
      }
    });
  });

  const pipeIntents = _.chain(pipeConfigs)
    .map((pipeConfig) => _.get(pipeConfig, 'config.legs', []))
    .flatten()
    .value();

  return _.chain(cases)
    .map((caseConfig) => {
      const { entry: { intents }, adjusments } = caseConfig;
      return _.chain(adjusments).map('intents').concat(intents).concat(pipeIntents)
        .flatten()
        .value();
    })
    .flatten()
    .filter((intent) => intent?.type === INTENT_TYPES.OpenLeg)
    .filter(
      (intent) => intent?.instrument?.instrumentIndex === instrumentIndex
        && intent?.instrument?.expiry?.cycle === expiryCycle
    )
    .some()
    .value();
};

export const getValidationErrorInstrumenWithStartDate = (
  instrumentGroups, quantStartDate, cases, pipeConfigs
) => {
  const startDate = moment(quantStartDate).format('YYYY-MM-DD');
  const flattenInstrument = _.flatten(instrumentGroups);
  let instrumentValidationObj = { validDate: startDate, isValid: true };

  if (_.isEmpty(flattenInstrument)) return instrumentValidationObj;

  _.each(flattenInstrument, (instrumentName) => {
    if (instrumentsWithStartedConfigs[instrumentName]) {
      const {
        startDate: instrumentStartDate, expiryCycles
      } = instrumentsWithStartedConfigs[instrumentName];
      const isBeforeDate = moment(startDate).isBefore(instrumentStartDate);

      if (isBeforeDate && _.size(expiryCycles) === 2) {
        instrumentValidationObj = {
          isValid: false,
          message: `${instrumentName} available only from ${instrumentStartDate}`
        };
      }

      if (isBeforeDate && _.size(expiryCycles) === 1) {
        const isPresent = isPresentExpiryCycle(
          instrumentGroups, instrumentName, expiryCycles[0], cases, pipeConfigs
        );

        if (isPresent) {
          instrumentValidationObj = {
            isValid: false,
            message: `${instrumentName} ${expiryCycles[0]} available only from ${instrumentStartDate}`
          };
        }
      }
    }
  });

  return instrumentValidationObj;
};
