import classNames from 'classnames';
import React, { useState } from 'react';
import HeaderDetails from './HeaderDetails/index';
import OrderDetails from './OrderDetails/index';
import UserDetails from './UserDetails/index';

const OrderAndUserDetails = (props) => {
  const orders = _.get(props, 'orders', []);
  const currentUser = _.get(props, 'currentUser');
  const strategies = _.get(props, 'strategies', []);
  const userAndStrategyDetails = _.get(props, 'userAndStrategyDetails', [])
    .filter(({ user: { id } }) => id !== currentUser.id);
  const [activeTab, setActiveTab] = useState('userDetails');

  const tabs = [
    { label: 'User Details', value: 'userDetails' },
    { label: 'Recent Orders', value: 'orders' }
  ];

  const renderTab = ({ label, value }, idx) => {
    const liClassName = classNames('custom-toggle-sm btn btn-primary mr-3', {
      active: value === activeTab
    });

    return (
      <li className="nav-item" key={idx}>
        <a
          className={liClassName}
          id="home-tab"
          data-toggle="tab"
          onClick={() => setActiveTab(value)}
          href={`#${value}`}
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          {label}
        </a>
      </li>
    );
  };

  return (
    <div className="my-5 user-details-b2b">
      <HeaderDetails userAndStrategyDetails={userAndStrategyDetails} orders={orders} />
      <ul className="nav nav-tabs analytics-orders-tabs" id="myTab" role="tablist">
        {_.map(tabs, renderTab)}
      </ul>
      <div className="tab-content my-3" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id={activeTab}
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          {activeTab === 'userDetails'
            ? <UserDetails userAndStrategyDetails={userAndStrategyDetails} strategies={strategies} />
            : <OrderDetails orders={orders} />}
        </div>
      </div>
    </div>
  );
};

OrderAndUserDetails.propTypes = {};

export default OrderAndUserDetails;
