import React, { useState } from 'react';
import { BROKERS } from 'common/constants';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};
const defaultProps = {};

const RupeezyLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with Rupeezy API.
            <br />
            <br />
            <button
              type="button"
              className="btn btn-outline-primary broker"
              onClick={() => { setCurrentBroker(BROKERS.rupeezy.id); }}
            >
              Login via
              {oneIndentation}
              <b>Rupeezy</b>
            </button>
          </p>
        </div>
      </div>
      <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
    </div>
  );
};

RupeezyLogin.propTypes = propTypes;
RupeezyLogin.defaultProps = defaultProps;

export default RupeezyLogin;
