import qs from 'qs';
import axios from 'axios';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { getUrlParams } from 'common/utils/urlUtils';
import wrappedFetch from './base';

export const getRuns = ({ params }) => {
  const queryParams = { ...params };

  return wrappedFetch(`/runs.json?${qs.stringify(queryParams)}`)
    .then((response) => response.json());
};

export const createRun = (runParams) => {
  return axios({
    method: 'post',
    url: '/runs.json',
    data: JSON.stringify({ run: runParams }),
    headers: { 'Content-Type': 'application/json' }
  }).then((response) => {
    const { id } = response.data;

    const urlParams = getUrlParams();
    const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';
    if (isMinifiedPage) {
      window.location.href = `/runs/${id}?locale=${I18n.locale}&minifiedpage=true`;
    } else {
      window.location.href = `/runs/${id}?locale=${I18n.locale}`;
    }
  });
};

export const deleteRun = ({ runId, successCallBack }) => {
  const url = `/runs/${runId}.json`;
  const options = { method: 'DELETE', credentials: 'same-origin' };

  const urlParams = getUrlParams();
  const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';

  return wrappedFetch(url, options).then(() => {
    if (_.isFunction(successCallBack)) {
      successCallBack();
    } else {
      try {
        if (isMinifiedPage) window.location.href = `/strategies/?locale=${I18n.locale}&minifiedpage=true`;
        else if (window.history.back()) window.history.back();
        else window.location.pathname = '/folders';
      } catch (err) { window.location.pathname = '/folders'; }
    }
  }).catch((error) => {
    try {
      error.json().then((data) => {
        const errors = _.get(data, 'errors.error', []);
        const errorMessages = _.get(errors, '0', '');

        if (errorMessages) { reactToastify(errorMessages, TOASTIFY_TYPES.ERROR); }
      });
    } catch (expection) {
      // may be 500 or 405 error code...
      reactToastify('Something went wrong unable to delete Run', TOASTIFY_TYPES.ERROR);
    }
  });
};

export const deleteMultipleRuns = ({ runIds, successCallBack }) => {
  const url = '/runs/delete_multiple_runs';
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ run_ids: runIds }),
  };

  return wrappedFetch(url, options)
    .then((response) => response.json())
    .then((res) => {
      if (_.isFunction(successCallBack)) {
        successCallBack();
      } else {
        const deletedRunIds = _.get(res, 'deleted_run_ids', []);
        let message = 'Run deleted successfully';
        let messageType = TOASTIFY_TYPES.SUCCESS;

        if (deletedRunIds.length === 0) {
          message = 'Unable to delete runs';
          messageType = TOASTIFY_TYPES.ERROR;
        } else if (deletedRunIds.length !== runIds.length) {
          message = 'Runs partially deleted';
          messageType = TOASTIFY_TYPES.WARNING;
        }

        try {
          if (message) { reactToastify(message, messageType); }
          setTimeout(() => {
            if (window.history.back()) window.history.back();
            else window.location.pathname = '/folders';
          }, 2000);
        } catch (err) { console.log('err: ', err); }
      }
    }).catch((error) => {
      try {
        error.json().then((data) => {
          const errors = _.get(data, 'errors.error', []);
          const errorMessages = _.get(errors, '0', '');

          if (errorMessages) { reactToastify(errorMessages, TOASTIFY_TYPES.ERROR); }
        });
      } catch (expection) {
      // may be 500 or 405 error code...
        reactToastify('Something went wrong unable to delete Run', TOASTIFY_TYPES.ERROR);
      }
    });
};

export const updateTradeSkipDates = (params, runId) => {
  const url = `/runs/${runId}/update_trade_skip_date.json`;
  const options = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ trade_skip_dates: params })
  };

  return wrappedFetch(url, options);
};

export const updateRun = ({ runId, run }) => {
  const url = `/runs/${runId}.json`;
  const options = {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ run })
  };

  return wrappedFetch(url, options);
};

export const moveRunToAnotherFolder = (args) => {
  const uri = '/runs/move_runs_to_another_folder';

  const params = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...args })
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

export const onReStartRun = (params, runId, callback) => {
  const url = `/runs/${runId}/restart.json`;

  const options = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...params })
  };

  return wrappedFetch(url, options)
    .then((response) => response.json())
    .then((response) => {
      const { status, errors } = response;
      if (status) {
        if (callback) { callback(); } else setTimeout(() => { window.location.reload(); }, 1000);
      } else {
        const errorMessages = _.join(_.map(errors, '0'), ', ');
        if (errorMessages) reactToastify(errorMessages, TOASTIFY_TYPES.ERROR);
      }
    }).catch(() => {
      // when 404, Not the current run.. to catch will execute..
      reactToastify('Unauthorized to restart', TOASTIFY_TYPES.ERROR);
    });
};

export const getLastTransaction = (runId) => {
  const url = `/runs/${runId}/get_last_transaction.json`;

  const options = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({})
  };

  return wrappedFetch(url, options).then((response) => response.json());
};

export const getLastTransactionForMultipleRuns = (runIds) => {
  const url = '/runs/get_last_transaction_for_multiple_runs.json';

  const options = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ run_ids: runIds })
  };

  return wrappedFetch(url, options).then((response) => response.json());
};

export const getRunningLiveRuns = () => {
  return wrappedFetch('/runs/get_live_running_runs')
    .then((response) => response.json());
};

export const getNumerousRuns = (params) => {
  const url = '/runs/get_numerous_runs';
  const options = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...params })
  };

  return wrappedFetch(url, options).then((response) => response.json());
};

export const onRunForceExit = (runId) => {
  const url = `/runs/${runId}/manual_square_off_open_positions.json`;
  const options = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: ''
  };

  return wrappedFetch(url, options).then((response) => response.json());
};

export const onLegForceExit = (jobId, orderId) => {
  // orderId is combination of order details which also contains #, so we encode it.
  const url = `/jobs/${jobId}/orders/${encodeURIComponent(orderId)}/manual_square_off_order_leg`;
  const options = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: ''
  };

  return wrappedFetch(url, options).then((response) => response.json());
};

export const onStopQuant = (runId, callBack) => {
  axios.post(`/runs/${runId}/stop.json`).finally(() => {
    if (callBack) { callBack(); } else setTimeout(() => window.location.reload(), 500);
    // Wait for the job to complete and update the status.
  });
};
