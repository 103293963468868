import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { signalsPropTypes } from 'common/propTypes';
import useOutputSchema from 'v2/modules/withRunForm/hooks/useOutputSchema';
import { OutputSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import Main from 'v2/modules/withRunForm/components/Signals/SignalsForm/Main';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: signalsPropTypes.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  pipeIndex: PropTypes.number
};

const defaultProps = {
  pipeIndex: undefined
};

const SignalFormField = ({
  name, value, control, errors, onUpdatePipeConfig, pipeIndex
}) => {
  const signals = value || [];
  const outputSchema = useOutputSchema();
  const filteredOutputSchema = useMemo(() => {
    return _.chain(outputSchema)
      .thru((schema) => OutputSchemaUtility.filterSchemaPriorToPipeIndex(schema, pipeIndex))
      .value();
  }, [outputSchema, pipeIndex]);

  const onUpdateSignals = useCallback((newSignals) => {
    onUpdatePipeConfig(name, newSignals);
  }, [onUpdatePipeConfig, name]);

  return (
    <div className="full__width add-signal-modal">
      <Main
        signals={signals}
        signalType=""
        outputSchema={filteredOutputSchema}
        control={control}
        errors={errors}
        onUpdateSignals={onUpdateSignals}
      />
    </div>
  );
};

SignalFormField.propTypes = propTypes;
SignalFormField.defaultProps = defaultProps;

export default SignalFormField;
