import React from 'react';
import PropTypes from 'prop-types';
import Money from 'common/components/MoneyDisplay';

const propTypes = {
  currentNetPrice: PropTypes.number,
  price: PropTypes.number,
  strikePrice: PropTypes.number,
  shouldAvoidDecimals: PropTypes.bool,
  cycleDurationInMonths: PropTypes.number,
  shouldHideBillCycle: PropTypes.bool
};

const defaultProps = {
  currentNetPrice: 0,
  price: 0,
  strikePrice: 0,
  shouldAvoidDecimals: true,
  cycleDurationInMonths: 1,
  shouldHideBillCycle: false
};

const PaymentMoneyDisplay = ({
  currentNetPrice,
  price,
  strikePrice,
  shouldAvoidDecimals,
  cycleDurationInMonths,
  shouldHideBillCycle
}) => {
  const oneIndentation = ' ';

  const cycleMonth = !shouldHideBillCycle ? cycleDurationInMonths : 1;

  const renderStrikedPrice = () => {
    if (!strikePrice) return null;

    return (
      <del className="strike-payment">
        <Money shouldAvoidDecimals>{Math.ceil(strikePrice / cycleMonth)}</Money>
      </del>
    );
  };
  const isPriceAndCurrentNetPriceSame = price === currentNetPrice;

  return (
    <div className={`payment-wrapper ${!strikePrice ? 'margin-mover' : ''}`}>
      {isPriceAndCurrentNetPriceSame && renderStrikedPrice(price)}
      {!isPriceAndCurrentNetPriceSame && renderStrikedPrice(currentNetPrice)}
      {oneIndentation}
      <span className="actual-payment">
        <Money shouldAvoidDecimals={shouldAvoidDecimals}>
          {Math.ceil(currentNetPrice / cycleMonth)}
        </Money>
        {!shouldHideBillCycle
        && <span className="tx-12">/ Month</span>}
      </span>
    </div>
  );
};

PaymentMoneyDisplay.propTypes = propTypes;
PaymentMoneyDisplay.defaultProps = defaultProps;

export default PaymentMoneyDisplay;
