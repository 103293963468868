export const ordersToCsv = (transactions, runName, runId) => {
  const { isAdmin, isBetaUser } = window;
  const headerData = ['Transaction', 'Instrument', 'Qty', 'Entry', 'Entry Price', 'Entry Time',
    'Exit', 'Exit Price', 'Exit Time', 'Profit'];

  const csvDataFormat = [];

  if (isAdmin || isBetaUser) {
    headerData.push('Min Profit');
    headerData.push('Max Profit');
    csvDataFormat.push(headerData);
  } else {
    csvDataFormat.push(headerData);
  }

  _.each(transactions, (transaction) => {
    const { transactionNumber, pairedOrders } = transaction;
    _.each(pairedOrders, (ordersPair) => {
      const {
        entryType, exitType, entryTime, exitTime, orderProfit, entryPrice,
        exitPrice, quantity, tradingSymbol, orderProfitMax, orderProfitMin
      } = ordersPair;
      const [entryMonthDay, entryTimeFormat] = _.split(entryTime, ',');
      const [exitMonthDay, exitTimeFormat] = _.split(exitTime, ',');
      const rowData = [
        transactionNumber, tradingSymbol, quantity, _.upperCase(entryType), _.round(entryPrice, 2),
        `${entryMonthDay} ${entryTimeFormat}`, _.upperCase(exitType),
        _.round(exitPrice, 2), `${exitMonthDay || ''} ${exitTimeFormat || ''}`, _.round(orderProfit, 2)
      ];

      if (isAdmin || isBetaUser) {
        rowData.push(orderProfitMin || '');
        rowData.push(orderProfitMax || '');
        csvDataFormat.push(rowData);
      } else {
        csvDataFormat.push(rowData);
      }
    });
  });

  const csvContent = `data:text/csv;charset=utf-8,${csvDataFormat.map((e) => e.join(',')).join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${runId}/${runName}.csv`);
  document.body.appendChild(link);
  link.click();
};
