import React from 'react';
import PropTypes from 'prop-types';
import { orderModificationPropTypes } from 'common/propTypes';
import OrderTypeSelector from 'common/components/OrderType';
import ModifyOrders from '../ModifyOrders/index';
import { handleErrors } from '../ModifyOrders/helper';

const propTypes = {
  entryOrderModification: orderModificationPropTypes.isRequired,
  exitOrderModification: orderModificationPropTypes.isRequired,
  onChangeEntryOrderType: PropTypes.func.isRequired,
  onChangeExitOrderType: PropTypes.func.isRequired,
  isShowEntryOrderType: PropTypes.bool.isRequired,
  isShowExitOrderType: PropTypes.bool.isRequired,
  onChangeEntryOrderModification: PropTypes.func.isRequired,
  onChangeExitOrderMofication: PropTypes.func.isRequired,
  entryOrderType: PropTypes.string.isRequired,
  exitOrderType: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
const defaultProps = {};

const OrderTypeConfiguration = ({
  entryOrderModification, exitOrderModification, onChangeEntryOrderType, onChangeExitOrderType,
  isShowExitOrderType, isShowEntryOrderType, onChangeEntryOrderModification,
  onChangeExitOrderMofication, entryOrderType, exitOrderType, errors
}) => {
  const renderEntryModification = () => {
    if (!isShowEntryOrderType) return null;

    const { action: entryAction, actionTimeInSeconds: entryActionTimeInSeconds } = entryOrderModification;
    const entryActionError = handleErrors(errors, 'entryOrderModification', 'action');
    const entryActionInSecondsError = handleErrors(errors, 'entryOrderModification', 'actionTimeInSeconds');
    return (
      <ModifyOrders
        action={entryAction}
        actionTimeInSeconds={!entryActionTimeInSeconds ? '' : entryActionTimeInSeconds.toString()}
        isHideCancel={false}
        onHandleChange={onChangeEntryOrderModification}
        orderConfig={entryOrderModification}
        actionError={entryActionError}
        actionsInSecondsError={entryActionInSecondsError}
      />
    );
  };

  const renderExitModification = () => {
    if (!isShowExitOrderType) return null;

    const { action: exitAction, actionTimeInSeconds: exitActionTimeInSeconds } = exitOrderModification;
    const exitActionError = handleErrors(errors, 'exitOrderModification', 'action');
    const exitActionInSecondsError = handleErrors(errors, 'exitOrderModification', 'actionTimeInSeconds');
    return (
      <ModifyOrders
        action={exitAction}
        actionTimeInSeconds={!exitActionTimeInSeconds ? '' : exitActionTimeInSeconds.toString()}
        isHideCancel
        orderConfig={exitOrderModification}
        onHandleChange={onChangeExitOrderMofication}
        actionError={exitActionError}
        actionsInSecondsError={exitActionInSecondsError}
      />
    );
  };

  return (
    <>
      <div className="orderTypes row align-items-center">
        <div className="col-md-2">
          <label className="col-form-label col-form-label-sm">Entry Order Type</label>
        </div>
        <div className="col-md-10">
          <div className="d-flex align-items-center flex-wrap">
            <OrderTypeSelector
              orderType={entryOrderType}
              onChange={onChangeEntryOrderType}
              title="Entry"
              type="entry"
            />
            {renderEntryModification()}
          </div>
        </div>
      </div>

      <div className="orderTypes row align-items-center">
        <div className="col-md-2">
          <label className="col-form-label col-form-label-sm">Exit Order Type</label>
        </div>
        <div className="col-md-10">
          <div className="d-flex align-items-center flex-wrap">
            <OrderTypeSelector
              orderType={exitOrderType}
              onChange={onChangeExitOrderType}
              title="Exit"
              type="exit"
            />
            {renderExitModification()}
          </div>
        </div>
      </div>
    </>
  );
};

OrderTypeConfiguration.propTypes = propTypes;
OrderTypeConfiguration.defaultProps = defaultProps;

export default OrderTypeConfiguration;
