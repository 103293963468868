import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import { availableBrokers } from 'common/brokerConfigs/config';
import { reDirectPage, shouldHideapisecret } from './config';
import BrokerContent from './BrokerContent/index';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  brokerUserId: PropTypes.string.isRequired,
  broker: PropTypes.string.isRequired
};

const defaultProps = {};

const btnTitle = 'Authenticate';

const SecretKeyAuthentication = ({ onCloseModal, brokerUserId, broker }) => {
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [isValid, setIsValid] = useState(true);
  const textFoucs = useRef(null);
  const shouldHideApiSecret = _.includes(shouldHideapisecret, broker);

  let label = {
    apiKey: 'API Key',
    apiSecret: 'Secret Key'
  };

  switch (broker) {
    case 'rupeezy':
      label = {
        apiKey: 'API key',
        apiSecret: 'Application ID'
      };
      break;
    case 'fyers':
      label = {
        apiKey: 'App ID',
        apiSecret: 'Secret ID'
      };
      break;
    default:
      label = {
        apiKey: 'API Key',
        apiSecret: 'Secret Key'
      };
  }

  useEffect(() => {
    textFoucs.current.focus();
  }, []);

  const onNavigate = () => {
    if (!apiKey || (!shouldHideApiSecret && !apiSecret)) return setIsValid(false);
    const reDirectData = { api_key: apiKey, api_secret: apiSecret, broker_user_id: brokerUserId };
    reDirectPage(reDirectData, broker);

    return '';
  };

  return (
    <Modal
      onSave={() => onNavigate()}
      onClose={onCloseModal}
      title={`${availableBrokers[broker]} API Credentials` || ''}
      size="lg"
      okBtnTitle={btnTitle}
    >
      <>
        <BrokerContent broker={broker} />
        <div className="row">
          <div className="col-md-12">
            <h4 className="text-center my-3">Register here, Please refer above instructions..</h4>
          </div>
          <div className="col-12 col-md-6 m-auto">
            <label>{label.apiKey}</label>
            <input
              className="form-control"
              type="text"
              value={apiKey}
              id={`${broker}-api-key`}
              ref={textFoucs}
              onChange={(event) => {
                setApiKey(event.target.value.trim());
              }}
            />
            {!shouldHideApiSecret && (
              <div className="mt-3">
                <label>{label.apiSecret}</label>
                <input
                  className="form-control"
                  type="text"
                  id={`${broker}-secret-id`}
                  value={apiSecret}
                  onChange={(event) => {
                    setApiSecret(event.target.value.trim());
                  }}
                />
              </div>
            )}
            {!isValid && <div className="invalid-feedback d-block">*Please provide the details</div>}
          </div>
        </div>
      </>
    </Modal>
  );
};

SecretKeyAuthentication.propTypes = propTypes;
SecretKeyAuthentication.defaultProps = defaultProps;

export default SecretKeyAuthentication;
