import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HoverMessage from 'common/components/HoverMessage';
import ReactHtmlParser from 'react-html-parser';

const propTypes = {
  isStopLossPresent: PropTypes.bool.isRequired,
  onShowTrailingStopLoss: PropTypes.func.isRequired,
  isShowCheckBox: PropTypes.bool,
  isTrailingStopLossEnabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

const defaultProps = {
  isShowCheckBox: false,
  isTrailingStopLossEnabled: false,
};

const AddTrailing = ({
  label, isStopLossPresent, onShowTrailingStopLoss, isShowCheckBox, isTrailingStopLossEnabled
}) => {
  const [isShowHoverMessage, setIsShowHoverMessage] = useState(false);
  const oneIndentation = ' ';

  useEffect(() => {
    if (isShowHoverMessage) { setTimeout(() => { setIsShowHoverMessage(false); }, 5000); }
  }, [isShowHoverMessage]);

  const renderAddButton = () => {
    const addButton = (
      <button
        className="btn btn-link btn-block btn-sm add-tsl-btn track"
        onClick={() => {
          if (!isStopLossPresent) setIsShowHoverMessage(true);
          else onShowTrailingStopLoss(true);
        }}
        type="button"
      >
        <span className="material-icons-outlined tx-22 mr-1">add</span>
        {oneIndentation}
        {label}
      </button>
    );

    const addCheckBox = (
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="trailingStopLoss"
          checked={isTrailingStopLossEnabled}
          onChange={() => {
            if (!isStopLossPresent) setIsShowHoverMessage(true);
            else onShowTrailingStopLoss(!isTrailingStopLossEnabled);
          }}
        />
        <label className="form-check-label" htmlFor="trailingStopLoss">
          Trailing Stop Loss
        </label>
      </div>
    );

    if (isStopLossPresent || !isShowHoverMessage) return isShowCheckBox ? addCheckBox : addButton;

    return (
      <HoverMessage
        title="Trailing Stop Loss"
        message={ReactHtmlParser('<b>Stop Loss</b> is Required For Trailing Stop Loss')}
        isOpen={isShowHoverMessage}
      >
        {isShowCheckBox ? addCheckBox : addButton}
      </HoverMessage>
    );
  };

  return (
    <div className="add-tsl">
      {renderAddButton()}
    </div>
  );
};

AddTrailing.propTypes = propTypes;
AddTrailing.defaultProps = defaultProps;

export default AddTrailing;
