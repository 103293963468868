import {
  INTENT_TYPES,
  SEGMENT_CONFIG,
  STRIKE_SELECTION_TYPES,
  BUILDER_QUANT_CONFIG_VERSION,
  STOPS_VALUE_TYPES,
  OPTION_TYPES,
  SL_TRAIL_TYPES
} from 'v2/common/constants/index';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';

export const getEmptySignals = [
  {
    conditionOperator: '',
    leftOperand: '',
    operator: '',
    rightOperand: '',
  },
];

export const signalTypes = {
  entrySignal: 'entrySignal',
  exitSignal: 'exitSignal',
  adjustSignal: 'adjustSignal',
};

export const CONDITION_OPERATORS = ['and', 'or'];

export const defaultIntentInstrumentExpiry = {
  index: 1,
  cycle: 'weekly'
};

export const IntentLabelConfig = (() => {
  const config = { default: 0 };

  return {
    get config() {
      return config;
    },
    setConfigNameAndValue(name, value) {
      config[name] = value;
    },
    reverse(name, value) {
      const label = config[name];
      if (name === 'default' && Number(value) === Number(label) && Number(label) !== 0) {
        config[name] = Number(label) - 1;
        return;
      }

      if (value === label) {
        const newLabel = String.fromCharCode(label.charCodeAt(0) - 1);
        config[name] = newLabel;
      }
    }
  };
})();

export const getDefaultIdentifier = (name = 'default') => {
  let newLabel = '';
  if (name === 'default') {
    const label = IntentLabelConfig.config[name] || 0;
    newLabel = Number(label) + 1;
  } else {
    const label = IntentLabelConfig.config[name];
    newLabel = !label ? String.fromCharCode('A'.charCodeAt(0)) : String.fromCharCode(label.charCodeAt(0) + 1);
  }
  IntentLabelConfig.setConfigNameAndValue(name, newLabel);

  return { label: `${newLabel}`, iteration: 0 };
};

export const getDefaultTarget = () => ({
  label: '',
  iteration: 0,
});

export const defaultStopGain = { type: STOPS_VALUE_TYPES.Percent, value: 0 };

export const defaultLinearTrail = { type: SL_TRAIL_TYPES.Linear, trailingValue: 0, adjustValue: 0 };

export const defaultStopLoss = {
  type: STOPS_VALUE_TYPES.Percent, value: 0, trail: defaultLinearTrail
};

export const getDefaultQuickAdjustments = () => ({
  signal: '',
  action: '',
  targets: [],
});

export const getDefaultIntent = () => ({
  type: INTENT_TYPES.OpenLeg,
  instrument: {
    type: SEGMENT_CONFIG.option,
    instrumentIndex: 0,
    expiry: defaultIntentInstrumentExpiry,
    strike: {
      type: STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.value,
      strikeIndex: 0,
      multiplier: 0,
    },
    optionType: OPTION_TYPES.CE,
  },
  target: getDefaultTarget(),
  side: 'buy',
  quantity: 1,
  stopGain: defaultStopGain,
  stopLoss: defaultStopLoss,
  waitUntilPremiumToIncreaseInPercentage: 0,
  waitUntilPremiumToDecreaseInPercentage: 0,
  waitAndTradeType: 'waitUntilPremiumToIncreaseInPercentage',
  reEntryCount: 0,
  reExecuteCount: 0,
  uiConfigs: { quickAdjustments: [] }
});

export const getDefaultModifyOrderIntent = () => ({
  type: INTENT_TYPES.CloseLeg,
  target: getDefaultTarget(),
  identifier: getDefaultIdentifier()
});

export const getDefaultBasketIntent = () => ({
  type: INTENT_TYPES.OpenBasket,
  name: '',
  // identifier: getDefaultIdentifier(),
  multiplier: 1,
});
// when the intent is of type OpenBasket,
// the identifier have to call seperately and attach to the intent

export const defaultUiBehaviourFlags = {
  isReEntry: false,
  isReExecute: false,
  isTrailingStopLoss: false,
  isWaitAndTrade: false,
};

export const getDefaultTimeBasedSignal = {
  entryTime: '09:15',
  exitTime: '15:25'
};

export const getDefaultExpiryHandling = {
  exitDaysBefore: 0,
  exitTime: '15:28'
};

export const getDefaultAdjustment = () => ({
  signal: [],
  intents: []
});

export const getDefaultCase = () => ({
  entry: {
    signal: [],
    intents: [{
      ...getDefaultIntent(),
      identifier: getDefaultIdentifier()
    }]
  },
  adjustments: [],
  exitSignal: [],
  uiConfigs: { isMoveToCost: false }
});

export const getQuantModes = {
  simple: { label: 'Simple', value: 'simple' },
  advanced: { label: 'Advanced', value: 'advanced' }
};

export const getDefaultQuantConfigUiConfigs = () => ({
  mode: getQuantModes.simple.value,
  segment: SEGMENT_CONFIG.equity
});

export const emptyQuantConfig = () => ({
  version: BUILDER_QUANT_CONFIG_VERSION,
  maxInstrumentsInGroup: 1,
  instrumentGroups: [],
  tradingType: 'intraday',
  pipeConfigs: [PipeDefinitionUtility.getPipeConfigDefaults('currentCandle')],
  cases: [getDefaultCase()],
  uiBehaviourFlags: defaultUiBehaviourFlags,
  adjustmentOptions: { executionType: 'nextMinute' },
  uiConfigs: getDefaultQuantConfigUiConfigs(),
  bounds: {
    maxTransactionsPerDay: 1,
    timeBasedSignal: getDefaultTimeBasedSignal,
    expiryHandling: {}
  },
  entryOrderType: 'market',
  entryOrderModification: {},
  exitOrderType: 'market',
  exitOrderModification: {},
  isPlaceStoplossOrderToBroker: true,
  isPlaceOptionsEntryBuyOrdersFirst: true,
});
