import React from 'react';
import PropTypes from 'prop-types';
import { transactionPropTypes } from 'common/propTypes';
import { ordersToCsv } from './orderCsvConversion';

const propTypes = {
  runName: PropTypes.string.isRequired,
  runId: PropTypes.number.isRequired,
  transactions: PropTypes.arrayOf(transactionPropTypes).isRequired,
};
const defaultProps = {};

const TransactionsDownload = ({ runName, runId, transactions }) => {
  return (
    <div>
      <button
        className="btn btn-primary btn-sm track"
        type="button"
        data-track-category="Transaction CSV"
        data-track-action="download"
        data-track-label="Transaction CSV"
        onClick={() => ordersToCsv(transactions, runName, runId)}
      >
        Download CSV
      </button>
    </div>
  );
};

TransactionsDownload.propTypes = propTypes;
TransactionsDownload.defaultProps = defaultProps;

export default TransactionsDownload;
