import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import LoadingSpinner from 'common/components/LoadingSpinner';
import { orderConfigsPropTypes } from 'common/propTypes';

const propTypes = {
  run: {
    quant_config: {
      orderConfigs: orderConfigsPropTypes
    },
    created_at: PropTypes.string
  }.isRequired,
  reloadUrl: PropTypes.string.isRequired,
  onEtaCompletion: PropTypes.func.isRequired,
};
const defaultProps = {};

const RUN_STARTUP_TIME = 3; // seconds
const INTERVAL = 1000;
const RELOADSECONDS = 360;

const getETA = (run) => {
  const { start_date: startDate, end_date: endDate } = run;
  const start = moment.utc(startDate);
  const end = moment.utc(endDate);
  const totalYears = end.diff(start, 'years') + 1;
  return totalYears * 1.5 + RUN_STARTUP_TIME;
};

const EstimatedTimeCountDown = (props) => {
  const {
    run,
    reloadUrl,
    onEtaCompletion
  } = props;
  const { created_at: createdAt } = run;
  const estimatedTime = getETA(run);
  const completionTime = moment.utc(createdAt).add(estimatedTime, 'seconds');
  const getRemainingSeconds = () => {
    const currentTime = moment.utc();

    if (currentTime.isAfter(completionTime)) return 0;

    return completionTime.diff(currentTime, 'seconds');
  };

  const reloadTime = moment.utc(createdAt).add(RELOADSECONDS, 'seconds');
  const reloadRemainingSeconds = () => {
    const currentTime = moment.utc();
    return reloadTime.diff(currentTime, 'seconds');
  };

  const [seconds, setSeconds] = useState(getRemainingSeconds());
  const [reloadSeconds, setReloadSeconds] = useState(reloadRemainingSeconds());

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds <= 0) {
        clearTimeout(timer);
        return;
      }
      setSeconds(seconds - 1);
    }, INTERVAL);

    const reloadTimer = setTimeout(() => {
      if (reloadSeconds <= 0) {
        onEtaCompletion();
        clearTimeout(reloadTimer);
        return;
      }
      setReloadSeconds(reloadSeconds - 1);
    }, INTERVAL);

    return () => {
      clearTimeout(timer);
      clearTimeout(reloadTimer);
    };
  });

  const renderMessage = () => {
    if (!reloadSeconds) {
      window.location.reload();
    }

    if (seconds) {
      return (
        <div className="message-container">
          Estimated time remaining
          &nbsp;
          {seconds}
          &nbsp;
          seconds
        </div>
      );
    }

    return (
      <div className="message-container">
        Taking too long than usual...
        {' '}
        <br />
        We are working on it please wait or
        &nbsp;
        <a href={reloadUrl}>click here to reload</a>
      </div>
    );
  };

  return (
    <div className="card estimated-time-count-down">
      <div className="image-container">
        <LoadingSpinner isLoading />
      </div>
      {renderMessage()}
    </div>
  );
};

EstimatedTimeCountDown.propTypes = propTypes;
EstimatedTimeCountDown.defaultProps = defaultProps;

export default EstimatedTimeCountDown;
