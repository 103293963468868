import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

const defaultProps = {
};

const LabelTags = ({ label, className }) => {
  const labelClassName = `badge badge-pill ${className}`;

  return (
    <span className={labelClassName}>{label}</span>
  );
};

LabelTags.propTypes = propTypes;
LabelTags.defaultProps = defaultProps;

export default LabelTags;
