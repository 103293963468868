import fetchStrategies from 'common/api/strategies';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { userPropTypes } from 'common/propTypes';
import React, { useEffect, useState } from 'react';
import StrategyCard from 'ui/Strategies/components/StrategyCard/index';
import PropTypes from 'prop-types';

const propTypes = {
  currentUserDetails: userPropTypes,
  currentUserDefaultFolderId: PropTypes.number
};

const defaultProps = {
  currentUserDetails: null,
  currentUserDefaultFolderId: null
};

const DashboardMarketPlaceStrategies = ({ currentUserDetails, currentUserDefaultFolderId }) => {
  const [strategies, setStrategies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchStrategies({ params: { limit: 6 } }).then((results) => {
      setStrategies(results);
      setIsLoading(false);
    });
  }, []);

  const renderStrategy = (strategy, idx) => {
    return (
      <StrategyCard
        strategy={{ ...strategy, currentUserDefaultFolderId, currentUser: currentUserDetails }}
        key={idx}
      />
    );
  };

  const renderStrategyContainer = () => {
    if (isLoading) return null;

    if (!_.isEmpty(strategies)) {
      return <div className="profitable-wrapper">{_.map(strategies, renderStrategy)}</div>;
    }

    return (
      <div className="no-strategies-info">
        <img src="/no-strategies.svg" alt="No Strategies" />
        <span className="text-muted tx-12">No Strategies Available</span>
      </div>
    );
  };

  return (
    <LoadingSpinner isLoading={isLoading} className="mt-5">
      {renderStrategyContainer()}
      {!isLoading && (
      <div className="text-center mt-5">
        <button
          className="btn btn-outline-primary btn-sm d-inline-flex btn-pill"
          type="button"
          onClick={() => { window.location.href = '/strategies'; }}
        >
          Go to Sample Strategies
          <span className="material-icons-outlined ml-1 tx-18"> arrow_forward </span>
        </button>
      </div>
      )}
    </LoadingSpinner>

  );
};

DashboardMarketPlaceStrategies.propTypes = propTypes;
DashboardMarketPlaceStrategies.defaultProps = defaultProps;

export default DashboardMarketPlaceStrategies;
