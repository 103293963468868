import { SEGMENT_CONFIG, STRIKE_SELECTION_TYPES } from 'v2/common/constants/index';
import { extractIndividualSchemaField } from '../../utils/signal';

const getIntentDependencies = (cases) => {
  const intentDependencies = {};

  const setDependent = (label) => {
    intentDependencies[label].isDependent = true;
  };

  const processOperand = (operand, label) => {
    const isIndividualStopIncludes = operand?.includes('individualStop.');
    if (isIndividualStopIncludes) {
      const { intentId, basketId } = extractIndividualSchemaField(operand);
      if (intentId && intentId.includes(label)) {
        setDependent(label);
      }
      if (basketId && basketId.includes(label)) {
        setDependent(label);
      }
    }
  };

  // Helper function to process adjustment intents
  const processAdjustmentIntents = (label, filteredAdjustments) => {
    filteredAdjustments.forEach(({ intents: adjustmentIntents }) => {
      if (intentDependencies[label].isDependent) return;

      adjustmentIntents.forEach((adjustmentIntent) => {
        const isOption = adjustmentIntent?.instrument?.type === SEGMENT_CONFIG.option;
        const strikeType = adjustmentIntent?.instrument?.strike?.type;
        const { IndicatorValueBasedStrikeIndex } = STRIKE_SELECTION_TYPES;
        if (isOption && strikeType === IndicatorValueBasedStrikeIndex.value) {
          processOperand(adjustmentIntent?.instrument?.strike?.valuePath ?? '', label);
        }
      });
    });
  };

  // Helper function to process adjustment signals
  const processAdjustmentSignals = (label, filteredAdjustments) => {
    filteredAdjustments.forEach(({ signal: signals }) => {
      if (intentDependencies[label].isDependent) return;

      signals.forEach(({ leftOperand, rightOperand }) => {
        processOperand(leftOperand, label);
        processOperand(rightOperand, label);
      });
    });
  };

  cases.forEach((caseConfig) => {
    const { entry: { intents: entryIntents }, adjustments } = caseConfig;

    entryIntents.forEach(({ identifier: { label } }) => {
      intentDependencies[label] = { isDependent: false };

      processAdjustmentIntents(label, adjustments);
      processAdjustmentSignals(label, adjustments);
    });

    // Process adjustments for intents that are not already in intentDependencies
    adjustments.forEach(({ intents: adjustmentIntents }) => {
      adjustmentIntents.forEach(({ identifier: { label } }) => {
        if (intentDependencies[label]) return;

        intentDependencies[label] = { isDependent: false };
        const filteredAdjustments = adjustments.map((adjustment) => ({
          ...adjustment,
          intents: adjustment?.intents?.filter((intent) => intent.identifier.label !== label),
        }));

        processAdjustmentIntents(label, filteredAdjustments);
        processAdjustmentSignals(label, filteredAdjustments);
      });
    });
  });

  return intentDependencies;
};

export default getIntentDependencies;
