import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';

import { setTimeBasedSignal } from 'modules/QuantBuilder/actions';
import { timeBasedSignalPropTypes } from 'common/propTypes';
import TimeSelector from 'common/components/TimeSelector';
import LimitTransactions from 'modules/LimitTransactions';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { getTimeValidators } from 'common/utils/validators';
import moment from 'moment';
import { PRODUCT_TYPE } from 'modules/QuantBuilder/config/index';
import { ORDER_TYPES, RUN_TYPES } from 'common/constants/index';

const propTypes = {
  timeBasedSignal: timeBasedSignalPropTypes.isRequired,
  dispatchSetTimeBasedSignal: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  runType: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired
};

const defaultProps = {};

const Intraday = (props) => {
  const {
    timeBasedSignal,
    timeBasedSignal: { entryTime, exitTime },
    dispatchSetTimeBasedSignal,
    product,
    runType,
    orderType
  } = props;
  const { mis } = PRODUCT_TYPE;
  const { currentUserBroker } = window;
  const isLive = runType === RUN_TYPES.live;
  const { paper: { value: paper } } = ORDER_TYPES;
  const isActualOrder = orderType !== paper && isLive;

  const { errors, control } = useFormContext();
  const [timePickerName] = useState(_.uniqueId('intradayTimePicker-'));
  const isShowInfo = moment(exitTime, 'HH:mm:ss').isSameOrAfter(moment('15:15:00', 'HH:mm:ss'))
    && product === mis && currentUserBroker && isActualOrder;

  const onTimeBasedSignalChange = (newConfig) => {
    dispatchSetTimeBasedSignal({
      ...timeBasedSignal,
      ...newConfig
    });
  };
  const startTimeStyle = classNames({
    invalid: !_.isEmpty(errors[`${timePickerName}-start-time`])
  });
  const endTimeStyle = classNames({
    invalid: !_.isEmpty(errors[`${timePickerName}-end-time`])
  });

  return (
    <div className="">
      <div className="row mb-3 align-items-center">
        <div className="col-md-2">
          <label className="col-form-label col-form-label-sm">Trade During</label>
        </div>
        <div className="col-md-10">

          <Controller
            render={({ onChange }) => (
              <TimeSelector
                style={startTimeStyle}
                onTimeChange={(newTime) => {
                  onTimeBasedSignalChange({ entryTime: newTime });
                  onChange(newTime);
                }}
                selectedTime={entryTime}
              />
            )}
            control={control}
            defaultValue={entryTime}
            name={`${timePickerName}-start-time`}
            rules={getTimeValidators()}
          />
          &nbsp;-&nbsp;

          <Controller
            render={({ onChange }) => (
              <TimeSelector
                style={endTimeStyle}
                onTimeChange={(newTime) => {
                  onTimeBasedSignalChange({ exitTime: newTime });
                  onChange(newTime);
                }}
                selectedTime={exitTime}
                minTime={entryTime}
              />
            )}
            control={control}
            defaultValue={exitTime}
            name={`${timePickerName}-end-time`}
            rules={getTimeValidators({ minTime: entryTime })}
          />
          <ErrorMesssage
            error={errors[`${timePickerName}-start-time`] || errors[`${timePickerName}-end-time`]}
          />
        </div>
      </div>
      {isShowInfo && (
        <div className="d-flex">
          <div className="alert alert-sm alert-warning">
            <b>Warning: </b>
            Brokers doing auto square-off for MIS orders between 03:15 pm - 03:30 pm. So, it is
            recommended that configure your trades before 03:15 pm.
          </div>
        </div>
      )}
      <LimitTransactions />
    </div>
  );
};

const mapStateToProps = (state) => ({
  timeBasedSignal: _.get(state, 'quantConfig.timeBasedSignal'),
  product: _.get(state, 'quantConfig.product'),
  runType: _.get(state, 'runType'),
  orderType: _.get(state, 'orderType')
});
const mapDispatchToProps = (dispatch) => ({
  dispatchSetTimeBasedSignal: (newTimeBasedSignal) => dispatch(setTimeBasedSignal(newTimeBasedSignal))
});

export default connect(mapStateToProps, mapDispatchToProps)(Intraday);
Intraday.propTypes = propTypes;
Intraday.defaultProps = defaultProps;
