import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import React, { memo, useCallback, useMemo } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { useSelector } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { onChangeGlobalSegment } from 'v2/modules/withRunForm/events/globalSegment';

const propTypes = {
  type: PropTypes.string.isRequired,
  onSetNewPipe: PropTypes.func.isRequired,
  validator: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isDependent: PropTypes.bool
};

const defaultProps = { validator: { }, isDependent: false };

const PipeSelector = ({
  type, onSetNewPipe, validator, control, errors, isDependent
}) => {
  const uiConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs'));
  const uniqueId = useMemo(() => _.uniqueId('pipe_type_'), []);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  const onChangePipe = useCallback((result, onChange) => {
    if (_.isEmpty(result)) return null;

    const newIndicatorType = _.get(result, [0, 'value']);

    const { pipeConfigs: newPipeConfigs } = onChangeGlobalSegment({
      pipeConfigs: [PipeDefinitionUtility.getPipeConfigDefaults(newIndicatorType)],
      uiConfigs
    });

    onSetNewPipe(newPipeConfigs[0]);
    return onChange(result);
  }, [onSetNewPipe, uiConfigs]);

  const validatorConfig = useMemo(() => ({
    ...validator,
    validate: (value) => {
      if (_.isEmpty(value)) return 'Please add indicator type';

      return null;
    }
  }), [validator]);

  const selectedOptions = useMemo(() => (type ? [type] : []), [type]);

  return (
    <div>
      <label className="col-form-label col-form-label-sm">Indicator Type</label>
      <div className="inside-icon">
        <i className="search-icon" />
        <ErrorMesssage message={errorMessage}>
          <Controller
            render={({ onChange, ref, name }) => (
              <Typeahead
                className="w-100"
                size="sm"
                id={name}
                disabled={isDependent}
                isInvalid={!!errorMessage}
                ref={ref}
                onFocus={(event) => event.target.select()}
                clearButton
                selected={selectedOptions}
                highlightOnlyResult
                options={PipeDefinitionUtility.getPipeConfigSearchTerms()}
                onChange={(result) => onChangePipe(result, onChange)}
              />
            )}
            control={control}
            defaultValue={selectedOptions}
            name={uniqueId}
            rules={validatorConfig}
          />
        </ErrorMesssage>
      </div>
    </div>
  );
};

PipeSelector.propTypes = propTypes;
PipeSelector.defaultProps = defaultProps;

export default memo(PipeSelector);
