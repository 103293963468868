import React, { useContext, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import { onReStartRun } from 'common/api/run';
import { runPropTypes } from 'common/propTypes';
import { quotaVerificationDom } from 'ui/QuotaVerification/quotaDom';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { MARKET_END_TIME } from 'common/constants/index';
import { OrderContext } from '../../OrderStore/index';

const propTypes = {
  instrumentGroups: PropTypes.arrayOf(PropTypes.array),
  run: runPropTypes.isRequired,
  isLiveRun: PropTypes.bool.isRequired,
  isRunBelongsToUser: PropTypes.bool.isRequired
};

const defaultProps = { instrumentGroups: [] };

const ReStartButton = ({
  instrumentGroups, run, isLiveRun, isRunBelongsToUser
}) => {
  const isValidToRestart = _.get(run, 'is_valid_to_restart', false);

  if (!isLiveRun || !isRunBelongsToUser || !isValidToRestart) return null;
  const [isShowModal, setIsShowModal] = useState(false);
  const [isSkipLastTransaction, setIsSkipLastTransaction] = useState('no');
  const {
    id: runId, order_type: orderType, run_type: runType,
    is_strategy_subscriber: isMarketPlaceSubscribedRun
  } = run;

  const { transactions } = useContext(OrderContext);
  const lastTransaction = _.last(transactions) || {};
  const pairedOrders = _.get(lastTransaction, 'pairedOrders', []);

  const subscriberStrategyCutOffTime = _.get(window, 'SA_COLLECTIONS.subscriberStrategyCutOffTime', null);

  const isLastTransactionNotCompleted = _.some(pairedOrders, (pairedOrder) => {
    const { isManualExitShow } = pairedOrder;
    return isManualExitShow;
  });

  const renderRadioBtn = (optionType) => {
    return (
      <div className="form-check form-check-sm mr-1" key={optionType}>
        <input
          className="form-check-input"
          type="radio"
          id={optionType}
          value={optionType}
          data-track-category="Last Transaction Skip"
          data-track-action={optionType}
          data-track-label="Last Transaction Skip"
          checked={optionType === isSkipLastTransaction}
          onChange={() => { setIsSkipLastTransaction(optionType); }}
        />
        <label className="form-check-label" htmlFor={`${optionType}`}>{_.startCase(optionType)}</label>
      </div>
    );
  };

  const executeRestartRun = () => {
    if (isLastTransactionNotCompleted && !isMarketPlaceSubscribedRun) {
      let content = 'Your last transaction is not yet completed ';
      content += 'We ignored those orders, so you have to take care of your orders manually';
      reactToastify(content, TOASTIFY_TYPES.INFO);
    }

    onReStartRun({ ignore_incomplete_transaction: isSkipLastTransaction === 'no' }, runId);
  };

  const onRestart = () => {
    setIsShowModal(false);

    const cutoffTimeMoment = moment(subscriberStrategyCutOffTime, 'HH:mm');
    const marketEndTimeMoment = moment(MARKET_END_TIME, 'HH:mm');

    const isAfterCutoff = moment().isAfter(cutoffTimeMoment);
    const isBeforeMarketEnd = moment().isBefore(marketEndTimeMoment);

    let delay = 0;
    if (isAfterCutoff && isBeforeMarketEnd && isMarketPlaceSubscribedRun) {
      delay = 5000;
      reactToastify(
        'Your deployment will take effect in the live market from tomorrow.',
        TOASTIFY_TYPES.INFO
      );
    }

    setTimeout(() => {
      quotaVerificationDom(instrumentGroups, { order_type: orderType, run_type: runType }, executeRestartRun);
    }, delay);
  };

  const renderModal = () => {
    return (
      <ModalComponent
        onSave={() => { onRestart(); }}
        onClose={() => setIsShowModal(false)}
        title="Restart Confirmation"
        size="sm"
        okBtnTitle="Restart"
      >
        <>
          Your last transaction is not yet completed. Do you want to continue with the last transaction?
          <div className="d-flex align-items-center mt-2">{_.map(['yes', 'no'], renderRadioBtn)}</div>
        </>
      </ModalComponent>
    );
  };

  return (
    <>
      <div
        className="text-nowrap btn btn-sm btn-outline-success btn-pill common-icon-btn track"
        onClick={() => {
          // if (!isMarketPlaceSubscribedRun && isLastTransactionNotCompleted) setIsShowModal(true);
          // else
          onRestart();
        }}
        key="stop-btn"
        data-track-category="Quant Restart"
        data-track-action="restart"
        data-track-label="Quant Restart"
      >
        <i className="material-icons-outlined tx-18">restart_alt</i>
        Restart
      </div>
      {isShowModal && renderModal()}
    </>
  );
};

ReStartButton.propTypes = propTypes;
ReStartButton.defaultProps = defaultProps;

export default ReStartButton;
