import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

const propTypes = {
  action: PropTypes.string,
  isHideCancel: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

const defaultProps = { action: '' };

const ACTIONS = {
  update: { label: 'Modify to market', value: 'update' },
  cancel: { label: 'Cancel', value: 'cancel' }
};

const ActionTypeSelector = ({ action: propsAction, isHideCancel, onChange }) => {
  const modifyOrderActionTypeBtnName = useMemo(() => _.uniqueId('modifyOrderActionType-'), []);
  const { errors, register, clearErrors } = useFormContext();
  const validatorConfig = {
    validate: (value) => {
      return (!value ? 'value required' : null);
    }
  };

  const validatorProps = { ref: register(validatorConfig) };

  const actionTypeStyle = classNames(
    'modify-order-selector',
    'align-items-center',
    'gap-5',
    'd-flex',
    'selector-radio-btn'
  );

  useEffect(() => {
    return () => clearErrors(modifyOrderActionTypeBtnName);
  }, [clearErrors, modifyOrderActionTypeBtnName]);

  const inputClassName = classNames('form-check-input', {
    'is-invalid': !!errors[modifyOrderActionTypeBtnName]
  });

  const renderRadioBtn = (optionType) => {
    if (isHideCancel && optionType.value === 'cancel') return null;

    return (
      <div className="form-check form-check-sm" key={optionType.value}>
        <input
          {...validatorProps}
          className={inputClassName}
          type="radio"
          name={modifyOrderActionTypeBtnName}
          id={`${modifyOrderActionTypeBtnName}-${optionType.value}`}
          value={optionType.value}
          tabIndex="-100"
          checked={optionType.value === propsAction}
          onChange={(e) => onChange(e.target.value)}
        />
        <label className="form-check-label" htmlFor={`${modifyOrderActionTypeBtnName}-${optionType.value}`}>
          <span>
            {_.startCase(optionType.label)}
          </span>
        </label>
      </div>
    );
  };

  return (
    <div className={actionTypeStyle}>
      {_.map(ACTIONS, renderRadioBtn)}
    </div>
  );
};

ActionTypeSelector.propTypes = propTypes;
ActionTypeSelector.defaultProps = defaultProps;

export default ActionTypeSelector;
