import wrappedFetch from './base';

export const onCreateNewCallbackRequest = (args) => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...args }),
  };

  return wrappedFetch('/callback_requests.json', config);
};
