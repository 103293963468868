import React from 'react';
import PropTypes from 'prop-types';

import Pagination from 'common/components/Pagination';
import { transactionPropTypes } from 'common/propTypes';

const propTypes = {
  transactions: PropTypes.arrayOf(transactionPropTypes).isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  transactionsPerPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const TransactionsFooter = (props) => {
  const {
    transactions, currentPageIndex, transactionsPerPage, onCurrentPageChange
  } = props;
  const totalTransactions = transactions.length;
  const totalPages = totalTransactions / transactionsPerPage;
  const startOfRange = transactionsPerPage * currentPageIndex;
  const endOfRange = startOfRange + transactionsPerPage < totalTransactions
    ? startOfRange + transactionsPerPage
    : totalTransactions;

  return (
    <div className="transactions-footer">
      <div className="count-show align-self-center">
        {`Showing ${startOfRange + 1} to ${endOfRange} of ${totalTransactions} entries`}
      </div>

      <div>
        { totalTransactions > transactionsPerPage
            && (
            <Pagination
              totalPages={totalPages}
              onPageChange={({ selected: selectedPage }) => onCurrentPageChange(selectedPage)}
              currentPageIndex={currentPageIndex}
            />
            )}
      </div>
    </div>
  );
};

TransactionsFooter.propTypes = propTypes;
TransactionsFooter.defaultProps = defaultProps;
export default TransactionsFooter;
