import wrappedFetch from './base';

export const validateBroker = (broker) => {
  return wrappedFetch(`/${broker}/validate`)
    .then((response) => response.json());
};

export const getSetting = () => {
  return wrappedFetch('/user/setting.json')
    .then((response) => response.json());
};

export const updateSetting = (updatedSetting) => {
  const config = {
    method: 'PUT',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ setting: updatedSetting }),
  };

  return wrappedFetch('/user/setting.json', config);
};

export const getWebPushSubscriptions = () => {
  return wrappedFetch('/web_push_subscribers.json')
    .then((response) => response.json());
};

export const destroyWebPushSubscription = (webPushSubscriberId) => {
  const config = {
    method: 'DELETE',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  };

  return wrappedFetch(`/web_push_subscribers/${webPushSubscriberId}.json`, config);
};
