import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';

import { setTimeBasedSignal, setExpiryHandling } from 'modules/QuantBuilder/actions';
import { expiryHandlingPropsTypes } from 'common/propTypes';
import {
  EXPIRY_TYPES, DAYS, getEmptyTimeBasedSignal, getDefaultExpiryHandling, OPTIONS_EXPIRY
} from 'modules/QuantBuilder/config';
import TimeSelector from 'common/components/TimeSelector';
import HoverMessage from 'common/components/HoverMessage';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { getTimeValidators } from 'common/utils/validators';

const propTypes = {
  expiryHandling: expiryHandlingPropsTypes.isRequired,
  optionsExpiryCycle: PropTypes.string.isRequired,
  dispatchSetTimeBasedSignal: PropTypes.func.isRequired,
  dispatchSetExpiryHandling: PropTypes.func.isRequired,
};
const defaultProps = {};

const Positional = (props) => {
  const {
    expiryHandling: { type, exitTime, exitDaysBefore },
    expiryHandling,
    dispatchSetTimeBasedSignal,
    dispatchSetExpiryHandling,
    optionsExpiryCycle
  } = props;
  const { auto: { key: autoExpiry }, rollover: { key: rollover } } = EXPIRY_TYPES;
  const { errors, control, clearErrors } = useFormContext();
  const [timePickerName] = useState(_.uniqueId('positionalTimePicker-'));
  const timePickerStyle = classNames({
    invalid: !_.isEmpty(errors[`${timePickerName}-start-time`])
  });

  const onExpiryTypeChange = (newExpiryType) => {
    clearErrors(timePickerName);
    dispatchSetTimeBasedSignal(getEmptyTimeBasedSignal());
    dispatchSetExpiryHandling({
      ...getDefaultExpiryHandling(),
      type: newExpiryType
    });
  };
  const onExpiryHandlingChange = (newConfig) => {
    dispatchSetExpiryHandling({
      ...expiryHandling,
      ...newConfig
    });
  };

  const renderExpiryTypes = _.map(EXPIRY_TYPES, ({ key: expiryType, diaplayName }) => {
    if (optionsExpiryCycle === OPTIONS_EXPIRY.weekly && expiryType === rollover) return null;

    const expiryTypeInput = (
      <div
        className="custom-control custom-radio custom-control-inline align-self-center"
      >
        <input
          className="custom-control-input"
          id={expiryType}
          name="expiryType"
          type="radio"
          disabled={expiryType === rollover && window.isDisabledRollover}
          value={expiryType}
          checked={type === expiryType}
          onChange={(event) => onExpiryTypeChange(event.target.value)}
        />
        <label className="custom-control-label tx-12 pt-1" htmlFor={expiryType}>
          {diaplayName}
        </label>
      </div>
    );

    return (
      <span key={expiryType}>
        { expiryType === rollover && window.isDisabledRollover ? (
          <HoverMessage
            title="Rollover"
            message="This feature is currently disabled"
          >
            {expiryTypeInput}
          </HoverMessage>
        )
          : expiryTypeInput}
      </span>
    );
  });

  const renderDayBeforeExpiryOptions = _.map(DAYS[optionsExpiryCycle], (day) => (
    <option key={day} value={day}>{day}</option>
  ));

  return (
    <div className="positional-exit-handler">
      <div className="row mb-3 align-items-center">
        <div className="col-md-2">
          <label className="col-form-label col-form-label-sm">Expiry Handling</label>
        </div>
        <div className="col-md-10 d-flex flex-wrap">
          {renderExpiryTypes}
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-2" />
        <div className="col-md-10 form-inline">
          {type !== autoExpiry
            && (
            <div className="input-group input-group-sm mb-2 mr-2">
              <Form.Control
                className="form-control custom-select custom-select-sm"
                as="select"
                value={exitDaysBefore}
                onChange={(event) => onExpiryHandlingChange({
                  exitDaysBefore: parseInt(event.target.value, 10)
                })}
              >
                <option key="default-option" value="" />
                {renderDayBeforeExpiryOptions}
              </Form.Control>
              <div className="input-group-append">
                <span className="input-group-text">Days Before Expiry</span>
              </div>
            </div>
            )}
          <div className="input-group input-group-sm mb-2 expiry-exit-time-selector">
            <div className="input-group-prepend">
              <span className="input-group-text">
                {(type === autoExpiry) ? 'On Expiry Day ' : ''}
                {(type === rollover) ? 'Rollover At ' : 'Force Exit At'}
              </span>
            </div>
            <Controller
              render={({ onChange }) => (
                <TimeSelector
                  style={timePickerStyle}
                  onTimeChange={(newExitTime) => {
                    onExpiryHandlingChange({ exitTime: newExitTime });
                    onChange(newExitTime);
                  }}
                  selectedTime={exitTime}
                />
              )}
              control={control}
              defaultValue={exitTime}
              name={timePickerName}
              rules={getTimeValidators()}
            />
            <ErrorMesssage
              error={errors[timePickerName]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  expiryHandling: _.get(state, 'quantConfig.expiryHandling'),
  optionsExpiryCycle: _.get(state, 'quantConfig.optionsExpiryCycle'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetTimeBasedSignal: (newTimeBasedSignal) => dispatch(setTimeBasedSignal(newTimeBasedSignal)),
  dispatchSetExpiryHandling: (newExpiry) => dispatch(setExpiryHandling(newExpiry)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Positional);

Positional.propTypes = propTypes;
Positional.defaultProps = defaultProps;
