import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { runPropTypes } from 'common/propTypes';
import DeleteRunBtn from 'ui/run/RunShow/components/RunTitleAndActions/DeleteRunBtn';
import MoveFolderModal from './RunListView/MoveFolderModal';

const propTypes = {
  onUpdateRun: PropTypes.func.isRequired,
  onDeleteRun: PropTypes.func.isRequired,
  run: runPropTypes.isRequired,
  folderName: PropTypes.string.isRequired
};
const defaultProps = {};
const ActionBtns = ({
  onDeleteRun, run, onUpdateRun, folderName
}) => {
  const {
    folder_id: folderId, id, is_starred: isStarred, display_name: displayName, status
  } = run;
  const isMarketPlaceSubscribedRun = _.get(run, 'is_strategy_subscriber', false);
  const isMarketPlaceAuthorRun = _.get(run, 'is_strategy_author', false);

  const favoriteIconClassNames = classNames(isStarred ? 'favorite' : 'favorite_border');
  const [isMoveFolder, setIsMoveFolder] = useState(false);

  return (
    <div className="action prevent-redirect">
      <button
        type="button"
        className="action-btn"
        onClick={() => onUpdateRun(run, 'is_starred', !isStarred)}
        data-toggle="tooltip"
        data-placement="top"
        title="Add My Strategies"
      >
        <i className="material-icons-outlined tx-16">{favoriteIconClassNames}</i>
      </button>

      {!isMarketPlaceSubscribedRun && (
        <a
          className="action-btn track"
          data-toggle="tooltip"
          data-placement="top"
          title="Clone"
          data-track-category="Folder"
          data-track-action="clone"
          data-track-label="Folder tracking"
          href={`/runs/new?clone_from_run_id=${id}&folder_id=${folderId || ''}&locale=${I18n.locale}`}
        >
          <i className="material-icons-outlined tx-16">file_copy</i>
        </a>
      )}
      <button
        type="button"
        className="action-btn"
        data-toggle="tooltip"
        data-placement="top"
        data-track-category="Folder"
        data-track-action="move-to-folder"
        data-track-label="Folder tracking"
        title="Move to another Folder"
        onClick={() => setIsMoveFolder(true)}
      >
        <i className="material-icons-outlined">drive_file_move_rtl</i>
      </button>

      <DeleteRunBtn
        isRunBelongsToUser
        onDeleteRun={() => onDeleteRun(run)}
        status={status}
        isMarketPlaceAuthorRun={isMarketPlaceAuthorRun}
      />

      {isMoveFolder && (
        <MoveFolderModal
          runIds={[id]}
          folderName={folderName}
          displayName={displayName}
          onClose={() => setIsMoveFolder(false)}
        />
      )}
    </div>
  );
};
ActionBtns.propTypes = propTypes;
ActionBtns.defaultProps = defaultProps;
export default ActionBtns;
