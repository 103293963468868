import React, { useState } from 'react';
import InstrumentGroupBuilder from 'common/components/InstrumentGroupBuilder/index';
import classNames from 'classnames';
import TemplateFooter from '../../TemplateFooter';
import {
  intervalTemplates, comparisonTypesTemplates, handleErrors
} from '../../config';
import Select from '../../CommonComponents/Select';
import Input from '../../CommonComponents/Input';
import TemplateSentence from '../../CommonComponents/TemplateSentence';
import LabelTags from '../../CommonComponents/LabelTags';
import {
  quantConfigConversionSt,
  superTrendDefaultConfig, SUPER_TREND
} from './helper';

export const templateName = SUPER_TREND;

const defaultConfig = superTrendDefaultConfig;

export const Builder = (props) => {
  const segments = _.get(props, 'segments', []);

  const propSegment = _.get(props, 'segment');
  const [segment, setSegment] = useState(propSegment);

  const [templateConfig, setTemplateConfig] = useState(defaultConfig);
  const [instruments, setIntruments] = useState([]);
  const isNew = true;
  const oneIndentation = ' ';
  const onAddInstrument = (instrument) => {
    setIntruments([...instruments, instrument]);
  };

  const onDeleteAddedInstrument = (instrumentIndex) => {
    const newInstruments = _.filter(instruments, (instrument, index) => instrumentIndex !== index);
    setIntruments(newInstruments);
  };

  const colClassName = classNames('', {
    '': isNew
  });

  const cardClassName = classNames('card h-100', {
    'new-strategie': isNew
  });

  const handleStrategyChange = (key, value) => {
    setTemplateConfig({
      ...templateConfig,
      [key]: value
    });
  };

  return (
    <div className={colClassName}>
      <div className={cardClassName}>
        <div className="card-header">
          <h6 className="font-weight-bold">
            Super Trend
          </h6>
          <div>
            {oneIndentation}
            <LabelTags
              label="intraday"
              className="intraday"
            />
            {oneIndentation}
            <LabelTags
              label="directional"
              className="directional"
            />
          </div>
        </div>

        <div className="card-body">
          <InstrumentGroupBuilder
            isInValid={false}
            instrumentGroups={instruments}
            maxInstrumentsInGroup={1}
            onAddInstrumentGroup={(newInstrumentGroup) => onAddInstrument(newInstrumentGroup)}
            onRemoveInstrumentGroup={(indexToRemove) => onDeleteAddedInstrument(indexToRemove)}
            titleMdSize="col-md-3 col-lg-2 p-0"
            inputMdSize="col-md-9 col-lg-10 p-0"
            segment={segment}
            onChangeSegment={(newSegment) => setSegment(newSegment)}
            segments={segments}
          />

          <div className="technical-indicators">
            <div className="technical-order">
              When close price
              <div>
                <Select
                  value={templateConfig.comparisonType}
                  onChange={(event) => handleStrategyChange('comparisonType', event.target.value)}
                  optionItems={comparisonTypesTemplates}
                />
              </div>
              <div>
                <Input
                  value={templateConfig.period}
                  onChange={(event) => handleStrategyChange('period', handleErrors(event.target.value))}
                />
              </div>
              Period
              <div>
                <Select
                  value={templateConfig.interval}
                  onChange={(event) => handleStrategyChange('interval', event.target.value)}
                  optionItems={intervalTemplates}
                />
              </div>
              interval
              <div>
                <Input
                  value={templateConfig.multiplier}
                  onChange={(event) => handleStrategyChange('multiplier', handleErrors(event.target.value))}
                />
              </div>
              Multiplier
            </div>

            <div className="template-sentence-wrapper">
              <TemplateSentence
                optionType={{ value: templateConfig.optionType, key: 'optionType' }}
                strikeIndex={{ value: templateConfig.strikeIndex, key: 'strikeIndex' }}
                actiontype={{ value: templateConfig.actiontype, key: 'actiontype' }}
                stopLoss={{ value: templateConfig.stopLoss, key: 'stopLoss' }}
                stopGain={{ value: templateConfig.stopGain, key: 'stopGain' }}
                quantity={{ value: templateConfig.quantity, key: 'quantity' }}
                handleStrategyChange={handleStrategyChange}
                stopGainType={{ value: templateConfig.stopGainType, key: 'stopGainType' }}
                stopLossType={{ value: templateConfig.stopLossType, key: 'stopLossType' }}
                instruments={instruments}
              />
            </div>
          </div>
        </div>
        <TemplateFooter
          templateConfig={templateConfig}
          templateName={templateName}
          instruments={instruments}
          segment={segment}
        />
      </div>
    </div>
  );
};

export const toQuantConfig = (config) => {
  return quantConfigConversionSt(config);
};
