import React from 'react';
import PropTypes from 'prop-types';
import TimeSelector from 'common/components/TimeSelector';
import { getMeetStatus } from './helper';

const propTypes = {
  config: PropTypes.shape({
    date: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    meetLink: PropTypes.string.isRequired,
    meetingType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  onDeleteMeetConfig: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  keyIndex: PropTypes.number.isRequired
};

const defaultProps = {};

const Details = ({
  config, onDeleteMeetConfig, onHandleChange, keyIndex
}) => {
  const oneIndentation = ' ';
  const {
    date, startTime, endTime, meetLink, meetingType, title
  } = config;

  const { status, label } = getMeetStatus(date, startTime, endTime);

  return (
    <div className="mt-5 mb-5 d-flex align-items-center">
      <label className="mr-2">Date:</label>
      <input
        type="date"
        value={date}
        className="mr-5"
        onChange={(event) => onHandleChange('date', event.target.value, keyIndex)}
      />

      <label className="mr-2">Start Time:</label>
      <TimeSelector
        onTimeChange={(newTime) => {
          onHandleChange('startTime', newTime, keyIndex);
        }}
        selectedTime={startTime}
      />
      <span className="mr-5" />

      <label className="mr-2">End Time:</label>
      <TimeSelector
        onTimeChange={(newTime) => {
          onHandleChange('endTime', newTime, keyIndex);
        }}
        selectedTime={endTime}
      />
      <span className="mr-5" />
      <label className="mr-2">Type of Meeting: </label>
      <select
        className="custom-select"
        style={{ width: '10%' }}
        value={meetingType}
        onChange={(event) => onHandleChange('meetingType', event.target.value, keyIndex)}
      >
        <option value="googleMeet" selected="">Google Meet</option>
        <option value="youtube">Youtube</option>
        <option value="zoom">Zoom</option>
      </select>

      <span className="mr-5" />
      <label className="mr-2">Meeting Link:</label>
      <input
        type="text"
        value={meetLink}
        className="mr-5"
        onChange={(event) => onHandleChange('meetLink', event.target.value, keyIndex)}
      />
      <span className="mr-5" />
      <label className="mr-2">Title</label>
      <input
        type="text"
        value={title}
        className="mr-5"
        onChange={(event) => onHandleChange('title', event.target.value, keyIndex)}
      />

      {meetLink
        && (
          <a href={meetLink} target="_blank" rel="noreferrer">
            Join
            {oneIndentation}
            {meetingType}
          </a>
        )}

      {status && (
      <>
        <span className="mr-5" />
        <label className="mr-2">
          Meeting Status:
          {oneIndentation}
          {label}
          {oneIndentation}
          (
          {status}
          )
        </label>
      </>
      )}

      <span className="mr-5" />
      <button
        type="button"
        className="delete-btn"
        onClick={() => onDeleteMeetConfig(keyIndex)}
      >
        <i className="material-icons-outlined tx-22">delete_forever</i>
      </button>
    </div>
  );
};

Details.propTypes = propTypes;
Details.defaultProps = defaultProps;

export default Details;
