import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setSlOrderPlaceBasedOnPrice } from 'modules/QuantBuilder/actions';
import SlOrderBasedOnPriceWrapper from './SlOrderBasedOnPriceWrapper';

const propTypes = {
  dispatchSlOrderPlaceBasedOnPrice: PropTypes.func.isRequired,
  slOrderPlaceBasedOnPrice: PropTypes.string,
};
const defaultProps = {
  slOrderPlaceBasedOnPrice: null,
};

const SlOrderBasedOnPrice = ({
  dispatchSlOrderPlaceBasedOnPrice, slOrderPlaceBasedOnPrice
}) => {
  useEffect(() => { // newly implemented, old runs don't have those config so i attached to quantConfig.
    if (slOrderPlaceBasedOnPrice === null) {
      setTimeout(() => {
        dispatchSlOrderPlaceBasedOnPrice('traded_price');
      }, 100);
    }
  }, []);

  if (slOrderPlaceBasedOnPrice === null) return null;

  return (
    <SlOrderBasedOnPriceWrapper
      slOrderPlaceBasedOnPrice={slOrderPlaceBasedOnPrice}
      handleChange={dispatchSlOrderPlaceBasedOnPrice}
    />
  );
};

SlOrderBasedOnPrice.propTypes = propTypes;
SlOrderBasedOnPrice.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  slOrderPlaceBasedOnPrice:
    _.get(state, 'quantConfig.slOrderPlaceBasedOnPrice', null),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSlOrderPlaceBasedOnPrice:
    (newValue) => dispatch(setSlOrderPlaceBasedOnPrice(newValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(SlOrderBasedOnPrice);
