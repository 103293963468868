import React from 'react';
import PropTypes from 'prop-types';
import { refreshCreditBalance } from 'common/api/credits';

const propTypes = {
  creditBalance: PropTypes.number.isRequired,
  isCreditApplied: PropTypes.bool.isRequired,
  onUpdateCredits: PropTypes.func.isRequired
};

const defaultProps = {};

const Credits = ({ creditBalance, isCreditApplied, onUpdateCredits }) => {
  const isUserSignedIn = _.get(window, 'isUserSignedIn', false);
  const isAdmin = _.get(window, 'isAdmin', false);

  if (!isUserSignedIn || !isAdmin) return null;
  if (creditBalance === 0) return null;

  const getUpdatedCreditBalance = () => {
    refreshCreditBalance().then((result) => {
      onUpdateCredits(
        parseFloat(result.credit_balance),
        isCreditApplied
      );
      return null;
    });
  };

  const onChangeCheckbox = (event) => {
    const { target: { checked } } = event;

    onUpdateCredits(creditBalance, checked);
  };

  return (
    <>
      <div className="credit-box">
        <div className="inner-credit-box">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <input
                className="form-check-input position-static"
                type="checkbox"
                id="blankCheckbox"
                checked={isCreditApplied}
                onChange={(event) => onChangeCheckbox(event)}
              />
              <h4>
                <i className="fa-solid fa-sack-dollar" />
              </h4>
            </div>
            <div className="">
              <h4>
                ₹
                {' '}
                {Math.trunc(creditBalance)}
              </h4>
              <span className="earned-credit">You Have earned till now</span>
            </div>
          </div>
        </div>
        <p className="credit-refresh">
          Do you want to refresh credit Now ?
          <a href="#button" onClick={getUpdatedCreditBalance}> Click Now</a>
        </p>
      </div>
    </>
  );
};

Credits.propTypes = propTypes;
Credits.defaultProps = defaultProps;

export default Credits;
