import React, { useEffect, useState } from 'react';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import PropTypes from 'prop-types';

const propTypes = {
  limitOrderBufferPercentage: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number]
  ).isRequired,
  onChangeLimitOrderBufferPercentage: PropTypes.func.isRequired,
};
const defaultProps = {};

const StopLimitPriceDifference = ({
  limitOrderBufferPercentage,
  onChangeLimitOrderBufferPercentage
}) => {
  const [bufferPercentage, setBufferPercentage] = useState(
    limitOrderBufferPercentage || 0
  );

  useEffect(() => {
    setBufferPercentage(limitOrderBufferPercentage);
  }, [limitOrderBufferPercentage]);

  const onUpdateState = () => onChangeLimitOrderBufferPercentage(bufferPercentage);

  return (
    <>
      <div className="row align-items-center mb-4">
        <div className="col-md-2">
          <label className="label_style">
            Limit Order Buffer
          </label>
        </div>
        <div className="col-md-10 limit-input-field">
          <div className="input-group">
            <input
              type="text"
              className="form-control form-control-sm"
              value={bufferPercentage}
              onChange={
                (event) => setBufferPercentage(onRegexOnlyNumberAndFloat(event.target.value) || '')
              }
              onBlur={onUpdateState}
            />
            <div className="input-group-append">
              <span className="input-group-text">%</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

StopLimitPriceDifference.propTypes = propTypes;
StopLimitPriceDifference.defaultProps = defaultProps;

export default StopLimitPriceDifference;
