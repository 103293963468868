import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import IndicatorValuePathSelector from './IndicatorValuePathSelector';
import useStrikeBased from './useStrikeBased';
import Selection from './Selection';
import { StrikeUtility } from './utility';

const propTypes = {
  strike: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  onChangeStrike: PropTypes.func.isRequired,
  isIndexInstrumentBased: PropTypes.bool.isRequired,
  optionType: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

const defaultProps = {};

const StrikeBasedSelector = ({
  strike, onChangeStrike, isIndexInstrumentBased, optionType, errors, control, clearErrors, setError
}) => {
  const [strikeBoxName] = useState(_.uniqueId('strikeName-'));
  const errorMessage = errors[strikeBoxName] ? errors[strikeBoxName]?.message || '' : '';
  const [isShowHoverMessage, setIsShowHoverMessage] = useState(false);

  useEffect(() => {
    if (isShowHoverMessage) { setTimeout(() => { setIsShowHoverMessage(false); }, 5000); }
  }, [isShowHoverMessage]);

  const {
    isIndexBased,
    value,
    type,
    valuePath,
    options,
    onChangeType,
    onChangeValue,
    onChangeValuePath,
    isIndicatorBased,
    comparison,
  } = useStrikeBased(strike, onChangeStrike, isIndexInstrumentBased, optionType);

  const isPermissionToChoose = StrikeUtility.isPermissionToChoose(type);

  useEffect(() => {
    if (!isPermissionToChoose) setIsShowHoverMessage(true);
  }, [isPermissionToChoose]);

  const validator = {
    validate: (val) => {
      if (val === '' || val === undefined) return 'should be included';
      if (Number.isNaN(Number(val))) return 'should be a number';
      if (!isIndexBased && val <= 0) return 'should be included';
      return null;
    }
  };

  const formInputProps = !isIndexBased ? { type: 'number' } : { as: 'select' };

  const strikeBoxStyle = classNames('', {
    'custom-select-sm': !!formInputProps.as,
    'strike-index': !!formInputProps.as,
    'is-invalid': !!errors[strikeBoxName]
  });

  const divClassName = classNames('strike-based-selector', { 'indicator-based': isIndicatorBased });

  return (
    <div className={divClassName}>
      <Selection
        type={type}
        onChangeType={onChangeType}
        errors={errors}
        comparison={comparison}
        control={control}
        setError={setError}
        clearErrors={clearErrors}
      />
      {isIndicatorBased
        && (
          <IndicatorValuePathSelector
            valuePath={valuePath}
            errorMessage={errorMessage}
            onChange={onChangeValuePath}
          />
        )}
      <Controller
        render={({ onChange, ref, name }) => (
          <Form.Control
            {...formInputProps}
            className={strikeBoxStyle}
            value={value}
            ref={ref}
            size="sm"
            name={name}
            onChange={(event) => {
              onChangeValue(parseFloat(event.target.value, 10));
              onChange(parseFloat(event.target.value, 10));
            }}
          >
            {formInputProps.as && _.map(options, ({ label, value: val }, idx) => {
              return <option value={val} key={idx}>{label}</option>;
            })}
          </Form.Control>
        )}
        control={control}
        defaultValue={value}
        name={strikeBoxName}
        rules={validator}
      />
    </div>
  );
};

StrikeBasedSelector.propTypes = propTypes;
StrikeBasedSelector.defaultProps = defaultProps;

export default StrikeBasedSelector;
