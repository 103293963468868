import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'common/components/Pagination';

const propTypes = {
  strategies: PropTypes.arrayOf(PropTypes.shape({})),
  handleCurrentPageIndex: PropTypes.func.isRequired,
  strategiesPerPage: PropTypes.number.isRequired,
  currentPageIndex: PropTypes.number.isRequired
};
const defaultProps = { strategies: [] };

const StrategieFooter = ({
  strategies, handleCurrentPageIndex, strategiesPerPage, currentPageIndex
}) => {
  const totalStrategies = strategies.length;
  const totalPages = totalStrategies / strategiesPerPage;
  const isShowPagination = totalStrategies > strategiesPerPage;
  if (!isShowPagination) return null;

  return (
    <div className="d-flex justify-content-center mt-3">
      <Pagination
        totalPages={totalPages}
        onPageChange={({ selected: selectedPage }) => { handleCurrentPageIndex(selectedPage); }}
        currentPageIndex={currentPageIndex}
      />
    </div>
  );
};

StrategieFooter.propTypes = propTypes;
StrategieFooter.defaultProps = defaultProps;

export default StrategieFooter;
