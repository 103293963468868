import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  optionType: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

const OPTION_TYPES = [
  { label: 'Place orders sequenced for Margin Benefit', value: true },
  { label: 'No', value: false }
];

const Selector = ({ onChange, optionType: propsOptionType }) => {
  return (
    <select
      className="form-control form-control-sm"
      value={propsOptionType}
      onChange={(event) => {
        onChange(JSON.parse(event.target.value));
      }}
    >
      {_.map(OPTION_TYPES, (optionType) => {
        return (
          <option
            key={optionType.value}
            value={optionType.value}
          >
            {_.startCase(optionType.label)}
          </option>
        );
      })}
    </select>
  );
};

Selector.propTypes = propTypes;
export default Selector;
