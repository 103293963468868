import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { userPropTypes, planPropTypes } from 'common/propTypes';
import { updateUrlQueryParams } from 'common/utils/urlUtils';
import NotificationSettings from './Components/NotificationSettings';
import MyProfile from './Components/MyProfile';
import PurchaseSettings from './Components/PurchaseSettings';

const I18N_SCOPE = { scope: 'settings' };
const SETTING_TYPES = [
  // TODO: I18n
  { labelFn: () => 'My Profile', value: 'profile' },
  { labelFn: () => 'Notification Settings', value: 'notification' },
  { labelFn: () => 'Payment Details', value: 'payment' }
];

const SETTING_COMPONENTS = {
  profile: MyProfile,
  notification: NotificationSettings,
  payment: PurchaseSettings
};

const propTypes = {
  activeSubscriptionId: PropTypes.number,
  currentUser: userPropTypes.isRequired,
  plan: planPropTypes,
  tab: PropTypes.oneOf(_.map(SETTING_TYPES, 'value')),
  gstPercentage: PropTypes.number,
  quantManGstNumber: PropTypes.string,
};
const defaultProps = {
  activeSubscriptionId: null,
  tab: SETTING_TYPES[1].value,
  plan: null,
  gstPercentage: 18,
  quantManGstNumber: ''
};

class Settings extends Component {
  constructor(props) {
    super(props);
    const { tab } = props;

    const selectedSettingType = _.find(SETTING_TYPES, { value: tab }) || SETTING_TYPES[1];
    this.state = { selectedSettingType };
  }

  onChangeUserSettingType = (settingType) => {
    updateUrlQueryParams('tab', settingType.value);
    this.setState({ selectedSettingType: settingType });
  }

  renderSettingTypeTabItem = (settingType) => {
    const { selectedSettingType } = this.state;
    const tabItemClassNames = classNames(
      'custom-toggle-sm',
      'btn',
      'btn-primary',
      { active: (settingType.value === selectedSettingType.value) }
    );

    return (
      <button
        className={tabItemClassNames}
        id={settingType.value}
        key={settingType.value}
        type="button"
        role="tab"
        data-toggle="list"
        aria-controls={settingType.label}
        onClick={() => this.onChangeUserSettingType(settingType)}
      >
        {I18n.t(settingType.value, I18N_SCOPE)}
      </button>
    );
  }

  render() {
    const { selectedSettingType } = this.state;
    const {
      activeSubscriptionId,
      currentUser, plan, gstPercentage, quantManGstNumber
    } = this.props;
    const CurrentSettingComponent = SETTING_COMPONENTS[selectedSettingType.value];

    return (
      <>
        <div className="border-bottom mb-8 user-setting-list">
          <div className="btn-group-toggle" id="list-tab" role="tablist">
            {_.map(SETTING_TYPES, this.renderSettingTypeTabItem)}
          </div>
        </div>

        <CurrentSettingComponent
          currentUser={currentUser}
          plan={plan}
          activeSubscriptionId={activeSubscriptionId}
          gstPercentage={gstPercentage}
          quantManGstNumber={quantManGstNumber}
        />

      </>
    );
  }
}

Settings.propTypes = propTypes;
Settings.defaultProps = defaultProps;

export default Settings;
