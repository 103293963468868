import { SEGMENT_CONFIG } from 'common/constants/index';

export const getSymbolType = (tradingSymbol) => {
  // todo: this is a hack, need to find a better way to do this
  if (_.endsWith(tradingSymbol, 'CE') && tradingSymbol !== 'BAJFINANCE') return 'CE';
  if (_.endsWith(tradingSymbol, 'PE')) return 'PE';
  if (_.endsWith(tradingSymbol, 'FUT')) return 'FUT';

  return 'EQUITY';
};

export const parseTradingSymbol = (tradingSymbol) => {
  const symbolType = getSymbolType(tradingSymbol);
  switch (symbolType) {
    case 'CE':
    case 'PE':
    case 'FUT':
      const sizeOfTradingSymbol = _.size(tradingSymbol);
      const instrumentEndIndex = tradingSymbol.search(/[0-9]/);
      const instrument = tradingSymbol.substring(0, instrumentEndIndex);
      const expiryDate = tradingSymbol.substring(instrumentEndIndex, instrumentEndIndex + 7,);
      if (symbolType === 'FUT') {
        return {
          instrument, expiryDate, strikePrice: '', segment: SEGMENT_CONFIG.future, symbolType
        };
      }

      const strikePrice = tradingSymbol.substring(instrumentEndIndex + 7, sizeOfTradingSymbol - 2);
      return {
        instrument, expiryDate, strikePrice, segment: SEGMENT_CONFIG.option, symbolType
      };
    default:
      return {
        instrument: tradingSymbol, expiryDate: '', strikePrice: '', segment: SEGMENT_CONFIG.equity
      };
  }
};
