import PropTypes from 'prop-types';

export const oneOfTypes = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
]);

export const addonPropTypes = PropTypes.shape({
  addon_name: PropTypes.string,
  historic_run_limit: PropTypes.number,
  live_run_limit: PropTypes.number,
});

export const strategyAccessPropTypes = PropTypes.shape({
  status: PropTypes.string,
  activeTill: PropTypes.string,
  paidTill: PropTypes.string
});

export const runPropTypes = PropTypes.shape({
  folder_id: PropTypes.number,
  id: PropTypes.number,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  run_type: PropTypes.string,
  order_type: PropTypes.string,
  status: PropTypes.string,
});

export const folderPropTypes = PropTypes.shape({
  id: PropTypes.number,
  is_default: PropTypes.bool,
  live_runs_count: PropTypes.number,
  historic_runs_count: PropTypes.number,
});

export const userPropTypes = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  folders_count: PropTypes.number,
  runs_count: PropTypes.number,
  sign_in_count: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  admin: PropTypes.bool
});

export const outputSchemaPropTypes = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}));

export const instrumentGroupsPropTypes = PropTypes.arrayOf(
  PropTypes.arrayOf(PropTypes.string),
);

export const timeBasedSignalPropTypes = PropTypes.shape({
  entryTime: PropTypes.string,
  exitTime: PropTypes.string
});

export const expiryHandlingPropsTypes = PropTypes.shape({
  type: PropTypes.string,
  exitTime: PropTypes.string,
  exitDaysBefore: PropTypes.number
});

export const transactionModePropTypes = PropTypes.shape({
  transaction: PropTypes.string,
  optionStrategy: PropTypes.string,
  optionSubStrategy: PropTypes.string,
});

export const optionConfigPropTypes = PropTypes.shape({
  strikeIndex: PropTypes.number,
  optionType: PropTypes.string,
  entryType: PropTypes.string,
  quantity: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
});

export const futureConfigPropTypes = PropTypes.shape({
  quantityExpression: PropTypes.string,
  amount: PropTypes.number,
  monthIndex: PropTypes.number,
  entryType: PropTypes.string
});

export const transactionConfigPropTypes = PropTypes.shape({
  mode: transactionModePropTypes,
  futureConfig: futureConfigPropTypes,
  optionConfigs: PropTypes.arrayOf(optionConfigPropTypes),
  quantityExpressionDetails: PropTypes.shape({
    quantity: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    operator: PropTypes.string,
    indicator: PropTypes.string,
  }),
});

export const transactionBuilderConfigPropTypes = PropTypes.shape({
  tradeType: PropTypes.string,
  strike: PropTypes.number,
  optionType: PropTypes.string,
  quantity: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  transactionType: PropTypes.string,
  stopGain: PropTypes.number,
  stopLoss: PropTypes.number,
});

export const transactionBuilderConfigsPropTypes = PropTypes.arrayOf(
  transactionBuilderConfigPropTypes
);

export const rightOperandPropTypes = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.string,
  PropTypes.number, // there is an enum 0 or 1.. or else user type number..
  PropTypes.arrayOf(PropTypes.string)
]);

export const signalConfigPropTypes = PropTypes.shape({
  conditionOperator: PropTypes.string,
  leftOperand: PropTypes.string,
  operator: PropTypes.string,
  rightOperand: rightOperandPropTypes,
});

export const orderConfigsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    entrySignalConfigs: PropTypes.arrayOf(signalConfigPropTypes),
    exitSignalConfigs: PropTypes.arrayOf(signalConfigPropTypes),
    transactionConfigs: PropTypes.arrayOf(transactionConfigPropTypes),
  }),
);

export const chartConfigPropTypes = PropTypes.shape({
  type: PropTypes.string,
  boxUnitSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  boxPercentage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
});

export const pipeConfigPropTypes = PropTypes.shape({
  type: PropTypes.string,
  name: PropTypes.string,
  config: PropTypes.shape({}),
  chartConfig: chartConfigPropTypes,
});

export const signalPropTypes = PropTypes.shape({
  conditionOperator: PropTypes.string,
  leftOperand: PropTypes.string,
  operator: PropTypes.string,
  rightOperand: rightOperandPropTypes,
});

export const signalsPropTypes = PropTypes.arrayOf(signalPropTypes);

export const intentPropTypes = PropTypes.shape({});
export const intentsPropTypes = PropTypes.arrayOf(intentPropTypes);

export const quickAdjustmentPropTypes = PropTypes.shape({
  trigger: PropTypes.string,
  action: PropTypes.string,
  targets: PropTypes.arrayOf(PropTypes.string),
});

export const casePropTypes = PropTypes.shape({
  entry: PropTypes.shape({
    signal: signalsPropTypes.isRequired,
    intents: intentsPropTypes.isRequired,
  }).isRequired,
  exitSignal: signalsPropTypes.isRequired,
  adjustments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
});

export const pipeConfigsPropTypes = PropTypes.arrayOf(pipeConfigPropTypes);

export const quantConfigPropTypes = PropTypes.shape({
  chartConfig: chartConfigPropTypes,
  entryOrderType: PropTypes.string,
  exitOrderType: PropTypes.string,
  expiryHandling: expiryHandlingPropsTypes,
  instrumentGroups: instrumentGroupsPropTypes,
  maxTransactionsPerDay: PropTypes.number,
  optionsExpiryCycle: PropTypes.string,
  orderConfigs: orderConfigsPropTypes,
  pipeConfigs: pipeConfigsPropTypes,
  repeatOrder: PropTypes.bool,
  timeBasedSignal: timeBasedSignalPropTypes,
  tradingType: PropTypes.string,
  trailingStopLoss: PropTypes.number,
  stopLoss: PropTypes.number,
  stopGain: PropTypes.number,
});

export const simpleBuilderConfigPropTypes = PropTypes.shape({
  instruments: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  paramsConfig: PropTypes.shape({
    periodInterval: PropTypes.string
  }),
  entrySignals: PropTypes.shape({}),
  exitSignals: PropTypes.shape({}),
  timeBasedSignal: timeBasedSignalPropTypes,
  expiryHandling: expiryHandlingPropsTypes,
  tradingType: PropTypes.string,
  transactionConfigs: PropTypes.arrayOf(transactionConfigPropTypes),
  trailingStopLoss: PropTypes.number,
  maxTransactionsPerDay: PropTypes.number,
});

// oneOfTypes used for live and historic propTypes differ
// TODO Better way

export const ordersPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    complete: oneOfTypes,
    case_index: oneOfTypes,
    created_at: oneOfTypes,
    id: oneOfTypes,
    instrument: oneOfTypes,
    instrument_group_index: oneOfTypes,
    is_entry: oneOfTypes,
    order_time: oneOfTypes,
    transaction_type: oneOfTypes,
    price: oneOfTypes,
    quantity: oneOfTypes,
    run_id: oneOfTypes,
    transaction_id: oneOfTypes,
    updated_at: oneOfTypes,
  })
);

export const validatorProptypes = PropTypes.arrayOf(
  PropTypes.shape({
    property: PropTypes.string,
    message: PropTypes.string
  })
);

export const tagPropTypes = PropTypes.shape({
  name: PropTypes.string,
});

export const templatePropTypes = PropTypes.shape({
  quantConfig: quantConfigPropTypes,
  name: PropTypes.string,
  tags: PropTypes.arrayOf(tagPropTypes)
});

export const planPropTypes = PropTypes.shape({
  id: PropTypes.number,
  plan_name: PropTypes.string,
  price: PropTypes.number,
  historic_run_limit: PropTypes.number,
  live_run_limit: PropTypes.number,
  razorpay_plan_id: PropTypes.string,
  discount_percentage: PropTypes.number,
  cycle_duration_in_months: PropTypes.number,
});

export const plansPropTypes = PropTypes.arrayOf(planPropTypes);

export const purchaseItem = PropTypes.shape({
  id: PropTypes.number,
  coupon_id: PropTypes.number,
  price: PropTypes.string,
  discount_percentage: PropTypes.string,
  purchasable: PropTypes.oneOfType([
    planPropTypes,
    addonPropTypes,
    strategyAccessPropTypes
  ]),
  purchaseType: PropTypes.string
});

export const purchasePropTypes = PropTypes.shape({
  id: PropTypes.number,
  created_at: PropTypes.string,
  paid_email_sent: PropTypes.bool,
  price_without_tax: PropTypes.string,
  price_gst: PropTypes.string,
  price_total: PropTypes.string,
  purchase_items: PropTypes.arrayOf(purchaseItem),
  razorpay_order_id: PropTypes.string,
  status: PropTypes.string,
  user_id: PropTypes.number,
  user_name: PropTypes.string,
  user_gst_number: PropTypes.string,
});

const jobSummaryPropTypes = PropTypes.shape({
  profit: PropTypes.number,
  complete_total_transactions: PropTypes.number,
  complete_total_orders: PropTypes.number,
  incomplete_amount: PropTypes.number,
  incomplete_transactions: PropTypes.number,
  incomplete_orders: PropTypes.number,
  incomplete_quantity: PropTypes.number
});

export const runJobPropTypes = PropTypes.shape({
  id: PropTypes.number,
  status: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  run_is: PropTypes.number,
  summary: jobSummaryPropTypes
});

export const runjobsProps = PropTypes.arrayOf(runJobPropTypes);

// oneOfTypes used for live and historic propTypes differ
// TODO Better way

export const transactionPropTypes = PropTypes.shape({
  isComplete: oneOfTypes,
  instruments: PropTypes.arrayOf(PropTypes.string),
  orders: ordersPropTypes,
  overallProfit: oneOfTypes,
  sortId: oneOfTypes,
  instrumentGroupIndex: oneOfTypes,
  transactionNumber: oneOfTypes,
  transactionTime: oneOfTypes,
});

export const orderTransactionPropTypes = PropTypes.shape({
  entry_status: PropTypes.string,
  sl_status: PropTypes.string,
  exit_status: PropTypes.string,
  run_same: PropTypes.string,
  instruments: PropTypes.arrayOf(PropTypes.string),
  notifiable_updated_time: PropTypes.string
});

export const orderModificationPropTypes = PropTypes.shape({
  action: PropTypes.string,
  actionTimeInSeconds: oneOfTypes
});

export const strategyPropTypes = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  margin_amount: PropTypes.number,
  max_drawdown: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.string),
  risk_category: PropTypes.string,
  segment_type: PropTypes.string,
  purchase_type: PropTypes.string,
  purchase_amount: PropTypes.number,
  backtest_run_id: PropTypes.number,
  live_run_id: PropTypes.number,
  description: PropTypes.string
});

export const pairedOrdersPropTypes = PropTypes.shape({
  tradingSymbol: PropTypes.string,
  entryType: PropTypes.string,
  actualEntryTimeISO: PropTypes.string,
  entryPrice: PropTypes.number,
  exitType: PropTypes.string,
  actualExitTimeISO: PropTypes.string,
  exitPrice: PropTypes.number,
});
