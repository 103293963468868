export const indicatorsTypes = { single: 'single', pair: 'pair' };
export const DOUBLE_COL_SPAN = 2;
export const NO_COL_SPAN = 1;
export const SUB_TITLE = 'subTitle';
export const INDICATORS_LENGTH = { single: 1, pair: 2 };

export const getTradeTypes = () => ({
  PE: { entry: { buy: 'putOptionLong', sell: 'putOptionShort', } },
  CE: { entry: { buy: 'callOptionLong', sell: 'callOptionShort', } },
  FUT: { entry: { buy: 'futuresLong', sell: 'futuresShort', } },
  EQUITY: { entry: { buy: 'equityLong', sell: 'equityShort', } },
});

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
  'November', 'December'
];

export const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const CENT = 100;
