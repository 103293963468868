export const getBrokersDetails = () => {
  return _.reduce(_.get(window, 'brokerConfigs', {}), (result, value) => {
    // eslint-disable-next-line no-param-reassign
    result[value.id] = value.name;
    return result;
  }, {});
};

export const availableBrokers = getBrokersDetails();

export const automaticSupportedBrokers = _.chain(_.get(window, 'brokerConfigs', {}))
  .filter((broker) => broker.automatic)
  .map((broker) => { return broker.id; })
  .value();

export const oneClickSupportedBrokers = _.chain(_.get(window, 'brokerConfigs', {}))
  .filter((broker) => broker.oneclick)
  .map((broker) => { return broker.id; })
  .value();
