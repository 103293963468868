import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const InvestRight = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              To start click on this link and login with InvestRight credentials
              {oneIndentation}
              <b>
                <a
                  href="https://developer.hdfcsec.com/"
                  target="blank"
                  title="InvestRight Developer Login"
                >
                  https://developer.hdfcsec.com/
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              Go to
              {oneIndentation}
              <b>My Apps.</b>
              {oneIndentation}
              Click on create App
            </p>
            <img
              src="/landing-page-img/brokers-img/invest_right_step_2.png"
              className="w-100"
              alt="API Apps page"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Fill below details and create.
            </p>

            <div className="stepper-desc">
              <p>
                <b>App Name:</b>
                {oneIndentation}
                Quantman
                <br />
                <b>Redirect URL:</b>
                {oneIndentation}
                https://www.quantman.in/auth/invest_right/callback
                <br />
                <b>Description:</b>
                {oneIndentation}
                Quantman
              </p>
            </div>

            <img
              src="/landing-page-img/brokers-img/invest_right_step_3.png"
              alt="Quantman App details"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Now, you can Copy and paste api details in appropriate fields below.
            </p>
            <img
              src="/landing-page-img/brokers-img/invest_right_step_4.png"
              className="w-100"
              alt="InvestRight Api credentials"
            />
          </div>
        </li>
      </ol>
    </div>
  </>
);

InvestRight.propTypes = propTypes;
InvestRight.defaultProps = defaultProps;

export default InvestRight;
