import { QUANT_SCHEMA_CATEGORIES } from 'common/constants/index';
import { additionalLeftOperandsConfig } from 'modules/QuantBuilder/config/signalConfigs';

export const getInstrumentDisplayName = (schemaName, instrumentGroups) => {
  const [groupIdx, item, price] = _.split(schemaName, '.');
  let displayName = item;
  const priceLabel = price ? 'Price' : '';

  if (_.includes(schemaName, 'item')) {
    const instrumentIdx = parseInt(_.find(item, (character) => character.match(/\d/)), 10);
    displayName = `${instrumentGroups[parseInt(groupIdx, 10)][instrumentIdx]}.${priceLabel}`;
  }

  return displayName;
};

export const getLabel = (obj) => {
  if (!_.isObject(obj)) return { label: _.capitalize(obj) }; // if it is string.. not an array eg:  100

  const { category, label } = obj;
  const { INDICATOR, INDIVIDUALSTOP, STRATEGY_STOP } = QUANT_SCHEMA_CATEGORIES;

  if (category && !_.includes([INDICATOR, INDIVIDUALSTOP, STRATEGY_STOP], category)) {
    return {
      labelHtml: `<span class="displayname">${_.startCase(category)}</span> ${label}`,
      label: `${category} ${label}`
    };
  }

  return { label: `${label}` };
};

export const getGroupDisplayName = (instrumentGroup) => _.join(instrumentGroup, ' ');

export const getLabeledHashFromArray = (arrayOfObjects, isEnumPresent) => {
  if (isEnumPresent) { // for enum Label
    return _.map(arrayOfObjects, (value) => ({
      id: value.toString(),
      label: value ? 'true' : 'false'
    }));
  }

  return (
    _.map(_.flatten(arrayOfObjects), (obj) => { // flatten for day of week like [Monday, Tuesday]
      if (!_.isObject(obj)) return { id: obj.toString(), label: _.capitalize(obj) };
      // startCase is showing 09 30.. to show 09:30
      // it is string value not an array eg:  Day of week

      return { id: obj.name ? obj.name : obj.toString(), ...getLabel(obj) };
    })
  );
};

export const removeTagsFromString = (operands) => {
  return _.map(operands, (operand) => {
    return { ...operand, label: operand.label.replace(/(<([^>]+)>)/ig, '') };
  });
};

export const getValuePathOptionsForIndicator = (outputSchema) => {
  const { isAdmin } = window;
  const isInclude = (category, name) => {
    if (_.includes([
      QUANT_SCHEMA_CATEGORIES.INDIAVIX,
      QUANT_SCHEMA_CATEGORIES.COMBINED_PREMIUM
    ], category)) {
      return true;
    } if (_.includes([QUANT_SCHEMA_CATEGORIES.FUTURE_PRICE,
      QUANT_SCHEMA_CATEGORIES.EQUITY_PRICE], category) && !_.includes(name, 'close')) {
      // removed .close from showing
      return true;
    }

    return false;
  };
  const instrumentOutputSchema = _.filter(outputSchema, ({ name, category }) => (
    (isAdmin || (!_.includes(name, 'INDIAVIX'))))
    && isInclude(category, name));

  // indivix and equity is hided for
  // testing purpose it is for admin only..

  return getLabeledHashFromArray(instrumentOutputSchema);
};

export const isValueMatchingWithSchema = (outputSchema, value) => {
  return _.filter(outputSchema, (schema) => schema.name === value);
};

export const getSelectedLabelForIndicator = (valuePaths, outputSchema) => {
  const filterLabel = _.map(valuePaths, (valuePath) => { // valuePaths is *mandatory matching with the schema
    return isValueMatchingWithSchema(outputSchema, valuePath);
  });

  return getLabeledHashFromArray(_.flattenDeep(filterLabel));
};

export const getSelectedOperandLabelForSignal = (outputSchema, leftOperand, rightOperand) => {
  const addedConfigsForValidation = [
    ...outputSchema,
    ..._.flattenDeep(_.values(additionalLeftOperandsConfig()))
  ];
  // added additional why.. because there is an option like dayofweek .. timeofday.. etc
  // why union beacuse there is timeOfDay in entry and exit singal.. while added additional leftoperand..
  // both will apply .. so uniq will be removed..
  const filteredMatchingLeftOperand = _.filter(_.unionBy(addedConfigsForValidation, 'name'),
    ({ name }) => name === leftOperand);
  const selectedLeftOperand = !_.isEmpty(filteredMatchingLeftOperand)
    ? filteredMatchingLeftOperand : [leftOperand];

  if (_.isUndefined(rightOperand)) return getLabeledHashFromArray(selectedLeftOperand); // for leftOperand

  const filteredMatchingRightOperand = _.filter(addedConfigsForValidation, (
    { name }
  ) => name === rightOperand);

  // whether right operand is matching with schema.. directly get label
  if (!_.isEmpty(filteredMatchingRightOperand)) return getLabeledHashFromArray(filteredMatchingRightOperand);

  // we have to find whether the value is enum or not... so right operand is not matching with schema...
  // we have leftoperand.. so check it is matching with schema.. while matching..
  // find it has enum or not.. if its enum.. return true or false;
  if (_.isEmpty(filteredMatchingLeftOperand)) return getLabeledHashFromArray([rightOperand]);

  const { enum: enumArray } = _.head(filteredMatchingLeftOperand);
  // if enum is present and rightOperand should not be empty string.. because we have to check 0 or 1.
  if (enumArray && rightOperand !== '') {
    if (_.includes(enumArray, Number(rightOperand))) {
      return getLabeledHashFromArray([Number(rightOperand)], true);
    } // if user can give 0 or 1 ... above conditions is handled..
    // if user give above 1 .. below return will be handled..

    return getLabeledHashFromArray([rightOperand]);
  }

  return getLabeledHashFromArray([rightOperand]);
};
