import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import { setPartialQuantConfig } from '../../redux/actions';
import { onChangeGlobalSegment } from '../../events/globalSegment';

const GlobalSegmentSelector = () => {
  const uiConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs'));
  const { segment } = uiConfigs;
  const dispatch = useDispatch();
  const pipeConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.pipeConfigs'));

  const options = [
    { value: SEGMENT_CONFIG.equity, label: 'Equity & Index' },
    { value: SEGMENT_CONFIG.future, label: 'Future & Options' },
  ];
  return (
    <select
      className="segment-selector form-control"
      value={segment}
      onChange={(event) => {
        const newUiConfigs = {
          ...uiConfigs,
          segment: event.target.value
        };

        dispatch(
          setPartialQuantConfig(
            onChangeGlobalSegment({
              pipeConfigs,
              uiConfigs: newUiConfigs
            })
          )
        );
      }}
    >
      {_.map(options, (op) => {
        return <option value={op.value} key={op.value}>{op.label}</option>;
      })}
    </select>
  );
};

GlobalSegmentSelector.propTypes = {};

export default GlobalSegmentSelector;
