import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import { pipeInputSchemaTypes } from 'v2/common/quantConfig/pipeDefinitions/config';
import { StrikeUtility } from 'v2/common/components/StrikeBasedSelector/utility';
import { extractIdsFromLabel, extractIndividualSchemaField } from '../utils/signal';

export const onResetLabels = (cases, pipeConfigs) => {
  const allIntents = _.chain(cases)
    .map((caseConfig) => {
      const {
        entry: { intents: entryIntents },
        adjustments,
      } = caseConfig;

      return _.concat(entryIntents, _.chain(adjustments).map('intents').flatten().value());
    })
    .flattenDeep()
    .value();

  let incrementalLabel = 0;
  const labelMappedConfig = {};

  allIntents.forEach((intent) => {
    const {
      identifier: { label },
    } = intent;

    if (!labelMappedConfig[label]) {
      const { basketId } = extractIdsFromLabel(label || '');
      incrementalLabel += 1;
      labelMappedConfig[label] = basketId
        ? `${incrementalLabel}(${basketId})`
        : `${incrementalLabel}`;
    }
  });

  const getUpdatedOperand = (operand) => {
    const isIndividualStopIncludes = operand?.includes('individualStop.');
    const isIndicatorBasedBasket = operand?.includes('basket_');
    if (isIndividualStopIncludes) {
      const {
        position, label, intentId, field, basketId
      } = extractIndividualSchemaField(operand || '');
      const updatedIntentId = basketId ? `${labelMappedConfig[intentId]}(${basketId})`
        : labelMappedConfig[label];
      const updatedLabel = updatedIntentId;
      return [position, updatedLabel, field].join('.');
    } if (isIndicatorBasedBasket) {
      const [basket, labelId, config] = _.split(operand, '_');
      const updatedbasketId = basket ? `${labelMappedConfig[labelId]}`
        : labelId;
      return [basket, updatedbasketId, config].join('_');
    }
    return operand;
  };

  const getUpdatedStrike = (strike) => {
    const newStrike = _.cloneDeep(strike);
    const { valuePath } = newStrike;
    return {
      ...newStrike,
      valuePath: getUpdatedOperand(valuePath) || newStrike.valuePath
    };
  };

  const getUpdatedTargets = (targets) => {
    return _.map(targets, (target) => labelMappedConfig[target] || target);
  };

  const getUpdatedQuickAdjustments = (quickAdjustments) => {
    return _.map(quickAdjustments, (quickAdjustment) => {
      const newQuickAdjustment = _.cloneDeep(quickAdjustment);
      const { targets } = newQuickAdjustment;

      return {
        ...newQuickAdjustment,
        targets: getUpdatedTargets(targets) || newQuickAdjustment.targets,
      };
    });
  };

  const getUpdatedIntents = (intents) => {
    return intents.map((intent) => {
      const { label } = intent.identifier;
      const updatedLabel = labelMappedConfig[label];
      const newIntent = _.cloneDeep(intent);
      newIntent.identifier.label = updatedLabel;
      const isQuickAdjustments = intent.uiConfigs?.quickAdjustments;

      if (isQuickAdjustments) {
        newIntent.uiConfigs.quickAdjustments = getUpdatedQuickAdjustments(isQuickAdjustments);
      }

      if (intent?.instrument?.strike) {
        const { isIndicatorBased } = StrikeUtility.getStrikeObj(intent.instrument.strike);

        if (isIndicatorBased) {
          newIntent.instrument.strike = getUpdatedStrike(newIntent.instrument.strike)
          || newIntent.instrument.strike;
        }
      }

      if (newIntent.target) {
        const { label: targetLabel } = newIntent.target;
        const { basketId, intentId } = extractIdsFromLabel(targetLabel || '');
        const updatedIntentId = basketId ? `${labelMappedConfig[intentId]}(${basketId})`
          : labelMappedConfig[intentId];
        const updatedTargetLabel = updatedIntentId || targetLabel;
        newIntent.target.label = updatedTargetLabel;
      }

      return newIntent;
    });
  };

  const getUpdatedSignal = (signals) => {
    return _.map(signals, (signal) => {
      const newSignal = _.cloneDeep(signal);
      const { leftOperand, rightOperand } = newSignal;

      return {
        ...newSignal,
        leftOperand: getUpdatedOperand(leftOperand) || newSignal.leftOperand,
        rightOperand: getUpdatedOperand(rightOperand) || newSignal.rightOperand,
      };
    });
  };

  const updatedCases = cases.map((caseConfig) => {
    const {
      entry: { intents: entryIntents },
      adjustments,
    } = caseConfig;

    const updatedEntryIntents = getUpdatedIntents(entryIntents);

    const updatedAdjustments = adjustments.map((adjustment) => {
      return {
        ...adjustment,
        intents: getUpdatedIntents(adjustment.intents) || adjustment.intents,
        signal: getUpdatedSignal(adjustment.signal) || adjustment.signal,
      };
    });

    return {
      ...caseConfig,
      entry: {
        ...caseConfig.entry,
        intents: updatedEntryIntents || []
      },
      adjustments: updatedAdjustments || []
    };
  });

  const updatedPipeConfigs = _.map(pipeConfigs, (pipeConfig) => {
    const inputSchema = PipeDefinitionUtility.getPipeInputSchemaByType(pipeConfig.type);
    const signalSchema = _.find(inputSchema, ({ type }) => type === pipeInputSchemaTypes.signal);

    if (_.isEmpty(signalSchema)) return pipeConfig;

    const signal = _.get(pipeConfig.config, signalSchema.name);

    return {
      ...pipeConfig,
      config: {
        ...pipeConfig.config,
        [signalSchema.name]: getUpdatedSignal(signal)
      },
    };
  });

  return { updatedCases, updatedPipeConfigs, lastLabelIndex: _.size(labelMappedConfig) };
};
