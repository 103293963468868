import { INTENT_TYPES } from 'v2/common/constants/index';
import { getDefaultIdentifier } from 'v2/modules/withRunForm/configs/index';
import { getTypeBasedValue } from './config';

export const moveToCostBasedAdjustmentAdder = (
  intent, lastAdjustmentConfigIndex, moveToCostOptionIndexes
) => {
  const {
    identifier, stopLoss, instrument: { instrumentIndex }
  } = intent;
  const currentAdjustmentConfigIndex = lastAdjustmentConfigIndex;

  return _.map(moveToCostOptionIndexes, (moveToCostOptionIndex) => {
    const {
      segment, adjustmentConfigIndex, optionConfigIndex, label
    } = identifier;

    let newLeftOperand = '';
    let newSegment = segment;
    if (optionConfigIndex !== null) newSegment += `_${optionConfigIndex}`;
    if (adjustmentConfigIndex !== null) newSegment += `_${adjustmentConfigIndex}`;

    // TODO: Use `buildLegProperty` from ./config.js
    newLeftOperand += `individualStop.${instrumentIndex}.${newSegment}.${getTypeBasedValue(stopLoss?.type)}`;

    const newIndentifer = {
      ...getDefaultIdentifier(),
      type: 'Legacy',
      segment,
      caseIndex: identifier.caseIndex,
      adjustmentConfigIndex: currentAdjustmentConfigIndex,
      instrumentIndex,
      optionConfigIndex: moveToCostOptionIndex,
    };

    const newTarget = {
      type: 'Legacy',
      label: `${label}`,
      iteration: 0,
      segment,
      caseIndex: identifier.caseIndex,
      adjustmentConfigIndex: null,
      instrumentIndex,
      optionConfigIndex: moveToCostOptionIndex,
    };

    const result = {
      uiConfigs: {
        isAutoAdjustment: true,
        adjustmentMode: 'moveToCost'
      },
      signal: [{
        conditionOperator: '',
        leftOperand: newLeftOperand,
        operator: 'equalOrAbove',
        rightOperand: stopLoss?.value,
      }],
      intents: [{
        type: INTENT_TYPES.UpdateStopLoss,
        target: newTarget,
        identifier: newIndentifer,
      }]
    };
    return result;
  });
};
