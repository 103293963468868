const configuration = {
  type: 'movingAverageConvergenceDivergence',
  defaults: {
    type: 'movingAverageConvergenceDivergence',
    name: 'macd',
    config: {
      fastPeriod: 12,
      slowPeriod: 26,
      signalPeriod: 9,
      candleInterval: '1 minutes',
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'MACD',
    'movingAverageConvergenceDivergence'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      'macd',
      'signal',
      'histogram'
    ];

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: name === property ? name : `${name} ${property}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
