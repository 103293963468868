import { useCallback } from 'react';
import { getStrikeIndexBasedOptions } from './config';
import { StrikeUtility } from './utility';

const useStrikeBased = (strike, onChangeStrike, isIndexInstrumentBased, optionType) => {
  const {
    isIndicatorBased,
    isIndexBased,
    value,
    valuePath,
    comparison,
    type,
    valueType,
  } = StrikeUtility.getStrikeObj(strike);

  const options = getStrikeIndexBasedOptions(type, isIndexInstrumentBased, optionType);

  const onChangeType = useCallback((event) => {
    const [newType, newComparison] = event.target.value.split('-');
    const newStrikeObj = StrikeUtility.getStrikeConfigBasedOnType(newType, newComparison);

    onChangeStrike(newStrikeObj);
  }, [onChangeStrike]);

  const onChangeValuePath = (newValuePath) => {
    onChangeStrike({ ...strike, valuePath: newValuePath });
  };

  const onChangeValue = (newValue) => {
    onChangeStrike({ ...strike, [valueType]: Number(newValue) });
  };

  return {
    isIndexBased,
    value,
    type,
    valuePath,
    options,
    isIndicatorBased,
    onChangeType,
    comparison,
    onChangeValuePath,
    onChangeValue,
  };
};

export default useStrikeBased;
