import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PaymentMoneyDisplay from 'ui/Plans/components/common/PaymentMoneyDisplay';

const propTypes = {
  purchaseList: PropTypes.arrayOf(PropTypes.shape({})),
  isShowPlan: PropTypes.bool,
  selectedPurchase: PropTypes.shape({}),
  handleSelectedPurchase: PropTypes.func.isRequired
};

const defaultProps = { purchaseList: [], selectedPurchase: {} };

const PurchaseSet = ({
  purchaseList, isShowPlan, handleSelectedPurchase, selectedPurchase
}) => {
  const selectedId = _.get(selectedPurchase, 'id', '');

  if (_.isEmpty(purchaseList)) return null;

  useEffect(() => {
    handleSelectedPurchase(_.head(_.orderBy(purchaseList, 'price', 'desc')));
  }, []);

  const renderPurchaseList = (list) => {
    const {
      id, price, addon_name: addonName, plan_name: planName, currentNetPrice,
      historic_run_limit: historicLimit, live_run_limit: liveLimit, strike_price: strikePrice,
      cycle_duration_in_months: cycleDurationInMonths
    } = list;

    const name = isShowPlan ? planName : addonName;
    const historicLimitStatement = `${historicLimit}`;

    const renderAddon = () => {
      return (
        <label htmlFor={id}>
          <div className="quota-values justify-content-between">
            <div className="quota-head">
              <div className="plan-name">{_.upperCase(name)}</div>
              <PaymentMoneyDisplay
                price={price}
                currentNetPrice={currentNetPrice}
                strikePrice={strikePrice}
                cycleDurationInMonths={cycleDurationInMonths}
              />
            </div>

            <div className="line" />

            <div className="values align-self-center flex-none">
              {historicLimitStatement}
              <small className="ml-1">Credits</small>
            </div>

          </div>
        </label>
      );
    };

    const renderPlan = () => {
      return (
        <label htmlFor={id}>
          <div className="quota-head">
            <div className="plan-name">{_.upperCase(name)}</div>
            <div className="d-flex gap-5">
              <PaymentMoneyDisplay
                price={price}
                currentNetPrice={currentNetPrice}
                strikePrice={strikePrice}
                cycleDurationInMonths={cycleDurationInMonths}
              />
            </div>
          </div>
          <hr />
          <div className="quota-values">
            <div className="values">
              {historicLimit}
              <small className="d-block">Backtest Credits</small>
            </div>
            <div className="line" />
            <div className="values">
              {liveLimit}
              <small className="d-block">Live Deployment</small>
            </div>

          </div>
        </label>
      );
    };

    return (
      <div className="plans-box quota-details" key={id}>
        <input
          type="radio"
          id={id}
          name="select"
          value={id}
          onChange={() => { }}
          checked={id === selectedId}
          onClick={() => handleSelectedPurchase(list)}
        />
        {isShowPlan ? renderPlan() : renderAddon()}
      </div>
    );
  };

  return _.map(_.orderBy(purchaseList, 'price', 'desc'), renderPurchaseList);
};

PurchaseSet.propTypes = propTypes;
PurchaseSet.defaultProps = defaultProps;

export default PurchaseSet;
