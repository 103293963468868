import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { removeExchangeFromInstrument } from 'common/utils/InstrumentUtils';
import MoneyDisplay from 'common/components/MoneyDisplay';
import TransactionType from 'common/components/TransactionType';
import moment from 'moment';
import { STATUS_STYLE_CONFIG } from 'modules/OrderDetails/config';
import PaginationFooter from 'common/components/PaginationFooter/index';
import { getDisplayRows } from '../helper';

const propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({}))
};

const defaultProps = { orders: [] };

const OrderDetails = ({ orders }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const ordersToDisplay = getDisplayRows(orders, currentPageIndex, 20);

  const renderOrder = (order, idx) => {
    const {
      instrument, is_entry: isEntry, is_stop_loss: isStopLoss, quantity, signal_price: signalPrice,
      signal_time: signalTime, traded_price: tradedPrice, traded_time: tradedTime,
      transaction_type: transctionType, status, trading_symbol: tradingSymbol
    } = order;

    const statusConfig = _.get(STATUS_STYLE_CONFIG, status);
    const statusText = _.get(statusConfig, 'statusText', status);
    const statusTextClassName = _.get(statusConfig, 'statusTextClassName', 'text-muted');

    return (
      <tr key={idx}>
        <td>{removeExchangeFromInstrument(instrument)}</td>
        <td><TransactionType type={transctionType} /></td>
        <td>{isEntry ? <i className="material-icons-outlined text-success">check_circle</i> : '-'}</td>
        <td>
          {!isEntry && !isStopLoss
            ? <i className="material-icons-outlined text-success">check_circle</i> : '-'}
        </td>
        <td>
          {!isEntry && isStopLoss
            ? <i className="material-icons-outlined text-success">check_circle</i> : '-'}
        </td>
        <td>{quantity}</td>
        <td>
          {signalPrice
            ? <MoneyDisplay shouldAvoidDecimals shouldColor>{signalPrice}</MoneyDisplay> : '-'}
        </td>
        <td>
          <span className="time">
            {signalTime ? moment(signalTime).format('YYYY-MM-DD HH:mm:ss') : '-'}
          </span>
        </td>
        <td>
          {tradedPrice
            ? <MoneyDisplay shouldAvoidDecimals shouldColor>{tradedPrice}</MoneyDisplay> : '-'}
        </td>
        <td>
          <span className="time">
            {tradedTime ? moment(tradedTime).format('YYYY-MM-DD HH:mm:ss') : '-'}
          </span>
        </td>
        <td>{tradingSymbol}</td>
        <td>
          <span className={statusTextClassName}>{_.startCase(statusText)}</span>
        </td>
      </tr>
    );
  };

  if (_.isEmpty(orders)) {
    return <div className="invalid-feedback d-block text-center">No Orders Placed</div>;
  }

  return (
    <div>
      <div className="table-responsive mt-3">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Instrument</th>
              <th>Transaction Type</th>
              <th>Entry</th>
              <th>Exit</th>
              <th>Stop Loss</th>
              <th>Quantity</th>
              <th>Signal Price</th>
              <th>Signal Time</th>
              <th>Traded Price</th>
              <th>Traded Time</th>
              <th>Trading Symbol</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {_.map(ordersToDisplay, renderOrder)}
          </tbody>
        </table>
      </div>

      <div className="mt-2">
        <PaginationFooter
          total={orders}
          currentPageIndex={currentPageIndex}
          perPage={20}
          onCurrentPageChange={(paramPageIndex) => setCurrentPageIndex(paramPageIndex)}
        />
      </div>
    </div>
  );
};

OrderDetails.propTypes = propTypes;
OrderDetails.defaultProps = defaultProps;

export default OrderDetails;
