// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
// Rails 6 default commenting for now.
// require("@rails/activestorage").start()
// Rails 6 by default generates a channel folder with js files. ignoring those for now
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const componentRequireContext = require.context('packs/rails_ujs', true);
const ReactRailsUJS = require('react_ujs');
const Sentry = require('@sentry/browser');

Sentry.init({
  dsn: SENTRY_DSN,
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'Error: Request failed with status code 404'
  ],
});

Sentry.setUser({ email: window.currentUserEmail });
Sentry.setTags({ service: 'frontend-javascript' });
ReactRailsUJS.useContext(componentRequireContext);
