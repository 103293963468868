import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'signal',
  defaults: {
    type: 'signal',
    name: 'signal',
    config: {
      signal: [
        {
          leftOperand: '',
          operator: '',
          rightOperand: '',
          conditionOperator: 'and'
        }
      ],
    }
  },
  searchTerms: [
    'signal',
    'condition'
  ],
  configFormOptions: {
    valuePathEditable: false,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  inputSchema: [
    {
      name: 'signal',
      type: pipeInputSchemaTypes.signal,
      label: 'Signal',
    }
  ],
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    return [
      {
        name,
        type: schemaTypes.boolean,
        indicatorType: type,
        label: name,
        category: 'indicator',
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      }
    ];
  },
  isEnabled: true
};
export default configuration;
