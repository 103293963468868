import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { PRODUCT_TYPE, TRADE_TYPES } from 'modules/QuantBuilder/config';
import ErrorMesssage from '../ErrorMesssage';

const propTypes = {
  product: PropTypes.string.isRequired,
  onChangeProduct: PropTypes.func.isRequired,
  tradingType: PropTypes.string
};
const defaultProps = {
  tradingType: ''
};

const ProductType = ({ product, onChangeProduct, tradingType }) => {
  const { positional } = TRADE_TYPES;
  const [optionTypeBtnName] = useState(_.uniqueId('optionTypeBtnName-'));
  const optionTypeInputId = _.uniqueId('optionType');
  const { errors, register } = useFormContext();

  const validatorConfig = {
    validate: (value) => {
      if (!value) return '*value required';
      if (value === PRODUCT_TYPE.mis && tradingType === positional) return '*mis is for Intraday';

      return null;
    }
  };
  const validatorProps = { ref: register(validatorConfig) };

  const optionTypeStyle = classNames(
    'product-type',
    'align-items-center',
    'gap-10',
    'mt-1',
    'ml-1',
    'd-flex',
    'selector-radio-btn'
  );

  const radioBtnStyle = classNames(
    'form-check-input',
    { 'is-invalid': !!errors[optionTypeBtnName] }
  );

  const renderRadioBtn = (optionType, key) => {
    return (
      <div className="form-check form-check-sm" key={key}>
        <input
          {...validatorProps}
          className={radioBtnStyle}
          type="radio"
          name={optionTypeBtnName}
          id={`${optionTypeInputId}-${optionType}`}
          value={optionType}
          tabIndex="-100"
          checked={optionType === product}
          onChange={() => onChangeProduct(optionType)}
        />
        <label className="form-check-label" htmlFor={`${optionTypeInputId}-${optionType}`}>
          {_.startCase(key)}
        </label>
      </div>
    );
  };

  const optionTypeRadioBtns = _.map(PRODUCT_TYPE, renderRadioBtn);

  const renderErrorMessage = () => {
    if (!errors[optionTypeBtnName]) return null;

    const { message } = errors[optionTypeBtnName];
    return <ErrorMesssage error={{ message }} />;
  };

  return (
    <div className={optionTypeStyle}>
      {optionTypeRadioBtns}
      {renderErrorMessage()}
    </div>
  );
};

ProductType.propTypes = propTypes;
ProductType.defaultProps = defaultProps;

export default ProductType;
