import _ from 'lodash';

const instrumentIndexToDisplayNameMap = {
  0: '1st instrument - ',
  1: '2nd instrument - '
};

const segmentKeyToDisplayNameMap = {
  equity: 'equity',
  future: 'future',
  option_0: '1st option leg',
  option_1: '2nd option leg',
  option_2: '3rd option leg',
  option_3: '4th option leg',
  option_4: '5th option leg',
  option_5: '6th option leg',
  option_6: '7th option leg',
  option_7: '8th option leg'
};

const schemasToDisplayNameMap = {
  lossPercentage: 'stop loss %',
  gainPercentage: 'stop gain %'
};

function getIndividualStopSchema(isPairTrade, instrumentIndex, segmentKey) {
  const instrumentDisplayName = isPairTrade
    ? instrumentIndexToDisplayNameMap[instrumentIndex]
    : '';

  return _.chain(schemasToDisplayNameMap)
    .map((displayName, schema) => {
      return {
        type: 'number',
        category: 'individualStop',
        name: `individualStop.${instrumentIndex}.${segmentKey}.${schema}`,
        label: `${instrumentDisplayName}`
          + `${segmentKeyToDisplayNameMap[segmentKey]} `
          + `${displayName}`
      };
    })
    .value();
}

export const buildIndividualStopOutputSchema = (orderConfigs) => {
  if (_.isEmpty(orderConfigs)) {
    return [];
  }

  const [orderConfig] = orderConfigs;
  const { transactionConfigs = [] } = orderConfig;
  const isPairTrade = _.size(transactionConfigs) === 2;

  return _.chain(transactionConfigs)
    .flatMap((transactionConfig, instrumentIndex) => {
      const {
        equityConfig = {},
        futureConfig = {},
        optionConfigs = []
      } = transactionConfig;
      const equitySchema = equityConfig.entryType
        ? getIndividualStopSchema(isPairTrade, instrumentIndex, 'equity')
        : [];
      const futureSchema = futureConfig.entryType
        ? getIndividualStopSchema(isPairTrade, instrumentIndex, 'future')
        : [];
      const optionsSchema = _.chain(optionConfigs)
        .flatMap((optionConfig, optionIndex) => {
          return getIndividualStopSchema(
            isPairTrade,
            instrumentIndex,
            `option_${optionIndex}`
          );
        })
        .value();

      return _.concat(equitySchema, futureSchema, optionsSchema);
    })
    .value();
};
