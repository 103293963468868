import React from 'react';
import moment from 'moment';
import qs from 'qs';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

// Project imports
import LoadingSpinner from 'common/components/LoadingSpinner';
import { runPropTypes } from 'common/propTypes';
import { VIEW_TYPE, DESKTOP_WIDTH } from '../config';
import RunListView from './RunListView';
// import MoveFolderModal from './RunListView/MoveFolderModal';
import MultipleSelectionActionBtns from './MultipleSelectionActionBtns';
import RunGridView from './RunGridView/index';

const propTypes = {
  folder: PropTypes.shape({}),
  runs: PropTypes.arrayOf(runPropTypes).isRequired,
  onDeleteRun: PropTypes.func.isRequired,
  onUpdateRun: PropTypes.func.isRequired,
  runListClassName: PropTypes.string,
  isFilteredRuns: PropTypes.bool
};

const defaultProps = { folder: null, runListClassName: '', isFilteredRuns: false };
const I18N_SCOPE = { scope: 'folders.show' };

export default class RunList extends React.Component {
  constructor(props) {
    super(props);

    moment.updateLocale(I18n.locale, {
      postformat: (string) => string
    });

    this.state = {
      isLoading: false,
      view: window.innerWidth > DESKTOP_WIDTH ? VIEW_TYPE.list : VIEW_TYPE.grid,
      selectedRunIds: []
    };
  }

  onSelectRuns = (selectedRunId, isSelected) => {
    const { selectedRunIds } = this.state;
    const updatedRunIds = isSelected
      ? [...selectedRunIds, selectedRunId]
      : selectedRunIds.filter((runId) => runId !== selectedRunId);

    this.setState({ selectedRunIds: updatedRunIds });
  }

  renderViewTypeSelector = () => (
    <ToggleButtonGroup
      bsPrefix="btn-group btn-group-toggle view-options"
      type="radio"
      name="options"
      defaultValue={VIEW_TYPE.list}
      onChange={(viewType) => this.setState({ view: viewType })}
    >
      <ToggleButton bsPrefix="btn list-btn" value={VIEW_TYPE.list}>
        <i className="material-icons-outlined">format_list_bulleted</i>
      </ToggleButton>
      <ToggleButton bsPrefix="btn grid-btn" value={VIEW_TYPE.grid}>
        <i className="material-icons-outlined">grid_view</i>
      </ToggleButton>
    </ToggleButtonGroup>
  )

  renderBtns = () => {
    const { folder, runs } = this.props;
    const { selectedRunIds } = this.state;
    const queryParams = { locale: I18n.locale, folder_id: _.get(folder, 'id', null) };
    const selectedRuns = _.filter(runs, (run) => _.includes(selectedRunIds, run.id));

    return (
      <div className="d-flex justify-content-between align-items-center folder-head-options">
        <a
          className="align-self-center btn btn-primary rounded-pill track"
          href={`/runs/new/?${qs.stringify(queryParams)}`}
          data-track-category="Folder"
          data-track-action="Create Quant Run"
          data-track-label="Folder Trackings"
        >
          <i className="material-icons-outlined mr-1 tx-20 align-text-bottom">insert_drive_file</i>
          {I18n.t('new_runs', I18N_SCOPE)}
        </a>
        <div className="d-flex align-items-center gap-15">
          {(_.size(selectedRuns) > 1) && (
          <MultipleSelectionActionBtns
            runs={selectedRuns}
            folder={folder}
          />
          )}
          {!_.isEmpty(runs) && this.renderViewTypeSelector()}
        </div>
      </div>
    );
  }

  renderEmptyMessage = () => {
    const { isLoading } = this.state;
    const { runs } = this.props;
    if (runs.length === 0 && !isLoading) {
      return (
        <div className="empty-runs">
          <i className="icons-folder tx-20 mr-1" />
          <span className="align-text-bottom invalid-feedback d-block">No strategies</span>
        </div>
      );
    }

    return null;
  }

  render() {
    const { isLoading, view } = this.state;
    const {
      runs, onDeleteRun, onUpdateRun, runListClassName, isFilteredRuns
    } = this.props;
    const ListComponent = (view === VIEW_TYPE.list) ? RunListView : RunGridView;

    return (
      <div className={runListClassName}>
        <LoadingSpinner isLoading={isLoading}>
          <>
            {this.renderBtns()}
            <ListComponent
              runs={runs}
              onDeleteRun={onDeleteRun}
              onUpdateRun={onUpdateRun}
              onSelectRuns={this.onSelectRuns}
              isFilteredRuns={isFilteredRuns}
            />
          </>
          {this.renderEmptyMessage()}
        </LoadingSpinner>
      </div>
    );
  }
}

RunList.propTypes = propTypes;
RunList.defaultProps = defaultProps;
