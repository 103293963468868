import React from 'react';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { emptyQuantConfig } from 'modules/QuantBuilder/config';
import reducers from './reducers';
import QuantBuilder from './QuantBuilder';

const configureStore = (props = {}) => {
  const {
    isClonedQuant,
    runType,
    onUpdate,
    outputSchema,
    quantConfig,
    quantConfigValidators,
    startDate,
    endDate,
    validatorErrors,
    orderType,
    segment,
    onChangeSegment,
    segments
  } = props;

  const initialState = {
    isClonedQuant,
    runType,
    outputSchema: outputSchema || [],
    quantConfig: quantConfig || emptyQuantConfig(),
    quantConfigValidators: quantConfigValidators || {},
    startDate,
    endDate,
    validatorErrors: validatorErrors || [],
    orderType,
    segment,
    onChangeSegment,
    segments
  };

  const propogateQuantConfigChanges = (reduxStore) => (next) => (action) => {
    const result = next(action);

    onUpdate(_.get(reduxStore.getState(), 'quantConfig'));
    return result;
  };
  return createStore(reducers, initialState, applyMiddleware(thunk, propogateQuantConfigChanges));
};

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const Main = (props = {}) => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <QuantBuilder />
    </Provider>
  );
};

export default Main;
