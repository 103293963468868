import React from 'react';
import PropTypes from 'prop-types';

import { signalConfigPropTypes, outputSchemaPropTypes } from 'common/propTypes';
import classNames from 'classnames';
import RemoveSignalConfig from './components/RemoveSignalConfig';
import SignalConfigBuilder from './components/SignalConfigBuilder';
import AddSignalConfig from './components/AddSignalConfig';

const propTypes = {
  isClonedQuant: PropTypes.bool.isRequired,
  signalConfigs: PropTypes.arrayOf(signalConfigPropTypes).isRequired,
  onSignalConfigsChange: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  signalConfigField: PropTypes.string.isRequired,
  signalValidator: PropTypes.shape({}).isRequired,
  isHistoricRun: PropTypes.bool.isRequired,
  segment: PropTypes.string.isRequired,
  isAdjustmentSignal: PropTypes.bool
};
const defaultProps = {
  isAdjustmentSignal: false
};

const SignalConfigsBuilder = (props) => {
  const {
    isClonedQuant,
    signalConfigs,
    onSignalConfigsChange,
    outputSchema,
    signalConfigField,
    signalValidator,
    isHistoricRun,
    segment,
    isAdjustmentSignal
  } = props;
  const hasMoreThanOneSignalConfig = signalConfigs.length > 1;

  const onSignalConfigChange = (signalConfigIndex, newSignalConfig) => {
    const newSignalConfigs = _.cloneDeep(signalConfigs);

    newSignalConfigs[signalConfigIndex] = newSignalConfig;
    onSignalConfigsChange(newSignalConfigs);
  };

  const renderSignalConfigBuilder = (signalConfig, signalConfigIndex) => {
    const signalConditionClassName = classNames('signal-condition', {
      'pr-9': hasMoreThanOneSignalConfig
    });

    return (
      <div className={signalConditionClassName} key={signalConfigIndex}>
        <SignalConfigBuilder
          isClonedQuant={isClonedQuant}
          shouldShowConditionOperator={!!signalConfigIndex}
          signalConfigField={signalConfigField}
          signalValidator={signalValidator}
          signalConfig={signalConfig}
          isHistoricRun={isHistoricRun}
          isAdjustmentSignal={isAdjustmentSignal}
          outputSchema={outputSchema}
          segment={segment}
          onSignalConfigChange={(newSignalConfig) => onSignalConfigChange(signalConfigIndex, newSignalConfig)}
        />
        {hasMoreThanOneSignalConfig && (
          <RemoveSignalConfig
            signalConfigs={signalConfigs}
            indexToRemove={signalConfigIndex}
            onRemoveSignalConfig={onSignalConfigsChange}
          />
        )}
      </div>
    );
  };

  return (
    <div className="signal-configs-builder d-flex flex-column gap-10">
      {_.map(signalConfigs, renderSignalConfigBuilder)}
      <AddSignalConfig
        onAddSignalConfig={onSignalConfigsChange}
        signalConfigs={signalConfigs}
      />
    </div>
  );
};

SignalConfigsBuilder.propTypes = propTypes;
SignalConfigsBuilder.defaultProps = defaultProps;
export default SignalConfigsBuilder;
