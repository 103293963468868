import React from 'react';
import PropTypes from 'prop-types';
import { setOrderConfigs } from 'modules/QuantBuilder/actions';
import { connect } from 'react-redux';
import { outputSchemaPropTypes, signalConfigPropTypes } from 'common/propTypes';
import SignalSentence from 'common/components/SignalSentence';
import SignalConfigsBuilderWrapper from '../../OrderConfigBuilder/SignalConfigsBuilderWrapper';
import SuggestionSignals from '../../OrderConfigBuilder/SuggestionSignals';

const propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    orderConfigIndex: PropTypes.number,
    signalConfigs: PropTypes.arrayOf(signalConfigPropTypes),
    signalConfigField: PropTypes.string,
  }).isRequired,
  dispatchSetOrderConfigs: PropTypes.func.isRequired,
  adjustmentSignalConfigIndex: PropTypes.number.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired
};

const defaultProps = {};

const AdjustmentSignalConfig = ({
  config, dispatchSetOrderConfigs, adjustmentSignalConfigIndex, outputSchema
}) => {
  const { signalConfigs, title } = config;

  const onUpdateSignalConfig = (updatedConfig) => {
    const {
      newOrderConfigs, orderConfigIndex, stateSignalConfigs
    } = updatedConfig;

    const updatedOrderConfig = _.map(newOrderConfigs, (newOrderConfig, idx) => {
      if (idx === orderConfigIndex) {
        const { adjustmentSignalConfigs } = newOrderConfig;

        const updatedAdjustmentSignalConfigs = _.map(adjustmentSignalConfigs,
          (adjustmentSignalConfig, adjustmentIndex) => {
            if (adjustmentIndex === adjustmentSignalConfigIndex) { return stateSignalConfigs; }

            return adjustmentSignalConfig;
          });

        return { ...newOrderConfig, adjustmentSignalConfigs: updatedAdjustmentSignalConfigs };
      }
      return newOrderConfig;
    });

    dispatchSetOrderConfigs(updatedOrderConfig);
  };

  return (
    <div className="border-bottom py-1 px-2">
      <div className="row align-items-center">
        <div className="col-md-2">
          {title}
        </div>
        <div className="col-md-10 mt-1 mt-md-0 d-flex justify-content-between align-items-center">
          <SignalSentence
            signalConfigs={signalConfigs}
            outputSchema={outputSchema}
          />
          &nbsp;&nbsp;&nbsp;
          <SignalConfigsBuilderWrapper
            config={{ ...config, signalConfigField: 'entrySignalConfigs' }}
            // for validation errors i overrided.. after validation added.. make sure to remove

            onUpdateSignalConfig={onUpdateSignalConfig}
            isAdjustmentSignal
          />
        </div>
      </div>
      <SuggestionSignals
        config={config}
        onUpdateSignalConfig={onUpdateSignalConfig}
      />
    </div>
  );
};

AdjustmentSignalConfig.propTypes = propTypes;
AdjustmentSignalConfig.defaultProps = defaultProps;

const mapStatetoProps = (state) => {
  return {
    outputSchema: _.get(state, 'outputSchema')
  };
};

const mapDispatchToProp = (dispatch) => ({
  dispatchSetOrderConfigs: (newOrderConfigs) => dispatch(setOrderConfigs(newOrderConfigs))
});

export default connect(mapStatetoProps, mapDispatchToProp)(AdjustmentSignalConfig);
