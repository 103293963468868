const configuration = {
  type: 'averageDirectionalIndex',
  defaults: {
    type: 'averageDirectionalIndex',
    name: 'adx',
    config: {
      DILength: 14,
      ADXSmoothing: 14,
      candleInterval: '1 minutes',
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'ADX',
    'averageDirectionalIndex'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    const properties = [
      { label: 'adx+dmi', value: 'pdi' },
      { label: 'adx-dmi', value: 'mdi' },
      { label: 'adx', value: 'adx' },
    ];

    return _.map(properties, (property) => {
      const { label, value } = property;

      return {
        name: `${name}.${value}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: name === 'adx' ? label : `${name} ${label}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
