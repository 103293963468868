import { ORDER_TYPES, PRODUCT_TYPES, RUN_TYPES } from 'v2/common/constants/index';

export const onChangeOrderType = ({
  orderType,
  quantConfig,
  runType
}) => {
  const omitKeysForNotActual = [
    'entryOrderType', 'exitOrderType', 'product', 'entryOrderModification', 'exitOrderModification',
    'isPlaceOptionsEntryBuyOrdersFirst',
    'isPlaceStoplossOrderToBroker', 'isSquareOffTransactionIfEntryFailed',
    'stopAndLimitPriceDifferenceInPercentage'
  ];
  const isLive = runType === RUN_TYPES.live;
  const isActual = orderType !== ORDER_TYPES.paper.value && isLive;

  let sanitizedQuantConfig = _.cloneDeep(quantConfig);
  if (!isActual) {
    sanitizedQuantConfig = _.omit(sanitizedQuantConfig, omitKeysForNotActual);
  }
  if (isActual) {
    const {
      product, entryOrderType, entryOrderModification,
      exitOrderType, exitOrderModification, limitOrderBufferPercentage,
      slOrderPlaceBasedOnPrice, isPlaceOptionsEntryBuyOrdersFirst,
      stopAndLimitPriceDifferenceInPercentage, isPlaceStoplossOrderToBroker,
      isSquareOffTransactionIfEntryFailed,
    } = quantConfig;

    if (!product) sanitizedQuantConfig.product = PRODUCT_TYPES.mis.value;
    if (!entryOrderType) sanitizedQuantConfig.entryOrderType = 'market';
    if (_.isEmpty(entryOrderModification)) sanitizedQuantConfig.entryOrderModification = {};
    if (!exitOrderType) sanitizedQuantConfig.exitOrderType = 'market';
    if (isPlaceOptionsEntryBuyOrdersFirst === undefined) {
      sanitizedQuantConfig.isPlaceOptionsEntryBuyOrdersFirst = true;
    }
    if (isPlaceStoplossOrderToBroker === undefined) {
      sanitizedQuantConfig.isPlaceStoplossOrderToBroker = true;
    }
    if (!stopAndLimitPriceDifferenceInPercentage) {
      sanitizedQuantConfig.stopAndLimitPriceDifferenceInPercentage = 0;
    }
    if (_.isEmpty(exitOrderModification)) sanitizedQuantConfig.exitOrderModification = {};
    if (!limitOrderBufferPercentage) sanitizedQuantConfig.limitOrderBufferPercentage = 0;
    if (isSquareOffTransactionIfEntryFailed === undefined) {
      sanitizedQuantConfig.isSquareOffTransactionIfEntryFailed = false;
    }
    if (!slOrderPlaceBasedOnPrice) sanitizedQuantConfig.slOrderPlaceBasedOnPrice = 'traded_price';
  }

  return { orderType, quantConfig: sanitizedQuantConfig, runType };
};
