import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Alert } from 'react-bootstrap';

import { getExpiringSoonItems } from 'common/api/user';
import { userPropTypes } from 'common/propTypes';
import { getLocalStorage, setLocalStorage } from 'common/utils/localstorage';

const propTypes = {
  currentUserDetails: userPropTypes
};
const defaultProps = {
  currentUserDetails: null
};

const LOCAL_STORAGE_KEY = 'hideExpiringSoon';
const today = moment().format('DD-MM-YYYY');
const renderEndDate = (endDate) => {
  return (
    <span className="end-date">
      {moment(endDate).format('dddd, Do MMMM YYYY')}
    </span>
  );
};

const renderAddon = (addon, endDate) => {
  return (
    <>
      Addon -&nbsp;
      <span className="item-name">{addon.addon_name}</span>
      &nbsp;expires on&nbsp;
      {renderEndDate(endDate)}
    </>
  );
};

const renderPlan = (plan, endDate) => {
  return (
    <div>
      <span className="item-name">{plan.plan_name}</span>
      &nbsp;Plan expires on&nbsp;
      {renderEndDate(endDate)}
    </div>
  );
};

const renderPurchaseItem = (purchaseItem) => {
  const { purchasable_type: purchasableType, end_date: endDate } = purchaseItem;
  let content = null;
  if (!endDate) {
    return null;
  }
  if (purchasableType === 'Addon') {
    content = renderAddon(purchaseItem.purchasable, endDate);
  }
  if (purchasableType === 'Plan') {
    content = renderPlan(purchaseItem.purchasable, endDate);
  }

  const classnames = classNames(
    'purchase-item',
    `item-type-${(purchasableType || '').toLowerCase()}`
  );

  return (
    <div className={classnames} key={purchaseItem.id}>
      {content}
    </div>
  );
};

const ExpiringSoonPurchasedItems = ({ currentUserDetails }) => {
  const [expiringSoonItems, setExpiringSoonItems] = useState([]);
  const [showExpiringSoon, setShowExpiringSoon] = useState(true);

  const hideExpiringSoon = () => {
    setLocalStorage(LOCAL_STORAGE_KEY, today);
    setShowExpiringSoon(false);
  };

  useEffect(() => {
    // To avoid warning. useEffect function should not return anything other than promise.
    // We cannot use async await directly in useEffect.
    const fetchItems = async () => {
      try {
        const expiringItems = await getExpiringSoonItems();
        const planActiveTillDate = currentUserDetails.plan_active_till.toString().slice(0, 10);

        const filteredExpiringItems = _.filter(expiringItems, (item) => {
          return (
            item.purchasable_type === 'Addon'
            || item?.end_date >= planActiveTillDate
          );
        });

        setExpiringSoonItems(filteredExpiringItems);
      } catch (e) {
        console.log('Error in fetching expiring soon items', e);
      }
    };
    fetchItems();
  }, []);

  if (getLocalStorage(LOCAL_STORAGE_KEY) <= today) {
    return null;
  }

  if (expiringSoonItems.length === 0 || !showExpiringSoon) {
    return null;
  }

  return (
    <div className="expiring-soon-container">
      <Alert variant="danger" onClose={hideExpiringSoon} dismissible>
        <Alert.Heading>Expiring Soon</Alert.Heading>
        {expiringSoonItems.map(renderPurchaseItem)}
      </Alert>
    </div>
  );
};

ExpiringSoonPurchasedItems.propTypes = propTypes;
ExpiringSoonPurchasedItems.defaultProps = defaultProps;

export default ExpiringSoonPurchasedItems;
