import { SEGMENT_CONFIG } from 'v2/common/constants/index';

const isSegmentMatched = (segment, intent) => {
  return intent?.identifier?.segment === segment;
};

const isOptionConfigIndexMatched = (optionConfigIndex, intent) => {
  return intent?.identifier?.optionConfigIndex === optionConfigIndex;
};

const isAdjustmentConfigIndexMatched = (adjustmentConfigIndex, intent) => {
  return intent?.identifier?.adjustmentConfigIndex === adjustmentConfigIndex;
};

export const getFormattedOperand = (operand, intents) => {
  const [type, , segmentWithIndex, field] = operand.split('.');
  const [segment, index1, index2] = segmentWithIndex.split('_');

  let optionConfigIndex;
  let adjustmentConfigIndex;

  if (_.includes([SEGMENT_CONFIG.future, SEGMENT_CONFIG.equity], segment)) {
    adjustmentConfigIndex = index1;
    optionConfigIndex = index2; // for this segment it will not be used
  } else {
    optionConfigIndex = index1;
    adjustmentConfigIndex = index2;
  }

  const baseIntent = _.find(intents, (intent) => {
    if (
      isSegmentMatched(segment, intent)
      && isOptionConfigIndexMatched(
        optionConfigIndex === undefined ? null : Number(optionConfigIndex),
        intent
      )
      && isAdjustmentConfigIndexMatched(
        adjustmentConfigIndex === undefined
          ? null
          : Number(adjustmentConfigIndex),
        intent
      )
    ) {
      return intent;
    }

    return null;
  });

  if (!baseIntent) return operand;

  const {
    identifier: { label },
  } = baseIntent;

  return `${type}.${label}.${field}`;
};

export const getSanitizedAdjustmentSignals = (signal, intents) => {
  return _.map(signal, (signalConfig) => {
    const { leftOperand, rightOperand } = signalConfig;
    const isIndividualStopIncludesLeftOperand = leftOperand.includes('individualStop.');
    const isIndividualStopIncludesRightOperand = rightOperand?.includes('individualStop.');

    let newLeftOperand = leftOperand;
    let newRightOperand = rightOperand;
    if (isIndividualStopIncludesLeftOperand) {
      newLeftOperand = getFormattedOperand(leftOperand, intents);
    }

    if (isIndividualStopIncludesRightOperand) {
      newRightOperand = getFormattedOperand(rightOperand, intents);
    }

    return {
      ...signalConfig,
      leftOperand: newLeftOperand,
      rightOperand: newRightOperand,
    };
  });
};
