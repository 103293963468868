import { INTENT_TYPES, SEGMENT_CONFIG } from 'v2/common/constants/index';
import _ from 'lodash';
import {
  isIndexInstrumentWithWeeklyExpiryOnly,
  isWeeklySupportedIndexInstrument
} from '../../components/InstrumentDetails/indexInstrumentsChecker';

const isEnabledCalenderSpread = _.get(
  window, 'SA_FEATURE_FLAGS.shouldShowCalenderSpread', false
) || window?.isAdmin || false;

export const getIntentsError = (intents, instrumentGroups) => {
  const openLegAndIntents = _.filter(intents, (intent) => _.includes([INTENT_TYPES.OpenLeg], intent.type));
  const optionIntents = _.filter(
    openLegAndIntents,
    (intent) => intent?.instrument?.type === SEGMENT_CONFIG.option
  );
  const optionIntentsWithExpiryDetails = _.chain(optionIntents)
    .filter((intent) => !_.isEmpty(_.get(intent, 'instrument.expiry', {})))
    .value();

  const setCalenderSpreadErrors = () => {
    return _.reduce(optionIntentsWithExpiryDetails, (acc, intent) => {
      return {
        ...acc,
        [`${intent.identifier.label}`]: {
          isErrorInExpiry: true,
          errorMessage: 'Please purchase a premium plan to use calender spread',
          isPremiumError: true
        }
      };
    }, {});
  };

  const setExpiryErrors = () => {
    const errorConfigs = {};
    if (_.isEmpty(_.flattenDeep(instrumentGroups))) {
      return errorConfigs;
    }
    _.chain(optionIntentsWithExpiryDetails)
      .forEach((intent) => {
        const { instrument: { instrumentIndex, expiry: { cycle } } } = intent;
        const isIndexInstrumentWithWeeklyExpiry = isIndexInstrumentWithWeeklyExpiryOnly(
          instrumentGroups, instrumentIndex
        );
        if (isIndexInstrumentWithWeeklyExpiry && cycle === 'monthly') {
          errorConfigs[`${intent.identifier.label}`] = {
            isErrorInExpiry: true,
            errorMessage: 'Selected Instrument has weekly expiry only',
            isPremiumError: false
          };
        }

        if (
          (
            !isWeeklySupportedIndexInstrument(instrumentGroups, instrumentIndex)
          ) && cycle === 'weekly'
        ) {
          errorConfigs[`${intent.identifier.label}`] = {
            isErrorInExpiry: true,
            errorMessage: 'Selected Instrument has monthly expiry only',
            isPremiumError: false
          };
        }
      })
      .value();

    return errorConfigs;
  };

  const isSame = _.chain(optionIntentsWithExpiryDetails)
    .map('instrument.expiry')
    .uniqWith(_.isEqual)
    .value().length === 1;

  const instrumentBasedExpiryErrors = setExpiryErrors();
  const hasToValidateForCalenderSpread = optionIntentsWithExpiryDetails.length > 1
  && !isSame && !isEnabledCalenderSpread;

  if (hasToValidateForCalenderSpread) {
    let expiryError = 'All option expires should be same or else ';
    expiryError += 'please purchase a premium plan to use multiple expiry';

    const errorConfigs = setCalenderSpreadErrors();
    return {
      errorMessage: expiryError,
      errorConfigs
    };
  }
  if (!_.isEmpty(instrumentBasedExpiryErrors)) {
    let expiryError = 'Please resolve the errors in the expiry ';
    expiryError += '(Some instrument have weekly or monthly expiry only)';

    return {
      errorMessage: expiryError,
      errorConfigs: instrumentBasedExpiryErrors
    };
  }

  return { errorMessage: '', errorConfigs: {} };
};
