import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { orderConfigsPropTypes, outputSchemaPropTypes } from 'common/propTypes';
import { SIGNAL_CONFIG } from 'modules/QuantBuilder/config';
import SignalSentence from 'common/components/SignalSentence';
import { setOrderConfigs } from 'modules/QuantBuilder/actions';
import TransactionsBuilderWrapper from './TransactionsBuilderWrapper';
import SignalConfigsBuilderWrapper from './SignalConfigsBuilderWrapper';
import Adjustments from '../components/Adjustments/index';
import SuggestionSignals from './SuggestionSignals';
import AdjustmentWrapper from '../components/AdjustmentWrapper/index';

const propTypes = {
  orderConfigIndex: PropTypes.number.isRequired,
  orderConfigs: orderConfigsPropTypes.isRequired,
  orderConfigErrors: PropTypes.shape({}),
  outputSchema: outputSchemaPropTypes.isRequired,
  dispatchSetOrderConfigs: PropTypes.func.isRequired
};

const defaultProps = {
  orderConfigErrors: {}
};

const I18N_SCOPE = { scope: 'advanced_quant_builder.index' };

const OrderConfigBuilder = (props) => {
  const getConfig = (signalConfigField) => {
    const { orderConfigIndex, orderConfigs, orderConfigErrors } = props;
    const entrySignalConfigs = _.get(orderConfigs, [orderConfigIndex, SIGNAL_CONFIG.ENTRY], []);
    const exitSignalConfigs = _.get(orderConfigs, [orderConfigIndex, SIGNAL_CONFIG.EXIT], []);
    const adjustmentSignalConfigs = _.get(orderConfigs, [
      orderConfigIndex, SIGNAL_CONFIG.ADJUSTMENT_SIGNAL_CONFIGS
    ], []);
    let signalConfigs = null;
    let title = null;
    let validatorErrors = [];

    switch (signalConfigField) {
      case SIGNAL_CONFIG.ENTRY:
        signalConfigs = entrySignalConfigs;
        title = I18n.t('entry_signal', I18N_SCOPE);
        validatorErrors = _.get(orderConfigErrors, [signalConfigField], []);
        break;
      case SIGNAL_CONFIG.EXIT:
        signalConfigs = exitSignalConfigs;
        title = I18n.t('exit_signal', I18N_SCOPE);
        validatorErrors = _.get(orderConfigErrors, [signalConfigField], []);
        break;
      case SIGNAL_CONFIG.ADJUSTMENT_SIGNAL_CONFIGS:
        signalConfigs = adjustmentSignalConfigs;
        title = I18n.t('adjustment_signals', I18N_SCOPE);
        validatorErrors = _.get(orderConfigErrors, [signalConfigField], []);
        break;
      case SIGNAL_CONFIG.TRANSACTION:
        title = I18n.t('transaction', I18N_SCOPE);
        validatorErrors = _.get(orderConfigErrors, [signalConfigField], []);
        break;
      default:
        title = '';
        signalConfigs = '';
    }

    return {
      signalConfigField,
      title,
      signalConfigs,
      orderConfigIndex,
      validatorErrors,
    };
  };

  const onUpdateSignalConfig = (updateConfig) => {
    const {
      newOrderConfigs, orderConfigIndex, signalConfigField, stateSignalConfigs
    } = updateConfig;
    const { dispatchSetOrderConfigs } = props;

    newOrderConfigs[orderConfigIndex] = {
      ...newOrderConfigs[orderConfigIndex],
      [signalConfigField]: stateSignalConfigs,
    };

    dispatchSetOrderConfigs(newOrderConfigs);
  };

  const renderEntrySignal = () => {
    const { orderConfigs, orderConfigIndex, outputSchema } = props;
    const entrySignalConfigs = _.get(orderConfigs, [orderConfigIndex, SIGNAL_CONFIG.ENTRY]);

    return (
      <div className="signal-config">
        <div className="row align-items-center">
          <div className="col-md-2">
            <i className="material-icons-outlined align-bottom mr-2"> login </i>
            <span className="font-weight-medium">{I18n.t('entry_signal', I18N_SCOPE)}</span>
          </div>
          <div
            className="col-md-10 mt-1 mt-md-0 d-flex justify-content-between align-items-center"
            id="entry-signal"
          >
            <SignalSentence
              signalConfigs={entrySignalConfigs}
              outputSchema={outputSchema}
            />
            &nbsp;&nbsp;&nbsp;
            <SignalConfigsBuilderWrapper
              config={getConfig(SIGNAL_CONFIG.ENTRY)}
              onUpdateSignalConfig={onUpdateSignalConfig}
            />
          </div>
        </div>
        <SuggestionSignals
          config={getConfig(SIGNAL_CONFIG.ENTRY)}
          onUpdateSignalConfig={onUpdateSignalConfig}
        />
      </div>
    );
  };

  const renderExitSignal = () => {
    const { orderConfigs, orderConfigIndex, outputSchema } = props;
    const exitSignalConfigs = _.get(orderConfigs, [orderConfigIndex, SIGNAL_CONFIG.EXIT]);

    return (
      <div className="signal-config">
        <div className="row align-items-center">
          <div className="col-md-2">
            <i className="material-icons-outlined align-bottom mr-2">logout</i>
            <span className="font-weight-medium">{I18n.t('exit_signal', I18N_SCOPE)}</span>
          </div>
          <div
            className="col-md-10 mt-1 mt-md-0 d-flex justify-content-between align-items-center"
            id="exit-signal"
          >
            <SignalSentence
              signalConfigs={exitSignalConfigs}
              outputSchema={outputSchema}
            />
            &nbsp;&nbsp;&nbsp;
            <SignalConfigsBuilderWrapper
              config={getConfig(SIGNAL_CONFIG.EXIT)}
              onUpdateSignalConfig={onUpdateSignalConfig}
            />
          </div>
        </div>
        <SuggestionSignals
          config={getConfig(SIGNAL_CONFIG.EXIT)}
          onUpdateSignalConfig={onUpdateSignalConfig}
        />
      </div>
    );
  };

  return (
    <div className="place-order-config-container">
      {renderEntrySignal()}
      {renderExitSignal()}
      <div className="place-order-transactions">
        <TransactionsBuilderWrapper config={getConfig(SIGNAL_CONFIG.TRANSACTION)} />
      </div>
      <AdjustmentWrapper adjustmentSignalConfigs={getConfig(SIGNAL_CONFIG.ADJUSTMENT_SIGNAL_CONFIGS)}>
        <Adjustments adjustmentSignalConfigs={getConfig(SIGNAL_CONFIG.ADJUSTMENT_SIGNAL_CONFIGS)} />
      </AdjustmentWrapper>
    </div>
  );
};

OrderConfigBuilder.propTypes = propTypes;
OrderConfigBuilder.defaultProps = defaultProps;

const mapStatetoProps = (state) => {
  return {
    orderConfigs: _.get(state, 'quantConfig.orderConfigs'),
    outputSchema: _.get(state, 'outputSchema')
  };
};

const mapDispatchToProp = (dispatch) => ({
  dispatchSetOrderConfigs: (newOrderConfigs) => dispatch(setOrderConfigs(newOrderConfigs))
});

export default connect(mapStatetoProps, mapDispatchToProp)(OrderConfigBuilder);
