import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setStopAndLimitPriceDifference,
  setGapBetweenBuyAndSellOrders
} from 'modules/QuantBuilder/actions';
import { validatorProptypes } from 'common/propTypes';
import { getValidationError } from 'common/utils/validators';
import { QUANT_CONFIG_FIELDS } from 'modules/QuantBuilder/config/index';
import StopLimitPriceDifferenceInPercentage from './StopLimitPriceDifferenceInPercentage';
import GapBetweenBuyAndSellOrdersInSeconds from './GapBetweenBuyAndSellOrdersInSeconds';

const propTypes = {
  dispatchWaitingTimeBetweenBuyAndSellOrdersInSeconds: PropTypes.func.isRequired,
  dispatchStopAndLimitPriceDifferenceInPercentage: PropTypes.func.isRequired,
  stopAndLimitPriceDifferenceInPercentage: PropTypes.number.isRequired,
  gapBetweenBuyAndSellOrdersInSeconds: PropTypes.number.isRequired,
  validatorErrors: validatorProptypes.isRequired
};
const defaultProps = {};

const getBuySellDiffAndStopLossDiffErrors = (validatorErrors) => {
  const {
    stopAndLimitPriceDifferenceInPercentage, gapBetweenBuyAndSellOrdersInSeconds
  } = QUANT_CONFIG_FIELDS;

  return []
    .concat(getValidationError(validatorErrors, stopAndLimitPriceDifferenceInPercentage))
    .concat(getValidationError(validatorErrors, gapBetweenBuyAndSellOrdersInSeconds));
};

const BuySellTimeAndStopLossDiff = (props) => {
  const {
    dispatchWaitingTimeBetweenBuyAndSellOrdersInSeconds,
    dispatchStopAndLimitPriceDifferenceInPercentage,
    stopAndLimitPriceDifferenceInPercentage, gapBetweenBuyAndSellOrdersInSeconds,
    validatorErrors
  } = props;

  const errors = getBuySellDiffAndStopLossDiffErrors(validatorErrors);

  return (
    <div className="orderTypes row align-items-center">
      <StopLimitPriceDifferenceInPercentage
        stopAndLimitPriceDifferenceInPercentage={stopAndLimitPriceDifferenceInPercentage}
        onChange={dispatchStopAndLimitPriceDifferenceInPercentage}
        labelClassName="col-md-2 mb-1"
        buttonClassName="col-md-2 mb-2 mb-md-0"
        errors={errors}
      />
      <GapBetweenBuyAndSellOrdersInSeconds
        gapBetweenBuyAndSellOrdersInSeconds={gapBetweenBuyAndSellOrdersInSeconds}
        onChange={dispatchWaitingTimeBetweenBuyAndSellOrdersInSeconds}
        labelClassName="col-md-2"
        buttonClassName="col-md-2"
        errors={errors}
      />
    </div>
  );
};

BuySellTimeAndStopLossDiff.propTypes = propTypes;
BuySellTimeAndStopLossDiff.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  stopAndLimitPriceDifferenceInPercentage:
    _.get(state, 'quantConfig.stopAndLimitPriceDifferenceInPercentage', ''),
  gapBetweenBuyAndSellOrdersInSeconds:
    _.get(state, 'quantConfig.gapBetweenBuyAndSellOrdersInSeconds', ''),
  validatorErrors: _.get(state, 'validatorErrors')
});

const mapDispatchToProps = (dispatch) => ({
  dispatchStopAndLimitPriceDifferenceInPercentage:
    (newDifference) => dispatch(setStopAndLimitPriceDifference(newDifference)),
  dispatchWaitingTimeBetweenBuyAndSellOrdersInSeconds:
    (newWaitingTimeBetween) => dispatch(
      setGapBetweenBuyAndSellOrders(newWaitingTimeBetween)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuySellTimeAndStopLossDiff);
