import React from 'react';
import PropTypes from 'prop-types';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { getUrlParams } from 'common/utils/urlUtils';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import Header from './Header';
import InstrumentAndTags from './InstrumentAndTags';
import ProfitableValues from './ProfitableValues';
import Footer from './Footer';

const propTypes = { strategy: PropTypes.shape({}).isRequired };

const defaultProps = {};

const StrategyCard = ({ strategy }) => {
  const description = _.get(strategy, 'description', '');
  const strategyId = _.get(strategy, 'id');
  const urlParams = getUrlParams();
  const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';
  const isPremium = _.get(strategy, 'is_premium', false);

  let strategyUrl = `/strategies/${strategyId}?locale=en`;
  if (isMinifiedPage) strategyUrl += '&minifiedpage=true';

  const strategyParentClassName = classNames('card mp-strategy-card', { 'premium-card': isPremium });

  return (
    <div className={strategyParentClassName}>
      <div className="card-header mp-strategy-head">
        <Header strategy={strategy} />
      </div>
      <div className="card-body mp-strategy-card-body">
        <div className="mp-strategy-description">
          <p className="description">{ReactHtmlParser(description)}</p>
        </div>
        <InstrumentAndTags strategy={strategy} />
        <div className="mt-md-auto"><ProfitableValues strategy={strategy} /></div>
        <Footer strategy={strategy} />
        <div className="d-flex justify-content-center">
          <a
            href={strategyUrl}
            rel="noreferrer"
            className="d-inline-flex btn btn-link btn-sm"
          >
            View Details
            <span className="material-icons-outlined tx-15"> chevron_right </span>
          </a>
        </div>
      </div>
    </div>
  );
};

StrategyCard.propTypes = propTypes;
StrategyCard.defaultProps = defaultProps;

export default withErrorBoundary(StrategyCard, '', false);
