import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import CopyTextButton from 'v2/common/components/CopyTextButton/index';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const Rupeezy = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              To start click on this link and
              <b> Login Using Rupeezy Credentials</b>
              <br />
              <b>
                <a
                  href="https://vortex.rupeezy.in/create-new-app"
                  target="blank"
                  title="Rupeezy API Login"
                >
                  https://vortex.rupeezy.in/create-new-app
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>Create New App</b>
            </p>
            <img
              src="/images/broker_setup_guide/rupeezy/rupeezy_app_1.png"
              alt="Create New App"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Fill the below details
            </p>

            <img
              src="/images/broker_setup_guide/rupeezy/rupeezy_app_2.1.png"
              alt="Quantman App details"
            />

            <div className="stepper-desc">
              <p>
                <b>App Name:</b>
                {oneIndentation}
                <CopyTextButton text="QUANTMAN" />
                <br />
                <b>Rupeezy Client ID:</b>
                {oneIndentation}
                eg. HIL789
                <br />
                (Enter you Client ID in the input field)
                <br />
                <div className="mb-1">
                  <b>Redirect URL:</b>
                  {oneIndentation}
                  <CopyTextButton text="https://www.quantman.in/auth/rupeezy/callback" />
                  <br />
                </div>
                <b>Postback URL:</b>
                {oneIndentation}
                <CopyTextButton text="https://www.quantman.in/rupeezy/postback" />
                <br />
                <b>Description:</b>
                {oneIndentation}
                <CopyTextButton text="QUANTMAN" />
              </p>
            </div>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Please
              {oneIndentation}
              click on
              {oneIndentation}
              <b>Create</b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <p className="stepper-desc">
              Please
              {oneIndentation}
              click on
              {oneIndentation}
              <b>View Details</b>
            </p>
          </div>
        </li>

        <img
          src="/images/broker_setup_guide/rupeezy/rupeezy_app_3.png"
          alt="Quantman App details"
        />

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 6</h3>
            <img
              src="/images/broker_setup_guide/rupeezy/rupeezy_app_4.png"
              alt="Copy and paste api details"
            />
            <p className="stepper-desc">
              Now, you can Copy and paste api details in appropriate fields below.
            </p>
          </div>
        </li>
      </ol>
    </div>
  </>
);

Rupeezy.propTypes = propTypes;
Rupeezy.defaultProps = defaultProps;

export default Rupeezy;
