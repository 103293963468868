import React from 'react';
import ModalComponent from 'v2/common/components/ModalComponent';
import OrderType from 'v2/modules/withRunForm/components/RunFormHeader/OrderType';
import RunName from 'v2/modules/withRunForm/components/RunFormHeader/RunName';
import withRunForm from 'v2/modules/withRunForm/index';
import { useForm, FormProvider } from 'react-hook-form';
import OrderExecutionDetails from 'v2/modules/withRunForm/components/OrderExecutionDetails/index';
import PropTypes from 'prop-types';
import useRunSubmit from 'v2/modules/withRunForm/hooks/useRunSubmit';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};
const defaultProps = {};

const Main = ({ onCloseModal }) => {
  const methods = useForm();
  const goLiveTitle = 'Deploy Strategy in Live';
  const { onSubmit } = useRunSubmit();

  return (
    <ModalComponent
      onSave={methods.handleSubmit(() => onSubmit())}
      onClose={onCloseModal}
      title={goLiveTitle}
      size="lg"
      okBtnTitle={goLiveTitle}
    >
      <FormProvider {...methods}>
        <div>
          <RunName />
          <OrderType />
          <OrderExecutionDetails />
        </div>
      </FormProvider>
    </ModalComponent>
  );
};

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;

export default withRunForm(Main, ['onCloseModal']);
