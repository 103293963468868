import React, {
  memo, useEffect, useState,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { WAIT_AND_TRADE_TYPES } from 'v2/common/constants/index';

const propTypes = {
  waitAndTradeConfigs: PropTypes.shape({
    waitUntilPremiumToIncreaseInPercentage: PropTypes.number,
    waitUntilPremiumToDecreaseInPercentage: PropTypes.number,
    waitAndTradeType: PropTypes.string,
  }).isRequired,
  onChangeWaitAndTradeConfigs: PropTypes.func.isRequired,
};
const defaultProps = {};

const WaitAndTrade = ({ waitAndTradeConfigs, onChangeWaitAndTradeConfigs }) => {
  const {
    waitUntilPremiumToIncreaseInPercentage, waitAndTradeType, waitUntilPremiumToDecreaseInPercentage
  } = waitAndTradeConfigs;

  const {
    WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE, WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE
  } = WAIT_AND_TRADE_TYPES;

  const activeCount = WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE === waitAndTradeType
    ? waitUntilPremiumToIncreaseInPercentage : waitUntilPremiumToDecreaseInPercentage;
  const isInitialLoad = useRef(true);
  const [waitValue, setWaitValue] = useState(activeCount);

  const onHandleActiveCount = (newCount = 0) => {
    if (waitAndTradeType === WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE) {
      onChangeWaitAndTradeConfigs({
        [WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: newCount,
        [WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
        waitAndTradeType,
      });
      return null;
    }

    return onChangeWaitAndTradeConfigs({
      [WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
      [WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: newCount,
      waitAndTradeType,
    });
  };

  useEffect(() => setWaitValue(activeCount), [activeCount]);

  useEffect(() => {
    if (!isInitialLoad.current) {
      setWaitValue(0);
    } else {
      isInitialLoad.current = false;
    }
  }, [waitAndTradeType]);

  return (
    <div className="input-group overall-stops wait-and-trade">
      <ToggleButtonGroup
        bsPrefix="btn-group btn-group-sm btn-group-toggle"
        type="radio"
        name="group-selector"
        value={waitAndTradeType}
        onChange={(newType) => {
          onChangeWaitAndTradeConfigs({
            [WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
            [WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
            waitAndTradeType: newType
          });
        }}
      >
        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          value={WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE}
        >
          <div className="d-flex align-items-center">
            <span className="material-icons-outlined">expand_more</span>
            %
          </div>
        </ToggleButton>

        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          value={WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE}
        >
          <div className="d-flex align-items-center">
            <span className="material-icons-outlined">expand_less</span>
            %
          </div>
        </ToggleButton>
      </ToggleButtonGroup>

      <input
        type="text"
        className="form-control form-control-sm"
        name="waitAndTrade"
        onBlur={(event) => onHandleActiveCount(onRegexOnlyNumberAndFloat(event.target.value, false))}
        value={waitValue === undefined ? '' : waitValue.toString()}
        onChange={(event) => setWaitValue(onRegexOnlyNumberAndFloat(event.target.value, true))}
      />
    </div>
  );
};

WaitAndTrade.propTypes = propTypes;
WaitAndTrade.defaultProps = defaultProps;

export default memo(withErrorBoundary(WaitAndTrade));
