import { getTips } from 'common/api/tip';
import React, { useEffect, useState } from 'react';
import ModalComponent from 'v2/common/components/ModalComponent';
import classNames from 'classnames';
import Media from './Media';
import MultiMedia from './MultiMedia';
import useTipsLocalStorage from './useTipsLocalStorage';
import Video from './Video';

const Tips = () => {
  const [tips, setTips] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const tip = tips[activeIndex] || tips[0] || {};

  useEffect(() => {
    getTips().then((res) => setTips(res));
  }, []);

  const { showModal, handleCloseModal } = useTipsLocalStorage();

  const onChangeTip = (isBefore) => {
    if (isBefore) {
      if (activeIndex === 0) return setActiveIndex(tips.length - 1);
      return setActiveIndex(activeIndex - 1);
    }
    if (activeIndex === tips.length - 1) return setActiveIndex(0);
    return setActiveIndex(activeIndex + 1);
  };

  const renderTip = () => {
    const { category, content } = tip;

    switch (category) {
      case 'media':
        return <Media content={content} />;
      case 'multiMedia':
        return <MultiMedia content={content} />;
      case 'video':
        return <Video content={content} />;
      default:
        return null;
    }
  };

  if (!showModal) return null;
  if (_.isEmpty(tip)) return null;

  const { title, description } = tip;

  const leftIconClassName = classNames('material-icons-outlined cursor-pointer tx-30');

  const rightIconClassName = classNames('material-icons-outlined cursor-pointer tx-30');

  let modalTitle = '<div class="d-flex align-items-center">';
  modalTitle += `${title}<small class="pl-1">(Daily Tips/Hint)</small></div>`;

  return (
    <ModalComponent
      size="lg"
      className="tips-modal"
      title={modalTitle}
      backdrop="static"
      onClose={() => handleCloseModal()}

    >
      <div>
        <div className="tip-content">
          <div className="tip-description mb-2">{description}</div>
          <div>{renderTip()}</div>

          <div className="d-flex align-items-center justify-content-between ">
            <div className="icon-wrapper-left">
              <span
                onClick={() => onChangeTip(true)}
                className={leftIconClassName}
              >
                chevron_left
              </span>
            </div>
            <div className="icon-wrapper-right">
              <span
                onClick={() => onChangeTip()}
                className={rightIconClassName}
              >
                chevron_right
              </span>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

Tips.propTypes = {};

export default Tips;
