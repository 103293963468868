import React from 'react';
import PropTypes from 'prop-types';
import { BROKERS, XTS_API_VENDOR } from 'common/constants/index';
import BrokerIdAuthentication from './BrokerIdAuthentication/index';
import Bigul from './BrokerSpecficComponents/Bigul';
import Xts from './BrokerSpecficComponents/Xts';
import GoodwillDealer from './BrokerSpecficComponents/GoodwillDealer';
import Finvasia from './BrokerSpecficComponents/Finvasia';
import Iifl from './BrokerSpecficComponents/iifl';
import Zebull from './BrokerSpecficComponents/Zebull';
import Bnrathi from './BrokerSpecficComponents/Bnrathi';
import Tradeplus from './BrokerSpecficComponents/Tradeplus';
import SkyBroking from './BrokerSpecficComponents/SkyBroking';
import KotakNeo from './BrokerSpecficComponents/KotakNeo';
import AcAgarwal from './BrokerSpecficComponents/AcAgarwal';
import PhilipCapital from './BrokerSpecficComponents/PhilipCapital';
import Samco from './BrokerSpecficComponents/Samco';
import IndiraSecurities from './BrokerSpecficComponents/IndiraSecurities';
import JainamBroking from './BrokerSpecficComponents/JainamBroking';
import Kasat from './BrokerSpecficComponents/Kasat';
import FairInvest from './BrokerSpecficComponents/FairInvest';
import Profitmart from './BrokerSpecficComponents/Profitmart';

const propTypes = {
  broker: PropTypes.string, onCloseModal: PropTypes.func
};

const defaultProps = { broker: '', onCloseModal: () => { } };

const AuthenticationModal = ({ broker, onCloseModal }) => {
  if (!broker) return null;

  switch (broker) {
    case BROKERS.bigul.id:
      return <Bigul onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.goodwill_dealer.id:
      return <GoodwillDealer onCloseModal={onCloseModal} broker={broker} />;
    case XTS_API_VENDOR:
      return <Xts onCloseModal={onCloseModal} />;
    case BROKERS.finvasia.id:
      return <Finvasia onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.iifl.id:
      return <Iifl onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.zebull.id:
      return <Zebull onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.bnrathi.id:
      return <Bnrathi onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.tradeplus.id:
      return <Tradeplus onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.kotak_neo.id:
      return <KotakNeo onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.sky_broking.id:
      return <SkyBroking onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.ac_agarwal.id:
      return <AcAgarwal onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.philip_capital.id:
      return <PhilipCapital onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.samco.id:
      return <Samco onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.indira_securities.id:
      return <IndiraSecurities onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.jainam_broking_retail.id:
      return <JainamBroking onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.fair_invest.id:
      return <FairInvest onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.profitmart.id:
      return <Profitmart onCloseModal={onCloseModal} broker={broker} />;
    case BROKERS.kasat.id:
      return <Kasat onCloseModal={onCloseModal} broker={broker} />;
    default:
      return <BrokerIdAuthentication onCloseModal={onCloseModal} broker={broker} />;
  }
};

export default AuthenticationModal;

AuthenticationModal.propTypes = propTypes;
AuthenticationModal.defaultProps = defaultProps;
