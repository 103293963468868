import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setIsSquareOffTransactionIfEntryFailed } from 'modules/QuantBuilder/actions';
import { oneOfTypes, orderConfigsPropTypes } from 'common/propTypes';
import SquareOffTransactionIfEntryFailed from './SquareOffTransactionIfEntryFailed';

const propTypes = {
  dispatchIsSquareOffTransactionIfEntryFailed: PropTypes.func.isRequired,
  isSquareOffTransactionIfEntryFailed: oneOfTypes,
  orderConfigs: orderConfigsPropTypes
};
const defaultProps = {
  isSquareOffTransactionIfEntryFailed: null,
  orderConfigs: []
};

const SquareOffEntireTransactionIfEntryFailed = ({
  dispatchIsSquareOffTransactionIfEntryFailed, isSquareOffTransactionIfEntryFailed, orderConfigs
}) => {
  useEffect(() => { // newly implemented, old runs don't have those config so we attached to quantConfig.
    if (isSquareOffTransactionIfEntryFailed === null) {
      dispatchIsSquareOffTransactionIfEntryFailed(false);
    }
  }, []);

  return (
    <SquareOffTransactionIfEntryFailed
      isSquareOffTransactionIfEntryFailed={!!isSquareOffTransactionIfEntryFailed}
      handleChange={dispatchIsSquareOffTransactionIfEntryFailed}
      orderConfigs={orderConfigs}
    />
  );
};

SquareOffEntireTransactionIfEntryFailed.propTypes = propTypes;
SquareOffEntireTransactionIfEntryFailed.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isSquareOffTransactionIfEntryFailed: _.get(state, 'quantConfig.isSquareOffTransactionIfEntryFailed', null),
  orderConfigs: _.get(state, 'quantConfig.orderConfigs', [])
});

const mapDispatchToProps = (dispatch) => ({
  dispatchIsSquareOffTransactionIfEntryFailed:
    (newValue) => dispatch(setIsSquareOffTransactionIfEntryFailed(newValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(SquareOffEntireTransactionIfEntryFailed);
