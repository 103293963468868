import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defaultFilterConfig } from 'ui/Strategies/config';
import Modal from 'common/components/ModalComponent';
import ArrowAccordion from 'common/components/ArrowAccordion';
import FilterHeader from './FilterHeader';
// import PurchaseFilter from './PurchaseFilter';
import SegmentFilter from './SegmentFilter';
import MarginFilter from './MarginFilter';
// import CategoryFilter from './CategoryFilter';
import InstrumentFilter from './InstrumentFilter';
import TagFilter from './TagFilter';
import PremiumFilter from './PremiumFilter';
import AuthorNameFilter from './AuthorNameFilter';
// import PurchaseAmountFilter from './PurchaseAmountFilter';

const propTypes = {
  filterConfigs: PropTypes.shape({}).isRequired,
  onUpdateFilterConfigs: PropTypes.func.isRequired,
  strategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const defaultProps = {};

const StrategyFilters = ({ filterConfigs, onUpdateFilterConfigs, strategies }) => {
  const [showModal, setShowModal] = useState(false);

  const structuredFilters = [
    // {
    //   title: 'Purchase Filters',
    //   isOpen: true,
    //   showItem: (
    //     <>
    //       <PurchaseFilter filterConfigs={filterConfigs} onUpdateFilterConfigs={onUpdateFilterConfigs} />
    //       <CategoryFilter filterConfigs={filterConfigs} onUpdateFilterConfigs={onUpdateFilterConfigs} />
    //       {/* <PurchaseAmountFilter
    //       filterConfigs={filterConfigs}
    //       onUpdateFilterConfigs={onUpdateFilterConfigs} /> */}
    //     </>
    //   )
    // },
    {
      title: 'Margin (Funds Required)',
      isOpen: true,
      showItem: <MarginFilter filterConfigs={filterConfigs} onUpdateFilterConfigs={onUpdateFilterConfigs} />
    },
    {
      title: 'Category',
      isOpen: true,
      showItem: (
        <>
          <TagFilter filterConfigs={filterConfigs} onUpdateFilterConfigs={onUpdateFilterConfigs} />
          <PremiumFilter filterConfigs={filterConfigs} onUpdateFilterConfigs={onUpdateFilterConfigs} />
          <AuthorNameFilter
            filterConfigs={filterConfigs}
            strategies={strategies}
            onUpdateFilterConfigs={onUpdateFilterConfigs}
          />
        </>
      )
    },
    {
      title: 'Segments & Instruments',
      isOpen: false,
      showItem: (
        <>
          <SegmentFilter filterConfigs={filterConfigs} onUpdateFilterConfigs={onUpdateFilterConfigs} />
          <InstrumentFilter
            filterConfigs={filterConfigs}
            onUpdateFilterConfigs={onUpdateFilterConfigs}
            strategies={strategies}
          />
        </>
      )
    }
  ];

  const renderStructedFilters = (structedFilter, idx) => {
    const { title, isOpen, showItem } = structedFilter;

    return (
      <ArrowAccordion
        defaultOpen={isOpen}
        key={idx}
        title={title}
        headerRightSectionContent={null}
        onToggle={() => { }}
      >
        {showItem}
      </ArrowAccordion>
    );
  };

  const renderFilters = () => {
    return <div className="mp-strategy-filter">{_.map(structuredFilters, renderStructedFilters)}</div>;
  };

  let modalProps = { title: 'Filter', size: 'lg' };
  if (!_.isMatch(defaultFilterConfig, filterConfigs)) {
    modalProps = { ...modalProps, okBtnTitle: 'Done', onSave: () => setShowModal(false) };
  }

  return (
    <>
      <FilterHeader
        filterConfigs={filterConfigs}
        onUpdateFilterConfigs={() => onUpdateFilterConfigs(defaultFilterConfig)}
        onUpdateModal={() => setShowModal(!showModal)}
      />

      <div className="d-none d-lg-block">{renderFilters()}</div>

      {showModal && (
        <Modal
          onClose={() => setShowModal(false)}
          className="market-place-filter"
          {...modalProps}
        >
          {renderFilters()}
        </Modal>
      )}
    </>
  );
};

StrategyFilters.propTypes = propTypes;
StrategyFilters.defaultProps = defaultProps;

export default StrategyFilters;
