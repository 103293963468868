import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onEditRun: PropTypes.func.isRequired,
};
const defaultProps = {};

const EditRun = ({ onEditRun }) => (
  <button
    type="button"
    onClick={() => onEditRun()}
    className="btn btn-sm btn-outline-primary text-nowrap copy-btn track"
    data-track-category="Run Edit"
    data-track-action="Run Edit"
    data-track-label="Show Page"
    data-toggle="tooltip"
    data-placement="top"
    id="run-show"
    title="Edit the same run"
    name="edit"
  >
    Edit Run
  </button>
);

EditRun.propTypes = propTypes;
EditRun.defaultProps = defaultProps;

export default EditRun;
