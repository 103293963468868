import { onVerifyPayment, onVerifySubscription } from 'common/api/payment';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { updateUrlQueryParams } from 'common/utils/urlUtils';

const PAID_STATUS = 'paid';
const SET_TIMEOUT_SECONDS = 2000;

const STATUS = {
  INITIATED: 'initiated',
  SUCCESS: 'success',
};

export const initializeRazorpay = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => { resolve(true); };
    script.onerror = () => { resolve(false); };

    document.body.appendChild(script);
  });
};

export const razorpayModal = (args) => {
  initializeRazorpay().then(() => {
    const isSubscription = _.get(args, 'isSubscription', false);
    const onVerifyPurchase = (responseArgs) => {
      return (isSubscription ? onVerifySubscription(responseArgs) : onVerifyPayment(responseArgs));
    };

    updateUrlQueryParams('status', STATUS.INITIATED);

    const {
      razorpayKeyId, description, razorpayPurchaseId, resyncTypes, razorpayNotes
    } = args;
    const immediateCallBackFunc = _.get(args, 'immediateCallBackFunc', () => { });

    const options = {
      key: razorpayKeyId,
      [isSubscription ? 'subscription_id' : 'order_id']: razorpayPurchaseId,
      // for subscription it will be subscription Id
      name: 'Quantman.in',
      description: description || '',
      notes: razorpayNotes,
      handler: (response) => {
        Promise.all(
          _.map(resyncTypes, (resyncType) => {
            onVerifyPurchase({ ...response, type: resyncType }).then((result) => {
              const { status, message, purchase_status: purchaseStatus } = result;
              let toastMessage = '';
              let toastFormat = TOASTIFY_TYPES.SUCCESS;

              if (status && purchaseStatus === PAID_STATUS) {
                toastMessage = message;
              } else if (status && purchaseStatus !== PAID_STATUS) {
                toastMessage = `${message}, but not updated, please deploy a strategy after few minutes`;
              } else {
                toastMessage = message;
                toastFormat = TOASTIFY_TYPES.ERROR;
              }

              if (toastMessage) reactToastify(toastMessage, toastFormat);
            }).catch(() => { });
          })
        ).then(() => {
          setTimeout(() => {
            immediateCallBackFunc();
            updateUrlQueryParams('status', STATUS.SUCCESS);
          }, SET_TIMEOUT_SECONDS);
        }).catch(() => { });
      }
    };

    const propay = new Razorpay(options);
    propay.open();
  });
};
