import {
  COMPARISION_TYPE_OPERATORS,
  signalConditionOptions,
} from 'modules/QuantBuilder/config';
import { getLabeledHashFromArray } from 'common/utils/displayNames';
import {
  additionalLeftOperandsConfig, adminOnlyLeftOperandsConfig
} from 'modules/QuantBuilder/config/signalConfigs';

const getRightOperandSuggestions = (leftOperand, outputSchema) => (
  _.find(outputSchema, (schema) => ((schema.name === leftOperand) && (!!schema.enum)))
);

const getAddonLeftOperands = (leftOperand, isClonedQuant, signalConfigField) => {
  let additionalLeftOperands = additionalLeftOperandsConfig()[signalConfigField];
  const stopGainOrLoss = _.get(additionalLeftOperandsConfig(), leftOperand);
  const adminOnlyAdditionalOperands = adminOnlyLeftOperandsConfig()[signalConfigField];
  if (window.isAdmin || window.currentUserBroker === 'aliceblue') {
    additionalLeftOperands = [...additionalLeftOperands, ...adminOnlyAdditionalOperands];
  }

  if (!isClonedQuant) return additionalLeftOperands;
  if (stopGainOrLoss) additionalLeftOperands.push(stopGainOrLoss);
  return additionalLeftOperands;
};

export const getLeftOperands = (
  leftOperand,
  signalConfigField,
  outputSchema,
  isClonedQuant,
) => {
  const additionalLeftOperands = getAddonLeftOperands(leftOperand, isClonedQuant, signalConfigField);
  const signalVariables = [''].concat(outputSchema)
    .concat(additionalLeftOperands);

  return getLabeledHashFromArray(signalVariables);
};

export const labelForEnumPattern = 'Pattern is';
export const getOperators = (leftOperand, outputSchema) => {
  let lo = leftOperand;
  const rightOperandSuggestion = getRightOperandSuggestions(lo, outputSchema);

  if (rightOperandSuggestion) {
    return [
      { value: '', label: '', }, { label: labelForEnumPattern, value: 'equalTo' }
    ];
  } // for enum.. eg: candle pattern charts

  if (_.includes(lo, 'individualStop')) { lo = 'stopGain'; }

  return (
    [''].concat(_.get(signalConditionOptions(),
      [lo, 'operatorOptions'], COMPARISION_TYPE_OPERATORS))
  );
};

export const getRightOperands = (leftOperand, outputSchema) => {
  const rightOperandSuggestion = getRightOperandSuggestions(leftOperand, outputSchema);

  if (rightOperandSuggestion) return getLabeledHashFromArray(rightOperandSuggestion.enum, true);
  // for enum.. candle pattern.. enum is handled for boolean values indicators..

  const rightOperands = _.get(signalConditionOptions(), [leftOperand, 'rightOperandOptions'], outputSchema);

  return getLabeledHashFromArray(rightOperands);
};
