import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { POSITION_VALUE_TYPES } from 'v2/common/constants/index';
import { onRegexOnlyNumber } from 'common/stringUtils/toPositiveNumberString';

const { Lot, Amount } = POSITION_VALUE_TYPES;

const propTypes = {
  quantityValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quantityType: PropTypes.string,
  isEquity: PropTypes.bool,
  onChangeQuantity: PropTypes.func.isRequired,
  label: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};
const defaultProps = {
  label: 'Lot',
  isEquity: false,
  quantityType: Lot,
  quantityValue: 1,
};

const Quantity = ({
  onChangeQuantity, label, control, errors, isEquity, quantityValue, quantityType
}) => {
  const options = [
    { name: Lot, tag: 'Lot', isShow: true },
    { name: Amount, tag: 'Amount', isShow: true }
  ];

  const [quantityName] = useState(_.uniqueId('optionsQuantity-'));
  const [type, setType] = useState(quantityType);
  const inputBoxStyle = classNames('form-control form-control-sm', { 'is-invalid': !!errors[quantityName] });

  const valueType = _.includes([Lot, Amount], type) ? `${type}` : `${Amount}`;

  useEffect(() => {
    if (!isEquity) {
      setType(Lot);
      onChangeQuantity({ type: Lot, value: quantityValue });
    }
  }, [isEquity]);

  const handleTypeChange = (newType) => {
    setType(newType);
    onChangeQuantity({ type: newType, value: quantityValue });
  };

  const validator = {
    validate: (value) => {
      if (!value) {
        return `${label} should be included`;
      }

      return true;
    }
  };

  return (
    <div className="total-lot">
      <div className="lot-container d-flex input-group overall-stops">
        {isEquity && (
        <select
          value={valueType}
          onChange={(event) => handleTypeChange(event.target.value)}
        >
          {_.map(options, ({ name, tag, isShow }, idx) => (
            isShow ? <option value={name} key={idx}>{tag}</option> : null
          ))}
        </select>
        )}
        <Controller
          render={({ onChange, ref, name }) => (
            <Form.Control
              type="text"
              ref={ref}
              className={inputBoxStyle}
              name={name}
              value={quantityValue === undefined ? '' : quantityValue.toString()}
              min={1}
              onChange={(event) => {
                onChangeQuantity({ type, value: onRegexOnlyNumber(event.target.value) });
                onChange(onRegexOnlyNumber(event.target.value));
              }}
            />
          )}
          name={quantityName}
          defaultValue={quantityValue}
          control={control}
          rules={validator}
        />
      </div>
      <ErrorMesssage message={errors[quantityName]?.message || ''} />
    </div>
  );
};

Quantity.propTypes = propTypes;
Quantity.defaultProps = defaultProps;

export default memo(Quantity);
