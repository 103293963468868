import React, { useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { updateUrlQueryParams } from 'common/utils/urlUtils';
import { showByOption } from './config';

const propTypes = {
  selectedFilter: PropTypes.string,
  onSelectFilter: PropTypes.func.isRequired
};

const defaultProps = { selectedFilter: 'all' };

const Filter = ({ selectedFilter, onSelectFilter }) => {
  useEffect(() => {
    updateUrlQueryParams('selectedFilter', selectedFilter);
  }, [selectedFilter]);

  const renderFilterOptions = (values, key) => {
    const { displayName, value } = values;
    return (
      <ToggleButton
        bsPrefix="btn btn-outline-primary btn-sm btn-lightness text-nowrap"
        value={value}
        key={key}
      >
        {displayName}
      </ToggleButton>
    );
  };

  return (
    <ToggleButtonGroup
      bsPrefix="btn-group btn-group-toggle btn-sm"
      type="radio"
      name="filterByOptions"
      value={selectedFilter}
      onChange={(value) => onSelectFilter(value)}
    >
      {_.map(showByOption, renderFilterOptions)}
    </ToggleButtonGroup>
  );
};

Filter.defaultProps = defaultProps;
Filter.propTypes = propTypes;

export default Filter;
