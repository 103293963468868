import { QUANT_SCHEMA_CATEGORIES } from 'common/constants/index';

export const sanitizeSignalConfigSchema = (outputSchema, isAdjustmentSignal, typeOfOperand) => {
  const { isAdmin } = window;

  let categories = [QUANT_SCHEMA_CATEGORIES.INDICATOR, QUANT_SCHEMA_CATEGORIES.CUSTOM_PROP];

  if (isAdmin) categories = [...categories, QUANT_SCHEMA_CATEGORIES.COMBINED_PREMIUM];
  if (isAdjustmentSignal && typeOfOperand === 'leftOperand') {
    categories = [
      ...categories, QUANT_SCHEMA_CATEGORIES.INDIVIDUALSTOP, QUANT_SCHEMA_CATEGORIES.STRATEGY_STOP
    ];
  }

  return _.filter(
    outputSchema, ({ name, category }) => {
      if (_.includes(categories, category)) return true;
      const nameSplit = name.split('.');

      // mainly for last traded price both equity and month1price...
      if (_.includes([QUANT_SCHEMA_CATEGORIES.FUTURE_PRICE, QUANT_SCHEMA_CATEGORIES.EQUITY_PRICE], category)
        && (_.includes(nameSplit, 'close'))) {
        return true;
      }

      return false;
    }
  );
};
