import React from 'react';
import PropTypes from 'prop-types';

import { ORDER_CONFIRMATION_STATUSES } from 'common/constants';
import { fetchOrders, updateSignalConfirmation } from 'common/api/signalConfirmation';
import Modal from 'common/components/ModalComponent';
import { getConvertedTime } from 'common/utils/transactions';
import { getLiveData } from 'common/api/liveData';
import BgBlink from 'common/components/BgBlink/index';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { mergeBestPriceWithOrders } from './helper';

const propTypes = {
  runId: PropTypes.number.isRequired,
  jobId: PropTypes.number.isRequired,
  orderConfirmationId: PropTypes.number.isRequired,
  verificationToken: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  closeCallBack: PropTypes.func,
};
const defaultProps = {
  closeCallBack: () => { }
};

const LIVE_DATA_REFRESH_RATE = 5000;

class SignalConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldShowModal: false,
      newOrders: [],
      cancelOrders: []
    };

    this.fetchLiveDataTimer = null;
  }

  componentDidMount() {
    const { runId, jobId, orderConfirmationId } = this.props;
    fetchOrders({ runId, jobId, orderConfirmationId })
      .then((response) => {
        const { status, data } = response;

        if (!status) return;

        const {
          new_orders: newOrders,
          cancel_orders: cancelOrders
        } = data;
        const tradingSymbols = _.map(newOrders, 'trading_symbol');

        getLiveData(tradingSymbols).then((liveData) => {
          this.setState({
            shouldShowModal: true,
            newOrders: mergeBestPriceWithOrders(newOrders, liveData),
            cancelOrders
          }, () => {
            this.fetchBestPrice();
          });
        });
      });
  }

  componentWillUnmount() {
    clearTimeout(this.fetchLiveDataTimer);
  }

  fetchBestPrice = () => {
    this.fetchLiveDataTimer = setTimeout(this.fetchBestPrice, LIVE_DATA_REFRESH_RATE);
    const { newOrders } = this.state;
    const tradingSymbols = _.map(newOrders, 'trading_symbol');

    getLiveData(tradingSymbols).then((liveData) => {
      this.setState({
        newOrders: mergeBestPriceWithOrders(newOrders, liveData),
      });
    });
  }

  onStatusChange = (status) => {
    const {
      runId,
      jobId,
      orderConfirmationId,
      onUpdate,
      verificationToken
    } = this.props;
    let ordersPrice = null;

    if (status === ORDER_CONFIRMATION_STATUSES.CONFIRMED) {
      const { newOrders } = this.state;
      ordersPrice = {};

      _.each(newOrders, (order) => {
        ordersPrice[order.id] = order.updated_price || order.signal_price;
      });
    }

    updateSignalConfirmation({
      runId,
      jobId,
      orderConfirmationId,
      verificationToken,
      status,
      ordersPrice
    }).then((response) => {
      if (_.get(response, 'status')) onUpdate(status);
    });
  }

  onClose = () => {
    const { closeCallBack } = this.props;
    this.setState({
      shouldShowModal: false
    }, () => {
      closeCallBack();
    });
  }

  onPriceChange = (orderId, price) => {
    if (_.isNaN(parseFloat(price))) return;

    const { newOrders } = this.state;
    const order = _.find(newOrders, ['id', orderId]);

    _.set(order, 'updated_price', parseFloat(price));
    this.setState({ newOrders: [...newOrders] });
  }

  renderPrice = (isNewOrder, order) => {
    const { id, signal_price: signalPrice, updated_price: updatedPrice } = order;

    if (!isNewOrder) {
      return updatedPrice || signalPrice;
    }

    return (
      <input
        type="number"
        className="form-control"
        value={updatedPrice || signalPrice}
        onChange={(event) => this.onPriceChange(id, event.target.value)}
      />
    );
  }

  renderOrder = (order, isNewOrder) => {
    const {
      trading_symbol: tradingSymbol,
      quantity,
      transaction_type: transactionType,
      signal_time: signalTime,
      bbPrice,
      bsPrice,
      id,
    } = order;

    return (
      <tr key={id}>
        <td>{tradingSymbol}</td>
        <td>{quantity}</td>
        <td>{this.renderPrice(isNewOrder, order)}</td>
        <td><BgBlink>{bbPrice}</BgBlink></td>
        <td><BgBlink>{bsPrice}</BgBlink></td>
        <td>{transactionType}</td>
        <td>{isNewOrder ? 'Place' : 'Cancel'}</td>
        <td>
          <span className="time">
            <small>{getConvertedTime(signalTime).format('DD MMM YYYY, HH:mm:ss')}</small>
          </span>
        </td>
      </tr>
    );
  }

  render() {
    const { shouldShowModal, newOrders, cancelOrders } = this.state;

    return (
      <>
        {
          shouldShowModal
          && (
            <Modal
              className="signal-confirmation-container"
              title="Signal Confirmation"
              okBtnTitle="Confirm"
              size="xl"
              onSave={() => this.onStatusChange(ORDER_CONFIRMATION_STATUSES.CONFIRMED)}
              onClose={this.onClose}
              onCancel={() => this.onStatusChange(ORDER_CONFIRMATION_STATUSES.IGNORED)}
              cancelBtnTitle="Skip"
            >
              <div className="table-responsive border rounded">
                <table className="table table-striped table-th-border-none mb-0">
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Best Buy Price</th>
                      <th>Best Sell Price</th>
                      <th>Transaction Type</th>
                      <th>Action</th>
                      <th>Signal Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newOrders.map((newOrder) => this.renderOrder(newOrder, true))}
                    {cancelOrders.map((cancelOrder) => this.renderOrder(cancelOrder, false))}
                  </tbody>
                </table>
              </div>
            </Modal>
          )
        }
      </>
    );
  }
}

SignalConfirmation.propTypes = propTypes;
SignalConfirmation.defaultProps = defaultProps;

export default withErrorBoundary(SignalConfirmation, '', false);
