const { OPTION_TYPES, SEGMENT_CONFIG, INTENT_TYPES } = require('v2/common/constants/index');

export const isEligibleForMoveToCost = (intents) => {
  let isCeAndStopLossPresent = false;
  let isPeAndStopLossPresent = false;

  return _.chain(intents)
    .filter((intent) => intent?.type === INTENT_TYPES.OpenLeg)
    .filter((intent) => intent?.instrument?.type === SEGMENT_CONFIG.option)
    .some((intent) => {
      const { instrument: { optionType }, stopLoss: { value: stopLossValue } } = intent;
      if (optionType === OPTION_TYPES.CE && stopLossValue) {
        isCeAndStopLossPresent = true;
      }
      if (optionType === OPTION_TYPES.PE && stopLossValue) {
        isPeAndStopLossPresent = true;
      }

      return isCeAndStopLossPresent && isPeAndStopLossPresent;
    })
    .value();
};

const withoutIntentIds = (intents, toRemoveIntentIds) => _.chain(intents)
  .filter((intent) => (!_.includes(toRemoveIntentIds, intent?.target?.label)))
  .map((intent) => {
    const { uiConfigs: { quickAdjustments = [] } = {} } = intent;
    if (quickAdjustments.length === 0) return intent;

    const containsDeletedIntent = _.some(quickAdjustments, ({ targets }) => {
      return _.intersection(targets, toRemoveIntentIds).length > 0;
    });
    if (!containsDeletedIntent) return intent;

    const newQuickAdjustments = _.map(quickAdjustments, (quickAdjustment) => {
      if (_.intersection(quickAdjustment.targets, toRemoveIntentIds).length === 0) {
        return quickAdjustment;
      }
      return {
        ...quickAdjustment,
        targets: _.without(quickAdjustment.targets, ...toRemoveIntentIds)
      };
    });
    return {
      ...intent,
      uiConfigs: {
        ...intent.uiConfigs,
        quickAdjustments: newQuickAdjustments
      }
    };
  })
  .value();

export const removeOpenIntentsReferences = (caseConfig, toRemoveIntentIds) => {
  if (!toRemoveIntentIds || toRemoveIntentIds.length <= 0) {
    return caseConfig;
  }

  const { entry, adjustments } = caseConfig;
  const newCaseConfig = {
    ...caseConfig,
    entry: {
      ...(caseConfig.entry),
      intents: withoutIntentIds(entry.intents, toRemoveIntentIds)
    },
    adjustments: _.map(adjustments, (adjustmentConfig) => {
      return {
        ...adjustmentConfig,
        intents: withoutIntentIds(adjustmentConfig.intents, toRemoveIntentIds)
      };
    })
  };

  return newCaseConfig;
};
