import React from 'react';
import PropTypes from 'prop-types';
import { BROKERS } from 'common/constants/index';
import IciciDirect from './IciciDirect';
import Zerodha from './Zerodha';
import Zebull from './Zebull';
import Flattrade from './Flattrade';
import Iifl from './iifl';
import Upstox from './upstox';
import Paytm from './Paytm';
import KotakNeo from './KotakNeo';
import Sharekhan from './Sharekhan';
import PhilipCapital from './PhilipCapital';
import Hdfcsky from './Hdfcsky';
import InvestRight from './InvestRight';
import Rupeezy from './Rupeezy';
import Fyers from './Fyers';

const propTypes = { broker: PropTypes.string.isRequired };

const defaultProps = {};

const BrokerContent = ({ broker }) => {
  switch (broker) {
    case BROKERS.icici_direct.id:
      return <IciciDirect broker={broker} />;
    case BROKERS.zebull.id:
      return <Zebull broker={broker} />;
    case BROKERS.flattrade.id:
      return <Flattrade broker={broker} />;
    // case BROKERS.zerodha.id:
    //   return <Zerodha broker={broker} />;
    case BROKERS.fivepaisa.id:
      return <Zerodha broker={broker} />;
    case BROKERS.iifl.id:
      return <Iifl broker={broker} />;
    case BROKERS.upstox.id:
      return <Upstox broker={broker} />;
    case BROKERS.paytm.id:
      return <Paytm broker={broker} />;
    case BROKERS.kotak_neo.id:
      return <KotakNeo broker={broker} />;
    case BROKERS.sharekhan.id:
      return <Sharekhan broker={broker} />;
    case BROKERS.philip_capital.id:
      return <PhilipCapital broker={broker} />;
    case BROKERS.hdfcsky.id:
      return <Hdfcsky broker={broker} />;
    case BROKERS.invest_right.id:
      return <InvestRight broker={broker} />;
    case BROKERS.rupeezy.id:
      return <Rupeezy broker={broker} />;
    case BROKERS.fyers.id:
      return <Fyers broker={broker} />;
    default:
      return <></>;
  }
};

BrokerContent.propTypes = propTypes;
BrokerContent.defaultProps = defaultProps;

export default BrokerContent;
