import React from 'react';
import PropTypes from 'prop-types';

import { STRIKE_KEYS } from 'modules/TransactionsBuilder/configs';
import { useSelector } from 'react-redux';
import { advancedFeatureConfigs } from 'modules/QuantBuilder/config/signalConfigs';

const propTypes = {
  strikeBasedOn: PropTypes.string.isRequired,
  isAdjustment: PropTypes.bool,
  orderConfigIndex: PropTypes.number.isRequired
};

const defaultProps = {
  isAdjustment: false
};

const {
  strikeIndex: STRIKE_INDEX,
  indicatorValue: INDICATOR_VALUE,
  equityPrice: EQUITY_PRICE
} = STRIKE_KEYS;

const TableHeader = ({ strikeBasedOn, isAdjustment, orderConfigIndex }) => {
  const isIndicatorValue = strikeBasedOn === INDICATOR_VALUE;
  const isEquityPrice = strikeBasedOn === EQUITY_PRICE;

  const globalReduxState = useSelector((state) => state);
  const runType = _.get(globalReduxState, 'runType');
  const orderConfigs = _.get(globalReduxState, 'quantConfig.orderConfigs', []);
  const currentOrderConfig = _.get(orderConfigs, orderConfigIndex);
  const advancedFeatures = _.get(currentOrderConfig, 'advancedFeatureConfigs', advancedFeatureConfigs());
  const {
    isReEntry, isReExecute, isTrailingStopLoss
  } = advancedFeatures;

  const isWaitAndTrade = _.get(advancedFeatures, 'isWaitAndTrade', false);
  const isShowWaitAndTradeForBackTest = runType === 'historic'
    && _.get(window, 'userPermissions.waitAndTradeForBacktest.isEnabled', false);

  const advancedThs = [
    { label: 'Trailing Stop Loss', shouldShow: isTrailingStopLoss && !isAdjustment },
    { label: 'ReEntry / ReExecute', shouldShow: (isReEntry || isReExecute) && !isAdjustment, },
    {
      label: 'Wait and Trade',
      shouldShow: (runType === 'historic' && !isShowWaitAndTradeForBackTest) ? false : isWaitAndTrade
    }
  ];

  const renderAdvancedTh = ({ label, shouldShow }, idx) => {
    if (!shouldShow) return null;

    return <th key={idx}>{label}</th>;
  };

  return (
    <thead>
      <tr>
        <th>Transaction</th>
        <th>Option Type</th>
        {isIndicatorValue && (<th>Value path</th>)}
        <th>{_.startCase((isIndicatorValue || isEquityPrice) ? STRIKE_INDEX : strikeBasedOn)}</th>
        <th>Total Lot</th>
        <th>Action Type</th>
        <th>Stop Loss</th>
        <th>Take Profit</th>
        {_.map(advancedThs, renderAdvancedTh)}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = propTypes;
TableHeader.defaultProps = defaultProps;

export default TableHeader;
