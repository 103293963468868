import { getConvertedTime } from 'common/utils/transactions';

export const renderOrderStatus = (order) => {
  const { is_entry: isEntry, is_stop_loss: isStopLoss } = order;

  if (isEntry) return 'Entry';
  if (isStopLoss) return 'Stop Loss';

  return 'Exit';
};

export const formattedDateAndTime = (time) => {
  if (!time) return 'N/A';

  return getConvertedTime(time).format('h:mm:ss a MMM Do, YYYY');
};
