import React, { useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { OrderContext } from 'ui/run/RunShow/OrderStore/index';

const RunSummaryCandleChart = () => {
  const context = useContext(OrderContext);
  const completedTransactions = _.get(context, 'completedTransactions', []);
  const data = _.map(completedTransactions, (completedTransaction, index) => {
    const {
      transactionCloseTime, overallMaxProfit, overallMinProfit, overallProfit
    } = completedTransaction;

    if (!index) return null;

    return {
      x: new Date(transactionCloseTime),
      y: [completedTransactions[index - 1].overallProfit, overallMaxProfit, overallMinProfit, overallProfit]
    };
  });

  const series = [
    {
      data: _.compact(data)
    }];
  const options = {
    chart: {
      type: 'candlestick',
      height: 350
    },
    title: {
      text: 'CandleStick Chart',
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    }
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="candlestick" height={350} />
    </div>
  );
};

RunSummaryCandleChart.propTypes = {};

export default RunSummaryCandleChart;
