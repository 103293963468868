import {
  useEffect, useRef, useState, useMemo
} from 'react';
import { isStrategyDomainUser } from 'ui/applicationDomainHelper';
import fetchAddons from 'common/api/addons';
import fetchPlans from 'common/api/plans';
import { fetchCoupon, fetchPreApplyCoupon } from 'common/api/coupons';
import {
  cyclicFilter, getCouponConfig, getPreFillCouponCode, initCurrentNetPrice
} from './helper';

const usePlans = ({ activePlan, activeSubscription }) => {
  const isStrategyDomain = isStrategyDomainUser();
  const defaultPlanType = isStrategyDomain ? 'monthly' : 'yearly';
  // state
  const [planCycleType, setPlanCycleType] = useState(defaultPlanType);
  const [addons, setaddons] = useState();
  const [couponCode, setCouponCode] = useState(getPreFillCouponCode());
  const [isApplied, setIsApplied] = useState(false);
  const [planGroups, setPlanGroups] = useState();
  const [appliedCouponConfig, setAppliedCouponConfig] = useState({});
  const [creditBalance, setCreditBalance] = useState(_.get(window, 'creditBalance', 0));
  const [isCreditApplied, setIsCreditApplied] = useState(true);
  const [message, setMessage] = useState('');
  // Refs
  const isPreFill = useRef(true);

  const activePlanId = _.get(activePlan, 'id', '');
  const activePlanName = _.get(activePlan, 'plan_name', '');
  const currentCyclicPeriodPlans = useMemo(() => {
    return _.flatMap(planGroups, (planGroup) => {
      return _.filter(planGroup, (plan) => cyclicFilter(planCycleType, plan));
    });
  }, [planGroups, planCycleType]);
  const currentCyclicPeriodAddons = useMemo(() => {
    return _.chain(addons)
      .filter((addon) => (addon.live_run_limit === 0) || cyclicFilter(planCycleType, addon))
      .sortBy('live_run_limit')
      .reverse()
      .value();
  }, [addons, planCycleType]);
  const plansGroupedByCyclicMonths = _.groupBy(_.flatMap(planGroups), 'cycle_duration_in_months');
  const shouldShowAddons = !isStrategyDomain;
  const isSubscriptionActive = !_.isEmpty(activeSubscription);
  const planPageNotice = _.get(window, 'applicationDomain.config.plan_page_notice', '');
  const isCouponsAllowedOnStrategyDomain = _.get(
    window, 'applicationDomain.config.allow_coupons', false
  );
  const shouldShowCoupon = !isStrategyDomainUser() || isCouponsAllowedOnStrategyDomain;

  useEffect(() => {
    fetchPlans()
      .then((results) => {
        setPlanGroups(_.groupBy(initCurrentNetPrice(results), 'plan_name'));
      }); // Plans

    fetchAddons().then((results) => { setaddons(initCurrentNetPrice(results)); }); // Addons

    (!couponCode ? fetchPreApplyCoupon() : fetchCoupon({ code: couponCode }))
      .then((result) => {
        const { status, coupon } = result;
        if (couponCode) setMessage('Invalid coupon');
        if (status) {
          setMessage(`${coupon.discount_percentage}% discount applied`);
          setAppliedCouponConfig(coupon);
        }
      });
  }, []);

  const onUpdateCouponPricesInPayments = (config) => {
    const {
      alteredCouponCode, alteredIsApplied, alteredAddons, alteredPlanGroups
    } = getCouponConfig(config, addons, planGroups);
    setCouponCode(alteredCouponCode);
    setIsApplied(alteredIsApplied);
    setaddons(alteredAddons);
    setAppliedCouponConfig(config);
    setPlanGroups(alteredPlanGroups);
  };

  useEffect(() => {
    const isPreFillEnable = addons && planGroups && isPreFill.current && _.keys(appliedCouponConfig).length;
    // for preFill
    if (isPreFillEnable) {
      isPreFill.current = false;
      onUpdateCouponPricesInPayments(appliedCouponConfig);
    }
  }, [appliedCouponConfig, addons, planGroups]);

  const onCouponUpdate = () => {
    if (!isApplied) {
      fetchCoupon({ code: couponCode, preapplied: false })
        .then((result) => {
          const { status, coupon } = result;
          if (status) {
            setMessage(`${coupon.discount_percentage}% discount applied`);
            setAppliedCouponConfig(coupon);
            return onUpdateCouponPricesInPayments(coupon);
          }
          return setMessage('Invalid Coupon');
        });
    }

    setMessage('');
    onUpdateCouponPricesInPayments({});
    return null;
  };

  return {
    planCycleType,
    setPlanCycleType,
    planGroups,
    activePlanId,
    activePlanName,
    addons,
    currentCyclicPeriodAddons,
    couponCode,
    setCouponCode,
    isApplied,
    appliedCouponConfig,
    creditBalance,
    setCreditBalance,
    isCreditApplied,
    setIsCreditApplied,
    message,
    currentCyclicPeriodPlans,
    plansGroupedByCyclicMonths,
    shouldShowAddons,
    isSubscriptionActive,
    planPageNotice,
    shouldShowCoupon,
    onCouponUpdate
  };
};

export default usePlans;
