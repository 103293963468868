import React, { useMemo } from 'react';
import { STOPS_VALUE_TYPES } from 'v2/common/constants/index';
import { useSelector } from 'react-redux';
import HoverMessage from 'common/components/HoverMessage';
import StopLossOrGainInput from './StopLossOrGainInput';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import OverallTransactionTSL from './OverallTransactionTSL';

const OverallExitCondition = () => {
  const tradingType = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.tradingType'));
  const isIntraday = useMemo(() => tradingType === 'intraday', [tradingType]);

  const ttContent = useMemo(() => (
    <div>
      After Entry, If all the legs combined profit crosses Take Profit
      <br />
      (Or)
      <br />
      After Entry, If all the legs combined loss crosses Stop Loss,
      <br />
      Then,
      <br />
      <br />
      It will exit all the legs.
    </div>
  ), []);

  const dtContent = useMemo(() => (
    <div>
      If the day profit (previous transactions and current transaction in the day) crosses Take Profit,
      <br />
      (Or)
      <br />
      If the day loss (previous transactions and current transaction in the day) crosses Stop Loss,
      <br />
      Then,
      <br />
      <br />
      It will exit the current transaction and will not enter any new transactions for the day.
    </div>
  ), []);

  const exitConfigs = [
    {
      label: 'Transaction Targets',
      renderInfoMessage: (
        <HoverMessage title="Transaction Targets" message={ttContent}>
          <span className="material-icons-outlined info-icon ml-1">
            info
          </span>
        </HoverMessage>
      ),
      parentClassName: 'col-md-6 col-sm-12 mb-4 mb-md-0',
      content: (
        <div className="transaction-targets-container">
          <StopLossOrGainInput type="stopLoss">
            <OverallTransactionTSL />
          </StopLossOrGainInput>
          <StopLossOrGainInput type="stopGain" />
        </div>
      ),
      isShow: true
    },
    {
      label: 'Daily Targets',
      renderInfoMessage: (
        <HoverMessage title="Daily Targets" message={dtContent}>
          <span className="material-icons-outlined info-icon ml-1">
            info
          </span>
        </HoverMessage>
      ),
      parentClassName: 'col-md-6 col-sm-12',
      content: (
        <div className="daily-targets-container">
          <StopLossOrGainInput
            type="dayStopLoss"
            defaultType={STOPS_VALUE_TYPES.Amount}
            isShowPercent={false}
            isShowPoints={false}
          />
          <StopLossOrGainInput
            type="dayStopGain"
            defaultType={STOPS_VALUE_TYPES.Amount}
            isShowPercent={false}
            isShowPoints={false}
          />
        </div>
      ),
      isShow: isIntraday
    }
  ];

  return (
    <>
      <div className="transaction_targets_wrap py-3 px-3 px-sm-4 py-sm-5">
        <div className="row">
          {exitConfigs.map((config, index) => {
            const {
              label, renderInfoMessage, parentClassName, content, isShow
            } = config;
            if (!isShow) return null;

            return (
              <div key={index} className={parentClassName}>
                <div className="mb-2 mb-md-5 font-weight-medium tx-15 d-flex align-items-center">
                  {label}
                  {renderInfoMessage}
                </div>
                {content}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OverallExitCondition;
