import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const Upstox = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              To start click on this link
              <b>
                <a
                  href="https://login.upstox.com/"
                  target="blank"
                  title="Upsotx Login"
                >
                  - https://login.upstox.com/
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              Click on your name on the top right corner and click on
              {oneIndentation}
              <b>My Account</b>
            </p>
            <img
              src="/images/broker_setup_guide/upstox/step-2.png"
              className="w-100"
              alt="Upstox dashboard"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Click on apps and select
              {oneIndentation}
              <b>New App</b>
            </p>
            <img
              src="/images/broker_setup_guide/upstox/step-3.png"
              className="w-100"
              alt="Create new API app"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Fill below details and submit.
            </p>

            <div className="stepper-desc">
              <p>
                <b>App Name:</b>
                {oneIndentation}
                Quantman
                <br />
                <b>Redirect URL:</b>
                {oneIndentation}
                https://www.quantman.in/auth/upstox/callback
                <br />
                <b>Postback URL:</b>
                {oneIndentation}
                https://www.quantman.in/upstox/postback
                <br />
              </p>
            </div>

            <img
              src="/images/broker_setup_guide/upstox/step-4.png"
              alt="Quantman App details"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <p className="stepper-desc">
              Now, you can Copy and paste api details in appropriate fields below.
            </p>
            <img
              src="/images/broker_setup_guide/upstox/step-5.png"
              className="w-100"
              alt="Upstox Api credentials"
            />
          </div>
        </li>
      </ol>
    </div>
  </>
);

Upstox.propTypes = propTypes;
Upstox.defaultProps = defaultProps;

export default Upstox;
