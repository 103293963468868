import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import {
  schemaShouldShowConfigTypes,
  schemaTypes,
} from '../outputSchema/config';

const configuration = {
  type: 'moneyFlowIndex',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'moneyFlowIndex',
    name: 'mfi',
    config: {
      noOfCandles: 14,
      candleInterval: '3 minutes',
      valuePaths: ['item0.equity'],
    },
  },
  searchTerms: ['mfi', 'moneyFlowIndex'],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future],
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    return [
      {
        name,
        type: schemaTypes.number,
        indicatorType: type,
        category: 'indicator',
        label: name,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name,
      },
    ];
  },
  isEnabled: true,
};
export default configuration;
