const isRetryTimeElapsed = (order) => {
  if (!order.placed_time) {
    return false;
  }
  const now = new Date();
  const placedTime = new Date(order.placed_time);
  const diffInMilliseconds = now - placedTime;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

  return diffInMinutes >= 60;
};

export const isEligibleForRetry = (order, orders) => {
  const stopLossOrders = _.filter(orders, { is_stop_loss: true });
  const exitOrders = _.filter(orders, { is_entry: false, is_stop_loss: false });

  if (order?.retried_time || isRetryTimeElapsed(order)) {
    return false;
  }
  const eligibleConditions = [
    (order?.is_entry
      && order.status === 'failed'
      && _.isEmpty(stopLossOrders)
      && _.isEmpty(exitOrders)
    ),
    (order?.is_stop_loss
      && order.status === 'failed'
      && _.isEmpty(exitOrders)
    ),
    ((!order?.is_entry && !order?.is_stop_loss)
      && (order.status === 'failed'
      || (order.status === 'confirmed' && _.get(stopLossOrders, '0.status', '') === 'placed')
      ))
  ];

  return _.some(eligibleConditions);
};
