import React from 'react';
import PropTypes from 'prop-types';
import { FIXED_AMOUNT } from 'common/constants/index';
import _ from 'lodash';
import moment from 'moment';

const propTypes = {
  isEmptyStrategyAccess: PropTypes.bool.isRequired,
  status: PropTypes.string,
  purchaseType: PropTypes.string.isRequired,
  strategyAccess: PropTypes.shape({}),
  initialFreeTrialDays: PropTypes.number
};

const defaultProps = {
  status: '',
  strategyAccess: null,
  initialFreeTrialDays: 0
};

const AccessTill = ({
  isEmptyStrategyAccess, status, purchaseType, strategyAccess, initialFreeTrialDays
}) => {
  if (purchaseType !== FIXED_AMOUNT) return null;
  const paidTill = _.get(strategyAccess, 'paid_till', '');
  const strategyAccessCreatedAt = _.get(strategyAccess, 'created_at', '');
  const freeTrialTill = !isEmptyStrategyAccess ? moment(strategyAccessCreatedAt)
    .add(initialFreeTrialDays, 'days').format() : null;
  const isFreeTrial = _.isEmpty(paidTill) && !(_.isEmpty(freeTrialTill));

  const oneIndentation = ' ';

  return (
    <>
      {(!isEmptyStrategyAccess && status === 'paid') && (
        <div className="access-till">
          <div className="access-inner alert-info">
            <span className="material-icons tx-15"> info </span>
            {oneIndentation}
            <div className="text-nowrap">
              <span className="font-weight-bold">
                {isFreeTrial ? 'Free trial till' : 'Purchased till'}
                {oneIndentation}
                :
              </span>
              {oneIndentation}
              {oneIndentation}
              {oneIndentation}
              {moment(isFreeTrial ? freeTrialTill : paidTill).format('MMM DD, YYYY') }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

AccessTill.propTypes = propTypes;
AccessTill.defaultProps = defaultProps;

export default AccessTill;
