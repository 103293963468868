import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';

const configuration = {
  type: 'openingRangeBreakout',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'openingRangeBreakout',
    name: 'orb',
    config: {
      candleInterval: '3 minutes',
      valuePaths: [
        'item0.equity'
      ]
    }
  },
  searchTerms: [
    'ORB',
    'openingRangeBreakout'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      { property: 'open', isBoolean: false },
      { property: 'high', isBoolean: false },
      { property: 'low', isBoolean: false },
      { property: 'close', isBoolean: false },
      { property: 'isOpenEqualToHigh', isBoolean: true },
      { property: 'isOpenEqualToLow', isBoolean: true }
    ];

    return _.map(properties, ({ property, isBoolean }) => {
      const schema = {
        name: `${name}.${property}`,
        type: schemaTypes.number,
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };

      if (isBoolean) {
        schema.type = schemaTypes.boolean;
        schema.shouldShowConfigs = [];
      }

      return schema;
    });
  },
  isEnabled: true
};
export default configuration;
