import React from 'react';
import PropTypes from 'prop-types';
import { RUN_TYPES } from 'common/constants/index';

const propTypes = {
  runType: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  runName: PropTypes.string.isRequired,
  folderName: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
};

const defaultProps = {};

const ConfigRunDetails = ({
  runType, startDate, endDate, runName, folderName, orderType, granularity
}) => {
  const isLive = runType === RUN_TYPES.live;

  const runDetails = [
    { title: 'Quant Run Type', value: _.startCase(runType), isShow: true },
    { title: 'Quant Order Type', value: _.startCase(orderType), isShow: isLive },
    { title: 'Start Date', value: startDate, isShow: !isLive },
    { title: 'End Date', value: endDate, isShow: !isLive },
    { title: 'Quant Run Name', value: runName, isShow: true },
    { title: 'Quant Folder Name', value: _.startCase(folderName), isShow: true },
    {
      title: 'Candle Type',
      value: _.startCase(granularity),
      isShow: !isLive
    },
  ];
  const renderDetails = (detail, key) => {
    if (!detail.isShow) return null;

    return (
      <div key={key}>
        <label className="col-form-label col-form-label-sm">{_.get(detail, 'title', '')}</label>
        <h6 className="main-head">{_.get(detail, 'value', '')}</h6>
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="config-rundetails">
          {_.map(runDetails, renderDetails)}
        </div>
      </div>
    </div>
  );
};

ConfigRunDetails.defaultProps = defaultProps;
ConfigRunDetails.propTypes = propTypes;

export default ConfigRunDetails;
