import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useOutputSchema from 'v2/modules/withRunForm/hooks/useOutputSchema';
import classNames from 'classnames';
import { OutputSchemaUtility, TypeheadSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import { schemaShouldShowConfigTypes } from 'v2/common/quantConfig/outputSchema/config';
import { IntentsContext } from 'v2/modules/withRunForm/hoc/withIntentsContext';

const propTypes = {
  valuePath: PropTypes.string,
  onChange: PropTypes.func,
};

const defaultProps = {
  valuePath: '',
  onChange: () => {},
};

const IndicatorValuePathSelector = ({ valuePath, onChange, errorMessage }) => {
  const {
    caseIndex, adjustmentIndex, isAdjustmentIntent, pipeIndex, isPipeIntent
  } = useContext(IntentsContext);
  const outputSchema = useOutputSchema();

  const indicatorSchema = _.chain(outputSchema)
    .thru((schema) => OutputSchemaUtility.filterByCaseIndex(schema, caseIndex))
    .thru((schema) => OutputSchemaUtility.filterByTypes(
      schema, [schemaShouldShowConfigTypes.intentValuePath]
    ))
    .thru((schema) => {
      if (isAdjustmentIntent) {
        return OutputSchemaUtility.filterSchemaPriorToAdjustmentIndex(schema, adjustmentIndex);
      }
      if (isPipeIntent) {
        return _.chain(schema)
          .thru((sch) => OutputSchemaUtility.filterByCategory(sch, 'indicator'))
          .thru((sch) => OutputSchemaUtility.filterSchemaPriorToPipeIndex(sch, pipeIndex))
          .value();
      }
      return OutputSchemaUtility.filterByCategory(schema, 'indicator');
    })
    .map((schema) => TypeheadSchemaUtility.getOption(schema))
    .value();

  const renderIndicatorSchema = (schema) => {
    return <option key={schema.id} value={schema.id}>{schema.searchLabel}</option>;
  };

  const selectClassName = classNames('indicator-selector', {
    'is-invalid': errorMessage,
  });

  const renderSelect = (
    <select
      className={selectClassName}
      value={valuePath}
      onChange={(event) => onChange(event.target.value)}
    >
      <option value="" />
      {_.map(indicatorSchema, renderIndicatorSchema)}
    </select>
  );

  return renderSelect;
};

IndicatorValuePathSelector.propTypes = propTypes;
IndicatorValuePathSelector.defaultProps = defaultProps;

export default IndicatorValuePathSelector;
