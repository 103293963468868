import wrappedFetch from './base';

export const getWebPushSubscriptions = () => {
  return wrappedFetch('/web_push_subscribers.json')
    .then((response) => response.json());
};

export const deleteWebPushSubscription = (webPushSubscriberId) => {
  const config = {
    method: 'DELETE',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  };

  return wrappedFetch(`/web_push_subscribers/${webPushSubscriberId}.json`, config);
};
