import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  setOrderConfigs
} from 'modules/QuantBuilder/actions';
import { connect } from 'react-redux';
import {
  orderConfigsPropTypes,
  signalConfigPropTypes
} from 'common/propTypes';
import { SIGNAL_CONFIG } from 'modules/QuantBuilder/config/signalConfigs';
import { getEmptyAdjustmentActionConfig } from 'modules/TransactionsBuilder/configs';
import { getAdjustmentSignalConfigsWithoutAutoAdjustment } from
  // eslint-disable-next-line max-len
  'modules/TransactionsBuilder/NewTransactionBuilder/components/AdvancedFeaturesEnablement/AdjustmentAdaptor/index';
import AdjustmentSignalConfig from './AdjustmentSignalConfig';
import AdjustmentActionConfig from './AdjustmentActionConfig';

const propTypes = {
  adjustmentSignalConfigs: PropTypes.shape({
    orderConfigIndex: PropTypes.number,
    signalConfigs: PropTypes.arrayOf(PropTypes.arrayOf(signalConfigPropTypes))
  }).isRequired,
  orderConfigs: orderConfigsPropTypes.isRequired,
  dispatchSetOrderConfigs: PropTypes.func.isRequired,
  maxInstrumentsInGroup: PropTypes.number.isRequired,
};

const defaultProps = {};

const I18N_SCOPE = { scope: 'advanced_quant_builder.index' };

const Adjustments = ({
  adjustmentSignalConfigs: {
    orderConfigIndex, signalConfigs
  },
  adjustmentSignalConfigs,
  orderConfigs, dispatchSetOrderConfigs, maxInstrumentsInGroup
}) => {
  const newOrderConfigs = _.cloneDeep(orderConfigs);
  const adjustmentSignalFields = newOrderConfigs[orderConfigIndex][
    SIGNAL_CONFIG.ADJUSTMENT_SIGNAL_CONFIGS
  ] || [];
  const adjustmentActionFields = newOrderConfigs[orderConfigIndex][
    SIGNAL_CONFIG.ADJUSTMENT_ACTION_CONFIGS] || [];
  const isInitialLoad = useRef(true);

  const withoutAutoAdjustmentSignalConfigs = getAdjustmentSignalConfigsWithoutAutoAdjustment(signalConfigs);

  const onAddAdjustment = useCallback((isAppendOld) => {
    const newAdjustmentSignalConfigs = isAppendOld ? [...adjustmentSignalFields, []] : [[]];
    const newAdjustmentActionConfigs = isAppendOld ? [
      ...adjustmentActionFields,
      _.times(maxInstrumentsInGroup, () => getEmptyAdjustmentActionConfig())] : [
      _.times(maxInstrumentsInGroup, () => getEmptyAdjustmentActionConfig())
    ];

    newOrderConfigs[orderConfigIndex] = {
      ...newOrderConfigs[orderConfigIndex],
      [SIGNAL_CONFIG.ADJUSTMENT_SIGNAL_CONFIGS]: newAdjustmentSignalConfigs,
      [SIGNAL_CONFIG.ADJUSTMENT_ACTION_CONFIGS]: newAdjustmentActionConfigs
    };

    dispatchSetOrderConfigs(newOrderConfigs);
  }, [adjustmentActionFields, adjustmentSignalFields,
    dispatchSetOrderConfigs, maxInstrumentsInGroup,
    newOrderConfigs, orderConfigIndex]);

  useEffect(() => {
    if (!isInitialLoad.current) {
      setTimeout(() => onAddAdjustment(), 500);
    } else {
      isInitialLoad.current = false;
    }
  }, [maxInstrumentsInGroup]);
  // it will be working for both clone and newly add...

  const onDeleteAdjustmentSignal = (adjustmentIndex) => {
    newOrderConfigs[orderConfigIndex] = {
      ...newOrderConfigs[orderConfigIndex],
      [SIGNAL_CONFIG.ADJUSTMENT_SIGNAL_CONFIGS]: _.filter(adjustmentSignalFields,
        (adjustmentSignalField, idx) => idx !== adjustmentIndex),
      [SIGNAL_CONFIG.ADJUSTMENT_ACTION_CONFIGS]: _.filter(adjustmentActionFields,
        (adjustmentActionField, idx) => idx !== adjustmentIndex)
    };

    dispatchSetOrderConfigs(newOrderConfigs);
  };

  const renderAdjustment = (adjustmentSignalConfig, idx) => {
    const updatedConfig = { ...adjustmentSignalConfigs, signalConfigs: adjustmentSignalConfig };
    // override for common purpose... repeat components...
    const adjustmentActionConfig = _.get(orderConfigs,
      [orderConfigIndex, 'adjustmentActionConfigs', idx], []);
    const transactionConfigs = _.get(orderConfigs, [orderConfigIndex, 'transactionConfigs'], []);
    const isAutoAdjustment = _.get(updatedConfig, 'signalConfigs.0.mode.isAutoAdjustment', false);

    if (isAutoAdjustment) return null;

    return (
      <div key={idx} className="card mb-2 mx-md-4">
        <div className="border-bottom py-1 px-2 d-flex bg-light justify-content-between align-items-center">
          <span className="font-weight-medium">
            Adjustment
          </span>
          <button
            className="delete-btn delete-btn-bg"
            onClick={() => onDeleteAdjustmentSignal(idx)}
            type="button"
          >
            <i className="material-icons-outlined">delete_forever</i>
          </button>
        </div>

        <AdjustmentSignalConfig
          config={updatedConfig}
          key={idx}
          adjustmentSignalConfigIndex={idx}
        />
        <AdjustmentActionConfig
          adjustmentActionConfig={adjustmentActionConfig}
          adjustmentActionConfigIndex={idx}
          orderConfigIndex={orderConfigIndex}
          transactionConfigs={transactionConfigs}
        />
      </div>
    );
  };

  return (
    <div className="adjustments-container py-15 px-2 px-md-6">
      <hr />
      {!_.isEmpty(withoutAutoAdjustmentSignalConfigs)
        && <h6 className="mb-3 mt-3">Adjustments</h6>}

      {_.map(signalConfigs, renderAdjustment)}

      <div className="d-flex justify-content-center align-items-center mt-2 mb-5">
        <button
          className="btn btn-sm btn-primary common-icon-btn track"
          type="button"
          data-track-category="Manual Adjustment"
          data-track-action="Manual Adjustment"
          data-track-label="Manual Adjustment"
          onClick={() => onAddAdjustment(true)}
        >
          <i className="material-icons-outlined">library_add</i>
          {I18n.t('add_adjustment', I18N_SCOPE)}
        </button>
      </div>
    </div>
  );
};

Adjustments.propTypes = propTypes;
Adjustments.defaultProps = defaultProps;

const mapStatetoProps = (state) => {
  const noOfInstrumentsInGroup = _.get(state, 'quantConfig.instrumentGroups.0', []).length;

  return {
    orderConfigs: _.get(state, 'quantConfig.orderConfigs'),
    maxInstrumentsInGroup: _.get(state, 'quantConfig.maxInstrumentsInGroup', noOfInstrumentsInGroup)
  };
};

const mapDispatchToProp = (dispatch) => ({
  dispatchSetOrderConfigs: (newOrderConfigs) => dispatch(setOrderConfigs(newOrderConfigs))
});

export default connect(mapStatetoProps, mapDispatchToProp)(Adjustments);
