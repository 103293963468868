import { getEmptySignals } from 'v2/modules/withRunForm/configs/index';
import {
  useEffect, useMemo, useState
} from 'react';

const useSignalsForm = (propsSignals, onUpdateSignals) => {
  const initialSignals = useMemo(
    () => (_.isEmpty(propsSignals) ? getEmptySignals : propsSignals), [propsSignals]
  );

  const [signals, setSignals] = useState(initialSignals);

  useEffect(() => setSignals(initialSignals), [initialSignals]);

  const onUpdateSignal = (signal, idx) => {
    const newSignals = _.map(signals, (s, index) => (index === idx ? signal : s));
    onUpdateSignals(newSignals);
    setSignals(newSignals);
  };

  const onAddNewSignal = () => {
    const newSignal = getEmptySignals[0];
    newSignal.conditionOperator = 'and';
    const newSignals = [...signals, newSignal];
    onUpdateSignals(newSignals);
    setSignals(newSignals);
  };

  const onDeleteSignal = (idx) => {
    const newSignals = [...signals.slice(0, idx), ...signals.slice(idx + 1)];
    onUpdateSignals(newSignals);
    setSignals(newSignals);
  };

  return {
    signals, onAddNewSignal, onUpdateSignal, onDeleteSignal
  };
};

export default useSignalsForm;
