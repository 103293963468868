import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import MessageToast from 'common/components/MessageToast';
import {
  getUnreadMessages, setUserMessagesLocalStorage, onUserMessageClose
} from 'common/utils/userMessages';

const propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string)
  })).isRequired
};
const defaultProps = {};

const UserMessageToast = ({ messages }) => {
  const [unReadMessges, setUnreadMessages] = useState([]);

  useEffect(() => {
    setUserMessagesLocalStorage(messages);
    setUnreadMessages(getUnreadMessages(messages));
  }, []);

  const renderToastMessage = ({ id, title, message }) => (
    <MessageToast
      key={id}
      title={title}
      message={ReactHtmlParser(message)}
      onClose={() => setUnreadMessages(onUserMessageClose(id, unReadMessges))}
    />
  );
  return (
    <div className="d-flex justify-content-center">
      {_.map(unReadMessges, renderToastMessage)}
    </div>
  );
};

UserMessageToast.propTypes = propTypes;
UserMessageToast.defaultProps = defaultProps;

export default UserMessageToast;
