import React, { useEffect, useRef } from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setTradeType, setTimeBasedSignal, setExpiryHandling, setLimitTransactions
} from 'modules/QuantBuilder/actions';
import {
  TRADE_TYPES,
  getEmptyTimeBasedSignal,
  getEmptyExpiryHandling,
  getDefaultExpiryHandling,
  getDefaultTimeBasedSignalForIntraday
} from 'modules/QuantBuilder/config';
import Intraday from './Intraday';
import Positional from './Positional';

const propTypes = {
  dispatchSetTradeType: PropTypes.func.isRequired,
  dispatchSetTimeBasedSignal: PropTypes.func.isRequired,
  dispatchSetExpiryHandling: PropTypes.func.isRequired,
  tradingType: PropTypes.string.isRequired,
  dispatchSetLimitTransactions: PropTypes.func.isRequired
};

const defaultProps = { };

const ExpiryHandler = (props) => {
  const {
    tradingType, dispatchSetTradeType, dispatchSetTimeBasedSignal, dispatchSetExpiryHandling,
    dispatchSetLimitTransactions
  } = props;
  const { positional, intraday } = TRADE_TYPES;
  const isInitialLoad = useRef(false);

  useEffect(() => {
    if (isInitialLoad.current) {
      if (tradingType === positional) dispatchSetLimitTransactions(10);
      else dispatchSetLimitTransactions(1);
    }

    isInitialLoad.current = true;
  }, [tradingType]);
  const onTradeTypeChange = (newTradeType) => {
    const defaultTimeBasedSignal = newTradeType === intraday
      ? getDefaultTimeBasedSignalForIntraday()
      : getEmptyTimeBasedSignal();

    const defaultExpiryHandling = newTradeType === positional
      ? getDefaultExpiryHandling()
      : getEmptyExpiryHandling();

    dispatchSetTradeType(newTradeType);
    dispatchSetTimeBasedSignal(defaultTimeBasedSignal);
    dispatchSetExpiryHandling(defaultExpiryHandling);
  };

  return (
    <div className="expiry-handler">
      <div className="row mb-3 align-items-center">
        <div className="col-md-2">
          <label className="col-form-label col-form-label-sm">Strategy Type</label>
        </div>
        <div className="col-md-10">
          <ToggleButtonGroup
            bsPrefix="btn-group btn-group-sm btn-group-toggle"
            type="radio"
            name="tradingType"
            defaultValue={tradingType}
            onChange={onTradeTypeChange}
          >
            <ToggleButton
              bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
              key={intraday}
              value={intraday}
              data-track-category="Expiry Handler"
              data-track-action={intraday}
              data-track-label="Expiry Handler Strategy Type"
            >
              {_.startCase(intraday)}
            </ToggleButton>

            <ToggleButton
              bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
              key={positional}
              value={positional}
              data-track-category="Expiry Handler"
              data-track-action={positional}
              data-track-label="Expiry Handler Strategy Type"
            >
              {_.startCase(positional)}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      {tradingType === intraday ? <Intraday /> : <Positional />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tradingType: _.get(state, 'quantConfig.tradingType')
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetTradeType: (newTradeType) => dispatch(setTradeType(newTradeType)),
  dispatchSetTimeBasedSignal: (newTimeBasedSignal) => dispatch(setTimeBasedSignal(newTimeBasedSignal)),
  dispatchSetExpiryHandling: (newExpiry) => dispatch(setExpiryHandling(newExpiry)),
  dispatchSetLimitTransactions: (newMaxTransactionsPerDay) => (
    dispatch(setLimitTransactions(newMaxTransactionsPerDay))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpiryHandler);

ExpiryHandler.propTypes = propTypes;
ExpiryHandler.defaultProps = defaultProps;
