import { getUrlParams } from 'common/utils/urlUtils';
import React from 'react';

import Plans from 'ui/Plans';
import {
  HomeSection, AboutSection, FeaturesSection, ContactSection
} from './pages';
import Faq from './pages/Faq';

const LandingPage = (props) => {
  const urlParams = getUrlParams();
  const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';

  return (
    <div className="landing-page">
      <HomeSection {...props} />
      <AboutSection />
      {!isMinifiedPage && (
      <div>
        <FeaturesSection />
        <section className="section" id="plans">
          <Plans {...props} />
        </section>
        <Faq />
        <ContactSection className="faq-section" />
        {/* <Footer /> */}
      </div>
      )}
    </div>
  );
};

export default LandingPage;
