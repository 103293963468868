import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { strategyPropTypes } from 'common/propTypes';

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  strategyConfig: strategyPropTypes.isRequired,
  onUpdateStrategyConfig: PropTypes.func.isRequired,
  strategyKey: PropTypes.string.isRequired
};

const defaultProps = {
  label: ''
};

const StrategyInput = ({
  label, value, strategyConfig, onUpdateStrategyConfig, strategyKey
}) => {
  const { errors, register } = useFormContext();

  const validatorConfig = {
    validate: (result) => {
      if (!result) return `Please type an ${label}*`;

      return null;
    }
  };

  const validatorProps = { ref: register(validatorConfig) };

  const inputClassName = classNames('form-control form-control-sm', {
    'border-danger': errors[`${strategyKey}`]
  });

  return (
    <>
      <label className="col-form-label col-form-label-sm" htmlFor={strategyKey}>{label}</label>
      <input
        {...validatorProps}
        type="string"
        className={inputClassName}
        id={strategyKey}
        name={strategyKey}
        value={value ? value.toString() : ''}
        placeholder={label}
        onChange={(event) => {
          const { target: { value: targetValue } } = event;
          onUpdateStrategyConfig({ ...strategyConfig, [strategyKey]: Number(targetValue) });
        }}
      />
    </>
  );
};

StrategyInput.propTypes = propTypes;
StrategyInput.defaultProps = defaultProps;

export default StrategyInput;
