import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { setPartialBounds } from 'v2/modules/withRunForm/redux/actions';
import StopsValueTypeToggleBtn from 'v2/common/components/StopsValueTypeToggleBtn.jsx';
import { onRegexOnlyNumber } from 'common/stringUtils/toPositiveNumberString';
import { STOPS_VALUE_TYPES } from 'v2/common/constants/index';

const TYPES = {
  stopLoss: { value: 'stopLoss', label: 'Stop Loss' },
  stopGain: { value: 'stopGain', label: 'Take Profit' },
  dayStopLoss: { value: 'dayStopLoss', label: 'Stop Loss' },
  dayStopGain: { value: 'dayStopGain', label: 'Take Profit' }
};

const propTypes = {
  type: PropTypes.oneOf(_.values(TYPES).map((type) => type.value)).isRequired,
  defaultType: PropTypes.string,
  isShowPercent: PropTypes.bool,
  isShowPoints: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string
  ])
};

const defaultProps = {
  defaultType: STOPS_VALUE_TYPES.Percent,
  isShowPercent: true,
  isShowPoints: true,
  children: null
};

const StopLossOrGainInput = ({
  type, defaultType, isShowPercent, isShowPoints, children
}) => {
  const dispatch = useDispatch();
  const selectedBound = useSelector(
    (state) => getSelectorBasedStoreValue(state, `quantConfig.bounds.${type}`)
  );

  const [config, setConfig] = useState({
    ...selectedBound,
    type: _.get(selectedBound, 'type', defaultType),
    value: _.get(selectedBound, 'value', 0)
  });

  useEffect(() => {
    setConfig({
      ...selectedBound,
      type: _.get(selectedBound, 'type', defaultType),
      value: _.get(selectedBound, 'value', 0)
    });
  }, [defaultType, selectedBound]);

  const onUpdateBoundTostate = () => {
    dispatch(setPartialBounds({ [type]: config }));
    return null;
  };

  const onValueTypeChange = (valueTypeArgs) => {
    dispatch(setPartialBounds({ [type]: { ...config, type: valueTypeArgs } }));
    setConfig({ ...config, type: valueTypeArgs });
  };

  const onValueChange = (valueArgs) => {
    setConfig({ ...config, value: valueArgs });
  };

  const inputBox = (
    <input
      type="text"
      className="form-control form-control-sm"
      name={config.type}
      onBlur={onUpdateBoundTostate}
      value={!config.value ? '' : config.value.toString()}
      onChange={(e) => { onValueChange(onRegexOnlyNumber(e.target.value)); }}
    />
  );

  return (
    <div className={`stop-loss-or-stop-gain-input ${type} align-items-center`}>
      <div className="row align-items-center">
        <div className="col-md-2"><label className="label_style">{TYPES[type].label}</label></div>
        <div className="col-md-10">
          <div className="d-flex align-items-center">
            <StopsValueTypeToggleBtn
              valueType={config.type}
              onValueTypeChange={onValueTypeChange}
              isShowPoints={isShowPoints}
              isShowPercent={isShowPercent}
            />
            {inputBox}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

StopLossOrGainInput.propTypes = propTypes;
StopLossOrGainInput.defaultProps = defaultProps;

export default StopLossOrGainInput;
