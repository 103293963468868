import React, { useEffect, useState } from 'react';
import { fetchV2DashboadJobsDetails } from 'common/api/job';
import ReactDataTable from 'common/components/ReactDataTable';

const V2Dashboard = () => {
  const oneIndentation = ' ';
  const [stuckedJobs, setStuckedJobs] = useState([]);
  const [totalV2LiveJobsCountInFrontend, setTotalV2LiveJobsCountInFrontend] = useState(0);
  const [totalV2StuckedJobsCountInEngine, setTotalV2StuckedJobsCountInEngine] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchV2DashboadJobsDetails();
        const {
          total_jobs_in_engine: engineJobsCount,
          total_jobs_in_frontend: frontendJobsCount,
          stucked_jobs: stuckedJobDetails
        } = result;
        setTotalV2StuckedJobsCountInEngine(engineJobsCount);
        setTotalV2LiveJobsCountInFrontend(frontendJobsCount);
        setStuckedJobs(stuckedJobDetails);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { Header: 'Job Id', accessor: 'job_id' },
    { Header: 'Frontend Status', accessor: 'frontend_status' }
  ];

  return (
    <div className="container-fluid v2-dashboard bg-white p-4">
      <h5 className="mb-2">Summary</h5>
      <div className="mb-2">
        <span className="badge badge-secondary ml-1">
          V2 Live Jobs in Frontend:
          {oneIndentation}
          {totalV2LiveJobsCountInFrontend}
        </span>
        <span className="badge badge-secondary ml-1">
          V2 Live Jobs in Engine:
          {oneIndentation}
          {totalV2StuckedJobsCountInEngine}
        </span>
        <span className="badge badge-secondary ml-1">
          Mismatch:
          {oneIndentation}
          {totalV2LiveJobsCountInFrontend - totalV2StuckedJobsCountInEngine}
        </span>
        <span className="badge badge-secondary ml-1">
          Stucked:
          {oneIndentation}
          {stuckedJobs.length}
        </span>
      </div>
      <h5 className="mb-2">Stucked Job Details</h5>
      <div className="row">
        <div className="col-lg">
          <ReactDataTable columns={columns} data={stuckedJobs} />
        </div>
      </div>
    </div>
  );
};

export default V2Dashboard;
