import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  IntentLabelConfig,
  getDefaultIdentifier,
  getDefaultIntent,
} from '../../configs/index';
import {
  isIndexInstrumentPresentInInstrumentGroup
} from '../../components/InstrumentDetails/indexInstrumentsChecker';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import useIntentsError from './useIntentsError';

const useIntents = (
  intents,
  onUpdateIntents,
  isPipeIntent,
  pipeName,
  control,
  errors,
  setError,
  clearErrors
) => {
  const onUpdateIntent = useCallback((newIntent, intentId, removedOpenIntentIds = []) => {
    const newIntents = _.cloneDeep(intents);
    const idx = _.findIndex(newIntents, (intent) => intent?.identifier?.label === intentId);
    newIntents[idx] = newIntent;
    onUpdateIntents(newIntents, removedOpenIntentIds);
  }, [intents, onUpdateIntents]);

  const instrumentGroups = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.instrumentGroups')
  );

  const onAddIntent = useCallback(() => {
    const defaultIntent = {
      ...getDefaultIntent(),
      identifier: isPipeIntent ? getDefaultIdentifier(pipeName) : getDefaultIdentifier(),
    };
    const isIndexInstrument = isIndexInstrumentPresentInInstrumentGroup(instrumentGroups, 0);
    if (isIndexInstrument) {
      defaultIntent.instrument.expiry.cycle = 'weekly';
    }
    onUpdateIntents([...intents, defaultIntent]);
  }, [intents, onUpdateIntents, instrumentGroups, isPipeIntent, pipeName]);

  const onDeleteIntent = useCallback((toDeleteIntentId) => {
    IntentLabelConfig.reverse(pipeName || 'default', toDeleteIntentId);
    const newIntents = _.filter(intents, (intent) => (intent?.identifier?.label !== toDeleteIntentId));
    onUpdateIntents(newIntents, [toDeleteIntentId]);
  }, [intents, onUpdateIntents, pipeName]);

  const { errorMessage, errorConfigs } = useIntentsError(
    intents,
    control,
    errors,
    setError,
    clearErrors
  );

  return {
    intents,
    onUpdateIntent,
    errorMessage,
    errorConfigs,
    onAddIntent,
    onDeleteIntent,
  };
};

export default useIntents;
