import {
  emptyQuantConfig, getDefaultQuantConfigUiConfigs, getQuantModes,
} from 'v2/modules/withRunForm/configs/index';
import { getQueryParamsBasedSanitizedQuantConfig } from './queryParams';
import { getSanitizedPipeConfigs } from './pipes';
import { getSanitizedCases } from './cases/index';
import { intentIdIntializer } from './intentidinitializer';

const getDefaultMode = (instrumentGroups) => {
  if (_.isEmpty(instrumentGroups)) return getQuantModes.simple.value;
  if (
    _.size(instrumentGroups) === 1 && _.size(_.head(instrumentGroups)) === 1
  ) return getQuantModes.simple.value;

  return getQuantModes.advanced.value;
};

const getSanitizedUiConfigs = (quantConfig) => {
  const uiConfigs = _.get(quantConfig, 'uiConfigs', {});
  const instrumentGroups = _.get(quantConfig, 'instrumentGroups', []);
  const newUiConfigs = { ...getDefaultQuantConfigUiConfigs(), ...uiConfigs };

  return {
    ...newUiConfigs,
    mode: _.get(newUiConfigs, 'mode', getDefaultMode(instrumentGroups))
  };
};

export const getSanitizedQuantConfig = (quantConfig) => {
  const queryParamsQuantConfig = getQueryParamsBasedSanitizedQuantConfig();
  if (!_.isEmpty(queryParamsQuantConfig)) return queryParamsQuantConfig;
  if (_.isEmpty(quantConfig)) return emptyQuantConfig();

  const { cases, pipeConfigs, adjustmentOptions } = quantConfig;

  let executionType = _.get(adjustmentOptions, 'executionType', 'nextMinute');
  const isPermissionToSelectExecutionType = _.get(
    window, 'SA_FEATURE_FLAGS.shouldShowAdjustmentExectionType', false
  )
  || window?.isAdmin;
  const isNotValidExecutionType = !isPermissionToSelectExecutionType && executionType !== 'nextMinute';
  if (isNotValidExecutionType) {
    executionType = 'nextMinute';
  }

  const newCases = getSanitizedCases(cases);
  const newPipeConfigs = getSanitizedPipeConfigs(pipeConfigs, cases);

  intentIdIntializer(newCases, newPipeConfigs);

  return {
    ...quantConfig,
    adjustmentOptions: { executionType },
    cases: newCases,
    uiConfigs: getSanitizedUiConfigs(quantConfig),
    pipeConfigs: newPipeConfigs
  };
};
