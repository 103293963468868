import React from 'react';
import PropTypes from 'prop-types';
import { planPropTypes } from 'common/propTypes';
import PaymentMoneyDisplay from 'ui/Plans/components/common/PaymentMoneyDisplay';
import moment from 'moment';
import MoneyDisplay from 'common/components/MoneyDisplay';
import PaymentCoupons from 'ui/Plans/components/common/PaymentCoupons';
import { cyclicPlansPeriods } from 'ui/Plans/config';
import Credits from 'ui/Plans/components/common/Credits';
import { applyCredits } from 'ui/Plans/helper';

const propTypes = {
  selectedPurchase: PropTypes.shape(planPropTypes.isRequired),
  isShowPlan: PropTypes.bool.isRequired,
  strategy: PropTypes.shape({}),
  couponCode: PropTypes.string.isRequired,
  onChangeCouponCode: PropTypes.func.isRequired,
  isApplied: PropTypes.bool.isRequired,
  onCouponUpdate: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  creditBalance: PropTypes.number.isRequired,
  isCreditApplied: PropTypes.bool.isRequired,
  onUpdateCredits: PropTypes.func.isRequired
};

const defaultProps = {
  selectedPurchase: {},
  strategy: {}
};

const QuotaConfirmation = ({
  selectedPurchase, isShowPlan, strategy, couponCode,
  onChangeCouponCode, isApplied, onCouponUpdate, message,
  creditBalance, isCreditApplied, onUpdateCredits
}) => {
  const isEmpty = _.isEmpty(selectedPurchase);
  const isEmptyStrategy = _.isEmpty(strategy);
  const oneIndentation = ' ';
  const {
    price, addon_name: addonName, plan_name: planName, currentNetPrice, cycle_duration_in_months: cycleMonth
  } = selectedPurchase;

  const name = isShowPlan ? planName : addonName;
  const totalAmount = _.get(strategy, 'purchase_amount', 0) + (isEmpty ? 0 : currentNetPrice);
  const reducedAmount = isCreditApplied ? applyCredits(totalAmount, creditBalance) : totalAmount;

  const renderStrategyRow = () => {
    if (isEmptyStrategy) return null;

    return (
      <>
        <tr>
          <td className="value-name">Strategy Name</td>
          <td className="text-right strategy-name">{_.startCase(_.get(strategy, 'title', ''))}</td>
        </tr>
        <tr>
          <td className="value-name">Strategy Fee</td>
          <td className="text-right"><MoneyDisplay>{_.get(strategy, 'purchase_amount')}</MoneyDisplay></td>
        </tr>
      </>
    );
  };

  const renderMessage = () => {
    if (isEmpty) return null;

    if (!isShowPlan) {
      return (
        <div className="alert alert-info mt-3 px-2">
          Your
          {oneIndentation}
          {_.startCase(name)}
          {oneIndentation}
          is life time credits
        </div>
      );
    }

    return (
      <div className="alert alert-info mt-3 px-2">
        Your
        {oneIndentation}
        {_.startCase(name)}
        {oneIndentation}
        subscription will activate
        {oneIndentation}
        <b>Today</b>
        {oneIndentation}
        and expires on
        {oneIndentation}
        <b>{moment().add(Number(cycleMonth), 'months').format('YYYY-MM-DD')}</b>
      </div>
    );
  };

  const renderCycleLable = () => {
    const {
      monthly: { label: monthlyLabel, period: monthlyPeriod },
      quarterly: { label: quarterLabel, period: quarterlyPeriod },
      yearly: { label: yearlyLabel }
    } = cyclicPlansPeriods;

    switch (cycleMonth) {
      case monthlyPeriod: return monthlyLabel;
      case quarterlyPeriod: return quarterLabel;
      default: return yearlyLabel;
    }
  };

  return (
    <div className="purchase-details mt-3 mt-md-0 col-12 col-md-7">
      <div className="ordersummary">
        <h5>Your order summary</h5>
        <hr />
        <table className="table summary-table table-borderless mb-0">
          <tbody>
            <tr>
              <td className="value-name">
                {isShowPlan ? 'Your Plan' : 'Your addon'}
                <div className="font-weight-bold tx-14">
                  {isEmpty ? '' : _.startCase(name)}
                  {isShowPlan && (
                    <span className="tx-11 ml-1 font-weight-normal">
                      /
                      {oneIndentation}
                      {isShowPlan ? renderCycleLable() : ''}
                    </span>
                  )}
                </div>
              </td>
              <td className="text-right align-bottom">
                {isEmpty ? '-' : (
                  <PaymentMoneyDisplay
                    price={price}
                    currentNetPrice={currentNetPrice}
                    shouldAvoidDecimals={false}
                    shouldHideBillCycle
                  />
                )}
              </td>
            </tr>
            {renderStrategyRow()}

            <tr>
              <td colSpan="2" className="text-left total-amount">
                <div className="payment-coupons">
                  <PaymentCoupons
                    couponCode={couponCode}
                    onChangeCouponCode={onChangeCouponCode}
                    isApplied={isApplied}
                    onCouponUpdate={onCouponUpdate}
                    message={message}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan="2" className="text-left total-amount">
                <div className="payment-coupons">
                  <Credits
                    creditBalance={creditBalance}
                    isCreditApplied={isCreditApplied}
                    onUpdateCredits={
                       (newCreditBalance, isCreditUsed) => {
                         onUpdateCredits(newCreditBalance, isCreditUsed);
                       }
                     }
                  />
                </div>
              </td>
            </tr>

            <tr className="border-top">
              <td className="text-left total-amount pt-3">
                <span className="font-weight-bold d-block">Total Amount</span>
                <small className="text-muted d-flex align-items-center">
                  <span className="material-icons-outlined tx-12 mr-1">task_alt</span>
                  Excluding GST
                </small>
              </td>

              <td className="font-weight-bold text-right align-top pt-3">
                {isEmpty ? '-' : <MoneyDisplay>{reducedAmount}</MoneyDisplay>}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {renderMessage()}
    </div>
  );
};

QuotaConfirmation.propTypes = propTypes;
QuotaConfirmation.defaultProps = defaultProps;

export default QuotaConfirmation;
