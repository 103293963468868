import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import HoverMessage from 'common/components/HoverMessage';
import ReactHtmlParser from 'react-html-parser';
import classNames from 'classnames';
import { StrikeUtility } from './utility';

const propTypes = {
  type: PropTypes.string.isRequired,
  onChangeType: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  comparison: PropTypes.string
};

const defaultProps = {
  comparison: ''
};

const Selection = ({
  type, onChangeType, errors, setError, clearErrors, comparison: propsComparison
}) => {
  const selectionErrorId = useMemo(() => _.uniqueId('selectionErrorId-'), []);
  const errorMessage = errors[selectionErrorId] ? errors[selectionErrorId]?.message || '' : '';
  const [isShowHoverMessage, setIsShowHoverMessage] = useState(false);

  const selections = StrikeUtility.getStrikeSelections();

  const isPermissionToChoose = StrikeUtility.isPermissionToChoose(type);

  useEffect(() => {
    if (!isPermissionToChoose) {
      setError(selectionErrorId, {
        type: 'custom',
        message: 'Please purchase a Premium plan to use this feature.'
      });
      setIsShowHoverMessage(true);
    } else {
      clearErrors(selectionErrorId);
    }

    return () => clearErrors(selectionErrorId);
  }, [clearErrors, isPermissionToChoose, selectionErrorId, setError]);

  useEffect(() => {
    if (!isPermissionToChoose) setIsShowHoverMessage(true);
  }, [isPermissionToChoose]);

  useEffect(() => {
    if (isShowHoverMessage) { setTimeout(() => { setIsShowHoverMessage(false); }, 5000); }
  }, [isShowHoverMessage]);

  const renderStrikeSelectionType = useCallback((sst) => {
    const {
      label, value: sstValue,
    } = sst;

    return (
      <option value={sstValue} key={sstValue}>{label}</option>
    );
  }, []);

  const renderSelect = (
    <select
      className={`based-on-select ${errorMessage ? 'is-invalid' : ''}`}
      value={`${type}-${propsComparison}`}
      onChange={onChangeType}
    >
      {_.map(selections, renderStrikeSelectionType)}
    </select>
  );

  const isDeltaBased = StrikeUtility.isDeltaBased(type);

  const renderMessage = '<b> Please purchase a <b>Premium plan to use this feature.</b>';

  const cronImageClassName = classNames('v2-crown-image position-absolute sticky-top', {
    'ml-10': isDeltaBased
  });

  const renderHoverMessage = (
    <HoverMessage
      title="Premium Plan Required"
      message={ReactHtmlParser(renderMessage)}
      isOpen={isShowHoverMessage}
    >
      <>
        {renderSelect}
        {errorMessage && <img src="/v2Crown.png" className={cronImageClassName} alt="" />}
      </>
    </HoverMessage>
  );

  return isPermissionToChoose ? renderSelect : renderHoverMessage;
};

Selection.propTypes = propTypes;
Selection.defaultProps = defaultProps;

export default Selection;
