import { getAllOrders } from 'common/api/orders';
import React, {
  useState, useEffect, createContext, useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { oneOfTypes, runPropTypes } from 'common/propTypes';
import { ORDER_TYPES, RUN_TYPES } from 'common/constants/index';
import { getContextConfig, getSlippageBasedOrders } from './helper';
import {
  getWeekdayAbbreviation,
  getBackTestFilteredStrategies,
  getParseTradingSymbol,
  getDaysToExpiry,
  getIntentConfig,
  getInstrumentType
} from '../components/BackTestFilters/helper';
import { optimizeJobs } from './metrics/formula';
import { defaultBackTestFilterConfig } from '../../../Strategies/config';

const propTypes = {
  runIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  jobIds: PropTypes.arrayOf(PropTypes.array).isRequired,
  runType: PropTypes.string.isRequired,
  showBy: oneOfTypes,
  children: PropTypes.element,
  orderType: PropTypes.string,
  run: runPropTypes,
  ordersAccessTokens: PropTypes.shape({}),
  optimizeBy: PropTypes.string,
  setCheckedRunIds: PropTypes.func
};

const { paper: { value: paper } } = ORDER_TYPES;

const defaultProps = {
  showBy: 'all',
  children: null,
  orderType: paper,
  run: {},
  ordersAccessTokens: window.ordersAccessTokens,
  optimizeBy: null,
  setCheckedRunIds: null
};

export const OrderContext = createContext(null);

const OrderStore = ({
  runIds, jobIds, runType, showBy, children, orderType, run, ordersAccessTokens, optimizeBy, setCheckedRunIds
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rawOrders, setRawOrders] = useState([]);
  const [contextConfig, setContextConfig] = useState({ orders: [], transactions: [] });
  const isMarketplaceAuthorRun = _.get(run, 'is_strategy_author', false);
  const ignoreInCompleteTransactionUntil = _.get(run, 'ignore_incomplete_orders_until', null);
  const quantConfig = _.get(run, 'quant_config', {});
  const [orders, setOrders] = useState([]);
  const intentConfig = useMemo(() => getIntentConfig(quantConfig?.cases || []), [quantConfig?.cases]);
  const { IntentMapObject, IntentArray } = intentConfig;
  const [filterConfigs, setFilterConfigs] = useState(defaultBackTestFilterConfig);
  const {
    weekDays, daysToExpiry, legs, slippageConfig: { slippage, slippageType }
  } = filterConfigs;
  const isBacktest = runType === RUN_TYPES.historic;

  const onHandleSlippage = (newSlippage, newSlippageType = slippageType) => {
    setFilterConfigs({
      ...filterConfigs,
      slippageConfig: { slippage: newSlippage, slippageType: newSlippageType }
    });
  };

  const getLeg = useMemo(
    () => (caseIndex, adjustmentIndex, optionIndex, instrumentGroupIndex, instrumentType) => {
      const caseData = IntentMapObject[caseIndex];
      if (!caseData) return null;

      const matchCriteria = {
        caseIdx: Number(caseIndex),
        adjustmentConfigIndex: adjustmentIndex === 'null' ? null : Number(adjustmentIndex),
        optionConfigIndex: optionIndex === 'null' ? null : Number(optionIndex),
        instrumentIndex: Number(instrumentGroupIndex),
        type: instrumentType
      };

      if (caseData.adjustments && caseData.adjustments[adjustmentIndex]) {
        const adjustmentLeg = caseData.adjustments[adjustmentIndex]
          .find((leg) => _.isMatch(leg, matchCriteria));
        if (adjustmentLeg) return adjustmentLeg.label;
      }

      if (caseData.entry) {
        const entryLeg = Object.values(caseData.entry).find((leg) => _.isMatch(leg, matchCriteria));
        if (entryLeg) return entryLeg.label;
      }

      return null;
    }, []
  );

  const processOrders = useCallback((transactionsorders) => {
    return _.map(transactionsorders, (order) => {
      const instrumentType = getInstrumentType(order.trading_symbol, order.option_config_index);
      return {
        ...order,
        day: getWeekdayAbbreviation(order.order_time),
        instrument_type: instrumentType,
        days_to_expiry: getDaysToExpiry(getParseTradingSymbol(order.trading_symbol), order.order_time),
        leg: getLeg(
          order.case_index,
          order.adjustment_config_index,
          order.option_config_index,
          order.instrument_group_index,
          instrumentType,
        ),
      };
    });
  }, []);

  useEffect(() => {
    Promise.all(_.map(runIds, (runId, idx) => {
      return getAllOrders({
        runId, jobIds: jobIds[idx], runType, orderType, ordersAccessTokens
      });
    })).then((result) => {
      const flattenedOrders = _.flattenDeep(result);
      console.time();
      setRawOrders(isBacktest ? processOrders(flattenedOrders) : flattenedOrders);
      setIsLoading(false);
      console.timeEnd();
    });
  }, [runIds]);

  useEffect(() => {
    if (!rawOrders.length) return;
    if (isBacktest) {
      const hasFilterConfigs = weekDays.length
      || daysToExpiry.length || legs.length || (
        !_.isEmpty(filterConfigs.slippageConfig) && Number(slippage) !== 0);
      setOrders(hasFilterConfigs ? getBackTestFilteredStrategies(rawOrders, filterConfigs) : rawOrders);
    } else {
      setOrders(Number(slippage) === 0
        ? rawOrders : getSlippageBasedOrders(rawOrders, slippage, slippageType));
    }
  }, [isBacktest, rawOrders, filterConfigs, slippage, slippageType]);

  useEffect(() => {
    if (orders.length) {
      const originalContextConfig = getContextConfig(
        orders,
        runIds,
        showBy,
        runType,
        orderType,
        ignoreInCompleteTransactionUntil,
        isMarketplaceAuthorRun,
        slippage,
        slippageType,
        filterConfigs
      );

      const optimized = optimizeJobs(originalContextConfig.transactions, optimizeBy, _.cloneDeep(runIds));

      setContextConfig(
        { ...originalContextConfig, transactions: optimized.transactions }
      );

      if (setCheckedRunIds) setCheckedRunIds(optimized.runIds);
    } else {
      setContextConfig({ orders, transactions: [] });
    }
  }, [showBy, orders, optimizeBy]);

  return (
    <OrderContext.Provider
      value={{
        ...contextConfig,
        isLoading,
        showBy,
        onHandleSlippage,
        slippage,
        slippageType,
        filterConfigs,
        IntentArray,
        setFilterConfigs
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

OrderStore.propTypes = propTypes;
OrderStore.defaultProps = defaultProps;

export default OrderStore;
