import React, { memo } from 'react';
import PropTypes from 'prop-types';
import BgBlink from 'common/components/BgBlink/index';
import MoneyDisplay from 'common/components/MoneyDisplay';
import TransactionType from 'common/components/TransactionType';
import ForceExitConfirmation from 'common/components/ForceExitConfirmation/index';
import { pairedPropTypes } from './pairedPropTypes';

const propTypes = {
  pairedOrder: pairedPropTypes,
  priceData: PropTypes.number,
  onForceExit: PropTypes.func.isRequired,
  isShowIndividualSquareOff: PropTypes.bool.isRequired
};

const defaultProps = { pairedOrder: {}, priceData: null };

const TableLegRow = ({
  pairedOrder, priceData, onForceExit, isShowIndividualSquareOff
}) => {
  const {
    entryType, exitType, orderProfit, quantity, entryPrice, exitPrice,
    tradingSymbol, shouldCalculateUnrealizedProfit, isManualExitShow
  } = pairedOrder;

  const renderPrice = (price) => {
    if (!_.isNumber(price) || price === 0) return '-';

    return <MoneyDisplay shouldColor>{price}</MoneyDisplay>;
  };

  return (
    <tr>
      <td>{tradingSymbol}</td>
      <td>{quantity}</td>
      <td>
        <div className="entry d-flex align-items-center gap-15">
          <TransactionType type={entryType} />
          <div className="price">{renderPrice(entryPrice)}</div>
        </div>
      </td>
      <td>
        <div className="entry d-flex align-items-center gap-15">
          <TransactionType type={exitType} />
          <div className="price">{renderPrice(exitPrice)}</div>
        </div>
      </td>
      <td>{(shouldCalculateUnrealizedProfit && priceData) ? <BgBlink>{priceData}</BgBlink> : '-'}</td>
      <td>{renderPrice(orderProfit)}</td>
      <td>
        {(isManualExitShow && isShowIndividualSquareOff)
          && <ForceExitConfirmation onForceExit={onForceExit} label="Close position" />}
      </td>
    </tr>
  );
};

TableLegRow.propTypes = propTypes;
TableLegRow.defaultProps = defaultProps;

export default memo(TableLegRow);
