import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';

const propTypes = {
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onChangeDate: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool
};
const defaultProps = {
  dateFormat: 'dd/MM/yyyy', showTimeSelect: false, minDate: '', maxDate: ''
};

const DateSelector = (props) => {
  const {
    selectedDate, onChangeDate, minDate, maxDate, dateFormat, showTimeSelect
  } = props;

  const customizedProps = !(minDate && maxDate) ? {} : {
    minDate: moment(minDate).toDate(),
    maxDate: moment(maxDate).toDate()
  };

  return (
    <DatePicker
      {...customizedProps}
      selected={moment(selectedDate).toDate()}
      className="form-control"
      showYearDropdown
      timeIntervals={1}
      timeFormat="HH:mm"
      timeCaption="time"
      showMonthDropdown
      showTimeSelect={showTimeSelect}
      onChange={onChangeDate}
      onYearChange={onChangeDate}
      onMonthChange={onChangeDate}
      disabledKeyboardNavigation
      dateFormat={dateFormat}
      popperModifiers={{ flip: { enabled: false }, }}
    />
  );
};

DateSelector.propTypes = propTypes;
DateSelector.defaultProps = defaultProps;

export default DateSelector;
