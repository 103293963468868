import React from 'react';
import PropTypes from 'prop-types';
import { marginFilterConfig } from 'ui/Strategies/config';

const propTypes = {
  filterConfigs: PropTypes.shape({
    margins: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  onUpdateFilterConfigs: PropTypes.func.isRequired
};

const defaultProps = {};

const MarginFilter = ({ filterConfigs, onUpdateFilterConfigs }) => {
  const { margins } = filterConfigs;

  const renderOption = (option, idx) => {
    const { label, value } = option;
    const onHandleMargin = () => {
      if (_.includes(margins, value)) {
        return _.filter(margins, (margin) => margin !== value);
      }

      return [...margins, value];
    };

    return (
      <div className="form-check form-check-sm" key={idx}>
        <input
          className="form-check-input track"
          type="checkbox"
          data-track-category="Market Place Filter"
          data-track-action={value}
          data-track-label="Margin Filter"
          id={value}
          value={value}
          checked={_.includes(margins, value)}
          onChange={() => {}}
          onClick={() => onUpdateFilterConfigs({
            ...filterConfigs,
            margins: onHandleMargin()
          })}
        />
        <label className="form-check-label" htmlFor={value}>
          {label}
        </label>
      </div>
    );
  };

  return (
    <div className="mp-filter">
      {/* <h6 className="mp-filter-title">Margin Required (Approx)</h6> */}
      <div className="mp-filter-list">
        {_.map(marginFilterConfig, renderOption)}
      </div>
    </div>
  );
};

MarginFilter.propTypes = propTypes;
MarginFilter.defaultProps = defaultProps;

export default MarginFilter;
