import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameMonth from 'date-fns/isSameMonth';
import isSameWeek from 'date-fns/isSameWeek';
import isSameDay from 'date-fns/isSameDay';
import isSameHour from 'date-fns/isSameHour';
import dateFnsGetDate from 'date-fns/getDate';
import dateFnsGetMonth from 'date-fns/getMonth';

import { getDate } from './dataUtils';

const minNumberOfTicks = 3;
const tickConfigs = [
  {
    name: 'multipleYears',
    daysInATick: 180,
    shouldShowTick: (prevDate, currentDate) => {
      return _.includes([0, 3, 6, 9], dateFnsGetMonth(currentDate)) && !isSameMonth(prevDate, currentDate);
    },
    tickFormat: (date, index) => {
      if (index === 0 || dateFnsGetMonth(date) === 0) {
        // First tick, show year. Or if Jan month show year
        return d3.timeFormat('%Y %b')(date);
      }
      return d3.timeFormat('%b')(date);
    }
  },
  {
    name: 'multipleMonths',
    daysInATick: 30,
    shouldShowTick: (prevDate, currentDate) => {
      return !isSameMonth(prevDate, currentDate);
    },
    tickFormat: (date, index) => {
      if (index === 0 || dateFnsGetMonth(date) === 0) {
        // First tick, show year. Or if Jan month show year
        return d3.timeFormat('%Y %b %e')(date);
      }
      return d3.timeFormat('%b %e')(date);
    }
  },
  {
    name: 'fewMonths',
    daysInATick: 15,
    shouldShowTick: (prevDate, currentDate) => {
      return !isSameMonth(prevDate, currentDate)
        || ((dateFnsGetDate(prevDate) <= 15) && (dateFnsGetDate(currentDate) > 15));
    },
    tickFormat: (date, index) => {
      // For second half of month, show just date.
      if (
        index === 0
        || (dateFnsGetMonth(date) === 0 && (dateFnsGetDate(date) <= 15))
      ) {
        // First tick, show year. Or if Jan month show year
        return d3.timeFormat('%Y %b %e')(date);
      }
      return d3.timeFormat('%b %e')(date);
    }
  },
  {
    name: 'weeks',
    daysInATick: 7,
    shouldShowTick: (prevDate, currentDate) => {
      return !isSameWeek(prevDate, currentDate);
    },
    tickFormat: (date, index) => {
      if (index === 0) {
        // First tick, show year
        return d3.timeFormat('%Y %b %e')(date);
      }
      return d3.timeFormat('%b %e')(date);
    }
  },
  {
    name: 'days',
    daysInATick: 1,
    shouldShowTick: (prevDate, currentDate) => {
      return !isSameDay(prevDate, currentDate);
    },
    tickFormat: (date) => {
      return d3.timeFormat('%b %e')(date);
    }
  },
  {
    name: 'hours',
    daysInATick: 0.01,
    shouldShowTick: (prevDate, currentDate) => {
      return !isSameHour(prevDate, currentDate);
    },
    tickFormat: (date, index) => {
      if (date.getHours() === 9) {
        return d3.timeFormat('%b %e')(date);
      } if (index === 0) {
        return d3.timeFormat('%b %e %H:00')(date);
      }
      return d3.timeFormat('%H:00')(date);
    }
  }
];

const getTickConfig = (minDate, maxDate, min, max) => {
  if (max - min <= 10) {
    // If only 10 values, show hourly.
    return _.last(tickConfigs);
  }
  const totalDays = Math.max(differenceInCalendarDays(maxDate, minDate), 1);

  return _.find(tickConfigs, (tickConfig) => {
    return (totalDays / tickConfig.daysInATick) >= minNumberOfTicks;
  });
};

export const getTicksGenerator = (xScale, sources) => {
  let [min, max] = xScale.domain();
  min = Math.max(Math.ceil(min), 0);
  max = Math.min(Math.floor(max), sources[0].series.length - 1);

  const minDate = getDate(sources[0].series[min]);
  const maxDate = getDate(sources[0].series[max]);

  const tickConfig = getTickConfig(minDate, maxDate, min, max);
  const tickValues = _.chain(min).range(max)
    .filter((dataIndex) => {
      const previousSeriesItem = sources[0].series[dataIndex - 1];
      const seriesItem = sources[0].series[dataIndex];
      if (!previousSeriesItem) { return true; }
      if (!seriesItem) { return false; }

      return tickConfig.shouldShowTick(
        getDate(previousSeriesItem),
        getDate(seriesItem)
      );
    })
    .value();
  const tickFormat = (d, index) => {
    const seriesItem = sources[0].series[d];
    if (!seriesItem) { return null; }
    return tickConfig.tickFormat(getDate(seriesItem), index);
  };

  return {
    tickValues,
    tickFormat
  };
};
