import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Spinner } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import { createFolder, getFolders } from 'common/api/folder';
import ErrorMesssage from '../ErrorMessage/index';

const propTypes = {
  selectedFolder: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  onSelect: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool,
  isValid: PropTypes.bool
};
const defaultProps = {
  selectedFolder: null,
  isValid: true,
  isUserLoggedIn: true,
};

const FolderSelector = (props) => {
  const {
    isUserLoggedIn, selectedFolder, isValid, onSelect
  } = props;
  const selectorId = _.uniqueId('folder-selector-');
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { errors, control } = useFormContext();
  const selectedOptions = selectedFolder ? [_.get(selectedFolder, 'name')] : [];
  const loadingClassNames = classNames('folder-selector position-relative w-100',
    { 'is-loading': isLoading });
  const errorMessage = errors['folder-selector'] ? errors['folder-selector']?.message : '';

  useEffect(() => {
    if (!isUserLoggedIn) return;

    setIsLoading(true);
    getFolders().then((fetchedFolders) => {
      setFolders(fetchedFolders);
      setIsLoading(false);
    });
  }, [isUserLoggedIn]);

  const onSelectFolder = (selectedFolderArray, onChange) => {
    if (_.isEmpty(selectedFolderArray)) return;

    const selectedArrayInitialObj = _.head(selectedFolderArray);
    const selectedFolderName = _.isObject(selectedArrayInitialObj)
      ? _.get(selectedArrayInitialObj, 'label', '') : selectedArrayInitialObj;

    if (!_.isObject(selectedArrayInitialObj)) { // already have folder select
      onSelect(_.find(folders, { name: selectedFolderName }));
      onChange(selectedFolderName);
      return;
    }

    // created new folder.. and then select
    const customOption = _.get(selectedArrayInitialObj, 'customOption', false);
    if (customOption && selectedFolderName) {
      setIsLoading(true);
      createFolder(selectedFolderName)
        .then(() => getFolders())
        .then((newlyFetchedFolders) => {
          const selectedNewlyCreatedFolder = _.find(newlyFetchedFolders, { name: selectedFolderName });
          setFolders(newlyFetchedFolders);
          setIsLoading(false);
          onSelect(selectedNewlyCreatedFolder);
          onChange(selectedFolderName);
        });
    }
  };

  return (
    <div className={loadingClassNames}>
      <div className="busy-indicator position-absolute w-100 h-100 text-center">
        <Spinner variant="primary" className="spinner" animation="border" size="sm" />
      </div>
      <ErrorMesssage message={errorMessage}>
        <Controller
          render={({ onChange, ref }) => (
            <Typeahead
              className=""
              isInvalid={!isValid || !!errors['folder-selector']}
              disabled={isLoading || !isUserLoggedIn}
              placeholder="Select a folder"
              id={selectorId}
              allowNew
              ref={ref}
              clearButton
              newSelectionPrefix="New Folder Name : "
              options={_.map(folders, 'name')}
              selected={selectedOptions}
              onChange={(newFolderName) => onSelectFolder(newFolderName, onChange)}
            />
          )}
          control={control}
          defaultValue={selectedOptions}
          name="folder-selector"
          rules={{ required: { value: true, message: 'Please select or create a new folder' } }}
        />
      </ErrorMesssage>
    </div>
  );
};

FolderSelector.propTypes = propTypes;
FolderSelector.defaultProps = defaultProps;
export default FolderSelector;
