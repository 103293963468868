import React from 'react';
import PropTypes from 'prop-types';

import {
  STOPS_VALUE_TYPES, STRIKE_KEYS
} from 'modules/TransactionsBuilder/configs';
import {
  transactionBuilderConfigPropTypes, outputSchemaPropTypes, instrumentGroupsPropTypes
} from 'common/propTypes';
import HoverMessage from 'common/components/HoverMessage';
import TradeTypeSelector from './TradeTypeSelector';
import CallOrPutSelector from './CallOrPutSelector';
import StrikeIndex from './StrikeIndex';
import Quantity from './Quantity';
import BuyOrSellSelector from './BuyOrSellSelector';
import StopsInput from './StopsInput';
import RemoveBtn from './RemoveBtn';
import ValuePathSelector from './ValuePathSelector';
import StopValueHint from './StopValueHint';
import EquityQuantityOrAmount from './EquityQuantityOrAmount';
import TrailingStopLoss from './AdvancedFeatures/TrailingStopLoss/index';
import ReEntryOrReExecute from './AdvancedFeatures/ReEntryOrReExecute/index';
import WaitAndTrade from './AdvancedFeatures/WaitAndTrade/index';

const propTypes = {
  transactionBuilderConfig: transactionBuilderConfigPropTypes.isRequired,
  onRemoveOption: PropTypes.func.isRequired,
  onTransactionBuilderChange: PropTypes.func.isRequired,
  isFutureDisable: PropTypes.bool.isRequired,
  isEquityDisable: PropTypes.bool.isRequired,
  strikeBasedOn: PropTypes.string.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  optionConfigsValidator: PropTypes.shape({}).isRequired,
  futureConfigValidator: PropTypes.shape({}).isRequired,
  equityConfigValidator: PropTypes.shape({}).isRequired,
  isInvalidStrike: PropTypes.bool.isRequired,
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  transactionConfigIdx: PropTypes.number.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDisableRemoveBtn: PropTypes.bool.isRequired,
  isAdjustment: PropTypes.bool.isRequired,
  orderConfigIndex: PropTypes.number.isRequired
};
const defaultProps = {};

const {
  strikeIndex: STRIKE_INDEX,
  indicatorValue: INDICATOR_VALUE
} = STRIKE_KEYS;

const TransactionBuilderForm = ({
  isFutureDisable, isEquityDisable, onRemoveOption, onTransactionBuilderChange, outputSchema,
  strikeBasedOn, transactionBuilderConfig, optionConfigsValidator, isInvalidStrike, instrumentGroups,
  transactionConfigIdx, segments, isDisableRemoveBtn, futureConfigValidator, equityConfigValidator,
  isAdjustment, orderConfigIndex
}) => {
  const {
    tradeType, strike, optionType, quantity, transactionType, stopGain, stopLoss,
    valuePath, stopGainPrice, stopLossPrice, amount
  } = transactionBuilderConfig;

  const isEquity = tradeType === 'equityConfig';
  const isFuture = tradeType === 'futureConfig';
  const { PERCENT, PRICE } = STOPS_VALUE_TYPES;

  const getValidator = () => {
    if (isEquity) return equityConfigValidator;
    if (isFuture) return futureConfigValidator;

    return optionConfigsValidator;
  };

  const onTransactionBuilderConfigChange = (partiallyNewConfig) => {
    onTransactionBuilderChange({ ...transactionBuilderConfig, ...partiallyNewConfig });
  };

  const renderLot = () => {
    const validator = _.get(getValidator(), isFuture ? 'quantityExpression' : 'quantity', {});

    if (isEquity && isAdmin) {
      return (
        <EquityQuantityOrAmount
          quantity={quantity}
          amount={amount}
          onUpdateConfig={onTransactionBuilderConfigChange}
          validator={validator}
        />
      );
    }

    return (
      <Quantity
        validator={validator}
        quantity={quantity}
        onQuantityChange={onTransactionBuilderConfigChange}
      />
    );
  };

  return (
    <tbody>
      <tr>
        <td>
          <TradeTypeSelector
            isFutureDisable={isFutureDisable}
            isAdjustment={isAdjustment}
            isEquityDisable={isEquityDisable}
            tradeType={tradeType}
            onTradeTypeChange={onTransactionBuilderConfigChange}
            instrumentGroups={instrumentGroups}
            segments={segments}
            onRemoveOption={onRemoveOption}
          />
        </td>
        <td>
          <CallOrPutSelector
            validator={_.get(getValidator(), 'optionType', {})}
            isHide={_.includes(['futureConfig', 'equityConfig'], tradeType)}
            optionType={optionType}
            onOptionTypeChange={onTransactionBuilderConfigChange}
          />
        </td>
        {strikeBasedOn === INDICATOR_VALUE && (
          <td>
            <ValuePathSelector
              valuePath={valuePath}
              isHide={_.includes(['futureConfig', 'equityConfig'], tradeType)}
              onValuePathChange={onTransactionBuilderConfigChange}
              outputSchema={outputSchema}
            />
          </td>
        )}
        <td>
          <StrikeIndex
            instrumentGroups={instrumentGroups}
            isHide={_.includes(['futureConfig', 'equityConfig'], tradeType)}
            isInvalidStrike={isInvalidStrike}
            onStrikeChange={onTransactionBuilderConfigChange}
            optionType={optionType}
            strike={strike}
            strikeBasedOn={strikeBasedOn}
            transactionConfigIdx={transactionConfigIdx}
            validator={_.get(getValidator(), STRIKE_INDEX, {})}
            valuePath={valuePath}
          />
        </td>
        <td>{renderLot()}</td>
        <td>
          <div>
            <BuyOrSellSelector
              validator={_.get(getValidator(), 'entryType', {})}
              transactionType={transactionType}
              onTransactionTypeChange={onTransactionBuilderConfigChange}
            />
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <StopsInput
              stopsType="stopLoss"
              stopsValue={stopLossPrice === undefined ? stopLoss : stopLossPrice}
              valueType={stopLossPrice === undefined ? PERCENT : PRICE}
              onStopsValueChange={onTransactionBuilderConfigChange}
            />
            <HoverMessage title="Stop Loss" message={(<StopValueHint isStopLoss />)}>
              <span className="material-icons-outlined text-dark tx-16 align-middle ml-2 cursor-pointer">
                info
              </span>
            </HoverMessage>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <StopsInput
              stopsType="stopGain"
              stopsValue={stopGainPrice === undefined ? stopGain : stopGainPrice}
              valueType={stopGainPrice === undefined ? PERCENT : PRICE}
              onStopsValueChange={onTransactionBuilderConfigChange}
            />
            <HoverMessage title="Take Profit" message={(<StopValueHint isStopLoss={false} />)}>
              <span className="material-icons-outlined text-dark tx-16 align-middle ml-2 cursor-pointer">
                info
              </span>
            </HoverMessage>
          </div>
        </td>
        {!isAdjustment && (
          <>
            <TrailingStopLoss
              onTransactionBuilderConfigChange={onTransactionBuilderConfigChange}
              transactionBuilderConfig={transactionBuilderConfig}
              orderConfigIndex={orderConfigIndex}
            />
            <ReEntryOrReExecute
              transactionBuilderConfig={transactionBuilderConfig}
              orderConfigIndex={orderConfigIndex}
              onTransactionBuilderConfigChange={onTransactionBuilderConfigChange}
            />
          </>
        )}

        <WaitAndTrade
          transactionBuilderConfig={transactionBuilderConfig}
          orderConfigIndex={orderConfigIndex}
          onTransactionBuilderConfigChange={onTransactionBuilderConfigChange}
        />
        <td><RemoveBtn onRemoveOption={onRemoveOption} isDisable={isDisableRemoveBtn} /></td>
      </tr>
    </tbody>
  );
};

TransactionBuilderForm.propTypes = propTypes;
TransactionBuilderForm.defaultProps = defaultProps;

export default TransactionBuilderForm;
