import React from 'react';

const ChartInk = () => {
  return (
    <div className="mt-2">
      <h6 className="mb-3">How to paste External Signal Id in Chart Ink</h6>
      <div className="tx-12">
        <ul>
          <li>
            <u className="mb-2">Open the chart ink and click on the Premium Real time alert button.</u>
            <img src="/chartInk-alert-premium.png" alt="Trading View alert" className="img-fluid mt-3 mb-5" />
          </li>
          <li>
            <u className="mb-2">In Dashboard click the alert tab.</u>
            <img src="/chartink-alert-tab.png" alt="Trading View alert" className="img-fluid mt-3 mb-5" />
          </li>
          <li>
            <u className="mb-2">Choose any strategy and Click the create alert button.</u>
            <img src="/chartInk-create-alert.png" alt="Trading View alert" className="img-fluid mt-3 mb-5" />
          </li>
          <li>
            <u className="mb-2">Paste the copied external signal id in the webhook URL box</u>
            <img src="/chartInk-webhook.png" alt="Trading View alert" className="img-fluid mt-3 mb-5" />
          </li>
        </ul>
      </div>
    </div>
  );
};

ChartInk.propTypes = {};

export default ChartInk;
