import qs from 'qs';
import wrappedFetch from './base';

export const onCreateStrategyAccess = (args) => {
  const config = {
    method: 'POST',
    url: '/strategy_accesses.json',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ strategy_access: args }),
  };

  return wrappedFetch('/strategy_accesses.json', config).then((response) => response.json());
};

export const onUpdateStrategyAccess = (args) => {
  const { id, strategyParams } = args;

  const config = {
    method: 'PUT',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ strategy_access: strategyParams }),
  };

  return wrappedFetch(`/strategy_accesses/${id}.json`, config).then((response) => response.json());
};

export const onGetStrategyAccessBasedOnStrategyId = (args) => {
  const queryParams = qs.stringify({ ...args });
  const uri = `/strategy_accesses/get_strategy_accesses_on_strategy_id?${queryParams}`;

  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};
