import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import ErrorMesssage from 'common/components/ErrorMesssage';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  isTextArea: PropTypes.bool
};

const defaultProps = { type: 'string', isTextArea: false };

const HelpLineInput = ({
  name, value, onUpdate, label, type, isTextArea
}) => {
  const { errors, register } = useFormContext();
  const mailformat = '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-zA-Z]{2,4}';

  const validatorConfig = {
    validate: (result) => {
      if (!result) return `Please type an ${name}`;
      if (result && name === 'email') {
        return !value.match(mailformat) ? 'Please type an valid email *' : null;
      }

      return null;
    }
  };

  const validatorProps = { ref: register(validatorConfig) };

  const inputClassName = classNames('form-control form-control-sm');

  return (
    <>
      <label className="col-form-label col-form-label-sm" htmlFor={label}>{label}</label>
      {isTextArea ? (
        <textarea
          {...validatorProps}
          className={inputClassName}
          value={value}
          name={name}
          id={name}
          onChange={(event) => {
            onUpdate(event.target.value);
          }}
        />
      ) : (
        <input
          {...validatorProps}
          type={type}
          required
          className={inputClassName}
          id={name}
          name={name}
          value={value ? value.toString() : ''}
          placeholder={label}
          onChange={(event) => {
            const { target: { value: targetValue } } = event;
            onUpdate(targetValue);
          }}
        />
      )}
      <ErrorMesssage error={errors[`${name}`]} />
    </>
  );
};

HelpLineInput.propTypes = propTypes;
HelpLineInput.defaultProps = defaultProps;

export default HelpLineInput;
