import {
  INTENT_TYPES,
  QUICK_ADJUSTMENT_ACTIONS,
  QUICK_ADJUSTMENT_TRIGGERS,
} from 'v2/common/constants/index';
import { buildLegProperty } from './config';

const intentTypeFor = (action) => {
  if (action === QUICK_ADJUSTMENT_ACTIONS.CloseLeg.value) {
    return INTENT_TYPES.CloseLeg;
  }

  if (action === QUICK_ADJUSTMENT_ACTIONS.ExecuteLeg.value) {
    return INTENT_TYPES.ExecuteLeg;
  }

  return INTENT_TYPES.UpdateStopLoss;
};

const intentsFor = (action, targets, labelToReferenceMap) => {
  return _.map(targets, (target) => {
    return {
      type: intentTypeFor(action),
      target: labelToReferenceMap[target],
    };
  });
};

const signalFor = (label, trigger, delayBy, labelToReferenceMap) => {
  const reference = labelToReferenceMap[label];
  const property = trigger === QUICK_ADJUSTMENT_TRIGGERS.ExitedInLoss.value
    ? 'hasExitedInLoss'
    : 'hasExitedInProfit';
  const signal = [
    {
      conditionOperator: '',
      leftOperand: buildLegProperty(reference, property),
      operator: 'equalTo',
      rightOperand: '1',
    },
  ];

  if (_.isNumber(delayBy)) {
    signal.push({
      conditionOperator: 'and',
      leftOperand: buildLegProperty(reference, 'exitTimeFromNow'),
      operator: 'equalOrAbove',
      rightOperand: `${delayBy || 0}seconds`,
    });
  }
  return signal;
};

const adjustmentsForQuickAdjustments = (
  label,
  quickAdjustments,
  labelToReferenceMap
) => {
  return _.chain(quickAdjustments)
    .groupBy((qa) => `${qa.trigger}-${qa.delayBy}`)
    .map((simillarQuickAdjustments) => {
      const { trigger, delayBy } = simillarQuickAdjustments[0] || {};
      return {
        uiConfigs: {
          isAutoAdjustment: true,
          adjustmentMode: 'quickAdjustment',
        },
        signal: signalFor(label, trigger, delayBy, labelToReferenceMap),
        intents: _.flatMap(simillarQuickAdjustments, ({ action, targets }) => {
          return intentsFor(action, targets, labelToReferenceMap);
        }),
      };
    })
    .value();
};

export const getSyntheticAdjustments = (allIntents) => {
  const labelToReferenceMap = _.reduce(
    allIntents,
    (hsh, intent) => {
      if (intent?.type === INTENT_TYPES.OpenLeg && intent?.identifier?.label) {
        // eslint-disable-next-line no-param-reassign
        hsh[intent?.identifier?.label] = intent?.identifier;
      }
      return hsh;
    },
    {}
  );

  return _.flatMap(allIntents, (intent) => {
    const quickAdjustments = intent?.uiConfigs?.quickAdjustments || [];
    const label = intent?.identifier?.label;
    if (quickAdjustments.length <= 0 || _.isUndefined(label)) {
      return [];
    }
    return adjustmentsForQuickAdjustments(
      label,
      quickAdjustments,
      labelToReferenceMap
    );
  });
};
