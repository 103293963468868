// vendor imports
import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

// project imports
import LoadingSpinner from 'common/components/LoadingSpinner';
import { handleApiError } from 'common/api/base';
import { updateUrlQueryParams } from 'common/utils/urlUtils';
import {
  createFolder, getFolders, deleteFolder, updateFolder
} from 'common/api/folder';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import Folder from './Folder';
import Filter from './Filter';
import FilteredRuns from './FilteredRuns';
import { deepLinkFilter } from './helper';
import SearchFilter from './SearchFilter';

const propsTypes = { folderId: PropTypes.number };

const defaultProps = { folderId: null };

const I18N_SCOPE = { scope: 'folders.index' };

class FolderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      folders: [],
      isLoading: false,
      currentlyUpdatingNameForFolderId: null,
      newFolderName: '',
      filterSearch: '',
      scrollToSearch: false,
      selectedFilter: 'all',
      currentlyOpenedFolderId: null,
      userLiveRunsCount: 0,
      userHistoricRunsCount: 0
    };
  }

  componentDidMount() {
    this.setState({ selectedFilter: deepLinkFilter(true) });
    this.fetchFolders();
  }

  fetchFolders = () => {
    const { folderId } = this.props;
    this.setState({ isLoading: true });

    getFolders()
      .then((folders) => {
        const historicRunsCount = [];
        const liveRunsCount = [];
        _.each(folders, (folder) => {
          historicRunsCount.push(folder.historic_runs_count);
          liveRunsCount.push(folder.live_runs_count);
        });

        this.setState({
          folders: _.orderBy(folders, 'id', 'desc'),
          isLoading: false,
          userLiveRunsCount: _.sum(liveRunsCount),
          userHistoricRunsCount: _.sum(historicRunsCount),
          currentlyOpenedFolderId: folderId || _.first(folders).id,
        });
      });
  }

  onCreateFolder = (e) => {
    const { newFolderName } = this.state;

    this.setState({ isLoading: true });
    createFolder(newFolderName)
      .then(() => { this.setState({ isLoading: false, newFolderName: '' }, this.fetchFolders); })
      .catch((error) => {
        this.setState({ isLoading: false });
        handleApiError(error);
      });

    e.preventDefault();
    return false;
  }

  onDeleteFolder = (folderId) => {
    const { folders } = this.state;

    this.setState({ isLoading: true });

    deleteFolder({ folderId })
      .then(() => {
        this.setState({
          isLoading: false,
          folders: _.filter(folders, (folder) => folder.id !== folderId),
        });
      })
      .catch((error) => {
        try {
          error.json().then((data) => {
            const errors = _.get(data, 'errors.error', []);
            const errorMessages = _.get(errors, '0', '');
            this.setState({ isLoading: false });

            if (errorMessages) { reactToastify(errorMessages, TOASTIFY_TYPES.ERROR); }
          });
        } catch (expection) {
          this.setState({ isLoading: false });
          reactToastify('Unable to delete the folder at the moment please try again', TOASTIFY_TYPES.ERROR);
        }
      });
  }

  onEditFolderName = (folderId, updatedName) => {
    const { folders } = this.state;
    this.setState({ currentlyUpdatingNameForFolderId: folderId });

    updateFolder({ folderId, folder: { name: updatedName } })
      .then(() => {
        const updatedFolders = _.map(folders, (folder) => {
          if (folder.id !== folderId) { return folder; }

          return { ...folder, name: updatedName };
        });
        this.setState({ currentlyUpdatingNameForFolderId: null, folders: updatedFolders });
      })
      .catch((error) => {
        Sentry.captureException(error);
        reactToastify('Unable to update folder name at the moment please try again', TOASTIFY_TYPES.ERROR);
      });
  }

  onSelectFolder = (newFolderId) => {
    updateUrlQueryParams('folder_id', newFolderId);
    this.setState({ currentlyOpenedFolderId: newFolderId, scrollToSearch: false });
  }

  renderFolders = () => {
    const {
      folders, currentlyUpdatingNameForFolderId, currentlyOpenedFolderId, scrollToSearch
    } = this.state;
    return _.map(folders, (folder) => (
      <Folder
        folder={folder}
        key={folder.id}
        isUpdatingFolderName={currentlyUpdatingNameForFolderId === folder.id}
        onDeleteFolder={() => this.onDeleteFolder(folder.id)}
        defaultOpen={currentlyOpenedFolderId === folder.id}
        onChangeCurrentlyOpenFolderId={this.onSelectFolder}
        onEditFolderName={(folderId, newName) => this.onEditFolderName(folderId, newName)}
        scrollToSearch={scrollToSearch}
      />
    ));
  }

  getTotalCount = () => {
    const { selectedFilter, userHistoricRunsCount, userLiveRunsCount } = this.state;
    if (selectedFilter === 'all') {
      return userLiveRunsCount + userHistoricRunsCount;
    }
    if (selectedFilter === 'historic') {
      return userHistoricRunsCount;
    }
    return userLiveRunsCount;
  }

  renderFilterBasedComponents = () => {
    const { selectedFilter, filterSearch } = this.state;

    switch (true) {
      case selectedFilter === 'all' && filterSearch === '':
        return this.renderFolders();
      default:
        const totalCount = this.getTotalCount();

        return (
          <FilteredRuns
            selectedFilter={selectedFilter}
            totalCount={totalCount}
            filterSearch={filterSearch}
          />
        );
    }
  }

  render() {
    const {
      isLoading, newFolderName, selectedFilter
    } = this.state;

    return (
      <div className="folder-view">
        <LoadingSpinner isLoading={isLoading}>
          <div className="page-heading">
            <h4 className="mb-3 mb-md-0 d-none d-md-block">Folder List</h4>
            <div className="heading-right-side ml-auto">
              <SearchFilter
                onSearchRun={((value) => {
                  this.setState({ filterSearch: value });
                })}
                isScrollToSearch={((value) => {
                  this.setState({ scrollToSearch: value });
                })}
              />
              <form
                className="input-group create-folder-form align-items-center"
                onSubmit={this.onCreateFolder}
              >
                <input
                  type="text"
                  className="form-control form-control-sm mb-0"
                  placeholder={I18n.t('create_folder_placeholder', I18N_SCOPE)}
                  value={newFolderName}
                  onChange={(event) => this.setState({ newFolderName: event.target.value })}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary track btn-sm"
                    data-track-category="Folder"
                    data-track-action="Create Folder"
                    data-track-label="Folder Trackings"
                    type="submit"
                  >
                    <i className="material-icons-outlined mr-1 tx-20 align-text-bottom">source</i>
                    {I18n.t('create_folder_btn', I18N_SCOPE)}
                  </button>
                </div>
              </form>

              <Filter
                selectedFilter={selectedFilter}
                onSelectFilter={(value) => {
                  this.setState({ selectedFilter: value });
                }}
              />
            </div>
          </div>
          <div className="folder-list-container">
            {this.renderFilterBasedComponents()}
          </div>
        </LoadingSpinner>
      </div>
    );
  }
}

FolderList.propTypes = propsTypes;
FolderList.defaultProps = defaultProps;

export default FolderList;
