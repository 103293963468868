import React from 'react';
import PropTypes from 'prop-types';
import PriceSelector from './PriceSelector';

const propTypes = {
  slOrderPlaceBasedOnPrice: PropTypes.string.isRequired,
  onChangeSlOrderPlaceBasedOnPrice: PropTypes.func.isRequired,
};

const defaultProps = {};

const SlOrderBasedOnPrice = ({ slOrderPlaceBasedOnPrice, onChangeSlOrderPlaceBasedOnPrice }) => {
  const label = 'SL order based on';

  const onChange = (newSlOrderPlaceBasedOnPrice) => {
    onChangeSlOrderPlaceBasedOnPrice(newSlOrderPlaceBasedOnPrice);
  };

  return (
    <div className="row align-items-center mb-4">
      <div className="col-md-2">
        <label className="label_style">{label}</label>
      </div>
      <div className="col-md-9 place-options">
        <PriceSelector
          optionType={slOrderPlaceBasedOnPrice}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

SlOrderBasedOnPrice.propTypes = propTypes;
SlOrderBasedOnPrice.defaultProps = defaultProps;

export default SlOrderBasedOnPrice;
