import React from 'react';
import PropTypes from 'prop-types';

import { pipeConfigsPropTypes } from 'common/propTypes';
import { indicatorsTypes } from 'ui/run/RunShow/config';
import Header from './Header';
import InstrumentsMarketDataTable from './InstrumentsMarketDataTable';
import IndicatorsDataTable from './IndicatorsDataTable';

const propTypes = {
  tockGroup: PropTypes.shape({}).isRequired,
  instrumentGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  headerData: PropTypes.arrayOf(PropTypes.shape({}))
};
const defaultProps = {
  headerData: []
};

const MarketDataMonitorTable = (props) => {
  const {
    instrumentGroup, tockGroup, pipeConfigs, headerData
  } = props;
  const timestamp = _.get(tockGroup, 'timestamp', '');

  const renderIndicatorsData = (indicatorType, key) => (
    <IndicatorsDataTable
      key={key}
      indicatorsType={indicatorType}
      tockGroup={tockGroup}
      pipeConfigs={pipeConfigs}
      instrumentGroup={instrumentGroup}
    />
  );

  return (
    <div className="table-responsive p-2">
      <table className="table mb-0 border rounded">
        <Header instrumentGroup={instrumentGroup} timestamp={timestamp} />
        <tbody>
          <InstrumentsMarketDataTable
            tockGroup={tockGroup}
            instrumentGroup={instrumentGroup}
            headerData={headerData}
          />
          {_.map(indicatorsTypes, renderIndicatorsData)}
        </tbody>
      </table>
    </div>
  );
};

MarketDataMonitorTable.propTypes = propTypes;
MarketDataMonitorTable.defaultProps = defaultProps;

export default MarketDataMonitorTable;
