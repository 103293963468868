import moment from 'moment';

export const getMeetStatus = (date, startTime, endTime) => {
  const momentDate = moment(date);
  const now = moment();

  if (!momentDate.isValid) { return { label: 'invalid Date Format', status: 'Completed' }; }

  const meetingBeginningTime = moment(startTime, 'hh:mm');
  const meetingEndTime = moment(endTime, 'hh:mm');

  const formattedMeetingBeginningTime = meetingBeginningTime.format('hh:mm A');
  const formattedMeetingEndTime = meetingEndTime.format('hh:mm A');

  if (now.isSame(date, 'day')) {
    if (now > meetingBeginningTime && now < meetingEndTime) {
      return { label: 'Streaming', status: 'Live' };
    }

    if (meetingEndTime < now) { return { label: 'meeting Closed', status: 'Completed' }; }

    return { label: ` at ${formattedMeetingBeginningTime} to ${formattedMeetingEndTime}`, status: 'Today' };
  }

  if (momentDate < now) { return { label: 'meeting Closed', status: 'Completed' }; }

  return {
    label: `Starts at (${momentDate.format('MMM Do YYYY')}), 
    ${formattedMeetingBeginningTime} to ${formattedMeetingEndTime}`,
    status: 'Upcoming'
  };
};
