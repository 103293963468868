import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setIsPlaceStoplossOrderToBroker } from 'modules/QuantBuilder/actions';
import { oneOfTypes, orderConfigsPropTypes } from 'common/propTypes';
import PlaceStoplossOrderToBrokerField from './PlaceStoplossOrderToBrokerField';

const propTypes = {
  dispatchIsPlaceStoplossOrderToBroker: PropTypes.func.isRequired,
  isPlaceStoplossOrderToBroker: oneOfTypes,
  orderConfigs: orderConfigsPropTypes
};
const defaultProps = {
  isPlaceStoplossOrderToBroker: null,
  orderConfigs: []
};

const PlaceStoplossOrderToBroker = ({
  dispatchIsPlaceStoplossOrderToBroker, isPlaceStoplossOrderToBroker, orderConfigs
}) => {
  useEffect(() => { // newly implemented, old runs don't have those config so i attached to quantConfig.
    if (isPlaceStoplossOrderToBroker === null) {
      setTimeout(() => {
        dispatchIsPlaceStoplossOrderToBroker(true);
      }, 200);
    }
  }, []);

  if (isPlaceStoplossOrderToBroker === null) return null;

  return (
    <PlaceStoplossOrderToBrokerField
      isPlaceStoplossOrderToBroker={isPlaceStoplossOrderToBroker}
      handleChange={dispatchIsPlaceStoplossOrderToBroker}
      orderConfigs={orderConfigs}
    />
  );
};

PlaceStoplossOrderToBroker.propTypes = propTypes;
PlaceStoplossOrderToBroker.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isPlaceStoplossOrderToBroker:
    _.get(state, 'quantConfig.isPlaceStoplossOrderToBroker', null),
  orderConfigs: _.get(state, 'quantConfig.orderConfigs', [])
});

const mapDispatchToProps = (dispatch) => ({
  dispatchIsPlaceStoplossOrderToBroker:
    (newValue) => dispatch(setIsPlaceStoplossOrderToBroker(newValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceStoplossOrderToBroker);
