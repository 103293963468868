import React from 'react';
import PropTypes from 'prop-types';
import ConfigDisplayDetails from './ConfigDisplayDetails';

const propTypes = {
  adjustmentOptions: PropTypes.shape({
    executionType: PropTypes.string,
  }).isRequired,
};

const defaultProps = { };

const ViewAdjustmentAdvancedOptions = ({ adjustmentOptions }) => {
  const { executionType = '' } = adjustmentOptions;
  const isShow = _.get(window, 'SA_FEATURE_FLAGS.shouldShowAdjustmentExectionType', false)
  || window?.isAdmin;

  if (!isShow) return null;

  if (!executionType) return null;

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="m-0 font-weight-medium">Advanced Options</h5>
      </div>
      <div className="card-body">
        <div className="instrument-groups-input config-rundetails">
          <ConfigDisplayDetails
            title="Adjustment / ReEntry / ReExecute / OpenNewlegs"
            value={_.startCase(executionType)}
          />
        </div>
      </div>
    </div>
  );
};

ViewAdjustmentAdvancedOptions.propTypes = propTypes;
ViewAdjustmentAdvancedOptions.defaultProps = defaultProps;

export default ViewAdjustmentAdvancedOptions;
