export const signUpFormProps = {
  email: {
    type: 'email',
    name: 'user[email]',
    autoComplete: 'new-password',
    placeholder: 'Your Email',
    required: 'required',
    icon: 'email',
    pattern: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,63}$'
  },
  password: {
    autoComplete: 'new-password',
    minLength: '6',
    name: 'user[password]',
    placeholder: 'Password',
    required: 'required',
    type: 'password',
    icon: 'lock',
  },
  passwordConfirmation: {
    minLength: '6',
    name: 'user[password_confirmation]',
    placeholder: 'Password',
    required: 'required',
    type: 'password',
    icon: 'lock',
  }
};

export const socialMedias = {
  gmail: {
    href: '#',
    className: 'social icons-google'
  },
  facebook: {
    href: '#',
    className: 'social icons-facebook-f'
  },
  twitter: {
    href: '#',
    className: 'social icons-twitter'
  },
  telegram: {
    href: '#',
    className: 'social icons-telegram'
  }
};

export const signInProps = {
  email: {
    type: 'email',
    name: 'user[email]',
    placeholder: 'Enter your email',
    icon: 'emails',
  },
  password: {
    type: 'password',
    name: 'user[password]',
    placeholder: 'Password',
    icon: 'lock',
  }
};

export const navBarItems = {
  home: { id: 'home', displayName: 'Home' },
  features: { id: 'features', displayName: 'Features' },
  plans: { id: 'plans', displayName: 'Buy a Plan' },
  faq: { id: 'faq', displayName: 'Faq' },
  contact: { id: 'contact', displayName: 'Contact Us' }
};

export const plans = {
  badshah: {
    monthly: {
      price: 2500,
      discountPercentage: 0,
      backtestCount: 250,
      liveCount: 10
    },
    halfYearly: {
      price: 11999,
      discountPercentage: 20,
      backtestCount: 250,
      liveCount: 10
    },
    yearly: {
      price: 17999,
      discountPercentage: 40,
      backtestCount: 250,
      liveCount: 10,
    }
  }
};
