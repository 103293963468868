import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TruncateText from 'common/components/TruncateText';

const propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
  onUpdate: PropTypes.func,
  isUpdating: PropTypes.bool,
  isShowEditIcon: PropTypes.bool
};
const defaultProps = {
  text: '',
  tooltip: 'Edit...',
  onUpdate: _.noop,
  isUpdating: false,
  isShowEditIcon: true
};

class InlineEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      currentText: props.text,
    };
  }

  onTextInputChange = (event) => {
    event.preventDefault();
    this.setState({ currentText: event.target.value });
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { currentText } = this.state;
      const { onUpdate } = this.props;

      onUpdate(currentText);
      this.setState({ isEditing: false });
    }
  }

  onUpdateNewText = (event) => {
    event.preventDefault();
    const { currentText } = this.state;
    const { onUpdate } = this.props;

    onUpdate(currentText);
    this.setState({ isEditing: false });
  }

  onCloseInputField = (event) => {
    const { text } = this.props;

    event.preventDefault();
    this.setState({ currentText: text, isEditing: false });
  }

  onEditBtnClick = (event) => {
    event.preventDefault();
    this.setState({ isEditing: true });
    setTimeout(() => (this.inputBoxRef && this.inputBoxRef.focus()), 50);
  }

  renderTextInputField = () => {
    const { currentText } = this.state;
    return (
      <div className="inline-editor-prevent-click prevent-collapse">
        <div className="input-group input-group-sm">
          <input
            type="text"
            className="form-control tx-15"
            value={currentText}
            onChange={this.onTextInputChange}
            onClick={(event) => event.preventDefault()}
            onKeyDown={this.handleKeyDown}
            ref={(ref) => { this.inputBoxRef = ref; }}
          />

          <div className="input-group-append" id="button-addon4">
            <button
              className="btn border-top border-bottom text-success"
              data-toggle="tooltip"
              title="Change"
              type="button"
              onClick={this.onUpdateNewText}
            >
              <i className="material-icons-outlined d-block tx-16">done</i>
            </button>

            <button
              className="btn border border text-danger"
              data-toggle="tooltip"
              title="Close"
              type="button"
              onClick={this.onCloseInputField}
            >
              <i className="material-icons-outlined d-block tx-15"> close </i>
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderLabel = () => {
    const {
      text, isUpdating, tooltip, isShowEditIcon
    } = this.props;
    return (
      <div className="common-component-inline-edit d-flex align-items-center">
        <TruncateText text={text} />
        {isShowEditIcon && (
          <span
            className="inline-editor-prevent-click prevent-collapse"
            data-toggle="tooltip"
            title={tooltip}
          >
            {isUpdating
              ? <div className="spinner-border text-primary" />
              : (
                <i className="cursor-pointer material-icons-outlined tx-16" onClick={this.onEditBtnClick}>
                  edit
                </i>
              )}
          </span>
        )}
      </div>
    );
  }

  render() {
    const { isEditing } = this.state;
    return (
      <div className="inline-edit-wrapper prevent-redirect">
        {isEditing ? this.renderTextInputField() : this.renderLabel()}
      </div>
    );
  }
}

InlineEdit.propTypes = propTypes;
InlineEdit.defaultProps = defaultProps;
export default InlineEdit;
