import React, { useEffect, useMemo } from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { RUN_GRANULARITY } from 'v2/common/constants/index';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from 'v2/common/components/ErrorMessage/index';
import moment from 'moment';
import { setRootValue } from '../../redux/actions';
import { getSelectorBasedStoreValue } from '../../redux/selector';

const propTypes = {};
const defaultProps = {};

const validInstrumentsForSecondGranularity = ['NFO:NIFTY', 'NFO:BANKNIFTY'];
const secondBySecondGranularityStartDate = '2023-01-01';

const GranularitySelector = () => {
  const granularity = useSelector((state) => getSelectorBasedStoreValue(state, 'granularity'));
  const instrumentGroups = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.instrumentGroups')
  );
  const startDate = useSelector((state) => getSelectorBasedStoreValue(state, 'startDate'));
  const dispatch = useDispatch();
  const onChangeGranularity = (newGranularity) => dispatch(setRootValue({ granularity: newGranularity }));
  const uniqueId = useMemo(() => _.uniqueId('granulatiry_error_'), []);
  const { errors, setError, clearErrors } = useFormContext();
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');
  const isPremiumError = errorMessage.includes('(second by second data)');
  const isSecond = useMemo(() => granularity === RUN_GRANULARITY.second, [granularity]);
  const isSecondBySecondEnabledForUser = _.get(
    window, 'SA_FEATURE_FLAGS.isRunGranularitySecondEnabled', false
  ) || window?.isAdmin;

  useEffect(() => {
    if (!isSecond) return clearErrors(uniqueId);

    const instruments = _.flattenDeep(instrumentGroups);
    const baseErrorMessage = 'Historic Data Type: "Second",';
    if (_.chain(instruments).difference(validInstrumentsForSecondGranularity).value().length > 0) {
      let instrumentErrorMessage = `${baseErrorMessage} `;
      instrumentErrorMessage += `supported only for ${validInstrumentsForSecondGranularity.join(' | ')}`;
      setError(uniqueId, { type: 'custom', message: instrumentErrorMessage });
    } else if (!isSecondBySecondEnabledForUser) {
      let newErrorMessage = 'Please purchase a premium plan to use this feature ';
      newErrorMessage += '(second by second data)';
      setError(uniqueId, { type: 'custom', message: newErrorMessage });
    } else if (moment(startDate) < moment(secondBySecondGranularityStartDate, 'YYYY-MM-DD')) {
      const newErrorMessage = `${baseErrorMessage} supported only from "2023 Jan 1st"`;
      setError(uniqueId, { type: 'custom', message: newErrorMessage });
    } else {
      clearErrors(uniqueId);
    }
    return () => clearErrors(uniqueId);
  }, [
    instrumentGroups,
    isSecond,
    uniqueId,
    startDate,
    clearErrors,
    setError,
    isSecondBySecondEnabledForUser
  ]);

  return (
    <div className="run-granularity-container new d-flex flex-column">
      <label className="label_style">
        Historic Candle
      </label>
      <ErrorMessage message={errorMessage}>
        <ToggleButtonGroup
          className="btn-group btn-group-sm btn-group-toggle"
          type="radio"
          size="sm"
          name="options"
          defaultValue={granularity}
          onChange={onChangeGranularity}
        >
          <ToggleButton
            bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
            className="btn-lightness track"
            value={RUN_GRANULARITY.minute}
          >
            Minute
          </ToggleButton>
          <ToggleButton
            bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
            className="btn-lightness track"
            value={RUN_GRANULARITY.second}
          >
            Second
            {isPremiumError && (
              <img src="/v2Crown.png" className="v2-crown-image" alt="" />
            )}
          </ToggleButton>
        </ToggleButtonGroup>
      </ErrorMessage>
    </div>
  );
};

export default GranularitySelector;

GranularitySelector.propTypes = propTypes;
GranularitySelector.defaultProps = defaultProps;
