import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ErrorMesssage from 'common/components/ErrorMesssage';
import { getLeftOperands } from 'common/utils/signalConfigUtils';
import { outputSchemaPropTypes } from 'common/propTypes';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  getSelectedOperandLabelForSignal,
  removeTagsFromString
} from 'common/utils/displayNames';
import ReactHtmlParser from 'react-html-parser';
import { sanitizeOutputSchema } from 'ui/run/RunForm/config';
import { sanitizeSignalConfigSchema } from './OperatorAndRightOperand/helper';

const propTypes = {
  isClonedQuant: PropTypes.bool.isRequired,
  leftOperand: PropTypes.string.isRequired,
  leftOperandValidator: PropTypes.shape({}).isRequired,
  onUpdateSignalConfig: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  signalConfigField: PropTypes.string.isRequired,
  segment: PropTypes.string.isRequired,
  isAdjustmentSignal: PropTypes.bool
};
const defaultProps = {
  isAdjustmentSignal: false
};

const LeftOperand = (props) => {
  const {
    isClonedQuant,
    leftOperand,
    leftOperandValidator,
    onUpdateSignalConfig,
    outputSchema,
    signalConfigField,
    segment,
    isAdjustmentSignal
  } = props;
  const [leftOperandName] = useState(_.uniqueId('leftOperand-'));
  const { errors, control } = useFormContext();
  const instrumentOutputSchema = sanitizeSignalConfigSchema(
    sanitizeOutputSchema(outputSchema, segment), isAdjustmentSignal, 'leftOperand'
  );

  const onLeftOperandChange = (newLeftOperand, onChange) => {
    if (_.isEmpty(newLeftOperand)) return;

    const { id } = _.first(newLeftOperand);

    onUpdateSignalConfig({
      leftOperand: id,
      operator: '',
      rightOperand: ''
    });
    onChange(newLeftOperand);
  };

  const leftOperandsOptions = getLeftOperands(
    leftOperand,
    signalConfigField,
    instrumentOutputSchema,
    isClonedQuant
  );

  const selectedOperand = getSelectedOperandLabelForSignal(instrumentOutputSchema, leftOperand);
  const operandStyle = classNames(
    'custom-rbt custom-form-control',
    errors[leftOperandName] ? 'is-invalid' : ''
  );

  return (
    <div className="left-operand">
      <Controller
        render={({ onChange, ref, name }) => (
          <Typeahead
            className={operandStyle}
            ref={ref}
            isInvalid={!!errors[leftOperandName]}
            id={name}
            options={leftOperandsOptions}
            selected={removeTagsFromString(selectedOperand)}
            clearButton
            renderMenuItemChildren={(option) => {
              return ReactHtmlParser(option.labelHtml || option.label);
            }}
            onChange={(value) => {
              if (!_.isEmpty(value)) { ref.current.blur(); }
              // when user click it will automatically focus remove

              onLeftOperandChange(value, onChange);
            }}
          />
        )}
        control={control}
        defaultValue={selectedOperand}
        name={leftOperandName}
        rules={leftOperandValidator}
      />
      <ErrorMesssage
        error={errors[leftOperandName]}
      />
    </div>
  );
};

LeftOperand.propTypes = propTypes;
LeftOperand.defaultProps = defaultProps;
export default LeftOperand;
