import wrappedFetch from './base';

export const createUsersDemos = (demoId) => {
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ demo_id: demoId })
  };

  return wrappedFetch('/users_demos/create', config)
    .then((response) => response.json());
};
