import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { IntentsContext } from 'v2/modules/withRunForm/hoc/withIntentsContext';

const propTypes = {
  target: PropTypes.string,
  onChangeTarget: PropTypes.func.isRequired,
};
const defaultProps = {
  target: '',
};

const TargetSelector = ({ target, onChangeTarget }) => {
  const {
    modifyLegsTargetSchema,
    errors,
    control
  } = useContext(IntentsContext);

  const uniqueId = useMemo(() => _.uniqueId('target_selector'), []);

  const validator = {
    validate: (val) => {
      if (!val) return 'target should be included';
      return null;
    }
  };

  return (
    <div className="modify-selector">
      <ErrorMesssage message={_.get(errors, `${uniqueId}.message`, '')}>
        <Controller
          render={({ onChange, ref, name }) => (
            <Form.Control
              as="select"
              value={target}
              ref={ref}
              size="sm"
              name={name}
              onChange={(event) => {
                onChangeTarget((event.target.value));
                onChange((event.target.value));
              }}
            >
              <option value="" />
              {_.map(modifyLegsTargetSchema, ({ id, label }) => (
                <option key={id} value={id}>{label}</option>
              ))}
            </Form.Control>
          )}
          control={control}
          defaultValue={target}
          name={uniqueId}
          rules={validator}
        />
      </ErrorMesssage>
    </div>
  );
};

TargetSelector.propTypes = propTypes;
TargetSelector.defaultProps = defaultProps;

export default TargetSelector;
