import React, { useContext } from 'react';
import { getMonthWiseData } from 'ui/run/RunShow/OrderStore/analytics/monthAnalytics';
import { OrderContext } from 'ui/run/RunShow/OrderStore/index';

import HeatMap from './chart/HeatMap';

const propTypes = {};
const defaultProps = {};
const HIT_MISS = 'hitMiss';

const MonthlyAnalytics = () => {
  const { completedTransactions } = useContext(OrderContext);
  const monthlyAnalytics = getMonthWiseData(completedTransactions);

  const renderChart = (heatMapData, title) => {
    if (title === HIT_MISS) return null;

    return (
      <div className="col-md-6" key={title}>
        <div className="chart-wraper">
          <HeatMap heatMapData={heatMapData} title={title} />
        </div>
      </div>
    );
  };

  return _.map(monthlyAnalytics, renderChart);
};

MonthlyAnalytics.propTypes = propTypes;
MonthlyAnalytics.defaultProps = defaultProps;

export default MonthlyAnalytics;
