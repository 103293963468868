import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  hasIndexInstruments, onValidateStrikeBasedOn, STRIKE_KEYS
} from 'modules/TransactionsBuilder/configs';

import { getTimeValidators } from 'common/utils/validators';
import { SEGMENT_CONFIG } from 'common/constants/index';
import { OPTIONS_EXPIRY } from 'modules/QuantBuilder/config/index';
import PropTypes from 'prop-types';
import { getQuantSupportSegment } from 'ui/run/RunForm/helper';
import {
  strategyBuilderConfig,
  emptyStrategyBuilderQuantConfig
} from './config';
import TopSection from './components/TopSection';
import OptionConfigForm from './components/OptionConfigForm';
import OptionConfigHeader from './components/OptionConfigHeader';
import StrategiesList from './components/StrategiesList';
import StrategyTimeBasedSignal from './components/StrategyTimeBasedSignal';
import { changeOptionConfigsBasedOn } from './helper';

const propTypes = {
  segments: PropTypes.arrayOf(PropTypes.string).isRequired,
  plan: PropTypes.shape({})
};

const defaultProps = { plan: null };

const getUpdatedQuantConfig = (quantConfig, newOptionConfigs, timeBasedSignal) => ({
  ...quantConfig,
  optionConfigs: newOptionConfigs,
  timeBasedSignal
});

const StrategyBuilder = ({ segments, plan }) => {
  const methods = useForm();
  const [strategies, setStrategies] = useState(strategyBuilderConfig(STRIKE_KEYS.strikeIndex));
  const initialTimeBasedSignal = _.get(strategies, 'buyCall.timeBasedSignal');
  const [segment, setSegment] = useState(getQuantSupportSegment(plan, SEGMENT_CONFIG.future));
  const [currentStrategy, setCurrentStrategy] = useState('buyCall');
  const initialOptionConfigs = _.get(strategies, [currentStrategy, 'optionConfigs']);
  const [error, setError] = useState(null);
  const [quantConfig, setQuantConfig] = useState(
    getUpdatedQuantConfig(emptyStrategyBuilderQuantConfig(), initialOptionConfigs, initialTimeBasedSignal)
  );

  useEffect(() => {
    setQuantConfig({
      ...quantConfig,
      optionStrikeCalculationBasedOn: segment === SEGMENT_CONFIG.future
        ? STRIKE_KEYS.strikeIndex : STRIKE_KEYS.equityPrice
    });
  }, [segment]);

  const {
    optionConfigs,
    optionStrikeCalculationBasedOn,
    instrumentGroups,
    timeBasedSignal
  } = quantConfig;

  const onOptionConfigsChange = (newOptionConfigs, signalTiming) => {
    setQuantConfig(getUpdatedQuantConfig(quantConfig,
      newOptionConfigs,
      signalTiming));
    setError(null);
  };
  const onQuantConfigChange = (patriallyUpdatedQuantConfig) => {
    setQuantConfig({
      ...quantConfig,
      ...patriallyUpdatedQuantConfig
    });
  };

  useEffect(() => {
    if (hasIndexInstruments(instrumentGroups)) return;
    const strikeIndexKey = onValidateStrikeBasedOn(optionStrikeCalculationBasedOn);

    const getOptionConfig = (optionConfig) => {
      return {
        ...optionConfig,
        [strikeIndexKey]: Math.abs(optionConfig[strikeIndexKey]) <= 10
          ? optionConfig[strikeIndexKey]
          : 0
      };
    };

    onQuantConfigChange({
      optionStrikeCalculationBasedOn,
      optionsExpiryCycle: OPTIONS_EXPIRY.monthly,
      optionConfigs: _.map(optionConfigs, getOptionConfig)
    });
  }, [quantConfig.instrumentGroups]);

  useEffect(() => {
    const newStrategies = strategyBuilderConfig(optionStrikeCalculationBasedOn);
    const newOptionConfigs = _.get(newStrategies, [currentStrategy, 'optionConfigs']);

    onOptionConfigsChange(
      changeOptionConfigsBasedOn(newOptionConfigs, optionStrikeCalculationBasedOn),
      timeBasedSignal
    );
    setStrategies(newStrategies);
  }, [optionStrikeCalculationBasedOn]);

  const onContinue = () => {
    const { timeBasedSignal: { entryTime, exitTime } } = quantConfig;

    if (getTimeValidators().validate(entryTime)) { // Entry time validatiom
      setError(getTimeValidators().validate(entryTime));

      return;
    }

    if (getTimeValidators({ minTime: entryTime }).validate(exitTime)) { // exit time validation
      setError(getTimeValidators({ minTime: entryTime }).validate(exitTime));

      return;
    }

    let url = '/runs/new?is_basic_builder=false&strategyBuilderConfig=';
    url += `${encodeURIComponent(JSON.stringify({ ...quantConfig, segment, currentStrategy }))}`;
    url += `&strategyName=${currentStrategy}`;

    window.location.href = url;
  };

  const renderStrategies = ({
    id, name, icon, optionConfigs: newOptionConfigs, timeBasedSignal: newTimeBasedSignal
  }) => (
    <StrategiesList
      id={id}
      key={id}
      name={name}
      icon={icon}
      optionConfigs={newOptionConfigs}
      onOptionConfigsChange={onOptionConfigsChange}
      timeBasedSignal={newTimeBasedSignal}
      optionStrikeCalculationBasedOn={optionStrikeCalculationBasedOn}
      onCurrentStrategyChange={setCurrentStrategy}
    />
  );

  const onSignalTimeChange = (time, type) => {
    setQuantConfig({
      ...quantConfig,
      timeBasedSignal: { ...quantConfig.timeBasedSignal, [type]: time }
    });
    setError(null);
  };

  const renderComponents = ({ id, name }, key) => (
    <div
      className={`tab-pane fade ${key === 'buyCall' ? 'show active' : ''}`}
      key={key}
      id={name}
      role="tabpanel"
      aria-labelledby={id}
    >
      <>
        <TopSection
          quantConfig={quantConfig}
          onQuantConfigChange={onQuantConfigChange}
          segment={segment}
          onChangeSegment={(newSegment) => setSegment(newSegment)}
          segments={segments}
        />
        <div className="mt-3 table-responsive">
          <table className="table table-sm table-th-border-none">
            <OptionConfigHeader
              optionStrikeCalculationBasedOn={optionStrikeCalculationBasedOn}
            />
            <tbody>
              <OptionConfigForm
                instrumentGroups={instrumentGroups}
                optionConfigs={optionConfigs}
                strategyName={name}
                onOptionConfigsChange={onOptionConfigsChange}
                timeBasedSignal={timeBasedSignal}
                optionStrikeCalculationBasedOn={optionStrikeCalculationBasedOn}
              />
            </tbody>
          </table>
        </div>
        <StrategyTimeBasedSignal
          timeBasedSignal={timeBasedSignal}
          onSignalTimeChange={onSignalTimeChange}
          error={error}
        />
        <button
          type="button"
          data-track-category="Strategy Option Builder"
          data-track-action={name}
          data-track-label="Navigate"
          className="btn btn-primary float-right track"
          onClick={() => onContinue(name)}
        >
          Continue
        </button>
      </>
    </div>
  );

  return (
    <FormProvider {...methods}>
      {/* <h4 className="mb-4">Options Builder</h4> */}
      <div className="option-strategy-wrapper">
        <div className="option-strategy-tab">
          <ul className="nav nav-tabs" id="myTab3" role="tablist">
            {_.map(strategies, renderStrategies)}
          </ul>
        </div>
        <div className="tab-content option-strategy-tab-content" id="myTabContent3">
          {_.map(strategies, renderComponents)}
        </div>
      </div>
    </FormProvider>
  );
};

StrategyBuilder.propTypes = propTypes;
StrategyBuilder.defaultProps = defaultProps;

export default StrategyBuilder;
