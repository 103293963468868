import React, { useEffect, useRef, useState } from 'react';
import fetchStrategies from 'common/api/strategies';
import PropTypes from 'prop-types';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { userPropTypes } from 'common/propTypes';
import { getUrlParams, updateUrlQueryParams } from 'common/utils/urlUtils';
import { deepLinkFilter } from 'ui/FolderList/helper';
import StrategyCard from './components/StrategyCard/index';
import { getDisplayStrategies, getFilteredStrategies } from './helper';
import { defaultFilterConfig } from './config';
import StrategyFilters from './components/StrategyFilters/index';
import StrategiesFilterNavTab from './components/StrategiesFilterNavTab';
import StrategyBanner from './components/StrategyBanner';
import StrategyErrorMessage from './components/StrategyErrorMessage';
import StrategieFooter from './StrategieFooter';

const propTypes = { currentUserDefaultFolderId: PropTypes.number, currentUser: userPropTypes };

const defaultProps = { currentUserDefaultFolderId: null, currentUser: null };

const INITIAL_PAGE_NO = 0;
const STRATEGIES_PER_PAGE = 10;

const Strategies = ({ currentUserDefaultFolderId, currentUser }) => {
  const [typesOfStrategies, setTypesOfStrategies] = useState({
    all: [], paid: [], deployed: [], own: []
  });
  const urlParams = getUrlParams();
  const [currentActiveStrategyType, setCurrentActiveStrategyType] = useState(deepLinkFilter());
  const [isLoading, setIsLoading] = useState(true);
  const pg = _.get(urlParams, 'pg', '');
  const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';
  const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfig);
  const currentUserId = _.get(currentUser, 'id', null);
  const strategies = _.get(typesOfStrategies, currentActiveStrategyType, []);
  const filteredStrategies = getFilteredStrategies(strategies, filterConfigs);
  const sortByPriority = (strategyArray) => _.sortBy(strategyArray, ['priority']);
  const [currentPageIndex, setCurrentPageIndex] = useState(
    Number(_.get(urlParams, 'currentPageIndex', INITIAL_PAGE_NO))
  );
  const isInitialLoad = useRef(true);
  const [strategiesPerPage, setStrategiesPerPage] = useState(STRATEGIES_PER_PAGE);
  const strategiesToDisplay = getDisplayStrategies(
    filteredStrategies, currentPageIndex, strategiesPerPage
  );
  const isShowEmptyStrategy = _.isEmpty(strategiesToDisplay) && !isLoading;

  const handleTypeOfStrategies = (results) => {
    setTypesOfStrategies({
      all: sortByPriority(results),
      paid: sortByPriority(_.filter(results,
        (result) => _.get(result, 'strategy_access.status', '') === 'paid')),
      deployed: sortByPriority(_.filter(results,
        (result) => _.get(result, 'strategy_access.is_deployed', false))),
      own: sortByPriority(_.filter(results, (result) => _.get(result, 'user_id', '') === currentUserId))
    });
  };

  useEffect(() => {
    updateUrlQueryParams('currentPageIndex', currentPageIndex, !!isMinifiedPage);
  }, [currentPageIndex]);

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    } else { setCurrentPageIndex(INITIAL_PAGE_NO); }
  }, [filterConfigs]);

  useEffect(() => {
    fetchStrategies({
      params: {
        offsetStrategyId: 0,
        minifiedpage: isMinifiedPage ? 'true' : 'false',
        pg
      }
    })
      .then((results) => {
        handleTypeOfStrategies(results);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    updateUrlQueryParams('selectedFilter', currentActiveStrategyType, !!isMinifiedPage);
  }, [currentActiveStrategyType]);

  const renderStrategy = (strategy, idx) => {
    return (
      <StrategyCard
        strategy={{ ...strategy, currentUserDefaultFolderId, currentUser }}
        key={idx}
      />
    );
  };

  const renderClearFilterButton = () => {
    const isShowClearFilterButton = _.some(filterConfigs, (filterConfig) => _.size(filterConfig) !== 0);
    if (!isShowClearFilterButton) return null;

    return (
      <button
        type="button"
        onClick={() => { setFilterConfigs(defaultFilterConfig); }}
        className="btn btn-outline-secondary"
      >
        Clear Filters
      </button>
    );
  };

  const renderStrategyContainer = () => {
    if (!isShowEmptyStrategy) {
      return <div className="profitable-wrapper">{_.map(strategiesToDisplay, renderStrategy)}</div>;
    }

    return (
      <div className="no-strategies">
        <img src="/no-strategies.svg" alt="No Strategies" />
        <div>
          No Strategies Available
          <br />
          <small>Try refining your search results or removing some filters</small>
        </div>
        {renderClearFilterButton()}
      </div>
    );
  };

  const isMarketPlaceOnlyDomain = window.applicationDomain.config.type === 'marketplace-only';

  return (
    <div className="mp-container mp-bg">
      <StrategyBanner />
      <div className="container content-area content-fixed">
        <LoadingSpinner isLoading={isLoading}>
          <main className="mp-strategy-wrapper mb-5">
            <div className="row">
              <aside className="col-lg-3 master-domain-only">
                <div className="card border-0 mt-4 mt-lg-0">
                  <StrategyFilters
                    filterConfigs={filterConfigs}
                    onUpdateFilterConfigs={setFilterConfigs}
                    strategies={strategies}
                  />
                </div>
              </aside>

              <div className={isMarketPlaceOnlyDomain ? 'col-lg-12' : 'col-lg-9'}>
                <StrategyErrorMessage currentUser={currentUser} />
                <div className="strategies-filter-navtab mt-3 mt-lg-0">
                  <div className="d-flex justify-content-between">
                    <StrategiesFilterNavTab
                      onhandleCurrentActiveStrategyType={setCurrentActiveStrategyType}
                      currentActiveStrategyType={currentActiveStrategyType}
                      typesOfStrategies={typesOfStrategies}
                      onResetPageIndex={() => setCurrentPageIndex(INITIAL_PAGE_NO)}
                    />
                  </div>
                </div>

                {!_.isEmpty(filteredStrategies) && (
                  <div className="d-flex justify-content-end mb-2">
                    <div>
                      <label>Show by</label>
                      <select
                        className="custom-select custom-select-sm ml-2 w-auto d-inline"
                        value={strategiesPerPage}
                        onChange={(event) => {
                          setStrategiesPerPage(event.target.value);
                          setCurrentPageIndex(INITIAL_PAGE_NO);
                        }}
                      >
                        <option value="6">6</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                )}

                {renderStrategyContainer()}
                {!isShowEmptyStrategy && (
                  <StrategieFooter
                    strategies={filteredStrategies}
                    handleCurrentPageIndex={setCurrentPageIndex}
                    strategiesPerPage={strategiesPerPage}
                    currentPageIndex={currentPageIndex}
                  />
                )}
              </div>
            </div>
          </main>
        </LoadingSpinner>
      </div>
    </div>
  );
};

Strategies.propTypes = propTypes;
Strategies.defaultProps = defaultProps;

export default withErrorBoundary(Strategies);
