import { CENT } from '../../config';

const roundOff = (value) => _.round(value, 2);

const getOrdersProfits = (symbolOrders) => _.map(symbolOrders, (orders) => {
  return orders.overallProfit;
});

export const computePLAndHM = (symbolOrders) => {
  const ordersProfits = getOrdersProfits(symbolOrders);
  const totalProfitsCount = ordersProfits.length;
  const profits = _.filter(ordersProfits, (profit) => profit > 0);
  const losses = _.filter(ordersProfits, (profit) => profit < 0);
  const hitPercent = roundOff((profits.length * CENT) / totalProfitsCount);
  const totalProfits = roundOff(_.sum(profits));
  const totalLosses = roundOff(_.sum(losses));
  const profitPercent = roundOff((totalProfits * CENT) / (Math.abs(totalLosses) + totalProfits));

  return {
    hit: profits.length,
    hitPercent,
    miss: losses.length,
    missPercent: roundOff(CENT - hitPercent),
    profit: totalProfits,
    profitPercent,
    loss: totalLosses,
    lossPercent: roundOff(CENT - profitPercent),
  };
};
