import { onCreatePayment, onCreateSubscription } from 'common/api/payment';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { razorpayModal } from './razorpayModal';

export const paymentInitialize = (args, immediateCallBackFunc = () => { }, isSubscription = false) => {
  const onCreatePurchase = () => {
    return (isSubscription ? onCreateSubscription(args) : onCreatePayment(args));
  };

  onCreatePurchase().then((result) => {
    const { status } = result;
    if (!status) return reactToastify('Something went wrong, Please try again', TOASTIFY_TYPES.ERROR);
    const razorpayOrderId = _.get(result, 'razorpay_order_id', '');
    const razorpaySubscriptionId = _.get(result, 'razorpay_subscription_id', '');
    const resyncTypes = _.get(result, 'resync_types', '');

    const { RAZORPAY_KEY_ID } = window;
    const razorpayModalArgs = {
      razorpayPurchaseId: razorpayOrderId || razorpaySubscriptionId,
      razorpayKeyId: RAZORPAY_KEY_ID,
      description: _.toString([resyncTypes]),
      isSubscription,
      resyncTypes,
      razorpayNotes: args,
      immediateCallBackFunc
    };

    if (razorpayOrderId === 'credit_payment') {
      reactToastify('Hurray! Purchase completed', TOASTIFY_TYPES.SUCCESS);
      setTimeout(() => { immediateCallBackFunc(); }, 2000);
      return null;
    }

    return razorpayModal(razorpayModalArgs);
  }).catch(() => {
    reactToastify('Something went wrong, Please try again', TOASTIFY_TYPES.ERROR);
  });
};
