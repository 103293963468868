import React, {
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalComponent from 'v2/common/components/ModalComponent';
import { setPartialQuantConfig } from 'v2/modules/withRunForm/redux/actions';
import { toPositiveNumberString } from 'common/stringUtils/toPositiveNumberString';
import { onResetQuantity } from 'v2/modules/withRunForm/events/quantityReset';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const QuantityReset = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState({});
  const [quantity, setQuantity] = useState(null);
  const inputRef = useRef(null);

  const { cases, pipeConfigs } = useSelector((state) => state.quantConfig);
  const dispatch = useDispatch();

  const handleValidation = useCallback((value) => {
    if (!_.toString(value)) {
      setError({ quantity: 'Lot is required' });
    } else if (parseFloat(value) <= 0) {
      setError({ quantity: 'Lot must be a positive number' });
    } else {
      setError();
    }
  }, []);

  const onSave = useCallback((event) => {
    event.preventDefault();
    handleValidation(quantity);

    if (!quantity || !_.isEmpty(error)) {
      return;
    }

    const { updatedCases, updatedPipeConfigs } = onResetQuantity(cases, pipeConfigs, quantity);
    try {
      dispatch(
        setPartialQuantConfig({
          cases: updatedCases,
          pipeConfigs: updatedPipeConfigs
        })
      );
      setIsModalOpen(false);
      reactToastify('Lot Update Successfully', TOASTIFY_TYPES.INFO);
    } catch (e) {
      reactToastify('Something went wrong unable to Update Lot', TOASTIFY_TYPES.ERROR);
    }
  }, [handleValidation, quantity, error, cases, pipeConfigs, dispatch]);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    if (isModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      setQuantity(null);
      setError({});
    };
  }, [isModalOpen]);

  const renderModal = () => {
    return (
      <ModalComponent
        onSave={(e) => onSave(e)}
        onClose={onClose}
        onCancel={onClose}
        cancelBtnTitle="Cancel"
        title="Update Lot"
        size="md"
        btnClassName="track"
        // shouldConfirmClose
        className="add-indicator-modal"
      >
        <form className="row m-0 justify-content-between">
          <div className="transaction_details-items quantity">
            <label className="col-form-label col-form-label-sm">Lot</label>
            <input
              type="number"
              name="quantity"
              min={1}
              className="form-control form-control-sm"
              onKeyDown={(e) => e.key === 'Enter' && onSave(e)}
              value={quantity === null ? '' : _.toString(quantity)}
              onBlur={(e) => handleValidation(e.target.value)}
              onChange={(e) => setQuantity(toPositiveNumberString(e.target.value))}
              ref={inputRef}
            />
            {error && error?.quantity && <span className="error text-danger tx-10">{error.quantity}</span>}
          </div>
        </form>
      </ModalComponent>
    );
  };

  return (
    <>
      { isModalOpen && renderModal()}
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalOpen(true)}
        className="dropdown-item"
      >
        Update Lot
      </div>
    </>
  );
};

export default QuantityReset;
