import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  orderType: PropTypes.string.isRequired
};

const defaultProps = {};

const RunFormInstructions = ({ orderType }) => {
  const oneIndentation = ' ';
  return (
    <div className="alert alert-sm alert-info mb-2">
      <strong>Information : </strong>
      {oneIndentation}
      You need to login into QuantMan daily before market starts
      for the
      {oneIndentation}
      {_.startCase(orderType)}
      {oneIndentation}
      order to be successfully placed with your broker.
    </div>
  );
};

RunFormInstructions.propTypes = propTypes;
RunFormInstructions.defaultProps = defaultProps;

export default RunFormInstructions;
