// vendor import
import React, { useState } from 'react';
import { strategyPropTypes } from 'common/propTypes';

import { useForm, FormProvider } from 'react-hook-form';
import { onStartStrategyTransaction } from 'common/api/strategies';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import TransactionBuilder from './TransactionBuilder';

const propTypes = {
  strategy: strategyPropTypes.isRequired,
};
const defaultProps = {
};
const TipsQuantConfigBuilderForm = (props) => {
  const { strategy: { id }, strategy } = props;
  const { liveRun } = strategy;
  const { quant_config: liveRunQuantConfig } = liveRun;

  const [quantConfig, setQuantConfig] = useState(liveRunQuantConfig);
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <TransactionBuilder quantConfig={quantConfig} onQuantConfigChange={setQuantConfig} run={liveRun} />
      <div className="d-flex">
        <button
          className="btn btn-primary ml-auto"
          type="submit"
          onClick={() => {
            methods.handleSubmit(onStartStrategyTransaction(id, quantConfig).then((response) => {
              const { status, message } = response;
              if (!status) return reactToastify(message, TOASTIFY_TYPES.ERROR);

              reactToastify('Please wait a moment, strategy is restarting', TOASTIFY_TYPES.INFO);
              return setTimeout(() => { window.location.reload(); }, 2000);
            }));
          }}
        >
          Create Now!
        </button>
      </div>
    </FormProvider>
  );
};

TipsQuantConfigBuilderForm.propTypes = propTypes;
TipsQuantConfigBuilderForm.defaultProps = defaultProps;

export default TipsQuantConfigBuilderForm;
