import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Money from 'common/components/MoneyDisplay';
import SmallInfoCard from 'common/components/SmallInfoCard';
import withErrorBoundary from 'common/components/withErrorBoundary/index';

const I18N_SCOPE = { scope: 'runs.show' };

const propTypes = {
  metrics: PropTypes.shape({})
};
const defaultProps = {
  metrics: {}
};

const renderMetrics = (metric) => {
  const {
    winningProbability,
    winLossRatio,
    recommendedAllocatedCapital,
    drawdown,
    avgProfit,
    avgLoss,
    winStreak,
    lossStreak,
    maxProfit,
    maxLoss,
    drawdowndays
  } = metric;

  const winningProbabilityStyle = classNames(
    'main-head',
    winningProbability > 0 ? 'text-success' : 'text-danger'
  );
  const roundedWinningProbability = _.round(winningProbability, 2) || winningProbability;
  const racStyle = classNames(
    'main-head',
    recommendedAllocatedCapital > 0 ? 'text-success' : 'text-danger'
  );
  const racElement = (
    <div className={racStyle}>
      {_.round(recommendedAllocatedCapital, 2)}
      &nbsp;
      %
    </div>
  );

  const runSummayInfoCards = [
    {
      leftHeader: I18n.t('winning_probability', I18N_SCOPE),
      leftValue: <div className={winningProbabilityStyle}>{roundedWinningProbability}</div>
    },
    {
      leftHeader: 'win loss ratio',
      leftValue: <div>{winLossRatio}</div>,
    },
    {
      leftHeader: I18n.t('risk_per_trade', I18N_SCOPE),
      leftValue: racElement,
    },
    {
      leftHeader: I18n.t('drawdown', I18N_SCOPE),
      leftValue: <div>{drawdown}</div>,
      rightHeader: 'Drawdown Days',
      rightValue: <div>{drawdowndays}</div>,
    },
    {
      leftHeader: 'AVG Profit',
      leftValue: <Money shouldColor>{avgProfit}</Money>,
      rightHeader: 'Avg Loss',
      rightValue: <Money shouldColor color="red">{avgLoss}</Money>,
    },
    {
      leftHeader: 'Win Streak',
      leftValue: <div>{winStreak}</div>,
      rightHeader: 'Loss Streak',
      rightValue: <div>{lossStreak}</div>,
    },
    {
      leftHeader: 'MAX Profit',
      leftValue: <Money shouldColor>{parseFloat(maxProfit)}</Money>,
      rightHeader: 'Max Loss',
      rightValue: <Money shouldColor color="red">{parseFloat(maxLoss)}</Money>,
    }
  ];

  return (
    <div className="metrics-info">
      {_.map(runSummayInfoCards,
        (cardProps, idx) => <SmallInfoCard key={idx} {...cardProps} />)}
    </div>
  );
};

const MetricsPanel = (props) => {
  const {
    metrics
  } = props;

  return (_.keys(metrics).length !== 0) && renderMetrics(metrics);
};

export default withErrorBoundary(MetricsPanel);

MetricsPanel.defaultProps = defaultProps;
MetricsPanel.propTypes = propTypes;
