import React, { Component } from 'react';
import ErrorHandler from './ErrorHandler';

const propTypes = {};

const defaultProps = {};

export default function withErrorBoundary(WrappedComponent, errorMessage = '', isShowHeader = false) {
  return class extends Component {
    render() {
      return (
        <ErrorHandler errorMessage={errorMessage} isShowHeader={isShowHeader}>
          <WrappedComponent {...this.props} />
        </ErrorHandler>
      );
    }
  };
}

withErrorBoundary.propTypes = propTypes;
withErrorBoundary.defaultProps = defaultProps;
