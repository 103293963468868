import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import ReactHtmlParser from 'react-html-parser';
import { validateBrokerUser } from 'common/api/user';
import { getBrokerImage, reDirectPage } from '../config';
import UserInput from '../CommonComponents/UserInput';
import BrokerContent from '../BrokerContent/index';

const propTypes = {
  broker: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

const defaultProps = {};

const Zebull = ({ broker, onCloseModal }) => {
  const [configs, setConfigs] = useState({
    clientId: '', apiKey: '', password: '', totp: ''
  });
  const {
    clientId, apiKey, password, totp
  } = configs;
  const [isValidateClientIdClicked, setIsValidateClientIdClicked] = useState(false);
  const [showApiKeyField, setShowApiKeyField] = useState(false);
  const [showCredentials, setShowCredentials] = useState(false);
  const [showGuideSteps, setShowGuideSteps] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const onHandleConfig = (key, value) => {
    setConfigs({ ...configs, [key]: value });
  };

  const onValidateClientId = () => {
    if (!clientId) return setIsValid(false);

    validateBrokerUser({ broker_user_id: clientId, broker }).then((result) => {
      const { status, broker_api_credentials: brokerApiCredientals } = result;
      if (!status) {
        setShowApiKeyField(true);
      } else {
        onHandleConfig('apiKey', brokerApiCredientals.api_key);
      }

      setShowCredentials(true);
      setIsValidateClientIdClicked(true);
    });

    return setIsValid(true);
  };

  const onLogin = () => {
    if (!clientId || !apiKey || !password || !totp) return setIsValid(false);
    reDirectPage({
      client_id: clientId, api_key: apiKey, password, totp
    }, broker);

    return '';
  };

  const modalProps = {
    onClose: onCloseModal,
    shouldShow: true,
    size: 'md',
    onSaveid: `btn-${broker}`,
    onSave: !isValidateClientIdClicked ? () => onValidateClientId() : () => onLogin(),
    okBtnTitle: !isValidateClientIdClicked ? 'Validate' : 'Login',
    isOkBtnDisabled: false
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="mb-6">
        {ReactHtmlParser(getBrokerImage(broker))}
        <label className="mt-2">Client Id</label>
        <UserInput
          id={`${broker}-client-id`}
          value={clientId}
          isDisabled={isValidateClientIdClicked}
          onHandleChange={(value) => { onHandleConfig('clientId', value); }}
        />

        {showCredentials && (
          <>
            <div className="mt-2">
              <label>Password</label>
              <UserInput
                id={`${broker}-password`}
                type="password"
                value={password}
                onHandleChange={(value) => { onHandleConfig('password', value); }}
              />
            </div>
            <div className="mt-2">
              <label>TOTP/OTP/DOB/PAN</label>
              <UserInput
                id={`${broker}-totp`}
                value={totp}
                onHandleChange={(value) => { onHandleConfig('totp', value); }}
              />
            </div>

          </>
        )}

        {showApiKeyField && (
        <>
          <div className="mt-2">
            <label>API Key (One-time registeration)</label>
            <UserInput
              id={`${broker}-api-key`}
              value={apiKey}
              onHandleChange={(value) => { onHandleConfig('apiKey', value); }}
            />
          </div>
          <div className="text-right mb-2">
            <button
              className="btn btn-link btn-sm"
              type="button"
              onClick={() => { setShowGuideSteps(true); }}
            >
              How to generate API?
            </button>
          </div>
        </>
        )}

        {(!showApiKeyField && showCredentials) && (
        <>
          <div className="text-right mb-2">
            <button
              className="btn btn-link btn-sm"
              type="button"
              onClick={() => {
                setConfigs({ ...configs, apiKey: '' });
                setShowApiKeyField(true);
              }}
            >
              Change api?
            </button>
          </div>
        </>
        )}

        {showGuideSteps
          && (
          <>
            <ModalComponent
              onClose={() => setShowGuideSteps(false)}
              title="Steps to generate API"
              size="lg"
              okBtnTitle=""
            >
              <BrokerContent broker={broker} />
            </ModalComponent>
          </>
          )}

        {!isValid && <div className="invalid-feedback d-block">Please provide the details</div>}
      </div>
    </ModalComponent>
  );
};

Zebull.propTypes = propTypes;
Zebull.defaultProps = defaultProps;

export default Zebull;
