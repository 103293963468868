import React from 'react';

const TradingView = () => {
  return (
    <div className="mt-2">
      <h6 className="mb-3">How to paste External Signal Id in Trading View</h6>
      <div className="tx-12">
        <ul>
          <li>
            <u className="mb-2">Open the trading view chart and click on the alert button.</u>
            <img src="/tradingview-alert.png" alt="Trading View alert" className="img-fluid mt-3 mb-5" />
          </li>
          <li>
            <u className="mb-2">
              Copy the External Signal Id and paste it in the Webhook URL box
              and click on the create button.
            </u>
            <img src="/tradingview-webhook.png" alt="Trading View alert" className="img-fluid mt-3" />
          </li>
        </ul>
      </div>
    </div>
  );
};

TradingView.propTypes = {};

export default TradingView;
