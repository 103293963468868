import {
  SET_INSTRUMENT_GROUPS,
  SET_PIPE_CONFIGS,
  SET_PARTIAL_QUANT_CONFIG,
  SET_RUN_FORM_ROOT_VALUE,
  SET_ADJUSTMENT_OPTIONS,
  SET_BOUNDS,
  SET_PARTIAL_BOUNDS,
  SET_CASES,
  SET_CASES_INDEX_BASED,
  SET_UI_BEHAVIOUR_FLAGS
} from './actions';

export default function (state = { quantConfig: {} }, action) {
  switch (action?.type) {
    case SET_PARTIAL_QUANT_CONFIG:
      return { ...state, quantConfig: { ...state.quantConfig, ...action.partialQuantConfig } };
    case SET_INSTRUMENT_GROUPS:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, instrumentGroups: action.instrumentGroups }
      };
    case SET_RUN_FORM_ROOT_VALUE:
      return { ...state, ...action.runFormConfig };
    case SET_PIPE_CONFIGS:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, pipeConfigs: action.pipeConfigs }
      };
    case SET_ADJUSTMENT_OPTIONS:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, adjustmentOptions: action.adjustmentOptions }
      };
    case SET_BOUNDS:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, bounds: action.bounds }
      };
    case SET_PARTIAL_BOUNDS:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          bounds: { ...state.quantConfig.bounds, ...action.partialBounds }
        }
      };
    case SET_CASES_INDEX_BASED:
      return {
        ...state,
        quantConfig: {
          ...state.quantConfig,
          cases: state.quantConfig.cases.map((caseConfig, idx) => (
            idx === action.idx ? action.caseConfig : caseConfig))
        },
      };
    case SET_CASES:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, cases: action.cases }
      };
    case SET_UI_BEHAVIOUR_FLAGS:
      return {
        ...state,
        quantConfig: { ...state.quantConfig, uiBehaviourFlags: action.uiBehaviourFlags }
      };
    default:
      return state;
  }
}
