import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  filteredVideos: PropTypes.arrayOf(PropTypes.shape({})),
  filteredTitles: PropTypes.arrayOf(PropTypes.string),
  onHandleFilteredTitles: PropTypes.func.isRequired
};
const defaultProps = { filteredVideos: [], filteredTitles: [] };

const VideoTitleOptionsAndSeachBar = ({ filteredVideos, filteredTitles, onHandleFilteredTitles }) => {
  const [currentSearchTitle, setCurrentSearchTitle] = useState('');
  const oneIndentation = ' ';
  const filteredTitleBasedOnUserSearch = _.filter(filteredVideos, (
    { title } // filter title in videos array.,, based on user search
  ) => title.toLowerCase().indexOf(currentSearchTitle.toLowerCase()) !== -1);

  const isTitleAlreadyFiltered = (title) => {
    return !_.find(filteredTitles, (filteredTitle) => filteredTitle === title);
  };

  const renderTitle = (video, key) => {
    const { title } = video;

    return (
      <span
        className="list-group-item list-group-item-action cursor-pointer"
        key={key}
        onClick={() => { // checked title is already in filteredTitles..
          if (isTitleAlreadyFiltered(title)) onHandleFilteredTitles([...filteredTitles, title]);
        }}
      >
        {title}
      </span>
    );
  };

  const renderSeachBar = () => {
    return (
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text material-icons-outlined">
            search
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          aria-label="currentSearchTitle"
          value={currentSearchTitle}
          onChange={(event) => {
            const { target: { value } } = event;
            setCurrentSearchTitle(value);
          }}
          aria-describedby="basic-addon1"
        />
      </div>
    );
  };

  const renderTitles = () => {
    return (
      <div className="list-group list-group-flush">
        {_.isEmpty(filteredTitleBasedOnUserSearch)
          ? <div className="text-center text-secondary">No results found...</div>
          : _.map(filteredTitleBasedOnUserSearch, renderTitle)}
      </div>
    );
  };

  const renderFilterButton = () => {
    return (
      <button
        className="video-filter-btn border dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="material-icons-outlined mr-2">
          filter_list
        </i>
        {oneIndentation}
        Videos Filters
      </button>
    );
  };

  return (
    <div className="col-md-4 col-xl-3 mb-1 mb-md-0">

      {/* Mobile View  */}
      <div className="dropdown position-relative video-filter-mv d-block d-md-none">
        {renderFilterButton()}

        <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
          {renderSeachBar()}
          {renderTitles()}
        </div>
      </div>

      {/* Web View */}
      <div className="videos-filters d-none d-md-block">
        {renderSeachBar()}
        {renderTitles()}
      </div>

    </div>
  );
};

VideoTitleOptionsAndSeachBar.propTypes = propTypes;
VideoTitleOptionsAndSeachBar.defaultProps = defaultProps;

export default VideoTitleOptionsAndSeachBar;
