import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
};

const Filter = ({ onSubmitFilter }) => {
  const today = moment().format('YYYY-MM-DD');
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmitFilter({ fromDate, toDate });
  };

  return (
    <form onSubmit={handleSubmit} method="get">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="form-group col-md-3">
              <label
                className="col-form-label col-form-label-sm"
                htmlFor="from_date"
              >
                From Date
              </label>
              <input
                type="date"
                id="from_date"
                className="form-control form-control-sm"
                value={fromDate}
                onChange={(event) => setFromDate(event.target.value)}
              />
            </div>
            <div className="form-group col-md-3">
              <label
                className="col-form-label col-form-label-sm"
                htmlFor="to_date"
              >
                To Date
              </label>
              <input
                type="date"
                id="to_date"
                className="form-control form-control-sm"
                value={toDate}
                onChange={(event) => setToDate(event.target.value)}
              />
            </div>
          </div>
          <div>
            <button type="submit" className="btn btn-sm btn-outline-primary">
              Filter
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

Filter.propTypes = propTypes;

export default Filter;
