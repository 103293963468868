import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  configKey: PropTypes.string.isRequired,
  config: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  indicatorType: PropTypes.string.isRequired
};
const defaultProps = {
  config: null
};

const statement = {
  heikinashi: (period, indicatorType) => (
    `last ${period} heikinashi candle should be ${indicatorType[1]} ${indicatorType[2] || ''}`
  ),
  renko: (period, indicatorType) => `last ${period} brick should be ${indicatorType[1]}`
};

const Hints = ({ configKey, config, indicatorType }) => {
  if (configKey !== 'noOfCandles') return null;

  const splittedIndicatorType = indicatorType.split(/(?=[A-Z])/);

  if (!_.includes(['heikinashi', 'renko'], _.head(splittedIndicatorType))) return null;

  return (
    <span style={{ fontSize: '0.8em' }}>
      {statement[_.head(splittedIndicatorType)](config, splittedIndicatorType)}
    </span>
  );
};

Hints.propTypes = propTypes;
Hints.defaultProps = defaultProps;

export default Hints;
