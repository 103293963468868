import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { onRegexOnlyNumber } from 'common/stringUtils/toPositiveNumberString';
import { QUANT_CONFIG_ORDER_TYPES } from 'v2/common/constants/index';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import ActionTypeSelector from './ActionTypeSelector';

const propTypes = {
  actionTimeInSeconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.string,
  orderType: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isExitOrder: PropTypes.bool.isRequired
};

const defaultProps = {
  actionTimeInSeconds: '',
  action: ''
};

const ModifyOrCancelOrder = ({
  action: propsAction, actionTimeInSeconds: propsActionTimeInSeconds, isExitOrder, orderType, onUpdate
}) => {
  const [action, setAction] = useState(propsAction);
  const [actionTimeInSeconds, setActionTimeInSeconds] = useState(propsActionTimeInSeconds);
  const { errors, register, clearErrors } = useFormContext();
  const validatorConfig = {
    validate: (value) => {
      return (!value ? 'value required' : null);
    }
  };

  const [modifySecondsName] = useState(_.uniqueId('modifySecondsName-'));
  const validatorProps = { ref: register(validatorConfig) };

  useEffect(() => {
    return () => {
      clearErrors(modifySecondsName);
    };
  }, [clearErrors, modifySecondsName]);

  if (orderType === QUANT_CONFIG_ORDER_TYPES.market.value) {
    return null;
  }

  const onChangeActionTimeInSeconds = () => {
    if (!_.isEmpty(action)) { onUpdate({ action, actionTimeInSeconds }); }
  };

  const onChangeAction = (value) => {
    setAction(value);
    if (_.isNumber(actionTimeInSeconds) && actionTimeInSeconds > 0) {
      onUpdate({ action: value, actionTimeInSeconds });
    }
  };

  const secondsClassNames = classNames(
    'form-control seconds-input', {
      'border border-danger': !!errors[modifySecondsName]
    }
  );

  const renderSeconds = (
    <input
      {...validatorProps}
      type="text"
      className={secondsClassNames}
      name={modifySecondsName}
      value={actionTimeInSeconds === undefined ? '' : actionTimeInSeconds.toString()}
      onBlur={() => onChangeActionTimeInSeconds()}
      onChange={(event) => setActionTimeInSeconds(onRegexOnlyNumber(event.target.value))}
    />
  );

  return (
    <div className="modify-orders mt-4">
      <div className="position-relative">
        <div className="d-flex align-items-center flex-wrap gap-5">
          <label className="mb-0 text-nowrap col-form-label col-form-label-sm">
            If order not executed in
          </label>
          <div className="position-relative d-flex gap-5 align-items-center flex-warp">
            {renderSeconds}
          </div>
          <label className="mb-0 col-form-label col-form-label-sm">seconds</label>
          <ActionTypeSelector
            action={action}
            isHideCancel={isExitOrder}
            onChange={onChangeAction}
          />
        </div>
      </div>
    </div>
  );
};

ModifyOrCancelOrder.propTypes = propTypes;
ModifyOrCancelOrder.defaultProps = defaultProps;

export default ModifyOrCancelOrder;
