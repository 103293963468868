import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  transactionConfigPropTypes, outputSchemaPropTypes, instrumentGroupsPropTypes
} from 'common/propTypes';
import { RUN_TYPES, SEGMENT_CONFIG } from 'common/constants/index';
import HoverMessage from 'common/components/HoverMessage';
import { useSelector } from 'react-redux';
import { reactToastify } from 'common/utils/reactToastify';
import {
  transactionConfigToTransactionBuilderConfig,
  convertToTransactionConfig,
  checkIsFutureAndOption,
  getDuplicateConfigIds
} from './configAdopter';
import {
  getEmptyTransactionBuilderConfig, getEmptyFutureConfig, STRIKE_KEYS
} from '../configs';
import AddOptionBtn from './components/AddOptionBtn';
import TransactionBuilderForm from './components/TransactionBuilderForm/index';
import OptionStrategySelector from './components/OptionStrategySelector/index';
import OptionsExpiryCycle from './components/OptionsExpiryCycle/index';
import TableHeader from './components/TableHeader';
import StrikeBasedOnSelector from './components/StrikeBasedOnSelector';
import AdvancedFeaturesEnablement from './components/AdvancedFeaturesEnablement/index';

const propTypes = {
  transactionConfig: transactionConfigPropTypes.isRequired,
  onTransactionConfigChange: PropTypes.func.isRequired,
  strikeBasedOn: PropTypes.string.isRequired,
  onStrikeBasedOnChange: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  transactionConfigsValidator: PropTypes.shape({}),
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  transactionConfigIdx: PropTypes.number.isRequired,
  segment: PropTypes.string.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAdjustment: PropTypes.bool,
  customPropConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  orderConfigIndex: PropTypes.number.isRequired
};
const defaultProps = {
  isAdjustment: false,
  transactionConfigsValidator: {},
  customPropConfigs: []
};

const NewTransactionBuilder = (props) => {
  const {
    transactionConfig, onTransactionConfigChange, strikeBasedOn, onStrikeBasedOnChange, outputSchema,
    transactionConfigsValidator, instrumentGroups, transactionConfigIdx, segment, segments,
    isAdjustment, customPropConfigs, orderConfigIndex
  } = props;

  const existingLegConfigs = _.get(transactionConfig, 'existingLegConfigs', []);
  const isInitialLoad = useRef(false);
  const { runType } = useSelector((state) => state);
  const isBacktest = runType === RUN_TYPES.historic;
  const isAdjustmentBasedWarningLeg = isAdjustment && !_.isEmpty(existingLegConfigs);
  const isAdjustmentBasedDisabledAddLegButton = isAdjustmentBasedWarningLeg && isBacktest;

  const [transactionBuilderConfigs, setTransactionBuilderConfigs] = useState(
    transactionConfigToTransactionBuilderConfig(transactionConfig, strikeBasedOn)
  );
  const { mode: { optionStrategy, optionSubStrategy }, mode } = transactionConfig;
  const { isFutureDisable, isEquityDisable } = checkIsFutureAndOption(transactionBuilderConfigs);
  const duplicateBuilderConfigIds = getDuplicateConfigIds(transactionBuilderConfigs);

  const handleStrikeBasedOn = (newStrikeBasedOn) => {
    if (STRIKE_KEYS.equityPrice === newStrikeBasedOn) return STRIKE_KEYS.strikeIndex;
    // for equity have to make as strikeIndex

    return newStrikeBasedOn;
  };

  useEffect(() => {
    const newTransactionBuilderConfig = transactionConfigToTransactionBuilderConfig(
      transactionConfig, handleStrikeBasedOn(strikeBasedOn)
    );

    if (_.isEqual(newTransactionBuilderConfig, transactionBuilderConfigs)) return;

    setTransactionBuilderConfigs(newTransactionBuilderConfig);
  }, [transactionConfig]);

  const updateTransactionConfig = (newTransactionBuilderConfigs, newStrikeBasedOn = strikeBasedOn) => {
    const newTransactionConfig = convertToTransactionConfig(
      newTransactionBuilderConfigs, transactionConfig, handleStrikeBasedOn(newStrikeBasedOn)
    );

    setTransactionBuilderConfigs(newTransactionBuilderConfigs);
    onTransactionConfigChange(newTransactionConfig);
  };

  const onTransactionBuilderConfigsChange = (newTransactionBuilderConfig, index) => {
    const newTransactionBuilderConfigs = transactionBuilderConfigs;

    newTransactionBuilderConfigs[index] = {
      ...newTransactionBuilderConfig, sortId: newTransactionBuilderConfig.sortId || index
    };

    updateTransactionConfig(newTransactionBuilderConfigs);
  };

  useEffect(() => {
    if (isInitialLoad.current) {
      updateTransactionConfig(_.map(
        transactionBuilderConfigs, (transactionBuilderConfig) => ({ ...transactionBuilderConfig, strike: 0 })
      ), strikeBasedOn);
    }

    isInitialLoad.current = true;
  }, [strikeBasedOn]);

  useEffect(() => {
    if (isAdjustmentBasedDisabledAddLegButton && !_.isEmpty(transactionBuilderConfigs)) {
      reactToastify('New legs are removed in adjustments. Only modify the existing legs.');
      updateTransactionConfig([], strikeBasedOn);
    }
  }, [runType]);

  const onStrategyUpdate = (newMode, newOptionConfig) => {
    onTransactionConfigChange({
      ...transactionConfig,
      mode: { ...mode, ...newMode },
      futureConfig: getEmptyFutureConfig(),
      optionConfigs: newOptionConfig
    });
  };

  const getSortId = () => (_.max(_.map(transactionBuilderConfigs, 'sortId')) + 1);

  const onAddOption = () => onTransactionBuilderConfigsChange(
    { ...getEmptyTransactionBuilderConfig(), sortId: getSortId() }, transactionBuilderConfigs.length
  );

  const onStrikeBasedOnUpdate = (newStrikeBasedOn) => { onStrikeBasedOnChange(newStrikeBasedOn); };

  const renderTransactionForm = (transactionBuilderConfig, tranIdx) => (
    <TransactionBuilderForm
      key={tranIdx}
      isAdjustment={isAdjustment}
      isDisableRemoveBtn={_.size(transactionBuilderConfigs) === 1 && !isAdjustment}
      instrumentGroups={instrumentGroups}
      optionConfigsValidator={_.get(transactionConfigsValidator, 'optionConfigsValidators', {})}
      futureConfigValidator={_.get(transactionConfigsValidator, 'futureConfigValidators', {})}
      equityConfigValidator={_.get(transactionConfigsValidator, 'equityConfigValidators', {})}
      isFutureDisable={isFutureDisable}
      isEquityDisable={isEquityDisable}
      strikeBasedOn={strikeBasedOn}
      outputSchema={outputSchema}
      customPropConfigs={customPropConfigs}
      isInvalidStrike={_.includes(duplicateBuilderConfigIds, transactionBuilderConfig.sortId)}
      transactionBuilderConfig={transactionBuilderConfig}
      transactionConfigIdx={transactionConfigIdx}
      orderConfigIndex={orderConfigIndex}
      onTransactionBuilderChange={(newConfig) => onTransactionBuilderConfigsChange(newConfig, tranIdx)}
      onRemoveOption={() => {
        updateTransactionConfig(_.filter(transactionBuilderConfigs, (value, idx) => idx !== tranIdx));
      }}
      segments={segments}
    />
  );

  const isDisabled = (!_.isEmpty(segments) && !_.includes(segments, SEGMENT_CONFIG.option))
    || (transactionBuilderConfigs || []).length > 7;

  const isShowOptionDetails = _.isEmpty(segments) || _.includes(segments, SEGMENT_CONFIG.option);

  const renderOptionAndStrikes = () => {
    if (isAdjustment) return null;

    return (
      <>
        <div className="hidden-for-future">
          <OptionStrategySelector
            optionStrategy={optionStrategy}
            strikeBasedOn={strikeBasedOn}
            optionSubStrategy={optionSubStrategy}
            onStrategyUpdate={onStrategyUpdate}
          />
        </div>
        {/* Option Stock Mock Builder... Hided default Strategies.. */}

        {isShowOptionDetails && (
          <div className="options-and-strike">
            <OptionsExpiryCycle {...props} />
            <StrikeBasedOnSelector
              strikeBasedOn={strikeBasedOn}
              onStrikeBasedOnChange={onStrikeBasedOnUpdate}
              outputSchema={outputSchema}
              instrumentGroups={instrumentGroups}
              segment={segment}
              customPropConfigs={customPropConfigs}
            />
          </div>
        )}
        {(!isAdjustment && !transactionConfigIdx)
          && <AdvancedFeaturesEnablement orderConfigIndex={orderConfigIndex} />}
      </>
    );
  };

  let warningMessage = 'You added the modification to the existing legs. ';
  warningMessage += 'If you want to enable "add legs," remove "modify legs" from below. ';
  warningMessage += 'If you add both modified legs and open new legs, the new leg will be open ';
  warningMessage += 'at the same time.';

  return (
    <div className="transaction-Builder d-flex flex-column gap-15">
      {renderOptionAndStrikes()}
      <div className="table-responsive">
        <table className="table mb-0 table-sm add-trailing-sl">
          <TableHeader
            strikeBasedOn={strikeBasedOn}
            isAdjustment={isAdjustment}
            orderConfigIndex={orderConfigIndex}
          />
          {_.map(transactionBuilderConfigs, renderTransactionForm)}
        </table>
      </div>

      <div className="text-left">
        <AddOptionBtn
          onAddOptionClick={onAddOption}
          isDisabled={isDisabled || isAdjustmentBasedDisabledAddLegButton}
        />
        {isAdjustmentBasedWarningLeg && (
          <HoverMessage message={warningMessage}>
            <span className="material-icons-outlined text-dark tx-16 align-middle ml-2 cursor-pointer">
              info
            </span>
          </HoverMessage>
        )}
      </div>
    </div>
  );
};

NewTransactionBuilder.propTypes = propTypes;
NewTransactionBuilder.defaultProps = defaultProps;

export default NewTransactionBuilder;
