import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  purchaseType: PropTypes.string.isRequired,
  onChangePurchaseType: PropTypes.func.isRequired
};

const defaultProps = {};

const PAYMENT_DESCRIPTION = 'Pay one time for the selected plan. Once the paid for duration is complete, '
  + 'you need to manually pay again to continue using the service.';
const SUBSCRIPTION_DESCRIPTION = 'In recurring payments, it automatically charges you payment every '
  + 'selected period(1month/ 1year). You do not need to manually pay every time.'
  + 'In case, you want to stop the subscription, you can cancel recurring payments anytime you want. '
  + 'Once you cancel a recurring payment, you can continue using the service for the paid duration, '
  + 'but when the paid period is over, you need to manually make payments to continue using the service.';

const purchaseTypes = [
  {
    type: 'subscription',
    label: 'Automatic Recurring Subscription',
    content: SUBSCRIPTION_DESCRIPTION,
  },
  {
    type: 'payment',
    label: 'One Time Payment',
    content: PAYMENT_DESCRIPTION,
  }
];

const PlanPurchaseTypeSelectionModal = ({
  purchaseType, onChangePurchaseType
}) => {
  const renderPurchaseType = (purchaseData, key) => {
    return (
      <React.Fragment key={key}>
        <div>
          <div className="form-check pl-0 mb-1">
            <label className="d-flex align-items-center gap-10 mb-0">
              <input
                name="purchaseType"
                type="radio"
                autoComplete="off"
                value={purchaseData.type}
                checked={purchaseType === purchaseData.type}
                onChange={() => { }}
                onClick={() => onChangePurchaseType(purchaseData.type)}
              />
              <span className="font-weight-semi">{purchaseData.label}</span>
            </label>
          </div>

          <div className="px-6 text-muted">
            {purchaseData.content}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return _.map(purchaseTypes, renderPurchaseType);
};

PlanPurchaseTypeSelectionModal.defaultProps = defaultProps;
PlanPurchaseTypeSelectionModal.propTypes = propTypes;

export default PlanPurchaseTypeSelectionModal;
