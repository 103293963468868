import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { pipeConfigPropTypes } from 'common/propTypes';
import { PipeDefinitionUtility, configDisplayNames } from 'v2/common/quantConfig/index';
import HoverMessage from 'common/components/HoverMessage';
import { PipeUtility } from '../utility';
import FloatFormField from '../components/FloatFormField';
import { customValidatorConfig } from '../customValidation';
import { customDisplayConfig } from '../customDisplayConfig';

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  validator: PropTypes.shape({}).isRequired,
  pipeIndex: PropTypes.number,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  existingPipeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Legacy = ({
  pipeConfig,
  onUpdatePipeConfig,
  control,
  errors,
  validator,
  pipeIndex,
  setError,
  clearErrors,
  existingPipeNames,
  inputKeys
}) => {
  const { type, config, chartConfig } = pipeConfig;

  const inputSchema = useMemo(
    () => PipeDefinitionUtility.getPipeInputSchemaByType(pipeConfig.type),
    [pipeConfig.type]
  );
  const customValidator = useMemo(() => customValidatorConfig(config), [config]);

  const renderDeclarativeComponent = (Component, configKey, inputSchemaConfig, inputType) => {
    const configLabel = _.get(
      inputSchemaConfig,
      'label',
      _.get(configDisplayNames, [configKey], configKey)
    );
    const className = PipeUtility.getParentClassNameByType(inputType);
    const options = _.get(inputSchemaConfig, 'options', []);
    const info = _.get(inputSchemaConfig, 'info', '');

    return (
      <div className={className} key={configKey}>
        <label className="d-flex align-items-center">
          {_.startCase(configLabel)}
          {info && (
            <HoverMessage message={info}>
              <span className="material-icons-outlined info-icon ml-1">info</span>
            </HoverMessage>
          )}
        </label>
        <Component
          pipeConfig={pipeConfig}
          type={type}
          name={configKey}
          pipeIndex={pipeIndex}
          value={_.get(config, configKey, _.get(chartConfig, configKey, ''))}
          validator={_.get(customValidator, configKey, _.get(validator, configKey, {}))}
          control={control}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          options={options}
          existingPipeNames={existingPipeNames}
          onUpdatePipeConfig={onUpdatePipeConfig}
        />
      </div>
    );
  };

  const renderField = (configKey) => {
    const inputSchemaConfig = _.find(inputSchema, { name: configKey }) || {};
    const inputType = _.get(inputSchemaConfig, 'type', configKey);
    const isDisplay = _.get(
      inputSchemaConfig,
      'isDisplay',
      _.get(customDisplayConfig(pipeConfig), configKey, true)
    );
    if (!isDisplay) return null;

    const component = PipeUtility.getComponentByType(inputType);
    if (component) {
      return renderDeclarativeComponent(
        component,
        configKey,
        inputSchemaConfig,
        inputType
      );
    }

    return renderDeclarativeComponent(FloatFormField, configKey, inputSchemaConfig);
  };

  return _.map(inputKeys, renderField);
};

Legacy.propTypes = propTypes;

export default Legacy;
