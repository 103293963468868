import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  referralURL: PropTypes.string.isRequired
};

const defaultProps = {};

const SocialShare = ({ referralURL }) => {
  const message = 'SignUp on Quantman to backtest and deploy your own strategies in live.';
  const messageAndReferralURL = `${message} ${referralURL}`;
  const whatsappURL = `https://wa.me/?text=${messageAndReferralURL}`;
  const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${referralURL}`;
  const twitterURL = `https://twitter.com/intent/tweet?text=${messageAndReferralURL}`;
  const telegramURL = `https://telegram.me/share/url?url=${messageAndReferralURL}`;

  const renderSocial = (socialName, socialImage, socialUrl) => {
    return (
      <a
        href={socialUrl}
        target="_blank"
        className="social-link track"
        rel="noreferrer"
        data-track-category="Footer"
        data-track-action={socialName}
        data-track-label="Footer track"
      >
        <div className="social-inner">
          <img src={socialImage} alt="facebook" />
        </div>
      </a>
    );
  };

  return (
    <div className="share-referral-link text-center">
      <p className="tx-16 text-muted m-0"><b>Share Referral Link on</b></p>
      <p className="tx-14 text-muted mb-3">
        You can also share your referral link
        <br />
        by copying and sending it or sharing it on your social media.
      </p>

      <div className="d-flex align-items-center flex-column justify-content-center gap-10">
        <div className="footer-social">
          {renderSocial('facebook', '/facebook.svg', facebookURL)}
          {renderSocial('twitter', '/twitter.svg', twitterURL)}
          {renderSocial('telegram', '/telegram.svg', telegramURL)}
          {renderSocial('whatsapp', '/whatsapp.png', whatsappURL)}
        </div>
      </div>
    </div>
  );
};

SocialShare.propTypes = propTypes;
SocialShare.defaultProps = defaultProps;

export default SocialShare;
