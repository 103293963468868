import {
  getEmptyTransactionBuilderConfig, onValidateStrikeBasedOn, ADJUST_PERCENTAGE,
  TRAILING_PERCENTAGE, TRANSACTION_MODES, waitAndTradeTypesAndNode
} from 'modules/TransactionsBuilder/configs';

const getTrailingStopLossConfig = (config) => {
  const trailingStopLoss = _.get(config, 'trailingStopLoss', {});
  const trailingPercentage = _.get(trailingStopLoss, TRAILING_PERCENTAGE, undefined);
  const adjustPercentage = _.get(trailingStopLoss, ADJUST_PERCENTAGE, undefined);

  return { trailingPercentage, adjustPercentage };
};

const getReExecuteConfig = (config) => { return { reExecuteCount: _.get(config, 'reExecuteCount', 0) }; };
const getReEntryConfig = (config) => { return { reEntryCount: _.get(config, 'reEntryCount', 0) }; };

const getWaitAndTradeConfig = (config) => {
  const waitUntilPremiumToDecreaseInPercentage = _.get(config, 'waitUntilPremiumToDecreaseInPercentage', 0);
  const waitUntilPremiumToIncreaseInPercentage = _.get(config, 'waitUntilPremiumToIncreaseInPercentage', 0);
  const waitAndTradeType = _.get(
    config, 'waitAndTradeType', waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE
  );

  return { waitUntilPremiumToDecreaseInPercentage, waitUntilPremiumToIncreaseInPercentage, waitAndTradeType };
};

const getTransactionBuilderFutureConfigs = (futureConfig) => {
  const {
    quantityExpression, entryType, stopGain, stopLoss, sortId, stopGainPrice, stopLossPrice
  } = futureConfig;

  return ([{
    ...getEmptyTransactionBuilderConfig(),
    ...getTrailingStopLossConfig(futureConfig),
    ...getReExecuteConfig(futureConfig),
    ...getReEntryConfig(futureConfig),
    ...getWaitAndTradeConfig(futureConfig),
    tradeType: 'futureConfig',
    quantity: quantityExpression,
    transactionType: entryType,
    stopGain,
    stopLoss,
    sortId,
    stopGainPrice,
    stopLossPrice
  }]);
};

const getTransactionBuilderEquityConfigs = (equityConfig) => {
  const {
    quantity, entryType, stopGain, stopLoss, sortId, stopGainPrice, stopLossPrice, amount
  } = equityConfig;

  return ([{
    ...getEmptyTransactionBuilderConfig(),
    ...getTrailingStopLossConfig(equityConfig),
    ...getReExecuteConfig(equityConfig),
    ...getReEntryConfig(equityConfig),
    ...getWaitAndTradeConfig(equityConfig),
    tradeType: 'equityConfig',
    quantity: quantity ? Number(quantity) : 0,
    transactionType: entryType,
    amount: amount || 0,
    stopGain,
    stopLoss,
    sortId,
    stopGainPrice,
    stopLossPrice
  }]);
};

const getTransactionBuilderOptionConfigs = (optionConfigs, strikeBasedOn) => (
  _.map(optionConfigs, (optionConfig) => {
    const {
      entryType,
      quantity,
      valuePath = '',
      stopGainPrice,
      stopLossPrice,
      optionType,
      stopGain,
      stopLoss,
      sortId
    } = optionConfig;
    const basedOn = onValidateStrikeBasedOn(strikeBasedOn);

    return {
      ...getEmptyTransactionBuilderConfig(),
      ...getTrailingStopLossConfig(optionConfig),
      ...getReExecuteConfig(optionConfig),
      ...getReEntryConfig(optionConfig),
      ...getWaitAndTradeConfig(optionConfig),
      tradeType: 'optionConfigs',
      strike: _.get(optionConfig, [basedOn], undefined),
      transactionType: entryType,
      quantity: quantity ? Number(quantity) : 0,
      valuePath,
      stopGainPrice,
      stopLossPrice,
      optionType,
      stopGain,
      stopLoss,
      sortId
    };
  })
);

export const transactionConfigToTransactionBuilderConfig = (transactionConfig, strikeBasedOn) => {
  const {
    futureConfig, optionConfigs, equityConfig, mode: { transaction },
  } = transactionConfig;

  // 1. this process is for current component state array
  // 2. why maintain mode ? in quant(transactionConfig) it has optionConfigs: [{...}, {....}],
  // futureConfig: {...} and equityConfig: {...} it all has defaultConfig.
  // 3. I don't know which i want to take.. so based on mode: { transaction: '' }.. i will take the
  // hash from quant transaction config. The mode is based on TRANSACTION_MODES and checkIsFutureAndOption fn
  // 4. Below conditions is completely based on based mode.

  // TODO in future may be... remove the mode combinations and give flag for each tradeTypes
  // eg.. isFuture: true, isOption: true, isEquity: true
  // if we approached this process.. the mode is stored in quant Config.. write migration for each runs to
  // change the mode  or something new..

  const {
    equityConfig: tranactionEquityConfig, futureAndEquity,
    futureAndOption, futureConfig: transactionFutureConfig, optionAndEquity,
    optionConfigs: transactionoptionConfigs, combinedAllConfigs
  } = TRANSACTION_MODES;

  const isIncludesFuture = _.includes(
    [transactionFutureConfig, futureAndOption, futureAndEquity, combinedAllConfigs], transaction
  );
  const isIncludesOption = _.includes(
    [transactionoptionConfigs, futureAndOption, optionAndEquity, combinedAllConfigs], transaction
  );

  const isIncludesEquity = _.includes(
    [tranactionEquityConfig, futureAndEquity, optionAndEquity, combinedAllConfigs], transaction
  );

  return _.chain([
    isIncludesFuture ? getTransactionBuilderFutureConfigs(futureConfig) : null,
    isIncludesOption ? getTransactionBuilderOptionConfigs(optionConfigs, strikeBasedOn) : null,
    isIncludesEquity ? getTransactionBuilderEquityConfigs(equityConfig) : null,
  ])
    .flattenDeep()
    .compact()
    .sortBy((formattedTransaction) => { return _.get(formattedTransaction, 'sortId'); })
    .value();
};
