import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { purchasePropTypes } from 'common/propTypes';
import { planCycleLabel } from 'modules/UpgradePlan/helper';
import { getConvertedTime } from 'common/utils/transactions';
import Money from 'common/components/MoneyDisplay';
import { number2words } from './paymentInvoice';

const propTypes = {
  purchase: purchasePropTypes.isRequired,
  gstPercentage: PropTypes.number.isRequired,
  quantManGstNumber: PropTypes.string
};
const defaultProps = {
  quantManGstNumber: null
};
const oneIndentation = ' ';

const renderPurchaseItem = (purchaseItem) => {
  const purchasable = _.get(purchaseItem, 'purchasable', {});
  let name;
  switch (purchaseItem.purchasable_type) {
    case 'Plan':
      name = (
        `${purchasable.plan_name}-`
        + `${planCycleLabel(purchasable.cycle_duration_in_months)}`
      );
      break;
    case 'Addon':
      name = purchasable.addon_name;
      break;
    default:
      name = `Strategy-${purchasable.strategy_id}`;
      break;
  }

  const discount = (
    purchaseItem.discount_percentage
      ? ((parseFloat(purchaseItem.price) / 100) * parseFloat(purchaseItem.discount_percentage))
      : 0
  );
  return (
    <tr key={purchaseItem.id}>
      <td className="">
        {purchaseItem.purchasable_type}
      </td>
      <td className="text-center">{name}</td>
      <td className="text-right">
        <Money shouldAvoidDecimals>{purchaseItem.price}</Money>
      </td>
      <td className="text-right">
        <Money shouldAvoidDecimals>{discount}</Money>
      </td>
      <td className="text-right">
        <Money shouldAvoidDecimals>{parseFloat(purchaseItem.price) - discount}</Money>
      </td>
    </tr>
  );
};

const InvoiceContent = ({ purchase, gstPercentage, quantManGstNumber }) => {
  const termsAndConditonsHeading = 'Terms & Conditions';
  const creditsUsed = _.get(purchase, 'credit_transaction.amount', 0);

  return (
    <div className="pr-2">
      <div className="invoice-container card p-5 mb-10">
        <header>
          <div className="row align-items-center">
            <div className="col-md-7 text-left">
              <img className="logo" src="/quantman-logo-light.svg" title="Quantman" alt="Quantman" />
            </div>
            <div className="col-md-5 text-right">
              <h4 className="tx-25 mb-0">Invoice</h4>
              {purchase.id}
            </div>
          </div>
        </header>

        <hr />

        <main>
          <div className="row mb-3">
            <div className="col-sm-6">
              <strong>Invoiced To:</strong>
              <br />
              {purchase.user_name && (
                <>
                  {_.capitalize(purchase.user_name)}
                  <br />
                </>
              )}
              GSTIN:
              {oneIndentation}
              {purchase.user_gst_number || '-'}
            </div>
            <div className="col-sm-6 text-right">
              <div>
                <strong>Invoice From</strong>
                <br />
                Simply Algo Fintech Private Limited
                <br />
                GSTIN:
                {oneIndentation}
                {quantManGstNumber}
              </div>
              <div>
                <strong>Invoice Date</strong>
                <br />
                {getConvertedTime(purchase.created_at).format('DD MMM YYYY, HH:mm:ss')}
              </div>
            </div>
          </div>

          <table className="table mb-0 mt-5">
            <thead className="">
              <tr>
                <th><strong>Type</strong></th>
                <th className="text-center"><strong>Details</strong></th>
                <th className="text-center"><strong>Actual Price</strong></th>
                <th className="text-center"><strong>Discount</strong></th>
                <th className="text-right"><strong>Discounted Price</strong></th>
              </tr>
            </thead>
            <tbody>
              {_.map(purchase.purchase_items, renderPurchaseItem)}
            </tbody>
            <tfoot className="card-footer">
              {(creditsUsed !== 0)
                && (
                <tr>
                  <td colSpan="4" className="text-right">
                    Credits Used:
                  </td>
                  <td className="text-right">
                    <Money shouldAvoidDecimals>{Math.trunc(parseFloat(creditsUsed))}</Money>
                  </td>
                </tr>
                )}
              <tr>
                <td colSpan="4" className="text-right">
                  Tax (GST
                  {gstPercentage}
                  %):
                </td>
                <td className="text-right">
                  <Money shouldAvoidDecimals>{Math.trunc(parseFloat(purchase.price_gst))}</Money>
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="text-right border-0 py-1 tx-16">
                  <strong>Grand Total (Incl.Tax):</strong>
                </td>
                <td className="text-right border-0 py-1 tx-16">
                  <strong>
                    <Money shouldAvoidDecimals>{Math.trunc(parseFloat(purchase.price_total))}</Money>
                  </strong>
                </td>
              </tr>

              <tr>
                <td colSpan="5" className="text-right">
                  Total Amount in Words :
                  {oneIndentation}
                  <b>
                    {_.capitalize(number2words(Math.trunc(parseFloat(purchase.price_total))))}
                  </b>
                </td>
              </tr>
            </tfoot>
          </table>
          <br />

          <div className="mt-25">
            <h6 className="mb-3">{termsAndConditonsHeading}</h6>
            <ol className="terms-Conditons pl-2">
              <li>All plans are non-refundable</li>
              <li>
                Cancellation does not lead to a refund.
                It only ensures that you will not be charged a renewal fee in future
              </li>
              <li>
                Your access remains active till the end of the
                current billing period even if you cancel membership midway
              </li>
            </ol>
          </div>

          <p className="tx-12">
            <strong>NOTE :</strong>
            {oneIndentation}
            This is computer generated receipt and does not require physical signature.
          </p>
        </main>

        <footer className="text-center border-top mt-3 pt-3">
          <div className="tx-12">
            9/28 Ramalakshmi nagar, Lakshmipuram, Peelamedu, Coimbatore-641004
            <br />
            <b>Email:</b>
            {oneIndentation}
            support@quantman.in
          </div>
        </footer>
      </div>
    </div>
  );
};

InvoiceContent.propTypes = propTypes;
InvoiceContent.defaultProps = defaultProps;

export default InvoiceContent;
