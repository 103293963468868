import React, {
  memo, useEffect, useState,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { transactionBuilderConfigPropTypes } from 'common/propTypes';
import { useSelector } from 'react-redux';
import { advancedFeatureConfigs } from 'modules/QuantBuilder/config/signalConfigs';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { waitAndTradeTypesAndNode } from 'modules/TransactionsBuilder/configs';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import withErrorBoundary from 'common/components/withErrorBoundary/index';

const propTypes = {
  transactionBuilderConfig: transactionBuilderConfigPropTypes.isRequired,
  orderConfigIndex: PropTypes.number.isRequired,
  onTransactionBuilderConfigChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const WaitAndTrade = ({
  transactionBuilderConfig, orderConfigIndex, onTransactionBuilderConfigChange
}) => {
  const {
    waitAndTradeType, waitUntilPremiumToIncreaseInPercentage,
    waitUntilPremiumToDecreaseInPercentage
  } = transactionBuilderConfig;

  const {
    WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE, WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE
  } = waitAndTradeTypesAndNode;

  const globalReduxState = useSelector((state) => state);
  const runType = _.get(globalReduxState, 'runType');
  const orderConfigs = _.get(globalReduxState, 'quantConfig.orderConfigs', []);
  const currentOrderConfig = _.get(orderConfigs, orderConfigIndex);
  const advancedFeatures = _.get(currentOrderConfig, 'advancedFeatureConfigs', advancedFeatureConfigs());
  const isWaitAndTrade = _.get(advancedFeatures, 'isWaitAndTrade', false);

  const activeCount = WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE === waitAndTradeType
    ? waitUntilPremiumToIncreaseInPercentage : waitUntilPremiumToDecreaseInPercentage;
  const isInitialLoad = useRef(true);
  const [waitValue, setWaitValue] = useState(activeCount);

  const onHandleActiveCount = (newCount = 0) => {
    if (waitAndTradeType === WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE) {
      onTransactionBuilderConfigChange({
        [WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: newCount,
        [WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
      });
      return null;
    }

    return onTransactionBuilderConfigChange({
      [WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
      [WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: newCount,
    });
  };

  useEffect(() => setWaitValue(activeCount), [activeCount]);

  useEffect(() => {
    if (!isInitialLoad.current) {
      setWaitValue(0);
    } else {
      isInitialLoad.current = false;
    }
  }, [waitAndTradeType]);

  useEffect(() => {
    if (runType === 'historic'
    && (waitUntilPremiumToIncreaseInPercentage || waitUntilPremiumToDecreaseInPercentage)
    ) {
      setTimeout(() => {
        onTransactionBuilderConfigChange({
          [WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
          [WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
        });
      }, [2000]);
    }
  }, [runType]);

  const isShowWaitAndTradeForBackTest = runType === 'historic'
    && _.get(window, 'userPermissions.waitAndTradeForBacktest.isEnabled', false);

  if ((runType === 'historic' && !isShowWaitAndTradeForBackTest) || !isWaitAndTrade) return null;

  const renderNode = (
    <div className="input-group overall-stops">
      <ToggleButtonGroup
        bsPrefix="btn-group btn-group-sm btn-group-toggle"
        type="radio"
        name="group-selector"
        value={waitAndTradeType}
        onChange={(newType) => {
          onTransactionBuilderConfigChange({
            [WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
            [WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
            waitAndTradeType: newType
          });
        }}
      >
        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          value={WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE}
        >
          <div className="d-flex align-items-center">
            <span className="material-icons-outlined">expand_more</span>
            %
          </div>
        </ToggleButton>

        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          value={WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE}
        >
          <div className="d-flex align-items-center">
            <span className="material-icons-outlined">expand_less</span>
            %
          </div>
        </ToggleButton>
      </ToggleButtonGroup>

      <input
        type="text"
        className="form-control form-control-sm"
        name="waitAndTrade"
        onBlur={(event) => onHandleActiveCount(onRegexOnlyNumberAndFloat(event.target.value, false))}
        value={waitValue === undefined ? '' : waitValue.toString()}
        onChange={(event) => setWaitValue(onRegexOnlyNumberAndFloat(event.target.value, true))}
      />
    </div>
  );

  return <td>{renderNode}</td>;
};

WaitAndTrade.propTypes = propTypes;
WaitAndTrade.defaultProps = defaultProps;

export default memo(withErrorBoundary(WaitAndTrade));
