import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toPositiveNumberString } from 'common/stringUtils/toPositiveNumberString';
import HoverMessage from 'common/components/HoverMessage';
import ReactHtmlParser from 'react-html-parser';

const propTypes = {
  reEntryOrExecuteOptionConfigs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangeReEntryorExecute: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

const defaultProps = {
  isDisabled: false,
};

const DEFAULT_CONFIG = {
  reEntryCount: 0,
  reExecuteCount: 0,
};

const ReEntryOrExecute = ({ reEntryOrExecuteOptionConfigs, onChangeReEntryorExecute, isDisabled }) => {
  const filteredConfigs = useMemo(
    () => _.filter(reEntryOrExecuteOptionConfigs, ({ isShow }) => isShow), [reEntryOrExecuteOptionConfigs]
  );
  const selected = useMemo(
    () => _.find(filteredConfigs, ({ value }) => value) || _.head(filteredConfigs), [filteredConfigs]
  );
  const [selectedType, setSelectedType] = useState(selected.name);
  const [isShowHoverMessage, setIsShowHoverMessage] = useState(false);

  useEffect(() => {
    if (isShowHoverMessage) { setTimeout(() => { setIsShowHoverMessage(false); }, 5000); }
  }, [isShowHoverMessage]);

  const renderTypeSelector = (
    <select
      value={selectedType}
      disabled={isDisabled}
      onChange={(event) => {
        setSelectedType(event.target.value);
        onChangeReEntryorExecute({ ...DEFAULT_CONFIG, [event.target.value]: selected.value });
      }}
    >
      <option value="" />
      {_.map(filteredConfigs, ({ name: optionName, label }, idx) => {
        return <option value={optionName} key={idx}>{label}</option>;
      })}
    </select>
  );

  const renderValueSelector = (
    <select
      value={selected.value?.toString()}
      disabled={isDisabled}
      className="form-control"
      onChange={(event) => {
        if (isDisabled) {
          setIsShowHoverMessage(true);
          return;
        }
        onChangeReEntryorExecute({
          ...DEFAULT_CONFIG,
          [selectedType]: toPositiveNumberString((event.target.value).toString())
        });
      }}
    >
      <option value="0" />
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
    </select>
  );

  const renderHoverMessageWithContent = (renderContent) => {
    return (
      <HoverMessage
        title="ReEntry or ReExecute"
        message={ReactHtmlParser('<b>Stop Loss</b> is Required For ReEntry or ReExecute')}
        isOpen={isShowHoverMessage}
      >
        {renderContent}
      </HoverMessage>
    );
  };

  return (
    <div>
      {isDisabled ? renderHoverMessageWithContent(renderTypeSelector) : renderTypeSelector}
      {isDisabled ? renderHoverMessageWithContent(renderValueSelector) : renderValueSelector}
    </div>
  );
};

ReEntryOrExecute.propTypes = propTypes;
ReEntryOrExecute.defaultProps = defaultProps;

export default ReEntryOrExecute;
