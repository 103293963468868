import React from 'react';
import loginInModal from 'v2/modules/withRunForm/utils/loginInModal';

const propTypes = {};
const defaultProps = {};

const EntichMoneyLogin = () => {
  const oneIndentation = ' ';

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16 mb-4">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with Enrich Money API.
          </p>
          <button
            type="button"
            className="btn btn-outline-primary broker"
            onClick={() => loginInModal('/auth/enrich_money')}
          >
            <div className="d-flex align-items-center gap-10">
              <span>Login with Enrich Money</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

EntichMoneyLogin.propTypes = propTypes;
EntichMoneyLogin.defaultProps = defaultProps;

export default EntichMoneyLogin;
