const configuration = {
  type: 'currentCandle',
  defaults: {
    type: 'currentCandle',
    name: 'current',
    config: {
      candleInterval: '5 minutes',
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'currentCandle'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, type, config } = pipe;
    const { valuePaths = [] } = config;
    const [valuePath] = valuePaths;
    const isVolume = _.includes(valuePath, '.volume');
    let properties = [
      'open',
      'high',
      'low',
      'close'
    ];

    if (isVolume) {
      properties = _.drop(properties, 3); // remove OHL
    }

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
