const buildOutputSchemaFromJsonSchema = (jsonSchema, parentPath) => {
  const { type } = jsonSchema;
  if (type === 'array') {
    throw new Error('Array type is not supported yet');
  } else if (type === 'object') {
    return _.flatMap(jsonSchema.properties, (itemConfig, name) => {
      return buildOutputSchemaFromJsonSchema(itemConfig, `${parentPath}.${name}`);
    });
  } else {
    return {
      name: parentPath,
      type: jsonSchema.type,
      indicatorType: 'customScript',
      category: 'indicator',
      label: parentPath
    };
  }
};

const configuration = {
  type: 'customScript',
  defaults: {
    type: 'customScript',
    name: 'customScript',
    config: {
      script: '100L',
      outputSchema: '{"type": "number"}'
    }
  },
  searchTerms: [
    'customScript'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, config: { outputSchema } } = pipe;
    const schema = JSON.parse(outputSchema);
    return buildOutputSchemaFromJsonSchema(schema, name);
  },
  isEnabled: true
};
export default configuration;
