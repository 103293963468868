import React, {
  memo, useCallback, useMemo, useState
} from 'react';
import { signalsPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';
import { signalTypes } from 'v2/modules/withRunForm/configs/index';
import useOutputSchema from 'v2/modules/withRunForm/hooks/useOutputSchema';
import classNames from 'classnames';
import { OutputSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import SignalsForm from './SignalsForm/index';
import SignalsView from './SignalsView/index';

const propTypes = {
  signals: signalsPropTypes.isRequired,
  caseIndex: PropTypes.number.isRequired,
  signalType: PropTypes.string.isRequired,
  onUpdateSignals: PropTypes.func.isRequired,
  adjustmentIndex: PropTypes.number,
  isHiddenSignal: PropTypes.bool.isRequired,
};

const defaultProps = {
  adjustmentIndex: undefined,
};

const signalLabels = {
  [signalTypes.entrySignal]: 'Entry When',
  [signalTypes.exitSignal]: 'Exit When',
  [signalTypes.adjustSignal]: 'Adjust When',
};

const Signals = ({
  signals, caseIndex, signalType, onUpdateSignals, adjustmentIndex, isHiddenSignal
}) => {
  const outputSchema = useOutputSchema();
  const filteredOutputSchema = useMemo(() => {
    return _.chain(outputSchema)
      .thru((schema) => OutputSchemaUtility.filterByCaseIndex(schema, caseIndex))
      .thru((schema) => OutputSchemaUtility.filterSchemaPriorToAdjustmentIndex(schema, adjustmentIndex))
      .value();
  }, [outputSchema, caseIndex, adjustmentIndex]);

  const [shouldRenderSignalForm, setShouldRenderSignalForm] = useState(false);
  const onSaveForm = useCallback((newSignals) => {
    onUpdateSignals(newSignals);
    setShouldRenderSignalForm(false);
  }, [onUpdateSignals]);

  const onCancel = useCallback(() => {
    setShouldRenderSignalForm(false);
  }, []);

  const onDeleteEntirely = useCallback(() => {
    onUpdateSignals([]);
    setShouldRenderSignalForm(false);
  }, [onUpdateSignals]);

  const renderSignalsForm = useMemo(() => {
    if (!shouldRenderSignalForm) return null;

    return (
      <SignalsForm
        outputSchema={filteredOutputSchema}
        signals={signals}
        caseIndex={caseIndex}
        signalType={signalType}
        onSaveForm={onSaveForm}
        onCancel={onCancel}
        onDeleteEntirely={onDeleteEntirely}
      />
    );
  }, [
    shouldRenderSignalForm,
    filteredOutputSchema, signals,
    caseIndex, signalType,
    onSaveForm, onCancel,
    onDeleteEntirely
  ]);

  const divClassName = classNames({
    'entry-signal': signalType === signalTypes.entrySignal,
    'exit-signal': signalType === signalTypes.exitSignal,
    'adjust-signal': signalType === signalTypes.adjustSignal,
  });

  return (
    <div className={divClassName}>
      <SignalsView
        signals={signals}
        outputSchema={outputSchema}
        hiddenSignal={isHiddenSignal}
        label={signalLabels[signalType]}
        onEdit={() => setShouldRenderSignalForm(true)}
      />
      {renderSignalsForm}
    </div>
  );
};

Signals.propTypes = propTypes;
Signals.defaultProps = defaultProps;

export default memo(Signals);
