import {
  INTENT_TYPES,
  SEGMENT_CONFIG,
  STRIKE_SELECTION_TYPES,
} from 'v2/common/constants/index';
import {
  defaultStopGain,
  defaultStopLoss,
  getDefaultTarget,
} from 'v2/modules/withRunForm/configs/index';
import { StrikeUtility } from 'v2/common/components/StrikeBasedSelector/utility';
import { getFormattedOperand } from './signal';
import { getSanitizedStopLoss } from '../stopLoss';

const shouldShowIndicatorBasedStrikeIndex = _.get(
  window,
  'SA_FEATURE_FLAGS.shouldShowIndicatorBasedStrikeIndex',
  false
)
  || window?.isAdmin
  || false;

const shouldShowDeltaBased = _.get(window, 'SA_FEATURE_FLAGS.shouldShowDeltaBased', false)
  || window?.isAdmin
  || false;

const shouldShowIntentMultiplier = _.get(window, 'SA_FEATURE_FLAGS.shouldShowIntentMultiplier', false)
  || window?.isAdmin
  || false;

export const getSanitizedIntents = (intents, allIntents = []) => {
  return _.chain(intents)
    .filter((intent) => !_.get(intent, 'uiConfigs.isBasketIntent', false))
    .map((intent) => {
      const newIntent = _.cloneDeep(intent);
      const identifier = _.pick(newIntent?.identifier, ['label', 'iteration']);
      const targetConfig = _.get(newIntent, 'target', getDefaultTarget());
      const target = _.pick(targetConfig, ['label', 'iteration']);
      if (intent?.type !== INTENT_TYPES.OpenLeg) {
        return { ...newIntent, target, identifier };
      }

      const strike = _.get(newIntent, 'instrument.strike', {
        type: STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.value,
        strikeIndex: 0,
        multiplier: 0,
      });

      newIntent.instrument.strike = StrikeUtility.getStrikeAlteredObj(strike);
      const isStopLossPesent = newIntent?.stopLoss?.value;
      const isStopGainPresent = newIntent?.stopGain?.value;

      const isOption = newIntent?.instrument?.type === SEGMENT_CONFIG.option;

      const { IndicatorValueBasedStrikeIndex } = STRIKE_SELECTION_TYPES;
      const isDeltaBased = newIntent?.instrument?.strike?.type
        === STRIKE_SELECTION_TYPES.DeltaBased.value;

      if (isOption && isDeltaBased && !shouldShowDeltaBased) {
        newIntent.instrument.strike = {
          type: STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.value,
          strikeIndex: 0,
          multiplier: 0,
        };
      }

      const isIndicatorBased = newIntent?.instrument?.strike?.type
        === IndicatorValueBasedStrikeIndex.value;
      if (
        isOption
        && isIndicatorBased
        && !shouldShowIndicatorBasedStrikeIndex
      ) {
        newIntent.instrument.strike = {
          type: STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.value,
          strikeIndex: 0,
          multiplier: 0,
        };
      }
      if (
        !shouldShowIntentMultiplier
        && newIntent?.instrument?.strike?.multiplier
      ) {
        newIntent.instrument.strike.multiplier = 0;
      }
      const strikeType = newIntent?.instrument?.strike?.type;

      if (isOption && strikeType === IndicatorValueBasedStrikeIndex.value) {
        const valuePath = newIntent?.instrument?.strike?.valuePath;
        if (valuePath?.includes('individualStop.')) {
          newIntent.instrument.strike.valuePath = getFormattedOperand(
            valuePath,
            allIntents
          );
        }
      }

      return {
        ...newIntent,
        target,
        identifier,
        stopLoss: isStopLossPesent
          ? getSanitizedStopLoss(intent?.stopLoss)
          : defaultStopLoss,
        stopGain: isStopGainPresent ? intent?.stopGain : defaultStopGain,
      };
    })
    .value();
};
