const configuration = {
  type: 'anchoredVolumeWeightedAveragePrice',
  defaults: {
    type: 'anchoredVolumeWeightedAveragePrice',
    name: 'avwap',
    config: {
      candleInterval: '1 minutes',
      valuePaths: [
        'item0.month1Price'
      ],
      multiplier: 1
    }
  },
  searchTerms: [
    'AVWAP',
    'anchoredVolumeWeightedAveragePrice'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: ({ type, name }) => {
    const properties = [
      'vwap',
      'upperBand',
      'lowerBand'
    ];

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: name === property ? name : `${name} ${property}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
