import React, { useState } from 'react';

import { BROKERS } from 'common/constants';
import PropTypes from 'prop-types';
import loginInModal from 'v2/modules/withRunForm/utils/loginInModal';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {
  allowGoodwillDealer: PropTypes.bool
};

const defaultProps = {
  allowGoodwillDealer: false
};

const GoodWillLogin = ({ allowGoodwillDealer }) => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  const renderBrokerImage = () => {
    return (
      <img
        src={BROKERS.goodwill.image}
        alt={BROKERS.goodwill.name}
        title={BROKERS.goodwill.name}
        className="goodwill-logo"
      />
    );
  };

  const renderGoodwillDefaultLoginBtn = () => {
    return (
      <>
        <button
          type="button"
          className="btn btn-outline-primary broker"
          onClick={() => loginInModal('/auth/goodwill')}
        >
          {renderBrokerImage()}
          <span>LOGIN WITH GOODWILL</span>
        </button>
      </>
    );
  };

  const renderGoodwillDealerLoginBtn = () => {
    return (
      <>
        <div
          onClick={() => { setCurrentBroker(BROKERS.goodwill_dealer.id); }}
          className="btn btn-outline-primary broker mt-2"
        >
          {renderBrokerImage()}
          <span>LOGIN WITH GOODWILL DEALER</span>
        </div>
      </>
    );
  };

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with GoodWill.
          </p>

          {allowGoodwillDealer ? renderGoodwillDealerLoginBtn() : renderGoodwillDefaultLoginBtn()}

          <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
        </div>
      </div>
    </div>
  );
};

GoodWillLogin.propTypes = propTypes;
GoodWillLogin.defaultProps = defaultProps;

export default GoodWillLogin;
