import React from 'react';
import PropTypes from 'prop-types';

import UsLogin from './LocaleWiseLogin/UsLogin';
import IndiaLogin from './LocaleWiseLogin/IndiaLogin';

const propTypes = {
  mode: PropTypes.string,
  // TODO: is this the best way?? should we set it ApplicationDomain as global var for all
  // components to consume without sending as props.
  supportedBrokers: PropTypes.arrayOf(PropTypes.string),
  allowAlternateLoginMode: PropTypes.bool,
  allowGoodwillDealer: PropTypes.bool
};
const defaultProps = {
  mode: 'signIn',
  supportedBrokers: null,
  allowAlternateLoginMode: true,
  allowGoodwillDealer: false
};

const UserAuth = (props) => {
  const { SA_LOCALE: locale } = window;

  if (locale.isUsVersion) {
    return <UsLogin />;
  }

  return <IndiaLogin {...props} />;
};

UserAuth.propTypes = propTypes;
UserAuth.defaultProps = defaultProps;

export default UserAuth;
