import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import ValidationError from 'common/components/ValidationError';
import { QUANT_CONFIG_FIELDS } from 'modules/QuantBuilder/config';
import { connect } from 'react-redux';
import { getValidationError } from 'common/utils/validators';
import {
  orderConfigsPropTypes, outputSchemaPropTypes, pipeConfigsPropTypes, quantConfigPropTypes, validatorProptypes
} from 'common/propTypes';
import { setOrderConfigs, setPipeConfigs, setQuantConfig } from 'modules/QuantBuilder/actions';
import PipesList from './PipesList';
import PipeForm from './PipeForm';
import PipeSuggestionAdapter from './PipeSuggestionAdapter/index';
import { indicatorNameChange } from './helper';
import { updateSignalNameBasedOnPipeName } from './PipeForm/helper';

const I18N_SCOPE = { scope: 'advanced_quant_builder.index' };

const propTypes = {
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  dispatchSetPipeConfigs: PropTypes.func.isRequired,
  validatorErrors: validatorProptypes.isRequired,
  pipesValidators: PropTypes.shape({}).isRequired,
  orderConfigs: orderConfigsPropTypes.isRequired,
  outputSchema: outputSchemaPropTypes,
  dispatchSetOrderConfigs: PropTypes.func.isRequired,
  segment: PropTypes.string,
  quantConfig: quantConfigPropTypes.isRequired,
  dispatchSetQuantConfig: PropTypes.func.isRequired
};

const defaultProps = {
  outputSchema: {},
  segment: ''
};

class Pipes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pipeConfig: null,
      formTitle: '',
      pipeConfigIndex: null,
      shouldRenderPipeForm: false,
      isInEditMode: false,
      lastAddedPipeConfig: null
    };
  }

  onRemovePipe = (pipeConfigIndex) => () => {
    const { pipeConfigs, dispatchSetPipeConfigs } = this.props;
    const newPipeConfigs = _.filter(pipeConfigs, (pipeConfig, pipeIndex) => pipeIndex !== pipeConfigIndex);
    dispatchSetPipeConfigs(newPipeConfigs);
  }

  onSavePipeConfig = () => {
    const { pipeConfig, pipeConfigIndex } = this.state;
    const {
      dispatchSetPipeConfigs, pipeConfigs, quantConfig, dispatchSetQuantConfig
    } = this.props;
    const newPipeConfigs = pipeConfigs;
    let oldPipeConfig = null;

    if (pipeConfigIndex === null) newPipeConfigs.push(pipeConfig);
    else {
      oldPipeConfig = newPipeConfigs[pipeConfigIndex];
      newPipeConfigs[pipeConfigIndex] = pipeConfig;
    }

    this.setState({ shouldRenderPipeForm: false, isInEditMode: false },
      () => {
        dispatchSetPipeConfigs(newPipeConfigs);
        this.setState({ lastAddedPipeConfig: pipeConfig }, () => {
          if (!_.isEmpty(oldPipeConfig)) {
            dispatchSetQuantConfig(updateSignalNameBasedOnPipeName(oldPipeConfig, quantConfig, pipeConfig));
          }
        });
      });
  }

  setPipeConfig = ({
    pipeConfig, formTitle, pipeConfigIndex, isInEditMode
  }) => {
    this.setState({
      pipeConfig,
      formTitle,
      pipeConfigIndex,
      isInEditMode,
      shouldRenderPipeForm: true,
    });
  }

  getPreOccupiedNames = () => {
    const { isInEditMode, pipeConfigIndex } = this.state;
    const { pipeConfigs } = this.props;

    if (!isInEditMode) return _.map(pipeConfigs, 'name');
    return _.compact(_.map(pipeConfigs, (config, idx) => ((idx !== pipeConfigIndex) ? config.name : null)));
  }

  renderAddPipeBtn = () => {
    const { pipeConfigs, segment } = this.props;

    const args = {
      pipeConfig: indicatorNameChange(pipeConfigs, 'simpleMovingAverage', segment),
      formTitle: I18n.t('add_indicator', I18N_SCOPE),
      pipeConfigIndex: null,
      isInEditMode: false,
    };

    const isDisabled = (pipeConfigs || []).length > 9;
    return (
      <>
        <button
          type="button"
          disabled={isDisabled}
          className="
          btn
          btn-sm
          btn-primary
          common-icon-btn
          track"
          id="add_indicator"
          data-track-category="Indicator"
          data-track-action="Add Indicator"
          data-track-label="Add Indicator"
          onClick={() => this.setPipeConfig(args)}
        >
          <i className="material-icons-outlined">library_add</i>
          {I18n.t('add_indicator', I18N_SCOPE)}
        </button>
        {isDisabled
          && <p className="text-danger text-center my-2 ml-2">Only possible to add 10 indicators</p>}
      </>
    );
  };

  render = () => {
    const {
      pipeConfigs, validatorErrors, pipesValidators, outputSchema,
      dispatchSetPipeConfigs, orderConfigs, dispatchSetOrderConfigs, segment, quantConfig
    } = this.props;

    const pipeErrors = getValidationError(validatorErrors, QUANT_CONFIG_FIELDS.pipeConfigs);
    const {
      pipeConfig, shouldRenderPipeForm, formTitle, lastAddedPipeConfig
    } = this.state;
    const currentIndicatorType = _.get(pipeConfig, 'type', 'simpleMovingAverage');

    return (
      <div className="">
        <ValidationError
          validatorErrors={pipeErrors}
        />
        {shouldRenderPipeForm && (
          <PipeForm
            pipeConfig={pipeConfig}
            title={formTitle}
            quantConfig={quantConfig}
            preOccupiedPipeNames={this.getPreOccupiedNames()}
            validators={_.get(pipesValidators, [currentIndicatorType])}
            onClose={() => this.setState({ shouldRenderPipeForm: false })}
            onUpdate={(newPipeConfig) => this.setState({ pipeConfig: newPipeConfig })}
            onSubmit={this.onSavePipeConfig}
          />
        )}

        <div className={classNames(_.isEmpty(pipeErrors) ? '' : 'border border-danger')}>
          <div className="pipe-form">
            <PipesList
              pipeConfigs={pipeConfigs}
              quantConfig={quantConfig}
              onEditPipe={this.setPipeConfig}
              onRemovePipe={this.onRemovePipe}
              outputSchema={outputSchema}
            />
          </div>

          <PipeSuggestionAdapter
            pipeConfigs={pipeConfigs}
            segment={segment}
            orderConfigs={orderConfigs}
            onUpdatePipeConfigs={dispatchSetPipeConfigs}
            lastAddedPipeConfig={lastAddedPipeConfig}
            onEmptyLastAddedPipeConfig={() => this.setState({ lastAddedPipeConfig: null })}
            onUpdateOrderConfig={dispatchSetOrderConfigs}
            outputSchema={outputSchema}
          />

          <div className="row d-flex justify-content-center align-items-center mt-2 mb-5">
            {this.renderAddPipeBtn()}
          </div>
        </div>
      </div>
    );
  }
}

Pipes.propTypes = propTypes;
Pipes.defaultProps = defaultProps;

function mapStateToProps(state) {
  const quantConfig = _.get(state, 'quantConfig');

  return {
    pipeConfigs: _.get(quantConfig, 'pipeConfigs'),
    validatorErrors: _.get(state, 'validatorErrors'),
    pipesValidators: _.get(state, 'quantConfigValidators.pipesValidators', {}),
    outputSchema: _.get(state, 'outputSchema'),
    orderConfigs: _.get(quantConfig, 'orderConfigs'),
    segment: _.get(state, 'segment'),
    quantConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetPipeConfigs: (newPipeConfigs) => dispatch(setPipeConfigs(newPipeConfigs)),
    dispatchSetOrderConfigs: (newOrderConfigs) => dispatch(setOrderConfigs(newOrderConfigs)),
    dispatchSetQuantConfig: (newQuantConfig) => dispatch(setQuantConfig(newQuantConfig))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Pipes);
