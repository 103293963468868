import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { TABLE_HEADER, SORT_BY } from 'ui/FolderList/config';
import RunListViewBody from './RunListViewBody';

const propTypes = {
  runs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDeleteRun: PropTypes.func.isRequired,
  onUpdateRun: PropTypes.func.isRequired,
  onSelectRuns: PropTypes.func.isRequired,
  isFilteredRuns: PropTypes.bool.isRequired
};

const defaultProps = {};
const I18N_SCOPE = { scope: 'folders.show' };

const RunListView = (props) => {
  const {
    onDeleteRun, onUpdateRun, runs, onSelectRuns, isFilteredRuns
  } = props;
  const [sortBy, setSortBy] = useState(SORT_BY.createdAt);
  const [sortOrder, setSortOrder] = useState('desc');

  const sortedRun = _.orderBy(runs, sortBy, sortOrder);

  const onSortOptionChange = (newSortBy) => {
    if (sortBy === newSortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      return;
    }

    setSortBy(newSortBy);
    setSortOrder('asc');
  };

  const renderTableHeader = () => {
    const alteredHeaders = isFilteredRuns
      ? _.filter(TABLE_HEADER, (value) => !_.isEmpty(value.title))
      : TABLE_HEADER;

    const headers = _.map(alteredHeaders, (header, idx) => {
      const style = classNames(
        { sorting: !!header.sortByKey },
        sortBy === header.sortByKey ? sortOrder : '',
        'list-view-cell'
      );

      return (
        <div
          key={idx}
          className={style}
          onClick={() => onSortOptionChange(header.sortByKey)}
        >
          {header.title}
        </div>
      );
    });

    return (
      <div className="list-view-table sorting-head">
        {headers}
        <div className="text-right">{I18n.t('actions', I18N_SCOPE)}</div>
      </div>
    );
  };

  return (
    <div className="table-responsive responsive-folders-table">
      {!_.isEmpty(runs) && renderTableHeader()}
      {_.map(sortedRun, (run, idx) => (
        <RunListViewBody
          key={idx}
          run={run}
          onDeleteRun={onDeleteRun}
          onUpdateRun={onUpdateRun}
          onSelectRuns={onSelectRuns}
          folderName={_.get(run, 'folder_name', '')}
          isFilteredRuns={props.isFilteredRuns}
        />
      ))}
    </div>
  );
};

RunListView.propTypes = propTypes;
RunListView.defaultProps = defaultProps;

export default RunListView;
