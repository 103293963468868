import React, { useState, useEffect, useContext } from 'react';
import { runPropTypes } from 'common/propTypes';
import {
  getDisplayTransactions
} from 'common/utils/transactionProfitHelper';
import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { RUN_TYPES } from 'common/constants/index';
import LiveTransactionDetails from 'ui/run/RunShow/components/LiveDetails/LiveTransactionDetails/index';
import PropTypes from 'prop-types';
import TransactionsList from './components/TransactionsList/index';
import Header from './components/Header/index';
import TransactionsFooter from './components/TransactionsFooter';

const propTypes = { run: runPropTypes.isRequired, isAccordianOpen: PropTypes.bool };

const defaultProps = { isAccordianOpen: true };
const INITIAL_PAGE_NO = 0;
const TRANSACTIONS_PER_PAGE = 10;

const TransactionTable = (props) => {
  const {
    run: {
      id, run_type: runType, display_name: displayName, order_type: orderType, granularity, status
    }, isAccordianOpen
  } = props;
  const { transactions, isLoading } = useContext(OrderContext);
  const { live } = RUN_TYPES;
  const isLive = runType === live;

  if (isLoading && !transactions.length) return null;

  const reverseTransactions = _.flatten(transactions.concat([])).reverse();
  // if reverse() the transactions all reference is also changed

  const [currentPageIndex, setCurrentPageIndex] = useState(INITIAL_PAGE_NO);
  const [transactionsPerPage, setTransactionsPerPage] = useState(TRANSACTIONS_PER_PAGE);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const transactionsToDisplay = getDisplayTransactions(
    filteredTransactions, currentPageIndex, transactionsPerPage
  );

  const onFilterChange = (newTransactions) => {
    setFilteredTransactions(newTransactions);
    setCurrentPageIndex(INITIAL_PAGE_NO);
  };

  const onTransactionsPerPageChange = (newCount) => {
    setTransactionsPerPage(newCount);
    setCurrentPageIndex(INITIAL_PAGE_NO);
  };

  useEffect(() => {
    setFilteredTransactions(reverseTransactions);
  }, [transactions]);

  if (_.isEmpty(transactions) && !isLoading) {
    window.prerenderReady = true; // for share // this for empty Transactions
  }

  if (_.isEmpty(transactions)) return null;

  const renderTransactionList = () => {
    const isEmptyTransactionsToDisplay = _.isEmpty(transactionsToDisplay);

    if (isEmptyTransactionsToDisplay) { // empty transaction
      return (
        <div className="text-center mt-5">
          <img className="no-data-found" src="/no-data.svg" alt="No Data Found" />
        </div>
      );
    }

    if (window.innerWidth <= 700 && isLive) {
      return (
        <div className="live-transactions card-body px-0 pt-0">
          <LiveTransactionDetails
            transactions={transactionsToDisplay}
            runId={id}
            orderType={orderType}
            isAccordianOpen={isAccordianOpen}
            runStatus={status}
          />
        </div>
      );
    }

    return (
      <>
        <Header
          onFilterChange={onFilterChange}
          runName={displayName}
          runId={id}
          onTransactionsPerPageChange={onTransactionsPerPageChange}
        />
        <TransactionsList
          transactions={transactionsToDisplay}
          runId={id}
          isLive={isLive}
          orderType={orderType}
          granularity={granularity}
          runStatus={status}
        />
      </>
    );
  };

  return (
    <>
      <div className="card-header justify-content-start justify-content-sm-between flex-wrap gap-5">
        <div className="card-title">Transaction order details</div>
      </div>

      <div className="transaction-table card-body">
        {renderTransactionList()}
        <TransactionsFooter
          transactions={filteredTransactions}
          currentPageIndex={currentPageIndex}
          onCurrentPageChange={setCurrentPageIndex}
          transactionsPerPage={transactionsPerPage}
        />
      </div>
    </>
  );
};

TransactionTable.propTypes = propTypes;
TransactionTable.defaultProps = defaultProps;

export default withErrorBoundary(TransactionTable);
