import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducers from './redux/reducers';

const withRunForm = (UIComponent) => {
  return (props) => {
    const filteredProps = _.reduce(props, (result, value, key) => {
      const newResult = result;
      if (!_.isFunction(value)) newResult[key] = value;
      return newResult;
    }, {});

    const store = configureStore({
      reducer: reducers,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
      preloadedState: { ...filteredProps },
    });

    return (
      <Provider store={store}>
        <UIComponent {...props} />
      </Provider>
    );
  };
};

export default withRunForm;
