import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { MAX_HISTORIC_COMBINED_RESULTS } from 'common/constants/index';
import MoveFolderModal from './RunListView/MoveFolderModal';
import DeleteMultipleRunsModal from './DeleteMultipleRunsModal';

const propTypes = {
  runs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  folder: PropTypes.shape({}).isRequired,
};
const defaultProps = {};
const MultipleSelectionActionBtns = ({ runs, folder }) => {
  const [isMoveFolder, setIsMoveFolder] = useState(false);
  const [isDeleteRuns, setIsDeleteRuns] = useState(false);

  const renderMoveFolderModal = () => {
    if (!isMoveFolder) { return null; }
    const runIds = _.map(runs, 'id');

    return (
      <MoveFolderModal
        runIds={runIds}
        folderName={_.get(folder, 'name')}
        onClose={() => { setIsMoveFolder(false); }}
      />
    );
  };

  const renderDeleteModal = () => {
    if (!isDeleteRuns) { return null; }

    return (
      <DeleteMultipleRunsModal
        runs={runs}
        onClose={() => { setIsDeleteRuns(false); }}
      />
    );
  };

  const onCombinedResults = () => {
    const historicRunIds = _.chain(runs)
      .filter({ run_type: 'historic' })
      .map('id')
      .value();

    const runIdsCount = _.size(historicRunIds);

    if (runIdsCount > MAX_HISTORIC_COMBINED_RESULTS) {
      let message = `Please choose less than ${MAX_HISTORIC_COMBINED_RESULTS} `;
      message += 'backtests to get the combined results';
      reactToastify(message, TOASTIFY_TYPES.ERROR);
      return null;
    }

    if (runIdsCount === 0) {
      reactToastify('Please choose a minimum of one backtest.', TOASTIFY_TYPES.ERROR);
      return null;
    }

    const reDirectUrl = `/numerous_runs?runids=[${historicRunIds.join(',')}]`;
    window.location.href = reDirectUrl;
    return null;
  };

  return (
    <div className="dropdown">
      {renderMoveFolderModal()}
      {renderDeleteModal()}
      <button
        className="btn btn-outline-primary btn-pill dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Actions
      </button>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <button
          type="button"
          className="dropdown-item"
          onClick={() => { setIsDeleteRuns(true); }}
        >
          Delete selected
        </button>
        <button
          type="button"
          className="dropdown-item"
          onClick={() => { setIsMoveFolder(true); }}
        >
          Move selected
        </button>
        <button
          type="button"
          className="dropdown-item"
          onClick={onCombinedResults}
        >
          Combined Results
        </button>
      </div>
    </div>
  );
};
MultipleSelectionActionBtns.propTypes = propTypes;
MultipleSelectionActionBtns.defaultProps = defaultProps;
export default MultipleSelectionActionBtns;
