import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { RUN_GRANULARITY, RUN_TYPES } from 'common/constants/index';

const propTypes = {
  runType: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  onChangeGranularity: PropTypes.func.isRequired
};
const defaultProps = {};

const Granularity = (props) => {
  const showGranularity = _.get(window, 'userPermissions.runGranularityAccess.isEnabled', false);
  const { runType, granularity, onChangeGranularity } = props;

  if (runType !== RUN_TYPES.historic || !showGranularity) {
    return null;
  }

  return (
    <>
      <div />
      <div className="run-granularity-container">
        <label htmlFor="colFormLabelSm" className="col-form-label col-form-label-sm">
          Historic Data Type
        </label>
        <div>
          <ToggleButtonGroup
            className="btn-block"
            type="radio"
            size="sm"
            name="options"
            defaultValue={granularity}
            onChange={onChangeGranularity}
          >
            <ToggleButton
              variant="outline-primary"
              className="btn-lightness track"
              value={RUN_GRANULARITY.minute}
              size="sm"
            >
              Minute Candle
            </ToggleButton>
            <ToggleButton
              size="sm"
              variant="outline-primary"
              className="btn-lightness track"
              value={RUN_GRANULARITY.second}
            >
              Second-wise
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </>
  );
};

export default Granularity;

Granularity.propTypes = propTypes;
Granularity.defaultProps = defaultProps;
