import React from 'react';
import PropTypes from 'prop-types';

import { getEmptySignalConfig } from 'modules/QuantBuilder/config';
import { signalConfigPropTypes } from 'common/propTypes';

const propTypes = {
  signalConfigs: PropTypes.arrayOf(signalConfigPropTypes).isRequired,
  onAddSignalConfig: PropTypes.func.isRequired
};
const defaultProps = {};

const AddSignalConfig = ({ signalConfigs, onAddSignalConfig }) => (
  <button
    className="
      btn btn-sm btn-outline-primary
      align-self-baseline"
    type="button"
    onClick={() => onAddSignalConfig(signalConfigs.concat(getEmptySignalConfig()))}
  >
    Add Condition
  </button>
);

AddSignalConfig.propTypes = propTypes;
AddSignalConfig.defaultProps = defaultProps;

export default AddSignalConfig;
