import React from 'react';
import PropTypes from 'prop-types';
import { optionConfigPropTypes, timeBasedSignalPropTypes } from 'common/propTypes';
import { changeOptionConfigsBasedOn } from '../helper';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optionConfigs: PropTypes.arrayOf(optionConfigPropTypes).isRequired,
  onOptionConfigsChange: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  timeBasedSignal: timeBasedSignalPropTypes.isRequired,
  onCurrentStrategyChange: PropTypes.func.isRequired,
  optionStrikeCalculationBasedOn: PropTypes.string.isRequired
};
const defaultProps = {};

const StrategiesList = ({
  id, name, icon, optionConfigs, onOptionConfigsChange, timeBasedSignal, onCurrentStrategyChange,
  optionStrikeCalculationBasedOn
}) => {
  return (
    <li className="nav-item" key={id}>
      <a
        className={`nav-link track ${name === 'buyCall' ? 'active' : ''}`}
        id={`${id} - view`}
        data-toggle="tab"
        href={`#${name}`}
        role="tab"
        label="selection box"
        name="Strategy Option Builder"
        data-track-category="Strategy Option Builder"
        data-track-action={id}
        data-track-label="View"
        aria-controls={name}
        aria-selected="true"
        onClick={() => {
          onOptionConfigsChange(
            changeOptionConfigsBasedOn(optionConfigs, optionStrikeCalculationBasedOn), timeBasedSignal
          );
          onCurrentStrategyChange(name);
        }}
      >
        <img src={icon} alt={name} />
        {_.startCase(name)}
      </a>
    </li>
  );
};

StrategiesList.propTypes = propTypes;
StrategiesList.defaultProps = defaultProps;

export default StrategiesList;
