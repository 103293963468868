import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import ReactHtmlParser from 'react-html-parser';
import { BROKERS, XTS_API_VENDOR } from 'common/constants/index';
import { getBrokerImage, reDirectPage } from '../config';

const propTypes = {
  onCloseModal: PropTypes.func.isRequired
};

const defaultProps = {};

const Xts = ({ onCloseModal }) => {
  const [isValid, setIsValid] = useState(true);
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [broker, setBroker] = useState('');
  const xtsSupportedBrokers = _.chain(BROKERS)
    .filter((brokerConfig) => brokerConfig.is_xts_api)
    .map((brokerConfig) => { return { id: brokerConfig.id, name: brokerConfig.broker_name }; })
    .value();

  const onNavigate = () => {
    if (!apiKey || !apiSecret || !broker) return setIsValid(false);
    const reDirectData = { app_key: apiKey, secret_key: apiSecret, broker };
    reDirectPage(reDirectData, 'xts', true);

    return '';
  };

  const renderOptions = (optionConfig, idx) => {
    return <option value={optionConfig.id} key={idx}>{optionConfig.name}</option>;
  };

  const modalProps = {
    onClose: onCloseModal,
    shouldShow: true,
    size: 'sm',
    onSaveid: 'btn-xts12121',
    onSave: () => onNavigate(),
    okBtnTitle: 'Login',
    isOkBtnDisabled: false
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="mb-3">
        {ReactHtmlParser(getBrokerImage(XTS_API_VENDOR))}
        <label className="mt-2">App Key</label>
        <input
          className="form-control"
          type="text"
          id="xts-app-key"
          value={apiKey}
          onChange={(event) => { setApiKey(event.target.value); }}
        />
        <label className="mt-2">Secret Key</label>
        <input
          className="form-control"
          type="text"
          id="xts-secret-key"
          value={apiSecret}
          onChange={(event) => { setApiSecret(event.target.value); }}
        />
        <label className="mt-2">Select Broker</label>
        <select
          className="custom-select custom-select-sm"
          id="xts-broker"
          onChange={(event) => { setBroker(event.target.value); }}
        >
          <option value="" />
          {_.map(xtsSupportedBrokers, renderOptions)}
        </select>
        {!isValid && <div className="invalid-feedback d-block">Please provide the details</div>}
      </div>
    </ModalComponent>
  );
};

Xts.propTypes = propTypes;
Xts.defaultProps = defaultProps;

export default Xts;
