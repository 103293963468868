import React from 'react';
import { planPropTypes } from 'common/propTypes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { applyCredits } from 'ui/Plans/helper';
import PaymentMoneyDisplay from '../common/PaymentMoneyDisplay';
import PlanButton from '../common/PlanButton';
import PlanContent from '../common/PlanContent';

const propTypes = {
  plan: planPropTypes.isRequired,
  activePlanId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  couponConfig: PropTypes.shape({ code: PropTypes.string }),
  activePlanName: PropTypes.string.isRequired,
  isSubscriptionActive: PropTypes.bool.isRequired,
  credits: PropTypes.number,
  couponCode: PropTypes.string.isRequired,
  onChangeCouponCode: PropTypes.func.isRequired,
  isApplied: PropTypes.bool.isRequired,
  onCouponUpdate: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  planCycleType: PropTypes.string.isRequired,
  isCreditApplied: PropTypes.bool.isRequired
};

const defaultProps = {
  couponConfig: {},
  activePlanId: null,
  credits: 0
};

const PlanCard = ({
  plan, couponConfig, activePlanId, activePlanName,
  isSubscriptionActive, credits, isCreditApplied,
  couponCode, onChangeCouponCode, isApplied, onCouponUpdate, message, planCycleType
}) => {
  const {
    plan_name: planName, price: actualPrice, currentNetPrice, strike_price: strikePrice,
    historic_run_limit: historicRunLimit, live_run_limit: liveRunLimit, is_premium: isPremium,
    cycle_duration_in_months: duration, id: planId
  } = plan;
  const accordionId = `plan${planId}`;

  const planCardClassName = classNames('basic-box', {
    'premium-box': isPremium,
    'basic-box': !isPremium
  });

  const renderPlanButton = (
    <PlanButton
      plan={plan}
      couponConfig={couponConfig}
      activePlanId={activePlanId}
      activePlanName={activePlanName}
      isSubscriptionActive={isSubscriptionActive}
      isCreditApplied={isCreditApplied}
      couponCode={couponCode}
      onChangeCouponCode={onChangeCouponCode}
      isApplied={isApplied}
      onCouponUpdate={onCouponUpdate}
      message={message}
    />
  );

  return (
    <div className={planCardClassName}>
      <div className="amount-box">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p>{_.startCase(planName)}</p>
            <h5>
              <PaymentMoneyDisplay
                price={actualPrice}
                currentNetPrice={applyCredits(currentNetPrice, credits)}
                strikePrice={strikePrice}
                cycleDurationInMonths={duration}
              />
            </h5>
            {isPremium && <img src="/premium-icon.png" className="premium-icon" alt="Premium-icon" />}
            <div className="d-flex align-items-center">
              <p>
                (Billed
                {' '}
                {_.startCase(planCycleType)}
                )
              </p>
              <p>Excluding GST</p>
            </div>
          </div>
          <div className="order-now-btn">
            {renderPlanButton}
          </div>
        </div>
      </div>

      <div className="plan-desk">
        <div className="content-border border-bottom d-lg-block d-none">
          <p className="">What&apos;s included</p>
        </div>

        <PlanContent
          isPremium={isPremium}
          historicRunLimit={historicRunLimit}
          liveRunLimit={liveRunLimit}
        />
      </div>

      <div className="accordion plan-mobile" id={accordionId}>
        <div className="card">
          <div className="card-header" id={`${accordionId}-head`}>
            <a
              href="#one"
              className={`btn btn-header-link ${isPremium ? 'premium-link' : 'normal-link'}`}
              data-toggle="collapse"
              data-target={`#${accordionId}-plan`}
              aria-expanded="true"
              aria-controls={`${accordionId}-plan`}
            >
              What&apos;s included
            </a>
          </div>

          <div
            id={`${accordionId}-plan`}
            className="collapse"
            aria-labelledby={`${accordionId}-head`}
            data-parent={`#${accordionId}`}
          >
            <div className="card-body">
              <PlanContent
                isPremium={isPremium}
                historicRunLimit={historicRunLimit}
                liveRunLimit={liveRunLimit}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mx-auto">
        {renderPlanButton}
      </div>
    </div>
  );
};

PlanCard.propTypes = propTypes;
PlanCard.defaultProps = defaultProps;

export default PlanCard;
