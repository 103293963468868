import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkIsValidBrokerAccessToken, signOut } from 'common/api/user';
import Modal from 'common/components/ModalComponent';
import { userPropTypes } from 'common/propTypes';

const propTypes = {
  accessTokenCallBack: PropTypes.func,
  currentUser: userPropTypes,
  isValidAccessToken: PropTypes.bool
};

const defaultProps = {
  accessTokenCallBack: () => { },
  currentUser: null,
  isValidAccessToken: true
};

const VALIDITY_CALL_RANGE = 60;

const AccessTokenInValidMessage = ({
  accessTokenCallBack, currentUser, isValidAccessToken: propsIsValidToken
}) => {
  const { isUserSignedIn, currentUserBroker } = window;
  if (!isUserSignedIn || !currentUserBroker || !currentUser) return null;
  const oneIndentation = ' ';

  const [isValidAccessToken, setIsValidAccessToken] = useState(propsIsValidToken);

  useEffect(() => {
    setIsValidAccessToken(propsIsValidToken);
  }, [propsIsValidToken]);

  const brokerAccessTokenCall = () => {
    checkIsValidBrokerAccessToken().then(({ status }) => {
      setIsValidAccessToken(status);
    });
  };

  useEffect(() => {
    const { broker_access_token_checked_at: brokerAccessTokenCheckedAt } = currentUser;
    if (brokerAccessTokenCheckedAt) {
      const updatedTime = new Date();
      updatedTime.setTime(new Date(brokerAccessTokenCheckedAt).getTime() + (VALIDITY_CALL_RANGE * 60 * 1000));
      if (new Date() > updatedTime) {
        brokerAccessTokenCall();
      }
    } else {
      brokerAccessTokenCall();
    }
  }, [currentUser]);

  if (isValidAccessToken) return null;

  return (
    <Modal
      size="md"
      backdrop="static"
      onClose={() => {
        setIsValidAccessToken(true);
        accessTokenCallBack();
      }}
    >
      <div className="text-center">
        <h4>
          Your broker access token is expired
        </h4>
        <img src="/disconnection.svg" alt="user-alert" className="alert-modal-img my-4" />
        <p>
          When you log in into QuantMan using your broker, we will get an access token
          to place or modify orders for you.
          {oneIndentation}
          <b>That access token has expired.</b>
          {oneIndentation}
          We will not be able to place or modify orders. Please login again
          using your broker to get order execution working properly.
        </p>
        <button
          type="button"
          onClick={() => signOut()}
          className="btn btn-outline-primary btn-pill my-3"
        >
          <i className="material-icons-outlined align-bottom mr-1 tx-20">logout</i>
          Logout
        </button>
      </div>
    </Modal>
  );
};

AccessTokenInValidMessage.propTypes = propTypes;
AccessTokenInValidMessage.defaultProps = defaultProps;

export default AccessTokenInValidMessage;
