import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SL_TRAIL_TYPES } from 'v2/common/constants/index';
import { STOPS_VALUE_TYPES } from 'common/constants/index';
import TrailingStopLossInputs from './TrailingStopLossInputs';

const TRAILING_VALUE = 'trailingValue';
const ADJUST_VALUE = 'adjustValue';
const BOOK_PROFIT_ON = 'bookProfitOn';
const BOOK_PROFIT = 'bookProfit';

const propTypes = {
  setTrailingStopLossConfig: PropTypes.func.isRequired,
  trailingStopLossConfig: PropTypes.shape({
    type: PropTypes.string,
    adjustValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    trailingValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bookProfitOn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    bookProfit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  stopLossType: PropTypes.string.isRequired,
};

const TrailingFields = ({
  trailingStopLossConfig,
  setTrailingStopLossConfig,
  stopLossType
}) => {
  const { Percent, Amount, Points } = STOPS_VALUE_TYPES;
  const renderSymbol = useMemo(() => {
    if (stopLossType === Percent) return '%';
    if (stopLossType === Amount) return <span>&#8377;</span>;
    if (stopLossType === Points) return 'Pts';
    return '';
  }, [Amount, Percent, Points, stopLossType]);

  const [isBookProfitEnabled, setIsBookProfitEnabled] = useState(
    trailingStopLossConfig.type === SL_TRAIL_TYPES.BookAndLinear
  );

  const renderTrailingStoplossInputs = (trailProperty) => {
    return (
      <TrailingStopLossInputs
        trailingInput={trailProperty}
        trailingValue={trailingStopLossConfig[trailProperty]}
        onTrailingValueChange={(partiallyUpdatedConfig) => {
          setTrailingStopLossConfig({
            ...trailingStopLossConfig,
            ...partiallyUpdatedConfig,
          });
        }}
        stopLossType={stopLossType}
      />
    );
  };

  const oneIndentation = ' ';

  const renderLinear = () => {
    if (trailingStopLossConfig.type !== SL_TRAIL_TYPES.Linear) return null;

    return (
      <>
        <div className="tsl-wrapper">
          <span>If Instument Moves</span>
          {oneIndentation}
          <b>X</b>
          {renderTrailingStoplossInputs(TRAILING_VALUE)}
          <span>Then Move Stoploss By</span>
          {oneIndentation}
          <b>Y</b>
          {renderTrailingStoplossInputs(ADJUST_VALUE)}
        </div>

        <p className="mt-4 text-center text-muted">
          Every time instrument moves X
          {' '}
          {renderSymbol}
          {' '}
          in the profit
          direction then move stop loss Y
          {' '}
          {renderSymbol}
          {' '}
          in same direction.
        </p>
      </>
    );
  };

  const renderBookAndLinear = () => {
    if (trailingStopLossConfig.type !== SL_TRAIL_TYPES.BookAndLinear) return null;

    return (
      <>
        <div className="tsl-wrapper">
          <span>When profit reaches</span>
          {oneIndentation}
          <b>P1</b>
          {renderTrailingStoplossInputs(BOOK_PROFIT_ON)}
          <span>then lock profit only once on</span>
          {oneIndentation}
          <b>P2</b>
          {renderTrailingStoplossInputs(BOOK_PROFIT)}
        </div>
        <div className="tsl-wrapper mt-2">
          <span>After that, </span>
        </div>
        <div className="tsl-wrapper mt-2">
          <span>Whenever profit increases by</span>
          <b>X</b>
          {oneIndentation}
          {renderTrailingStoplossInputs(TRAILING_VALUE)}
          <span>then trail by</span>
          <b>Y</b>
          {oneIndentation}
          {renderTrailingStoplossInputs(ADJUST_VALUE)}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="form-group form-check mt-4">
        <input
          type="checkbox"
          className="form-check-input tsl-wrapper"
          id="bookProfitCheckBox"
          checked={trailingStopLossConfig.type === SL_TRAIL_TYPES.BookAndLinear}
          onChange={() => {
            setIsBookProfitEnabled(!isBookProfitEnabled);
            setTrailingStopLossConfig({
              ...trailingStopLossConfig,
              type: isBookProfitEnabled
                ? SL_TRAIL_TYPES.Linear
                : SL_TRAIL_TYPES.BookAndLinear,
            });
          }}
        />
        <label className="form-check-label" htmlFor="bookProfitCheckBox">
          Book Profit
        </label>
      </div>

      {renderLinear()}
      {renderBookAndLinear()}
    </div>
  );
};

TrailingFields.propTypes = propTypes;

export default TrailingFields;
