import React from 'react';
import PropTypes from 'prop-types';

import { templatePropTypes } from 'common/propTypes';

const renderTemplate = (template, idx) => {
  const { id, name, tags } = template;
  const tagsContent = _.map(tags, (tag) => {
    return (
      <span className="badge tx-12" key={tag.id}>{tag.name}</span>
    );
  });
  return (
    <a
      key={idx}
      className="template-picker-list-item d-block"
      href={`/runs/new?prebuilt_id=${id}&locale=${I18n.locale}`}
    >
      <div className="list-inner d-flex flex-column h-100" key={idx}>
        <div className="list-head">{name}</div>
        <div className="tags-content">
          <div className="d-flex flex-wrap px-2">
            {' '}
            {tagsContent}
          </div>
        </div>
      </div>
    </a>
  );
};

const TemplateList = ({ templates }) => {
  return (
    <div className="template-picker-list">
      {_.map(templates, renderTemplate)}
      <div className="text-muted p-2 no-match">
        {_.isEmpty(templates) ? 'No Matching Templates Found.' : ''}
      </div>
    </div>
  );
};

TemplateList.propTypes = {
  templates: PropTypes.arrayOf(templatePropTypes).isRequired
};

TemplateList.defaultProps = {};

export default TemplateList;
