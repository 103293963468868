import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useOnClickOutside } from 'common/hooks/onClickOutside';
import { getAllTemplates } from 'common/api/templates';
import LoadingSpinner from 'common/components/LoadingSpinner';
import TemplateList from './TemplateList';

const renderHeader = ({ tagNames, filters, onFiltersChange }) => {
  const { searchTerm, selectedTagNames } = filters;
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsExpanded(false));

  const tagCheckboxesContent = _.chain(tagNames)
    .toPairs()
    .sortBy(([tagName]) => (_.includes(selectedTagNames, tagName) ? 1 : 9))
    .map(([tagName, tagTemplateCount]) => {
      const id = `template-tag-${tagName}-checkbox`;
      const onChange = (event) => {
        onFiltersChange({
          ...filters,
          selectedTagNames: event.target.checked
            ? _.union(selectedTagNames, [tagName])
            : _.without(selectedTagNames, tagName)
        });
      };
      return (
        <div key={tagName}>
          <div className="custom-control custom-checkbox control">
            <input
              type="checkbox"
              className="custom-control-input"
              id={id}
              onChange={onChange}
              defaultChecked={_.includes(selectedTagNames, tagName)}
            />
            <label className="custom-control-label" htmlFor={id}>
              {tagName}
              <span className="badge rounded-pill">{tagTemplateCount}</span>
            </label>
          </div>
        </div>
      );
    })
    .value();

  const tagsContainerClassNames = classNames('collapse', { show: isExpanded });
  return (
    <div className="templates-filter">
      <div className="templates-filter-wrapper">
        <div className="search-filter">
          <i className="material-icons manage-search">manage_search</i>
          <input
            type="text"
            className="form-control"
            placeholder="Search strategies templates"
            value={searchTerm}
            onChange={(event) => onFiltersChange({ searchTerm: event.target.value, selectedTagNames })}
          />
        </div>

        <div className="tag-filter" ref={ref}>
          <div className={tagsContainerClassNames}>
            <div className="templates-picker d-flex flex-wrap gap-8 mt-1">
              <div className="d-none d-md-flex">
                <i className="material-icons"> filter_alt </i>
                <div className="mx-2 font-weight-semi tx-15"> Filter by:</div>
              </div>
              {tagCheckboxesContent}
            </div>
          </div>
          <button
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}
            className="btn btn-outline-primary btn-sm align-self-start mt-1"
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
    </div>
  );
};

const TemplatePicker = ({ isMinified }) => {
  const [tagNames, setTagNames] = useState({});
  const [templates, setTemplates] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [filters, setFilters] = useState({ searchTerm: '', selectedTagNames: [] });
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const onFiltersChange = (newFilters) => {
    const { searchTerm, selectedTagNames } = newFilters;
    const newFilteredTemplates = _.filter(templates, (template) => {
      return (
        _.includes(_.lowerCase(template.name), _.lowerCase(searchTerm))
        && (
          _.isEmpty(selectedTagNames)
          || _.intersection(selectedTagNames, _.map(template.tags, 'name')).length > 0
        )
      );
    });

    setFilters(newFilters);
    setFilteredTemplates(newFilteredTemplates);
  };

  useEffect(() => {
    setLoading(true);
    getAllTemplates()
      .then((results) => {
        setTemplates(results);
        setFilteredTemplates(results);
        setTagNames(_.chain(results).map('tags').flatten().countBy('name')
          .value());
        setLoading(false);
      });
  }, []);

  const containerClassNames = classNames('template-picker', {
    'is-minified': isMinified
  });

  return (
    <div className={containerClassNames}>
      {renderHeader({ tagNames, filters, onFiltersChange })}
      <LoadingSpinner isLoading={isLoading}>
        <div className="prebuilts-wrapper">
          <TemplateList templates={filteredTemplates} />
          {/* {(filteredTemplates.length === 0 && !isLoading) ? 'No matching templates' : ''} */}
        </div>
      </LoadingSpinner>
    </div>
  );
};

TemplatePicker.propTypes = {
  isMinified: PropTypes.bool
};
TemplatePicker.defaultProps = {
  isMinified: false
};

export default TemplatePicker;
