const buildDependencyGraph = (pipeConfigs, cases) => {
  const graph = new Map();

  const addNode = (name) => {
    if (!graph.has(name)) {
      graph.set(name, { isDependent: false });
    }
  };

  const addDependency = (target) => {
    if (!graph.get(target).isDependent) {
      graph.get(target).isDependent = true;
    }
  };

  const processSignal = (signal, name) => {
    const { leftOperand, rightOperand } = signal;
    const regex = new RegExp(`\\b${name}\\b`);
    if (regex.test(leftOperand) || regex.test(rightOperand)) {
      addDependency(name);
    }
  };

  const processIntent = (intent, name) => {
    if (graph.get(name).isDependent) {
      return;
    }

    const bName = intent?.name || '';
    if (name === bName) addDependency(name);
  };

  const processPipeConfig = (pipeConfig) => {
    const { name } = pipeConfig;
    addNode(name);

    if (graph.get(name).isDependent) {
      return;
    }

    cases.forEach((caseConfig) => {
      const {
        entry: { signal: entrySignals, intents: entryIntents },
        adjustments,
        exitSignal: exitSignals
      } = caseConfig;

      entrySignals.forEach((signal) => processSignal(signal, name));
      exitSignals.forEach((signal) => {
        if (graph.get(name).isDependent) {
          return; // Break the loop if already dependent
        }
        processSignal(signal, name);
      });

      if (graph.get(name).isDependent) {
        return;
      }

      entryIntents.forEach((intent) => processIntent(intent, name));

      adjustments.forEach(({ signal: adjustmentSignals, intents: adjustmentIntents }) => {
        adjustmentSignals.forEach((signal) => {
          if (graph.get(name).isDependent) {
            return; // Break the loop if already dependent
          }
          processSignal(signal, name);
          adjustmentIntents.forEach((intent) => processIntent(intent, name));
        });
      });
    });

    pipeConfigs.forEach((pipeConfigToCheck) => {
      const signals = pipeConfigToCheck.config?.signal || pipeConfigToCheck?.config?.when || [];
      signals.forEach((signal) => {
        if (graph.get(name).isDependent) {
          return;
        }
        processSignal(signal, name);
      });
    });
  };

  pipeConfigs.forEach((pipeConfig) => {
    processPipeConfig(pipeConfig);
  });

  return graph;
};

const getPipesDependencies = (pipeConfigs, cases) => {
  const graph = buildDependencyGraph(pipeConfigs, cases);
  const pipeDependencies = Object.fromEntries([...graph.entries()]);
  return pipeDependencies;
};

export default getPipesDependencies;
