import { setLimitOrderBufferPercantage } from 'modules/QuantBuilder/actions';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import BufferPercentage from './BufferPercentage';

const propTypes = {
  limitOrderBufferPercentage: PropTypes.number.isRequired,
  dispatchLimitOrderBufferPercantage: PropTypes.func.isRequired
};
const defaultProps = {};

const LimitOrderBufferPercentage = ({
  dispatchLimitOrderBufferPercantage, limitOrderBufferPercentage
}) => {
  return (
    <div className="orderTypes row align-items-center">
      <BufferPercentage
        bufferPercentage={limitOrderBufferPercentage}
        onChange={dispatchLimitOrderBufferPercantage}
        labelClassName="col-md-2 mb-1"
        buttonClassName="col-md-2 mb-2 mb-md-0"
      />
    </div>
  );
};

LimitOrderBufferPercentage.propTypes = propTypes;
LimitOrderBufferPercentage.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  limitOrderBufferPercentage:
    _.get(state, 'quantConfig.limitOrderBufferPercentage', 0),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLimitOrderBufferPercantage:
    (newPer) => dispatch(setLimitOrderBufferPercantage(newPer))
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitOrderBufferPercentage);
