import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  strategy: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
};

const defaultProps = {
};
const Header = ({
  strategy
}) => {
  const { title } = strategy;

  return (
    <div className="mps-header-inner">
      <h5 className="card-title">{title || '-'}</h5>
      <div className="premium">
        <img src="../crowns.png" className="crown" alt="" />
        {' '}
        <span className="d-none d-md-block">PREMIUM</span>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
