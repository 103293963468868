import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { advancedFeatureConfigs } from 'modules/QuantBuilder/config/signalConfigs';
import { setOrderConfigs } from 'modules/QuantBuilder/actions';
import HoverMessage from 'common/components/HoverMessage';
import { reEntryAndReExecuteBasedReset, trailingStopLossBasedReset, waitAndTradeReset } from './fellowWorker';
import { isEligibleToMoveToCostTransactionConfigs } from './AdjustmentAdaptor/moveToCostAdjustment';
import { onConfigureAutoAdjustment } from './AdjustmentAdaptor/index';

const propTypes = {
  orderConfigIndex: PropTypes.number.isRequired,
};

const defaultProps = {};

const AdvancedFeaturesEnablement = (props) => {
  const { orderConfigIndex } = props;
  const globalReduxState = useSelector((state) => state);
  const runType = _.get(globalReduxState, 'runType');
  const orderConfigs = _.get(globalReduxState, 'quantConfig.orderConfigs', []);
  const currentOrderConfig = _.get(orderConfigs, orderConfigIndex, {});
  const advancedFeatures = _.get(currentOrderConfig, 'advancedFeatureConfigs', advancedFeatureConfigs());
  const {
    isMoveToCost, isReEntry, isReExecute, isTrailingStopLoss,
  } = advancedFeatures;
  const isWaitAndTrade = _.get(advancedFeatures, 'isWaitAndTrade', false);
  const dispatch = useDispatch();
  const transactionConfigs = _.get(currentOrderConfig, 'transactionConfigs', []);

  const noOfInstrumentsInGroup = _.get(globalReduxState, 'quantConfig.instrumentGroups.0', []).length;
  const maxInstrumentsInGroup = _.get(
    globalReduxState, 'quantConfig.maxInstrumentsInGroup', noOfInstrumentsInGroup
  );

  const isShowWaitAndTrade = runType === 'live'
    ? _.get(window, 'userPermissions.waitAndTrade.isEnabled', false)
    : _.get(window, 'userPermissions.waitAndTradeForBacktest.isEnabled', false);

  const getSanitizedOrderConfig = (orderConfig, config) => {
    let alteredOrderConfig = orderConfig;

    // Trailing stoploss reset for particular orderConfigIndex.
    if (isTrailingStopLoss && !config.isTrailingStopLoss) {
      alteredOrderConfig = trailingStopLossBasedReset(alteredOrderConfig);
    }

    if (isWaitAndTrade && !config.isWaitAndTrade) {
      alteredOrderConfig = waitAndTradeReset(alteredOrderConfig);
    }

    // (ReEntry or Reset) reset for particular orderConfigIndex.
    if ((isReEntry || isReExecute) && (!config.isReEntry && !config.isReExecute)) {
      alteredOrderConfig = reEntryAndReExecuteBasedReset(alteredOrderConfig);
    }

    return { ...alteredOrderConfig, advancedFeatureConfigs: config };
  };

  const onUpdateOrderConfigs = (alteredAdvancedFeatures) => {
    const newOrderConfigs = _.map(orderConfigs, (orderConfig, idx) => {
      if (orderConfigIndex === idx) {
        return getSanitizedOrderConfig(orderConfig, { ...advancedFeatures, ...alteredAdvancedFeatures });
      }

      return orderConfig;
    });

    const newCurrentOrderConfig = _.get(newOrderConfigs, orderConfigIndex, {});
    const newTransactionConfigs = _.get(newCurrentOrderConfig, 'transactionConfigs', []);

    dispatch(
      setOrderConfigs(
        onConfigureAutoAdjustment({
          orderConfigs: newOrderConfigs,
          orderConfigIndex,
          newTransactionConfigs,
          maxInstrumentsInGroup
        })
      )
    );
  };

  const isPermissionToEnableReEntry = _.get(window, 'userPermissions.reEntry.isEnabled', false);
  const isPermissionToEnableReExecute = _.get(window, 'userPermissions.reExecute.isEnabled', false);

  let reEntryAndReExecuteLabel = '';
  if (isPermissionToEnableReEntry) { reEntryAndReExecuteLabel = 'ReEntry'; }
  if (reEntryAndReExecuteLabel) { reEntryAndReExecuteLabel += ' / '; }
  if (isPermissionToEnableReExecute) { reEntryAndReExecuteLabel += 'ReExecute'; }
  const mtcContent = (
    <div>
      If any leg SL hits, the opposite leg SL is moved to Cost.
      <br />
      <br />
      <b>*Only when positions favour us will we move to SL.</b>
    </div>
  );

  const reEntryReExecuteInfoContent = (
    <div>
      No re-entry / re-execute happens if the trailing stop loss or move to cost is already triggered.
    </div>
  );

  const featureConfigs = [
    {
      label: 'Trailing Stop Loss',
      isPermissionToShow: _.get(window, 'userPermissions.trailingStopLoss.isEnabled', false),
      isChecked: isTrailingStopLoss,
      id: `isTrailingStopLoss${orderConfigIndex}`,
      updateValues: { isTrailingStopLoss: !isTrailingStopLoss },
      isdisabled: false
    },
    {
      label: 'Move to cost',
      isPermissionToShow: _.get(window, 'userPermissions.moveToCost.isEnabled', false),
      isChecked: isMoveToCost,
      id: `isMoveToCost${orderConfigIndex}`,
      updateValues: { isMoveToCost: !isMoveToCost },
      isdisabled: !isEligibleToMoveToCostTransactionConfigs(transactionConfigs),
      infoIcon: (
        <HoverMessage title="Move to Cost" message={mtcContent}>
          <span className="material-icons-outlined text-dark tx-16 align-middle ml-1 cursor-pointer">
            info
          </span>
        </HoverMessage>
      )
    },
    {
      label: reEntryAndReExecuteLabel,
      isPermissionToShow: isPermissionToEnableReEntry || isPermissionToEnableReExecute,
      isChecked: isReEntry || isReExecute,
      id: `isReEntry${orderConfigIndex}`,
      updateValues: { isReEntry: !isReEntry, isReExecute: !isReExecute },
      isdisabled: false,
      infoIcon: (
        <HoverMessage message={reEntryReExecuteInfoContent}>
          <span className="material-icons-outlined text-dark tx-16 align-middle ml-1 cursor-pointer">
            info
          </span>
        </HoverMessage>
      )
    },
    {
      label: 'Wait and Trade',
      isPermissionToShow: isShowWaitAndTrade,
      isChecked: isWaitAndTrade,
      id: `isWaitAndTrade${orderConfigIndex}`,
      updateValues: { isWaitAndTrade: !isWaitAndTrade },
      isdisabled: false,
    }
  ];

  const renderFeatureConfig = ({
    label, isPermissionToShow, isChecked, id, updateValues, isdisabled, infoIcon
  }) => {
    if (!isPermissionToShow) return null;

    return (
      <div key={id} className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input track"
            type="checkbox"
            data-track-category="Advanced Features Enablement"
            data-track-action={label}
            data-track-label={label}
            disabled={isdisabled}
            id={id}
            onClick={() => {}}
            onChange={() => { onUpdateOrderConfigs(updateValues); }}
            checked={isChecked}
          />
          <label className="form-check-label" htmlFor={id} disabled={isdisabled}>{label}</label>
        </div>
        {infoIcon && <>{infoIcon}</>}
      </div>
    );
  };

  if (_.isEmpty(featureConfigs)) return null;

  return (
    <div className="d-flex justify-content align-items-center gap-15 ml-2 flex-wrap my-2">
      {_.map(featureConfigs, renderFeatureConfig)}
    </div>
  );
};

AdvancedFeaturesEnablement.propTypes = propTypes;
AdvancedFeaturesEnablement.defaultProps = defaultProps;

export default AdvancedFeaturesEnablement;
