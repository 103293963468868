import { getEmptyAdjustmentActionConfig } from 'modules/TransactionsBuilder/configs';

const getSignalConfigsForAdjustment = (leftOperand, rightOperand) => {
  return [
    {
      conditionOperator: '',
      leftOperand,
      operator: 'equalOrAbove',
      rightOperand: `${rightOperand}`,
      mode: { isAutoAdjustment: true, adjustmentMode: 'reExecute' }
    }
  ];
};

const buildAdjustmentTransactionsConfigs = (
  configName, config, maxInstrumentsInGroup, transactionConfigIndex
) => {
  const timesOfAdjustmentConfig = _.times(maxInstrumentsInGroup, () => {
    const emptyAdjustmentActionConfig = getEmptyAdjustmentActionConfig();
    const { mode } = emptyAdjustmentActionConfig;

    return {
      ...emptyAdjustmentActionConfig,
      mode: { ...mode, isAutoAdjustment: true, adjustmentMode: 'reExecute' }
    };
  });

  const adjustConfig = timesOfAdjustmentConfig[transactionConfigIndex];
  const { mode } = adjustConfig;

  switch (configName) {
    case 'equity':
      timesOfAdjustmentConfig[transactionConfigIndex] = {
        ...adjustConfig,
        optionConfigs: [],
        equityConfig: { ...config, reExecuteCount: 0 },
        mode: { ...mode, transaction: 'equity' },
        existingLegConfigs: []
      };
      break;
    case 'future':
      timesOfAdjustmentConfig[transactionConfigIndex] = {
        ...adjustConfig,
        optionConfigs: [],
        futureConfig: { ...config, reExecuteCount: 0 },
        mode: { ...mode, transaction: 'future' },
        existingLegConfigs: []
      };
      break;
    default:
      timesOfAdjustmentConfig[transactionConfigIndex] = {
        ...adjustConfig,
        optionConfigs: [{ ...config, reExecuteCount: 0 }],
        mode: { ...mode, transaction: 'option' },
        existingLegConfigs: []
      };
      break;
  }

  return timesOfAdjustmentConfig;
};

const addAdjustmentConfigsBasedOnParentLegs = (
  config, transactionConfigIndex, configName, optionConfigIndex = 0, maxInstrumentsInGroup, count,
  onChangeCount
) => {
  let reAssignedCount = count;
  const reExecuteCount = _.get(config, 'reExecuteCount', 0);

  if (reExecuteCount === 0) { return { signalConfigs: [], adjustmentConfigs: [] }; }
  const { stopLoss } = config;

  const configs = _.map(_.range(reExecuteCount), (reExecuteIndex) => {
    switch (configName) {
      case 'equity':
        let equityLeftOperand = '';
        if (reExecuteIndex === 0) {
          equityLeftOperand = `individualStop.${transactionConfigIndex}.${configName}.lossPercentage`;
        } else {
          equityLeftOperand = `individualStop.${transactionConfigIndex}.${configName}`;
          equityLeftOperand += `_${reAssignedCount - 1}.lossPercentage`;
        }

        reAssignedCount += 1;
        onChangeCount(reAssignedCount);

        return {
          signalConfigs: getSignalConfigsForAdjustment(equityLeftOperand, stopLoss),
          adjustmentConfigs: buildAdjustmentTransactionsConfigs(
            configName, config, maxInstrumentsInGroup, transactionConfigIndex
          )
        };
      case 'future':
        let futureLeftOperand = `individualStop.${transactionConfigIndex}.${configName}.lossPercentage`;
        if (reExecuteIndex === 0) {
          futureLeftOperand = `individualStop.${transactionConfigIndex}.${configName}.lossPercentage`;
        } else {
          futureLeftOperand = `individualStop.${transactionConfigIndex}.${configName}`;
          futureLeftOperand += `_${reAssignedCount - 1}.lossPercentage`;
        }

        reAssignedCount += 1;
        onChangeCount(reAssignedCount);

        return {
          signalConfigs: getSignalConfigsForAdjustment(futureLeftOperand, stopLoss),
          adjustmentConfigs: buildAdjustmentTransactionsConfigs(
            configName, config, maxInstrumentsInGroup, transactionConfigIndex
          )
        };
      default:
        let optionLeftOperand = '';
        if (reExecuteIndex === 0) {
          optionLeftOperand = `individualStop.${transactionConfigIndex}.${configName}`;
          optionLeftOperand += `_${optionConfigIndex}.lossPercentage`;
        } else {
          optionLeftOperand = `individualStop.${transactionConfigIndex}.${configName}_`;
          optionLeftOperand += `0_${reAssignedCount - 1}.lossPercentage`;
        }

        reAssignedCount += 1;
        onChangeCount(reAssignedCount);
        return {
          signalConfigs: getSignalConfigsForAdjustment(optionLeftOperand, stopLoss),
          adjustmentConfigs: buildAdjustmentTransactionsConfigs(
            configName, config, maxInstrumentsInGroup, transactionConfigIndex
          )
        };
    }
  });

  return {
    signalConfigs: _.map(configs, ({ signalConfigs }) => { return signalConfigs; }),
    adjustmentConfigs: _.map(configs, ({ adjustmentConfigs }) => { return adjustmentConfigs; })
  };
};

export const addAdjustmentBasedOnReExecute = (
  transactionConfigs, maxInstrumentsInGroup, aac, asc, initialCount
) => {
  let adjustmentSignalConfigs = asc;
  let adjustmentActionConfigs = aac;

  let count = initialCount;
  const onChangeCount = (reAssignedCount) => { count = reAssignedCount; };

  _.each(transactionConfigs, (transactionConfig, transactionConfigIndex) => {
    const { equityConfig, futureConfig, optionConfigs } = transactionConfig;
    const {
      signalConfigs: equitySignalConfigs, adjustmentConfigs: equityAdjustmentConfigs
    } = addAdjustmentConfigsBasedOnParentLegs(
      equityConfig, transactionConfigIndex, 'equity', 0, maxInstrumentsInGroup, count, onChangeCount
    );
    const {
      signalConfigs: futureSignalConfigs, adjustmentConfigs: futureAdjustmentConfigs
    } = addAdjustmentConfigsBasedOnParentLegs(
      futureConfig, transactionConfigIndex, 'future', 0, maxInstrumentsInGroup, count, onChangeCount
    );

    adjustmentSignalConfigs = [...adjustmentSignalConfigs, ...equitySignalConfigs];
    adjustmentSignalConfigs = [...adjustmentSignalConfigs, ...futureSignalConfigs];

    adjustmentActionConfigs = [...adjustmentActionConfigs, ...equityAdjustmentConfigs];
    adjustmentActionConfigs = [...adjustmentActionConfigs, ...futureAdjustmentConfigs];

    _.each(optionConfigs, (optionConfig, optionConfigIndex) => {
      const {
        signalConfigs: optionSignalConfigs, adjustmentConfigs: optionAdjustmentConfigs
      } = addAdjustmentConfigsBasedOnParentLegs(
        optionConfig, transactionConfigIndex, 'option', optionConfigIndex, maxInstrumentsInGroup, count,
        onChangeCount
      );

      adjustmentSignalConfigs = [...adjustmentSignalConfigs, ...optionSignalConfigs];
      adjustmentActionConfigs = [...adjustmentActionConfigs, ...optionAdjustmentConfigs];
    });
  });

  return { adjustmentSignalConfigs, adjustmentActionConfigs, reAssignedCount: count };
};
