import React from 'react';
import { planPropTypes } from 'common/propTypes';
import PaymentMoneyDisplay from 'ui/Plans/components/common/PaymentMoneyDisplay';
import PropTypes from 'prop-types';
import { planCycleLabel } from './helper';

const propTypes = {
  currentPlan: planPropTypes.isRequired,
  isUpgradePlan: PropTypes.bool
};

const defaultProps = {
  isUpgradePlan: false
};

const CurrentAndUpgradePlanDetail = ({ currentPlan, isUpgradePlan }) => {
  const {
    plan_name: planName,
    live_run_limit: liveRunLimit,
    price,
    historic_run_limit: historicRunLimit,
    cycle_duration_in_months: duration,
  } = currentPlan;

  const currentNetPrice = _.get(currentPlan, 'currentNetPrice', price);
  const oneIndentation = ' ';

  return (
    <div className="card plan-info mb-2">
      <div className="card-body">
        <h4 className="main-title">{isUpgradePlan ? 'Upgrade Plan' : 'Current Plan'}</h4>
        <h5 className="plan-title">
          {_.startCase(planName)}
          {oneIndentation}
          <small className="sub">{planCycleLabel(duration)}</small>
        </h5>
        <div className="current-plan-details">
          <div className="plan-info">
            <span className="info-text">Live </span>
            {liveRunLimit}
          </div>
          <div className="plan-info">
            <span className="info-text">Backtest </span>
            {historicRunLimit}
          </div>
          <div className="plan-info">
            <span className="info-text">Plan Amount </span>
            <PaymentMoneyDisplay
              shouldAvoidDecimals
              currentNetPrice={currentNetPrice}
              price={price}
              shouldHideBillCycle
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CurrentAndUpgradePlanDetail.propTypes = propTypes;
CurrentAndUpgradePlanDetail.defaultProps = defaultProps;

export default CurrentAndUpgradePlanDetail;
