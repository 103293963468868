import React from 'react';
import PropTypes from 'prop-types';
import OrderTypeSelector from './OrderTypeSelector';
import ModifyOrCancelOrder from './ModifyOrCancelOrder';

const propTypes = {
  orderType: PropTypes.string.isRequired,
  orderModification: PropTypes.shape({}).isRequired,
  onHandleTypeAndModification: PropTypes.func.isRequired,
  isExitOrder: PropTypes.bool,
};

const defaultProps = { isExitOrder: false };

const EntryOrExitOrderTypes = ({
  orderType, orderModification, onHandleTypeAndModification, isExitOrder
}) => {
  const action = _.get(orderModification, 'action', '');
  const actionTimeInSeconds = _.get(orderModification, 'actionTimeInSeconds', '');

  const onChangeOrderType = ((value) => {
    onHandleTypeAndModification({ orderType: value, orderModification: {} });
  });

  const onChangeOrderModification = (orderModificationValue) => {
    onHandleTypeAndModification({ orderType, orderModification: orderModificationValue });
  };

  return (
    <div className="order-type">
      <div className="order-select-field">
        <div className="input-group-text">
          {_.startCase(isExitOrder ? 'Exit Order' : 'Entry Order')}
        </div>
        <OrderTypeSelector onChange={onChangeOrderType} selectedOrderType={orderType} />
      </div>
      <ModifyOrCancelOrder
        orderType={orderType}
        isExitOrder={isExitOrder}
        action={action}
        actionTimeInSeconds={actionTimeInSeconds}
        onUpdate={onChangeOrderModification}
      />
    </div>
  );
};

EntryOrExitOrderTypes.propTypes = propTypes;
EntryOrExitOrderTypes.defaultProps = defaultProps;

export default EntryOrExitOrderTypes;
