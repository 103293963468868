import React from 'react';
import { NOTIFICATION_EVENTS } from 'common/constants';
import notificationPropType from './propTypes/notificationPropType';
import RunDetailNotificationMessage from './RunDetailNotificationMessage';
import OrderConfirmationDetailedMessage from './OrderConfirmationDetailedMessage';

const propTypes = {
  notification: notificationPropType.isRequired,
};

const defaultProps = {};

const NotificationMessage = ({ notification }) => {
  const { notifiable_type: notifiableType } = notification;

  const renderMessage = () => {
    switch (notifiableType) {
      case NOTIFICATION_EVENTS.RUN.NAME:
        return <RunDetailNotificationMessage notification={notification} />;
      case NOTIFICATION_EVENTS.ORDER_CONFIRMATION.NAME:
        return <OrderConfirmationDetailedMessage notification={notification} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="icons" />
      {renderMessage()}
    </>
  );
};

NotificationMessage.propTypes = propTypes;
NotificationMessage.defaultProps = defaultProps;

export default NotificationMessage;
