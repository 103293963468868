import React, { useState } from 'react';

import { BROKERS } from 'common/constants';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};
const defaultProps = {};

const PhilipCapitalLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <img
            src="https://www.phillipcapital.in/assets/img/logo.png"
            title="Philip Capital"
            alt="Philip Capital"
            className="mb-5"
          />
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16 mb-4">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with PhillipCapital API.
          </p>
          <div
            onClick={() => { setCurrentBroker(BROKERS.philip_capital.id); }}
            className="btn btn-outline-primary broker"
          >
            Login via
            {oneIndentation}
            <b>PhillipCapital</b>
          </div>
        </div>
      </div>
      <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
    </div>
  );
};

PhilipCapitalLogin.propTypes = propTypes;
PhilipCapitalLogin.defaultProps = defaultProps;

export default PhilipCapitalLogin;
