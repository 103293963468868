import React from 'react';

const propTypes = {};
const defaultProps = {};

const Header = () => {
  return (
    <div className="filter-header">
      <div className="filter-rs">
        <div>
          <ul className="list-inline legend">
            <li className="list-inline-item">
              <span className="legend-color hit100" />
              hit
            </li>
            <li className="list-inline-item">
              <span className="legend-color miss100" />
              miss
            </li>
            <li className="list-inline-item">
              <span className="legend-color profit100" />
              Profit
            </li>
            <li className="list-inline-item">
              <span className="legend-color loss100" />
              loss
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
