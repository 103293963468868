export const SET_OUTPUT_SCHEMA = 'SET_OUTPUT_SCHEMA';
export const setOutputSchema = (outputSchema) => ({
  type: SET_OUTPUT_SCHEMA,
  outputSchema,
});

export const SET_MAX_INSTRUMENTS_IN_GROUP = 'SET_MAX_INSTRUMENTS_IN_GROUP';
export const setMaxInstrumentsInGroup = (maxInstrumentsInGroup) => ({
  type: SET_MAX_INSTRUMENTS_IN_GROUP,
  maxInstrumentsInGroup,
});

export const SET_INSTRUMENT_GROUPS = 'SET_INSTRUMENT_GROUPS';
export const setInstrumentGroups = (instrumentGroups) => ({
  type: SET_INSTRUMENT_GROUPS,
  instrumentGroups,
});

export const SET_CHART_CONFIG = 'SET_CHART_CONFIG';
export const setChartConfig = (chartConfig) => ({
  type: SET_CHART_CONFIG,
  chartConfig
});

export const SET_PIPE_CONFIGS = 'SET_PIPE_CONFIGS';
export const setPipeConfigs = (pipeConfigs) => ({
  type: SET_PIPE_CONFIGS,
  pipeConfigs,
});

export const SET_REPEAT_ORDER = 'SET_REPEAT_ORDER';
export const setRepeatOrder = (repeatOrder) => ({
  type: SET_REPEAT_ORDER,
  repeatOrder,
});

export const SET_ORDER_CONFIGS = 'SET_ORDER_CONFIGS';
export const setOrderConfigs = (orderConfigs) => ({
  type: SET_ORDER_CONFIGS,
  orderConfigs,
});

export const SET_TRADE_TYPE = 'SET_TRADE_TYPE';
export const setTradeType = (tradingType) => ({
  type: SET_TRADE_TYPE,
  tradingType,
});

export const SET_OPTIONS_EXPIRY_CYCLE = 'SET_OPTIONS_EXPIRY_CYCLE';
export const setOptionsExpiryCycle = (optionsExpiryCycle) => ({
  type: SET_OPTIONS_EXPIRY_CYCLE,
  optionsExpiryCycle,
});

export const SET_TIME_BASED_SIGNAL = 'SET_TIME_BASED_SIGNAL';
export const setTimeBasedSignal = (timeBasedSignal) => ({
  type: SET_TIME_BASED_SIGNAL,
  timeBasedSignal,
});

export const SET_EXPIRY_HANDLING = 'SET_EXPIRY_HANDLING';
export const setExpiryHandling = (expiryHandling) => ({
  type: SET_EXPIRY_HANDLING,
  expiryHandling,
});

export const SET_QUANT_CONFIG = 'SET_QUANT_CONFIG';
export const setQuantConfig = (partialQuantConfig) => ({
  type: SET_QUANT_CONFIG,
  partialQuantConfig,
});

export const SET_LIMIT_TRANSACTIONS = 'SET_LIMIT_TRANSACTIONS';
export const setLimitTransactions = (maxTransactionsPerDay) => ({
  type: SET_LIMIT_TRANSACTIONS,
  maxTransactionsPerDay,
});

export const SET_ENTRY_ORDER_TYPE = 'SET_ENTRY_ORDER_TYPE';
export const setEntryOrderType = (entryOrderType) => ({
  type: SET_ENTRY_ORDER_TYPE,
  entryOrderType,
});

export const SET_EXIT_ORDER_TYPE = 'SET_EXIT_ORDER_TYPE';
export const setExitOrderType = (exitOrderType) => ({
  type: SET_EXIT_ORDER_TYPE,
  exitOrderType,
});

export const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE';
export const setProductType = (product) => ({
  type: SET_PRODUCT_TYPE,
  product,
});

export const SET_OPTION_STRIKE_CALCULATION_BASED_ON = 'SET_OPTION_STRIKE_CALCULATION_BASED_ON';
export const setOptionStrikeCalculationBasedOn = (optionStrikeCalculationBasedOn) => ({
  type: SET_OPTION_STRIKE_CALCULATION_BASED_ON,
  optionStrikeCalculationBasedOn,
});

export const
  SET_GAP_BETWEEN_BUY_AND_SELL_ORDERS_IN_SECONDS = 'SET_GAP_BETWEEN_BUY_AND_SELL_ORDERS_IN_SECONDS';
export const setGapBetweenBuyAndSellOrders = (gapBetweenBuyAndSellOrdersInSeconds) => ({
  type: SET_GAP_BETWEEN_BUY_AND_SELL_ORDERS_IN_SECONDS,
  gapBetweenBuyAndSellOrdersInSeconds,
});

export const
  STOP_AND_LIMIT_PRICE_DIFFERENCE_IN_PERCENTAGE = 'STOP_AND_LIMIT_PRICE_DIFFERENCE_IN_PERCENTAGE';
export const setStopAndLimitPriceDifference = (stopAndLimitPriceDifferenceInPercentage) => ({
  type: STOP_AND_LIMIT_PRICE_DIFFERENCE_IN_PERCENTAGE,
  stopAndLimitPriceDifferenceInPercentage,
});

export const SET_COMBINED_PREMIUM_CONFIGS = 'SET_COMBINED_PREMIUM_CONFIGS';
export const setCombinedPremiumConfigs = (combinedPremiumConfigs) => ({
  type: SET_COMBINED_PREMIUM_CONFIGS,
  combinedPremiumConfigs,
});

export const SET_CUSTOM_PROP_CONFIGS = 'SET_CUSTOM_PROP_CONFIGS';
export const setCustomPropConfigs = (customPropConfigs) => ({
  type: SET_CUSTOM_PROP_CONFIGS,
  customPropConfigs
});

export const SET_ENTRY_ORDER_MODIFICATION = 'SET_ENTRY_ORDER_MODIFICATION';
export const setEntryOrderModification = (entryOrderModification) => ({
  type: SET_ENTRY_ORDER_MODIFICATION,
  entryOrderModification,
});

export const SET_EXIT_ORDER_MODIFICATION = 'SET_EXIT_ORDER_MODIFICATION';
export const setExitOrderModification = (exitOrderModification) => ({
  type: SET_EXIT_ORDER_MODIFICATION,
  exitOrderModification,
});

export const OMIT_KEY_VALUES = 'OMIT_KEYS';
export const removeKeyValues = (omitKeyValues) => ({
  type: OMIT_KEY_VALUES,
  omitKeyValues,
});

export const SET_IS_PLACE_OPTIONS_ENTRY_BUY_ORDERS_FIRST = 'isPlaceOptionsEntryBuyOrdersFirst';
export const setIsPlaceOptionsEntryBuyOrdersFirst = (isPlaceOptionsEntryBuyOrdersFirst) => ({
  type: SET_IS_PLACE_OPTIONS_ENTRY_BUY_ORDERS_FIRST,
  isPlaceOptionsEntryBuyOrdersFirst,
});

export const SET_SL_ORDER_PLACE_BASED_ON_PRICE = 'slOrderPlaceBasedOnPrice';
export const setSlOrderPlaceBasedOnPrice = (slOrderPlaceBasedOnPrice) => ({
  type: SET_SL_ORDER_PLACE_BASED_ON_PRICE,
  slOrderPlaceBasedOnPrice,
});

export const SET_LIMIT_ORDER_BUFFER_PERCENTAGE = 'limitOrderBufferPercentage';
export const setLimitOrderBufferPercantage = (limitOrderBufferPercentage) => ({
  type: SET_LIMIT_ORDER_BUFFER_PERCENTAGE,
  limitOrderBufferPercentage
});

export const SET_IS_PLACE_STOPLOSS_ORDER_TO_BROKER = 'isPlaceStoplossOrderToBroker';
export const setIsPlaceStoplossOrderToBroker = (isPlaceStoplossOrderToBroker) => ({
  type: SET_IS_PLACE_STOPLOSS_ORDER_TO_BROKER,
  isPlaceStoplossOrderToBroker,
});

export const SET_IS_SQUARE_OFF_TRANSACTION_IF_ENTRY_FAILED = 'isSquareOffTransactionIfEntryFailed';
export const setIsSquareOffTransactionIfEntryFailed = (isSquareOffTransactionIfEntryFailed) => ({
  type: SET_IS_SQUARE_OFF_TRANSACTION_IF_ENTRY_FAILED,
  isSquareOffTransactionIfEntryFailed,
});

export const SET_ADJUSTMENT_OPTIONS = 'adjustmentOptions';
export const setAdjustmentOptions = (adjustmentOptions) => ({
  type: SET_ADJUSTMENT_OPTIONS,
  adjustmentOptions,
});
