import React from 'react';
import PropTypes from 'prop-types';
import MoneyDisplay from 'common/components/MoneyDisplay';
// import HoverMessage from 'common/components/HoverMessage';
// import moment from 'moment';
import { runPropTypes } from 'common/propTypes';

const propTypes = {
  strategy: PropTypes.shape(
    {
      margin_amount: PropTypes.number.isRequired,
      max_drawdown: PropTypes.number,
      live_run: runPropTypes,
      deployed_run_details: PropTypes.shape({}),
      deployed_user_count: PropTypes.number
    }
  ).isRequired,
  isShowDates: PropTypes.bool,
  profitableValueParentClassName: PropTypes.string
};

const defaultProps = { isShowDates: false, profitableValueParentClassName: 'mpstrategy-value-wrapper' };

const ProfitableValues = ({ strategy, isShowDates, profitableValueParentClassName }) => {
  const {
    margin_amount: marginAmount,
    // max_drawdown: maxDrawDown,
    deployed_run_details: deployedRunDetails,
    deployed_user_count: deployedUserCount
    // live_run: liveRun if we enable live run for users.. remove maxdrawdown in market place
  } = strategy;
  // const backtestProfit = _.get(strategy, 'backtest_run.summary.profit', 0);
  const backtestStartDate = _.get(strategy, 'backtest_run.start_date', '');
  const backtestEndDate = _.get(strategy, 'backtest_run.end_date', '');
  // const backtestYear = moment(backtestEndDate).diff(moment(backtestStartDate), 'year');
  const deployedRun = _.get(deployedRunDetails, 'deployed_run', {});
  const deployedOrderType = _.get(deployedRun, 'order_type', '');
  // const { summary: { profit } } = liveRun;

  const renderBacktestDates = () => {
    const isShow = (backtestStartDate && backtestEndDate);
    if (!isShow) return null;

    const renderDate = (label, value) => (
      <div className="value-point border-0">
        <div className="value-name">{label}</div>
        <span className="font-weight-medium">{value || '-'}</span>
      </div>
    );

    return (
      <>
        {renderDate('Start Date', backtestStartDate)}
        {renderDate('End Date', backtestEndDate)}
      </>
    );
  };

  const renderData = (displayName, value) => {
    return (
      <div className="value-point">
        <div className="value-name">{displayName}</div>
        <div className="font-weight-semi tx-15">{value || ''}</div>
      </div>
    );
  };

  // const backtestPercentage = ((backtestProfit / backtestYear) / marginAmount) * 100;

  const initialColumnData = () => {
    return (
      <>
        {!isShowDates && renderData(
          'Margin Required (Approx)',
          <MoneyDisplay shouldAvoidDecimals>{marginAmount}</MoneyDisplay>
        )}
        {/* {renderData(
          'Yearly Profit',
          <div className="d-flex align-items-center gap-5">
            {_.isFinite(backtestPercentage) ? `${_.round(backtestPercentage, 2)} %` : '-'}
            <HoverMessage
              title="Backtest Profit and Loss"
              message={`Backtest from ${backtestStartDate} to ${backtestEndDate}.
              Brokerage and Slippage are not considered.`}
            >
              <i className="tx-15 material-icons cursor-pointer">info</i>
            </HoverMessage>
          </div>
        )}
        {renderData('Drawdown', <MoneyDisplay shouldAvoidDecimals>{maxDrawDown}</MoneyDisplay> || '-')} */}
        {renderData('Deployed Users', deployedUserCount.toString())}
        {(deployedOrderType && !isShowDates)
          && renderData('Deployed Order Type', _.startCase(deployedOrderType))}
      </>
    );
  };

  return (
    <div className={profitableValueParentClassName}>
      <div className="profitable-value">
        {isShowDates && renderBacktestDates()}
        {initialColumnData()}
      </div>
    </div>
  );
};

ProfitableValues.propTypes = propTypes;
ProfitableValues.defaultProps = defaultProps;

export default ProfitableValues;
