import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import ReactHtmlParser from 'react-html-parser';
import { getBrokerImage, reDirectPage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

const defaultProps = {};

const SkyBroking = ({ onCloseModal, broker }) => {
  const [isValid, setIsValid] = useState(true);
  const [clientId, setClientId] = useState('');
  const [token, setToken] = useState('');

  const onNavigate = () => {
    if (!clientId || !token) return setIsValid(false);
    const reDirectData = {
      client_id: clientId,
      token
    };
    reDirectPage(reDirectData, broker);

    return '';
  };

  const modalProps = {
    onClose: onCloseModal,
    shouldShow: true,
    size: 'sm',
    onSaveid: `btn-${broker}`,
    onSave: () => onNavigate(),
    okBtnTitle: 'Login',
    isOkBtnDisabled: false
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="mb-3">
        {ReactHtmlParser(getBrokerImage(broker))}
        <label className="mt-2">Client Id</label>
        <input
          className="form-control text-uppercase"
          type="text"
          id={`${broker}-id`}
          value={clientId}
          onChange={(event) => { setClientId(event.target.value); }}
        />
        <label className="mt-2">token</label>
        <input
          className="form-control"
          type="text"
          id={`${broker}-token`}
          value={token}
          onChange={(event) => { setToken(event.target.value); }}
        />
        {!isValid && <div className="invalid-feedback d-block">Please provide the details</div>}

        <div className="my-2 alert alert-warning py-1 px-2">
          Please follow the instructions below to obtain a token.
          <br />
          <ul>
            <li>
              Login
              {' '}
              <a href="https://skypro.skybroking.com/" target="_blank" rel="noreferrer">skypro</a>
            </li>
            <li>Go to profile section</li>
            <li>Click API key</li>
            <li>Choose QUANTMAN in vendor</li>
            <li>Generate API token and paste in appropriate field</li>
          </ul>
        </div>
      </div>
    </ModalComponent>
  );
};

SkyBroking.propTypes = propTypes;
SkyBroking.defaultProps = defaultProps;

export default SkyBroking;
