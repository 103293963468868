import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { useSelector } from 'react-redux';
import { basket } from 'v2/common/quantConfig/pipeDefinitions/index';
import { IntentsContext } from 'v2/modules/withRunForm/hoc/withIntentsContext';

const propTypes = {
  name: PropTypes.string,
  onChangeName: PropTypes.func.isRequired,
};

const defaultProps = {
  name: '',
};

const BasketSelector = ({ name: bName, onChangeName }) => {
  const {
    errors,
    control
  } = useContext(IntentsContext);

  const uniqueId = useMemo(() => _.uniqueId('basket_selector'), []);
  const pipeConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.pipeConfigs'));
  const pipeNames = _.chain(pipeConfigs)
    .filter(({ type }) => type === basket?.type)
    .map('name')
    .value();

  const validator = {
    validate: (val) => {
      if (!val) return 'basket should be selected';
      return null;
    }
  };

  return (
    <ErrorMesssage message={_.get(errors, `${uniqueId}.message`, '')}>
      <Controller
        render={({ onChange, ref, name }) => (
          <Form.Control
            as="select"
            value={bName}
            ref={ref}
            size="sm"
            name={name}
            onChange={(event) => {
              onChangeName(event.target.value);
              onChange(event.target.value);
            }}
          >
            <option value="" />
            {_.map(pipeNames, (pipeName) => (
              <option key={pipeName} value={pipeName}>{_.startCase(pipeName)}</option>
            ))}
          </Form.Control>
        )}
        control={control}
        defaultValue={bName}
        name={uniqueId}
        rules={validator}
      />
    </ErrorMesssage>
  );
};

BasketSelector.propTypes = propTypes;
BasketSelector.defaultProps = defaultProps;

export default BasketSelector;
