import moment from 'moment';
import { computePLAndHM } from './common';

export const getAnalyticsData = (transactions, analyticsGroupBy) => {
  return _.chain(transactions)
    .groupBy(analyticsGroupBy)
    .reduce((hsh, symbolOrdersList, analyticsKey) => {
      // symbolOrdersList ==> [ [<order>, <order>], [<order>, <order>] ]
      // eslint-disable-next-line no-param-reassign
      hsh[analyticsKey] = computePLAndHM(symbolOrdersList);

      return hsh;
    }, {})
    .value();
};

export const getDayOfWeekData = (transactions, isEntryFlag) => {
  return getAnalyticsData(
    transactions,
    ({ transactionOpenTime, transactionCloseTime }) => {
      const time = isEntryFlag ? transactionOpenTime : transactionCloseTime;

      return moment(time).format('dddd');
    }
  );
};

export const getYearWiseData = (transactions) => {
  return getAnalyticsData(
    transactions,
    ({ transactionOpenTime }) => {
      return moment(transactionOpenTime).format('YYYY');
    }
  );
};

export const getCaseWiseData = (transactions) => {
  return getAnalyticsData(
    transactions,
    (symbolOrders) => {
      const { case_index: caseIndex } = _.find(
        symbolOrders.orders, (({ is_entry: isEntry }) => isEntry === true || isEntry === 'true')
      );
      return caseIndex;
    }
  );
};

export const getInstrumentData = (transactions) => {
  return getAnalyticsData(
    transactions,
    ({ instruments }) => { return instruments; }
  );
};

export const getRunProfits = (transactions) => {
  const runProfits = _.map(transactions, (transaction) => {
    const { cumulativeProfit } = transaction;
    if (!cumulativeProfit.timestamp) return null;

    const profit = cumulativeProfit.value;
    const timestamp = moment(cumulativeProfit.timestamp).local().toDate();
    return {
      timestamp, value: profit
    };
  });

  return _.without(runProfits, null);
};
