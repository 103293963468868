import React from 'react';
import PropTypes from 'prop-types';
import { runPropTypes } from 'common/propTypes';

const propTypes = {
  runIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  runs: PropTypes.arrayOf(runPropTypes).isRequired,
  checkedRunIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  rowFunc: PropTypes.func.isRequired
};
const defaultProps = {};

const SelectRuns = ({
  runIds, runs, checkedRunIds, handleCheckboxChange, rowFunc
}) => {
  return (
    <div className="card my-3 selected-run-data">
      <div className="card-header ">
        <div className="card-title">
          Selected Runs
        </div>
        <div>{rowFunc()}</div>
      </div>
      <div className="card-body">
        <div className="row mt-2">
          {_.map(runIds, (runId) => (
            <div className="col-lg-4 mb-2" key={runId}>
              <div className="small-info-card">
                <div className="card info">
                  <div className="info-wrapper">
                    <div className="form-check">
                      <div className="info-avatar-custom custom-checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={checkedRunIds.includes(runId)}
                          onChange={() => handleCheckboxChange(runId)}
                          id={`checkbox-${runId}`}
                        />
                      </div>

                      <label className="form-check-label" htmlFor={`checkbox-${runId}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="run-id-label">
                            {`${_.find(runs, { id: runId })?.name || ''}`}
                          </span>
                          <span className="run-id">
                            Id:
                            {' '}
                            {runId}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

SelectRuns.propTypes = propTypes;
SelectRuns.defaultProps = defaultProps;

export default SelectRuns;
