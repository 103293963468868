import { outputSchemaPropTypes, signalPropTypes } from 'common/propTypes';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ConditionalOperator from './ConditionalOperator/index';
import LeftOperand from './LeftOperand/index';
import Operator from './Operator/index';
import RightOperand from './RightOperand/index';

const propTypes = {
  signal: signalPropTypes.isRequired,
  shouldShowConditionalOperator: PropTypes.bool.isRequired,
  onUpdateSignal: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  signalType: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onDeleteSignal: PropTypes.func.isRequired,
  isShowDeleteBtn: PropTypes.bool.isRequired,
};

const defaultProps = {};

const Signal = ({
  signal,
  shouldShowConditionalOperator,
  onUpdateSignal,
  outputSchema,
  signalType,
  control,
  errors,
  onDeleteSignal,
  isShowDeleteBtn
}) => {
  const {
    leftOperand, operator, rightOperand, conditionOperator
  } = signal;

  const onUpdateConditionalOperator = useCallback((newOperator) => {
    onUpdateSignal({ ...signal, conditionOperator: newOperator });
  }, [signal, onUpdateSignal]);

  const onChangeLeftOperand = useCallback((newLeftOperand) => {
    onUpdateSignal({
      ...signal, leftOperand: newLeftOperand, operator: '', rightOperand: ''
    });
  }, [signal, onUpdateSignal]);

  return (
    <div className="signal-item">
      {shouldShowConditionalOperator && (
        <ConditionalOperator
          conditionOperator={conditionOperator}
          onUpdateConditionalOperator={onUpdateConditionalOperator}
        />
      )}
      <div className="operand-info-wrap">
        <LeftOperand
          leftOperand={leftOperand}
          signalType={signalType}
          outputSchema={outputSchema}
          onChangeLeftOperand={onChangeLeftOperand}
          control={control}
          errors={errors}
        />
        <Operator
          operator={operator}
          leftOperand={leftOperand}
          outputSchema={outputSchema}
          onChangeOperator={(newOperator) => onUpdateSignal({ ...signal, operator: newOperator })}
          control={control}
          errors={errors}
        />
        <RightOperand
          rightOperand={rightOperand}
          outputSchema={outputSchema}
          leftOperand={leftOperand}
          signalType={signalType}
          control={control}
          errors={errors}
          onChangeRightOperand={(newRightOperand) => {
            onUpdateSignal({ ...signal, rightOperand: newRightOperand });
          }}
        />
        {isShowDeleteBtn
        && <button type="button" className="btn btn-delete-outline" onClick={onDeleteSignal}><i /></button>}
      </div>
    </div>
  );
};

Signal.propTypes = propTypes;
Signal.defaultProps = defaultProps;

export default memo(Signal);
