import consumer from 'channels/consumer';

const subscribeChannel = (args) => {
  const { roomId, channelName, onDataReceive } = args;

  consumer.subscriptions.create(
    { channel: 'NotificationsChannel', room: roomId },
    {
      connected: () => { console.log('connected', channelName); },
      disconnected: () => { console.log('disconnected', channelName); },
      received: (data) => {
        onDataReceive(data, channelName);
      }
    }
  );
};

export default subscribeChannel;
