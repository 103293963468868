import axios from 'axios';

const sendOtp = (phoneNumber) => {
  return axios({
    method: 'post',
    url: '/send_otp',
    data: JSON.stringify({ phone_number: phoneNumber }),
    headers: { 'Content-Type': 'application/json' }
  });
};

export default sendOtp;
