import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setIsPlaceOptionsEntryBuyOrdersFirst } from 'modules/QuantBuilder/actions';
import { oneOfTypes, orderConfigsPropTypes } from 'common/propTypes';
import PlaceOptionsEntryBuyOrdersFirst from './PlaceOptionsEntryBuyOrdersFirst';

const propTypes = {
  dispatchIsPlaceOptionsEntryBuyOrdersFirst: PropTypes.func.isRequired,
  isPlaceOptionsEntryBuyOrdersFirst: oneOfTypes,
  orderConfigs: orderConfigsPropTypes
};
const defaultProps = {
  isPlaceOptionsEntryBuyOrdersFirst: null,
  orderConfigs: []
};

const OptionsEntryBuyOrders = ({
  dispatchIsPlaceOptionsEntryBuyOrdersFirst, isPlaceOptionsEntryBuyOrdersFirst, orderConfigs
}) => {
  useEffect(() => { // newly implemented, old runs don't have those config so i attached to quantConfig.
    if (isPlaceOptionsEntryBuyOrdersFirst === null) {
      dispatchIsPlaceOptionsEntryBuyOrdersFirst(true);
    }
  }, []);

  if (isPlaceOptionsEntryBuyOrdersFirst === null) return null;

  return (
    <PlaceOptionsEntryBuyOrdersFirst
      isPlaceOptionsEntryBuyOrderFirst={isPlaceOptionsEntryBuyOrdersFirst}
      handleChange={dispatchIsPlaceOptionsEntryBuyOrdersFirst}
      orderConfigs={orderConfigs}
    />
  );
};

OptionsEntryBuyOrders.propTypes = propTypes;
OptionsEntryBuyOrders.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isPlaceOptionsEntryBuyOrdersFirst:
    _.get(state, 'quantConfig.isPlaceOptionsEntryBuyOrdersFirst', null),
  orderConfigs: _.get(state, 'quantConfig.orderConfigs', [])
});

const mapDispatchToProps = (dispatch) => ({
  dispatchIsPlaceOptionsEntryBuyOrdersFirst:
    (newValue) => dispatch(setIsPlaceOptionsEntryBuyOrdersFirst(newValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionsEntryBuyOrders);
