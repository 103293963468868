import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const propTypes = {
  alert: PropTypes.string,
  notice: PropTypes.string
};

const defaultProps = {
  alert: null,
  notice: null
};

const FlashNotice = (props) => {
  const { alert, notice } = props;

  useEffect(() => {
    if (alert) reactToastify(alert, TOASTIFY_TYPES.ERROR);
  }, [alert]);

  useEffect(() => {
    if (notice) reactToastify(notice, TOASTIFY_TYPES.INFO);
  }, [notice]);

  return <ToastContainer />;
};

FlashNotice.propTypes = {};

export default FlashNotice;

FlashNotice.propTypes = propTypes;
FlashNotice.defaultProps = defaultProps;
