import { cyclicPlansPeriods } from 'ui/Plans/config';

export const planCycleLabel = (planCycle) => {
  const { monthly: { period: monthlyPeriod }, quarterly: { period: quarterPeriod } } = cyclicPlansPeriods;

  switch (planCycle) {
    case monthlyPeriod: return 'Month';
    case quarterPeriod: return 'Quarter';
    default: return 'Annual';
  }
};
