import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const IciciDirect = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              To start click on this link
              <b>
                <a
                  href="https://api.icicidirect.com/apiuser/home"
                  target="blank"
                  title="ICICI Direct"
                >
                  - https://api.icicidirect.com/apiuser/home
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">Use ICICI Direct credentials to login</p>
            <img
              src="/landing-page-img/brokers-img/icici-direct-1.png"
              className="w-100"
              alt="ICICI Direct Signup or Login"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Click on
              {oneIndentation}
              <b>Register an App</b>
            </p>
            <img
              src="/landing-page-img/brokers-img/icici-direct-2.png"
              className="w-100"
              alt="ICICI Direct Register an App"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Fill below details and submit.
            </p>

            <div className="stepper-desc">
              <p>
                <b>App Name:</b>
                {oneIndentation}
                Quantman
                <br />
                <b>Redirect URL:</b>
                {oneIndentation}
                https://quantman.in/auth/icici_direct/callback
              </p>
            </div>

            <img
              src="/landing-page-img/brokers-img/icici-direct-3.png"
              alt="ICICI Direct Submit Details"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>View Apps</b>
            </p>
            <img
              src="/landing-page-img/brokers-img/icici-direct-4.png"
              className="w-100"
              alt="ICICI Direct"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 6</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>Deactive</b>
              {oneIndentation}
              in App status.
            </p>
            <img
              src="/landing-page-img/brokers-img/icici-direct-5.png"
              className="w-100"
              alt="ICICI Direct"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 7</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>Yes</b>
              {oneIndentation}
              button in confirmation popup and refresh the page.
            </p>
            <img
              src="/landing-page-img/brokers-img/icici-direct-6.png"
              className="w-100"
              alt="ICICI Direct"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 8</h3>
            <p className="stepper-desc">
              Now, Please check your app status if its
              {oneIndentation}
              <b> Active </b>
              ,
              {oneIndentation}
              you can Copy and paste api details in appropriate fields below.
            </p>
            <img
              src="/landing-page-img/brokers-img/icici-direct-7.png"
              className="w-100"
              alt="ICICI Direct"
            />
          </div>
        </li>
      </ol>
    </div>
  </>
);

IciciDirect.propTypes = propTypes;
IciciDirect.defaultProps = defaultProps;

export default IciciDirect;
