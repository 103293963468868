import consumer from 'channels/consumer';

export const redisLiveDataSubscribedChannel = (args) => {
  const { channelName, onDataReceive } = args;
  return consumer.subscriptions.create(
    { channel: 'RedisLiveDataChannel', channelName },
    {
      connected: () => { console.log('connected', channelName); },
      disconnected: () => { console.log('disconnected', channelName); },
      received: (data) => { onDataReceive(data); }
    }
  );
};

export const redisLiveDataUnSubscribedChannel = (subscribe) => {
  return consumer.subscriptions.remove(subscribe);
};
