import React, { useEffect, useMemo } from 'react';
import { RUN_TYPES } from 'v2/common/constants/index';
import moment from 'moment';
import DateSelector from 'common/components/DateSelector';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { setRootValue } from 'v2/modules/withRunForm/redux/actions';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { TOASTIFY_TYPES, reactToastify } from 'common/utils/reactToastify';
import { getValidationErrorInstrumenWithStartDate } from './error';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

const propTypes = { isEndDate: PropTypes.bool };
const defaultProps = { isEndDate: false };

const HistoricDateRangePicker = ({ isEndDate }) => {
  const uniqueId = useMemo(
    () => _.uniqueId(`${isEndDate ? 'end' : 'start'}_date_error_message_`),
    [isEndDate]
  );
  const { errors, setError, clearErrors } = useFormContext();
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  const dispatch = useDispatch();
  const runType = useSelector((state) => getSelectorBasedStoreValue(state, 'runType'));
  const startDate = useSelector((state) => getSelectorBasedStoreValue(state, 'startDate'));
  const endDate = useSelector((state) => getSelectorBasedStoreValue(state, 'endDate'));
  const calendarStartDate = useSelector((state) => getSelectorBasedStoreValue(state, 'calendarStartDate'));
  const calendarEndDate = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'calendarEndDate')
  );
  const instrumentGroups = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.instrumentGroups')
  );
  const isHistoric = useMemo(() => runType === RUN_TYPES.historic, [runType]);
  const cases = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.cases'));
  const pipeConfigs = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.pipeConfigs')
  );

  useEffect(() => {
    if (!isHistoric) return clearErrors(uniqueId);
    if (isEndDate) return clearErrors(uniqueId);

    const validationObj = getValidationErrorInstrumenWithStartDate(
      instrumentGroups, startDate, cases, pipeConfigs
    );
    const { isValid, message } = validationObj;
    if (!isValid) {
      setError(uniqueId, { type: 'custom', message });
      reactToastify(message, TOASTIFY_TYPES.WARNING);
    } else {
      clearErrors(uniqueId);
    }
    return () => clearErrors(uniqueId);
  }, [
    startDate,
    instrumentGroups,
    isHistoric,
    uniqueId,
    isEndDate,
    clearErrors,
    setError,
    cases,
    pipeConfigs
  ]);

  if (!isHistoric) return null;

  const handleDateUpdate = (date, type) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    dispatch(setRootValue({ [type]: formattedDate }));
  };

  // Will revisit this props later
  const getDatePickerProps = (type) => {
    const toDateFormat = (newDate) => moment(newDate).toDate();
    const MaxStartDate = toDateFormat(moment(calendarEndDate));

    const onStartDateChange = (newStartDate) => {
      const isStartDateBeforeEndDate = moment(newStartDate).isSameOrBefore(endDate);
      if (!isStartDateBeforeEndDate) {
        handleDateUpdate(MaxStartDate, END_DATE);
        handleDateUpdate(MaxStartDate, START_DATE);
        return;
      }

      handleDateUpdate(newStartDate || MaxStartDate, START_DATE);
    };

    if (type === START_DATE) {
      return {
        type,
        selectedDate: toDateFormat(startDate),
        onChangeDate: (newStartDate) => { onStartDateChange(newStartDate); },
        minDate: toDateFormat(calendarStartDate),
        maxDate: MaxStartDate
      };
    }

    return {
      type,
      selectedDate: toDateFormat(endDate),
      onChangeDate: (newEndDate) => {
        if (moment(calendarEndDate).isBefore(newEndDate) || moment(newEndDate).isBefore(startDate)) {
          return handleDateUpdate(toDateFormat(moment(calendarEndDate)), END_DATE);
        }

        return handleDateUpdate(newEndDate || calendarEndDate, END_DATE);
      },
      minDate: toDateFormat(moment(startDate)),
      maxDate: toDateFormat(calendarEndDate),
    };
  };
  return (
    <div className="date-picker-input">
      <label className="label_style">
        {isEndDate ? 'End Date' : 'Start Date'}
      </label>
      <ErrorMesssage message={errorMessage}>
        <DateSelector {...getDatePickerProps(isEndDate ? END_DATE : START_DATE)} />
      </ErrorMesssage>
    </div>
  );
};

HistoricDateRangePicker.propTypes = propTypes;
HistoricDateRangePicker.defaultProps = defaultProps;

export default HistoricDateRangePicker;
