const configuration = {
  type: 'stochasticOscillator',
  defaults: {
    type: 'stochasticOscillator',
    name: 'stochasticOscillator',
    config: {
      noOfCandles: 14,
      candleInterval: '1 minutes',
      signalPeriod: 3,
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'stochasticOscillator'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    return _.map(['k', 'd'], (property) => {
      return {
        name: `${name}.${property}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
