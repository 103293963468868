const LOGIN_WATCH_INTERVAL = 1000;

const getWindowProperty = (window, propertyName) => {
  try {
    return window[propertyName];
  } catch (err) {
    console.log(`caught error${err}`);
  }

  return false;
};

const getLoginWindow = (URL) => {
  return window.open(
    URL,
    'newWindow',
    'height=770,width=1220,scrollbars=yes,status=yes,modal=yes,alwaysRaised=yes'
  );
};

export const initiateUserAuth = () => {
  const loginWindow = getLoginWindow('/popup_login?origin=pop_up_window');

  return new Promise((resolve, reject) => {
    const loginWatch = setInterval(async () => {
      if (getWindowProperty(loginWindow, 'closed') || getWindowProperty(loginWindow, 'complete')) {
        clearInterval(loginWatch);
        await fetch('/clear_auth_window_session');

        if (getWindowProperty(loginWindow, 'complete') && getWindowProperty(loginWindow, 'loginSuccess')) {
          loginWindow.close();
          resolve();
        }

        reject();
      }
    }, LOGIN_WATCH_INTERVAL);
  });
};

export const initiateBrokerCallbackLogin = (selectedBrokerHref) => {
  const loginWindow = getLoginWindow(selectedBrokerHref);

  return new Promise((resolve, reject) => {
    const loginWatch = setInterval(async () => {
      try {
        if (getWindowProperty(loginWindow, 'closed')
          || getWindowProperty(loginWindow, 'complete')
          || getWindowProperty(loginWindow, 'isUserSignedIn')
        ) {
          clearInterval(loginWatch);
          await fetch('/clear_auth_window_session');

          if (getWindowProperty(loginWindow, 'isUserSignedIn')
            && getWindowProperty(loginWindow, 'currentUserId')) {
            loginWindow.close();
            resolve(loginWindow.location);
          }
          reject();
        }
      } catch (error) {
        console.error(error);
        reject(error);
      }
    }, LOGIN_WATCH_INTERVAL);
  });
};
