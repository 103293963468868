import qs from 'qs';
import wrappedFetch from './base';

export const fetchLiveTicks = (tickParams) => {
  const queryParams = qs.stringify({ ...tickParams });
  const uri = `/admin/ticks/live/fetch?${queryParams}`;

  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};

export const fetchTradingSymbolTicks = (tickParams) => {
  const queryParams = qs.stringify({ ...tickParams });
  const uri = `/external/api/live_data?${queryParams}`;

  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};

export const fetchHistoricAdminTicks = (tickParams) => {
  const queryParams = qs.stringify({ ...tickParams });
  const uri = `/admin/ticks/historic/fetch?${queryParams}`;

  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};

export const fetchRocksDbData = (Params) => {
  const queryParams = qs.stringify({ ...Params });
  const uri = `/external/api/rocksdb_data?${queryParams}`;

  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};
