import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setQuantConfig } from 'modules/QuantBuilder/actions';
import { quantConfigPropTypes } from 'common/propTypes';
import {
  QUANT_CONFIG_FIELDS, overAllStopsConfig, STOPS_VALUE_TYPES
} from 'modules/QuantBuilder/config';
import HoverMessage from 'common/components/HoverMessage';
import StopsValueTypeToggleBtn from 'common/components/StopsValueTypeToggleBtn';
import {
  onRegexOnlyNumberAndFloat
} from 'common/stringUtils/toPositiveNumberString';

const propTypes = {
  dispatchQuantConfig: PropTypes.func.isRequired,
  quantConfig: quantConfigPropTypes.isRequired,
  type: PropTypes.string.isRequired,
};
const defaultProps = {};

const { trailingStopLoss, overallStopLoss, overallStopGain } = QUANT_CONFIG_FIELDS;
const DISPLAY_NAME = {
  [trailingStopLoss]: 'Trailing Stop Loss',
  [overallStopLoss]: 'Stop Loss',
  [overallStopGain]: 'Take Profit'
};

const StopLossOrStopGainInput = ({ dispatchQuantConfig, quantConfig, type }) => {
  const isCurrentValueInPrice = quantConfig[`${type}Price`] !== undefined;
  const { PERCENT, PRICE } = STOPS_VALUE_TYPES;
  const [valueType, setValueType] = useState(isCurrentValueInPrice ? PRICE : PERCENT);
  const newStopsType = valueType === PERCENT ? type : `${type}Price`;
  const currentConfigValue = _.get(quantConfig, [newStopsType], undefined);
  const emptyStopsValue = _.get(overAllStopsConfig, [type, valueType, 'config'], {});

  const onChange = (event, isString) => {
    dispatchQuantConfig({
      ...emptyStopsValue,
      [newStopsType]: onRegexOnlyNumberAndFloat(event.target.value, isString)
    });
  };

  const onStopsValueTypeChange = () => {
    setValueType(valueType === PERCENT ? PRICE : PERCENT);
    dispatchQuantConfig({ ...emptyStopsValue });
  };

  const isInputDisabled = type === trailingStopLoss && window.isDisabledTrailingStopLoss;

  const inputBox = (
    <input
      type="text"
      className="form-control form-control-sm"
      name={type}
      onBlur={(event) => { onChange(event, false); }}
      value={currentConfigValue === undefined ? '' : currentConfigValue.toString()}
      disabled={isInputDisabled}
      onChange={(event) => onChange(event, true)}
    />
  );

  return (
    <div className={`stop-loss-or-stop-gain-input ${type} align-items-center`}>
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <span className="input-group-text">{DISPLAY_NAME[type]}</span>
        </div>

        {type !== trailingStopLoss && (
          <StopsValueTypeToggleBtn valueType={valueType} onValueTypeChange={onStopsValueTypeChange} />
        )}

        {isInputDisabled ? (
          <HoverMessage title="Trailing Stop Loss" message="This feature is currently disabled">
            {inputBox}
          </HoverMessage>
        ) : inputBox}

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  quantConfig: _.get(state, 'quantConfig'),
});
const dispatchStateToProps = (dispatch) => ({
  dispatchQuantConfig: (newConfig) => dispatch(setQuantConfig(newConfig))
});

StopLossOrStopGainInput.propTypes = propTypes;
StopLossOrStopGainInput.defaultProps = defaultProps;
export default connect(mapStateToProps, dispatchStateToProps)(StopLossOrStopGainInput);
