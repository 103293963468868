import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

import { planPropTypes } from 'common/propTypes';
import { paymentInitialize } from 'common/razorpay/paymentInitialize';
import ModalComponent from 'common/components/ModalComponent';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import PlanTypeSelector from 'ui/Plans/components/common/PlanTypeSelector';
import PaymentCoupons from 'ui/Plans/components/common/PaymentCoupons';
import { cyclicPlansPeriods } from 'ui/Plans/config';
import { fetchCoupon, fetchPreApplyCoupon } from 'common/api/coupons';
import Credits from 'ui/Plans/components/common/Credits';
import { cyclicFilter, getCouponConfig, initCurrentNetPrice } from 'ui/Plans/helper';
import Plan from './Plan';
import CurrentAndUpgradePlanDetail from './CurrentAndUpgradePlanDetail';
import UpgradePaymentDetails from './UpgradePaymentDetails';

const propTypes = {
  currentPlan: planPropTypes.isRequired,
  upgradeConfigs: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const defaultProps = {};

const UpgradePlan = ({
  currentPlan,
  upgradeConfigs
}) => {
  const isEmptyUpgradeConfigs = _.isEmpty(upgradeConfigs);
  if (isEmptyUpgradeConfigs) return null;

  const [isShowModal, setIsShowModal] = useState(false);
  const [planCycleType, setPlanCycleType] = useState('yearly');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [isApplied, setIsApplied] = useState(false);
  const [message, setMessage] = useState('');

  const [creditBalance, setCreditBalance] = useState(_.get(window, 'creditBalance', 0));
  const [isCreditApplied, setIsCreditApplied] = useState(true);

  const modifiedUpgradeConfigs = _.map(upgradeConfigs, ({ targetPlan, upgradeDetails }) => {
    return { ...targetPlan, upgradeDetails, targetPlan };
  }); // default have.. but spreaded for common use coupon flow...

  const [planGroups, setPlanGroups] = useState(
    _.groupBy(initCurrentNetPrice(modifiedUpgradeConfigs), 'plan_name')
  );
  const currentCyclicPeriodPlans = _.flatten(_.map(planGroups, (planGroup) => {
    return _.filter(planGroup, (plan) => cyclicFilter(planCycleType, plan));
  }));
  const [currentUpgradePlan, setCurrentUpgradePlan] = useState(_.last(currentCyclicPeriodPlans));
  const plansGroupedByCyclicMonths = _.groupBy(_.flatMap(planGroups), 'cycle_duration_in_months');

  const onUpdateCouponPricesInPayments = (config) => {
    const {
      alteredCouponCode, alteredIsApplied, alteredPlanGroups,
      discountPercentage: couponDiscountPercentage
    } = getCouponConfig(config, [], planGroups);
    setCouponCode(alteredCouponCode);
    setIsApplied(alteredIsApplied);
    setPlanGroups(alteredPlanGroups);
    setDiscountPercentage(couponDiscountPercentage);
    const updatedCurrentUpgradedPlan = _.find(_.flattenDeep(_.values(alteredPlanGroups)), (
      { id }
    ) => currentUpgradePlan.id === id);
    setCurrentUpgradePlan(updatedCurrentUpgradedPlan || currentUpgradePlan);
  };

  useEffect(() => {
    fetchPreApplyCoupon().then((result) => {
      const { status, coupon } = result;
      if (status) {
        setMessage(`${coupon.discount_percentage}% discount applied`);
        onUpdateCouponPricesInPayments(coupon);
      }
    });
  }, []);

  const onUpgrade = () => {
    const args = {
      plan_id: currentUpgradePlan.targetPlan.id,
      purchase_type: 'payment',
      is_upgrade_plan: true,
      coupon_code: couponCode,
      credits_applied: isCreditApplied,
    };

    paymentInitialize(args, () => {
      reactToastify('Plan Upgrade Successful!', TOASTIFY_TYPES.SUCCESS);
      setTimeout(() => { window.location.reload(); }, 1.5 * 1000);
    });
    setIsShowModal(false);
  };

  const renderUpgradeToTargetPlan = (config, key) => {
    return (
      <Plan
        config={{ ...config }}
        currentUpgradePlan={currentUpgradePlan}
        key={key}
        onHandleConfig={() => { setCurrentUpgradePlan(config); }}
      />
    );
  };

  const onCouponUpdate = (isUpgradePlan = false) => {
    if (!isApplied) {
      fetchCoupon({ code: couponCode, is_upgrade_plan: isUpgradePlan })
        .then((result) => {
          const { status, coupon } = result;
          if (status) {
            setMessage(`${coupon.discount_percentage}% discount applied`);
            return onUpdateCouponPricesInPayments(coupon);
          }
          return setMessage('Invalid Coupon');
        });
    }
    setMessage('');
    onUpdateCouponPricesInPayments({});
    return null;
  };

  const cyclicPeriods = _.filter(cyclicPlansPeriods, ({ period }) => {
    return _.some(upgradeConfigs, ({ targetPlan }) => {
      return targetPlan.cycle_duration_in_months === period;
    });
  });

  const renderModal = () => {
    let modalProps = {
      onClose: () => setIsShowModal(false),
      title: 'Upgrade Plan',
      shouldShow: isShowModal,
      size: 'lg'
    };

    if (!_.isEmpty(currentUpgradePlan)) {
      modalProps = { ...modalProps, okBtnTitle: 'Upgrade', onSave: () => onUpgrade() };
    }

    return (
      <ModalComponent {...modalProps}>
        <div className="upgrade-container plans-page">
          <div className="text-center plan-type-selector">
            <PlanTypeSelector
              planCycleType={planCycleType}
              onHandlePlanType={setPlanCycleType}
              cyclicPeriods={cyclicPeriods}
              plansGroupedByCyclicMonths={plansGroupedByCyclicMonths}
            />
          </div>
          <div className="d-flex flex-wrap justify-content-center align-items-start gap-20 mb-3">
            <Credits
              creditBalance={creditBalance}
              isCreditApplied={isCreditApplied}
              onUpdateCredits={
                (newCreditBalance, isCreditUsed) => {
                  setCreditBalance(newCreditBalance);
                  setIsCreditApplied(isCreditUsed);
                }
              }
            />
            <PaymentCoupons
              couponCode={couponCode}
              onChangeCouponCode={setCouponCode}
              isApplied={isApplied}
              onCouponUpdate={() => onCouponUpdate(true)}
              message={message}
            />
          </div>
          <div className="eligible-upgrade">
            <h5 className="mb-4">Eligible Upgrade plans</h5>
            <div className="quota-verification row">
              {_.map(currentCyclicPeriodPlans, renderUpgradeToTargetPlan)}
            </div>
          </div>
          <div className="upgrade-plans-wrapper">
            <CurrentAndUpgradePlanDetail currentPlan={currentPlan} />
            <div>
              <div className="text-center"><img src="/change.png" alt="Change" /></div>
            </div>
            <CurrentAndUpgradePlanDetail currentPlan={{ ...currentUpgradePlan }} isUpgradePlan />
          </div>
          {!_.isEmpty(currentUpgradePlan)
            && (
            <UpgradePaymentDetails
              currentUpgradePlan={{ ...currentUpgradePlan }}
              discountPercentage={discountPercentage}
              credits={isCreditApplied ? creditBalance : 0}
            />
            )}
        </div>
      </ModalComponent>
    );
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => { setIsShowModal(true); }}
        className="hide-minimal-content upgrade-btn btn-info btn-pill btn btn-sm nav-item"
      >
        Upgrade plan
      </button>
      {renderModal()}
    </div>
  );
};

UpgradePlan.propTypes = propTypes;
UpgradePlan.defaultProps = defaultProps;

export default withErrorBoundary(UpgradePlan);
