export const RUN_STATUS_CODES = {
  ERRORED: 'errored',
  COMPLETED: 'completed',
  REMOVED: 'removed',
  KILLED: 'killed',
  STOPPED: 'stopped',
  STARTED: 'started',
  RESTARTING: 'restarting',
  QUEUED: 'queued',
  HOPPING: 'hopping',
  STARTING: 'starting',
  NOT_QUEUED: 'not_queued',
  SERVER_STOP: 'server_stop',
  PAUSED: 'paused'
};

export const RUN_FINISHED_STATUSES = [
  RUN_STATUS_CODES.ERRORED,
  RUN_STATUS_CODES.COMPLETED,
  RUN_STATUS_CODES.REMOVED,
  RUN_STATUS_CODES.KILLED,
  RUN_STATUS_CODES.STOPPED
];

export const RUN_IN_PROGRESS_STATUS = [
  RUN_STATUS_CODES.RESTARTING,
  RUN_STATUS_CODES.QUEUED,
  RUN_STATUS_CODES.STARTED,
  RUN_STATUS_CODES.HOPPING,
  RUN_STATUS_CODES.STARTING,
  RUN_STATUS_CODES.PAUSED,
  RUN_STATUS_CODES.SERVER_STOP
];

export const RUN_UNFINISHED_STATUSES = [...RUN_IN_PROGRESS_STATUS, RUN_STATUS_CODES.NOT_QUEUED];

export const runStatusColorCode = (status) => {
  switch (status) {
    case RUN_STATUS_CODES.ERRORED:
      return { statusText: _.startCase(status), statusClassName: 'bg-danger' };
    case RUN_STATUS_CODES.COMPLETED:
      return { statusText: _.startCase(status), statusClassName: 'bg-success' };
    case RUN_STATUS_CODES.REMOVED:
      return { statusText: _.startCase(status), statusClassName: 'bg-danger' };
    case RUN_STATUS_CODES.KILLED:
      return { statusText: _.startCase(status), statusClassName: 'bg-danger' };
    case RUN_STATUS_CODES.STOPPED:
      return { statusText: _.startCase(status), statusClassName: 'bg-success' };
    case RUN_STATUS_CODES.STARTED:
      return { statusText: _.startCase(status), statusClassName: 'bg-info' };
    case RUN_STATUS_CODES.RESTARTING:
      return { statusText: _.startCase(status), statusClassName: 'bg-danger' };
    case RUN_STATUS_CODES.QUEUED:
      return { statusText: _.startCase(status), statusClassName: 'bg-dark' };
    case RUN_STATUS_CODES.HOPPING:
      return { statusText: _.startCase(RUN_STATUS_CODES.STARTING), statusClassName: 'bg-dark' };
    case RUN_STATUS_CODES.STARTING:
      return { statusText: _.startCase(status), statusClassName: 'bg-dark' };
    case RUN_STATUS_CODES.NOT_QUEUED:
      return { statusText: _.startCase(status), statusClassName: 'bg-danger' };
    case RUN_STATUS_CODES.PAUSED:
      return { statusText: _.startCase(status), statusClassName: 'bg-dark' };
    case RUN_STATUS_CODES.SERVER_STOP:
      return { statusText: _.startCase(status), statusClassName: 'bg-dark' };
    default:
      return { statusText: status ? _.startCase(status) : '-', statusClassName: 'text-success' };
  }
};
