import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import ErrorMesssage from 'common/components/ErrorMesssage';

const propTypes = {
  emailId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onEmailIdSubmit: PropTypes.func.isRequired,
  onEmailIdChange: PropTypes.func.isRequired
};
const defaultProps = {};

const emailIdValidator = {
  required: 'please enter email id',
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: 'Enter valid email id'
  }
};

const EmailIdForm = (props) => {
  const { emailId, onEmailIdSubmit, onEmailIdChange } = props;
  const { register, errors, handleSubmit } = useForm();

  return (
    <div>
      <div className="text-center">
        <img alt="smartphone" src="/email_verification.png" />
        <h4 className="font-weight-bold mb-1">Enter Your Email Id</h4>
        <h6 className="text-muted">
          Email Id helps to deliver you the updates such as subscriptions, feature updates etc...
          We will not spam you
        </h6>
      </div>
      <form className="login-form text-center mt-5" onSubmit={handleSubmit(onEmailIdSubmit)}>
        <div className="form-group">
          <div className="inside-icon">
            <i className="icons material-icons-outlined">
              email
            </i>
            <input
              key="emailId"
              type="text"
              value={emailId}
              className="form-control form-control-lg pl-5"
              name="emailId"
              onChange={(event) => onEmailIdChange(event.target.value)}
              ref={register(emailIdValidator)}
              placeholder="e.g. email@example.com"
            />
          </div>
          <ErrorMesssage
            error={errors.emailId}
          />
        </div>
        <button
          className="btn btn-primary btn-pill text-uppercase"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

EmailIdForm.defaultProps = defaultProps;
EmailIdForm.propTypes = propTypes;

export default EmailIdForm;
