import React from 'react';
import PropTypes from 'prop-types';
import OrderStore from 'ui/run/RunShow/OrderStore/index';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { runPropTypes } from 'common/propTypes';
import RunDetail from 'ui/run/RunShow/components/RunDetail/index';
import ShowCase from './ShowCase';

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  run: runPropTypes.isRequired,
  runType: PropTypes.string.isRequired,
  runId: PropTypes.number.isRequired,
  jobIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isShare: PropTypes.bool,
  orderType: PropTypes.string,
  ordersAccessTokens: PropTypes.shape({})
};

const defaultProps = {
  type: '',
  name: '',
  startDate: '',
  endDate: '',
  description: '',
  isShare: false,
  orderType: '',
  ordersAccessTokens: {}
};

const Summary = ({
  type, name, startDate, endDate, description, runType, runId,
  jobIds, run, isShare, orderType,
  ordersAccessTokens
}) => {
  return (
    <OrderStore
      runIds={[runId]}
      jobIds={[jobIds]}
      runType={runType}
      showBy="all"
      ordersAccessTokens={ordersAccessTokens}
      orderType={orderType}
    >
      <>
        {isShare && <RunDetail run={run} />}
        <ShowCase
          type={type}
          name={name}
          startDate={startDate}
          endDate={endDate}
          description={description}
          runType={runType}
          run={run}
        />
      </>
    </OrderStore>
  );
};

Summary.defaultProps = defaultProps;
Summary.propTypes = propTypes;
export default withErrorBoundary(Summary);
