import React, { useRef, useEffect } from 'react';
import { validatorProptypes } from 'common/propTypes';

const propTypes = {
  validatorErrors: validatorProptypes.isRequired
};
const defaultProps = {};

function ValidationError({ validatorErrors }) {
  if (_.isEmpty(validatorErrors)) return null;
  const currentErrorRef = useRef(null);

  useEffect(() => {
    currentErrorRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  });

  const getPropertyName = (property) => {
    return _.chain(property)
      .split('.')
      .filter((propertyName) => !_.includes(propertyName, 'instance'))
      .split(/[[ \]]/)
      .join('  ')
      .value();
  };

  const errorMessages = _.map(validatorErrors, ({ property, message }, idx) => (
    <div key={idx}>
      {getPropertyName(property)}
      &nbsp;
      -
      &nbsp;
      {message}
    </div>
  ));

  return (
    <div ref={currentErrorRef} className="invalid-feedback d-block">
      <span>{errorMessages}</span>
    </div>
  );
}

export default ValidationError;

ValidationError.propTypes = propTypes;
ValidationError.defaultProps = defaultProps;
