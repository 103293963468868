import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';

import { instrumentGroupsPropTypes } from 'common/propTypes';
import { getOptionConfigSettings, STRIKE_KEYS } from 'modules/TransactionsBuilder/configs';

const propTypes = {
  strike: PropTypes.number,
  onStrikeChange: PropTypes.func.isRequired,
  optionType: PropTypes.string.isRequired,
  isHide: PropTypes.bool.isRequired,
  strikeBasedOn: PropTypes.string.isRequired,
  valuePath: PropTypes.string,
  isInvalidStrike: PropTypes.bool.isRequired,
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  transactionConfigIdx: PropTypes.number.isRequired,
};
const defaultProps = {
  strike: undefined,
  isHide: false,
  isInvalidStrike: false,
  valuePath: ''
};

const StrikeIndex = ({
  strike,
  onStrikeChange,
  optionType,
  isHide,
  strikeBasedOn,
  valuePath,
  isInvalidStrike,
  instrumentGroups,
  transactionConfigIdx
}) => {
  const [strikeBoxName] = useState(_.uniqueId('strikeName-'));
  const {
    errors, clearErrors, setError, control
  } = useFormContext();
  const options = _.get(
    getOptionConfigSettings(
      optionType, valuePath, instrumentGroups, transactionConfigIdx
    ), [strikeBasedOn, 'options'], []
  );
  const validator = {
    required: { value: true, message: 'value should be included' },
    validate: () => (isInvalidStrike ? 'similar option available' : null)
  };
  const formInputProps = _.includes([STRIKE_KEYS.premium, STRIKE_KEYS.strikePrice], strikeBasedOn)
    ? { type: 'number' }
    : { as: 'select' };

  const strikeIndexOptions = _.map(options, ({ value, displayName }) => (
    <option key={value} value={value}>
      {displayName}
    </option>
  ));

  useEffect(() => {
    if (!isInvalidStrike) {
      clearErrors(strikeBoxName);
      return;
    }

    setError(strikeBoxName, { type: 'manual', message: 'isInvalid' });
  }, [isInvalidStrike]);

  const onStrikeUpdate = (event, onChange) => {
    const parsedValue = event.target.value === ''
      ? undefined
      : parseFloat(event.target.value);

    onStrikeChange({ strike: parsedValue });
    onChange(parsedValue);
  };

  const strikeBoxStyle = classNames('', {
    'custom-select custom-select-sm': !!formInputProps.as,
    'strike-index': !!formInputProps.as,
    'border-danger': isInvalidStrike || !!errors[strikeBoxName]
  });

  const renderStrikeSelector = () => (
    <div className="strike-options">
      <Controller
        render={({ onChange, ref, name }) => (
          <Form.Control
            {...formInputProps}
            className={strikeBoxStyle}
            value={strike === undefined ? '' : strike && strike.toString()}
            ref={ref}
            size="sm"
            name={name}
            disabled={isHide}
            onChange={(event) => onStrikeUpdate(event, onChange)}
          >
            {formInputProps.as && strikeIndexOptions}
          </Form.Control>
        )}
        control={control}
        defaultValue={strike === undefined ? '' : strike && strike.toString()}
        name={strikeBoxName}
        rules={validator}
      />
    </div>
  );

  if (isHide) {
    return null;
  }

  return (
    renderStrikeSelector()
  );
};

StrikeIndex.propTypes = propTypes;
StrikeIndex.defaultProps = defaultProps;

export default StrikeIndex;
