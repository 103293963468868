import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import Modal from 'common/components/ModalComponent';
import {
  getUnreadMessages, setUserMessagesLocalStorage, onUserMessageClose
} from 'common/utils/userMessages';
import { initializeConfettiAnimationParty } from 'common/utils/initializeConfettiAnimationParty';
import FireworksBlast from './FireworksBlast';

const propTypes = {
  modalMessages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string),
    showTo: PropTypes.string,
    filterByDomain: PropTypes.string,
  })).isRequired
};
const defaultProps = {};

const UserMessageModal = ({ modalMessages }) => {
  const [unReadMessges, setUnreadMessages] = useState([]);
  const unReadMessage = _.head(unReadMessges) || {};
  const isShowFireBlast = _.get(unReadMessage, 'isShowFireBlast', false);

  useEffect(() => {
    setUserMessagesLocalStorage(modalMessages);
    setUnreadMessages(getUnreadMessages(modalMessages));
  }, []);

  useEffect(() => {
    const isShowConfetti = _.get(unReadMessage, 'isShowConfetti', false);
    if (isShowConfetti) {
      initializeConfettiAnimationParty().then(() => {
        setTimeout(() => {
          party.confetti(document.getElementById('confetti-paper'));
        }, 1500);
      }).catch(() => { });
    }
  }, [unReadMessage]);

  // show only one modal at the time.. no overlapping while mapping..
  if (_.isEmpty(unReadMessage)) return null;

  const {
    id, title, messages, filterByDomain, showTo
  } = unReadMessage;
  const size = _.get(unReadMessage, 'size', 'lg');

  const { currentUserBroker, currentDomain } = window;
  const isInvalidMessage = ((filterByDomain && filterByDomain !== currentDomain)
    || (showTo && showTo !== currentUserBroker));

  if (isInvalidMessage) return null;

  return (
    <>
      <Modal
        key={id}
        size={size}
        title={title}
        backdrop="static"
        // Release modal mandatory to click close.... backdrop close is stopped
        onClose={() => setUnreadMessages(onUserMessageClose(id, unReadMessges))}
      >
        <>
          {_.map(messages, (message, idx) => (
            <div key={idx}>
              {ReactHtmlParser(message)}
            </div>
          ))}
          <div id="confetti-paper" />
          {isShowFireBlast && (
            <FireworksBlast intensity={10} />
          )}
        </>
      </Modal>
      {isShowFireBlast && (
        <FireworksBlast />
      )}
    </>
  );
};

UserMessageModal.propTypes = propTypes;
UserMessageModal.defaultProps = defaultProps;

export default UserMessageModal;
