export const customValidatorConfig = (config) => {
  const maxPeriodAllowed = {
    '1 minutes': 7500,
    '3 minutes': 2500,
    '5 minutes': 1500,
    '15 minutes': 500,
    '30 minutes': 260,
    '1 hours': 140,
  };

  return {
    noOfCandles: {
      validate: (value) => {
        if (Number(value) === 0) return 'Please enter the valid value';
        if (Number(value) > maxPeriodAllowed[config.candleInterval]) {
          let error = `Max period allowed for ${config.candleInterval} `;
          error += `candle intervel is ${maxPeriodAllowed[config.candleInterval]}`;
          return error;
        }

        return null;
      }
    },
  };
};
