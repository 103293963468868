import React from 'react';

import { TABLE_HEADER } from 'ui/Home/config';

const propTypes = {};
const defaultProps = {};

const RunsTableHeader = () => {
  const headers = _.map(TABLE_HEADER, (header, idx) => {
    return (
      <th key={idx}>
        {header.title}
      </th>
    );
  });

  return (
    <thead>
      <tr className="sorting-head">
        {headers}
      </tr>
    </thead>
  );
};

RunsTableHeader.propTypes = propTypes;
RunsTableHeader.defaultProps = defaultProps;

export default RunsTableHeader;
