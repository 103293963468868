import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';
import { setLimitTransactions } from 'modules/QuantBuilder/actions';

const propTypes = {
  maxTransactionsPerDay: PropTypes.number,
  dispatchSetLimitTransactions: PropTypes.func.isRequired
};
const defaultProps = {
  maxTransactionsPerDay: 1
};

const LimitTransactions = ({
  maxTransactionsPerDay, dispatchSetLimitTransactions
}) => {
  const [limitTransactionName] = useState(_.uniqueId('limitTransactionName-'));
  const { errors, register } = useFormContext();

  const validatorConfig = {
    validate: (value) => {
      const typeConversionValue = Number(value);
      const isValidCondition = typeConversionValue >= 1 && typeConversionValue <= 10;
      if (!isValidCondition) return 'Must be between 1 to 10 ';

      return null;
    }
  };

  const validatorProps = { ref: register(validatorConfig) };

  return (
    <div className="limit-transactions row align-items-center">
      <div className="col-md-2">
        <label className="col-form-label-sm">Max Transactions Per Day</label>
      </div>
      <div className="col-md-10">
        <Form.Control
          {...validatorProps}
          type="number"
          min="1"
          size="sm"
          name={limitTransactionName}
          value={!maxTransactionsPerDay ? '' : maxTransactionsPerDay.toString()}
          onChange={(event) => {
            dispatchSetLimitTransactions(Number((event.target.value)));
          }}
        />
        <ErrorMesssage
          error={errors[`${limitTransactionName}`]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  maxTransactionsPerDay: _.get(state, 'quantConfig.maxTransactionsPerDay')
});
const mapDispatchToProps = (dispatch) => ({
  dispatchSetLimitTransactions: (newMaxTransactionsPerDay) => (
    dispatch(setLimitTransactions(newMaxTransactionsPerDay))
  ),
});

LimitTransactions.propTypes = propTypes;
LimitTransactions.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(LimitTransactions);
