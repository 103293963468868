import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TrailingStopLoss from 'v2/common/components/TrailingStopLoss/index';
import { STOPS_VALUE_TYPES } from 'v2/common/constants/index';
import { defaultLinearTrail } from 'v2/modules/withRunForm/configs/index';
import { setPartialBounds } from 'v2/modules/withRunForm/redux/actions';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';

const propTypes = {};
const defaultProps = {};

const OverallTransactionTSL = () => {
  const dispatch = useDispatch();
  const overallStopLoss = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.bounds.stopLoss')
  ) || {};

  const {
    type: slType = STOPS_VALUE_TYPES.Percent,
    value: slValue
  } = overallStopLoss;
  const trail = overallStopLoss.uiConfigs?.trail || defaultLinearTrail;

  const onTrailChange = (newTrail) => {
    dispatch(setPartialBounds({
      stopLoss: {
        ...overallStopLoss,
        uiConfigs: {
          ...overallStopLoss.uiConfigs,
          trail: newTrail
        }
      }
    }));
  };

  if (!slValue) {
    return null;
  }

  return (
    <TrailingStopLoss
      onUpdateTrailingConfigs={onTrailChange}
      trailingConfigs={trail}
      isStopLossPresent={!!slValue}
      stopLossType={slType}
      addTrailingLabel="Add Trailing SL"
    />
  );
};

OverallTransactionTSL.propTypes = propTypes;
OverallTransactionTSL.defaultProps = defaultProps;

export default OverallTransactionTSL;
