import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getIntentCategory,
  INTENT_CATEGORIES,
  INTENT_TYPES,
  SEGMENT_CONFIG
} from 'v2/common/constants/index';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import {
  isIndexInstrumentPresentInInstrumentGroup, isIndexInstrumentWithWeeklyExpiryOnly
} from '../../components/InstrumentDetails/indexInstrumentsChecker';
import {
  getDefaultBasketIntent,
  getDefaultIntent,
  getDefaultModifyOrderIntent
} from '../../configs/index';
import { IntentsContext } from '../../hoc/withIntentsContext';

const isBasketEnabled = _.get(window, 'SA_FEATURE_FLAGS.isBasketEnabled', false);

export const useIntent = (intent) => {
  const {
    onUpdateIntent,
    isPipeIntent,
  } = useContext(IntentsContext);

  const segment = intent?.instrument?.type;
  const intentId = intent?.identifier?.label;
  const instrumentIndex = intent?.instrument?.instrumentIndex;

  const instrumentGroups = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.instrumentGroups')
  );
  const maxInstrumentsInGroup = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.maxInstrumentsInGroup')
  );

  const isIndexInstrumentIntent = useMemo(() => {
    return isIndexInstrumentPresentInInstrumentGroup(instrumentGroups, instrumentIndex);
  }, [instrumentGroups, instrumentIndex]);

  const isIndexInstrumentWithWeeklyExpiry = useMemo(() => {
    return isIndexInstrumentWithWeeklyExpiryOnly(instrumentGroups, instrumentIndex);
  }, [instrumentGroups, instrumentIndex]);

  const createOnChangeHandler = useCallback((field) => (newValue) => {
    const newIntent = _.cloneDeep(intent);
    _.set(newIntent, field, newValue);
    onUpdateIntent(newIntent, intentId);
  }, [intent, intentId, onUpdateIntent]);

  const onChangeSegment = useCallback((newSegment) => {
    let newIntent = _.cloneDeep(intent);
    if (newSegment === INTENT_TYPES.OpenBasket) {
      return onUpdateIntent(
        { ...getDefaultBasketIntent(), ..._.pick(newIntent, ['identifier']) },
        intentId
      );
    }
    const isPreviousBasket = newIntent?.type === INTENT_TYPES.OpenBasket;
    newIntent = isPreviousBasket ? {
      ...getDefaultIntent(), ..._.pick(newIntent, ['identifier'])
    } : newIntent;

    newIntent.instrument.type = newSegment;
    if (_.includes([SEGMENT_CONFIG.future, SEGMENT_CONFIG.equity], newSegment)) {
      newIntent.instrument.expiry = { index: 1, cycle: 'monthly' };
    }

    return onUpdateIntent(newIntent, intentId);
  }, [intent, intentId, onUpdateIntent]);

  const segmentOptionConfigs = useMemo(() => {
    return _.chain(SEGMENT_CONFIG)
      .thru((config) => (isIndexInstrumentIntent ? _.omit(config, SEGMENT_CONFIG.equity) : config))
      .thru((config) => (
        isPipeIntent ? _.omit(config, [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]) : config
      ))
      .map((value, key) => ({ label: value, value: key }))
      .thru((configs) => {
        if (isPipeIntent || !isBasketEnabled) return configs;

        return [...configs, { label: 'Basket', value: INTENT_TYPES.OpenBasket }];
      })
      .value();
  }, [isIndexInstrumentIntent, isPipeIntent]);

  const isShowInstrumentIndex = useMemo(() => maxInstrumentsInGroup > 1, [maxInstrumentsInGroup]);

  const onChangeIntentType = useCallback((newIntentType, isIdle = false) => {
    const oldIntentCategory = getIntentCategory(intent?.type);
    const newIntentCategory = getIntentCategory(newIntentType);
    let newIntent;
    let removedOpenIntentIds = [];
    if (oldIntentCategory === newIntentCategory) {
      newIntent = _.cloneDeep(intent);
    } else if (newIntentCategory === INTENT_CATEGORIES.Other) {
      newIntent = getDefaultModifyOrderIntent();
      removedOpenIntentIds = [intentId];
    } else {
      newIntent = getDefaultIntent();
    }

    if (newIntentType === INTENT_TYPES.OpenLeg) {
      newIntent.isIdle = isIdle;
    }
    newIntent.type = newIntentType;
    newIntent.identifier = intent.identifier;
    return onUpdateIntent(newIntent, intentId, removedOpenIntentIds);
  }, [intent, intentId, onUpdateIntent]);

  const onUpdateQuickAdjustments = useCallback((newQuickAdjustments) => {
    const newIntent = _.cloneDeep(intent);
    newIntent.uiConfigs = newIntent.uiConfigs || {};
    newIntent.uiConfigs.quickAdjustments = newQuickAdjustments;

    return onUpdateIntent(newIntent, intentId);
  }, [intent, intentId, onUpdateIntent]);

  return {
    segment,
    onChangeSegment,
    isIndexInstrumentIntent,
    isShowInstrumentIndex,
    segmentOptionConfigs,
    intentId,
    onUpdateQuickAdjustments,
    onChangeIntentType,
    createOnChangeHandler,
    maxInstrumentsInGroup,
    isIndexInstrumentWithWeeklyExpiry,
    instrumentGroups,
  };
};
