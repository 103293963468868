import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import {
  OPTIONS_EXPIRY, WEEKLY_OPTION_AVAILABLE_FROM, TRANSACTION_MODES
} from 'modules/TransactionsBuilder/configs';
import HoverMessage from 'common/components/HoverMessage';

import {
  isRunEligibleForWeeklyExpiry, hasNiftyInInstrumentGroup, isEligibleForMonthly
} from 'common/utils/optionExpiryCycleHelper';
import { instrumentGroupsPropTypes } from 'common/propTypes';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const propTypes = {
  optionsExpiryCycle: PropTypes.string.isRequired,
  onOptionsExpiryCycleChange: PropTypes.func.isRequired,
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
};
const defaultProps = {};

const getDisabledExpiryCycleConfig = ({
  shouldDisableExpiryCycle,
  instrumentGroups,
  startDate,
  transactionConfig: { mode: { transaction: transactionMode } }
}) => {
  const { futureAndOption: FUTURE_AND_OPTION, futureConfig: FUTURE_CONFIG } = TRANSACTION_MODES;

  if (shouldDisableExpiryCycle) {
    return {
      title: 'Cannot Change Expiry Cycle',
      message: 'You have already selected expiry cycle. To change this Goto case 1 options form'
    };
  }

  if (hasNiftyInInstrumentGroup(instrumentGroups) && WEEKLY_OPTION_AVAILABLE_FROM.isAfter(startDate)) {
    return {
      title: 'No Weekly Options',
      message: `weekly  options is only available from
        ${WEEKLY_OPTION_AVAILABLE_FROM.format('MMM Do YYYY')}`
    };
  }

  if (!isRunEligibleForWeeklyExpiry(instrumentGroups, startDate)) {
    return {
      title: 'No Weekly Options',
      message: 'Weekly expiry options available only for Nifty, Finnifty and BankNifty Options'
    };
  }

  if (_.includes([FUTURE_AND_OPTION, FUTURE_CONFIG], transactionMode)) {
    return {
      title: 'No Weekly Options',
      message: 'Weekly expiry options is not available for "future" transactions'
    };
  }

  return {};
};

const getDisabledMonthlyExpiryCycleConfig = (hasEligibleForMonthly, isSensex) => {
  if (hasEligibleForMonthly) { return {}; }

  let message = 'Selected instrument is not Eligible for Monthly options';
  if (isSensex) {
    message = 'No volume in Sensex monthly options';
  }

  return {
    title: 'No Monthly Options',
    message
  };
};

const OptionsExpiryCycle = (props) => {
  const {
    optionsExpiryCycle, onOptionsExpiryCycleChange, instrumentGroups,
  } = props;
  const { weekly, monthly } = OPTIONS_EXPIRY;
  const disabledExpiryCycleConfig = getDisabledExpiryCycleConfig(props);

  const hasEligibleForMonthly = isEligibleForMonthly(instrumentGroups);

  const isSensex = _.includes(_.flatten(instrumentGroups), 'BFO:SENSEX');

  const disableMonthlyExpiryCycleConfig = getDisabledMonthlyExpiryCycleConfig(
    hasEligibleForMonthly, isSensex
  );

  const hoverMessageConfig = _.isEmpty(disabledExpiryCycleConfig)
    ? disableMonthlyExpiryCycleConfig
    : disabledExpiryCycleConfig;

  useEffect(() => {
    if (!hasEligibleForMonthly && optionsExpiryCycle === monthly) {
      reactToastify(
        'Selected instrument is not Eligible for Monthly Expiry', TOASTIFY_TYPES.WARNING
      );
      onOptionsExpiryCycleChange(weekly);
    }
  }, [hasEligibleForMonthly]);

  const renderWeeklyMonthlyBtn = (
    <ToggleButtonGroup
      name="optionExpiryCycle"
      bsPrefix="btn btn-sm btn-group btn-group-toggle"
      type="radio"
      onChange={() => onOptionsExpiryCycleChange(optionsExpiryCycle === weekly ? monthly : weekly)}
      value={optionsExpiryCycle}
    >
      <ToggleButton
        bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
        data-track-category="Option Expiry Cycle"
        data-track-action={weekly}
        data-track-label="Weekly"
        value={weekly}
        disabled={!_.isEmpty(disabledExpiryCycleConfig)}
      >
        {_.startCase(weekly)}
      </ToggleButton>

      <ToggleButton
        bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
        data-track-category="Option Expiry Cycle"
        data-track-action={monthly}
        data-track-label="Monthly"
        value={monthly}
        disabled={!hasEligibleForMonthly}
      >
        {_.startCase(monthly)}
      </ToggleButton>

    </ToggleButtonGroup>

  );

  return (
    <div className="options-expiry-cycle">
      <label className="col-form-label col-form-label-sm">Options expiry cycle</label>
      <div className="switch-btn options-cycle-btn">
        {_.isEmpty(hoverMessageConfig)
          ? renderWeeklyMonthlyBtn
          : (
            <HoverMessage {...hoverMessageConfig}>
              {renderWeeklyMonthlyBtn}
            </HoverMessage>
          )}
      </div>
    </div>
  );
};

OptionsExpiryCycle.propTypes = propTypes;
OptionsExpiryCycle.defaultProps = defaultProps;

export default OptionsExpiryCycle;
