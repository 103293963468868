import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import { validateBrokerUser, sendOtpToBrokerUser, validateOtpToBrokerUser } from 'common/api/user';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { reDirectPage } from '../config';
import UserInput from '../CommonComponents/UserInput';
import BrokerContent from '../BrokerContent/index';

const propTypes = {
  broker: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

const defaultProps = {};

const KotakNeo = ({ broker, onCloseModal }) => {
  const [configs, setConfigs] = useState({
    mobileNumber: '',
    password: '',
    otp: '',
    consumerKey: '',
    consumerSecret: ''
  });
  const {
    mobileNumber, password, otp, consumerKey, consumerSecret
  } = configs;
  const [stepOne, setStepOne] = useState(true);
  const [showApiDetailsField, setShowApiDetailsField] = useState(false);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);
  const [mandatoryDetails, setMandatoryDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [showGuideSteps, setShowGuideSteps] = useState(false);

  const onHandleConfig = (key, value) => {
    setConfigs({ ...configs, [key]: value });
    if (errorMessage) { setErrorMessage(''); }
  };

  const onValidateClientId = () => {
    // if (!mobileNumber) return setIsExistingClient(false);

    validateBrokerUser({ broker_user_id: mobileNumber, broker }).then(async (result) => {
      const { status, broker_api_credentials: brokerApiCredientals } = result;
      if (!status) {
        setShowApiDetailsField(true);
      } else {
        setConfigs({
          ...configs,
          consumerKey: brokerApiCredientals.api_key,
          consumerSecret: brokerApiCredientals.api_secret
        });
      }

      setStepOne(false);
    });

    return null;
  };

  const onSendOtp = (isResendOtp = false) => {
    sendOtpToBrokerUser({
      mobile_number: mobileNumber, password, otp, consumer_key: consumerKey, consumer_secret: consumerSecret
    }, isResendOtp, broker).then((res) => {
      const { status, message, extras } = res;
      if (!status) setErrorMessage(message);
      else {
        setIsSendOtpClicked(true);
        setMandatoryDetails(extras);
      }
    }).catch(() => { });
  };

  const onValidateOTP = () => {
    validateOtpToBrokerUser({
      consumer_key: consumerKey,
      consumer_secret: consumerSecret,
      mobile_number: mobileNumber,
      otp,
      extras: mandatoryDetails
    }, broker).then((res) => {
      const {
        status, token, client_id: clientId, message
      } = res;
      if (!status) { setErrorMessage(message); } else {
        reDirectPage({
          client_id: clientId,
          token
        }, broker, true);
      }
    }).catch(() => { });
  };

  const onSaveAction = () => {
    if (stepOne) {
      return onValidateClientId();
    }
    if (!isSendOtpClicked) {
      return onSendOtp();
    }
    if (isSendOtpClicked) {
      return onValidateOTP();
    }
    return () => {};
  };

  const btnLabel = () => {
    if (stepOne) {
      return 'Validate';
    }
    if (!isSendOtpClicked) {
      return 'Send OTP';
    }

    if (isSendOtpClicked) {
      return 'Login';
    }
    return () => {};
  };

  const modalProps = {
    onClose: onCloseModal,
    shouldShow: true,
    size: 'sm',
    onSaveId: `btn-${broker}`,
    onSave: () => onSaveAction(),
    okBtnTitle: btnLabel(),
    isOkBtnDisabled: false,
    title: 'Kotak Neo'
  };

  const renderMobileNumberField = () => {
    return (
      <div className="mt-2">
        <label>Registered Mobile Number</label>
        <UserInput
          id={`${broker}-mobile-number`}
          type="number"
          value={mobileNumber}
          isDisabled={!stepOne}
          onHandleChange={(value) => { onHandleConfig('mobileNumber', value); }}
        />
      </div>
    );
  };

  const renderApiCredentials = () => {
    return (
      <>
        <div className="mt-2">
          <label>Consumer Key</label>
          <UserInput
            id={`${broker}-consumer-key`}
            value={consumerKey}
            isDisabled={isSendOtpClicked}
            onHandleChange={(value) => { onHandleConfig('consumerKey', value); }}
          />
        </div>
        <div className="mt-2">
          <label>Consumer Secret</label>
          <UserInput
            id={`${broker}-consumer-secret`}
            value={consumerSecret}
            isDisabled={isSendOtpClicked}
            onHandleChange={(value) => { onHandleConfig('consumerSecret', value); }}
          />
        </div>
        <div className="text-right mb-2">
          <button className="btn btn-link btn-sm" type="button" onClick={() => { setShowGuideSteps(true); }}>
            Steps to get consumer API details?
          </button>
        </div>
      </>
    );
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="mb-3">
        {renderMobileNumberField()}
        {showApiDetailsField && renderApiCredentials()}
        {!stepOne && !showApiDetailsField && (
          <div className="text-right mb-2">
            <button
              className="btn btn-link btn-sm"
              type="button"
              onClick={() => {
                onHandleConfig('consumerKey', '');
                onHandleConfig('consumerSecret', '');
                setShowApiDetailsField(true);
              }}
            >
              Change in consumer API details?
            </button>
          </div>
        )}

        {!stepOne && (
          <div>
            <label className="mt-2">Password</label>
            <UserInput
              id={`${broker}-password`}
              value={password}
              isDisabled={isSendOtpClicked}
              type="password"
              onHandleChange={(value) => { onHandleConfig('password', value); }}
            />
          </div>
        )}

        {isSendOtpClicked && (
          <div className="mt-2">
            <label>OTP</label>
            <UserInput
              id={`${broker}-otp`}
              value={otp}
              onHandleChange={(value) => { onHandleConfig('otp', value); }}
            />
          </div>
        )}

        {showGuideSteps
          && (
          <>
            <ModalComponent
              onClose={() => setShowGuideSteps(false)}
              title="Steps to generate API"
              size="lg"
              okBtnTitle=""
            >
              <BrokerContent broker={broker} />
            </ModalComponent>
          </>
          )}

        <ErrorMesssage error={{ message: _.capitalize(_.startCase(errorMessage)) }} />
      </div>
    </ModalComponent>
  );
};

KotakNeo.propTypes = propTypes;
KotakNeo.defaultProps = defaultProps;

export default KotakNeo;
