import React from 'react';
import { ORDER_CONFIRMATION_STATUSES, NOTIFICATION_EVENTS } from 'common/constants';
import notificationPropType from './propTypes/notificationPropType';

const propTypes = {
  notification: notificationPropType.isRequired
};
const defaultProps = {};

const { ORDER_CONFIRMATION } = NOTIFICATION_EVENTS;
const { EVENTS } = ORDER_CONFIRMATION;
const {
  ENTRY_SIGNAL_CONFIRMATION,
  EXIT_SIGNAL_CONFIRMATION,
  ENTRY_ORDERS_FAILED,
  ENTRY_ORDERS_CANCEL_FAILED,
  ENTRY_ORDERS_MODIFY_FAILED,
  EXIT_ORDERS_FAILED,
  EXIT_ORDERS_MODIFY_FAILED,
  STOPLOSS_ORDERS_FAILED,
  STOPLOSS_ORDERS_CANCEL_FAILED
} = EVENTS;

const OrderConfirmationDetailedMessage = ({ notification }) => {
  const { details, event } = notification;
  const { run_name: fileName, folder_name: folderName, status: confirmationStatus } = details;
  const oneIndentation = ' ';

  const signalType = () => {
    switch (event) {
      case ENTRY_SIGNAL_CONFIRMATION:
        return 'Entry Signal';
      case EXIT_SIGNAL_CONFIRMATION:
        return 'Exit Signal';
      case ENTRY_ORDERS_FAILED:
        return 'Entry Order(s)';
      case ENTRY_ORDERS_CANCEL_FAILED:
        return 'Unable to cancel entry orders(s)';
      case ENTRY_ORDERS_MODIFY_FAILED:
        return 'Unable to modify entry orders(s)';
      case EXIT_ORDERS_FAILED:
        return 'Exit Order(s)';
      case EXIT_ORDERS_MODIFY_FAILED:
        return 'Unable to modify exit orders(s)';
      case STOPLOSS_ORDERS_FAILED:
        return 'Unable to cancel stoploss orders(s)';
      case STOPLOSS_ORDERS_CANCEL_FAILED:
        return 'Unable to cancel stoploss orders(s)';
      default:
        return '';
    }
  };

  const orderConfirmationMessage = () => {
    if (confirmationStatus === ORDER_CONFIRMATION_STATUSES.NEW) return 'Please Confirm';
    if (confirmationStatus) return `and you have ${confirmationStatus} it`;
    return 'and you have ignored it';
  };

  const renderMessage = () => {
    if (!_.includes(_.values(EVENTS), event)) return null;

    if (_.includes([ENTRY_SIGNAL_CONFIRMATION, EXIT_SIGNAL_CONFIRMATION], event)) {
      return (
        <span>
          <span className="noti-status">
            {signalType()}
            {oneIndentation}
          </span>
          {oneIndentation}
          received from
          {oneIndentation}
          <span className="noti-file">
            {`(${fileName})`}
            {oneIndentation}
          </span>
          in
          {oneIndentation}
          <span className="noti-file">
            {`(${folderName})`}
            ,
            {oneIndentation}
          </span>
          {orderConfirmationMessage()}
        </span>
      );
    }

    return (
      <span>
        <span className="noti-status">
          {signalType()}
          {oneIndentation}
        </span>
        {oneIndentation}
        {_.includes([ENTRY_ORDERS_FAILED, EXIT_ORDERS_FAILED], event) && 'failed'}
        ,
        {oneIndentation}
        <span className="noti-file">
          {`(${fileName})`}
          {oneIndentation}
        </span>
        in
        {oneIndentation}
        <span className="noti-file">
          {`(${folderName})`}
        </span>
      </span>
    );
  };

  return renderMessage();
};

OrderConfirmationDetailedMessage.defaultProps = defaultProps;
OrderConfirmationDetailedMessage.propTypes = propTypes;

export default OrderConfirmationDetailedMessage;
