import React from 'react';
import PropTypes from 'prop-types';
import { orderConfigsPropTypes } from 'common/propTypes';
import SquareOffTransactionIfEntryFailedSelector from './SquareOffTransactionIfEntryFailedSelector';
import { isTransactionHaveMoreThanOneLeg } from '../ProfitAndOrdersComponents/helper';

const propTypes = {
  isSquareOffTransactionIfEntryFailed: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  orderConfigs: orderConfigsPropTypes
};
const defaultProps = {
  orderConfigs: []
};

const SquareOffTransactionIfEntryFailed = ({
  isSquareOffTransactionIfEntryFailed, handleChange, orderConfigs
}) => {
  if (!isTransactionHaveMoreThanOneLeg(orderConfigs)) { return null; }

  return (
    <div className="row mb-1">
      <label className="col-md-3 place-orders-label">
        Square Off Transaction if any entry is failed
      </label>
      <div className="col-md-9 place-options">
        <SquareOffTransactionIfEntryFailedSelector
          optionType={isSquareOffTransactionIfEntryFailed}
          onOptionTypeChange={(value) => {
            handleChange(value);
          }}
        />
      </div>
    </div>
  );
};

SquareOffTransactionIfEntryFailed.propTypes = propTypes;
SquareOffTransactionIfEntryFailed.defaultProps = defaultProps;

export default SquareOffTransactionIfEntryFailed;
