import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { CHART_PATTERNS } from 'modules/QuantBuilder/config';
import { setChartConfig } from 'modules/QuantBuilder/actions';
import { chartConfigPropTypes } from 'common/propTypes';

const propTypes = {
  chartConfig: chartConfigPropTypes.isRequired,
  dispatchSetChartPattern: PropTypes.func.isRequired,
};
const defaultProps = {};
const I18N_SCOPE = { scope: 'runs.form' };

const ChartPattern = (props) => {
  const {
    dispatchSetChartPattern, chartConfig, chartConfig: { boxUnitSize, type }
  } = props;

  const handleChartConfigChange = (newChartConfig) => {
    dispatchSetChartPattern({
      ...chartConfig,
      ...newChartConfig
    });
  };

  const renderBoxConfig = () => {
    const onBoxValueChange = (event) => handleChartConfigChange({
      [event.target.name]: event.target.value === ''
        ? undefined
        : parseFloat(event.target.value)
    });

    return (
      <div className="d-inline-block">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <span className="input-group-text">
              {I18n.t('box_unit_size', I18N_SCOPE)}
            </span>
          </div>
          <input
            className="form-control"
            name="boxUnitSize"
            type="number"
            value={boxUnitSize === undefined ? '' : boxUnitSize}
            onChange={onBoxValueChange}
          />
        </div>
      </div>
    );
  };

  const chartTypeOptions = _.map(_.omit(CHART_PATTERNS, [CHART_PATTERNS.renko]), (chartPattern, idx) => (
    <ToggleButton
      bsPrefix="btn btn-sm btn-outline-primary text-capitalize btn-lightness track"
      key={idx}
      data-track-category="Chart Pattern"
      data-track-action={chartPattern}
      data-track-label="Chart Pattern Drop-Down"
      value={chartPattern}
    >
      {chartPattern}
    </ToggleButton>
  ));

  return (
    <div className="chart-pattern">
      <ToggleButtonGroup
        className="mr-2"
        size="sm"
        type="radio"
        bsPrefix="btn-group btn-group-toggle"
        name="chartPattern"
        value={type}
        defaultValue={type}
        onChange={(newChartPattern) => handleChartConfigChange({ type: newChartPattern })}
      >
        {chartTypeOptions}
      </ToggleButtonGroup>
      {(type === CHART_PATTERNS.renko) && (renderBoxConfig())}
    </div>

  );
};

ChartPattern.propTypes = propTypes;
ChartPattern.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  chartConfig: _.get(state, 'quantConfig.chartConfig'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetChartPattern: (newChartPattern) => dispatch(setChartConfig(newChartPattern)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartPattern);
