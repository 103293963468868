import React from 'react';
import PropTypes from 'prop-types';

import { getUrlParams } from 'common/utils/urlUtils';
import Transaction from './Transaction';

const propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  runId: PropTypes.number.isRequired,
  isLive: PropTypes.bool.isRequired,
  orderType: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  runStatus: PropTypes.string
};
const defaultProps = {
  runStatus: '',
};

const TransactionsList = (props) => {
  const {
    transactions, runId, isLive, orderType, granularity, runStatus
  } = props;
  const urlParams = getUrlParams();
  const isNumerousRuns = _.get(urlParams, 'runids', '');

  const renderTransaction = (transaction, idx) => {
    if (_.isEmpty(transaction)) return null;

    return (
      <Transaction
        key={idx}
        isLive={isLive}
        cumulativeProfit={_.get(transaction, 'cumulativeProfit.value', 0)}
        transaction={transaction}
        runId={runId}
        isNumerousRuns={!!isNumerousRuns}
        orderType={orderType}
        granularity={granularity}
        runStatus={runStatus}
      />
    );
  };

  const TransactionHeader = () => (
    <thead>
      <tr>
        <th align="center">Transaction</th>
        <th align="center">Instrument</th>
        <th align="center" />
        <th align="center" className="entry-wrapper pl-2" colSpan="3">Entry</th>
        <th align="center" className="exit-wrapper pl-2" colSpan="3">Exit</th>
        <th align="center" className="text-right">Profit</th>
        <th align="center" className="text-right">Cumulative Profit</th>
        <th align="center" width="50px">Chart</th>
      </tr>
    </thead>
  );

  return (
    <div className="transactions-list">
      <div className="txn-order-details-table table-responsive">
        <table className="table table-sm mb-0">
          <TransactionHeader />
          {_.map(transactions, renderTransaction)}
        </table>
      </div>
    </div>
  );
};

TransactionsList.propTypes = propTypes;
TransactionsList.defaultProps = defaultProps;

export default TransactionsList;
