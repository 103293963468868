import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import MoneyDisplay from 'common/components/MoneyDisplay';
import Multiplier from 'ui/Strategies/components/Strategy/Multiplier';
import ErrorMesssage from 'common/components/ErrorMesssage';

const MULTIPLIER_LIMIT = 10;

const propTypes = {
  price: PropTypes.number.isRequired,
  cycleMonth: PropTypes.number.isRequired,
  historicLimit: PropTypes.number.isRequired,
  liveLimit: PropTypes.number.isRequired,
  multiplier: PropTypes.number.isRequired,
  onChangeMultiplier: PropTypes.func.isRequired,
  onCreatePayment: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const MultiplierModal = ({
  multiplier,
  onChangeMultiplier,
  onCreatePayment,
  onCloseModal,
  price,
  historicLimit,
  liveLimit,
  cycleMonth,
}) => {
  const isInValidMultiplier = multiplier > MULTIPLIER_LIMIT;
  const isLiveCreditsAvailable = liveLimit > 0;
  const creditsConsumptionGuide = `1 credit = 1 Instrument x 1 Case ${historicLimit > 0 ? 'x 1 Year' : ''}`;

  return (
    <Modal
      onSave={onCreatePayment}
      onClose={onCloseModal}
      size="md"
      okBtnTitle="Purchase Addon"
      isOkBtnDisabled={isInValidMultiplier}
      btnClassName="btn-success btn-sm"
    >
      <div className="text-center mb-2">
        <div className="alert-icons icon-info alert-info" />
        <h4 className="font-weight-medium mb-3">Confirmation</h4>
        <p>Number of AddOns</p>
        <Multiplier multiplier={multiplier} onChangeMultiplier={onChangeMultiplier} />

        {liveLimit > 0 && (
          <div className="mt-2">
            Validity:
            {' '}
            <b>
              {cycleMonth}
              {' '}
              month
            </b>
          </div>
        )}
        {historicLimit > 0 && (
          <div className="mt-2">
            Backtest credits:
            {' '}
            <b>{`${historicLimit * multiplier} (${historicLimit} * ${multiplier})`}</b>
          </div>
        )}
        {isLiveCreditsAvailable && (
          <div className="mt-2">
            Live credits:
            {' '}
            <b>{`${liveLimit * multiplier} (${liveLimit} * ${multiplier})`}</b>
          </div>
        )}
        <span className="tx-10 text-muted">
          (
          {creditsConsumptionGuide}
          )
        </span>
        <div className="my-2">
          Total Addon Cost:
          <b>
            <MoneyDisplay>{price * multiplier}</MoneyDisplay>
            {' '}
            (
            {price}
            {' '}
            *
            {multiplier}
            )
          </b>
        </div>
        {isInValidMultiplier && (
          <ErrorMesssage
            error={{ message: '*Limit exceeded, you can multiply addon credits up to 10' }}
          />
        )}
        {isLiveCreditsAvailable && (
          <div className="tx-12 mt-2">
            <b>Note:</b>
            {' '}
            To consume your addon credits, you need an active plan.
          </div>
        )}
      </div>
    </Modal>
  );
};

MultiplierModal.propTypes = propTypes;
export default MultiplierModal;
