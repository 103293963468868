import wrappedFetch from './base';

export const refreshCreditBalance = () => {
  const uri = '/credits/refresh';

  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};
