import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';

const propTypes = {
  stopAndLimitPriceDifferenceInPercentage: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number]
  ).isRequired,
  onChangeStopAndLimitPriceDifferenceInPercentage: PropTypes.func.isRequired,
};
const defaultProps = {};

const StopLimitPriceDifference = ({
  stopAndLimitPriceDifferenceInPercentage,
  onChangeStopAndLimitPriceDifferenceInPercentage
}) => {
  const [stopLossLimitPrice, setStopLossLimitPrice] = useState(
    stopAndLimitPriceDifferenceInPercentage || 0
  );

  useEffect(() => {
    setStopLossLimitPrice(stopAndLimitPriceDifferenceInPercentage);
  }, [stopAndLimitPriceDifferenceInPercentage]);

  const onUpdateState = () => {
    onChangeStopAndLimitPriceDifferenceInPercentage(stopLossLimitPrice);
  };

  return (
    <>
      <div className="row align-items-center mb-4">
        <div className="col-md-2">
          <label className="label_style">
            SL-Limit Price Difference
          </label>
        </div>
        <div className="col-md-10 limit-input-field">
          <div className="input-group input-group-sm">
            <input
              type="text"
              className="form-control form-control-sm"
              value={stopLossLimitPrice ? stopLossLimitPrice.toString() : ''}
              onChange={(event) => setStopLossLimitPrice(onRegexOnlyNumberAndFloat(event.target.value) || '')}
              onBlur={onUpdateState}
            />
            <div className="input-group-append">
              <span className="input-group-text">%</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

StopLimitPriceDifference.propTypes = propTypes;
StopLimitPriceDifference.defaultProps = defaultProps;

export default StopLimitPriceDifference;
