import moment from 'moment';

const getDifferanceUnit = (differenceInDays) => {
  if (differenceInDays < 30) return 'days';
  if (_.inRange(differenceInDays, 30, 365)) return 'months';

  return 'years';
};

export const getDateDifferance = (startDate, endDate) => {
  const startDateObj = moment(startDate);
  const endDateObj = moment(endDate);

  const differenceInDays = endDateObj.diff(startDateObj, 'days');
  const differenceUnit = getDifferanceUnit(differenceInDays);
  const dateDifference = endDateObj.diff(startDateObj, differenceUnit);

  return `${differenceUnit !== 'days' ? 'over' : ''} ${dateDifference} ${differenceUnit}`;
};
