import React from 'react';
import PropTypes from 'prop-types';
import { planPropTypes } from 'common/propTypes';
import usePlans from './usePlans';
import Web from './components/Web/index';
import Mobile from './components/Mobile/index';

const propTypes = {
  freePlanDetails: planPropTypes,
  brokerId: PropTypes.string,
  activePlan: PropTypes.shape({}),
  freeTrailDays: PropTypes.shape({}),
  activeSubscription: PropTypes.shape({})
};
const defaultProps = {
  freePlanDetails: {},
  brokerId: null,
  activePlan: {},
  freeTrailDays: {},
  activeSubscription: {}
};

const Plans = ({
  freePlanDetails, brokerId, activePlan, freeTrailDays, activeSubscription
}) => {
  const configs = usePlans({ activePlan, activeSubscription });

  if (_.isEmpty(configs?.planGroups) || !_.keys(freePlanDetails).length) return null;

  const propsArgs = {
    ...configs, freePlanDetails, freeTrailDays, brokerId
  };

  return (
    <>
      <div className="plans-page container plan-page-web">
        <Web {...propsArgs} />
      </div>
      <div className="plans-page-mobile container mt-2">
        <Mobile {...propsArgs} />
      </div>
    </>

  );
};

Plans.propTypes = propTypes;
Plans.defaultProps = defaultProps;
export default Plans;
