const buildClosePriceLabel = (maxInstrumentsInGroup, instrumentIndex) => {
  if (maxInstrumentsInGroup === 1) return 'Last Traded Price';

  return `item${instrumentIndex + 1} Last Traded Price`;
};

const buildPriceLabel = (maxInstrumentsInGroup, instrumentIndex) => {
  if (maxInstrumentsInGroup === 1) return 'Price';

  return `item${instrumentIndex + 1} Price`;
};

const buildDefaultLabel = (maxInstrumentsInGroup, instrumentIndex, labelName) => {
  if (maxInstrumentsInGroup === 1) return `${_.startCase(labelName)}`;

  return `item${instrumentIndex + 1} ${_.startCase(labelName)}`;
};

export const buidInstrumentOutputSchema = (maxInstrumentsInGroup) => {
  const schemas = _.flatMap(_.times(maxInstrumentsInGroup), (instrumentIndex) => {
    const equitySchema = _.flatMap(['equity'], (key) => {
      return [
        {
          name: `item${instrumentIndex}.${key}`,
          type: 'object',
          label: buildPriceLabel(maxInstrumentsInGroup, instrumentIndex),
          category: `${key}`
        },
        {
          name: `item${instrumentIndex}.${key}.close`,
          type: 'number',
          label: buildClosePriceLabel(maxInstrumentsInGroup, instrumentIndex),
          category: `${key}`
        },
        {
          name: `item${instrumentIndex}.${key}.volume`,
          type: 'number',
          label: buildDefaultLabel(maxInstrumentsInGroup, instrumentIndex, 'Volume'),
          category: `${key}`
        }
      ];
    });

    const monthPriceSchema = _.flatMap(['month1Price'], (key) => {
      return [
        {
          name: `item${instrumentIndex}.${key}`,
          type: 'object',
          category: 'future',
          label: buildPriceLabel(maxInstrumentsInGroup, instrumentIndex)
        },
        {
          name: `item${instrumentIndex}.${key}.close`,
          type: 'number',
          category: 'future',
          label: buildClosePriceLabel(maxInstrumentsInGroup, instrumentIndex)
        },
        {
          name: `item${instrumentIndex}.${key}.volume`,
          type: 'number',
          category: 'future',
          label: buildDefaultLabel(maxInstrumentsInGroup, instrumentIndex, 'Volume')
        },
        {
          name: `item${instrumentIndex}.${key}.openInterest`,
          type: 'number',
          category: 'future',
          label: buildDefaultLabel(maxInstrumentsInGroup, instrumentIndex, 'Open Interest')
        }
      ];
    });

    return [...monthPriceSchema, ...equitySchema];
  });

  schemas.push({
    name: 'INDIAVIX',
    type: 'object',
    label: 'INDIAVIX',
    category: 'indiaVix'
  });

  return schemas;
};
