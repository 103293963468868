import PropTypes from 'prop-types';
import { folderPropTypes, quantConfigPropTypes } from 'common/propTypes';

export const runFormPropTypes = {
  endDate: PropTypes.string.isRequired,
  folder: folderPropTypes,
  isClonedQuant: PropTypes.bool.isRequired,
  isHistoricRunDisabled: PropTypes.bool.isRequired,
  isLiveRunDisabled: PropTypes.bool.isRequired,
  maxEndDate: PropTypes.string,
  minStartDate: PropTypes.string.isRequired,
  orderType: PropTypes.string,
  prebuiltId: PropTypes.number,
  quantConfig: quantConfigPropTypes,
  runName: PropTypes.string,
  runType: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  isEditQuant: PropTypes.bool,
  strategyId: PropTypes.number
};

export const defaultRunFormProps = {
  folder: null,
  maxEndDate: null,
  quantConfig: null,
  runType: 'historic',
  orderType: 'paper',
  prebuiltId: null,
  runName: '',
  isEditQuant: false,
  strategyId: null
};
