import React from 'react';
import PropTypes from 'prop-types';

import { signalConfigPropTypes } from 'common/propTypes';

const propTypes = {
  signalConfigs: PropTypes.arrayOf(signalConfigPropTypes).isRequired,
  indexToRemove: PropTypes.number.isRequired,
  onRemoveSignalConfig: PropTypes.func.isRequired
};
const defaultProps = {};

const RemoveSignalConfig = ({ signalConfigs, indexToRemove, onRemoveSignalConfig }) => {
  const onRemoveConditionClick = () => {
    const updatedSignalConfigs = _.filter(
      signalConfigs, (signalConfig, signalConfigIndex) => signalConfigIndex !== indexToRemove,
    );

    if (indexToRemove === 0) {
      updatedSignalConfigs[indexToRemove] = {
        ...updatedSignalConfigs[indexToRemove],
        conditionOperator: ''
      };
    }

    onRemoveSignalConfig(updatedSignalConfigs);
  };

  return (
    <button
      type="button"
      className="delete-btn align-self-end border border-danger"
      onClick={onRemoveConditionClick}
    >
      <i className="material-icons-outlined">delete_outline</i>
    </button>
  );
};

RemoveSignalConfig.propTypes = propTypes;
RemoveSignalConfig.defaultProps = defaultProps;

export default RemoveSignalConfig;
