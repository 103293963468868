import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getEmptyOrderConfig } from 'modules/QuantBuilder/config';
import { setOrderConfigs } from 'modules/QuantBuilder/actions';
import { orderConfigsPropTypes } from 'common/propTypes';

const propTypes = {
  maxInstrumentsInGroup: PropTypes.number.isRequired,
  orderConfigs: orderConfigsPropTypes.isRequired,
  dispatchSetOrderConfigs: PropTypes.func.isRequired,
};

const defaultProps = {};
const I18N_SCOPE = { scope: 'advanced_quant_builder.index' };

const AddOrderConfig = (props) => {
  const { orderConfigs, dispatchSetOrderConfigs, maxInstrumentsInGroup } = props;

  const onAddOrderConfig = () => {
    dispatchSetOrderConfigs([
      ...orderConfigs,
      getEmptyOrderConfig(maxInstrumentsInGroup)
    ]);
  };

  const { isBetaUser, isAdmin } = window;
  const isDisabled = (orderConfigs || []).length > 1 && !isAdmin && !isBetaUser;

  return (
    <>
      <button
        type="button"
        disabled={isDisabled}
        className="btn btn-primary d-flex align-items-center pl-0 track"
        onClick={onAddOrderConfig}
        data-track-category="Conditional Case"
        data-track-action="Conditional Case"
        data-track-label="Conditional Case"
      >
        <i className="material-icons-outlined tx-18 mx-1">add_circle</i>
        {I18n.t('add_placeorder_btn', I18N_SCOPE)}
      </button>
      {isDisabled
        && <p className="text-danger text-center my-2 ml-2">Only possible to add 2 strategy cases</p>}
    </>
  );
};

const mapStateToProps = (state) => {
  const noOfInstrumentsInGroup = _.get(state, 'quantConfig.instrumentGroups.0', []).length;

  return ({
    orderConfigs: _.get(state, 'quantConfig.orderConfigs'),
    maxInstrumentsInGroup: _.get(state, 'quantConfig.maxInstrumentsInGroup', noOfInstrumentsInGroup),
  });
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSetOrderConfigs: (newOrderConfigs) => dispatch(setOrderConfigs(newOrderConfigs)),
});

AddOrderConfig.propTypes = propTypes;
AddOrderConfig.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddOrderConfig);
