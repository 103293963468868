const configuration = {
  type: 'shortBuildup',
  defaults: {
    type: 'shortBuildup',
    name: 'shortBuildup',
    config: {
      candleInterval: '1 minutes',
      valuePaths: [
        'item0.month1Price.openInterest',
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'shortBuildup'
  ],
  configFormOptions: {
    valuePathEditable: false,
    configFieldOptions: {}
  },
  buildOutputSchema: ({ name, type }) => {
    return [
      {
        name,
        type: 'boolean',
        enum: [0, 1],
        indicatorType: type,
        category: 'indicator',
        label: name
      }
    ];
  },
  isEnabled: true
};
export default configuration;
