// vendor imports
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

// project imports
import { runPropTypes } from 'common/propTypes';
import ActionBtns from 'ui/FolderList/RunList/ActionBtns';
import InlineEdit from 'common/components/InlineEdit';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';
import getFormattedDate from 'common/utils/dateFormat';
import { runStatusColorCode } from 'common/configs/runStatus';
import {
  BACK_TEST_ICON, LIVE_ICON, MARKET_PLACE_ICON, RUN_TYPES
} from 'common/constants/index';
import MoneyDisplay from 'common/components/MoneyDisplay';
import classNames from 'classnames';

const propTypes = {
  run: runPropTypes.isRequired,
  onDeleteRun: PropTypes.func.isRequired,
  onUpdateRun: PropTypes.func.isRequired,
  folderName: PropTypes.string.isRequired,
  onSelectRuns: PropTypes.func.isRequired,
  isFilteredRuns: PropTypes.bool.isRequired
};
const defaultProps = {};

const renderDates = (startDate, endDate) => (
  <>
    {getFormattedDate(startDate)}
    &nbsp;
    -
    &nbsp;
    {getFormattedDate(endDate)}
  </>
);

const RunListViewBody = ({
  run, onDeleteRun, onUpdateRun, folderName, onSelectRuns, isFilteredRuns
}) => {
  const {
    id,
    name,
    display_name: displayName,
    run_type: runType,
    created_at: createdAt,
    status,
    start_date: startDate,
    end_date: endDate,
    strategy_id: strategyId,
    summary,
    is_individual_deployed_run: isIndividualDeployedRun
  } = run;
  const profit = _.get(summary, 'profit', 0);
  const isBacktest = runType === RUN_TYPES.historic;
  const instrumentGroups = _.get(run, 'instrument_groups', []);
  const { statusText, statusClassName } = runStatusColorCode(status);
  const [checked, setChecked] = useState(false);

  const getIcon = () => {
    if (!isIndividualDeployedRun) return MARKET_PLACE_ICON;
    if (isBacktest) return BACK_TEST_ICON;

    return LIVE_ICON;
  };

  const listClassName = classNames('list-row', { 'marketplace-run': !isIndividualDeployedRun });

  const handleRunSelect = (runId) => {
    onSelectRuns(runId, !checked);
    setChecked(!checked);
  };

  return (
    <div className={listClassName}>
      <div className="list-view-table" key={id}>
        <a className="list-view-table" href={`/runs/${id}/?locale=${I18n.locale}`}>
          {!isFilteredRuns && (
            <div className="action align-items-center">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  id={`checkbok-${id}`}
                  className="custom-control-input cursor-pointer"
                  onChange={() => { handleRunSelect(id); }}
                  onClick={(e) => e.stopPropagation()}
                  checked={checked}
                />
              </div>
            </div>
          )}
          <div className="list-view-cell">
            <div className="qd-head">
              <i className={getIcon()} alt={runType} title={runType} />
              <div className="col-10">
                <h6>
                  <InlineEdit
                    text={name || displayName}
                    onUpdate={(newName) => onUpdateRun(run, 'name', newName)}
                    tooltip="Edit Run name"
                    isShowEditIcon={!strategyId}
                  />
                </h6>
                <small>
                  {isBacktest ? renderDates(startDate, endDate)
                    : `Created At - ${moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}`}
                </small>
              </div>
            </div>
          </div>
          <div className="list-view-cell">
            <h6 className="d-flex"><MoneyDisplay shouldColor>{profit}</MoneyDisplay></h6>
          </div>
          <div className="list-view-cell">
            <h6><InstrumentGroupsSentence instrumentGroups={instrumentGroups} /></h6>
          </div>
          <div className="list-view-cell">
            <h6 className="text-muted font-weight-normal tx-12">{moment(createdAt).fromNow()}</h6>
          </div>
          <div className="list-view-cell run-status-details">
            <h6>
              {status && <span className={statusClassName} />}
              {statusText}
            </h6>
          </div>
        </a>
        <div className="action-btns">
          <ActionBtns
            onUpdateRun={onUpdateRun}
            onDeleteRun={onDeleteRun}
            run={run}
            folderName={folderName}
          />
        </div>
      </div>
    </div>
  );
};

RunListViewBody.propTypes = propTypes;
RunListViewBody.defaultProps = defaultProps;

export default RunListViewBody;
