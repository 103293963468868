const getPatternRegex = () => ({
  number: /[-+]?([0-9]*\.[0-9]+|[0-9]+)/,
  string: /\w/,
  integer: /^[-+]?\d*$/
});

const getChartConfigValidator = (chartConfig) => {
  const { properties, required } = chartConfig;
  const chartConfigValidators = {};

  _.each(properties, (property, key) => {
    const { type } = property;

    chartConfigValidators[key] = {
      required: {
        value: _.includes(required, key),
        message: 'value is required'
      },
      pattern: {
        value: getPatternRegex()[type],
        message: `value must be a ${type}`
      }
    };
  });

  return chartConfigValidators;
};

const getConfigValidator = (config) => {
  const { properties, required } = config;
  const configValidators = {};

  _.each(properties, (property, key) => {
    const { type } = property;

    if (key === 'valuePaths') {
      const { minItems, maxItems, items } = property;
      configValidators[key] = {
        required: {
          value: _.includes(required, key),
          message: 'Value is required',
        },
        pattern: {
          value: getPatternRegex()[items.type],
          message: `value should be a ${items.type}`
        },
        minLength: {
          value: minItems,
          message: `should have atleast ${minItems} items`
        },
        maxLength: {
          value: maxItems,
          message: `should have at most ${maxItems} items`
        }
      };

      return;
    }

    configValidators[key] = {
      required: {
        value: _.includes(required, key),
        message: 'value is required'
      },
      pattern: {
        value: getPatternRegex()[type],
        message: `value must be a ${type}`
      }
    };
  });

  return configValidators;
};

export const getPipeValidators = (pipesSchema) => {
  const pipesValidators = {};
  _.each(pipesSchema, (pipeSchema, pipeType) => {
    const { properties, required } = pipeSchema;
    const { type: { type }, config, chartConfig } = properties;

    pipesValidators[pipeType] = {
      name: {
        required: {
          value: _.includes(required, 'name'),
          message: 'Name is required'
        },
        pattern: {
          value: /^[^\\.]+$/,
          message: 'Character "." (dot) not allowed in name.'
        }
      },
      type: {
        required: {
          value: _.includes(required, 'type'),
          message: 'indicator is required'
        },
        pattern: {
          value: getPatternRegex()[type],
          message: 'Alphabetical characters only'
        }
      },
      config: getConfigValidator(config),
      chartConfig: getChartConfigValidator(chartConfig)
    };
  });

  return pipesValidators;
};
