import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  phoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onEditPhoneNumber: PropTypes.func.isRequired,
  onResendOtp: PropTypes.func.isRequired,
  routePath: PropTypes.string.isRequired
};
const defaultProps = {};
const OTP_LENGTH = 6;
const RESEND_LABELS = {
  resend: 'resend',
  sending: 'sending',
};

const OtpForm = (props) => {
  const {
    phoneNumber, onResendOtp, onEditPhoneNumber, routePath
  } = props;
  const [otp, setOtp] = useState('');
  const [resendLabel, setResendLabel] = useState(RESEND_LABELS.resend);
  const [isResendingOtp, setIsResendingOtp] = useState(false);

  const onOtpChange = (event) => {
    const { target: { value } } = event;
    if (value.length > OTP_LENGTH) return;

    setOtp(value);
  };

  const onClickResendOtp = () => {
    setIsResendingOtp(true);
    setResendLabel(RESEND_LABELS.sending);

    setTimeout(() => {
      setIsResendingOtp(false);
      setResendLabel(RESEND_LABELS.resend);
    }, 2000);

    onResendOtp();
  };

  return (
    <div>
      <div className="text-center">
        <img alt="smartphone" src="/smartphones-notification.svg" />
        <h4 className="font-weight-bold mb-1">OTP Verification</h4>
        <h5 className="text-muted">
          Enter the OTP sent to
          <span className="font-weight-bold mx-2">{phoneNumber}</span>
          <i onClick={onEditPhoneNumber} className="cursor-pointer icons-pen tx-18" />
        </h5>
      </div>
      <form className="text-center mt-4" method="post" action="/verify_otp">
        <input type="hidden" value={routePath} name="route_path" />
        <input
          type="number"
          key="otp"
          className="otp-input"
          name="otp"
          value={otp}
          onChange={onOtpChange}
        />
        <input
          type="hidden"
          name="phone_number"
          value={phoneNumber}
        />

        <div className="d-flex align-items-center justify-content-center">
          <h6 className="my-5 text-muted">
            Didn&lsquo;t receive OTP?
            <span
              className="text-primary cursor-pointer font-weight-bold mx-2"
              onClick={onClickResendOtp}
            >
              {_.startCase(resendLabel)}
            </span>
          </h6>
          {isResendingOtp && <div className="dot-elastic " />}
        </div>

        <button
          className="btn btn-primary btn-pill text-uppercase"
          type="submit"
        >
          Verify & Continue
        </button>
      </form>
    </div>
  );
};

OtpForm.defaultProps = defaultProps;
OtpForm.propTypes = propTypes;

export default OtpForm;
