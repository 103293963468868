import classNames from 'classnames';
import React from 'react';

const GoToMarketPlaceBanner = () => {
  const marketplaceBannerClassNames = classNames(
    'marketplace-banner', 'rounded mt-3 mt-md-0 mb-3 mb-md-6',
    'd-none d-md-block', 'master-domain-only'
  );

  return (
    <div className={marketplaceBannerClassNames}>
      <div className="px-5 d-flex flex-wrap flex-sm-nowrap align-items-center">
        <div className="w-100 mr-0 mb-3 mr-sm-10 mb-sm-0">
          <h1 className="text-white tx-25 font-weight-bold">Sample Strategies</h1>
          <p className="tx-14 text-white mt-1 mb-0">
            Explore a world of trading strategies, where you choose and deploy from professionally curated
            strategies.
          </p>
        </div>

        <button
          type="button"
          className="goto-btn btn btn-success text-nowrap btn-pill"
          onClick={() => { window.location.href = '/strategies'; }}
        >
          Explore Now
          <i className="material-icons-outlined tx-18 align-middle ml-1">arrow_forward</i>
        </button>
      </div>
    </div>
  );
};

GoToMarketPlaceBanner.propTypes = {};

export default GoToMarketPlaceBanner;
