import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SocialShare from './SocialShare';

const propTypes = {
  referralURL: PropTypes.string.isRequired
};

const defaultProps = {};

const ReferAndEarn = ({ referralURL }) => {
  const [copyBtnText, setCopyBtnText] = useState('Copy Link');
  const oneIndentation = ' ';

  const onCopyReferralLink = () => {
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = referralURL;
    tempInput.select();
    document.execCommand('copy', false);
    tempInput.remove();

    setCopyBtnText('Copied!');
    setTimeout(() => { setCopyBtnText('Copy Link'); }, 5000);
    return null;
  };

  return (
    <div className="content-area refer-page">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-3 mt-lg-10 align-self-center text-center">
            <h6 className="mb-2">REFER &amp; EARN</h6>
            <h1>Earn 10% of each payment that Your Friends &amp; Family made.</h1>
            <img src="./share.png" alt="share" />
            <p className="py-4 sub-text">
              Create a passive income by referring your friends, colleagues, and family members to
              <br />
              <b>QUANTMAN</b>
              {oneIndentation}
              and earning the 10% credits from each payments they make for lifetime.
              {oneIndentation}
              <br />
              You can use this credits while make your payment in Quantman
            </p>
          </div>
        </div>

        <div className="row justify-content-center mb-10">
          <div className="col-lg-6 col-md-9 mt-2 mt-md-5">
            <div className="card shadow-lg p-5 border-0">
              <div className="d-flex gap-15 share-form mb-4 border rounded p-1">
                <input
                  type="text"
                  className="form-control border-0 shadow-none flex-grow-1"
                  placeholder="Your Referral Link"
                  value={referralURL}
                  disabled
                />
                <button
                  type="button"
                  id="d_generate_link"
                  className="btn text-nowrap btn-primary d-flex gap-5 align-items-center"
                  onClick={() => { onCopyReferralLink(); }}
                >
                  <span className="material-icons-outlined tx-18">content_copy</span>
                  {oneIndentation}
                  {copyBtnText}

                </button>
              </div>
              <SocialShare referralURL={referralURL} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReferAndEarn.propTypes = propTypes;
ReferAndEarn.defaultProps = defaultProps;

export default ReferAndEarn;
