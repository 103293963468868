import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { CONDITION_OPERATORS } from 'modules/QuantBuilder/config';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { signalConfigPropTypes } from 'common/propTypes';

const propTypes = {
  signalConfig: signalConfigPropTypes.isRequired,
  onUpdateSignalConfig: PropTypes.func.isRequired,
  logicalOperatorValidator: PropTypes.shape({}).isRequired
};
const defaultProps = {};

const ConditionOperator = (props) => {
  const { signalConfig, onUpdateSignalConfig, logicalOperatorValidator } = props;
  const { conditionOperator } = signalConfig;
  const { register, errors } = useFormContext();
  const [radioBtnName] = useState(_.uniqueId('conditionOperator-'));

  const renderConditionRadioBtns = _.map(CONDITION_OPERATORS, (operator, idx) => {
    return (
      <div className="form-check form-check-sm" key={idx}>
        <input
          type="radio"
          id={`${radioBtnName}-${idx}`}
          name={radioBtnName}
          className="form-check-input track"
          ref={register(logicalOperatorValidator)}
          value={operator}
          checked={conditionOperator === operator}
          onChange={(event) => onUpdateSignalConfig({ conditionOperator: event.target.value })}
        />
        <label
          className="form-check-label"
          htmlFor={`${radioBtnName}-${idx}`}
        >
          {operator}
        </label>
      </div>
    );
  });

  return (
    <div className="mb-2">

      <div className="d-flex gap-10">
        {renderConditionRadioBtns}
      </div>

      <ErrorMesssage
        error={errors[radioBtnName]}
      />
    </div>
  );
};

export default ConditionOperator;

ConditionOperator.propTypes = propTypes;
ConditionOperator.defaultProps = defaultProps;
