import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setChartConfig } from 'modules/QuantBuilder/actions';
import { chartConfigPropTypes } from 'common/propTypes';
import HoverMessage from 'common/components/HoverMessage';

const propTypes = {
  chartConfig: chartConfigPropTypes.isRequired,
  dispatchSetChartPattern: PropTypes.func.isRequired,
};
const defaultProps = {};

const RollupType = (props) => {
  const {
    chartConfig, chartConfig: { isContinuousRollup }, dispatchSetChartPattern
  } = props;
  const onRollUpTypeChange = (newRollUp) => {
    dispatchSetChartPattern({
      ...chartConfig,
      isContinuousRollup: newRollUp
    });
  };

  return (
    <HoverMessage
      title="Continuous"
      message="This feature is coming soon"
    >
      <ToggleButtonGroup
        type="radio"
        size="sm"
        bsPrefix="btn-group btn-group-toggle"
        name="rollupType"
        defaultValue={isContinuousRollup}
        onChange={onRollUpTypeChange}
      >
        <ToggleButton
          bsPrefix="btn btn-outline-primary text-capitalize"
          value
          disabled
        >
          Continuous
        </ToggleButton>

        <ToggleButton
          bsPrefix="btn btn-outline-primary text-capitalize"
          value={false}
        >
          End of Candle
        </ToggleButton>
      </ToggleButtonGroup>
    </HoverMessage>
  );
};

RollupType.propTypes = propTypes;
RollupType.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    chartConfig: _.get(state, 'quantConfig.chartConfig'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchSetChartPattern: (newChartPattern) => dispatch(setChartConfig(newChartPattern)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RollupType);
