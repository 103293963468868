import _ from 'lodash';
import * as pipeDefinitions from '../pipeDefinitions';
import { buidInstrumentOutputSchema } from './instrumentOutputSchema';
import { buildIndividualStopOutputSchema } from './individualStopOutputSchema';
import { buildStrategyStopOutputSchema } from './strategyStopOutputSchema';

const getOutputSchemaForPipe = (pipe) => {
  const { type } = pipe;
  const pipeDefinition = pipeDefinitions[type];
  if (pipeDefinition) {
    return pipeDefinition.buildOutputSchema(pipe);
  }
  return [];
};

export const fetchOutputSchemaImmediate = (quantConfig) => {
  if (_.isEmpty(quantConfig)) { return []; }

  const {
    maxInstrumentsInGroup,
    pipeConfigs = [],
    orderConfigs = []
  } = quantConfig;
  return _.flatten([
    _.flatMap(pipeConfigs, getOutputSchemaForPipe),
    buidInstrumentOutputSchema(maxInstrumentsInGroup),
    buildIndividualStopOutputSchema(orderConfigs),
    buildStrategyStopOutputSchema()
  ]);
};

export const fetchOutputSchema = async (quantConfig) => {
  if (_.isEmpty(quantConfig)) { return []; }

  // TODO make it work with existing code, this can be removed later
  return fetchOutputSchemaImmediate(quantConfig);
};
