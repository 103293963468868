import PropTypes from 'prop-types';

import { quickAdjustmentPropTypes } from 'common/propTypes';
import { QUICK_ADJUSTMENT_ACTIONS, QUICK_ADJUSTMENT_TRIGGERS } from 'v2/common/constants/index';

const propTypes = {
  quickAdjustments: PropTypes.arrayOf(quickAdjustmentPropTypes),
};

const defaultProps = {
  quickAdjustments: [],
};

const renderQuickAdjustmentView = (quickAdjustment) => {
  const {
    trigger, action, targets, delayBy
  } = quickAdjustment;
  const triggerItem = _.find(QUICK_ADJUSTMENT_TRIGGERS, { value: trigger });
  const actionItem = _.find(QUICK_ADJUSTMENT_ACTIONS, { value: action });
  const delayByItem = _.isNumber(delayBy) ? `after ${delayBy} secs` : '';

  return `[On ${triggerItem.shortLabel}, ${actionItem.shortLabel} Leg: ${targets.join('|')} ${delayByItem}]`;
};

const QuickAdjustmentsView = ({ quickAdjustments }) => {
  return quickAdjustments.map(renderQuickAdjustmentView).join('');
};

QuickAdjustmentsView.propTypes = propTypes;
QuickAdjustmentsView.defaultProps = defaultProps;

export default QuickAdjustmentsView;
