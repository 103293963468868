import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import ReactHtmlParser from 'react-html-parser';
import { validateBrokerUser } from 'common/api/user';
import { getBrokerImage, reDirectPage } from '../config';
import UserInput from '../CommonComponents/UserInput';

const propTypes = {
  broker: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const defaultProps = {};

const kasat = ({ broker, onCloseModal }) => {
  const [configs, setConfigs] = useState({
    clientId: '',
    apikey: '',
    secretKey: ''
  });
  const {
    clientId, apiKey, secretKey
  } = configs;
  const [isValidateClientIdClicked, setIsValidateClientIdClicked] = useState(false);
  const [showApiKeyField, setShowApiKeyField] = useState(false);
  const [showSecretKeyField, setShowSecretKeyField] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const onHandleConfig = (key, value) => {
    setConfigs({ ...configs, [key]: value });
  };

  const onValidateClientId = () => {
    if (!clientId) return setIsValid(false);

    validateBrokerUser({
      broker_user_id: clientId, broker,
    }).then((result) => {
      const { status, broker_api_credentials: brokerApiCredientals } = result;
      if (!status) {
        setShowApiKeyField(true);
      } else {
        onHandleConfig('apiKey', brokerApiCredientals.api_key);
      }

      setIsValidateClientIdClicked(true);
      setShowSecretKeyField(true);
    });

    return setIsValid(true);
  };

  const onLogin = () => {
    if (!clientId || !apiKey || !secretKey) return setIsValid(false);
    reDirectPage(
      {
        client_id: clientId,
        app_key: apiKey,
        secret_key: secretKey
      },
      broker,
      true
    );

    return '';
  };

  const modalProps = {
    onClose: onCloseModal,
    shouldShow: true,
    size: 'sm',
    onSaveid: `btn-${broker}`,
    onSave: !isValidateClientIdClicked
      ? () => onValidateClientId()
      : () => onLogin(),
    okBtnTitle: !isValidateClientIdClicked ? 'Validate' : 'Login',
    isOkBtnDisabled: false,
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="mb-6">
        {ReactHtmlParser(getBrokerImage(broker))}
        <label className="mt-2">Client Id</label>
        <UserInput
          id={`${broker}-client-id`}
          value={clientId}
          isDisabled={isValidateClientIdClicked}
          onHandleChange={(value) => {
            onHandleConfig('clientId', value);
          }}
        />

        {showApiKeyField && (
          <div className="mt-2">
            <label>API Key</label>
            <UserInput
              id={`${broker}-api-key`}
              value={apiKey}
              onHandleChange={(value) => {
                onHandleConfig('apiKey', value);
              }}
            />
          </div>
        )}

        {showSecretKeyField && (
          <div className="mt-2">
            <label>API Secret</label>
            <UserInput
              id={`${broker}-secret-key`}
              value={secretKey}
              onHandleChange={(value) => {
                onHandleConfig('secretKey', value);
              }}
            />
          </div>
        )}

        {isValidateClientIdClicked && (
          <div className="my-2 alert alert-warning py-1 px-2">
            Please contact Kasat Securities support to get your API Key and Secret
            Key
          </div>
        )}

        {!isValid && (
          <div className="invalid-feedback d-block">
            Please provide the details
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

kasat.propTypes = propTypes;
kasat.defaultProps = defaultProps;

export default kasat;
