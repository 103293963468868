import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import {
  getExchangeAndSymbol, getInstrument, EXCHANGES,
} from 'common/utils/config';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  SEGMENT_CONFIG, SEGMENT_NAMES, SEGMENT_SUPPORT_CONFIGS, BFO_INSTRUMENTS
} from 'common/constants/index';
import _ from 'lodash';
import { LocaleUtils } from 'v2/common/utils/zeequantConstants';

const propTypes = {
  shouldDisableInput: PropTypes.bool,
  isInvalid: PropTypes.bool,
  instrument: PropTypes.string,
  exchange: PropTypes.string,
  onComplete: PropTypes.func,
  shouldShowExchange: PropTypes.bool,
  onExchangeChange: PropTypes.func,
  shouldClearTradeSymbol: PropTypes.bool,
  segment: PropTypes.string,
  onChangeSegment: PropTypes.func,
  shouldShowSegment: PropTypes.bool,
  segments: PropTypes.arrayOf(PropTypes.string).isRequired
};

const defaultProps = {
  isInvalid: false,
  shouldShowExchange: true,
  shouldClearTradeSymbol: false,
  instrument: '',
  exchange: '',
  segment: SEGMENT_CONFIG.equityAndFuture,
  onComplete: _.noop,
  shouldShowSegment: true,
  onExchangeChange: _.noop,
  shouldDisableInput: false,
  onChangeSegment: () => { }
};
const I18N_SCOPE = { scope: 'basic_quant_builder.index' };

export default class InstrumentInput extends React.Component {
  constructor(props) {
    super(props);

    this.asyncTypeaheadRef = React.createRef();
    const { instrument } = props;
    const { exchange } = getExchangeAndSymbol(instrument);

    this.state = {
      exchange,
      symbol: '',
      suggestions: [],
      isLoading: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { instrument, exchange: exchangeFromProps, shouldClearTradeSymbol } = props;
    const { exchange: currentExchange, symbol } = state;
    const { exchange, symbol: propsSymbol } = getExchangeAndSymbol(instrument);

    if (shouldClearTradeSymbol) return { symbol: '' };

    if ((exchangeFromProps) && (exchangeFromProps !== currentExchange)) {
      return { exchange: exchangeFromProps };
    }

    if (exchange !== currentExchange) return { exchange: currentExchange };

    if (symbol) return { symbol };

    return { exchange, symbol: propsSymbol };
  }

  getTradingSymbolSuggestions = (searchTerm) => {
    if (!searchTerm) {
      this.setState({ suggestions: [] });
      return;
    }
    const ignorableInstruments = [
      'AUBANK',
      'IEX',
      'SBICARD',
      'SHRIRAMFIN',
      'INDHOTEL',
      'ASTRAL',
      'MPHASIS',
      'PERSISTENT',
      'POLYCAB',
      'ABBOTINDIA',
      'IRCTC'
    ];

    const instruments = LocaleUtils().getAllSymbols();

    this.setState({ isLoading: true });

    const newSuggestions = _.chain(instruments)
      .filter((instrument) => _.includes(instrument.toLowerCase(), searchTerm.toLowerCase()))
      .filter((instrument) => !_.includes(ignorableInstruments, instrument))
      .map((instrument) => ({
        label: instrument,
        value: instrument
      }))
      .value();
    this.setState({ suggestions: newSuggestions, isLoading: false });
  }

  onExchangeChange = (event) => {
    this.asyncTypeaheadRef.current.clear();
    this.asyncTypeaheadRef.current.focus();

    const { onExchangeChange } = this.props;
    const newExchange = event.target.value;

    this.setState({
      exchange: newExchange,
      symbol: '',
      suggestions: []
    });

    onExchangeChange(newExchange);
  }

  onTradingSymbolChange = (selectedTradingSymbols) => {
    const symbol = _.get(selectedTradingSymbols[0], 'value', '');
    const { onComplete } = this.props;
    const { exchange } = this.state;
    const alteredExchange = (BFO_INSTRUMENTS.includes(symbol)) ? 'BFO' : exchange;
    const instrument = getInstrument(alteredExchange, symbol);

    this.setState({
      symbol,
      suggestions: []
    }, () => { if (symbol) onComplete(instrument); });
  }

  renderExchangeInput = () => {
    const { exchange } = this.state;
    const options = _.map(EXCHANGES, (exch, key) => (<option key={key} value={exch}>{exch}</option>));

    return (
      <Form.Control
        as="select"
        custom
        className="mb-2 mb-xm-0 mr-xm-2 col col-xm-4"
        defaultValue={exchange}
        onChange={(event) => this.onExchangeChange(event)}
      >
        {options}
      </Form.Control>
    );
  }

  renderTradingSymbolInput = () => {
    const { isInvalid, shouldDisableInput } = this.props;
    const { symbol, suggestions, isLoading } = this.state;
    const alteredSuggestions = _.map(suggestions, (suggestion) => {
      const { value } = suggestion;
      return { label: value, value };
    });

    const selectedTradingSymbols = _.isEmpty(symbol) ? [] : [symbol];

    return (
      <AsyncTypeahead
        disabled={shouldDisableInput}
        isInvalid={isInvalid}
        isLoading={isLoading}
        placeholder={I18n.t('instrument_search_placeholder', I18N_SCOPE)}
        id="instrument-input"
        clearButton
        minLength={1}
        ref={this.asyncTypeaheadRef}
        options={alteredSuggestions}
        selected={selectedTradingSymbols}
        onSearch={this.getTradingSymbolSuggestions}
        onChange={this.onTradingSymbolChange}
        useCache={false}
      />
    );
  }

  getSegmentConfig = () => {
    const { segments } = this.props;
    const { isSupportEquity, isSupportFuture, isSupportOption } = SEGMENT_SUPPORT_CONFIGS(segments);
    const isNonPaidView = !isSupportEquity && !isSupportFuture && !isSupportOption;

    if (isNonPaidView) { // non paid users flow and before login flow
      const alteredSegmentNames = _.map(SEGMENT_NAMES, (segmentNames) => { return segmentNames; });

      return _.compact(alteredSegmentNames);
    }

    // paid flow for users...
    let newSegments = [];
    const optionName = isSupportOption ? ' & Options' : '';

    if (isSupportEquity) newSegments = [...newSegments, { ...SEGMENT_NAMES[1] }];
    if (isSupportFuture) {
      newSegments = [...newSegments, { ...SEGMENT_NAMES[0], displayName: `Future${optionName}` }];
    }

    return newSegments;
  };

  renderSegments = () => {
    const { segment, onChangeSegment } = this.props;
    const newSegments = this.getSegmentConfig();
    if (_.isEmpty(newSegments)) return null;

    const options = _.map(newSegments,
      ({ value, displayName }) => (<option key={value} value={value}>{displayName}</option>));

    return (
      <Form.Control
        as="select"
        custom
        size="sm"
        className="track"
        data-track-category="Segment"
        data-track-action={segment}
        data-track-label="Segments Drop-Down"
        id={segment}
        name="Segment"
        defaultValue={segment}
        onChange={(event) => onChangeSegment(event.target.value)}
      >
        {options}
      </Form.Control>
    );
  };

  render() {
    const { shouldShowExchange, shouldShowSegment } = this.props;

    return (
      <div className="common-component-instrument-input">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">

            <span className="input-group-text hidden-for-future">NFO</span>
            {/* hidden (common) .. because equity integration  */}

            <div className="hidden-for-future">
              {shouldShowExchange && this.renderExchangeInput()}
            </div>
            {/* hided exchange  */}
            <div>
              {shouldShowSegment && this.renderSegments()}
            </div>

          </div>
          {this.renderTradingSymbolInput()}
        </div>
      </div>
    );
  }
}

InstrumentInput.propTypes = propTypes;
InstrumentInput.defaultProps = defaultProps;
