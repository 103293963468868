import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ORDER_CONFIRMATION_STATUSES, NOTIFICATION_EVENTS } from 'common/constants';
import SignalConfirmation from 'modules/SignalConfirmation/index';
import notificationPropType from './propTypes/notificationPropType';

const propTypes = {
  notification: notificationPropType.isRequired,
  signalConfirmation: PropTypes.func.isRequired,
};

const defaultProps = {};

const ViewConfirmButton = ({ notification, signalConfirmation }) => {
  const [isSignal, setIsSignalModal] = useState(false);
  const { event, notifiable_id: notifiableId, details } = notification;
  const {
    run_id: runId,
    job_id: jobId,
    status,
    verification_token: verificationToken
  } = details;

  const renderSignalConfirmation = () => {
    return (
      <div
        className="order-transaction-panel-modal"
        key={notifiableId}
      >
        {/* order-transaction-panel-modal # classname handled for orderModal  */}
        <SignalConfirmation
          key={notifiableId}
          runId={runId}
          jobId={jobId}
          orderConfirmationId={notifiableId}
          onUpdate={(newStatus) => signalConfirmation(notifiableId, newStatus)}
          verificationToken={verificationToken}
          closeCallBack={() => setIsSignalModal(false)}
        />
      </div>
    );
  };

  if (_.includes(
    _.values(NOTIFICATION_EVENTS.ORDER_CONFIRMATION.EVENTS),
    event
  ) && (status === ORDER_CONFIRMATION_STATUSES.NEW)) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-outline-info mr-2"
          onClick={() => setIsSignalModal(true)}
        >
          Confirm
        </button>
        {isSignal && renderSignalConfirmation()}
      </div>
    );
  }

  return null;
};

ViewConfirmButton.defaultProps = defaultProps;
ViewConfirmButton.propTypes = propTypes;

export default ViewConfirmButton;
