import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  content: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired
};

const defaultProps = {};

const Media = ({ content }) => {
  const { url, alt } = content;

  return (
    <img src={url} alt={alt} className="img-fluid" />
  );
};

Media.propTypes = propTypes;
Media.defaultProps = defaultProps;

export default Media;
