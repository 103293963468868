export const toPositiveNumberString = (value) => {
  if (Number(value) < 0 || value === '') return '0';

  let targetValue = value;
  if (targetValue.substring(0, 1) === '0') {
    targetValue = targetValue.substring(1);
    return targetValue;
  }

  return value;
};

export const onRegexOnlyNumberAndFloat = (value, isString) => {
  if (!value) return undefined;

  const regxValue = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
  return isString ? regxValue : parseFloat(value);
};

export const onRegexOnlyNumber = (value) => {
  return Number(value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1'));
};
