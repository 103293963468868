/* eslint-disable no-param-reassign */
import { ORDER_TYPES, RUN_TYPES } from 'common/constants/index';
import wrappedFetch from './base';

export const formatOrdersBasedOnJobs = (ordersList, runId, orderType, runType) => {
  const {
    automatic: { value: automatic }, oneClick: { value: oneclick }
  } = ORDER_TYPES;

  const previousListTransactionIds = {};
  _.each(ordersList, (orders) => {
    if (_.isEmpty(orders)) return;

    _.each(orders, (order) => {
      if (runType === RUN_TYPES.live) {
        const isActualLive = orderType === automatic || orderType === oneclick;
        const newObj = {
          price: isActualLive ? order.traded_price : order.signal_price,
          // this order_time is used for display entry and exit time in orders table
          // if order is stop loss and it is traded then we will show traded time
          order_time: (
            isActualLive && _.get(order, 'is_stop_loss', false) && order.traded_time
          ) ? order.traded_time : order.signal_time
        };
        order = _.merge(order, newObj);
      }

      order.transaction_id = (
        Number(order.transaction_id)
        + _.get(previousListTransactionIds, order.instrument_group_index, 0)
      ).toString();

      order.transaction_and_run_id = `${order.transaction_id}-${runId}`;
      order.run_id = runId;
    });
    const groupOrdersList = _.groupBy(orders, 'instrument_group_index');
    _.each(groupOrdersList, (groupOrders, groupKey) => {
      previousListTransactionIds[groupKey] = Number(_.get(_.last(groupOrders), 'transaction_id', 0));
    });
  });

  return ordersList;
};

export const getAllOrders = _.memoize(({
  runId, jobIds, runType, orderType, ordersAccessTokens
}) => {
  return Promise.all(
    _.map(jobIds, (jobId) => {
      let queryString = `run_type=${runType}`;

      if (runType === 'historic') {
        queryString += `&accessToken=${ordersAccessTokens[jobId]}`;
      }

      return wrappedFetch(`/runs/${jobId}/orders?${queryString}`)
        .then((response) => response.json());
    })
  ).then((ordersList) => {
    return formatOrdersBasedOnJobs(ordersList, runId, orderType, runType);
  });
}, ({ jobIds, runType }) => `${_.first(jobIds)}-${runType}`);

export const retryOrder = (jobId, orderId) => {
  return wrappedFetch(`/runs/${jobId}/orders/${encodeURIComponent(orderId)}/retry`)
    .then((response) => response.json());
};
