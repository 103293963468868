import React from 'react';

import { pipeConfigPropTypes } from 'common/propTypes';
import { getExternalSignalUrl } from '../PipeConfigForm/components/ExternalSignal/url';

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
};

const defaultProps = {};

const ExternalSignalView = ({ pipeConfig }) => {
  const { config: { token } } = pipeConfig;
  const url = getExternalSignalUrl(token);

  return (
    <>
      <span>{url}</span>
    </>
  );
};

ExternalSignalView.propTypes = propTypes;
ExternalSignalView.defaultProps = defaultProps;

export default ExternalSignalView;
