import PropTypes from 'prop-types';
import React from 'react';

import { outputSchemaPropTypes, pipeConfigsPropTypes, quantConfigPropTypes } from 'common/propTypes';
import HoverMessage from 'common/components/HoverMessage';
import ViewPipeConfig from './ViewPipeConfig';
import { isDisabledPipeName } from './PipeForm/helper';

const propTypes = {
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  onEditPipe: PropTypes.func.isRequired,
  onRemovePipe: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  quantConfig: quantConfigPropTypes.isRequired
};
const defaultProps = {};
const I18N_SCOPE = { scope: 'advanced_quant_builder.index' };

export default class PipesList extends React.Component {
  renderEditBtn = (pipeConfig, pipeConfigIndex) => {
    const { onEditPipe } = this.props;
    const args = {
      isInEditMode: true,
      pipeConfig,
      pipeConfigIndex,
      formTitle: I18n.t('edit_indicator', I18N_SCOPE),
    };

    return (
      <button
        type="button"
        className="
          align-items-center
          btn
          btn-link
          d-flex
          font-weight-semi
          gap-5
        "
        onClick={() => onEditPipe(args)}
      >
        <i className="material-icons-outlined tx-16">edit</i>
        Edit
      </button>
    );
  };

  renderPipe = (pipeConfig, pipeConfigIndex) => {
    const { onRemovePipe, outputSchema, quantConfig } = this.props;
    const { name, config, type } = pipeConfig;
    const isDisable = isDisabledPipeName(quantConfig, name);

    return (
      <div key={pipeConfigIndex} className="col-md-12 mb-2">
        <div className="pipes-list">
          <span className="tx-14">{_.startCase(type)}</span>

          <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
            <div className="d-flex align-items-center gap-5 tx-14 text-muted flex-wrap text-capitalize">
              <ViewPipeConfig
                config={config}
                type={type}
                name={name}
                outputSchema={outputSchema}
              />
            </div>

            <div className="indicator-action">
              {this.renderEditBtn(pipeConfig, pipeConfigIndex)}
              {isDisable ? (
                <HoverMessage
                  title="Included in Signal"
                  message="Please remove in Signals and then Rename"
                >
                  <button
                    type="button"
                    className="delete-btn"
                    disabled={isDisable}
                    onClick={onRemovePipe(pipeConfigIndex)}
                  >
                    <i className="material-icons-outlined">delete_outline</i>
                  </button>
                </HoverMessage>
              ) : (
                <button
                  type="button"
                  className="delete-btn"
                  onClick={onRemovePipe(pipeConfigIndex)}
                >
                  <i className="material-icons-outlined">delete_outline</i>
                </button>
              )}

            </div>
          </div>

        </div>
      </div>
    );
  }

  render() {
    const { pipeConfigs } = this.props;

    if (_.isEmpty(pipeConfigs)) {
      return (<p className="text-danger text-center my-2">{I18n.t('no_indicator', I18N_SCOPE)}</p>);
    }

    return (
      <div className="row px-1 px-md-3">
        {_.map(pipeConfigs, this.renderPipe)}
      </div>
    );
  }
}

PipesList.propTypes = propTypes;
PipesList.defaultProps = defaultProps;
