import { extractIndividualSchemaField } from 'v2/modules/withRunForm/utils/signal';

export const getFormattedOperand = (operand, intents) => {
  const {
    position,
    field,
    label,
  } = extractIndividualSchemaField(operand);

  const findIntent = _.find(intents, (intent) => `${intent.identifier.label}` === `${label}`);
  const {
    identifier: {
      optionConfigIndex, instrumentIndex, segment, adjustmentConfigIndex
    }
  } = findIntent;

  let newSegment = segment;
  if (optionConfigIndex !== null) newSegment += `_${optionConfigIndex}`;
  if (adjustmentConfigIndex !== null) newSegment += `_${adjustmentConfigIndex}`;

  // TODO: Use `buildLegProperty` from ./config.js
  return `${position}.${instrumentIndex}.${newSegment}.${field}`;
};

export const getSanitizedAdjustmentSignals = (signal, intents) => {
  const newSignal = _.map(signal, (signalConfig) => {
    const { leftOperand, rightOperand } = signalConfig;
    const isIndividualStopIncludesLeftOperand = leftOperand.includes('individualStop.');
    const isIndividualStopIncludesRightOperand = rightOperand?.includes('individualStop.');

    let newLeftOperand = leftOperand;
    let newRightOperand = rightOperand;

    if (isIndividualStopIncludesLeftOperand) {
      newLeftOperand = getFormattedOperand(leftOperand, intents);
    }

    if (isIndividualStopIncludesRightOperand) {
      newRightOperand = getFormattedOperand(rightOperand, intents);
    }

    return {
      ...signalConfig,
      leftOperand: newLeftOperand,
      rightOperand: newRightOperand
    };
  });

  return newSignal;
};
