import React, { useContext, useCallback, useMemo } from 'react';
import { OrderContext } from '../../OrderStore/index';

const WeekDaysFilter = () => {
  const days = [
    { value: 'Monday', label: 'M' },
    { value: 'Tuesday', label: 'T' },
    { value: 'Wednesday', label: 'W' },
    { value: 'Thursday', label: 'Th' },
    { value: 'Friday', label: 'F' },
    { value: 'Saturday', label: 'Sa' },
  ];

  const { filterConfigs, setFilterConfigs } = useContext(OrderContext);
  const { weekDays, isWeekDaysFilterActive, isDaysToExpiryFilterActive } = filterConfigs;

  if (isDaysToExpiryFilterActive) return null;

  const toggleWeekDay = useCallback(
    (day) => {
      setFilterConfigs({
        ...filterConfigs,
        weekDays: _.includes(weekDays, day)
          ? _.filter(weekDays, (weekDay) => weekDay !== day)
          : [...weekDays, day],
      });
    },
    [filterConfigs, weekDays, setFilterConfigs]
  );

  const openFilter = useCallback(() => {
    setFilterConfigs({
      ...filterConfigs,
      isDaysToExpiryFilterActive: false,
      isWeekDaysFilterActive: true,
      daysToExpiry: [],
      weekDays: days.map((day) => day.value),
    });
  }, [filterConfigs, setFilterConfigs]);

  const closeFilter = useCallback(() => {
    setFilterConfigs({
      ...filterConfigs,
      isDaysToExpiryFilterActive: false,
      isWeekDaysFilterActive: false,
      weekDays: [],
    });
  }, [filterConfigs, setFilterConfigs]);

  const renderedOptions = useMemo(() => {
    return (
      <div>
        {
          days.map((day, idx) => (
            <label
              key={idx}
              htmlFor={`weekly_filters_${idx}`}
              className={`weekdays-filter-label p-1 m-1 border 
                ${_.includes(weekDays, day.value) ? 'checked' : ''}`}
            >
              <input
                hidden
                id={`weekly_filters_${idx}`}
                type="checkbox"
                value={day.value}
                name="weekDays"
                data-track-category="Week Days Filter"
                data-track-action={day.value}
                data-track-label="Week Days Filter"
                checked={_.includes(weekDays, day.value)}
                onChange={() => toggleWeekDay(day.value)}
                className="hidden"
              />
              {day.label}
            </label>
          ))
        }
        <button
          className="btn border mx-1 close-btn"
          data-toggle="tooltip"
          title="Clear Filter"
          type="button"
          onClick={closeFilter}
        >
          <i className="material-icons-outlined d-block tx-15">close</i>
        </button>
      </div>
    );
  }, [days, weekDays, toggleWeekDay]);

  return (
    <div className="weekdays-filter">
      <button
        className={`btn btn-md btn-outline-primary text-nowrap copy-btn track d-flex 
        weekdays-filter-button ${isWeekDaysFilterActive ? 'active' : ''}`}
        data-toggle="tooltip"
        title="WeekDays Filter"
        type="button"
        onClick={!isWeekDaysFilterActive ? openFilter : null}
      >
        WeekDays
      </button>

      {isWeekDaysFilterActive && <div className="label-container">{renderedOptions}</div>}
    </div>
  );
};

export default WeekDaysFilter;
