import { onGetCampaign, onCreateNewCampaignRegistration } from 'common/api/campaigns';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { getUrlParams } from 'common/utils/urlUtils';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

const CampaignRegistration = () => {
  const [config, setConfig] = useState({ email: '', phone_number: '' });
  const urlParams = getUrlParams();
  const campaignId = _.get(urlParams, 'campaign', null);
  const [campaignDetails, setCampaignDetails] = useState({});

  useEffect(() => {
    onGetCampaign(campaignId).then((response) => {
      setCampaignDetails(response);
    });
  }, []);

  const onSubmit = () => {
    let args = config;
    if (campaignId) { args = { ...args, campaign_id: campaignId }; }

    onCreateNewCampaignRegistration(args)
      .then(() => {
        reactToastify('Thank you for your registration', TOASTIFY_TYPES.SUCCESS);
        setTimeout(() => { window.location.href = '/'; }, 2000);
      }).catch((errorResults) => {
        errorResults.json().then(({ errors }) => {
          _.each(errors, (error, key) => {
            reactToastify(`${_.startCase(key)} - ${_.join(error, ', ')}`, TOASTIFY_TYPES.ERROR);
          });
        });
      });
  };

  const renderCampaignDetails = () => {
    if (_.isEmpty(campaignDetails)) return null;

    const { campaign_name: campaignName, campaign_html: campaignHtml } = campaignDetails;

    return (
      <div className="mt-4">
        <h2>{campaignName}</h2>
        {ReactHtmlParser(campaignHtml)}
      </div>
    );
  };

  return (
    <div>
      {renderCampaignDetails()}
      <h5 className="mb-4">Campagin Registration</h5>
      <div className="form-group">
        <label htmlFor="email">Email address</label>
        <input
          type="email"
          className="form-control"
          id="email"
          aria-describedby="emailHelp"
          placeholder="Enter email"
          value={config.email}
          onChange={(event) => { setConfig({ ...config, email: event.target.value }); }}
        />
        <small id="emailHelp" className="form-text text-muted">
          we will never share
          your email with anyone else.
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="phone_number">Phone Number</label>
        <input
          type="number"
          className="form-control"
          id="phone_number"
          placeholder="Enter phone number"
          value={config.phone_number}
          onChange={(event) => { setConfig({ ...config, phone_number: event.target.value }); }}
        />
      </div>
      <button type="submit" onClick={() => { onSubmit(); }} className="btn btn-primary">Submit</button>
    </div>
  );
};

CampaignRegistration.propTypes = {};

export default CampaignRegistration;
