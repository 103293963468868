import React, { useState } from 'react';
import { NOTIFICATION_EVENTS } from 'common/constants';
import { fetchOrders } from 'common/api/signalConfirmation';
import notificationPropType from './propTypes/notificationPropType';
import OrderDetailsInModal from './OrderDetailsInModal';

const propTypes = {
  notification: notificationPropType.isRequired
};

const defaultProps = {};

const ViewOrdersPanel = ({ notification }) => {
  const [isModal, hasShowModal] = useState(false);
  const [orders, setOrders] = useState([]);

  const { notifiable_type: notifiableType } = notification;

  if (NOTIFICATION_EVENTS.ORDER_CONFIRMATION.NAME !== notifiableType) return null;

  const {
    details: {
      run_id: runId,
      job_id: jobId,
      run_name: runName,
      order_type: orderType
    }, notifiable_id: orderConfirmationId
  } = notification;

  const fetchOrdersDetails = () => {
    fetchOrders({ runId, jobId, orderConfirmationId })
      .then((response) => {
        const { status, data } = response; // new orders and cancel
        if (status) {
          const { cancel_orders: cancelOrders, new_orders: newOrders } = data;
          setOrders([...orders, ...cancelOrders, ...newOrders]);
        }
        hasShowModal(true);
      });
  };
  return (
    <div className="notification-footer d-flex align-items-center justify-content-between">
      <span
        className="btn btn-link btn-sm p-0"
        onClick={() => fetchOrdersDetails()}
      >
        Show Details
      </span>
      {isModal && (
        <OrderDetailsInModal
          orders={orders}
          orderType={orderType}
          title={runName}
          onClose={() => {
            hasShowModal(false);
            setOrders([]);
          }}
        />
      )}
    </div>
  );
};

ViewOrdersPanel.defaultProps = defaultProps;
ViewOrdersPanel.propTypes = propTypes;

export default ViewOrdersPanel;
