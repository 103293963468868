import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { generateToken } from 'common/api/externalSignal';
import { EXTERNAL_SIGNALS_VENDOR_TYPES } from 'v2/common/constants/index';
import ReadOnlyField from '../ReadOnlyField';
import { getExternalSignalUrl } from './url';
import TradingView from './TradingView';
import ChartInk from './ChartInk';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  clearErrors: PropTypes.func.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  validator: PropTypes.shape({}).isRequired,
  pipeConfig: PropTypes.shape({
    name: PropTypes.string,
    config: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

const ExternalSignal = (props) => {
  const {
    onUpdatePipeConfig,
    name: propsName,
    value,
    pipeConfig,
    clearErrors
  } = props;

  const onGeneratePostback = useCallback(() => {
    clearErrors('read_only__');
    generateToken().then((response) => {
      const { status, token, id: externalSignalId } = response;
      if (!status) {
        reactToastify('Failed to generate postback', TOASTIFY_TYPES.ERROR);
        return;
      }
      onUpdatePipeConfig({ token, [propsName]: externalSignalId });
      reactToastify('Postback generated successfully', TOASTIFY_TYPES.SUCCESS);
    }).catch(() => {
      reactToastify('Failed to generate postback', TOASTIFY_TYPES.ERROR);
    });
  }, [clearErrors, onUpdatePipeConfig, propsName]);

  const onValidate = ({
    validate: () => {
      if (!value) {
        return 'External signal cannot be empty';
      }
      return null;
    }
  });

  const token = _.get(pipeConfig, 'config.token', '');
  const customValue = token ? getExternalSignalUrl(token) : token;
  const isTradingView = _.get(pipeConfig, 'config.vendor') === EXTERNAL_SIGNALS_VENDOR_TYPES.TradingView;

  return (
    <>
      <span className="mb-2">
        <ReadOnlyField {...props} validator={onValidate} value={customValue} />
      </span>
      <div>
        <button className="btn btn-primary" type="button" onClick={onGeneratePostback}>
          Generate Postback
        </button>
      </div>
      {isTradingView ? <TradingView /> : <ChartInk />}
    </>
  );
};

ExternalSignal.propTypes = propTypes;

export default ExternalSignal;
