import { SL_TRAIL_TYPES } from 'v2/common/constants/index';

export const getSanitizedStopLoss = (stopLoss) => {
  let trail = {};

  if (stopLoss?.trail?.type !== SL_TRAIL_TYPES.BookAndLinear) {
    trail = {
      type: SL_TRAIL_TYPES.Linear,
      trailingValue: stopLoss?.trail?.trailingValue || 0,
      adjustValue: stopLoss?.trail?.adjustValue || 0
    };
  }

  if (stopLoss?.trail?.type === SL_TRAIL_TYPES.BookAndLinear) {
    trail = {
      type: SL_TRAIL_TYPES.BookAndLinear,
      trailingValue: stopLoss?.trail?.trailingValue || 0,
      adjustValue: stopLoss?.trail?.adjustValue || 0,
      bookProfitOn: stopLoss?.trail?.bookProfitOn || 0,
      bookProfit: stopLoss?.trail?.bookProfit || 0
    };
  }

  return {
    ...stopLoss,
    trail
  };
};
