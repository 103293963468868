import { INTENT_TYPES } from 'v2/common/constants/index';
import { extractIdsFromLabel, extractIndividualSchemaField } from '../../utils/signal';

const getPipeIntentDependencies = (pipeConfig, cases) => {
  const { name, config: { legs: intents } } = pipeConfig;

  const pipeIntentDependencies = {};
  const dependentLabels = cases.flatMap(
    ({ entry: { intents: entryIntents, signal: entrySignals }, adjustments }) => {
      const allIntents = [
        ...entryIntents,
        ...adjustments.flatMap(({ intents: adjustmentIntents }) => adjustmentIntents)
      ];
      const allSignals = [
        ...entrySignals,
        ...adjustments.flatMap(({ signal: adjustmentSignals }) => adjustmentSignals)
      ];

      const openBasketIntents = allIntents.filter(
        (intent) => intent?.type === INTENT_TYPES.OpenBasket && intent.name === name
      );

      return openBasketIntents.map((openBasketIntent) => {
        const { identifier: { label: openBasketLabel } } = openBasketIntent;
        const filteredIntentLabel = allIntents.flatMap((intent) => {
          const { identifier: { label } } = intent;
          const { intentId } = extractIdsFromLabel(label || '');
          const { intentId: targetIntentId } = extractIdsFromLabel(_.get(intent, 'target.label', ''));
          if (intentId?.includes(openBasketLabel)) {
            return [label];
          }
          if (targetIntentId?.includes(openBasketLabel)) {
            return [_.get(intent, 'target.label', '')];
          }
          return [];
        }).filter(Boolean);

        const filteredSignalLabels = allSignals.flatMap(({ leftOperand, rightOperand }) => {
          return [leftOperand, rightOperand].flatMap((operand) => {
            const { intentId, label } = extractIndividualSchemaField(operand);
            if (intentId?.includes(openBasketLabel)) {
              return [label];
            }
            return [];
          });
        }).filter(Boolean);

        return [...filteredIntentLabel, ...filteredSignalLabels];
      });
    }
  ).flat().filter(Boolean);

  intents.forEach((intent) => {
    const { identifier: { label: intentLabel } } = intent;
    pipeIntentDependencies[intentLabel] = {
      isDependent: dependentLabels.some((label) => extractIdsFromLabel(label).basketId?.includes(intentLabel))
    };
  });

  return pipeIntentDependencies;
};

export default getPipeIntentDependencies;
