import React from 'react';
import PropTypes from 'prop-types';
import { removeExchangeFromInstrument } from 'common/utils/InstrumentUtils';
import moment from 'moment';

const propTypes = {
  instrumentGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
  timestamp: PropTypes.string.isRequired
};
const defaultProps = {};

const Header = ({ instrumentGroup, timestamp }) => {
  const oneIndentation = ' ';

  const renderTime = () => {
    if (!timestamp) return <></>;

    return (
      <th>
        <h6>
          Updated at:
          {oneIndentation}
          {moment(timestamp).utc().format('YYYY-MM-DD hh:mm:ss A')}
        </h6>
      </th>
    );
  };

  return (
    <thead className="bg-light">
      <tr>
        {renderTime()}
        <th colSpan={!timestamp ? '2' : '1'} className="border-bottom-0" />
        {_.map(instrumentGroup, (instrument, idx) => (
          <th
            className="text-right border-bottom-0"
            key={idx}
          >
            <h6>{removeExchangeFromInstrument(instrument)}</h6>
          </th>
        ))}
      </tr>
    </thead>
  );
};

Header.defaultProps = defaultProps;
Header.propTypes = propTypes;

export default Header;
