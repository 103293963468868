import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import { useForm, FormProvider } from 'react-hook-form';
import FolderSelector from 'common/components/FolderSelector';
import { moveRunToAnotherFolder } from 'common/api/run';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  runIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  folderName: PropTypes.string.isRequired,
  displayName: PropTypes.string
};

const defaultProps = { displayName: '' };

const MoveFolderModal = ({
  onClose, runIds, folderName, displayName
}) => {
  const methods = useForm();
  const oneIndentation = ' ';

  const [selectedFolder, setSelectedFolder] = useState(undefined);
  const [isValid, setIsValid] = useState(true);

  const onSave = () => {
    if (!selectedFolder) return setIsValid(false);

    const { id } = selectedFolder;

    moveRunToAnotherFolder(
      { run_ids: runIds, folder_id: id }
    ).then((result) => {
      const { status } = result;
      if (status) window.location.href = `/folders?folder_id=${id}`;
    });

    return null;
  };

  return (
    <FormProvider {...methods}>
      <Modal
        onClose={onClose}
        title="Move to another folder"
        size="md"
        onSave={() => onSave()}
        okBtnTitle="Move"
      >
        <>
          <div className="mb-3 d-flex align-items-center">
            Move
            {oneIndentation}
            <i className="material-icons-outlined tx-20 text-muted">arrow_right</i>
            <b>
              {oneIndentation}
              {folderName}
            </b>
            {displayName && (
            <>
              <i className="material-icons-outlined tx-20 text-muted">arrow_right</i>
              <b className="mr-1">
                {displayName}
              </b>
            </>
            )}
            <span className="ml-2">to</span>
            <b>
              {selectedFolder ? selectedFolder.name : null}
            </b>
          </div>
          <label>Select the Folder name</label>
          <FolderSelector
            selectedFolder={selectedFolder}
            isUserLoggedIn
            onSelect={(newFolder) => setSelectedFolder(newFolder)}
          />
          {!isValid && <div className="invalid-feedback d-block">*Please enter the folder name</div>}
        </>
      </Modal>
    </FormProvider>
  );
};

MoveFolderModal.propTypes = propTypes;
MoveFolderModal.defaultProps = defaultProps;

export default MoveFolderModal;
