import { INTENT_TYPES, POSITION_VALUE_TYPES } from 'v2/common/constants/index';

export const getBasketOpenLegIntentsBasedOnIntents = (intents, pipeConfigs) => {
  const [basketIntents, remainingIntents] = _.partition(
    intents, ({ type }) => type === INTENT_TYPES.OpenBasket
  );

  const basketBasedOpenLegIntents = _.flatMap(basketIntents,
    ({ name: bName, multiplier, identifier: { label: basketLabel } }) => {
      const {
        config: { legs = [], when = {} } = {}
      } = _.find(pipeConfigs, ({ name }) => name === bName) || {};

      return legs.map((intent) => {
        const { identifier: { label } } = intent;
        const instrumentConfig = _.get(intent, 'instrument', {});

        return {
          ..._.omit(intent, ['instrument']),
          instrument: { type: 'capture', subSelection: instrumentConfig, when },
          identifier: { label: `${basketLabel}(${label})` },
          quantity: {
            type: intent?.quantity?.type ?? POSITION_VALUE_TYPES.Lot,
            value: Number(intent.quantity?.value ?? intent.quantity) * Number(multiplier),
          },
          uiConfigs: { isBasketIntent: true, name: bName }
        };
      });
    });

  return [...basketIntents, ...remainingIntents, ...basketBasedOpenLegIntents];
};
