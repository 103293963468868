import React from 'react';
import PropTypes from 'prop-types';

import {
  signalConfigPropTypes, outputSchemaPropTypes
} from 'common/propTypes';
import ConditionOperator from './ConditionOperator';
import OperatorAndRightOperand from './OperatorAndRightOperand';
import LeftOperand from './LeftOperand';

const propTypes = {
  isClonedQuant: PropTypes.bool.isRequired,
  shouldShowConditionOperator: PropTypes.bool.isRequired,
  signalConfig: signalConfigPropTypes.isRequired,
  signalValidator: PropTypes.shape({
    conditionOperator: PropTypes.shape({}),
  }),
  outputSchema: outputSchemaPropTypes.isRequired,
  onSignalConfigChange: PropTypes.func.isRequired,
  signalConfigField: PropTypes.string.isRequired,
  isHistoricRun: PropTypes.bool.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string),
  segment: PropTypes.string.isRequired,
  isAdjustmentSignal: PropTypes.bool
};

const defaultProps = {
  signalValidator: {},
  segments: [],
  isAdjustmentSignal: false
};

const SignalConfigBuilder = (props) => {
  const {
    isClonedQuant,
    shouldShowConditionOperator,
    signalConfig,
    signalValidator,
    outputSchema,
    onSignalConfigChange,
    signalConfigField,
    isHistoricRun,
    segments,
    segment,
    isAdjustmentSignal
  } = props;

  const { leftOperand, operator, rightOperand } = signalConfig;

  const onUpdateSignalConfig = (partialNewConfig) => {
    onSignalConfigChange({
      ...signalConfig,
      ...partialNewConfig
    });
  };

  return (
    <div className="transaction-signal flex-grow-1">
      {shouldShowConditionOperator && (
        <ConditionOperator
          signalConfig={signalConfig}
          logicalOperatorValidator={signalValidator.conditionOperator}
          onUpdateSignalConfig={onUpdateSignalConfig}
        />
      )}
      <div className="signal-wrapper">
        <div>
          <LeftOperand
            isClonedQuant={isClonedQuant}
            leftOperand={leftOperand}
            leftOperandValidator={_.get(signalValidator, 'leftOperand', {})}
            onUpdateSignalConfig={onUpdateSignalConfig}
            signalConfigField={signalConfigField}
            outputSchema={outputSchema}
            segments={segments}
            segment={segment}
            isAdjustmentSignal={isAdjustmentSignal}
          />
        </div>

        <OperatorAndRightOperand
          leftOperand={leftOperand}
          operator={operator}
          signalValidator={signalValidator}
          rightOperand={rightOperand}
          outputSchema={outputSchema}
          onOperatorChange={(newOperator) => onUpdateSignalConfig({ operator: newOperator })}
          onRightOperandChange={(newRightOperand) => onUpdateSignalConfig({
            rightOperand: newRightOperand
          })}
          isHistoricRun={isHistoricRun}
          segments={segments}
          segment={segment}
          isAdjustmentSignal={isAdjustmentSignal}
        />
      </div>
    </div>
  );
};

export default SignalConfigBuilder;

SignalConfigBuilder.propTypes = propTypes;
SignalConfigBuilder.defaultProps = defaultProps;
