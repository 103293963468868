import React from 'react';
import PropTypes from 'prop-types';

import { transactionConfigPropTypes } from 'common/propTypes';
import {
  existingLegActionTypes, getStrikeDisplayName, waitAndTradeTypesAndNode
} from 'modules/TransactionsBuilder/configs';
import withErrorBoundary from 'common/components/withErrorBoundary/index';

const propTypes = {
  transactionConfigs: PropTypes.arrayOf(transactionConfigPropTypes).isRequired,
};
const defaultProps = {};

const renderTrailingStopLossConfig = (config) => {
  const trailingStopLoss = _.get(config, 'trailingStopLoss', {});
  if (_.isEmpty(trailingStopLoss)) return null;

  const { trailingPercentage, adjustPercentage } = trailingStopLoss;

  return ` and TSL X(${trailingPercentage}%), Y(${adjustPercentage}%)`;
};

const renderWaitAndTrade = (config) => {
  const waitAndTradeType = _.get(config, 'waitAndTradeType', '');
  const waitUntilPremiumToIncreaseInPercentage = _.get(config, 'waitUntilPremiumToIncreaseInPercentage', 0);
  const waitUntilPremiumToDecreaseInPercentage = _.get(config, 'waitUntilPremiumToDecreaseInPercentage', 0);
  if (!waitUntilPremiumToIncreaseInPercentage && !waitUntilPremiumToDecreaseInPercentage) return null;

  if (waitAndTradeType === waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE) {
    return ` and WaitandTrade Decrease - ${waitUntilPremiumToDecreaseInPercentage}%`;
  }

  return ` and WaitandTrade Increase - ${waitUntilPremiumToIncreaseInPercentage}%`;
};

const renderReExecute = (config) => {
  const reExecuteCount = _.get(config, 'reExecuteCount', 0);
  if (!reExecuteCount) return null;

  return ` and Re-Execute ${reExecuteCount}`;
};

const renderReEntry = (config) => {
  const reEntryCount = _.get(config, 'reEntryCount', 0);
  if (!reEntryCount) return null;

  return ` and Re-Entry ${reEntryCount}`;
};

const renderStopConfig = (config) => {
  const {
    stopLoss, stopGain, stopLossPrice, stopGainPrice
  } = config;
  if (!_.isUndefined(stopLoss) && !_.isUndefined(stopGain)) {
    return `with SL ${stopLoss}% and TP ${stopGain}%`;
  }
  if (!_.isUndefined(stopLossPrice) && !_.isUndefined(stopGainPrice)) {
    return `with SL ${stopLossPrice}₹ and TP ${stopGainPrice}₹`;
  }
  if (!_.isUndefined(stopLoss) && !_.isUndefined(stopGainPrice)) {
    return `with SL ${stopLoss}% and TP ${stopGainPrice}₹`;
  }
  if (!_.isUndefined(stopGain) && !_.isUndefined(stopLossPrice)) {
    return `with SL ${stopLossPrice}₹ and TP ${stopGain}%`;
  }
  if (!_.isUndefined(stopLoss)) return `with SL ${stopLoss}%`;
  if (!_.isUndefined(stopGain)) return `with TP ${stopGain}%`;
  if (!_.isUndefined(stopLossPrice)) return `with SL ${stopLossPrice}₹`;
  if (!_.isUndefined(stopGainPrice)) return `with TP ${stopGainPrice}₹`;
  return null;
};

const renderConfig = (config, type = 'Futures') => {
  if (!config) { return null; }
  const {
    quantityExpression, entryType, quantity, amount
  } = config;
  if (entryType !== 'buy' && entryType !== 'sell') {
    return null;
  }

  // quantityExpression is used for future
  // quantity is used for equity
  const renderQuantity = () => {
    const quantityDetail = quantityExpression || quantity || '';
    if (!quantityDetail) return null;

    return (
      <>
        {quantityDetail}
        &nbsp;
        {type === 'Equity' ? 'quantity' : 'lot'}
      </>
    );
  };

  const renderAmount = () => {
    if (!amount) return null;

    return (
      <>
        {amount}
        &nbsp;
        <span>&#8377;</span>
      </>
    );
  };

  return (
    <span className="badge badge-light badge-pill mr-1 border" key={`${type}-order-details`}>
      {_.startCase(entryType)}
      &nbsp;
      {renderQuantity()}
      {renderAmount()}
      &nbsp;
      {type}
      &nbsp;
      {renderStopConfig(config)}
      {renderTrailingStopLossConfig(config)}
      {renderReExecute(config)}
      {renderReEntry(config)}
      {renderWaitAndTrade(config)}
    </span>
  );
};

const renderOptionConfig = (optionConfig, index) => {
  const {
    quantity, entryType, optionType
  } = optionConfig;
  if (
    optionType !== 'CE' && optionType !== 'PE'
    && entryType !== 'buy' && entryType !== 'sell'
  ) {
    return null;
  }
  const strikeStatement = _.join(getStrikeDisplayName(optionConfig), ' ');

  return (
    <span className="badge badge-light badge-pill mr-1 border" key={`option-order-details-${index}`}>
      {_.startCase(entryType)}
      &nbsp;
      {quantity}
      &nbsp;
      <span className="font-weight-normal">lot</span>
      &nbsp;
      {strikeStatement}
      &nbsp;
      {optionType}
      &nbsp;
      <span className="font-weight-normal">options</span>
      &nbsp;
      {renderStopConfig(optionConfig)}
      {renderTrailingStopLossConfig(optionConfig)}
      {renderReExecute(optionConfig)}
      {renderReEntry(optionConfig)}
      {renderWaitAndTrade(optionConfig)}
    </span>
  );
};

const renderExistingLegConfigs = (existingLegConfigs) => {
  if (_.isEmpty(existingLegConfigs)) return null;
  const renderExistingLegConfig = (existingLegConfig, idx) => {
    const { legType, action, optionConfigIndex } = existingLegConfig;
    if (!legType && !action && !optionConfigIndex) return null;

    return (
      <span className="badge badge-light badge-pill mr-1 border" key={`existingLegConfig-${idx}`}>
        {_.size(existingLegConfigs) !== 1 && (
          <span className="font-weight-bold">
            Existing leg
            {idx + 1}
            :
          </span>
        )}
        &nbsp;
        {_.get(_.find(existingLegActionTypes, ({ value }) => value === action), 'label', action)}
        &nbsp;
        <b>{legType}</b>
        &nbsp;
        {optionConfigIndex !== null && `Leg ${optionConfigIndex + 1}`}
      </span>
    );
  };

  return _.compact(_.map(existingLegConfigs, renderExistingLegConfig));
};

const renderTransactionView = (transactionConfig) => {
  const { futureConfig, optionConfigs, equityConfig } = transactionConfig;
  const existingLegConfigs = _.get(transactionConfig, 'existingLegConfigs', []);

  let contents = [renderConfig(futureConfig, 'Futures')]; // futures
  contents = contents.concat(renderConfig(equityConfig, 'Equity')); // Equity
  contents = contents.concat(_.map(optionConfigs, renderOptionConfig)); // options
  contents = contents.concat(renderExistingLegConfigs(existingLegConfigs)); // existingLegConfigs
  contents = _.compact(contents);
  if (_.isEmpty(contents)) { contents.push('(None)'); }

  return <div className="transaction-view">{contents}</div>;
};

const TransactionsView = (props) => {
  const { transactionConfigs } = props;
  const transactionConfigsContent = _.map(transactionConfigs, (transactionConfig, index) => {
    if (transactionConfigs.length === 1) {
      return <span key={`transaction-config${index}`}>{renderTransactionView(transactionConfig)}</span>;
    }

    return (
      <div key={`transaction-config${index}`}>
        <div className="font-weight-semi mr-4 mb-1">
          Instrument
          {index + 1}
          :
        </div>
        {renderTransactionView(transactionConfig)}
      </div>
    );
  });
  return <div className="transaction-views d-flex flex-column gap-15">{transactionConfigsContent}</div>;
};

TransactionsView.propTypes = propTypes;
TransactionsView.defaultProps = defaultProps;

export default withErrorBoundary(TransactionsView);
