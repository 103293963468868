import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ChartJsGraph from 'common/components/ChartJsGraph';

const propTypes = {
  userAndStrategyDetails: PropTypes.arrayOf(PropTypes.shape({})),
  orders: PropTypes.arrayOf(PropTypes.shape({}))
};

const defaultProps = { userAndStrategyDetails: [], orders: [] };

const HeaderDetails = ({ userAndStrategyDetails, orders }) => {
  const groupedByOrdersDates = _.groupBy(orders, (order) => moment(order.created_at).format('YYYY-MM-DD'));
  const chartDataSetsForOrders = [
    {
      label: 'Orders By Date',
      data: _.map(groupedByOrdersDates, (groupedByOrdersDate) => _.size(groupedByOrdersDate)),
      fill: true,
      backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
      hoverOffset: 4
    },
  ];

  const chartDataSetsForStrategies = [
    {
      label: 'Permitted Strategy Based On Users',
      data: _.map(userAndStrategyDetails, ({ strategy_access: strategyAccess }) => {
        return _.size(strategyAccess);
      }),
      fill: true,
      backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
      hoverOffset: 4
    },
  ];

  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <ChartJsGraph
            chartType="Bar"
            labels={_.keys(groupedByOrdersDates)}
            datasets={chartDataSetsForOrders}
          />
        </div>
        <div className="col-sm-6">
          <ChartJsGraph
            labels={_.map(userAndStrategyDetails, ({ user: { broker_client_id: brokerClientId } }) => {
              return brokerClientId;
            })}
            datasets={chartDataSetsForStrategies}
          />
        </div>
      </div>
    </div>
  );
};

HeaderDetails.propTypes = propTypes;
HeaderDetails.defaultProps = defaultProps;

export default HeaderDetails;
