import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import { STOPS_VALUE_TYPES } from 'v2/common/constants/index';

const propTypes = {
  stopsType: PropTypes.string.isRequired,
  stopsValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onStopsValueChange: PropTypes.func.isRequired,
  shouldIgnorePoints: PropTypes.bool,
  shouldIgnoreUnderlyingSpot: PropTypes.bool,
  origin: PropTypes.string
};
const defaultProps = {
  stopsValue: undefined,
  shouldIgnorePoints: false,
  shouldIgnoreUnderlyingSpot: false,
  origin: 'self'
};

const StopsInput = ({
  stopsType, stopsValue, onStopsValueChange,
  shouldIgnorePoints,
  shouldIgnoreUnderlyingSpot,
  origin
}) => {
  const { Percent, Amount, Points } = STOPS_VALUE_TYPES;
  const options = [
    {
      name: Percent, label: '%', origin: 'self', isShow: true
    },
    {
      name: Amount, label: '₹', origin: 'self', isShow: true
    },
    {
      name: Points, label: 'Pts', origin: 'self', isShow: !shouldIgnorePoints
    },
    {
      name: Percent, label: 'Spot %', origin: 'underlyingSpot', isShow: !shouldIgnoreUnderlyingSpot
    },
    {
      name: Points,
      label: 'Spot Pts',
      origin: 'underlyingSpot',
      isShow: !shouldIgnoreUnderlyingSpot && !shouldIgnorePoints
    }
  ];

  const valueType = _.includes([Percent, Amount, Points], stopsType)
    ? `${stopsType}-${origin}`
    : `${Amount}-${origin}`;

  const [value, setValue] = useState(stopsValue);

  useEffect(() => { setValue(stopsValue); }, [stopsValue]);

  const onValueTypeChange = (newStopsValueWithOrigin) => {
    const [newStopsValue, newOrigin] = newStopsValueWithOrigin.split('-');
    const commonConfig = { type: newStopsValue, value: stopsValue };

    return onStopsValueChange({
      ...commonConfig,
      origin: { type: newOrigin }
    });
  };

  const onChange = (event) => {
    const [newStopsValue, newOrigin] = valueType.split('-');
    const commonConfig = { type: newStopsValue, value: onRegexOnlyNumberAndFloat(event.target.value, false) };

    return onStopsValueChange({
      ...commonConfig,
      origin: { type: newOrigin }
    });
  };

  return (
    <div className="input-group overall-stops">
      <select
        value={valueType}
        className={origin !== 'self' ? 'elaborate' : ''}
        onChange={(event) => onValueTypeChange(event.target.value)}
      >
        {_.map(options, ({
          name, label, origin: staticOrigin, isShow
        }, idx) => {
          if (!isShow) return null;
          return <option value={`${name}-${staticOrigin}`} key={idx}>{label}</option>;
        })}
      </select>
      <input
        type="text"
        className="form-control form-control-sm"
        name="stopsInput"
        data-stoploss-field-name={`${stopsType}-${valueType}`}
        onBlur={(event) => onChange(event, false)}
        value={(value === undefined || !value) ? '' : value.toString()}
        onChange={(event) => { setValue(onRegexOnlyNumberAndFloat(event.target.value, true)); }}
      />
    </div>
  );
};

StopsInput.propTypes = propTypes;
StopsInput.defaultProps = defaultProps;

export default StopsInput;
