import qs from 'qs';
import wrappedFetch from './base';

export const fetchCoupon = (args) => {
  const queryParams = qs.stringify({ ...args });
  const uri = `/coupons/available?${queryParams}`;

  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};

export const fetchPreApplyCoupon = () => {
  const uri = '/coupons/preapply_coupon';

  const params = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};
