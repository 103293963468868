import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';

export const onChangeGlobalSegment = ({
  uiConfigs,
  pipeConfigs
}) => {
  const { segment } = uiConfigs;

  const newPipeConfigs = _.map(pipeConfigs, (pipeConfig) => {
    const supportedSegments = PipeDefinitionUtility.getPipeValuePathSupportedSegments(pipeConfig?.type);
    const valuePaths = _.get(pipeConfig, 'config.valuePaths', []);
    const isEmptyValuePath = _.isEmpty(valuePaths);
    const newSegmentValuePaths = _.map(valuePaths, (valuePath) => {
      const type = _.get(valuePath.split('.'), '1', '');
      if (type === 'month1Price'
          && segment === SEGMENT_CONFIG.equity
          && _.includes(supportedSegments, SEGMENT_CONFIG.equity)
      ) {
        return valuePath.replace('month1Price', 'equity');
      }
      if (type === 'equity'
          && segment === SEGMENT_CONFIG.future
          && _.includes(supportedSegments, SEGMENT_CONFIG.future)
      ) {
        return valuePath.replace('equity', 'month1Price');
      }
      return valuePath;
    });
    return isEmptyValuePath ? pipeConfig : {
      ...pipeConfig, config: { ...pipeConfig.config, valuePaths: newSegmentValuePaths }
    };
  });

  return { pipeConfigs: newPipeConfigs, uiConfigs };
};
