import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const propTypes = {
  isEntry: PropTypes.bool.isRequired,
  onEntryExitChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const EntryExitSelector = ({ isEntry, onEntryExitChange }) => {
  const entryExitTypes = ['entry', 'exit'];

  const renderGroupBtns = (type) => (
    <ToggleButton
      key={type}
      bsPrefix="btn btn-outline-primary btn-lightness"
      value={type === 'entry'}
    >
      {_.startCase(type)}
    </ToggleButton>
  );

  return (
    <ToggleButtonGroup
      bsPrefix="btn-group btn-group-toggle ml-auto"
      type="radio"
      name="group-selector"
      value={isEntry}
      onChange={onEntryExitChange}
    >
      {_.map(entryExitTypes, renderGroupBtns)}
    </ToggleButtonGroup>
  );
};

EntryExitSelector.propTypes = propTypes;
EntryExitSelector.defaultProps = defaultProps;

export default EntryExitSelector;
