import React from 'react';
import ProductType from './ProductType/index';
import OrderType from './OrderType/index';
import StopLimitPriceDifference from './StopLossLimitPriceDifference/index';
import LimitOrderBuffer from './LimitOrderBuffer/index';
import MarginBenefit from './MarginBenefit/index';
import SlOrderBasedOnPrice from './SlOrderBasedOnPrice/index';
import useOrderExecutionDetails from '../../hooks/useOrderExecutionDetails/index';
import PlaceStoplossOrderToBroker from './PlaceStoplossOrderToBroker/index';
import SquareOffEntireTransactionIfEntryFailed from './SquareOffEntireTransactionIfEntryFailed/index';

const OrderExecutionDetails = () => {
  const {
    product,
    onChangeProduct,
    isShowOrderExectionDetails,
    entryOrderType,
    entryOrderModification,
    exitOrderType,
    exitOrderModification,
    onChangeOrderTypeConfigs,
    isShowBufferPercentage,
    stopAndLimitPriceDifferenceInPercentage,
    onChangeStopAndLimitPriceDifferenceInPercentage,
    limitOrderBufferPercentage,
    onChangeLimitOrderBufferPercentage,
    isPlaceOptionsEntryBuyOrdersFirst,
    onChangeIsPlaceOptionsEntryBuyOrdersFirst,
    slOrderPlaceBasedOnPrice,
    onChangeSlOrderPlaceBasedOnPrice,
    isPlaceStoplossOrderToBroker,
    onChangeIsPlaceStoplossOrderToBroker,
    isSquareOffTransactionIfEntryFailed,
    onChangeIsSquareOffTransactionIfEntryFailed
  } = useOrderExecutionDetails();

  if (!isShowOrderExectionDetails) {
    return null;
  }

  return (

    <div className="order_execution_details p-4 py-5">
      <div className="mb-2 mb-md-5 font-weight-medium tx-15">Order execution Details</div>
      <ProductType product={product} onChangeProduct={onChangeProduct} />
      <OrderType
        entryOrderType={entryOrderType}
        entryOrderModification={entryOrderModification}
        exitOrderType={exitOrderType}
        exitOrderModification={exitOrderModification}
        onChangeOrderTypeConfigs={onChangeOrderTypeConfigs}
      />
      <StopLimitPriceDifference
        stopAndLimitPriceDifferenceInPercentage={stopAndLimitPriceDifferenceInPercentage}
        onChangeStopAndLimitPriceDifferenceInPercentage={
          onChangeStopAndLimitPriceDifferenceInPercentage
        }
      />
      {isShowBufferPercentage
          && (
            <LimitOrderBuffer
              limitOrderBufferPercentage={limitOrderBufferPercentage}
              onChangeLimitOrderBufferPercentage={onChangeLimitOrderBufferPercentage}
            />
          )}
      <MarginBenefit
        isPlaceOptionsEntryBuyOrdersFirst={isPlaceOptionsEntryBuyOrdersFirst}
        onChangeIsPlaceOptionsEntryBuyOrdersFirst={onChangeIsPlaceOptionsEntryBuyOrdersFirst}
      />
      <SlOrderBasedOnPrice
        slOrderPlaceBasedOnPrice={slOrderPlaceBasedOnPrice}
        onChangeSlOrderPlaceBasedOnPrice={onChangeSlOrderPlaceBasedOnPrice}
      />
      <PlaceStoplossOrderToBroker
        isPlaceStoplossOrderToBroker={isPlaceStoplossOrderToBroker}
        onChange={onChangeIsPlaceStoplossOrderToBroker}
      />
      <SquareOffEntireTransactionIfEntryFailed
        isSquareOffTransactionIfEntryFailed={isSquareOffTransactionIfEntryFailed}
        onChange={onChangeIsSquareOffTransactionIfEntryFailed}
      />
    </div>
  );
};

export default OrderExecutionDetails;
