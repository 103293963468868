import React from 'react';
import PropTypes from 'prop-types';

import Pagination from 'common/components/Pagination';
import { transactionPropTypes } from 'common/propTypes';
import withErrorBoundary from '../withErrorBoundary/index';

const propTypes = {
  total: PropTypes.arrayOf(transactionPropTypes).isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const PaginationFooter = ({
  total, currentPageIndex, perPage, onCurrentPageChange
}) => {
  const totalLength = total.length;
  const totalPages = totalLength / perPage;
  const startOfRange = perPage * currentPageIndex;
  const endOfRange = startOfRange + perPage < totalLength
    ? startOfRange + perPage
    : totalLength;

  return (
    <div className="total-footer">
      <div className="count-show align-self-center">
        {`Showing ${startOfRange + 1} to ${endOfRange} of ${totalLength} entries`}
      </div>

      <div>
        {totalLength > perPage
          && (
            <Pagination
              totalPages={totalPages}
              onPageChange={({ selected: selectedPage }) => onCurrentPageChange(selectedPage)}
              currentPageIndex={currentPageIndex}
            />
          )}
      </div>
    </div>
  );
};

PaginationFooter.propTypes = propTypes;
PaginationFooter.defaultProps = defaultProps;

export default withErrorBoundary(PaginationFooter);
