import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { RUN_TYPES } from 'v2/common/constants/index';
import { PIPE_CATEGORIES } from 'v2/common/quantConfig/index';
import { getIntentsError } from 'v2/modules/withRunForm/hooks/useIntents/getIntentsError';
import { getSanitizedStopLoss } from './stopLoss';
import { getSanitizedAdjustmentSignals } from './adjustment/signal';

const defaultIdentifierExtraConfig = {
  type: 'Legacy',
  segment: 'option',
  caseIndex: -1,
  adjustmentConfigIndex: null,
  instrumentIndex: -1,
  optionConfigIndex: -1,
};

const getSanitizedIntent = (intent) => {
  return {
    ...intent,
    identifier: {
      ...defaultIdentifierExtraConfig,
      ...intent.identifier,
      label: `${intent.identifier?.label}`,
    },
  };
};

const throwIntentsError = (intents, instrumentGroups, pipeName) => {
  const { errorMessage } = getIntentsError(intents, instrumentGroups);

  if (errorMessage) {
    reactToastify(
      `Indicator Error (${pipeName}): ${errorMessage}`,
      TOASTIFY_TYPES.ERROR
    );
    throw new Error(errorMessage);
  }
};

const getSanitizedSignals = (signals, intents) => {
  return getSanitizedAdjustmentSignals(signals, intents);
};

export const getSanitizedPipes = (
  pipeConfigs,
  instrumentGroups,
  runType,
  sanitizedCases
) => {
  const allIntents = _.chain(sanitizedCases)
    .map(({ entry: { intents: baseIntents }, adjustments }) => {
      return _.chain(adjustments).map('intents').concat(baseIntents).value();
    })
    .flattenDeep()
    .value();

  // Engine requires customSnippet type as customScript
  const isExternalSignalPresent = _.some(
    pipeConfigs,
    ({ type }) => type === 'externalSignal'
  );

  if (isExternalSignalPresent && runType === RUN_TYPES.historic) {
    reactToastify(
      'External Signal Indicator is not supported for historic run',
      TOASTIFY_TYPES.ERROR
    );
    throw new Error(
      'External Signal Indicator is not supported for historic run'
    );
  }

  return _.map(pipeConfigs, (pipeConfig) => {
    let newPipeConfig = pipeConfig;
    if (newPipeConfig?.category === PIPE_CATEGORIES.snippet) {
      return { ...pipeConfig, type: 'customScript' };
    }

    if (newPipeConfig?.type === 'transactionStopLoss') {
      return {
        ...newPipeConfig,
        config: {
          stopLoss: getSanitizedStopLoss(newPipeConfig?.config?.stopLoss),
        },
      };
    }
    if (!_.isEmpty(newPipeConfig?.config?.legs)) {
      const intents = newPipeConfig?.config?.legs || [];
      const newIntents = _.map(intents, getSanitizedIntent);
      throwIntentsError(newIntents, instrumentGroups, newPipeConfig?.name);

      newPipeConfig = {
        ...newPipeConfig,
        config: { ...newPipeConfig.config, legs: newIntents },
      };
    }

    if (!_.isEmpty(newPipeConfig?.config?.when)) {
      const signals = newPipeConfig?.config?.when;

      newPipeConfig = {
        ...newPipeConfig,
        config: {
          ...newPipeConfig.config,
          when: getSanitizedSignals(signals, allIntents),
        },
      };
    }

    if (!_.isEmpty(newPipeConfig?.config?.signal)) {
      const signals = newPipeConfig?.config?.signal;

      newPipeConfig = {
        ...newPipeConfig,
        config: {
          ...newPipeConfig.config,
          signal: getSanitizedSignals(signals, allIntents),
        },
      };
    }

    return newPipeConfig;
  });
};
