import React, { useEffect, useMemo, useState } from 'react';
import PropTyps from 'prop-types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import HoverMessage from 'common/components/HoverMessage';
import ReactHtmlParser from 'react-html-parser';

const propTypes = {
  executionType: PropTyps.string.isRequired,
  onChange: PropTyps.func.isRequired,
};

const EXECUTION_TYPES = {
  nextMinute: 'Next Minute Start',
  immediate: 'Immediate',
  delayBy: 'Delay By' // While update state, will replace delayBy with delayBySeconds
};

const RenderExecutionType = ({ executionType, onChange }) => {
  const uniqueId = useMemo(() => _.uniqueId('execution_type_'), []);
  const [isShowHoverMessage, setIsShowHoverMessage] = useState(false);
  const { errors, setError, clearErrors } = useFormContext();

  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  useEffect(() => {
    if (isShowHoverMessage) { setTimeout(() => { setIsShowHoverMessage(false); }, 5000); }
  }, [isShowHoverMessage]);

  const isPermissionToChoose = _.get(window, 'SA_FEATURE_FLAGS.shouldShowAdjustmentExectionType', false)
  || window?.isAdmin || false;

  useEffect(() => {
    if (!isPermissionToChoose) {
      if (executionType !== 'nextMinute') {
        let message = "You don't have permission to choose this type, ";
        message += 'Please purchase a premium plan to use this feature';
        setIsShowHoverMessage(true);
        setError(uniqueId, { type: 'custom', message });
      }
    } else {
      clearErrors(uniqueId);
    }

    return () => clearErrors(uniqueId);
  }, [clearErrors, executionType, isPermissionToChoose, setError, uniqueId]);

  const renderOptions = (key, value) => {
    return <option value={key} key={key}>{value}</option>;
  };

  const selectClassNames = classNames('form-control form-control-sm', {
    'is-invalid': errors[uniqueId],
  });

  const renderSelect = (
    <select
      className={selectClassNames}
      value={executionType}
      onChange={(e) => onChange(e.target.value)}
    >
      {_.map(EXECUTION_TYPES, (value, key) => renderOptions(key, value))}
    </select>
  );

  const renderMessage = '<b> Please purchase a <b>Premium plan to use this feature.</b>';
  const renderHoverMessage = (
    <HoverMessage
      title="Premium Plan Required"
      message={ReactHtmlParser(renderMessage)}
      isOpen={isShowHoverMessage}
    >
      <div className="execution-type">
        {renderSelect}
        <img src="/v2Crown.png" className="v2-crown-image" alt="" />
      </div>
    </HoverMessage>
  );

  return errorMessage ? renderHoverMessage : renderSelect;
};

RenderExecutionType.propTypes = propTypes;

export default RenderExecutionType;
