import React, {
  memo, useEffect, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { transactionBuilderConfigPropTypes } from 'common/propTypes';
import { useSelector } from 'react-redux';
import { advancedFeatureConfigs } from 'modules/QuantBuilder/config/signalConfigs';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import HoverMessage from 'common/components/HoverMessage';
import ReactHtmlParser from 'react-html-parser';
import { ADJUST_PERCENTAGE, TRAILING_PERCENTAGE } from 'modules/TransactionsBuilder/configs';

const propTypes = {
  transactionBuilderConfig: transactionBuilderConfigPropTypes.isRequired,
  orderConfigIndex: PropTypes.number.isRequired,
  onTransactionBuilderConfigChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const ReEntryOrReExecute = ({
  transactionBuilderConfig, orderConfigIndex, onTransactionBuilderConfigChange
}) => {
  const { stopLoss, reEntryCount, reExecuteCount } = transactionBuilderConfig;

  const globalReduxState = useSelector((state) => state);
  const orderConfigs = _.get(globalReduxState, 'quantConfig.orderConfigs', []);
  const currentOrderConfig = _.get(orderConfigs, orderConfigIndex);
  const advancedFeatures = _.get(currentOrderConfig, 'advancedFeatureConfigs', advancedFeatureConfigs());
  const { isReEntry, isReExecute, } = advancedFeatures;

  const isStopLossValuePresent = !!stopLoss;
  const isEnabledRetryOrReExecute = isReEntry || isReExecute;
  const propsActiveType = reExecuteCount ? 'reExecute' : 'reEntry';
  const activeCount = propsActiveType === 'reExecute' ? reExecuteCount : reEntryCount;
  const [activeType, setActiveType] = useState(propsActiveType);
  const isInitialLoad = useRef(true);

  const onHandleActiveCount = (newCount = 0) => {
    if (activeType === 'reExecute') {
      onTransactionBuilderConfigChange({ reExecuteCount: newCount, reEntryCount: 0 });
      return null;
    }
    return onTransactionBuilderConfigChange({ reExecuteCount: 0, reEntryCount: newCount });
  };

  useEffect(() => {
    setActiveType(propsActiveType);
  }, [reEntryCount, reExecuteCount]);

  useEffect(() => {
    if (!isInitialLoad.current && (reExecuteCount || reEntryCount)) {
      onHandleActiveCount(activeCount);
    } else { isInitialLoad.current = false; }
  }, [activeType]);

  useEffect(() => {
    const isResetReEntryAndReExecuteCount = !isInitialLoad.current
      && !isStopLossValuePresent && (reExecuteCount || reEntryCount);

    if (isResetReEntryAndReExecuteCount) {
      onTransactionBuilderConfigChange({
        reExecuteCount: 0, reEntryCount: 0, [TRAILING_PERCENTAGE]: undefined, [ADJUST_PERCENTAGE]: undefined
      });
    }
  }, [isStopLossValuePresent]);

  if (!isEnabledRetryOrReExecute) return null;

  const renderNode = (
    <div className="input-group overall-stops">
      <ToggleButtonGroup
        bsPrefix="btn-group btn-group-sm btn-group-toggle"
        type="radio"
        name="group-selector"
        value={activeType}
        onChange={setActiveType}
      >
        <ToggleButton
          disabled={!isStopLossValuePresent}
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          value="reEntry"
        >
          ReEntry
        </ToggleButton>

        <ToggleButton
          disabled={!isStopLossValuePresent}
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          value="reExecute"
        >
          ReExecute
        </ToggleButton>
      </ToggleButtonGroup>

      <select
        value={activeCount.toString()}
        disabled={!isStopLossValuePresent}
        onChange={(event) => { onHandleActiveCount(Number(event.target.value)); }}
        className="custom-select custom-select-sm strike-index form-control form-control-sm"
      >
        <option value="0" />
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
    </div>
  );

  if (isStopLossValuePresent) return <td>{renderNode}</td>;

  return (
    <td>
      <HoverMessage message={ReactHtmlParser('<b>Stop Loss Percentage</b> is Required')}>
        {renderNode}
      </HoverMessage>
    </td>
  );
};

ReEntryOrReExecute.propTypes = propTypes;
ReEntryOrReExecute.defaultProps = defaultProps;

export default memo(ReEntryOrReExecute);
