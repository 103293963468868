import React from 'react';
import PropTypes from 'prop-types';

import { months } from 'ui/run/RunShow/config';

const propTypes = {
  heatMapData: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired
};
const defaultProps = {};
const HIT_MISS = 'hitMiss';

const HeatMap = ({ heatMapData, title }) => {
  if (title === HIT_MISS) return null;

  const renderCells = (yearData) => (month) => {
    const monthData = _.get(yearData, [month]);
    const color = _.get(monthData, 'color', '');
    const value = _.get(monthData, 'PLOrHMValue', '');
    const tooltopData = `${color.replace(/[0-9]/g, '')} ${value}`;

    return (
      <div
        key={`${month}-${Math.random()}`} // Math.random is used for unique key # TODO better way
        className={`cell ${color}`}
        data-toggle="tooltip"
        title={tooltopData}
      />
    );
  };

  const renderMonthLabel = (month) => (
    <div className="cell" key={month}>{month.slice(0, 3)}</div>
  );

  const renderHeatMapChart = (yearData, year) => {
    return (
      <li key={year}>
        <div className="y-axis">{year}</div>
        {_.map(months, renderCells(yearData))}
      </li>
    );
  };

  return (
    <div className="heat-chart-wrapper">
      <h6>{_.startCase(title)}</h6>
      <div className="heat-chart">
        <ul>
          {_.map(heatMapData, renderHeatMapChart)}
          <li className="x-axis">
            <div className="cell"> </div>
            {_.map(months, renderMonthLabel)}
          </li>
        </ul>
      </div>
    </div>
  );
};

HeatMap.propTypes = propTypes;
HeatMap.defaultProps = defaultProps;
export default HeatMap;
