import { SEGMENT_CATEGORY_IN_QUANT, SEGMENT_CONFIG } from 'common/constants/index';
import { updatedPipeConfigs } from 'ui/run/RunForm/config';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';

export const onUpdatePipeConfigBasedOnIndicator = (segment, pipeConfig) => {
  if (segment === SEGMENT_CONFIG.equity) {
    return _.head(updatedPipeConfigs(
      [pipeConfig], SEGMENT_CATEGORY_IN_QUANT.equity, SEGMENT_CATEGORY_IN_QUANT.month1Price
    ));
  }

  if (segment === SEGMENT_CONFIG.future) {
    return _.head(updatedPipeConfigs(
      [pipeConfig], SEGMENT_CATEGORY_IN_QUANT.month1Price, SEGMENT_CATEGORY_IN_QUANT.equity
    ));
  }

  return pipeConfig;
};

export const indicatorNameChange = (pipeConfigs, indicatorType, segment) => {
  const indicatorTypeArray = _.filter(pipeConfigs, ({ type }) => type === indicatorType);
  if (_.isEmpty(indicatorTypeArray)) {
    return onUpdatePipeConfigBasedOnIndicator(
      segment, PipeDefinitionUtility.getPipeConfigDefaults(indicatorType)
    );
  }

  const indicatorName = _.get(
    PipeDefinitionUtility.getPipeConfigDefaults(indicatorType),
    'name',
    indicatorType
  );
  const period = _.get(
    PipeDefinitionUtility.getPipeConfigDefaults(indicatorType),
    'config.noOfCandles',
    indicatorTypeArray.length * 10
  );

  return onUpdatePipeConfigBasedOnIndicator(segment,
    { ...PipeDefinitionUtility.getPipeConfigDefaults(indicatorType), name: `${indicatorName}${period}` });
};

export const mandatoryMultipleInstrumentForPipes = () => [
  // getPipeConfigDefaults('augmentedDickeyFuller').type,
  // getPipeConfigDefaults('densityCurve').type,
  PipeDefinitionUtility?.getPipeConfigDefaults('ratio').type,
  // getPipeConfigDefaults('ratioStandardDeviation').type,
  // getPipeConfigDefaults('zScore').type,
];

export const isInvalidForInstrument = (pipeConfig, maxInstrumentsInGroup) => {
  const { type, config: { valuePaths = [] } } = pipeConfig;

  const hasDefaultValuePaths = _.filter(_.get(
    PipeDefinitionUtility.getPipeConfigDefaults(type),
    'config.valuePaths',
    []
  ),
  (valuePath) => {
    return _.includes(valuePaths, valuePath);
  }).length === valuePaths.length;

  return _.includes(mandatoryMultipleInstrumentForPipes(), type)
    && (maxInstrumentsInGroup === 1 && hasDefaultValuePaths);
};
