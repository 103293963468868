import _ from 'lodash';
import { INTENT_TYPES } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from './config';

const INDIVIDUAL_STOP_TAG_COLOR = '#7987a1';
const isAdmin = window?.isAdmin;
const isDisplay = _.get(window, 'SA_FEATURE_FLAGS.shouldShowNewSignalProperties', false) || isAdmin;
const BASKET_TAG_COLOR = '#7988b2';

const schemasToDisplayNameMap = {
  lossPercentage: {
    displayName: 'Loss %',
    isDisplay: true,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  gainPercentage: {
    displayName: 'Profit %',
    isDisplay: true,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  entryTime: {
    displayName: 'Entry Time',
    isDisplay,
    type: schemaTypes.time,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  entryTimeFromNow: {
    displayName: 'Duration After entry',
    isDisplay,
    type: schemaTypes.duration,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  entryPrice: {
    displayName: 'Entry Price',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  exitTime: {
    displayName: 'Exit Time',
    isDisplay,
    type: schemaTypes.time,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  exitTimeFromNow: {
    displayName: 'Duration After exit',
    isDisplay,
    type: schemaTypes.duration,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  exitPrice: {
    displayName: 'Exit Price',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  lastTradedPrice: {
    displayName: 'Last Traded Price',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  hasEntered: {
    displayName: 'Already Entered',
    isDisplay,
    type: schemaTypes.boolean,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  hasExited: {
    displayName: 'Already Exited',
    isDisplay,
    type: schemaTypes.boolean,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  hasExitedInProfit: {
    displayName: 'Already Exited In Profit',
    isDisplay,
    type: schemaTypes.boolean,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  hasExitedInLoss: {
    displayName: 'Already Exited In Loss',
    isDisplay,
    type: schemaTypes.boolean,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  hasMovedToCost: {
    displayName: 'Already Moved To Cost',
    isDisplay,
    type: schemaTypes.boolean,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  stopLossTrailCount: {
    displayName: 'Number Of StopLoss Trails',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  lossAmount: {
    displayName: 'Loss Amount',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  gainAmount: {
    displayName: 'Profit Amount',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  lossPoints: {
    displayName: 'Loss Points',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  gainPoints: {
    displayName: 'Profit Points',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand
    ]
  },
  strikePrice: {
    displayName: 'Strike Price',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand,
      schemaShouldShowConfigTypes.intentValuePath
    ]
  }
};

const getIndividualStopSchema = (label, extraArgs = {}) => {
  return _.chain(schemasToDisplayNameMap)
    .pickBy(({ isDisplay: isShow }) => isShow)
    .map(({ displayName, type, shouldShowConfigs }, schema) => {
      return {
        type,
        schemaName: schema,
        category: 'individualStop',
        name: `individualStop.${label}.${schema}`,
        label: `${displayName}`,
        tagConfig: { tag: `Leg ${label}`, color: INDIVIDUAL_STOP_TAG_COLOR },
        shouldShowConfigs,
        priority: 110,
        infoLink: `/faq/signalProperties/#${displayName}`,
        ...extraArgs
      };
    })
    .value();
};

const getBaseIntentSchema = (intents, caseIndex) => {
  return _.chain(intents)
    .filter((intent) => intent?.type === INTENT_TYPES.OpenLeg)
    .map((intent) => {
      const { identifier: { label } } = intent;
      return getIndividualStopSchema(
        label,
        { caseIndex }
      );
    })
    .value();
};

const getAdjustmentIntentSchema = (adjustments, caseIndex) => {
  return _.map(adjustments, (adjustment, adjustmentIndex) => {
    const { intents: adjustmentIntents } = adjustment;
    return _.chain(adjustmentIntents)
      .filter((intent) => intent?.type === INTENT_TYPES.OpenLeg)
      .map((intent) => {
        const { identifier: { label } } = intent;
        return getIndividualStopSchema(
          label,
          { caseIndex, adjustmentIndex }
        );
      })
      .value();
  });
};

const getBasketIntentsInPipeConfigs = (pipeConfigs, bName) => {
  return _.chain(pipeConfigs)
    .filter(({ type, name }) => type === 'basket' && name === bName)
    .map(({ config: { legs } }) => legs)
    .compact()
    .flatten()
    .value();
};

const getBasketIntentSchema = (intents, pipeConfigs, extraArgs) => {
  return _.chain(intents)
    .filter((intent) => intent?.type === INTENT_TYPES.OpenBasket)
    .map((intent) => {
      const { identifier: { label: basketLabel }, name: bName } = intent;
      const basketIntents = getBasketIntentsInPipeConfigs(pipeConfigs, bName);

      return _.chain(basketIntents)
        .filter((intnt) => intnt?.type === INTENT_TYPES.OpenLeg)
        .map((intnt) => {
          const { identifier: { label } } = intnt;
          return getIndividualStopSchema(
            `${basketLabel}(${label})`,
            {
              tagConfig: { tag: `Basket ${basketLabel} (Leg ${label})`, color: BASKET_TAG_COLOR },
              ...extraArgs
            }
          );
        })
        .value();
    })
    .flatten()
    .value();
};

export const buildIndividualStopOutputSchema = (cases, pipeConfigs) => {
  if (_.isEmpty(cases)) return [];

  return _.chain(cases)
    .map((caseConfig, caseIndex) => {
      const { entry: { intents: baseIntents }, adjustments } = caseConfig;
      const adjustmentBasketIntentSchema = _.chain(adjustments)
        .map((adjustment, adjustmentIndex) => {
          return getBasketIntentSchema(adjustment?.intents, pipeConfigs, {
            caseIndex,
            adjustmentIndex
          });
        })
        .flatten()
        .value();

      return [
        ...getBaseIntentSchema(baseIntents, caseIndex),
        ...getAdjustmentIntentSchema(adjustments, caseIndex),
        ...getBasketIntentSchema(baseIntents, pipeConfigs, {
          caseIndex,
        }),
        ...adjustmentBasketIntentSchema,
      ];
    })
    .flattenDeep()
    .value();
};
