import React from 'react';
import PropTypes from 'prop-types';
import { cyclicPlansPeriods } from 'ui/Plans/config';
import classNames from 'classnames';

const propTypes = {
  planCycleType: PropTypes.string.isRequired,
  handlePlanCycleType: PropTypes.func.isRequired,
  isShowPlan: PropTypes.bool.isRequired,
  isStepOne: PropTypes.bool.isRequired,
};

const defaultProps = {};

const BillingCycleFilter = ({
  planCycleType, handlePlanCycleType, isShowPlan, isStepOne
}) => {
  const isShow = isShowPlan && !isStepOne;
  if (!isShow) return null;

  const renderPlanCycleTypes = (planConfig, key) => {
    const labelClassName = classNames('btn btn-outline-primary', {
      'btn-lightness btn btn-outline-primary btn-lightness-primary': true,
      active: planCycleType === planConfig.value
    });

    return (
      <label className={labelClassName} key={key}>
        <input
          name="planCycleType"
          type="radio"
          autoComplete="off"
          value={planConfig.value}
          checked={planCycleType === planConfig.value}
          onChange={() => { }}
          onClick={() => handlePlanCycleType(planConfig.value)}
        />
        {planConfig.label}
      </label>
    );
  };

  return (
    <div className="text-center mb-5">
      <div role="group" className="btn-group btn-group-toggle">
        {_.map(cyclicPlansPeriods, renderPlanCycleTypes)}
      </div>
    </div>
  );
};

BillingCycleFilter.propTypes = propTypes;
BillingCycleFilter.defaultProps = defaultProps;

export default BillingCycleFilter;
