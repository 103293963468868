import wrappedFetch from './base';

export const onCreateNewCampaignRegistration = (args) => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...args }),
  };

  return wrappedFetch('/campaign_registrations.json', config);
};

export const onGetCampaign = (id) => {
  const url = `/campaigns/${id}.json`;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  return wrappedFetch(url, options).then((response) => response.json());
};
