import React, { memo, useMemo } from 'react';
import { outputSchemaPropTypes, rightOperandPropTypes } from 'common/propTypes';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { OutputSchemaUtility, TypeheadSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import { schemaShouldShowConfigTypes } from 'v2/common/quantConfig/outputSchema/config';
import { signalTypes } from 'v2/modules/withRunForm/configs/index';
import { OperandUtility } from '../../../utility';

const propTypes = {
  rightOperand: rightOperandPropTypes.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  leftOperand: PropTypes.string,
  onChangeRightOperand: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  signalType: PropTypes.string.isRequired,
};

const defaultProps = {
  leftOperand: '',
};

const RightOperand = ({
  rightOperand, outputSchema, leftOperand,
  onChangeRightOperand, control, signalType,
  errors
}) => {
  const { exitSignal, entrySignal } = signalTypes;

  const leftOperandSchema = useMemo(() => OutputSchemaUtility.findByName(
    outputSchema, leftOperand
  ), [outputSchema, leftOperand]);

  const types = useMemo(() => {
    if (signalType === entrySignal) return [schemaShouldShowConfigTypes.entryRightOperand];
    if (signalType === exitSignal) return [schemaShouldShowConfigTypes.exitRightOperand];

    return [schemaShouldShowConfigTypes.adjustmentRightOperand];
  }, [entrySignal, exitSignal, signalType]);

  const leftOperandType = leftOperandSchema?.type;

  const {
    component: Component,
    options: suggestionSchema,
    isAllowNew
  } = OperandUtility.getRightOperandConfigs(leftOperandType);

  const rightOperandOutputSchema = useMemo(() => {
    return _.chain(outputSchema)
      .thru((schema) => (signalType ? OutputSchemaUtility.filterByTypes(schema, types) : schema))
      .sortBy('priority')
      .thru((schema) => OutputSchemaUtility.filterByOnlyEnabled(schema))
      .thru((schema) => {
        if (!_.isEmpty(suggestionSchema)) return suggestionSchema;

        return schema;
      })
      .value();
  }, [outputSchema, types, suggestionSchema, signalType]);

  const selected = useMemo(
    () => TypeheadSchemaUtility.getSelectedOption(
      rightOperandOutputSchema,
      rightOperand
    ), [rightOperandOutputSchema, rightOperand]
  );

  const options = useMemo(() => {
    return _.chain(rightOperandOutputSchema)
      .map((schema) => TypeheadSchemaUtility.getOption(schema))
      .value();
  }, [rightOperandOutputSchema]);

  return (
    <div className="right-operand operand-info d-flex align-items-center">
      <Component
        rightOperand={rightOperand}
        isAllowNew={isAllowNew}
        onChangeRightOperand={(newRightOperand) => onChangeRightOperand(newRightOperand)}
        selected={selected}
        options={options}
        outputSchema={rightOperandOutputSchema}
        control={control}
        errors={errors}
      />
    </div>
  );
};

RightOperand.propTypes = propTypes;
RightOperand.defaultProps = defaultProps;

export default memo(RightOperand);
