import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  initialFreeTrialDays: PropTypes.number.isRequired,
  purchaseAmount: PropTypes.number,
  isStrategyConfirmationModel: PropTypes.bool
};

const defaultProps = {
  purchaseAmount: null,
  isStrategyConfirmationModel: false
};

const FreeTrialInfo = ({
  initialFreeTrialDays, purchaseAmount, isStrategyConfirmationModel
}) => {
  const oneIndentation = ' ';
  if (isStrategyConfirmationModel) {
    return (
      <div className="alert alert-sm alert-info mt-3 py-1 px-2 text-wrap">
        Strategy available for
        {oneIndentation}
        <b>
          free trial for first
          {oneIndentation}
          {initialFreeTrialDays}
          {oneIndentation}
          days.
        </b>
        {oneIndentation}
        After the trial period, you must pay
        {oneIndentation}
        <b>
          Rs.
          {oneIndentation}
          {purchaseAmount}
          {oneIndentation}
          to purchase the strategy
        </b>
        {oneIndentation}
        or the deployment will be stopped.
      </div>
    );
  }

  return (
    <div className="run-type-show alert alert-sm alert-info mb-0 py-1 px-2 text-nowrap">
      <span className="material-icons-outlined tx-20 align-bottom mr-1">
        {oneIndentation}
        campaign
      </span>
      {initialFreeTrialDays}
      {oneIndentation}
      days free trial
    </div>
  );
};

FreeTrialInfo.propTypes = propTypes;
FreeTrialInfo.defaultProps = defaultProps;

export default FreeTrialInfo;
