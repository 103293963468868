import React from 'react';

import { getExchangeAndSymbol } from 'common/utils/config';

const renderInstruments = (onRemoveInstrumentGroup) => (instrumentGroup, instrumentGroupIndex) => {
  const instrumentsContent = _.map(instrumentGroup, (instrument) => {
    const { symbol } = getExchangeAndSymbol(instrument);

    return symbol;
  });

  return (
    <div key={instrumentGroupIndex} className="mt-2 mr-2 d-inline-block">
      <div className="d-flex border align-items-center pl-1">

        <span className="instrument-filter-vaule pr-1 tx-12 font-weight-bold">
          {_.join(instrumentsContent, ', ')}
        </span>

        <button
          className="btn text-danger btn-sm align-self-center rounded-0 border-left"
          type="button"
          onClick={() => onRemoveInstrumentGroup(instrumentGroupIndex)}
        >
          <i className="material-icons-outlined tx-16 d-block">close</i>
        </button>
      </div>
    </div>
  );
};

const ViewInstrumentGroup = (props) => {
  const { instrumentGroups, onRemoveInstrumentGroup } = props;

  return _.map(instrumentGroups, renderInstruments(onRemoveInstrumentGroup));
};

export default ViewInstrumentGroup;
