import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  name: PropTypes.string,
  onUpdateName: PropTypes.func.isRequired,
  existingPipeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  validator: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  pipeType: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired
};

const defaultProps = {
  name: '',
  validator: {}
};

const PipeName = ({
  name: pipeName, onUpdateName, existingPipeNames, validator,
  control, errors, pipeType, clearErrors
}) => {
  const uniqueId = useMemo(() => _.uniqueId('pipe_name_'), []);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  useEffect(() => clearErrors(uniqueId), [clearErrors, pipeType, uniqueId]);

  const validatorConfig = useMemo(() => ({
    ...validator,
    validate: () => {
      if (!pipeName) return 'Please name indicator';
      if (_.includes(existingPipeNames, pipeName)) return 'Name already exists';

      return null;
    }
  }), [validator, pipeName, existingPipeNames]);

  return (
    <>
      <div>
        <label>Name</label>
        <ErrorMesssage message={errorMessage}>
          <Controller
            render={({ onChange, ref, name }) => (
              <div className="inside-icon">
                <i className="edit-icon" />
                <Form.Control
                  type="text"
                  name={name}
                  size="sm"
                  ref={ref}
                  value={pipeName}
                  onChange={(event) => {
                    onUpdateName({ name: event.target.value });
                    onChange(event.target.value);
                  }}
                />
              </div>
            )}
            rules={validatorConfig}
            name={uniqueId}
            defaultValue={pipeName}
            control={control}
          />
        </ErrorMesssage>
      </div>
    </>
  );
};

PipeName.propTypes = propTypes;
PipeName.defaultProps = defaultProps;

export default memo(PipeName);
