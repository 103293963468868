import React, { useState } from 'react';
import TimeSelector from 'common/components/TimeSelector';
import { timeBasedSignalPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';

const propTypes = {
  timeBasedSignal: timeBasedSignalPropTypes.isRequired,
  onSignalTimeChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: null
};

const StrategyTimeBasedSignal = ({ timeBasedSignal, onSignalTimeChange, error }) => {
  const { entryTime, exitTime } = timeBasedSignal;
  const [timePickerName] = useState(_.uniqueId('intradayTimePicker-'));

  return (
    <>
      <div className="d-flex gap-10 pl-2">
        <div>
          <label className="col-form-label col-form-label-sm d-block">Entry Time</label>
          <TimeSelector
            style={`${timePickerName}-start-time`}
            onTimeChange={(newTime) => {
              onSignalTimeChange(newTime, 'entryTime');
            }}
            selectedTime={entryTime}
          />
        </div>
        <div>
          <label className="col-form-label col-form-label-sm d-block">Exit Time</label>
          <TimeSelector
            style={`${timePickerName}-end-time`}
            onTimeChange={(newTime) => {
              onSignalTimeChange(newTime, 'exitTime');
            }}
            selectedTime={exitTime}
          />
        </div>
      </div>
      <div className="invalid-feedback d-block">{error || null}</div>
    </>
  );
};

StrategyTimeBasedSignal.propTypes = propTypes;
StrategyTimeBasedSignal.defaultProps = defaultProps;

export default StrategyTimeBasedSignal;
