import wrappedFetch from './base';

const urlPath = '/dashboards/strategy_analytics';

export const fetchStrategyTransactions = (args) => {
  const { strategyId } = args;
  const url = `${urlPath}/transactions?strategy_id=${strategyId}`;

  return wrappedFetch(url).then(
    (response) => {
      console.log(response);
      return response.json();
    }
  ).catch((e) => console.log('ERORRRR:', e));
};

export const fetchTransactionUsers = (args) => {
  const { strategyId, transactionId } = args;
  const url = `${urlPath}/users?strategy_id=${strategyId}&transaction_id=${transactionId}`;

  return wrappedFetch(url).then(
    (response) => {
      console.log(response);
      return response.json();
    }
  ).catch((e) => console.log('ERORRRR:', e));
};

export const fetchTransactionLegsForUser = (args) => {
  const { strategyId, transactionId, userId } = args;
  const url = `${urlPath}/legs?strategy_id=${strategyId}&transaction_id=${transactionId}&user_id=${userId}`;

  return wrappedFetch(url).then(
    (response) => {
      console.log(response);
      return response.json();
    }
  ).catch((e) => console.log('ERORRRR:', e));
};
