import { chartConfigPropTypes } from 'common/propTypes';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CHART_PATTERNS } from 'v2/common/constants/index';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const propTypes = {
  chartConfig: chartConfigPropTypes.isRequired,
  onUpdateChartConfig: PropTypes.func.isRequired
};
const defaultProps = {};

const PipeChartConfig = ({ chartConfig, onUpdateChartConfig }) => {
  const { type } = chartConfig;

  const onChange = useCallback((chartType) => {
    onUpdateChartConfig({ chartConfig: { ...chartConfig, type: chartType } });
  }, [chartConfig, onUpdateChartConfig]);

  const renderOptions = useCallback(({ label, value, isDisplay }) => {
    if (!isDisplay) return null;

    return (
      <ToggleButton
        key={value}
        bsPrefix="btn btn-sm btn-outline-primary btn-lightness text-white"
        className={`btn-lightness text-white track ${type === value ? 'active' : ''}`}
        onChange={() => onChange(value)}
      >
        {label}
      </ToggleButton>

    );
  }, [onChange, type]);

  return (
    <div className="indicator-chart-type">
      <label>Chart Type</label>
      <ToggleButtonGroup
        className="btn-group btn-group-sm btn-group-toggle"
        type="radio"
        size="sm"
        name="options"
      >
        {_.map(CHART_PATTERNS, renderOptions)}
      </ToggleButtonGroup>
    </div>
  );
};

PipeChartConfig.propTypes = propTypes;
PipeChartConfig.defaultProps = defaultProps;

export default memo(PipeChartConfig);
