import React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  defaultOpen: PropTypes.bool,
  headerRightSectionContent: PropTypes.element,
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  isInvalid: PropTypes.bool,
  onToggle: PropTypes.func,
  headerRightIconSection: PropTypes.element,
  isDisabledOnClick: PropTypes.bool
};

const defaultProps = {
  children: null,
  defaultOpen: false,
  headerRightSectionContent: null,
  title: '',
  isDisabledOnClick: false,
  isInvalid: false,
  onToggle: _.noop,
  headerRightIconSection: null
};

export default class ArrowAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.defaultOpen,
      defaultOpenFromProps: props.defaultOpen
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.defaultOpenFromProps === props.defaultOpen) return null;

    props.onToggle(props.defaultOpen);
    return {
      isOpen: props.defaultOpen,
      defaultOpenFromProps: props.defaultOpen
    };
  }

  onClick = (event) => {
    const { onToggle } = this.props;
    const { isOpen } = this.state;

    const shouldToggleForEvent = () => {
      const isClickedOnPreventedIcon = $(event.target).closest('.prevent-collapse').length > 0;
      return !isClickedOnPreventedIcon;
    };

    if (shouldToggleForEvent()) {
      this.setState({ isOpen: !isOpen });
      onToggle(!isOpen);
    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      title, headerRightSectionContent, children, isInvalid, headerRightIconSection, isDisabledOnClick
    } = this.props;

    const headerClassNames = classNames(
      { open: isOpen }
    );
    const accordionClass = `common-component-arrow-accordion ${isInvalid ? 'border-danger' : ''}`;

    return (
      <Card className={accordionClass}>
        <Card.Header className={headerClassNames} onClick={isDisabledOnClick ? () => {} : this.onClick}>
          <div className="arrow-accordion-title">
            {title}
          </div>
          <span className="accordion-rs prevent-collapse">{headerRightSectionContent}</span>
          <span className="action-section">{headerRightIconSection}</span>
        </Card.Header>
        <Accordion.Collapse in={isOpen}>
          <Card.Body className="border-top">{isOpen && children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

ArrowAccordion.propTypes = propTypes;
ArrowAccordion.defaultProps = defaultProps;
