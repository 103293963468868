import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import { instrumentGroupsPropTypes } from 'common/propTypes';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  instrumentGroups: instrumentGroupsPropTypes
};

const defaultProps = {
  instrumentGroups: []
};

const RunFormConfirmationModal = ({ onSubmit, onClose, instrumentGroups }) => {
  return (
    <Modal
      onSave={onSubmit}
      onClose={onClose}
      title="Confirmation Modal"
      size="sm"
      okBtnTitle="Yes"
    >
      <div>
        <b><InstrumentGroupsSentence instrumentGroups={instrumentGroups} /></b>
        <br />
        <br />
        Unlike indexes, some stocks and their derivatives
        might not be very liquid. And while placing orders in those,
        you will face a lot of variations/slippages in executed price.
        Please verify liquidity/traded volume of the instruments that this
        live deployment will be trading.
        <br />
        <br />
        *Please press yes to continue, creating the live deployment
      </div>
    </Modal>
  );
};

RunFormConfirmationModal.propTypes = propTypes;
RunFormConfirmationModal.defaultProps = defaultProps;

export default RunFormConfirmationModal;
