import React, {
  Fragment, memo, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { casePropTypes } from 'common/propTypes';
import { signalTypes } from 'v2/modules/withRunForm/configs/index';
import { useCase } from 'v2/modules/withRunForm/hooks/useCase/index';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import Signals from 'v2/modules/withRunForm/components/Signals/index';
import Intents from 'v2/modules/withRunForm/components/Intents/index';
import HoverMessage from 'common/components/HoverMessage';
import CaseBasedFeatureFlags from './CaseBasedBehaviourFlags/index';

const propTypes = {
  caseConfig: casePropTypes.isRequired,
  caseIndex: PropTypes.number.isRequired,
  intentDependencies: PropTypes.shape({}).isRequired,
  errorMessage: PropTypes.string,
  errorConfigs: PropTypes.shape({}),
  isHiddenSignal: PropTypes.bool.isRequired,
};
const defaultProps = {
  errorMessage: '',
  errorConfigs: {},
};

const Case = ({
  caseIndex, caseConfig, intentDependencies, errorConfigs, isHiddenSignal
}) => {
  const {
    entrySignals,
    exitSignals,
    intents,
    onUpdateEntrySignals,
    onUpdateExitSignals,
    onUpdateIntents,
    uiConfigs,
    onUpdateUiConfigs,
    isValidToEnableMoveToCost,
    adjustments,
    onAddAdjustment,
    onUpdateAdjustmentSignals,
    onUpdateAdjustmentIntents,
    onDeleteAdjustment,
    getModifyLegsTargetSchema,
  } = useCase(caseConfig, caseIndex);

  const adjustmentErrorMessage = errorConfigs[caseIndex]?.adjustments?.errorMessage || '';
  const isPremiumError = errorConfigs[caseIndex]?.adjustments?.isPremiumError || false;

  const renderSignals = useCallback(
    (signals, signalType, onUpdateSignals, extraArgs = {}) => {
      const signalProps = {
        signals,
        caseIndex,
        signalType,
        onUpdateSignals,
        isHiddenSignal,
        ...extraArgs,
      };

      return <Signals {...signalProps} />;
    },
    [caseIndex, isHiddenSignal]
  );

  const renderMoveToCostCheckbox = useCallback(() => {
    return (
      <CaseBasedFeatureFlags
        uiConfigs={uiConfigs}
        onUpdateUiConfigs={onUpdateUiConfigs}
        isValidToEnableMoveToCost={isValidToEnableMoveToCost}
      />
    );
  }, [uiConfigs, onUpdateUiConfigs, isValidToEnableMoveToCost]);

  const renderAdjustment = useCallback(
    (adjustment, adjustmentIndex) => {
      const { signal, intents: adjustIntents } = adjustment;
      const modifyLegsTargetSchema = getModifyLegsTargetSchema(adjustmentIndex);
      // Note: Not a callback, new function everytime.
      const onUpdateIntentsForAdjustment = (
        newIntents,
        removedOpenIntentIds = []
      ) => {
        return onUpdateAdjustmentIntents(
          newIntents,
          removedOpenIntentIds,
          adjustmentIndex
        );
      };

      return (
        <Fragment key={adjustmentIndex}>
          <div className="adjust-signal-container">
            {isPremiumError && (
              <img
                src="/v2Crown.png"
                className="ajustment-crown v2-crown-image"
                alt=""
              />
            )}
            <span className="adjustment-number-badge badge">
              Adjustment&nbsp;
              {adjustmentIndex + 1}
            </span>
            {renderSignals(
              signal,
              signalTypes.adjustSignal,
              (newSignals) => onUpdateAdjustmentSignals(newSignals, adjustmentIndex),
              { adjustmentIndex }
            )}
            <button
              className="delete-button btn btn-delete-outline"
              type="button"
              onClick={() => onDeleteAdjustment(adjustmentIndex)}
            >
              <i aria-disabled="false" />
            </button>
          </div>
          <Intents
            intents={adjustIntents}
            caseIndex={caseIndex}
            onUpdateIntents={onUpdateIntentsForAdjustment}
            isAdjustmentIntent
            adjustmentIndex={adjustmentIndex}
            intentDependencies={intentDependencies}
            modifyLegsTargetSchema={modifyLegsTargetSchema}
          />
        </Fragment>
      );
    },
    [
      caseIndex,
      getModifyLegsTargetSchema,
      intentDependencies,
      isPremiumError,
      onDeleteAdjustment,
      onUpdateAdjustmentIntents,
      onUpdateAdjustmentSignals,
      renderSignals,
    ]
  );

  const renderAddAdjustmentButton = useMemo(() => {
    return (
      <button
        type="button"
        disabled={isHiddenSignal}
        className="custom_outline__btn tx-12
                font-weight-semi d-flex
                align-items-center"
        onClick={() => {
          if (!isHiddenSignal) onAddAdjustment();
        }}
      >
        <i className="material-icons-outlined tx-14 mr-1">library_add</i>
        Add Adjustment
      </button>
    );
  }, [isHiddenSignal, onAddAdjustment]);

  return (
    <>
      <div className="case-container p-1 p-sm-2 border rounded tabs-info">
        {renderSignals(
          entrySignals,
          signalTypes.entrySignal,
          onUpdateEntrySignals
        )}
        {renderSignals(
          exitSignals,
          signalTypes.exitSignal,
          onUpdateExitSignals
        )}
        <Intents
          caseIndex={caseIndex}
          intents={intents}
          onUpdateIntents={onUpdateIntents}
          intentDependencies={intentDependencies}
          renderMoveToCostCheckbox={renderMoveToCostCheckbox}
        />
        <ErrorMesssage
          message={adjustmentErrorMessage}
          isPremiumError={isPremiumError}
        />
        {adjustments.map(renderAdjustment)}
        <div className="add-adjustment-container d-flex justify-content-center py-2">
          {isHiddenSignal ? (
            <HoverMessage message="This action has been disabled by the Author">
              {renderAddAdjustmentButton}
            </HoverMessage>
          ) : (
            renderAddAdjustmentButton
          )}
        </div>

      </div>
    </>
  );
};

Case.propTypes = propTypes;
Case.defaultProps = defaultProps;

export default memo(Case);
