import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import ErrorMesssage from 'common/components/ErrorMesssage';

const propTypes = {
  phoneNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  onPhoneNumberSubmit: PropTypes.func.isRequired,
  onPhoneNumberChange: PropTypes.func.isRequired
};
const defaultProps = {};

const phoneNumberValidator = {
  required: 'please enter phone number',
  pattern: {
    value: /^\d{10}$/,
    message: 'enter a 10 digit phone number without country code ex: 9868547851'
  }
};

const PhoneNumberForm = (props) => {
  const { phoneNumber, onPhoneNumberSubmit, onPhoneNumberChange } = props;
  const { register, errors, handleSubmit } = useForm();

  return (
    <div>
      <div className="text-center">
        <img alt="smartphone" src="/smartphone-dial.svg" />
        <h4 className="font-weight-bold mb-1">Enter Your Phone Number</h4>
        <h5 className="text-muted">we will send you the 6 digit verification code</h5>
      </div>
      <form className="login-form text-center mt-5" onSubmit={handleSubmit(onPhoneNumberSubmit)}>
        <div className="form-group">
          <div className="inside-icon">
            <i className="icons material-icons-outlined">
              phone_android
            </i>
            <input
              key="phoneNumber"
              type="number"
              value={phoneNumber}
              className="form-control form-control-lg pl-5"
              name="phoneNumber"
              onChange={(event) => onPhoneNumberChange(event.target.value)}
              ref={register(phoneNumberValidator)}
              placeholder="e.g.9999999999"
            />
          </div>
          <ErrorMesssage
            error={errors.phoneNumber}
          />
        </div>
        <button
          className="btn btn-primary btn-pill text-uppercase"
          type="submit"
        >
          Generate otp
        </button>
      </form>
    </div>
  );
};

PhoneNumberForm.defaultProps = defaultProps;
PhoneNumberForm.propTypes = propTypes;

export default PhoneNumberForm;
