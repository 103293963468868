import React, { useState } from 'react';

import { BROKERS } from 'common/constants';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};
const defaultProps = {};

const SharekhanLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16 mb-4">
            Build and deploy your own strategies in the live market and
            {oneIndentation}
            seamlessly executing orders through integration with the Sharekhan API
          </p>
          <div
            onClick={() => { setCurrentBroker(BROKERS.sharekhan.id); }}
            className="btn btn-outline-primary broker"
          >
            Login via
            {oneIndentation}
            <b>Sharekhan</b>
          </div>
        </div>
      </div>
      <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
    </div>
  );
};

SharekhanLogin.propTypes = propTypes;
SharekhanLogin.defaultProps = defaultProps;

export default SharekhanLogin;
