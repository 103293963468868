import React from 'react';
import PropTypes from 'prop-types';
import { oneOfTypes, orderConfigsPropTypes } from 'common/propTypes';
import HoverMessage from 'common/components/HoverMessage';
import PlaceOptionsEntryBuyOrdersSelector from './PlaceOptionsEntryBuyOrdersSelector';
import { isShowEntryBuyOptionsOrdersFirst } from '../ProfitAndOrdersComponents/helper';

const propTypes = {
  isPlaceOptionsEntryBuyOrderFirst: oneOfTypes,
  handleChange: PropTypes.func.isRequired,
  orderConfigs: orderConfigsPropTypes
};
const defaultProps = { isPlaceOptionsEntryBuyOrderFirst: '', orderConfigs: [] };

const PlaceOptionsEntryBuyOrdersFirst = ({
  isPlaceOptionsEntryBuyOrderFirst, handleChange, orderConfigs
}) => {
  const oneIndentation = ' ';
  const isApplicableForMarginBenefit = isShowEntryBuyOptionsOrdersFirst(orderConfigs);
  const content = isApplicableForMarginBenefit ? 'Margin Benefit will be applicable for your transaction'
    : 'Margin Benefit will not be applicable for your transaction';

  return (
    <div className="row mb-1">
      <label className="col-md-3 place-orders-label">
        Place orders sequenced for
        {oneIndentation}
        <b>Margin Benefit</b>
        {oneIndentation}
        <HoverMessage
          title=""
          message={content}
        >
          <span className="material-icons-outlined text-muted tx-16 align-middle">
            help_outline
          </span>
        </HoverMessage>
      </label>
      <div className="col-md-9 place-options">
        <PlaceOptionsEntryBuyOrdersSelector
          optionType={isPlaceOptionsEntryBuyOrderFirst}
          onOptionTypeChange={(value) => {
            handleChange(value);
          }}
        />
      </div>
    </div>
  );
};

PlaceOptionsEntryBuyOrdersFirst.propTypes = propTypes;
PlaceOptionsEntryBuyOrdersFirst.defaultProps = defaultProps;

export default PlaceOptionsEntryBuyOrdersFirst;
