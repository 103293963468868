import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import getFormattedDate from 'common/utils/dateFormat';
import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import { getRunProfits } from 'ui/run/RunShow/OrderStore/analytics/index';
import { runPropTypes } from 'common/propTypes';
import MetricsPanel from './MetricsPanel';
import RunSummaryChart from './RunSummaryChart';
import RunShowSummary from './RunShowSummary';

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  run: runPropTypes
};
const defaultProps = {
  type: '',
  name: '',
  startDate: '',
  endDate: '',
  description: '',
  run: {}
};

const RunSummary = ({
  type, name, startDate, endDate, description
}) => {
  // runsummary component will be used for multiple place
  // 1. context child component ? description comes ? it is profitable strategy from dashboard ?
  // or else not runshow view ? below see seperate renders

  const { isLoading, metrics, transactions } = useContext(OrderContext);

  const runProfits = !isLoading ? getRunProfits(transactions) : [];
  const isEmptyRunProfits = _.isEmpty(runProfits);

  const renderMetrics = () => <MetricsPanel metrics={metrics} />;

  const renderShowCase = () => {
    return (
      <>
        <div className="card-body row">
          <div className="col-md-4">
            <div className="overflow-hidden chart-description">
              <h6 className="text-truncate-twoline-clamp font-weight-bold">{name}</h6>
              <div className="date sub-head mb-2 mt-1 tx-12">
                <i className="material-icons-outlined mr-1 tx-20 align-bottom"> date_range </i>
                {getFormattedDate(startDate)}
                &nbsp;
                <span className="mx-1">to</span>
                &nbsp;
                {getFormattedDate(endDate)}
              </div>
              <p>{description}</p>
            </div>
          </div>
          <div className="col-md-8">
            <RunSummaryChart isLoading={isLoading} profits={runProfits} />
          </div>
        </div>
        <div className="card-footer">{renderMetrics()}</div>
      </>
    );
  };

  const renderRunShow = () => {
    return <RunShowSummary />;
  };

  const renderShowPage = () => {
    switch (type) {
      case 'ShowCase': return renderShowCase();
      default: return renderRunShow();
    }
  };

  return (!isEmptyRunProfits || isLoading) && renderShowPage();
};

RunSummary.propTypes = propTypes;
RunSummary.defaultProps = defaultProps;

export default RunSummary;
