import { availableBrokers } from 'common/brokerConfigs/config';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { userPropTypes } from 'common/propTypes';
import UserStrategyDetails from './UserStrategyDetails';

const propTypes = {
  strategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userAndStrategyDetail: PropTypes.shape({
    user: userPropTypes,
    strategy_access: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

const defaultProps = {};

const TableBody = ({ userAndStrategyDetail: propsUserAndStrategyDetail, strategies }) => {
  const [userAndStrategyDetail, setUserAndStrategyDetail] = useState(propsUserAndStrategyDetail);
  const { user, strategy_access: strategyAccess } = userAndStrategyDetail;
  const { broker, created_at: createdAt } = user;
  const [shouldShowModal, setShouldShowModal] = useState(false);

  useEffect(() => {
    setUserAndStrategyDetail(propsUserAndStrategyDetail);
  }, [propsUserAndStrategyDetail]);

  const renderPermittedStrategyNames = () => {
    if (_.isEmpty(strategyAccess)) return '-';

    return _.join(
      _.map(strategyAccess, ({ strategy_id: strategyId }) => _.get(_.find(
        strategies, ({ id }) => id === strategyId
      ), 'title', '')), ', '
    );
  };

  return (
    <tr>
      <td>
        <span
          className="material-icons-outlined tx-14 ml-2"
          onClick={() => setShouldShowModal(true)}
        >
          edit
        </span>
        <UserStrategyDetails
          strategies={strategies}
          userAndStrategyDetail={userAndStrategyDetail}
          shouldShowModal={shouldShowModal}
          onCloseModal={() => setShouldShowModal(false)}
          onUpdateDetail={(updatedDetail) => {
            setUserAndStrategyDetail(updatedDetail);
          }}
        />
      </td>
      <td>{availableBrokers[broker] || broker}</td>
      <td>{_.get(user, 'broker_client_name', '')}</td>
      <td>{_.get(user, 'broker_client_id', '')}</td>
      <td>{_.get(user, 'broker_client_email', '')}</td>
      <td>{_.get(user, 'phone_number', '')}</td>
      <td>{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
      <td>{renderPermittedStrategyNames(strategyAccess)}</td>
    </tr>
  );
};

TableBody.propTypes = propTypes;
TableBody.defaultProps = defaultProps;

export default TableBody;
