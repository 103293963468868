import { BROKERS } from 'common/constants/index';

export const getBrokersDetails = () => {
  return _.reduce(BROKERS, (result, value) => {
    // eslint-disable-next-line no-param-reassign
    result[value.id] = value.name;
    return result;
  }, {});
};

export const availableBrokers = getBrokersDetails();

export const automaticSupportedBrokers = _.chain(BROKERS)
  .filter((broker) => broker.automatic)
  .map((broker) => { return broker.id; })
  .value();

export const oneClickSupportedBrokers = _.chain(BROKERS)
  .filter((broker) => broker.oneclick)
  .map((broker) => { return broker.id; })
  .value();
