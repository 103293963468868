export const pipeInputSchemaTypes = {
  signal: 'signal',
  intents: 'intents',
  number: 'number',
  float: 'float',
  select: 'select',
  valuePaths: 'valuePaths',
  candleInterval: 'candleInterval',
  volumeInterval: 'volumeInterval',
  stopLoss: 'stopLoss',
  timeOfDay: 'timeOfDay',
  vendor: 'vendor',
  readOnly: 'readOnly',
  externalSignalId: 'externalSignalId',
};
