import {
  getDefaultExistingLegConfig, getEmptyAdjustmentActionConfig
} from 'modules/TransactionsBuilder/configs';

const getSignalConfigsForAdjustment = (leftOperand) => {
  return [{
    conditionOperator: '',
    leftOperand,
    operator: 'equalTo',
    rightOperand: '1',
    mode: { isAutoAdjustment: true, adjustmentMode: 'reEntry' }
  }];
};

const buildAdjustmentTransactionsConfigs = (
  configName, maxInstrumentsInGroup, transactionConfigIndex, optionConfigIndex = 0
) => {
  const timesOfAdjustmentConfig = _.times(maxInstrumentsInGroup, () => {
    const emptyAdjustmentActionConfig = getEmptyAdjustmentActionConfig();
    const { mode } = emptyAdjustmentActionConfig;

    return {
      ...emptyAdjustmentActionConfig,
      mode: { ...mode, isAutoAdjustment: true, adjustmentMode: 'reEntry' }
    };
  });

  const adjustConfig = timesOfAdjustmentConfig[transactionConfigIndex];
  const { mode } = adjustConfig;

  switch (configName) {
    case 'equity':
      timesOfAdjustmentConfig[transactionConfigIndex] = {
        ...adjustConfig,
        optionConfigs: [],
        mode: { ...mode, transaction: 'equity' },
        existingLegConfigs: [{
          ...getDefaultExistingLegConfig, legType: 'equity', action: 'reEntry', optionConfigIndex: null
        }]
      };
      break;
    case 'future':
      timesOfAdjustmentConfig[transactionConfigIndex] = {
        ...adjustConfig,
        optionConfigs: [],
        mode: { ...mode, transaction: 'future' },
        existingLegConfigs: [{
          ...getDefaultExistingLegConfig, legType: 'future', action: 'reEntry', optionConfigIndex: null
        }]
      };
      break;
    default:
      timesOfAdjustmentConfig[transactionConfigIndex] = {
        ...adjustConfig,
        optionConfigs: [],
        mode: { ...mode, transaction: 'option' },
        existingLegConfigs: [{
          ...getDefaultExistingLegConfig, legType: 'option', action: 'reEntry', optionConfigIndex
        }]
      };
      break;
  }

  return timesOfAdjustmentConfig;
};

const addAdjustmentConfigsBasedOnParentLegs = (
  config, transactionConfigIndex, configName, optionConfigIndex = 0, maxInstrumentsInGroup, count,
  onChangeCount
) => {
  let reAssignedCount = count;
  const reEntryCount = _.get(config, 'reEntryCount', 0);

  if (reEntryCount === 0) { return { signalConfigs: [], adjustmentConfigs: [] }; }
  const { stopLoss } = config;

  const configs = _.map(_.range(reEntryCount), (reEntryIndex) => {
    switch (configName) {
      case 'equity':
        let equityLeftOperand = '';
        if (reEntryIndex === 0) {
          equityLeftOperand = `ordersExtra.${transactionConfigIndex}`;
          equityLeftOperand += `.${configName}.isPriceCrossedEntryPriceAfterStopLoss`;
        } else {
          equityLeftOperand = `ordersExtra.${transactionConfigIndex}.${configName}`;
          equityLeftOperand += `_${reAssignedCount - 1}.isPriceCrossedEntryPriceAfterStopLoss`;
        }

        reAssignedCount += 1;
        onChangeCount(reAssignedCount);

        return {
          signalConfigs: getSignalConfigsForAdjustment(equityLeftOperand, stopLoss),
          adjustmentConfigs: buildAdjustmentTransactionsConfigs(
            configName, maxInstrumentsInGroup, transactionConfigIndex
          )
        };
      case 'future':
        let futureLeftOperand = `ordersExtra.${transactionConfigIndex}`;
        futureLeftOperand = `.${configName}.isPriceCrossedEntryPriceAfterStopLoss`;

        if (reEntryIndex === 0) {
          futureLeftOperand = `ordersExtra.${transactionConfigIndex}`;
          futureLeftOperand += `.${configName}.isPriceCrossedEntryPriceAfterStopLoss`;
        } else {
          futureLeftOperand = `ordersExtra.${transactionConfigIndex}.${configName}`;
          futureLeftOperand += `_${reAssignedCount - 1}.isPriceCrossedEntryPriceAfterStopLoss`;
        }

        reAssignedCount += 1;
        onChangeCount(reAssignedCount);

        return {
          signalConfigs: getSignalConfigsForAdjustment(futureLeftOperand, stopLoss),
          adjustmentConfigs: buildAdjustmentTransactionsConfigs(
            configName, maxInstrumentsInGroup, transactionConfigIndex
          )
        };
      default:
        let optionLeftOperand = '';
        if (reEntryIndex === 0) {
          optionLeftOperand = `ordersExtra.${transactionConfigIndex}.${configName}`;
          optionLeftOperand += `_${optionConfigIndex}.isPriceCrossedEntryPriceAfterStopLoss`;
        } else {
          optionLeftOperand = `ordersExtra.${transactionConfigIndex}.${configName}_`;
          optionLeftOperand += `${optionConfigIndex}_${reAssignedCount - 1}`;
          optionLeftOperand += '.isPriceCrossedEntryPriceAfterStopLoss';
        }

        reAssignedCount += 1;
        onChangeCount(reAssignedCount);
        return {
          signalConfigs: getSignalConfigsForAdjustment(optionLeftOperand, stopLoss),
          adjustmentConfigs: buildAdjustmentTransactionsConfigs(
            configName, maxInstrumentsInGroup, transactionConfigIndex, optionConfigIndex
          )
        };
    }
  });

  return {
    signalConfigs: _.map(configs, ({ signalConfigs }) => { return signalConfigs; }),
    adjustmentConfigs: _.map(configs, ({ adjustmentConfigs }) => { return adjustmentConfigs; })
  };
};

export const addAdjustmentBasedOnReEntry = (
  transactionConfigs, maxInstrumentsInGroup, aac,
  asc, initialCount
) => {
  let count = initialCount;
  const onChangeCount = (reAssignedCount) => { count = reAssignedCount; };

  let adjustmentSignalConfigs = asc;
  let adjustmentActionConfigs = aac;

  _.each(transactionConfigs, (transactionConfig, transactionConfigIndex) => {
    const { equityConfig, futureConfig, optionConfigs } = transactionConfig;
    const {
      signalConfigs: equitySignalConfigs, adjustmentConfigs: equityAdjustmentConfigs
    } = addAdjustmentConfigsBasedOnParentLegs(
      equityConfig, transactionConfigIndex, 'equity', 0, maxInstrumentsInGroup, count, onChangeCount
    );
    const {
      signalConfigs: futureSignalConfigs, adjustmentConfigs: futureAdjustmentConfigs
    } = addAdjustmentConfigsBasedOnParentLegs(
      futureConfig, transactionConfigIndex, 'future', 0, maxInstrumentsInGroup, count, onChangeCount
    );

    adjustmentSignalConfigs = [...adjustmentSignalConfigs, ...equitySignalConfigs];
    adjustmentSignalConfigs = [...adjustmentSignalConfigs, ...futureSignalConfigs];

    adjustmentActionConfigs = [...adjustmentActionConfigs, ...equityAdjustmentConfigs];
    adjustmentActionConfigs = [...adjustmentActionConfigs, ...futureAdjustmentConfigs];

    _.each(optionConfigs, (optionConfig, optionConfigIndex) => {
      const {
        signalConfigs: optionSignalConfigs, adjustmentConfigs: optionAdjustmentConfigs
      } = addAdjustmentConfigsBasedOnParentLegs(
        optionConfig, transactionConfigIndex, 'option', optionConfigIndex, maxInstrumentsInGroup, count,
        onChangeCount
      );

      adjustmentSignalConfigs = [...adjustmentSignalConfigs, ...optionSignalConfigs];
      adjustmentActionConfigs = [...adjustmentActionConfigs, ...optionAdjustmentConfigs];
    });
  });

  return { adjustmentSignalConfigs, adjustmentActionConfigs, reAssignedCount: count };
};
