import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import { STOPS_VALUE_TYPES } from 'v2/common/constants/index';

const propTypes = {
  trailingInput: PropTypes.string.isRequired,
  trailingValue: PropTypes.number,
  onTrailingValueChange: PropTypes.func.isRequired,
  stopLossType: PropTypes.string.isRequired,
};
const defaultProps = { trailingValue: undefined };

const TrailingStopLossInputs = ({
  trailingInput, trailingValue, onTrailingValueChange, stopLossType
}) => {
  const { Percent, Amount, Points } = STOPS_VALUE_TYPES;
  const renderSymbol = useMemo(() => {
    if (stopLossType === Percent) return '%';
    if (stopLossType === Amount) return <span>&#8377;</span>;
    if (stopLossType === Points) return 'Pts';
    return '';
  }, [Amount, Percent, Points, stopLossType]);

  const [trailingBtnName] = useState(_.uniqueId('trailingBtnName-'));
  const [value, setValue] = useState(trailingValue);

  useEffect(() => { setValue(trailingValue); }, [trailingValue]);

  const onChange = (event) => {
    const targetValue = Number(event.target.value) !== 0 ? event.target.value : undefined;

    onTrailingValueChange({ [trailingInput]: onRegexOnlyNumberAndFloat(targetValue, false) });
  };

  return (
    <div className="input-group overall-stops">
      <div className="symbol">{renderSymbol}</div>
      <input
        type="text"
        className="form-control form-control-sm"
        name={trailingBtnName}
        onBlur={(event) => onChange(event, false)}
        value={value === undefined ? '' : value.toString()}
        onChange={(event) => { setValue(onRegexOnlyNumberAndFloat(event.target.value, true)); }}
      />
    </div>
  );
};

TrailingStopLossInputs.propTypes = propTypes;
TrailingStopLossInputs.defaultProps = defaultProps;

export default TrailingStopLossInputs;
