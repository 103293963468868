import React from 'react';
import { connect } from 'react-redux';

import { QUANT_CONFIG_FIELDS } from 'modules/QuantBuilder/config';
import StopLossOrStopGainInput from './StopLossOrStopGainInput';

const propTypes = {};

const defaultProps = {};

const OverallStopsInput = () => {
  const { overallStopLoss, overallStopGain } = QUANT_CONFIG_FIELDS;

  return (
    <div className="overall-stops-input">
      <div>
        {/* <div className="col-md-2">
           Before => Overall Stops
          <label className="col-form-label col-form-label-sm">Transaction Targets</label>
        </div> */}
        <div className="stop-loss-orderconfig px-1 px-md-3">
          <StopLossOrStopGainInput type={overallStopLoss} />
          <StopLossOrStopGainInput type={overallStopGain} />
          {/* <StopLossOrStopGainInput type={trailingStopLoss} /> */}
        </div>

      </div>
    </div>
  );
};

OverallStopsInput.propTypes = propTypes;
OverallStopsInput.defaultProps = defaultProps;

const mapStateToProps = () => ({
});

export default connect(mapStateToProps)(OverallStopsInput);
