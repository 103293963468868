import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  validator: PropTypes.shape({}).isRequired
};

const ReadOnlyField = ({
  name: propsName, value, control, errors, validator
}) => {
  const uniqueId = useMemo(() => 'read_only__', []);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  const rulesValidator = useMemo(() => {
    if (!_.isEmpty(validator)) return validator;

    return {
      validate: () => {
        if (!value) {
          return `${propsName} field cannot be empty`;
        }

        return null;
      }
    };
  }, [value, validator, propsName]);

  return (
    <ErrorMesssage message={errorMessage}>
      <Controller
        render={({ ref, name }) => (
          <Form.Control
            type="text"
            id={name}
            readOnly
            size="sm"
            name={uniqueId}
            ref={ref}
            value={value}
            onChange={() => {}}
          />
        )}
        control={control}
        defaultValue={value}
        name={uniqueId}
        rules={rulesValidator}
      />
    </ErrorMesssage>
  );
};

ReadOnlyField.propTypes = propTypes;

export default ReadOnlyField;
