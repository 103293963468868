import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import ValueTypeToggleBtn from 'common/components/StopsValueTypeToggleBtn';
import { STOPS_VALUE_TYPES } from 'modules/QuantBuilder/config';

const propTypes = {
  trailingInput: PropTypes.string.isRequired,
  trailingValue: PropTypes.number,
  onTrailingValueChange: PropTypes.func.isRequired,
};
const defaultProps = { trailingValue: undefined };

const TrailingStopLossInputs = ({
  trailingInput, trailingValue, onTrailingValueChange,
}) => {
  const { PERCENT } = STOPS_VALUE_TYPES;
  const [trailingBtnName] = useState(_.uniqueId('trailingBtnName-'));
  const [value, setValue] = useState(trailingValue);

  useEffect(() => { setValue(trailingValue); }, [trailingValue]);

  const onChange = (event) => {
    const targetValue = Number(event.target.value) !== 0 ? event.target.value : undefined;

    onTrailingValueChange({ [trailingInput]: onRegexOnlyNumberAndFloat(targetValue, false) });
  };

  return (
    <div className="input-group overall-stops">
      <ValueTypeToggleBtn valueType={PERCENT} onValueTypeChange={() => { }} isShowPrice={false} />
      <input
        type="text"
        className="form-control form-control-sm"
        name={trailingBtnName}
        onBlur={(event) => onChange(event, false)}
        value={value === undefined ? '' : value.toString()}
        onChange={(event) => { setValue(onRegexOnlyNumberAndFloat(event.target.value, true)); }}
      />
    </div>
  );
};

TrailingStopLossInputs.propTypes = propTypes;
TrailingStopLossInputs.defaultProps = defaultProps;

export default TrailingStopLossInputs;
