import React from 'react';
import PropTypes from 'prop-types';

import QuantBuilder from 'modules/QuantBuilder';
import {
  outputSchemaPropTypes, quantConfigPropTypes, validatorProptypes
} from 'common/propTypes';

const propTypes = {
  isClonedQuant: PropTypes.bool.isRequired,
  runType: PropTypes.string.isRequired,
  onQuantConfigUpdate: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes,
  quantConfig: quantConfigPropTypes,
  quantConfigValidators: PropTypes.shape({}),
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  validatorErrors: validatorProptypes,
  orderType: PropTypes.string.isRequired,
  segment: PropTypes.string.isRequired,
  onChangeSegment: PropTypes.func.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string)
};
const defaultProps = {
  outputSchema: null,
  quantConfig: null,
  quantConfigValidators: {},
  validatorErrors: [],
  segments: []
};

const AdvancedBuilder = (props) => {
  const {
    isClonedQuant,
    runType,
    onQuantConfigUpdate,
    outputSchema,
    quantConfig,
    quantConfigValidators,
    startDate,
    endDate,
    validatorErrors,
    orderType,
    segment,
    onChangeSegment,
    segments
  } = props;

  return (
    <QuantBuilder
      isClonedQuant={isClonedQuant}
      runType={runType}
      onUpdate={onQuantConfigUpdate}
      outputSchema={outputSchema}
      quantConfig={quantConfig}
      quantConfigValidators={quantConfigValidators}
      startDate={startDate}
      endDate={endDate}
      validatorErrors={validatorErrors}
      orderType={orderType}
      segment={segment}
      onChangeSegment={onChangeSegment}
      segments={segments}
    />
  );
};

export default AdvancedBuilder;

AdvancedBuilder.propTypes = propTypes;
AdvancedBuilder.defaultProps = defaultProps;
