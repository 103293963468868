export const initializeConfettiAnimationParty = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/party-js@latest/bundle/party.min.js';
    script.onload = () => { resolve(true); };
    script.onerror = () => { resolve(false); };

    document.body.appendChild(script);
  });
};
