import React from 'react';
import PropTypes from 'prop-types';
import { cyclicPlansPeriods } from 'ui/Plans/config';
import classNames from 'classnames';

const propTypes = {
  planCycleType: PropTypes.string.isRequired,
  onHandlePlanType: PropTypes.func.isRequired,
  cyclicPeriods: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
  plansGroupedByCyclicMonths: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]).isRequired
};

const defaultProps = {
  cyclicPeriods: cyclicPlansPeriods
};

const PlanTypeSelector = ({
  planCycleType, onHandlePlanType, cyclicPeriods, plansGroupedByCyclicMonths
}) => {
  const renderPlanCycleType = (planConfig, key) => {
    if (_.size(plansGroupedByCyclicMonths[planConfig.period]) < 1) { return null; }

    const liClassName = classNames('nav-link btn btn-outline-primary btn-pill', {
      'btn-lightness btn btn-outline-primary btn-lightness-primary': true,
      active: planCycleType === planConfig.value
    });

    return (
      <li className="nav-item" key={key}>
        <a
          className={liClassName}
          onClick={() => onHandlePlanType(planConfig.value)}
          href="#tab"
        >
          {planConfig.label}
        </a>
      </li>
    );
  };

  if (_.isEmpty(cyclicPeriods) || _.size(cyclicPeriods) === 1) return null;

  return (
    <div role="group" className="btn-group btn-pill btn-group-toggle ">
      <ul className="nav nav-tabs plan-tabs" role="tablist">
        {_.map(cyclicPeriods, renderPlanCycleType)}
      </ul>
    </div>
  );
};

PlanTypeSelector.propTypes = propTypes;
PlanTypeSelector.defaultProps = defaultProps;

export default PlanTypeSelector;
