import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import HoverMessage from 'common/components/HoverMessage';

const propTypes = {
  uiConfigs: PropTypes.shape({
    isMoveToCost: PropTypes.bool,
  }).isRequired,
  onUpdateUiConfigs: PropTypes.func.isRequired,
  isValidToEnableMoveToCost: PropTypes.bool.isRequired,
};
const defaultProps = {};

const CaseBasedBehaviourFlags = ({ uiConfigs, onUpdateUiConfigs, isValidToEnableMoveToCost }) => {
  const { isMoveToCost } = uiConfigs;
  const id = _.uniqueId('moveToCost-');
  const isPermissionToShow = _.get(window, 'userPermissions.moveToCost.isEnabled', false);

  const mtcContent = useMemo(() => (
    <div>
      If any leg SL hits, the opposite leg SL is moved to Cost.
      <br />
      <br />
      <b>*Only when positions favour us will we move to SL.</b>
    </div>
  ), []);

  if (!isPermissionToShow) return null;

  return (
    <div className="form-check d-flex flex-row align-items-center">
      <input
        className="form-check-input track"
        type="checkbox"
        disabled={!isValidToEnableMoveToCost}
        id={id}
        onClick={() => {}}
        onChange={() => { onUpdateUiConfigs({ ...uiConfigs, isMoveToCost: !isMoveToCost }); }}
        checked={isMoveToCost}
      />
      <label
        className="form-check-label"
        htmlFor={id}
        disabled={!isValidToEnableMoveToCost}
      >
        Move to Cost
      </label>
      <HoverMessage title="Move to Cost" message={mtcContent}>
        <span className="material-icons-outlined info-icon ml-1">
          info
        </span>
      </HoverMessage>
    </div>
  );
};

CaseBasedBehaviourFlags.propTypes = propTypes;
CaseBasedBehaviourFlags.defaultProps = defaultProps;

export default CaseBasedBehaviourFlags;
