import { RUN_TYPES } from 'v2/common/constants/index';
import { onChangeUiBehaviourFlags } from './uiBehaviourFlags';
import { onChangeOrderType } from './orderType';

export const onChangeRunType = ({
  quantConfig,
  runType,
  orderType
}) => {
  const isLive = runType === RUN_TYPES.live;
  const isGloballyEnabled = isLive
    ? _.get(window, 'userPermissions.waitAndTrade.isEnabled', false)
    : _.get(window, 'userPermissions.waitAndTradeForBacktest.isEnabled', false);

  const isWaitAndTradeEnabledAlready = quantConfig.uiBehaviourFlags.isWaitAndTrade;
  let sanitizedQuantConfig = quantConfig;
  if (isWaitAndTradeEnabledAlready && !isGloballyEnabled) {
    const newUiBehaviourFlags = _.cloneDeep(quantConfig.uiBehaviourFlags);
    newUiBehaviourFlags.isWaitAndTrade = false;
    const { cases, uiBehaviourFlags } = onChangeUiBehaviourFlags({
      cases: sanitizedQuantConfig.cases,
      uiBehaviourFlags: newUiBehaviourFlags
    });
    sanitizedQuantConfig = { ...sanitizedQuantConfig, cases, uiBehaviourFlags };
  }
  const updateConfig = {
    orderType,
    quantConfig: sanitizedQuantConfig,
    runType,
  };

  if (isLive) updateConfig.granularity = 'minute';

  return onChangeOrderType(updateConfig);
};
