import { STRIKE_SELECTION_TYPES } from 'v2/common/constants/index';
import { isStrikeIndexBased } from './config';

export const StrikeUtility = (() => {
  return {
    getStrikeConfigBasedOnType: (newType, comparison) => {
      const isNewIndicatorBased = newType === STRIKE_SELECTION_TYPES.IndicatorValueBasedStrikeIndex.value;
      const newObj = {
        type: newType,
        [STRIKE_SELECTION_TYPES[newType].key]: 0,
      };
      if (isNewIndicatorBased) {
        newObj.valuePath = '';
      }
      if (comparison) {
        newObj.comparison = comparison;
      }
      return newObj;
    },
    getStrikeLabel: (strike) => {
      const { type, comparison = '' } = strike;
      const strikeObj = STRIKE_SELECTION_TYPES[type];
      if (comparison) {
        const comparisonLabel = _.find(strikeObj.comparsions, { value: comparison })?.label || '';
        return `${strikeObj.label} ${comparisonLabel}`;
      }

      return strikeObj.label;
    },
    getStrikeObj: (strike) => {
      const {
        type, valuePath = '', comparison = '', multiplier = 0
      } = strike;
      const strikeObj = STRIKE_SELECTION_TYPES[type];
      const isIndexBased = isStrikeIndexBased(type);
      const valueType = strikeObj.key;
      const value = _.get(strike, valueType, 0);
      const isIndicatorBased = type === STRIKE_SELECTION_TYPES.IndicatorValueBasedStrikeIndex.value;
      return {
        isIndicatorBased,
        isIndexBased,
        value,
        valuePath,
        comparison,
        multiplier,
        type,
        valueType,
      };
    },
    getStrikeSelections: () => {
      return _.chain(STRIKE_SELECTION_TYPES)
        .filter(({ isShow }) => isShow)
        .map((obj) => {
          if (_.isEmpty(obj.comparsions)) {
            return { label: obj.label, value: `${obj.value}-` };
          }

          return obj.comparsions.map((comp) => {
            return {
              label: `${obj.label} ${comp.label}`,
              value: `${obj.value}-${comp.value}`,
            };
          });
        })
        .flatten()
        .value();
    },
    isPermissionToChoose: (type) => {
      return _.get(
        _.find(STRIKE_SELECTION_TYPES, ({ value: val }) => val === type),
        'isPermissionToChoose',
        false
      );
    },
    isDeltaBased: (type) => {
      return type === STRIKE_SELECTION_TYPES.DeltaBased.value;
    },
    isPremiumBased: (type) => {
      return type === STRIKE_SELECTION_TYPES.PremiumBased.value;
    },
    getStrikeAlteredObj: (strike) => {
      const { type, comparison = '' } = strike;
      const isPremiumOrDeltaBased = StrikeUtility.isPremiumBased(type) || StrikeUtility.isDeltaBased(type);
      if (isPremiumOrDeltaBased && !comparison) {
        return {
          ...strike,
          comparison: STRIKE_SELECTION_TYPES.PremiumBased.comparsions[0].value,
        };
      }
      return strike;
    },
  };
})();
