import React from 'react';
import ReactHtmlParser, { processNodes } from 'react-html-parser';
import PropTypes from 'prop-types';
import qs from 'qs';

import { signUpFormProps, socialMedias } from 'ui/LandingPage/config';

const propTypes = {
  errorMessages: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  referredBy: PropTypes.string
};
const defaultProps = {
  errorMessages: null,
  referredBy: null,
};

const transform = (node, index) => {
  /* eslint-disable consistent-return */
  if (node.type !== 'tag') {
    return;
  }

  return (
    <div key={index}>
      {processNodes(node.children, transform)}
    </div>
  );
  /* eslint-enable consistent-return */
};

const SignUp = (props) => {
  const { errorMessages, referredBy: propsRefrredBy } = props;
  const queryString = window.location.search;
  const parsedQueryString = qs.parse(queryString, { parseBooleans: true });
  const referredBy = !propsRefrredBy
    ? _.get(parsedQueryString, 'referred_by', propsRefrredBy) : propsRefrredBy;

  const renderFormInputs = (formProps, field) => (
    <div className="form-group" key={field}>
      <label>
        {_.startCase(field)}
        <span className="text-danger">*</span>
      </label>
      <div className="inside-icon">
        <i className="material-icons-outlined icons">{`${formProps.icon}`}</i>
        <input
          {...formProps}
          className="form-control"
          defaultValue={props[field]}
        />
      </div>
    </div>
  );

  return (
    <div>
      <div className="text-danger mt-2">
        {!!errorMessages && ReactHtmlParser(errorMessages, { transform })}
      </div>
      <form className="login-form mt-4" method="post" action="/users" autoComplete="new-password">
        {_.map(signUpFormProps, renderFormInputs)}
        <input type="hidden" name="user[referred_by]" defaultValue={referredBy} />
        <button
          type="submit"
          className="btn btn-block btn-primary btn-pill mt-4"
        >
          Create Account
        </button>
      </form>
      <div className="clearfix" />

      <h6 className="text-center mb-3 hidden-for-future">Or sign up with social account</h6>
      <div className="text-center hidden-for-future">
        {_.map(socialMedias, (socialProps, key) => (
          <a key={key} href={socialProps.href} className={socialProps.className} /> // eslint-disable-line
        ))}
      </div>

      <div className="clearfix" />
      <div className="social-login-container">
        <div className="text-center">Sign up with social media</div>
        <a href="/auth/facebook" className="social"><img src="/facebook.svg" alt="facebook" /></a>
        <a href="/auth/twitter2" className="social"><img src="/twitter.svg" alt="twitter" /></a>
      </div>
    </div>
  );
};

SignUp.propTypes = propTypes;
SignUp.defaultProps = defaultProps;

export default SignUp;
