const BUY = 'buy';
const SELL = 'sell';

const isEntryBuyOptionsOrdersFirst = (configs) => {
  return _.some(configs, (config) => {
    const optionConfigs = _.get(config, 'optionConfigs', []);
    const futureConfig = _.get(config, 'futureConfig', {});
    const equityConfig = _.get(config, 'equityConfig', {});

    const optionBuys = _.filter(optionConfigs, (optionConfig) => {
      const { entryType } = optionConfig;
      return entryType === BUY;
    });
    const optionSells = _.filter(optionConfigs, (optionConfig) => {
      const { entryType } = optionConfig;
      return entryType === SELL;
    });

    const onlyOptionsTrue = _.some(optionBuys, ({ optionType }) => {
      return _.some(optionSells, ({ optionType: optionTypeSell }) => optionTypeSell === optionType);
    });

    if (onlyOptionsTrue) return true;
    // Have atleast one Buy in options in optionConfig.. and must have to atleast one sell.. if there is
    // 3 optionConfig is BUY.. no sell .. return false.. 3 BUY and 1 SELL return true.

    if (equityConfig) { // if option has BUY.. future must be true
      const { entryType } = equityConfig;
      if (entryType && !_.isEmpty(optionBuys)) return true;
    }
    if (futureConfig) { // if option has BUY.. equity must be true
      const { entryType } = futureConfig;
      if (entryType && !_.isEmpty(optionBuys)) return true;
    }

    return false;
  });
};

const filteredTransactionConfigs = (transactionConfigs) => {
  return (
    _.map(transactionConfigs, (transactionConfig) => {
      const equityConfig = _.get(transactionConfig, 'equityConfig', '');
      const futureConfig = _.get(transactionConfig, 'futureConfig', '');
      const optionConfigs = _.get(transactionConfig, 'optionConfigs', []);

      return {
        equityConfig: !_.isEmpty(equityConfig.entryType) ? equityConfig : null,
        futureConfig: !_.isEmpty(futureConfig.entryType) ? futureConfig : null,
        optionConfigs,
      };
    })
  );
};

export const isShowEntryBuyOptionsOrdersFirst = (orderConfigs = []) => {
  return _.some(orderConfigs, (orderConfig) => {
    const transactionConfigs = _.get(orderConfig, 'transactionConfigs', []);
    const adjustmentActionConfigs = _.get(orderConfig, 'adjustmentActionConfigs', []);
    const isShowBasedOnAdjustment = _.some(adjustmentActionConfigs, (adjustmentActionConfig) => {
      return isEntryBuyOptionsOrdersFirst(adjustmentActionConfig);
    });

    const isCloseBasedShow = _.some(adjustmentActionConfigs, (adjustmentActionConfig) => {
      return _.some(adjustmentActionConfig, (config) => {
        const { mode: { transaction }, existingLegConfigs } = config;
        const isClosePresent = _.find(existingLegConfigs, ({ action }) => action === 'close');
        return isClosePresent && transaction;
      });
    });

    return isEntryBuyOptionsOrdersFirst(transactionConfigs) || isShowBasedOnAdjustment || isCloseBasedShow;
  });
};

export const isTransactionHaveMoreThanOneLeg = (orderConfigs = []) => {
  return _.some(orderConfigs, (orderConfig) => {
    const transactionConfigs = _.get(orderConfig, 'transactionConfigs', []);

    return (
      _.some(filteredTransactionConfigs(transactionConfigs), (filteredconfigs) => {
        const { optionConfigs, equityConfig, futureConfig } = filteredconfigs;
        const legsCount = (_.size(optionConfigs) + _.size(equityConfig) + _.size(futureConfig));
        return legsCount > 1;
      })
    );
  });
};

export const isIndividualStoplossAvailable = (orderConfigs = []) => {
  return _.some(orderConfigs, (orderConfig) => {
    const transactionConfigs = _.get(orderConfig, 'transactionConfigs', []);

    return (
      _.some(filteredTransactionConfigs(transactionConfigs), (filteredconfigs) => {
        const { optionConfigs, equityConfig, futureConfig } = filteredconfigs;
        const configs = [...optionConfigs, equityConfig, futureConfig];
        return _.some(configs, (config) => config && (config.stopLoss || config.stopLossPrice));
      })
    );
  });
};
