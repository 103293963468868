import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import { ORDER_TYPES } from 'common/constants/index';
import ErrorMesssage from 'common/components/ErrorMesssage';
import MoneyDisplay from 'common/components/MoneyDisplay';
import { QuantOrderType } from 'ui/run/RunForm/components/index';
import Multiplier from './Multiplier';
import FreeTrialInfo from './freeTrialInfo';

const propTypes = {
  multiplier: PropTypes.number,
  onChangeMultiplier: PropTypes.func.isRequired,
  onCreateRun: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  selectedOrderType: PropTypes.string.isRequired,
  onChangeSelectedOrderType: PropTypes.func.isRequired,
  marginAmount: PropTypes.number.isRequired,
  isMinifiedPage: PropTypes.bool.isRequired,
  initialFreeTrialDays: PropTypes.number,
  purchaseAmount: PropTypes.number,
  isFreeTrialAvailable: PropTypes.bool
};
const defaultProps = {
  multiplier: 1,
  initialFreeTrialDays: 0,
  purchaseAmount: null,
  isFreeTrialAvailable: false
};

const MULTIPLIER_LIMIT = 200;

const StrategyConfirmationModal = ({
  multiplier, onChangeMultiplier, onCreateRun, onCloseModal,
  selectedOrderType, onChangeSelectedOrderType, marginAmount, isMinifiedPage,
  initialFreeTrialDays, purchaseAmount, isFreeTrialAvailable
}) => {
  const [isAgreed] = useState(true);
  // setIsAgreed
  const orderTypes = _.omit(ORDER_TYPES, isMinifiedPage ? ['oneClick', 'paper'] : ['oneClick']);
  const isInValidMultiplier = multiplier > MULTIPLIER_LIMIT;

  return (
    <Modal
      onSave={onCreateRun}
      onClose={onCloseModal}
      size="md"
      okBtnTitle="Deploy Strategy"
      isOkBtnDisabled={!isAgreed || isInValidMultiplier}
      btnClassName="btn-success btn-sm"
    >
      <>
        <div className="text-center mb-2">
          <div className="alert-icons icon-info alert-info" />
          <h4 className="font-weight-medium mb-3">Confirmation</h4>
          <p>
            Number of lots
          </p>
          <div className="my-2">
            <Multiplier
              multiplier={multiplier}
              onChangeMultiplier={onChangeMultiplier}
              maxMultiplier={MULTIPLIER_LIMIT}
            />
          </div>
          Margin Required:
          <b>
            <MoneyDisplay>{multiplier ? marginAmount * Math.abs(multiplier) : marginAmount}</MoneyDisplay>
          </b>
          {isInValidMultiplier && (
            <ErrorMesssage
              error={{ message: `*You are exceed, Limit for multiplier is upto ${MULTIPLIER_LIMIT}` }}
            />
          )}
          <QuantOrderType
            orderType={selectedOrderType}
            onOrderTypeChange={onChangeSelectedOrderType}
            orderTypes={orderTypes}
            orderTypeParentClassName="d-flex gap-15 mt-2 justify-content-center"
            isShowOrderTypeLabel={false}
          />
          <div className="tx-12 mt-2">
            The past backtesting performance of the strategy is not necessarily
            indicative of future performance
            of the strategy and trading is subject to market risks
          </div>

          {isFreeTrialAvailable && (
            <FreeTrialInfo
              initialFreeTrialDays={initialFreeTrialDays}
              purchaseAmount={purchaseAmount}
              isStrategyConfirmationModel
            />
          )}
        </div>
      </>
    </Modal>
  );
};

StrategyConfirmationModal.propTypes = propTypes;
StrategyConfirmationModal.defaultProps = defaultProps;

export default StrategyConfirmationModal;
