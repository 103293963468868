import wrappedFetch from './base';

export const fetchV2JobDetails = (jobId) => {
  return wrappedFetch(`/jobs/${jobId}/get_v2_job_details`)
    .then((response) => response.json());
};

export const fetchV2DashboadJobsDetails = () => {
  return wrappedFetch('v2_engine/dashboard_job_details')
    .then((response) => response.json());
};

export const V2JobsAction = (jobIds, action) => {
  const uri = 'v2_engine/jobs_action';

  const params = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ job_ids: jobIds, action_type: action }),
  };

  return wrappedFetch(uri, params)
    .then((response) => response.json());
};
