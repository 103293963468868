import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import { rightOperandPropTypes } from 'common/propTypes';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { onRegexOnlyNumber } from 'common/stringUtils/toPositiveNumberString';

const propTypes = {
  onChangeRightOperand: PropTypes.func.isRequired,
  rightOperand: rightOperandPropTypes.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};

const defaultProps = {};

const UNITS = ['hours', 'minutes', 'seconds'];

const Duration = ({
  onChangeRightOperand, rightOperand, control, errors
}) => {
  const [uniqueId] = useState(_.uniqueId('Duration-'));
  const value = _.parseInt(rightOperand) || 0;
  const unit = _.trim(_.replace(rightOperand, value, '')) || 'minutes';

  const rulesValidator = useMemo(() => {
    return {
      validate: () => {
        if (Number(value) === 0) {
          return 'Please enter a valid value';
        }

        return null;
      },
    };
  }, [value]);

  const onDurationChange = (newValue, onChange) => {
    const newDuration = `${newValue}${unit}`;
    onChangeRightOperand(newDuration);
    onChange(newDuration);
  };

  const onUnitChange = (newUnit, onChange) => {
    const newDuration = `${value}${newUnit}`;
    onChangeRightOperand(newDuration);
    onChange(newDuration);
  };

  const renderOptions = (option) => (
    <option key={option} value={option}>
      {_.startCase(option)}
    </option>
  );

  return (
    <>
      <Row noGutters>
        <Col>
          <Controller
            render={({ onChange, name, ref }) => (
              <Form.Control
                type="number"
                id={name}
                size="sm"
                name={uniqueId}
                ref={ref}
                value={value === 0 ? '' : value.toString()}
                onChange={(event) => {
                  onDurationChange(onRegexOnlyNumber(event.target.value), onChange);
                }}
              />
            )}
            control={control}
            defaultValue={value}
            name={uniqueId}
            rules={rulesValidator}
          />
        </Col>
        <Col>
          <Controller
            render={({ onChange, name, ref }) => (
              <Form.Control
                as="select"
                id={name}
                size="sm"
                name={uniqueId}
                ref={ref}
                value={unit}
                onChange={(event) => {
                  onUnitChange(event.target.value, onChange);
                }}
              >
                {_.map(UNITS, renderOptions)}
              </Form.Control>
            )}
            control={control}
            defaultValue={unit}
            name={uniqueId}
            rules={rulesValidator}
          />
        </Col>
      </Row>
      <ErrorMesssage message={errors[uniqueId]?.message || ''} />
    </>
  );
};

Duration.propTypes = propTypes;
Duration.defaultProps = defaultProps;

export default memo(Duration);
