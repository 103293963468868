import React from 'react';
import PropTypes from 'prop-types';
import ReactDataTable from 'common/components/ReactDataTable';

const propTypes = {
  linkedUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const LinkedUserList = ({ linkedUsers }) => {
  const columns = [
    { Header: 'Broker', accessor: 'broker' },
    { Header: 'Broker Client Id', accessor: 'broker_client_id' },
    { Header: 'SignedUp Date', accessor: 'signed_up' },
  ];

  return (
    <div>
      <ul>
        <ReactDataTable columns={columns} data={linkedUsers} />
      </ul>
    </div>
  );
};

LinkedUserList.propTypes = propTypes;

export default LinkedUserList;
