import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  multiplier: PropTypes.number,
  onChangeMultiplier: PropTypes.func.isRequired,
  maxMultiplier: PropTypes.number
};

const defaultProps = { multiplier: 1, maxMultiplier: 10 };

const Multiplier = ({ multiplier, onChangeMultiplier, maxMultiplier }) => {
  return (
    <div className="multiplier-action">
      <div className="d-flex align-items-center gap-5">
        <button
          className="btn btn-secondary btn-sm"
          disabled={multiplier === 1}
          onClick={() => {
            onChangeMultiplier(multiplier ? (Number(multiplier) - 1) : 1);
          }}
          type="button"
        >
          <span className="material-icons-outlined">remove</span>
        </button>
        <input
          type="number"
          className="form-control form-control-sm"
          id="multiplier"
          min={0}
          max={200}
          value={multiplier ? multiplier.toString() : ''}
          onChange={(event) => {
            onChangeMultiplier(Number(event.target.value));
          }}
        />
        <button
          className="btn btn-secondary btn-sm"
          disabled={maxMultiplier && multiplier >= maxMultiplier}
          onClick={() => {
            onChangeMultiplier(multiplier ? (Number(multiplier) + 1) : 1);
          }}
          type="button"
        >
          <span className="material-icons-outlined">add</span>
        </button>
      </div>
    </div>
  );
};

Multiplier.propTypes = propTypes;
Multiplier.defaultProps = defaultProps;

export default Multiplier;
