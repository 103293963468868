import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

const isPermissionToAddManualAdjustment = _.get(window, 'userPermissions.adjustments.isEnabled', false)
  || window?.isAdmin || false;

const useCasesError = (cases) => {
  const uniqueId = useMemo(() => 'cases_based_error', []);
  const { errors, setError, clearErrors } = useFormContext();
  const error = _.get(errors, uniqueId, {});
  const errorMessage = error?.message || '';

  const adjustmentsError = (kase, idx) => {
    const adjustments = _.get(kase, 'adjustments', []);

    if (_.isEmpty(adjustments)) return {};
    if (isPermissionToAddManualAdjustment) return {};

    return {
      errorMessage: 'Please purchase a premium plan to use adjustments.',
      errorConfigs: {},
      isPremiumError: true,
      caseIndex: idx
    };
  };

  useEffect(() => {
    const errorConfigs = {};
    _.forEach(cases, (kase, idx) => {
      errorConfigs[idx] = { adjustments: adjustmentsError(kase, idx), caseIndex: idx };
    });

    const errorCaseIndex = _.find(errorConfigs, ({ adjustments }) => !_.isEmpty(adjustments))?.caseIndex;
    if (!_.isUndefined(errorCaseIndex)) {
      setError(uniqueId, {
        type: 'custom',
        message: 'Please resolve the errors in the cases',
        forceScrollTo: '.cases-wrap .tab-content .adjust-signal-container',
        caseIndex: errorCaseIndex,
        errorConfigs
      });
    } else {
      clearErrors(uniqueId);
    }

    return () => clearErrors(uniqueId);
  }, [clearErrors, uniqueId, setError, cases]);

  return { errorMessage, errorConfigs: error?.errorConfigs || {} };
};

export default useCasesError;
