import { QUOTA_FOR_SECOND_WISE_BACKTEST, RUN_TYPES } from 'common/constants/index';
import moment from 'moment';

export const isIndexInstrumentPresentInQuantInstrumentGroups = (quantInstrumentGroups) => {
  if (_.isEmpty(quantInstrumentGroups)) return false;

  const { indexInstruments } = window;
  const instruments = _.flattenDeep(quantInstrumentGroups);

  const difference = _.filter(instruments, (
    instrument
  ) => !_.includes(indexInstruments, instrument)); // take not matching instruments
  // eg: quant ['ICICIBANK', 'BANKNIFTY'] .. index instrument = ['BANKNIFTY'] .. result ['ICICIBANK']

  if (_.isEmpty(difference)) return true;

  return false;
};

export const equityNotPossibleInstruments = window.indexInstruments;

export const checkInstrumentPossibleForEquity = (quantInstrumentGroups) => {
  const instruments = _.flattenDeep(quantInstrumentGroups);

  const difference = _.filter(instruments, (
    instrument
  ) => _.includes(equityNotPossibleInstruments, instrument));

  if (_.isEmpty(difference)) return true;

  return false;
};

export const removeExchangeFromInstrument = (instrument) => {
  return _.replace(instrument, /NFO:|BFO:/g, '');
};

export const getQuotaConsumption = (
  runType, startDate, endDate, granularity, instrumentGroups = [], cases = [],
) => {
  const numberOfInstruments = _.size(_.flattenDeep(instrumentGroups));
  const numberOfCases = _.isEmpty(cases) ? 1 : _.size(cases);

  if (runType === RUN_TYPES.live) {
    return {
      requiredQuota: numberOfInstruments * numberOfCases,
      quotaBreakout: { numberOfInstruments, numberOfCases }
    };
  }

  const numberOfCreditsForGranularitySecond = granularity === 'second' ? QUOTA_FOR_SECOND_WISE_BACKTEST : 1;

  const years = _.max([1, Math.ceil(Number(moment(endDate).diff(moment(startDate), 'days') / 366.0))]);
  const requiredQuota = numberOfInstruments * years * numberOfCases * numberOfCreditsForGranularitySecond;

  return {
    requiredQuota,
    quotaBreakout: {
      numberOfInstruments, years, numberOfCases, numberOfCreditsForGranularitySecond
    }
  };
};
