import { setOrderConfigs } from 'modules/QuantBuilder/actions';
import {
  getAdjustmentActionConfigsWithoutAutoAdjustment,
  getAdjustmentSignalConfigsWithoutAutoAdjustment
} from
  // eslint-disable-next-line max-len
  'modules/TransactionsBuilder/NewTransactionBuilder/components/AdvancedFeaturesEnablement/AdjustmentAdaptor/index';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const propTypes = {
  children: PropTypes.element.isRequired,
};

const defaultProps = {};

const AdjustmentWrapper = (props) => {
  const children = _.get(props, 'children', null);
  const configs = _.get(props, 'adjustmentSignalConfigs');
  const { orderConfigIndex } = configs;

  const globalState = useSelector((state) => state);
  const orderConfigs = _.get(globalState, 'quantConfig.orderConfigs', []);
  const dispatch = useDispatch();

  const currentOrderConfig = _.get(orderConfigs, orderConfigIndex, {});
  const adjustmentActionConfigs = getAdjustmentActionConfigsWithoutAutoAdjustment(
    _.get(currentOrderConfig, 'adjustmentActionConfigs', [])
  );
  const adjustmentSignalConfigs = getAdjustmentSignalConfigsWithoutAutoAdjustment(
    _.get(currentOrderConfig, 'adjustmentSignalConfigs', [])
  );
  const isPermissionAddManualAdjustment = _.get(window, 'userPermissions.adjustments.isEnabled', {});
  // custom Adjustment...

  useEffect(() => {
    if (!isPermissionAddManualAdjustment
      && (!_.isEmpty(adjustmentSignalConfigs) || !_.isEmpty(adjustmentActionConfigs))) {
      orderConfigs[orderConfigIndex] = {
        ...orderConfigs[orderConfigIndex], adjustmentSignalConfigs: [], adjustmentActionConfigs: []
      };

      dispatch(setOrderConfigs(orderConfigs));
    }
  }, []);

  if (!isPermissionAddManualAdjustment) return null;

  return children;
};

AdjustmentWrapper.propTypes = propTypes;
AdjustmentWrapper.defaultProps = defaultProps;

export default AdjustmentWrapper;
