import moment from 'moment';
import { SEGMENT_CONFIG } from 'common/constants/index';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import { getEmptyOrderConfig } from './signalConfigs';

export const TRADE_TYPES = {
  positional: 'positional',
  intraday: 'intraday'
};

export {
  signalConditionOptions,
  defaultSignalConfigs,
  CONDITION_OPERATORS,
  getEmptySignalConfig,
  COMPARISION_TYPE_OPERATORS,
  SIGNAL_CONFIG,
  getEmptyOrderConfig,
  WEEKLY_OPTION_AVAILABLE_FROM
} from './signalConfigs';

export const QUANT_CONFIG_FIELDS = {
  instrumentGroups: 'instrumentGroups',
  pipeConfigs: 'pipeConfigs',
  entrySignalConfigs: 'entrySignalConfigs',
  exitSignalConfigs: 'exitSignalConfigs',
  expiryConfigs: 'expiryConfigs',
  transactionConfigs: 'transactionConfigs',
  name: 'name',
  trailingStopLoss: 'trailingStopLoss',
  overallStopLoss: 'overallStopLoss',
  overallStopGain: 'overallStopGain',
  overallStopGainPrice: 'overallStopGainPrice',
  overallStopLossPrice: 'overallStopLossPrice',
  orderConfigs: 'orderConfigs',
  stopAndLimitPriceDifferenceInPercentage: 'stopAndLimitPriceDifferenceInPercentage',
  gapBetweenBuyAndSellOrdersInSeconds: 'gapBetweenBuyAndSellOrdersInSeconds',
  combinedPremiumConfigs: 'combinedPremiumConfigs',
  entryOrderModification: 'entryOrderModification',
  exitOrderModification: 'exitOrderModification'
};

export const EXPIRY_TYPES = {
  auto: {
    key: 'auto',
    diaplayName: 'Auto Expiry',
  },
  expire: {
    key: 'expire',
    diaplayName: 'Manual Expiry',
  },
  rollover: {
    key: 'rollover',
    diaplayName: 'RollOver',
  }
};

export const OPTIONS_EXPIRY = {
  monthly: 'monthly',
  weekly: 'weekly'
};

export const HOURS = _.range(9, 16);
export const MINUTES = _.range(0, 60);
export const DAYS = {
  [OPTIONS_EXPIRY.monthly]: _.range(0, 30),
  [OPTIONS_EXPIRY.weekly]: _.range(0, 5),
};
export const ENTRY_TIME = 'entryTime';
export const EXIT_TIME = 'exitTime';

export const getEmptyTimeBasedSignal = () => ({
  entryTime: '',
  exitTime: ''
});

export const getDefaultExpiryHandling = () => ({
  type: EXPIRY_TYPES.auto.key,
  exitTime: '15:28',
  exitDaysBefore: 0
});

export const getEmptyExpiryHandling = () => ({
  type: EXPIRY_TYPES.auto.key,
  exitTime: '',
  exitDaysBefore: 0
});

export const getDefaultTimeBasedSignalForIntraday = () => ({
  entryTime: '09:15',
  exitTime: '15:25'
});

export const QUANT_CONFIG_ORDER_TYPE = {
  market: 'market',
  limit: 'limit',
  'sl-l': 'sl-l'
};

export const PRODUCT_TYPE = {
  mis: 'mis',
  normal: 'normal'
};

export const STRIKE_INDEX = 'strikeIndex';

export const getOrderModificationEmptyConfig = { action: '', actionTimeInSeconds: 0 };

export const isPlaceOptionsEntryBuyOrdersFirst = '';

const defaultMaxInstrumentsInGroup = 1;
export const emptyQuantConfig = () => ({
  chartConfig: {
    type: 'candle',
    boxUnitSize: 5,
    boxPercentage: 0,
    isContinuousRollup: false,
  },
  segment: SEGMENT_CONFIG.equity,
  entryOrderType: QUANT_CONFIG_ORDER_TYPE.market,
  exitOrderType: QUANT_CONFIG_ORDER_TYPE.market,
  expiryHandling: getEmptyExpiryHandling(),
  instrumentGroups: [],
  maxInstrumentsInGroup: defaultMaxInstrumentsInGroup,
  maxTransactionsPerDay: 1,
  optionsExpiryCycle: OPTIONS_EXPIRY.monthly,
  optionStrikeCalculationBasedOn: STRIKE_INDEX,
  orderConfigs: [].concat(getEmptyOrderConfig(defaultMaxInstrumentsInGroup)),
  overallStopGain: undefined,
  overallStopGainPrice: undefined,
  overallStopLoss: undefined,
  overallStopLossPrice: undefined,
  combinedPremiumConfigs: [],
  pipeConfigs: [],
  product: PRODUCT_TYPE.mis,
  repeatOrder: true,
  stopAfterTrade: false,
  timeBasedSignal: getDefaultTimeBasedSignalForIntraday(),
  tradingType: TRADE_TYPES.intraday,
  trailingStopLoss: undefined,
  stopAndLimitPriceDifferenceInPercentage: 0,
  gapBetweenBuyAndSellOrdersInSeconds: 0,
  isSquareOffTransactionIfEntryFailed: false,
  limitOrderBufferPercentage: 0,
  customPropConfigs: [],
  adjustmentOptions: {},
});

export const emptyCustomPropConfig = {
  name: '', // <frontend-usage-only>
  outputSchema: '', // <frontend-usage-only>
  code: '',
  inputVals: ''
};

// For Modify Integration added
// entryOrderModification: { action: '', actionTimeInSeconds: 0 }
// exitOrderModification: { action: '', actionTimeInSeconds: 0 }
// those key values is only for actual....
// -These keyValue has validation in quant schema, when we send the keys

// For Options Buy Place First
// isPlaceOptionsEntryBuyOrdersFirst: true || false
// same as
// these key values is only for actual....
// -This keyValue has validation in frontend true or false selector...
// backtest and paper.. those key values are not come.. ProfitAndOrdersComponents.jsx

export const CHART_PATTERNS = {
  candle: 'candle',
  heikinashi: 'heikinashi',
  renko: 'renko'
};

export const groupingOptions = [
  {
    type: 'single',
    noOfInstruments: 1
  },
  {
    type: 'pair',
    noOfInstruments: 2
  }
];

export const START_OF_DATE = moment('01-06-2019', 'DD-MM-YYYY').toDate();
export const END_OF_DATE = moment().subtract(1, 'days').toDate();

export const STOPS_VALUE_TYPES = {
  PERCENT: 'percent',
  PRICE: 'price'
};

const getEmptyOverAllStopGainConfig = () => ({
  overallStopGain: undefined,
  overallStopGainPrice: undefined
});

const getEmptyOverAllTrailingStopLossConfig = () => ({
  trailingStopLoss: undefined,
  trailingStopLossPrice: undefined
});

const getOverAllStopLossConfig = () => ({
  overallStopLoss: undefined,
  overallStopLossPrice: undefined
});

export const overAllStopsConfig = {
  overallStopGain: {
    percent: {
      key: 'overallStopGain',
      config: getEmptyOverAllStopGainConfig()
    },
    price: {
      key: 'overallStopGain',
      config: getEmptyOverAllStopGainConfig()
    }
  },
  overallStopLoss: {
    percent: {
      key: 'overallStopLoss',
      config: getOverAllStopLossConfig()
    },
    price: {
      key: 'overallStopLoss',
      config: getOverAllStopLossConfig()
    }
  },
  trailingStopLoss: {
    percent: {
      key: 'trailingStopLoss',
      config: getEmptyOverAllTrailingStopLossConfig()
    },
    price: {
      key: 'trailingStopLoss',
      config: getEmptyOverAllTrailingStopLossConfig()
    }
  }
};

export const getAlteredEmptyQuantConfig = () => {
  const currentCandle = PipeDefinitionUtility.getPipeConfigDefaults('currentCandle');
  const currentoneMinuteCandleFuturePrice = {
    ...currentCandle,
    name: 'Current 5 minute',
  };

  return {
    ...emptyQuantConfig(),
    pipeConfigs: [currentoneMinuteCandleFuturePrice]
  };
};
