import wrappedFetch from './base';

export const validateCustomScript = (script, previousPipeConfigs) => {
  const params = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      pipeConfigs: previousPipeConfigs,
      script
    }),
  };
  const url = '/external/api/validate_custom_script';

  return wrappedFetch(url, params)
    .then((response) => response.json())
    .catch((err) => {
      console.log('err trying to validate script', (err && err.message) ? err.message.slice(0, 200) : err);
      const error = [
        'Error validating script. Please check your script.',
        'If you are still having issues, try again after sometime or please contact support.'
      ];
      return { success: false, error };
    });
};
