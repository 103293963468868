import React from 'react';
import BgBlink from 'common/components/BgBlink';
import PropTypes from 'prop-types';

const propTypes = {
  tockGroup: PropTypes.shape({}).isRequired,
  instrumentGroup: PropTypes.arrayOf(PropTypes.string).isRequired,
  headerData: PropTypes.arrayOf(PropTypes.shape({}))
};
const defaultProps = {
  quantConfig: {}
};

const getTdProps = (currentDataColKey) => {
  if (currentDataColKey === 'title') {
    return {
      colSpan: 2,
      key: currentDataColKey,
    };
  }

  return {
    className: 'text-right',
    key: currentDataColKey,
  };
};

const getInstrumentsMarketData = (tockGroup, instrumentGroup, headerData) => {
  const marketData = {};

  return _.map(headerData, (data) => {
    const { label, value, segment } = data;

    _.each(instrumentGroup, (instrument, idx) => {
      marketData[instrument] = _.get(tockGroup, [`item${idx}`, segment, value], 0);
    });

    return ({
      title: label,
      ...marketData
    });
  });
};

const InstrumentsMarketDataTable = ({ tockGroup, instrumentGroup, headerData }) => {
  const instrumentsMarketData = getInstrumentsMarketData(tockGroup, instrumentGroup, headerData);

  return _.map(instrumentsMarketData, (data, idx) => (
    <tr key={idx}>
      {_.map(data, (value, key) => (
        <td {...getTdProps(key)}>
          <BgBlink>{value}</BgBlink>
        </td>
      ))}
    </tr>
  ));
};

InstrumentsMarketDataTable.defaultProps = defaultProps;
InstrumentsMarketDataTable.propTypes = propTypes;

export default InstrumentsMarketDataTable;
