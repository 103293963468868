import { INTENT_TYPES } from 'v2/common/constants/index';
import { defaultLinearTrail } from '../configs/index';

const getSanitizedIntents = (intents, uiBehaviourFlags) => {
  const {
    isReEntry, isReExecute, isTrailingStopLoss, isWaitAndTrade
  } = uiBehaviourFlags;

  const sanitizedIntents = _.chain(intents)
    .map((intent) => {
      const isOpenLeg = intent?.type === INTENT_TYPES.OpenLeg;
      if (!isOpenLeg) return intent; // Do not sanitize non open leg intents

      const newIntent = _.cloneDeep(intent);
      if (!isTrailingStopLoss) {
        newIntent.stopLoss.trail = defaultLinearTrail;
      }
      if (!isReEntry) {
        newIntent.reEntryCount = 0;
      }
      if (!isReExecute) {
        newIntent.reExecuteCount = 0;
      }
      if (!isWaitAndTrade) {
        newIntent.waitUntilPremiumToIncreaseInPercentage = 0;
        newIntent.waitUntilPremiumToDecreaseInPercentage = 0;
        newIntent.waitAndTradeType = 'waitUntilPremiumToIncreaseInPercentage';
      }
      return newIntent;
    })
    .value();

  return sanitizedIntents;
};

const getSanitizedCases = (cases, uiBehaviourFlags) => {
  const sanitizedCases = cases.map((caseConfig) => {
    const {
      entry,
      entry: { intents },
      adjustments,
    } = caseConfig;
    return {
      ...caseConfig,
      entry: {
        ...entry,
        intents: getSanitizedIntents(intents, uiBehaviourFlags),
      },
      adjustments: adjustments.map((adjustment) => {
        return {
          ...adjustment,
          intents: getSanitizedIntents(adjustment.intents, uiBehaviourFlags),
        };
      }),
    };
  });

  return sanitizedCases;
};

export const onChangeUiBehaviourFlags = ({ cases, uiBehaviourFlags }) => {
  return {
    cases: getSanitizedCases(cases, uiBehaviourFlags),
    uiBehaviourFlags,
  };
};
