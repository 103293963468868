import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  instrumentGroupsPropTypes, pipeConfigsPropTypes, runPropTypes, userPropTypes
} from 'common/propTypes';
import LiveMonitor from '../LiveMonitor/index';
import { OrderContext } from '../../OrderStore/index';
import TradeSkipDates from './TradeSkipDates';
import TransactionDetail from '../TransactionDetail/index';

const propTypes = {
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  run: runPropTypes.isRequired,
  jobIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentUser: userPropTypes
};

const defaultProps = { currentUser: null };

const LiveDetails = ({
  instrumentGroups, pipeConfigs, run, jobIds, currentUser
}) => {
  const { isLoading } = useContext(OrderContext);

  return (
    <div className="position-relative">
      <TradeSkipDates run={run} currentUser={currentUser} />
      <LiveMonitor
        run={run}
        jobIds={jobIds}
        onOrderReceived={() => { }}
        instrumentGroups={instrumentGroups}
        pipeConfigs={pipeConfigs}
      />
      {!isLoading && <div><TransactionDetail run={run} /></div>}
    </div>
  );
};

export default LiveDetails;

LiveDetails.propTypes = propTypes;
LiveDetails.defaultProps = defaultProps;
