import React, { useState } from 'react';
import PropTypes from 'prop-types';

import HoverMessage from 'common/components/HoverMessage';
import OrderTypeInput from './OrderTypeInput';

const propTypes = {
  orderType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};
const defaultProps = {};

const OrderType = ({
  orderType, onChange, title, type
}) => {
  const [shouldShowWarningMessage, setShouldShowWarningMessage] = useState(false);
  const renderOrderType = () => {
    const renderOrderTypeInput = () => (
      <OrderTypeInput
        title={title}
        type={type}
        orderType={orderType}
        onChange={(newOrderType) => {
          if (type === 'exit') {
            setShouldShowWarningMessage(true);
            setTimeout(() => {
              setShouldShowWarningMessage(false);
            }, 5000);
          }
          onChange(newOrderType);
        }}
      />
    );

    if (type === 'exit' && orderType === 'limit') {
      return (
        <HoverMessage
          title="Limit Order Warning"
          message="Limit order exit might not execute during sudden spikes of price."
          isOpen={shouldShowWarningMessage}
        >
          {renderOrderTypeInput()}
        </HoverMessage>
      );
    }

    return renderOrderTypeInput();
  };

  return (
    <div className=" form-inline">
      {renderOrderType()}
    </div>
  );
};

OrderType.propTypes = propTypes;
OrderType.defaultProps = defaultProps;

export default OrderType;
