import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useForm, FormProvider } from 'react-hook-form';
import FolderSelector from 'common/components/FolderSelector';
import {
  folderPropTypes, instrumentGroupsPropTypes, userPropTypes, validatorProptypes
} from 'common/propTypes';
import { RUN_TYPES } from 'common/constants/index';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import {
  DateSelector, QuantOrderType, RunNameInput, Granularity, RunType
} from './index';
import { END_DATE, I18N_SCOPE, START_DATE } from '../config';

const propTypes = {
  isHistoricRunDisabled: PropTypes.bool.isRequired,
  isLiveRunDisabled: PropTypes.bool.isRequired,
  runType: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  onHandleOrderType: PropTypes.func.isRequired,
  onHandleRunType: PropTypes.func.isRequired,
  onHandleGranularity: PropTypes.func.isRequired,
  planActiveTill: PropTypes.string,
  onHandleEndDate: PropTypes.func.isRequired,
  onHandleStartDate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onHandleName: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  selectedFolder: folderPropTypes,
  onHandleSelectedFolder: PropTypes.func.isRequired,
  validatorErrors: validatorProptypes,
  calendarStartDate: PropTypes.string.isRequired,
  calendarEndDate: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  currentUser: userPropTypes,
  instrumentGroups: instrumentGroupsPropTypes
};

const defaultProps = {
  planActiveTill: '',
  selectedFolder: null,
  validatorErrors: [],
  currentUser: {},
  instrumentGroups: []
};

const RunFormHeadPiece = ({
  isHistoricRunDisabled, isLiveRunDisabled, runType, orderType, onHandleOrderType, onHandleRunType,
  planActiveTill, onHandleEndDate, onHandleStartDate, name, onHandleName, isUserLoggedIn,
  selectedFolder, onHandleSelectedFolder, validatorErrors, startDate, endDate, calendarEndDate,
  calendarStartDate, currentUser, instrumentGroups, granularity, onHandleGranularity
}) => {
  const methods = useForm();
  const { historic, live } = RUN_TYPES;

  const isEligibleForLive = () => {
    const instruments = _.flattenDeep(instrumentGroups);
    const notEligibleInstrument = [];
    // NFO:FINNIFTY

    const difference = _.filter(instruments, (
      instrument
    ) => {
      return _.includes(notEligibleInstrument, instrument);
    });

    if (_.isEmpty(difference)) return true;

    return false;
  };

  const isDisableLiveBasedOnCondition = !isEligibleForLive();

  useEffect(() => {
    if (isDisableLiveBasedOnCondition) {
      if (runType === RUN_TYPES.live) {
        reactToastify('Finnifty is not eligible for live', TOASTIFY_TYPES.WARNING);
      }
      onHandleRunType(RUN_TYPES.historic);
    }
  }, [isDisableLiveBasedOnCondition]);

  const getDatePickerProps = (type) => {
    const toDateFormat = (newDate) => moment(newDate).toDate();
    const MaxStartDate = toDateFormat(moment(calendarEndDate));

    const onStartDateChange = (newStartDate) => {
      const isStartDateBeforeEndDate = moment(newStartDate).isSameOrBefore(endDate);
      if (!isStartDateBeforeEndDate) {
        onHandleEndDate(MaxStartDate);
        onHandleStartDate(MaxStartDate);
        return;
      }

      onHandleStartDate(newStartDate || MaxStartDate);
    };

    if (type === START_DATE) {
      return {
        type,
        selectedDate: toDateFormat(startDate),
        onChangeDate: (newStartDate) => { onStartDateChange(newStartDate); },
        minDate: toDateFormat(calendarStartDate),
        maxDate: MaxStartDate
      };
    }

    return {
      type,
      selectedDate: toDateFormat(endDate),
      onChangeDate: (newEndDate) => {
        if (moment(calendarEndDate).isBefore(newEndDate) || moment(newEndDate).isBefore(startDate)) {
          return onHandleEndDate(toDateFormat(moment(calendarEndDate)));
        }

        return onHandleEndDate(newEndDate || calendarEndDate);
      },
      minDate: toDateFormat(moment(startDate)),
      maxDate: toDateFormat(calendarEndDate),
    };
  };

  const renderDatePickers = () => {
    if (runType !== historic) return null;

    return (
      <div className="date-picker run-period-container d-flex justify-content-between">
        <div className="date-picker-input">
          <label className="col-form-label col-form-label-sm">
            {I18n.t(_.snakeCase(START_DATE), I18N_SCOPE)}
          </label>
          <DateSelector {...getDatePickerProps(START_DATE)} />
        </div>
        <div className="align-self-center mt-5 mx-1"> - </div>
        <div className="date-picker-input">
          <label className="col-form-label col-form-label-sm">
            {I18n.t(_.snakeCase(END_DATE), I18N_SCOPE)}
          </label>
          <DateSelector {...getDatePickerProps(END_DATE)} />
        </div>
      </div>
    );
  };

  const renderFolderSelector = () => {
    return (
      <div className="select-folder">
        <label className="col-form-label col-form-label-sm">{I18n.t('folder_name', I18N_SCOPE)}</label>
        <FormProvider {...methods}>
          <FolderSelector
            selectedFolder={selectedFolder}
            isUserLoggedIn={isUserLoggedIn}
            onSelect={(newFolder) => onHandleSelectedFolder(newFolder)}
          />
        </FormProvider>
      </div>
    );
  };

  return (
    <div className="card mb-2">
      <div className="card-body">
        <div className="runtype-action">
          <RunType
            isHistoricRunDisabled={isHistoricRunDisabled}
            isLiveRunDisabled={isLiveRunDisabled || isDisableLiveBasedOnCondition}
            runType={runType}
            onChangeRunType={(newRunType) => onHandleRunType(newRunType)}
            planActiveTill={planActiveTill}
          />
          {renderDatePickers()}

          {runType === live && (
            <QuantOrderType
              orderType={orderType}
              onOrderTypeChange={(newOrderType) => onHandleOrderType(newOrderType)}
              currentUser={currentUser}
              instrumentGroups={instrumentGroups}
            />
          )}

          <div className="run-name-container">
            <RunNameInput
              name={name}
              validatorErrors={validatorErrors}
              onChangeRunName={(newRunName) => onHandleName(newRunName)}
            />
          </div>

          <div className="run-folder-container">{renderFolderSelector()}</div>

          <Granularity
            runType={runType}
            granularity={granularity}
            onChangeGranularity={onHandleGranularity}
          />
        </div>
      </div>
    </div>
  );
};

RunFormHeadPiece.propTypes = propTypes;
RunFormHeadPiece.defaultProps = defaultProps;

export default RunFormHeadPiece;
