import { SEGMENT_CATEGORY_IN_QUANT } from 'common/constants/index';

export const isIncludeEquityOrFuture = (operand) => {
  if (_.includes(operand, SEGMENT_CATEGORY_IN_QUANT.month1Price)
    || _.includes(operand, SEGMENT_CATEGORY_IN_QUANT.equity)) {
    return true;
  }

  return false;
};

export const getRemoveConfigs = (signalConfigs) => {
  return _.chain(signalConfigs)
    .map(({ leftOperand, rightOperand }) => {
      let returnArray = [];
      if (isIncludeEquityOrFuture(leftOperand)) returnArray = [...returnArray, leftOperand];
      if (isIncludeEquityOrFuture(rightOperand)) returnArray = [...returnArray, rightOperand];

      return returnArray;
    })
    .flattenDeep()
    .compact()
    .uniq()
    .value();
};
