import {
  ADJUST_PERCENTAGE, TRAILING_PERCENTAGE,
  waitAndTradeTypesAndNode
} from 'modules/TransactionsBuilder/configs';

export const trailingStopLossBasedReset = (orderConfig) => {
  const { transactionConfigs } = orderConfig;
  const newTransactionConfigs = _.map(transactionConfigs, (transactionConfig) => {
    const { optionConfigs, equityConfig, futureConfig } = transactionConfig;

    const getTrailStopLossResetConfig = (config) => {
      return {
        ...config,
        trailingStopLoss: { [TRAILING_PERCENTAGE]: undefined, [ADJUST_PERCENTAGE]: undefined }
      };
    };

    return {
      ...transactionConfig,
      equityConfig: getTrailStopLossResetConfig(equityConfig),
      futureConfig: getTrailStopLossResetConfig(futureConfig),
      optionConfigs: _.map(optionConfigs, (optionConfig) => {
        return getTrailStopLossResetConfig(optionConfig);
      })
    };
  });

  return { ...orderConfig, transactionConfigs: newTransactionConfigs };
};

export const reEntryAndReExecuteBasedReset = (orderConfig) => {
  const { transactionConfigs } = orderConfig;
  const newTransactionConfigs = _.map(transactionConfigs, (transactionConfig) => {
    const { optionConfigs, equityConfig, futureConfig } = transactionConfig;

    const getReEntryAndReExecuteResetConfig = (config) => {
      return {
        ...config,
        reExecuteCount: 0,
        reEntryCount: 0
      };
    };

    return {
      ...transactionConfig,
      equityConfig: getReEntryAndReExecuteResetConfig(equityConfig),
      futureConfig: getReEntryAndReExecuteResetConfig(futureConfig),
      optionConfigs: _.map(optionConfigs, (optionConfig) => {
        return getReEntryAndReExecuteResetConfig(optionConfig);
      })
    };
  });

  return { ...orderConfig, transactionConfigs: newTransactionConfigs };
};

export const waitAndTradeReset = (orderConfig) => {
  const { transactionConfigs } = orderConfig;
  const adjustmentActionConfigs = _.get(orderConfig, 'adjustmentActionConfigs', []);

  const getSanitizedConfigs = (configs) => {
    return _.map(configs, (transactionConfig) => {
      const { optionConfigs, equityConfig, futureConfig } = transactionConfig;

      const getWaitAndTradeResetConfig = (config) => {
        return {
          ...config,
          [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE]: 0,
          [waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE]: 0,
          waitAndTradeType: waitAndTradeTypesAndNode.WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE,
        };
      };

      return {
        ...transactionConfig,
        equityConfig: getWaitAndTradeResetConfig(equityConfig),
        futureConfig: getWaitAndTradeResetConfig(futureConfig),
        optionConfigs: _.map(optionConfigs, (optionConfig) => {
          return getWaitAndTradeResetConfig(optionConfig);
        })
      };
    });
  };

  return {
    ...orderConfig,
    transactionConfigs: getSanitizedConfigs(transactionConfigs),
    adjustmentActionConfigs: _.map(adjustmentActionConfigs, (adjustmentActionConfig) => {
      return getSanitizedConfigs(adjustmentActionConfig);
    })
  };
};
