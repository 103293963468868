import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
};

const SelectFormField = ({
  name: propsName, options, value, control, errors, onUpdatePipeConfig
}) => {
  const uniqueId = useMemo(() => _.uniqueId(`pipe_${propsName}_`), [propsName]);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  const rulesValidator = useMemo(() => {
    return {
      validate: () => {
        if (_.isEmpty(value) && !_.isBoolean(value)) {
          return 'Please select the valid value';
        }

        return null;
      }
    };
  }, [value]);

  const renderOption = (opt) => {
    if (_.isObject(opt)) {
      return <option key={opt.value} value={opt.value}>{opt.label}</option>;
    }

    return <option key={opt} value={opt}>{_.startCase(opt)}</option>;
  };
  return (
    <ErrorMesssage message={errorMessage}>
      <Controller
        render={({
          onChange, ref, name
        }) => (
          <Form.Control
            as="select"
            id={name}
            size="sm"
            name={uniqueId}
            ref={ref}
            value={value}
            onChange={(event) => {
              const targetValue = event.target.value;
              if (['true', 'false'].includes(targetValue)) {
                onUpdatePipeConfig(propsName, (targetValue === 'true'));
              } else {
                onUpdatePipeConfig(propsName, (targetValue || 0));
              }
              onChange(targetValue);
            }}
          >
            {_.map(options, renderOption)}
          </Form.Control>
        )}
        control={control}
        defaultValue={value}
        name={uniqueId}
        rules={rulesValidator}
      />
    </ErrorMesssage>
  );
};

SelectFormField.propTypes = propTypes;

export default SelectFormField;
