import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { availableBrokers } from 'common/brokerConfigs/config';
import { onUpdateStrategyAccess } from 'common/api/strategyAccess';
import { STRATEGY_ACCESS_STATUS } from 'common/constants/index';
import moment from 'moment';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import ReactDataTable from 'common/components/ReactDataTable';

const propTypes = { strategyAccessDetails: PropTypes.arrayOf(PropTypes.shape({})) };

const defaultProps = { strategyAccessDetails: null };

const StrategyAccessDetails = ({ strategyAccessDetails: accessDetails }) => {
  if (_.isEmpty(accessDetails)) return null;

  const [strategyAccessDetails, setStrategyAccessDetails] = useState(accessDetails);

  const renderTitle = (userDetails) => {
    const { name, email, broker_client_email: brokerEmail } = userDetails;

    return <h6>{_.capitalize(name || email || brokerEmail)}</h6>;
  };

  const onUpdateStatus = (id, status) => {
    onUpdateStrategyAccess({ id, strategyParams: { status } }).then(() => {
      const isPaid = status === STRATEGY_ACCESS_STATUS.PAID;
      const type = isPaid ? TOASTIFY_TYPES.SUCCESS : TOASTIFY_TYPES.ERROR;
      setStrategyAccessDetails(_.map(strategyAccessDetails, (strategyAccess) => {
        if (id === strategyAccess.id) { return { ...strategyAccess, status }; }
        return strategyAccess;
      }));
      return reactToastify(`${isPaid ? 'Approved' : 'Rejected'}`, type);
    }).catch(() => reactToastify('Something went wrong', TOASTIFY_TYPES.ERROR));
  };

  const getFormattedData = (strateyAccessDetail) => {
    const {
      user_details: userDetails, id, created_at: createdAt, status
    } = strateyAccessDetail;
    const { phone_number: phoneNumber, broker, broker_client_id: brokerClientId } = userDetails;
    const isAfter4PM = moment().isAfter(moment().hour(16).startOf('hour'));
    const isBefore8PM = moment().isBefore(moment().hour(8).startOf('hour'));
    const isAllowedRejectTime = isBefore8PM || isAfter4PM;

    const isShowApproveBtn = _.includes(
      [STRATEGY_ACCESS_STATUS.REQUESTED, STRATEGY_ACCESS_STATUS.REJECTED], status
    );
    const isShowRejectBtn = status !== STRATEGY_ACCESS_STATUS.REJECTED
      && (
        (status === STRATEGY_ACCESS_STATUS.PAID && isAllowedRejectTime)
        || status !== STRATEGY_ACCESS_STATUS.PAID
      );

    const showInfoMsgAboutRejectBtn = status === STRATEGY_ACCESS_STATUS.PAID && !isAllowedRejectTime;

    return {
      userName: renderTitle(userDetails),
      phoneNumber: !_.isEmpty(phoneNumber) ? `${phoneNumber.slice(0, 5)} *****` : '-',
      broker: !_.isEmpty(broker) ? `${availableBrokers[broker]} - ${brokerClientId}` : '-',
      date: moment(createdAt).format('YYYY-MM-DD'),
      status: _.capitalize(status === 'paid' ? 'approved' : status),
      action: (
        <div className="d-flex">
          {isShowApproveBtn && (
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => onUpdateStatus(id, STRATEGY_ACCESS_STATUS.PAID)}
            >
              Approve
            </button>
          )}
          {(isShowRejectBtn) && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => onUpdateStatus(id, STRATEGY_ACCESS_STATUS.REJECTED)}
            >
              Reject
            </button>
          )}
          {showInfoMsgAboutRejectBtn && (
            <div>
              <small className="text-danger tx-10">
                *You cannot able to reject this request between 8 AM to 4 PM
              </small>
            </div>
          )}
        </div>
      )
    };
  };

  const headers = [
    { Header: 'User name', accessor: 'userName' },
    { Header: 'Mobile', accessor: 'phoneNumber' },
    { Header: 'Broker', accessor: 'broker' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Action', accessor: 'action' }
  ];

  return (
    <>
      {!_.isEmpty(strategyAccessDetails) && (
        <div className="mt-5 bg-white p-3 border ">
          <h5 className="mb-2">Strategy Access Details</h5>
          <ReactDataTable
            columns={headers}
            data={_.map(strategyAccessDetails, getFormattedData)}
          />
        </div>
      )}
    </>
  );
};

StrategyAccessDetails.propTypes = propTypes;
StrategyAccessDetails.defaultProps = defaultProps;

export default StrategyAccessDetails;
