export const historicDefaultConfig = {
  data_type: 'historic',
  includeEquity: 'true',
  includeIndiaVix: 'false',
  includeNextExpiry: 'false',
  includeOptions: 'false',
  instruments: 'NFO:HDFC',
  interval: 'minute',
  isWeeklyOptions: 'false',
  month: '1',
  year: '2020'
};
