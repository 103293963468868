import React, { useState } from 'react';

import { BROKERS } from 'common/constants';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};
const defaultProps = {};

const SamcoLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <div className="broker mb-1">
            <img
              src={BROKERS.samco.image}
              alt={BROKERS.samco.name}
              title={BROKERS.samco.name}
              className="bigul-login-logo"
            />
          </div>
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with Samco.
          </p>

          <div
            onClick={() => { setCurrentBroker(BROKERS.samco.id); }}
            className="btn btn-outline-primary broker"
          >
            <div className="d-flex align-items-center gap-10">
              <img
                src="/landing-page-img/brokers-img/bigul-icon.png"
                title="Login with bigul"
                alt={BROKERS.samco.name}
                className="broker-logo bigul-icon"
              />
              <span>LOGIN WITH SAMCO</span>
            </div>
          </div>
        </div>
        <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
      </div>
    </div>
  );
};

SamcoLogin.propTypes = propTypes;
SamcoLogin.defaultProps = defaultProps;

export default SamcoLogin;
