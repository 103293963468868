export const strategyTransactionHeaders = () => {
  return (
    [
      { Header: 'Id', accessor: 'transaction_id' },
      { Header: 'Symbol', accessor: 'instrument' },
      {
        Header: 'Transaction Time',
        columns: [{
          Header: 'First',
          accessor: 'transaction_start_time',
        },
        {
          Header: 'Last',
          accessor: 'transaction_end_time',
        }]
      },
      {
        Header: 'Runs count',
        columns: [{
          Header: 'Paper',
          accessor: 'paper_runs_count',
        },
        {
          Header: 'Automatic',
          accessor: 'automatic_runs_count',
        }]
      },
      { Header: 'Users Count', accessor: 'users_count' },
      {
        Header: 'Automatic Transactions',
        columns: [{
          Header: 'Incomplete',
          accessor: 'incomplete_automatic_transaction_count',
        },
        {
          Header: 'Success',
          accessor: 'completed_automatic_transaction_count',
        },
        {
          Header: 'Failure',
          accessor: 'failed_automatic_transaction_count',
        }]
      },
      {
        Header: 'Actual Profit',
        columns: [{
          Header: 'Min',
          accessor: 'min_actual_profit',
        },
        {
          Header: 'Max',
          accessor: 'max_actual_profit',
        },
        {
          Header: 'Avg',
          accessor: 'avg_actual_profit',
        }]
      },
      {
        Header: 'Quantman Broker Delay',
        columns: [{
          Header: 'Min',
          accessor: 'min_quantman_broker_delay',
        },
        {
          Header: 'Max',
          accessor: 'max_quantman_broker_delay',
        },
        {
          Header: 'Avg',
          accessor: 'avg_quantman_broker_delay',
        }]
      },
      {
        Header: 'Broker Market Delay',
        columns: [{
          Header: 'Min',
          accessor: 'min_broker_market_delay',
        },
        {
          Header: 'Max',
          accessor: 'max_broker_market_delay',
        },
        {
          Header: 'Avg',
          accessor: 'avg_broker_market_delay',
        }]
      },
      { Header: '', accessor: 'show_more' },
    ]
  );
};

export const particularTransactionDetailsForAllUsersHeaders = () => {
  return (
    [
      { Header: 'userId', accessor: 'user_id' },
      { Header: 'Broker', accessor: 'broker' },
      { Header: 'Type', accessor: 'run_order_type' },
      {
        Header: 'Transaction Time',
        columns: [{
          Header: 'First',
          accessor: 'transaction_start_time',
        },
        {
          Header: 'Last',
          accessor: 'transaction_end_time',
        }]
      },
      { Header: 'Entry', accessor: 'entry_status' },
      { Header: 'StopLoss', accessor: 'sl_status' },
      { Header: 'Exit', accessor: 'exit_status' },
      { Header: 'Signal Profit', accessor: 'signal_profit' },
      { Header: 'Actual profit', accessor: 'traded_profit' },
      { Header: 'Slippage', accessor: 'slippage' },
      {
        Header: 'Quantman Broker Delay',
        columns: [{
          Header: 'Min',
          accessor: 'min_quantman_broker_delay',
        },
        {
          Header: 'Max',
          accessor: 'max_quantman_broker_delay',
        },
        {
          Header: 'Avg',
          accessor: 'avg_quantman_broker_delay',
        }]
      },
      {
        Header: 'Broker Market Delay',
        columns: [{
          Header: 'Min',
          accessor: 'min_broker_market_delay',
        },
        {
          Header: 'Max',
          accessor: 'max_broker_market_delay',
        },
        {
          Header: 'Avg',
          accessor: 'avg_broker_market_delay',
        }]
      },
      { Header: '', accessor: 'show_more' }
    ]
  );
};

export const individualUserTransactionLegsHeaders = () => {
  return (
    [
      // { Header: 'Broker', accessor: 'broker' },
      // { Header: 'Type', accessor: 'run_order_type' },
      { Header: 'TradingSymbol', accessor: 'trading_symbol' },

      {
        Header: 'Entry',
        columns: [{
          Header: 'Qty',
          accessor: 'entry_quantity',
        },
        {
          Header: 'Signal Price',
          accessor: 'entry_signal_price',
        },
        {
          Header: 'Traded Price',
          accessor: 'entry_traded_price',
        },
        {
          Header: 'Signal Time',
          accessor: 'entry_signal_time',
        },
        {
          Header: 'Placed Time',
          accessor: 'entry_placed_time',
        },
        {
          Header: 'Traded Time',
          accessor: 'entry_traded_time',
        }],
      },

      {
        Header: 'Stoploss',
        columns: [{
          Header: 'Qty',
          accessor: 'sl_quantity',
        },
        {
          Header: 'Signal Price',
          accessor: 'sl_signal_price',
        },
        {
          Header: 'Traded Price',
          accessor: 'sl_traded_price',
        },
        {
          Header: 'Signal Time',
          accessor: 'sl_signal_time',
        },
        {
          Header: 'Placed Time',
          accessor: 'sl_placed_time',
        },
        {
          Header: 'Traded Time',
          accessor: 'sl_traded_time',
        }],
      },

      {
        Header: 'Exit',
        columns: [{
          Header: 'Qty',
          accessor: 'exit_quantity',
        },
        {
          Header: 'Signal Price',
          accessor: 'exit_signal_price',
        },
        {
          Header: 'Traded Price',
          accessor: 'exit_traded_price',
        },
        {
          Header: 'Signal Time',
          accessor: 'exit_signal_time',
        },
        {
          Header: 'Placed Time',
          accessor: 'exit_placed_time',
        },
        {
          Header: 'Traded Time',
          accessor: 'exit_traded_time',
        }],
      },
      {
        Header: 'Profit',
        columns: [{
          Header: 'Signal',
          accessor: 'signal_profit',
        },
        {
          Header: 'Traded',
          accessor: 'traded_profit',
        }]
      }
    ]
  );
};
