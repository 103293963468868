import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import { sendOtpToBrokerUser, validateBrokerUser } from 'common/api/user';
import ReactHtmlParser from 'react-html-parser';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import UserInput from '../CommonComponents/UserInput';
import { getBrokerImage, reDirectPage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const defaultProps = {};

const FairInvest = ({ broker, onCloseModal }) => {
  const [configs, setConfigs] = useState({
    clientId: '',
    password: '',
    otp: '',
    apiKey: '',
  });
  const {
    clientId,
    password,
    otp,
    apiKey
  } = configs;
  const [isValidUser, setIsValidUser] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [showApiKeyField, setShowApiKeyField] = useState(false);
  const [checkedApiKey, setCheckedApiKey] = useState(false);

  const onHandleConfig = (key, value) => {
    const updatedValue = key === 'clientId' ? value.toUpperCase() : value;
    setConfigs({ ...configs, [key]: updatedValue });
  };

  const onLogin = () => {
    if (!clientId || !password || !otp) return setIsValid(false);
    reDirectPage({
      client_id: clientId, password, otp, api_key: apiKey
    }, broker, false);

    return '';
  };

  const checkExistingUser = () => {
    if (!clientId) return setIsValid(false);

    validateBrokerUser({ broker_user_id: clientId, broker }).then((result) => {
      const { status, broker_api_credentials: brokerApiCredientals } = result;
      if (!status) {
        setShowApiKeyField(true);
      } else {
        onHandleConfig('apiKey', brokerApiCredientals.api_key);
      }

      setCheckedApiKey(true);
    });

    return setIsValid(true);
  };

  const authenticateUser = (isResendOtp = false) => {
    if (clientId && !checkedApiKey) return checkExistingUser();

    if (_.isEmpty(clientId) || _.isEmpty(password)) {
      setIsValid(false);
      return null;
    }

    setIsValid(true);
    setIsValidUser(false);
    sendOtpToBrokerUser(
      { client_id: clientId, password, api_key: apiKey },
      isResendOtp,
      broker
    ).then((result) => {
      if (result && result.status) {
        setIsValidUser(true);
      } else {
        reactToastify((result?.message || 'Wrong ClientId or Password'), TOASTIFY_TYPES.ERROR);
      }
    });
    return null;
  };

  const modalProps = {
    onClose: () => onCloseModal(),
    shouldShow: true,
    size: 'md',
    onSaveid: `btn-${broker}`,
    onSave: () => (isValidUser ? onLogin() : authenticateUser()),
    okBtnTitle: isValidUser ? 'Validate OTP' : 'Login',
    isOkBtnDisabled: false,
  };

  return (
    <ModalComponent {...modalProps}>
      <>
        <div className="text-center mb-3">
          {ReactHtmlParser(getBrokerImage(broker))}
        </div>
        <div className="form-group mt-2">
          <label htmlFor={`${broker}-client-id`} className="mt-2">Client Id</label>
          <UserInput
            id={`${broker}-client-id`}
            value={clientId}
            className="form-control"
            disabled={isValidUser}
            onHandleChange={(value) => { onHandleConfig('clientId', value); }}
          />
        </div>

        {(checkedApiKey) && (
          <div className="form-group mt-2">
            <label htmlFor={`${broker}-password`}>Password</label>
            <UserInput
              id={`${broker}-password`}
              type="password"
              value={password}
              className="form-control"
              disabled={isValidUser}
              onHandleChange={(value) => { onHandleConfig('password', value); }}
            />
          </div>
        )}

        {(checkedApiKey && showApiKeyField) && (
          <>
            <div className="form-group mt-2">
              <label htmlFor={`${broker}-api-key`}>API Key</label>
              <UserInput
                id={`${broker}-api-key`}
                type="text"
                value={apiKey}
                className="form-control"
                onHandleChange={(value) => { onHandleConfig('apiKey', value); }}
              />
            </div>
            <div className="my-2 alert alert-warning py-1 px-2">
              Please contact FairInvest support to get your API Key
            </div>
          </>
        )}
        {isValidUser && (
          <>
            <div className="form-group mt-2">
              <label htmlFor={`${broker}-otp`}>OTP</label>
              <UserInput
                id={`${broker}-otp`}
                value={otp}
                className="form-control"
                onHandleChange={(value) => { onHandleConfig('otp', value); }}
              />
            </div>
            <div className="text-right mb-2">
              <button className="btn btn-link btn-sm" type="button" onClick={() => authenticateUser(true)}>
                Re-Send OTP
              </button>
            </div>
          </>
        )}

        {!isValid && <div className="invalid-feedback d-block">Please provide the details</div>}
      </>
    </ModalComponent>
  );
};

FairInvest.propTypes = propTypes;
FairInvest.defaultProps = defaultProps;

export default FairInvest;
