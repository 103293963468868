import { onlyForBetaUsers } from 'common/conditionalHOC';
import React from 'react';

const IoBtns = () => {
  return (
    <div className="io-btn-legend d-flex align-items-center justify-content-evenly">
      <div className="text-center d-flex align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#295F98"
        >
          <path d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160
            0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280
            160 160 280-280v113L560-447 400-607 120-327Z"
          />
        </svg>
        <p className="text-premium-chart px-1">Premium Chart</p>
      </div>
      {/* <div className="btn-group mb-1">
          <div className="btn  btn-sm">
            <i className="material-icons-outlined tx-16 d-block">table_chart</i>
          </div>
          <div className="btn btn-outline-info btn-sm">
            OptionChain
          </div>
        </div> */}
      <div className="text-center d-flex align-items-center px-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="19px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0091ae"
        >
          <path d="m296-80-56-56 276-277 140 140 207-207 57 57-264 263-140-140L296-80Zm-136-40q-33
            0-56.5-23.5T80-200v-560q0-33 23.5-56.5T160-840h560q33 0 56.5
            23.5T800-760v168H160v472Zm0-552h560v-88H160v88Zm0 0v-88 88Z"
          />
        </svg>
        <p className="text-option-chain px-1">OptionChain</p>
      </div>
    </div>
  );
};

IoBtns.propTypes = {};

export default onlyForBetaUsers(IoBtns);
