import React from 'react';
import PropTypes from 'prop-types';
import ErrorMesssage from 'common/components/ErrorMesssage';

const propTypes = {
  currentBrokerName: PropTypes.string.isRequired,
  broker: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  brokerUserId: PropTypes.string.isRequired,
  isValidUser: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const BrokerSimpleLogin = ({
  currentBrokerName, broker, handleChange, handleClick, brokerUserId,
  isValidUser, errorMessage
}) => {
  return (
    <div className="text-center zerodha-content">
      {/* <p>
        Login just using your
        {oneIndentation}
        {currentBrokerName}
        {oneIndentation}
        credentials.
        <br />
        <span className="font-weight-semi">Backtest / Only Paper Trade live deployments allowed.</span>
        <br />
        <span className="text-danger">Automatic or One-Click-Confirmed live deployments not supported.</span>
      </p>
      <a className="d-inline-flex align-items-center gap-10 btn btn-light" href={`/auth/${broker}`}>
        Simple
        {oneIndentation}
        {currentBrokerName}
        {oneIndentation}
        Login
      </a>

      <div className="divider my-4">
        <p className="text-center font-weight-bold mx-3 mb-0">Or</p>
      </div> */}

      <div className="mt-3">
        <p>
          Login via
          {oneIndentation}
          {currentBrokerName}
          {oneIndentation}
          using your
          {oneIndentation}
          {currentBrokerName}
          {oneIndentation}
          API key/secret.
          <br />
          (API key/secret required only for the first time).
          <br />
          {/* <span className="font-weight-semi">
            Backtest / Live deployments with paper-trade /
            {oneIndentation}
            <br />
            automatic-order /
            one-click-confirmed-order
            {oneIndentation}
            <br />
            all supported
          </span> */}
        </p>
        <div className="col-md-10 m-auto">
          <label className="mt-2 text-left">
            {currentBrokerName}
            {oneIndentation}
            User Id
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Client Id"
              value={brokerUserId}
              id={`${broker}-client-id`}
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              onChange={(event) => {
                const { target: { value } } = event;
                handleChange(value);
              }}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleClick}
                id={`${broker}-login`}
              >
                Login
              </button>
            </div>
          </div>
        </div>
        <ErrorMesssage error={{ message: isValidUser ? '' : errorMessage }} />
        {/* <div className="my-2 alert alert-warning py-1 px-2">
          <b>*Note:</b>
          {oneIndentation}
          Once you logged in with api login, Simple login will not work any more
        </div> */}
      </div>
    </div>
  );
};

BrokerSimpleLogin.propTypes = propTypes;
BrokerSimpleLogin.defaultProps = defaultProps;

export default BrokerSimpleLogin;
