import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import {
  getUnreadMessages, onUserMessageClose, setUserMessagesLocalStorage
} from 'common/utils/userMessages';

const propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
    showBy: PropTypes.string,
    referredBy: PropTypes.string
  })).isRequired,
};
const defaultProps = {};

const UserMessageBanner = ({ messages }) => {
  const [unReadMessges, setUnreadMessages] = useState([]);

  useEffect(() => {
    setUserMessagesLocalStorage(messages);
    setUnreadMessages(getUnreadMessages(messages));
  }, []);

  const renderBannerMessage = (value, key) => {
    const { currentUserBroker } = window;
    const {
      message, filterByDomain, showTo, id, isShowBeforeLoggedIn
    } = value;

    const { currentDomain, isUserSignedIn } = window;

    const isShowBeforeSignedInBasedOnFlag = !isUserSignedIn && isShowBeforeLoggedIn;
    const isInValidCase = ((filterByDomain && filterByDomain !== currentDomain)
      || (showTo && showTo !== currentUserBroker));

    const renderMessage = (
      <div key={key} className="banner-message">
        {ReactHtmlParser(message)}
        <button
          type="button"
          className="btn msg-close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() => { setUnreadMessages(onUserMessageClose(id, unReadMessges)); }}
        >
          <i className="material-icons-outlined">close</i>
        </button>
      </div>
    );

    if (isShowBeforeSignedInBasedOnFlag) return renderMessage;
    if (isInValidCase) return null;

    return renderMessage;
  };

  return _.map(unReadMessges, renderBannerMessage);
};

UserMessageBanner.propTypes = propTypes;
UserMessageBanner.defaultProps = defaultProps;

export default UserMessageBanner;
