import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onAddOptionClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};
const defaultProps = {};

const AddOptionBtn = ({ onAddOptionClick, isDisabled }) => (
  <button
    type="button"
    disabled={isDisabled}
    className="btn btn-sm btn-primary track"
    onClick={onAddOptionClick}
    data-track-category="Add Legs"
    data-track-action="Add Legs"
    data-track-label="Add Legs"
  >
    Add Legs
  </button>
);

AddOptionBtn.propTypes = propTypes;
AddOptionBtn.defaultProps = defaultProps;

export default AddOptionBtn;
