import { buildOutputSchemaFromJsonSchema } from './pipeDefinitions/utilities/buildOutputSchemaFromJsonSchema';

export const indicatorCodeSnippets = () => {
  const snippets = _.get(window, 'SA_COLLECTIONS.indicatorCodeSnippets', {});

  const addPrefixToLabel = (label) => `snippet - ${label}`;

  const buildDefaultConfig = (snippet) => {
    const configs = {};

    _.each(_.get(snippet, 'parameters', []), ({ name, defaultValue }) => {
      if (name !== 'chartConfig') {
        configs[name] = defaultValue;
      }
    });

    return configs;
  };

  const getChartConfig = (parameters) => {
    const chartConfig = (parameters || []).find(({ name }) => name === 'chartConfig');
    return _.isEmpty(chartConfig) ? undefined : {
      type: chartConfig.defaultValue,
      boxUnitSize: 0,
      boxPercentage: 0
    };
  };

  return _.map(snippets, (snippet) => {
    const modifiedParameters = _.compact(_.map(snippet.parameters, (parameter) => {
      return parameter.name === 'chartConfig' ? null : parameter;
    }));

    // for snippet, we have naming parameters

    return {
      searchTerms: [addPrefixToLabel(snippet.label), addPrefixToLabel(snippet.label_alias)],
      type: addPrefixToLabel(snippet.label),
      inputSchema: modifiedParameters,
      defaults: {
        type: addPrefixToLabel(snippet.label),
        category: 'snippet',
        name: addPrefixToLabel(snippet.label) || addPrefixToLabel(snippet.label_alias),
        config: {
          ...buildDefaultConfig(snippet),
          snippetId: snippet.id,
          script: snippet.script, // TODO: remove this after engine starts supporting snippetId,
          outputJsonSchema: snippet.output_json_schema
        },
        chartConfig: getChartConfig(snippet.parameters),
      },
      buildOutputSchema: (pipe) => {
        const { name, config: { outputJsonSchema } } = pipe;
        const schema = outputJsonSchema;
        return buildOutputSchemaFromJsonSchema(schema, name);
      },
      isEnabled: true,
    };
  });
};
