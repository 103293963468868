import React from 'react';
import PropTypes from 'prop-types';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';
import { quantConfigPropTypes } from 'common/propTypes';

const propTypes = {
  strategy: PropTypes.shape({
    quant_config: quantConfigPropTypes
  }).isRequired,
  tagClassName: PropTypes.string
};

const defaultProps = {
  tagClassName: 'ins-tag'
};

const InstrumentAndTags = ({ strategy, tagClassName }) => {
  const oneIndentation = ' ';
  const instrumentGroups = _.get(strategy, 'live_instrument_groups', []);
  const tags = _.get(strategy, 'tags', '');

  const renderTags = () => {
    if (_.isEmpty(tags)) return null;

    const tagMap = _.map(tags, (tag, idx) => {
      return <div className="badge badge-light" key={idx}>{tag}</div>;
    });

    return (
      <div className={tagClassName}>
        {tagMap}
      </div>
    );
  };

  return (
    <div className="instrument-and-tags">
      {/* Instruments  */}
      <span className="value-name">Instruments :</span>
      {oneIndentation}
      <span className="font-weight-semi">
        <InstrumentGroupsSentence instrumentGroups={instrumentGroups} />
      </span>
      {/* Tags */}
      {renderTags()}
    </div>
  );
};

InstrumentAndTags.propTypes = propTypes;
InstrumentAndTags.defaultProps = defaultProps;

export default InstrumentAndTags;
