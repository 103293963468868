import { outputSchemaPropTypes, signalsPropTypes } from 'common/propTypes';
import React, { memo, useCallback, useEffect } from 'react';
import ModalComponent from 'v2/common/components/ModalComponent';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import Main from './Main';

const propTypes = {
  signals: signalsPropTypes.isRequired,
  signalType: PropTypes.string.isRequired,
  onSaveForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDeleteEntirely: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
};

const defaultProps = {};

const SignalsForm = ({
  signals, signalType, onSaveForm, onCancel, onDeleteEntirely,
  outputSchema
}) => {
  const { handleSubmit, control, errors } = useForm();

  const signalsRef = React.useRef(signals);

  useEffect(() => {
    signalsRef.current = signals;
  }, [signals]);

  const onSave = () => onSaveForm(signalsRef.current);

  const onUpdateSignals = useCallback((newSignals) => {
    signalsRef.current = newSignals;
  }, []);

  return (
    <ModalComponent
      onSave={handleSubmit(onSave)}
      onClose={onCancel}
      title="Add Signal"
      onCancel={onDeleteEntirely}
      cancelBtnTitle="Delete"
      size="lg"
      btnClassName="track"
      shouldConfirmClose
      className="add-signal-modal"
    >
      <Form>
        <Main
          signals={signals}
          signalType={signalType}
          outputSchema={outputSchema}
          control={control}
          errors={errors}
          onUpdateSignals={onUpdateSignals}
        />
      </Form>
    </ModalComponent>
  );
};

SignalsForm.propTypes = propTypes;
SignalsForm.defaultProps = defaultProps;

export default memo(SignalsForm);
