// export const EXCHANGES = ['NSE', 'MCX', 'NFO', 'CDS'];  // hidden for furure
export const EXCHANGES = ['NFO'];

export const getExchangeAndSymbol = (instrument) => {
  let exchange;
  let symbol;

  if (!_.isUndefined(instrument)) {
    [exchange, symbol] = _.split(instrument || '', ':');
  }

  return {
    exchange: exchange || EXCHANGES[0],
    symbol: symbol || '',
  };
};

export const getInstrument = (exchange, symbol) => {
  return `${exchange}:${symbol}`;
};

export const getInstrumentLabel = ({ symbol, type }) => {
  const label = symbol;
  return `${label} (${type})`;
};
