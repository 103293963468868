import { RUN_GRANULARITY } from 'v2/common/constants/index';
import { getSanitizedQuantConfig } from './quantConfig/index';

export const beforeSubmitSanitizer = (runConfig) => {
  const {
    quant_config: quantConfig,
    run_type: runType,
    order_type: orderType,
    granularity,
  } = runConfig;

  const isSecondBySecondEnabledForUser = _.get(
    window,
    'SA_FEATURE_FLAGS.isRunGranularitySecondEnabled',
    false
  ) || window?.isAdmin;

  return {
    ...runConfig,
    granularity: isSecondBySecondEnabledForUser
      ? granularity
      : RUN_GRANULARITY.minute,
    quant_config: JSON.stringify(
      getSanitizedQuantConfig(quantConfig, runType, orderType)
    ),
  };
};
