import React from 'react';
import PropTypes from 'prop-types';
import HoverMessage from 'common/components/HoverMessage';
import SlOrderBasedOnPriceSelector from './SlOrderBasedOnPriceSelector';

const propTypes = {
  slOrderPlaceBasedOnPrice: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};
const defaultProps = { };

const SlOrderBasedOnPriceWrapper = ({
  slOrderPlaceBasedOnPrice, handleChange,
}) => {
  const oneIndentation = ' ';
  const label = 'Stop Loss orders place based on entry\'s';
  const content = 'Stop Loss order will be placed based on entry\'s signal price or traded price';

  return (
    <div className="row mb-1">
      <label className="col-md-3 place-orders-label">
        {label}
        {oneIndentation}
        <HoverMessage
          title=""
          message={content}
        >
          <span className="material-icons-outlined text-muted tx-16 align-middle">
            help_outline
          </span>
        </HoverMessage>
      </label>
      <div className="col-md-9 place-options">
        <SlOrderBasedOnPriceSelector
          optionType={slOrderPlaceBasedOnPrice}
          onOptionTypeChange={(value) => { handleChange(value); }}
        />
      </div>
    </div>
  );
};

SlOrderBasedOnPriceWrapper.propTypes = propTypes;
SlOrderBasedOnPriceWrapper.defaultProps = defaultProps;

export default SlOrderBasedOnPriceWrapper;
