import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import classNames from 'classnames';
import { rightOperandPropTypes } from 'common/propTypes';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  rightOperand: rightOperandPropTypes.isRequired,
  onChangeRightOperand: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};
const defaultProps = {
  selected: [],
  options: [],
};

const DayOfWeek = ({
  rightOperand, onChangeRightOperand, selected, options, control, errors
}) => {
  const validator = {
    validate: (result) => {
      if (_.isEmpty(result)) return 'Please select a day';
      return null;
    },
  };
  const [daySelectorName] = useState(_.uniqueId('daySelector-'));
  const daySelectorInputStyle = classNames(errors[daySelectorName] ? 'is-invalid' : '');

  const onDayChange = (newRightOperand, onChange) => {
    const selectedDays = _.map(newRightOperand, 'id');

    onChangeRightOperand(selectedDays);
    onChange(selectedDays);
  };

  return (
    <>
      <div className="day-of-week-input-container">
        <ErrorMesssage message={errors[daySelectorName]?.message || ''}>
          <Controller
            render={({ onChange, name }) => (
              <Typeahead
                className="custom-form-control"
                inputProps={{ className: daySelectorInputStyle }}
                id={name}
                multiple
                placeholder="Can select muliple day"
                // isInvalid={!!errors[name]}
                options={options}
                selected={selected}
                onChange={(newRightOperand) => onDayChange(newRightOperand, onChange)}
              />
            )}
            control={control}
            defaultValue={rightOperand}
            name={daySelectorName}
            rules={validator}
          />
        </ErrorMesssage>
      </div>
    </>
  );
};

export default memo(DayOfWeek);

DayOfWeek.propTypes = propTypes;
DayOfWeek.defaultProps = defaultProps;
