import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { oneOfTypes } from 'common/propTypes';

const OPTION_TYPES = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

const propTypes = {
  optionType: oneOfTypes.isRequired,
  onOptionTypeChange: PropTypes.func.isRequired
};
const defaultProps = {};

const PlaceOptionsEntryBuyOrdersSelector = ({
  optionType: propsOptionType, onOptionTypeChange
}) => {
  const [optionTypeBtnName] = useState(_.uniqueId('optionPlaceOptionsEntryBuyOrdersSelector-'));
  const optionTypeInputId = _.uniqueId('optionTypeSelector');
  const { errors, register } = useFormContext();
  const validatorConfig = { validate: (value) => (!value ? 'value required' : null) };
  const validatorProps = { ref: register(validatorConfig) };

  const optionTypeStyle = classNames(
    'option-type',
    'align-items-center',
    'gap-10',
    'mt-1',
    'd-flex',
    'selector-radio-btn'
  );
  const radioBtnStyle = classNames(
    'form-check-input',
    { 'is-invalid': !!errors[optionTypeBtnName] }
  );

  const renderRadioBtn = (optionType) => {
    return (
      <div className="form-check" key={optionType.value}>
        <input
          {...validatorProps}
          className={radioBtnStyle}
          type="radio"
          name={optionTypeBtnName}
          id={`${optionTypeInputId}-${optionType.value}`}
          value={optionType.value}
          tabIndex="-100"
          checked={optionType.value === propsOptionType}
          onChange={() => onOptionTypeChange(optionType.value)}
        />
        <label className="form-check-label" htmlFor={`${optionTypeInputId}-${optionType.value}`}>
          {_.startCase(optionType.label)}
        </label>
      </div>
    );
  };

  const optionTypeRadioBtns = _.map(OPTION_TYPES, renderRadioBtn);

  return (
    <div className={optionTypeStyle}>
      {optionTypeRadioBtns}
    </div>
  );
};

PlaceOptionsEntryBuyOrdersSelector.propTypes = propTypes;
PlaceOptionsEntryBuyOrdersSelector.defaultProps = defaultProps;

export default PlaceOptionsEntryBuyOrdersSelector;
