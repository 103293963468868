// vendor imports
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// project imports
import { setOrderConfigs } from 'modules/QuantBuilder/actions';
import { orderConfigsPropTypes } from 'common/propTypes';

const propTypes = {
  indexToRemove: PropTypes.number.isRequired,
  orderConfigs: orderConfigsPropTypes.isRequired,
  dispatchSetOrderConfigs: PropTypes.func.isRequired,
};
const defaultProps = {};

const RemoveOrderConfig = (props) => {
  const { orderConfigs, dispatchSetOrderConfigs, indexToRemove } = props;

  const onRemoveOrderConfig = () => {
    const newOrderConfigs = _.filter(orderConfigs, (orderConfig, orderConfigIndex) => {
      return orderConfigIndex !== indexToRemove;
    });

    dispatchSetOrderConfigs(newOrderConfigs);
  };

  return (
    <button
      type="button"
      className="delete-btn"
      onClick={onRemoveOrderConfig}
    >
      <i className="material-icons-outlined">delete_outline</i>
    </button>
  );
};

const mapStateToProps = (state) => ({
  orderConfigs: _.get(state, 'quantConfig.orderConfigs')
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetOrderConfigs: (newOrderConfigs) => dispatch(setOrderConfigs(newOrderConfigs))
});

RemoveOrderConfig.propTypes = propTypes;
RemoveOrderConfig.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(RemoveOrderConfig);
