import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import Filters from './Filters';
import TransactionsPerPage from './TransactionsPerPage';
import TransactionsDownload from './TransactionsDownload';
import IoBtns from './IoBtns';

const propTypes = {
  onTransactionsPerPageChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  runName: PropTypes.string,
  runId: PropTypes.number.isRequired,
};
const defaultProps = {
  runName: '',
};

const Header = (props) => {
  const {
    onTransactionsPerPageChange, onFilterChange, runName, runId
  } = props;
  const { transactions } = useContext(OrderContext);

  const [specialFilter, setSpecialFilter] = useState('');

  useEffect(() => {
    setSpecialFilter('');
  }, [transactions]);

  const onSpecialFilterChange = (newFilter) => {
    setSpecialFilter(newFilter);
  };

  return (
    <div className="d-flex justify-content-between">
      <IoBtns />
      <div className="transaction-filter">
        <div className="d-flex gap-10 flex-wrap align-items-center">
          <Filters
            specialFilter={specialFilter}
            onSpecialFilterChange={onSpecialFilterChange}
            transactions={transactions}
            onFilterChange={onFilterChange}
          />
          <TransactionsDownload
            transactions={transactions}
            runName={runName}
            runId={runId}
          />
          <TransactionsPerPage
            onTransactionsPerPageChange={onTransactionsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
