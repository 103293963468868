import classNames from 'classnames';
import React from 'react';

const GoToCreateStrategyBanner = () => {
  const createStrategyBannerClassNames = classNames(
    'marketplace-banner', 'rounded mt-3 mt-md-0 mb-3 mb-md-6',
    'd-none d-md-block', 'master-domain-only'
  );

  return (
    <div className={createStrategyBannerClassNames}>
      <div className="px-5 d-flex flex-wrap flex-sm-nowrap align-items-center">
        <div className="w-100 mr-0 mb-3 mr-sm-10 mb-sm-0">
          <h1 className="text-white tx-25 font-weight-bold">Create your own strategy</h1>
          <p className="tx-14 text-white mt-1 mb-0">
            We allows you to tailor your approach
            to the financial markets and implement personalized trading rules.
          </p>
        </div>

        <button
          type="button"
          className="goto-btn btn btn-success text-nowrap btn-pill"
          onClick={() => { window.location.href = '/runs/new'; }}
        >
          Create Strategy
          <i className="material-icons-outlined tx-18 align-middle ml-1">arrow_forward</i>
        </button>
      </div>
    </div>
  );
};

GoToCreateStrategyBanner.propTypes = {};

export default GoToCreateStrategyBanner;
