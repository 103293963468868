export const onRunViewClick = (event, id) => {
  const runShowUrl = `/runs/${id}/?locale=${I18n.locale}`;
  const shouldRedirectToShowPage = () => {
    const isClickedOnPreventedIcon = $(event.target).closest('.prevent-redirect').length > 0;
    return !isClickedOnPreventedIcon;
  };

  if (!shouldRedirectToShowPage()) return;

  if (event.ctrlKey) {
    window.open(runShowUrl, '_blank');
    return;
  }

  window.location = runShowUrl;
};
