import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RUN_TYPES } from 'common/constants/index';
import RunSummary from 'modules/RunSummary/index';
import TransactionTable from 'modules/TransactionTable/index';
import { runPropTypes } from 'common/propTypes';
import { OrderContext } from 'ui/run/RunShow/OrderStore/index';

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  run: runPropTypes.isRequired,
  runType: PropTypes.string.isRequired
};

const defaultProps = {
  type: '', name: '', startDate: '', endDate: '', description: ''
};

const ShowCase = ({
  type, name, startDate, endDate, description, runType, run
}) => {
  const { isLoading } = useContext(OrderContext);
  if (isLoading) return null;

  const { live } = RUN_TYPES;
  switch (runType) {
    case live:
      return <TransactionTable run={run} />;
    default:
      return (
        <RunSummary
          name={name}
          startDate={startDate}
          endDate={endDate}
          description={description}
          type={type}
        />
      );
  }
};

ShowCase.propTypes = propTypes;
ShowCase.defaultProps = defaultProps;

export default ShowCase;
