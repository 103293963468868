import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'common/components/ModalComponent';

const propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({})),
  selectedVideoId: PropTypes.number
};

const defaultProps = { videos: [], selectedVideoId: 0 };

const VIDEO_SUBSTRING_ID = 17;

const VideosDetails = ({ videos, selectedVideoId }) => {
  const [currentVideoDetails, setCurrentVideoDetails] = useState({});

  useEffect(() => {
    const selectedVideo = _.find(videos, { id: selectedVideoId });

    if (!_.isEmpty(selectedVideo)) {
      const { title, video_link: videoLink, id } = selectedVideo;
      const videoUniqId = videoLink.substring(VIDEO_SUBSTRING_ID);
      const alteredLink = `https://www.youtube.com/embed/${videoUniqId}`;
      setCurrentVideoDetails({ title, videoLink: alteredLink, id });
    }
  }, [videos]);

  const renderVideoImage = (id, videoUniqId, title, alteredLink) => {
    return (
      <div
        className="videos"
        onClick={() => setCurrentVideoDetails({ title, videoLink: alteredLink, id })}
      >
        <div className="play-btn">
          <i className="material-icons">play_arrow</i>
        </div>
        <img
          src={`https://img.youtube.com/vi/${videoUniqId}/maxresdefault.jpg`}
          alt="video"
          className="rounded w-100"
        />
      </div>
    );
  };

  const renderVideoModal = () => {
    if (!_.keys(currentVideoDetails).length) return null;

    const { title, videoLink, id } = currentVideoDetails;
    window.history.pushState(null, '', `/learningvideos/${id}`);

    return (
      <Modal
        onClose={() => {
          window.history.pushState(null, '', '/learningvideos/');
          setCurrentVideoDetails({});
        }}
        title={title}
        className="video-modal"
        size="lg"
      >
        <iframe
          src={videoLink}
          title={title}
          frameBorder="0"
          allow="accelerometer;
                  autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal>
    );
  };

  const renderVideoDetails = (title, createdAt) => {
    return (
      <>
        <div className="videos-description">
          <h6
            className="text-capitalize"
          >
            {title}
          </h6>
        </div>
        <div className="video-item__meta">
          <div className="video-item__date">
            <i className="material-icons-outlined">
              calendar_month
            </i>
            {moment(createdAt).fromNow()}
          </div>
        </div>
      </>
    );
  };

  const renderVideo = (video, key) => {
    const {
      video_link: videoLink, title, created_at: createdAt, id
    } = video;
    const videoUniqId = videoLink.substring(VIDEO_SUBSTRING_ID);
    const alteredLink = `https://www.youtube.com/embed/${videoUniqId}`;

    return (
      <div className="col-sm-6 col-xl-4 mb-3 mb-lg-5" key={key}>
        <div className="video-card">
          {/* Video Image  */}
          {renderVideoImage(id, videoUniqId, title, alteredLink)}
          {/* Video title and time */}
          {renderVideoDetails(title, createdAt)}
        </div>
      </div>
    );
  };

  return (
    <div className="tab-content" id="pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="row">
          {_.isEmpty(videos)
            ? (
              <div className="no-video w-100 mt-6">
                <i className="icon-no-video" />
                No Videos
              </div>
            ) : _.map(videos, renderVideo)}
          {renderVideoModal()}
        </div>
      </div>
    </div>
  );
};

VideosDetails.propTypes = propTypes;
VideosDetails.defaultProps = defaultProps;

export default VideosDetails;
