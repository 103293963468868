const configuration = {
  type: 'williamsAlligator',
  defaults: {
    type: 'williamsAlligator',
    name: 'williamsAlligator',
    config: {
      jawLength: 13,
      teethLength: 8,
      lipsLength: 5,
      jawOffset: 8,
      teethOffset: 5,
      lipsOffset: 3,
      candleInterval: '1 minutes',
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'williamsAlligator'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipeConfig) => {
    const { name, type } = pipeConfig;
    const resultKeys = [
      'jaw',
      'teeth',
      'lips'
    ];

    return _.map(resultKeys, (resultKey) => {
      return {
        name: `${name}.${resultKey}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${resultKey}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
