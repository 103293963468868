import { getDefaultCase } from 'v2/modules/withRunForm/configs/index';
import { INTENT_TYPES } from 'v2/common/constants/index';
import { getSanitizedIntents } from './intent';
import { getSanitizedAdjustmentSignals } from './signal';

// userPermissions
const isMultipleCasesEnabled = _.get(window, 'SA_FEATURE_FLAGS.isMultipleCasesEnabled', false)
  || window?.isAdmin
  || false;

const getSanitizedAdjustments = (adjustments, baseIntents) => {
  return _.chain(adjustments)
    .filter(
      (adjustment) => !_.get(adjustment, 'uiConfigs.isAutoAdjustment', false)
    )
    .thru((newAdjustments) => {
      return _.chain(newAdjustments)
        .map('intents')
        .concat(baseIntents)
        .flatten()
        .filter((intent) => _.includes(
          [INTENT_TYPES.OpenLeg, INTENT_TYPES.OpenBasket],
            intent?.type
        ))
        .thru((intents) => {
          return _.map(newAdjustments, (adjustmentConfig) => {
            return {
              ...adjustmentConfig,
              signal: getSanitizedAdjustmentSignals(
                adjustmentConfig?.signal,
                intents
              ),
              intents: getSanitizedIntents(adjustmentConfig?.intents, intents),
            };
          });
        })
        .value();
    })
    .value();
};

export const getSanitizedCases = (cases) => {
  const newCases = _.chain(cases)
    .thru((caseConfigs) => {
      return isMultipleCasesEnabled
        ? caseConfigs
        : [_.get(caseConfigs, '0', getDefaultCase())];
    })
    .map((caseConfig) => {
      const {
        entry,
        entry: { intents: baseIntents },
        adjustments,
      } = caseConfig;

      return {
        ...caseConfig,
        entry: { ...entry, intents: getSanitizedIntents(baseIntents) },
        adjustments: getSanitizedAdjustments(adjustments, baseIntents),
      };
    })
    .value();

  return newCases;
};
