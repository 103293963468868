import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';

const configuration = {
  type: 'qqeSignals',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'qqeSignals',
    name: 'qqeSignals',
    config: {
      candleInterval: '3 minutes',
      rsiLength: 14,
      smoothingFactor: 5,
      qqeFactor: 4.238,
      valuePaths: [
        'item0.equity'
      ]
    },
  },
  searchTerms: [
    'qqeSignals'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      { value: 'QQELong', type: schemaTypes.number },
      { value: 'QQEShort', type: schemaTypes.number },
      { value: 'isLong', type: schemaTypes.boolean },
      { value: 'isShort', type: schemaTypes.boolean },
    ];

    return _.map(properties, ({ value, type: schemaType }) => {
      return {
        name: `${name}.${value}`,
        type: schemaType,
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${value}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };
    });
  },
  isEnabled: true,
};

export default configuration;
