import React from 'react';
import { runPropTypes, userPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';
import { getUserAccountDetails, modalConditionBasedRender } from './helper';
import RunningLiveRuns from './RunningLiveRuns';
import QuotaPurchase from './QuotaPurchase';
import { isStrategyDomainUser } from '../applicationDomainHelper';

const propTypes = {
  currentUser: userPropTypes,
  runParams: runPropTypes,
  callbackFunc: PropTypes.func,
  strategy: PropTypes.shape({}),
  instrumentGroups: PropTypes.arrayOf(PropTypes.array)
};

const defaultProps = {
  currentUser: null, runParams: {}, callbackFunc: () => { }, strategy: {}, instrumentGroups: []
};

const QuotaVerification = ({
  currentUser, runParams, callbackFunc, strategy, instrumentGroups
}) => {
  if (!currentUser) return callbackFunc();

  const {
    isShowPlan, isShowAddon, isShowLiveRun, backtestCredits
  } = getUserAccountDetails(currentUser, runParams, instrumentGroups);

  const {
    isStrategyInitialPayment,
    isCallBackFuncCall,
    isRenderRunningLiveRuns,
  } = modalConditionBasedRender(isShowPlan, isShowAddon, isShowLiveRun, strategy);

  if (isStrategyInitialPayment) return callbackFunc({ isReload: false });
  if (isCallBackFuncCall) return callbackFunc(runParams);
  if (isRenderRunningLiveRuns) {
    return <RunningLiveRuns callbackFunc={() => callbackFunc(runParams)} />;
  }

  // In modal, there is a coupon and other stuffs, that is no need for strategy domain users
  // so, we redirect user to plan page instead of modal
  if (isStrategyDomainUser()) {
    window.location.href = '/plans';
    return null;
  }

  return (
    <QuotaPurchase
      callbackFunc={() => callbackFunc(runParams)}
      isShowPlan={isShowPlan}
      strategy={strategy}
      isShowAddon={isShowAddon}
      backtestCredits={backtestCredits}
    />
  );
};

QuotaVerification.propTypes = propTypes;
QuotaVerification.defaultProps = defaultProps;

export default QuotaVerification;
