import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';

const propTypes = {
  onDeleteFolder: PropTypes.func.isRequired
};
const defaultProps = {};

const FolderDeleteConfirmation = ({ onDeleteFolder }) => {
  const [isShowModal, setIsShowModal] = useState(false);

  const modalProps = {
    title: 'Delete Confirmation',
    size: 'sm',
    shouldShow: isShowModal,
    onClose: () => setIsShowModal(false),
    okBtnTitle: 'Delete Folder',
    onSave: () => {
      onDeleteFolder();
      setIsShowModal(false);
    },
  };

  const renderModal = () => {
    return (
      <ModalComponent {...modalProps}>
        <div className="text-center">
          <img src="/delete-icon.png" alt="Delete" className="alert-modal-img mb-4" />
          <p>Once You delete the folder, it cannot be reverted back. Please make sure to delete.</p>
        </div>
      </ModalComponent>
    );
  };

  const renderButton = () => {
    return (
      <button
        type="button"
        className="delete-btn ml-2"
        onClick={(e) => {
          e.stopPropagation();
          setIsShowModal(true);
        }}
      >
        <i className="material-icons-outlined tx-22">delete_forever </i>
      </button>
    );
  };
  return (
    <div>
      {renderButton()}
      {renderModal()}
    </div>
  );
};

FolderDeleteConfirmation.propTypes = propTypes;
FolderDeleteConfirmation.defaultProps = defaultProps;

export default FolderDeleteConfirmation;
