import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useIntent } from './index';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import { getQuantModes } from '../../configs/index';

export const useModifyExistingIntent = (intent) => {
  const {
    createOnChangeHandler,
    onChangeIntentType
  } = useIntent(intent);
  const {
    type,
    target = '',
  } = intent;

  const mode = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs.mode')
  ) || getQuantModes.simple.value;
  const isAdvancedMode = useMemo(() => mode === getQuantModes.advanced.value, [mode]);

  return {
    type,
    target,
    isAdvancedMode,
    onChangeTarget: createOnChangeHandler('target.label'),
    onChangeIntentType
  };
};
