import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onHandleChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  isDisabled: PropTypes.bool
};

const defaultProps = { type: 'text', isDisabled: false };

const UserInput = ({
  id, value, onHandleChange, type, isDisabled
}) => {
  return (
    <input
      className="form-control"
      type={type}
      id={id}
      disabled={isDisabled}
      value={value}
      onChange={(event) => { onHandleChange(event.target.value); }}
    />
  );
};

UserInput.propTypes = propTypes;
UserInput.defaultProps = defaultProps;

export default UserInput;
