import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import Modal from 'common/components/ModalComponent';
import { getEmptySignalConfig } from 'modules/QuantBuilder/config';
import {
  outputSchemaPropTypes, orderConfigsPropTypes, signalConfigPropTypes
} from 'common/propTypes';
import SignalConfigsBuilder from 'modules/SignalConfigsBuilder';
import { RUN_TYPES } from 'common/constants/index';

const propTypes = {
  isClonedQuant: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    title: PropTypes.string,
    orderConfigIndex: PropTypes.number,
    signalConfigs: PropTypes.arrayOf(signalConfigPropTypes),
    signalConfigField: PropTypes.string,
  }).isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  runType: PropTypes.string.isRequired,
  orderConfigs: orderConfigsPropTypes.isRequired,
  orderConfigsValidators: PropTypes.shape({}),
  segment: PropTypes.string.isRequired,
  onUpdateSignalConfig: PropTypes.func.isRequired,
  isAdjustmentSignal: PropTypes.bool,
  customPropConfigs: PropTypes.arrayOf(PropTypes.shape({}))
};

const defaultProps = {
  orderConfigsValidators: {},
  isAdjustmentSignal: false,
  customPropConfigs: []
};

const SignalConfigsBuilderWrapper = (props) => {
  const {
    config: {
      signalConfigs, signalConfigField, title, orderConfigIndex
    },
    isClonedQuant,
    orderConfigs,
    outputSchema,
    orderConfigsValidators,
    runType,
    segment,
    onUpdateSignalConfig,
    isAdjustmentSignal,
    customPropConfigs
  } = props;

  const isHistoricRun = runType === RUN_TYPES.historic;

  const methods = useForm();
  const [shouldRenderModal, setShouldRenderModal] = useState(false);
  const initialSignalConfigs = _.isEmpty(signalConfigs)
    ? getEmptySignalConfig()
    : _.cloneDeep(signalConfigs);

  const [stateSignalConfigs, setStateSignalConfigs] = useState(initialSignalConfigs);
  const signalValidator = _.get(orderConfigsValidators, signalConfigField, {});

  useEffect(() => {
    setStateSignalConfigs(initialSignalConfigs);
  }, [signalConfigs]); // Added useEffect.. because.. In indicator there is an suggestion option
  // when the suggestion indicator added.. entry when is also added, but here we set as props in state..
  // so update the state while signalConfigs changes

  const onCancel = () => {
    setStateSignalConfigs(initialSignalConfigs);
    setShouldRenderModal(!shouldRenderModal);
  };
  const onSignalSave = () => {
    const newOrderConfigs = _.cloneDeep(orderConfigs);
    setShouldRenderModal(!shouldRenderModal);

    const updateConfig = {
      newOrderConfigs, orderConfigIndex, signalConfigField, stateSignalConfigs
    };

    onUpdateSignalConfig(updateConfig);
  };

  const onDelete = () => {
    const newOrderConfigs = _.cloneDeep(orderConfigs);
    const updateConfig = {
      newOrderConfigs, orderConfigIndex, signalConfigField, stateSignalConfigs: []
    };

    onUpdateSignalConfig(updateConfig);

    setShouldRenderModal(!shouldRenderModal);
  };

  const sanitizedOutputSchema = _.flattenDeep([outputSchema,
    _.chain(customPropConfigs).map(({ outputSchema: cpcOutputSchema }) => {
      try { return JSON.parse(cpcOutputSchema); } catch (err) {
        return null;
      }
    }).compact().value()
  ]);

  const modalSaveButtonProps = {
    'data-track-category': 'Operands',
    'data-track-action': `Left Operand - ${_.get(_.head(stateSignalConfigs), 'leftOperand', '')}`,
    'data-track-label': `Right Operand - ${_.get(_.head(stateSignalConfigs), 'rightOperand', '')}`
  };

  return (
    <>
      <button
        type="button"
        className="align-items-center btn btn-link d-flex font-weight-semi gap-5 pr-0"
        onClick={() => setShouldRenderModal(!shouldRenderModal)}
      >
        <i className="material-icons-outlined tx-16">edit</i>
        Edit
      </button>
      {
        shouldRenderModal
        && (
          <Modal
            onSave={methods.handleSubmit(onSignalSave)}
            onClose={onCancel}
            title={title}
            onCancel={() => onDelete()}
            cancelBtnTitle="Delete"
            size="lg"
            btnClassName="track"
            modalSaveButtonProps={modalSaveButtonProps}
            shouldConfirmClose={() => !_.isEqual(stateSignalConfigs, initialSignalConfigs)}
          >
            <div className="signal-configs">
              <FormProvider {...methods}>
                <SignalConfigsBuilder
                  isClonedQuant={isClonedQuant}
                  signalValidator={signalValidator}
                  segment={segment}
                  signalConfigField={signalConfigField}
                  outputSchema={sanitizedOutputSchema}
                  signalConfigs={stateSignalConfigs}
                  isAdjustmentSignal={isAdjustmentSignal}
                  isHistoricRun={isHistoricRun}
                  onSignalConfigsChange={(newSignalConfigs) => setStateSignalConfigs(newSignalConfigs)}
                />
              </FormProvider>
            </div>
          </Modal>
        )
      }
    </>
  );
};

SignalConfigsBuilderWrapper.propTypes = propTypes;
SignalConfigsBuilderWrapper.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  isClonedQuant: _.get(state, 'isClonedQuant'),
  runType: _.get(state, 'runType'),
  outputSchema: _.get(state, 'outputSchema'),
  orderConfigs: _.get(state, 'quantConfig.orderConfigs'),
  orderConfigsValidators: _.get(state, ['quantConfigValidators', 'orderConfigsValidators']),
  segment: _.get(state, 'segment'),
  customPropConfigs: _.get(state, 'quantConfig.customPropConfigs', [])
});

export default connect(mapStateToProps)(SignalConfigsBuilderWrapper);
