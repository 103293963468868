export const getSanitizedStopLoss = (stopLoss) => {
  const trail = stopLoss?.trail || {};

  const isOmitTrailingStopLoss = !trail.trailingValue;
  const isOmitAdjustStopLoss = !trail.adjustValue;
  const isOmitBookProfitOn = !trail.bookProfitOn;
  const isOmitBookProfit = !trail.bookProfit;

  const isOmitTrail = isOmitTrailingStopLoss && isOmitAdjustStopLoss
    && isOmitBookProfitOn && isOmitBookProfit;

  const sanitizedTrail = _.omit(trail, [
    isOmitTrailingStopLoss ? 'trailingValue' : null,
    isOmitAdjustStopLoss ? 'adjustValue' : null,
    isOmitBookProfitOn ? 'bookProfitOn' : null,
    isOmitBookProfit ? 'bookProfit' : null,
  ]);

  return {
    ...stopLoss,
    trail: isOmitTrail ? undefined : sanitizedTrail,
  };
};
