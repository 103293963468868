import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { outputSchemaPropTypes } from 'common/propTypes';
import { OutputSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import { OperandUtility } from '../../../utility';

const propTypes = {
  operator: PropTypes.string,
  onChangeOperator: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  leftOperand: PropTypes.string,
  outputSchema: outputSchemaPropTypes.isRequired,
};
const defaultProps = {
  operator: '',
  leftOperand: '',
};

const Operator = ({
  operator, onChangeOperator, control, errors, outputSchema, leftOperand
}) => {
  const uniqueId = useMemo(() => _.uniqueId('operator_'), []);
  const errorMessage = useMemo(() => _.get(errors, `${uniqueId}.message`, ''), [errors, uniqueId]);
  const leftOperandSchema = OutputSchemaUtility.findByName(outputSchema, leftOperand);
  const leftOperandType = leftOperandSchema?.type;

  const operatorOptions = OperandUtility.getOperators(leftOperandType);
  const validatorConfig = {
    validate: (value) => {
      if (_.isEmpty(value)) return 'Please select a operator';

      return true;
    }
  };

  const renderOperatorOption = (option) => {
    const { name, label } = option;

    return (
      <option value={name} key={name}>{_.startCase(label)}</option>
    );
  };

  return (
    <div className="operator operand-info">
      <ErrorMesssage message={errorMessage}>
        <Controller
          render={({ onChange, ref, name }) => (
            <Form.Control
              as="select"
              className=""
              value={operator}
              ref={ref}
              size="sm"
              name={name}
              onChange={(event) => {
                onChangeOperator(event.target.value);
                onChange(event.target.value);
              }}
            >
              <option value="" key="" />
              {_.map(operatorOptions, renderOperatorOption)}
            </Form.Control>
          )}
          control={control}
          defaultValue={operator}
          name={uniqueId}
          rules={validatorConfig}
        />
      </ErrorMesssage>
    </div>
  );
};

Operator.propTypes = propTypes;
Operator.defaultProps = defaultProps;

export default memo(Operator);
