import React, { useContext, useMemo } from 'react';
import { intentPropTypes } from 'common/propTypes';
import { INTENT_TYPES } from 'v2/common/constants/index';
import { IntentsContext } from 'v2/modules/withRunForm/hoc/withIntentsContext';
import HoverMessage from 'common/components/HoverMessage';
import ModifyExistingLegsIntent from './ModifyExistingLegsIntent/index';
import OpenLegIntent from './OpenLegIntent/index';
import BasketIntent from './BasketIntent/index';

const propTypes = {
  intent: intentPropTypes.isRequired,
};

const defaultProps = {};

const Intent = ({ intent }) => {
  const {
    onDeleteIntent,
    intentDependencies,
  } = useContext(IntentsContext);
  const intentDependency = _.get(intentDependencies, intent?.identifier?.label, {});

  const isDependent = _.get(intentDependency, 'isDependent', false);

  const renderDeleteBtn = useMemo(() => (
    <HoverMessage
      message={isDependent
        ? 'Leg used in adjustment. Please remove the usage and then delete this leg.' : 'Delete'}
      popClassName="d-sm-none d-md-block custom-popover"
    >
      <button
        className="btn btn-delete-outline"
        type="button"
        onClick={() => {
          if (isDependent) {
            reactToastify('Cannot delete dependent leg', TOASTIFY_TYPES.ERROR);
            return;
          }
          onDeleteIntent(intent?.identifier?.label);
        }}
        disabled={isDependent}
      >
        <i aria-disabled={isDependent} />
      </button>
    </HoverMessage>
  ), [intent.identifier.label, isDependent, onDeleteIntent]);

  const IntentComponent = useMemo(() => {
    switch (intent.type) {
      case INTENT_TYPES.CloseLeg:
      case INTENT_TYPES.UpdateStopLoss:
        return ModifyExistingLegsIntent;

      case INTENT_TYPES.OpenBasket:
        return BasketIntent;

      default:
        return OpenLegIntent;
    }
  }, [intent.type]);

  return (
    <IntentComponent intent={intent} renderDeleteBtn={renderDeleteBtn} />
  );
};

Intent.propTypes = propTypes;
Intent.defaultProps = defaultProps;

export default Intent;
