import React from 'react';
import PropTypes from 'prop-types';

import InlineEdit from 'common/components/InlineEdit';

const propTypes = {
  name: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  isRunBelongsToUser: PropTypes.bool.isRequired,
  isHideEditRun: PropTypes.bool
};
const defaultProps = {
  isHideEditRun: false
};

const RunName = ({
  name, onNameChange, isRunBelongsToUser, isHideEditRun
}) => {
  return (
    <div className="run-display-name">
      <h4>
        <InlineEdit
          text={name}
          onUpdate={onNameChange}
          tooltip="Edit Run name"
          isShowEditIcon={!(isHideEditRun || !isRunBelongsToUser)}
        />
      </h4>
    </div>
  );
};

RunName.propTypes = propTypes;
RunName.defaultProps = defaultProps;

export default RunName;
