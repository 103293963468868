import { getValue } from './dataUtils';
import { getTipsyHandler } from './tipsyHandler';
import { getTicksGenerator } from './ticksGenerator';
import { createGradient } from './gradientUtils';

const initTemplate = (chartContainer) => {
  const svg = chartContainer.select('svg');
  const svgDef = svg.append('defs');

  const tooltip = chartContainer.select('div.tooltip');
  tooltip
    .attr('style', 'position: absolute; opacity: 0; top: 0px; left: 0px; pointer-events:none;');

  const chartArea = svg
    .append('g')
    .attr('class', 'chartArea');

  const clipPath = svgDef
    .append('SVG:clipPath')
    .attr('id', 'clip');
  clipPath.append('SVG:rect')
    .attr('x', 0)
    .attr('y', 0);

  const xAxis = chartArea.append('g')
    .attr('class', 'axis axis--x');

  const yAxis = chartArea.append('g')
    .attr('class', 'axis axis--y');

  const viewPort = chartArea.append('g')
    .attr('class', 'viewPort')
    .attr('clip-path', 'url(#clip)');

  const fillGradient = createGradient(
    svgDef,
    'fill-gradient',
    ['rgb(124, 244, 176)', 'rgb(124, 244, 176)', 'rgb(237, 151, 160)', 'rgb(237, 151, 160)'],
    ['0.5', '0.0', '0.0', '0.5']
  );
  const strokeGradient = createGradient(
    svgDef,
    'stroke-gradient',
    ['rgb(16, 183, 89)', 'rgb(16, 183, 89)', 'rgb(220, 53, 69)', 'rgb(220, 53, 69)'],
    ['1', '1', '1', '1']
  );

  const pattern = svgDef
    .append('pattern')
    .attr('width', '1')
    .attr('patternUnits', 'userSpaceOnUse')
    .attr('id', _.uniqueId('stripes'));

  pattern.append('rect')
    .attr('fill', `url(#${fillGradient.attr('id')})`) // Option 1. Profit green/ Loss Red gradient fill
    // .attr('fill', 'rgba(63, 81, 181, 0.2)') // Option 2. Normal fill
    .attr('width', '4')
    .attr('transform', 'translate(0,0)');

  const zoomer = svg.append('rect')
    .attr('class', 'zoomer')
    .style('fill', 'none')
    .style('pointer-events', 'all');

  return {
    chartArea,
    clipPath,
    xAxis,
    yAxis,
    viewPort,
    fillGradient,
    strokeGradient,
    pattern,
    svg,
    tooltip,
    zoomer
  };
};

const getTemplate = (chartContainer) => {
  const svg = chartContainer.select('svg');
  const chartArea = svg.select('g.chartArea');
  const clipPath = svg.select('def clipPath');
  const xAxis = svg.select('g.axis.axis--x');
  const yAxis = svg.select('g.axis.axis--y');
  const viewPort = svg.select('g.viewPort');

  const fillGradient = svg.select('defs linearGradient.fill-gradient');
  const strokeGradient = svg.select('defs linearGradient.stroke-gradient');
  const pattern = svg.select('defs pattern');
  const tooltip = chartContainer.select('div.tooltip');
  const zoomer = svg.select('rect.zoomer');

  return {
    chartArea,
    clipPath,
    xAxis,
    yAxis,
    viewPort,
    fillGradient,
    strokeGradient,
    pattern,
    svg,
    tooltip,
    zoomer
  };
};

const getAreaRenderer = (xScale, yScale, options, fromZero) => {
  return d3.area()
    .defined((d) => getValue(d, options.valuePath) !== null)
    .curve(d3.curveMonotoneX)
    .x((d, i) => xScale(i))
    .y0(yScale(0))
    .y1(fromZero ? yScale(0) : (d) => yScale(getValue(d, options.valuePath)));
};

const repositionElements = ({
  elements, yScale, margin, dimension
}) => {
  const {
    chartArea, clipPath, fillGradient, strokeGradient, pattern, xAxis, zoomer
  } = elements;
  const yPerc = yScale.copy().range([100, 0]);
  const zeroValueYRangePercentage = yPerc(0);

  chartArea
    .attr('transform', `translate(${margin.left}, ${margin.top})`);
  clipPath.select('rect')
    .attr('width', dimension.width - 2)
    .attr('height', dimension.height);
  xAxis
    .attr('transform', `translate(0, ${dimension.height})`);
  zoomer.attr('width', dimension.width)
    .attr('height', dimension.height)
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  fillGradient.select('stop.stop-1')
    .attr('offset', `${zeroValueYRangePercentage.toFixed(4)}%`);
  fillGradient.select('stop.stop-2')
    .attr('offset', `${zeroValueYRangePercentage.toFixed(4)}%`);
  strokeGradient.select('stop.stop-1')
    .attr('offset', `${zeroValueYRangePercentage.toFixed(4)}%`);
  strokeGradient.select('stop.stop-2')
    .attr('offset', `${zeroValueYRangePercentage.toFixed(4)}%`);
  pattern
    .attr('height', `${dimension.height}`);
  pattern.select('rect')
    .attr('height', `${dimension.height}`);
};

const renderAxis = ({
  elements, xScale, yScale, sources
}) => {
  const { xAxis, yAxis } = elements;
  const ticksGenerator = getTicksGenerator(xScale, sources);

  const xAxisGenerator = d3.axisBottom(xScale)
    .tickValues(ticksGenerator.tickValues)
    .tickFormat(ticksGenerator.tickFormat);
  xAxis
    .call(xAxisGenerator)
    .call((elem) => {
      const newDomainLine = d3.line()([
        [xScale.range()[0], yScale.range()[1]],
        [xScale.range()[1], yScale.range()[1]]
      ]);
      elem.select('.domain').attr('d', newDomainLine);
    });
  yAxis
    .call(d3.axisLeft(yScale).tickSize(-xScale.range()[1]))
    .call((elem) => elem.select('.domain').remove())
    .selectAll('.tick line')
    .attr('stroke', 'rgba(77, 77, 77, 0.1)')
    .attr('stroke-dasharray', '0');
};

const updateData = (elements, area, transition) => {
  let { viewPort } = elements;

  if (transition) {
    viewPort = viewPort.transition(transition);
  }
  viewPort.selectAll('.area')
    .selectAll('path')
    .attr('d', (d) => area(d.series));
};

const renderData = ({
  elements, xScale, yScale, sources, options
}) => {
  const { viewPort, strokeGradient, fillGradient } = elements;
  const area = getAreaRenderer(xScale, yScale, options);
  // const startArea = getAreaRenderer(xScale, yScale, options, true);

  const filledLines = viewPort.selectAll('.area').data(sources);
  filledLines.enter().append('g')
    .attr('class', 'area')
    .append('path')
    .attr('stroke', `url(#${strokeGradient.attr('id')})`)
    .attr('stroke-width', '2')
    .style('fill', `url(#${fillGradient.attr('id')})`);

  filledLines.select('path').datum((source) => source);
  filledLines.exit().remove();

  // Commenting out animation.
  // updateData(elements, startArea);
  // setTimeout(() => updateData(elements, area, 300), 100);
  updateData(elements, area);
};

const setupZoom = ({
  elements, xScale, yScale, dimension, options, sources, tipsyHandler
}) => {
  const { zoomer } = elements;
  const zoom = d3.zoom()
    .scaleExtent([0.95, 10]) // This control how much you can unzoom (x0.5) and zoom (x20)
    .extent([[0, 0], [dimension.width, dimension.height]]);

  zoom.on('zoom', (event) => {
    const newXScale = event.transform.rescaleX(xScale);
    const newArea = getAreaRenderer(newXScale, yScale, options);

    renderAxis({
      elements, xScale: newXScale, yScale, sources
    });
    updateData(elements, newArea);
    tipsyHandler.update(newXScale);

    // If required to zoom y dimension
    // const newY = event.transform.rescaleY(y);
    // yAxis.call(d3.axisLeft(newY))
  });

  zoomer.call(zoom);
};

export const renderChart = (chartContainer, margin, sources, options) => {
  if (!sources || !sources[0] || !sources[0].series || !sources[0].series.length) {
    return;
  }
  const templateAlreadyInitialized = chartContainer.select('g.viewPort').size() > 0;
  const elements = templateAlreadyInitialized ? getTemplate(chartContainer) : initTemplate(chartContainer);
  const { zoomer } = elements;

  const chartContainerBoundingRect = chartContainer.node().getBoundingClientRect();
  const dimension = {
    width: +chartContainerBoundingRect.width - margin.left - margin.right,
    height: +chartContainerBoundingRect.height - margin.top - margin.bottom
  };

  const xScale = d3.scaleLinear()
    .range([0, dimension.width])
    .domain([-1, sources[0].series.length]);
  xScale.clamp(true);
  const yScale = d3.scaleLinear()
    .range([dimension.height, 0])
    .domain([
      d3.min(sources, (source) => d3.min(source.series, (d) => getValue(d, options.valuePath))) || -100,
      d3.max(sources, (source) => d3.max(source.series, (d) => getValue(d, options.valuePath))) || 100
    ]);

  const tipsyHandler = getTipsyHandler({
    elements, xScale, yScale, sources, margin, options
  });
  zoomer
    .on('mouseover', null).on('mouseover', tipsyHandler.onMouseOver)
    .on('mousemove', null).on('mousemove', tipsyHandler.onMouseMove)
    .on('mouseleave', null)
    .on('mouseleave', tipsyHandler.onMouseLeave);
  tipsyHandler.hideTooltip();

  repositionElements({
    elements, xScale, yScale, margin, dimension
  });
  renderAxis({
    elements, xScale, yScale, sources
  });
  renderData({
    elements, xScale, yScale, sources, options
  });
  setupZoom({
    elements, xScale, yScale, dimension, options, sources, tipsyHandler
  });
  window.prerenderReady = true;
};
