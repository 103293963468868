import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onCloneRun: PropTypes.func.isRequired
};
const defaultProps = {};

const CloneRunBtn = ({ onCloneRun }) => (
  <a
    type="button"
    href="#clone"
    onClick={onCloneRun}
    className="btn btn-sm btn-outline-primary text-nowrap copy-btn track"
    data-track-category="Quant Clone"
    data-track-action="clone"
    data-track-label="Show Page"
    data-toggle="tooltip"
    data-placement="top"
    id="run-show"
    name="Clone"
  >
    Copy and Edit
  </a>
);

CloneRunBtn.propTypes = propTypes;
CloneRunBtn.defaultProps = defaultProps;

export default CloneRunBtn;
