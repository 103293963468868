import qs from 'qs';
import wrappedFetch from './base';

const params = {
  method: 'POST',
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
};

export const signOut = () => {
  return wrappedFetch('/users/sign_out')
    .then(() => { window.location.href = '/'; });
};

export const updateUser = (args) => {
  const queryParams = qs.stringify({ current_user: { ...args } });
  const uri = `/user/update/update_user_detail?${queryParams}`;

  return wrappedFetch(uri, params)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

export const validateBrokerUser = (args) => {
  const queryParams = qs.stringify({ ...args });
  const uri = `/user/validate_broker_client?${queryParams}`;

  return wrappedFetch(uri, params)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

export const sendOtpToBrokerUser = (userCredentials, isResendOtp, broker) => {
  const uri = `/${broker}/user_authenticator/send_otp`;
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ user_credentials: userCredentials, is_resend_otp: isResendOtp }),
  };

  return wrappedFetch(uri, config)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

export const validateOtpToBrokerUser = (userCredentials, broker) => {
  const uri = `/${broker}/user_authenticator/validate_otp`;
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ user_credentials: userCredentials }),
  };

  return wrappedFetch(uri, config)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

export const getCurrentUserDetail = () => {
  return wrappedFetch('/user/get_current_user_detail').then((response) => response.json());
};

export const getExpiringSoonItems = () => {
  return wrappedFetch('/purchases/expiring_soon.json')
    .then((response) => response.json());
};

export const checkIsValidBrokerAccessToken = () => {
  return wrappedFetch('user/is_valid_broker_access_token').then((response) => response.json());
};

export const updateEmailId = (emailId) => {
  const uri = '/user/update_email_id';
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ email_id: emailId }),
  };

  return wrappedFetch(uri, config)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

export const updateReferralCode = (referralCode) => {
  const uri = '/update_referral_code';
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ referral_code: referralCode }),
  };

  return wrappedFetch(uri, config)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};

export const updateMandatoryPreferences = (preferences) => {
  const uri = '/user/update_mandatory_preferences';
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(preferences),
  };

  return wrappedFetch(uri, config)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
};
