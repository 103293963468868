import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import {
  schemaShouldShowConfigTypes,
  schemaTypes,
} from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'meanReversionChannel',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'meanReversionChannel',
    name: 'mrc',
    config: {
      period: 200,
      innerMultiplier: 1,
      outerMultiplier: 2.415,
      filterType: 'superSmoother',
      candleInterval: '3 minutes',
      valuePaths: ['item0.equity'],
    },
  },
  searchTerms: ['mrc', 'meanReversionChannel'],
  inputSchema: [
    {
      name: 'filterType',
      type: pipeInputSchemaTypes.select,
      label: 'Filter Type',
      options: [
        'superSmoother',
        'ehlersEma',
        'gaussian',
        'butterWorth',
        'bandStop',
        'sma',
        'ema',
        'rma',
      ],
    },
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future],
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    const properties = [
      { value: 'mean' },
      { value: 'r1' },
      { value: 's1' },
      { value: 'r2' },
      { value: 's2' },
    ];

    return _.map(properties, ({ value }) => {
      return {
        name: `${name}.${value}`,
        type: schemaTypes.number,
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${value}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };
    });
  },
  isEnabled: true
};

export default configuration;
