import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';

const propTypes = {
  brokersConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supportedBrokers: PropTypes.arrayOf(PropTypes.string),
  onCloseModal: PropTypes.func.isRequired,
  onSetBroker: PropTypes.func.isRequired
};
const defaultProps = {
  supportedBrokers: null
};

const BrokerAccountLoginModal = ({ onCloseModal, brokersConfig, onSetBroker }) => {
  const [filteredBrokers, setFilteredBrokers] = useState(
    _.sortBy(brokersConfig, 'sortOrder')
  );

  const modalProps = {
    size: 'lg',
    shouldShow: true,
    title: 'Login with your Broking Account',
    onClose: () => { onCloseModal(); },
  };

  const onSearch = (searchValue) => {
    let filteredBrokersBySearchValue = brokersConfig;

    if (searchValue !== '') {
      filteredBrokersBySearchValue = _.filter(brokersConfig, (broker) => {
        return _.get(broker, 'brokerConfig.name', '').toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    setFilteredBrokers(_.sortBy(filteredBrokersBySearchValue, 'sortOrder'));
  };

  const renderFilter = () => {
    return (
      <div className="row">
        <div className="col-md-12 mb-5">
          <div className="input-group search-bar mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="material-icons-outlined">search</i>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search Broker"
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderBroker = (broker, idx) => {
    const {
      brokerConfig, brokerClassName, imagePath, imageClassName
    } = broker;
    const href = _.get(broker, 'href', '');
    const name = _.get(broker, 'name', '');

    return (
      <button
        type="button"
        className={`${brokerClassName}`}
        key={idx}
        id={`${brokerConfig.id}-user-auth-modal-btn`}
        onClick={() => {
          if (href) window.location.href = href;
          else onSetBroker(brokerConfig.id);
        }}
      >
        {imagePath && <img src={imagePath} alt={brokerConfig.name} className={imageClassName} />}
        {name && <span>{name}</span>}
      </button>
    );
  };

  const renderModal = () => {
    return (
      <ModalComponent {...modalProps}>
        <div className="modal-broker">
          <div>
            {renderFilter()}
            <div className="broker-login-container">
              {_.map(filteredBrokers, renderBroker)}
            </div>
          </div>
        </div>
      </ModalComponent>
    );
  };

  return (
    <div>
      {renderModal()}
    </div>
  );
};

export default BrokerAccountLoginModal;

BrokerAccountLoginModal.propTypes = propTypes;
BrokerAccountLoginModal.defaultProps = defaultProps;
