import React, {
  memo,
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { pipeConfigPropTypes } from 'common/propTypes';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ModalComponent from 'v2/common/components/ModalComponent';
import { useForm } from 'react-hook-form';
import useValidationSchema from 'v2/modules/withRunForm/hooks/useValidationSchema/index';
import { intentIdIntializer } from 'v2/modules/withRunForm/sanitizer/onLoad/quantConfig/intentidinitializer';
import PipeSelector from './PipeSelector';
import PipeName from './PipeName';
import PipeChartConfig from './PipeChartConfig';
import PipeInput from './PipeInput';

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  onUpdate: PropTypes.func.isRequired,
  existingPipeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDependent: PropTypes.bool,
  pipeIndex: PropTypes.number,
  getPipeUpdatedNameIfAlreadyPresent: PropTypes.func.isRequired
};
const defaultProps = {
  isDependent: false,
  pipeIndex: undefined
};

const PipeConfigForm = ({
  pipeConfig: propsPipeConfig,
  onUpdate, existingPipeNames, isDependent, pipeIndex, getPipeUpdatedNameIfAlreadyPresent
}) => {
  // must clean up code....
  const [pipeConfig, setPipeConfig] = useState(propsPipeConfig);
  const pipeConfigRef = useRef(propsPipeConfig);
  const { type, name, chartConfig } = pipeConfig;
  const { pipesValidators } = useValidationSchema();
  const pipeValidator = pipesValidators[type?.includes('snippet') ? 'snippet' : type] || {};
  const {
    handleSubmit, trigger, errors, control, clearErrors, setError
  } = useForm();

  useEffect(() => {
    setPipeConfig(propsPipeConfig);
    pipeConfigRef.current = propsPipeConfig;
  }, [propsPipeConfig]);

  const modalSaveButtonProps = useMemo(() => ({
    'data-track-category': 'Indicator',
    'data-track-action': _.get(pipeConfig, 'type', ''),
    'data-track-label': 'Indicator'
  }), [pipeConfig]);

  const onUpdatePipeConfig = useCallback((partialPipeConfig, isSave = false) => {
    const newPipeConfig = { ...pipeConfigRef.current, ...partialPipeConfig };
    setPipeConfig(newPipeConfig);
    pipeConfigRef.current = newPipeConfig;
    if (isSave) {
      intentIdIntializer([], [pipeConfigRef.current]);
      onUpdate(pipeConfigRef.current);
    }
  }, [onUpdate]);

  const onSave = async () => {
    setTimeout(() => onUpdatePipeConfig({}, true), 500);
  };

  const onClose = useCallback(() => {
    intentIdIntializer([], [propsPipeConfig]);
    onUpdate({});
  }, [onUpdate, propsPipeConfig]);

  return (
    <ModalComponent
      onSave={handleSubmit(onSave)}
      onClose={onClose}
      title="Add Indicator"
      size="lg"
      btnClassName="track"
      onSaveid={type}
      className="add-indicator-modal"
      shouldConfirmClose
      modalSaveButtonProps={modalSaveButtonProps}
    >
      <Form className="row m-0 justify-content-between">
        <PipeSelector
          control={control}
          errors={errors}
          type={type}
          isDependent={isDependent}
          onSetNewPipe={(newPipeConfig) => {
            const updatedPipeConfig = getPipeUpdatedNameIfAlreadyPresent(newPipeConfig);
            setPipeConfig(updatedPipeConfig);
            pipeConfigRef.current = updatedPipeConfig;
          }}
          validator={_.get(pipeValidator, 'type', {})}
        />
        <PipeName
          name={name}
          onUpdateName={onUpdatePipeConfig}
          existingPipeNames={existingPipeNames}
          control={control}
          pipeType={type}
          clearErrors={clearErrors}
          errors={errors}
          validator={_.get(pipeValidator, 'name', {})}
        />
        {!_.isEmpty(chartConfig) && (
          <PipeChartConfig
            chartConfig={chartConfig}
            onUpdateChartConfig={onUpdatePipeConfig}
            validator={_.get(pipeValidator, 'chartConfig', {})}
          />
        )}
        <PipeInput
          pipeConfig={pipeConfig}
          onUpdatePipeConfig={onUpdatePipeConfig}
          validator={_.get(pipeValidator, 'config', {})}
          existingPipeNames={existingPipeNames}
          control={control}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          trigger={trigger}
          pipeIndex={pipeIndex}
        />
      </Form>
    </ModalComponent>
  );
};

PipeConfigForm.propTypes = propTypes;
PipeConfigForm.defaultProps = defaultProps;

export default memo(PipeConfigForm);
