import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {
  value: undefined,
};

const TYPES = {
  IMMEDIATELY: 'immediately',
  AFTER: 'after',
};

const renderInput = (type, value, changeValue) => {
  if (type === TYPES.IMMEDIATELY) {
    return null;
  }

  return (
    <>
      <div className="input-group ">
        <input
          className="quick-delay-by-input"
          type="number"
          value={value}
          onChange={changeValue}
        />
        <div className="input-group-append">
          <label className="input-group-text" htmlFor="inputGroupSelect02">
            Secs
          </label>
        </div>
      </div>
      {/* <span className="quick-delay-by-seconds">Secs</span> */}
    </>
  );
};

const QuickAdjustmentDelayBy = ({ value, onChange }) => {
  const [type, setType] = useState(
    _.isNumber(value) ? TYPES.AFTER : TYPES.IMMEDIATELY
  );

  const changeValue = useCallback((event) => {
    onChange('delayBy', _.trim(event.target.value.toString()).length > 0
      ? Number(event.target.value)
      : '');
  }, [onChange]);
  const changeType = useCallback((event) => {
    const newType = event.target.value;
    setType(newType);
    onChange('delayBy', newType === TYPES.IMMEDIATELY ? '' : 0);
  },
  [setType, onChange]);

  const componentClassNames = classNames('quick-adjustment-delay-by', {
    'type-immediate': type === TYPES.IMMEDIATELY,
    'type-after': type === TYPES.AFTER,
  });

  return (
    <div className={componentClassNames}>
      <select
        className="quick-delay-by-select"
        onChange={changeType}
        value={type}
      >
        <option value={TYPES.IMMEDIATELY}>Immediately</option>
        <option value={TYPES.AFTER}>After</option>
      </select>
      {renderInput(type, value, changeValue)}
    </div>
  );
};

QuickAdjustmentDelayBy.propTypes = propTypes;
QuickAdjustmentDelayBy.defaultProps = defaultProps;

export default QuickAdjustmentDelayBy;
