import { PIPE_CATEGORIES } from 'v2/common/quantConfig/index';
import { getSanitizedStopLoss } from './stopLoss';
import { getSanitizedAdjustmentSignals } from './cases/signal';

const getSanitizedIntent = (intent) => {
  const { identifier } = intent;
  return {
    ...intent,
    identifier: _.pick(identifier, ['label', 'iteration']),
  };
};

export const getSanitizedPipeConfigs = (pipeConfigs, cases) => {
  const allIntents = _.chain(cases)
    .map(({ entry: { intents: baseIntents }, adjustments }) => {
      return _.chain(adjustments).map('intents').concat(baseIntents).value();
    })
    .flattenDeep()
    .value();

  // customScript type to actual customScript snippet label
  return _.chain(pipeConfigs)
    .filter((pipeConfig) => !pipeConfig.uiConfigs?.isAutoPipe)
    .map((pipeConfig) => {
      let newPipeConfig = pipeConfig;
      if (
        newPipeConfig.type === 'customScript'
        && newPipeConfig.category === PIPE_CATEGORIES.snippet
      ) {
        const { snippetId } = newPipeConfig.config;
        const snippet = _.find(window.SA_COLLECTIONS.indicatorCodeSnippets, {
          id: snippetId,
        });

        if (_.isEmpty(snippet)) {
          return newPipeConfig;
        }

        return {
          ...newPipeConfig,
          type: `snippet - ${snippet.label || snippet.label_alias}`,
        };
      }

      if (newPipeConfig.type === 'transactionStopLoss') {
        return {
          ...newPipeConfig,
          config: {
            stopLoss: getSanitizedStopLoss(newPipeConfig.config?.stopLoss),
          },
        };
      }

      if (!_.isEmpty(newPipeConfig?.config?.legs)) {
        const newLegs = _.map(newPipeConfig?.config?.legs, getSanitizedIntent);
        newPipeConfig = {
          ...newPipeConfig,
          config: { ...newPipeConfig?.config, legs: newLegs },
        };
      }

      if (!_.isEmpty(newPipeConfig?.config?.when)) {
        const signals = newPipeConfig?.config?.when;

        newPipeConfig = {
          ...newPipeConfig,
          config: {
            ...newPipeConfig.config,
            when: getSanitizedAdjustmentSignals(signals, allIntents),
          },
        };
      }

      if (!_.isEmpty(newPipeConfig?.config?.signal)) {
        const signals = newPipeConfig?.config?.signal;

        newPipeConfig = {
          ...newPipeConfig,
          config: {
            ...newPipeConfig.config,
            signal: getSanitizedAdjustmentSignals(signals, allIntents),
          },
        };
      }

      return newPipeConfig;
    })
    .value();
};
