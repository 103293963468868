import { fetchHistoricAdminTicks } from 'common/api/adminTicks';
import PaginationFooter from 'common/components/PaginationFooter/index';
import { getDisplayTransactions } from 'common/utils/transactionProfitHelper';
import React, { useEffect, useState } from 'react';
import { Table, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { historicDefaultConfig } from './config';

const defaultFilterConfig = [
  {
    label: 'INDIAVIX',
    value: 'additionalData.INDIAVIX'
  }
];

const INITIAL_PAGE_NO = 0;
const DETAILS_PER_PAGE = 40;

const HistoricDataAdminPanel = () => {
  const [config, setConfig] = useState(historicDefaultConfig);
  const [historicData, setHistoricData] = useState([]);
  const [activeFilter, setActiveFilter] = useState('additionalData.INDIAVIX');
  const [filterConfig, setFilterConfig] = useState(defaultFilterConfig);
  const [filteredData, setFilteredData] = useState([]);
  const isIncludesOptionTicks = activeFilter.includes('optionsTicks');
  const [detailsPerPage] = useState(DETAILS_PER_PAGE);
  const [currentPageIndex, setCurrentPageIndex] = useState(INITIAL_PAGE_NO);
  const detailsToDisplay = getDisplayTransactions(
    filteredData, currentPageIndex, detailsPerPage
  );

  const getAdminHistoricTicks = () => {
    const instrumentSplits = config.instruments.split(', ');
    const instrumentConfig = _.flattenDeep(_.map(instrumentSplits, (instrument) => {
      return [
        {
          label: `${instrument} Month1Price`,
          value: `ticks.${instrument}.month1Price`
        },
        {
          label: `${instrument} Month2Price`,
          value: `ticks.${instrument}.month2Price`
        },
        {
          label: `${instrument} Equity`,
          value: `ticks.${instrument}.equity`
        },
        {
          label: `${instrument} OptionTicks Month1Price`,
          value: `optionsTicks.${instrument}.month1`
        },
        {
          label: `${instrument} OptionTicks Month2Price`,
          value: `optionsTicks.${instrument}.month2`
        },
      ];
    }));

    fetchHistoricAdminTicks(config).then((result) => {
      setHistoricData(result);
      setFilterConfig([...instrumentConfig, ...defaultFilterConfig]);
    });
  };

  useEffect(() => {
    setFilteredData(_.flattenDeep(_.map(historicData, (data) => {
      if (isIncludesOptionTicks) {
        const ticksSet = _.get(data, activeFilter, {});
        const dataSet = _.flattenDeep(_.map(ticksSet, (ticks, key) => {
          return { name: key, ...ticks };
        }));
        return dataSet;
      }

      const time = _.get(data, 'ticks.dateTime', '');
      const ticksSet = _.get(data, activeFilter, {});
      return { time, ...ticksSet };
    })));

    setCurrentPageIndex(INITIAL_PAGE_NO);
  }, [activeFilter, historicData]);

  const renderInstrument = () => {
    return (
      <div className="form-group row">
        <label htmlFor="instruments" className="col-sm-2 col-form-label">Instruments</label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="instruments"
            name="instruments"
            placeholder="NFO:HDFC,NFO:HDFCBANK"
            value={config.instruments}
            onChange={(event) => { setConfig({ ...config, instruments: event.target.value }); }}
          />
        </div>
      </div>
    );
  };

  const renderCheckBox = () => {
    const dataSet = [
      { label: 'Historic data', value: 'historic' },
      { label: 'Current Historic Live', value: 'current-day-hl' }
    ];
    return (
      <div className="form-group row">
        <legend className="col-form-label col-sm-2 pt-0">Data Type</legend>
        {_.map(dataSet, (data, idx) => {
          return (
            <div className="form-check ml-1" key={idx}>
              <input
                className="form-check-input"
                type="radio"
                name="data_type"
                id={data.value}
                value={data.value}
                checked={data.value === config.data_type}
                onChange={(event) => {
                  setConfig({ ...config, data_type: event.target.value });
                }}
              />
              <label className="form-check-label" htmlFor={data.value}>{data.label}</label>
            </div>
          );
        })}
      </div>
    );
  };
  const structuredData = [
    {
      label: 'Year',
      value: config.year,
      placeholder: '2020',
      onChange: (event) => { setConfig({ ...config, year: event.target.value }); },
      isShow: config.data_type === 'historic'
    },
    {
      label: 'Month',
      value: config.month,
      placeholder: '1',
      onChange: (event) => { setConfig({ ...config, month: event.target.value }); },
      isShow: config.data_type === 'historic'
    },
    {
      label: 'Interval',
      value: config.interval,
      placeholder: 'day/minute',
      onChange: (event) => { setConfig({ ...config, interval: event.target.value }); },
      isShow: config.data_type === 'historic'
    },
    {
      label: 'IncludeOptions',
      value: config.includeOptions,
      placeholder: 'true/false',
      onChange: (event) => { setConfig({ ...config, includeOptions: event.target.value }); },
      isShow: config.data_type === 'historic'
    },
    {
      label: 'isWeeklyOptions',
      value: config.isWeeklyOptions,
      placeholder: 'true/false',
      onChange: (event) => { setConfig({ ...config, isWeeklyOptions: event.target.value }); },
      isShow: config.data_type === 'historic'
    },
    {
      label: 'includeNextExpiry',
      value: config.includeNextExpiry,
      placeholder: 'true/false',
      onChange: (event) => { setConfig({ ...config, includeNextExpiry: event.target.value }); },
      isShow: config.data_type === 'historic'
    },
    {
      label: 'includeEquity',
      value: config.includeEquity,
      placeholder: 'true/false',
      onChange: (event) => { setConfig({ ...config, includeEquity: event.target.value }); },
      isShow: true
    },
    {
      label: 'includeIndiaVix',
      value: config.includeIndiaVix,
      placeholder: 'true/false',
      onChange: (event) => { setConfig({ ...config, includeIndiaVix: event.target.value }); },
      isShow: true
    }
  ];

  const renderStructuredData = (data, idx) => {
    if (!data.isShow) return null;

    return (
      <div className="form-group row historic-data-fields" key={idx}>
        <label htmlFor="interval" className="col-sm-2 col-form-label">{data.label}</label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id={data.label}
            name={data.label}
            placeholder={data.placeholder}
            value={data.value}
            onChange={data.onChange}
          />
        </div>
      </div>
    );
  };

  const renderViewHistoricData = () => {
    if (_.isEmpty(historicData)) {
      return <div className="invalid-feedback d-block text-center mt-3">No Backend Data Present</div>;
    }

    return (
      <>
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th scope="col">S No</th>
              <th scope="col">{isIncludesOptionTicks ? 'Name' : 'Time'}</th>
              <th scope="col">Close</th>
              <th scope="col">High</th>
              <th scope="col">Low</th>
              <th scope="col">Open</th>
              <th scope="col">Open Interest</th>
              <th scope="col">Volume</th>
            </tr>
          </thead>
          <tbody>
            {_.map(detailsToDisplay, (tick, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{isIncludesOptionTicks ? _.get(tick, 'name', '-') : _.get(tick, 'time', '-')}</td>
                  <td>{_.get(tick, 'close', '-')}</td>
                  <td>{_.get(tick, 'high', '-')}</td>
                  <td>{_.get(tick, 'low', '-')}</td>
                  <td>{_.get(tick, 'open', '-')}</td>
                  <td>{_.get(tick, 'openInterest', '-')}</td>
                  <td>{_.get(tick, 'volume', '-')}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationFooter
          total={filteredData}
          currentPageIndex={currentPageIndex}
          onCurrentPageChange={setCurrentPageIndex}
          perPage={detailsPerPage}
        />
      </>
    );
  };

  const renderFilterOptions = () => {
    const options = _.map(filterConfig, ({ label, value }) => (
      <ToggleButton
        bsPrefix="btn btn-outline-primary btn-lightness"
        value={value}
        key={value}
      >
        {label}
      </ToggleButton>
    ));

    return (
      <ToggleButtonGroup
        bsPrefix="btn-group btn-group-toggle"
        type="radio"
        name="filterByOptions"
        value={activeFilter}
        onChange={(value) => setActiveFilter(value)}
      >
        {options}
      </ToggleButtonGroup>
    );
  };
  return (
    <div className="container mt-5">
      <form id="historic-ticks-form">
        {renderInstrument()}
        {renderCheckBox()}
        {_.map(structuredData, renderStructuredData)}

        <div className="btn btn-primary" onClick={() => getAdminHistoricTicks()}>
          View Historic Data
        </div>
      </form>
      <div className="mt-3">
        {renderFilterOptions()}
      </div>

      {renderViewHistoricData()}
      <br />

      <div className="card mt-4 bg-dark text-white hidden-for-future">
        <div className="card-body">
          <h5 className="card-title">JSON</h5>
          <pre className="well">
            <code className="ticks-viewer text-white">
              {JSON.stringify(historicData.slice(0, 20), null, 2)}
            </code>
          </pre>
        </div>
      </div>
    </div>
  );
};

HistoricDataAdminPanel.propTypes = {};

export default HistoricDataAdminPanel;
