import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const propTypes = {
  templateConfig: PropTypes.shape({}).isRequired,
  templateName: PropTypes.string.isRequired,
  instruments: PropTypes.arrayOf(PropTypes.array).isRequired,
  timeValidate: PropTypes.func,
  segment: PropTypes.string.isRequired
};

const defaultProps = {
  timeValidate: () => { }
};

const TemplateFooter = ({
  templateConfig, templateName, instruments, timeValidate, segment
}) => {
  const config = {
    ...templateConfig,
    instruments,
    segment
  };

  const navigateAdvanceBuilder = () => {
    let url = 'runs/new?is_basic_builder=false&';
    url += `templateName=${templateName}&`;
    url += `template=${encodeURIComponent(JSON.stringify(config))}&locale=en`;
    window.location.href = url;
  };

  const toAdvancedBuilder = () => {
    if (_.includes(['intradyStraddle', 'ironFly'], templateName)) {
      const { time } = config;
      const MARKET_START_TIME = moment('9:14', 'h:mm k');
      const MARKET_END_TIME = moment('15:29', 'h:mm k');
      const minTime = moment(time, 'h:mm k');
      if (moment(minTime, 'h:mm k').isBetween(MARKET_START_TIME, MARKET_END_TIME)) navigateAdvanceBuilder();
      else timeValidate();
    } else navigateAdvanceBuilder();
  };

  return (
    <div className="d-flex py-1">
      <button
        className="ml-auto btn btn-link d-flex align-items-center track"
        data-track-category="Ready Made Strategies"
        data-track-action={templateName}
        data-track-label="Ready Made Strategies"
        type="button"
        onClick={() => toAdvancedBuilder()}
      >
        Continue
        <i className="material-icons-outlined tx-16 ml-1">
          arrow_forward_ios
        </i>
      </button>
    </div>
  );
};

TemplateFooter.defaultProps = defaultProps;
TemplateFooter.propTypes = propTypes;

export default TemplateFooter;
