import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MoneyDisplay from 'common/components/MoneyDisplay';

const propTypes = {
  overAllProfit: PropTypes.number,
};

const defaultProps = {
  overAllProfit: 0
};

const OverallTransactionProfit = ({
  overAllProfit
}) => {
  const renderProfit = () => {
    if (!overAllProfit) return null;

    return (
      <div className="over-all-profit mr-2">
        <MoneyDisplay shouldColor shouldAvoidDecimals>{overAllProfit}</MoneyDisplay>
      </div>
    );
  };

  return (
    <div className="overall-transaction-profit">
      {renderProfit()}
    </div>
  );
};

OverallTransactionProfit.propTypes = propTypes;
OverallTransactionProfit.defaultProps = defaultProps;

export default memo(OverallTransactionProfit);
