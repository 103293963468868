import React from 'react';
import {
  FIXED_AMOUNT, REQUEST_TYPE
} from 'common/constants/index';
import { strategyPropTypes } from 'common/propTypes';
import PaymentButton from '../Strategy/PaymentButton';
import DeployStrategyButton from '../Strategy/DeployStrategyButton';
import RequestTypeButton from '../Strategy/RequestTypeButton';
import AccessTill from '../Strategy/AccessTill';
// import RiskCategory from '../Strategy/RiskCategory';
import StrategyFreeButton from '../Strategy/StrategyFreeButton';

const propTypes = {
  strategy: strategyPropTypes.isRequired
};

const defaultProps = {};

const Footer = ({ strategy }) => {
  const {
    // risk_category: riskCategory,
    user_id: userId
  } = strategy;
  const currentUser = _.get(strategy, 'currentUser', {});
  const currentUserId = _.get(currentUser, 'id', null);
  const isStrategyBelongsToUser = userId === currentUserId;
  const purchaseType = _.get(strategy, 'purchase_type', '');
  const strategyAccess = _.get(strategy, 'strategy_access', {});
  const isEmptyStrategyAccess = _.isEmpty(strategyAccess); // for initial users
  const status = _.get(strategyAccess, 'status', '');

  const renderConditionBasedButton = () => {
    if (isStrategyBelongsToUser) return null;
    if (purchaseType === FIXED_AMOUNT) return <PaymentButton strategy={strategy} />;
    if (purchaseType === REQUEST_TYPE) return <RequestTypeButton strategy={strategy} />;

    return <StrategyFreeButton />;
  };

  return (
    <div className="strategy-card-footer">
      <div className="accesstill-and-risk">
        <AccessTill
          isEmptyStrategyAccess={isEmptyStrategyAccess}
          status={status}
          purchaseType={purchaseType}
          strategyAccess={strategyAccess}
        />
        {/* <RiskCategory riskCategory={riskCategory} /> */}
      </div>

      <div className="strategy-action">
        {renderConditionBasedButton()}
        <DeployStrategyButton strategy={strategy} />
      </div>
      {purchaseType === FIXED_AMOUNT && (
      <div className="excluding-gst">
        <i className="tx-12 material-icons-outlined">task_alt</i>
        Excluding GST
      </div>
      )}

    </div>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
