import { getMeetStatus } from 'ui/Meeting/helper';

export const getUpcomingEvent = (meetConfigs) => {
  const filterUpComingEvents = _.filter(meetConfigs, (meetConfig) => {
    const { date, startTime, endTime } = meetConfig;
    const { status } = getMeetStatus(date, startTime, endTime);
    return status !== 'Completed';
  });

  // only sort by date only

  // if there is 2 to 3 meeting at same day.. sortBy is not handle ?
  // there is only one meeting perDay
  const sortByDate = _.sortBy(filterUpComingEvents, (event) => { return _.get(event, 'date'); });
  if (!_.isEmpty(sortByDate)) return _.head(sortByDate);

  return {};
};

export const languageOptions = [
  { label: 'Choose...', value: '' },
  { label: 'English', value: 'english' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Tamil', value: 'tamil' }
];
