export const getDisplayRows = (rows, currentPageIndex, perIndex) => {
  if (_.isEmpty(rows)) return [];

  const totalRows = _.size(rows);
  const startOfRange = perIndex * currentPageIndex;
  const endOfRange = startOfRange + perIndex < totalRows
    ? startOfRange + perIndex
    : totalRows;
  const range = _.range(startOfRange, endOfRange);

  return _.flattenDeep(_.map(range, (idx) => _.get(rows, idx, [])));
};
