import React, { useEffect, useState } from 'react';
import { getRuns } from 'common/api/run';
import classNames from 'classnames';
import moment from 'moment';
import MoneyDisplay from 'common/components/MoneyDisplay';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';
import LoadingSpinner from 'common/components/LoadingSpinner';

const propTypes = {};

const defaultProps = { currentUserDetails: {} };

const SHOW_COUNT = 8;

const strategyConfig = [
  { label: 'Your Strategies', value: 'yourStrategies' },
  { label: 'Sample Strategies', value: 'marketPlaceStrategies' },
  { label: 'All', value: 'all' }
];

const DashboardDeployedStrategies = () => {
  const oneIndentation = ' ';
  const [deployedStrategies, setDeployedStrategies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeStrategy, setActiveStrategy] = useState('all');
  const activeStrategies = () => {
    if (activeStrategy === 'marketPlaceStrategies') {
      return _.filter(deployedStrategies,
        ({ is_individual_deployed_run: isIndividualDeployedrun }) => { return !isIndividualDeployedrun; });
    }

    if (activeStrategy === 'yourStrategies') {
      return _.filter(deployedStrategies,
        ({ is_individual_deployed_run: isIndividualDeployedrun }) => { return isIndividualDeployedrun; });
    }

    return deployedStrategies;
  };
  const isHideMoreStrategiesButton = _.size(activeStrategies()) < SHOW_COUNT;

  const showStrategies = isExpanded ? activeStrategies() : _.take(activeStrategies(), SHOW_COUNT);

  useEffect(() => {
    const { isUserSignedIn } = window;
    if (!isUserSignedIn) { setIsLoading(false); } else {
      getRuns({ params: { offsetRunId: 0, locale: I18n.locale, filter_type: 'running_live_runs' } })
        .then((resultRuns) => {
          setDeployedStrategies(resultRuns);
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => { setIsExpanded(false); }, [activeStrategy]);

  const renderShowMoreStrategiesButton = () => {
    if (isHideMoreStrategiesButton || isExpanded) { return null; }

    return (
      <div className="text-center mt-4">
        <button
          type="button"
          className="btn btn-sm btn-primary btn-pill"
          onClick={() => { setIsExpanded(true); }}
        >
          More Strategies
        </button>
      </div>
    );
  };

  const renderNoStrategiesView = (isHideContent = false) => {
    const normalContent = "You don't have any deployed strategies";
    const marketPlaceContent = "You don't have any marketplace strategies";
    const isMarketPlaceNoStrategyView = activeStrategy === 'marketPlaceStrategies';
    const actionClassName = classNames('actions-footer', {
      mpaction: isMarketPlaceNoStrategyView
    });

    return (
      <div className="status-wrapper">
        <div className="no-strategies-info text-center">
          <img src="/no-strategies.svg" alt="No Strategies" />
          {!isHideContent && (
          <span className="text-muted tx-12">
            {isMarketPlaceNoStrategyView ? marketPlaceContent : normalContent}
          </span>
          )}
          {oneIndentation}
          <div className={actionClassName}>
            {!isMarketPlaceNoStrategyView && (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary btn-pill"
                  onClick={() => { window.location.href = '/runs/new?locale=en'; }}
                >
                  Create your strategy
                </button>
                <span className="font-weight-medium text-muted tx-12">or</span>
              </>
            )}
            <button
              type="button"
              className="btn btn-sm btn-outline-primary btn-pill"
              onClick={() => { window.location.href = '/strategies?locale=en'; }}
            >
              Explore Sample Strategies
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderHeaders = () => {
    return (
      <div className="d-flex">
        <div className="btn-group mt-sm-0 ml-md-auto" role="group" aria-label="Basic example">
          {_.map(strategyConfig, (config, key) => {
            const { label, value } = config;
            const btnClassName = classNames('btn btn-sm btn-outline-primary btn-lightness', {
              active: value === activeStrategy
            });

            return (
              <button
                type="button"
                key={key}
                onClick={() => setActiveStrategy(value)}
                className={btnClassName}
              >
                {label}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDeployedStrategies = (run, key) => {
    const {
      is_individual_deployed_run: isIndividualDeployedrun,
      id, display_name: displayName, created_at: createdAt, summary,
      order_type: orderType
    } = run;

    const instrumentGroups = _.get(run, 'instrument_groups', []);
    const profit = _.get(summary, 'profit', 0);
    const parentClassName = classNames('strategies-card', { 'market-strategies': !isIndividualDeployedrun });

    return (
      <div className={parentClassName} key={key}>
        <div className="run-list-details">
          <a href={`runs/${id}/?locale=en`} className="run-details">
            <div className="strategies-head">
              <h6 className="text-truncate w-100">
                {displayName || id}
              </h6>
              <small className="text-muted">
                Created At -
                {oneIndentation}
                {moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
              </small>
            </div>

            <div className="qd-inner mb-auto">
              <div>
                <small className="text-muted">Instruments</small>
                <h6><InstrumentGroupsSentence instrumentGroups={instrumentGroups} /></h6>
              </div>

              <div>
                <small className="text-muted">Order type</small>
                <h6>{orderType}</h6>
              </div>

              <div>
                <small className="text-muted">Profit &amp; Loss</small>
                <h6 className="d-flex font-weight-medium">
                  <MoneyDisplay shouldAvoidDecimals shouldColor>{profit}</MoneyDisplay>
                </h6>
              </div>

            </div>
          </a>
        </div>
      </div>

    );
  };

  const renderEmptyCardToExplore = () => {
    if (!isHideMoreStrategiesButton) return null;

    return (
      <div className="create-strategies-box">
        {renderNoStrategiesView(true)}
      </div>
    );
  };

  const renderView = () => {
    if (_.isEmpty(deployedStrategies)) return renderNoStrategiesView();

    return (
      <div>
        {renderHeaders()}
        {_.isEmpty(showStrategies) ? renderNoStrategiesView()
          : (
            <div className="grid-view">
              {_.map(showStrategies, renderDeployedStrategies)}
              {renderEmptyCardToExplore()}
            </div>
          )}
        {renderShowMoreStrategiesButton()}
      </div>
    );
  };

  return (
    <div className="deployed-strategies">
      <LoadingSpinner isLoading={isLoading}>
        {renderView()}
      </LoadingSpinner>
    </div>
  );
};

DashboardDeployedStrategies.propTypes = propTypes;
DashboardDeployedStrategies.defaultProps = defaultProps;

export default DashboardDeployedStrategies;
