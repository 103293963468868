import React from 'react';
import PropTypes from 'prop-types';
import { DOMAINS } from 'common/constants/index';
import { automaticSupportedBrokers, oneClickSupportedBrokers } from 'common/brokerConfigs/config';
import { getContent } from 'ui/Plans/helper';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import HoverMessage from 'common/components/HoverMessage';

const propTypes = {
  type: PropTypes.string.isRequired
};

const defaultProps = {};

const HoveMessagedBasedOnType = ({ type }) => {
  const { currentDomain } = window;

  const renderNotes = (notesHtml) => {
    return (
      <HoverMessage message={notesHtml}>
        <span className="material-icons-outlined help ml-1">info</span>
      </HoverMessage>
    );
  };

  const isDefaultDomain = currentDomain === DOMAINS.DEFAULT_DOMAIN;
  switch (type) {
    case 'backTestCredits':
      const backtestNotes = <span className="notes">(1 credit = 1 year 1 instrument backtest)</span>;

      return renderNotes(backtestNotes);
    case 'liveDeploymentCredits':
      const liveNotes = (
        <span className="notes">
          Maximum live deployments at any time.
          (Only currently running live deployments are count. Stopped live deployments are not
          considered.)
        </span>
      );

      return renderNotes(liveNotes);
    case 'oneClickBrokerOrderIntegration':
      const oneClickNotes = isDefaultDomain && (
        <span className="notes">
          (
          {getContent(oneClickSupportedBrokers)}
          )
        </span>
      );

      return renderNotes(oneClickNotes);
    case 'automaticOrderWithBrokers':
      const automaticNotes = isDefaultDomain && (
        <span className="notes">
          (
          {getContent(automaticSupportedBrokers)}
          )
        </span>
      );

      return renderNotes(automaticNotes);

    case 'quantmanMentorShip':
      const quantmanMentorShip = (
        <span className="notes">
          Quantman will issue you a certificate upon completion of your mentorship.
        </span>
      );
      return renderNotes(quantmanMentorShip);
    default:
      return <></>;
  }
};

HoveMessagedBasedOnType.propTypes = propTypes;
HoveMessagedBasedOnType.defaultProps = defaultProps;

export default withErrorBoundary(HoveMessagedBasedOnType);
