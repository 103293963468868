import _ from 'lodash';
import { INTENT_TYPES } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from './config';

const isDisplay = window?.isAdmin;

const schemasToDisplayNameMap = {
  exitTime: {
    displayName: 'Exit Time',
    isDisplay,
    type: schemaTypes.time,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand,
      schemaShouldShowConfigTypes.intentValuePath
    ]
  },
  exitTimeFromNow: {
    displayName: 'Duration After exit',
    isDisplay,
    type: schemaTypes.duration,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand,
      schemaShouldShowConfigTypes.intentValuePath
    ]
  },
  hasExited: {
    displayName: 'Already Exited',
    isDisplay,
    type: schemaTypes.boolean,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand,
      schemaShouldShowConfigTypes.intentValuePath
    ]
  },
  lossAmount: {
    displayName: 'Loss Amount',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand,
      schemaShouldShowConfigTypes.intentValuePath
    ]
  },
  gainAmount: {
    displayName: 'Profit Amount',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand,
      schemaShouldShowConfigTypes.intentValuePath
    ]
  },
  lossPoints: {
    displayName: 'Loss Points',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand,
      schemaShouldShowConfigTypes.intentValuePath
    ]
  },
  gainPoints: {
    displayName: 'Profit Points',
    isDisplay,
    type: schemaTypes.number,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.adjustmentRightOperand,
      schemaShouldShowConfigTypes.intentValuePath
    ]
  }
};

const getConfigs = (basketIntent, extraArgs = {}) => {
  return _.map(schemasToDisplayNameMap, (schemaConfig, schemaName) => {
    const {
      displayName, isDisplay: isShow, type, shouldShowConfigs
    } = schemaConfig;
    if (!isShow) return null;
    const { label } = basketIntent.identifier;

    return {
      name: `basket_${basketIntent.identifier.label}_${schemaName}`,
      type,
      tagConfig: { tag: `Basket ${label}`, color: '#FFA500' },
      category: 'basket',
      label: displayName,
      shouldShowConfigs,
      priority: 111,
      schemaName,
      ...extraArgs,
    };
  });
};

export const buildBasketOutputSchema = (cases) => {
  if (_.isEmpty(cases)) return null;

  return _.chain(cases)
    .map((caseConfig, caseIndex) => {
      const { entry: { intents: baseIntents }, adjustments } = caseConfig;

      return [
        _.chain(baseIntents)
          .filter(({ type }) => type === INTENT_TYPES.OpenBasket)
          .map((basketIntent) => {
            return getConfigs(basketIntent, { caseIndex });
          })
          .value(),
        _.chain(adjustments)
          .map((adjustment, adjustmentIndex) => {
            const basketIntents = _.filter(
              adjustment?.intents ?? [], ({ type }) => type === INTENT_TYPES.OpenBasket
            );
            return _.map(basketIntents, (basketIntent) => {
              return getConfigs(basketIntent, { caseIndex, adjustmentIndex });
            });
          })
          .value()
      ];
    })
    .compact()
    .flattenDeep()
    .value();
};
