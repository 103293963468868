import React, {
  useEffect, memo, useState, useRef
} from 'react';
import { getOrderProfit } from 'common/utils/transactionProfitHelper';
import {
  redisLiveDataSubscribedChannel, redisLiveDataUnSubscribedChannel
} from 'common/api/redisLiveDataChannel';
import PropTypes from 'prop-types';
import { onLegForceExit } from 'common/api/run';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { getChannelName } from './helper';
import { pairedPropTypes } from './pairedPropTypes';
import TableLegRow from './TableLegRow';
import CardLeg from './CardLeg';

const propTypes = {
  pairedOrder: pairedPropTypes,
  isCardView: PropTypes.bool,
  onUpdatePairedOrder: PropTypes.func,
  isShowIndividualSquareOff: PropTypes.bool
};

const defaultProps = {
  pairedOrder: {},
  isCardView: false,
  onUpdatePairedOrder: () => { },
  isShowIndividualSquareOff: false
};

const PairedOrderData = ({
  pairedOrder, isCardView, onUpdatePairedOrder, isShowIndividualSquareOff
}) => {
  const {
    entryOrderId, jobId, entryType, quantity, entryPrice,
    tradingSymbol, shouldCalculateUnrealizedProfit, exitPrice
  } = pairedOrder;
  const [priceData, setPriceData] = useState(exitPrice);
  const subscribeRef = useRef(null);

  useEffect(() => {
    onUpdatePairedOrder({
      ...pairedOrder, orderProfit: getOrderProfit(entryType, entryPrice, priceData, quantity)
    });
  }, [priceData]);

  const channelName = getChannelName(tradingSymbol);

  const onReceivedData = (data) => {
    const price = _.get(data, 'close', null);

    if (price) { setPriceData(_.round(price, 2)); }
  };

  useEffect(() => {
    if (shouldCalculateUnrealizedProfit) {
      subscribeRef.current = redisLiveDataSubscribedChannel({
        channelName,
        onDataReceive: (data) => { onReceivedData(data); }
      });
    } else if (subscribeRef.current) {
      redisLiveDataUnSubscribedChannel(subscribeRef.current);
    }
  }, [shouldCalculateUnrealizedProfit]);

  const ComponentName = isCardView ? CardLeg : TableLegRow;

  const onForceExit = (successCallback = () => { }) => {
    onLegForceExit(jobId, entryOrderId).then(() => {
      reactToastify('Close position requested succesfully', TOASTIFY_TYPES.SUCCESS);
      successCallback();
    }).catch(() => {
      reactToastify('Something went wrong, please try again', TOASTIFY_TYPES.ERROR);
    });
  };

  return (
    <ComponentName
      pairedOrder={pairedOrder}
      priceData={priceData}
      onForceExit={onForceExit}
      isShowIndividualSquareOff={isShowIndividualSquareOff}
    />
  );
};

PairedOrderData.propTypes = propTypes;
PairedOrderData.defaultProps = defaultProps;

export default memo(PairedOrderData);
