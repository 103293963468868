import React, { useState } from 'react';
import InstrumentGroupBuilder from 'common/components/InstrumentGroupBuilder/index';
import classNames from 'classnames';
import TemplateFooter from '../../TemplateFooter';
import {
  intervalTemplates, comparisonTypesTemplates, handleErrors
} from '../../config';
import Select from '../../CommonComponents/Select';
import Input from '../../CommonComponents/Input';
import TemplateSentence from '../../CommonComponents/TemplateSentence';
import LabelTags from '../../CommonComponents/LabelTags';
import {
  quantConfigConversionStAndRsi,
  superTrendAndRsiDefaultConfig, SUPER_TREND_AND_RSI
} from './helper';

export const templateName = SUPER_TREND_AND_RSI;

const defaultConfig = superTrendAndRsiDefaultConfig;

export const Builder = (props) => {
  const segments = _.get(props, 'segments', []);

  const propSegment = _.get(props, 'segment');
  const [segment, setSegment] = useState(propSegment);
  const [templateConfig, setTemplateConfig] = useState(defaultConfig);
  const [instruments, setIntruments] = useState([]);
  const isNew = false;
  const oneIndentation = ' ';
  const onAddInstrument = (instrument) => {
    setIntruments([...instruments, instrument]);
  };

  const onDeleteAddedInstrument = (instrumentIndex) => {
    const newInstruments = _.filter(instruments, (instrument, index) => instrumentIndex !== index);
    setIntruments(newInstruments);
  };

  const colClassName = classNames('', {
    '': isNew
  });

  const cardClassName = classNames('card h-100', {
    'new-strategie': isNew
  });

  const handleStrategyChange = (key, value) => {
    setTemplateConfig({
      ...templateConfig,
      [key]: value
    });
  };

  return (
    <>
      <div className={colClassName}>
        <div className={cardClassName}>
          <div className="card-header">
            <h6 className="font-weight-bold">
              Super Trend + RSI
            </h6>
            <div>
              {oneIndentation}
              <LabelTags
                label="positional"
                className="positional"
              />
              {oneIndentation}
              <LabelTags
                label="directional"
                className="directional"
              />
            </div>
          </div>

          <div className="card-body">
            <InstrumentGroupBuilder
              isInValid={false}
              instrumentGroups={instruments}
              maxInstrumentsInGroup={1}
              onAddInstrumentGroup={(newInstrumentGroup) => onAddInstrument(newInstrumentGroup)}
              onRemoveInstrumentGroup={(indexToRemove) => onDeleteAddedInstrument(indexToRemove)}
              titleMdSize="col-md-3 col-lg-2 p-0"
              inputMdSize="col-md-9 col-lg-10 p-0"
              segment={segment}
              onChangeSegment={(newSegment) => setSegment(newSegment)}
              segments={segments}
            />

            <div className="technical-indicators">
              <div className="technical-order">
                When close price
                <div>
                  <Select
                    value={templateConfig.comparisonType}
                    onChange={(event) => handleStrategyChange('comparisonType', event.target.value)}
                    optionItems={comparisonTypesTemplates}
                  />
                </div>
                <div>
                  <Input
                    value={templateConfig.period}
                    onChange={(event) => handleStrategyChange('period', handleErrors(event.target.value))}
                  />
                </div>
                Period
                <div>
                  <Select
                    value={templateConfig.interval}
                    onChange={(event) => handleStrategyChange('interval', event.target.value)}
                    optionItems={intervalTemplates}
                  />
                </div>
                interval
                <div>
                  <Input
                    value={templateConfig.multiplier}
                    onChange={(event) => handleStrategyChange('multiplier', event.target.value)}
                  />
                </div>
                Multiplier
              </div>
              and
              <div className="technical-order">
                When
                <div>
                  <Input
                    value={templateConfig.periodRsi}
                    onChange={(event) => handleStrategyChange('periodRsi', handleErrors(event.target.value))}
                  />
                </div>
                period RSI
                <div>
                  <Select
                    value={templateConfig.comparisonTypeRsi}
                    onChange={(event) => handleStrategyChange('comparisonTypeRsi', event.target.value)}
                    optionItems={comparisonTypesTemplates}
                  />
                </div>
                55
              </div>

              <div className="template-sentence-wrapper">
                <TemplateSentence
                  optionType={{ value: templateConfig.optionType, key: 'optionType' }}
                  strikeIndex={{ value: templateConfig.strikeIndex, key: 'strikeIndex' }}
                  actiontype={{ value: templateConfig.actiontype, key: 'actiontype' }}
                  stopLoss={{ value: templateConfig.stopLoss, key: 'stopLoss' }}
                  stopGain={{ value: templateConfig.stopGain, key: 'stopGain' }}
                  handleStrategyChange={handleStrategyChange}
                  quantity={{ value: templateConfig.quantity, key: 'quantity' }}
                  stopGainType={{ value: templateConfig.stopGainType, key: 'stopGainType' }}
                  stopLossType={{ value: templateConfig.stopLossType, key: 'stopLossType' }}
                  instruments={instruments}
                />
              </div>
              and Exit when price crosses below Super Trend or
              RSI crosses below 40
            </div>
          </div>

          <TemplateFooter
            templateConfig={templateConfig}
            templateName={templateName}
            instruments={instruments}
            segment={segment}
          />
        </div>
      </div>
    </>
  );
};

export const toQuantConfig = (config) => {
  return quantConfigConversionStAndRsi(config);
};
