import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  runPropTypes, runjobsProps, instrumentGroupsPropTypes, userPropTypes
} from 'common/propTypes';
import subscribeChannel from 'common/api/subscribeChannel';
import { RUN_TYPES } from 'common/constants';
import EstimatedTimeCountDown from 'common/components/EstimatedTimeCountDown';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { RUN_STATUS_CODES } from 'common/configs/runStatus';
import { removeExchangeFromInstrument } from 'common/utils/InstrumentUtils';
import OrderStore from './OrderStore/index';
import RunShowDetails from './components/RunShowDetails';

const propTypes = {
  run: runPropTypes.isRequired,
  runJobs: runjobsProps.isRequired,
  currentUserDefaultFolderId: PropTypes.number,
  instrumentGroups: instrumentGroupsPropTypes,
  strategyDetails: PropTypes.shape({}),
  currentUser: userPropTypes
};
const defaultProps = {
  currentUserDefaultFolderId: null,
  instrumentGroups: [],
  strategyDetails: {},
  currentUser: ''
};

const getShowByOptions = (instrumentGroups) => { // options for multiple instruments
  const initialObj = { displayName: 'All', value: 'all' };
  const showArray = _.map(instrumentGroups, (value, key) => {
    const sanitizeInstrumentGroup = _.map(instrumentGroups[key],
      (instrument) => removeExchangeFromInstrument(instrument));

    return ({
      displayName: _.join(sanitizeInstrumentGroup, '-') || 'All',
      value: `${key}`
    });
  });

  return [...showArray, initialObj];
};

const RunShow = (props) => {
  const {
    run, runJobs, currentUserDefaultFolderId,
    instrumentGroups, strategyDetails, currentUser,
    run: {
      id, run_type: runType, status, order_type: orderType
    }
  } = props;
  const { ERRORED, COMPLETED } = RUN_STATUS_CODES;
  const { historic } = RUN_TYPES;

  const [showByOptions] = useState(getShowByOptions(instrumentGroups));
  const [showBy, setShowBy] = useState('all');
  const reloadUrl = `/runs/${id}?locale=${I18n.locale}`;

  const [shouldRenderLoadingSpinner, setShouldRenderLoadingSpinner] = useState(
    (runType === historic) && !_.includes([ERRORED, COMPLETED], status)
  );
  const jobIds = _.map(runJobs, 'id').sort();
  const shouldShowFilter = runType === historic;
  // showByOptions.length > 2;

  const runShowDetailsProps = { // props for runshowDetailsPage
    ...props,
    shouldRenderLoadingSpinner,
    jobIds,
    instrumentGroups,
    shouldShowFilter,
    currentUserDefaultFolderId,
    strategyDetails,
    currentUser
  };

  const onStatusChange = ({ status: newStatus }) => {
    if (newStatus === COMPLETED || newStatus === ERRORED) {
      window.location.reload();
    }
  };

  const initializeStatusUpdateListner = () => {
    subscribeChannel({
      roomId: `${id}-progress`,
      channelName: 'Run Show',
      onDataReceive: onStatusChange
    });
  };

  useEffect(() => {
    if (shouldRenderLoadingSpinner) initializeStatusUpdateListner();
  }, []);

  if (shouldRenderLoadingSpinner) { // wait for run to status update ? untill it showing loading timing
    return (
      <EstimatedTimeCountDown
        run={run}
        reloadUrl={reloadUrl}
        onEtaCompletion={() => setShouldRenderLoadingSpinner(false)}
      />
    );
  }

  const renderFilterOptions = () => {
    const options = _.map(showByOptions, ({ displayName, value }) => (
      <ToggleButton
        bsPrefix="btn btn-outline-primary btn-lightness btn-sm"
        value={value}
        key={value}
      >
        {displayName}
      </ToggleButton>
    ));

    return (
      <ToggleButtonGroup
        bsPrefix="btn-group btn-group-toggle d-inline"
        type="radio"
        name="filterByOptions"
        value={showBy}
        onChange={(value) => setShowBy(value)}
      >
        {options}
      </ToggleButtonGroup>
    );
  };

  return (
    <OrderStore
      runIds={[id]}
      jobIds={[jobIds]}
      runType={runType}
      showBy={showBy}
      orderType={orderType}
      run={run}
    >
      <RunShowDetails {...runShowDetailsProps} renderFilterOptions={() => renderFilterOptions()} />
    </OrderStore>
  );
};

RunShow.propTypes = propTypes;
RunShow.defaultProps = defaultProps;

export default RunShow;
