import React from 'react';
import PropTypes from 'prop-types';
import { getOptionConfigStrategies } from 'modules/TransactionsBuilder/configs';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const propTypes = {
  optionSubStrategy: PropTypes.string.isRequired,
  optionStrategy: PropTypes.string.isRequired,
  onStrategyUpdate: PropTypes.func.isRequired,
  strikeBasedOn: PropTypes.string.isRequired,
};
const defaultProps = {};

const OptionSubStrategySelector = (props) => {
  const {
    optionSubStrategy, optionStrategy, onStrategyUpdate, strikeBasedOn
  } = props;
  const strategies = getOptionConfigStrategies(strikeBasedOn);

  const onSubStrategyChange = (newSubStrategy) => {
    const newOptionConfigs = _.get(strategies, [optionStrategy, newSubStrategy, 'optionConfigs']);
    const newMode = { optionSubStrategy: newSubStrategy };

    onStrategyUpdate(newMode, newOptionConfigs);
  };

  const renderSubStrategyOptions = _.map(strategies[optionStrategy], (subStrategy, subStrategyKey) => (
    <ToggleButton
      key={subStrategyKey}
      bsPrefix="btn btn-outline-primary btn-lightness option-config-btn"
      value={subStrategyKey}
    >
      <img src={subStrategy.icon} alt="" />
      {_.startCase(_.get(subStrategy, 'name', ''))}
    </ToggleButton>
  ));

  return (
    <ToggleButtonGroup
      bsPrefix="btn-group btn-group-toggle options-config-btn-group"
      type="radio"
      name="optionStrategySelector"
      value={optionSubStrategy}
      onChange={onSubStrategyChange}
    >
      {renderSubStrategyOptions}
    </ToggleButtonGroup>
  );
};

OptionSubStrategySelector.propTypes = propTypes;
OptionSubStrategySelector.defaultProps = defaultProps;

export default OptionSubStrategySelector;
