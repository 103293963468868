export const schemaShouldShowConfigTypes = {
  pipeValuePath: 'pipeValuePath',
  entryLeftOperand: 'entryLeftOperand',
  entryRightOperand: 'entryRightOperand',
  exitLeftOperand: 'exitLeftOperand',
  exitRightOperand: 'exitRightOperand',
  adjustmentLeftOperand: 'adjustmentLeftOperand',
  adjustmentRightOperand: 'adjustmentRightOperand',
  intentValuePath: 'intentValuePath',
};

export const schemaTypes = {
  number: 'number',
  boolean: 'boolean',
  duration: 'duration',
  target: 'target',
  date: 'date',
  time: 'time',
  dayOfWeek: 'dayOfWeek',
  minute: 'minute',
  array: 'array',
  object: 'object',
};
