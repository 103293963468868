import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import moment from 'moment';
import { rightOperandPropTypes } from 'common/propTypes';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import DateSelector from 'common/components/DateSelector';

const propTypes = {
  rightOperand: rightOperandPropTypes.isRequired,
  onChangeRightOperand: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
};

const defaultProps = {};
const Date = (props) => {
  const {
    onChangeRightOperand,
    rightOperand,
    control, errors
  } = props;
  const [DatePickerName] = useState(_.uniqueId('datePicker-'));

  const onDateChange = (newDate, onChange) => {
    onChangeRightOperand(moment(newDate).format('YYYY-MM-DD'));
    onChange(moment(newDate).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (rightOperand) {
      onChangeRightOperand(moment(rightOperand).format('YYYY-MM-DD'));
    } else {
      onChangeRightOperand(moment().format('YYYY-MM-DD'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validatorConfig = {
    validate: (value) => {
      if (!value) return '*Date required';
      return null;
    }
  };

  return (
    <>
      <Controller
        render={({ onChange }) => (
          <DateSelector
            selectedDate={rightOperand ? moment(rightOperand, 'YYYY-MM-DD').toDate() : moment().toDate()}
            onChangeDate={(newRightOperand) => {
              onDateChange(newRightOperand, onChange);
            }}
          />
        )}
        control={control}
        defaultValue={rightOperand}
        name={DatePickerName}
        rules={() => validatorConfig}
      />
      <ErrorMesssage message={errors[DatePickerName]?.message || ''} />
    </>
  );
};

export default memo(Date);

Date.propTypes = propTypes;
Date.defaultProps = defaultProps;
