import React, { memo } from 'react';
import _ from 'lodash';
import SingleInstrumentSelector from 'v2/common/components/SingleInstrumentSelector/index';
import classNames from 'classnames';
import ModeSelector from './ModeSelector';
import useInstrumentDetails from '../../hooks/useInstrumentDetails/index';
import GlobalSegmentSelector from './GlobalSegmentSelector';

const InstrumentDetails = () => {
  const {
    instrumentGroups,
    maxInstrumentsInGroup,
    maxInstrumentGroups,
    isAdvancedMode,
    onUpdateInstrumentGroups,
    onUpdateInstrument,
    onDeleteInstrumentGroup,
    onAddInstumentGroup,
    onChangeMode,
    mode
  } = useInstrumentDetails();

  const renderInstrumentSelector = (instrumentGroupIndex, instrumentIndex, prependLabel = '') => {
    return (
      <SingleInstrumentSelector
        instrument={_.get(instrumentGroups, [instrumentGroupIndex, instrumentIndex], '')}
        prependLabel={prependLabel}
        onUpdateInstrument={(newInstrument) => onUpdateInstrument(
          newInstrument, instrumentGroupIndex, instrumentIndex
        )}
      />
    );
  };

  const renderDeleteBtn = (instrumentGroupIndex) => {
    return (
      <button
        type="button"
        className="btn btn-delete-outline"
        onClick={() => onDeleteInstrumentGroup(instrumentGroupIndex)}
      >
        <i />
        <span className="ml-1 tx-12">Delete Strategy</span>
      </button>
    );
  };

  const renderAddBtn = () => {
    return (
      <button
        type="button"
        className="btn btn-delete-outline"
        onClick={onAddInstumentGroup}
      >
        <span className="material-icons-outlined tx-12 mr-1">add</span>
        <span className="tx-12">Add Strategy</span>
      </button>
    );
  };

  const renderAddOrDeleteBtn = (instrumentGroupIndex) => {
    if (instrumentGroupIndex === 0 && maxInstrumentGroups === 1) {
      return renderAddBtn(instrumentGroupIndex);
    }

    return instrumentGroupIndex === maxInstrumentGroups - 1
      ? renderAddBtn(instrumentGroupIndex)
      : renderDeleteBtn(instrumentGroupIndex);
  };

  const renderMaxInstrumentGroupsSelector = () => {
    if (!isAdvancedMode) return renderInstrumentSelector(0, 0);

    return _.range(0, maxInstrumentGroups)
      .map((instrumentGroupIndex) => {
        return (
          <div className="instrument-strategy-item" key={instrumentGroupIndex}>
            <label className="label_style mb-2 mb-md-0">
              Strategy
              {instrumentGroupIndex + 1}
            </label>
            {_.range(0, maxInstrumentsInGroup).map((instrumentIndex) => {
              return (
                <React.Fragment key={instrumentIndex}>
                  {renderInstrumentSelector(
                    instrumentGroupIndex,
                    instrumentIndex, `Item ${instrumentIndex + 1}`
                  )}
                </React.Fragment>
              );
            })}
            {renderAddOrDeleteBtn(instrumentGroupIndex)}
          </div>
        );
      });
  };

  const renderMaxInstrumentsInGroupSelector = () => {
    return (
      <>
        <div className="d-flex align-items-center strategy">
          <span className="tx-15 font-weight-medium selector-text">
            {!isAdvancedMode ? 'Instrument' : 'Strategy Based on'}
          </span>
          <GlobalSegmentSelector />
        </div>
        {isAdvancedMode && (
          <select
            className="ml-1 select-instrument"
            value={maxInstrumentsInGroup}
            onChange={(event) => onUpdateInstrumentGroups([], event.target.value)}
          >
            {[1, 2].map((value) => {
              return (
                <option value={value} key={value}>
                  {value}
                  {' '}
                  Instrument
                </option>
              );
            })}
          </select>
        )}
      </>
    );
  };

  const flexClassNameModeBased = classNames({ 'd-flex gap-10': !isAdvancedMode });
  const maxInstrumentClassName = classNames('d-flex align-items-center flex-wrap',
    {
      'strategy-container': true,
      'mb-2': isAdvancedMode
    });

  return (
    <div className="instrument_wrap py-3 px-4">
      <div className="d-flex align-items-start justify-content-between">
        <div className="d-flex align-items-start flex-column gap-10">
          <div className={flexClassNameModeBased}>
            <div className={maxInstrumentClassName}>
              {renderMaxInstrumentsInGroupSelector()}
            </div>
            <div className={`${isAdvancedMode ? '' : ''}`}>{renderMaxInstrumentGroupsSelector()}</div>
          </div>
        </div>
        <div className="d-flex flex-column">
          <ModeSelector mode={mode} onChange={onChangeMode} />
        </div>
      </div>
    </div>
  );
};

export default memo(InstrumentDetails);
