import { getDefaultTimeBasedSignalForIntraday, OPTIONS_EXPIRY } from 'modules/QuantBuilder/config/index';
import {
  getOptionConfigStrategies, onValidateStrikeBasedOn, STRIKE_KEYS
} from 'modules/TransactionsBuilder/configs';

export const emptyStrategyBuilderQuantConfig = () => ({
  instrumentGroups: [],
  optionStrikeCalculationBasedOn: STRIKE_KEYS.strikeIndex,
  optionsExpiryCycle: OPTIONS_EXPIRY.monthly,
  optionConfigs: [],
  timeBasedSignal: getDefaultTimeBasedSignalForIntraday()
});

export const strategyBuilderConfig = (strikeKey) => {
  const basedOn = onValidateStrikeBasedOn(strikeKey);
  let strategies = {};

  _.each(getOptionConfigStrategies(basedOn), (category, categoryName) => {
    if (categoryName !== 'custom') {
      _.each(category, (strategy, strategyName) => {
        strategies = {
          ...strategies,
          [strategyName]: {
            ...strategy,
            timeBasedSignal: getDefaultTimeBasedSignalForIntraday()
          }
        };
      });
    }
  });

  return strategies;
};

export const STOPS_KEYS = {
  STOP_GAIN: 'stopGain',
  STOP_LOSS: 'stopLoss',
  STOP_GAIN_PRICE: 'stopGainPrice',
  STOP_LOSS_PRICE: 'stopLossPrice'
};

export const STOPS_VALUE_TYPES = {
  PERCENT: 'percent',
  PRICE: 'price'
};
