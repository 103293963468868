import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
    PropTypes.string
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string
  ])
};

const defaultProps = {
  value: null,
  children: null
};

const ConfigDisplayDetails = ({ title, value, children }) => {
  const isNullValue = (
    (_.isUndefined(value) || value === null)
    && (_.isUndefined(children) || children === null)
  );
  if (isNullValue) return null;

  return (
    <div>
      <label className="col-form-label col-form-label-sm">{title}</label>
      <h6 className="main-head">{children || value}</h6>
    </div>
  );
};

ConfigDisplayDetails.propTypes = propTypes;
ConfigDisplayDetails.defaultProps = defaultProps;

export default ConfigDisplayDetails;
