import React, { useContext } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { runPropTypes } from 'common/propTypes';
import Money from 'common/components/MoneyDisplay';
import SmallInfoCard from 'common/components/SmallInfoCard';
import { RUN_TYPES } from 'common/constants/index';
import { OrderContext } from '../../OrderStore/index';

const propTypes = {
  run: runPropTypes.isRequired
};

const I18N_SCOPE = { scope: 'runs.show' };

const getFormattedDate = (date) => moment(date).format('MMM Do YYYY');

const RunDetail = ({ run }) => {
  const {
    run_type: runType, start_date: startDate, end_date: endDate, order_type: orderType, status
  } = run;
  const { live, historic } = RUN_TYPES;

  const { overallProfit, isLoading } = useContext(OrderContext);

  const profit = isLoading ? 0 : overallProfit;

  const profitLossLabel = profit < 0 ? 'Loss' : 'Profit';
  const profitLossTitle = runType === live
    ? `Completed Transactions ${profitLossLabel}`
    : profitLossLabel;

  // Profit Loss icon
  const profitLossIconClassName = classNames(
    {
      'icons-profit text-success': profitLossLabel === 'Profit',
      'icons-loss text-danger': profitLossLabel === 'Loss'
    }
  );

  const orderTypeClassName = classNames(
    'main-head',
    orderType === 'actual'
      ? 'text-success'
      : 'text-info'
  );

  const orderTypeIconClassName = classNames(
    'icon-order',
    orderType === 'actual'
      ? 'text-success'
      : 'text-info'
  );

  const isDarkThemedStatus = (
    status === 'queued'
      || status === 'hopping'
      || status === 'starting'
      || status === 'paused'
      || status === 'server_stop'
  );

  const statusClassName = classNames(
    'main-head',
    {
      'text-dark': isDarkThemedStatus,
      'text-info': status === 'started',
      'text-success': status === 'completed' || status === 'stopped',
      'text-danger': status === 'errored' || status === 'killed'
    }
  );

  // Status icon
  const statusIconClassName = classNames(
    {
      'icons-queue text-dark': isDarkThemedStatus,
      'icons-start text-info': status === 'started',
      'icons-completed text-success': status === 'completed' || status === 'stopped',
      'icons-error text-danger': status === 'errored' || status === 'killed'
    }
  );

  // Run type Icon
  const renderRunTypeIcon = () => (runType === historic
    ? <i className="icons-history" key="historicIcon" />
    : <i className="icons-live" key="liveIcon" />
  );

  const alteredStatus = status === 'hopping' ? 'starting' : status;

  return (

    <div className="run-info mb-3">
      <SmallInfoCard
        infoAvatar={(
          <div className="info-avatar-custom">
            <i className={profitLossIconClassName} />
          </div>
        )}
        leftHeader={profitLossTitle}
        leftValue={
          isLoading ? <div>---</div> : <Money shouldColor>{profit}</Money>
        }
      />

      <SmallInfoCard
        infoAvatar={(
          <div className="info-avatar-custom">
            <div>{[renderRunTypeIcon()]}</div>
          </div>
        )}
        leftHeader="Run Type"
        leftValue={<div>{[I18n.t(runType, I18N_SCOPE)]}</div>}
      />

      {runType === 'historic'
        ? (
          <SmallInfoCard
            infoAvatar={(
              <div className="info-avatar-custom">
                <span className="material-icons-outlined">date_range</span>
              </div>
            )}
            leftHeader={I18n.t('from', I18N_SCOPE)}
            leftValue={<div>{getFormattedDate(startDate)}</div>}
            rightHeader={I18n.t('to', I18N_SCOPE)}
            rightValue={<div>{getFormattedDate(endDate)}</div>}
          />
        )
        : (
          <SmallInfoCard
            infoAvatar={(
              <div className="info-avatar-custom">
                <i className={orderTypeIconClassName} />
              </div>
            )}
            leftHeader="Order Type"
            leftValue={<div className={orderTypeClassName}>{orderType}</div>}
          />
        )}

      <SmallInfoCard
        infoAvatar={(
          <div className="info-avatar-custom">
            <i className={statusIconClassName} />
          </div>
        )}
        leftHeader={I18n.t('status', I18N_SCOPE)}
        leftValue={<div className={statusClassName}>{_.startCase(alteredStatus)}</div>}
      />
    </div>
  );
};

RunDetail.propTypes = propTypes;

export default RunDetail;
