import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import { getOperators } from 'common/utils/signalConfigUtils';
import TimeSelector from 'common/components/TimeSelector';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { getTimeValidators } from 'common/utils/validators';
import { rightOperandPropTypes } from 'common/propTypes';
import classNames from 'classnames';

const propTypes = {
  leftOperand: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  rightOperand: rightOperandPropTypes.isRequired,
  onRightOperandChange: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  operatorValidator: PropTypes.shape({}).isRequired,
  isHistoricRun: PropTypes.bool.isRequired
};

const defaultProps = {};

const getDropdownOptions = (leftOperand) => _.map(getOperators(leftOperand),
  (value, optionIndex) => (
    <option key={optionIndex} value={value}>{value}</option>
  ));

const TimeOfDay = (props) => {
  const {
    onRightOperandChange,
    operator,
    leftOperand,
    rightOperand,
    onOperatorChange,
    operatorValidator,
    isHistoricRun
  } = props;
  const [operatorFieldName] = useState(_.uniqueId('operator-'));
  const [timePickerName] = useState(_.uniqueId('timePicker-'));
  const { errors, control } = useFormContext();

  const onChangeOperator = (newOperator, onChange) => {
    onOperatorChange(newOperator);
    onChange(newOperator);
  };

  const onTimeChange = (newTime, onChange) => {
    onRightOperandChange(newTime);
    onChange(newTime);
  };
  // TODO: 'is-invalid' is not setting a red border around the input field.
  const operandStyle = classNames('form-control', errors[timePickerName] ? 'is-invalid' : '');

  return (
    <>
      <div className="operator">
        <Controller
          render={({ onChange }) => (
            <Form.Control
              className="custom-select custom-select-sm"
              isInvalid={!_.isEmpty(errors[operatorFieldName])}
              as="select"
              name={operatorFieldName}
              value={operator}
              onChange={(event) => onChangeOperator(event.target.value, onChange)}
            >
              {getDropdownOptions(leftOperand)}
            </Form.Control>
          )}
          control={control}
          defaultValue={operator}
          name={operatorFieldName}
          rules={operatorValidator}
        />
        <ErrorMesssage
          error={errors[operatorFieldName]}
        />
      </div>

      <div className="right-operand">
        <Controller
          render={({ onChange }) => (
            <TimeSelector
              onTimeChange={(newTime) => {
                onTimeChange(newTime, onChange);
              }}
              selectedTime={rightOperand}
              className={operandStyle}
              format="HH:mm:ss"
            />
          )}
          control={control}
          defaultValue={rightOperand}
          name={timePickerName}
          rules={{
            required: { value: true, message: 'Please select a time between 09:15 to 15:28' },
            ...getTimeValidators()
          }}
        />
        <ErrorMesssage
          error={errors[timePickerName]}
        />
        <span className="tooltiptext tooltip-hide">
          24 Hour time format
          {
            isHistoricRun ? ', seconds will be ignored in backtests' : ''
          }
        </span>
      </div>
    </>
  );
};

export default TimeOfDay;

TimeOfDay.propTypes = propTypes;
TimeOfDay.defaultProps = defaultProps;
