import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  notifiable_type: PropTypes.oneOf(['Run', 'OrderConfirmation']),
  notifiable_id: PropTypes.number.isRequired,
  event: PropTypes.string.isRequired,
  details: PropTypes.shape({}),
  is_new: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});
