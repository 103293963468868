import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Money from 'common/components/MoneyDisplay';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { getPurchases } from 'common/api/payment';
import { planCycleLabel } from 'modules/UpgradePlan/helper';

import Pagination from 'common/components/Pagination';
import InvoiceContent from './InvoiceContent';
import { generatePaymentInvoice } from './InvoiceContent/paymentInvoice';

const propTypes = {
  gstPercentage: PropTypes.number.isRequired,
  quantManGstNumber: PropTypes.string
};

const defaultProps = {
  quantManGstNumber: null
};

const MAX_RECORDS_IN_PAGE = 10;

const oneIndentation = ' ';
const renderDatetime = (datetimeStr) => {
  if (!datetimeStr) { return '-'; }
  return moment(datetimeStr).format('YYYY-MM-DD');
};

const PurchaseSettings = ({ gstPercentage, quantManGstNumber }) => {
  const [isLoading, setIsLoading] = useState(null);
  const [purchases, setPurchases] = useState([]);
  const [showablePurchases, setShowablePurchases] = useState([]);

  if (isLoading === null) {
    getPurchases()
      .then((retrievedPurchases) => {
        setPurchases(retrievedPurchases);
        setShowablePurchases(retrievedPurchases.slice(0, MAX_RECORDS_IN_PAGE));
      })
      .finally(() => setIsLoading(false));
  }

  if (_.isEmpty(showablePurchases)) {
    return (
      <div className="text-center purchase-settings">
        <img src="/no-receipts.svg" alt="No Receipts" className="no-receipts-img" />
      </div>
    );
  }

  const renderPurchaseItem = (purchaseItem) => {
    let content = null;
    if (purchaseItem.purchasable_type === 'Plan') {
      const planCycleDurationInMonths = _.get(purchaseItem, 'purchasable.cycle_duration_in_months');
      content = (
        <>
          {_.get(purchaseItem, 'purchasable.plan_name')}
          {oneIndentation}
          <small className="sub">{planCycleLabel(planCycleDurationInMonths)}</small>
        </>
      );
    } else if (purchaseItem.purchasable_type === 'Addon') {
      content = (
        <>
          {_.get(purchaseItem, 'purchasable.addon_name')}
          (Addon)
        </>
      );
    } else {
      content = `(${purchaseItem.purchasable_type})`;
    }
    return (
      <>
        <td>{content}</td>
        <td>
          <div className="text-center">
            <span className="live-credits">{purchaseItem?.purchasable?.live_run_limit || 0}</span>
            <span className="historic-credits">{purchaseItem?.purchasable?.historic_run_limit || 0}</span>
          </div>
        </td>
        <td>{purchaseItem.end_date ? renderDatetime(purchaseItem.end_date) : 'NA'}</td>
      </>
    );
  };

  const renderRow = (purchase, purchaseItem) => {
    const rowspan = purchase.purchase_items.length || 1;
    return (
      <>
        <td rowSpan={rowspan}>{purchase.id}</td>
        {renderPurchaseItem(purchaseItem)}
        <td rowSpan={rowspan}><Money shouldAvoidDecimals>{purchase.price_total}</Money></td>
        <td rowSpan={rowspan}>{purchase.status}</td>
        <td rowSpan={rowspan}>{renderDatetime(purchase.created_at)}</td>
        <td rowSpan={rowspan}>
          <span
            className="material-icons-outlined receipt"
            onClick={() => generatePaymentInvoice(purchase)}
          >
            receipt
          </span>
        </td>
        <td rowSpan={rowspan} className="hidden-for-future">
          <div id={`purchase-${purchase.id}`}>
            <InvoiceContent
              purchase={purchase}
              quantManGstNumber={quantManGstNumber}
              gstPercentage={gstPercentage}
            />
          </div>
        </td>
      </>
    );
  };

  const renderTableBody = (purchase) => {
    return _.map(purchase.purchase_items, (purchaseItem, index) => {
      const key = `${purchase.id}-${index}`;
      const content = (index === 0)
        ? renderRow(purchase, purchaseItem)
        : renderPurchaseItem(purchaseItem);

      return (
        <tr key={key}>
          {content}
        </tr>
      );
    });
  };

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th>Purchase Id</th>
        <th>Items</th>
        <th width="110px">
          <div className="text-center">Credits</div>
          <div className="text-center">
            <span className="live-credits">Live</span>
            <span className="historic-credits">Historic</span>
          </div>
        </th>
        <th>Valid Till</th>
        <th>Amount</th>
        <th>Status</th>
        <th>Paid at</th>
        <th>Invoice</th>
      </tr>
    </thead>
  );

  return (
    <div>
      <div className="purchase-settings card mb-4">
        <div className="table-responsive">
          <LoadingSpinner isLoadin={isLoading}>
            <table className="table table-striped table-bordered mb-0">
              {renderTableHeader()}
              <tbody className="payment-details-body">
                {_.map(showablePurchases, renderTableBody)}
              </tbody>
            </table>
          </LoadingSpinner>
        </div>
      </div>
      <div className="mb-2">
        <Pagination
          totalPages={purchases.length / MAX_RECORDS_IN_PAGE}
          onPageChange={({ selected: selectedPage }) => {
            const start = selectedPage * MAX_RECORDS_IN_PAGE;
            const end = start + MAX_RECORDS_IN_PAGE;
            setShowablePurchases(purchases.slice(start, end));
          }}
          currentPageIndex={0}
        />
      </div>
    </div>
  );
};

PurchaseSettings.propTypes = propTypes;
PurchaseSettings.defaultProps = defaultProps;

export default PurchaseSettings;
