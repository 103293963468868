import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { instrumentGroupsPropTypes } from 'common/propTypes';
import { checkInstrumentPossibleForEquity } from 'common/utils/InstrumentUtils';
import { SEGMENT_CONFIG } from 'common/constants/index';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const propTypes = {
  tradeType: PropTypes.string.isRequired,
  onTradeTypeChange: PropTypes.func.isRequired,
  isFutureDisable: PropTypes.bool.isRequired,
  isEquityDisable: PropTypes.bool.isRequired,
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRemoveOption: PropTypes.func.isRequired,
  isAdjustment: PropTypes.bool.isRequired
};
const defaultProps = {};

const TradeTypeSelector = ({
  tradeType, onTradeTypeChange, isFutureDisable, isEquityDisable, instrumentGroups,
  segments, onRemoveOption, isAdjustment
}) => {
  const isEquityEnable = checkInstrumentPossibleForEquity(instrumentGroups);
  const shouldRemoveEquityLegBasedOnIndexInstrument = (tradeType === 'equityConfig' && !isEquityEnable);
  // TODO .. Equity hided for particular instruments.. so while integrating equity we have to show
  // hovermessage for disbaled equity.. eg: NFO:BANKNIFTY is not possible for equity

  useEffect(() => {
    if (shouldRemoveEquityLegBasedOnIndexInstrument) {
      if (!isAdjustment) {
        reactToastify('Index Instruments are not possible for equity leg, it is removed please check',
          TOASTIFY_TYPES.ERROR);
      }
      onRemoveOption();
    }
  }, [shouldRemoveEquityLegBasedOnIndexInstrument]);

  const renderOption = () => {
    const isHide = !_.isEmpty(segments) && !_.includes(segments, SEGMENT_CONFIG.option);
    if (isHide) return null;

    return (
      <ToggleButton
        bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
        value="optionConfigs"
        data-track-category="Trade Type Configs"
        data-track-action="optionConfigs"
        data-track-label="Trade Type Configs"
      >
        Option
      </ToggleButton>
    );
  };

  const renderFuture = () => {
    const isHide = !_.isEmpty(segments) && !_.includes(segments, SEGMENT_CONFIG.future);
    if (isHide) return null;

    return (
      <ToggleButton
        bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
        value="futureConfig"
        data-track-category="Trade Type Configs"
        data-track-action="futureConfig"
        data-track-label="Trade Type Configs"
        disabled={isFutureDisable && tradeType !== 'futureConfig'}
      >
        Future
      </ToggleButton>
    );
  };

  const renderEquity = () => {
    const isHide = !_.isEmpty(segments) && !_.includes(segments, SEGMENT_CONFIG.equity);
    if (isHide) return null;

    if (isEquityEnable) {
      return (
        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness track"
          value="equityConfig"
          data-track-category="Trade Type Configs"
          data-track-action="equityConfig"
          data-track-label="Trade Type Configs"
          disabled={isEquityDisable && tradeType !== 'equityConfig'}
        >
          Equity
        </ToggleButton>
      );
    }

    return null;
  };

  return (
    <ToggleButtonGroup
      name="tradeTypeSelector"
      bsPrefix="btn-group btn-group-sm btn-block btn-group-toggle"
      type="radio"
      onChange={(newTradeType) => onTradeTypeChange({ tradeType: newTradeType })}
      value={tradeType}
    >
      {renderOption()}
      {renderFuture()}
      {renderEquity()}
    </ToggleButtonGroup>
  );
};

TradeTypeSelector.propTypes = propTypes;
TradeTypeSelector.defaultProps = defaultProps;

export default TradeTypeSelector;
