import React, { useState } from 'react';
import { fetchRocksDbData } from 'common/api/adminTicks';
import moment from 'moment';
import LoadingSpinner from 'common/components/LoadingSpinner';
import ReactDataTable from 'common/components/ReactDataTable';

const RocksDBDataAdminPanel = () => {
  const [rocksDBData, setRocksDBData] = useState([]);

  const [tradingSymbol, setTradingSymbol] = useState('BANKNIFTY');
  const [granularity, setGranularity] = useState('minute');
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'day').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'day').format('YYYY-MM-DD')
  );
  const [isLoading, setIsLoading] = useState(false);

  const getRocksDBData = () => {
    const queryParams = {
      tradingSymbol,
      startDate,
      endDate,
      granularity
    };
    setIsLoading(true);
    fetchRocksDbData(queryParams).then((result) => {
      setRocksDBData(result);
      setIsLoading(false);
    });
  };

  const renderTradingSymbolBadge = (ts) => {
    return (
      <span
        className="badge badge-secondary text-white mr-2"
        onClick={() => setTradingSymbol(ts)}
      >
        {ts}
      </span>
    );
  };

  const renderInputField = () => {
    return (
      <div className="col-sm">
        <div className="bg-white p-3 border border-primary">
          <form id="live-ticks-form" onSubmit={(e) => { getRocksDBData(); e.preventDefault(); }}>
            <div className="form-group row">
              <div className="col-sm-4">
                <label htmlFor="tradingSymbol" className="col-form-label">Trading Symbol</label>
                <input
                  type="text"
                  className="form-control"
                  id="tradingSymbol"
                  name="tradingSymbol"
                  placeholder="BANKNIFTY23FEB2339400CE"
                  onChange={(event) => {
                    const { target: { value } } = event;
                    setTradingSymbol(value);
                  }}
                  value={tradingSymbol}
                />
              </div>

              <div className="col-sm-2">
                <label htmlFor="startDate" className="col-form-label">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  name="startDate"
                  onChange={(event) => {
                    const { target: { value } } = event;
                    setStartDate(value);
                  }}
                  value={startDate}
                />
              </div>

              <div className="col-sm-2">
                <label htmlFor="endDate" className="col-form-label">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="endDate"
                  name="endDate"
                  onChange={(event) => {
                    const { target: { value } } = event;
                    setEndDate(value);
                  }}
                  value={endDate}
                />
              </div>

              <div className="col-sm-2">
                <label htmlFor="granularity" className="col-form-label">Granularity</label>
                <select
                  value={granularity}
                  onChange={(event) => setGranularity(event.target.value)}
                  className="form-control"
                >
                  <option value="minute">Minute</option>
                  <option value="second">Second</option>
                </select>
              </div>

              <div className="col-sm-2 align-self-end">
                <div className="btn btn-primary" onClick={() => getRocksDBData()}>
                  View RocksDB Data
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="container">
                <b className="mr-3">Sample Trading Symbols</b>
                <div>
                  <b className="mr-3">Options</b>
                  {renderTradingSymbolBadge('BANKNIFTY23FEB2339400CE')}
                </div>
                <div>
                  <b className="mr-3">Futures</b>
                  {renderTradingSymbolBadge('BANKNIFTY-IFUT')}
                  {renderTradingSymbolBadge('BANKNIFTY-IIFUT')}
                </div>
                <div>
                  <b className="mr-3">EQUITY</b>
                  {renderTradingSymbolBadge('BANKNFITY')}
                  {renderTradingSymbolBadge('INDIAVIX')}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const renderDataTable = () => {
    const columns = [
      { Header: 'Timestamp', accessor: 'timestamp' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Volume', accessor: 'volume' },
      { Header: 'Open Interest', accessor: 'openInterest' }
    ];

    _.forEach(rocksDBData, (obj) => {
      // eslint-disable-next-line no-param-reassign
      obj.timestamp = _.chain(obj.timestamp)
        .thru((timestamp) => moment(timestamp).format('YYYY-MM-DD HH:mm:ss'))
        .value();
    });

    return (<ReactDataTable columns={columns} data={rocksDBData} />);
  };

  return (
    <div className="container mt-5">
      <div className="row mb-2">{renderInputField()}</div>
      <LoadingSpinner isLoading={isLoading} />
      {!_.isEmpty(rocksDBData) && renderDataTable()}
    </div>
  );
};

RocksDBDataAdminPanel.propTypes = {};

export default RocksDBDataAdminPanel;
