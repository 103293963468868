import React, { useState } from 'react';
import PropTypes from 'prop-types';
import templates from './Templates/index';

const MINIMIZED_SHOW_SIGNAL_COUNT = 2;

const propTypes = {
  segments: PropTypes.arrayOf(PropTypes.string).isRequired,
  segment: PropTypes.string.isRequired
};

const defaultProps = {};

const ReadyMadeTemplates = ({ segments, segment }) => {
  const [isExpanded, hasExpandedChange] = useState(false);
  const readyMadeTemplates = isExpanded
    ? templates
    : _.take(templates, MINIMIZED_SHOW_SIGNAL_COUNT);

  const renderShowMore = () => {
    if (templates.length <= MINIMIZED_SHOW_SIGNAL_COUNT) {
      return null;
    }

    return (
      <div className="d-flex justify-content-center mt-5">
        <button
          type="button"
          className="btn btn-sm btn-outline-primary btn-pill"
          onClick={() => hasExpandedChange(!isExpanded)}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
          {/* <span className="material-icons-outlined align-middle">
            {isExpanded ? 'expand_less' : 'expand_more'}
          </span> */}
        </button>
      </div>
    );
  };

  const renderTemplates = (template, key) => (
    <template.Builder key={key} segments={segments} segment={segment} />
  );

  return (
    <>
      <div className="strategies-container">
        {_.map(readyMadeTemplates, renderTemplates)}
      </div>
      {renderShowMore()}
    </>
  );
};

ReadyMadeTemplates.propTypes = propTypes;
ReadyMadeTemplates.defaultProps = defaultProps;

export default ReadyMadeTemplates;
