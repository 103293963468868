import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  currentActiveStrategyType: PropTypes.string.isRequired,
  onhandleCurrentActiveStrategyType: PropTypes.func.isRequired,
  typesOfStrategies: PropTypes.shape({}).isRequired,
  onResetPageIndex: PropTypes.func.isRequired
};

const defaultProps = {};

const StrategiesFilterNavTab = ({
  currentActiveStrategyType, onhandleCurrentActiveStrategyType, typesOfStrategies, onResetPageIndex
}) => {
  const types = [
    { id: 'all', label: 'All Strategy' },
    { id: 'deployed', label: 'Deployed' },
    { id: 'paid', label: 'Subscribed' },
    { id: 'own', label: 'Own Strategies' }
  ];

  const renderTypes = (language, key) => {
    const { id, label } = language;
    const navLinkClassName = classNames('nav-link', { active: id === currentActiveStrategyType });
    const count = _.size(_.get(typesOfStrategies, id, []));
    if (!count) return null;

    return (
      <li className="nav-item" key={key}>
        <a
          className={navLinkClassName}
          id={id}
          href={`#${id}`}
          data-toggle="pill"
          role="tab"
          aria-controls={`#${id}`}
          aria-selected="true"
          onClick={() => {
            onhandleCurrentActiveStrategyType(id);
            onResetPageIndex();
          }}
        >
          {label}
          <span className="badge badge-secondary">{count}</span>
        </a>
      </li>
    );
  };

  return <ul className="nav nav-pills" id="pills-tab" role="tablist">{_.map(types, renderTypes)}</ul>;
};

StrategiesFilterNavTab.propTypes = propTypes;
StrategiesFilterNavTab.defaultProps = defaultProps;

export default StrategiesFilterNavTab;
