import React from 'react';
import PropTypes from 'prop-types';

import { renderChart } from './lib/renderer';

const MARGIN = {
  top: 20, right: 20, bottom: 30, left: 50
};

const propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({
    series: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.instanceOf(Date)
    }))
  })).isRequired,
  valuePath: PropTypes.arrayOf(PropTypes.string)
};
const defaultProps = {
  valuePath: ['value']
};

class PLChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    const { chartData, valuePath } = this.props;
    const options = {
      valuePath
    };

    renderChart(
      d3.select(this.chartRef.current),
      MARGIN,
      chartData,
      options
    );
  }

  render() {
    return (
      <div className="pl-chart" ref={this.chartRef}>
        <svg width="100%" height="100%" />
        <div className="tooltip fade bs-tooltip-top" role="tooltip" x-placement="top">
          <div className="arrow" />
          <div className="text-capitalize tooltip-inner">Tooltip on top</div>
        </div>
      </div>
    );
  }
}

PLChart.propTypes = propTypes;
PLChart.defaultProps = defaultProps;
export default PLChart;
