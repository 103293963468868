const configuration = {
  type: 'openingRangeBreakout',
  defaults: {
    type: 'openingRangeBreakout',
    name: 'orb',
    config: {
      candleInterval: '1 minutes',
      maxDiffBetweenOpenHighAndOpenLowInPercentage: 0.5,
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'ORB',
    'openingRangeBreakout'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      { property: 'open', isBoolean: false },
      { property: 'high', isBoolean: false },
      { property: 'low', isBoolean: false },
      { property: 'close', isBoolean: false },
      { property: 'isOpenEqualToHigh', isBoolean: true },
      { property: 'isOpenEqualToLow', isBoolean: true }
    ];

    return _.map(properties, ({ property, isBoolean }) => {
      const schema = {
        name: `${name}.${property}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`
      };

      if (isBoolean) {
        schema.type = 'boolean';
        schema.enum = [0, 1];
      }

      return schema;
    });
  },
  isEnabled: true
};
export default configuration;
