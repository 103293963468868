import React from 'react';
import TimePicker from 'react-time-picker';
import PropTypes from 'prop-types';

const TIME_PICKER_FORMAT = 'HH:mm';

const propTypes = {
  onTimeChange: PropTypes.func.isRequired,
  selectedTime: PropTypes.string,
  style: PropTypes.string,
  format: PropTypes.string
};
const defaultProps = {
  selectedTime: '',
  style: '',
  format: TIME_PICKER_FORMAT
};

const TimeSelector = ({
  onTimeChange, selectedTime, style, format
}) => {
  const maxDetail = TIME_PICKER_FORMAT === format ? 'minute' : 'second';
  // for 10:00:11 including seconds and 10:00 minutes (default)

  return (
    <TimePicker
      className={style}
      onChange={onTimeChange}
      maxDetail={maxDetail}
      value={selectedTime}
      hourPlaceholder="hh"
      minutePlaceholder="mm"
      secondPlaceholder="ss"
      disableClock
      format={format}
      clearIcon={null}
    />
  );
};

export default TimeSelector;
TimeSelector.propTypes = propTypes;
TimeSelector.defaultProps = defaultProps;
