import React, { useState } from 'react';
import V2Dashboard from './V2Dashboard';
import V2JobDetails from './V2JobDetails';
import V2JobActions from './V2JobActions';

const AdminV2JobsDetailsAndActions = () => {
  const [activeTab, setActiveTab] = useState('dashboard');

  const tabItems = [
    { label: 'Dashboard', value: 'dashboard' },
    { label: 'Job Details', value: 'jobDetails' },
    { label: 'Job Actions', value: 'jobActions' },
  ];

  const handleTabClick = (tabValue) => {
    setActiveTab(tabValue);
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'jobDetails':
        return <V2JobDetails />;
      case 'jobActions':
        return <V2JobActions />;
      default:
        return <V2Dashboard />;
    }
  };

  return (
    <div className="container-fluid">
      <ul className="nav nav-pills mb-2">
        {tabItems.map((tab) => (
          <li key={tab.value} className="nav-item cursor-pointer">
            <span
              className={`nav-link ${activeTab === tab.value ? 'active' : ''}`}
              onClick={() => handleTabClick(tab.value)}
            >
              {tab.label}
            </span>
          </li>
        ))}
      </ul>
      {renderActiveTab()}
    </div>
  );
};

export default AdminV2JobsDetailsAndActions;
