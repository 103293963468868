import React, { useMemo } from 'react';
import moment from 'moment';
import { runPropTypes } from 'common/propTypes';
import { RunConfigSanitizer } from 'v2/modules/withRunForm/sanitizer/index';
import Main from 'v2/modules/withRunForm/Main';

const propTypes = runPropTypes.isRequired;
const defaultProps = {};

const RunForm = ({
  endDate: propsEndDate, startDate: propsStartDate, maxEndDate, minStartDate: calendarStartDate,
  runType: propsRunType, folder, orderType: propsOrderType, currentUser,
  granularity: propsGranularity, runName: propsRunName, quantConfig: propsQuantConfig,
  isHistoricRunDisabled: propsIsHistoricRunDisabled,
  isLiveRunDisabled: propsIsLiveRunDisabled,
  engineVersion: propsEngineVersion, strategyId,
  isEditQuant, isHiddenSignal, hiddenByUserId
}) => {
  // Manipulating the props to make it compatible with the run form component
  const isUserEnabled = useMemo(() => _.get(currentUser, 'enabled', false), [currentUser]);
  const calendarEndDate = useMemo(() => (
    maxEndDate || moment.utc().subtract(1, 'days').format('YYYY-MM-DD')
  ), [maxEndDate]);

  const config = {
    startDate: moment.utc(propsStartDate).format('YYYY-MM-DD'),
    endDate: moment.utc(moment(propsEndDate)
      .isAfter(maxEndDate) ? maxEndDate : propsEndDate).format('YYYY-MM-DD'),
    runType: propsRunType,
    selectedFolder: folder,
    orderType: propsOrderType,
    granularity: propsGranularity,
    runName: propsRunName,
    engineVersion: propsEngineVersion,
    quantConfig: propsQuantConfig,
    calendarEndDate,
    calendarStartDate,
    isUserEnabled,
    isHistoricRunDisabled: propsIsHistoricRunDisabled,
    isLiveRunDisabled: propsIsLiveRunDisabled,
    isEditQuant,
    currentUser,
    strategyId,
    isHiddenSignal,
    hiddenByUserId
  };

  const sanitizedRunConfig = RunConfigSanitizer.onLoad(config);

  return <Main {...sanitizedRunConfig} />;
};

RunForm.propTypes = propTypes;
RunForm.defaultProps = defaultProps;

export default RunForm;
