import { ORDER_TYPES } from 'v2/common/constants/index';
import moment from 'moment';
import { automaticSupportedBrokers } from 'v2/common/brokerConfigs/index';
import { oneClickSupportedBrokers } from 'common/brokerConfigs/config';

export const getDefaultOrderType = (newOrderType, currentUser) => {
  const planActiveTill = _.get(currentUser, 'plan_active_till', null);
  const broker = _.get(currentUser, 'broker', '');

  const { paper: { value: paper } } = ORDER_TYPES;

  const { isInInitialOfferPeriod, isAdmin } = window;
  if (!broker) return paper; // normal mail users... mandatory paper

  if (isAdmin || isInInitialOfferPeriod) return newOrderType;

  if (((_.includes(automaticSupportedBrokers, broker))
    || _.includes(oneClickSupportedBrokers, broker))
    && (planActiveTill && moment().isBefore(planActiveTill))
  ) {
    return newOrderType;
  }

  return paper;
};
