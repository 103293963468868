import React from 'react';
import { onClaimFreeTrial } from 'common/api/payment';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const propTypes = {};

const defaultProps = {};

const AvailableFreeTrialCard = () => {
  const { availableFreeTrial, userReferredBy } = window;
  if (_.isEmpty(availableFreeTrial)) return null;
  if (userReferredBy !== 'angelone') return null;
  // Should revamp this component again.
  // Last minute changed custom content inside component for broker specific.
  // Need to make this component generic.

  const { live_quota: liveQuota, historic_quota: historicQuota, trial_days: trialDays } = availableFreeTrial;
  const oneIndentation = ' ';

  const onClickClaimFreeTrialBtn = () => {
    onClaimFreeTrial().then((res) => {
      if (res.success) {
        reactToastify('Free trial claimed, Happy Trading', TOASTIFY_TYPES.SUCCESS);
        window.location.reload();
      } else {
        reactToastify('Something went wrong! Please contact support', TOASTIFY_TYPES.ERROR);
      }
    }).catch(() => {
      reactToastify('Something went wrong! Please contact support', TOASTIFY_TYPES.ERROR);
    });

    return null;
  };

  return (
    <div className="available-free-trial">
      <div className="status-info">
        <span className="font-weight-bold tx-14">
          Rs. 1534 worth
          {liveQuota && (
            <>
              {oneIndentation}
              {liveQuota}
              {oneIndentation}
              live runs
            </>
          )}
          {historicQuota && (
            <>
              {oneIndentation}
              {historicQuota}
              {oneIndentation}
              backtest
            </>
          )}
          {oneIndentation}
          free for
          {oneIndentation}
          {trialDays}
          {oneIndentation}
          days.
        </span>
        {oneIndentation}
        If you want please click here
        <button
          type="button"
          className="btn btn-light"
          onClick={() => onClickClaimFreeTrialBtn()}
        >
          Claim Free Trial
        </button>
      </div>
    </div>
  );
};

AvailableFreeTrialCard.propTypes = propTypes;
AvailableFreeTrialCard.defaultProps = defaultProps;

export default AvailableFreeTrialCard;
