import Money from 'common/components/MoneyDisplay';
import React from 'react';
import PropTypes from 'prop-types';
import { ordersPropTypes } from 'common/propTypes';
import { getProfit } from 'common/utils/transactionProfitHelper';

const propTypes = {
  cumulativeProfit: PropTypes.shape({}),
  orders: ordersPropTypes.isRequired,
  isPaperTrade: PropTypes.bool.isRequired
};

const defaultProps = { cumulativeProfit: {} };

const signalProfitMapBy = (order) => (
  (order.quantity * order.signal_price * (order.transaction_type === 'buy' ? -1 : +1))
);

const tradedProfitMapBy = (order) => (
  (order.quantity * order.traded_price * (order.transaction_type === 'buy' ? -1 : +1))
);

const LiveProfits = ({
  orders, isPaperTrade
}) => {
  const oneIndentation = ' ';

  return (
    <>
      {!isPaperTrade && (
        <div className="d-flex">
          P :
          {oneIndentation}
          <span className="font-weight-semi">
            <Money shouldColor shouldAvoidDecimals>{getProfit(orders, tradedProfitMapBy)}</Money>
          </span>
        </div>
      )}
      {oneIndentation}
      {isPaperTrade && (
        <div>
          P :
          {oneIndentation}
          <span className="font-weight-semi">
            <Money shouldColor shouldAvoidDecimals>{getProfit(orders, signalProfitMapBy)}</Money>
          </span>
        </div>
      )}
    </>
  );
};

LiveProfits.defaultProps = defaultProps;
LiveProfits.propTypes = propTypes;

export default LiveProfits;
