import { useMemo, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { INTENT_TYPES } from 'v2/common/constants/index';
import { useIntent } from './index';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import { getQuantModes } from '../../configs/index';
import { IntentsContext } from '../../hoc/withIntentsContext';

export const useBasketIntent = (intent) => {
  const {
    createOnChangeHandler,
    intentId,
    segment,
    onChangeSegment,
    onChangeIntentType,
    segmentOptionConfigs,
    isShowInstrumentIndex
  } = useIntent(intent);

  const { onUpdateIntent } = useContext(IntentsContext);

  const mode = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs.mode')
  ) || getQuantModes.simple.value;
  const isAdvancedMode = useMemo(() => mode === getQuantModes.advanced.value, [mode]);

  const createOnChangeMultiplierHandler = useCallback((field) => (newValue) => {
    const newIntent = _.cloneDeep(intent);
    _.unset(newIntent, 'quantity');
    _.set(newIntent, field, newValue?.value);
    onUpdateIntent(newIntent, intentId);
  }, [intent, intentId, onUpdateIntent]);

  return {
    name: intent?.name || '',
    onChangeBasketName: createOnChangeHandler('name'),
    multiplier: intent?.multiplier,
    onChangeMultiplier: createOnChangeMultiplierHandler('multiplier'),
    onChangeIntentType,
    intentId,
    segment: segment || INTENT_TYPES.OpenBasket,
    onChangeSegment,
    segmentOptionConfigs,
    isShowInstrumentIndex,
    isAdvancedMode
  };
};
