export const STATUS_STYLE_CONFIG = {
  created: {
    statusText: 'signal received',
    statusTextClassName: 'text-warning',
    progressCirlcClassName: ['completed', 'processing', '', '']
  },
  confirmed: {
    statusText: 'signal received',
    statusTextClassName: 'text-warning',
    progressCirlcClassName: ['completed', 'completed', 'processing', ''],
  },
  placed: {
    statusTextClassName: 'text-warning',
    progressCirlcClassName: ['completed', 'completed', 'completed', 'processing'],
  },
  cancelled: {
    statusTextClassName: 'text-notice',
    progressCirlcClassName: ['completed', 'completed', 'completed', 'completed'],
  },
  executed: {
    statusTextClassName: 'text-success',
    progressCirlcClassName: ['completed', 'completed', 'completed', 'completed'],
  },
  failed: {
    statusTextClassName: 'text-danger',
    progressCirlcClassName: ['completed', 'completed', 'completed', 'error'],
  },
  'sl-achieved': {
    statusTextClassName: 'text-notice',
    progressCirlcClassName: ['completed', 'completed', 'completed', 'completed'],
  },
  partially_executed: {
    statusText: 'partially executed',
    statusTextClassName: 'text-notice',
    progressCirlcClassName: ['completed', 'completed', 'processing', ''],
  },
  modified_to_exit: {
    statusText: 'modified to exit',
    statusTextClassName: 'text-notice',
    progressCirlcClassName: ['completed', 'completed', 'completed', ''],
  }
};
