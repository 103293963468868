import React from 'react';
import PropTypes from 'prop-types';

import { runPropTypes } from 'common/propTypes';
import RunsTableHeader from './RunsTableHeader';
import RunsTableBody from './RunsTableBody';

const propTypes = {
  runs: PropTypes.arrayOf(runPropTypes),
  containerClsName: PropTypes.string.isRequired,
};
const defaultProps = {
  runs: []
};
const I18N_SCOPE = { scope: 'folders.show' };

const ICON = {
  historic: 'icons-history mr-2',
  live: 'icons-live mr-2'
};

const RecentRuns = (props) => {
  const { runs, containerClsName } = props;
  const runType = _.get(runs, [0, 'run_type']);

  if (_.isEmpty(runs)) return null;

  return (
    <div className={`recent-runs-list d-none col mb-5 ${containerClsName}`}>
      <div className="h-100">
        <h4 className="mb-2 d-flex align-items-center">
          <i className={`${ICON[runType]}`} alt={runType} title={runType} />
          {I18n.t(`${runType}`, I18N_SCOPE)}
        </h4>
        <div className="card">
          <div className="card-body pb-0">
            <div className="responsive-folders-table table-responsive">
              <table className="table list-view">
                <RunsTableHeader />
                <RunsTableBody runs={runs} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RecentRuns.propTypes = propTypes;
RecentRuns.defaultProps = defaultProps;

export default RecentRuns;
