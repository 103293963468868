import { onCreateNewCallbackRequest } from 'common/api/callbackRequest';
import HoverMessage from 'common/components/HoverMessage';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { userPropTypes } from 'common/propTypes';
import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useForm, FormProvider } from 'react-hook-form';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { languageOptions } from './config';
import HelpLineInput from './HelpLineInput';
import { isStrategyDomainUser } from '../applicationDomainHelper';

const propTypes = { currentUser: userPropTypes };

const defaultProps = { currentUser: {} };

let messageContent = '<h6 class="tx-16 font-weight-bold mb-2">Need Help?</h6> ';
messageContent += '<p class="mb-0 tx-13">Please give a call back request, we will get back to you</p>';

const HelpLine = ({ currentUser }) => {
  const pathname = _.get(window, 'location.pathname', '');
  if (pathname === '/advanced_analytics') return null;

  const userPhoneNumber = _.get(currentUser, 'phone_number', '');

  if (_.isEmpty(currentUser)) return null;

  const methods = useForm();

  const [isShow, setIsShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber || '');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [language, setLanguage] = useState('english');
  const [isOpenToolTip, setIsOpenToolTip] = useState(true);
  const callbackRef = useRef(false);
  const { applicationDomain } = window;
  const isCallBackRequestAllowed = (
    !isStrategyDomainUser()
    || _.get(applicationDomain, 'config.callback_request_allowed', false)
  );

  if (!isCallBackRequestAllowed) return null;

  const handleDocumentClick = (event) => {
    const isClicked = callbackRef.current.contains(event.target);
    const isPopUpOpen = document.getElementsByClassName('pop-up').length;
    if (!isClicked && isPopUpOpen === 1) return setIsShow(false);

    return null;
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, true);
    setTimeout(() => { setIsOpenToolTip(false); }, 3000);

    return () => { document.removeEventListener('click', handleDocumentClick, true); };
  }, []);

  const onSendDetails = () => {
    onCreateNewCallbackRequest({
      phone_number: phoneNumber, description, language, email, user_id: currentUser.id
    })
      .then(() => {
        reactToastify('Request has been sent, we will get back you soon');
      }).catch(() => {
        reactToastify('There will be some problem, please try again later', TOASTIFY_TYPES.ERROR);
      });

    setIsShow(!isShow);
    setDescription('');
    setEmail('');

    return null;
  };

  const renderCallBackDetails = () => {
    return (
      <FormProvider {...methods}>
        <div className="floating-wrapper hide-minimal-content" ref={callbackRef}>
          <input
            type="checkbox"
            className="support-check"
            id="toggle"
            checked={isShow}
            onChange={() => { setIsShow(!isShow); }}
          />

          <label className="support-btn" htmlFor="toggle" />

          {isShow
            && (
              <div className="support-inner pop-up">
                <div className="support-head">
                  <h5>Support</h5>
                  <p className="mb-0 tx-12">Submit your Callback Request we will get back to you soon...</p>
                </div>
                <div className="d-flex flex-column gap-2 p-4">
                  <HelpLineInput
                    name="phoneNumber"
                    value={phoneNumber}
                    onUpdate={setPhoneNumber}
                    label="Mobile Number"
                    type="number"
                  />
                  <HelpLineInput
                    name="email"
                    value={email}
                    onUpdate={setEmail}
                    label="Email"
                    type="email"
                  />

                  <div>
                    <label className="col-form-label col-form-label-sm">Select Language</label>
                    <select
                      defaultValue={language}
                      onChange={(event) => { setLanguage(event.target.value); }}
                      className="custom-select custom-select-sm"
                      id="inputGroupSelect01"
                    >
                      {_.map(languageOptions, ({ label, value }) => {
                        return <option value={value} key={value}>{label}</option>;
                      })}
                    </select>
                  </div>
                  <div>
                    <HelpLineInput
                      name="description"
                      value={description}
                      onUpdate={setDescription}
                      label="Request Description"
                      isTextArea
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-outline-secondary"
                    onClick={methods.handleSubmit(() => onSendDetails())}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
        </div>
      </FormProvider>
    );
  };

  if (isShow) return renderCallBackDetails();

  return (
    <HoverMessage
      message={ReactHtmlParser(messageContent)}
      isOpen={isOpenToolTip}
      popClassName="d-sm-none d-md-block custom-popover"
    >
      {renderCallBackDetails()}
    </HoverMessage>
  );
};

HelpLine.defaultProps = defaultProps;
HelpLine.propTypes = propTypes;

export default withErrorBoundary(HelpLine);
