import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { setRootValue } from 'v2/modules/withRunForm/redux/actions';
import FolderSelector from 'v2/common/components/FolderSelector/index';

const Folder = () => {
  const methods = useForm();
  const dispatch = useDispatch();
  const selectedFolder = useSelector((state) => getSelectorBasedStoreValue(state, 'selectedFolder'));
  const { isUserSignedIn } = window;
  const onSelectFolder = (value) => dispatch(setRootValue({ selectedFolder: value }));

  return (
    <div className="select-folder">
      <label className="label_style">Folder Name</label>
      <FormProvider {...methods}>
        <FolderSelector
          selectedFolder={selectedFolder}
          isUserLoggedIn={isUserSignedIn}
          onSelect={(newFolder) => onSelectFolder(newFolder)}
        />
      </FormProvider>
    </div>
  );
};

export default Folder;
