import { WEEKLY_OPTION_AVAILABLE_FROM, TRANSACTION_MODES } from 'modules/TransactionsBuilder/configs';

const { futureAndOption: FUTURE_AND_OPTION, futureConfig: FUTURE_CONFIG } = TRANSACTION_MODES;

export const instrumentsWithWeeklyExpiry = window.indexInstruments;
const NIFTY = 'NFO:NIFTY';

export const hasNiftyInInstrumentGroup = (instrumentGroups) => _.some(instrumentGroups,
  (instrumentGroup) => _.includes(instrumentGroup, NIFTY));

export const hasWeeklyExpiryInstruments = (instrumentGroups) => {
  const noOfInstruments = _.size(_.first(instrumentGroups));

  return _.some(
    _.times(noOfInstruments, (instumentIndex) => (
      _.every(_.map(instrumentGroups, (instrumentGroup) => (
        _.includes(instrumentsWithWeeklyExpiry, instrumentGroup[instumentIndex])
      )))
    ))
  );
};

const checkIsFutureConfigAvailable = (orderconfigs) => _.some(
  _.flatMap(orderconfigs, ({ transactionConfigs }) => _.flatMap(
    transactionConfigs, ({ mode: { transaction } }) => (_.includes(
      [FUTURE_AND_OPTION, FUTURE_CONFIG], transaction
    ))
  ))
);

export const isRunEligibleForWeeklyExpiry = (instrumentGroups, startDate, orderConfigs) => {
  if (!hasWeeklyExpiryInstruments(instrumentGroups)) return false;

  const isFutureConfigAvailable = checkIsFutureConfigAvailable(orderConfigs);

  if (
    (hasNiftyInInstrumentGroup(instrumentGroups)
      && WEEKLY_OPTION_AVAILABLE_FROM.isAfter(startDate))
    || isFutureConfigAvailable
  ) {
    return false;
  }

  return true;
};

export const isEligibleForMonthly = (instrumentGroups) => {
  const instruments = _.flattenDeep(instrumentGroups);
  const notEligibleInstrument = ['NFO:FINNIFTY', 'NFO:MIDCPNIFTY', 'BFO:SENSEX', 'BFO:BANKEX'];

  const difference = _.filter(instruments, (
    instrument
  ) => {
    return _.includes(notEligibleInstrument, instrument);
  });

  if (_.isEmpty(difference)) return true;

  return false;
};
