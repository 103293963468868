import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { strategyPropTypes, userPropTypes } from 'common/propTypes';
import { RUN_TYPES } from 'common/constants/index';
import classNames from 'classnames';
import { getUrlParams, updateUrlQueryParams } from 'common/utils/urlUtils';
import { ACTIVE_STRATEGY_TYPE, buildCategory } from 'ui/Strategies/helper';
import StrategyDeployedRunDetails from '../StrategyRunDetails/StrategyDeployedRunDetails';
import StrategyBacktestRunDetails from '../StrategyRunDetails/StrategyBacktestRunDetails';
import StrategyLiveRunDetails from '../StrategyRunDetails/StrategyLiveRunDetails';
import IndividualStrategyDetails from './IndividualStrategyDetails';
import StrategyAnalytics from '../StrategyAnalytics/index';

const propTypes = {
  currentUserDefaultFolderId: PropTypes.number,
  currentUser: userPropTypes,
  strategy: strategyPropTypes.isRequired,
  strategyAccessDetails: PropTypes.arrayOf(PropTypes.shape({}))
};

const defaultProps = { currentUserDefaultFolderId: null, currentUser: null, strategyAccessDetails: null };

const Strategy = ({
  strategy, currentUser, currentUserDefaultFolderId, strategyAccessDetails
}) => {
  const { deployed_run_details: deployedRunDetails, margin_amount: marginAmount } = strategy;
  const urlParams = getUrlParams();
  const [activeStrategyType, setActiveStrategyType] = useState(
    _.get(urlParams, ACTIVE_STRATEGY_TYPE, RUN_TYPES.historic)
  );
  const { SA_USER_FLAGS, isUserSignedIn } = window;
  const currentUserId = _.get(currentUser, 'id', '');
  const strategyUserId = _.get(strategy, 'user_id', '-');
  const isOwnerStrategy = currentUserId === strategyUserId;
  let goToMarkerPlaceUrl = `/strategies?locale=${I18n.locale}`;
  const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';
  if (isMinifiedPage) goToMarkerPlaceUrl = `/strategies?locale=${I18n.locale}&minifiedpage=true`;

  const isAdmin = _.get(SA_USER_FLAGS, 'isAdmin', false);
  const isStaff = _.get(SA_USER_FLAGS, 'isStaff', false);
  const isStaffOrAdmin = isAdmin || isStaff;

  const categories = [
    buildCategory('My Deployment', 'deployed_run', !isOwnerStrategy,
      <i className="material-icons-outlined">rocket_launch</i>),
    buildCategory('Live', RUN_TYPES.live, (isStaffOrAdmin || isOwnerStrategy), <i className="icons-live" />),
    buildCategory('Backtest',
      RUN_TYPES.historic, false, <i className="icons-history" />),
    buildCategory('Analytics', 'Analytics', isAdmin, <i className="icons-order" />),
  ];

  const isPermitToShow = _.find(categories, ({ value, isShow }) => value === activeStrategyType && isShow);

  useEffect(() => {
    if (!isPermitToShow) setActiveStrategyType(isOwnerStrategy ? RUN_TYPES.live : 'deployed_run');
  }, []);

  useEffect(() => {
    updateUrlQueryParams(ACTIVE_STRATEGY_TYPE, activeStrategyType, !!isMinifiedPage);
  }, [activeStrategyType]);

  const renderCategories = (category, key) => {
    const {
      label, value, isShow, icon
    } = category;

    if (!isShow) return null;

    const liClassName = classNames('list-item', { active: value === activeStrategyType });

    return (
      <li
        role="presentation"
        className={liClassName}
        key={key}
        onClick={() => { setActiveStrategyType(value); }}
      >
        <span>
          {icon}
          {label}
        </span>
      </li>
    );
  };

  const renderCatrgoryBasedRuns = () => {
    if (!isPermitToShow) return null;

    switch (activeStrategyType) {
      case RUN_TYPES.live:
        return (
          <StrategyLiveRunDetails
            strategy={{ ...strategy, currentUserDefaultFolderId, currentUser }}
            isAccordianOpen={(isStaffOrAdmin || isOwnerStrategy)}
            currentUser={currentUser}
          />
        );
      case 'deployed_run':
        return (
          <StrategyDeployedRunDetails
            deployedRunDetails={deployedRunDetails}
            currentUser={currentUser}
            marginAmount={marginAmount}
            strategy={{ ...strategy, currentUserDefaultFolderId, currentUser }}
          />
        );
      case RUN_TYPES.historic:
        return <StrategyBacktestRunDetails strategy={strategy} />;
      case 'Analytics':
        return <StrategyAnalytics strategy={strategy} />;
      default:
        return null;
    }
  };

  return (
    <div className="marketplace-strategies-details">
      <div className="content-area content-fixed">
        <div className="container">
          <div>
            <a
              href={goToMarkerPlaceUrl}
              className="goto-btn my-3 btn btn-sm btn-outline-primary d-inline-flex btn-pill"
            >
              <i className="material-icons-outlined tx-18 align-middle mr-1">arrow_back</i>
              Go to Strategies
            </a>
          </div>

          <IndividualStrategyDetails
            strategy={strategy}
            currentUser={currentUser}
            currentUserDefaultFolderId={currentUserDefaultFolderId}
            strategyAccessDetails={strategyAccessDetails}
          />
        </div>
        {isUserSignedIn && (
          <div className="custom-tabs mt-5 strategies-details">
            <div className="tabs-list-warpper">
              <div className="container tabs-navbar">
                <ul className="tabs-list">{_.map(categories, renderCategories)}</ul>
              </div>
            </div>

            <div className="container tabs-content-wrapper">{renderCatrgoryBasedRuns()}</div>
          </div>
        )}
      </div>
    </div>
  );
};

Strategy.propTypes = propTypes;
Strategy.defaultProps = defaultProps;

export default Strategy;
