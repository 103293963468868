import {
  FIXED_AMOUNT, FREE, highRiskHighReward, SEGMENT_CONFIG
} from 'common/constants/index';

export const defaultStrategyFormConfig = (run) => {
  const {
    id, name, quant_config: quantConfig, user_id: userId
  } = run;

  const segmentType = _.get(quantConfig, 'segment', SEGMENT_CONFIG.equity);

  return ({
    title: name,
    margin_amount: 0,
    max_drawdown: 0,
    tags: [],
    risk_category: highRiskHighReward,
    segment_type: segmentType,
    purchase_type: FREE,
    purchase_amount: 0,
    backtest_run_id: 0,
    live_run_id: id,
    description: '',
    user_id: userId,
    purchase_valid_month: 0
  });
};

export const getColumnDetails = (strategyConfig) => {
  return [
    {
      label: 'Max Drawdown',
      strategyKey: 'max_drawdown',
      value: strategyConfig.max_drawdown,
      className: 'form-group col-md-3'
    }
  ];
};

export const getPurchaseDetails = (strategyConfig) => {
  return [
    {
      strategyKey: 'purchase_amount',
      value: strategyConfig.purchase_amount,
      label: 'Purchase Amount',
      isShow: strategyConfig.purchase_type === FIXED_AMOUNT,
      className: 'form-group col-md-4'
    },
    {
      strategyKey: 'margin_amount',
      value: strategyConfig.margin_amount,
      label: 'Margin Amount',
      isShow: true,
      className: 'form-group col-md-4'
    },
    {
      strategyKey: 'purchase_valid_month',
      value: strategyConfig.purchase_valid_month,
      label: 'Purchase Valid Month',
      isShow: true,
      className: 'form-group col-md-4'
    },
    {
      strategyKey: 'backtest_run_id',
      value: strategyConfig.backtest_run_id,
      label: 'Backtest Run Id',
      isShow: true,
      className: 'form-group col-md-4'
    }
  ];
};
