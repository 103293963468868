import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RUN_FINISHED_STATUSES } from 'common/configs/runStatus';
import HoverMessage from 'common/components/HoverMessage';
import ModalComponent from 'common/components/ModalComponent';

const propTypes = {
  isRunBelongsToUser: PropTypes.bool.isRequired,
  onDeleteRun: PropTypes.func.isRequired,
  status: PropTypes.string,
  isMarketPlaceAuthorRun: PropTypes.bool
};
const defaultProps = { status: '', isMarketPlaceAuthorRun: false };

const DeleteRunBtn = ({
  isRunBelongsToUser, onDeleteRun, status, isMarketPlaceAuthorRun
}) => {
  if (!isRunBelongsToUser) return null;

  const [isShowModal, setIsShowModal] = useState(false);

  const content = isMarketPlaceAuthorRun
    ? 'Strategy has dependencies' : 'Please stop the strategy and delete it';

  const isDisabled = !_.includes(RUN_FINISHED_STATUSES, status) || isMarketPlaceAuthorRun;

  const modalProps = {
    title: 'Delete Confirmation',
    size: 'sm',
    shouldShow: isShowModal,
    onClose: () => { setIsShowModal(false); },
    okBtnTitle: 'Delete Deployment',
    onSave: () => { onDeleteRun(); },
  };

  const renderModal = () => {
    return (
      <ModalComponent {...modalProps}>
        <div className="text-center">
          <img src="/delete-icon.png" alt="Delete" className="alert-modal-img mb-4" />
          <p>
            Once you delete the deployment, it cannot be reverted. Please make sure to delete the deployment
          </p>
        </div>
      </ModalComponent>
    );
  };

  const renderButton = () => {
    return (
      <button
        type="button"
        key="delete-btn"
        className="delete-btn"
        disabled={isDisabled}
        onClick={() => { setIsShowModal(true); }}
      >
        <i className="material-icons-outlined tx-22">delete_forever</i>
        <span className="delete-text">Delete</span>
      </button>
    );
  };

  if (!isDisabled) {
    return (
      <div>
        {renderButton()}
        {renderModal()}
      </div>
    );
  }

  return <HoverMessage title={status || 'status'} message={content}>{renderButton()}</HoverMessage>;
};

DeleteRunBtn.propTypes = propTypes;
DeleteRunBtn.defaultProps = defaultProps;

export default DeleteRunBtn;
