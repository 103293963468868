import { pipeInputSchemaTypes } from 'v2/common/quantConfig/pipeDefinitions/config';
import NumberFormField from './components/NumberFormField';
import SelectFormField from './components/SelectFormField';
import ValuePathsFormField from './components/ValuePathsFormField';
import SignalFormField from './components/SignalFormField';
import PipeCandleInterval from './components/PipeCandleInterval';
import PipeIntents from './components/PipeIntents';
import StopLossFormField from './components/StopLossFormField';
import TimeOfDayField from './components/TimeOfDayField';
import VendorField from './components/VendorField';
import ReadOnlyField from './components/ReadOnlyField';
import ExternalSignal from './components/ExternalSignal/index';
import FloatFormField from './components/FloatFormField';
import PipeVolumeInterval from './components/PipeVolumeInterval';

const config = {
  [pipeInputSchemaTypes.number]: {
    component: NumberFormField,
    className: '',
  },
  [pipeInputSchemaTypes.timeOfDay]: {
    component: TimeOfDayField,
    className: '',
  },
  [pipeInputSchemaTypes.float]: {
    component: FloatFormField,
    className: '',
  },
  [pipeInputSchemaTypes.select]: {
    component: SelectFormField,
    className: ''
  },
  [pipeInputSchemaTypes.volumeInterval]: {
    component: PipeVolumeInterval,
    className: '',
  },
  [pipeInputSchemaTypes.valuePaths]: {
    component: ValuePathsFormField,
    className: '',
  },
  [pipeInputSchemaTypes.intents]: {
    component: PipeIntents,
    className: 'full__width',
  },
  [pipeInputSchemaTypes.signal]: {
    component: SignalFormField,
    className: 'full__width',
  },
  [pipeInputSchemaTypes.candleInterval]: {
    component: PipeCandleInterval,
    className: '',
  },
  [pipeInputSchemaTypes.vendor]: {
    component: VendorField,
    className: ''
  },
  [pipeInputSchemaTypes.readOnly]: {
    component: ReadOnlyField,
    className: ''
  },
  [pipeInputSchemaTypes.externalSignalId]: {
    component: ExternalSignal,
    className: 'full__width',
  },
  [pipeInputSchemaTypes.stopLoss]: {
    component: StopLossFormField,
    className: 'full__width pipe_transaction_sl',
  },
};

export const PipeUtility = (() => {
  return {
    getComponentByType: (type) => {
      return config[type]?.component;
    },
    getParentClassNameByType: (type) => {
      return config[type]?.className || '';
    }
  };
})();
