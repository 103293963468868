import { getSymbolType } from 'v2/common/utils/tradingSymbol';
import { getTradeTypes } from '../../config';
import { computePLAndHM } from './common';

export const getTradeType = (symbolOrder) => {
  const order = _.find(symbolOrder, (({ is_entry: isEntry }) => isEntry === true || isEntry === 'true'));

  if (!order) return { symbolCategory: null, tradeType: null };

  const { trading_symbol: tradingSymbol, transaction_type: transactionType } = order;
  const tradeType = getSymbolType(tradingSymbol);

  return { symbolCategory: getTradeTypes()[tradeType].entry[transactionType], tradeType };
};

export const getTradeTypeData = (completedTransactions) => {
  return _.chain(completedTransactions)
    .reduce((hash, symbolOrder) => {
      const analyticsKey = symbolOrder.symbolCategory;

      // eslint-disable-next-line no-param-reassign
      (hash[analyticsKey] || (hash[analyticsKey] = [])).push(symbolOrder);
      return hash;
    }, {})
    .reduce((tradeTypeAnalyticsData, tradeTypeOrders, tradeTypeAnalyticsKey) => {
      // eslint-disable-next-line no-param-reassign
      tradeTypeAnalyticsData[tradeTypeAnalyticsKey] = computePLAndHM(tradeTypeOrders);

      return tradeTypeAnalyticsData;
    }, {})
    .value();
};
