import {
  quantConfigPropTypes, runjobsProps, runPropTypes, userPropTypes
} from 'common/propTypes';
import PropTypes from 'prop-types';

export const runShowDetailsPropTypes = PropTypes.shape({
  status: PropTypes.string.isRequired,
  runType: PropTypes.string.isRequired,
  shouldRenderLoadingSpinner: PropTypes.bool.isRequired,
  jobIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  instrumentGroups: PropTypes.arrayOf(PropTypes.array),
  pipeConfigs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errorInfo: PropTypes.shape({}),
  currentUserId: PropTypes.number,
  quantConfig: quantConfigPropTypes.isRequired,
  run: runPropTypes.isRequired,
  runJobs: runjobsProps.isRequired,
  currentUser: userPropTypes,
  strategyDetails: PropTypes.shape({}).isRequired
});
