import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useRunSubmit from '../../hooks/useRunSubmit';

const propTypes = {};
const defaultProps = {};

const SubmitButton = () => {
  const { onSubmit, isLoading } = useRunSubmit();
  const { handleSubmit } = useFormContext();
  const runConfig = useSelector((state) => state);
  const onError = (errors) => {
    const anyAutoScrollErrors = _.some(errors, (error) => !error.forceScrollTo);
    if (anyAutoScrollErrors) {
      // Already react hook form would have scrolled to the first auto scroll error.
      return;
    }
    const firstForceScrollElemId = _.chain(errors)
      .filter(({ forceScrollTo }) => {
        return forceScrollTo && $(forceScrollTo).length > 0;
      })
      .map('forceScrollTo')
      .first()
      .value();

    const firstForceScollElem = $(firstForceScrollElemId)[0];
    if (firstForceScollElem) {
      firstForceScollElem.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <>
      {isLoading ? 'Loading...' : ''}
      <span className="hidden-for-future">{JSON.stringify(runConfig)}</span>
      <button
        type="submit"
        disabled={isLoading}
        className="btn btn-lg btn-success btn-submit"
        onClick={handleSubmit(onSubmit, onError)}
      >
        Submit
      </button>
    </>
  );
};

SubmitButton.propTypes = propTypes;
SubmitButton.defaultProps = defaultProps;

export default SubmitButton;
