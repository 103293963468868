import React from 'react';
import PropTypes from 'prop-types';
import ConfigDisplayDetails from './ConfigDisplayDetails';

const propTypes = {
  tradingType: PropTypes.string.isRequired,
  timeBasedSignal: PropTypes.shape({
    entryTime: PropTypes.string,
    exitTime: PropTypes.string
  }),
  expiryHandling: PropTypes.shape({
    exitDaysBefore: PropTypes.number,
    exitTime: PropTypes.string
  }),
  maxTransactionsPerDay: PropTypes.number.isRequired
};

const defaultProps = { timeBasedSignal: {}, expiryHandling: {} };

const ViewConfigExpiry = ({
  tradingType, timeBasedSignal, expiryHandling, maxTransactionsPerDay
}) => {
  const renderExpiry = () => {
    if (tradingType === 'intraday') {
      const { entryTime, exitTime } = timeBasedSignal;
      const tradeDuration = (
        <>
          {entryTime}
          &nbsp; -&nbsp;
          {exitTime}
        </>
      );

      return (
        <div className="instrument-groups-input config-rundetails">
          <ConfigDisplayDetails title="Strategy Type" value={_.startCase(tradingType)} />
          <ConfigDisplayDetails title="Trade During" value={tradeDuration} />
          <ConfigDisplayDetails title="Max Transactions Per Day" value={maxTransactionsPerDay} />
        </div>
      );
    }
    const { exitDaysBefore, exitTime } = expiryHandling;

    return (
      <div className="instrument-groups-input config-rundetails">
        <ConfigDisplayDetails title="Strategy Type" value={_.startCase(tradingType)} />
        <ConfigDisplayDetails title="Expiry Before (Day)" value={exitDaysBefore} />
        <ConfigDisplayDetails title="On Expiry Day Force Exit At" value={exitTime} />
        <ConfigDisplayDetails title="Max Transactions Per Day" value={maxTransactionsPerDay} />
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="m-0 font-weight-medium">Expiry</h5>
      </div>
      <div className="card-body">
        {renderExpiry()}
      </div>
    </div>
  );
};

ViewConfigExpiry.propTypes = propTypes;
ViewConfigExpiry.defaultProps = defaultProps;

export default ViewConfigExpiry;
