import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })).isRequired
};

const defaultProps = {};

const MultiMedia = ({ content }) => {
  const renderContent = ({ url, alt }, idx) => {
    return (
      <div key={idx} className="tip-media mb-2">
        <img src={url} alt={alt} className="img-fluid" />
      </div>
    );
  };

  return _.map(content, renderContent);
};

MultiMedia.propTypes = propTypes;
MultiMedia.defaultProps = defaultProps;

export default MultiMedia;
