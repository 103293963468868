import React from 'react';
import PropTypes from 'prop-types';
import { parseTradingSymbol } from 'v2/common/utils/tradingSymbol';

const propTypes = { tradingSymbol: PropTypes.string };
const defaultProps = { tradingSymbol: '' };

const TradingSymbol = ({ tradingSymbol }) => {
  if (!tradingSymbol) return null;

  const {
    instrument, expiryDate, strikePrice, symbolType
  } = parseTradingSymbol(tradingSymbol);

  return (
    <>
      <span className="mr-1 font-weight-bold">{instrument}</span>
      {expiryDate && <span className="mr-1 font-weight-normal">{expiryDate}</span>}
      {strikePrice && <span className="mr-1 font-weight-bold">{strikePrice}</span>}
      {symbolType !== 'EQUITY' && <span>{symbolType}</span>}
    </>
  );
};

TradingSymbol.propTypes = propTypes;
TradingSymbol.defaultProps = defaultProps;

export default TradingSymbol;
