import _ from 'lodash';

const schemaToLabelMap = {
  stopLoss: 'strategy stop loss %',
  stopGain: 'strategy target profit %'
};

export const buildStrategyStopOutputSchema = () => {
  return _.chain(schemaToLabelMap)
    .map((label, name) => {
      return {
        type: 'number',
        category: 'strategyStop',
        name,
        label
      };
    })
    .value();
};
