import React from 'react';
import PropTypes from 'prop-types';
import {
  orderConfigsPropTypes, outputSchemaPropTypes,
  pipeConfigsPropTypes, signalConfigPropTypes
} from 'common/propTypes';
import { connect } from 'react-redux';
// import { RUN_TYPES } from 'common/constants/index';
import ReactHtmlParser from 'react-html-parser';
import { getSelectedOperandLabelForSignal } from 'common/utils/displayNames';
import { setPipeConfigs } from 'modules/QuantBuilder/actions';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { getRemoveConfigs } from './helper';
import { getSignalSuggestionConfig } from './config';

const propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    orderConfigIndex: PropTypes.number,
    signalConfigs: PropTypes.arrayOf(signalConfigPropTypes),
    signalConfigField: PropTypes.string,
  }).isRequired,
  onUpdateSignalConfig: PropTypes.func.isRequired,
  // runType: PropTypes.string.isRequired,
  orderConfigs: orderConfigsPropTypes.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  dispatchSetPipeConfigs: PropTypes.func.isRequired
};

const defaultProps = {};

const SuggestionSignals = ({
  config, onUpdateSignalConfig, orderConfigs, outputSchema, pipeConfigs, dispatchSetPipeConfigs
}) => {
  const oneIndentation = ' ';
  const {
    signalConfigs, signalConfigField, orderConfigIndex
  } = config;

  const removeConfigs = getRemoveConfigs(signalConfigs);
  const isShow = !_.isEmpty(removeConfigs);
  if (!isShow) return null;

  const removeConfigSentence = () => {
    const sentence = _.map(removeConfigs, (removeConfig, key) => {
      const operandConfig = getSelectedOperandLabelForSignal(outputSchema, removeConfig);
      const operandLabel = _.map(operandConfig, (
        { label, labelHtml }
      ) => { return labelHtml || label; }).join(',');

      return (
        <span className="operand-label" key={key}>
          {ReactHtmlParser(operandLabel || '')}
        </span>
      );
    });

    return <span className="signal-sentance">{sentence}</span>;
  };

  const onUpdate = () => {
    const newOrderConfigs = _.cloneDeep(orderConfigs);
    const { updatedSignalFields, isUpdatePipe, newPipeConfigs } = getSignalSuggestionConfig(
      pipeConfigs, signalConfigs
    );
    if (isUpdatePipe) { dispatchSetPipeConfigs([...newPipeConfigs]); } // update the pipeconfig
    // added 1 minute current candle...

    const updateConfig = {
      newOrderConfigs, orderConfigIndex, signalConfigField, stateSignalConfigs: updatedSignalFields
    };

    reactToastify('Please Check the signal and indicator', TOASTIFY_TYPES.INFO);
    onUpdateSignalConfig(updateConfig);
  };

  return (
    <div className="d-flex justify-content-center align-items-center my-3">
      <div className="indicator-suggestions signals-warning">
        <div className="suggestions-title">
          <i className="material-icons-outlined">warning</i>
          Warning :
        </div>
        <div className="indicator-details">
          Backtest is based on 1 minute candle data. Live deployment is based on second by second data.
          The results will vary a lot. Do you want to use
          <span className="indicator-value">
            &quot;
            {oneIndentation}
            1 minute current candle close price
            {oneIndentation}
            &quot;
          </span>
          instead of
          {oneIndentation}
          {removeConfigSentence()}
        </div>
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm btn-md-block"
            onClick={() => onUpdate()}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

SuggestionSignals.propTypes = propTypes;
SuggestionSignals.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  runType: _.get(state, 'runType'),
  orderConfigs: _.get(state, 'quantConfig.orderConfigs'),
  segment: _.get(state, 'segment'),
  outputSchema: _.get(state, 'outputSchema'),
  pipeConfigs: _.get(state, 'quantConfig.pipeConfigs')
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetPipeConfigs: (newPipeConfigs) => dispatch(setPipeConfigs(newPipeConfigs)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionSignals);
