import React, { useState } from 'react';
import InstrumentGroupBuilder from 'common/components/InstrumentGroupBuilder/index';
import classNames from 'classnames';
import TimeSelector from 'common/components/TimeSelector';
import TemplateFooter from '../../TemplateFooter';
import TemplateSentence from '../../CommonComponents/TemplateSentence';
import LabelTags from '../../CommonComponents/LabelTags';
import {
  intradyStraddleDefaultConfig,
  quantConfigConversionIntraday, INTRADAY_STRADDLE
} from './helper';

export const templateName = INTRADAY_STRADDLE;

const defaultConfig = intradyStraddleDefaultConfig;

export const Builder = (props) => {
  const segments = _.get(props, 'segments', []);
  const propSegment = _.get(props, 'segment');
  const [segment, setSegment] = useState(propSegment);

  const [templateConfig, setTemplateConfig] = useState(defaultConfig);
  const [instruments, setIntruments] = useState([]);
  const [isTimeValidate, setTimeValidate] = useState(false);
  const isNew = false;
  const oneIndentation = ' ';
  const onAddInstrument = (instrument) => {
    setIntruments([...instruments, instrument]);
  };

  const onDeleteAddedInstrument = (instrumentIndex) => {
    const newInstruments = _.filter(instruments, (instrument, index) => instrumentIndex !== index);
    setIntruments(newInstruments);
  };

  const colClassName = classNames('', {
    '': isNew
  });

  const cardClassName = classNames('card h-100', {
    'new-strategie': isNew
  });

  const handleStrategyChange = (key, value) => {
    setTemplateConfig({
      ...templateConfig,
      [key]: value
    });
  };

  return (
    <>
      <div className={colClassName}>
        <div className={cardClassName}>
          <div className="card-header">
            <h6 className="font-weight-bold">
              Intraday Straddle
            </h6>
            <div>
              {oneIndentation}
              <LabelTags
                label="intraday"
                className="intraday"
              />
              {oneIndentation}
              <LabelTags
                label="non-directional"
                className="non-directional"
              />
            </div>
          </div>

          <div className="card-body">
            <InstrumentGroupBuilder
              isInValid={false}
              instrumentGroups={instruments}
              maxInstrumentsInGroup={1}
              onAddInstrumentGroup={(newInstrumentGroup) => onAddInstrument(newInstrumentGroup)}
              onRemoveInstrumentGroup={(indexToRemove) => onDeleteAddedInstrument(indexToRemove)}
              titleMdSize="col-md-3 col-xl-2 p-0"
              inputMdSize="col-md-9 col-xl-10 p-0"
              segment={segment}
              onChangeSegment={(newSegment) => setSegment(newSegment)}
              segments={segments}
            />

            <div className="technical-indicators">

              <div className="technical-order">
                At
                <TimeSelector
                  selectedTime={templateConfig.time}
                  onTimeChange={(newTime) => {
                    handleStrategyChange('time', newTime);
                  }}
                />
                {isTimeValidate
                  && (
                    <div className="invalid-feedback d-block">
                      Please select a time between 09:15 to 15:28
                    </div>
                  )}
              </div>
              <div className="template-sentence-wrapper">
                <TemplateSentence
                  optionType={{ value: templateConfig.optionType1, key: 'optionType1' }}
                  strikeIndex={{ value: templateConfig.strikeIndex1, key: 'strikeIndex1' }}
                  actiontype={{ value: templateConfig.actiontype1, key: 'actiontype1' }}
                  stopLoss={{ value: templateConfig.stopLoss1, key: 'stopLoss1' }}
                  stopGain={{ value: templateConfig.stopGain1, key: 'stopGain1' }}
                  handleStrategyChange={handleStrategyChange}
                  quantity={{ value: templateConfig.quantity1, key: 'quantity1' }}
                  stopGainType={{ value: templateConfig.stopGain1Type, key: 'stopGain1Type' }}
                  stopLossType={{ value: templateConfig.stopLoss1Type, key: 'stopLoss1Type' }}
                  instruments={instruments}
                />
                <TemplateSentence
                  optionType={{ value: templateConfig.optionType2, key: 'optionType2' }}
                  strikeIndex={{ value: templateConfig.strikeIndex2, key: 'strikeIndex2' }}
                  actiontype={{ value: templateConfig.actiontype2, key: 'actiontype2' }}
                  stopLoss={{ value: templateConfig.stopLoss2, key: 'stopLoss2' }}
                  stopGain={{ value: templateConfig.stopGain2, key: 'stopGain2' }}
                  handleStrategyChange={handleStrategyChange}
                  quantity={{ value: templateConfig.quantity2, key: 'quantity2' }}
                  stopGainType={{ value: templateConfig.stopGain2Type, key: 'stopGain2Type' }}
                  stopLossType={{ value: templateConfig.stopLoss2Type, key: 'stopLoss2Type' }}
                  instruments={instruments}
                />
              </div>
            </div>
          </div>

          <TemplateFooter
            templateConfig={templateConfig}
            templateName={templateName}
            instruments={instruments}
            timeValidate={() => setTimeValidate(true)}
            segment={segment}
          />
        </div>
      </div>
    </>
  );
};

export const toQuantConfig = (config) => {
  return quantConfigConversionIntraday(config);
};
