import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  selectedBroker: PropTypes.string,
  brokerStructuredData: PropTypes.arrayOf(PropTypes.shape({})),
  onHandleSelectedBroker: PropTypes.func.isRequired,
};

const defaultProps = { selectedBroker: '', brokerStructuredData: [] };

const DROPDOWN_SHOW_EVENT = 'shown.bs.dropdown';

const BrokerSelectionDropDown = ({ selectedBroker, brokerStructuredData, onHandleSelectedBroker }) => {
  const [searchBroker, setSearchBroker] = useState('');
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const onDropdownShow = () => {
      // eslint-disable-next-line no-unused-expressions
      inputRef && inputRef.current && inputRef.current.focus();
    };
    if (dropdownRef && dropdownRef.current) {
      $(dropdownRef.current).on(DROPDOWN_SHOW_EVENT, onDropdownShow);
      return () => {
        $(dropdownRef.current).off(DROPDOWN_SHOW_EVENT, onDropdownShow);
      };
    }
    return () => {};
  }, [inputRef]);

  const selectedBrokerConfig = _.find(brokerStructuredData,
    (broker) => _.get(broker, 'brokerConfig.id', '') === selectedBroker);
  const selectedBrokerName = _.get(selectedBrokerConfig, 'brokerConfig.name', '');
  const filteredBrokerStructuredData = _.filter(brokerStructuredData, (broker) => {
    return _.get(broker, 'brokerConfig.name', '').toLowerCase().includes(searchBroker.toLowerCase());
  });

  const sortedBrokerStructuredData = _.sortBy(filteredBrokerStructuredData, (broker) => {
    return _.get(broker, 'sortOrder', 0);
  });

  const renderDropDownItem = (broker) => (
    <div
      className="dropdown-item"
      value={broker.brokerConfig.id}
      selected={selectedBroker === broker.brokerConfig.id}
      key={broker.brokerConfig.id}
      onClick={() => onHandleSelectedBroker(broker.brokerConfig.id)}
    >
      {broker.brokerConfig.name}
    </div>
  );

  return (
    <div className="dropdown w-100" ref={dropdownRef}>
      <button
        className="btn btn-secondary btn-block dropdown-toggle"
        type="button"
        id="brokerDropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div className="d-flex align-items-center justify-content-between">
          {selectedBrokerName || 'Select Broker'}
          <i className="material-icons-outlined">keyboard_arrow_down</i>
        </div>
      </button>
      <div className="dropdown-menu" aria-labelledby="brokerDropdownMenuButton">
        <div className="search-broker">
          <i className="material-icons-outlined">search</i>
          <input
            id="dropdownSearchInput"
            type="text"
            ref={inputRef}
            className="form-control dropdown-search"
            value={searchBroker}
            placeholder="search..."
            onChange={(event) => setSearchBroker(event.target.value)}
          />
        </div>
        {_.map(sortedBrokerStructuredData, renderDropDownItem)}
      </div>
    </div>
  );
};

BrokerSelectionDropDown.propTypes = propTypes;
BrokerSelectionDropDown.defaultProps = defaultProps;

export default BrokerSelectionDropDown;
