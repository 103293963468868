import React from 'react';
import PropTypes from 'prop-types';
import { SEGMENT_NAMES } from 'common/constants/index';

const propTypes = {
  filterConfigs: PropTypes.shape({
    segments: PropTypes.arrayOf(PropTypes.string.isRequired)
  }).isRequired,
  onUpdateFilterConfigs: PropTypes.func.isRequired
};

const defaultProps = {};

const SegmentFilter = ({ filterConfigs, onUpdateFilterConfigs }) => {
  const { segments } = filterConfigs;

  const renderOptions = (option, idx) => {
    const { value, displayName } = option;
    const onHandleSegments = () => {
      if (_.includes(segments, value)) {
        return _.filter(segments, (segment) => segment !== value);
      }

      return [...segments, value];
    };

    return (
      <div className="form-check form-check-sm" key={idx}>
        <input
          className="form-check-input track"
          type="checkbox"
          data-track-category="Market Place Filter"
          data-track-action={value}
          data-track-label="Segment Filter"
          id={value}
          value={value}
          checked={_.includes(segments, value)}
          onChange={() => {}}
          onClick={() => onUpdateFilterConfigs({
            ...filterConfigs,
            segments: onHandleSegments()
          })}
        />
        <label className="form-check-label" htmlFor={value}>
          {displayName}
        </label>
      </div>
    );
  };

  return (
    <div className="mp-filter">
      <h6 className="mp-filter-title">Segment</h6>
      <div className="mp-filter-list">
        {_.map(SEGMENT_NAMES, renderOptions)}
      </div>
    </div>
  );
};

SegmentFilter.propTypes = propTypes;
SegmentFilter.defaultProps = defaultProps;

export default SegmentFilter;
