import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFolders } from 'common/api/folder';
import qs from 'qs';
import { createRun, updateRun } from 'common/api/run';
import { TOASTIFY_TYPES, reactToastify } from 'common/utils/reactToastify';
import { initiateUserAuth } from 'common/auth/modalLogin';
import moment from 'moment';
import { quotaVerificationDom } from 'ui/QuotaVerification/quotaDom';
import { RunConfigSanitizer } from '../sanitizer/index';

const useRunSubmit = () => {
  const runConfig = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const {
    runType,
    granularity,
    quantConfig,
    startDate,
    selectedFolder,
    endDate,
    strategyId,
    runName,
    orderType,
    engineVersion,
    isEditQuant,
    hiddenByUserId,
    isHiddenSignal
  } = runConfig;
  const { instrumentGroups } = quantConfig;
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(window?.isUserSignedIn);

  const getDefaultFolderId = useCallback(async () => {
    const defaultFolderId = await getFolders().then((folders) => {
      const { id } = _.find(folders, 'is_default');
      return id;
    });

    return defaultFolderId;
  }, []);

  const executeEditRun = (runParams) => {
    const queryString = window.location.search;
    const parsedQueryString = qs.parse(queryString, { parseBooleans: true });
    const runId = _.get(parsedQueryString, 'edit_run_id');
    updateRun({ runId, run: { ...runParams } })
      .then(() => {
        window.location.href = `/runs/${runId}?locale=${I18n.locale}`;
      })
      .catch((error) => {
        try {
          error.json().then((e) => {
            const errors = _.get(e, 'errors', []);
            const errorMessages = _.join(_.get(_.values(errors), '0', []), ',');
            setIsLoading(false);
            if (errorMessages) {
              reactToastify(errorMessages, TOASTIFY_TYPES.ERROR);
            }
          });
        } catch (exception) {
          setIsLoading(false);
          reactToastify(
            'Something went wrong, unable to edit run.',
            TOASTIFY_TYPES.ERROR
          );
        }
      });
    return null;
  };

  const executeCreateRun = (runParams) => {
    createRun(runParams).catch((error) => {
      const errors = _.get(error.response, 'data.errors', []);
      const additionalErrors = _.filter(
        errors,
        (err, key) => key !== 'quant_config'
      );
      const errorMessages = _.join(_.map(additionalErrors, '0'), ', ');
      setIsLoading(false);
      if (errorMessages) reactToastify(errorMessages, TOASTIFY_TYPES.ERROR);
    });

    return null;
  };

  const handleCreateRun = async () => {
    const folderId = _.get(selectedFolder, 'id', '') || runConfig?.folderId || null;
    const runParams = {
      name: runName,
      engine_version: engineVersion || 2,
      folder_id: folderId,
      quant_config: quantConfig,
      run_type: runType,
      order_type: orderType,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      is_webhook_enabled: false,
      strategy_id: strategyId || null,
      granularity,
    };

    if (!folderId) {
      const defaultFolderId = await getDefaultFolderId();
      runParams.folder_id = defaultFolderId;
    }

    if (isHiddenSignal) {
      runParams.hidden_by_user_id = hiddenByUserId;
    }

    try {
      const sanitizedRunParams = RunConfigSanitizer.beforeSubmit(runParams);
      if (isEditQuant) {
        return executeEditRun(sanitizedRunParams);
      }
      return quotaVerificationDom(
        instrumentGroups,
        sanitizedRunParams,
        executeCreateRun
      );
    } catch (error) {
      console.error(error);
      reactToastify(
        'Something went wrong, Please try again',
        TOASTIFY_TYPES.ERROR
      );

      return setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    if (!isUserLoggedIn) {
      initiateUserAuth()
        .then(() => {
          setIsUserLoggedIn(true);
          handleCreateRun();
          setIsLoading(true);
        })
        .catch(() => {
          reactToastify(
            'Unauthorized, Please Login and continue',
            TOASTIFY_TYPES.ERROR
          );
        });
    } else {
      setIsLoading(true);
      handleCreateRun();
    }
  };

  return {
    onSubmit,
    isLoading,
  };
};

export default useRunSubmit;
