import { pipeInputSchemaTypes } from 'v2/common/quantConfig/pipeDefinitions/config';

const configuration = {
  type: 'centralPivotRange',
  defaults: {
    type: 'centralPivotRange',
    name: 'cpr',
    config: {
      timeFrame: 'day',
      category: 'traditional',
      narrowRange: 0.2,
      moderatelyRange: 0.5,
      wideRange: 0.5,
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'CPR',
    'centralPivotRange'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {
      category: [
        'traditional',
        'fibonacci',
        'woodie',
        'classic',
        'demarks',
        'camarilla'
      ]
    }
  },
  inputSchema: [
    {
      name: 'timeFrame',
      type: pipeInputSchemaTypes.select,
      label: 'Time Frame',
      options: ['day', 'week']
    },
    {
      name: 'category',
      type: pipeInputSchemaTypes.select,
      label: 'Category',
      options: [
        'traditional',
        'fibonacci',
        'woodie',
        'classic',
        'demarks',
        'camarilla'
      ]
    }
  ],
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const numericProperties = [
      'pivot',
      'BC',
      'TC',
      'R1',
      'R2',
      'R3',
      'R4',
      'R5',
      'S1',
      'S2',
      'S3',
      'S4',
      'S5'
    ];
    const booleanProperties = [
      'isNarrow',
      'isModerately',
      'isWide',
      'isInsideValue',
      'isOutsideValue',
      'isOverlappingHigherValue',
      'isOverlappingLowerValue'
    ];
    const properties = [
      ...numericProperties,
      ...booleanProperties
    ];

    return _.map(properties, (property) => {
      const schema = {
        indicatorType: type,
        name: `${name}.${property}`,
        type: 'number',
        category: 'indicator',
        label: `${name} ${property}`
      };

      if (_.includes(booleanProperties, property)) {
        schema.type = 'boolean';
        schema.enum = [0, 1];
      }

      return schema;
    });
  },
  isEnabled: true
};
export default configuration;
