import React, { useState } from 'react';
import InstrumentGroupBuilder from 'common/components/InstrumentGroupBuilder/index';
import classNames from 'classnames';
import TimeSelector from 'common/components/TimeSelector';
import { instrumentsWithWeeklyExpiry } from 'common/utils/optionExpiryCycleHelper';
import TemplateFooter from '../../TemplateFooter';
import {
  daysTemplate, monthly, weekly
} from '../../config';
import Select from '../../CommonComponents/Select';
import TemplateSentence from '../../CommonComponents/TemplateSentence';
import LabelTags from '../../CommonComponents/LabelTags';
import {
  ironFlyDefaultConfig,
  quantConfigConversionIronFly, IRON_FLY
} from './helper';

export const templateName = IRON_FLY;

const defaultConfig = ironFlyDefaultConfig;

export const Builder = (props) => {
  const segments = _.get(props, 'segments', []);

  const propSegment = _.get(props, 'segment');
  const [segment, setSegment] = useState(propSegment);

  const [templateConfig, setTemplateConfig] = useState(defaultConfig);
  const [instruments, setIntruments] = useState([]);
  const [isTimeValidate, setTimeValidate] = useState(false);
  const isNew = false;
  const oneIndentation = ' ';
  const onAddInstrument = (instrument) => {
    setIntruments([...instruments, instrument]);
  };

  const onDeleteAddedInstrument = (instrumentIndex) => {
    const newInstruments = _.filter(instruments, (instrument, index) => instrumentIndex !== index);
    setIntruments(newInstruments);
  };

  const colClassName = classNames('', {
    '': isNew
  });

  const cardClassName = classNames('card h-100', {
    'new-strategie': isNew
  });

  const handleStrategyChange = (key, value) => {
    setTemplateConfig({
      ...templateConfig,
      [key]: value
    });
  };

  const hasFutureIndexInstruments = () => {
    const futureIndexInstruments = _.filter(instruments, (instrument) => {
      return _.includes(instrumentsWithWeeklyExpiry, ...instrument);
    });

    const indexBasedInstruments = () => {
      if (_.isEmpty(futureIndexInstruments) || (
        futureIndexInstruments.length !== instruments.length
      )) return monthly;

      return [...monthly, ...weekly];
    };

    return indexBasedInstruments();
  };

  return (
    <>
      <div className={colClassName}>
        <div className={cardClassName}>
          <div className="card-header">
            <h6 className="font-weight-bold">
              IronFly
            </h6>

            <div className="">
              {oneIndentation}
              <LabelTags
                label="positional"
                className="positional"
              />
              {oneIndentation}
              <LabelTags
                label="non-directional"
                className="non-directional"
              />
            </div>
          </div>

          <div className="card-body">
            <InstrumentGroupBuilder
              isInValid={false}
              instrumentGroups={instruments}
              maxInstrumentsInGroup={1}
              onAddInstrumentGroup={(newInstrumentGroup) => onAddInstrument(newInstrumentGroup)}
              onRemoveInstrumentGroup={(indexToRemove) => onDeleteAddedInstrument(indexToRemove)}
              titleMdSize="col-md-3 col-lg-2 p-0"
              inputMdSize="col-md-9 col-lg-10 p-0"
              segment={segment}
              onChangeSegment={(newSegment) => setSegment(newSegment)}
              segments={segments}
            />

            <div className="technical-indicators">
              <div className="technical-order">
                <div>
                  <Select
                    className="custom-select form-control"
                    value={templateConfig.days}
                    onChange={(event) => handleStrategyChange('days', event.target.value)}
                    optionItems={daysTemplate()}
                  />
                </div>
                <label className="text-nowrap mb-0">days before</label>
                <div>
                  <Select
                    className="custom-select form-control"
                    value={templateConfig.monthWeek}
                    onChange={(event) => handleStrategyChange('monthWeek', event.target.value)}
                    optionItems={hasFutureIndexInstruments()}
                  />
                </div>
                <label className="text-nowrap mb-0">expiry at</label>
                <TimeSelector
                  selectedTime={templateConfig.time}
                  onTimeChange={(newTime) => {
                    handleStrategyChange('time', newTime);
                  }}
                />
                {isTimeValidate
                  && (
                    <div className="invalid-feedback d-block">
                      Please select a time between 09:15 to 15:28
                    </div>
                  )}
              </div>

              <div className="template-sentence-wrapper">
                <TemplateSentence
                  optionType={{ value: templateConfig.optionType1, key: 'optionType1' }}
                  strikeIndex={{ value: templateConfig.standardDeviation1, key: 'standardDeviation1' }}
                  actiontype={{ value: templateConfig.actiontype1, key: 'actiontype1' }}
                  stopLoss={{ value: templateConfig.stopLoss1, key: 'stopLoss1' }}
                  stopGain={{ value: templateConfig.stopGain1, key: 'stopGain1' }}
                  handleStrategyChange={handleStrategyChange}
                  isStandardDeviation
                  quantity={{ value: templateConfig.quantity1, key: 'quantity1' }}
                  stopGainType={{ value: templateConfig.stopGain1Type, key: 'stopGain1Type' }}
                  stopLossType={{ value: templateConfig.stopLoss1Type, key: 'stopLoss1Type' }}
                  instruments={instruments}

                />
                <TemplateSentence
                  optionType={{ value: templateConfig.optionType2, key: 'optionType2' }}
                  strikeIndex={{ value: templateConfig.standardDeviation2, key: 'standardDeviation2' }}
                  actiontype={{ value: templateConfig.actiontype2, key: 'actiontype2' }}
                  stopLoss={{ value: templateConfig.stopLoss2, key: 'stopLoss2' }}
                  stopGain={{ value: templateConfig.stopGain2, key: 'stopGain2' }}
                  handleStrategyChange={handleStrategyChange}
                  quantity={{ value: templateConfig.quantity2, key: 'quantity2' }}
                  stopGainType={{ value: templateConfig.stopGain2Type, key: 'stopGain2Type' }}
                  stopLossType={{ value: templateConfig.stopLoss2Type, key: 'stopLoss2Type' }}
                  instruments={instruments}
                  isStandardDeviation
                />
                <TemplateSentence
                  optionType={{ value: templateConfig.optionType3, key: 'optionType3' }}
                  strikeIndex={{ value: templateConfig.standardDeviation3, key: 'standardDeviation3' }}
                  actiontype={{ value: templateConfig.actiontype3, key: 'actiontype3' }}
                  stopLoss={{ value: templateConfig.stopLoss3, key: 'stopLoss3' }}
                  stopGain={{ value: templateConfig.stopGain3, key: 'stopGain3' }}
                  handleStrategyChange={handleStrategyChange}
                  quantity={{ value: templateConfig.quantity3, key: 'quantity3' }}
                  stopGainType={{ value: templateConfig.stopGain3Type, key: 'stopGain3Type' }}
                  stopLossType={{ value: templateConfig.stopLoss3Type, key: 'stopLoss3Type' }}
                  instruments={instruments}
                  isStandardDeviation
                />
                <TemplateSentence
                  optionType={{ value: templateConfig.optionType4, key: 'optionType4' }}
                  strikeIndex={{ value: templateConfig.standardDeviation4, key: 'standardDeviation4' }}
                  actiontype={{ value: templateConfig.actiontype4, key: 'actiontype4' }}
                  stopLoss={{ value: templateConfig.stopLoss4, key: 'stopLoss4' }}
                  stopGain={{ value: templateConfig.stopGain4, key: 'stopGain4' }}
                  handleStrategyChange={handleStrategyChange}
                  isStandardDeviation
                  quantity={{ value: templateConfig.quantity4, key: 'quantity4' }}
                  stopGainType={{ value: templateConfig.stopGain4Type, key: 'stopGain4Type' }}
                  stopLossType={{ value: templateConfig.stopLoss4Type, key: 'stopLoss4Type' }}
                  instruments={instruments}
                />
              </div>
            </div>

          </div>

          <TemplateFooter
            templateConfig={templateConfig}
            templateName={templateName}
            instruments={instruments}
            timeValidate={() => setTimeValidate(true)}
            segment={segment}
          />
        </div>
      </div>
    </>
  );
};

export const toQuantConfig = (config) => {
  return quantConfigConversionIronFly(config);
};
