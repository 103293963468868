import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defaultFilterConfig } from '../../config';

const propTypes = {
  filterConfigs: PropTypes.shape({}).isRequired,
  onUpdateFilterConfigs: PropTypes.func.isRequired,
  onUpdateModal: PropTypes.func.isRequired
};
const defaultProps = {};

const FilterHeader = ({ onUpdateFilterConfigs, filterConfigs, onUpdateModal }) => {
  const oneIndentation = ' ';
  const [count, setCount] = useState(0);

  useEffect(() => {
    let alteredCount = 0;
    _.each(filterConfigs, (filterConfig) => {
      alteredCount += _.size(filterConfig);
    });

    setCount(alteredCount);
  }, [filterConfigs]);

  return (
    <div className="card-header">
      <button
        className="btn btn-link btn-block text-left d-block d-lg-none track"
        type="button"
        data-track-category="Market Place Filter"
        data-track-action="modal"
        data-track-label="Mobile View Modal"
        onClick={() => onUpdateModal()}
      >
        <h5 className="font-weight-semi">
          <i className="material-icons-outlined align-middle mr-1">filter_list</i>
          {oneIndentation}
          Filter
          <span className="badge badge-secondary tx-11 align-middle ml-1">{count || null}</span>
          {oneIndentation}
        </h5>
      </button>

      <h5 className="d-none d-lg-block font-weight-semi">
        <i className="material-icons-outlined align-middle mr-1">filter_list</i>
        {oneIndentation}
        Filter
        <span className="badge badge-secondary tx-11 align-middle ml-1">{count || null}</span>
        {oneIndentation}
      </h5>
      <button
        className="btn btn-link btn-sm text-nowrap ml-auto track"
        onClick={onUpdateFilterConfigs}
        disabled={_.isMatch(defaultFilterConfig, filterConfigs)}
        type="button"
        data-track-category="Market Place Filter"
        data-track-action="clea-all"
        data-track-label="Clear All Filter"
      >
        Clear All
      </button>
    </div>
  );
};

FilterHeader.propTypes = propTypes;
FilterHeader.defaultProps = defaultProps;

export default FilterHeader;
