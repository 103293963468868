import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

import HoverMessage from 'common/components/HoverMessage';
import { RUN_TYPES } from 'common/constants/index';

const I18N_SCOPE = { scope: 'runs.form' };
const propTypes = {
  runType: PropTypes.string.isRequired,
  onChangeRunType: PropTypes.func.isRequired,
  isHistoricRunDisabled: PropTypes.bool.isRequired,
  isLiveRunDisabled: PropTypes.bool.isRequired,
};
const defaultProps = {};

const RunType = ({
  runType, onChangeRunType, isHistoricRunDisabled, isLiveRunDisabled
}) => {
  const currentUserBroker = _.get(window, 'currentUserBroker', '');
  const isUserSignedIn = _.get(window, 'isUserSignedIn', false);

  const isNonBrokerUser = isUserSignedIn && _.isEmpty(currentUserBroker);

  const disabledMessage = () => {
    if (isNonBrokerUser) {
      return 'Run creations are not supported for non-broker users. '
        + 'Please sign-in with broker account to create runs.';
    }
    if (isHistoricRunDisabled && isLiveRunDisabled) {
      return 'Historic and live run creation is temporarily disabled';
    }
    if (isHistoricRunDisabled) return 'Historic run creation is temporarily disabled';

    return 'Live run creation is temporarily disabled';
  };

  const runTypeBtns = (
    <ToggleButtonGroup
      className="btn-block"
      type="radio"
      size="sm"
      name="options"
      defaultValue={runType}
      onChange={onChangeRunType}
    >
      <ToggleButton
        variant="outline-primary"
        value={RUN_TYPES.historic}
        size="sm"
        disabled={isHistoricRunDisabled || isNonBrokerUser}
      >
        {I18n.t('historic', I18N_SCOPE)}
      </ToggleButton>
      <ToggleButton
        size="sm"
        variant="outline-primary"
        value={RUN_TYPES.live}
        disabled={isLiveRunDisabled || isNonBrokerUser}
      >
        {I18n.t('live', I18N_SCOPE)}
      </ToggleButton>
    </ToggleButtonGroup>
  );
  return (
    <div className="run-type-btn run-type-container">
      <label htmlFor="colFormLabelSm" className="col-form-label col-form-label-sm">
        {I18n.t('run_type', I18N_SCOPE)}
      </label>
      <div>
        {(isHistoricRunDisabled || isLiveRunDisabled || isNonBrokerUser)
          ? <HoverMessage title="Run Type" message={disabledMessage()}>{runTypeBtns}</HoverMessage>
          : runTypeBtns}
      </div>
    </div>
  );
};

export default RunType;

RunType.propTypes = propTypes;
RunType.defaultProps = defaultProps;
