import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { OrderContext } from 'ui/run/RunShow/OrderStore/index';

const propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  onSpecialFilterChange: PropTypes.func.isRequired,
  specialFilter: PropTypes.string,
};
const defaultProps = {
  specialFilter: null
};

const Filters = ({
  onFilterChange, specialFilter, onSpecialFilterChange
}) => {
  const {
    transactions, inCompleteTransactions
  } = useContext(OrderContext);

  const filterConfigs = {
    totalTransaction: {
      value: _.flatten(transactions.concat([])).reverse(),
      displayName: 'Transactions',
      id: 'totalTransaction',
      iconStyle: '',
    },
    totalIncompleteTransactions: {
      value: _.flatten(inCompleteTransactions.concat([])).reverse(),
      displayName: 'Incomplete',
      id: 'totalIncompleteTransactions',
      iconStyle: 'incomplete',
    },
    // totalIlliquidTransactions: {
    //   value: _.flatten(illiquidTransactions.concat([])).reverse(),
    //   displayName: 'Illiquid',
    //   id: 'totalIlliquidTransactions',
    //   iconStyle: 'illiquid',
    // }
  };

  const onChange = (newFilter) => {
    onSpecialFilterChange(newFilter);
    onFilterChange(filterConfigs[newFilter].value);
  };

  const filterOptionBtns = () => _.map(filterConfigs, ({
    value, displayName, id, iconStyle
  }) => {
    if (_.isEmpty(value)) return null;
    return (
      <ToggleButton
        bsPrefix="btn btn-outline-primary btn-sm btn-lightness d-flex align-items-center"
        value={id}
        key={id}
      >
        <>
          <div className={iconStyle} />
          <span className="font-weight-bold mr-1">{value.length}</span>
          {displayName}
        </>
      </ToggleButton>
    );
  });

  return (
    <ToggleButtonGroup
      bsPrefix="btn-group btn-group-toggle btn-sm"
      type="radio"
      name="filter"
      value={specialFilter}
      onChange={onChange}
    >
      {filterOptionBtns()}
    </ToggleButtonGroup>
  );
};

Filters.propTypes = propTypes;
Filters.defaultProps = defaultProps;

export default Filters;
