import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import ReactHtmlParser from 'react-html-parser';

import { createUsersDemos } from 'common/api/siteTourDemo';

const propTypes = {
  id: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    target: PropTypes.string,
    content: PropTypes.string
  }))
};
const defaultProps = {
  steps: [],
  id: null,
};

class SiteTour extends React.Component {
  constructor(props) {
    super(props);
    this.state = { run: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ run: true });
    }, 500);
  }

  handleCallback = (data) => {
    if (data.type === 'tour:end') {
      const { id } = this.props;
      createUsersDemos(id);
    }
  }

  render() {
    const { steps } = this.props;
    const { run } = this.state;
    const parsedSteps = _.map(steps, (step) => {
      return {
        ...step,
        content: ReactHtmlParser(step.content)
      };
    });

    return (
      <div className="app">
        <Joyride
          steps={parsedSteps}
          run={run}
          continuous
          scrollToFirstStep
          callback={this.handleCallback}
          showProgress
          showSkipButton
          styles={{
            options: {
              zIndex: 1000,
            }
          }}

        />
      </div>
    );
  }
}

SiteTour.propTypes = propTypes;
SiteTour.defaultProps = defaultProps;

export default SiteTour;
