import React from 'react';
import { NOTIFICATION_EVENTS, RUN_TYPES } from 'common/constants';
import notificationPropType from './propTypes/notificationPropType';

const propTypes = {
  notification: notificationPropType.isRequired,
};

const defaultProps = {};

const RunDetailNotificationMessage = ({ notification }) => {
  const { details, event } = notification;
  const oneIndentation = ' ';

  const statusEvent = () => {
    switch (event) {
      case _.includes(_.values(NOTIFICATION_EVENTS.RUN.EVENTS), event):
        return 'failed';
      default:
        return event;
    }
  };

  const renderMessage = () => {
    const { run_type: runType, run_name: fileName, folder_name: folderName, } = details;
    const testType = (runType === RUN_TYPES.historic)
      ? 'Backtest' : 'Live Depolyment';
    const status = statusEvent();
    const runFileName = `(${fileName})`;
    const runFolderName = `(${folderName})`;

    return (
      <span>
        <span className="noti-status">{testType}</span>
        {oneIndentation}
        {status}
        ,
        <span className="noti-file">
          {oneIndentation}
          {runFileName}
        </span>
        {oneIndentation}
        in
        {oneIndentation}
        <span className="noti-file">
          {runFolderName}
        </span>
      </span>
    );
  };

  return renderMessage();
};

RunDetailNotificationMessage.defaultProps = defaultProps;
RunDetailNotificationMessage.propTypes = propTypes;

export default RunDetailNotificationMessage;
