// import { automaticSupportedBrokers } from 'common/brokerConfigs/config';
import {
  FIXED_AMOUNT, FREE, REQUEST_TYPE, STRATEGY_ACCESS_STATUS
} from 'common/constants/index';
import moment from 'moment';
import { marginFilterConfig, purchaseFilterConfig, tagsconfig } from './config';

export const ACTIVE_STRATEGY_TYPE = 'activestrategytype';

const validation = (anonymousNumericValue, anonymousArray, anonymousConfig) => {
  if (_.isEmpty(anonymousArray)) return true;
  if (anonymousArray) {
    const booleanArray = _.map(anonymousArray, (value) => {
      const matchConfig = _.find(anonymousConfig, ({ value: configValue }) => configValue === value);
      if (!matchConfig) return false;

      const { rangeStart, rangeEnd } = matchConfig;
      return anonymousNumericValue >= rangeStart && anonymousNumericValue <= rangeEnd;
    });

    return !!_.compact(booleanArray).length;
  }
  return false;
};

export const getFilteredStrategies = (strategies, filterConfigs) => {
  const {
    segments, margins, categories, timePeriods, instruments, purchaseTypes, purchaseAmounts, tags,
    premiums, authorNames
  } = filterConfigs;

  if (_.isEmpty(segments)
    && _.isEmpty(margins)
    && _.isEmpty(categories)
    && _.isEmpty(timePeriods)
    && _.isEmpty(instruments)
    && _.isEmpty(purchaseTypes)
    && _.isEmpty(purchaseAmounts)
    && _.isEmpty(tags)
    && _.isEmpty(premiums)
    && _.isEmpty(authorNames)) { return strategies; }

  return _.chain(strategies)
    .filter(({
      segment_type: segmentType
    }) => {
      if (_.isEmpty(segments)) return true;
      if (segments && _.includes(segments, segmentType)) return true;

      return false;
    })
    .filter(({
      margin_amount: marginAmount
    }) => {
      return validation(marginAmount, margins, marginFilterConfig);
    })
    .filter(({
      purchase_amount: purchaseAmount
    }) => {
      return validation(purchaseAmount, purchaseAmounts, purchaseFilterConfig);
    })
    .filter(({
      risk_category: riskCategory
    }) => {
      if (_.isEmpty(categories)) return true;
      if (categories && _.includes(categories, riskCategory)) return true;
      return false;
    })
    .filter(({
      purchase_type: purchaseType, is_premium: isPremium
    }) => {
      if (_.isEmpty(purchaseTypes)) return true;
      if (isPremium && _.includes(purchaseTypes, 'premium')) return true;
      if (purchaseTypes && _.includes(purchaseTypes, purchaseType)) return true;
      return false;
    })
    .filter(({
      live_instrument_groups: liveInstrumentGroups
    }) => {
      if (_.isEmpty(instruments)) return true;

      return _.some(instruments, (instrument) => _.includes(_.flattenDeep(liveInstrumentGroups), instrument));
    })
    .filter(({
      tags: strategyTags
    }) => {
      if (_.isEmpty(tags)) return true;
      const strategyTagsAltered = _.map(strategyTags, (tag) => _.lowerCase(tag));

      return _.some(tags, (tag) => { return tagsconfig[tag](strategyTagsAltered); });
    })
    .filter(({ is_premium: isPremium }) => {
      if (_.isEmpty(premiums)) return true;
      if (premiums && _.includes(premiums, isPremium)) return true;

      return false;
    })
    .filter(({
      author_name: authorName
    }) => {
      if (_.isEmpty(authorNames)) return true;
      if (authorNames && _.includes(authorNames, authorName)) return true;

      return false;
    })
    .value();
};

export const getInstrumentOptions = (strategies) => {
  const instruments = _.map(strategies, ({ live_instrument_groups: liveInstrumentGroups }) => {
    return liveInstrumentGroups || [];
  });

  return _.uniq(_.flattenDeep(instruments));
};

export const hasDeployStrategyDisabled = (strategy, purchaseType) => {
  const strategyAccess = _.get(strategy, 'strategy_access', {});
  const { isUserSignedIn } = window;
  const isEmptyStrategyAccess = _.isEmpty(strategyAccess); // for initial users

  if (!isUserSignedIn) return true;
  if (isEmptyStrategyAccess && purchaseType === FREE) return false;
  if (isEmptyStrategyAccess) return true;

  const { is_deployed: isDeployed, status } = strategyAccess;

  if (status !== STRATEGY_ACCESS_STATUS.PAID) return true;

  return status === STRATEGY_ACCESS_STATUS.PAID && isDeployed;
};

export const buttonTypesInStrategy = {
  deployButton: 'deployButton',
  requestType: 'requestType'
};

export const handleDisabledButtonInMarketPlace = (strategy, type) => {
  const currentUser = _.get(strategy, 'currentUser', {});
  const strategyUserId = _.get(strategy, 'user_id', null);
  const currentUserId = _.get(currentUser, 'id', '');
  const isStrategyBelongsToUser = strategyUserId === currentUserId;
  const purchaseType = _.get(strategy, 'purchase_type', '');
  const strategyAccess = _.get(strategy, 'strategy_access', {});
  const isEmptyStrategyAccess = _.isEmpty(strategyAccess); // for initial users
  const isDeployed = _.get(strategyAccess, 'is_deployed', false);
  const status = _.get(strategyAccess, 'status', '');
  const paidTill = _.get(strategyAccess, 'paid_till', '');

  const strategyAccessCreatedAt = _.get(strategyAccess, 'created_at', '');
  const freeTrialDays = _.get(strategy, 'initial_free_trial_days', 0);

  const isFreeTrialAvailable = freeTrialDays > 0 && isEmptyStrategyAccess;
  const freeTrialTill = moment(strategyAccessCreatedAt).add(freeTrialDays, 'days').format();
  const isInFreeTrialPeriod = (
    freeTrialDays > 0
    && !isEmptyStrategyAccess
    && (_.isEmpty(paidTill)
      && moment(strategyAccessCreatedAt).isBefore(freeTrialTill))
  );

  // const broker = _.get(currentUser, 'broker', '');

  const { subscribePremiumPlan } = window.userPermissions;
  const isEnabled = _.get(subscribePremiumPlan, 'isEnabled', false);
  const disabledReason = _.get(subscribePremiumPlan, 'disabledReason', '');

  if (isStrategyBelongsToUser) { // case 2: owner so disabled
    return { isDisabled: true, message: 'You are the owner of strategy' };
  }

  // if (!_.includes(automaticSupportedBrokers, broker)) { // case 3: current user is support for broker
  //   return { isDisabled: true, message: 'Market place is only for automatic and Paper' };
  // }

  if (type === buttonTypesInStrategy.deployButton) { // for deploy button
    if (!isEnabled && strategy.is_premium) { return { isDisabled: true, message: disabledReason }; }

    if (status === STRATEGY_ACCESS_STATUS.PAID && isDeployed) { // case 6: Already Deployed the strategy
      return { isDisabled: true, message: 'Already Deployed the strategy' };
    }

    if (purchaseType === FIXED_AMOUNT) {
      if (!isFreeTrialAvailable && !isInFreeTrialPeriod) { // not a free trail
        if (isEmptyStrategyAccess) { // case 7: Expired
          return { isDisabled: true, message: 'Please purchase' };
        }

        if ((!isEmptyStrategyAccess
          && status !== STRATEGY_ACCESS_STATUS.PAID)) { // case 5: Purchase a strategy
          return { isDisabled: true, message: 'Please purchase a strategy' };
        }

        if (!isEmptyStrategyAccess && !(strategyAccessCreatedAt
          && moment().isBefore(paidTill))) { // case 7: Expired
          return { isDisabled: true, message: 'Expired, Please renew your plan' };
        }
      }
    }

    if (purchaseType === REQUEST_TYPE) {
      if (status === '' && !isDeployed) { // case 6: Already Deployed the strategy
        return { isDisabled: true, message: 'Please request the strategy' };
      }
      if (!isEmptyStrategyAccess && status === STRATEGY_ACCESS_STATUS.REQUESTED) { // case 8: request sent
        return { isDisabled: true, message: 'Your request has been sent to Strategy owner' };
      }
      if (!isEmptyStrategyAccess && status === STRATEGY_ACCESS_STATUS.REJECTED) { // case 10: rejected
        return { isDisabled: true, message: 'Your request has been rejected by strategy owner' };
      }
    }
  }

  if (type === buttonTypesInStrategy.requestType) { // for request type button
    if (!isEmptyStrategyAccess && status === STRATEGY_ACCESS_STATUS.REQUESTED) { // case 8: request sent
      return { isDisabled: true, message: 'Your request has been sent to Strategy owner' };
    }
    if (!isEmptyStrategyAccess && status === STRATEGY_ACCESS_STATUS.PAID) { // case 9: paid
      return { isDisabled: true, message: 'Your request has been approved by strategy owner' };
    }
    if (!isEmptyStrategyAccess && status === STRATEGY_ACCESS_STATUS.REJECTED) { // case 10: rejected
      return { isDisabled: true, message: 'Your request has been rejected by strategy owner' };
    }
  }

  return { isDisabled: false, message: '' };
};

export const getDisplayStrategies = (strategies, currentPageIndex, strategiesPerIndex) => {
  if (_.isEmpty(strategies)) return [];

  const totalStrategies = _.size(strategies);
  const startOfRange = strategiesPerIndex * currentPageIndex;
  const endOfRange = startOfRange + strategiesPerIndex < totalStrategies
    ? startOfRange + strategiesPerIndex
    : totalStrategies;
  const strategyRange = _.range(startOfRange, endOfRange);

  return _.flattenDeep(_.map(strategyRange, (idx) => _.get(strategies, idx, [])));
};

export const buildCategory = (label, value, isShow, icon) => {
  return {
    label, value, isShow, icon
  };
};
