import React from 'react';

import { instrumentGroupsPropTypes } from 'common/propTypes';
import { removeExchangeFromInstrument } from 'common/utils/InstrumentUtils';
import withErrorBoundary from './withErrorBoundary/index';

const propTypes = {
  instrumentGroups: instrumentGroupsPropTypes,
};
const defaultProps = {
  instrumentGroups: [[]]
};

const InstrumentGroupsSentence = ({ instrumentGroups }) => {
  if (_.isEmpty(instrumentGroups)) {
    return (
      <div>(None)</div>
    );
  }

  return _.map(instrumentGroups, (instrumentGroup, index) => {
    const sanitizeInstrumentGroup = _.map(instrumentGroup,
      (instrument) => removeExchangeFromInstrument(instrument));

    return (
      <span className="instrument-group" key={index}>
        {sanitizeInstrumentGroup.join('-')}
        {index !== instrumentGroups.length - 1 && ', '}
      </span>
    );
  });
};

InstrumentGroupsSentence.propTypes = propTypes;
InstrumentGroupsSentence.defaultProps = defaultProps;

export default withErrorBoundary(InstrumentGroupsSentence, 'Company', false);
