import React, { useState } from 'react';
import Modal from 'common/components/ModalComponent';
import PropTypes from 'prop-types';

import sendOtp from 'common/api/otp';
import { signOut } from 'common/api/user';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import PhoneNumberForm from './PhoneNumberForm';
import OtpForm from './OtpForm';

const propTypes = {
  isSessionActive: PropTypes.bool.isRequired,
  onCloseModalPresent: PropTypes.bool,
  onCloseModal: PropTypes.func,
  routePath: PropTypes.string.isRequired
};
const defaultProps = {
  onCloseModalPresent: false,
  onCloseModal: () => { }
};

const PhoneNumberVerification = ({
  isSessionActive, onCloseModalPresent, onCloseModal, routePath
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSendingOtp, setIsSendingOtp] = useState(isSessionActive);

  const onPhoneNumberSubmit = () => {
    sendOtp(phoneNumber)
      .then((res) => {
        if (!res.data.status) { reactToastify(res.data.message, TOASTIFY_TYPES.ERROR); } else {
          setIsSendingOtp(true);
          reactToastify(res.data.message, TOASTIFY_TYPES.SUCCESS);
        }
      });
  };

  const onResendOtp = () => {
    if (!phoneNumber) {
      setIsSendingOtp(false);
      return;
    }
    onPhoneNumberSubmit();
  };

  return (
    <Modal
      size="md"
      onClose={onCloseModalPresent ? onCloseModal : signOut}
      className="mobile-verification"
    >
      {!isSendingOtp
        ? (
          <PhoneNumberForm
            phoneNumber={phoneNumber}
            onPhoneNumberChange={setPhoneNumber}
            onPhoneNumberSubmit={onPhoneNumberSubmit}
          />
        )
        : (
          <OtpForm
            phoneNumber={phoneNumber}
            onResendOtp={onResendOtp}
            onEditPhoneNumber={() => setIsSendingOtp(false)}
            routePath={routePath}
          />
        )}
    </Modal>
  );
};

PhoneNumberVerification.propTypes = propTypes;
PhoneNumberVerification.defaultProps = defaultProps;

export default PhoneNumberVerification;
