import React, { createContext } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import useIntents from '../hooks/useIntents/index';

export const IntentsContext = createContext(null);

const withIntentsContext = (UIComponent) => {
  const WithIntentsContext = (props) => {
    const {
      errors, setError, clearErrors, control
    } = useFormContext();

    const {
      pipeName,
      isPipeIntent,
      onUpdateIntents,
      intents,
      modifyLegsTargetSchema,
      intentDependencies,
      isAdjustmentIntent,
      errors: propsErrors,
      control: propsControl,
      setError: propsSetError,
      clearErrors: propsClearErrors,
      adjustmentIndex,
      caseIndex,
      pipeIndex,
    } = props;

    const formContext = {
      control: propsControl || control,
      errors: propsErrors || errors,
      setError: propsSetError || setError,
      clearErrors: propsClearErrors || clearErrors,
    };

    const {
      onUpdateIntent,
      onAddIntent,
      onDeleteIntent,
      errorMessage,
      errorConfigs,
    } = useIntents(
      intents,
      onUpdateIntents,
      isPipeIntent,
      pipeName,
      formContext.control,
      formContext.errors,
      formContext.setError,
      formContext.clearErrors,
    );

    return (
      <IntentsContext.Provider
        value={{
          intents,
          onUpdateIntent,
          onAddIntent,
          onDeleteIntent,
          errorMessage,
          errorConfigs,
          modifyLegsTargetSchema,
          intentDependencies,
          isAdjustmentIntent,
          isPipeIntent,
          control: formContext.control,
          errors: formContext.errors,
          setError: formContext.setError,
          clearErrors: formContext.clearErrors,
          adjustmentIndex,
          caseIndex,
          pipeIndex
        }}
      >
        <UIComponent {...props} />
      </IntentsContext.Provider>
    );
  };

  WithIntentsContext.propTypes = {
    pipeName: PropTypes.string,
    isPipeIntent: PropTypes.bool,
    onUpdateIntents: PropTypes.func,
    intents: PropTypes.arrayOf(PropTypes.shape({})),
    modifyLegsTargetSchema: PropTypes.arrayOf(PropTypes.shape({})),
    intentDependencies: PropTypes.shape({}),
    isAdjustmentIntent: PropTypes.bool,
    errors: PropTypes.shape({}),
    control: PropTypes.shape({}),
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    adjustmentIndex: PropTypes.number,
    caseIndex: PropTypes.number,
    pipeIndex: PropTypes.number,
  };

  WithIntentsContext.defaultProps = {
    pipeName: '',
    isPipeIntent: false,
    onUpdateIntents: () => {},
    intents: [],
    modifyLegsTargetSchema: [],
    intentDependencies: {},
    isAdjustmentIntent: false,
    errors: null,
    control: null,
    setError: null,
    clearErrors: null,
    adjustmentIndex: undefined,
    caseIndex: undefined,
    pipeIndex: undefined,
  };

  return WithIntentsContext;
};

export default withIntentsContext;
