import React from 'react';
import { optimizeFields } from 'ui/run/RunShow/OrderStore/metrics/formula';
import PropTypes from 'prop-types';

const propTypes = {
  optimizeBy: PropTypes.string.isRequired,
  handleOptimizeBy: PropTypes.func.isRequired,
  onClickRefineBtn: PropTypes.func.isRequired,
};
const defaultProps = {};

const OptimizeBy = ({ optimizeBy, handleOptimizeBy, onClickRefineBtn }) => {
  const optimizeValues = _.keys(optimizeFields);
  return (
    <div className="optimize-card">
      <div className="optimize-by">
        <label>Optimize By</label>
        <select
          className="form-select"
          value={optimizeBy}
          onChange={(e) => {
            handleOptimizeBy(e.target.value);
          }}
        >
          <option value="">None</option>
          {optimizeValues.map((value) => (
            <option key={value} value={value}>{value}</option>
          ))}
        </select>
        <button
          type="button"
          className="btn btn-primary refineBtn"
          onClick={onClickRefineBtn}
          title="Open the combined results with optimized runs only"
        >
          Refine
        </button>
      </div>
    </div>
  );
};

OptimizeBy.propTypes = propTypes;
OptimizeBy.defaultProps = defaultProps;

export default OptimizeBy;
