import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import ReactHtmlParser from 'react-html-parser';
import { sendOtpToBrokerUser } from 'common/api/user';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { getBrokerImage, reDirectPage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

const defaultProps = {};

const Profitmart = ({ onCloseModal, broker }) => {
  const [isValid, setIsValid] = useState(true);
  const [clientId, setClientId] = useState('');
  const [password, setpassword] = useState('');
  const [totp, setTotp] = useState('');

  const onNavigate = () => {
    if (!clientId || !password || !totp) return setIsValid(false);
    const reDirectData = {
      client_id: clientId,
      password,
      totp
    };
    reDirectPage(reDirectData, broker);

    return '';
  };

  const onSendOtp = (isResendOtp = false) => {
    if (!clientId || !password) {
      setIsValid(false);
    }

    sendOtpToBrokerUser({ client_id: clientId, password }, isResendOtp, broker).then((res) => {
      const { status, message } = res;
      if (!status) {
        reactToastify(message, TOASTIFY_TYPES.ERROR);
      } else {
        reactToastify('Sent OTP', TOASTIFY_TYPES.SUCCESS);
      }
    }).catch(() => { });

    setIsValid(true);

    return null;
  };

  const modalProps = {
    onClose: onCloseModal,
    shouldShow: true,
    size: 'sm',
    onSaveid: `btn-${broker}`,
    onSave: () => onNavigate(),
    okBtnTitle: 'Login',
    isOkBtnDisabled: false
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="mb-3">
        {ReactHtmlParser(getBrokerImage(broker))}
        <label className="mt-2">Client Id</label>
        <input
          className="form-control"
          type="text"
          id={`${broker}-id`}
          value={clientId}
          onChange={(event) => { setClientId(event.target.value); }}
        />
        <label className="mt-2">Password</label>
        <input
          className="form-control"
          type="password"
          id={`${broker}-password`}
          value={password}
          onChange={(event) => { setpassword(event.target.value); }}
        />
        <label className="mt-2">OTP/TOTP</label>
        <input
          className="form-control"
          type="text"
          id={`${broker}-totp`}
          value={totp}
          onChange={(event) => { setTotp(event.target.value); }}
        />
        <div className="text-right mb-2">
          <button className="btn btn-link btn-sm" type="button" onClick={() => onSendOtp()}>
            Send OTP
          </button>
        </div>
        {!isValid && <div className="invalid-feedback d-block">Please provide the details</div>}
      </div>
    </ModalComponent>
  );
};

Profitmart.propTypes = propTypes;
Profitmart.defaultProps = defaultProps;

export default Profitmart;
