import Modal from 'common/components/ModalComponent';
import PropTypes from 'prop-types';
import React, {
  useEffect, useState, useRef, useCallback
} from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import {
  outputSchemaPropTypes, pipeConfigPropTypes, pipeConfigsPropTypes
} from 'common/propTypes';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';

import {
  CANDLE_INTERVALS,
  configDisplayNames,
  PipeDefinitionUtility,
} from 'v2/common/quantConfig/index';
import {
  getSelectedLabelForIndicator, getValuePathOptionsForIndicator, removeTagsFromString
} from 'common/utils/displayNames';
import { getConfigAndPeriodValidator } from 'common/utils/validators';
import ErrorMesssage from 'common/components/ErrorMesssage';
import ReactHtmlParser from 'react-html-parser';
import { sanitizeOutputSchema } from 'ui/run/RunForm/config';
import TimeSelector from 'common/components/TimeSelector';
import DateSelector from 'common/components/DateSelector';
import moment from 'moment';
import Hints from './Hints';
import { indicatorNameChange, isInvalidForInstrument } from '../helper';
import { getDropDownFieldValues } from './helper';
import CustomScriptConfigForm from './customScriptConfigForm';

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes,
  validators: PropTypes.shape({}),
  preOccupiedPipeNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  segment: PropTypes.string,
  maxInstrumentsInGroup: PropTypes.number.isRequired,
};

const defaultProps = {
  outputSchema: {},
  validators: {},
  title: '',
  segment: '',
  onSubmit: () => { }
};

const PipeForm = (props) => {
  const {
    pipeConfig, onUpdate, outputSchema, title, onClose, onSubmit, validators, pipeConfigs,
    maxInstrumentsInGroup, segment
  } = props;
  const [shouldConfirmClose, setShouldConfirmClose] = useState(false);
  const {
    errors, handleSubmit, control, reset, setValue, trigger
  } = useForm();
  const indicatorTypeRef = useRef();

  const { config, type } = pipeConfig;

  const isInvalid = isInvalidForInstrument(pipeConfig, maxInstrumentsInGroup);

  useEffect(() => {
    if (isInvalid) {
      onUpdate({ ...pipeConfig, config: { ...config, valuePaths: [_.head(pipeConfig.config.valuePaths)] } });
    }
  }, [isInvalid]);

  useEffect(() => {
    setValue('name', pipeConfig.name);
    _.each(pipeConfig.config, (configValue, configKey) => { setValue(configKey, configValue); });
    reset({ valuePaths: pipeConfig.config.valuePaths });
  }, [pipeConfig]);

  const onPipeConfigUpdate = (updatedValue) => {
    onUpdate({ ...pipeConfig, ...updatedValue });
    setShouldConfirmClose(true);
  };

  const onUpdateConfigInput = (key, value, isUpdateNormally = false) => {
    const newConfig = _.cloneDeep(config);

    if (isUpdateNormally) {
      newConfig[key] = value;
    } else {
      newConfig[key] = /[a-zA-Z]/g.test(value) ? value : parseFloat(value);
    }
    onPipeConfigUpdate({ config: newConfig });
  };

  const getPipeNameValidator = () => {
    return {
      ..._.get(validators, 'name'),
      validate: (pipeName) => (_.includes(props.preOccupiedPipeNames, pipeName) ? 'Name already used' : null)
    };
  };

  const getValuePathValidator = (valuePathValidator) => {
    if (!valuePathValidator) return null;

    const { minLength, maxLength } = valuePathValidator;
    return {
      ...valuePathValidator,
      validate: (newValuePath) => {
        if (_.size(newValuePath) > maxLength.value) return maxLength.message;
        if (_.size(newValuePath) < minLength.value) return minLength.message;
        if (isInvalid) return 'should have pair trade';

        return null;
      }
    };
  };

  const renderValuePathsOptions = (validator) => {
    const { config: { valuePaths } } = pipeConfig;
    const isNonEditableValuePaths = _.includes(
      PipeDefinitionUtility.getIndicatorsWithNonEditableValuePaths(),
      pipeConfig.type
    );

    const selectedArray = getSelectedLabelForIndicator(valuePaths, outputSchema);
    const onValuePathChange = (value, onChange) => {
      const newValuePath = _.map(value, 'id');

      onUpdateConfigInput('valuePaths', (newValuePath));
      onChange(newValuePath);
    };

    const options = getValuePathOptionsForIndicator(sanitizeOutputSchema(outputSchema, segment));
    // based on segment shown shcema
    return (
      <Controller
        render={({ onChange, ref, name }) => (
          <Typeahead
            className="custom-form-control"
            multiple
            disabled={isNonEditableValuePaths}
            ref={ref}
            isInvalid={!!errors.valuePaths}
            id={name}
            options={options}
            renderMenuItemChildren={(option) => { return ReactHtmlParser(option.labelHtml || option.label); }}
            selected={removeTagsFromString(selectedArray)}
            onChange={(value) => onValuePathChange(value, onChange)}
          />
        )}
        control={control}
        defaultValue={selectedArray}
        name="valuePaths"
        rules={getValuePathValidator(validator)}
      />
    );
  };
  const renderAccuracy = (validator) => {
    const { config: { accuracy } } = pipeConfig;
    const accuracyValues = [90, 95, 99];
    const accuracyValuesStyle = classNames(errors.accuracy ? 'is-invalid' : '');

    return (
      <div>
        <Controller
          render={({ onChange, ref, name }) => (
            <Form.Control
              as="select"
              size="sm"
              name={name}
              value={accuracy}
              className={accuracyValuesStyle}
              ref={ref}
              onChange={(event) => {
                onUpdateConfigInput(event.target.name, event.target.value);
                onChange(event.target.value);
              }}
            >
              {_.map(accuracyValues, (accuracyValue, index) => (
                <option key={index} value={accuracyValue}>{accuracyValue}</option>
              ))}
            </Form.Control>
          )}
          control={control}
          defaultValue={accuracy}
          name="accuracy"
          rules={validator}
        />

      </div>
    );
  };

  const renderDropDownFields = (validator, configKey) => {
    const { id, options, value } = getDropDownFieldValues(pipeConfig, configKey);
    if (_.isEmpty(options)) return null;
    const dropDownStyle = classNames(errors[id] ? 'is-invalid' : '');

    return (
      <div>
        <Controller
          render={({ onChange, ref, name }) => (
            <Form.Control
              as="select"
              size="sm"
              name={name}
              value={value}
              className={dropDownStyle}
              ref={ref}
              onChange={(event) => {
                const isResetDateAndTime = value === 'dateTime'
                  && event.target.value !== 'dateTime' && configKey === 'basedOn';
                if (isResetDateAndTime) {
                  onPipeConfigUpdate({
                    config: {
                      ...config, date: '', time: '', [event.target.name]: event.target.value
                    }
                  });
                } else {
                  onUpdateConfigInput(event.target.name, event.target.value);
                }
                onChange(event.target.value);
              }}
            >
              {_.map(options, (option) => (<option key={option} value={option}>{option}</option>))}
            </Form.Control>
          )}
          control={control}
          defaultValue={value}
          name={id}
          rules={validator}
        />
      </div>
    );
  };

  const renderCandleInterval = (configValue, configKey, validator) => {
    const candleIntervalStyle = classNames(
      'custom-select',
      errors.candleInterval ? 'is-invalid' : ''
    );
    const candleIntervalOptions = _.map(CANDLE_INTERVALS, ({ displayValue, value }, idx) => (
      <option key={idx} value={value}>{displayValue}</option>
    ));

    return (
      <Controller
        render={({ onChange, name, ref }) => (
          <Form.Control
            className={candleIntervalStyle}
            as="select"
            size="sm"
            name={name}
            ref={ref}
            value={configValue}
            onChange={(event) => {
              onUpdateConfigInput(configKey, event.target.value);
              onChange(event.target.value);
            }}
          >
            {candleIntervalOptions}
          </Form.Control>
        )}
        control={control}
        defaultValue={configValue}
        name="candleInterval"
        rules={validator}
      />
    );
  };

  const renderPipeTypes = () => {
    const onSearchSelect = (result) => {
      if (_.isEmpty(result)) return;

      indicatorTypeRef.current.blur();
      const newIndicatorType = _.get(result, [0, 'value']);
      onPipeConfigUpdate(indicatorNameChange(pipeConfigs, newIndicatorType, segment));
    };

    return (
      <div>
        <label className="col-form-label col-form-label-sm">Indicator Type</label>
        <div className="inside-icon">
          <i className="icons material-icons-outlined">search</i>
          <Typeahead
            className="w-100"
            size="sm"
            id={type}
            ref={indicatorTypeRef}
            onFocus={(event) => event.target.select()}
            clearButton
            selected={[type]}
            highlightOnlyResult
            options={PipeDefinitionUtility.getPipeConfigSearchTerms()}
            onChange={onSearchSelect}
          />
        </div>
      </div>
    );
  };

  const renderPipeName = () => {
    const { name } = pipeConfig;
    const pipeNameStyle = classNames(errors.name ? 'is-invalid' : '');

    return (
      <Form.Group>
        <Form.Label className="col-form-label col-form-label-sm">Name</Form.Label>
        <Controller
          render={({ onChange, ref }) => (
            <div className="inside-icon">
              <i className="icons material-icons-outlined">edit</i>
              <Form.Control
                type="text"
                name="name"
                size="sm"
                ref={ref}
                className={pipeNameStyle}
                value={name}
                onChange={(event) => {
                  onPipeConfigUpdate({ name: event.target.value });
                  onChange(event.target.value);
                }}
              />
            </div>
          )}
          rules={getPipeNameValidator()}
          name="name"
          defaultValue={name}
          control={control}
        />
        <ErrorMesssage error={errors.name} />
      </Form.Group>
    );
  };

  const renderTime = useCallback(
    (configKey) => {
      const { config: { time, basedOn } } = pipeConfig;
      if (basedOn !== 'dateTime') return null;

      return (
        <TimeSelector
          onTimeChange={(newTime) => onUpdateConfigInput(configKey, newTime, true)}
          selectedTime={time}
          className="form-control"
          format="HH:mm:ss"
        />
      );
    },
    [pipeConfig],
  );

  const renderDate = useCallback(
    (configKey) => {
      const { config: { date, basedOn } } = pipeConfig;
      if (basedOn !== 'dateTime') return null;

      return (
        <DateSelector
          selectedDate={date ? moment(date, 'YYYY-MM-DD').toDate() : new Date()}
          onChangeDate={(value) => {
            onUpdateConfigInput(configKey, (value ? moment(value).format('YYYY-MM-DD') : ''), true);
          }}
        />
      );
    },
    [pipeConfig],
  );

  const renderConfigField = (configKey, configValue) => {
    const { config: { candleInterval } } = pipeConfig;
    const configOptions = PipeDefinitionUtility.getPipeConfigOptions(pipeConfig.type);
    let configFieldContent;
    const validator = _.get(validators, ['config', configKey]);
    const errorClass = classNames(errors[configKey] ? 'is-invalid' : '');

    if (configKey === 'valuePaths') {
      configFieldContent = renderValuePathsOptions(validator);
    } else if (configKey === 'accuracy') {
      configFieldContent = renderAccuracy(validator);
    } else if (configKey === 'time') {
      configFieldContent = renderTime(configKey);
    } else if (configKey === 'date') {
      configFieldContent = renderDate(configKey);
    } else if (configKey === 'timeFrame' || configKey === 'basedOn' || configKey === 'source') {
      configFieldContent = renderDropDownFields(validator, configKey);
    } else if (configOptions && configOptions[configKey]) {
      configFieldContent = renderDropDownFields(validator, configKey);
    } else if (configKey === 'candleInterval') {
      configFieldContent = renderCandleInterval(configValue, configKey, validator);
    } else {
      configFieldContent = (
        <Controller
          render={({ onChange, ref, value }) => (
            <Form.Control
              type="number"
              id={configKey}
              size="sm"
              className={errorClass}
              name={configKey}
              ref={ref}
              value={value.toString()}
              onChange={(event) => {
                onUpdateConfigInput(configKey, (event.target.value || 0));
                onChange(event.target.value);
              }}
            />
          )}
          name={configKey}
          defaultValue={configValue}
          control={control}
          rules={getConfigAndPeriodValidator(validator, configKey, candleInterval)}
        />
      );
    }

    return configFieldContent;
  };

  const renderConfigFields = () => {
    if (type === 'customScript') {
      return (
        <div className="config-fields col-md-12">
          <CustomScriptConfigForm
            pipeConfig={pipeConfig}
            pipeConfigs={pipeConfigs}
            onUpdate={onPipeConfigUpdate}
            errors={errors}
            trigger={trigger}
            control={control}
          />
        </div>
      );
    }
    const configFieldsContent = _.map(_.keys(config), (configKey, idx) => {
      const configValue = config[configKey];
      const configLabel = _.get(configDisplayNames, [configKey], configKey);
      const basedOn = _.get(config, 'basedOn', '');
      if (basedOn !== 'dateTime' && _.includes(['time', 'date'], configKey)) return null;

      return (
        <div key={idx} className="col">
          <Form.Group>
            <Form.Label className="text-nowrap col-form-label col-form-label-sm">
              {_.startCase(configLabel)}
            </Form.Label>
            {renderConfigField(configKey, configValue)}
            <Hints configKey={configKey} period={configValue} indicatorType={type} />
            <ErrorMesssage error={errors[configKey]} />
          </Form.Group>
        </div>
      );
    });

    return (
      <div className="config-fields d-flex flex-wrap">
        {configFieldsContent}
      </div>
    );
  };

  const modalSaveButtonProps = {
    'data-track-category': 'Indicator',
    'data-track-action': _.get(pipeConfig, 'type', ''),
    'data-track-label': 'Indicator'
  };

  return (
    <div>
      <Modal
        onSave={handleSubmit(onSubmit)}
        onClose={onClose}
        title={title}
        size="lg"
        btnClassName="track"
        onSaveid={_.get(pipeConfig, 'name', '')}
        className="add-indicator-modal"
        shouldConfirmClose={shouldConfirmClose}
        modalSaveButtonProps={modalSaveButtonProps}
      >
        <Form className="row">
          <div className="col-md-6">
            {renderPipeTypes()}
          </div>
          <div className="col-md-6">
            {renderPipeName()}
          </div>
          {renderConfigFields()}
        </Form>
      </Modal>
    </div>
  );
};

PipeForm.propTypes = propTypes;
PipeForm.defaultProps = defaultProps;

function mapStateToProps(state) {
  const noOfInstrumentsInGroup = _.get(state, 'quantConfig.instrumentGroups.0', []).length;

  return {
    outputSchema: _.get(state, 'outputSchema'),
    pipeConfigs: _.get(state, 'quantConfig.pipeConfigs'),
    segment: _.get(state, 'segment'),
    maxInstrumentsInGroup: _.get(state, 'quantConfig.maxInstrumentsInGroup', noOfInstrumentsInGroup)
  };
}

export default connect(mapStateToProps)(PipeForm);
