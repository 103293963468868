import React, {
  useState, useRef, useEffect
} from 'react';
import PropTypes from 'prop-types';

const propTypes = { onSearchRun: PropTypes.func.isRequired, isScrollToSearch: PropTypes.func.isRequired };

const defaultProps = {};

const I18N_SCOPE = { scope: 'folders.index' };

const SearchFilter = ({ onSearchRun, isScrollToSearch }) => {
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef(null);

  const handelClearFilter = () => {
    setSearchValue('');
    onSearchRun('');
    isScrollToSearch(true);
    inputRef.current.focus();
  };

  const handelKeyDown = (event) => {
    const currentValue = event.target.value;
    if ((event.key === 'Backspace' || event.key === 'Delete')) {
      if (currentValue.length === 1) {
        isScrollToSearch(true);
      }
    }
  };

  useEffect(() => {
    onSearchRun('');
  }, [searchValue === '']);

  return (
    <>
      <form
        className="search-filter input-group create-folder-form align-items-center"
        onSubmit={(e) => {
          e.preventDefault();
          onSearchRun(searchValue);
        }}
      >
        <div className="input-group">
          <input
            type="text"
            ref={inputRef}
            className="form-control form-control-sm mb-0"
            name="search"
            placeholder={I18n.t('create_filter_placeholder', I18N_SCOPE)}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            onKeyDown={handelKeyDown}
            aria-label="currentSearchTitle"
            aria-describedby="basic-addon1"
          />
          {searchValue && (
          <i
            className="material-icons close-icon tx-20"
            onClick={handelClearFilter}
          >
            close
          </i>
          )}
          <div className="input-group-append">
            <button
              className="btn btn-primary track btn-sm"
              data-track-category="Filter"
              data-track-action="Filter Strategy"
              data-track-label="Filter Trackings"
              type="submit"
            >
              <i className="material-icons-outlined mr-1 tx-20 align-text-bottom">search</i>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

SearchFilter.defaultProps = defaultProps;
SearchFilter.propTypes = propTypes;

export default React.memo(SearchFilter);
