import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import { STRATEGY_ACCESS_STATUS } from 'common/constants/index';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { onCreateStrategyAccess, onUpdateStrategyAccess } from 'common/api/strategyAccess';
import { userPropTypes } from 'common/propTypes';
import { RUN_STATUS_CODES } from 'common/configs/runStatus';
import axios from 'axios';
import { onReStartRun } from 'common/api/run';
import qs from 'qs';

const propTypes = {
  userAndStrategyDetail: PropTypes.shape({
    strategy_access: PropTypes.arrayOf(PropTypes.shape({})),
    user: userPropTypes,
    runs: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  strategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdateDetail: PropTypes.func.isRequired,
  shouldShowModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired
};
const defaultProps = { userAndStrategyDetail: {} };

const UserStrategyDetails = ({
  strategies, userAndStrategyDetail, onUpdateDetail, shouldShowModal, onCloseModal
}) => {
  if (!shouldShowModal) return null;
  const { strategy_access: strategyAccess, user, runs } = userAndStrategyDetail;

  const onUpdateUserAndStrategyDetail = (res) => {
    const { strategy_access: resStrategyAccess } = res;

    const isAlreadyPresent = _.some(
      strategyAccess, ({ id }) => id === resStrategyAccess.id
    );
    const updatedStrategyAccess = !isAlreadyPresent ? [...strategyAccess, resStrategyAccess]
      : _.map(strategyAccess, (access) => {
        if (access.id === resStrategyAccess.id) return resStrategyAccess;
        return access;
      });

    onUpdateDetail({ ...userAndStrategyDetail, strategy_access: updatedStrategyAccess });
    return reactToastify('Access Granted', TOASTIFY_TYPES.SUCCESS);
  };

  const onCreateNewAccess = (strategyId) => {
    onCreateStrategyAccess({
      strategy_id: strategyId, status: STRATEGY_ACCESS_STATUS.PAID, user_id: user.id
    }).then((res) => {
      onUpdateUserAndStrategyDetail(res);
    });
  };

  const onUpdateStatus = (id, status) => {
    onUpdateStrategyAccess({ id, strategyParams: { status } }).then((res) => {
      onUpdateUserAndStrategyDetail(res);
    });
  };

  const renderStrategyAccessBasedOnStrategy = (strategy) => {
    const strategyId = strategy.id;
    const accessObj = _.find(strategyAccess, ({ strategy_id: sId }) => strategyId === sId);
    const status = _.get(accessObj, 'status', '');
    const id = _.get(accessObj, 'id', '');

    if (status === STRATEGY_ACCESS_STATUS.PAID) return 'Access granted';

    return (
      <button
        type="button"
        className="btn btn-primary mr-2"
        onClick={() => (id ? onUpdateStatus(id, STRATEGY_ACCESS_STATUS.PAID) : onCreateNewAccess(strategyId))}
      >
        Give Access
      </button>
    );
  };

  const renderRunDetails = (strategy) => {
    const strategyId = strategy.id;
    const accessObj = _.find(strategyAccess, ({ strategy_id: sId }) => strategyId === sId);
    const runId = _.get(accessObj, 'run_id', '');
    if (!runId) return '-';

    const runObj = _.find(runs, ({ id }) => id === runId);
    if (_.isEmpty(runObj)) return '-';

    const { status } = runObj;

    const isStarted = status === RUN_STATUS_CODES.STARTED;

    const onStopRun = () => {
      const queryParams = qs.stringify({ is_stop_auxiliary: true });
      const uri = `/runs/${runId}/stop.json?${queryParams}`;
      axios.post(uri).finally(() => {
        onUpdateDetail({
          ...userAndStrategyDetail,
          runs: _.map(runs, (run) => {
            if (run.id === runId) { return { ...run, status: RUN_STATUS_CODES.STOPPED }; }
            return run;
          })
        });
      });
    };

    const onRestartRun = () => {
      onReStartRun({
        ignore_incomplete_transaction: true,
        is_stop_auxiliary: 'true'
      }, runId, () => {
        onUpdateDetail({
          ...userAndStrategyDetail,
          runs: _.map(runs, (run) => {
            if (run.id === runId) { return { ...run, status: RUN_STATUS_CODES.STARTED }; }
            return run;
          })
        });
      });
    };

    return (
      <div
        className="text-nowrap btn btn-sm btn-outline-danger btn-pill common-icon-btn track"
        onClick={isStarted ? () => onStopRun() : () => onRestartRun()}
        key="stop-btn"
      >
        <i className="material-icons-outlined tx-18">stop_circle</i>
        {isStarted ? 'Stop Run' : 'Restart Run'}
      </div>
    );
  };

  const renderStrategy = (strategy, idx) => {
    const { title } = strategy;

    return (
      <tr key={idx}>
        <td>{title}</td>
        <td>{renderStrategyAccessBasedOnStrategy(strategy)}</td>
        <td>{renderRunDetails(strategy)}</td>
      </tr>
    );
  };

  return (
    <Modal
      onClose={() => { onCloseModal(); }}
      title="Strategy Details"
      size="lg"
    >
      <div className="table-responsive mt-3">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Strategy Name</th>
              <th>Strategy Access</th>
              <th>Run</th>
            </tr>
          </thead>
          <tbody>
            {_.map(strategies, renderStrategy)}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

UserStrategyDetails.propTypes = propTypes;
UserStrategyDetails.defaultProps = defaultProps;

export default UserStrategyDetails;
