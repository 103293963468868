import { useDispatch, useSelector } from 'react-redux';
import { useState, useMemo } from 'react';
import _ from 'lodash';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { getSelectorBasedStoreValue } from '../../redux/selector';
import { getQuantModes } from '../../configs/index';
import { onChangeInstrumentGroups } from '../../events/instrumentGroups';
import { setPartialQuantConfig } from '../../redux/actions';

export const MAX_INSTRUMENT_GROUPS = window?.isAdmin ? 20 : 2;

const getMaxInstrumentsGroups = (mode, instrumentGroups) => {
  return mode === getQuantModes.simple.value ? 1 : _.size(instrumentGroups) || 1;
};

const useInstrumentDetails = () => {
  const instrumentGroups = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.instrumentGroups')
  );
  const maxInstrumentsInGroup = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.maxInstrumentsInGroup')
  );
  const mode = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs.mode')
  ) || getQuantModes.simple.value;

  const uiConfigs = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.uiConfigs')
  ) || {};

  const [maxInstrumentGroups, setMaxInstrumentGroups] = useState(
    getMaxInstrumentsGroups(mode, instrumentGroups)
  );
  const cases = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.cases'));
  const isAdvancedMode = useMemo(() => mode === getQuantModes.advanced.value, [mode]);
  const dispatch = useDispatch();

  const onUpdateInstrumentGroups = (
    newInstrumentGroups, newMaxInstrumentInGroup, newUiConfigs = uiConfigs
  ) => {
    dispatch(
      setPartialQuantConfig(
        onChangeInstrumentGroups({
          instrumentGroups: newInstrumentGroups,
          cases,
          maxInstrumentsInGroup: Number(newMaxInstrumentInGroup),
          uiConfigs: newUiConfigs
        })
      )
    );
  };

  const onUpdateInstrumentGroup = (newInstrumentGroup, idx) => {
    const newInstrumentGroups = _.cloneDeep(instrumentGroups);
    newInstrumentGroups[idx] = newInstrumentGroup;

    dispatch(
      setPartialQuantConfig(
        onChangeInstrumentGroups({
          instrumentGroups: newInstrumentGroups,
          cases,
          maxInstrumentsInGroup: Number(maxInstrumentsInGroup),
          uiConfigs
        })
      )
    );
  };

  const onUpdateInstrument = (newInstrument, instrumentGroupIndex, instrumentIndex) => {
    const newInstrumentGroup = _.cloneDeep(_.get(instrumentGroups, instrumentGroupIndex, []));
    newInstrumentGroup[instrumentIndex] = newInstrument;
    onUpdateInstrumentGroup(newInstrumentGroup, instrumentGroupIndex);
  };

  const onDeleteInstrumentGroup = (instrumentGroupIndex) => {
    const newInstrumentGroups = _.cloneDeep(instrumentGroups);
    newInstrumentGroups.splice(instrumentGroupIndex, 1);
    setMaxInstrumentGroups(maxInstrumentGroups - 1);
    onUpdateInstrumentGroups(newInstrumentGroups, maxInstrumentsInGroup);
  };

  const onAddInstumentGroup = () => {
    if (maxInstrumentGroups < MAX_INSTRUMENT_GROUPS) {
      setMaxInstrumentGroups(maxInstrumentGroups + 1);
    } else {
      reactToastify('Maximum strategy limit exceeded', TOASTIFY_TYPES.INFO);
    }
  };

  const onChangeMode = (newMode) => {
    const newUiConfigs = { ...uiConfigs, mode: newMode };
    const flattenInstrumentGroups = _.flattenDeep(instrumentGroups);
    if (!_.isEmpty(flattenInstrumentGroups)) {
      return onUpdateInstrumentGroups([[_.head(flattenInstrumentGroups)]], 1, newUiConfigs);
    }
    if (newMode === getQuantModes.simple.value && _.isEmpty(flattenInstrumentGroups)) {
      return onUpdateInstrumentGroups([], 1, newUiConfigs);
    }

    return onUpdateInstrumentGroups(instrumentGroups, maxInstrumentsInGroup, newUiConfigs);
  };

  return {
    instrumentGroups,
    maxInstrumentsInGroup,
    maxInstrumentGroups,
    isAdvancedMode,
    onUpdateInstrumentGroups,
    onUpdateInstrumentGroup,
    onUpdateInstrument,
    onDeleteInstrumentGroup,
    onAddInstumentGroup,
    onChangeMode,
    mode
  };
};

export default useInstrumentDetails;
