import { initiateBrokerCallbackLogin } from 'common/auth/modalLogin';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const loginInModal = (href) => {
  initiateBrokerCallbackLogin(href)
    .then((res) => {
      window.location.href = res?.href;
    })
    .catch((error) => {
      console.error(error);
      reactToastify(
        'Unauthorized, Please Login and continue',
        TOASTIFY_TYPES.ERROR
      );
    });
};

export default loginInModal;
