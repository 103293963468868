import React from 'react';
import PropTypes from 'prop-types';
import PaymentMoneyDisplay from 'ui/Plans/components/common/PaymentMoneyDisplay';
import MoneyDisplay from 'common/components/MoneyDisplay';
import { applyCredits } from 'ui/Plans/helper';

const propTypes = {
  currentUpgradePlan: PropTypes.shape({
    upgradeDetails: PropTypes.shape({}),
    cycle_duration_in_months: PropTypes.number
  }),
  discountPercentage: PropTypes.number,
  credits: PropTypes.number
};

const defaultProps = {
  currentUpgradePlan: {},
  discountPercentage: 0,
  credits: 0
};

const UpgradePaymentDetails = ({ currentUpgradePlan, discountPercentage, credits }) => {
  const oneIndentation = ' ';
  const { upgradeDetails } = currentUpgradePlan;
  const upgradeAmount = _.get(upgradeDetails, 'upgrade_amount', 0);
  const price = _.get(currentUpgradePlan, 'price', 0);
  const currentNetPrice = _.get(currentUpgradePlan, 'currentNetPrice', price);
  let reducedUpgradeAmount = upgradeAmount;
  if (currentNetPrice !== price) {
    reducedUpgradeAmount -= (reducedUpgradeAmount / 100) * discountPercentage;
  }

  return (
    <div className="total-amount">
      <table className="table upgrade-custom-table table-borderless w-100">
        <tbody className="text-right bg-transparent">
          <tr>
            <td className="pr-2 text-wrap" colSpan="2">
              Your existing plan unused
              {oneIndentation}
              {parseInt(_.get(upgradeDetails, 'unused_percentage', 0), 10)}
              %
              {oneIndentation}
              is adjusted
            </td>
          </tr>
          {(credits > 0) && (
            <tr>
              <td className="pr-2 text-wrap">Credits used:</td>
              <td>
                <MoneyDisplay shouldAvoidDecimals>{credits}</MoneyDisplay>
              </td>
            </tr>
          )}
          <tr>
            <td className="pr-2 text-wrap">Total Upgrade amount :</td>
            <td>
              <PaymentMoneyDisplay
                currentNetPrice={applyCredits(reducedUpgradeAmount, credits)}
                price={upgradeAmount}
                shouldAvoidDecimals
                shouldHideBillCycle
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

UpgradePaymentDetails.propTypes = propTypes;
UpgradePaymentDetails.defaultProps = defaultProps;

export default UpgradePaymentDetails;
