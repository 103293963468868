import { toast } from 'react-toastify';

export const TOASTIFY_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  DEFAULT: 'default'
};

export const reactToastify = (message, type = TOASTIFY_TYPES.INFO, options = {}) => {
  // options.className: 'large-toast' => For a larger toast
  toast(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    type,
    theme: 'light',
    ...options,
  });
};
