import React, { useEffect } from 'react';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import PropTypes from 'prop-types';
import Pagination from 'common/components/Pagination';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
const defaultProps = {};

const ReactDataTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    pageCount,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: 'transaction_id',
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(globalFilter);
  }, [globalFilter, setGlobalFilter]);

  const sortArrow = (column) => {
    if (column.isSorted) {
      const icon = column.isSortedDesc ? 'keyboard_arrow_down' : 'keyboard_arrow_up';
      return icon ? <span className="material-icons-outlined">{icon}</span> : '';
    }

    return '';
  };

  return (
    <>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </div>
      <div className="table-responsive">
        <table {...getTableProps()} className="table table-bordered table-sm">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="d-flex align-items-center">
                      {column.render('Header')}
                      {sortArrow(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center gap-3">
        <Pagination
          totalPages={pageCount}
          onPageChange={({ selected: selectedPage }) => gotoPage(selectedPage)}
          currentPageIndex={pageIndex}
        />
        {' '}
        <select
          className="form-control form-control-sm show-page-size-pagination"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((currentPageSize) => (
            <option key={currentPageSize} value={currentPageSize}>
              Show
              {' '}
              {currentPageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default ReactDataTable;

ReactDataTable.propTypes = propTypes;
ReactDataTable.defaultProps = defaultProps;
