import qs from 'qs';
import { STOPS_VALUE_TYPES, STRIKE_SELECTION_TYPES } from 'v2/common/constants/index';
import {
  getDefaultCase, getDefaultIntent, emptyQuantConfig, getDefaultIdentifier
} from 'v2/modules/withRunForm/configs/index';

export const getOptionBuilderQuantConfig = (parsedQueryString) => {
  const strategyBuilderConfig = JSON.parse(
    decodeURIComponent(parsedQueryString.strategyBuilderConfig)
  );

  const strikeKeys = {
    equityPrice: {
      key: STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.key,
      newType: STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.value,
      optionBuilderKey: STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.key,
    },
    strikeIndex: {
      key: STRIKE_SELECTION_TYPES.FutureBasedStrikeIndex.key,
      newType: STRIKE_SELECTION_TYPES.FutureBasedStrikeIndex.value,
      optionBuilderKey: STRIKE_SELECTION_TYPES.FutureBasedStrikeIndex.key,
    },
    premium: {
      key: STRIKE_SELECTION_TYPES.PremiumBased.key,
      newType: STRIKE_SELECTION_TYPES.PremiumBased.value,
      optionBuilderKey: STRIKE_SELECTION_TYPES.PremiumBased.key,
    },
    strikePrice: {
      key: STRIKE_SELECTION_TYPES.StrikePrice.key,
      newType: STRIKE_SELECTION_TYPES.StrikePrice.value,
      optionBuilderKey: STRIKE_SELECTION_TYPES.StrikePrice.key,
    },
  };

  const {
    optionConfigs, timeBasedSignal, optionStrikeCalculationBasedOn,
    optionsExpiryCycle,
    instrumentGroups,
  } = strategyBuilderConfig;
  const defaultQuantConfig = emptyQuantConfig();
  const { newType } = strikeKeys[optionStrikeCalculationBasedOn];

  const oldStrikePair = _.reduce(strikeKeys, (result, value) => {
    const newResult = result;
    newResult[value.newType] = value.optionBuilderKey;
    return newResult;
  }, {});

  const intents = _.map(optionConfigs, (optionConfig) => {
    const defaultIntent = {
      ...getDefaultIntent(),
      identifier: getDefaultIdentifier(),
    };
    const { stopLoss, stopGain } = defaultIntent;
    const stopLossName = optionConfig?.stopLossPrice ? STOPS_VALUE_TYPES.Amount : STOPS_VALUE_TYPES.Percent;
    const stopGainName = optionConfig?.stopGainPrice ? STOPS_VALUE_TYPES.Amount : STOPS_VALUE_TYPES.Percent;

    const newStrike = {
      type: newType,
      [strikeKeys[optionStrikeCalculationBasedOn].key]: optionConfig[oldStrikePair[newType]] || 0,
    };

    if (newType === STRIKE_SELECTION_TYPES.PremiumBased.value) {
      newStrike.comparison = STRIKE_SELECTION_TYPES.PremiumBased.comparsions[0]?.value;
    }
    return {
      ...defaultIntent,
      quantity: optionConfig.quantity,
      side: optionConfig.entryType,
      instrument: {
        ...defaultIntent.instrument,
        optionType: optionConfig.optionType,
        expiry: {
          ...defaultIntent.instrument.expiry,
          cycle: optionsExpiryCycle,
        },
        strike: newStrike,
      },
      stopLoss: {
        ...stopLoss,
        type: stopLossName,
        value: optionConfig.stopLoss || optionConfig.stopLossPrice || 0,
      },
      stopGain: {
        ...stopGain,
        type: stopGainName,
        value: optionConfig.stopGain || optionConfig.stopGainPrice || 0,
      },
    };
  });
  const defaultCase = getDefaultCase();
  return {
    ...defaultQuantConfig,
    instrumentGroups,
    cases: [{
      ...defaultCase,
      entry: {
        ...defaultCase.entry,
        intents,
      }
    }],
    bounds: {
      ...defaultQuantConfig.bounds,
      timeBasedSignal,
    }
  };
};

export const getQueryParamsBasedSanitizedQuantConfig = () => {
  const queryString = window.location.search;
  const parsedQueryString = qs.parse(queryString, { parseBooleans: true });
  const queryStringParams = _.keys(parsedQueryString);

  if (_.includes(queryStringParams, 'strategyBuilderConfig')) {
    return getOptionBuilderQuantConfig(parsedQueryString);
  }

  return null;
};
