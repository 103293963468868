// import { automaticSupportedBrokers, oneClickSupportedBrokers } from 'common/brokerConfigs/config';
import { DOMAINS } from 'common/constants/index';
import React from 'react';
// import { getContent } from 'ui/Plans/helper';

const AboutSection = () => {
  const { currentDomain } = window;
  const isDefaultDomain = currentDomain === DOMAINS.DEFAULT_DOMAIN;
  const liveTypesClassName = isDefaultDomain
    ? 'col-md-6 col-lg-4 mb-3 mb-lg-0'
    : 'col-md-6 col-lg-6 mb-3 mb-lg-0';

  return (
    <section className="about-section section">
      <div className="container">
        <div className="section-head text-center">
          <h2 className="font-weight-bold">Live Execution</h2>
          <p>
            Execute your strategies on the live market with our world-class execution platform.
            Deploy your custom strategies with never before heard reliability uninterrupted.
          </p>
        </div>

        <div className="row mt-5 mt-md-10">
          <div className={liveTypesClassName}>
            <div className="card as-card">
              <div className="card-body">
                <div className="d-flex align-items-center gap-10 mb-4">
                  <div className="alert-icons icon-auto alert-primary" />
                  <h3 className="text-bold">Automatic Order</h3>
                </div>
                <ul className="list-unstyled list pl-0 pl-md-2">
                  <li>
                    Place orders directly on the broker account on the account used to log in.
                    (no additional setup required.)
                  </li>
                  <li>
                    We place stop-loss orders based on executed prices with the brokers.
                  </li>

                  <li>
                    Stop loss orders are automatically canceled on take profit.
                  </li>

                  <li>
                    You can track the status of the transactions easily.
                  </li>

                  {/* {isDefaultDomain && (
                    <li>
                      <div>
                        Supported brokers :
                        {' '}
                        <span className="font-weight-semi">{getContent(automaticSupportedBrokers)}</span>
                      </div>
                    </li>
                  )} */}
                </ul>
              </div>
            </div>
          </div>

          <div className={liveTypesClassName}>
            <div className="card as-card">
              <div className="card-body">
                <div className="d-flex align-items-center gap-10 mb-4">
                  <div className="alert-icons icon-click alert-success" />
                  <h3 className="text-bold">One Click order</h3>
                </div>

                <ul className="list-unstyled success list pl-0 pl-md-2">
                  <li>
                    On every signal, get a phone call with IVR.
                  </li>
                  <li>
                    You can confirm the order simply by pressing 1 on the phone call.
                  </li>

                  <li>
                    Tweak the price or other details of the order before actually executing it.
                  </li>

                  {/* {isDefaultDomain && (
                    <li>
                      <div>
                        Supported brokers:
                        {' '}
                        <span className="font-weight-semi">{getContent(oneClickSupportedBrokers)}</span>
                      </div>
                    </li>
                  )} */}

                </ul>
              </div>
            </div>
          </div>

          <div className={`${liveTypesClassName} ${!isDefaultDomain ? 'hidden-for-future' : ''}`}>
            <div className="card as-card">
              <div className="card-body">
                <div className="d-flex align-items-center gap-10 mb-4">
                  <div className="alert-icons icon-paper alert-info" />
                  <h3 className="text-bold">Paper Testing</h3>
                </div>
                <ul className="list-unstyled info list pl-0 pl-md-2">
                  <li>
                    Checks signal conditions every second just like live execution.
                  </li>
                  <li>
                    Instead of placing orders in the broker, we store them in our database
                    so that you can verify if the strategy works as you expect it to.
                  </li>

                  <li>
                    <div>Log in via any supported broker to try this out.</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutSection;
