const I18N_SCOPE = { scope: 'folders.show' };

export const SORT_BY = {
  name: 'name',
  profit: 'summary.profit',
  createdAt: 'created_at'
};

export const TABLE_HEADER = [
  { title: I18n.t('name_&_details', I18N_SCOPE) },
  { title: 'Profit & Loss' },
  { title: I18n.t('created_at', I18N_SCOPE) }
];

export const SLIDES_TO_SHOW = 4;

export const PREBUILT_SLIDER_CONFIG = {
  infinite: false,
  dots: false,
  arrows: false,
  slidesToShow: 6,
  slidesToScroll: 1,
  speed: 1000,
  row: 1,
  autoplay: true,
  autoplaySpeed: 6000,
};

export const SHOWCASE_SLIDER_CONFIG = (setActiveIndex) => {
  return {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    fade: true,
    arrows: true,
    dots: true,
    beforeChange: (current, next) => setActiveIndex(next)
  };
};

export const FAVORITE_SLIDER_CONFIG = {
  infinite: false,
  dots: false,
  arrows: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  speed: 1000,
  row: 1,
  autoplay: false,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]

};

export const videosConfig = [
  {
    img: 'video-thumbanil.jpg',
    title: 'Basic Builder',
    url: 'https://www.youtube.com/embed/4R2CDbw4g88?autoplay=1',
  },
  {
    img: 'video-thumbanil2.jpg',
    title: 'Advance Builder',
    url: 'https://www.youtube.com/embed/OGFxp3Pixg8?autoplay=1',
  },
  {
    img: 'video-thumbanil3.jpg',
    title: 'Transactions',
    url: 'https://www.youtube.com/embed/5rMkQurfxrE?autoplay=1',
  },
];
