import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { getDefaultAdjustment } from 'v2/modules/withRunForm/configs/index';
import { INTENT_TYPES } from 'v2/common/constants/index';
import { OutputSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import { setCasesIndexBased } from '../../redux/actions';
import useOutputSchema from '../useOutputSchema';
import { extractIndividualSchemaField } from '../../utils/signal';
import { isEligibleForMoveToCost, removeOpenIntentsReferences } from './helper';

export const useCase = (caseConfig, caseIndex) => {
  const entrySignals = useMemo(() => caseConfig?.entry?.signal || [], [caseConfig]);
  const exitSignals = useMemo(() => caseConfig?.exitSignal || [], [caseConfig]);
  const adjustments = useMemo(() => caseConfig?.adjustments || [], [caseConfig]);
  const intents = useMemo(() => caseConfig?.entry?.intents || [], [caseConfig.entry.intents]);
  const uiConfigs = useMemo(() => caseConfig?.uiConfigs || {}, [caseConfig.uiConfigs]);
  const dispatch = useDispatch();
  const { errors } = useFormContext();
  const outputSchema = useOutputSchema();

  const adjustmentPermissionId = useMemo(() => _.uniqueId('adjustment_permission_id'), []);
  const adjustmentPermissionErrorMessage = _.get(errors, `${adjustmentPermissionId}.message`, '');

  const isValidToEnableMoveToCost = useMemo(() => isEligibleForMoveToCost(intents), [intents]);
  const isPermissionToAddManualAdjustment = _.get(window, 'userPermissions.adjustments.isEnabled', false);

  const onUpdateCase = useCallback((newCaseConfig, idx, removedOpenIntentIds = []) => {
    dispatch(setCasesIndexBased(
      removeOpenIntentsReferences(newCaseConfig, removedOpenIntentIds),
      idx
    ));
  }, [dispatch]);

  const onUpdateEntrySignals = useCallback((newSignals) => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    newCaseConfig.entry.signal = newSignals;
    onUpdateCase(newCaseConfig, caseIndex);
  }, [caseConfig, caseIndex, onUpdateCase]);

  const onUpdateExitSignals = useCallback((newSignals) => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    newCaseConfig.exitSignal = newSignals;
    onUpdateCase(newCaseConfig, caseIndex);
  }, [caseConfig, caseIndex, onUpdateCase]);

  const onModifyCaseAndUiConfigsBasedOnNewIntents = useCallback((newIntens, removedOpenIntentIds) => {
    const isValidForMoveToCost = uiConfigs.isMoveToCost && isEligibleForMoveToCost(newIntens);
    const newCaseConfig = _.cloneDeep(caseConfig);
    newCaseConfig.entry.intents = newIntens;
    newCaseConfig.uiConfigs.isMoveToCost = isValidForMoveToCost;
    onUpdateCase(newCaseConfig, caseIndex, removedOpenIntentIds);
  }, [caseConfig, caseIndex, onUpdateCase, uiConfigs.isMoveToCost]);

  const onUpdateIntents = useCallback((newIntents, removedOpenIntentIds) => {
    onModifyCaseAndUiConfigsBasedOnNewIntents(newIntents, removedOpenIntentIds);
  }, [onModifyCaseAndUiConfigsBasedOnNewIntents]);

  const onUpdateUiConfigs = useCallback((newUiConfigs) => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    newCaseConfig.uiConfigs = newUiConfigs;
    onUpdateCase(newCaseConfig, caseIndex);
  }, [caseConfig, caseIndex, onUpdateCase]);

  const onAddAdjustment = useCallback(() => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    newCaseConfig.adjustments.push(getDefaultAdjustment(caseIndex));
    onUpdateCase(newCaseConfig, caseIndex);
  }, [caseConfig, caseIndex, onUpdateCase]);

  const onUpdateAdjustmentSignals = useCallback((newSignals, idx) => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    newCaseConfig.adjustments[idx].signal = newSignals;
    onUpdateCase(newCaseConfig, caseIndex);
  }, [caseConfig, caseIndex, onUpdateCase]);

  const onUpdateAdjustmentIntents = useCallback((newIntents, removedOpenIntentIds, idx) => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    newCaseConfig.adjustments[idx].intents = newIntents;
    onUpdateCase(newCaseConfig, caseIndex, removedOpenIntentIds);
  }, [caseConfig, caseIndex, onUpdateCase]);

  const onDeleteAdjustment = useCallback((idx) => {
    const newCaseConfig = _.cloneDeep(caseConfig);
    const removedOpenIntentIds = _.chain(caseConfig).get(['adjustments', idx, 'intents'])
      .filter({ type: INTENT_TYPES.OpenLeg })
      .map('identifier.label')
      .value();
    newCaseConfig.adjustments.splice(idx, 1);
    onUpdateCase(newCaseConfig, caseIndex, removedOpenIntentIds);
  }, [caseConfig, caseIndex, onUpdateCase]);

  const getModifyLegsTargetSchema = useCallback((adjustmentIndex) => {
    return _.chain(outputSchema)
      .thru((schema) => OutputSchemaUtility.filterByCaseIndex(schema, caseIndex))
      .thru((schema) => OutputSchemaUtility.filterByCategory(schema, 'individualStop'))
      .thru((schema) => OutputSchemaUtility.filterSchemaUpToAdjustmentIndex(schema, adjustmentIndex))
      .thru((schema) => _.map(schema, (sch) => {
        return {
          id: extractIndividualSchemaField(sch?.name)?.label,
          label: sch.tagConfig?.tag,
        };
      }))
      .uniqBy('id')
      .value();
  }, [caseIndex, outputSchema]);

  return {
    entrySignals,
    exitSignals,
    intents,
    onUpdateEntrySignals,
    onUpdateExitSignals,
    onUpdateAdjustmentIntents,
    onUpdateIntents,
    uiConfigs,
    isValidToEnableMoveToCost,
    onUpdateAdjustmentSignals,
    onDeleteAdjustment,
    onUpdateUiConfigs,
    adjustments,
    adjustmentPermissionErrorMessage,
    isPermissionToAddManualAdjustment,
    onAddAdjustment,
    getModifyLegsTargetSchema,
  };
};
