import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_TYPES, RUN_TYPES } from 'v2/common/constants/index';
import { setPartialQuantConfig } from '../../redux/actions';
import { getSelectorBasedStoreValue } from '../../redux/selector';

const PRODUCT_TYPES = {
  mis: { label: 'MIS', value: 'mis' },
  normal: { label: 'Normal', value: 'normal' },
};

const useOrderExecutionDetails = () => {
  const quantRunType = useSelector((state) => getSelectorBasedStoreValue(state, 'runType'));
  const quantOrderType = useSelector((state) => getSelectorBasedStoreValue(state, 'orderType'));
  const entryOrderType = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.entryOrderType')
  ) || 'market';
  const entryOrderModification = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.entryOrderModification')
  ) || {};

  const exitOrderType = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.exitOrderType')
  ) || 'market';
  const exitOrderModification = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.exitOrderModification')
  ) || {};

  const dispatch = useDispatch();

  const onChangeOrderExecutionConfigs = useCallback((partialConfigs) => {
    dispatch(setPartialQuantConfig(partialConfigs));
  }, [dispatch]);

  return {
    product: useSelector(
      (state) => getSelectorBasedStoreValue(state, 'quantConfig.product')
    ) || PRODUCT_TYPES.mis.value,
    onChangeProduct: useCallback(
      (newProduct) => onChangeOrderExecutionConfigs({ product: newProduct }), [onChangeOrderExecutionConfigs]
    ),
    isShowOrderExectionDetails: useMemo(
      () => quantRunType !== RUN_TYPES.historic && quantOrderType !== ORDER_TYPES.paper.value,
      [quantRunType, quantOrderType]
    ),
    isPlaceStoplossOrderToBroker: useSelector(
      (state) => getSelectorBasedStoreValue(state, 'quantConfig.isPlaceStoplossOrderToBroker')
    ) || false,
    onChangeIsPlaceStoplossOrderToBroker: useCallback(
      (isPlaceStoplossOrderToBroker) => onChangeOrderExecutionConfigs({ isPlaceStoplossOrderToBroker }),
      [onChangeOrderExecutionConfigs]
    ),
    isSquareOffTransactionIfEntryFailed: useSelector(
      (state) => getSelectorBasedStoreValue(state, 'quantConfig.isSquareOffTransactionIfEntryFailed')
    ) || false,
    onChangeIsSquareOffTransactionIfEntryFailed: useCallback(
      (isSquareOffTransactionIfEntryFailed) => onChangeOrderExecutionConfigs(
        { isSquareOffTransactionIfEntryFailed }
      ), [onChangeOrderExecutionConfigs]
    ),
    entryOrderType,
    entryOrderModification,
    onChangeOrderTypeConfigs: useCallback(
      (partialConfigs) => onChangeOrderExecutionConfigs(partialConfigs), [onChangeOrderExecutionConfigs]
    ),
    exitOrderType,
    exitOrderModification,
    stopAndLimitPriceDifferenceInPercentage: useSelector(
      (state) => getSelectorBasedStoreValue(state, 'quantConfig.stopAndLimitPriceDifferenceInPercentage')
    ) || 0,
    limitOrderBufferPercentage: useSelector(
      (state) => getSelectorBasedStoreValue(state, 'quantConfig.limitOrderBufferPercentage')
    ) || '',
    onChangeLimitOrderBufferPercentage: useCallback(
      (newLimitOrderBufferPercentage) => onChangeOrderExecutionConfigs(
        { limitOrderBufferPercentage: newLimitOrderBufferPercentage }
      ), [onChangeOrderExecutionConfigs]
    ),
    onChangeStopAndLimitPriceDifferenceInPercentage: useCallback(
      (stopLossLimitPrice) => onChangeOrderExecutionConfigs(
        { stopAndLimitPriceDifferenceInPercentage: stopLossLimitPrice }
      ), [onChangeOrderExecutionConfigs]
    ),
    isShowBufferPercentage: useMemo(
      () => entryOrderType !== 'market' || exitOrderType !== 'market',
      [entryOrderType, exitOrderType]
    ),
    isPlaceOptionsEntryBuyOrdersFirst: useSelector(
      (state) => getSelectorBasedStoreValue(state, 'quantConfig.isPlaceOptionsEntryBuyOrdersFirst')
    ) || false,

    onChangeIsPlaceOptionsEntryBuyOrdersFirst: useCallback(
      (isPlaceOptionsEntryBuyOrdersFirst) => onChangeOrderExecutionConfigs(
        { isPlaceOptionsEntryBuyOrdersFirst }
      ), [onChangeOrderExecutionConfigs]
    ),
    slOrderPlaceBasedOnPrice: useSelector(
      (state) => getSelectorBasedStoreValue(state, 'quantConfig.slOrderPlaceBasedOnPrice')
    ) || 'traded_price',
    onChangeSlOrderPlaceBasedOnPrice: useCallback(
      (slOrderPlaceBasedOnPrice) => onChangeOrderExecutionConfigs(
        { slOrderPlaceBasedOnPrice }
      ), [onChangeOrderExecutionConfigs]
    ),
  };
};

export default useOrderExecutionDetails;
