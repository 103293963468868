import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ArrowAccordion from 'common/components/ArrowAccordion';
import { STATUS_STYLE_CONFIG } from 'modules/OrderDetails/config';
import classNames from 'classnames';
import { ORDER_TYPES } from 'common/constants/index';
import { formattedDateAndTime } from 'modules/OrderDetails/helper';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';
import { getUrlParams } from 'common/utils/urlUtils';
import Money from 'common/components/MoneyDisplay';
import LinkToIOStrategy from 'v2/common/components/LinkToIOStrategy/index';
import LiveProfits from './LiveProfits';
import LiveOrderDetails from './LiveOrderDetails';

const propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderType: PropTypes.string.isRequired,
  isAccordianOpen: PropTypes.bool,
  runStatus: PropTypes.string
};

const defaultProps = {
  isAccordianOpen: true,
  runStatus: ''
};

const STATUSES = { // implemented same as order_transactions.rb
  created: { name: 'created', priority: 0 },
  confirmed: { name: 'confirmed', priority: 1 },
  placed: { name: 'placed', priority: 5 },
  cancelled: { name: 'cancelled', priority: 9 },
  executed: { name: 'executed', priority: 10 },
  failed: { name: 'executed', priority: 11 },
  partially_executed: { name: 'partially executed', priority: 12 }
};

const getPriority = (status) => {
  if (!status || !STATUSES[status]) return 20;
  // why handled because.. for old paper trade.. there is status null.. backend handled it after aug 2021
  // status must be  --> created.. not null; so handled for old runs in frontend

  return STATUSES[status].priority;
};

const getStatus = (orders, slOrders) => {
  const filteredOrders = _.filter(orders, ({ orderTradingSymbol }) => {
    const slOrder = _.find(slOrders,
      ({ slOrderTradingSymbol }) => orderTradingSymbol === slOrderTradingSymbol);
    return !(slOrder && slOrder.status === 'executed');
  });
  const initialObj = _.first(
    _.sortBy(filteredOrders, [({ status }) => { return getPriority(status) || 20; }])
  );
  if (!initialObj) {
    return '';
  }
  return initialObj.status;
};

const getClassNamesForProgressCircle = (status, circleIndex) => {
  return classNames(
    'stepper-item',
    _.get(STATUS_STYLE_CONFIG, [status, 'progressCirlcClassName', circleIndex])
  );
};

const LiveTransactionDetails = ({
  transactions, orderType, isAccordianOpen, runStatus
}) => {
  const { paper: { value: paper } } = ORDER_TYPES;
  const urlParams = getUrlParams();
  const isNumerousRuns = _.get(urlParams, 'runids', '');

  const isPaperTrade = orderType === paper;
  const oneIndentation = ' ';

  const getIndex = (status) => {
    if (status === 'created') return 1;
    if (status === 'confirmed') return 2;

    return 3;
  };

  useEffect(() => {
    window.prerenderReady = true; // for share
  }, []);

  const renderSignals = (entry, sl, exit) => {
    return (
      <div className="stepper-wrapper d-none">
        <div className="status-bubble">
          <div className={getClassNamesForProgressCircle(entry, getIndex(entry))}>
            <span className="bubble" />
          </div>
          <label>Entry</label>
        </div>
        <div className="status-bubble">
          <div className={getClassNamesForProgressCircle(sl, getIndex(sl))}>
            <span className="bubble" />
          </div>
          <label>Stop Loss</label>
        </div>
        <div className="status-bubble">
          <div className={getClassNamesForProgressCircle(exit, getIndex(exit))}>
            <span className="bubble" />
          </div>
          <label>Exit</label>
        </div>
      </div>
    );
  };

  const renderTransaction = (transaction, key) => {
    if (_.isEmpty(transaction)) return null;

    const {
      transactionNumber, instruments, orders, transactionOpenTime,
      isComplete, cumulativeProfit, groupedOrderSorted,
      transactionAndRunId, pairedOrders
    } = transaction;
    const entryOrders = _.filter(groupedOrderSorted, ({ is_entry: isEntry }) => isEntry);
    const slOrders = _.filter(groupedOrderSorted, ({ is_stop_loss: isStopLoss }) => isStopLoss);
    const exitOrders = _.filter(
      groupedOrderSorted, ({ is_entry: isEntry, is_stop_loss: isStopLoss }) => !isStopLoss && !isEntry
    );

    const entryStatus = getStatus(entryOrders, []);
    // implemented same as order_transactions.rb TODO as Better Way

    const slStatus = getStatus(slOrders, []);
    let exitStatus = getStatus(exitOrders, slOrders);

    if (slStatus === 'executed') exitStatus = 'sl-achieved';

    const renderOverallProfit = () => {
      if (!isComplete) return null;

      return (
        <LiveProfits orders={orders} isPaperTrade={isPaperTrade} />
      );
    };

    const renderTitle = () => {
      return (
        <div className="white-space-nowrap">
          <LinkToIOStrategy pairedOrders={pairedOrders} />
          <InstrumentGroupsSentence instrumentGroups={[instruments]} />
          {oneIndentation}
          #
          {isNumerousRuns ? transactionAndRunId : transactionNumber}
          {isAccordianOpen && ( // mainly for market place user flows
            <span className="time">{formattedDateAndTime(transactionOpenTime)}</span>
          )}
        </div>
      );
    };

    const renderHeaderRightSectionContent = () => {
      return (
        <div className="right-section-content">
          {renderOverallProfit()}
          {!isPaperTrade ? renderSignals(entryStatus, slStatus, exitStatus) : null}
          {isComplete && (
            <div className="font-weight-bold">
              CP :
              <Money shouldColor shouldAvoidDecimals>{_.get(cumulativeProfit, 'value', 0)}</Money>
            </div>
          )}
        </div>
      );
    };

    const renderPairedOrder = (pairedOrder, index) => {
      return (
        <LiveOrderDetails
          pairedOrder={pairedOrder}
          key={index}
          orderType={orderType}
          runStatus={runStatus}
        />
      );
    };

    return (
      <ArrowAccordion
        defaultOpen={isAccordianOpen ? !key : false}
        class="my-AccordianOpen"
        key={key}
        title={renderTitle()}
        headerRightSectionContent={renderHeaderRightSectionContent()}
        onToggle={() => { }}
        isDisabledOnClick={!isAccordianOpen}
      >
        <div className="live-transactions-mobile live-monitor-wrapper d-block">
          {_.map(pairedOrders, renderPairedOrder)}
        </div>

      </ArrowAccordion>
    );
  };

  const liveStatusIndicator = () => {
    return (
      <div className="status-indicator-info sidebar-legend d-none">
        <div>
          <span className="indicator-circle bg-warning" />
          Processing
        </div>

        <div>
          <span className="indicator-circle bg-success" />
          Completed (or) Cancel
        </div>

        <div>
          <span className="indicator-circle bg-danger" />
          Error
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="legend-wrapper">
        <div className="profit-legend-info">
          <span>
            <b>CP:</b>
            {oneIndentation}
            Cumulative Profit
          </span>
          <span>
            <b>P:</b>
            {oneIndentation}
            Profit
          </span>

        </div>
        {!isPaperTrade && liveStatusIndicator()}
      </div>
      {_.map(transactions, renderTransaction)}
    </>
  );
};

export default LiveTransactionDetails;

LiveTransactionDetails.propTypes = propTypes;
LiveTransactionDetails.defaultProps = defaultProps;
