import React, { useState } from 'react';
import { createRun } from 'common/api/run';
import { MARKET_END_TIME, ORDER_TYPES, RUN_TYPES } from 'common/constants/index';
import { buttonTypesInStrategy, handleDisabledButtonInMarketPlace } from 'ui/Strategies/helper';
import PropTypes from 'prop-types';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { quotaVerificationDom } from 'ui/QuotaVerification/quotaDom';
import { getUrlParams } from 'common/utils/urlUtils';
import { strategyPropTypes } from 'common/propTypes';
import { initiateUserAuth } from 'common/auth/modalLogin';
import moment from 'moment';
import StrategyConfirmationModal from './StrategyConfirmationModal';

const propTypes = {
  strategy: strategyPropTypes.isRequired,
  btnClassName: PropTypes.string,
  isStrategyDetailView: PropTypes.bool
};

const defaultProps = {
  btnClassName: 'common-icon-btn btn btn-success btn-block btn-sm',
  isStrategyDetailView: false
};

const DeployStrategyButton = ({ strategy, btnClassName, isStrategyDetailView }) => {
  const {
    currentUserDefaultFolderId, title, id, margin_amount: marginAmount,
    live_instrument_groups: liveInstrumentGroups, initial_free_trial_days: initialFreeTrialDays,
    purchase_amount: purchaseAmount, isFreeTrialAvailable
  } = strategy;
  const isUserLoggedIn = _.get(window, 'isUserSignedIn', false);
  const subscriberStrategyCutOffTime = _.get(window, 'SA_COLLECTIONS.subscriberStrategyCutOffTime', null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [multiplier, setMultiplier] = useState(1);
  const { automatic: { value: automatic } } = ORDER_TYPES;
  const [selectedOrderType, setSelectedOrderType] = useState(automatic);
  const strategyAccess = _.get(strategy, 'strategy_access', {});
  const isDeployedStrategy = _.get(strategyAccess, 'is_deployed', false);
  const runId = _.get(strategyAccess, 'run_id', null);
  const urlParams = getUrlParams();
  const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';

  const executeCreateRun = (runParams) => {
    createRun(runParams).catch((error) => {
      const errors = _.get(error.response, 'data.errors', []);
      const additionalErrors = _.filter(errors, (err, key) => key !== 'quant_config');
      const errorMessages = _.join(_.map(additionalErrors, '0'), ', ');
      if (errorMessages) reactToastify(errorMessages, TOASTIFY_TYPES.ERROR);
    });
  };

  const onCreateRun = () => {
    setIsShowModal(false);

    const runParams = {
      folder_id: currentUserDefaultFolderId,
      quant_config: null,
      name: title || '',
      run_type: RUN_TYPES.live,
      strategy_id: id,
      order_type: selectedOrderType,
      multiplier: multiplier ? Math.abs(multiplier) : 1,
      is_public: true,
      is_strategy_subscriber: true
    };

    const cutoffTimeMoment = moment(subscriberStrategyCutOffTime, 'HH:mm');
    const marketEndTimeMoment = moment(MARKET_END_TIME, 'HH:mm');

    const isAfterCutoff = moment().isAfter(cutoffTimeMoment);
    const isBeforeMarketEnd = moment().isBefore(marketEndTimeMoment);

    let delay = 0;
    if (isAfterCutoff && isBeforeMarketEnd) {
      delay = 5000;
      reactToastify(
        'Your deployment will take effect in the live market from tomorrow.',
        TOASTIFY_TYPES.INFO
      );
    }

    setTimeout(() => {
      quotaVerificationDom(liveInstrumentGroups, runParams, executeCreateRun);
    }, delay);

    return null;
  };

  const { isDisabled, message } = handleDisabledButtonInMarketPlace(
    strategy, buttonTypesInStrategy.deployButton
  );

  if (runId) {
    if (isStrategyDetailView) return null;

    let reDirectUrl = `/runs/${runId}?locale=${I18n.locale}`;
    if (isMinifiedPage) { reDirectUrl += '&minifiedpage=true'; }

    return (
      <button
        className="common-icon-btn btn btn-outline-success btn-block btn-sm mt-0"
        type="button"
        onClick={() => { window.location.href = reDirectUrl; }}
      >
        <span className="material-icons-outlined tx-17">visibility</span>
        View Deployed Run
      </button>
    );
  }

  const onDeployStrategy = () => {
    if (isUserLoggedIn) { return setIsShowModal(true); }

    initiateUserAuth().then(() => {
      window.location.reload();
    }).catch(() => {
      reactToastify('Unauthorized, Please Login and continue', TOASTIFY_TYPES.ERROR);
    });

    return null;
  };

  return (
    <>
      <button
        className={btnClassName}
        type="button"
        onClick={() => onDeployStrategy()}
        data-toggle="tooltip"
        title={message}
        disabled={isDisabled}
      >
        <span className="material-icons-outlined tx-17">rocket_launch</span>
        {isDeployedStrategy ? 'Deployed' : 'Deploy Strategy'}
      </button>
      {isShowModal
        && (
          <StrategyConfirmationModal
            multiplier={multiplier}
            onChangeMultiplier={setMultiplier}
            marginAmount={marginAmount}
            onCreateRun={() => onCreateRun()}
            onCloseModal={() => setIsShowModal(false)}
            selectedOrderType={selectedOrderType}
            isMinifiedPage={isMinifiedPage}
            onChangeSelectedOrderType={setSelectedOrderType}
            initialFreeTrialDays={initialFreeTrialDays}
            purchaseAmount={purchaseAmount}
            isFreeTrialAvailable={isFreeTrialAvailable}
          />
        )}
    </>
  );
};

DeployStrategyButton.propTypes = propTypes;
DeployStrategyButton.defaultProps = defaultProps;

export default DeployStrategyButton;
