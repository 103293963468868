import React from 'react';
import { RWebShare } from 'react-web-share';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  successCallBack: PropTypes.func,
  children: PropTypes.element.isRequired
};
const defaultProps = { title: '', text: '', successCallBack: () => { } };

const WebShare = ({
  title, text, url, successCallBack, children
}) => {
  return (
    <RWebShare data={{ text, url, title, }} onClick={successCallBack}>
      {children}
    </RWebShare>
  );
};

WebShare.propTypes = propTypes;
WebShare.defaultProps = defaultProps;

export default WebShare;
