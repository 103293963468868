import _ from 'lodash';
import { PipeDefinitionUtility } from '../index';
import { buidInstrumentOutputSchema } from './instrumentOutputSchema';
import { buildIndividualStopOutputSchema } from './individualStopOutputSchema';
import { buildStrategyStopOutputSchema } from './strategyStopOutputSchema';
import { buildCommonEntryOrExitOutputSchema } from './commonEntryOrExitOutputSchema';
import { buildBasketOutputSchema } from './basketOutputSchema';

const getOutputSchemaForPipe = (pipe, pipeIndex) => {
  const { type } = pipe;
  const pipeDefinition = PipeDefinitionUtility.getDefinitions()[type];
  if (pipeDefinition) {
    return _.chain(pipeDefinition.buildOutputSchema(pipe))
      .map((schema) => ({ ...schema, pipeIndex }))
      .value();
  }
  return [];
};

export const getOutputSchema = (quantConfig) => {
  if (_.isEmpty(quantConfig)) { return []; }

  const {
    pipeConfigs = [],
    cases = [],
    maxInstrumentsInGroup,
  } = quantConfig;
  return _.flatten([
    _.flatMap(pipeConfigs, getOutputSchemaForPipe),
    buidInstrumentOutputSchema(maxInstrumentsInGroup),
    buildIndividualStopOutputSchema(cases, pipeConfigs),
    buildBasketOutputSchema(cases),
    buildCommonEntryOrExitOutputSchema(),
    buildStrategyStopOutputSchema()
  ]).map((schema) => ({ ...schema }));
};
