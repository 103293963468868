import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  optionItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.string,
};

const defaultProps = {
  disabled: false,
  style: 'form-control'
};

const Select = ({
  value,
  onChange,
  optionItems,
  disabled,
  style
}) => {
  const optionIteration = (optionValue, key) => (
    <option value={optionValue.value} key={key}>{optionValue.optionShow}</option>
  );

  return (
    <select
      className={style}
      onChange={onChange}
      value={value}
      disabled={disabled}
    >
      {_.map(optionItems, optionIteration)}
    </select>
  );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
