import { retryOrder } from 'common/api/orders';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import React from 'react';
import PropTypes from 'prop-types';
import { isEligibleForRetry } from './eligibleForRetry';

const propTypes = {
  order: PropTypes.shape({}).isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const defaultProps = {};

const RetryOrder = ({ order, orders }) => {
  // user related data
  const { currentUserId } = window;

  // order related data
  const userId = _.get(order, 'user_id', '');
  const orderId = _.get(order, 'id', '');
  const orderableId = _.get(order, 'orderable_id', '');

  if (!(`${currentUserId}` === `${userId}`)) return null;
  if (!isEligibleForRetry(order, orders)) return null;

  return (
    <button
      type="button"
      className="btn btn-sm ml-2 btn-link"
      id="retry"
      onClick={() => retryOrder(orderableId, orderId)
        .then(({ message }) => { reactToastify(message, TOASTIFY_TYPES.SUCCESS); })
        .catch(() => { })}
    >
      <u>Retry Order</u>
    </button>
  );
};

RetryOrder.propTypes = propTypes;
RetryOrder.defaultProps = defaultProps;

export default RetryOrder;
