import { ORDER_TYPES, RUN_TYPES } from 'common/constants/index';
import moment from 'moment';

const BUY = 'buy';
const DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm:ss';
const IST_TIME_ZONE = '+05:30';

export const getConvertedTime = (time) => {
  if (_.includes(time, IST_TIME_ZONE)) return moment(time);

  return moment.utc(time);
};

export const getformattedTime = (time) => {
  if (!moment(time).isValid()) return null;

  return getConvertedTime(time).format(DATE_TIME_FORMAT);
};

export const getGroupedOrderProfit = (entryType, entryPrice, exitPrice, quantity) => {
  if (!entryPrice || !exitPrice) return null;

  const parsedQuantity = parseFloat(quantity);
  const parsedEntryPrice = parseFloat(entryPrice);
  const parsedExitPrice = parseFloat(exitPrice);

  if (entryType === BUY) {
    return (parsedQuantity * parsedExitPrice) - (parsedQuantity * parsedEntryPrice);
  }

  return (parsedQuantity * parsedEntryPrice) - (parsedQuantity * parsedExitPrice);
};

export const getCompletedOrders = (orders, runType, orderType) => {
  const {
    automatic: { value: automatic }, oneClick: { value: oneclick }
  } = ORDER_TYPES;
  const isBacktest = runType === RUN_TYPES.historic;
  const isActualLive = !isBacktest && [automatic, oneclick].includes(orderType);

  const entryOrders = _.filter(orders, (order) => order.is_entry === true || order.is_entry === 'true');
  let exitOrders = _.filter(orders, (order) => (order.is_entry === false || order.is_entry === 'false') && (
    !isActualLive || isBacktest || order.status === 'executed'
  ));

  if (_.isEmpty(exitOrders) && !isBacktest) {
    exitOrders = _.filter(orders, (order) => {
      return (!order.is_entry || order.is_entry === 'false')
        && (!order.is_stop_loss || order.is_stop_loss === 'false')
        && (!isActualLive || order.status === 'executed');
    });
  }

  const COMPLETED_STATUSES = ['executed', 'cancelled', 'failed', 'partially_executed'];

  const shouldExitOrderCompletedBasedOnStatus = () => {
    // this function is mainly for actual live
    const exitCompleteOrders = _.filter(orders, (order) => {
      return (!order.is_entry || order.is_entry === 'false')
        && (!order.is_stop_loss || order.is_stop_loss === 'false')
        && (_.includes(COMPLETED_STATUSES, order.status));
    });

    const isExitCompletedForActual = !_.isEmpty(exitCompleteOrders);
    const entryPrice = _.get(_.head(entryOrders), 'price', null);

    if ((entryPrice === null || isExitCompletedForActual)) { return true; }

    return false;
  };

  const isCompleted = _.size(entryOrders) === _.size(exitOrders);
  const shouldCalculateUnrealizedProfit = !(isCompleted || (
    isActualLive && shouldExitOrderCompletedBasedOnStatus()
  ));

  return {
    isCompleted,
    completedOrders: isCompleted ? [...entryOrders, ...exitOrders] : [],
    shouldCalculateUnrealizedProfit
  };
};
