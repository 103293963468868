import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyOptionConfigs, getOptionConfigStrategies } from 'modules/TransactionsBuilder/configs';
import classNames from 'classnames';
import OptionSubStrategySelector from './OptionSubStrategySelector';

const propTypes = {
  optionStrategy: PropTypes.string.isRequired,
  onStrategyUpdate: PropTypes.func.isRequired,
  optionSubStrategy: PropTypes.string.isRequired,
  strikeBasedOn: PropTypes.string.isRequired,
};
const defaultProps = {};

const OptionStrategySelector = ({
  optionStrategy, onStrategyUpdate, optionSubStrategy, strikeBasedOn
}) => {
  const strategies = getOptionConfigStrategies(strikeBasedOn);
  const onStrategyChange = (event) => {
    const newStrategy = event.target.value;
    const newOptionConfigs = newStrategy === 'custom' ? [].concat(getEmptyOptionConfigs())
      : _.get(_.values(strategies[newStrategy]), '[0].optionConfigs', []);

    const newSubStrategy = newStrategy === 'custom' ? ''
      : _.get(_.keys(strategies[newStrategy]), '0', '');

    const newMode = { optionStrategy: newStrategy, transaction: 'option', optionSubStrategy: newSubStrategy };

    onStrategyUpdate(newMode, newOptionConfigs);
  };

  const renderStrategyOptions = _.map(strategies, (strategyConfig, strategyKey) => {
    const dropDownStyle = classNames(
      'dropdown-item',
      'text-capitalize',
      { active: strategyKey === optionStrategy }
    );

    return (
      <button
        type="button"
        className={dropDownStyle}
        key={strategyKey}
        value={strategyKey}
        onClick={onStrategyChange}
      >
        {strategyKey}
      </button>
    );
  });

  return (
    <div className="options-config-section d-flex align-items-baseline gap-10">
      <div className="dropdown border-bottom border-primary custom-dropdown">
        <button
          className="p-0 btn dropdown-toggle text-primary text-capitalize"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {optionStrategy}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {renderStrategyOptions}
        </div>
      </div>
      <span>:</span>

      <OptionSubStrategySelector
        strikeBasedOn={strikeBasedOn}
        optionSubStrategy={optionSubStrategy}
        optionStrategy={optionStrategy}
        onStrategyUpdate={onStrategyUpdate}
      />
    </div>
  );
};

OptionStrategySelector.propTypes = propTypes;
OptionStrategySelector.defaultProps = defaultProps;

export default OptionStrategySelector;
