import * as Sentry from '@sentry/browser';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const wrappedFetch = (...args) => fetch(...args)
  .then((response) => {
    if (response.status === 401 || response.status === 403) {
      console.log(`Reloading because of api call failure with status ${response.status}`);
      window.location.reload();
      reactToastify('Un-authorized, Please Login and continue', TOASTIFY_TYPES.ERROR);

      return new Promise(() => { });
    }

    if (response.status === 422 || response.status === 404) {
      return Promise.reject(response);
    }

    if (!response.ok) {
      return response.text().then((errors) => {
        throw Error(`Failed ${args[0]} 
        status: ${response.status} ${response.status}. body: ${errors} -${JSON.stringify(response)}`);
      });
    }

    return response;
  });

export const handleApiError = async (errorResponse) => {
  if (errorResponse.status !== 422) {
    Sentry.captureException(errorResponse);
    console.log('Error occured during api call ', errorResponse);
    return;
  }

  const errorContent = await errorResponse.json();
  const errorMessage = `Error creating folder!. ${_.chain(errorContent)
    .get('errors')
    .map((message, key) => `${key} ${message}`)
    .value()}`;

  reactToastify(errorMessage, TOASTIFY_TYPES.ERROR);
};

export default wrappedFetch;
