import { useEffect, useState } from 'react';

const TIP_LOCAL_STORAGE_KEY = 'tipsPopup';

const useTipsLocalStorage = () => {
  const [showModal, setShowModal] = useState(false);
  const today = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    const lastModalDate = localStorage.getItem(TIP_LOCAL_STORAGE_KEY);

    if (!lastModalDate) {
      setShowModal(true);
      return;
    }

    if (today !== lastModalDate) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem(TIP_LOCAL_STORAGE_KEY, today);
    setShowModal(false);
  };

  return { showModal, handleCloseModal };
};

export default useTipsLocalStorage;
