import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const propTypes = {
  conditionOperator: PropTypes.string,
  onUpdateConditionalOperator: PropTypes.func.isRequired,
};
const defaultProps = {
  conditionOperator: '',
};

const ConditionalOperator = ({
  conditionOperator, onUpdateConditionalOperator
}) => {
  return (
    <div className="and-or-operator new">
      <ToggleButtonGroup
        className="btn-group btn-group-sm btn-group-toggle"
        type="radio"
        size="sm"
        name="options"
        defaultValue={conditionOperator}
        onChange={onUpdateConditionalOperator}
      >
        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          className="btn-lightness track"
          value="and"
        >
          AND
        </ToggleButton>
        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          className="btn-lightness track"
          value="or"
        >
          OR
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

ConditionalOperator.propTypes = propTypes;
ConditionalOperator.defaultProps = defaultProps;

export default memo(ConditionalOperator);
