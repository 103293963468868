import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import StopsInput from 'v2/common/components/StopsInput/index';
import { SL_TRAIL_TYPES } from 'v2/common/constants/index';
import AddTrailing from 'v2/common/components/TrailingStopLoss/AddTrailing';
import TrailingFields from 'v2/common/components/TrailingStopLoss/TrailingFields';
import {
  getTrailingStopLossValidationErrors
} from 'v2/common/components/TrailingStopLoss/errorConfig';
import { defaultLinearTrail } from 'v2/modules/withRunForm/configs/index';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  validator: PropTypes.shape({}).isRequired
};

const StopLossFormField = ({
  name: propsName, value, control, errors, onUpdatePipeConfig
}) => {
  const uniqueId = useMemo(() => _.uniqueId(`pipe_${propsName}_`), [propsName]);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');
  const [stopLossConfig, setStopLossConfig] = useState(value);
  const stopLossErrors = _.get(errors, `${uniqueId}.errorConfigs`, {});

  const { type, value: stopLossValue, trail } = stopLossConfig;
  const [isTrailingStopLoss, setIsTrailingStopLoss] = useState(
    (!_.isEmpty(trail?.type) && trail?.trailingValue > 0) && stopLossValue > 0
  );

  const rulesValidator = useMemo(() => {
    return {
      validate: () => {
        if (!stopLossValue) {
          return 'Please enter the stop loss value';
        }

        if (isTrailingStopLoss) {
          const tslErrors = getTrailingStopLossValidationErrors(trail);
          if (tslErrors.length > 0) {
            return tslErrors[0];
          }
        }

        return null;
      }
    };
  }, [stopLossValue, isTrailingStopLoss, trail]);

  useEffect(() => {
    if (value && value !== stopLossConfig && Object.keys(value).length > 0) {
      onUpdatePipeConfig(propsName, stopLossConfig);
    }
  }, [onUpdatePipeConfig, propsName, stopLossConfig, value, isTrailingStopLoss]);

  const renderTsl = () => {
    if (!isTrailingStopLoss) return null;

    return (
      <div
        className={`full__width form-group transaction_details-items trailing_stop_loss ${
          trail.type === SL_TRAIL_TYPES.BookAndLinear ? 'tsl_book_and_linear' : ''
        }`}
      >
        <TrailingFields
          trailingStopLossConfig={trail}
          setTrailingStopLossConfig={(updatedConfig) => {
            setStopLossConfig({
              ...stopLossConfig,
              trail: updatedConfig
            });
          }}
          stopLossType={type}
        />
      </div>
    );
  };

  const onChangeIsTrailingStopLoss = (isTrailingStopLossEnabled) => {
    const sanitizedConfig = !isTrailingStopLossEnabled
      ? { ...stopLossConfig, trail: defaultLinearTrail }
      : stopLossConfig;

    setIsTrailingStopLoss(isTrailingStopLossEnabled);
    setStopLossConfig(sanitizedConfig);
  };

  return (
    <Form.Group className="full__width pipe-config-stop-loss-form-field">
      <Controller
        render={({ onChange }) => (
          <div>
            <div className="transaction_details-items stop_loss">
              <StopsInput
                stopsType={type}
                stopsValue={stopLossValue}
                onStopsValueChange={({ type: newType, value: newValue }) => {
                  onChange(stopLossConfig);
                  setStopLossConfig({ ...stopLossConfig, type: newType, value: newValue });
                }}
                shouldIgnorePoints
                shouldIgnoreUnderlyingSpot
              />
            </div>
            <div className="add-tsl mt-4">
              <AddTrailing
                isStopLossPresent={stopLossValue > 0}
                onShowTrailingStopLoss={(isTrailingStopLossEnabled) => {
                  onChangeIsTrailingStopLoss(isTrailingStopLossEnabled);
                }}
                isShowCheckBox
                isTrailingStopLossEnabled={isTrailingStopLoss}
              />
            </div>
            {renderTsl()}
            {!_.isEmpty(stopLossErrors) && (
              <div className="invalid-feedback d-block">
                {_.map(stopLossErrors, (error, idx) => {
                  return <div key={idx}>{error}</div>;
                })}
              </div>
            )}
          </div>
        )}
        control={control}
        defaultValue={stopLossConfig}
        name={uniqueId}
        rules={rulesValidator}
      />
      <ErrorMesssage message={errorMessage} />
    </Form.Group>
  );
};

StopLossFormField.propTypes = propTypes;

export default StopLossFormField;
