import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from '../ModalComponent';

const propTypes = {
  onForceExit: PropTypes.func.isRequired,
  divClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  isShowText: PropTypes.bool,
  label: PropTypes.string
};
const defaultProps = {
  divClassName: 'square-off-btn track',
  iconClassName: 'material-icons-outlined',
  isShowText: true,
  label: 'Square off'
};

const ForceExitConfirmation = ({
  onForceExit, divClassName, iconClassName, isShowText, label
}) => {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const modalProps = {
    size: 'sm',
    shouldShow: shouldShowModal,
    onClose: () => { setShouldShowModal(false); },
    okBtnTitle: 'Square Off',
    onSave: () => {
      setShouldShowModal(false);
      onForceExit();
    },
  };

  const renderModal = () => {
    return (
      <ModalComponent {...modalProps}>
        <div className="text-center">
          <h5 className="mb-3">Square Off Confirmation</h5>
          <img src="/exit-signal.svg" alt="Delete" className="alert-modal-img mb-4" />
          <p>
            Once Square Off, transaction will be completed
          </p>
        </div>
      </ModalComponent>
    );
  };

  const renderButton = () => {
    return (
      <div
        className={divClassName}
        onClick={() => onForceExit()}
        key="force-exit"
        data-track-category="Quant Force Exit"
        data-track-action="force exit"
        data-track-label="Quant Force Exit"
      >
        <i className={iconClassName}>exit_to_app</i>
        {isShowText && <span>{label}</span>}
      </div>
    );
  };

  return (
    <>
      {renderButton()}
      {renderModal()}
    </>
  );
};

ForceExitConfirmation.propTypes = propTypes;
ForceExitConfirmation.defaultProps = defaultProps;

export default ForceExitConfirmation;
