import { SPECIAL_CHARACTER_REGEX } from 'v2/common/constants/index';

const OutputSchemaUtility = (() => {
  return {
    filterByTypes: (outputSchema, types) => _.filter(outputSchema, (schema) => {
      return _.some(schema.shouldShowConfigs || [], (type) => _.includes(types, type));
    }),
    filterByOnlyEnabled: (outputSchema) => _.filter(outputSchema, (schema) => {
      return !_.isEmpty(schema.shouldShowConfigs);
    }),
    findByName: (outputSchema, findName) => _.find(
      outputSchema, ({ name }) => name?.toString() === findName?.toString()
    ) || {},
    filterByCaseIndex: (outputSchema, caseIndex) => _.filter(outputSchema, (schema) => {
      if (schema?.caseIndex === undefined) return true;

      return schema?.caseIndex === caseIndex;
    }),
    filterSchemaPriorToPipeIndex: (outputSchema, pipeIndex) => _.filter(outputSchema, (schema) => {
      if (schema?.pipeIndex === undefined) return true;
      if (pipeIndex === undefined) return true;

      return schema?.pipeIndex < pipeIndex;
    }),
    filterSchemaPriorToAdjustmentIndex: (
      outputSchema, adjustmentIndex
    ) => _.filter(outputSchema, (schema) => {
      if (schema?.adjustmentIndex === undefined) return true;

      return schema?.adjustmentIndex < adjustmentIndex;
    }),
    filterSchemaUpToAdjustmentIndex: (
      outputSchema, adjustmentIndex
    ) => _.filter(outputSchema, (schema) => {
      if (schema?.adjustmentIndex === undefined) return true;

      return schema?.adjustmentIndex <= adjustmentIndex;
    }),
    filterByCategory: (outputSchema, category) => _.filter(outputSchema, (schema) => {
      return schema?.category === category;
    }),
  };
})();

const TypeheadSchemaUtility = (() => {
  const getBadge = (tag, style) => {
    const badge = tag ? `<span class="badge badge-info" ${style}>${tag}</span>` : '';
    return badge;
  };

  const getLabelString = (label) => {
    const labelString = label?.toString() || '';
    return SPECIAL_CHARACTER_REGEX.test(labelString) ? labelString : _.startCase(labelString);
  };

  return {
    getOption: (schema) => {
      const tag = schema?.tagConfig?.tag || '';
      const backgroundColor = schema?.tagConfig?.color || '';
      const style = backgroundColor ? `style="background-color: ${backgroundColor}"` : '';
      const badge = getBadge(tag, style);
      const labelString = getLabelString(schema?.label);

      return {
        id: schema.name?.toString(),
        label: `${badge} ${labelString}`,
        searchLabel: `${tag} ${labelString}`,
      };
    },
    getSelectedOption: (outputSchema, value) => {
      if (!value) {
        return [];
      }
      if (_.isArray(value)) {
        return _.chain(value)
          .map((val) => TypeheadSchemaUtility.getSelectedOption(outputSchema, val))
          .flattenDeep()
          .value();
      }

      const schema = OutputSchemaUtility.findByName(outputSchema, value);
      if (!_.isEmpty(schema)) {
        return [TypeheadSchemaUtility.getOption(schema)];
      }

      return [{
        id: (value || '')?.toString(),
        label: (value || '')?.toString(),
        searchLabel: (value || '')?.toString(),
      }];
    },
    getOptionFilteredBySearchText: (option, filterProps) => {
      // both arguments are from react-bootstrap-typeahead component
      try {
        const regex = new RegExp(filterProps?.text?.replace(/\s/g, ''), 'i');
        return regex.test(option?.searchLabel?.replace(/\s/g, ''));
      } catch (error) {
        return true;
      }
    }
  };
})();

export {
  OutputSchemaUtility,
  TypeheadSchemaUtility,
};
