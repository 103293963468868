import React, { useEffect } from 'react';
import AdvancedOptions from 'v2/modules/withRunForm/components/AdvancedOptions/index';
import Cases from 'v2/modules/withRunForm/components/Cases/index';
import ExpiryDetails from 'v2/modules/withRunForm/components/ExpiryDetails/index';
import FeatureEnablements from 'v2/modules/withRunForm/components/FeatureEnablements/index';
import InstrumentDetails from 'v2/modules/withRunForm/components/InstrumentDetails/index';
import OrderExecutionDetails from 'v2/modules/withRunForm/components/OrderExecutionDetails/index';
import OverallExitCondition from 'v2/modules/withRunForm/components/OverallExitCondition/index';
import PipeConfigs from 'v2/modules/withRunForm/components/PipeConfigs/index';

const QuantBuilder = () => {
  useEffect(() => {
    function onIntentsScroll() {
      $('.intents-container')
        // eslint-disable-next-line react/no-this-in-sfc
        .prop('scrollTop', this.scrollTop)
        // eslint-disable-next-line react/no-this-in-sfc
        .prop('scrollLeft', this.scrollLeft);
    }
    const debouncedOnIntentScroll = _.debounce(onIntentsScroll, 100);
    $('.intents-container').on('scroll', debouncedOnIntentScroll);
    return () => {
      $('.intents-container').off('scroll', debouncedOnIntentScroll);
    };
  }, []);

  return (
    <div className="card-wrapper mt-2">
      <InstrumentDetails />
      <PipeConfigs />
      <FeatureEnablements />
      <Cases />
      <AdvancedOptions />
      <OverallExitCondition />
      <ExpiryDetails />
      <OrderExecutionDetails />
    </div>
  );
};

QuantBuilder.propTypes = {};
QuantBuilder.defaultProps = {};

export default QuantBuilder;
