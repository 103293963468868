import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  isStopLoss: PropTypes.bool.isRequired
};

const defaultProps = {};

const StopValueHint = ({ isStopLoss }) => {
  const oneIndentation = ' ';
  const arrow = '=>';

  if (isStopLoss) {
    return (
      <div>
        <b>Percentage:</b>
        <div className="pl-1 pt-1">
          <div className="mb-1">
            Enter Action  : Buy
            <br />
            Enter Price   : Rs. 500
            <br />
          </div>
          <div className="mb-3">
            SL Percentage : 5%   SL Target
            {oneIndentation}
            {arrow}
            {oneIndentation}
            Rs. 475
            <br />
            SL Percentage : 10%  SL Target
            {oneIndentation}
            {arrow}
            {oneIndentation}
            Rs. 450
          </div>
        </div>

        <b>Rupees:</b>
        <div className="pl-1 pt-1">
          <div className="mb-1">
            Enter Action  : Buy
            <br />
            Enter Price   : Rs. 500
            <br />
            Lot Size      : 25 Quantity
          </div>

          SL Amount     : 1000  SL Target
          {oneIndentation}
          {arrow}
          {oneIndentation}
          Rs.460
          {oneIndentation}
          <div className="tx-10 text-muted mb-2">
            (Loss Per Quantity * Quantity
            {oneIndentation}
            {arrow}
            {oneIndentation}
            40 * 25 = 1000)

          </div>

          SL Amount     : 2000  SL Target
          {oneIndentation}
          {arrow}
          {oneIndentation}
          Rs.420
          {oneIndentation}
          <div className="tx-10 text-muted">
            (Loss Per Quantity * Quantity
            {oneIndentation}
            {arrow}
            {oneIndentation}
            80 * 25 = 2000)

          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <b>Percentage:</b>
      <div className="pl-1">
        <div className="mb-1">
          Enter Action  : Buy
          <br />
          Enter Price   : Rs. 500
        </div>
        <div className="mb-4">
          TP Percentage : 5%   TP Target
          {oneIndentation}
          {arrow}
          {oneIndentation}
          Rs. 525
          <br />
          TP Percentage : 10%  TP Target
          {oneIndentation}
          {arrow}
          {oneIndentation}
          Rs. 550
        </div>
      </div>

      <b>Rupees:</b>
      <div className="pl-1">
        <div className="mb-1">
          Enter Action  : Buy
          <br />
          Enter Price   : Rs. 500
          <br />
          Lot Size      : 25 Quantity
        </div>

        TP Amount     : 1000  TP Target
        {oneIndentation}
        {arrow}
        {oneIndentation}
        Rs.540

        <div className="tx-10 text-muted mb-2">
          (Profit Per Quantity * Quantity
          {oneIndentation}
          {arrow}
          {oneIndentation}
          40 * 25 = 1000)
        </div>

        TP Amount     : 2000 TP Target
        {oneIndentation}
        {arrow}
        {oneIndentation}
        Rs.580

        <div className="tx-10 text-muted">
          (Profit Per Quantity * Quantity
          {oneIndentation}
          {arrow}
          {oneIndentation}
          80 * 25 = 2000)
        </div>
      </div>
    </div>
  );
};

StopValueHint.propTypes = propTypes;
StopValueHint.defaultProps = defaultProps;

export default StopValueHint;
