import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import TransactionsBuilder from 'modules/TransactionsBuilder';
import { onConfigureAutoAdjustment } from
  // eslint-disable-next-line max-len
  'modules/TransactionsBuilder/NewTransactionBuilder/components/AdvancedFeaturesEnablement/AdjustmentAdaptor/index';
import { setOrderConfigs } from 'modules/QuantBuilder/actions';
// import { advancedFeatureConfigs } from 'modules/QuantBuilder/config/signalConfigs';

const propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    key: PropTypes.string,
    orderConfigIndex: PropTypes.number,
    validatorErrors: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  transactionConfigsValidator: PropTypes.shape({}),
};

const defaultProps = {
  transactionConfigsValidator: {}
};

const TransactionsBuilderWrapper = (props) => {
  const {
    config: { orderConfigIndex, validatorErrors },
  } = props;
  const globalReduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  const orderConfigs = _.get(globalReduxState, 'quantConfig.orderConfigs');
  const isInitailLoad = useRef(true);

  const currentOrderConfig = _.get(orderConfigs, orderConfigIndex, {});
  const transactionConfigs = _.get(currentOrderConfig, 'transactionConfigs', []);
  const noOfInstrumentsInGroup = _.get(globalReduxState, 'quantConfig.instrumentGroups.0', []).length;
  const maxInstrumentsInGroup = _.get(
    globalReduxState, 'quantConfig.maxInstrumentsInGroup', noOfInstrumentsInGroup
  );

  const onComplete = (newTransactionConfigs) => {
    dispatch(setOrderConfigs(onConfigureAutoAdjustment({
      orderConfigs, orderConfigIndex, newTransactionConfigs, maxInstrumentsInGroup
    })));
  };

  useEffect(() => {
    if (!isInitailLoad.current) {
      dispatch(setOrderConfigs(onConfigureAutoAdjustment({
        orderConfigs, orderConfigIndex, newTransactionConfigs: transactionConfigs, maxInstrumentsInGroup
      })));
    } else { isInitailLoad.current = false; }
  }, [maxInstrumentsInGroup]);

  return (
    <>
      <TransactionsBuilder
        transactionConfigsErrors={validatorErrors}
        transactionConfigs={transactionConfigs}
        onTransactionConfigsChange={onComplete}
        orderConfigIndex={orderConfigIndex}
      />
    </>
  );
};

TransactionsBuilderWrapper.propTypes = propTypes;
TransactionsBuilderWrapper.defaultProps = defaultProps;

export default TransactionsBuilderWrapper;
