import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

const propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  isShowHeader: PropTypes.bool
};

const defaultProps = {
  children: null,
  errorMessage: '',
  isShowHeader: true
};

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null, error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
      error
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children, errorMessage, isShowHeader } = this.props;

    if (errorInfo) {
      Sentry.captureException(error); // sentry capture

      return (
        <div className="text-center p-4">
          {isShowHeader && <h2>Something went wrong</h2>}
          <span className="invalid-feedback d-block">
            {errorMessage}
          </span>
          <details className="hidden-for-future">
            {error && error.toString()}
            <br />
            {errorInfo.componentStack || null}
          </details>
        </div>
      );
    }
    return children;
  }
}

ErrorHandler.propTypes = propTypes;
ErrorHandler.defaultProps = defaultProps;

export default ErrorHandler;
