import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import ErrorMesssage from 'common/components/ErrorMesssage';
import classNames from 'classnames';

const propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  validator: PropTypes.shape({}).isRequired,
};
const defaultProps = {};

const Quantity = ({ quantity, onQuantityChange, validator }) => {
  const { errors, control } = useFormContext();
  const [quantityName] = useState(_.uniqueId('optionsQuantity-'));
  const inputBoxStyle = classNames('form-control form-control-sm', { 'is-invalid': !!errors[quantityName] });

  return (
    <div className="total-lot">
      <Controller
        render={({ onChange, ref, name }) => (
          <Form.Control
            type="number"
            ref={ref}
            className={inputBoxStyle}
            name={name}
            value={quantity === undefined ? '' : quantity.toString()}
            min={1}
            onChange={(event) => {
              onQuantityChange({ quantity: event.target.value });
              onChange(event.target.value);
            }}
          />
        )}
        rules={validator}
        name={quantityName}
        defaultValue={quantity}
        control={control}
      />
      <ErrorMesssage
        error={errors[quantityName]}
      />
    </div>
  );
};

Quantity.propTypes = propTypes;
Quantity.defaultProps = defaultProps;

export default Quantity;
