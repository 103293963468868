import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOutputSchema } from 'v2/common/quantConfig/outputSchema/index';

const useOutputSchema = () => {
  const quantConfig = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig'));
  const outputSchema = useMemo(() => getOutputSchema(quantConfig), [quantConfig]);

  return outputSchema;
};

export default useOutputSchema;
