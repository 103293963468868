// vendor import
import React, { useEffect, useState } from 'react';

import { RUN_FINISHED_STATUSES } from 'common/configs/runStatus';
import { strategyPropTypes, userPropTypes } from 'common/propTypes';
import OrderStore from 'ui/run/RunShow/OrderStore/index';

import { RUN_TYPES } from 'common/constants/index';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';
import ViewConfigConditions from
  'ui/run/RunShow/components/RunTitleAndActions/ViewConfigs/ViewConfigConditions';
import { fetchOutputSchema } from 'common/quantConfig/outputSchema';
import StrategyRunDetails from './StrategyRunDetails';
import TipsQuantConfigBuilderForm from './TipsQuantConfigBuilderForm';

const propTypes = {
  strategy: strategyPropTypes.isRequired,
  currentUser: userPropTypes,
};

const defaultProps = { currentUser: {} };

const TipsQuantConfigBuilder = ({ strategy, currentUser }) => {
  const [outputSchema, setOutputSchema] = useState([]);
  const currentUserId = _.get(currentUser, 'id', '');
  const strategyUserId = _.get(strategy, 'user_id', '-');
  const isManualSignalsAllowed = _.get(strategy, 'is_manual_signals_allowed', false);
  const { liveJobs, liveRun } = strategy;
  const liveJobIds = _.map(liveJobs, 'id').sort();
  const liveRunId = _.get(liveJobs, '[0].run_id');
  const { status, order_type: orderType } = liveRun;

  const isRunning = !_.includes(RUN_FINISHED_STATUSES, status);
  const { isUserSignedIn } = window;
  const isShow = isManualSignalsAllowed && isUserSignedIn && (currentUserId === strategyUserId);
  if (!isShow) return null;

  const quantConfig = _.get(liveRun, 'quant_config', {});
  useEffect(() => {
    if (!_.isEmpty(quantConfig)) {
      fetchOutputSchema(quantConfig).then((schema) => { setOutputSchema(schema); });
    }
  }, []);

  const renderQuantDetails = () => {
    if (_.isEmpty(quantConfig) || !isRunning) return null;

    const {
      instrumentGroups,
      maxInstrumentsInGroup = _.head(instrumentGroups).length,
      optionsExpiryCycle,
      orderConfigs
    } = quantConfig;

    const groupType = maxInstrumentsInGroup === 1 ? 'Single' : 'Pair';

    return (
      <div className="tips-transaction-wrapper">
        <div className="details-list">
          <label className="col-form-label-sm mb-0">Instrument</label>
          <div className="pl-1"><InstrumentGroupsSentence instrumentGroups={instrumentGroups} /></div>
        </div>

        <ViewConfigConditions
          orderConfigs={orderConfigs}
          optionsExpiryCycle={optionsExpiryCycle}
          hasMultipleInstruments={groupType === 'Pair'}
          outputSchema={outputSchema}
        />
      </div>
    );
  };

  return (
    <div className="tips-quant-config mb-2 border-top pt-3">
      <OrderStore
        runIds={[liveRunId]}
        jobIds={[liveJobIds]}
        runType={RUN_TYPES.live}
        orderType={orderType}
        run={liveRun}
      >
        <div>
          <StrategyRunDetails liveRun={liveRun} isRunning={isRunning} />
          {renderQuantDetails()}
          {!isRunning && <TipsQuantConfigBuilderForm strategy={strategy} />}
        </div>
      </OrderStore>
    </div>
  );
};

TipsQuantConfigBuilder.propTypes = propTypes;
TipsQuantConfigBuilder.defaultProps = defaultProps;

export default TipsQuantConfigBuilder;
