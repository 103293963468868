import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  couponCode: PropTypes.string.isRequired,
  onChangeCouponCode: PropTypes.func.isRequired,
  isApplied: PropTypes.bool.isRequired,
  onCouponUpdate: PropTypes.func.isRequired,
  message: PropTypes.string,
  isMobile: PropTypes.bool
};

const defaultProps = {
  message: '',
  isMobile: false,
};

const PaymentCoupons = ({
  couponCode, onChangeCouponCode, isApplied, onCouponUpdate, message, isMobile
}) => {
  const isInValid = _.includes(message, 'Invalid') || _.includes(message, 'Expired');

  const messageClassName = classNames('tx-12', {
    'd-block': true,
    'valid-feedback': !isInValid,
    'invalid-feedback': isInValid
  });

  const renderIconOrContent = () => {
    if (isMobile) {
      return isApplied ? (<i className="fa-solid fa-xmark" />) : (<i className="fa-solid fa-check" />);
    }

    return isApplied ? 'Remove' : 'Apply Coupon';
  };

  return (
    <div>
      <div className="card-body coupon-code-wrapper ">
        <div className="coupon-code">
          <div className="coupon-code-input border rounded p-1">
            <div className="input-group">
              <input
                type="text"
                value={couponCode}
                disabled={isApplied}
                onChange={(event) => {
                  const { target: { value } } = event;
                  onChangeCouponCode(value);
                }}
                className="form-control border-0 shadow-none"
                placeholder="Ex 'qum20'"
                aria-label="Coupon Code"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button
                  className={isApplied ? 'btn btn-outline-danger rounded'
                    : 'btn btn-light rounded'}
                  onClick={() => { onCouponUpdate(); }}
                  type="button"
                >
                  {renderIconOrContent()}
                </button>
              </div>
            </div>
          </div>
          {message && <div className={messageClassName}>{message}</div>}
        </div>
      </div>
    </div>
  );
};

PaymentCoupons.propTypes = propTypes;
PaymentCoupons.defaultProps = defaultProps;

export default PaymentCoupons;
