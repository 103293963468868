export { convertToTransactionConfig } from './builderConfigToTransactionConfig';
export { transactionConfigToTransactionBuilderConfig } from './transactionConfigToBuilderConfig';
export { checkIsFutureAndOption } from './builderConfigToTransactionConfig';

export const getDuplicateConfigIds = (transactionBuilderConfigs) => (
  _.chain(transactionBuilderConfigs)
    .reduce((result, { optionType, strike, sortId }) => {
      if (!optionType) return result;
      const dupconfigKey = `${optionType}${strike}`;

      if (!result[dupconfigKey]) {
      // eslint-disable-next-line no-param-reassign
        result[dupconfigKey] = [sortId];

        return result;
      }

      result[dupconfigKey].push(sortId);
      return result;
    }, {})
    .filter((duplicates) => duplicates.length > 1)
    .flattenDeep()
    .value()
);
