import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { RUN_FINISHED_STATUSES } from 'common/configs/runStatus';
import ModalComponent from 'common/components/ModalComponent';
import { OrderContext } from '../../OrderStore/index';

const propTypes = {
  status: PropTypes.string,
  isLiveRun: PropTypes.bool.isRequired,
  onStopRun: PropTypes.func.isRequired,
  isRunBelongsToUser: PropTypes.bool.isRequired,
};

const defaultProps = { status: '' };

const StopRunbtn = ({
  status, isLiveRun, onStopRun, isRunBelongsToUser
}) => {
  if (!isLiveRun || !isRunBelongsToUser || _.includes(RUN_FINISHED_STATUSES, status)) return null;

  const [isShowModal, setIsShowModal] = useState(false);

  const { transactions } = useContext(OrderContext);
  const lastTransaction = _.last(transactions) || {};
  const pairedOrders = _.get(lastTransaction, 'pairedOrders', []);

  const isLastTransactionNotCompleted = _.some(pairedOrders, (pairedOrder) => {
    const { isManualExitShow } = pairedOrder;
    return isManualExitShow;
  });

  const modalProps = {
    title: 'Stop Confirmation',
    size: 'md',
    onClose: () => { setIsShowModal(false); },
    okBtnTitle: 'Stop Run',
    onSave: () => { onStopRun(); },
  };

  const renderModal = () => {
    return (
      <ModalComponent {...modalProps}>
        <div className="text-center">
          <img src="/exit-signal.svg" alt="user-alert" className="alert-modal-img my-4" />
          <p>Your last transaction is not yet completed</p>
          If you stop the run, you have to close your positions in broker side
        </div>
      </ModalComponent>
    );
  };

  const validateRunToStop = () => {
    if (isLastTransactionNotCompleted) setIsShowModal(true);
    else onStopRun();
  };

  return (
    <>
      <div
        className="text-nowrap btn btn-sm btn-outline-danger btn-pill common-icon-btn track"
        onClick={() => validateRunToStop()}
        key="stop-btn"
        data-track-category="Quant Stop"
        data-track-action="stop run"
        data-track-label="Quant Stop"
      >
        <i className="material-icons-outlined tx-18">stop_circle</i>
        Stop Run
      </div>
      {isShowModal && renderModal()}
    </>
  );
};

StopRunbtn.propTypes = propTypes;
StopRunbtn.defaultProps = defaultProps;

export default StopRunbtn;
