import {
  INTENT_TYPES,
  SEGMENT_CONFIG,
  STRIKE_SELECTION_TYPES,
} from 'v2/common/constants/index';
import { getFormattedOperand, getSanitizedAdjustmentSignals } from './signal';
import { moveToCostBasedAdjustmentAdder } from './moveToCost';
import { reExecuteBasedAdjutmentAdder } from './reExectute';
import { reEntryBasedAdjustmentAdder } from './reEntry';
import { getBasketOpenLegIntentsBasedOnIntents } from '../basket';
import * as quickAdjustments from './quickAdjustments';

const getAdjustmentNewTarget = (intent, intents) => {
  const {
    target: { label },
  } = intent;
  if (!label) return intent.target;

  const findIntent = _.find(
    intents,
    (bIntent) => bIntent.identifier.label === label
  );
  return findIntent.identifier;
};

const getPositionReferenceForAdjustmentIntent = (
  intent,
  intentIndex,
  caseIndex,
  adjustmentConfigIndex,
  intents
) => {
  const newIntent = _.cloneDeep(intent);
  const { identifier, type } = newIntent;

  if (!_.includes([INTENT_TYPES.OpenLeg, INTENT_TYPES.OpenBasket], type)) {
    return { ...newIntent, target: getAdjustmentNewTarget(newIntent, intents) };
  }

  const instrumentConfig = _.get(newIntent, 'instrument.subSelection')
    || _.get(newIntent, 'instrument', {});
  const segment = instrumentConfig?.type;
  const isOption = segment === SEGMENT_CONFIG.option;
  const strikeType = newIntent?.instrument?.strike?.type;
  const { IndicatorValueBasedStrikeIndex } = STRIKE_SELECTION_TYPES;

  if (isOption && strikeType === IndicatorValueBasedStrikeIndex.value) {
    const valuePath = newIntent?.instrument?.strike?.valuePath;
    if (valuePath?.includes('individualStop.')) {
      newIntent.instrument.strike.valuePath = getFormattedOperand(
        valuePath,
        intents
      );
    }
  }

  return {
    ...newIntent,
    identifier: {
      ...identifier,
      label: `${identifier?.label}`,
      type: 'Legacy',
      segment,
      caseIndex,
      adjustmentConfigIndex,
      instrumentIndex: instrumentConfig?.instrumentIndex,
      optionConfigIndex: isOption ? intentIndex : null,
    },
  };
};

const isReEntryEligible = (isReEntry, intent) => {
  return isReEntry && intent?.reEntryCount;
};

const isReExecuteEligible = (isReExecute, intent) => {
  return isReExecute && intent?.reExecuteCount;
};

const isMoveToCostEligible = (isMoveToCost, intent) => {
  return (
    isMoveToCost
    && intent?.identifier.segment === SEGMENT_CONFIG.option
    && intent?.stopLoss?.value
  );
};

const getMoveToCostOptionIndexes = (intents, optionType) => {
  return _.chain(intents)
    .map((intent) => {
      if (optionType === 'CE' && intent.instrument.optionType === 'PE') {
        return intent.identifier.optionConfigIndex;
      }
      if (optionType === 'PE' && intent.instrument.optionType === 'CE') {
        return intent.identifier.optionConfigIndex;
      }
      return undefined;
    })
    .filter((val) => val !== undefined)
    .value();
};

const getIntentsBasedAutoAdjustments = (
  adjustments,
  intents,
  caseIndex,
  isMoveToCost,
  isReEntry,
  isReExecute
) => {
  let lastAdjustmentIndex = adjustments.length;

  const newAutoAdjustments = _.chain(intents)
    .map((intent) => {
      let reEntryAutoAdjustments = [];
      let reExecuteAutoAdjustments = [];
      let moveToCostAutoAdjustments = [];

      if (isReEntryEligible(isReEntry, intent)) {
        reEntryAutoAdjustments = reEntryBasedAdjustmentAdder(
          intent,
          lastAdjustmentIndex,
          caseIndex
        );
        lastAdjustmentIndex += Number(intent?.reEntryCount) || 0;
      }
      if (isReExecuteEligible(isReExecute, intent)) {
        reExecuteAutoAdjustments = reExecuteBasedAdjutmentAdder(
          intent,
          lastAdjustmentIndex
        );
        lastAdjustmentIndex += Number(intent?.reExecuteCount) || 0;
      }
      if (isMoveToCostEligible(isMoveToCost, intent)) {
        const {
          instrument: { optionType },
        } = intent;

        const moveToCostOptionIndexes = getMoveToCostOptionIndexes(
          intents,
          optionType
        );
        moveToCostAutoAdjustments = moveToCostBasedAdjustmentAdder(
          intent,
          lastAdjustmentIndex,
          moveToCostOptionIndexes
        );
        lastAdjustmentIndex += _.isEmpty(moveToCostAutoAdjustments) ? 0 : 1;
      }

      return [
        ...reEntryAutoAdjustments,
        ...reExecuteAutoAdjustments,
        ...moveToCostAutoAdjustments,
      ];
    })
    .flattenDeep()
    .value();

  return [...adjustments, ...newAutoAdjustments];
};

export const getBeforeSubmitSanitizedAdjustments = (
  adjustments,
  baseIntents,
  caseIndex,
  isMoveToCost,
  isReEntry,
  isReExecute,
  pipeConfigs
) => {
  const openLegIntents = _.filter(
    baseIntents,
    ({ type }) => type === INTENT_TYPES.OpenLeg
  );

  return _(adjustments)
    .map((adjustmentConfig, adjustmentConfigIndex) => {
      const basketLegAddedIntents = getBasketOpenLegIntentsBasedOnIntents(
        adjustmentConfig?.intents || [],
        pipeConfigs
      );

      const newIntents = _.chain(basketLegAddedIntents)
        .partition(({ type }) => type === INTENT_TYPES.OpenLeg)
        .flatMap()
        .map((intent, intentIndex) => {
          const newIntent = getPositionReferenceForAdjustmentIntent(
            intent,
            intentIndex,
            caseIndex,
            adjustmentConfigIndex,
            openLegIntents
          );
          if (newIntent.type === INTENT_TYPES.OpenLeg) openLegIntents.push(newIntent);
          return newIntent;
        })
        .value();

      return {
        ...adjustmentConfig,
        signal: getSanitizedAdjustmentSignals(
          adjustmentConfig?.signal,
          openLegIntents
        ),
        intents: newIntents,
      };
    })
    .thru((adjustmentConfigs) => {
      const adjustmentIntents = _.chain(adjustmentConfigs)
        .map('intents')
        .flatten()
        .reject({ type: INTENT_TYPES.OpenBasket })
        .value();

      return _.chain(adjustmentConfigs)
        .thru((oldAdjustmentConfigs) => {
          return getIntentsBasedAutoAdjustments(
            oldAdjustmentConfigs,
            _.chain(baseIntents)
              .reject({ type: INTENT_TYPES.OpenBasket })
              .value(),
            caseIndex,
            isMoveToCost,
            isReEntry,
            isReExecute
          );
        })
        .thru((newAdjustmentConfigs) => {
          return getIntentsBasedAutoAdjustments(
            newAdjustmentConfigs,
            adjustmentIntents,
            caseIndex,
            false,
            isReEntry,
            isReExecute
          );
        })
        .value();
    })
    .thru((adjustmentConfigs) => {
      const adjustmentIntents = _.chain(adjustmentConfigs)
        .flatMap(({ intents: i }) => i || [])
        .filter(({ uiConfigs }) => !uiConfigs?.isAutoAdjustment)
        .value();
      const allIntents = _.chain(baseIntents)
        .concat(adjustmentIntents)
        .filter(({ type }) => type === INTENT_TYPES.OpenLeg)
        .value();
      return adjustmentConfigs.concat(quickAdjustments.getSyntheticAdjustments(allIntents));
    })
    .value();
};
