import React, { useState } from 'react';
import InstrumentGroupBuilder from 'common/components/InstrumentGroupBuilder/index';
import classNames from 'classnames';
import TemplateFooter from '../../TemplateFooter';
import {
  comparisonTypesTemplates, handleErrors, intervalTemplates,
  movingAverageTypeTemplate, transactionTypesTemplate
} from '../../config';
import Select from '../../CommonComponents/Select';
import Input from '../../CommonComponents/Input';
import TemplateSentence from '../../CommonComponents/TemplateSentence';
import LabelTags from '../../CommonComponents/LabelTags';
import {
  movingAverageDefaultConfig,
  quantConfigConversionMcp, CLOSE_PRICE_STRATEGY
} from './helper';

export const templateName = CLOSE_PRICE_STRATEGY;

const defaultConfig = movingAverageDefaultConfig;

export const Builder = (props) => {
  const segments = _.get(props, 'segments', []);
  const propSegment = _.get(props, 'segment');
  const [segment, setSegment] = useState(propSegment);

  const [templateConfig, setTemplateConfig] = useState(defaultConfig);
  const [instruments, setIntruments] = useState([]);
  const isNew = false;
  const oneIndentation = ' ';

  const onAddInstrument = (instrument) => {
    setIntruments([...instruments, instrument]);
  };

  const onDeleteAddedInstrument = (instrumentIndex) => {
    const newInstruments = _.filter(instruments, (instrument, index) => instrumentIndex !== index);
    setIntruments(newInstruments);
  };

  const colClassName = classNames('', {
    '': isNew
  });

  const cardClassName = classNames('card h-100', {
    'new-strategie': isNew
  });

  const handleStrategyChange = (key, value) => {
    if (key === 'transaction' && value === 'future') {
      setTemplateConfig({
        ...templateConfig,
        [key]: value,
        optionType: 'CE',
        strikeIndex: 0,
      });
      return;
    }

    setTemplateConfig({
      ...templateConfig,
      [key]: value
    });
  };

  return (
    <>
      <div className={colClassName}>
        <div className={cardClassName}>
          <div className="card-header">
            <h6 className="font-weight-bold">
              Moving Average Close Price
            </h6>
            <div>
              {oneIndentation}
              <LabelTags
                label="intraday"
                className="intraday"
              />
              {oneIndentation}
              <LabelTags
                label="directional"
                className="directional"
              />
            </div>
          </div>

          <div className="card-body">
            <InstrumentGroupBuilder
              isInValid={false}
              instrumentGroups={instruments}
              maxInstrumentsInGroup={1}
              onAddInstrumentGroup={(newInstrumentGroup) => onAddInstrument(newInstrumentGroup)}
              onRemoveInstrumentGroup={(indexToRemove) => onDeleteAddedInstrument(indexToRemove)}
              titleMdSize="col-md-3 col-lg-2 p-0"
              inputMdSize="col-md-9 col-lg-10 p-0"
              segment={segment}
              onChangeSegment={(newSegment) => setSegment(newSegment)}
              segments={segments}
            />

            <div className="technical-indicators">
              <div className="technical-order">
                When close price
                <div>
                  <Select
                    value={templateConfig.comparisonType}
                    onChange={(event) => handleStrategyChange('comparisonType', event.target.value)}
                    optionItems={comparisonTypesTemplates}
                  />
                </div>
                <div>
                  <Input
                    value={templateConfig.period}
                    onChange={(event) => handleStrategyChange('period', handleErrors(event.target.value))}
                  />
                </div>
                Period
                <div>
                  <Select
                    value={templateConfig.interval}
                    onChange={(event) => handleStrategyChange('interval', event.target.value)}
                    optionItems={intervalTemplates}
                  />
                </div>
                <div>
                  <Select
                    value={templateConfig.movingAverageType}
                    onChange={(event) => handleStrategyChange('movingAverageType', event.target.value)}
                    optionItems={movingAverageTypeTemplate}
                  />
                </div>

                <div>
                  <Select
                    value={templateConfig.transaction}
                    onChange={(event) => handleStrategyChange('transaction', event.target.value)}
                    optionItems={transactionTypesTemplate}
                  />
                </div>
              </div>
              <div className="template-sentence-wrapper">
                <TemplateSentence
                  optionType={{ value: templateConfig.optionType, key: 'optionType' }}
                  strikeIndex={{ value: templateConfig.strikeIndex, key: 'strikeIndex' }}
                  actiontype={{ value: templateConfig.actiontype, key: 'actiontype' }}
                  stopLoss={{ value: templateConfig.stopLoss, key: 'stopLoss' }}
                  stopGain={{ value: templateConfig.stopGain, key: 'stopGain' }}
                  handleStrategyChange={handleStrategyChange}
                  quantity={{ value: templateConfig.quantity, key: 'quantity' }}
                  transaction={templateConfig.transaction}
                  stopGainType={{ value: templateConfig.stopGainType, key: 'stopGainType' }}
                  stopLossType={{ value: templateConfig.stopLossType, key: 'stopLossType' }}
                  instruments={instruments}
                />
              </div>
            </div>
          </div>
          <TemplateFooter
            templateConfig={templateConfig}
            templateName={templateName}
            instruments={instruments}
            segment={segment}
          />
        </div>
      </div>
    </>
  );
};

export const toQuantConfig = (config) => {
  return quantConfigConversionMcp(config);
};
