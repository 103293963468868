import { isIndexInstrumentPresentInQuantInstrumentGroups } from 'common/utils/InstrumentUtils';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import { OPTIONS_EXPIRY } from 'modules/QuantBuilder/config/index';
import { POSITION_VALUE_TYPES } from 'v2/common/constants/index';

const strikeBasedValues = (iteration, optionType) => {
  const strikeArray = [];
  const isOptionTypePE = optionType === 'PE';
  _.range(Number(`-${iteration}`), iteration + 1).forEach((index) => {
    if (index < 0) {
      strikeArray.push({
        optionShow: isOptionTypePE
          ? `OTM ${Math.abs(index)}` : `ITM ${Math.abs(index)}`,
        value: `${index}`
      });
    }
    if (index === 0) {
      strikeArray.push({ optionShow: 'ATM', value: '0' });
    }
    if (index > 0) {
      strikeArray.push({
        optionShow: isOptionTypePE
          ? `ITM ${index}` : `OTM ${index}`,
        value: `${index}`
      });
    }
  });
  return strikeArray;
};

export const strikeIndexes = (optionType, instrumentGroups) => {
  if (isIndexInstrumentPresentInQuantInstrumentGroups(instrumentGroups)) {
    return strikeBasedValues(30, optionType);
  } // working fine.. TODO.. when ready made strategy changes make..
  // have to make common.. remove seperate logics..

  return strikeBasedValues(10, optionType);
};

export const comparisonTypesTemplates = [
  { optionShow: 'crossesAbove', value: 'crossesAbove' },
  { optionShow: 'equalTo', value: 'equalTo' },
  { optionShow: 'isAbove', value: 'isAbove' },
  { optionShow: 'isBelow', value: 'isBelow' },
  { optionShow: 'crossesBelow', value: 'crossesBelow' },
];

export const intervalTemplates = [
  { optionShow: '1 minutes', value: '1 minutes' },
  { optionShow: '3 minutes', value: '3 minutes' },
  { optionShow: '5 minutes', value: '5 minutes' },
  { optionShow: '15 minutes', value: '15 minutes' },
  { optionShow: '30 minutes', value: '30 minutes' },
  { optionShow: '1 hours', value: '1 hours' },
];

export const actionTypesTemplates = [
  { optionShow: 'Buy', value: 'buy' },
  { optionShow: 'Sell', value: 'sell' },
];

export const optionTypesTemplates = [
  { optionShow: 'CE', value: 'CE' },
  { optionShow: 'PE', value: 'PE' },
];

export const movingAverageTypeTemplate = [
  { optionShow: 'simpleMovingAverage', value: 'simpleMovingAverage' },
  { optionShow: 'exponentialMovingAverage', value: 'exponentialMovingAverage' },
  { optionShow: 'weightedMovingAverage', value: 'weightedMovingAverage' },
];

export const transactionTypesTemplate = [
  { optionShow: 'option', value: 'option' },
  { optionShow: 'future', value: 'future' },
];

export const daysTemplate = () => {
  const dayArray = [];
  _.range(0, 30).forEach((index) => {
    dayArray.push({ optionShow: `${index}`, value: `${index}` });
  });
  return dayArray;
};

export const monthly = [
  { optionShow: OPTIONS_EXPIRY.monthly, value: OPTIONS_EXPIRY.monthly },
];

export const weekly = [
  { optionShow: 'weekly', value: 'weekly' },
];

export const standardDeviationTemplate = [
  { optionShow: 'ATM', value: '0' },
  { optionShow: '1 SD', value: '1' },
  { optionShow: '2 SD', value: '2' },
  { optionShow: '3 SD', value: '3' },
];

export const priceAndPercentTemplate = [
  { optionShow: '%', value: 'percent' },
  { optionShow: '₹', value: 'price' },
];

export const isPercent = (type) => type === 'percent';

export const getStopGainPercent = (type, amount) => {
  if (isPercent(type)) return Number(amount);

  return undefined;
};

export const isPrice = (type) => type === 'price';

export const getStopGainPrice = (type, amount) => {
  if (isPrice(type)) return Number(amount);

  return undefined;
};

export const getQuantityObject = (value) => {
  return {
    value,
    type: POSITION_VALUE_TYPES.Lot,
  };
};

export const handleErrors = (value) => {
  if (value === '') return '0';

  let targetValue = value;
  if (targetValue.substring(0, 1) === '0') {
    targetValue = targetValue.substring(1);
    return targetValue;
  }

  return value;
};

export const CURRENT_ONE_MINUTE_CANDLE_NAME = 'current 1 minute';

export const current1minuteCandle = () => {
  const currentCandle = PipeDefinitionUtility.getPipeConfigDefaults('currentCandle');
  const { config } = currentCandle;

  return {
    ...currentCandle,
    name: CURRENT_ONE_MINUTE_CANDLE_NAME,
    config: { ...config, candleInterval: '1 minutes' }
  };
};
