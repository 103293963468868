import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import { getTimeValidatorsV2 } from 'common/utils/validators';
import TimeSelector from 'common/components/TimeSelector';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired
};

const TimeOfDayField = ({
  name: propsName, value, control, errors, onUpdatePipeConfig
}) => {
  const uniqueId = useMemo(() => _.uniqueId(`time_of_day_field_${propsName}_`), [propsName]);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  return (
    <ErrorMesssage message={errorMessage}>
      <Controller
        render={({ onChange }) => (
          <TimeSelector
            onTimeChange={(newTime) => {
              onUpdatePipeConfig(propsName, newTime);
              onChange(newTime);
            }}
            selectedTime={value}
            className="form-control"
            format="HH:mm"
          />
        )}
        control={control}
        defaultValue={value}
        name={uniqueId}
        rules={getTimeValidatorsV2()}
      />
    </ErrorMesssage>
  );
};

TimeOfDayField.propTypes = propTypes;

export default TimeOfDayField;
