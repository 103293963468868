import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'common/components/ModalComponent';
import { orderTransactionPropTypes } from 'common/propTypes';
import OrderDetails from 'modules/OrderDetails/index';

const propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape(orderTransactionPropTypes.isRequired)).isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired
};
const defaultProps = {};

const OrderDetailsInModal = ({
  orders, onClose, title, orderType
}) => {
  return (
    <div className="order-transaction-details order-transaction-panel-modal">
      {/* order-transaction-panel-modal # classname handled for orderModal  */}
      <Modal
        size="xl"
        title={title}
        onClose={onClose}
      >
        <div>
          <OrderDetails orders={orders} orderType={orderType} />
        </div>
      </Modal>
    </div>
  );
};

OrderDetailsInModal.propTypes = propTypes;
OrderDetailsInModal.defaultProps = defaultProps;
export default OrderDetailsInModal;
