// vendor imports
import React, { memo } from 'react';
import PropTypes from 'prop-types';

// project import
import {
  instrumentGroupsPropTypes, outputSchemaPropTypes, transactionConfigPropTypes
} from 'common/propTypes';
import { connect } from 'react-redux';
import { setOptionsExpiryCycle, setOptionStrikeCalculationBasedOn } from 'modules/QuantBuilder/actions';
import NewTransactionBuilder from './NewTransactionBuilder/index';

const propTypes = {
  transactionConfigs: PropTypes.arrayOf(transactionConfigPropTypes).isRequired,
  onTransactionConfigsChange: PropTypes.func.isRequired,
  transactionConfigsErrors: PropTypes.arrayOf(PropTypes.shape({})),
  strikeBasedOn: PropTypes.string.isRequired,
  onStrikeBasedOnChange: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  transactionConfigsValidator: PropTypes.shape({}),
  startDate: PropTypes.instanceOf(Date),
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  segment: PropTypes.string.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string).isRequired,
  onOptionsExpiryCycleChange: PropTypes.func.isRequired,
  optionsExpiryCycle: PropTypes.string.isRequired,
  customPropConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  orderConfigIndex: PropTypes.number.isRequired,
};

const defaultProps = {
  transactionConfigsErrors: [],
  transactionConfigsValidator: {},
  startDate: '',
  customPropConfigs: []
};
const I18N_SCOPE = { scope: 'basic_quant_builder.index' };

const TransactionsBuilder = (props) => {
  const {
    onTransactionConfigsChange,
    transactionConfigs, transactionConfigsErrors,
    strikeBasedOn, onStrikeBasedOnChange, outputSchema,
    transactionConfigsValidator, instrumentGroups, segment, segments, optionsExpiryCycle,
    onOptionsExpiryCycleChange, startDate,
    customPropConfigs, orderConfigIndex
  } = props;

  const onTransactionConfigUpdate = (newTransactionConfig, transactionConfigIndex) => {
    const newTransactionConfigs = transactionConfigs;
    newTransactionConfigs[transactionConfigIndex] = newTransactionConfig;

    onTransactionConfigsChange(newTransactionConfigs);
  };

  if (_.isEmpty(transactionConfigs)) return null;

  const sanitizedOutputSchema = _.flattenDeep([outputSchema,
    _.chain(customPropConfigs).map(({ outputSchema: cpcOutputSchema }) => {
      try { return JSON.parse(cpcOutputSchema); } catch (err) { return null; }
    }).compact().value()
  ]);

  const renderTransactionConfig = (transactionConfig, transactionConfigIdx) => {
    return (
      <div className="card" key={transactionConfigIdx}>
        <div className="card-body transaction-Builder-body">
          <NewTransactionBuilder
            shouldDisableExpiryCycle={!!transactionConfigIdx}
            orderConfigIndex={orderConfigIndex}
            transactionConfig={transactionConfig}
            transactionConfigIdx={transactionConfigIdx}
            transactionConfigErrors={_.get(transactionConfigsErrors, [transactionConfigIdx], {})}
            onTransactionConfigChange={
              (newTransactionConfig) => onTransactionConfigUpdate(newTransactionConfig, transactionConfigIdx)
            }
            isAdjustment={false}
            optionsExpiryCycle={optionsExpiryCycle}
            onOptionsExpiryCycleChange={onOptionsExpiryCycleChange}
            strikeBasedOn={strikeBasedOn}
            onStrikeBasedOnChange={onStrikeBasedOnChange}
            outputSchema={sanitizedOutputSchema}
            transactionConfigsValidator={transactionConfigsValidator}
            instrumentGroups={instrumentGroups}
            segment={segment}
            startDate={startDate}
            segments={segments}
            customPropConfigs={customPropConfigs}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="transaction-config">
      <div className="signal-config border-bottom-0 d-flex align-items-center py-2">
        <span className="material-icons-outlined tx-18 mr-3">settings_input_composite</span>
        <span className="tx-14 font-weight-medium">{I18n.t('transaction_details', I18N_SCOPE)}</span>
      </div>

      <div className="transaction-config-builder">
        {_.map(transactionConfigs, renderTransactionConfig)}
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    segment: _.get(state, 'segment'),
    transactionConfigsValidator: _.get(state, [
      'quantConfigValidators', 'orderConfigsValidators', 'transactionConfigsValidator'
    ]),
    instrumentGroups: _.get(state, 'quantConfig.instrumentGroups'),
    optionsExpiryCycle: _.get(state, 'quantConfig.optionsExpiryCycle'),
    startDate: _.get(state, 'startDate'),
    outputSchema: _.get(state, 'outputSchema'),
    segments: _.get(state, 'segments', []),
    strikeBasedOn: _.get(state, 'quantConfig.optionStrikeCalculationBasedOn', 'strikeIndex'),
    customPropConfigs: _.get(state, 'quantConfig.customPropConfigs', [])
  };
};

const mapDispatchToProp = (dispatch) => ({
  onOptionsExpiryCycleChange: (newOptionsExpiryCycle) => {
    dispatch(setOptionsExpiryCycle(newOptionsExpiryCycle));
  },
  onStrikeBasedOnChange: (newOptionStrikeCalculationBasedOn) => (
    dispatch(setOptionStrikeCalculationBasedOn(newOptionStrikeCalculationBasedOn))
  ),
});

TransactionsBuilder.propTypes = propTypes;
TransactionsBuilder.defaultProps = defaultProps;

export default memo(connect(mapStatetoProps, mapDispatchToProp)(TransactionsBuilder));
