import React from 'react';
import PropTypes, { oneOfType, object } from 'prop-types';

const propTypes = {
  particularTransactionDetailsForAllUsers: oneOfType([object]).isRequired,
  individualUserTransactionLegs: oneOfType([object]).isRequired,
  onTransactionsClick: PropTypes.func.isRequired,
  onUsersClick: PropTypes.func.isRequired,
  onLegsClick: PropTypes.func.isRequired
};

const defaultProps = {};

const BreadCrumb = ({
  particularTransactionDetailsForAllUsers,
  individualUserTransactionLegs,
  onTransactionsClick,
  onUsersClick,
  onLegsClick,
}) => {
  const isParticularTransactionDetailsForAllUsers = !_.isEmpty(
    particularTransactionDetailsForAllUsers.data
  );

  const isIndividualUserTransactionLegs = !_.isEmpty(
    individualUserTransactionLegs.data
  );

  return (
    <ol className="breadcrumb analytic-breadcrumb">
      <li className={`cursor-pointer ${(!isParticularTransactionDetailsForAllUsers
          && !isIndividualUserTransactionLegs) ? 'active' : ''}`}
      >
        <span onClick={onTransactionsClick}>Transactions</span>
      </li>
      {isParticularTransactionDetailsForAllUsers && (
      <li
        className={`cursor-pointer ${(isParticularTransactionDetailsForAllUsers
            && !isIndividualUserTransactionLegs) ? 'active' : ''}`}
        role="presentation"
        onClick={onUsersClick}
      >
        <span>
          Users
          {' '}
          (
          transaction Id:
          {' '}
          {particularTransactionDetailsForAllUsers.data[0].transaction_id}
          )
        </span>
      </li>
      )}
      {isIndividualUserTransactionLegs && (
      <li
        role="presentation"
        className="active"
        onClick={onLegsClick}
      >
        <span>
          Legs
          {' '}
          (
          User Id:
          {' '}
          {individualUserTransactionLegs.data[0].user_id}
          )
        </span>
      </li>
      )}
    </ol>
  );
};

BreadCrumb.propTypes = propTypes;
BreadCrumb.defaultProps = defaultProps;

export default BreadCrumb;
