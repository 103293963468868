import React from 'react';
import PropTypes from 'prop-types';
import SignalSentence from 'common/components/SignalSentence';
import TransactionsView from 'modules/TransactionsBuilder/TransactionsView';
import { outputSchemaPropTypes } from 'common/propTypes';
import classNames from 'classnames';

const propTypes = {
  isSignal: PropTypes.bool.isRequired,
  config: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  hasMultipleInstruments: PropTypes.bool.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired
};

const defaultProps = {};

const ViewConfigSentences = ({
  isSignal,
  config,
  title,
  hasMultipleInstruments,
  outputSchema
}) => {
  const divClassName = classNames('row align-items-center', {
    signal: isSignal,
    transaction: !isSignal
  });

  return (
    <div className={divClassName}>
      <div className="col-md-2">
        <label className="col-form-label-sm mb-0">{title}</label>
      </div>

      <div className="col-md-10 config-wrapper">
        {isSignal
          ? (
            <SignalSentence
              signalConfigs={config}
              hasMultipleInstruments={hasMultipleInstruments}
              outputSchema={outputSchema}
            />
          )
          : <TransactionsView transactionConfigs={config} />}
      </div>
    </div>

  );
};

ViewConfigSentences.defaultProps = defaultProps;
ViewConfigSentences.propTypes = propTypes;

export default ViewConfigSentences;
