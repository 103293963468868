import React from 'react';

const propTypes = {};

const defaultProps = {};

const UsLogin = () => {
  return (
    <div className="login-panel-wrapper user-auth-component broker-new-login">
      <div className="login-panel">
        <div className="px-4 py-5">
          <h5 className="text-center mb-5">Sign in to your account</h5>
          <div className="d-flex flex-column align-items-center broker-login-container">

            <a href="/auth/google_oauth2" className="btn btn-primary btn-lg btn-pill mt-3">
              <i className="fab fa-google mr-2" />
              <span>Google</span>
            </a>

            <a href="/auth/facebook" className="btn btn-primary btn-lg btn-pill mt-3">
              <i className="fab fa-facebook-f mr-2" />
              <span>Facebook</span>
            </a>

            <a href="/auth/twitter2" className="btn btn-primary btn-lg btn-pill mt-3">
              <i className="fab fa-twitter mr-2" />
              <span>X (formerly Twitter)</span>
            </a>

          </div>
        </div>
      </div>
    </div>
  );
};

UsLogin.propTypes = propTypes;
UsLogin.defaultProps = defaultProps;

export default UsLogin;
