import React from 'react';
import PropTypes from 'prop-types';

import { INTENT_TYPES } from 'v2/common/constants/index';

const propTypes = {
  isAdjustmentIntent: PropTypes.bool,
  isAdvancedMode: PropTypes.bool,
  isIdle: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(Object.values(INTENT_TYPES)).isRequired,
};
const defaultProps = {
  isAdjustmentIntent: false,
  isAdvancedMode: false,
  isIdle: false
};

const IntentTypeSelector = ({
  isAdjustmentIntent, isAdvancedMode, isIdle, value, onChange
}) => {
  const localValue = (value === INTENT_TYPES.OpenLeg && isIdle) ? 'Idle' : value;
  const onLocalChange = (event) => {
    const newValue = event.target.value;
    if (newValue === 'Idle') {
      onChange(INTENT_TYPES.OpenLeg, true);
      return;
    }
    onChange(newValue);
  };

  const options = [
    { value: INTENT_TYPES.OpenLeg, label: 'Open' },
  ];

  if (isAdjustmentIntent) {
    options.push({ value: INTENT_TYPES.CloseLeg, label: 'Close' });
    options.push({ value: INTENT_TYPES.UpdateStopLoss, label: 'MTC' });
  }

  if (isAdvancedMode) {
    options.push({ value: 'Idle', label: 'Idle' });
  }

  return (
    <div className="transaction_details-items intent-type">
      <select
        className="intent-type-selector"
        disabled={options.length <= 1}
        value={localValue}
        onChange={onLocalChange}
      >
        {options.map((opt) => (<option value={opt.value} key={opt.value}>{opt.label}</option>))}
      </select>
    </div>
  );
};

IntentTypeSelector.propTypes = propTypes;
IntentTypeSelector.defaultProps = defaultProps;

export default IntentTypeSelector;
