import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import {
  QUANT_SCHEMA_CATEGORIES, SEGMENT_CONFIG, SEGMENT_CATEGORY_IN_QUANT, RUN_TYPES, SEGMENT_SUPPORT_CONFIGS
} from 'common/constants/index';
import {
  getEmptyEquityConfig, getEmptyFutureConfig, getEmptyOptionConfigs,
  getSegmentStrikeBasedOn, getStrikeBasedOn, TRANSACTION_MODES
} from 'modules/TransactionsBuilder/configs';
import moment from 'moment';

export const I18N_SCOPE = { scope: 'runs.form' };
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const MAX_YEARS_ALLOWED = 40;

export const sanitizeOutputSchema = (outputSchema, segment) => {
  switch (segment) {
    case SEGMENT_CONFIG.equity:
      const filterOutputSchemaForEquity = _.filter(outputSchema,
        ({ category }) => category !== QUANT_SCHEMA_CATEGORIES.FUTURE_PRICE);

      return filterOutputSchemaForEquity;

    case SEGMENT_CONFIG.future:
      const filterOutputSchemaForFuture = _.filter(outputSchema,
        ({ category }) => category !== QUANT_SCHEMA_CATEGORIES.EQUITY_PRICE);

      return filterOutputSchemaForFuture;

    default:
      return outputSchema;
  }
};

const quantIndicatorAndSignal = { indicator: 'indicator', signal: 'signal' };

const getValueString = (isConstruct, value, resultArray = [], changeName) => {
  if (!isConstruct) { return value; }

  let returnString = '';

  _.each(resultArray, (result, idx) => {
    returnString += idx === 1 ? changeName : result;
    if (idx !== resultArray.length - 1) returnString += '.';
  });

  return returnString;
};

const getValue = (value, changeName, comparableName, configType) => {
  if (_.isArray(value) || _.isObject(value)) return value;

  const result = value.split(/[.]/);

  const isChange = _.size(result) === 2 || _.includes(value, 'volume');

  if (_.includes(value, comparableName)
    && ((comparableName === SEGMENT_CATEGORY_IN_QUANT.month1Price)
      || (comparableName === SEGMENT_CATEGORY_IN_QUANT.equity))) {
    // new for only signal last traded price --- below if condition...

    if (configType === quantIndicatorAndSignal.signal) {
      return `${_.head(result)}.${changeName}.close`;
    }

    if (isChange) return getValueString(isChange, value, result, changeName);

    const returnChange = configType === quantIndicatorAndSignal.indicator
      ? `${_.head(result)}.${changeName}` : `${_.head(result)}.${changeName}.volume`;

    reactToastify('No Matching found, Field has been Changed Please Check');

    return returnChange;
  }
  return value;
};

export const updatedPipeConfigs = (pipeConfigs, changeName, comparableName) => {
  return _.map(pipeConfigs, (pipeConfig) => {
    const { config } = pipeConfig;
    const { valuePaths } = config;

    if (valuePaths) {
      const updatedValuePaths = _.map(valuePaths, (valuePath) => {
        return getValue(valuePath, changeName, comparableName, quantIndicatorAndSignal.indicator);
      });
      return { ...pipeConfig, config: { ...config, valuePaths: updatedValuePaths } };
    }
    return pipeConfig;
  });
};

const updateSignalConfigs = (signalConfigs, changeName, comparableName) => {
  return _.map(signalConfigs, (signalConfig) => {
    const { rightOperand, leftOperand } = signalConfig;
    return {
      ...signalConfig,
      leftOperand: getValue(leftOperand, changeName, comparableName, quantIndicatorAndSignal.signal),
      rightOperand: getValue(rightOperand, changeName, comparableName, quantIndicatorAndSignal.signal)
    };
  });
};

export const isSegmentBasedConfig = (segments, transaction) => {
  const isConstant = _.size(segments) === 3;

  const isMultipleSegment = (
    _.size(segments) === 2
    && (_.includes(segments, SEGMENT_CONFIG.equity) && _.includes(segments, SEGMENT_CONFIG.option))
    && _.includes([
      TRANSACTION_MODES.optionAndEquity, TRANSACTION_MODES.optionConfigs, TRANSACTION_MODES.equityConfig
    ], transaction))
    || (_.size(segments) === 2
      && (_.includes(segments, SEGMENT_CONFIG.future) && _.includes(segments, SEGMENT_CONFIG.option))
      && _.includes([
        TRANSACTION_MODES.futureAndOption, TRANSACTION_MODES.optionConfigs, TRANSACTION_MODES.futureConfig
      ], transaction))
    || (_.size(segments) === 2
      && (_.includes(segments, SEGMENT_CONFIG.equity) && _.includes(segments, SEGMENT_CONFIG.future))
      && _.includes([
        TRANSACTION_MODES.futureConfig, TRANSACTION_MODES.futureAndEquity, TRANSACTION_MODES.equityConfig
      ], transaction));

  const isSingleSegment = (_.size(segments) === 1
    && _.head(segments) === SEGMENT_CONFIG.future && transaction === TRANSACTION_MODES.futureConfig)
    || (_.size(segments) === 1
      && _.head(segments) === SEGMENT_CONFIG.equity && transaction === TRANSACTION_MODES.equityConfig)
    || (_.size(segments) === 1
      && _.head(segments) === SEGMENT_CONFIG.option && transaction === TRANSACTION_MODES.optionConfigs);

  return isConstant || isMultipleSegment || isSingleSegment || _.isEmpty(segments);
};

const updateTransactionConfig = (transactionConfigs, segments) => {
  return _.map(transactionConfigs, (transactionConfig) => {
    const { mode } = transactionConfig;
    const transaction = _.get(mode, 'transaction', '');

    if (isSegmentBasedConfig(segments, transaction)) return transactionConfig;

    const { isSupportEquity, isSupportFuture, isSupportOption } = SEGMENT_SUPPORT_CONFIGS(segments);
    let newTransctionMode = TRANSACTION_MODES.optionConfigs;

    if (isSupportEquity && !isSupportFuture && !isSupportOption) {
      newTransctionMode = TRANSACTION_MODES.equityConfig;
    }
    if (!isSupportEquity && isSupportFuture && !isSupportOption) {
      newTransctionMode = TRANSACTION_MODES.futureConfig;
    }

    // If clone the equity config.. have to prefill .. same as future..
    // this is complete reset config... based on segment...

    reactToastify('Transaction config will be changed, Please Verify', TOASTIFY_TYPES.WARNING);

    return {
      ...transactionConfig,
      mode: { ...mode, transaction: newTransctionMode },
      futureConfig: getEmptyFutureConfig(),
      optionConfigs: [].concat(getEmptyOptionConfigs()),
      equityConfig: getEmptyEquityConfig()
    };
  });
};

const updateOrderConfigs = (orderConfigs, changeName, comparableName, segments) => {
  return _.map(orderConfigs, (orderConfig) => {
    const { entrySignalConfigs, exitSignalConfigs, transactionConfigs } = orderConfig;
    const adjustmentSignalConfigs = _.get(orderConfig, 'adjustmentSignalConfigs', []);
    const adjustmentActionConfigs = _.get(orderConfig, 'adjustmentActionConfigs', []);

    return {
      ...orderConfig,
      transactionConfigs: updateTransactionConfig(transactionConfigs, segments),
      entrySignalConfigs: updateSignalConfigs(entrySignalConfigs, changeName, comparableName),
      exitSignalConfigs: updateSignalConfigs(exitSignalConfigs, changeName, comparableName),
      adjustmentActionConfigs: _.map(adjustmentActionConfigs,
        (adjustmentActionConfig) => {
          return updateTransactionConfig(adjustmentActionConfig, segments);
        }),
      adjustmentSignalConfigs: _.map(adjustmentSignalConfigs,
        (adjustmentSignalConfig) => {
          return updateSignalConfigs(adjustmentSignalConfig, changeName, comparableName);
        })
    };
  });
};

export const onUpdateQuantConfigBasedOnSegmentChange = (quantConfig, segment, segments) => {
  const { equityPrice: { value: equityValue }, strikeIndex: { value: strikeValue } } = getStrikeBasedOn();
  const { orderConfigs, pipeConfigs, optionStrikeCalculationBasedOn } = quantConfig;

  switch (segment) {
    case SEGMENT_CONFIG.equity:
      const updatedQuantConfigForEquity = {
        ...quantConfig,
        optionStrikeCalculationBasedOn: _.includes(
          _.map(getSegmentStrikeBasedOn(segment, getStrikeBasedOn()), ({ value }) => { return value; }),
          optionStrikeCalculationBasedOn
        ) ? optionStrikeCalculationBasedOn : equityValue,
        pipeConfigs: updatedPipeConfigs(
          pipeConfigs, SEGMENT_CATEGORY_IN_QUANT.equity, SEGMENT_CATEGORY_IN_QUANT.month1Price
        ),
        orderConfigs: updateOrderConfigs(
          orderConfigs, SEGMENT_CATEGORY_IN_QUANT.equity, SEGMENT_CATEGORY_IN_QUANT.month1Price,
          segments
        )
      };

      return updatedQuantConfigForEquity;

    case SEGMENT_CONFIG.future:
      const updatedQuantConfigForFuture = {
        ...quantConfig,
        optionStrikeCalculationBasedOn: _.includes(
          _.map(getSegmentStrikeBasedOn(segment, getStrikeBasedOn()), ({ value }) => { return value; }),
          optionStrikeCalculationBasedOn
        ) ? optionStrikeCalculationBasedOn : strikeValue,
        pipeConfigs: updatedPipeConfigs(
          pipeConfigs, SEGMENT_CATEGORY_IN_QUANT.month1Price, SEGMENT_CATEGORY_IN_QUANT.equity
        ),
        orderConfigs: updateOrderConfigs(
          orderConfigs, SEGMENT_CATEGORY_IN_QUANT.month1Price, SEGMENT_CATEGORY_IN_QUANT.equity,
          segments
        )
      };

      return updatedQuantConfigForFuture;

    default: return quantConfig;
  }
};

export const isDisabledLive = (planActiveTill) => {
  const { isInInitialOfferPeriod, isAdmin } = window;
  const isPaidActiveUser = planActiveTill && moment().isBefore(planActiveTill);
  if (isInInitialOfferPeriod || isPaidActiveUser || isAdmin) return false;

  return true;
};

export const getDefaultOrderType = (propsRunType, planActiveTill) => {
  if (isDisabledLive(planActiveTill)) return RUN_TYPES.historic;

  return propsRunType;
};
