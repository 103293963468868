import React, { useContext, useState, useEffect } from 'react';

import { weekDays } from 'ui/run/RunShow/config';
import { getDayOfWeekData } from 'ui/run/RunShow/OrderStore/analytics/index';
import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BarChart from '../chart/BarChart';
import EntryExitSelector from './EntryExitSelector';

const propTypes = {
  tradingType: PropTypes.string.isRequired
};
const defaultProps = {};

const INTRADAY = 'intraday';

const DayOfWeekAnalytics = ({ tradingType }) => {
  const { completedTransactions } = useContext(OrderContext);

  const [isEntry, setIsEntry] = useState(true);
  const [dayOfWeekData, setDayOfWeekData] = useState({});

  useEffect(() => {
    setDayOfWeekData(getDayOfWeekData(completedTransactions, isEntry));
  }, [isEntry, completedTransactions]);

  if (_.keys(dayOfWeekData).length === 0) return null;

  const renderChart = (day) => {
    const dayOfWeekAnalytics = dayOfWeekData[day];
    if (!dayOfWeekAnalytics) return null;

    return <BarChart key={day} analyticsData={dayOfWeekAnalytics} title={day} />;
  };

  const headingClassName = classNames('d-flex align-items-center', { 'mb-3': tradingType !== INTRADAY });

  return (
    <div className="col-md-6">
      <div className={headingClassName}>
        <h6>Day Of Week</h6>
        {tradingType !== INTRADAY && <EntryExitSelector isEntry={isEntry} onEntryExitChange={setIsEntry} />}
      </div>
      {_.map(weekDays, renderChart)}
    </div>
  );
};

DayOfWeekAnalytics.propTypes = propTypes;
DayOfWeekAnalytics.defaultProps = defaultProps;

export default DayOfWeekAnalytics;
