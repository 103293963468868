import React from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { strategyPropTypes } from 'common/propTypes';

const propTypes = {
  strategyConfig: strategyPropTypes.isRequired,
  onUpdateStrategyConfig: PropTypes.func.isRequired
};

const defaultProps = {};

const StrategyFormTags = ({ strategyConfig, onUpdateStrategyConfig }) => {
  return (
    <div className="form-group col-md-6 select-tags">
      <label className="col-form-label col-form-label-sm" htmlFor="">Select Tags</label>
      <Typeahead
        className="custom-form-control"
        multiple
        options={[]}
        allowNew
        id="tags"
        onChange={(values) => {
          const tags = _.map(values, ({ label }) => { return label; });
          onUpdateStrategyConfig({
            ...strategyConfig, tags
          });
        }}
      />
    </div>
  );
};

StrategyFormTags.propTypes = propTypes;
StrategyFormTags.defaultProps = defaultProps;

export default StrategyFormTags;
