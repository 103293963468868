import { SL_TRAIL_TYPES } from 'v2/common/constants/index';

const TRAILING_VALUE = 'trailingValue';
const ADJUST_VALUE = 'adjustValue';
const BOOK_PROFIT_ON = 'bookProfitOn';
const BOOK_PROFIT = 'bookProfit';

export const getTrailingStopLossValidationErrors = (trailingStopLossConfig) => {
  const errorConfig = [];

  if (trailingStopLossConfig[ADJUST_VALUE] === undefined
    && trailingStopLossConfig[TRAILING_VALUE] === undefined
    && trailingStopLossConfig[BOOK_PROFIT_ON] === undefined
    && trailingStopLossConfig[BOOK_PROFIT] === undefined) {
    return errorConfig;
  }

  // if (trailingStopLossConfig[TRAILING_VALUE] !== undefined
  //   && trailingStopLossConfig[TRAILING_VALUE] < 5) {
  //   errorConfig.push('* For Option, the X percentage is Minimum 5');
  // }

  // if (trailingStopLossConfig[BOOK_PROFIT_ON] !== undefined
  //   && trailingStopLossConfig[BOOK_PROFIT_ON] < 5) {
  //   errorConfig.push('* P1 percentage is Minimum 5');
  // }

  const isAnyUndefinedInLinear = (
    trailingStopLossConfig.type === SL_TRAIL_TYPES.Linear
    && (
      (trailingStopLossConfig[TRAILING_VALUE] !== undefined
        && trailingStopLossConfig[ADJUST_VALUE] === undefined)
      || (trailingStopLossConfig[TRAILING_VALUE] === undefined
        && trailingStopLossConfig[ADJUST_VALUE] !== undefined)
    )
  );

  const isAnyUndefinedInBookAndLinear = (
    trailingStopLossConfig.type === SL_TRAIL_TYPES.BookAndLinear
    && (
      (trailingStopLossConfig[BOOK_PROFIT_ON] !== undefined
        && trailingStopLossConfig[BOOK_PROFIT] === undefined)
      || (trailingStopLossConfig[BOOK_PROFIT_ON] === undefined
        && trailingStopLossConfig[BOOK_PROFIT] !== undefined)
    )
  );

  if (isAnyUndefinedInLinear || isAnyUndefinedInBookAndLinear) {
    errorConfig.push('* All values are Required');
  }

  if ((trailingStopLossConfig[TRAILING_VALUE] && trailingStopLossConfig[ADJUST_VALUE])
    && (trailingStopLossConfig[TRAILING_VALUE] < trailingStopLossConfig[ADJUST_VALUE])) {
    errorConfig.push('* X should be Greater than or Equal to Y');
  }

  if (trailingStopLossConfig.type === SL_TRAIL_TYPES.BookAndLinear
    && (trailingStopLossConfig[BOOK_PROFIT_ON] && trailingStopLossConfig[BOOK_PROFIT])
    && (trailingStopLossConfig[BOOK_PROFIT_ON] < trailingStopLossConfig[BOOK_PROFIT])) {
    errorConfig.push('* P1 should be Greater than or Equal to P2');
  }

  return errorConfig;
};
