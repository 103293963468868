import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const PhilipCapital = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              Please login PhilipCapital
              <b>
                <a
                  href="https://phillip9.phillipcapital.in/#/entrylevel/login"
                  target="blank"
                  title="PhilipCapital"
                >
                  - https://phillip9.phillipcapital.in/#/entrylevel/login
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              After login, go to settings page or
              {' '}
              <a
                href="https://Phillip9.phillipcapital.in/#/usercontrol/dashboard/settings"
              >
                click here
              </a>
              {' '}
              , then choose 10 years and generate API Key
            </p>
            <img
              src="/images/broker_setup_guide/philip_capital/api_key.png"
              className="w-100"
              alt="PhilipCapital API page"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Now, you can copy the
              {oneIndentation}
              <b>API Key and paste it in appropriate field.</b>
            </p>
          </div>
        </li>
      </ol>
    </div>
  </>
);

PhilipCapital.propTypes = propTypes;
PhilipCapital.defaultProps = defaultProps;

export default PhilipCapital;
