import wrappedFetch from './base';

export const getPurchases = () => {
  return wrappedFetch('/purchases')
    .then((response) => response.json());
};

export const onCreatePayment = (args) => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...args }),
  };

  return wrappedFetch('/payment/init_payment', config).then((response) => response.json());
};

export const onVerifyPayment = (args) => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...args }),
  };

  return wrappedFetch('/payment/payment_verification', config).then((response) => response.json());
};

export const onCreateSubscription = (args) => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...args }),
  };

  return wrappedFetch('/subscription/init_subscription', config).then((response) => response.json());
};

export const onVerifySubscription = (args) => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...args }),
  };

  return wrappedFetch('/subscription/verify_subscription', config).then((response) => response.json());
};

export const onClaimFreeTrial = () => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };

  return wrappedFetch('/payment/claim_free_trial', config).then((response) => response.json());
};
