import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { validateCustomScript } from 'common/api/quantConfig';
import AceEditor from 'react-ace';

import { pipeConfigPropTypes } from 'common/propTypes';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { useSelector } from 'react-redux';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const OUTPUT_SCHEMA_VALIDATION = {
  required: {
    value: true,
    message: 'script is required'
  },
  validate: (value) => {
    try {
      JSON.parse(value);
      return null;
    } catch (e) {
      return 'Invalid JSON';
    }
  }
};

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  onUpdate: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  trigger: PropTypes.func.isRequired,
};
const defaultProps = {};

const renderScriptError = (scriptError) => {
  if (!scriptError) {
    return null;
  }
  return (
    <div className="card border-danger">
      <pre className="card-body text-danger">
        {scriptError.message}
      </pre>
    </div>
  );
};

const ScriptFormField = ({
  pipeConfig, onUpdate, errors, control, trigger
}) => {
  const pipeConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.pipeConfigs'));
  const previousPipeConfigs = (() => {
    const index = pipeConfigs.findIndex((config) => config.name === pipeConfig.name);
    return pipeConfigs.slice(0, index === -1 ? pipeConfigs.length : index);
  })();
  const { config: { script, outputJsonSchema } } = pipeConfig;
  const onConfigItemUpdate = useCallback((key, value) => {
    onUpdate(key, value);
  }, [onUpdate]);

  const scriptValidation = useMemo(() => ({
    required: {
      value: true,
      message: 'script is required',
    },
    validate: async () => {
      const result = await validateCustomScript(script, previousPipeConfigs);
      const { success, schema, error } = result;
      if (success) {
        onConfigItemUpdate('outputJsonSchema', schema);
      }
      return error && error.join ? error.join('\n') : error;
    }
  }), [onConfigItemUpdate, previousPipeConfigs, script]);

  useEffect(() => {
    const keydownHandler = (e) => {
      if (e.key === 'Enter' && e.ctrlKey) {
        trigger(['script']);
      }
    };
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [trigger]);

  return (
    <>
      <Form.Group className="full__width">
        <Form.Label className="text-nowrap col-form-label col-form-label-sm">Script</Form.Label>
        <Controller
          render={({ onChange, ref, value }) => (
            <AceEditor
              height="200px"
              width="100%"
              id="script"
              name="script"
              onChange={(code) => {
                onConfigItemUpdate('script', code);
                onChange(code);
              }}
              value={value}
              ref={ref}
              mode="scala"
              theme="eclipse"
              fontSize={14}
              showPrintMargin
              showGutter
              highlightActiveLine
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          )}
          name="script"
          defaultValue={script}
          control={control}
          rules={scriptValidation}
        />
        {renderScriptError(_.get(errors, 'script', ''))}
      </Form.Group>
      <Form.Group className="hidden-for-future">
        <Form.Label className="text-nowrap col-form-label col-form-label-sm">
          OutputSchema(Will be autofilled)
        </Form.Label>
        <Controller
          render={({ onChange, ref, value }) => (
            <AceEditor
              height="100px"
              width="100%"
              id="outputJsonSchema"
              name="outputJsonSchema"
              onChange={(code) => {
                onConfigItemUpdate('outputJsonSchema', JSON.parse(code));
                onChange(code);
              }}
              value={value}
              ref={ref}
              mode="json"
              theme="eclipse"
              fontSize={14}
              showPrintMargin
              showGutter
              highlightActiveLine
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          )}
          name="outputJsonSchema"
          defaultValue={JSON.stringify(outputJsonSchema, null, 2)}
          control={control}
          rules={OUTPUT_SCHEMA_VALIDATION}
        />
        <ErrorMesssage message={_.get(errors, 'outputJsonSchema', '')} />
      </Form.Group>
    </>
  );
};

ScriptFormField.propTypes = propTypes;
ScriptFormField.defaultProps = defaultProps;

export default ScriptFormField;
