import React from 'react';
import UserAuth from 'modules/UserAuth';
import {
  REMOVE_BACKTEST_CONTENT_ON_BROKER_DOMAINS,
  DOMAINS
} from 'common/constants';

const HomeSection = (props) => {
  const oneIndentation = ' ';
  const currentDomain = _.get(window, 'currentDomain', '');
  const isShowBackTestRemovedSubMessage = REMOVE_BACKTEST_CONTENT_ON_BROKER_DOMAINS.includes(currentDomain);
  const usersCountMessage = _.get(window, 'usersCountMessage', '');
  const freeTrialDays = currentDomain === DOMAINS.UPSTOX ? 30 : 7;

  const defaultSubMessage = (
    <p>
      With Quantman&apos;s Algo Trading. Access over
      {oneIndentation}
      <b>6 years</b>
      {oneIndentation}
      of futures & options
      data and choose from 100+ tried-and-tested strategies. With Quantman at
      your fingertips, anyone can trade effortlessly –
      {oneIndentation}
      <b>no coding</b>
      {oneIndentation}
      required.
      And yes, you can even customise your strategies.
    </p>
  );

  const backestRemovedSubMessage = (
    <p>
      Analyze hypotheses and test theories efficiently using QuantMan.
      {oneIndentation}
      Discover patterns and strategies,
      {oneIndentation}
      and easily deploy your validated trading algorithms with a straightforward
      {oneIndentation}
      <b>single click setup.</b>
    </p>
  );

  const onOpenDropDown = () => {
    // Trigger broker dropdown toggle using jQuery by ID
    $('#brokerDropdownMenuButton').dropdown('toggle');
    // Focus the input field when the dropdown is shown
    $('#dropdownSearchInput').focus();
  };

  return (
    <>
      <section className="hero-section animation-bg d-flex align-items-center" id="home">
        <div className="hero-section-bg" />
        <div className="container">
          <div className="row align-items-center mt-0 mt-sm-5">
            <div className="col-lg-7 col-md-12">
              <div
                className="mb-0 mb-sm-4 text-center text-lg-left"
              >
                <h1 className="font-weight-bold mb-3 header">Algo trading</h1>
                <h2 className="mb-3">Trade Smarter, Not Harder</h2>

                <h3 className="content mb-3">
                  {
                isShowBackTestRemovedSubMessage
                  ? backestRemovedSubMessage
                  : defaultSubMessage
                }
                </h3>

                <button type="button" className="btn btn-primary" onClick={onOpenDropDown}>
                  <span className="font-weight-bold tx-14">
                    Try it now with a
                    {oneIndentation}
                    {freeTrialDays}
                    -day free trial
                  </span>
                </button>
                <div className="mt-2">
                  Trusted by
                  {oneIndentation}
                  <b>{usersCountMessage}</b>
                  {oneIndentation}
                  happy customers.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mt-3 pt-2 mt-sm-0 pt-sm-0 offset-lg-1">
              <div><UserAuth {...props} /></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeSection;
