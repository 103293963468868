import _ from 'lodash';
import { IntentLabelConfig } from '../../configs/index';
import { extractIdsFromLabel } from '../../utils/signal';

const getReplacedLabel = (label, intentIdsMappedNewIds) => {
  const { intentId } = extractIdsFromLabel(label);
  const newLabel = intentIdsMappedNewIds[intentId] || intentId;

  return _.replace(label, intentId, newLabel);
};

const getSanitizedIntents = (intents, intentIdsMappedNewIds) => {
  return _.map(intents, (intent) => {
    const newIntent = _.cloneDeep(intent);

    if (intentIdsMappedNewIds[intent.identifier.label]) {
      newIntent.identifier.label = intentIdsMappedNewIds[intent.identifier.label];
    }

    if (newIntent.target?.label) {
      newIntent.target.label = getReplacedLabel(newIntent.target.label, intentIdsMappedNewIds);
    }

    return newIntent;
  });
};

const getFormattedOperand = (operand, intentIdsMappedNewIds) => {
  try {
    const [field, label, value] = operand.split('.');
    const newLabel = getReplacedLabel(label, intentIdsMappedNewIds);

    return `${field}.${newLabel}.${value}`;
  } catch (e) {
    return operand;
  }
};

const getSanitizedSignals = (signals, intentIdsMappedNewIds) => {
  return _.map(signals, (signal) => {
    const { leftOperand, rightOperand } = signal;
    const isIndividualStopIncludesLeftOperand = leftOperand.includes('individualStop.');
    const isIndividualStopIncludesRightOperand = rightOperand?.includes('individualStop.');

    let newLeftOperand = leftOperand;
    let newRightOperand = rightOperand;

    if (isIndividualStopIncludesLeftOperand) {
      newLeftOperand = getFormattedOperand(leftOperand, intentIdsMappedNewIds);
    }

    if (isIndividualStopIncludesRightOperand) {
      newRightOperand = getFormattedOperand(rightOperand, intentIdsMappedNewIds);
    }

    return { ...signal, leftOperand: newLeftOperand, rightOperand: newRightOperand };
  });
};

export const getClonedCase = (cases, idx) => {
  const cloneCase = _.cloneDeep(cases[idx]);

  const { entry: { intents: entryIntents, signal: entrySignals }, adjustments } = cloneCase;
  const intentIds = _.chain(entryIntents)
    .map('identifier.label')
    .concat(_.chain(adjustments).map('intents').flatten().map('identifier.label')
      .value())
    .flattenDeep()
    .value();

  const lastestLabelId = IntentLabelConfig.config.default;
  const intentIdsMappedNewIds = _.chain(_.range(lastestLabelId + 1, lastestLabelId + 1 + intentIds.length))
    .reduce((acc, id, index) => {
      acc[intentIds[index]] = `${id}`;
      return acc;
    }, {})
    .value();

  const newEntryIntents = getSanitizedIntents(entryIntents, intentIdsMappedNewIds);
  const newEntrySignals = getSanitizedSignals(entrySignals, intentIdsMappedNewIds);

  const newAdjustments = _.map(adjustments, (adjustment) => {
    const newAdjustment = _.cloneDeep(adjustment);
    newAdjustment.signal = getSanitizedSignals(adjustment.signal, intentIdsMappedNewIds);
    newAdjustment.intents = getSanitizedIntents(adjustment.intents, intentIdsMappedNewIds);
    return newAdjustment;
  });

  const newCloneCase = _.cloneDeep(cloneCase);
  newCloneCase.entry.intents = newEntryIntents;
  newCloneCase.entry.signal = newEntrySignals;
  newCloneCase.adjustments = newAdjustments;

  IntentLabelConfig.setConfigNameAndValue('default', lastestLabelId + intentIds.length);

  return newCloneCase;
};
