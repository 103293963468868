import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updateSiteConfig } from 'common/api/siteConfig';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { reactToastify } from 'common/utils/reactToastify';
import MeetingDetails from './MeetingDetails';

const propTypes = {
  googleMeetConfig: PropTypes.arrayOf(PropTypes.shape({}))
};
const defaultProps = {
  googleMeetConfig: []
};

export const defaultConfig = {
  date: '',
  startTime: '',
  endTime: '',
  meetLink: '',
  meetingType: 'googleMeet',
  title: ''
};

const Meeting = ({ googleMeetConfig }) => {
  const [meetconfigs, setMeetConfigs] = useState(googleMeetConfig);

  const onDeleteMeetConfig = (deletekey) => {
    const newMeetConfig = _.filter(meetconfigs,
      (meetconfig, key) => (deletekey !== key));
    setMeetConfigs(newMeetConfig);
  };

  const onAddNewMeetConfig = () => {
    const updateConfig = [defaultConfig, ...meetconfigs];
    setMeetConfigs(updateConfig);
  };

  const onHandleChange = (name, value, updateKey) => {
    const newMeetConfig = _.map(meetconfigs,
      (meetconfig, key) => (updateKey === key ? { ...meetconfig, [name]: value } : meetconfig));
    setMeetConfigs(newMeetConfig);
  };

  const updateSiteConfigDetails = () => {
    updateSiteConfig('meeting_config', meetconfigs);
    reactToastify('Updated Site Config Details');
  };

  return (
    <>
      <button
        className="btn btn-primary mt-5"
        type="button"
        onClick={() => onAddNewMeetConfig()}
      >
        Add New Meeting
      </button>
      <MeetingDetails
        meetconfigs={meetconfigs}
        onHandleChange={(name, value, updateKey) => onHandleChange(name, value, updateKey)}
        onDeleteMeetConfig={(deletekey) => onDeleteMeetConfig(deletekey)}
      />

      <button
        className="btn btn-primary mt-5"
        type="button"
        onClick={() => updateSiteConfigDetails()}
      >
        Update Meeting Details
      </button>
    </>
  );
};

Meeting.propTypes = propTypes;
Meeting.defaultProps = defaultProps;

export default withErrorBoundary(Meeting);
