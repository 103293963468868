import React, { useState } from 'react';
import { fetchLiveTicks, fetchTradingSymbolTicks } from 'common/api/adminTicks';
import ArrowAccordion from 'common/components/ArrowAccordion';
import { Table } from 'react-bootstrap';

const defaultConfig = {
  date: null, marketData: {}, time: null, error: false, message: ''
};

const LiveDataAdminPanel = () => {
  const oneIndentation = ' ';
  const [liveData, setLiveData] = useState(defaultConfig);

  const [instruments, setInstruments] = useState('FUTSTK-HDFC');
  const [tradeSymbols, setTradingSymbols] = useState('HDFC25MAR21FUT');

  const getLiveData = () => {
    fetchLiveTicks({ instruments }).then((result) => {
      setLiveData(result);
    });
  };

  const getTradingSymbolTicks = () => {
    fetchTradingSymbolTicks({ tradeSymbols }).then((result) => {
      console.log(`Trade Symbols - ${result}`);
      setLiveData(result);
    });
  };

  const dataSet = [
    {
      label: 'Instruments',
      placeholder: 'FUTSTK-HDFC,FUTSTK-HDFCBANK',
      name: 'instruments',
      onClick: () => getLiveData(),
      btnLabel: 'View Live Data',
      onChange: (event) => {
        const { target: { value } } = event;
        setInstruments(value);
      },
      value: instruments
    },
    {
      label: 'Trading Symbol',
      placeholder: 'HDFC25MAR21FUT, BANKNIFTY25MAR2140000CE',
      name: 'tradeSymbols',
      onClick: () => getTradingSymbolTicks(),
      btnLabel: 'View Trading Symbol Live Data',
      onChange: (event) => {
        const { target: { value } } = event;
        setTradingSymbols(value);
      },
      value: tradeSymbols
    }
  ];

  const renderCol = (data, idx) => {
    return (
      <div className="col-sm" key={idx}>
        <form id="live-ticks-form">
          <div className="form-group row">
            <label htmlFor={data.name} className="col-sm-2 col-form-label">{data.label}</label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id={data.name}
                name={data.name}
                placeholder={data.placeholder}
                onChange={data.onChange}
                value={data.value}
              />
            </div>
          </div>
          <div className="btn btn-primary" onClick={data.onClick}>
            {data.btnLabel}
          </div>
        </form>
      </div>
    );
  };

  const renderInstrumentIndividualDetails = (instrumentIndividualDetails, instrumentName) => {
    return (
      <Table striped bordered hover key={instrumentName}>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th scope="col">BB Price</th>
            <th scope="col">BS Price</th>
            <th scope="col">Day Volume</th>
            <th scope="col">Open Interest</th>
          </tr>
        </thead>
        <tbody>
          {_.map(instrumentIndividualDetails, (details, key) => {
            return (
              <tr key={`${_.get(details, 'price', '-')} ${key}`}>
                <td>{`${instrumentName} - ${key}`}</td>
                <td>{_.get(details, 'price', '-')}</td>
                <td>{_.get(details, 'bbPrice', '-')}</td>
                <td>{_.get(details, 'bsPrice', '-')}</td>
                <td>{_.get(details, 'dayVolume', '-')}</td>
                <td>{_.get(details, 'openInterest', '-')}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const renderMarketData = (dataFormat, key) => {
    return (
      <ArrowAccordion
        defaultOpen
        key={key}
        title={key}
        headerRightSectionContent={null}
        onToggle={() => { }}
      >
        {_.map(dataFormat, (instrumentIndividualDetails, instrumentName) => {
          return renderInstrumentIndividualDetails(instrumentIndividualDetails, instrumentName);
        })}
      </ArrowAccordion>
    );
  };

  const renderLiveDataTable = () => {
    const marketData = _.get(liveData, 'marketData', {});
    const error = _.get(liveData, 'error', false);

    if (_.isEmpty(marketData)) {
      return <div className="invalid-feedback d-block text-center mt-3">No Data Present</div>;
    }

    if (error) {
      return (
        <div className="invalid-feedback d-block text-center mt-3">
          {_.get(liveData, 'message', 'No Data Present')}
        </div>
      );
    }

    const { date, time } = liveData;
    const dateTime = `${date}, ${time}`;

    return (
      <div>
        Fetch Time:
        {oneIndentation}
        {dateTime}
        <div className="mt-3">
          {_.map(marketData, renderMarketData)}
        </div>
      </div>
    );
  };

  return (
    <div className="container mt-5">
      <div className="row">{_.map(dataSet, renderCol)}</div>
      {renderLiveDataTable()}
    </div>
  );
};

LiveDataAdminPanel.propTypes = {};

export default LiveDataAdminPanel;
