import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { transactionConfigPropTypes } from 'common/propTypes';
import {
  existingLegActionTypes, existingLegTypes, getDefaultExistingLegConfig,
  TRANSACTION_MODES
} from 'modules/TransactionsBuilder/configs';
import { SEGMENT_CONFIG } from 'common/constants/index';
import HoverMessage from 'common/components/HoverMessage';
import ErrorMesssage from 'common/components/ErrorMesssage';

const propTypes = {
  actionConfig: transactionConfigPropTypes.isRequired,
  onUpdateAdjustmentActionConfigs: PropTypes.func.isRequired,
  transactionConfig: transactionConfigPropTypes.isRequired,
  isDisabledModifyBtn: PropTypes.bool.isRequired,
  isShowWarning: PropTypes.bool.isRequired
};
const defaultProps = {};

const ExistingLegConfigs = ({
  actionConfig, onUpdateAdjustmentActionConfigs, transactionConfig,
  isDisabledModifyBtn, isShowWarning
}) => {
  const existingLegConfigs = _.get(actionConfig, 'existingLegConfigs', []);
  const transaction = _.get(transactionConfig, 'mode.transaction', '');
  const actionOptionConfigs = _.get(transactionConfig, 'optionConfigs', []);
  const { errors, register } = useFormContext();

  const {
    futureConfig, optionConfigs, equityConfig, futureAndOption, futureAndEquity,
    optionAndEquity, combinedAllConfigs
  } = TRANSACTION_MODES;

  const isOptionPresent = _.includes(
    [optionConfigs, futureAndOption, optionAndEquity, combinedAllConfigs], transaction
  );
  const isFuturePresent = _.includes(
    [futureConfig, futureAndOption, futureAndEquity, combinedAllConfigs], transaction
  );
  const isEquityPresent = _.includes(
    [equityConfig, futureAndEquity, optionAndEquity, combinedAllConfigs], transaction
  );

  const optionConfigIndexes = isOptionPresent && actionOptionConfigs
    ? _.map(actionOptionConfigs, (config, idx) => {
      return { label: `Option Leg ${idx + 1}`, value: idx };
    }) : [];

  const sanitizedExistingLegTypes = _.filter(existingLegTypes, ({ value }) => {
    if (value === SEGMENT_CONFIG.option && isOptionPresent) return true;
    if (value === SEGMENT_CONFIG.future && isFuturePresent) return true;
    if (value === SEGMENT_CONFIG.equity && isEquityPresent) return true;

    return false;
  });

  if (_.isEmpty(sanitizedExistingLegTypes)) return null;

  const onUpdateExistingLegActions = (key, value, index) => {
    const updatedExistingLegActions = _.map(existingLegConfigs, (existingLegAction, idx) => {
      if (index === idx) {
        if (key === 'legType') {
          return {
            ...existingLegAction, [key]: value, action: '', optionConfigIndex: null
          };
        }

        return { ...existingLegAction, [key]: value };
      }
      return existingLegAction;
    });

    return updatedExistingLegActions;
  };

  const renderSelectBox = (key, index, options, selected, isDisabled = false) => {
    const validatorConfig = {
      required: isDisabled ? false : '*value required',
    };
    const validatorProps = { ref: register(validatorConfig) };
    const selectClasses = classNames(
      'custom-select custom-select-sm track form-control',
      { 'is-invalid': !!errors[key] }
    );
    return (
      <>
        <select
          name={key}
          {...validatorProps}
          disabled={isDisabled}
          key={key}
          onChange={(event) => {
            const { target: { value } } = event;
            const finalValue = (key === 'optionConfigIndex' && value) ? Number(value) : value;
            onUpdateAdjustmentActionConfigs({
              ...actionConfig,
              existingLegConfigs: onUpdateExistingLegActions(key, finalValue, index)
            });
          }}
          value={_.get(selected, ['0', 'value'], '')}
          className={selectClasses}
        >
          <option value="" />
          {_.map(options, ({ label, value }, idx) => {
            return <option key={idx} value={value}>{label}</option>;
          })}
        </select>
        <ErrorMesssage message={errors[key]} />
      </>
    );
  };

  const renderExistingLegAction = (existingLegAction, idx) => {
    const { legType, action, optionConfigIndex } = existingLegAction;
    const selectedLegType = _.filter(sanitizedExistingLegTypes, ({ value }) => value === legType);
    const selectedLegTypeArray = _.isEmpty(selectedLegType) ? [] : selectedLegType;

    const selectedLegAction = _.filter(existingLegActionTypes, ({ value }) => value === action);
    const selectedLegActionArray = _.isEmpty(selectedLegAction) ? [] : selectedLegAction;

    const selectedLegOptionConfigIndex = _.filter(optionConfigIndexes, (
      { value }
    ) => value === optionConfigIndex);
    const selectedLegOptionConfigIndexArray = _.isEmpty(selectedLegOptionConfigIndex)
      ? [] : selectedLegOptionConfigIndex;

    return (
      <div key={idx} className="existing-config">
        <div>
          <label className="col-form-label col-form-label-sm">Segment Type</label>
          {renderSelectBox('legType', idx, sanitizedExistingLegTypes, selectedLegTypeArray)}
        </div>
        <div>
          <label className="col-form-label col-form-label-sm">Option Number</label>
          {renderSelectBox(
            'optionConfigIndex',
            idx,
            optionConfigIndexes,
            selectedLegOptionConfigIndexArray,
            !(legType === SEGMENT_CONFIG.option && !_.isEmpty(optionConfigIndexes))
          )}
        </div>
        <div>
          <label className="col-form-label col-form-label-sm">Action Type</label>
          {renderSelectBox('action', idx, existingLegActionTypes, selectedLegActionArray)}
        </div>

        <button
          className="delete-btn delete-btn-bg"
          type="button"
          onClick={() => onUpdateAdjustmentActionConfigs({
            ...actionConfig, existingLegConfigs: _.filter(existingLegConfigs, (c, index) => idx !== index)
          })}
        >
          <i className="material-icons-outlined">delete_forever</i>
        </button>
      </div>
    );
  };

  let warningMessage = 'You added new legs. If you want to modify existing legs, ';
  warningMessage += 'remove "add legs" from above. If you';
  warningMessage += ' add both modified legs and open new legs, ';
  warningMessage += 'the new leg will be open at the same time.';

  return (
    <>
      {_.map(existingLegConfigs, renderExistingLegAction)}
      {_.isEmpty(existingLegConfigs)
        && <p className="text-danger text-center my-2">No entry actions added</p>}

      <div className="d-flex align-items-center mt-3">
        <button
          type="button"
          className="btn btn-sm btn-primary track"
          disabled={isDisabledModifyBtn}
          data-track-category="Adjustment"
          data-track-action="Add Existing Leg Actions"
          data-track-label="Add Existing Leg Actions"
          onClick={() => onUpdateAdjustmentActionConfigs({
            ...actionConfig, existingLegConfigs: [...existingLegConfigs, getDefaultExistingLegConfig]
          })}
        >
          Modify Existing Legs
        </button>
        {isShowWarning && (
          <HoverMessage message={warningMessage}>
            <span className="material-icons-outlined text-dark tx-16 align-middle ml-2 cursor-pointer">
              info
            </span>
          </HoverMessage>
        )}
      </div>
    </>
  );
};

ExistingLegConfigs.propTypes = propTypes;
ExistingLegConfigs.defaultProps = defaultProps;

export default ExistingLegConfigs;
