import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import { updateReferralCode } from 'common/api/user';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const propTypes = {
  redirectPath: PropTypes.string.isRequired,
};

const defaultProps = {};

const ReferralCollector = ({ redirectPath }) => {
  const [referralCode, setReferralCode] = useState('');
  const [invalidMessage, setInvalidMessage] = useState('');

  const redirectPage = () => {
    window.location.href = redirectPath;
  };

  const onUpdateReferralCode = () => {
    if (!referralCode) {
      setInvalidMessage('Please provide the details');
      return null;
    }
    setInvalidMessage('');

    updateReferralCode(referralCode)
      .then((res) => {
        if (res.status) {
          reactToastify('Referral code updated', TOASTIFY_TYPES.SUCCESS);
          setTimeout(() => {
            redirectPage();
          }, 1000);
        } else {
          setInvalidMessage(
            `${res.message || 'Referral code updation failed'} Still, you can skip this step.`
          );
        }
      });

    return null;
  };
  return (
    <Modal
      size="md"
      onClose={() => redirectPage()}
      className=""
      okBtnTitle="Submit"
      cancelBtnTitle="Skip"
      onSave={() => onUpdateReferralCode()}
      onCancel={() => redirectPage()}
    >
      <div className="text-center">
        <img className="w-75" alt="smartphone" src="/share.png" />
        <h5 className="text-muted mb-2">Are you signing up here from any referrer?</h5>
      </div>
      <form>
        <div className="form-group">
          <label htmlFor="referralCode">Referral Code</label>
          <input
            type="text"
            className="form-control"
            id="referralCode"
            onChange={(event) => setReferralCode(event.target.value)}
          />
        </div>
      </form>
      {invalidMessage && <div className="invalid-feedback d-block">{invalidMessage}</div>}
    </Modal>
  );
};

ReferralCollector.propTypes = propTypes;
ReferralCollector.defaultProps = defaultProps;

export default ReferralCollector;
