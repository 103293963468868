import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TRANSACTION_TYPES } from 'modules/TransactionsBuilder/configs';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

const propTypes = {
  transactionType: PropTypes.string.isRequired,
  onTransactionTypeChange: PropTypes.func.isRequired,
  validator: PropTypes.shape({}).isRequired,
};
const defaultProps = {};

const BuyOrSellSelector = ({
  transactionType: propsTransactionType, onTransactionTypeChange, validator
}) => {
  const [transactionTypeInputId] = useState(_.uniqueId('transactionType-'));
  const { errors, register } = useFormContext();
  const radioBtnStyle = classNames('form-check-input', { 'is-invalid': !!errors[transactionTypeInputId] });
  const transactionTypeStyle = classNames(
    'transaction-type',
    'align-items-center',
    'gap-10',
    'mt-1',
    'd-flex',
    'selector-radio-btn',
  );

  const renderRadioBtn = (transactionType) => {
    const transactionTypeClassName = classNames('form-check form-check-sm', {
      buy: transactionType === 'buy',
      sell: transactionType === 'sell'
    });

    return (
      <div className={transactionTypeClassName} key={transactionType}>
        <input
          className={radioBtnStyle}
          type="radio"
          name={transactionTypeInputId}
          ref={register(validator)}
          id={`${transactionTypeInputId}-${transactionType}`}
          value={transactionType}
          checked={transactionType === propsTransactionType}
          onChange={() => onTransactionTypeChange({ transactionType })}
        />
        <label className="form-check-label" htmlFor={`${transactionTypeInputId}-${transactionType}`}>
          {_.startCase(transactionType)}
          {/* <TransactionType type={transactionType} /> */}
        </label>
      </div>
    );
  };

  return (
    <div className={transactionTypeStyle}>
      {_.map(TRANSACTION_TYPES, renderRadioBtn)}
    </div>
  );
};

BuyOrSellSelector.propTypes = propTypes;
BuyOrSellSelector.defaultProps = defaultProps;

export default BuyOrSellSelector;
