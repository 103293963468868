import React, { useCallback, useMemo } from 'react';
import usePipeConfigs from 'v2/modules/withRunForm/hooks/usePipeConfigs/index';
import HoverMessage from 'common/components/HoverMessage';
import PipeConfigForm from './PipeConfigForm/index';
import PipeConfigView from './PipeConfigView/index';

const propTypes = {};
const defaultProps = {};

const PipeConfigs = () => {
  const {
    pipeConfigs,
    shouldRenderPipeForm,
    currentPipeConfig,
    onUpdatePipeFormConfigs,
    onUpdatePipeConfigsToStore,
    onAddIndicator,
    outputSchema,
    onDeletePipeConfig,
    onEditPipeConfig,
    pipeDependencies,
    existingPipeNames,
    editIndex,
    getPipeUpdatedNameIfAlreadyPresent,
    isHiddenSignal
  } = usePipeConfigs();

  const onUpdate = useCallback((newPipeConfig) => {
    if (!_.isEmpty(newPipeConfig)) {
      onUpdatePipeConfigsToStore(newPipeConfig);
    }
    onUpdatePipeFormConfigs({ shouldRenderPipeForm: false, currentPipeConfig: null, editIndex: null });
  }, [onUpdatePipeConfigsToStore, onUpdatePipeFormConfigs]);

  const renderPipeView = useCallback((pipeConfig, idx) => {
    return (
      <PipeConfigView
        pipeConfig={pipeConfig}
        key={idx}
        isHiddenSignal={isHiddenSignal}
        isDependent={_.get(pipeDependencies, `${pipeConfig?.name}.isDependent`, false)}
        onEditPipeConfig={() => onEditPipeConfig(idx)}
        outputSchema={outputSchema}
        onDeletePipeConfig={() => onDeletePipeConfig(idx)}
      />
    );
  }, [isHiddenSignal, onDeletePipeConfig, onEditPipeConfig, outputSchema, pipeDependencies]);

  const renderAddIndicatorButton = useMemo(() => {
    return (
      <button
        type="button"
        disabled={isHiddenSignal}
        className="custom_outline__btn tx-12
            font-weight-semi d-flex
            align-items-center"
        onClick={() => {
          if (!isHiddenSignal) onAddIndicator();
        }}
      >
        <i className="material-icons-outlined tx-14 mr-1">library_add</i>
        Add Indicator
      </button>
    );
  }, [isHiddenSignal, onAddIndicator]);

  return (
    <div className="indicators-details py-2 px-4">
      <div className="d-flex align-items-center flex-row mb-2">
        <div className="font-weight-medium tx-15">
          Indicators
        </div>
      </div>
      {_.map(pipeConfigs, renderPipeView)}
      {shouldRenderPipeForm && (
        <PipeConfigForm
          getPipeUpdatedNameIfAlreadyPresent={getPipeUpdatedNameIfAlreadyPresent}
          pipeConfig={currentPipeConfig}
          onUpdate={onUpdate}
          pipeIndex={_.isNumber(editIndex) ? editIndex : undefined}
          isDependent={_.get(pipeDependencies, `${currentPipeConfig?.name}.isDependent`, false)}
          existingPipeNames={existingPipeNames}
        />
      )}
      <div className="d-flex justify-content-center py-2">
        {isHiddenSignal ? (
          <HoverMessage message="This action has been disabled by the Author">
            {renderAddIndicatorButton}
          </HoverMessage>
        ) : (
          renderAddIndicatorButton
        )}
      </div>
    </div>
  );
};

PipeConfigs.propTypes = propTypes;
PipeConfigs.defaultProps = defaultProps;

export default PipeConfigs;
