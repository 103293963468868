const configuration = {
  type: 'volumeWeightedAveragePrice',
  defaults: {
    type: 'volumeWeightedAveragePrice',
    name: 'vwap',
    config: {
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'VWAP',
    'volumeWeightedAveragePrice'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      'previousDayVWAP',
      'currentDayVWAP'
    ];

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
