import _ from 'lodash';
import { schemaShouldShowConfigTypes, schemaTypes } from './config';

const commonEntryOrExitOutputSchema = [
  {
    name: 'timeOfDay',
    label: 'Time Of Day',
    type: schemaTypes.time,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.entryLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
    ],
  },
  {
    name: 'dayOfWeek',
    label: 'Day Of Week',
    type: schemaTypes.dayOfWeek,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.entryLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
    ],
  },
  {
    name: 'date',
    label: 'Date',
    type: schemaTypes.date,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.entryLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
    ],
  },
  {
    name: 'daysToExpire',
    label: 'Days To Expire',
    type: schemaTypes.target,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.entryLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
      schemaShouldShowConfigTypes.exitLeftOperand,
    ],
  },
  {
    name: 'numberOfMinutesFromEntry',
    label: 'Number Of Minutes From Entry',
    type: schemaTypes.minute,
    shouldShowConfigs: [
      schemaShouldShowConfigTypes.exitLeftOperand,
      schemaShouldShowConfigTypes.adjustmentLeftOperand,
    ],
  }
];

export const buildCommonEntryOrExitOutputSchema = () => {
  return _.chain(commonEntryOrExitOutputSchema)
    .map(({
      label, name, type, shouldShowConfigs
    }) => {
      return {
        type,
        schemaName: name,
        category: 'exitSignalLeftOperand',
        shouldShowConfigs,
        name,
        label,
        caseIndex: undefined,
        priority: 80,
      };
    })
    .value();
};
