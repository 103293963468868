import React from 'react';
import PropTypes from 'prop-types';
import { tagsconfig } from 'ui/Strategies/config';

const propTypes = {
  filterConfigs: PropTypes.shape({
    premiums: PropTypes.arrayOf(PropTypes.bool)
  }).isRequired,
  onUpdateFilterConfigs: PropTypes.func.isRequired,
};

const defaultProps = {};

const PremiumFilter = ({ filterConfigs, onUpdateFilterConfigs }) => {
  const { premiums } = filterConfigs;
  const options = _.keys(tagsconfig);

  const renderOptions = ({ label, value }, idx) => {
    const onHandleOption = () => {
      if (_.includes(premiums, value)) {
        return _.filter(premiums, (p) => p !== value);
      }

      return [...premiums, value];
    };

    return (
      <div className="form-check form-check-sm" key={idx}>
        <input
          className="form-check-input track"
          type="checkbox"
          data-track-category="Market Place Filter"
          data-track-action={value}
          data-track-label="Premium Filter"
          value={value}
          id={label}
          checked={_.includes(premiums, value)}
          onChange={() => { }}
          onClick={() => onUpdateFilterConfigs({
            ...filterConfigs,
            premiums: onHandleOption()
          })}
        />
        <label className="form-check-label" htmlFor={label}>{_.startCase(label)}</label>
      </div>
    );
  };

  return _.isEmpty(options) ? null : (
    <div className="mp-filter">
      <h6 className="mp-filter-title">Premium</h6>
      <div className="mp-filter-list">
        {_.map(
          [{ label: 'Premium Strategy', value: true }, { label: 'Non Premium Strategy', value: false }
          ], renderOptions
        )}
      </div>
    </div>
  );
};

PremiumFilter.propTypes = propTypes;
PremiumFilter.defaultProps = defaultProps;

export default PremiumFilter;
