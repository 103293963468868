import React, { useState } from 'react';
import { fetchV2JobDetails } from 'common/api/job';
import _ from 'lodash';

const V2JobDetails = () => {
  const [jobId, setJobId] = useState('');
  const [jobDetails, setJobDetails] = useState({});
  const [errorMsg, setErrorMsg] = useState('');

  const getJobDetails = () => {
    fetchV2JobDetails(jobId)
      .then((result) => {
        setJobDetails(result);
        setErrorMsg('');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setJobDetails({});
        setErrorMsg(`Error fetching data, Status: ${error.status}, statusText: ${error.statusText}`);
      });
  };

  return (
    <div className="container-fluid mt-4">
      <div className="row justify-content-center">
        <div className="col-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Job Details</h5>
              <form>
                <div className="form-group">
                  <label htmlFor="jobId">Job ID</label>
                  <input
                    type="number"
                    className="form-control"
                    id="jobId"
                    name="jobId"
                    placeholder="Enter Job ID"
                    onChange={(event) => {
                      const { target: { value } } = event;
                      setJobId(value);
                    }}
                    value={jobId}
                  />
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => getJobDetails()}
                  >
                    Get Job Details
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {(!_.isEmpty(jobDetails) || !_.isEmpty(errorMsg)) && (
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-md-12">
            <div className="card bg-dark text-white">
              <div className="card-body">
                <h5 className="card-title text-white">Job Details</h5>
                <pre className="well">
                  <code className="ticks-viewer text-white">
                    {!_.isEmpty(jobDetails) && JSON.stringify(jobDetails, null, 2)}
                    {!_.isEmpty(errorMsg) && errorMsg}
                  </code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default V2JobDetails;
