import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { onRegexOnlyNumberAndFloat } from 'common/stringUtils/toPositiveNumberString';
import withErrorBoundary from 'common/components/withErrorBoundary/index';

const propTypes = {
  stopAndLimitPriceDifferenceInPercentage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({}))
};
const defaultProps = {
  errors: []
};

const StopLimitPriceDifferenceInPercentage = ({
  stopAndLimitPriceDifferenceInPercentage,
  onChange, labelClassName,
  buttonClassName,
  errors
}) => {
  const hasError = _.find(errors, (error) => error.property
    === 'instance.stopAndLimitPriceDifferenceInPercentage');

  const [stopAndLimitPrice, setStopAndLimitPrice] = useState(stopAndLimitPriceDifferenceInPercentage);

  useEffect(() => {
    setStopAndLimitPrice(stopAndLimitPriceDifferenceInPercentage);
  }, [stopAndLimitPriceDifferenceInPercentage]);

  return (
    <>
      <div className={labelClassName}>
        <label className="mb-1 mb-lg-0 col-form-label col-form-label-sm">Stop Limit Price Difference</label>
      </div>
      <div className={buttonClassName}>
        <div className="input-group overall-stops">
          <div
            role="group"
            className="btn-group btn-group-sm
          btn-group-toggle btn-group btn-group-toggle-toggle"
          >
            <label
              className="btn btn-sm btn-outline-primary btn-lightness
              active"
            >
              <input
                name="group-selector"
                type="radio"
                autoComplete="off"
                value="percent"
                checked=""
                onChange={() => { }}
              />
              %
            </label>
          </div>
          <input
            type="text"
            className="form-control form-control-sm"
            name="stopLimitPriceDifferenceInPercentage"
            onBlur={(event) => onChange(event.target.value === ''
              ? 0 : onRegexOnlyNumberAndFloat(event.target.value, false))}
            value={(stopAndLimitPrice === undefined || stopAndLimitPrice === null)
              ? '' : stopAndLimitPrice.toString()}
            onChange={(event) => setStopAndLimitPrice(onRegexOnlyNumberAndFloat(event.target.value, true))}
          />
        </div>
        {hasError ? (
          <div className="invalid-feedback d-block">
            Number should be integer
          </div>
        ) : null}
      </div>
    </>
  );
};

StopLimitPriceDifferenceInPercentage.propTypes = propTypes;
StopLimitPriceDifferenceInPercentage.defaultProps = defaultProps;

export default withErrorBoundary(StopLimitPriceDifferenceInPercentage);
