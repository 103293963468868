import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OPTION_TYPES } from 'modules/TransactionsBuilder/configs';
import { useFormContext } from 'react-hook-form';

const propTypes = {
  optionType: PropTypes.string.isRequired,
  onOptionTypeChange: PropTypes.func.isRequired,
  isHide: PropTypes.bool.isRequired
};
const defaultProps = {};

const CallOrPutSelector = ({
  optionType: propsOptionType, onOptionTypeChange, isHide
}) => {
  const [optionTypeBtnName] = useState(_.uniqueId('optionTypeBtnName-'));
  const optionTypeInputId = _.uniqueId('optionType');
  const { errors, register, clearErrors } = useFormContext();
  const shouldDisable = isHide;
  const validatorConfig = {
    validate: (value) => (!shouldDisable && !value ? 'value required' : null)
  };
  const validatorProps = isHide ? {} : { ref: register(validatorConfig) };

  const optionTypeStyle = classNames(
    'option-type',
    'align-items-center',
    'gap-10',
    'mt-1',
    'd-flex',
    'selector-radio-btn',
    { disabled: shouldDisable, }
  );

  const radioBtnStyle = classNames(
    'form-check-input',
    'track',
    { 'is-invalid': !!errors[optionTypeBtnName] }
  );

  useEffect(() => {
    if (shouldDisable) clearErrors(optionTypeBtnName);
  }, [isHide]);

  const renderRadioBtn = (optionType) => (
    <div className="form-check form-check-sm" key={optionType}>
      <input
        {...validatorProps}
        className={radioBtnStyle}
        type="radio"
        name={optionTypeBtnName}
        id={`${optionTypeInputId}-${optionType}`}
        value={optionType}
        data-track-category="Call Or Put Selectors"
        data-track-action={optionType}
        data-track-label="Selectors"
        tabIndex="-100"
        disabled={shouldDisable}
        checked={optionType === propsOptionType}
        onChange={() => onOptionTypeChange({ optionType })}
      />
      <label className="form-check-label" htmlFor={`${optionTypeInputId}-${optionType}`}>
        {_.startCase(optionType)}
      </label>
    </div>
  );

  const optionTypeRadioBtns = _.map(OPTION_TYPES, renderRadioBtn);

  if (shouldDisable) {
    return null;
  }

  return (
    <div className={optionTypeStyle}>
      {optionTypeRadioBtns}
    </div>
  );
};

CallOrPutSelector.propTypes = propTypes;
CallOrPutSelector.defaultProps = defaultProps;

export default CallOrPutSelector;
