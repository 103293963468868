import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilteredTitles from './Components/FilteredTitles';
import LanguageTab from './Components/LanguageTab';
import VideosDetails from './Components/VideosDetails';
import VideoTitleOptionsAndSeachBar from './Components/VideoTitleOptionsAndSeachBar';

const propTypes = {
  videos: PropTypes.arrayOf(PropTypes.shape({})),
  selectedVideoId: PropTypes.number
};

const defaultProps = { videos: [], selectedVideoId: 0 };

const DEFAULT_LANGUAGE = 'english';

const VideoPage = ({ videos, selectedVideoId }) => {
  const selectedVideo = _.find(videos, { id: selectedVideoId });
  const selectedLanguage = selectedVideo ? selectedVideo.language : DEFAULT_LANGUAGE;

  const [activeLanguage, setActiveLanguage] = useState(selectedLanguage);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [filteredTitles, setFilteredTitles] = useState([]);
  const languageBasedVideos = _.filter(videos, ({ language }) => language === activeLanguage);

  useEffect(() => {
    const filterTitleBasedVideos = _.chain(filteredTitles)
      .map((filteredTitle) => {
        return _.find(languageBasedVideos, ({ title }) => title === filteredTitle);
      }).compact().value();

    setFilteredVideos(_.isEmpty(filteredTitles) ? languageBasedVideos : filterTitleBasedVideos);
  }, [activeLanguage, videos, filteredTitles]);

  return (
    <div className="videos-page">
      <div className="row">
        <LanguageTab activeLanguage={activeLanguage} onChangeLanguage={setActiveLanguage} />

        <VideoTitleOptionsAndSeachBar
          filteredVideos={languageBasedVideos}
          onHandleFilteredTitles={setFilteredTitles}
          filteredTitles={filteredTitles}
        />

        <div className="col-md-8 col-xl-9">
          <FilteredTitles
            onHandleFilteredTitles={setFilteredTitles}
            filteredTitles={filteredTitles}
          />
          <VideosDetails videos={filteredVideos} selectedVideoId={selectedVideoId} />
        </div>

      </div>
    </div>
  );
};

VideoPage.propTypes = propTypes;
VideoPage.defaultProps = defaultProps;

export default VideoPage;
