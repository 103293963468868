import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';

import { quickAdjustmentPropTypes } from 'common/propTypes';
import {
  QUICK_ADJUSTMENT_TRIGGERS,
  QUICK_ADJUSTMENT_ACTIONS,
} from 'v2/common/constants/index';
import useOutputSchema from 'v2/modules/withRunForm/hooks/useOutputSchema';
import { OutputSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';
import { extractIndividualSchemaField } from 'v2/modules/withRunForm/utils/signal';
import QuickAdjustmentDelayBy from './QuickAdjustmentDelayBy';

export const BLANK_QUICK_ADJUSTMENT = {
  trigger: QUICK_ADJUSTMENT_TRIGGERS.ExitedInLoss.value,
  action: QUICK_ADJUSTMENT_ACTIONS.CloseLeg.value,
  targets: [],
};

const propTypes = {
  intentId: PropTypes.string.isRequired,
  quickAdjustments: PropTypes.arrayOf(quickAdjustmentPropTypes),
  onUpdate: PropTypes.func.isRequired,
};

const defaultProps = {
  quickAdjustments: [BLANK_QUICK_ADJUSTMENT],
};

const renderTrigger = (trigger, onUpdateKey) => {
  return (
    <>
      When
      <select
        className="quick-trigger"
        value={trigger}
        onChange={(e) => onUpdateKey('trigger', e.target.value)}
      >
        {_.map(QUICK_ADJUSTMENT_TRIGGERS, (t) => (
          <option value={t.value} key={t.value}>
            {t.displayName}
          </option>
        ))}
      </select>
    </>
  );
};

const renderAction = (action, onUpdateKey) => {
  return (
    <>
      Then
      <select
        className="quick-action"
        value={action}
        onChange={(e) => onUpdateKey('action', e.target.value)}
      >
        {_.map(QUICK_ADJUSTMENT_ACTIONS, (a) => (
          <option value={a.value} key={a.value}>
            {a.displayName}
          </option>
        ))}
      </select>
    </>
  );
};

const renderQuickAdjustment = (
  quickAdjusment,
  legOptions,
  index,
  onUpdate,
  onDelete
) => {
  const {
    trigger, action, targets, delayBy
  } = quickAdjusment;

  const onUpdateKey = (key, value) => {
    onUpdate(index, { ...quickAdjusment, [key]: value });
  };
  const selectedTargetOptions = _.map(targets, (target) => ({
    id: target,
    label: `Leg ${target}`,
  }));
  const onTargetsUpdate = (newSelections) => {
    return onUpdateKey('targets', _.map(newSelections, 'id'));
  };

  return (
    <div className="quick-adjustment mb-3" key={index}>
      {renderTrigger(trigger, onUpdateKey)}
      {renderAction(action, onUpdateKey)}
      <Typeahead
        id="quick-adjustments-targets"
        className="quick-targets"
        labelKey="label"
        multiple
        onChange={onTargetsUpdate}
        options={legOptions}
        placeholder="Select Leg..."
        selected={selectedTargetOptions}
        positionFixed
      />
      <QuickAdjustmentDelayBy value={delayBy} onChange={onUpdateKey} />
      <button
        className="btn btn-delete-outline"
        type="button"
        onClick={() => onDelete(index)}
      >
        <i aria-disabled="false" />
      </button>
    </div>
  );
};

const QuickAdjustmentsForm = ({ intentId, quickAdjustments, onUpdate }) => {
  const outputSchema = useOutputSchema();
  const legOptions = useMemo(() => {
    return _.chain(outputSchema)
      .thru((schema) => OutputSchemaUtility.filterByCategory(schema, 'individualStop'))
      .thru((schema) => _.map(
        schema,
        (sch) => extractIndividualSchemaField(sch?.name)?.intentId
      ))
      .uniq()
      .without(intentId)
      .map((id) => ({ id: id.toString(), label: `Leg ${id}` }))
      .value();
  }, [intentId, outputSchema]);

  const addQuickAdjustment = useCallback(() => {
    onUpdate([...quickAdjustments, BLANK_QUICK_ADJUSTMENT]);
  }, [quickAdjustments, onUpdate]);

  const onDeleteQuickAdjustment = useCallback(
    (index) => {
      onUpdate(_.filter(quickAdjustments, (_, i) => i !== index));
    },
    [quickAdjustments, onUpdate]
  );

  const updateQuickAdjustment = useCallback(
    (index, updatedQuickAdjustment) => {
      const newQuickAdjustments = [...quickAdjustments];
      newQuickAdjustments[index] = updatedQuickAdjustment;
      onUpdate(newQuickAdjustments);
    },
    [quickAdjustments, onUpdate]
  );

  return (
    <div className="quick-adjustments-container">
      {quickAdjustments.map((quickAdjustment, index) => {
        return renderQuickAdjustment(
          quickAdjustment,
          legOptions,
          index,
          updateQuickAdjustment,
          onDeleteQuickAdjustment
        );
      })}
      <div className="d-flex align-items-center justify-content-center">
        <button
          type="button"
          className="add-quick-adjustment btn btn-edit-fill py-1 px-2"
          onClick={addQuickAdjustment}
        >
          <span className="material-icons-outlined tx-22 mr-1">add</span>
          Add Quick Action
        </button>
      </div>
    </div>
  );
};

QuickAdjustmentsForm.propTypes = propTypes;
QuickAdjustmentsForm.defaultProps = defaultProps;

export default QuickAdjustmentsForm;
