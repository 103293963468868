import React from 'react';

const propTypes = {};
const defaultProps = {};

const LoginButton = () => {
  const pathArray = window.location.pathname.split('/');
  const returnToPath = _.get(pathArray, '[1]', '');
  const { applicationDomain } = window;
  const isPrimaryDomain = (_.isNull(_.get(applicationDomain, 'id', null)));

  let signInPath = isPrimaryDomain ? '/?locale=en' : 'users/sign_in?locale=en';
  const returnToParam = window.location.pathname + window.location.search;
  if (returnToPath) signInPath += `&return_to=${encodeURIComponent(returnToParam)}`;

  return (
    <li>
      <button
        type="button"
        className="btn btn-sm btn-pill lg-btn btn-outline-primary"
        onClick={() => { window.location.href = signInPath; }}
      >
        <i className="material-icons-outlined mr-1">lock</i>
        {' '}
        Login
      </button>
    </li>
  );
};

LoginButton.propTypes = propTypes;
LoginButton.defaultProps = defaultProps;

export default LoginButton;
