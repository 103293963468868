import React from 'react';
import PropTypes from 'prop-types';
import {
  Line, Bar, Pie, Bubble
} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const propTypes = {
  labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  datasets: PropTypes.arrayOf(PropTypes.shape({})),
  chartType: PropTypes.string
};

const defaultProps = {
  labels: [],
  datasets: [],
  chartType: 'Line'
};

const ChartJsGraph = ({ labels, datasets, chartType }) => {
  const data = { labels, datasets };

  switch (chartType) {
    // if some chart integrte refer chart js
    case 'Bubble':
      return <Bubble data={data} />;
    case 'Pie':
      return <Pie data={data} />;
    case 'Bar':
      return <Bar data={data} />;
    default:
      return <Line data={data} />;
  }
};

ChartJsGraph.propTypes = propTypes;
ChartJsGraph.defaultProps = defaultProps;

export default ChartJsGraph;
