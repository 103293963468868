const configuration = {
  type: 'simpleMovingAverage',
  defaults: {
    type: 'simpleMovingAverage',
    name: 'sma',
    config: {
      noOfCandles: 9,
      candleInterval: '1 minutes',
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'SMA',
    'simpleMovingAverage'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;

    return [
      {
        name,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: name
      }
    ];
  },
  isEnabled: true
};
export default configuration;
