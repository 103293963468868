import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { webAndMobileDefaultProps, webAndMobileRootPropTypes } from 'ui/Plans/defaultProps';
import PlanTypeSelector from '../common/PlanTypeSelector';
import Credits from '../common/Credits';
import PlanCard from './PlanCard';
import PlanContent from '../common/PlanContent';
import PlanButton from '../common/PlanButton';
import AddonCard from './AddonCard';
import PaymentCoupons from '../common/PaymentCoupons';

const propTypes = webAndMobileRootPropTypes;
const defaultProps = webAndMobileDefaultProps;

const Mobile = ({
  planCycleType,
  setPlanCycleType,
  activePlanId,
  activePlanName,
  couponCode,
  setCouponCode,
  isApplied,
  appliedCouponConfig,
  creditBalance,
  setCreditBalance,
  isCreditApplied,
  setIsCreditApplied,
  message,
  currentCyclicPeriodPlans,
  currentCyclicPeriodAddons,
  plansGroupedByCyclicMonths,
  shouldShowAddons,
  isSubscriptionActive,
  shouldShowCoupon,
  onCouponUpdate
}) => {
  const [currentTab, setCurrentTab] = useState('plans');
  const [selectedPlanId, setSelectedPlanId] = useState(0);

  useEffect(() => {
    setSelectedPlanId(
      _.chain(currentCyclicPeriodPlans)
        .orderBy('price', 'desc')
        .first()
        .value()?.id
    );
  }, [planCycleType]);

  const selectedPlan = _.find(currentCyclicPeriodPlans, { id: selectedPlanId }) || {
    historic_run_limit: 0, live_run_limit: 0, is_premium: false
  };

  const {
    historic_run_limit: historicRunLimit, live_run_limit: liveRunLimit, is_premium: isPremium,
  } = selectedPlan;

  const tabConfigs = [
    { key: 'plans', label: 'Plans' },
    { key: 'addons', label: 'Add-ons' }
  ];

  const renderli = (liConfig) => {
    const liClassName = classNames({
      'nav-link': true, active: currentTab === liConfig.key,
    });

    return (
      <li className="nav-item" key={liConfig.key}>
        <span className={liClassName} onClick={() => setCurrentTab(liConfig.key)}>
          {liConfig.label}
        </span>
      </li>
    );
  };

  const renderPlan = (plan, idx) => {
    return (
      <PlanCard
        plan={plan}
        key={idx}
        isActive={selectedPlanId === plan?.id}
        setSelectedPlanId={setSelectedPlanId}
        activePlanName={activePlanName}
        couponConfig={appliedCouponConfig}
        planCycleType={planCycleType}
        activePlanId={activePlanId}
        isSubscriptionActive={isSubscriptionActive}
        isCreditApplied={isCreditApplied}
        credits={isCreditApplied ? creditBalance : 0}
        couponCode={couponCode}
        onChangeCouponCode={setCouponCode}
        isApplied={isApplied}
        onCouponUpdate={onCouponUpdate}
        message={message}
      />
    );
  };

  const renderCouponAndCredits = () => {
    if (!shouldShowCoupon) return null;
    let headerClass = 'col-md-12 m-auto d-flex align-item-start gap-15 ';
    headerClass += ' justify-content-center';

    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className={headerClass}>
          <Credits
            creditBalance={creditBalance}
            isCreditApplied={isCreditApplied}
            onUpdateCredits={
              (newCreditBalance, isCreditUsed) => {
                setCreditBalance(newCreditBalance);
                setIsCreditApplied(isCreditUsed);
              }
            }
          />
          <PaymentCoupons
            couponCode={couponCode}
            onChangeCouponCode={setCouponCode}
            isApplied={isApplied}
            onCouponUpdate={onCouponUpdate}
            message={message}
            isMobile
          />
        </div>
      </div>
    );
  };

  const renderPlanContent = () => {
    return (
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="switch-btn">
            <PlanTypeSelector
              planCycleType={planCycleType}
              onHandlePlanType={setPlanCycleType}
              plansGroupedByCyclicMonths={plansGroupedByCyclicMonths}
            />
          </div>
        </div>
        {renderCouponAndCredits()}
        <div className="plan-card-row">
          {_.map(_.orderBy(currentCyclicPeriodPlans, 'price', 'desc'), renderPlan)}
        </div>
        <div className="plan-desk">
          <div className="content-border border-bottom "><p className="mt-2">Whats included</p></div>
          <PlanContent
            isPremium={isPremium}
            parentClassName="plan-list"
            historicRunLimit={historicRunLimit}
            liveRunLimit={liveRunLimit}
          />
          <div className="d-block text-center mx-auto">
            <PlanButton
              btnClassName="btn btn-primary btn-lg mobile-order-now"
              plan={selectedPlan}
              isHideIcon
              activePlanId={activePlanId}
              couponConfig={appliedCouponConfig}
              activePlanName={activePlanName}
              isSubscriptionActive={isSubscriptionActive}
              isCreditApplied={isCreditApplied}
              couponCode={couponCode}
              onChangeCouponCode={setCouponCode}
              isApplied={isApplied}
              onCouponUpdate={onCouponUpdate}
              message={message}
            />
          </div>

        </div>
      </div>
    );
  };

  const renderAddon = (addon, id) => (
    <AddonCard
      key={id}
      addon={addon}
      purchaseType="addon"
      couponConfig={appliedCouponConfig}
      credits={isCreditApplied ? creditBalance : 0}
    />
  );

  const renderAddonsContent = (isShowCreditAndCoupon = true) => {
    if (!shouldShowAddons) return null;
    if (_.isEmpty(currentCyclicPeriodAddons)) return null;

    return (
      <>
        {isShowCreditAndCoupon && renderCouponAndCredits()}
        <div className="addon-card-mobile">
          {_.map(currentCyclicPeriodAddons, renderAddon)}
        </div>
      </>
    );
  };

  return (
    <>
      <ul className="nav nav-pills">
        {tabConfigs.map((tabConfig) => renderli(tabConfig))}
      </ul>

      {currentTab === 'plans' && (
        <>
          {renderPlanContent()}
          {renderAddonsContent(false)}
        </>
      )}
      {currentTab === 'addons' && renderAddonsContent(true)}
    </>
  );
};

Mobile.propTypes = propTypes;
Mobile.defaultProps = defaultProps;

export default Mobile;
