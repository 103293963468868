const configuration = {
  type: 'lastNCandles',
  defaults: {
    type: 'lastNCandles',
    name: 'lastNCandles',
    config: {
      noOfCandles: 7,
      candleInterval: '1 minutes',
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'lastNCandles'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: (pipeConfig) => {
    const { name, type } = pipeConfig;
    const properties = [
      'highestHigh',
      'lowestLow'
    ];

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: 'number',
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`
      };
    });
  },
  isEnabled: true
};
export default configuration;
