import React from 'react';
import PropTypes from 'prop-types';
import MoneyDisplay from 'common/components/MoneyDisplay';

const propTypes = { strategy: PropTypes.shape({}), onContinue: PropTypes.func.isRequired };

const defaultProps = { strategy: {} };

const StrategyConfirmation = ({ strategy, onContinue }) => {
  const money = _.get(strategy, 'purchase_amount', 0);

  return (
    <div>
      <div className="strategy-confirmation">
        <img src="/plan-purchase.svg" className="plan-purchase-img" alt="purchase-img" />
        <div className="info-text">You don&apos;t have enough live credits to run this strategy.</div>
        <div className="strategy-details">
          <div className="strategy-title">
            {_.upperCase(_.startCase(_.get(strategy, 'title', '')))}
          </div>
          <div className="strategy-price">
            <div><MoneyDisplay>{money}</MoneyDisplay></div>
          </div>
        </div>
        <div className="info-text">For purchase a plan, please continue</div>
        <button className="goto-btn btn btn-primary btn-pill" type="button" onClick={() => onContinue()}>
          Continue
          <i className="material-icons-outlined tx-18 align-middle ml-1">arrow_forward</i>
        </button>
      </div>
    </div>
  );
};

StrategyConfirmation.propTypes = propTypes;
StrategyConfirmation.defaultProps = defaultProps;

export default StrategyConfirmation;
