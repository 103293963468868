import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  style: PropTypes.string,
};

const defaultProps = {
  type: 'number',
  style: 'form-control',
};

const Input = ({
  value,
  onChange,
  type,
  style
}) => {
  return (
    <input
      type={type}
      className={style}
      value={value}
      onChange={onChange}
    />
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
