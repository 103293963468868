import React from 'react';
import PropTypes from 'prop-types';
import {
  // strategyPropTypes,
  userPropTypes
} from 'common/propTypes';
import RunDetail from 'ui/run/RunShow/components/RunDetail/index';
import TransactionDetail from 'ui/run/RunShow/components/TransactionDetail/index';
import RunTitleAndActions from 'ui/run/RunShow/components/RunTitleAndActions/index';
import CreditDetails from 'ui/run/RunShow/components/CreditDetails';
import { RUN_TYPES } from 'common/constants/index';
import OrderStore from 'ui/run/RunShow/OrderStore/index';
import moment from 'moment';
import MoneyDisplay from 'common/components/MoneyDisplay';
// import DeployStrategyButton from '../Strategy/DeployStrategyButton';

const propTypes = {
  deployedRunDetails: PropTypes.shape({}),
  currentUser: userPropTypes,
  marginAmount: PropTypes.number.isRequired,
  // strategy: strategyPropTypes.isRequired
};
const defaultProps = {
  deployedRunDetails: {},
  currentUser: null
};

const StrategyDeployedRunDetails = ({
  deployedRunDetails, currentUser, marginAmount,
  //  strategy
}) => {
  const deployedRun = _.get(deployedRunDetails, 'deployed_run', null);

  if (!deployedRun) {
    return (
      <div className="status-wrapper">
        <div className="info-msg mt-8">
          <img src="../deployed-strategy.svg" className="small" alt="Strategy is running" />
          <div>
            <h6>You have not deployed this strategy</h6>
            It will take new orders as when you deploy a strategy.
          </div>
          {/* <DeployStrategyButton
            strategy={strategy}
            btnClassName="common-icon-btn btn btn-sm btn-success"
          /> */}
        </div>
      </div>
    );
  }

  const deployedRunJobs = _.get(deployedRunDetails, 'deployed_run_jobs', []);
  const deployedRunJobIds = _.map(deployedRunJobs, 'id').sort();
  const deployedRunId = _.get(deployedRunJobs, '[0].run_id');

  const {
    run_type: runType,
    instrument_groups: instrumentGroups,
    order_type: orderType,
    created_at: createdAt,
    status,
    multiplier,
    summary
  } = deployedRun;
  const profit = _.get(summary, 'profit', 0);

  const renderRunButtons = () => {
    return (
      <div className="d-flex align-items-center flex-wrap gap-10 justify-content-end">
        <CreditDetails
          runType={runType}
          granularity={_.get(deployedRun, 'granularity', '')}
          isConsumed
          startDate={_.get(deployedRun, 'start_date', '')}
          endDate={_.get(deployedRun, 'end_date', '')}
          instrumentGroups={instrumentGroups}
          cases={_.get(deployedRun, 'quant_config.cases', [])}
        />
        <RunTitleAndActions
          run={deployedRun}
          currentUser={currentUser}
          strategyDetails={{}}
        />
      </div>
    );
  };

  return (
    <div className="strategy-order-store deployed-strategy-run px-3">
      <div className="profitable-value py-3 mb-3 border-bottom">
        <div className="value-point">
          <label className="value-name">Deployed At</label>
          <div className="values">
            <span className="time">{moment(createdAt).format('ll')}</span>
          </div>
        </div>
        <div className="value-point border-0">
          <label className="value-name">Margin Required</label>
          <div className="values"><MoneyDisplay>{multiplier * marginAmount}</MoneyDisplay></div>
        </div>
        <div className="value-point">
          <label className="value-name">Realized Profit</label>
          <div className="values"><MoneyDisplay>{profit}</MoneyDisplay></div>
        </div>

        <div className="value-point">
          <label className="value-name">Status</label>
          <div className="values">{_.startCase(status)}</div>
        </div>
        <div className="value-point">
          <label className="value-name">Lot size Multipiler</label>
          <div className="values">{multiplier}</div>
        </div>
      </div>

      <OrderStore
        runIds={[deployedRunId]}
        jobIds={[deployedRunJobIds]}
        runType={RUN_TYPES.live}
        orderType={orderType}
        run={deployedRun}
      >
        <div className="marketplace-live">
          <RunDetail run={deployedRun} />
          {renderRunButtons()}

          <TransactionDetail run={deployedRun} isAccordianOpen />
        </div>
      </OrderStore>
    </div>
  );
};

StrategyDeployedRunDetails.propTypes = propTypes;
StrategyDeployedRunDetails.defaultProps = defaultProps;

export default StrategyDeployedRunDetails;
