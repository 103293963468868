import { getAlteredEmptyQuantConfig } from 'modules/QuantBuilder/config/index';
import {
  current1minuteCandle, CURRENT_ONE_MINUTE_CANDLE_NAME, getStopGainPercent, getStopGainPrice
} from '../../config';

export const CLOSE_PRICE_STRATEGY = 'closePriceStrategy';

export const movingAverageDefaultConfig = {
  period: 20,
  comparisonType: 'crossesAbove',
  interval: '1 minutes',
  actiontype: 'buy',
  transaction: 'option',
  movingAverageType: 'simpleMovingAverage',
  optionType: 'CE',
  strikeIndex: 0,
  stopLoss: 0,
  stopGain: 0,
  quantity: 1,
  stopLossType: 'percent',
  stopGainType: 'percent',
};

export const quantConfigConversionMcp = (config) => {
  const {
    period,
    comparisonType,
    interval,
    actiontype,
    transaction,
    movingAverageType,
    optionType,
    strikeIndex,
    stopLoss,
    instruments,
    stopGain,
    quantity,
    stopLossType,
    stopGainType,
    segment
  } = config;

  const emptyConfig = getAlteredEmptyQuantConfig();
  const { orderConfigs, pipeConfigs } = emptyConfig;
  const pipeConfig = [
    {
      type: movingAverageType,
      name: `${movingAverageType}${period}`,
      config: {
        noOfCandles: Number(period),
        candleInterval: interval,
        valuePaths: ['item0.month1Price'],
      },
    },
    current1minuteCandle()
  ];
  const { transactionConfigs } = _.head(orderConfigs);
  const { optionConfigs, futureConfig, mode } = _.head(transactionConfigs);
  const optionConfigObj = _.head(optionConfigs);
  const setOptionConfig = {
    ...optionConfigObj,
    entryType: actiontype,
    optionType,
    stopGainPrice: getStopGainPrice(stopGainType, stopGain),
    stopLossPrice: getStopGainPrice(stopLossType, stopLoss),
    stopGain: getStopGainPercent(stopGainType, stopGain),
    stopLoss: getStopGainPercent(stopLossType, stopLoss),
    strikeIndex: Number(strikeIndex),
    quantity: getQuantityObject(Number(quantity)),
    sortId: 0
  };

  const setFutureConfig = {
    ...futureConfig,
    entryType: actiontype,
    stopGainPrice: getStopGainPrice(stopGainType, stopGain),
    stopLossPrice: getStopGainPrice(stopLossType, stopLoss),
    stopGain: getStopGainPercent(stopGainType, stopGain),
    stopLoss: getStopGainPercent(stopLossType, stopLoss),
    quantityExpression: `${quantity}`,
    sortId: 1
  };

  const transactionIsOption = transaction === 'option';
  const modifiedOrderConfigs = [{
    ..._.head(orderConfigs),
    entrySignalConfigs: [{
      conditionOperator: '',
      leftOperand: `${CURRENT_ONE_MINUTE_CANDLE_NAME}.close`,
      operator: comparisonType,
      rightOperand: `${movingAverageType}${period}`,
    }],
    transactionConfigs: [
      {
        ..._.head(transactionConfigs),
        mode: { ...mode, transaction },
        futureConfig: transactionIsOption ? futureConfig : setFutureConfig,
        optionConfigs: transactionIsOption ? [{ ...optionConfigObj, ...setOptionConfig, }] : []
      }
    ]
  }];

  return {
    ...emptyConfig,
    segment,
    instrumentGroups: instruments,
    pipeConfigs: [...pipeConfigs, ...pipeConfig],
    orderConfigs: modifiedOrderConfigs,
  };
};
