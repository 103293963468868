import React from 'react';
import classNames from 'classnames';

import { getSetting, updateSetting } from 'common/api/setting';

const I18N_SCOPE = { scope: 'settings' };
const THEMES = [
  // TODO: I18n
  {
    labelFn: () => {
      return (
        <div>
          <img src="light-theme.png" className="w-100" alt="light theme" />
          <span>{I18n.t('light', I18N_SCOPE)}</span>
        </div>
      );
    },
    value: 'light'
  },
  {
    labelFn: () => {
      return (
        <div>
          <img src="dark-theme.png" className="w-100" alt="dark theme" />
          <span>{I18n.t('dark', I18N_SCOPE)}</span>
        </div>
      );
    },
    value: 'dark'
  },
];
const THEME_SETTING_KEY = 'default_theme';

const propTypes = {};
const defaultProps = {};

class ThemeSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = { setting: {} };
  }

  componentDidMount() {
    getSetting()
      .then((setting) => this.setState({ setting }));
  }

  onSelectTheme = (theme) => {
    const { setting } = this.state;
    const updatedSetting = { ...setting, [THEME_SETTING_KEY]: theme.value };

    this.setState({ setting: updatedSetting });
    updateSetting({ [THEME_SETTING_KEY]: theme.value })
      .then(() => window.location.reload());
  }

  renderThemeSelector = (theme) => {
    const { setting } = this.state;
    const { [THEME_SETTING_KEY]: defaultTheme } = setting;

    const selectorClassNames = classNames(
      'btn',
      (defaultTheme === theme.value) ? 'btn-primary' : 'btn-light'
    );

    return (
      <div
        key={theme.value}
        className={selectorClassNames}
        onClick={() => this.onSelectTheme(theme)}
      >
        {theme.labelFn()}
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="theme-setting mb-5">
          {THEMES.map(this.renderThemeSelector)}
        </div>
      </>
    );
  }
}

ThemeSettings.propTypes = propTypes;
ThemeSettings.defaultProps = defaultProps;
export default ThemeSettings;
