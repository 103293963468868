import { getLocalStorage, setLocalStorage } from './localstorage';

const today = new Date().toLocaleDateString();

export const setUserMessagesLocalStorage = (userMessages) => {
  let localStorage = null;
  try { localStorage = _.get(window, 'localStorage', null); } catch (e) { localStorage = null; }

  if (!localStorage) return;

  const keysInLocalStorage = _.keys(localStorage);

  _.each(userMessages, ({ id }) => {
    if (!_.includes(keysInLocalStorage, `message_read_${id}`)) {
      setLocalStorage(`message_read_${id}`, '');
    }
  });
};

export const getUnreadMessages = (userMessages) => {
  let localStorage = null;
  try { localStorage = _.get(window, 'localStorage', null); } catch (e) { localStorage = null; }

  if (!localStorage) return [];

  return _.filter(userMessages, ({ id, isShowEveryDay }) => {
    const messageReadAt = getLocalStorage(`message_read_${id}`);
    return (isShowEveryDay && messageReadAt !== today) || _.isEmpty(messageReadAt);
  });
};

export const onUserMessageClose = (messageIdToClose, unreadMessages) => {
  let localStorage = null;
  try { localStorage = _.get(window, 'localStorage', null); } catch (e) { localStorage = null; }

  if (!localStorage) return [];

  setLocalStorage(`message_read_${messageIdToClose}`, today);

  return _.filter(unreadMessages, ({ id }) => id !== messageIdToClose);
};
