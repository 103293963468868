import React from 'react';
import PropTypes from 'prop-types';
import InstrumentGroupsSentence from 'v2/common/components/InstrumentGroupsSentence';
import ConfigDisplayDetails from './ConfigDisplayDetails';

const propTypes = {
  groupType: PropTypes.string.isRequired,
  instrumentGroups: PropTypes.arrayOf(PropTypes.array).isRequired,
};

const defaultProps = {};

const ViewConfigInstrumentalDetails = ({
  groupType, instrumentGroups,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="m-0 font-weight-medium">Instrument Details</h5>
      </div>
      <div className="card-body">
        <div className="instrument-groups-input config-rundetails">
          <ConfigDisplayDetails title="Group Type" value={groupType} />
          <ConfigDisplayDetails
            title="Instruments"
            value={<InstrumentGroupsSentence instrumentGroups={instrumentGroups} />}
          />
        </div>
      </div>
    </div>
  );
};

ViewConfigInstrumentalDetails.propTypes = propTypes;
ViewConfigInstrumentalDetails.defaultProps = defaultProps;

export default ViewConfigInstrumentalDetails;
