import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import { isIncludeEquityOrFuture } from './helper';

const getPipeName = (operand) => {
  const currentCandle = 'currentCandle 1 minute';
  switch (operand) {
    case 'item0.month1Price.close':
      return `${currentCandle} Item 1 Future Last Traded Price`;
    case 'item1.month1Price.close':
      return `${currentCandle} Item 2 Future Last Traded Price`;
    case 'item0.month1Price.volume':
      return `${currentCandle} Item 1 Future Volume`;
    case 'item1.month1Price.volume':
      return `${currentCandle} Item 2 Future Volume`;
    case 'item0.month1Price.openInterest':
      return `${currentCandle} Item 1 Future Open Interest`;
    case 'item1.month1Price.openInterest':
      return `${currentCandle} Item 2 Future Open Interest`;
    case 'item0.equity':
      return `${currentCandle} Item 1 Equity Last Traded Price`;
    case 'item1.equity':
      return `${currentCandle} Item 2 Equity Last Traded Price`;
    case 'item0.equity.volume':
      return `${currentCandle} Item 1 Equity Volume`;
    case 'item1.equity.volume':
      return `${currentCandle} Item 2 Equity Volume`;
    default:
      return `${currentCandle} ${Math.random()}`;
  }
};

const onAddNewConfigs = (operand, newPipeConfigs) => {
  const isFutureClose = _.includes(operand, 'month1Price.close');

  const validPipeConfig = _.filter(newPipeConfigs, ({ type, config }) => {
    const { valuePaths } = config;

    const isValuePathAlreadyPresent = _.some(valuePaths, (valuePath) => {
      if (isFutureClose) {
        const [item, month1Price] = _.split(operand, '.');
        return valuePath === `${item}.${month1Price}`;
      }

      return valuePath === operand;
    });

    return type === PipeDefinitionUtility.getPipeConfigDefaults('currentCandle').type
      && config.candleInterval === '1 minutes' && isValuePathAlreadyPresent;
  });

  if (!_.isEmpty(validPipeConfig)) { // already present config
    const config = _.head(validPipeConfig);
    const { name } = config;
    return { operand: `${name}.close`, updatedPipeConfigs: [] };
  }

  const newCurrentCandlePipeConfig = PipeDefinitionUtility.getPipeConfigDefaults('currentCandle');
  const { config } = newCurrentCandlePipeConfig;
  const { valuePaths } = config;

  const updatedValuePaths = _.map(valuePaths, () => {
    if (isFutureClose) {
      const [item, month1Price] = _.split(operand, '.');
      return `${item}.${month1Price}`;
    }

    return operand;
  });

  const updatedName = getPipeName(operand);
  const updatedCurrentCandlePipeConfig = {
    ...newCurrentCandlePipeConfig,
    name: updatedName,
    config: { ...config, valuePaths: updatedValuePaths, candleInterval: '1 minutes' }
  };

  return { operand: `${updatedName}.close`, updatedPipeConfigs: [updatedCurrentCandlePipeConfig] };
};

const getValidatedOperand = (operand, newPipeConfigs) => {
  if (!isIncludeEquityOrFuture(operand)) return { operand, updatedPipeConfigs: [] };

  return { ...onAddNewConfigs(operand, newPipeConfigs) };
};

export const getSignalSuggestionConfig = (pipeConfigs, signalConfigs) => {
  let newPipeConfigs = pipeConfigs;

  const updatedSignalFields = _.map(signalConfigs, (signalConfig) => {
    const { leftOperand, rightOperand } = signalConfig;
    const {
      operand: newLeftOperand,
      updatedPipeConfigs: leftPipeConfigs
    } = getValidatedOperand(leftOperand, newPipeConfigs);
    newPipeConfigs = [...newPipeConfigs, ...leftPipeConfigs];

    const {
      operand: newRightOperand,
      updatedPipeConfigs: rightPipeConfigs
    } = getValidatedOperand(rightOperand, newPipeConfigs);
    newPipeConfigs = [...newPipeConfigs, ...rightPipeConfigs];

    return {
      ...signalConfig,
      leftOperand: newLeftOperand,
      rightOperand: newRightOperand
    };
  });

  return {
    updatedSignalFields,
    isUpdatePipe: true,
    newPipeConfigs
  };
};
