import React, { useState } from 'react';
import { runPropTypes, userPropTypes } from 'common/propTypes';
import DateSelector from 'common/components/DateSelector';
import moment from 'moment';
import { RUN_UNFINISHED_STATUSES } from 'common/configs/runStatus';
import ReactHtmlParser from 'react-html-parser';
import { updateTradeSkipDates } from 'common/api/run';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const propTypes = {
  run: runPropTypes.isRequired,
  currentUser: userPropTypes
};

const defaultProps = { currentUser: null };

const TradeSkipDates = ({ run, currentUser }) => {
  const [isShowPicker, setIsShowPicker] = useState(false);
  const toDateFormat = (newDate) => moment(newDate).toDate();

  const { trade_skip_start_date: tradeSkipStartDate, trade_skip_end_date: tradeSkipEndDate } = run;
  const [startDate, setStartDate] = useState(tradeSkipStartDate);
  const [endDate, setEndDate] = useState(tradeSkipEndDate);
  const startCalenderDate = new Date();
  const endCalenderDate = new Date();
  endCalenderDate.setDate(endCalenderDate.getDate() + 30);
  const format = 'YYYY-MM-DD HH:mm';
  const {
    quant_config: quantConfig, user_id: userId, status, id: runId, strategy_id: strategyId
  } = run;
  const currentUserId = _.get(currentUser, 'id', null);
  const isEmptyQuantConfig = _.isEmpty(quantConfig);

  if (currentUserId !== userId
    || !_.includes(RUN_UNFINISHED_STATUSES, status)
  ) {
    return null;
  }

  let contentString = '';

  const { publishInMarketPlace } = window.userPermissions;
  const isEnabled = _.get(publishInMarketPlace, 'isEnabled', false);

  if (isEmptyQuantConfig) {
    if (startDate || endDate) {
      contentString += 'Due to some reason, Strategy owner has skiped the order between';
      contentString += ` <b>${startDate ? moment(startDate).format(format) : '-'}</b> to `;
      contentString += `<b>${endDate ? moment(endDate).format(format) : '-'}</b> `;
    }
    contentString += '';
  } else if (isEnabled) {
    contentString += 'Do you want to skip the trade ? Please choose the date range';
    contentString += ` <b>${startDate ? moment(startDate).format(format) : '-'}</b> to `;
    contentString += `<b>${endDate ? moment(endDate).format(format) : '-'}</b> `;
  }

  const onUpdateRun = (params) => {
    updateTradeSkipDates(params, runId).then(() => {
      const growlStatus = strategyId ? 'Dependency runs also applied skip dates successfully' : '';
      reactToastify(`Trade Skip Dates Updates Successfully, ${growlStatus}`);
    }).catch(() => { reactToastify('Something went wrong', TOASTIFY_TYPES.ERROR); });
    setIsShowPicker(false);
  };

  if (!contentString) return null;
  const isShowClearDate = startDate || endDate;

  return (
    <div className="alert alert-secondary trade-skip-date">
      <span className="mr-2">
        {ReactHtmlParser(contentString)}
      </span>
      {!isEmptyQuantConfig && (
        <>
          {isShowClearDate && (
            <button
              className="btn btn-link text-danger btn-sm pl-0"
              type="button"
              data-track-category="Trade Skip"
              data-track-action="Clear Trade Skip"
              data-track-label="Clear Trade Skip"
              onClick={() => {
                setStartDate(null);
                setEndDate(null);
                onUpdateRun({ trade_skip_start_date: null, trade_skip_end_date: null });
              }}
            >
              <u>Clear Date</u>
            </button>
          )}

          <button
            className="btn btn-link btn-sm pl-0 font-weight-semi"
            type="button"
            data-track-category="Trade Skip"
            data-track-action="Change Trade Skip Date"
            data-track-label="Change Trade Skip Date"
            onClick={() => {
              setIsShowPicker(!isShowPicker);
              if (!startDate) setStartDate(moment().format(format));
              if (!endDate) setEndDate(moment().format(format));
            }}
          >
            <u>Change trade skip range</u>
          </button>
        </>
      )}
      {isShowPicker
        && (
          <div className="d-flex align-items-center gap-15 mt-2 flex-wrap">
            <div>
              <label className="col-form-label col-form-label-sm mb-0 mr-1">Start date</label>
              <DateSelector
                selectedDate={startDate ? toDateFormat(startDate) : new Date()}
                onChangeDate={(value) => {
                  setStartDate(value ? moment(value).format(format) : null);
                }}
                minDate={startCalenderDate}
                maxDate={endCalenderDate}
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
              />
            </div>
            <div>
              <label className="col-form-label col-form-label-sm mb-0 mr-1">End date</label>
              <DateSelector
                selectedDate={endDate ? toDateFormat(endDate) : new Date()}
                onChangeDate={(value) => {
                  setEndDate(value ? moment(value).format(format) : null);
                }}
                minDate={startCalenderDate}
                maxDate={endCalenderDate}
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
              />
            </div>
            <button
              className="btn btn-sm btn-outline-primary align-self-end"
              type="button"
              data-track-category="Trade Skip"
              data-track-action="Update Trade Skip Date"
              data-track-label="Update Trade Skip Date"
              onClick={() => onUpdateRun({ trade_skip_start_date: startDate, trade_skip_end_date: endDate })}
            >
              Update Range
            </button>
          </div>
        )}
    </div>
  );
};

TradeSkipDates.propTypes = propTypes;
TradeSkipDates.defaultProps = defaultProps;

export default TradeSkipDates;
