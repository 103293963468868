import React from 'react';

const RenderQAndA = ({
  // eslint-disable-next-line react/prop-types
  index, question, expanded, children
}) => {
  return (
    <div className="card">
      <div className="card-header" id={`heading-${index}`}>
        <h5 className="mb-0">
          <button
            className="btn"
            type="button"
            data-toggle="collapse"
            data-target={`#collapse-${index}`}
            aria-expanded={expanded ? 'true' : 'false'}
            aria-controls={`collapse-${index}`}
          >
            <span>{question}</span>
            <div className="arrow-accordion" />
          </button>
        </h5>
      </div>
      <div
        id={`collapse-${index}`}
        className={`collapse ${expanded ? ' show' : ''}`}
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body">
          {children}
        </div>
      </div>
    </div>
  );
};

const Faq = () => {
  const oneIndentation = ' ';

  return (
    <div className="faq-section section overflow-hidden bg-white" id="faq">
      <div className="container">
        <h2 className="text-center mb-3 mb-md-5">Frequently Asked Questions</h2>
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="faq-accordion" id="accordion">
              <RenderQAndA
                expanded
                question="Steps required for broker order integration in live deployment?"
                index="one"
              >
                <ul className="list-unstyled list">
                  <li>
                    Login into
                    {oneIndentation}
                    <b> QuantMan </b>
                    {oneIndentation}
                    via your broker.
                    {oneIndentation}
                  </li>
                  <li>
                    Create a live deployment to place an order and verify it in your broker terminal.
                  </li>

                  <li>
                    <b>NOTE : </b>
                    {oneIndentation}
                    You must log in daily to QuantMan via the same broker account
                    for orders that need to be executed from QuantMan on that day.
                  </li>
                </ul>
              </RenderQAndA>
              <RenderQAndA
                question="How does live deployment work?"
                index="two"
              >
                <ul className="list-unstyled list">
                  <li>
                    <div>
                      Once you create a live deployment, it will monitor your entry signals continuosly.
                      <br />

                      <ol className="my-3 pt-0">
                        <li>
                          Once an entry signal is met,
                          it will place the entry orders.
                        </li>
                        <li>
                          To utilize the margin effectively, we place option buy orders
                          before option sell orders if you choose the wait and trade execution.
                        </li>
                        <li>
                          Stop loss orders are placed with the brokers
                          when individual stop loss are provided for the leg.
                        </li>
                        <li>
                          <div>
                            After entering into a transaction, live deployment will continuously check for

                            <ol className="pl-0 my-4 ml-4 pt-0">
                              <li>exit condition</li>
                              <li>
                                trading-time / expiry-condition
                                {oneIndentation}
                                <b>(trade during 10:00AM - 03:10PM)</b>
                              </li>
                              <li>Overall stop loss</li>
                            </ol>
                          </div>
                        </li>
                        <li>
                          If any-of the above conditions are met,
                          it will place orders to close the entered positions.
                        </li>
                      </ol>
                    </div>
                  </li>
                </ul>
              </RenderQAndA>
              <RenderQAndA
                question="Can I manually close the positions taken by live deployment?"
                index="three"
              >
                <ul className="list-unstyled list">
                  <li>
                    <b>
                      Please stop the live deployment and close the positions manually.
                    </b>
                    {' '}
                    The system will try to close the position
                    when an exit signal condition is met even if it is closed in the broker terminal.
                  </li>
                </ul>
              </RenderQAndA>
              <RenderQAndA
                question="Do I need to login daily for broker order execution in live deployments?"
                index="four"
              >
                <ul className="list-unstyled list">
                  <li>
                    <div>
                      Yes. The broker sessions are
                      {' '}
                      <strong>valid only for the day.</strong>
                      {' '}
                      And you must log in to QuantMan every day for the execution
                      to successfully go through for that day.
                    </div>
                  </li>
                </ul>
              </RenderQAndA>
              <RenderQAndA
                question="Can I expect exact returns in live deployment based on backtest?"
                index="five"
              >
                <ul className="list-unstyled list">
                  <li>
                    Backtesting and Past Performance is not necessary indication of future profits.
                    Substantial deviations from past performance are possible based on volatility,
                    slippages and market conditions.
                  </li>
                  <li>
                    Backtesting is a tool to verify strategies on past data.
                  </li>
                  <li>
                    The bullish/bearish nature of the market, global events, government policies,
                    economics, and other fundamentals must be taken into consideration
                    during the interpretation of backtest metrics.
                  </li>
                  <li>
                    A directional strategy in a bullish market that made profit
                    will not make a profit or the same returns in a bearish market.
                  </li>
                </ul>
              </RenderQAndA>
              <RenderQAndA
                question="Can I use multiple broker accounts with QuantMan?"
                index="six"
              >
                <ul className="list-unstyled list">
                  <li>
                    No, for the sake of simplicity,
                    we support just one broker login for each QuantMan account.
                  </li>
                  <li>
                    Incase you want to use QuantMan with a different broker than what is in use currently,
                    you can migrate your existing data/credits/payments to a new broker login in QuantMan.
                    Please contact support (email or use the feedback button on the right side).
                    The custom strategies, run metrics and credits will be completely
                    moved out of your old account.
                  </li>
                </ul>
              </RenderQAndA>
            </div>
            <div className="text-right">

              <a
                className="d-flex align-items-center font-weight-bold btn btn-link btn-lg"
                href="/faq"
                rel="noreferrer"
                target="_blank"
              >
                More FAQ
                <span className="ml-1 material-icons-outlined">trending_flat</span>
              </a>
            </div>
            {/* <div className="wow animate__fadeInUp animation"
            data-wow-delay="0.4s" data-wow-duration="2000">
            <iframe
              className="video"
              src="https://www.youtube.com/embed/73yO8NigrsU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
