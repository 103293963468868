// Vendor imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Project imports
import Table from 'common/components/Table';
import Money from 'common/components/MoneyDisplay';
import moment from 'moment';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { getOpenOrdersData } from './helper';

const propTypes = {
  onOrderReceived: PropTypes.func.isRequired,
  liveOrdersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
const defaultProps = {};

const renderOrderDetails = (orderDetails) => {
  if (_.isEmpty(orderDetails)) return null;

  const {
    isLiquid, transactionType, price, timestamp
  } = orderDetails;

  const [date, time] = _.split(timestamp, 'T');
  const [timeZone] = _.split(time, 'Z');

  return (
    <div className="d-flex">
      {!isLiquid && <i className="mr-1 illiquid tx-12 material-icons-outlined">star</i>}
      &nbsp;
      {transactionType}
      <span className="ml-2 font-weight-semi"><Money>{price}</Money></span>
      &nbsp;
      {moment(`${date} ${timeZone}`).fromNow()}
    </div>
  );
};

const columns = [
  { Header: 'Trade Symbol', accessor: 'tradeSymbol' },
  { Header: 'Qty', accessor: 'quantity' },
  { Header: 'Entry', accessor: ({ entry }) => renderOrderDetails(entry), },
  { Header: 'Exit', accessor: ({ exit }) => renderOrderDetails(exit) }
];

const OpenTransactionsCard = ({ liveOrdersData, onOrderReceived }) => {
  const [openOrdersData, setOpenOrdersData] = useState([]);
  const hasIlliquidOrder = _.some(liveOrdersData, ['isLiquid', false]);

  useEffect(() => {
    setOpenOrdersData(getOpenOrdersData(openOrdersData, liveOrdersData));
    onOrderReceived();
  }, [liveOrdersData]);

  if (_.isEmpty(openOrdersData)) return null;

  return (
    <div className="mt-2 p-2">
      {hasIlliquidOrder && (
        <div className="float-right">
          <i className="mr-1 illiquid tx-12 material-icons-outlined">star</i>
          Illiquid
        </div>
      )}

      <div className="table-responsive border rounded">
        <Table data={openOrdersData} columns={columns} />
      </div>
    </div>
  );
};

export default withErrorBoundary(OpenTransactionsCard, '', false);
OpenTransactionsCard.propTypes = propTypes;
OpenTransactionsCard.defaultProps = defaultProps;
