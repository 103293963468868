import React, {
  useCallback, useContext, useMemo
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import withIntentsContext, { IntentsContext } from 'v2/modules/withRunForm/hoc/withIntentsContext';
import { INTENT_TYPES, SL_TRAIL_TYPES } from 'v2/common/constants/index';
import Intent from './Intent/index';
import IntentHeaders from './IntentHeaders/index';

const propTypes = {
  renderMoveToCostCheckbox: PropTypes.func,
};

const defaultProps = {
  renderMoveToCostCheckbox: () => null,
};

const Intents = ({
  renderMoveToCostCheckbox
}) => {
  const {
    intents,
    onAddIntent,
    isAdjustmentIntent,
    errorMessage,
    isPipeIntent,
  } = useContext(IntentsContext);
  const renderMoveToCost = renderMoveToCostCheckbox();

  const renderIntent = useCallback((intent, idx) => {
    const key = `${intent.type}${intent?.identifier?.label || idx}`;

    return (
      <Intent intent={intent} key={key} />
    );
  }, []);

  const addLegClassName = classNames(
    'add-leg-container d-flex justify-content-between align-items-center py-2'
  );

  const isBookProfitTsl = useMemo(() => {
    return intents.some((intent) => {
      return (
        intent.type === INTENT_TYPES.OpenLeg
        && intent?.stopLoss?.trail?.type === SL_TRAIL_TYPES.BookAndLinear
      );
    });
  }, [intents]);

  const renderIntentHeaders = useMemo(() => {
    return (
      <IntentHeaders
        isPipeIntent={isPipeIntent}
        isBookProfitTsl={isBookProfitTsl}
      />
    );
  }, [isPipeIntent, isBookProfitTsl]);

  return (
    <div className="intents-container">
      {!isAdjustmentIntent && renderIntentHeaders}
      {_.map(intents, renderIntent)}

      <div className={addLegClassName}>
        {renderMoveToCost}
        <ErrorMesssage message={errorMessage} />
        <button
          className="
            custom_outline__btn tx-12
            font-weight-semi d-flex
            align-items-center"
          type="button"
          onClick={onAddIntent}
        >
          <i className="material-icons-outlined tx-14 mr-1">library_add</i>
          {isAdjustmentIntent ? 'Add/Modify Leg' : 'Add Leg'}
        </button>
      </div>
    </div>
  );
};

Intents.propTypes = propTypes;
Intents.defaultProps = defaultProps;

export default withIntentsContext(Intents);
