import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PaginationFooter from 'common/components/PaginationFooter/index';
import { getDisplayRows } from '../helper';
import TableBody from './TableBody';

const propTypes = {
  userAndStrategyDetails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  strategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const defaultProps = {};

const UserDetails = ({ userAndStrategyDetails, strategies }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const userAndStrategyDetailsToDisplay = getDisplayRows(userAndStrategyDetails, currentPageIndex, 20);

  const renderUserAndStrategyDetail = (userAndStrategyDetail, idx) => {
    return (
      <TableBody userAndStrategyDetail={userAndStrategyDetail} strategies={strategies} key={idx} />
    );
  };

  if (_.isEmpty(userAndStrategyDetails)) {
    return <div className="invalid-feedback d-block text-center">No Users Present</div>;
  }

  return (
    <div>
      <div className="table-responsive mt-3">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Edit</th>
              <th>Broker Name</th>
              <th>Broker Client Name</th>
              <th>Broker Client Id</th>
              <th>Mail Id</th>
              <th>Mobile Number</th>
              <th>Created At</th>
              <th>Permitted Strategies</th>
            </tr>
          </thead>
          <tbody>
            {_.map(userAndStrategyDetailsToDisplay, renderUserAndStrategyDetail)}
          </tbody>
        </table>
      </div>
      <div className="mt-2">
        <PaginationFooter
          total={userAndStrategyDetails}
          currentPageIndex={currentPageIndex}
          perPage={20}
          onCurrentPageChange={(paramPageIndex) => setCurrentPageIndex(paramPageIndex)}
        />
      </div>
    </div>
  );
};

UserDetails.propTypes = propTypes;
UserDetails.defaultProps = defaultProps;

export default UserDetails;
