import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { getQuotaConsumption } from 'common/utils/InstrumentUtils';
import HoverMessage from 'common/components/HoverMessage';

const propTypes = {
  runType: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  instrumentGroups: PropTypes.arrayOf(PropTypes.array),
  cases: PropTypes.arrayOf(PropTypes.object),
  isConsumed: PropTypes.bool
};

const defaultProps = {
  startDate: '',
  endDate: '',
  instrumentGroups: [],
  isConsumed: false,
  cases: []
};

const CreditDetails = ({
  startDate, endDate, runType, instrumentGroups, isConsumed, cases, granularity
}) => {
  const oneIndentation = ' ';
  const { requiredQuota, quotaBreakout } = getQuotaConsumption(
    runType, startDate, endDate, granularity, instrumentGroups, cases
  );

  if (!requiredQuota) return null;

  const {
    numberOfInstruments, years, numberOfCases, numberOfCreditsForGranularitySecond
  } = quotaBreakout;

  const breakOutcontent = ''
    + `${numberOfInstruments} instrument(s)`
    + `${!_.isUndefined(years) ? ` x ${years} year(s)` : ''}`
    + ` x ${numberOfCases} case(s) `
    + `${numberOfCreditsForGranularitySecond > 1
      ? `<br> x ${numberOfCreditsForGranularitySecond} (For Second-wise backtest) ` : ''}`;

  return (
    <HoverMessage title="Credits Breakout" message={ReactHtmlParser(breakOutcontent)}>
      <div className="
      credit-details alert-info master-domain-only px-2 py-2 cursor-pointer text-center mb-2 mr-2"
      >
        <div className="d-flex align-items-center justify-content-center">
          <i className="material-icons tx-15 mr-1">help_outline</i>
          <span>
            {isConsumed ? 'Consumed' : 'Required'}
            {oneIndentation}
            Credits:
            {oneIndentation}
            <b>{requiredQuota}</b>
          </span>
        </div>
      </div>
    </HoverMessage>
  );
};

CreditDetails.propTypes = propTypes;
CreditDetails.defaultProps = defaultProps;

export default CreditDetails;
