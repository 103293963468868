import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { RUN_TYPES } from 'v2/common/constants/index';
import RunType from './RunType';
import OrderType from './OrderType';
import RunName from './RunName';
import Folder from './Folder';
import GranularitySelector from './GranularitySelector';
import HistoricDateRangePicker from './HistoricDateRangePicker/index';

const RunFormHeader = () => {
  const runType = useSelector((state) => getSelectorBasedStoreValue(state, 'runType'));
  const isLive = useMemo(() => runType === RUN_TYPES.live, [runType]);
  const isSecondBySecondEnabledForUser = _.get(
    window, 'SA_FEATURE_FLAGS.isRunGranularitySecondEnabled', false
  ) || window?.isAdmin;

  const shouldShowGranularity = !isLive && isSecondBySecondEnabledForUser;

  return (
    <div className="card-wrapper p-4 pt-5 position-relative">
      <RunType />
      <div className="row">
        {isLive ? <div className="col-sm mb-3 mb-md-0"><OrderType /></div> : (
          <>
            <div className="col-sm mb-3 mb-md-0"><HistoricDateRangePicker /></div>
            <div className="col-sm mb-3 mb-md-0"><HistoricDateRangePicker isEndDate /></div>
          </>
        )}
        <div className="col-sm mb-3 mb-md-0"><RunName /></div>
        <div className="col-sm mb-3 mb-md-0"><Folder /></div>
        {shouldShowGranularity && <div className="col-sm mb-3"><GranularitySelector /></div>}
      </div>
    </div>
  );
};

export default memo(RunFormHeader);
