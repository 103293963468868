import React from 'react';
import { planPropTypes } from 'common/propTypes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { applyCredits } from 'ui/Plans/helper';
import PaymentMoneyDisplay from '../common/PaymentMoneyDisplay';
import PlanButton from '../common/PlanButton';

const propTypes = {
  plan: planPropTypes.isRequired,
  activePlanId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  couponConfig: PropTypes.shape({ code: PropTypes.string }),
  activePlanName: PropTypes.string.isRequired,
  isSubscriptionActive: PropTypes.bool.isRequired,
  credits: PropTypes.number,
  couponCode: PropTypes.string.isRequired,
  onChangeCouponCode: PropTypes.func.isRequired,
  isApplied: PropTypes.bool.isRequired,
  onCouponUpdate: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  planCycleType: PropTypes.string.isRequired,
  setSelectedPlanId: PropTypes.func.isRequired,
  isCreditApplied: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
};

const defaultProps = {
  couponConfig: {},
  activePlanId: null,
  credits: 0,
};

const PlanCard = ({
  plan, couponConfig, activePlanId, activePlanName,
  isSubscriptionActive, credits, isCreditApplied, isActive, setSelectedPlanId,
  couponCode, onChangeCouponCode, isApplied, onCouponUpdate, message, planCycleType
}) => {
  const {
    plan_name: planName, price: actualPrice, currentNetPrice, strike_price: strikePrice,
    cycle_duration_in_months: duration, id: planId
  } = plan;

  const planCardClassName = classNames('plan-card-mobile', {
    active: isActive,
  });

  const renderPlanButton = (
    <PlanButton
      plan={plan}
      couponConfig={couponConfig}
      activePlanId={activePlanId}
      activePlanName={activePlanName}
      isSubscriptionActive={isSubscriptionActive}
      isCreditApplied={isCreditApplied}
      couponCode={couponCode}
      onChangeCouponCode={onChangeCouponCode}
      isApplied={isApplied}
      isHideIcon
      onCouponUpdate={onCouponUpdate}
      message={message}
    />
  );

  return (
    <div className={planCardClassName} onClick={() => setSelectedPlanId(planId)}>
      <p>{_.startCase(planName)}</p>
      <h5 className={`plan-amount-wrapper ${isActive ? 'active' : ''}`}>
        <PaymentMoneyDisplay
          price={actualPrice}
          currentNetPrice={applyCredits(currentNetPrice, credits)}
          strikePrice={strikePrice}
          cycleDurationInMonths={duration}
        />
      </h5>
      <div className="d-flex billed-gst">
        <p>
          (Billed
          {' '}
          {_.startCase(planCycleType)}
          )
        </p>
        <p>Excluding GST</p>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {renderPlanButton}
        <div className="Checkbox">
          <input type="checkbox" checked={isActive} onChange={() => setSelectedPlanId(planId)} />
          <div className="Checkbox-visible" />
        </div>
      </div>
    </div>
  );
};

PlanCard.propTypes = propTypes;
PlanCard.defaultProps = defaultProps;

export default PlanCard;
