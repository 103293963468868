import React from 'react';
import PropTypes from 'prop-types';
import { outputSchemaPropTypes } from 'common/propTypes';
import {
  getAdjustmentActionConfigsWithoutAutoAdjustment,
  getAdjustmentSignalConfigsWithoutAutoAdjustment
} from
  // eslint-disable-next-line max-len
  'modules/TransactionsBuilder/NewTransactionBuilder/components/AdvancedFeaturesEnablement/AdjustmentAdaptor/index';
import { advancedFeatureConfigs } from 'modules/QuantBuilder/config/signalConfigs';
import ViewConfigSentences from './ViewConfigSentences';

const propTypes = {
  orderConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionsExpiryCycle: PropTypes.string.isRequired,
  hasMultipleInstruments: PropTypes.bool.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired
};

const defaultProps = {};

const ViewConfigConditions = ({
  orderConfigs, optionsExpiryCycle, hasMultipleInstruments, outputSchema
}) => {
  const renderConditions = (configs, index) => {
    const entrySignalConfigs = _.get(configs, 'entrySignalConfigs');
    const exitSignalConfigs = _.get(configs, 'exitSignalConfigs');
    const transactionConfigs = _.get(configs, 'transactionConfigs');
    const adjustmentActionConfigs = getAdjustmentActionConfigsWithoutAutoAdjustment(
      _.get(configs, 'adjustmentActionConfigs', [])
    );
    const adjustmentSignalConfigs = getAdjustmentSignalConfigsWithoutAutoAdjustment(
      _.get(configs, 'adjustmentSignalConfigs', [])
    );
    const adjustmentTransactionTitle = 'Adjustment Transaction';
    const adjustmentSignalTitle = 'Adjustment When';
    const featureConfigs = _.get(configs, 'advancedFeatureConfigs', advancedFeatureConfigs());

    const {
      isMoveToCost
    } = featureConfigs;
    return (
      <div key={index} className="d-flex flex-column gap-10 conditions-row">
        <ViewConfigSentences
          title="Entry When"
          isSignal
          config={entrySignalConfigs}
          hasMultipleInstruments={hasMultipleInstruments}
          outputSchema={outputSchema}
        />
        <ViewConfigSentences
          title="Exit When"
          isSignal
          config={exitSignalConfigs}
          hasMultipleInstruments={hasMultipleInstruments}
          outputSchema={outputSchema}
        />
        <ViewConfigSentences
          title="Transaction"
          isSignal={false}
          config={transactionConfigs}
          hasMultipleInstruments={hasMultipleInstruments}
          outputSchema={outputSchema}
        />
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-10">
            <div className="d-flex align-items-center flex-wrap tx-12">
              {isMoveToCost && (
                <div className="alert alert-primary p-1 mb-0 d-flex align-items-center font-weight-medium">
                  <span className="material-icons-outlined mr-1 tx-12">star</span>
                  Move to Cost Enabled
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          {_.map(adjustmentSignalConfigs, (adjustmentSignalConfig, idx) => {
            return (
              <ViewConfigSentences
                key={idx}
                title={_.size(adjustmentSignalConfigs) !== 1
                  ? `${adjustmentSignalTitle} ${idx + 1}` : adjustmentSignalTitle}
                isSignal
                config={adjustmentSignalConfig}
                hasMultipleInstruments={hasMultipleInstruments}
                outputSchema={outputSchema}
              />
            );
          })}
          {_.map(adjustmentActionConfigs, (adjustmentActionConfig, idx) => {
            return (
              <ViewConfigSentences
                key={idx}
                title={_.size(adjustmentActionConfigs) !== 1
                  ? `${adjustmentTransactionTitle} ${idx + 1}` : adjustmentTransactionTitle}
                isSignal={false}
                config={adjustmentActionConfig}
                hasMultipleInstruments={hasMultipleInstruments}
                outputSchema={outputSchema}
              />
            );
          })}
        </div>
        <div className="row align-items-center view-config-conditions">
          <div className="col-sm-2">
            <label className="col-form-label col-form-label-sm">Expiry Cycle</label>
          </div>
          <div
            className="col-sm-10 d-flex justify-content-between align-items-center"
          >
            <samp>{_.startCase(optionsExpiryCycle)}</samp>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="m-0 font-weight-medium">Conditions</h5>
      </div>

      <div className="card-body">
        {_.map(orderConfigs, renderConditions)}
      </div>
    </div>
  );
};

ViewConfigConditions.propTypes = propTypes;
ViewConfigConditions.defaultProps = defaultProps;

export default ViewConfigConditions;
