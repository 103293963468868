import { STOPS_VALUE_TYPES } from 'v2/common/constants/index';

export const getTypeBasedValue = (type) => {
  switch (type) {
    case STOPS_VALUE_TYPES.Amount:
      return 'lossAmount';
    case STOPS_VALUE_TYPES.Points:
      return 'lossPoints';
    default:
      return 'lossPercentage';
  }
};

export const buildLegProperty = (identifier, property) => {
  const {
    instrumentIndex,
    segment,
    adjustmentConfigIndex,
    optionConfigIndex
  } = identifier;

  let locator = segment;

  if (optionConfigIndex !== null) locator += `_${optionConfigIndex}`;
  if (adjustmentConfigIndex !== null) locator += `_${adjustmentConfigIndex}`;

  return `ordersExtra.${instrumentIndex}.${locator}.${property}`;
};
