import React, { useMemo } from 'react';
import V2RunForm from 'v2/ui/run/RunForm/index';
import qs from 'qs';
import { defaultRunFormProps, runFormPropTypes } from './runFormPropTypes';
import Version1 from './Version1';

const propTypes = runFormPropTypes;
const defaultProps = defaultRunFormProps;

const RunForm = (props) => {
  const queryString = window.location.search.replace(/^\?/, '');
  const parsedQueryString = qs.parse(queryString, { parseBooleans: true });
  const queryStringParams = _.keys(parsedQueryString);
  const formVersion = useMemo(() => {
    return (_.includes(queryStringParams, 'version') && parsedQueryString?.version === 'v1') ? 1 : 2;
  }, [queryStringParams, parsedQueryString]);

  if (formVersion === 1) {
    return <Version1 {...props} />;
  }

  return <V2RunForm {...props} />;
};

RunForm.propTypes = propTypes;
RunForm.defaultProps = defaultProps;

export default RunForm;
