import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setEntryOrderModification, setEntryOrderType, setExitOrderModification, setExitOrderType
} from 'modules/QuantBuilder/actions';
import { orderModificationPropTypes, validatorProptypes } from 'common/propTypes';
import { getOrderModificationEmptyConfig, QUANT_CONFIG_FIELDS } from 'modules/QuantBuilder/config/index';
import { getValidationError } from 'common/utils/validators';
import OrderTypeConfiguration from './OrderTypeConfiguration';

const propTypes = {
  entryOrderType: PropTypes.string.isRequired,
  exitOrderType: PropTypes.string.isRequired,
  dispatchSetEntryOrderType: PropTypes.func.isRequired,
  dispatchSetExitOrderType: PropTypes.func.isRequired,
  validatorErrors: validatorProptypes.isRequired,
  entryOrderModification: orderModificationPropTypes.isRequired,
  exitOrderModification: orderModificationPropTypes.isRequired,
  dispatchEntryOrderModification: PropTypes.func.isRequired,
  dispatchExitOrderModification: PropTypes.func.isRequired,
};
const defaultProps = {};

const getErrors = (validatorErrors) => {
  const { entryOrderModification, exitOrderModification } = QUANT_CONFIG_FIELDS;

  return []
    .concat(getValidationError(validatorErrors, entryOrderModification))
    .concat(getValidationError(validatorErrors, exitOrderModification));
};

const OrderType = (props) => {
  const {
    entryOrderType, exitOrderType, dispatchSetEntryOrderType, dispatchSetExitOrderType,
    entryOrderModification, exitOrderModification,
    dispatchEntryOrderModification, dispatchExitOrderModification, validatorErrors,
  } = props;
  const errors = getErrors(validatorErrors);

  useEffect(() => { // newly implemented, old runs don't have those config so i attached to quantConfig.
    if (!_.keys(entryOrderModification).length && entryOrderType !== 'market') {
      dispatchEntryOrderModification(getOrderModificationEmptyConfig);
    }
    if (!_.keys(exitOrderModification).length && exitOrderType !== 'market') {
      dispatchExitOrderModification(getOrderModificationEmptyConfig);
    }
  }, [entryOrderType, exitOrderType]);

  return (
    <>
      <OrderTypeConfiguration
        entryOrderModification={entryOrderModification}
        exitOrderModification={exitOrderModification}
        onChangeEntryOrderType={dispatchSetEntryOrderType}
        onChangeExitOrderType={dispatchSetExitOrderType}
        isShowEntryOrderType={_.keys(entryOrderModification).length !== 0}
        isShowExitOrderType={_.keys(exitOrderModification).length !== 0}
        validatorErrors={validatorErrors}
        onChangeEntryOrderModification={dispatchEntryOrderModification}
        onChangeExitOrderMofication={dispatchExitOrderModification}
        entryOrderType={entryOrderType}
        exitOrderType={exitOrderType}
        errors={errors}
      />
    </>
  );
};

OrderType.propTypes = propTypes;
OrderType.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  entryOrderType: _.get(state, 'quantConfig.entryOrderType'),
  exitOrderType: _.get(state, 'quantConfig.exitOrderType'),
  validatorErrors: _.get(state, 'validatorErrors'),
  entryOrderModification: _.get(state, 'quantConfig.entryOrderModification', {}),
  exitOrderModification: _.get(state, 'quantConfig.exitOrderModification', {}),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetEntryOrderType: (newEntryOrderType) => dispatch(setEntryOrderType(newEntryOrderType)),
  dispatchSetExitOrderType: (newExitOrderType) => dispatch(setExitOrderType(newExitOrderType)),
  dispatchEntryOrderModification:
    (newEntryOrderModification) => dispatch(setEntryOrderModification(newEntryOrderModification)),
  dispatchExitOrderModification:
    (newExitOrderModification) => dispatch(setExitOrderModification(newExitOrderModification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderType);
