import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { OPTION_TYPES } from 'v2/common/constants/index';
import classNames from 'classnames';

const propTypes = {
  optionType: PropTypes.string,
  onChangeOptionType: PropTypes.func.isRequired,
};
const defaultProps = {
  optionType: '',
};

const OptionTypeSelector = ({ optionType, onChangeOptionType }) => {
  const { CE, PE } = OPTION_TYPES;
  const isCE = optionType === CE;

  const divClassName = classNames('option-type-selector', {
    'bg-success p-1': isCE,
    'bg-danger p-1': !isCE
  });

  return (
    <div
      className={divClassName}
      onClick={() => onChangeOptionType(isCE ? PE : CE)}
    >
      <label className="text-white">
        {_.startCase(optionType)}
      </label>
    </div>
  );
};

OptionTypeSelector.propTypes = propTypes;
OptionTypeSelector.defaultProps = defaultProps;

export default memo(OptionTypeSelector);
