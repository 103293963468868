// vendor import
import React from 'react';
import PropTypes from 'prop-types';

import { outputSchemaPropTypes, quantConfigPropTypes, runPropTypes } from 'common/propTypes';
import { AdvancedBuilder } from 'ui/run/RunForm/components/index';

const propTypes = {
  quantConfig: quantConfigPropTypes.isRequired,
  onQuantConfigChange: PropTypes.func.isRequired,
  run: runPropTypes.isRequired,
  outputSchema: outputSchemaPropTypes,
  parentClassName: PropTypes.string,
};

const defaultProps = {
  outputSchema: [],
  parentClassName: 'tips-form'
};

const TransactionBuilder = ({
  quantConfig, onQuantConfigChange, run, outputSchema, parentClassName
}) => {
  const segment = _.get(quantConfig, 'segment');
  const { order_type: orderType, run_type: runType } = run;
  const segments = [];

  return (
    <div className={parentClassName}>
      <AdvancedBuilder
        orderType={orderType}
        isClonedQuant={false}
        runType={runType}
        onQuantConfigUpdate={(newQuantConfig) => { onQuantConfigChange(newQuantConfig); }}
        outputSchema={outputSchema}
        prebuiltId={null}
        quantConfig={quantConfig}
        quantConfigValidators={{}}
        startDate={new Date()}
        endDate={new Date()}
        validatorErrors={[]}
        segment={segment}
        segments={segments}
        onChangeSegment={(newSegment) => {
          onQuantConfigChange({ ...quantConfig, segment: newSegment });
        }}
      />
    </div>
  );
};

TransactionBuilder.propTypes = propTypes;
TransactionBuilder.defaultProps = defaultProps;

export default TransactionBuilder;
