import _ from 'lodash';
import * as pipeDefinitions from './pipeDefinitions';
import { indicatorCodeSnippets } from './snippets';

export const PIPE_CATEGORIES = {
  snippet: 'snippet',
  customScript: 'customScript'
};

export const CANDLE_INTERVALS = [
  { displayValue: '1 minute', value: '1 minutes' },
  { displayValue: '3 minutes', value: '3 minutes' },
  { displayValue: '5 minutes', value: '5 minutes' },
  { displayValue: '10 minutes', value: '10 minutes' },
  { displayValue: '15 minutes', value: '15 minutes' },
  { displayValue: '30 minutes', value: '30 minutes' },
  { displayValue: '45 minutes', value: '45 minutes' },
  { displayValue: '1 hour', value: '1 hours' },
];

const DISABLED_INDICATORS = (() => {
  const { isAdmin, SA_FEATURE_FLAGS = {} } = window;
  const list = [];
  if (!SA_FEATURE_FLAGS.isCustomScriptsEnabled) {
    list.push('customScript');
  }
  if (!isAdmin) {
    list.push('anchoredVolumeWeightedAveragePrice');
    list.push('indiaVIXPercentile');
    list.push('customScript');
  }
  return list;
})();

export const removedIndicators = [
  'augmentedDickeyFuller',
  'indiaVIXPercentile',
  'zScore',
  'densityCurve',
  'ratioStandardDeviation',
  'renkoBearishBrick',
  'renkoBullishBrick',
];

const getSanitizedPipeDefinitions = () => {
  const isIndicatorCodeSnippetsEnabled = _.get(
    window, 'SA_FEATURE_FLAGS.isIndicatorCodeSnippetEnabled', false
  );

  if (!isIndicatorCodeSnippetsEnabled) return pipeDefinitions;
  const clonedDefinitions = _.cloneDeep({ ...pipeDefinitions });
  const snippets = indicatorCodeSnippets();

  snippets.forEach((snippet) => { clonedDefinitions[snippet.type] = snippet; });
  return clonedDefinitions;
};

export const PipeDefinitionUtility = (() => {
  let definitions = getSanitizedPipeDefinitions();

  return {
    getPipeInputSchemaByType: (type) => {
      return _.get(definitions, [type, 'inputSchema'], []);
    },

    getDefinitions: () => definitions,
    setDefinitions: () => {
      definitions = getSanitizedPipeDefinitions();
    },
    getPipeConfigSearchTerms: () => {
      return _.chain(definitions)
        .reduce((acc, pipeDefinition, type) => {
          if (pipeDefinition?.isEnabled) {
            acc[type] = pipeDefinition;
          }
          return acc;
        }, {})
        .flatMap((pipeDefinition) => pipeDefinition.searchTerms.map((searchTerm) => ({
          label: _.startCase(searchTerm),
          value: pipeDefinition.type
        })))
        .filter(({ value }) => !_.includes(DISABLED_INDICATORS, value))
        .value();
    },
    getPipeConfigOptions: (pipeType) => _.get(
      definitions,
      [pipeType, 'configFormOptions', 'configFieldOptions'],
      {}
    ),
    getPipeConfigDefaults: (type) => {
      return _.cloneDeep(_.get(definitions, [type, 'defaults'], {}));
    },
    getPipeValuePathSupportedSegments: (type) => {
      return _.cloneDeep(
        _.get(definitions, [type, 'configFormOptions', 'valuePathSupportedSegments'], [])
      );
    },
    getIndicatorsWithNonEditableValuePaths: () => _.chain(definitions)
      .filter((pipe) => !pipe?.configFormOptions?.valuePathEditable)
      .map((pipe) => pipe.type)
      .value()
  };
})();

// TODO configFieldOptions
export const configDisplayNames = {
  noOfCandles: 'Period',
  valuePaths: 'Field'
};
