const instruments = {
  'NFO:SBILIFE': {
    startDate: '2020-05-04',
  },
  'NFO:AARTIIND': {
    startDate: '2021-01-01',
  },
  'NFO:BAJAJFINSV': {
    startDate: '2017-04-03',
  },
  'NFO:FINNIFTY': {
    startDate: '2022-04-01',
  },
  'NFO:MIDCPNIFTY': {
    startDate: '2023-07-01',
  },
};

export default instruments;
