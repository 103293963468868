import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RUN_FINISHED_STATUSES } from 'common/configs/runStatus';
import ForceExitConfirmation from 'common/components/ForceExitConfirmation/index';
import { OrderContext } from '../../OrderStore/index';

const propTypes = {
  status: PropTypes.string,
  isLiveRun: PropTypes.bool.isRequired,
  onForceExit: PropTypes.func.isRequired,
  isRunBelongsToUser: PropTypes.bool.isRequired,
};

const defaultProps = { status: '' };

const ForceExitBtn = ({
  status, isLiveRun, onForceExit, isRunBelongsToUser
}) => {
  const { transactions } = useContext(OrderContext);
  const lastTransaction = _.last(transactions) || {};
  const transactionAndRunId = _.get(lastTransaction, 'transactionAndRunId', null);
  const lastPairedTransactions = _.filter(transactions,
    (transaction) => _.get(transaction, 'transactionAndRunId') === transactionAndRunId);

  const isShowBtn = _.some(lastPairedTransactions, (lpt) => {
    return _.some(_.get(lpt, 'pairedOrders', []), (pairedOrder) => {
      const { isManualExitShow } = pairedOrder;
      return isManualExitShow;
    });
  });

  const isInvalidCase = !isLiveRun || !isRunBelongsToUser
    || _.includes(RUN_FINISHED_STATUSES, status) || !isShowBtn;

  if (isInvalidCase) return null;

  return (
    <ForceExitConfirmation onForceExit={onForceExit} />
  );
};

ForceExitBtn.propTypes = propTypes;
ForceExitBtn.defaultProps = defaultProps;

export default ForceExitBtn;
