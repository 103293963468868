import React, { useEffect } from 'react';
import OrderStore from 'ui/run/RunShow/OrderStore/index';
import RunDetail from 'ui/run/RunShow/components/RunDetail/index';
import TransactionDetail from 'ui/run/RunShow/components/TransactionDetail/index';
import { RUN_TYPES } from 'common/constants/index';
import { strategyPropTypes, userPropTypes } from 'common/propTypes';
import PropTypes from 'prop-types';
import subscribeChannel from 'common/api/subscribeChannel';
import RunTitleAndActions from 'ui/run/RunShow/components/RunTitleAndActions/index';

const propTypes = {
  strategy: strategyPropTypes.isRequired,
  isAccordianOpen: PropTypes.bool,
  currentUser: userPropTypes
};
const defaultProps = {
  isAccordianOpen: false,
  currentUser: null
};

const StrategyLiveRunDetails = ({ strategy, isAccordianOpen, currentUser }) => {
  const { liveJobs, liveRun, is_manual_signals_allowed: isAllowManualSignals } = strategy;
  const liveJobIds = _.map(liveJobs, 'id').sort();
  const liveRunId = _.get(liveJobs, '[0].run_id');
  const { order_type: orderType } = liveRun;
  const jobId = _.head(liveJobIds);

  useEffect(() => {
    const socketConnection = {
      channelName: 'NotificationsChannel',
      roomId: `job_${jobId}`,
      onDataReceive: () => {
        setTimeout(() => { window.location.reload(); }, 1000);
      }
    };

    subscribeChannel(socketConnection);
  }, []);

  return (
    <div className="strategy-order-store mt-3">
      <OrderStore
        runIds={[liveRunId]}
        jobIds={[liveJobIds]}
        runType={RUN_TYPES.live}
        orderType={orderType}
        run={liveRun}
      >
        <div className="marketplace-live">
          {!isAllowManualSignals && (
            <RunTitleAndActions
              run={liveRun}
              currentUser={currentUser}
              strategyDetails={{}}
            />
          )}
          <RunDetail run={liveRun} />
          <TransactionDetail run={liveRun} isAccordianOpen={isAccordianOpen} />
        </div>
      </OrderStore>
    </div>
  );
};

StrategyLiveRunDetails.propTypes = propTypes;
StrategyLiveRunDetails.defaultProps = defaultProps;

export default StrategyLiveRunDetails;
