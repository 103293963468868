import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import classNames from 'classnames';

import { signalConditionOptions } from 'modules/QuantBuilder/config/signalConfigs';
import { getDayOfWeekValidation } from 'common/utils/validators';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { getLabeledHashFromArray } from 'common/utils/displayNames';
import { rightOperandPropTypes } from 'common/propTypes';

const propTypes = {
  rightOperand: rightOperandPropTypes.isRequired,
  onRightOperandChange: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  operator: PropTypes.string.isRequired,
  operatorValidator: PropTypes.shape({}).isRequired
};
const defaultProps = {};

const DayOfWeek = (props) => {
  const {
    operator, rightOperand, onRightOperandChange, onOperatorChange, operatorValidator
  } = props;
  const { dayOfWeek: { rightOperandOptions, operatorOptions } } = signalConditionOptions();
  const { errors, control } = useFormContext();
  const [daySelectorName] = useState(_.uniqueId('daySelector-'));
  const [operatorFieldName] = useState(_.uniqueId('operator-'));
  const rightOperands = getLabeledHashFromArray(rightOperandOptions);
  const selectedRightOperand = getLabeledHashFromArray(rightOperand);
  const operatorStyle = classNames(
    'custom-select custom-select-sm',
    errors[operatorFieldName] ? 'is-invalid' : ''
  );
  const daySelectorInputStyle = classNames(errors[daySelectorName] ? 'is-invalid' : '');

  const onDayChange = (newRightOperand, onChange) => {
    const selectedDays = _.map(newRightOperand, 'id');

    onRightOperandChange(selectedDays);
    onChange(selectedDays);
  };

  const operatorsDropDownOptions = _.map(operatorOptions, (option, idx) => (
    <option value={option} key={idx}>
      {_.startCase(option)}
    </option>
  ));

  return (
    <>
      <div className="conditonsAndRightOperand">
        <Controller
          render={({ onChange, ref, name }) => (
            <Form.Control
              className={operatorStyle}
              as="select"
              ref={ref}
              name={name}
              value={operator}
              onChange={(event) => {
                onOperatorChange(event.target.value);
                onChange(event.target.value);
              }}
            >
              <option value="" key="blank" />
              {operatorsDropDownOptions}
            </Form.Control>
          )}
          control={control}
          defaultValue={operator}
          name={operatorFieldName}
          rules={operatorValidator}
        />
        <ErrorMesssage
          error={errors[operatorFieldName]}
        />
      </div>

      <div>
        <Controller
          render={({ onChange, name }) => (
            <Typeahead
              className="custom-form-control"
              inputProps={{ className: daySelectorInputStyle }}
              id={name}
              multiple
              placeholder="Can select muliple day"
              isInvalid={!!errors[name]}
              options={rightOperands}
              selected={selectedRightOperand}
              onChange={(newRightOperand) => onDayChange(newRightOperand, onChange)}
            />
          )}
          control={control}
          defaultValue={rightOperand}
          name={daySelectorName}
          rules={getDayOfWeekValidation()}
        />
        <ErrorMesssage
          error={errors[daySelectorName]}
        />
      </div>
    </>
  );
};

export default DayOfWeek;

DayOfWeek.propTypes = propTypes;
DayOfWeek.defaultProps = defaultProps;
