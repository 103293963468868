import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onRemoveOption: PropTypes.func.isRequired,
  isDisable: PropTypes.bool.isRequired
};
const defaultProps = {};

const RemoveBtn = ({ onRemoveOption, isDisable }) => {
  return (
    <button
      type="button"
      className="delete-btn"
      disabled={isDisable}
      onClick={onRemoveOption}
    >
      <i className="material-icons-outlined">delete_outline</i>
    </button>
  );
};

RemoveBtn.propTypes = propTypes;
RemoveBtn.defaultProps = defaultProps;

export default RemoveBtn;
