import { getValue, getDate } from './dataUtils';

export const getTipsyHandler = ({
  elements, xScale: argXScale, yScale, sources, margin, options
}) => {
  const { tooltip } = elements;
  // On zoom xScale will change;
  let xScale = argXScale;
  const offset = {
    left: -10,
    top: -10
  };

  const hideTooltip = () => {
    tooltip
      .transition()
      .duration(200)
      .style('opacity', 0);
  };

  return {
    update: (newXScale) => {
      xScale = newXScale;
    },
    onMouseOver: () => {
      tooltip
        .style('opacity', 1);
    },
    onMouseMove: (event) => {
      const nearestXIndex = Math.round(xScale.invert(event.layerX - margin.left));
      const nearestSeriesItem = sources[0].series[nearestXIndex];
      const value = getValue(nearestSeriesItem, options.valuePath);
      const date = getDate(nearestSeriesItem);

      if (nearestXIndex && value && date) {
        const tooltipElem = tooltip.node();
        const tooltipAnchor = {
          x: (xScale(nearestXIndex) - (tooltipElem.clientWidth / 2) + offset.left),
          y: (yScale(value) - tooltipElem.clientHeight + offset.top)
        };
        const formattedValue = Intl.NumberFormat(
          'en-IN', { style: 'currency', currency: 'INR' }
        ).format(parseFloat(value));
        const htmlContent = `
          <div class="price-info">
            Profit/Loss <br/>
            <span class="tx-16 font-weight-bold">${formattedValue}</span>
          </div>
          <div class="order-info">
            <div class="mr-2"> Date: ${d3.timeFormat('%e %b %Y')(date)} </div>
            <div>Time: ${d3.timeFormat('%I:%S %p')(date)}</div>
          </div>
        `;

        tooltip
          .style('left', `${margin.left + tooltipAnchor.x}px`)
          .style('top', `${margin.top + tooltipAnchor.y}px`);

        tooltip.select('div.text-capitalize').html(htmlContent);

        tooltip.select('div.arrow')
          .style('left', `${tooltipElem.clientWidth / 2}px`);
      }
    },
    onMouseLeave: () => {
      hideTooltip();
    },
    hideTooltip
  };
};
