import React from 'react';
import MoneyDisplay from 'common/components/MoneyDisplay';
import { handleDisabledButtonInMarketPlace } from 'ui/Strategies/helper';
import { RUN_TYPES } from 'common/constants/index';
import { quotaVerificationDom } from 'ui/QuotaVerification/quotaDom';
import { paymentInitialize } from 'common/razorpay/paymentInitialize';
import { getUrlParams } from 'common/utils/urlUtils';
import { strategyPropTypes } from 'common/propTypes';
import { initiateUserAuth } from 'common/auth/modalLogin';

const propTypes = { strategy: strategyPropTypes.isRequired };

const defaultProps = {};

const PaymentButton = ({ strategy }) => {
  const {
    id, purchase_amount: purchaseAmount, purchase_valid_month: purchaseMonth,
    live_instrument_groups: liveInstrumentGroups
  } = strategy;
  const isUserLoggedIn = _.get(window, 'isUserSignedIn', false);
  const strategyAccess = _.get(strategy, 'strategy_access', {});
  const status = _.get(strategyAccess, 'status', '');
  const paidTill = _.get(strategyAccess, 'paid_till', '');
  const isPaid = status === 'paid' && !(_.isEmpty(paidTill));

  const { isDisabled, message } = handleDisabledButtonInMarketPlace(strategy);
  const oneIndentation = ' ';

  const razorpayButton = () => {
    const args = { strategy_id: id };
    const urlParams = getUrlParams();
    const isMinifiedPage = _.get(urlParams, 'minifiedpage', 'false') === 'true';
    let reDirectUrl = `/strategies/${id}?locale=${I18n.locale}`;
    if (isMinifiedPage) { reDirectUrl += '&minifiedpage=true'; }

    if (isUserLoggedIn) {
      if (isPaid) return paymentInitialize(args, () => { window.location.href = reDirectUrl; });
      // if it paid strategy.. just renew only

      return quotaVerificationDom(liveInstrumentGroups, { run_type: RUN_TYPES.live }, (argsObj) => {
        const isReload = _.get(argsObj, 'isReload', true);
        if (isReload) window.location.href = reDirectUrl;
        else paymentInitialize(args, () => { window.location.href = reDirectUrl; });
      }, strategy);
    }

    initiateUserAuth().then(() => {
      window.location.reload();
    }).catch(() => {
      reactToastify('Unauthorized, Please Login and continue', TOASTIFY_TYPES.ERROR);
    });

    return null;
  };

  const renderButton = (
    <button
      className="payment-btn btn btn-sm btn-primary btn-block text-nowrap"
      type="button"
      data-toggle="tooltip"
      title={message}
      disabled={isDisabled}
      onClick={() => razorpayButton()}
    >
      {isPaid ? 'Extend' : 'Pay'}
      {oneIndentation}
      <MoneyDisplay>{purchaseAmount}</MoneyDisplay>
      {oneIndentation}
      <small>
        /
        {oneIndentation}
        {purchaseMonth}
        {oneIndentation}
        month
      </small>
    </button>
  );

  return renderButton;
};

PaymentButton.propTypes = propTypes;
PaymentButton.defaultProps = defaultProps;

export default PaymentButton;
