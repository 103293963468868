import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import MoneyDisplay from 'common/components/MoneyDisplay';

const summaryShape = PropTypes.shape({
  purchase_count: PropTypes.number.isRequired,
  purchase_amount: PropTypes.number.isRequired,
});

const propTypes = {
  summary: PropTypes.shape({
    coupon_purchase: summaryShape.isRequired,
    without_coupon_purchase: summaryShape.isRequired,
    quantman_coupon_purchase: summaryShape.isRequired,
    total_purchase: summaryShape.isRequired,
  }).isRequired,
};

const Summary = ({ summary }) => {
  const infoCard = (title, value) => (
    <div className="small-info-card">
      <div className="card info">
        <div className="info-wrapper">
          <div className="card-details d-flex align-items-center justify-content-between">
            <div className="left-side">
              <div className="sub-head">{title}</div>
              <div className="main-head">
                <span><MoneyDisplay isAttachCurrency={false}>{value}</MoneyDisplay></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const reportConfig = {
    coupon_purchase: {
      title: 'Referrer Coupon Purchase',
    },
    without_coupon_purchase: {
      title: 'Without Coupon Purchase',
    },
    quantman_coupon_purchase: {
      title: 'Quantman Coupon Purchase',
    },
    total_purchase: {
      title: 'Total Purchase',
    },
  };

  return (
    <div>
      {Object.entries(summary).map(([key, value]) => (
        <div className="card mb-3" key={key}>
          <h5 className="card-header">{startCase(reportConfig[key].title)}</h5>
          <div className="card-body">
            <div className="run-info account-details-card">
              {infoCard('Purchases Count', value.purchase_count)}
              {infoCard('Purchased Amount', value.purchase_amount)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

Summary.propTypes = propTypes;

export default Summary;
