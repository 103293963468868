import React, { useEffect, useState } from 'react';
import { getLinkedUsers, getPurchaseReport } from 'common/api/referrer';
import moment from 'moment';
import Filter from './Filter';
import LinkedUserList from './LinkedUserList';
import Summary from './Summary';

const Dashboard = () => {
  const today = moment().format('YYYY-MM-DD');
  const [linkedUsers, setLinkedUsers] = useState([]);
  const [summary, setSummary] = useState({});
  const [activeTab, setActiveTab] = useState('summary');
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  const fetchData = (fetchFunction, tabId) => {
    if (tabId !== activeTab) return;

    fetchFunction({ fromDate, toDate })
      .then((res) => {
        if (res.success) {
          if (tabId) setActiveTab(tabId);
          if (tabId === 'linkedUsers') {
            setLinkedUsers(res.linked_users);
          } else if (tabId === 'summary') {
            setSummary(res.report);
          }
        } else {
          console.error(res.message);
        }
      })
      .catch((error) => {
        console.error(
          `Error fetching ${
            tabId === 'linkedUsers' ? 'linked users' : 'purchase report'
          }:`,
          error
        );
      });
  };

  const onSubmitFilter = ({ fromDate: filterFromDate, toDate: filterToDate }) => {
    setFromDate(filterFromDate);
    setToDate(filterToDate);
    // console.log('Filtering data...', filterFromDate, filterToDate, fromDate, toDate);
    // fetchData(getLinkedUsers, 'linkedUsers');
    // fetchData(getPurchaseReport, 'summary');
  };

  useEffect(() => {
    fetchData(getLinkedUsers, 'linkedUsers');
    fetchData(getPurchaseReport, 'summary');
  }, [activeTab, fromDate, toDate]);

  const tabData = [
    {
      id: 'summary',
      name: 'Summary',
      component: <Summary summary={summary} />,
    },
    {
      id: 'linkedUsers',
      name: 'Linked Users',
      component: <LinkedUserList linkedUsers={linkedUsers} />,
    },
  ];

  return (
    <div>
      <h3 className="mb-2">Referrer Dashboard</h3>
      <Filter onSubmitFilter={onSubmitFilter} />
      <ul className="nav nav-tabs mb-2 mt-2">
        {tabData.map((tab, index) => (
          <li key={index} className="nav-item">
            <button
              className={`nav-link ${activeTab === tab.id ? 'active bg-primary text-light' : ''}`}
              onClick={() => setActiveTab(tab.id)}
              type="button"
            >
              {tab.name}
            </button>
          </li>
        ))}
      </ul>
      <br />
      {tabData.map((tab, index) => (
        <React.Fragment key={index}>
          {activeTab === tab.id && tab.component}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Dashboard;
