import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { STOPS_VALUE_TYPES } from 'modules/QuantBuilder/config';

const propTypes = {
  valueType: PropTypes.string.isRequired,
  onValueTypeChange: PropTypes.func.isRequired,
  isShowPrice: PropTypes.bool
};
const defaultProps = { isShowPrice: true };

const ValueTypeToggleBtn = ({ valueType, onValueTypeChange, isShowPrice }) => {
  const { PERCENT, PRICE } = STOPS_VALUE_TYPES;

  return (
    <ToggleButtonGroup
      bsPrefix="btn-group btn-group-sm btn-group-toggle"
      type="radio"
      name="group-selector"
      value={valueType}
      onChange={onValueTypeChange}
    >
      <ToggleButton bsPrefix="btn btn-sm btn-outline-primary btn-lightness" value={PERCENT}>
        %
      </ToggleButton>

      {isShowPrice && (
        <ToggleButton bsPrefix="btn btn-sm btn-outline-primary btn-lightness" value={PRICE}>
          <span>&#8377;</span>
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

ValueTypeToggleBtn.propTypes = propTypes;
ValueTypeToggleBtn.defaultProps = defaultProps;

export default ValueTypeToggleBtn;
