import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updateUser } from 'common/api/user';
import Content from './Content';
import StrategyDomainContent from './strategyDomainContent';
import { isStrategyDomainUser } from '../applicationDomainHelper';

const propTypes = {
  currentUserId: PropTypes.number,
  isTermsAndConditionsAgreed: PropTypes.bool.isRequired
};

const defaultProps = {
  currentUserId: null
};

const TermsAndConditions = ({
  currentUserId,
  isTermsAndConditionsAgreed
}) => {
  const [isAgreed] = useState(isTermsAndConditionsAgreed);

  const hasAgreeTermsAndConditions = () => {
    updateUser({ id: currentUserId, is_terms_and_conditions_agreed: true }).then(() => {
      window.location.reload();
    });
  };

  const renderBackButton = (styleClass) => {
    const aTagClassName = `d-inline-flex btn btn-outline-primary btn-sm btn-pill my-2 ${styleClass}`;

    if ((!currentUserId || (currentUserId && isAgreed))) {
      return (
        <a className={aTagClassName} href="/">
          <span className="material-icons-outlined tx-18 mr-1">arrow_back</span>
          Back
        </a>
      );
    }

    return null;
  };

  const renderButton = () => {
    if (!currentUserId) return null;
    if (isAgreed) return null;

    return (
      <button
        type="button"
        className="btn btn-sm btn-primary mt-5"
        onClick={() => hasAgreeTermsAndConditions()}
        disabled={isAgreed}
      >
        {isAgreed ? 'Agreed' : 'Agree'}
      </button>
    );
  };

  const ContentComponent = isStrategyDomainUser ? StrategyDomainContent : Content;

  return (
    <>
      {renderBackButton('mb-5')}
      <ContentComponent />
      <div className="d-flex justify-content-between">
        {renderButton()}
      </div>
    </>
  );
};

TermsAndConditions.propTypes = propTypes;
TermsAndConditions.defaultProps = defaultProps;

export default TermsAndConditions;
