export const cyclicPlansPeriods = {
  monthly: {
    period: 1,
    label: 'Monthly',
    value: 'monthly'
  },
  quarterly: {
    period: 3,
    label: 'Quarterly',
    value: 'quarterly'
  },
  yearly: {
    period: 12,
    label: 'Annually',
    value: 'yearly'
  }
};
