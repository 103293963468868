import React from 'react';
import PropTypes from 'prop-types';
import {
  orderConfigsPropTypes, outputSchemaPropTypes, pipeConfigPropTypes, pipeConfigsPropTypes
} from 'common/propTypes';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import { SIGNAL_CONFIG } from 'modules/QuantBuilder/config/signalConfigs';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import { getIndicatorBasedOnSignalConfig, suggestionCandleConfig, suggestionPipes } from './config';

const propTypes = {
  onUpdatePipeConfigs: PropTypes.func.isRequired,
  pipeConfigs: pipeConfigsPropTypes.isRequired,
  lastAddedPipeConfig: pipeConfigPropTypes,
  onEmptyLastAddedPipeConfig: PropTypes.func.isRequired,
  onUpdateOrderConfig: PropTypes.func.isRequired,
  orderConfigs: orderConfigsPropTypes.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  segment: PropTypes.string
};

const defaultProps = {
  lastAddedPipeConfig: null,
  segment: ''
};

const PipeSuggestionAdapter = ({
  pipeConfigs, onUpdatePipeConfigs, lastAddedPipeConfig, onEmptyLastAddedPipeConfig,
  onUpdateOrderConfig, orderConfigs, outputSchema, segment
}) => {
  if (!lastAddedPipeConfig) return null;
  const { type } = lastAddedPipeConfig;
  const isSuggestionShow = _.includes(suggestionPipes(), type);
  if (!isSuggestionShow) return null;

  const oneIndentation = ' ';
  const suggestionConfig = suggestionCandleConfig(
    lastAddedPipeConfig,
    PipeDefinitionUtility.getPipeConfigDefaults('currentCandle').type,
    pipeConfigs,
    segment
  );

  if (!_.keys(suggestionConfig).length) return null;

  const { config: { candleInterval }, name: suggestionName } = suggestionConfig;
  const orderConfigIndex = 0;
  const entrySignalConfigs = _.get(orderConfigs, [orderConfigIndex, SIGNAL_CONFIG.ENTRY], []);

  const { indicatorName, signalConfig } = getIndicatorBasedOnSignalConfig(
    outputSchema, lastAddedPipeConfig, entrySignalConfigs, suggestionName
  );

  const handleUpdateOrderConfig = () => {
    const newOrderConfigs = _.cloneDeep(orderConfigs);
    newOrderConfigs[orderConfigIndex] = {
      ...newOrderConfigs[orderConfigIndex],
      [SIGNAL_CONFIG.ENTRY]: [...entrySignalConfigs, signalConfig],
    };

    onUpdateOrderConfig(newOrderConfigs);
  };

  return (
    <div className="d-flex justify-content-center align-items-center my-3">
      <div className="indicator-suggestions">
        <div className="suggestions-title">
          <i className="material-icons-outlined">lightbulb</i>
          Suggestions
        </div>

        <div className="indicator-details">
          Do you want
          <span className="indicator-value">
            &quot;
            {oneIndentation}
            {candleInterval}
            {oneIndentation}
            candle close price
            {oneIndentation}
            <u className="font-weight-semi mx-1">Crosses Above</u>
            {oneIndentation}
            {indicatorName}
            &quot;
          </span>
          . Please add this indicator and Entry when
        </div>

        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm btn-md-block"
            onClick={() => {
              onUpdatePipeConfigs([...pipeConfigs, suggestionConfig]);
              onEmptyLastAddedPipeConfig();
              handleUpdateOrderConfig();
            }}
          >
            Add
          </button>
        </div>
        <span className="close-btn" onClick={() => onEmptyLastAddedPipeConfig()}>
          <i className="material-icons cursor-pointer">
            cancel
          </i>
        </span>
      </div>
    </div>
  );
};

PipeSuggestionAdapter.propTypes = propTypes;
PipeSuggestionAdapter.defaultProps = defaultProps;

export default withErrorBoundary(PipeSuggestionAdapter, '', false);
