import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  delayBy: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {
  delayBy: undefined,
};

const ONE_SECOND = 1000;

// Local value in seconds. Value in quantConfig in millis.
const DelayBy = ({ delayBy, onChange }) => {
  const [localDelayBy, setLocalDelayBy] = useState(
    _.isNumber(delayBy) ? delayBy / ONE_SECOND : delayBy
  );

  const onLocalDelayByChange = useCallback((event) => {
    const valueString = _.trim(event.target.value);
    const newDelayBy = Number(valueString);
    if (!_.isEmpty(valueString) && _.isNumber(newDelayBy)) {
      setLocalDelayBy(newDelayBy);
      onChange(newDelayBy * ONE_SECOND);
      return;
    }
    setLocalDelayBy(undefined);
    onChange(undefined);
  }, [onChange]);

  useEffect(() => {
    setLocalDelayBy(_.isNumber(delayBy) ? delayBy / ONE_SECOND : delayBy);
  }, [delayBy]);

  return (
    <div className="input-group delay-by-container">
      <input
        className="form-control"
        type="number"
        value={localDelayBy}
        onChange={onLocalDelayByChange}
      />
      <div className="input-group-append">
        <span className="input-group-text">secs</span>
      </div>
    </div>
  );
};

DelayBy.propTypes = propTypes;
DelayBy.defaultProps = defaultProps;

export default DelayBy;
