import React, { useEffect, useState } from 'react';
import { getRunningLiveRuns, onStopQuant } from 'common/api/run';
import moment from 'moment';
import { RUN_TYPES } from 'common/constants/index';
import MoneyDisplay from 'common/components/MoneyDisplay';
import InlineEdit from 'common/components/InlineEdit';
import ModalComponent from 'common/components/ModalComponent';
import PropTypes from 'prop-types';

const propTypes = {
  callbackFunc: PropTypes.func.isRequired
};

const defaultProps = {};

const RunningLiveRuns = ({ callbackFunc }) => {
  const [runningLiveRuns, setRunningLiveRuns] = useState([]);
  const [isShowModal, setIsShowModal] = useState(true);
  const [isEnableDeploy, setIsEnableDeploy] = useState(true);
  const oneIndentation = ' ';
  const [sizeOfLiveRuns, setSizeOfLiveRuns] = useState(0);
  const [reducedSizeOfLiveRuns, setReducedSizeOfLiveRuns] = useState(0);

  useEffect(() => {
    getRunningLiveRuns().then((result) => {
      setRunningLiveRuns(result.running_live_runs);
      setSizeOfLiveRuns(_.size(result.running_live_runs));
      setReducedSizeOfLiveRuns(_.size(result.running_live_runs));
    });
  }, []);

  const onStopRun = (runId) => {
    onStopQuant(runId, () => {
      setTimeout(() => {
        setRunningLiveRuns(_.filter(runningLiveRuns, ({ id }) => runId !== id));
        setReducedSizeOfLiveRuns(_.size(runningLiveRuns) - 1);
        setIsEnableDeploy(false);
      }, 500);
    });
  };

  const renderLiveRuns = (run) => {
    const {
      id,
      name,
      display_name: displayName,
      run_type: runType,
      created_at: createdAt,
      start_date: startDate,
      end_date: endDate,
      summary
    } = run;
    const profit = _.get(summary, 'profit', 0);
    const isBacktest = runType === RUN_TYPES.historic;

    return (
      <div
        className="col-12 col-sm-6 col-md-6 col-lg-4"
        key={id}
      >
        <div className="run-list-details">
          <a
            href={`/runs/${id}/?locale=${I18n.locale}`}
            target="_blank"
            rel="noreferrer"
            className="run-details"
          >

            <div className="qd-head border-bottom p-2">
              <div className="col-12 p-0">
                <h6><InlineEdit text={name || displayName || `${id}`} isShowEditIcon={false} /></h6>
                <small>
                  {isBacktest ? `${startDate} - ${endDate}`
                    : `Created At - ${moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}`}
                </small>
              </div>
            </div>

            <div className="qd-inner mb-auto align-items-center">
              <div>
                <small className="text-muted">Profit & Loss</small>
                <h6 className="d-flex">
                  <MoneyDisplay shouldColor>{profit}</MoneyDisplay>
                </h6>
              </div>
            </div>
          </a>
          <div className="action">
            <button
              className="text-nowrap btn btn-sm btn-outline-danger common-icon-btn track"
              onClick={() => onStopRun(id)}
              key="stop-btn"
              data-track-category="Quant Stop"
              data-track-action="stop run"
              data-track-label="Quant Stop"
              type="button"
            >
              <i className="material-icons-outlined tx-18">
                stop_circle
              </i>
              Stop Run
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isShowModal && (
        <ModalComponent
          onSave={() => {
            callbackFunc();
            setIsShowModal(false);
          }}
          onClose={() => setIsShowModal(!isShowModal)}
          size="lg"
          isOkBtnDisabled={isEnableDeploy}
          okBtnTitle="Deploy a Live"
        >
          <div className="quota-verification">
            <div className="head mb-2 d-flex flex-wrap">
              <div>
                <h1>
                  Live Deployments max quota/credits used
                  {oneIndentation}
                  {sizeOfLiveRuns ? (`${sizeOfLiveRuns}/${reducedSizeOfLiveRuns}`) : ''}
                </h1>
                <p>Please stop any of the following live deployments to continue</p>
              </div>
            </div>

            <div className="grid-view row flex-wrap">
              {_.isEmpty(runningLiveRuns) ? (
                <div className=" invalid-feedback d-block text-center mt-2"> No Running Live Deployments</div>
              ) : _.map(runningLiveRuns, renderLiveRuns)}
            </div>
          </div>
        </ModalComponent>
      )}
    </>
  );
};

RunningLiveRuns.propTypes = propTypes;
RunningLiveRuns.defaultProps = defaultProps;

export default RunningLiveRuns;
