import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'candleAt',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '1 minutes',
    },
    type: 'candleAt',
    name: 'RangeBreakout',
    config: {
      candleInterval: '1 minutes',
      startTime: '09:30',
      endTime: '10:30',
      valuePaths: [
        'item0.month1Price'
      ]
    }
  },
  searchTerms: [
    'RangeBreakout',
    'CandleAt'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  inputSchema: [
    {
      name: 'startTime',
      type: pipeInputSchemaTypes.timeOfDay,
      label: 'Start Time',
    },
    {
      name: 'endTime',
      type: pipeInputSchemaTypes.timeOfDay,
      label: 'End Time',
    },
    {
      name: 'candleInterval',
      isDisplay: false
    }
  ],
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      'open',
      'high',
      'low',
      'close',
    ];

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: schemaTypes.number,
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };
    });
  },
  isEnabled: true
};
export default configuration;
