import React from 'react';
import PropTypes from 'prop-types';
import SquareOffTransactionIfEntryFailedSelector from './SquareOffTransactionIfEntryFailedSelector';

const propTypes = {
  isSquareOffTransactionIfEntryFailed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const SquareOffEntireTransactionIfEntryFailed = ({
  isSquareOffTransactionIfEntryFailed, onChange
}) => {
  return (
    <div className="row align-items-center mb-1">
      <div className="col-md-3">
        <label className="label_style">Square Off Transaction if any entry is failed</label>
      </div>
      <div className="col-md-9 place-options">
        <SquareOffTransactionIfEntryFailedSelector
          optionType={isSquareOffTransactionIfEntryFailed}
          onOptionTypeChange={(value) => onChange(value)}
        />
      </div>
    </div>
  );
};

SquareOffEntireTransactionIfEntryFailed.propTypes = propTypes;
SquareOffEntireTransactionIfEntryFailed.defaultProps = defaultProps;

export default SquareOffEntireTransactionIfEntryFailed;
