import React, { useState } from 'react';
import { userPropTypes } from 'common/propTypes';
import moment from 'moment';
import { checkIsValidBrokerAccessToken, updateUser } from 'common/api/user';
import { availableBrokers } from 'common/brokerConfigs/config';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import MobileNumberDetails from './MobileNumberDetails';
import AccessTokenInValidMessage from '../AccessTokenInValidMessage';

const propTypes = {
  currentUser: userPropTypes.isRequired
};

const defaultProps = {};

const UserDetails = ({ currentUser }) => {
  const [currentUserDetails, setCurrentUserDetails] = useState(currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidAccessToken, setIsValidAccessToken] = useState(true);

  const { publishInMarketPlace } = window.userPermissions;
  const isEnabled = _.get(publishInMarketPlace, 'isEnabled', false);

  const {
    email, id, folders_count: foldersCount, runs_count: runsCount, created_at: createdAt, admin,
    gst_number: gstNumber, name, broker, broker_client_id: brokerClientId,
    broker_client_name: brokerClientName, broker_client_email: brokerClientEmail,
    broker_user_id: brokerUserId, facebook_link: facebookLink, twitter_link: twitterLink,
    instagram_link: instagramLink, telegram_link: telegramLink, account_name: accountName,
    account_number: accountNumber, ifsc_code: ifscCode, youtube_link: youtubeLink,
    broker_access_token_checked_at: brokerAccessTokenUpdatedAt
  } = currentUserDetails;

  const getUpdateDetails = [
    { key: 'name', value: name, label: 'Name' },
    { key: 'email', value: email, label: 'Email' },
    { key: 'gst_number', value: gstNumber, label: 'GST Number' }
  ];

  const userInfoDetails = [
    { label: 'User id', value: id },
    { label: 'Total folders', value: foldersCount },
    { label: 'Runs Count', value: runsCount },
    { label: 'Created at', value: moment(createdAt).fromNow() },
    { label: 'Admin', value: admin ? 'Yes' : 'No' },
    { label: 'Broker', value: availableBrokers[broker] || broker },
    { label: 'Broker User id', value: brokerUserId },
    { label: 'Broker Client Id', value: brokerClientId },
    { label: 'Broker Client Name', value: brokerClientName },
    { label: 'Broker Client Email', value: brokerClientEmail },
    {
      label: 'Token last checked',
      value: brokerAccessTokenUpdatedAt ? moment(brokerAccessTokenUpdatedAt).fromNow() : ''
    },
    { label: 'Broker Access Token', value: '' }
  ];

  const socialLinkDetails = [
    { label: 'Facebook', value: facebookLink, key: 'facebook_link' },
    { label: 'Twitter', value: twitterLink, key: 'twitter_link' },
    { label: 'Instagram', value: instagramLink, key: 'instagram_link' },
    { label: 'Telegram', value: telegramLink, key: 'telegram_link' },
    { label: 'Youtube', value: youtubeLink, key: 'youtube_link' }
  ];

  const accountInfoDetails = [
    { label: 'Account Name', value: accountName, key: 'account_name' },
    { label: 'Account Number', value: accountNumber, key: 'account_number' },
    { label: 'IFSC Code', value: ifscCode, key: 'ifsc_code' }
  ];

  const updateUserDetails = () => {
    setIsLoading(true);
    let updateDetails = { gst_number: gstNumber, name };

    if (isEnabled) { // for strategy creator only
      updateDetails = {
        ...updateDetails,
        facebook_link: facebookLink,
        twitter_link: twitterLink,
        instagram_link: instagramLink,
        telegram_link: telegramLink,
        youtube_link: youtubeLink,
        account_name: accountName,
        account_number: accountNumber,
        ifsc_code: ifscCode
      };
    }

    updateUser({ ...updateDetails }).then(() => {
      setIsLoading(false);
      reactToastify('Details Updated Successfully');
      if (isEnabled) window.location.reload(); // because of disbled the account the details
    });
  };

  const renderAccessTokenButton = (label, idx) => {
    return (
      <div className="info-view" key={idx}>
        <div className="info-view-label">{label}</div>
        <div className="info-view-value">
          <button
            type="button"
            className="btn btn-primary mt-1"
            onClick={() => {
              checkIsValidBrokerAccessToken().then(({
                status, broker_access_token_checked_at: checkedAt
              }) => {
                setCurrentUserDetails({ ...currentUserDetails, broker_access_token_checked_at: checkedAt });
                if (status) reactToastify('Valid Access Token', TOASTIFY_TYPES.SUCCESS);
                setIsValidAccessToken(status);
              });
            }}
          >
            Validate
          </button>
        </div>
      </div>
    );
  };

  const renderUserInfoDetails = (info, idx) => {
    const { label, value } = info;

    if (label === 'Admin' && !admin) return null;
    if (label === 'Broker Access Token') {
      if (!broker) return null;
      return renderAccessTokenButton(label, idx);
    }
    if (!value) return null;

    return (
      <div className="info-view" key={idx}>
        <div className="info-view-label">{label}</div>
        <div className="info-view-value">{value}</div>
      </div>
    );
  };

  const isDisabled = (detail) => {
    const { key } = detail;
    if (key === 'email') return true;
    if ((key === 'account_name') || (key === 'account_number') || (key === 'ifsc_code')) {
      return !!currentUser[detail.key];
    }

    return false;
  };

  const renderInputForm = (detail, key) => {
    return (
      <div className="form-group col-md-3" key={key}>
        <label className="col-form-label col-form-label-sm" htmlFor={key}>{detail.label}</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder={`${detail.label}`}
            aria-label={key}
            id={key}
            disabled={isDisabled(detail)}
            value={detail.value || ''}
            onChange={(event) => setCurrentUserDetails({
              ...currentUserDetails,
              [detail.key]: event.target.value
            })}
          />
        </div>
      </div>
    );
  };

  const buttonName = isLoading ? (
    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />) : ' Update ';

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h6 className="tx-16">Basic Infomation</h6>
        </div>
        <div className="card-body">
          <form>
            <div className="form-row ml-1">
              {_.map(getUpdateDetails, renderInputForm)}
              <MobileNumberDetails currentUserDetails={currentUserDetails} />
            </div>
          </form>
          {isEnabled && (
            <>
              <h6 className="border-bottom pb-1 mb-2 my-3 font-weight-medium">Social Links</h6>
              <form className="my-3">
                <div className="form-row ml-1">
                  {_.map(socialLinkDetails, renderInputForm)}
                </div>
              </form>
              <h6 className="border-bottom pb-1 mb-2 font-weight-medium">Bank Account Details</h6>

              <form className="my-3">
                <div className="form-row ml-1">
                  {_.map(accountInfoDetails, renderInputForm)}
                </div>
              </form>
            </>
          )}
        </div>
        <div className="card-footer d-flex">
          <button
            className="btn btn-primary ml-auto"
            type="button"
            disabled={_.isMatch(currentUser, currentUserDetails)}
            onClick={() => updateUserDetails()}
          >
            {buttonName}
          </button>
        </div>
      </div>

      <div className="card mt-3">
        <div className="card-header">
          <h6 className="tx-16">Account Information</h6>
        </div>
        <div className="card-body">
          <div className="account-information-wrapper">
            {_.map(userInfoDetails, renderUserInfoDetails)}
          </div>
        </div>
      </div>
      <AccessTokenInValidMessage
        currentUser={currentUserDetails}
        isValidAccessToken={isValidAccessToken}
        accessTokenCallBack={() => setIsValidAccessToken(true)}
      />
    </>
  );
};

UserDetails.propTypes = propTypes;
UserDetails.defaultProps = defaultProps;

export default UserDetails;
