import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  instrumentIndex: PropTypes.number.isRequired,
  onChangeInstrumentIndex: PropTypes.func.isRequired,
  maxInstrumentsInGroup: PropTypes.number.isRequired,
};
const defaultProps = {};

const InstrumentIndexSelector = ({ instrumentIndex, onChangeInstrumentIndex, maxInstrumentsInGroup }) => {
  const renderInstrumentIndexOption = useCallback((index) => {
    return (
      <option value={index} key={index}>
        Item
        {index + 1}
      </option>
    );
  }, []);

  return (
    <div className="instrument-index-selector">
      <select
        value={instrumentIndex}
        onChange={
        (event) => onChangeInstrumentIndex(Number(event.target.value))
      }
      >
        {_.range(0, maxInstrumentsInGroup).map(renderInstrumentIndexOption)}
      </select>
    </div>
  );
};

InstrumentIndexSelector.propTypes = propTypes;
InstrumentIndexSelector.defaultProps = defaultProps;

export default memo(InstrumentIndexSelector);
