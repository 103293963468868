import React, { useState } from 'react';

import { BROKERS } from 'common/constants';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};
const defaultProps = {};

const PaytmLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <img
            src="/landing-page-img/brokers-img/paytm-money-logo-lg.svg"
            title="Paytm Money"
            alt="Paytm Money"
            className="mb-5"
          />
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16 mb-4">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with paytm money API.
          </p>
          <div
            onClick={() => { setCurrentBroker(BROKERS.paytm.id); }}
            className="btn btn-outline-primary broker"
          >
            Login via
            {oneIndentation}
            <b>PAYTM MONEY</b>
          </div>
        </div>
      </div>
      <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
    </div>
  );
};

PaytmLogin.propTypes = propTypes;
PaytmLogin.defaultProps = defaultProps;

export default PaytmLogin;
