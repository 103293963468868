import React, { useContext } from 'react';
import { getYearWiseData } from 'ui/run/RunShow/OrderStore/analytics/index';

import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import BarChart from './chart/BarChart';

const propTypes = {};
const defaultProps = {};

const YearAnalytics = () => {
  const { completedTransactions } = useContext(OrderContext);

  const yearAnalyticsData = getYearWiseData(completedTransactions);

  const renderChart = (yearAnalytics, year) => (
    <BarChart
      key={year}
      analyticsData={yearAnalytics}
      title={year}
    />
  );

  return (
    <div className="col-md-6">
      <h6 className="mb-3">Year</h6>
      {_.map(yearAnalyticsData, renderChart)}
    </div>
  );
};

YearAnalytics.propTypes = propTypes;
YearAnalytics.defaultProps = defaultProps;

export default YearAnalytics;
