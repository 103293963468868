import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  name: PropTypes.string.isRequired,
  onChangeRunName: PropTypes.func.isRequired
};
const defaultProps = {};
const I18N_SCOPE = { scope: 'runs.form' };

const RunNameInput = ({ name, onChangeRunName }) => {
  return (
    <div className="run-name-input">
      <label className="col-form-label col-form-label-sm">
        {I18n.t('run_name', I18N_SCOPE)}
      </label>
      <div className="input-wrapper">
        <input
          type="text"
          value={name}
          className="form-control form-control-sm"
          placeholder={I18n.t('name_input_placeholder', I18N_SCOPE)}
          onChange={(event) => onChangeRunName(event.target.value)}
        />
      </div>
    </div>
  );
};

export default RunNameInput;

RunNameInput.propTypes = propTypes;
RunNameInput.defaultProps = defaultProps;
