import React from 'react';
import PropTypes from 'prop-types';
import { STRIKE_KEYS } from 'modules/TransactionsBuilder/configs';

const propTypes = {
  optionStrikeCalculationBasedOn: PropTypes.string.isRequired,
};
const defaultProps = {};

const OptionConfigHeader = ({ optionStrikeCalculationBasedOn }) => {
  const strikeBasedOn = (optionStrikeCalculationBasedOn === STRIKE_KEYS.equityPrice)
    ? STRIKE_KEYS.strikeIndex : optionStrikeCalculationBasedOn;

  return (
    <thead>
      <tr>
        <th>Option Type</th>
        <th>{_.startCase(strikeBasedOn)}</th>
        <th>Total Lot</th>
        <th>Action Type</th>
        <th>Stop Loss</th>
        <th>Take Profit</th>
      </tr>
    </thead>
  );
};

OptionConfigHeader.propTypes = propTypes;
OptionConfigHeader.defaultProps = defaultProps;

export default OptionConfigHeader;
