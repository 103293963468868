import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'pivotPoints',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0
    },
    type: 'pivotPoints',
    name: 'pivotPoints',
    config: {
      timeFrame: 'day',
      category: 'traditional',
      valuePaths: [
        'item0.equity'
      ]
    }
  },
  searchTerms: [
    'pivotPoints'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {
      category: [
        'traditional',
        'fibonacci',
        'woodie',
        'classic',
        'demarks',
        'camarilla'
      ]
    },
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  inputSchema: [
    {
      name: 'timeFrame',
      type: pipeInputSchemaTypes.select,
      label: 'Time Frame',
      options: ['day', 'week']
    },
    {
      name: 'category',
      type: pipeInputSchemaTypes.select,
      label: 'Category',
      options: [
        'traditional',
        'fibonacci',
        'woodie',
        'classic',
        'demarks',
        'camarilla'
      ]
    }
  ],
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      'pivotPoint',
      'r1',
      'r2',
      'r3',
      'r4',
      'r5',
      's1',
      's2',
      's3',
      's4',
      's5'
    ];

    return _.map(properties, (property) => {
      return {
        indicatorType: type,
        name: `${name}.${property}`,
        type: schemaTypes.number,
        category: 'indicator',
        label: name === property ? name : `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };
    });
  },
  isEnabled: true
};
export default configuration;
