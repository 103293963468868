import qs from 'qs';
import wrappedFetch from './base';

const fetchStrategies = ({ params }) => {
  const queryParams = { ...params };

  return wrappedFetch(`/strategies.json?${qs.stringify(queryParams)}`)
    .then((response) => response.json());
};

export const onCreateNewStrategy = (args) => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...args }),
  };

  return wrappedFetch('/strategies.json', config);
};

export const onStartStrategyTransaction = (id, quantConfig) => {
  const url = `/strategies/${id}/start_with_transaction.json`;

  const options = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ quantConfig: JSON.stringify(quantConfig) })
  };

  return wrappedFetch(url, options).then((response) => response.json());
};

export default fetchStrategies;
