import React from 'react';

const StrategyFreeButton = () => {
  return (
    <div className="free-ribbon">Free strategy</div>
  );
};

StrategyFreeButton.propTypes = {};

export default StrategyFreeButton;
