export const onlyForAdmins = (component) => {
  if (window.isAdmin) {
    return component;
  }
  return () => { return null; };
};

export const onlyForBetaUsers = (component) => {
  if (window.isAdmin || window.isBetaUser) {
    return component;
  }
  return () => { return null; };
};
