import React from 'react';

import ExpiryHandler from 'modules/QuantBuilder/components/ExpiryHandler';
import OrderConfigsBuilder from 'modules/QuantBuilder/components/OrderConfigsBuilder';
import ChartPattern from './components/ChartPattern';
import InstrumentGroupInput from './components/instrumentGroups/InstrumentGroupInput';
import PipesInput from './components/Pipes';
import RollupType from './components/ChartPattern/RollupType';
import ProfitAndOrdersComponents from './components/ProfitAndOrdersComponents/index';
import AdvancedOptions from './components/AdvancedOptions/index';

export default class QuantBuilder extends React.Component {
  render() {
    const { isAdmin } = window;
    return (
      <div className="quant-builder mb-2">
        <div className="card">
          <div className="card-body">
            <InstrumentGroupInput />

            <div className="chart-type-container row align-items-center my-3">
              <div className="col-md-2">
                <label className="col-form-label col-form-label-sm">Chart Type</label>
              </div>
              <div className="col-md-10">
                <ChartPattern />
              </div>
            </div>

            <div className="indicator-rollup-type mb-2 hidden-for-future">
              <div className="row align-items-center">
                <div className="col-md-2">
                  <label className="col-form-label col-form-label-sm">Indicators Calculation</label>
                </div>
                <div className="col-md-10">
                  <RollupType />
                </div>
              </div>
            </div>
            <hr className="divider" />
            {/* <CombinedPremium />
            <CustomPropsBuilder /> */}
            <h6 className="sub-head mb-3">Indicators</h6>
            <div className="pipes-input-container">
              <PipesInput />
            </div>

            <hr className="divider" />

            <OrderConfigsBuilder />
            <hr className="divider" />

            {isAdmin && (
              <>
                <AdvancedOptions />
                <hr className="divider" />
              </>
            )}

            <ProfitAndOrdersComponents />
            <hr className="divider" />

            <h6 className="sub-head mb-3">Expiry</h6>
            <div className="pl-3">
              <ExpiryHandler />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
