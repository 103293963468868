import React from 'react';
import PropTypes from 'prop-types';
import Details from './Details';

const propTypes = {
  meetconfigs: PropTypes.arrayOf(PropTypes.shape({})),
  onDeleteMeetConfig: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

const defaultProps = {
  meetconfigs: []
};

const MeetingDetails = ({ meetconfigs, onDeleteMeetConfig, onHandleChange }) => {
  const renderMeet = (meetconfig, key) => {
    return (
      <div key={key}>
        <Details
          config={meetconfig}
          onHandleChange={onHandleChange}
          keyIndex={key}
          onDeleteMeetConfig={onDeleteMeetConfig}
        />
      </div>
    );
  };

  return (
    <div className="mt-5">
      <h4>Meeting Details</h4>
      {_.map(meetconfigs, renderMeet)}
    </div>
  );
};

MeetingDetails.propTypes = propTypes;
MeetingDetails.defaultProps = defaultProps;

export default MeetingDetails;
