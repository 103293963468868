import qs from 'qs';
import wrappedFetch from './base';

export const getLinkedUsers = (args) => {
  const queryParams = qs.stringify({ from_date: args.fromDate, to_date: args.toDate });
  const uri = `/dashboards/referrer/linked_users?${queryParams}`;

  return wrappedFetch(uri)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    });
};

export const getPurchaseReport = (args) => {
  const queryParams = qs.stringify({ from_date: args.fromDate, to_date: args.toDate });
  const uri = `/dashboards/referrer/purchase_report?${queryParams}`;

  return wrappedFetch(uri)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    });
};
