import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const KotakNeo = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              To start click on this link
              <b>
                <a
                  href="https://www.kotaksecurities.com/trading-tools/kotak-neo-trading-platform/trading-api/"
                  target="blank"
                  title="Kotak Neo trade API Login"
                >
                  - https://www.kotaksecurities.com/trading-tools/kotak-neo-trading-platform/trading-api/
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              Following your registration for the Neo TradeAPIs,
              {oneIndentation}
              you will receive an email from the Kotak team which included the your API details
            </p>
            <img
              src="/images/broker_setup_guide/kotak_neo/kotak_neo_step_2.png"
              className="w-100"
              alt="Step 2"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Please go to the API portal by
              {oneIndentation}
              <a
                href="https://napi.kotaksecurities.com/devportal/apis"
                target="blank"
                title="Kotak API Portal"
              >
                Clicking here
              </a>
              {oneIndentation}
              and
              {oneIndentation}
              use the credentials provided by the Kotak through email.
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Once you logged into the API gateway,
              {oneIndentation}
              go to Applications and then click on Default Application, as shown in the image below.
            </p>
            <img
              src="/images/broker_setup_guide/kotak_neo/kotak_neo_step_3.png"
              className="w-100"
              alt="Step 3"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <p className="stepper-desc">
              Go to Production keys and scroll down the page and then change the default time from
              {oneIndentation}
              <b>3600 to 86400</b>
              {oneIndentation}
              in all of the fields shown in the image below, then click on update at the bottom.
            </p>
            <img
              src="/images/broker_setup_guide/kotak_neo/kotak_neo_step_5.png"
              alt="Step 4"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 6</h3>
            <p className="stepper-desc">
              Once updated, you can copy and paste the Consumer API details in the appropriate fields.
            </p>
            <img
              src="/images/broker_setup_guide/kotak_neo/kotak_neo_step_4.png"
              alt="Step 5"
            />
          </div>
        </li>
      </ol>
    </div>
  </>
);

KotakNeo.propTypes = propTypes;
KotakNeo.defaultProps = defaultProps;

export default KotakNeo;
