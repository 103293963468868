import React, { useState } from 'react';

import { BROKERS } from 'common/constants';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};
const defaultProps = {};

const IciciDirectLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with ICICI direct API.
            <br />
            <br />
            <div
              onClick={() => { setCurrentBroker(BROKERS.icici_direct.id); }}
              className="btn btn-outline-primary broker"
            >
              Login via
              {oneIndentation}
              <b>ICICI Direct</b>
            </div>
          </p>
          {/* <div
            onClick={() => { setCurrentBroker(BROKERS.icici_direct.id); }}
            className="broker btn btn-light"
          >

            <img
              src="/landing-page-img/brokers-img/icici-direct.png"
              alt={BROKERS.icici_direct.name}
              title={BROKERS.icici_direct.name}
              className="icicidirect-logo"
            />
          </div> */}
        </div>
        <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
      </div>
    </div>
  );
};

IciciDirectLogin.propTypes = propTypes;
IciciDirectLogin.defaultProps = defaultProps;

export default IciciDirectLogin;
