import ReadMore from 'common/components/ReadMore';
import { runPropTypes } from 'common/propTypes';
import React, { useState } from 'react';
import qs from 'qs';
import InstrumentGroupsSentence from 'common/components/InstrumentGroupsSentence';
import classNames from 'classnames';
// import CreditDetails from 'ui/run/RunShow/components/CreditDetails';
import PropTypes from 'prop-types';
import { getFolders } from 'common/api/folder';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import moment from 'moment';
import { createRun } from 'common/api/run';
import { quotaVerificationDom } from 'ui/QuotaVerification/quotaDom';
import { initiateUserAuth } from 'common/auth/modalLogin';
import { getSanitizedQuantConfig } from 'v2/modules/withRunForm/sanitizer/onLoad/quantConfig/index';

const propTypes = { run: runPropTypes.isRequired, currentUserDefaultFolderId: PropTypes.number };
const defaultProps = { currentUserDefaultFolderId: null };

const ShowCaseRunCard = ({ run, currentUserDefaultFolderId }) => {
  const [isExpanded] = useState(false);
  const {
    name, description, id: runId, quant_config: quantConfig, run_type: runType,
    order_type: orderType, start_date: startDate, end_date: endDate, strategy_id: strategyId
  } = run;
  const cloneUrl = `/runs/new?${qs.stringify({ clone_from_run_id: runId, locale: I18n.locale })}`;
  const { isUserSignedIn } = window;
  const sanitizedQuantConfig = getSanitizedQuantConfig(quantConfig);

  const { instrumentGroups } = sanitizedQuantConfig;

  const divClassName = classNames('bt-transaction-details mb-3', {
    'expand-more': isExpanded,
    '': !isExpanded
  });

  const executeCreateRun = (runParams) => {
    createRun(runParams).catch((error) => {
      const errors = _.get(error.response, 'data.errors', []);
      const additionalErrors = _.filter(errors, (err, key) => key !== 'quant_config');
      const errorMessages = _.join(_.map(additionalErrors, '0'), ', ');

      if (errorMessages) reactToastify(errorMessages, TOASTIFY_TYPES.ERROR);
    });

    return null;
  };

  const executeRun = (runParams) => {
    return quotaVerificationDom(instrumentGroups, runParams, executeCreateRun);
  };

  const handleCreateRun = () => {
    const runParams = {
      name,
      folder_id: currentUserDefaultFolderId,
      quant_config: JSON.stringify(quantConfig),
      run_type: runType,
      order_type: orderType,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      strategy_id: strategyId
    };

    const onHandleDefaultFolderAndExecuteRun = () => {
      getFolders().then((folders) => {
        const { id } = _.find(folders, 'is_default');
        executeRun({ ...runParams, folder_id: id });
      });
    };

    if (isUserSignedIn) {
      if (!currentUserDefaultFolderId) return onHandleDefaultFolderAndExecuteRun();
      return executeRun(runParams);
    }

    initiateUserAuth().then(() => {
      onHandleDefaultFolderAndExecuteRun();
    }).catch(() => { reactToastify('Unauthorized, Please Login and continue', TOASTIFY_TYPES.ERROR); });

    return null;
  };

  return (
    <div className="col-md-4 mb-2">
      <div className="card h-100">
        <div className="card-header"><h5 className="card-title">{name || runId}</h5></div>
        <div className="card-body">

          <div className="pl-1 mb-2">
            <ReadMore textCount={200}>{description}</ReadMore>
          </div>

          <div className={divClassName}>
            <div className="bt-transaction-wrapper">

              <div className="details-list">
                <div className="value-name">Instrument</div>
                <div className="pl-1"><InstrumentGroupsSentence instrumentGroups={instrumentGroups} /></div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          {/* <CreditDetails
            runType={runType}
            startDate={_.get(run, 'start_date', '')}
            endDate={_.get(run, 'end_date', '')}
            instrumentGroups={instrumentGroups}
          /> */}
          <button type="button" className="btn btn-pill btn-sm btn-primary" onClick={() => handleCreateRun()}>
            Run Backtest
          </button>
          <a href={cloneUrl} className="btn btn-pill btn-sm btn-outline-primary"> Copy and Edit</a>
        </div>
      </div>
    </div>
  );
};

ShowCaseRunCard.propTypes = propTypes;
ShowCaseRunCard.defaultProps = defaultProps;

export default ShowCaseRunCard;
