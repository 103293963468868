// TODO: rename
import wrappedFetch from './base';

export const getFolders = () => {
  return wrappedFetch('/folders.json')
    .then((response) => response.json());
};

export const createFolder = (name) => {
  const config = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ name }),
  };

  return wrappedFetch('/folders.json', config);
};

export const updateFolder = ({ folderId, folder }) => {
  const config = {
    method: 'PUT',
    body: JSON.stringify(folder),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return wrappedFetch(`/folders/${folderId}.json`, config);
};

export const deleteFolder = ({ folderId }) => {
  return wrappedFetch(`/folders/${folderId}.json`, { method: 'DELETE' });
};
