import { reactToastify } from 'common/utils/reactToastify';

export const generatePaymentInvoice = (purchase) => {
  const element = document.getElementById(`purchase-${purchase.id}`);
  const filename = `quantman-${purchase.id}.pdf`;
  const opt = {
    margin: 0.2,
    filename,
    image: { type: 'jpeg', quality: 1.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  };

  // New Promise-based usage:
  html2pdf().set(opt).from(element).save();
  reactToastify(`${filename} downloaded`);

  // Old monolithic-style usage: html2pdf(element, opt);
};

export const number2words = (num) => {
  const ones = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ',
    'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ',
    'eighteen ', 'nineteen '];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  if ((num.toString()).length > 9) return 'overflow';
  const n = (`000000000${num}`).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return null; let str = '';
  str += (n[1] !== '00' && n[1] !== '0')
    ? `${ones[Number(n[1])] || `${tens[n[1][0]]} ${ones[n[1][1]]}`}crore ` : '';
  str += (n[2] !== '00' && n[2] !== '0')
    ? `${ones[Number(n[2])] || `${tens[n[2][0]]} ${ones[n[2][1]]}`}lakh ` : '';
  str += (n[3] !== '00' && n[3] !== '0')
    ? `${ones[Number(n[3])] || `${tens[n[3][0]]} ${ones[n[3][1]]}`}thousand ` : '';
  str += (n[4] !== '00' && n[4] !== '0')
    ? `${ones[Number(n[4])] || `${tens[n[4][0]]} ${ones[n[4][1]]}`}hundred ` : '';
  str += (n[5] !== '00' && n[5] !== '0') ? `${((str !== '') ? 'and ' : '')
    + (ones[Number(n[5])] || `${tens[n[5][0]]} ${ones[n[5][1]]}`)}only ` : '';

  return str;
};
