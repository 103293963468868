import {
  getDefaultExistingLegConfig, getEmptyAdjustmentActionConfig
} from 'modules/TransactionsBuilder/configs';

export const isEligibleToMoveToCostTransactionConfigs = (transactionConfigs) => {
  return _.some(transactionConfigs, (transactionConfig) => {
    const { mode: { transaction }, optionConfigs } = transactionConfig;
    if (transaction !== 'option') return false;

    let isCeAndStopLossPresent = false;
    let isPeAndStopLossPresent = false;

    return _.some(optionConfigs, (optionConfig) => {
      const { optionType, stopLoss } = optionConfig;
      if (optionType === 'CE' && stopLoss) isCeAndStopLossPresent = true;
      if (optionType === 'PE' && stopLoss) isPeAndStopLossPresent = true;

      return isCeAndStopLossPresent && isPeAndStopLossPresent;
    });
  });
};

const getSignalConfigsForAdjustment = (leftOperand, rightOperand) => {
  return [{
    conditionOperator: '',
    leftOperand,
    operator: 'equalOrAbove',
    rightOperand: `${rightOperand}`,
    mode: { isAutoAdjustment: true, adjustmentMode: 'moveToCost' }
  }];
};

const buildAdjustmentTransactionsConfigs = (
  configName, maxInstrumentsInGroup, transactionConfigIndex, moveToCostIndexes
) => {
  const timesOfAdjustmentConfig = _.times(maxInstrumentsInGroup, () => {
    const emptyAdjustmentActionConfig = getEmptyAdjustmentActionConfig();
    const { mode } = emptyAdjustmentActionConfig;

    return {
      ...emptyAdjustmentActionConfig,
      mode: { ...mode, isAutoAdjustment: true, adjustmentMode: 'moveToCost' }
    };
  });
  const adjustConfig = timesOfAdjustmentConfig[transactionConfigIndex];
  const { mode } = adjustConfig;

  switch (configName) {
    default:
      timesOfAdjustmentConfig[transactionConfigIndex] = {
        ...adjustConfig,
        optionConfigs: [],
        mode: { ...mode, transaction: 'option' },
        existingLegConfigs: _.map(moveToCostIndexes, (moveToCostIndex) => {
          return {
            ...getDefaultExistingLegConfig,
            legType: 'option',
            action: 'updateStopLoss',
            optionConfigIndex: moveToCostIndex
          };
        })
      };
      break;
  }

  return timesOfAdjustmentConfig;
};

const addAdjustmentConfigsBasedOnParentLegs = (
  config, transactionConfigIndex, configName, optionConfigIndex = 0, maxInstrumentsInGroup, count,
  onChangeCount, moveToCostIndexes
) => {
  let reAssignedCount = count;
  const { stopLoss } = config;

  const configs = _.map(_.range(1), (index) => {
    switch (configName) {
      default:
        let optionLeftOperand = '';
        if (index === 0) {
          optionLeftOperand = `individualStop.${transactionConfigIndex}.${configName}`;
          optionLeftOperand += `_${optionConfigIndex}.lossPercentage`;
        } else {
          optionLeftOperand = `individualStop.${transactionConfigIndex}.${configName}_`;
          optionLeftOperand += `0_${reAssignedCount - 1}.lossPercentage`;
        }

        reAssignedCount += 1;
        onChangeCount(reAssignedCount);
        return {
          signalConfigs: getSignalConfigsForAdjustment(optionLeftOperand, stopLoss),
          adjustmentConfigs: buildAdjustmentTransactionsConfigs(
            configName, maxInstrumentsInGroup, transactionConfigIndex, moveToCostIndexes
          )
        };
    }
  });

  return {
    signalConfigs: _.map(configs, ({ signalConfigs }) => { return signalConfigs; }),
    adjustmentConfigs: _.map(configs, ({ adjustmentConfigs }) => { return adjustmentConfigs; })
  };
};

export const addAdjustmentBasedOnMoveToCost = (
  transactionConfigs, maxInstrumentsInGroup, aac,
  asc, initialCount
) => {
  let count = initialCount;
  const onChangeCount = (reAssignedCount) => { count = reAssignedCount; };

  let adjustmentSignalConfigs = asc;
  let adjustmentActionConfigs = aac;
  _.each(transactionConfigs, (transactionConfig, transactionConfigIndex) => {
    const { optionConfigs } = transactionConfig;

    _.each(optionConfigs, (optionConfig, optionConfigIndex) => {
      const { stopLoss, optionType } = optionConfig;

      // if stoploss and optionTypePresent check the conditions
      if (stopLoss && optionType) {
        const getMoveToCostOptionIndex = () => {
          if (optionType === 'CE') {
            const ceIndexes = _.map(optionConfigs, (config, idx) => {
              if (config.optionType === 'PE' && config.stopLoss) {
                return idx;
              }
              return undefined;
            });

            return _.filter(ceIndexes, (ceIndex) => ceIndex !== undefined);
          }
          const peIndexes = _.map(optionConfigs, (config, idx) => {
            if (config.optionType === 'CE' && config.stopLoss) {
              return idx;
            }
            return undefined;
          });

          return _.filter(peIndexes, (peIndex) => peIndex !== undefined);
        };

        // move to cost index get from above functions
        const moveToCostIndexes = getMoveToCostOptionIndex();

        if (!_.isEmpty(moveToCostIndexes)) {
          const {
            signalConfigs: optionSignalConfigs, adjustmentConfigs: optionAdjustmentConfigs
          } = addAdjustmentConfigsBasedOnParentLegs(
            optionConfig, transactionConfigIndex, 'option', optionConfigIndex, maxInstrumentsInGroup, count,
            onChangeCount, moveToCostIndexes
          );

          adjustmentSignalConfigs = [...adjustmentSignalConfigs, ...optionSignalConfigs];
          adjustmentActionConfigs = [...adjustmentActionConfigs, ...optionAdjustmentConfigs];
        }
      }
    });
  });

  return { adjustmentSignalConfigs, adjustmentActionConfigs, reAssignedCount: count };
};
