import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import { pipeConfigPropTypes } from 'common/propTypes';
import { PipeUtility } from '../utility';

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  validator: PropTypes.shape({}).isRequired
};

const Snippet = ({
  pipeConfig, onUpdatePipeConfig, control, errors, validator
}) => {
  const { type: pipeType } = pipeConfig;
  const inputSchema = PipeDefinitionUtility.getPipeInputSchemaByType(pipeType);

  const renderSnippetFields = useCallback((parameter, idx) => {
    const {
      type, name, options, label
    } = parameter;

    const Component = PipeUtility.getComponentByType(type);
    if (!Component) return null;

    const parentClassName = PipeUtility.getParentClassNameByType(type);

    return (
      <div key={idx} className={parentClassName}>
        <label>{label}</label>
        <Component
          key={idx}
          pipeConfig={pipeConfig}
          type={pipeType}
          name={name}
          value={pipeConfig.config[name]}
          valuePaths={_.get(pipeConfig, 'config.valuePaths', [])}
          validator={_.get(validator, name, {})}
          control={control}
          errors={errors}
          options={options}
          onUpdatePipeConfig={onUpdatePipeConfig}
        />
      </div>
    );
  }, [control, errors, onUpdatePipeConfig, pipeConfig, pipeType, validator]);

  return _.map(inputSchema, renderSnippetFields);
};

Snippet.propTypes = propTypes;

export default Snippet;
