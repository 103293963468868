import fetchValidationSchemas from 'common/api/validationSchema';
import { useEffect, useMemo, useState } from 'react';
import { getPipeValidators } from './pipeValidators';

const useValidationSchema = () => {
  const [validationSchema, setValidationSchema] = useState({ pipesSchema: {} });
  const { pipesSchema } = validationSchema;
  const pipesValidators = useMemo(() => getPipeValidators(pipesSchema), [pipesSchema]);

  useEffect(() => {
    fetchValidationSchemas().then((schema) => {
      setValidationSchema(schema);
    });
  }, []);

  return {
    pipesValidators
  };
};

export default useValidationSchema;
