const configuration = {
  type: 'ratio',
  defaults: {
    type: 'ratio',
    name: 'ratio',
    config: {
      valuePaths: [
        'item0.month1Price',
        'item1.month1Price'
      ]
    }
  },
  searchTerms: [
    'price ration',
    'ratio'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {}
  },
  buildOutputSchema: ({ type, name }) => {
    return [
      {
        name,
        label: name,
        indicatorType: type,
        type: 'number',
        category: 'indicator'
      }
    ];
  },
  isEnabled: true
};
export default configuration;
