import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import { schemaShouldShowConfigTypes, schemaTypes } from '../outputSchema/config';

const configuration = {
  type: 'keltnerChannel',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'keltnerChannel',
    name: 'keltnerChannel',
    config: {
      candleInterval: '3 minutes',
      length: 20,
      multiplier: 2,
      atrLength: 10,
      valuePaths: [
        'item0.equity'
      ]
    },
  },
  searchTerms: [
    'keltnerChannel'
  ],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future]
  },
  buildOutputSchema: (pipe) => {
    const { name, type } = pipe;
    const properties = [
      'upper',
      'basis',
      'lower',
    ];

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: schemaTypes.number,
        indicatorType: type,
        category: 'indicator',
        label: `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name
      };
    });
  },
  isEnabled: true
};

export default configuration;
