import { STRIKE_SELECTION_TYPES } from 'v2/common/constants/index';

export const isStrikeIndexBased = (type) => {
  return _.includes(
    [
      STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.value,
      STRIKE_SELECTION_TYPES.FutureBasedStrikeIndex.value,
      STRIKE_SELECTION_TYPES.IndicatorValueBasedStrikeIndex.value,
    ], type
  );
};

export const getStrikeIndexDisplayName = (value, optionType) => {
  if (value === 0) {
    return 'ATM';
  }

  if (value > 0) return (`${optionType === 'CE' ? 'OTM' : 'ITM'} ${Math.abs(value)}`);

  return (`${optionType === 'CE' ? 'ITM' : 'OTM'} ${Math.abs(value)}`);
};

const getStrikeRange = (isIndexBased) => (
  isIndexBased
    ? _.range(-30, 31)
    : _.range(-10, 11)
);

export const getStrikeIndexBasedOptions = (type, isIndexInstrumentBased, optionType) => {
  switch (type) {
    case STRIKE_SELECTION_TYPES.FutureBasedStrikeIndex.value:
    case STRIKE_SELECTION_TYPES.EquityBasedStrikeIndex.value:
    case STRIKE_SELECTION_TYPES.IndicatorValueBasedStrikeIndex.value:
      return _.map(getStrikeRange(isIndexInstrumentBased), (strike) => ({
        value: strike,
        label: getStrikeIndexDisplayName(strike, optionType),
      }));

    default:
      return [];
  }
};
