import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string
  ]).isRequired
};
const defaultProps = {};

const TIMEOUT = 500;

const BgBlink = ({ children }) => {
  const currentValue = _.get(children, ['props', 'children'], children);
  const previousValue = useRef(currentValue);

  const [blinkStyle, setBlinkStyle] = useState('blink');

  useEffect(() => {
    setBlinkStyle(
      classNames('blink', {
        green: previousValue.current < currentValue,
        red: previousValue.current > currentValue,
      })
    );

    setTimeout(() => { setBlinkStyle('blink'); }, TIMEOUT);

    previousValue.current = currentValue;

    return (() => { });
  }, [currentValue]);

  return (
    <div className={blinkStyle}>{children}</div>
  );
};

BgBlink.propTypes = propTypes;
BgBlink.defaultProps = defaultProps;

export default BgBlink;
