import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

const OPTION_TYPES = [
  { label: 'modify to market', value: 'update' },
  { label: 'cancel', value: 'cancel' }
];

const propTypes = {
  optionType: PropTypes.string.isRequired,
  onOptionTypeChange: PropTypes.func.isRequired,
  isHideCancel: PropTypes.bool.isRequired
};
const defaultProps = {};

const ModifyOrderSelector = ({
  optionType: propsOptionType, onOptionTypeChange, isHideCancel
}) => {
  const [optionTypeBtnName] = useState(_.uniqueId('optionTypeBtnName-'));
  const optionTypeInputId = _.uniqueId('optionType');
  const { errors, register } = useFormContext();
  const validatorConfig = { validate: (value) => (!value ? 'value required' : null) };
  const validatorProps = { ref: register(validatorConfig) };

  const optionTypeStyle = classNames(
    'modify-order-selector',
    'align-items-center',
    'gap-5',
    'd-flex',
    'selector-radio-btn'
  );
  const radioBtnStyle = classNames(
    'form-check-input',
    { 'is-invalid': !!errors[optionTypeBtnName] }
  );

  const renderRadioBtn = (optionType) => {
    if (isHideCancel && optionType.value === 'cancel') return null;

    return (
      <div className="form-check form-check-sm" key={optionType.value}>
        <input
          {...validatorProps}
          className={radioBtnStyle}
          type="radio"
          name={optionTypeBtnName}
          id={`${optionTypeInputId}-${optionType.value}`}
          value={optionType.value}
          tabIndex="-100"
          checked={optionType.value === propsOptionType}
          onChange={() => onOptionTypeChange(optionType.value)}
        />
        <label className="form-check-label" htmlFor={`${optionTypeInputId}-${optionType.value}`}>
          {_.startCase(optionType.label)}
        </label>
      </div>
    );
  };

  const optionTypeRadioBtns = _.map(OPTION_TYPES, renderRadioBtn);

  return (
    <div className={optionTypeStyle}>
      {optionTypeRadioBtns}
    </div>
  );
};

ModifyOrderSelector.propTypes = propTypes;
ModifyOrderSelector.defaultProps = defaultProps;

export default ModifyOrderSelector;
