import moment from 'moment';
import { getSymbolType } from 'v2/common/utils/tradingSymbol';
import { removeExchangeFromInstrument } from 'common/utils/InstrumentUtils';

const getStrikePrice = (strikePriceSplit) => {
  const strikePriceInitial = _.get(strikePriceSplit, 0);
  const strikePriceAfterDecimal = _.get(strikePriceSplit, 1, '00');

  return `${strikePriceInitial}.${_.size(strikePriceAfterDecimal) >= 2
    ? strikePriceAfterDecimal : `${strikePriceAfterDecimal}0`}`;
};

export const getChannelName = (tradingSymbol) => {
  const symbolType = getSymbolType(tradingSymbol);
  if (symbolType === 'EQUITY') return `EQUITY:${tradingSymbol}`;

  const firstDigitIndex = tradingSymbol.search(/\d/);
  const date = tradingSymbol.substring(firstDigitIndex, firstDigitIndex + 7);
  const instrument = tradingSymbol.substring(0, firstDigitIndex);
  const formattedDate = moment(date, 'DDMMMYY').format('DD-MM-YYYY');

  const { indexInstruments } = window;

  const isIndexPresent = _.includes(
    _.map(indexInstruments, (indexInstrument) => removeExchangeFromInstrument(indexInstrument)), instrument
  );

  const indexType = isIndexPresent ? 'IDX' : 'STK';
  switch (symbolType) {
    case 'FUT':
      return `FUT${indexType}:${instrument}FUT${indexType}${formattedDate}`;
    default:
      const strikePriceSplit = _.split(tradingSymbol.substring(firstDigitIndex + 7).slice(0, -2), '.');
      const strikePrice = getStrikePrice(strikePriceSplit);

      return `OPT${indexType}:${instrument}OPT${indexType}${formattedDate}${strikePrice}${symbolType}`;
  }
};
