import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';

export const isDisabledPipeName = (quantConfig, name) => {
  const orderConfigs = _.get(quantConfig, 'orderConfigs', []);

  const isPresent = (signalArray) => {
    return _.some(signalArray, ({ leftOperand, rightOperand }) => {
      return _.includes(leftOperand, name) || _.includes(rightOperand, name);
    });
  };

  return _.some(orderConfigs, (orderConfig) => {
    const entrySignalConfigs = _.get(orderConfig, 'entrySignalConfigs', []);
    const exitSignalConfigs = _.get(orderConfig, 'exitSignalConfigs', []);
    const adjustmentSignalConfigs = _.get(orderConfig, 'adjustmentSignalConfigs', []);

    return isPresent(entrySignalConfigs)
      || isPresent(exitSignalConfigs)
      || _.some(adjustmentSignalConfigs, (
        adjustmentSignalConfig
      ) => { return isPresent(adjustmentSignalConfig); });
  });
};

export const updateSignalNameBasedOnPipeName = (oldPipeConfig, quantConfig, newPipeConfig) => {
  const oldName = _.get(oldPipeConfig, 'name', '');
  const newName = _.get(newPipeConfig, 'name', '');
  const oldPipeType = _.get(oldPipeConfig, 'type', '');
  const newPipeType = _.get(newPipeConfig, 'type', '');
  const isPipeDiffer = oldPipeType !== newPipeType;

  if (oldName === newName) return quantConfig;
  if (isPipeDiffer) {
    reactToastify(`You changed the indicator 
    (${oldName}) to (${newName}), ${oldName} indicators are removed in signals, please verify`,
    TOASTIFY_TYPES.INFO);
  } else {
    reactToastify(`Indicator (${oldName}) changed to (${newName}) in signals, please verify`,
      TOASTIFY_TYPES.INFO);
  }

  const orderConfigs = _.get(quantConfig, 'orderConfigs', []);
  const updatedOrderConfigs = _.map(orderConfigs, (orderConfig) => {
    const entrySignalConfigs = _.get(orderConfig, 'entrySignalConfigs', []);
    const exitSignalConfigs = _.get(orderConfig, 'exitSignalConfigs', []);
    const adjustmentSignalConfigs = _.get(orderConfig, 'adjustmentSignalConfigs', []);

    const updatedArray = (signalArray) => {
      const newSignalArray = _.compact(_.map(signalArray, (signal) => {
        const { leftOperand, rightOperand } = signal;
        const [leftOperandFirstName] = _.split(leftOperand, '.');
        const [rightOperandFirstName] = _.split(rightOperand, '.');
        const isChangeLeftOperand = leftOperandFirstName === oldName;
        const isChangeRightOperand = rightOperandFirstName === oldName;

        // case 1: if user changed the pipe in edit flow.. we have to remove the old pipe in signals..
        if (isPipeDiffer) {
          if (isChangeLeftOperand || isChangeRightOperand) { return null; }
          return signal;
        }

        // case 2: when user changed the pipename.. we replace the pipe name of same pipe
        return {
          ...signal,
          leftOperand: (!isPipeDiffer && isChangeLeftOperand)
            ? _.replace(leftOperand, oldName, newName) : leftOperand,
          rightOperand: (!isPipeDiffer && isChangeRightOperand)
            ? _.replace(rightOperand, oldName, newName) : rightOperand
        };
      }));

      return _.map(newSignalArray, (signal, idx) => (!idx ? { ...signal, conditionOperator: '' } : signal));
    };

    return {
      ...orderConfig,
      entrySignalConfigs: updatedArray(entrySignalConfigs),
      exitSignalConfigs: updatedArray(exitSignalConfigs),
      adjustmentSignalConfigs: _.map(adjustmentSignalConfigs, (adjustmentSignalConfig) => {
        return updatedArray(adjustmentSignalConfig);
      })
    };
  });

  return { ...quantConfig, orderConfigs: updatedOrderConfigs };
};

export const getDropDownFieldValues = (pipeConfig, configKey) => {
  const { config: { timeFrame, basedOn, source } } = pipeConfig;
  const configOptions = PipeDefinitionUtility.getPipeConfigOptions(pipeConfig.type);

  if (configOptions[configKey]) {
    return {
      id: configKey,
      options: configOptions[configKey],
      value: _.get(pipeConfig, ['config', configKey])
    };
  }

  switch (configKey) {
    case 'timeFrame':
      return { id: 'timeFrame', options: ['day', 'week'], value: timeFrame };
    case 'basedOn':
      return { id: 'basedOn', options: ['expiry', 'signal', 'dateTime'], value: basedOn };
    case 'source':
      return { id: 'source', options: ['open', 'high', 'low', 'close', 'hlc3'], value: source };
    default:
      return { id: '', options: [], value: '' };
  }
};
