import React, { useEffect, useState } from 'react';
import { getLastTransactionForMultipleRuns, getRuns } from 'common/api/run';
import { ORDER_TYPES } from 'common/constants/index';
import LoadingSpinner from 'common/components/LoadingSpinner';
import LiveRunCard from './LiveRunCard';

const propTypes = {};
const defaultProps = {};

// TODO: Move this as a seperate generic component outside of Home.
const LiveTransactions = () => {
  const { userPermissions } = window;
  const isEnabled = _.get(userPermissions, 'socketTransactions.isEnabled', false);
  if (!isEnabled) return null;

  const [runningStrategies, setRunningStrategies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { automatic: { value: automatic }, oneClick: { value: oneclick } } = ORDER_TYPES;

  const getSortId = (orderType) => {
    switch (orderType) {
      case automatic: return 0;
      case oneclick: return 1;
      default: return 2;
    }
  };

  const orderedStrategies = _.orderBy(runningStrategies, ['sortId', 'created_at'], ['asc', 'desc']);

  useEffect(() => {
    async function fetchOrders() {
      const resultRuns = await getRuns({
        params:
         { offsetRunId: 0, locale: I18n.locale, filter_type: 'running_live_runs' }
      });
      const lastTransactions = await getLastTransactionForMultipleRuns(_.map(resultRuns, 'id'));

      const modifiedRuns = _.map(resultRuns, (resultRun) => {
        const { order_type: orderType } = resultRun;
        return { ...resultRun, sortId: getSortId(orderType), orders: lastTransactions[resultRun.id] };
      });

      setIsLoading(false);
      setRunningStrategies(modifiedRuns);
    }
    fetchOrders();
  }, []);

  const renderRunningStrategies = (run, idx) => {
    return <LiveRunCard run={run} key={idx} />;
  };

  if (_.isEmpty(orderedStrategies)) return null;

  return (
    <div className="live-transactions-container mb-6">
      <h4 className="mb-4">Recent Transactions</h4>
      <LoadingSpinner isLoading={isLoading}>
        {_.map(orderedStrategies, renderRunningStrategies)}
      </LoadingSpinner>
    </div>
  );
};

LiveTransactions.propTypes = propTypes;
LiveTransactions.defaultProps = defaultProps;

export default LiveTransactions;
