import React, { useEffect, useRef } from 'react';
import { Fireworks } from 'fireworks-js';
import PropTypes from 'prop-types';

const propTypes = {
  intensity: PropTypes.number,
};

const defaultProps = {
  intensity: 30,
};

const FireworksBlast = ({
  intensity,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    // Initialize fireworks instance
    const fireworks = new Fireworks(canvasRef.current, {
      speed: 5,
      acceleration: 1.05,
      friction: 0.98,
      gravity: 1.2,
      particles: 150, // Number of particles per firework (blast effect)
      explosion: 4, // Blast effect size
      intensity, // Number of fireworks per second
      trace: 5,
      autoresize: true,
    });

    // Start fireworks on component mount
    fireworks.start();

    // Cleanup function to stop fireworks when component unmounts
    return () => fireworks.stop();
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'transparent',
      }}
    />
  );
};

FireworksBlast.propTypes = propTypes;
FireworksBlast.defaultProps = defaultProps;

export default FireworksBlast;
