import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/ModalComponent';
import classNames from 'classnames';

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  onCloseModal: PropTypes.func,
  isSuccess: PropTypes.bool
};
const defaultProps = {
  title: '', message: '', onCloseModal: () => { }, isSuccess: true
};

const SuccessFailureMessageModal = ({
  title, message, onCloseModal, isSuccess
}) => {
  const divClassName = classNames('', {
    message: isSuccess,
    failure: !isSuccess
  });

  return (
    <Modal
      onClose={onCloseModal}
      title={title}
      size="modal-sm"
      onSave={onCloseModal}
      okBtnTitle="Done"
    >
      <div className={divClassName}>{message}</div>
    </Modal>
  );
};

SuccessFailureMessageModal.propTypes = propTypes;
SuccessFailureMessageModal.defaultProps = defaultProps;

export default SuccessFailureMessageModal;
