import { schemaShouldShowConfigTypes, schemaTypes } from '../../outputSchema/config';

export const buildOutputSchemaFromJsonSchema = (jsonSchema, parentPath) => {
  const { type } = jsonSchema;
  if (type === schemaTypes.array) {
    throw new Error('Array type is not supported yet');
  } else if (type === schemaTypes.object) {
    return _.flatMap(jsonSchema.properties, (itemConfig, name) => {
      return buildOutputSchemaFromJsonSchema(itemConfig, `${parentPath}.${name}`);
    });
  } else {
    return {
      name: parentPath,
      type: jsonSchema.type,
      indicatorType: 'customScript',
      category: 'indicator',
      label: parentPath,
      shouldShowConfigs: [
        schemaShouldShowConfigTypes.adjustmentLeftOperand,
        schemaShouldShowConfigTypes.adjustmentRightOperand,
        schemaShouldShowConfigTypes.entryLeftOperand,
        schemaShouldShowConfigTypes.entryRightOperand,
        schemaShouldShowConfigTypes.exitLeftOperand,
        schemaShouldShowConfigTypes.exitRightOperand,
        schemaShouldShowConfigTypes.intentValuePath,
      ],
      priority: 70,
      schemaName: parentPath
    };
  }
};
