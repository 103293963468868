import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import ReactHtmlParser from 'react-html-parser';
import { getBrokerImage, reDirectPage } from '../config';
import UserInput from '../CommonComponents/UserInput';

const propTypes = {
  broker: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

const defaultProps = {};

const Samco = ({ broker, onCloseModal }) => {
  const [configs, setConfigs] = useState({
    clientId: '', password: '', yob: ''
  });
  const { clientId, password, yob } = configs;
  const [isValid, setIsValid] = useState(true);

  const onHandleConfig = (key, value) => {
    setConfigs({ ...configs, [key]: value });
  };

  const onLogin = () => {
    if (!clientId || !password || !yob) return setIsValid(false);
    reDirectPage({
      client_id: clientId, password, yob
    }, broker);

    return '';
  };

  const modalProps = {
    onClose: onCloseModal,
    shouldShow: true,
    size: 'md',
    onSaveid: `btn-${broker}`,
    onSave: () => onLogin(),
    okBtnTitle: 'Login',
    isOkBtnDisabled: false
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="mb-6">
        {ReactHtmlParser(getBrokerImage(broker))}
        <label className="mt-2">Client Id</label>
        <UserInput
          id={`${broker}-client-id`}
          value={clientId}
          onHandleChange={(value) => { onHandleConfig('clientId', value); }}
        />
        <div className="mt-2">
          <label>Password</label>
          <UserInput
            id={`${broker}-password`}
            type="password"
            value={password}
            onHandleChange={(value) => { onHandleConfig('password', value); }}
          />
        </div>
        <div className="mt-2">
          <label>Year of Birth</label>
          <UserInput
            id={`${broker}-yob`}
            value={yob}
            onHandleChange={(value) => { onHandleConfig('yob', value); }}
          />
        </div>
        {!isValid && <div className="invalid-feedback d-block">Please provide the details</div>}
        <div className="my-2 alert alert-warning py-1 px-2">
          You need an API access from your broker in order to log in to Quantman.
        </div>
      </div>
    </ModalComponent>
  );
};

Samco.propTypes = propTypes;
Samco.defaultProps = defaultProps;

export default Samco;
