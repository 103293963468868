import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  isStarred: PropTypes.bool.isRequired,
  isRunBelongsToUser: PropTypes.bool.isRequired,
  onStarRunChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const StarRunBtn = ({ isStarred, isRunBelongsToUser, onStarRunChange }) => {
  if (!isRunBelongsToUser) return null;

  return (
    <button
      type="button"
      key="star-btn"
      className="action-btn btn-sm"
      onClick={onStarRunChange}
      data-toggle="tooltip"
      data-placement="top"
      title="Add My Strategies"
    >
      <i className="material-icons-outlined tx-16">
        {isStarred ? 'favorite' : 'favorite_border'}
      </i>
    </button>
  );
};

StarRunBtn.propTypes = propTypes;
StarRunBtn.defaultProps = defaultProps;

export default StarRunBtn;
