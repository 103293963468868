import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  filteredTitles: PropTypes.arrayOf(PropTypes.string),
  onHandleFilteredTitles: PropTypes.func.isRequired
};

const defaultProps = {
  filteredTitles: []
};

const FilteredTitles = ({ filteredTitles, onHandleFilteredTitles }) => {
  const renderFilteredTitle = (title, key) => {
    return (
      <div className="filter-list d-none" key={key}>
        <span className="badge badge-light">
          {title}
          <i
            className="material-icons"
            onClick={() => onHandleFilteredTitles(
              _.filter(filteredTitles, (filteredTitle) => filteredTitle !== title)
            )}
          >
            cancel
          </i>
        </span>
      </div>
    );
  };

  const renderClearAll = () => {
    return (
      <button
        className="btn btn-link clear-btn text-nowrap"
        type="button"
        onClick={() => onHandleFilteredTitles([])}
      >
        Clear all
      </button>
    );
  };

  return (
    <div className="filtered-wrraper">
      {!_.isEmpty(filteredTitles) && renderClearAll()}
      {_.map(filteredTitles, renderFilteredTitle)}
    </div>
  );
};

FilteredTitles.propTypes = propTypes;
FilteredTitles.defaultProps = defaultProps;

export default FilteredTitles;
