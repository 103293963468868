import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { getBrokerImage } from '../config';

const propTypes = {
  broker: PropTypes.string.isRequired
};
const defaultProps = {};

const oneIndentation = ' ';

const Sharekhan = ({ broker }) => (
  <>
    <div className="text-center mb-5">
      {ReactHtmlParser(getBrokerImage(broker))}
    </div>
    <div className="border py-2 pl-2 pr-1 rounded">
      <ol className="broker-api-step">
        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 1</h3>
            <p className="stepper-desc">
              Sign in to your Sharekhan account by clicking on this link:
              {oneIndentation}
              <b>
                <a
                  href="https://newtrade.sharekhan.com/"
                  target="blank"
                  title="Sharekhan Login step 1"
                >
                  https://newtrade.sharekhan.com/.
                </a>
              </b>
            </p>
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 2</h3>
            <p className="stepper-desc">
              Select the menu icon in the upper right corner.
            </p>
            <img
              src="/images/broker_setup_guide/sharekhan/sharekhan_step_2.png"
              className="w-100"
              alt="Sharekhan Step 2"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 3</h3>
            <p className="stepper-desc">
              Select
              {oneIndentation}
              <b>Trading API</b>
              {oneIndentation}
              from the
              {oneIndentation}
              <b>Sharekhan products</b>
              {oneIndentation}
              <b>menu</b>
            </p>

            <img
              src="/images/broker_setup_guide/sharekhan/sharekhan_step_3.png"
              className="w-100"
              alt="Sharekhan Step 3"
            />
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 4</h3>
            <p className="stepper-desc">
              Click
              {oneIndentation}
              <b>Create New App,</b>
              {oneIndentation}
              then select
              {oneIndentation}
              <b>Partners App,</b>
              {oneIndentation}
              fill out the form with following details, and click Create.
            </p>

            <div className="stepper-desc">
              <p>
                <b>App Name:</b>
                {oneIndentation}
                Quantman
                {/* <br /> */}
                {/* <b>Redirect URL:</b>
                {oneIndentation}
                https://www.quantman.in/auth/sharekhan/callback
                <br />
                <b>Postback URL:</b>
                {oneIndentation}
                https://www.quantman.in/sharekhan/postback
                <br />
                <b>App Description:</b>
                {oneIndentation}
                QuantMan */}
              </p>
            </div>

            {/* <img
              src="/images/broker_setup_guide/sharekhan/sharekhan_step_4.png"
              className="w-100"
              alt="Sharekhan Step 4"
            /> */}
          </div>
        </li>

        <li className="stepper-item">
          <div className="stepper-content">
            <h3 className="stepper-title">Step 5</h3>
            <p className="stepper-desc">
              You can now copy and paste the API details into the appropriate fields below.
            </p>
            <img
              src="/images/broker_setup_guide/sharekhan/sharekhan_step_5.png"
              className="w-100"
              alt="Sharekhan Step 5"
            />
          </div>
        </li>
      </ol>
    </div>
  </>
);

Sharekhan.propTypes = propTypes;
Sharekhan.defaultProps = defaultProps;

export default Sharekhan;
