import React, { useState } from 'react';

import { BROKERS } from 'common/constants';
import AuthenticationModal from '../AuthenticationModal/index';

const propTypes = {};
const defaultProps = {};

const KotakNeoLogin = () => {
  const oneIndentation = ' ';
  const [currentBroker, setCurrentBroker] = useState('');

  return (
    <div className="borker-login login-panel-wrapper">
      <div className="panel">
        <div className="bg-white p-5 rounded text-center">
          <h4>
            Welcome to
            {oneIndentation}
            <b>Quantman</b>
          </h4>
          <p className="my-3 tx-16">
            Explore, Analyse and Learn via backtests on
            {oneIndentation}
            <b>6+ years</b>
            {oneIndentation}
            of data.
            Deploy strategies on live market with order execution
            integrated with Kotak Neo API.
            <br />
            <br />
            <div
              onClick={() => { setCurrentBroker(BROKERS.kotak_neo.id); }}
              className="btn btn-outline-primary broker"
            >
              Login via
              {oneIndentation}
              <b>Kotak Neo</b>
            </div>
          </p>
        </div>
        <AuthenticationModal broker={currentBroker} onCloseModal={() => setCurrentBroker('')} />
      </div>
    </div>
  );
};

KotakNeoLogin.propTypes = propTypes;
KotakNeoLogin.defaultProps = defaultProps;

export default KotakNeoLogin;
