import { advancedFeatureConfigs } from 'modules/QuantBuilder/config/signalConfigs';
import {
  addAdjustmentBasedOnMoveToCost, isEligibleToMoveToCostTransactionConfigs
} from './moveToCostAdjustment';
import { addAdjustmentBasedOnReEntry } from './reEntryAdjustment';
import { addAdjustmentBasedOnReExecute } from './reExecuteAdjustment';

export const getAdjustmentSignalConfigsWithoutAutoAdjustment = (adjustmentSignalConfigs) => {
  return _.chain(adjustmentSignalConfigs).map((adjustmentSignalConfig) => {
    if (_.isEmpty(adjustmentSignalConfig)) return adjustmentSignalConfig;

    const filteredAdjustmentSignalConfig = _.filter(adjustmentSignalConfig, (config) => {
      const isAutoAdjustment = _.get(config, 'mode.isAutoAdjustment', false);

      return !isAutoAdjustment;
    });

    if (_.isEmpty(filteredAdjustmentSignalConfig)) return undefined;

    return filteredAdjustmentSignalConfig;
  }).compact().value();
};

export const getAdjustmentActionConfigsWithoutAutoAdjustment = (adjustmentActionConfigs) => {
  return _.chain(adjustmentActionConfigs).map((adjustmentActionConfig) => {
    if (_.isEmpty(adjustmentActionConfig)) return adjustmentActionConfig;

    const filteredAdjustmentActionConfig = _.filter(adjustmentActionConfig, (config) => {
      const isAutoAdjustment = _.get(config, 'mode.isAutoAdjustment', false);

      return !isAutoAdjustment;
    });

    if (_.isEmpty(filteredAdjustmentActionConfig)) return undefined;

    return filteredAdjustmentActionConfig;
  }).compact().value();
};

export const onConfigureAutoAdjustment = (args) => {
  const {
    orderConfigs, orderConfigIndex, newTransactionConfigs, maxInstrumentsInGroup
  } = args;

  const currentOrderConfig = _.get(orderConfigs, orderConfigIndex, {});
  const adjustmentActionConfigs = _.get(currentOrderConfig, 'adjustmentActionConfigs', []);
  const adjustmentSignalConfigs = _.get(currentOrderConfig, 'adjustmentSignalConfigs', []);
  const featureConfigs = _.get(currentOrderConfig, 'advancedFeatureConfigs', advancedFeatureConfigs());
  const { isMoveToCost } = featureConfigs;

  const isPermissionToMoveToCost = _.get(window, 'userPermissions.moveToCost.isEnabled', false)
    && isEligibleToMoveToCostTransactionConfigs(newTransactionConfigs) && isMoveToCost;

  const isPermissionToEnableReEntry = _.get(window, 'userPermissions.reEntry.isEnabled', false);
  const isPermissionToEnableReExecute = _.get(window, 'userPermissions.reExecute.isEnabled', false);

  const filteredAdjustmentSignalConfigs = getAdjustmentSignalConfigsWithoutAutoAdjustment(
    adjustmentSignalConfigs
  );
  const filteredAdjustmentActionConfigs = getAdjustmentActionConfigsWithoutAutoAdjustment(
    adjustmentActionConfigs
  );

  let initialCount = 0;
  const onChangeCount = (reAssignedCount) => { initialCount = reAssignedCount; };

  let newAdjustmentActionConfigs = [];
  let newAdjustmentSignalConfigs = [];
  // ReEntry Adjustment Added

  if (isPermissionToEnableReEntry) {
    const {
      adjustmentSignalConfigs: reEntryasc,
      adjustmentActionConfigs: reEntryaac,
      reAssignedCount: reEntryReAssignedCount
    } = addAdjustmentBasedOnReEntry(
      newTransactionConfigs, maxInstrumentsInGroup, newAdjustmentActionConfigs,
      newAdjustmentSignalConfigs, initialCount
    );
    onChangeCount(reEntryReAssignedCount);

    newAdjustmentActionConfigs = reEntryaac;
    newAdjustmentSignalConfigs = reEntryasc;
  }

  if (isPermissionToEnableReExecute) {
    // ReExecute Adjustment Added
    const {
      adjustmentSignalConfigs: reExecuteasc,
      adjustmentActionConfigs: reExecuteaac,
      reAssignedCount: reExecuteReAssignedCount
    } = addAdjustmentBasedOnReExecute(
      newTransactionConfigs, maxInstrumentsInGroup, newAdjustmentActionConfigs,
      newAdjustmentSignalConfigs, initialCount
    );

    onChangeCount(reExecuteReAssignedCount);

    newAdjustmentActionConfigs = reExecuteaac;
    newAdjustmentSignalConfigs = reExecuteasc;
  }

  // MoveToCost Adjustment Added
  if (isPermissionToMoveToCost) {
    const {
      adjustmentSignalConfigs: moveToCostasc,
      adjustmentActionConfigs: moveToCostaac,
      reAssignedCount: moveToCostReAssignedCount
    } = addAdjustmentBasedOnMoveToCost(
      newTransactionConfigs, maxInstrumentsInGroup, newAdjustmentActionConfigs,
      newAdjustmentSignalConfigs, initialCount, onChangeCount
    );

    onChangeCount(moveToCostReAssignedCount);

    newAdjustmentActionConfigs = moveToCostaac;
    newAdjustmentSignalConfigs = moveToCostasc;
  }

  orderConfigs[orderConfigIndex] = {
    ...orderConfigs[orderConfigIndex],
    transactionConfigs: newTransactionConfigs,
    adjustmentSignalConfigs: [...newAdjustmentSignalConfigs, ...filteredAdjustmentSignalConfigs],
    adjustmentActionConfigs: [...newAdjustmentActionConfigs, ...filteredAdjustmentActionConfigs]
  };

  return orderConfigs;
};
