import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  status: PropTypes.string,
  errorInfo: PropTypes.shape({})
};
const defaultProps = {
  status: '',
  errorInfo: {}
};

const RunErrorMessage = ({ status, errorInfo }) => {
  if (status !== 'errored' || !_.get(errorInfo, 'message')) {
    return null;
  }

  return (
    <div className="alert alert-danger" role="alert">
      {_.get(errorInfo, 'message')}
    </div>
  );
};

RunErrorMessage.propTypes = propTypes;
RunErrorMessage.defaultProps = defaultProps;

export default RunErrorMessage;
