import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { runPropTypes } from 'common/propTypes';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import ShowCaseRunCard from './ShowCaseRunCard/index';

const MINIMIZED_SHOW_COUNT = 6;

const propTypes = {
  showcasedRuns: PropTypes.arrayOf(runPropTypes).isRequired,
  currentUserDefaultFolderId: PropTypes.number
};
const defaultProps = { currentUserDefaultFolderId: null };

const ShowCaseRunCards = ({ showcasedRuns, currentUserDefaultFolderId }) => {
  if (_.isEmpty(showcasedRuns)) return null;
  const [isExpanded, hasExpandedChange] = useState(false);

  const runs = isExpanded ? showcasedRuns : _.take(showcasedRuns, MINIMIZED_SHOW_COUNT);

  const renderShowCaseRun = (run, idx) => (
    <ShowCaseRunCard run={run} key={idx} currentUserDefaultFolderId={currentUserDefaultFolderId} />
  );

  const renderShowMore = () => {
    if (showcasedRuns.length <= MINIMIZED_SHOW_COUNT) { return null; }

    return (
      <div className="d-flex justify-content-center mt-5">
        <button
          type="button"
          className="btn btn-sm btn-outline-primary btn-pill"
          onClick={() => hasExpandedChange(!isExpanded)}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      </div>
    );
  };

  return (
    <>
      <h4 className="mb-4">Sample Strategies</h4>
      <div className="row showcase-runcards">{_.map(runs, renderShowCaseRun)}</div>
      {renderShowMore()}
    </>
  );
};

ShowCaseRunCards.propTypes = propTypes;
ShowCaseRunCards.defaultProps = defaultProps;

export default withErrorBoundary(ShowCaseRunCards);
