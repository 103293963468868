export const setLocalStorage = (key, value) => {
  let localStorage = null;
  try { localStorage = _.get(window, 'localStorage', null); } catch (e) { localStorage = null; }

  if (!localStorage) return;

  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  let localStorage = null;
  try { localStorage = _.get(window, 'localStorage', null); } catch (e) { localStorage = null; }

  if (!localStorage) return '';

  return localStorage.getItem(`${key}`);
};
