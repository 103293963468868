import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  filterConfigs: PropTypes.shape({
    authorNames: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onUpdateFilterConfigs: PropTypes.func.isRequired,
  strategies: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const defaultProps = {};

const AuthorNameFilter = ({ strategies, filterConfigs, onUpdateFilterConfigs }) => {
  const { authorNames } = filterConfigs;
  const options = _.chain(strategies)
    .map(({ author_name: authorName }) => (authorName || '')).compact().uniq()
    .value();

  const renderAuthorName = (authorName, idx) => {
    const onHandleAuthorNames = () => {
      if (_.includes(authorNames, authorName)) {
        return _.filter(authorNames, (value) => value !== authorName);
      }

      return [...authorNames, authorName];
    };

    return (
      <div className="form-check form-check-sm" key={idx}>
        <input
          className="form-check-input track"
          type="checkbox"
          data-track-category="Market Place Filter"
          data-track-action={authorName}
          data-track-label="Author Name filter"
          value={authorName}
          id={authorName}
          checked={_.includes(authorNames, authorName)}
          onChange={() => { }}
          onClick={() => onUpdateFilterConfigs({
            ...filterConfigs,
            authorNames: onHandleAuthorNames()
          })}
        />
        <label className="form-check-label" htmlFor={authorName}>
          {_.startCase(authorName)}
        </label>
      </div>
    );
  };
  return _.isEmpty(options) ? null : (
    <div className="mp-filter">
      <h6 className="mp-filter-title">Authors</h6>
      <div className="mp-filter-list">
        {_.map(options, renderAuthorName)}
      </div>
    </div>
  );
};

AuthorNameFilter.propTypes = propTypes;
AuthorNameFilter.defaultProps = defaultProps;

export default AuthorNameFilter;
