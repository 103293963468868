import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  content: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired
};

const defaultProps = {};

const Video = ({ content }) => {
  const { url, alt } = content;
  let allow = 'accelerometer; autoplay; ';
  allow += 'clipboard-write; encrypted-media; ';
  allow += 'gyroscope; picture-in-picture; web-share';

  return (
    <iframe
      width="100%"
      className="tip-iframe"
      height="100%"
      frameBorder="0"
      title={alt || 'video'}
      allow={allow}
      src={url}
      allowFullScreen
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    />
  );
};

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;
