import { IntentLabelConfig } from 'v2/modules/withRunForm/configs/index';

export const intentIdIntializer = (cases, pipeConfigs) => {
  const legIds = [];
  const loopIntents = (intents) => {
    _.each(intents, (intent) => {
      legIds.push(intent?.identifier?.label);
    });
  };
  _.each(cases, (caseConfig) => {
    const { entry: { intents }, adjustments } = caseConfig;
    loopIntents(intents);
    _.each(adjustments, ({ intents: adjustmentIntents }) => {
      loopIntents(adjustmentIntents);
    });
  });

  _.each(pipeConfigs, (pipeConfig) => {
    const intents = _.get(pipeConfig, 'config.legs', []);
    _.each(intents, (intent) => {
      IntentLabelConfig.setConfigNameAndValue(pipeConfig?.name, intent?.identifier?.label);
    });
  });

  const maxLegId = _.chain(legIds)
    .map((legId) => Number(legId))
    .max()
    .value();
  if (!maxLegId) return;

  IntentLabelConfig.setConfigNameAndValue('default', maxLegId);
};
