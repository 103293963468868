import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { setRootValue } from 'v2/modules/withRunForm/redux/actions';

const RunName = () => {
  const dispatch = useDispatch();
  const runName = useSelector((state) => getSelectorBasedStoreValue(state, 'runName'));
  const onUpdateRunName = (value) => dispatch(setRootValue({ runName: value }));

  return (
    <div className="run-name-input">
      <label className="label_style">
        Run Name
      </label>
      <div className="input-wrapper">
        <input
          type="text"
          value={runName || ''}
          className="form-control form-control-sm"
          placeholder="Enter Run Name"
          onChange={(event) => onUpdateRunName(event.target.value)}
        />
      </div>
    </div>
  );
};

export default RunName;
