import { PipeDefinitionUtility } from 'v2/common/quantConfig/index';
import { onUpdatePipeConfigBasedOnIndicator } from '../helper';

export const suggestionPipes = () => [
  PipeDefinitionUtility.getPipeConfigDefaults('aroon').type,
  PipeDefinitionUtility.getPipeConfigDefaults('averageDirectionalIndex').type,
  PipeDefinitionUtility.getPipeConfigDefaults('exponentialMovingAverage').type,
  PipeDefinitionUtility.getPipeConfigDefaults('movingAverageConvergenceDivergence').type,
  PipeDefinitionUtility.getPipeConfigDefaults('simpleMovingAverage').type,
  PipeDefinitionUtility.getPipeConfigDefaults('weightedMovingAverage').type,
  PipeDefinitionUtility.getPipeConfigDefaults('bollingerBand').type,
  PipeDefinitionUtility.getPipeConfigDefaults('pivotPoints').type,
  PipeDefinitionUtility.getPipeConfigDefaults('superTrend').type,
];

export const suggestionCandleConfig = (lastAddedConfig, suggestionPipeType, pipeConfigs, segment) => {
  switch (suggestionPipeType) {
    case PipeDefinitionUtility.getPipeConfigDefaults('currentCandle').type: // particulary for currentCandle
      const currentCandleConfig = onUpdatePipeConfigBasedOnIndicator(
        segment,
        PipeDefinitionUtility.getPipeConfigDefaults('currentCandle')
      );
      const {
        config: currentCandleValueConfig, name: currentCandleName, type: currentCandleType
      } = currentCandleConfig;
      const { config: { candleInterval } } = lastAddedConfig;

      const currentCandlesInPipeConfig = _.filter(pipeConfigs, ({ type }) => type === currentCandleType);

      if (!candleInterval && !_.isEmpty(currentCandlesInPipeConfig)) return {};
      if (!candleInterval) return onUpdatePipeConfigBasedOnIndicator(segment, currentCandleConfig);

      const isSameConfigPresent = _.find(pipeConfigs, (
        { type, config: { candleInterval: pipeCandleInterval } }
      ) => type === currentCandleType && pipeCandleInterval === candleInterval);

      if (isSameConfigPresent) return {};

      return onUpdatePipeConfigBasedOnIndicator(segment, {
        ...currentCandleConfig,
        name: `${currentCandleName} ${candleInterval}`,
        config: { ...currentCandleValueConfig, candleInterval }
      });

    default:
      return {};
  }
};

export const rightOperandSuggestionName = (type, lastPipeName) => {
  switch (type) {
    case PipeDefinitionUtility.getPipeConfigDefaults('aroon').type:
      return lastPipeName === 'aroonUp' ? lastPipeName : `${lastPipeName}.aroonUp`;
    case PipeDefinitionUtility.getPipeConfigDefaults('averageDirectionalIndex').type:
      return lastPipeName === 'pdi' ? lastPipeName : `${lastPipeName}.pdi`;
    case PipeDefinitionUtility.getPipeConfigDefaults('movingAverageConvergenceDivergence').type:
      return lastPipeName === 'macd' ? lastPipeName : `${lastPipeName}.macd`;
    case PipeDefinitionUtility.getPipeConfigDefaults('bollingerBand').type:
      return lastPipeName === 'upperBollingerBand' ? lastPipeName : `${lastPipeName}.upperBollingerBand`;
    case PipeDefinitionUtility.getPipeConfigDefaults('pivotPoints').type:
      return lastPipeName === 'pivotPoint' ? lastPipeName : `${lastPipeName}.pivotPoint`;
    default:
      return lastPipeName;
  }
};

export const getIndicatorBasedOnSignalConfig = (
  outputSchema, lastAddedPipeConfig, signalConfigs, leftOperandName
) => {
  const { name: lastPipeName, type } = lastAddedPipeConfig;

  const matchingPipeInSchema = _.find(outputSchema, ({ name }) => name === lastPipeName);
  // have to do for aroon types.. like subcategory..
  const name = _.get(matchingPipeInSchema, 'name', rightOperandSuggestionName(type, lastPipeName));

  return {
    indicatorName: name,
    signalConfig: {
      conditionOperator: signalConfigs.length ? 'and' : '',
      leftOperand: `${leftOperandName}.close`,
      operator: 'crossesAbove',
      rightOperand: name,
    }
  };
};
