import { SEGMENT_CATEGORY_IN_QUANT } from 'common/constants/index';
import { TRANSACTION_MODES } from 'modules/TransactionsBuilder/configs';
import { INDICATORS_LENGTH, indicatorsTypes } from 'ui/run/RunShow/config';

export const getOpenOrdersData = (openOrdersData, latestOrders) => {
  const openOrderTransactionId = _.get(openOrdersData, [0, 'transactionId'], null);
  const latestOrderTransactionId = _.get(latestOrders, [0, 'transactionId'], null);
  const isNewTransaction = openOrderTransactionId !== latestOrderTransactionId;

  return _.map(latestOrders, (order, orderIndex) => {
    const {
      tradeSymbol, transactionId, quantity, isLiquid, price, timestamp, transactionType, isEntryOrder
    } = order;
    const orderDetails = {
      isLiquid, price, timestamp, transactionType
    };

    return {
      tradeSymbol,
      transactionId,
      quantity,
      entry: (isNewTransaction && isEntryOrder) ? orderDetails
        : _.get(openOrdersData, [orderIndex, 'entry'], {}),
      exit: !isEntryOrder ? orderDetails : {},
    };
  });
};

const getRespectiveInstruments = (value, instrumentRepresentName, instrumentGroup) => {
  const indicatorValuesOfInstrument = {};

  _.each(instrumentGroup, (instrument, idx) => {
    indicatorValuesOfInstrument[instrument] = (
      instrumentRepresentName === `item${idx}`
      || instrumentRepresentName === 'basket'
      || instrumentRepresentName === 'INDIAVIX'
    ) ? value : '';
  });

  return indicatorValuesOfInstrument;
};

const getSubIndicatorsData = (indicator, tockGroup, instrumentGroup, indicatorsType) => {
  const { name, config: { valuePaths } } = indicator;
  const indicatorValues = _.get(tockGroup, [indicator.name], 0);
  const isPairIndicators = indicatorsType === indicatorsTypes.pair;
  const [instrumentRepresentName] = _.split(_.head(valuePaths), '.');

  const getSubIndicatorData = (value, key) => {
    return isPairIndicators
      ? { [key]: value }
      : getRespectiveInstruments(value, instrumentRepresentName, instrumentGroup);
  };

  if (_.isObject(indicatorValues)) {
    return _.map(indicatorValues, (value, key) => ({
      subTitle: key,
      ...getSubIndicatorData(value, key)
    }));
  }

  return [].concat(getSubIndicatorData(indicatorValues, name));
};

export const getFormattedIndicatorsData = (args) => {
  const {
    pipeConfigs, tockGroup, instrumentGroup, indicatorsType
  } = args;
  const indicators = _.filter(pipeConfigs, ({ config: { valuePaths } }) => (
    (valuePaths || []).length === INDICATORS_LENGTH[indicatorsType]
  ));

  return _.map(indicators, (indicator) => ({
    title: indicator.name,
    indicatorData: getSubIndicatorsData(indicator, tockGroup, instrumentGroup, indicatorsType)
  }));
};

export const getLiveMonitorData = (quantConfig) => {
  const data = [
    { label: 'Future Price', value: 'close', segment: 'month1Price' },
    { label: 'Future Open Interest', value: 'openInterest', segment: 'month1Price' },
    { label: 'Future Volume', value: 'volume', segment: 'month1Price' },
    { label: 'Equity Price', value: 'close', segment: 'equity' },
    { label: 'Equity Volume', value: 'volume', segment: 'equity' }
  ];

  const {
    futureAndEquity, futureAndOption, futureConfig, equityConfig, optionAndEquity, combinedAllConfigs
  } = TRANSACTION_MODES;
  const { orderConfigs, pipeConfigs } = quantConfig;

  const isFuturePresentInOrderConfigs = _.some(orderConfigs, ({ transactionConfigs }) => {
    const isFutureMode = _.some(transactionConfigs, ({ mode }) => {
      const { transaction } = mode;
      if (_.includes(
        [futureAndEquity, futureAndOption, futureConfig, combinedAllConfigs], transaction
      )) return true;

      return false;
    });
    return isFutureMode;
  });

  const isEquityPresentInOrderConfigs = _.some(orderConfigs, ({ transactionConfigs }) => {
    const isEquityMode = _.some(transactionConfigs, ({ mode }) => {
      const { transaction } = mode;
      if (_.includes(
        [futureAndEquity, equityConfig, optionAndEquity, combinedAllConfigs], transaction
      )) return true;

      return false;
    });
    return isEquityMode;
  });

  const isFuturePresentInPipeConfigs = _.some(pipeConfigs, (pipeConfig) => {
    const { config } = pipeConfig;
    const valuePaths = _.get(config, 'valuePaths', []);
    const isMonth1Present = _.some(valuePaths, (valuePath) => _.includes(
      valuePath, SEGMENT_CATEGORY_IN_QUANT.month1Price
    ));
    return isMonth1Present;
  });

  const isEquityPresentInPipeConfigs = _.some(pipeConfigs, (pipeConfig) => {
    const { config } = pipeConfig;
    const valuePaths = _.get(config, 'valuePaths', []);
    const isEquityPresent = _.some(valuePaths, (valuePath) => _.includes(
      valuePath, SEGMENT_CATEGORY_IN_QUANT.equity
    ));

    return isEquityPresent;
  });

  const isFutureShow = isFuturePresentInOrderConfigs || isFuturePresentInPipeConfigs;
  const isEquityShow = isEquityPresentInOrderConfigs || isEquityPresentInPipeConfigs;

  return _.chain(data).filter(({ segment }) => {
    if ((segment === SEGMENT_CATEGORY_IN_QUANT.equity && isEquityShow)
      || (segment === SEGMENT_CATEGORY_IN_QUANT.month1Price && isFutureShow)
    ) return true;

    return false;
  }).value();
};

export const formatV2QuantMonitorData = (tockGroup) => {
  return _.map(tockGroup, (tock) => {
    const { lastTick, output } = tock;
    return {
      ...output,
      timestamp: `${lastTick}Z`,
      profitDetail: {
        profitPrice: _.get(output, 'profitPrice', 0),
        profitPercentage: _.round(_.get(output, 'profitPercent', 0), 2)
      }
    };
  });
};
