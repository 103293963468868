import React from 'react';

import { quantConfigPropTypes } from 'common/propTypes';

const propTypes = {
  quantConfig: quantConfigPropTypes,
};

const defaultProps = {
  quantConfig: null
};
class RunConfig extends React.Component {
  render() {
    const { quantConfig } = this.props;

    return <div id="quant-config-hidden" className="hidden-for-future">{JSON.stringify(quantConfig)}</div>;
  }
}

RunConfig.propTypes = propTypes;
RunConfig.defaultProps = defaultProps;

export default RunConfig;
