import React from 'react';
import PropTypes from 'prop-types';
import ReferralCollector from './ReferralCollector';
import Dashboard from './Dashboard/index';

const propTypes = {
  redirectPath: PropTypes.string,
  isDashboard: PropTypes.bool,
};
const defaultProps = {
  redirectPath: '/',
  isDashboard: false,
};

const Referrer = ({ redirectPath, isDashboard }) => {
  if (isDashboard) return <Dashboard />;

  return <ReferralCollector redirectPath={redirectPath} />;
};

Referrer.propTypes = propTypes;
Referrer.defaultProps = defaultProps;

export default Referrer;
