import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import
StrikeBasedOnSelector
  from 'modules/TransactionsBuilder/NewTransactionBuilder/components/StrikeBasedOnSelector';
import InstrumentGroupBuilder from 'common/components/InstrumentGroupBuilder';
import { getEmptyTransactionConfig } from 'modules/TransactionsBuilder/configs';
import { instrumentGroupsPropTypes } from 'common/propTypes';
import
OptionsExpiryCycle
  from 'modules/TransactionsBuilder/NewTransactionBuilder/components/OptionsExpiryCycle/index';

const propTypes = {
  quantConfig: PropTypes.shape({
    optionsExpiryCycle: PropTypes.string,
    instrumentGroups: instrumentGroupsPropTypes,
    optionStrikeCalculationBasedOn: PropTypes.string.isRequired,
  }).isRequired,
  onQuantConfigChange: PropTypes.func.isRequired,
  segment: PropTypes.string.isRequired,
  onChangeSegment: PropTypes.func.isRequired,
  segments: PropTypes.arrayOf(PropTypes.string).isRequired
};
const defaultProps = {};

const TopSection = ({
  quantConfig, onQuantConfigChange, segment, onChangeSegment, segments
}) => {
  const {
    optionsExpiryCycle,
    instrumentGroups,
    optionStrikeCalculationBasedOn,
  } = quantConfig;
  const maxInstrumentsInGroup = _.isEmpty(instrumentGroups)
    ? 1
    : _.head(instrumentGroups).length;

  return (
    <div className="instruments-details">
      <div className="options-instruments-group">
        <label className="col-form-label col-form-label-sm">Instruments</label>
        <InstrumentGroupBuilder
          shouldHideLabel
          maxInstrumentsInGroup={maxInstrumentsInGroup}
          onRemoveInstrumentGroup={() => onQuantConfigChange({
            instrumentGroups: []
          })}
          instrumentGroups={instrumentGroups}
          onAddInstrumentGroup={(newInstrumentGroup) => onQuantConfigChange({
            instrumentGroups: [newInstrumentGroup]
          })}
          segment={segment}
          onChangeSegment={onChangeSegment}
          segments={segments}
        />
      </div>

      <div>
        <OptionsExpiryCycle
          shouldDisableExpiryCycle={false}
          instrumentGroups={instrumentGroups}
          startDate={moment.utc().toDate()}
          transactionConfig={getEmptyTransactionConfig()}
          optionsExpiryCycle={optionsExpiryCycle}
          onOptionsExpiryCycleChange={(newOptionsExpiryCycle) => onQuantConfigChange({
            optionsExpiryCycle: newOptionsExpiryCycle
          })}
        />
      </div>

      <div className="strike-select">
        <StrikeBasedOnSelector
          strikeBasedOn={optionStrikeCalculationBasedOn}
          onStrikeBasedOnChange={(newStrikeBasedOn) => onQuantConfigChange({
            optionStrikeCalculationBasedOn: newStrikeBasedOn
          })}
          outputSchema={[]}
          segment={segment}
          instrumentGroups={instrumentGroups}
        />
      </div>
    </div>
  );
};

TopSection.propTypes = propTypes;
TopSection.defaultProps = defaultProps;

export default TopSection;
