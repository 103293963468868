import { removeExchangeFromInstrument } from 'common/utils/InstrumentUtils';
import React, { useContext } from 'react';
import { getInstrumentData } from 'ui/run/RunShow/OrderStore/analytics/index';

import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import BarChart from './chart/BarChart';

const propTypes = {};
const defaultProps = {};

const InstrumentGroupAnalytics = () => {
  const { completedTransactions } = useContext(OrderContext);

  const instrumentGroupsAnalystics = getInstrumentData(completedTransactions);

  const renderChart = (instrumentGroupAnalysticsData, instrument) => {
    const splitedInstruments = _.split(instrument, ',');
    const sanitizeInstruments = _.map(splitedInstruments,
      (ins) => removeExchangeFromInstrument(ins));

    return (
      <BarChart
        key={instrument}
        analyticsData={instrumentGroupAnalysticsData}
        title={splitedInstruments ? sanitizeInstruments.join('-') : instrument}
      />
    );
  };

  return (
    <div className="col-md-12 instrument-groups">
      <h6>Instruments</h6>
      {_.map(instrumentGroupsAnalystics, renderChart)}
    </div>
  );
};

InstrumentGroupAnalytics.propTypes = propTypes;
InstrumentGroupAnalytics.defaultProps = defaultProps;

export default InstrumentGroupAnalytics;
