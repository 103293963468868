import { getStrikeIndexDisplayName } from 'v2/common/components/StrikeBasedSelector/config';
import { StrikeUtility } from 'v2/common/components/StrikeBasedSelector/utility';

export const getStrikeStatement = (strike, optionType, outputSchema) => {
  const {
    isIndicatorBased, isIndexBased, multiplier, valueType
  } = StrikeUtility.getStrikeObj(strike);

  const strikeLabel = StrikeUtility.getStrikeLabel(strike);

  const multiplierLabel = multiplier ? `Multiplier ${multiplier}` : '';

  if (isIndicatorBased) {
    const { valuePath } = strike;
    const schema = outputSchema.find(({ name }) => name === valuePath);

    const indicatorLabel = _.isEmpty(schema)
      ? valuePath || ''
      : `${schema?.tagConfig?.tag || ''} ${schema?.label}`;

    return (
      `${strikeLabel} ${indicatorLabel} `
      + `${getStrikeIndexDisplayName(
        strike[valueType] || 0,
        optionType
      )} ${multiplierLabel}`
    );
  }

  if (isIndexBased) {
    return (
      `${strikeLabel} `
      + `${getStrikeIndexDisplayName(
        strike[valueType] || 0,
        optionType
      )} ${multiplierLabel}`
    );
  }

  return `${strikeLabel} ${strike[valueType] || ''} ${multiplierLabel}`;
};
