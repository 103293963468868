import React from 'react';
import PropTypes from 'prop-types';

import TemplatePicker from 'common/components/TemplatePicker/index';
import { I18N_SCOPE } from '../config';

const propTypes = { isEditQuant: PropTypes.bool };

const defaultProps = { isEditQuant: false };

const RunFormHeader = ({ isEditQuant }) => {
  return (
    <div className="page-heading">
      <h4 className="mb-3 mb-md-0">
        {isEditQuant ? I18n.t('edit_strategy', I18N_SCOPE) : I18n.t('create_strategy', I18N_SCOPE)}
      </h4>
      <div className="dropdown position-relative strategie-dropdown">
        <button
          className="btn btn-primary dropdown-toggle track"
          type="button"
          data-track-category="Prebuild Strategies"
          data-track-action="prebuild"
          data-track-label="Quant Form track"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          name="Ready Made Strategies Template In Quant"
        >
          Ready Made Strategies Template
        </button>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          <TemplatePicker isMinified />
        </div>
      </div>
    </div>
  );
};

RunFormHeader.propTypes = propTypes;
RunFormHeader.defaultProps = defaultProps;

export default RunFormHeader;
