import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import HoverMessage from 'common/components/HoverMessage';

const propTypes = {
  isPublicRun: PropTypes.bool.isRequired,
  runId: PropTypes.number.isRequired,
  isBelongsToCurrentUser: PropTypes.bool.isRequired,
  onPublicRunChange: PropTypes.func.isRequired,
  isHiddenSignal: PropTypes.bool.isRequired
};
const defaultProps = {};

const SocialShare = ({
  isPublicRun, runId, onPublicRunChange, isBelongsToCurrentUser, isHiddenSignal
}) => {
  const encodedUrl = encodeURI(`${FRONTEND_PUBLIC_URL}/runs/${runId}`);
  const socialShareIconsClass = classNames(
    'social-link d-flex gap-5 justify-content-center',
    !isPublicRun || isHiddenSignal ? 'disabled' : ''
  );

  const renderPublicPrivateSwitchBtn = () => (
    <label>
      <input
        type="checkbox"
        checked={isPublicRun}
        onChange={onPublicRunChange}
      />
      <span className="private">Private</span>
      <span className="lever mx-1" />
      <span className="public">Public</span>
    </label>
  );

  const renderSocialShareBtns = () => (
    <div className="share-options switch-btn gap-5">
      {isBelongsToCurrentUser && !isHiddenSignal && renderPublicPrivateSwitchBtn()}
      <div className={socialShareIconsClass}>
        <a
          className="social track"
          target="_blank"
          rel="noreferrer"
          href={`http://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
          data-track-category="Social Share"
          data-track-action="facebook"
          data-track-label="Social Share"
        >
          <img src="/facebook.svg" alt="facebook" />
        </a>

        <a
          className="social track"
          target="_blank"
          rel="noreferrer"
          data-track-category="Social Share"
          data-track-action="twitter"
          data-track-label="Social Share"
          href={`http://twitter.com/intent/tweet?url=${encodedUrl}`}
        >
          <img src="/twitter.svg" alt="twitter" />
        </a>
      </div>
    </div>
  );

  if (isPublicRun) return renderSocialShareBtns();

  return (
    <HoverMessage
      title="Enable Share"
      message={!isHiddenSignal
        ? 'To Share and allow others to view your run, switch to Public'
        : 'This run has disabled To Share by the Author'}
    >
      {renderSocialShareBtns()}
    </HoverMessage>
  );
};

export default SocialShare;

SocialShare.defaultProps = defaultProps;
SocialShare.propTypes = propTypes;
