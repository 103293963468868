import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { outputSchemaPropTypes } from 'common/propTypes';
import _ from 'lodash';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';
import ReactHtmlParser from 'react-html-parser';
import { signalTypes } from 'v2/modules/withRunForm/configs/index';
import { schemaShouldShowConfigTypes } from 'v2/common/quantConfig/outputSchema/config';
import { OutputSchemaUtility, TypeheadSchemaUtility } from 'v2/common/quantConfig/outputSchema/utility';

const propTypes = {
  leftOperand: PropTypes.string,
  outputSchema: outputSchemaPropTypes.isRequired,
  onChangeLeftOperand: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  signalType: PropTypes.string.isRequired,
};

const defaultProps = {
  leftOperand: ''
};

const LeftOperand = ({
  leftOperand, outputSchema, onChangeLeftOperand, control, errors, signalType
}) => {
  const { exitSignal, entrySignal } = signalTypes;

  const validator = {
    validate: (value) => {
      if (_.isArray(value) && _.isEmpty(value[0]?.searchLabel)) return 'Please select a Left operand';

      return true;
    },
  };

  const types = useMemo(() => {
    if (signalType === entrySignal) return [schemaShouldShowConfigTypes.entryLeftOperand];
    if (signalType === exitSignal) return [schemaShouldShowConfigTypes.exitLeftOperand];

    return [schemaShouldShowConfigTypes.adjustmentLeftOperand];
  }, [entrySignal, exitSignal, signalType]);

  const options = useMemo(() => {
    return _.chain(outputSchema)
      .thru((schema) => (signalType ? OutputSchemaUtility.filterByTypes(schema, types) : schema))
      .sortBy('priority')
      .thru((schema) => OutputSchemaUtility.filterByOnlyEnabled(schema))
      .map((schema) => TypeheadSchemaUtility.getOption(schema))
      .value();
  }, [outputSchema, types, signalType]);

  const uniqueId = useMemo(() => _.uniqueId('left-operand-'), []);
  const errorMessage = useMemo(() => _.get(errors, 'leftOperand.message', ''), [errors]);
  const selectedSchema = useMemo(
    () => OutputSchemaUtility.findByName(outputSchema, leftOperand), [outputSchema, leftOperand]
  );

  const selected = useMemo(
    () => TypeheadSchemaUtility.getSelectedOption(outputSchema, leftOperand), [outputSchema, leftOperand]
  );

  return (
    <div className="left-operand operand-info d-flex align-items-center">
      <ErrorMesssage message={errorMessage}>
        <Controller
          render={({ onChange, ref, name }) => (
            <Typeahead
              className="custom-form-control"
              disabled={false}
              ref={ref}
              isInvalid={false}
              id={name}
              labelKey="searchLabel"
              options={options}
              clearButton={!_.isEmpty(selected)}
              selected={selected}
              renderMenuItemChildren={(option) => ReactHtmlParser(option.label)}
              filterBy={(
                option, filterProps
              ) => TypeheadSchemaUtility.getOptionFilteredBySearchText(option, filterProps)}
              onChange={(value) => {
                if (_.isEmpty(value)) return;

                const { id } = _.first(value);
                onChangeLeftOperand(id);
                onChange(value);
              }}
            />
          )}
          control={control}
          defaultValue={selected}
          name={uniqueId}
          rules={validator}
        />
        {selectedSchema?.infoLink && (
          <span
            className="material-icons-outlined info-icon ml-1"
            onClick={() => window.open(selectedSchema?.infoLink, '_blank')}
          >
            info
          </span>
        )}
      </ErrorMesssage>
    </div>
  );
};

LeftOperand.propTypes = propTypes;
LeftOperand.defaultProps = defaultProps;

export default memo(LeftOperand);
