import React from 'react';
import PropTyps from 'prop-types';
import { onRegexOnlyNumber } from 'common/stringUtils/toPositiveNumberString';

const propTypes = {
  delay: PropTyps.oneOfType([PropTyps.string, PropTyps.number]).isRequired,
  onUpdate: PropTyps.func.isRequired,
};

const RenderDelayInput = ({ delay, onUpdate }) => {
  return (
    <div className="input-group input-group-sm">
      <div className="input-group-prepend">
        <span className="input-group-text">After</span>
      </div>
      <input
        type="text"
        className="form-control form-control-sm"
        value={delay}
        min="1"
        onChange={(event) => onUpdate(onRegexOnlyNumber(event.target.value))}
      />
      <div className="input-group-append">
        <span className="input-group-text">seconds</span>
      </div>
    </div>
  );
};

RenderDelayInput.propTypes = propTypes;

export default RenderDelayInput;
