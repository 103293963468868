import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';

import ErrorMesssage from 'common/components/ErrorMesssage';
import { outputSchemaPropTypes, rightOperandPropTypes } from 'common/propTypes';
import {
  getSelectedOperandLabelForSignal,
  removeTagsFromString
} from 'common/utils/displayNames';
import { getOperators, getRightOperands } from 'common/utils/signalConfigUtils';
import ReactHtmlParser from 'react-html-parser';
import { sanitizeOutputSchema } from 'ui/run/RunForm/config';
import RIGHT_OPERAND from './components';
import { sanitizeSignalConfigSchema } from './helper';

const propTypes = {
  leftOperand: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  rightOperand: rightOperandPropTypes.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  onRightOperandChange: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  signalValidator: PropTypes.shape({}).isRequired,
  isHistoricRun: PropTypes.bool.isRequired,
  segment: PropTypes.string.isRequired,
  isAdjustmentSignal: PropTypes.bool
};

const defaultProps = {
  isAdjustmentSignal: false
};

const OperatorAndRightOperand = (props) => {
  const {
    onRightOperandChange,
    operator,
    rightOperand,
    onOperatorChange,
    outputSchema,
    signalValidator,
    leftOperand,
    isHistoricRun,
    segment,
    isAdjustmentSignal
  } = props;
  const CustomRightOperand = _.get(RIGHT_OPERAND, [leftOperand], null);
  const { errors, control, setValue } = useFormContext();
  const [rightOperandTypeHeadName] = useState(_.uniqueId('typeHead-'));
  const [operatorFieldName] = useState(_.uniqueId('operator-'));
  const operatorValidator = _.get(signalValidator, 'operator', {});
  const instrumentOutputSchema = sanitizeSignalConfigSchema(
    sanitizeOutputSchema(outputSchema, segment), isAdjustmentSignal, 'rightOperand'
  );

  useEffect(() => {
    setValue(rightOperandTypeHeadName, rightOperand);
    setValue(operatorFieldName, operator);
  }, [leftOperand]);

  const onCompleteRightOperand = (newRightOperand) => {
    onRightOperandChange(newRightOperand);
  };

  const onSelectRightOperand = (selectedItem, onChange) => {
    if (_.isEmpty(selectedItem)) return;

    const {
      id, label, customOption,
    } = _.first(selectedItem);
    if (!customOption) {
      onCompleteRightOperand(id);
      onChange(selectedItem);
      return;
    }

    if (!label) return;

    if (!_.isNaN(parseFloat(label))) {
      onCompleteRightOperand(label);
      onChange(selectedItem);
      return;
    }

    onChange(selectedItem);
  };

  const getDropdownOptions = (options) => _.map(options, (option, optionIndex) => {
    const optionValue = _.get(option, 'value', option);
    const label = _.get(option, 'label', option);

    return <option key={optionIndex} value={optionValue}>{_.startCase(label)}</option>;
  });

  const renderOperator = () => {
    const operatorStyle = `custom-select custom-select-sm ${errors[operatorFieldName] ? 'is-invalid' : ''}`;
    const dropDowns = getDropdownOptions(getOperators(leftOperand, instrumentOutputSchema));

    return (
      <div className="conditonsAndRightOperand">
        <Controller
          render={({ onChange, ref, name }) => (
            <Form.Control
              className={operatorStyle}
              as="select"
              ref={ref}
              name={name}
              value={operator}
              onChange={(event) => {
                onOperatorChange(event.target.value);
                onChange(event.target.value);
              }}
            >
              {dropDowns}
            </Form.Control>
          )}
          control={control}
          defaultValue={operator}
          name={operatorFieldName}
          rules={operatorValidator}
        />
        <ErrorMesssage
          error={errors[operatorFieldName]}
        />
      </div>
    );
  };

  const renderRightOperand = () => {
    const rightOperands = getRightOperands(leftOperand, instrumentOutputSchema);
    const selectedOperand = getSelectedOperandLabelForSignal(
      outputSchema, leftOperand, rightOperand
    );

    const inputStyle = classNames({ 'is-invalid': errors[rightOperandTypeHeadName] });
    const rightOperandValidator = _.get(signalValidator, 'rightOperand');
    const onBlur = (newValue, onChange) => {
      onSelectRightOperand(
        [{ customOption: true, label: newValue, id: newValue }],
        onChange
      );
    };

    return (
      <div>
        <Controller
          render={({ onChange, ref }) => {
            const renderTypeHead = (
              <Typeahead
                className="custom-rbt custom-form-control label-info"
                inputProps={{ className: inputStyle }}
                id={rightOperandTypeHeadName}
                options={rightOperands}
                clearButton
                ref={ref}
                allowNew
                placeholder="Enter new value or select"
                onBlur={(event) => onBlur(event.target.value, onChange)}
                renderMenuItemChildren={(option) => {
                  return ReactHtmlParser(option.labelHtml || option.label);
                }}
                selected={removeTagsFromString(selectedOperand)}
                onChange={(newRightOperand) => {
                  if (!_.isEmpty(newRightOperand)) { ref.current.blur(); }
                  onSelectRightOperand(newRightOperand, onChange);
                }}
              />
            );
            return renderTypeHead;
          }}
          control={control}
          defaultValue={selectedOperand}
          name={rightOperandTypeHeadName}
          rules={rightOperandValidator}
        />

        <ErrorMesssage
          error={errors[rightOperandTypeHeadName]}
        />
      </div>
    );
  };

  if (CustomRightOperand) {
    return (
      <CustomRightOperand
        leftOperand={leftOperand}
        operator={operator}
        operatorValidator={operatorValidator}
        rightOperand={rightOperand}
        onOperatorChange={(newOperator) => onOperatorChange(newOperator)}
        onRightOperandChange={(newRightOperand) => onCompleteRightOperand(newRightOperand)}
        isHistoricRun={isHistoricRun}
      />
    );
  }

  return (
    <>
      {renderOperator()}
      {renderRightOperand()}
    </>
  );
};

export default OperatorAndRightOperand;

OperatorAndRightOperand.propTypes = propTypes;
OperatorAndRightOperand.defaultProps = defaultProps;
