import React from 'react';
import PropTypes from 'prop-types';
import { pipeConfigPropTypes } from 'common/propTypes';
import ScriptFormField from '../components/ScriptFormField';

const propTypes = {
  pipeConfig: pipeConfigPropTypes.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  trigger: PropTypes.func.isRequired,
};

const CustomScript = ({
  pipeConfig, onUpdatePipeConfig, control, errors, trigger
}) => {
  return (
    <ScriptFormField
      errors={errors}
      control={control}
      trigger={trigger}
      pipeConfig={pipeConfig}
      onUpdate={onUpdatePipeConfig}
    />
  );
};

CustomScript.propTypes = propTypes;

export default CustomScript;
