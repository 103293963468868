import React from 'react';
// import PropTypes from 'prop-types';

import withErrorBoundary from 'common/components/withErrorBoundary/index';
import UserMessageToast from './UserMessageToast';
import UserMessageModal from './UserMessageModal';
import UserMessageBanner from './UserMessageBanner';

const propTypes = {
  // userMessages: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.string,
  //   title: PropTypes.string,
  //   message: PropTypes.string,
  //   messages: PropTypes.arrayOf(PropTypes.string)
  // }))).isRequired
  // for reference
};
const defaultProps = {};

const BANNERS = 'banners';
const MODALS = 'modals';
const TOASTS = 'toasts';

const UserMessages = () => {
  const userMessages = _.get(window, 'applicationDomain.config.userMessages', {});

  return (
    <>
      <UserMessageBanner messages={_.get(userMessages, BANNERS, [])} />
      <UserMessageToast messages={_.get(userMessages, TOASTS, [])} />
      <UserMessageModal modalMessages={_.get(userMessages, MODALS, [])} />
    </>
  );
};

UserMessages.defaultProps = defaultProps;
UserMessages.propTypes = propTypes;

export default withErrorBoundary(UserMessages);
