import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  isShowPlan: PropTypes.bool.isRequired,
  isStepOne: PropTypes.bool.isRequired,
  strategy: PropTypes.shape({})
};

const defaultProps = { strategy: {} };

const Header = (props) => {
  const {
    isShowPlan, isStepOne, strategy
  } = props;
  const oneIndentation = ' ';

  const renderHeader = () => {
    if (isShowPlan) return 'Paid Plan required for live deployment';

    return 'Insufficient Backtest Quota / Credits';
  };

  const renderSubHeader = () => {
    if (!_.isEmpty(strategy) && isShowPlan) {
      let content = 'You will need a paid plan to deploy live strategies. ';
      content += 'Please purchase a plan along with the strategy.';
      return content;
    }

    if (isShowPlan) return 'You do not have a paid plan. Please purchase a plan to start a live deployment';

    return (
      <>
        Backtest credits are required to run this backtest. You do not have that many credits.
        Please purchase
        {oneIndentation}
        <b>addons</b>
        {oneIndentation}
        to run this backtest
      </>
    );
  };

  return (
    <div className="head mb-4">
      {isStepOne ? <div /> : (
        <div>
          <h1 className="mb-1">{renderHeader()}</h1>
          <p>{renderSubHeader()}</p>
        </div>
      )}
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
