import { STRIKE_KEYS } from 'modules/TransactionsBuilder/configs';

export const changeOptionConfigsBasedOn = (optionConfigs, basedOn) => {
  const getPremiumValue = (premiumValue) => {
    if (premiumValue === 0) return 500;
    if (premiumValue > 0) {
      const value = 500 - (50 * premiumValue);
      return value >= 50 ? value : 50;
    }
    const value = 500 + (50 * premiumValue);
    return value <= 1000 ? value : 1000;
  };

  switch (basedOn) {
    case STRIKE_KEYS.premium:
      return _.map(optionConfigs, (optionConfig) => {
        return {
          ...optionConfig,
          [basedOn]: getPremiumValue(optionConfig[basedOn])
        };
      });
    case STRIKE_KEYS.strikePrice:
      return _.map(optionConfigs, (optionConfig) => { return { ...optionConfig, [basedOn]: 0 }; });
    default:
      return optionConfigs;
  }
};
