import axios from 'axios';

export const updateSiteConfig = (name, config) => {
  return axios({
    method: 'post',
    url: '/active_admin/meeting/save',
    data: JSON.stringify({ config, name }),
    headers: { 'Content-Type': 'application/json' }
  }).then(() => {});
};
