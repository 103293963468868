import React from 'react';
import PropTypes from 'prop-types';
import { toPositiveNumberString } from 'common/stringUtils/toPositiveNumberString';

const propTypes = {
  gapBetweenBuyAndSellOrdersInSeconds: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({}))
};
const defaultProps = {
  errors: []
};

const GapBetweenBuyAndSellOrdersInSeconds = ({
  gapBetweenBuyAndSellOrdersInSeconds,
  onChange, labelClassName,
  buttonClassName,
  errors
}) => {
  const gapBetweenBuyAndSell = gapBetweenBuyAndSellOrdersInSeconds.toString();

  const hasError = _.find(errors, (error) => error.property
    === 'instance.gapBetweenBuyAndSellOrdersInSeconds');
  return (
    <div className="hidden-for-future">
      <div className={labelClassName}>
        <label className="mb-1 mb-lg-0">Gap Between Buy and Sell Orders In Seconds</label>
      </div>
      <div className={buttonClassName}>
        <div className="input-group overall-stops">
          <input
            type="number"
            className="form-control"
            name="stopsInput"
            value={gapBetweenBuyAndSell}
            onChange={(event) => onChange(toPositiveNumberString((event.target.value).toString()))}
          />
        </div>
        {hasError ? (
          <div className="invalid-feedback d-block">
            Give the seconds 0 to 10
          </div>
        ) : null}
      </div>
    </div>
  );
};

GapBetweenBuyAndSellOrdersInSeconds.propTypes = propTypes;
GapBetweenBuyAndSellOrdersInSeconds.defaultProps = defaultProps;

export default GapBetweenBuyAndSellOrdersInSeconds;
