import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { deleteRun, getRuns, updateRun } from 'common/api/run';
import RunList from './RunList/index';

const propTypes = {
  selectedFilter: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  filterSearch: PropTypes.string
};

const defaultProps = { filterSearch: '' };

const I18N_SCOPE = { scope: 'notifications' };

// This is mainly for inProgress runs.. because in Folder Table there is no sepeate count.. so i used this way
// to solve the solution.. this 30 is same as run.rb model Run::PAGE_SIZE

const RUNS_COUNT_FOR_LOADMORE = 30;

const FilteredRuns = ({ selectedFilter, totalCount, filterSearch }) => {
  const [runs, setRuns] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const [isShowLoadMore, setIsShowLoadMore] = useState(true);

  const getParams = (offsetRunId) => {
    return _.omitBy({
      offsetRunId, locale: I18n.locale, filter_type: selectedFilter, search: filterSearch
    }, (value) => value === '');
  };

  const getFilterRuns = () => {
    getRuns({ params: getParams(0) })
      .then((resultRuns) => {
        if (resultRuns.length !== RUNS_COUNT_FOR_LOADMORE) setIsShowLoadMore(false);
        setRuns(resultRuns);
        setIsLoadingMore(false);
      });
  };

  useEffect(() => {
    setRuns([]);
    setIsShowLoadMore(true);
    getFilterRuns();
  }, [selectedFilter, filterSearch]);

  const onDeleteRun = (toDeleteRun) => {
    const { id: toDeleteRunId } = toDeleteRun;
    const successCallBack = () => { setRuns(_.filter(runs, (run) => run.id !== toDeleteRunId)); };

    deleteRun({ runId: toDeleteRunId, successCallBack });
  };

  const onUpdateRun = (toUpdateRun, key, value) => {
    const newRuns = _.map(runs, (run) => {
      return (run.id === toUpdateRun.id) ? { ...toUpdateRun, [key]: value } : { ...run };
    });

    updateRun({ runId: toUpdateRun.id, run: { [key]: value } }).then(() => setRuns(newRuns));
  };

  const loadMore = () => {
    const offsetRunId = _.last(runs).id;

    setIsLoadingMore(true);
    getRuns({ params: getParams(offsetRunId) })
      .then((olderRuns) => {
        if (olderRuns.length !== RUNS_COUNT_FOR_LOADMORE) setIsShowLoadMore(false);
        setRuns(runs.concat(olderRuns));
        setIsLoadingMore(false);
      });
  };

  const renderLoadMore = () => {
    const shouldHide = runs.length >= totalCount || !runs.length || !isShowLoadMore;
    if (shouldHide) return null;

    return (
      <div className="load-more-container">
        {isLoadingMore
          ? <div className="spinner-border text-primary" />
          : (
            <button className="btn btn-light" type="button" onClick={() => loadMore()}>
              {I18n.t('load_more', I18N_SCOPE)}
            </button>
          )}
      </div>
    );
  };

  return (
    <>
      <RunList
        folder={null}
        runs={runs}
        onDeleteRun={onDeleteRun}
        runListClassName="runs-list-or-grid filter-quant-list"
        onUpdateRun={onUpdateRun}
        isFilteredRuns
      />
      {renderLoadMore()}
    </>
  );
};

FilteredRuns.propTypes = propTypes;
FilteredRuns.defaultProps = defaultProps;

export default FilteredRuns;
