import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CANDLE_INTERVALS } from 'v2/common/quantConfig/index';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import ErrorMesssage from 'v2/common/components/ErrorMessage/index';

const propTypes = {
  value: PropTypes.string.isRequired,
  onUpdatePipeConfig: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  validator: PropTypes.shape({}).isRequired,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired
};

const defaultProps = {};

const PipeCandleInterval = ({
  value, onUpdatePipeConfig, name: propsName, validator, control, errors
}) => {
  const uniqueId = useMemo(() => _.uniqueId('pipe_candle_interval_'), []);
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  const intervalOptions = useMemo(() => _.map(
    CANDLE_INTERVALS,
    ({ displayValue, value: val }, idx) => <option key={idx} value={val}>{displayValue}</option>
  ), []);

  const rulesValidator = useMemo(() => {
    if (_.isEmpty(validator)) return null;

    return {
      ...validator,
      validate: () => {
        if (!value) {
          return 'Candle Interval is required';
        }

        return null;
      }
    };
  }, [value, validator]);

  return (
    <ErrorMesssage message={errorMessage}>
      <Controller
        render={({ onChange, name, ref }) => (
          <Form.Control
            className=""
            as="select"
            size="sm"
            name={name}
            ref={ref}
            value={value}
            onChange={(event) => {
              onUpdatePipeConfig(propsName, event.target.value);
              onChange(event.target.value);
            }}
          >
            {intervalOptions}
          </Form.Control>
        )}
        control={control}
        defaultValue={value}
        name={uniqueId}
        rules={rulesValidator}
      />
    </ErrorMesssage>
  );
};

PipeCandleInterval.propTypes = propTypes;
PipeCandleInterval.defaultProps = defaultProps;

export default memo(PipeCandleInterval);
