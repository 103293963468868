import { getCurrentUserDetail } from 'common/api/user';
import ReactDOM from 'react-dom';
import QuotaVerification from './index';

export const quotaVerificationDom = async (
  instrumentGroups, runParams, callbackFunc = () => { }, strategy = {}
) => {
  const { current_user: currentUser } = await getCurrentUserDetail();
  const divTag = document.createElement('div');
  const props = {
    currentUser, runParams, callbackFunc, strategy, instrumentGroups
  };

  ReactDOM.render(QuotaVerification(props), divTag);
};
