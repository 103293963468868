import {
  TRANSACTION_MODES,
  getEmptyFutureConfig,
  getEmptyEquityConfig,
  onValidateStrikeBasedOn,
  STRIKE_KEYS
} from 'modules/TransactionsBuilder/configs';

const isTradeTypeValidate = (tradeTypes, configType1, configType2) => {
  let isConfigType1Present = false;
  let isConfigType2Present = false;

  _.each(tradeTypes, (tradeType) => {
    if (tradeType === configType1) isConfigType1Present = true;
    if (tradeType === configType2) isConfigType2Present = true;
  });

  return isConfigType1Present && isConfigType2Present;
};

export const checkIsFutureAndOption = (transactionBuilderConfigs) => {
  const tradeTypes = _.map(transactionBuilderConfigs, 'tradeType');
  const firstTradeType = _.first(tradeTypes);
  const isFutureAndOption = isTradeTypeValidate(tradeTypes, 'futureConfig', 'optionConfigs');
  const isFutureAndEquity = isTradeTypeValidate(tradeTypes, 'futureConfig', 'equityConfig');
  const isOptionAndEquity = isTradeTypeValidate(tradeTypes, 'optionConfigs', 'equityConfig');

  // based on these types.. we handle the optionsConfig [] and remaining single objects like. futureConfig: {}
  return {
    tradeType: firstTradeType,
    isFutureAndOption,
    isFutureAndEquity,
    isOptionAndEquity,
    isFutureDisable: isFutureAndOption || isFutureAndEquity,
    isEquityDisable: isOptionAndEquity || isFutureAndEquity
  };
};

const getTransactionMode = (transactionBuilderConfigs) => {
  const {
    tradeType, isFutureAndOption, isFutureAndEquity, isOptionAndEquity
  } = checkIsFutureAndOption(transactionBuilderConfigs);

  if (isFutureAndOption && isFutureAndEquity && isOptionAndEquity) {
    return TRANSACTION_MODES.combinedAllConfigs;
  }

  if (isFutureAndOption) return TRANSACTION_MODES.futureAndOption;
  if (isFutureAndEquity) return TRANSACTION_MODES.futureAndEquity;
  if (isOptionAndEquity) return TRANSACTION_MODES.optionAndEquity;

  return TRANSACTION_MODES[tradeType];
};

const getFutureConfig = (transactionBuilderConfig, futureConfig) => {
  const builderFutureConfig = _.find(transactionBuilderConfig, ['tradeType', 'futureConfig']);

  if (!builderFutureConfig) return getEmptyFutureConfig();

  const {
    quantity, transactionType, stopGain, stopLoss, sortId, stopGainPrice, stopLossPrice,
    trailingPercentage, reExecuteCount, reEntryCount,
    adjustPercentage, waitUntilPremiumToDecreaseInPercentage, waitUntilPremiumToIncreaseInPercentage,
    waitAndTradeType
  } = builderFutureConfig;

  return ({
    ...futureConfig,
    quantityExpression: quantity.toString(),
    entryType: transactionType,
    stopGain,
    stopLoss,
    sortId,
    stopGainPrice,
    stopLossPrice,
    reExecuteCount,
    reEntryCount,
    trailingStopLoss: { trailingPercentage, adjustPercentage },
    waitUntilPremiumToDecreaseInPercentage,
    waitUntilPremiumToIncreaseInPercentage,
    waitAndTradeType
  });
};

const getEquityConfig = (transactionBuilderConfig, equityConfig) => {
  const builderEquityConfig = _.find(transactionBuilderConfig, ['tradeType', 'equityConfig']);

  if (!builderEquityConfig) return getEmptyEquityConfig();

  const {
    quantity, transactionType, stopGain, stopLoss, sortId, stopGainPrice, stopLossPrice,
    trailingPercentage, adjustPercentage, amount,
    reExecuteCount, reEntryCount,
    waitUntilPremiumToDecreaseInPercentage, waitUntilPremiumToIncreaseInPercentage,
    waitAndTradeType
  } = builderEquityConfig;

  return ({
    ...equityConfig,
    quantity: Number(quantity),
    entryType: transactionType,
    stopGain,
    amount: amount ? parseFloat(amount) : 0,
    stopLoss,
    sortId,
    stopGainPrice,
    stopLossPrice,
    reExecuteCount,
    reEntryCount,
    trailingStopLoss: { trailingPercentage, adjustPercentage },
    waitUntilPremiumToDecreaseInPercentage,
    waitUntilPremiumToIncreaseInPercentage,
    waitAndTradeType
  });
};

const getOptionConfigs = (transactionBuilderConfig, strikeBasedOn) => {
  const builderOptionConfigs = _.filter(transactionBuilderConfig, ['tradeType', 'optionConfigs']);
  const basedOn = onValidateStrikeBasedOn(strikeBasedOn);

  if (_.isEmpty(builderOptionConfigs)) return [];

  return _.map(builderOptionConfigs, (builderOptionConfig) => {
    const {
      strike,
      optionType,
      quantity,
      transactionType,
      stopGain,
      stopLoss,
      sortId,
      valuePath,
      stopGainPrice,
      stopLossPrice,
      trailingPercentage,
      adjustPercentage,
      reExecuteCount,
      reEntryCount,
      waitUntilPremiumToDecreaseInPercentage, waitUntilPremiumToIncreaseInPercentage,
      waitAndTradeType
    } = builderOptionConfig;
    const valuePathConfig = strikeBasedOn === STRIKE_KEYS.indicatorValue ? { valuePath } : {};

    return ({
      ...valuePathConfig,
      [basedOn]: strike,
      optionType,
      entryType: transactionType,
      quantity: parseInt(quantity, 10),
      stopGain,
      stopLoss,
      sortId,
      stopGainPrice,
      stopLossPrice,
      reExecuteCount,
      reEntryCount,
      trailingStopLoss: { trailingPercentage, adjustPercentage },
      waitUntilPremiumToDecreaseInPercentage,
      waitUntilPremiumToIncreaseInPercentage,
      waitAndTradeType
    });
  });
};

export const convertToTransactionConfig = (transactionBuilderConfigs, transactionConfig, strikeBasedOn) => {
  const { mode, futureConfig, equityConfig } = transactionConfig;
  const newMode = { ...mode, transaction: getTransactionMode(transactionBuilderConfigs) };

  return ({
    ...transactionConfig,
    mode: newMode,
    futureConfig: getFutureConfig(transactionBuilderConfigs, futureConfig),
    optionConfigs: getOptionConfigs(transactionBuilderConfigs, strikeBasedOn),
    equityConfig: getEquityConfig(transactionBuilderConfigs, equityConfig)
  });
};
