import React from 'react';
import PropTypes from 'prop-types';
import { FIXED_AMOUNT, REQUEST_TYPE } from 'common/constants/index';
import { strategyPropTypes, userPropTypes } from 'common/propTypes';
import classNames from 'classnames';
// import TipsQuantConfigBuilder from 'modules/TipsQuantConfigBuilder/index';
import ReactHtmlParser from 'react-html-parser';
import StrategyErrorMessage from '../StrategyErrorMessage';
import Header from '../StrategyCard/Header';
import InstrumentAndTags from '../StrategyCard/InstrumentAndTags';
// import RiskCategory from './RiskCategory';
import AccessTill from './AccessTill';
import DeployStrategyButton from './DeployStrategyButton';
import StrategyAccessDetails from './StrategyAccessDetails';
import PaymentButton from './PaymentButton';
import RequestTypeButton from './RequestTypeButton';
import ProfitableValues from '../StrategyCard/ProfitableValues';
import FreeTrialInfo from './freeTrialInfo';

const propTypes = {
  currentUserDefaultFolderId: PropTypes.number,
  currentUser: userPropTypes,
  strategy: strategyPropTypes.isRequired,
  strategyAccessDetails: PropTypes.arrayOf(PropTypes.shape({}))
};
const defaultProps = { currentUserDefaultFolderId: null, currentUser: null, strategyAccessDetails: null };

const IndividualStrategyDetails = ({
  strategy, currentUser, currentUserDefaultFolderId, strategyAccessDetails
}) => {
  const {
    // risk_category: riskCategory,
    user_id: userId,
    is_premium: isPremium
  } = strategy;
  const purchaseType = _.get(strategy, 'purchase_type', '');
  const description = _.get(strategy, 'description', '');
  const currentUserId = _.get(currentUser, 'id', null);
  const isStrategyBelongsToUser = userId === currentUserId;
  const strategyAccess = _.get(strategy, 'strategy_access', {});
  const isEmptyStrategyAccess = _.isEmpty(strategyAccess); // for initial users
  const status = _.get(strategyAccess, 'status', '');
  const isShowCampaign = !_.get(window, 'userPermissions.subscribePremiumPlan.isEnabled') && isPremium;
  const initialFreeTrialDays = _.get(strategy, 'initial_free_trial_days', 0);
  const isFreeTrialAvailable = (initialFreeTrialDays > 0 && isEmptyStrategyAccess);

  const renderConditionBasedButton = () => {
    if (isStrategyBelongsToUser) return null;
    if (purchaseType === FIXED_AMOUNT) {
      if (isFreeTrialAvailable) {
        return (
          <FreeTrialInfo
            initialFreeTrialDays={initialFreeTrialDays}
            purchaseAmount={_.get(strategy, 'purchase_amount', 0)}
          />
        );
      }
      return <PaymentButton strategy={{ ...strategy, currentUser }} />;
    }
    if (purchaseType === REQUEST_TYPE) return <RequestTypeButton strategy={strategy} />;

    return null;
  };

  const strategyCardClassName = classNames('card mp-strategy-card', { 'premium-card': isPremium });

  return (
    <div className="individual-strategy-details">
      <StrategyErrorMessage currentUser={currentUser} />
      <div className={strategyCardClassName}>
        <div className="card-header mp-strategy-head">
          <Header
            strategy={{ ...strategy, currentUser }}
            name={_.get(strategy, 'strategy_author_details.name', '')}
          />
        </div>

        <div className="card-body">
          <div className="mp-strategy-card-body">
            <div className="row">
              <div className="col-md-7">
                <p className="description">{ReactHtmlParser(description)}</p>
              </div>
              <div className="col-md-5">
                <InstrumentAndTags strategy={strategy} tagClassName="ins-tag" />
              </div>
            </div>
            {/* <TipsQuantConfigBuilder strategy={strategy} currentUser={currentUser} /> */}
            <div className="mpstrategy-value-wrapper">
              <ProfitableValues
                profitableValueParentClassName=""
                strategy={strategy}
              />
              {/* <RiskCategory riskCategory={riskCategory} /> */}
            </div>
            <div className="strategy-action">
              <AccessTill
                isEmptyStrategyAccess={isEmptyStrategyAccess}
                status={status}
                purchaseType={purchaseType}
                strategyAccess={strategyAccess}
                initialFreeTrialDays={initialFreeTrialDays}
              />

              <div className="right-side-action">
                {renderConditionBasedButton()}
                <DeployStrategyButton
                  btnClassName="common-icon-btn btn btn-success btn-sm btn-block text-nowrap mt-0"
                  strategy={{
                    ...strategy, currentUserDefaultFolderId, currentUser, isFreeTrialAvailable
                  }}
                  isStrategyDetailView
                />
              </div>

              {isShowCampaign && (
                <div className="access-till ml-0 mt-2 ml-sm-2 mt-sm-0">
                  <div className="access-inner alert-danger">
                    <span className="material-icons-outlined tx-18"> campaign </span>
                    <div className="text-nowrap">
                      Strategy is available for premium user
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <StrategyAccessDetails strategyAccessDetails={strategyAccessDetails} />
    </div>
  );
};

IndividualStrategyDetails.propTypes = propTypes;
IndividualStrategyDetails.defaultProps = defaultProps;

export default IndividualStrategyDetails;
