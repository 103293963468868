import React, { Component } from 'react';

import {
  getSetting,
  updateSetting,
} from 'common/api/setting';
import WebPushSubscriptionsList from './WebPushSubscriptionsList';

const NOTIFICATION_TYPES = [
  // TODO: I18n
  { labelFn: () => 'Order', value: 'order_placed' },
  { labelFn: () => 'Backtest Run Failed', value: 'historic_run_failed' },
  { labelFn: () => 'Live Run Failed', value: 'live_run_failed' },
  { labelFn: () => 'Login Reminder', value: 'login_reminder' }
];

const propTypes = {};
const defaultProps = {};

const I18N_SCOPE = { scope: 'settings' };

class NotificationSettings extends Component {
  constructor(props) {
    super(props);

    this.state = { setting: [] };
  }

  componentDidMount() {
    getSetting()
      .then((setting) => this.setState({ setting }));
  }

  onSettingToggle = (settingKey) => {
    const { setting } = this.state;

    updateSetting({ [settingKey]: !setting[settingKey] });

    this.setState({
      setting: {
        ...setting,
        [settingKey]: !setting[settingKey]
      }
    });
  }

  renderSettingCheckbox = (settingKey) => {
    const { setting } = this.state;

    const isSettingsKeyNotAllowed = _.includes(
      ['login_reminder_application_notification', 'login_reminder_browser_notification'],
      settingKey
    );

    if (isSettingsKeyNotAllowed) return null;

    return (
      <div className="custom-checkbox text-center custom-switch">

        <input
          type="checkbox"
          className="custom-control-input"
          id={settingKey}
          checked={!!setting[settingKey]}
          onChange={() => this.onSettingToggle(settingKey)}
        />

        <label className="custom-control-label" htmlFor={settingKey} />
      </div>
    );
  }

  renderNotificationTypeSetting = (notificationType, isBrokerUser) => {
    if (!isBrokerUser && notificationType.value === 'login_reminder') return null;
    return (
      <tr key={notificationType.value} className="notification-type">
        <td>{notificationType.labelFn()}</td>
        <td>{this.renderSettingCheckbox(`${notificationType.value}_application_notification`)}</td>
        <td>{this.renderSettingCheckbox(`${notificationType.value}_browser_notification`)}</td>
        <td>{this.renderSettingCheckbox(`${notificationType.value}_call_notification`)}</td>
      </tr>
    );
  }

  render() {
    const { currentUserBroker } = window;
    const isBrokerUser = !_.isEmpty(currentUserBroker);
    return (
      <>
        <div className="user-notification-settings card">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>{I18n.t('type', I18N_SCOPE)}</th>
                  <th className="text-center">
                    {I18n.t('application_notification', I18N_SCOPE)}
                  </th>
                  <th className="text-center">
                    {I18n.t('browser_notification', I18N_SCOPE)}
                  </th>
                  <th className="text-center">
                    {I18n.t('call_notification', I18N_SCOPE)}
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {NOTIFICATION_TYPES.map((type) => this.renderNotificationTypeSetting(type, isBrokerUser))}
              </tbody>
            </table>
          </div>
        </div>
        <WebPushSubscriptionsList />
      </>
    );
  }
}

NotificationSettings.propTypes = propTypes;
NotificationSettings.defaultProps = defaultProps;
export default NotificationSettings;
