import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import InstrumentInput from 'common/components/InstrumentInput';
import { instrumentGroupsPropTypes } from 'common/propTypes';
import ViewInstrumentGroup from './ViewInstrumentGroup';

const propTypes = {
  maxInstrumentsInGroup: PropTypes.number.isRequired,
  onAddInstrumentGroup: PropTypes.func.isRequired,
  instrumentGroups: instrumentGroupsPropTypes.isRequired,
  onRemoveInstrumentGroup: PropTypes.func.isRequired,
  isInValid: PropTypes.bool,
  titleMdSize: PropTypes.string,
  inputMdSize: PropTypes.string,
  shouldHideLabel: PropTypes.bool,
  segment: PropTypes.string,
  onChangeSegment: PropTypes.func,
  segments: PropTypes.arrayOf(PropTypes.string)
};
const defaultProps = {
  isInValid: false,
  titleMdSize: 'col-md-2',
  inputMdSize: 'col-md-10',
  shouldHideLabel: false,
  segment: '',
  onChangeSegment: () => {},
  segments: []
};
const MAX_INSTRUMENT_GROUPS_ALLOWED = 2;

const InstrumentGroupBuilder = (props) => {
  const {
    maxInstrumentsInGroup, onAddInstrumentGroup, instrumentGroups, onRemoveInstrumentGroup, isInValid,
    titleMdSize, inputMdSize, shouldHideLabel, segment, onChangeSegment, segments
  } = props;
  const [exchange, onChangeExchange] = useState('');
  const [shouldClearTradeSymbol, onClearTradeSymbolChange] = useState(false);
  const [instrumentGroup, onChangeInstrumentGroup] = useState([]);
  const shouldDisableInput = instrumentGroups.length >= MAX_INSTRUMENT_GROUPS_ALLOWED;
  const lmtReachedMsg = shouldDisableInput ? 'max groups limit reached' : '';
  const instrumentInputRef = React.createRef();

  const onInstrumentAdd = (newInstrument, instrumentIndex) => {
    const newInstrumentGroup = instrumentGroup;
    newInstrumentGroup[instrumentIndex] = newInstrument;

    const numberOfInstrumentsInGroup = _.size(
      _.filter(newInstrumentGroup, (instrument) => !!instrument)
    );

    onChangeInstrumentGroup(newInstrumentGroup);
    const instrumentInputs = $(instrumentInputRef.current).find('.rbt-input-main');
    const instrumentToFocus = (instrumentIndex + 1) % maxInstrumentsInGroup;

    instrumentInputs[instrumentToFocus].focus();

    if (numberOfInstrumentsInGroup === (maxInstrumentsInGroup)) {
      onAddInstrumentGroup(instrumentGroup);
      onChangeInstrumentGroup([]);
      onClearTradeSymbolChange(true);
      instrumentInputs[0].focus();
    }
  };

  useEffect(() => {
    if (shouldClearTradeSymbol) onClearTradeSymbolChange(false);
  }, [instrumentGroup, shouldClearTradeSymbol]);

  return (
    <>
      {!shouldHideLabel && (
        <div className={titleMdSize}>
          <label className="col-form-label col-form-label-sm">Instruments</label>
        </div>
      )}

      <div className={inputMdSize} data-toggle="popover" title={lmtReachedMsg}>
        <div className="instruments-group" ref={instrumentInputRef}>
          {_.times(maxInstrumentsInGroup, (idx) => {
            return (
              <div key={idx} className="instruments-inputs">
                <InstrumentInput
                  isInvalid={isInValid}
                  segment={segment}
                  onChangeSegment={onChangeSegment}
                  shouldDisableInput={shouldDisableInput}
                  exchange={exchange}
                  shouldShowExchange={!idx}
                  shouldShowSegment={!idx}
                  segments={segments}
                  shouldClearTradeSymbol={shouldClearTradeSymbol}
                  onExchangeChange={(newExchange) => onChangeExchange(newExchange)}
                  onComplete={(newInstrument) => onInstrumentAdd(newInstrument, idx)}
                />
                <i className="icons icons-cross text-danger hidden-for-future" />
              </div>
            );
          })}
        </div>
      </div>
      <div className={titleMdSize} />
      <div className={inputMdSize}>
        <ViewInstrumentGroup
          instrumentGroups={instrumentGroups}
          onRemoveInstrumentGroup={onRemoveInstrumentGroup}
        />
      </div>
    </>
  );
};

InstrumentGroupBuilder.propTypes = propTypes;
InstrumentGroupBuilder.defaultProps = defaultProps;

export default InstrumentGroupBuilder;
