import { availableBrokers } from 'common/brokerConfigs/config';
import { BROKERS } from 'common/constants/index';
import qs from 'qs';
import { cyclicPlansPeriods } from './config';

export const getContent = (brokers) => {
  let content = '';
  const hiddenBrokers = [BROKERS.broker_stub.id, BROKERS.goodwill_dealer.id, BROKERS.enrich_money.id];
  const filteredBrokers = _.filter(
    brokers, (broker) => !hiddenBrokers.includes(broker) && !broker.includes('xts')
  );

  _.each(filteredBrokers, (broker, idx) => {
    content += `${availableBrokers[broker]}`;
    if (idx !== filteredBrokers.length - 1) content += ', ';
  });

  return content;
};

export const applyCredits = (price, credits) => {
  return credits < price ? price - credits : 0;
};

export const getPreFillCouponCode = () => {
  const queryString = window.location.search;
  const parsedQueryString = qs.parse(queryString, { parseBooleans: true });

  return _.get(parsedQueryString, 'referralCode', '');
};

export const cyclicFilter = (planCycleType, plan) => {
  switch (planCycleType) {
    case cyclicPlansPeriods[planCycleType].value:
      return plan.cycle_duration_in_months === cyclicPlansPeriods[planCycleType].period;
    default:
      return plan.cycle_duration_in_months === cyclicPlansPeriods[planCycleType].yearly;
  }
};

const getPercentageValue = (price, percantage) => {
  return (Number(percantage) / 100) * Number(price);
};

export const initCurrentNetPrice = (results = []) => {
  return _.map(results, (result) => { return { ...result, currentNetPrice: result.price }; });
};

const priceCalculations = (isBothIdsEmpty, price, discountPercentage, currentHash, currentIds) => {
  if (isBothIdsEmpty) {
    return _.isUndefined(discountPercentage) ? price
      : _.round(price - getPercentageValue(price, discountPercentage), 2);
  }

  const { id } = currentHash;
  if ((_.size(currentIds) === 0) || _.includes(currentIds, id)) {
    return _.isUndefined(discountPercentage) ? price
      : _.round(price - getPercentageValue(price, discountPercentage), 2);
  }

  return price;
};

const getAddonsAndPlanGroups = (addons, planGroups, discountPercentage, addonIds, planIds) => {
  const isBothIdsEmpty = _.isEmpty(addonIds) && _.isEmpty(planIds);
  const alteredAddons = _.map(addons, (addon) => {
    const { price } = addon;
    return {
      ...addon,
      currentNetPrice: priceCalculations(
        isBothIdsEmpty, price, discountPercentage, addon, addonIds
      )
    };
  });

  const alteredPlanGroups = _.reduce(planGroups, (result, arrayValue, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key] = _.map(arrayValue, (value) => {
      const { price } = value;
      return {
        ...value,
        currentNetPrice: priceCalculations(
          isBothIdsEmpty, price, discountPercentage, value, planIds
        )
      };
    });
    return result;
  }, {});

  return { alteredAddons, alteredPlanGroups };
};

export const getCouponConfig = (config, addons, planGroups) => {
  if (!_.keys(config).length) {
    return {
      alteredCouponCode: '',
      alteredIsApplied: false,
      ...getAddonsAndPlanGroups(addons, planGroups)
    };
  }

  const {
    discount_percentage: discountPercentage, code, addon_ids: addonIds, plan_ids: planIds
  } = config;

  return {
    alteredCouponCode: code,
    alteredIsApplied: true,
    ...getAddonsAndPlanGroups(
      addons, planGroups, discountPercentage, addonIds, planIds
    ),
    discountPercentage
  };
};
