import React from 'react';
import PropTypes from 'prop-types';
import { planPropTypes } from 'common/propTypes';
import classNames from 'classnames';
import PaymentMoneyDisplay from 'ui/Plans/components/common/PaymentMoneyDisplay';

const defaultUpgradePlanPropTypes = PropTypes.shape({
  targetPlan: planPropTypes,
  upgradeDetails: PropTypes.shape({
    upgradable: PropTypes.bool,
    start: PropTypes.string,
    end: PropTypes.string,
    unused_percentage: PropTypes.number,
    current_plan_amount: PropTypes.number,
    target_plan_amount: PropTypes.number,
    upgrade_amount: PropTypes.number
  })
});

const propTypes = {
  currentUpgradePlan: defaultUpgradePlanPropTypes,
  config: defaultUpgradePlanPropTypes.isRequired,
  onHandleConfig: PropTypes.func.isRequired
};
const defaultProps = { currentUpgradePlan: {} };

const Plan = ({ config, onHandleConfig, currentUpgradePlan }) => {
  const { targetPlan } = config;
  const oneIndentation = ' ';
  const cardClassName = classNames('plans-details col-12 col-md-5', {
    premiumplan: targetPlan.is_premium,
  });

  const {
    plan_name: planName,
    live_run_limit: liveRunLimit,
    historic_run_limit: historicRunLimit,
    cycle_duration_in_months: duration,
  } = targetPlan;

  const price = _.get(config, 'price');
  const currentNetPrice = _.get(config, 'currentNetPrice', price);

  return (

    <div className={cardClassName}>
      <div className="plans-box quota-details">
        <input
          onClick={() => { onHandleConfig(); }}
          checked={targetPlan.id === _.get(currentUpgradePlan, 'targetPlan.id', '')}
          type="radio"
          id={targetPlan.id}
          name="select"
          value={targetPlan.id}
          onChange={() => {}}
        />
        <label htmlFor={targetPlan.id}>
          <div className="quota-head">
            <div className="plan-name">{_.startCase(planName)}</div>
            <div className="d-flex gap-5">
              <div className="coupon-price">
                <span className="">
                  <PaymentMoneyDisplay
                    shouldAvoidDecimals
                    currentNetPrice={currentNetPrice}
                    price={price}
                    cycleDurationInMonths={duration}
                  />
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="quota-values">
            <div className="values">
              {historicRunLimit}
              {oneIndentation}
              <small className="d-block">Backtest</small>
            </div>
            <div className="line" />
            <div className="values">
              {liveRunLimit}
              {oneIndentation}
              <small className="d-block">Live</small>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

Plan.propTypes = propTypes;
Plan.defaultProps = defaultProps;

export default Plan;
