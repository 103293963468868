import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { QUANT_CONFIG_FIELDS } from 'modules/QuantBuilder/config';
import {
  getValidationError,
  serverErrorsToObject,
} from 'common/utils/validators';
import { validatorProptypes, orderConfigsPropTypes } from 'common/propTypes';
import RepeatOrder from 'modules/QuantBuilder/components/RepeatOrder';
import { setOrderConfigs } from 'modules/QuantBuilder/actions';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OrderConfigBuilder from './OrderConfigBuilder';
import AddOrderConfig from './components/AddOrderConfig';
import RemoveOrderConfig from './components/RemoveOrderConfig';

const I18N_SCOPE = { scope: 'advanced_quant_builder.index' };
const propTypes = {
  orderConfigs: orderConfigsPropTypes.isRequired,
  validatorErrors: validatorProptypes.isRequired,
  dispatchSetOrderConfigs: PropTypes.func.isRequired,
};

const defaultProps = {};

const getOrderConfigsErrors = (validatorErrors) => {
  const {
    entrySignalConfigs,
    exitSignalConfigs,
    expiryConfigs,
    transactionConfigs,
  } = QUANT_CONFIG_FIELDS;

  return []
    .concat(getValidationError(validatorErrors, entrySignalConfigs))
    .concat(getValidationError(validatorErrors, exitSignalConfigs))
    .concat(getValidationError(validatorErrors, expiryConfigs))
    .concat(getValidationError(validatorErrors, transactionConfigs));
};

const OrderConfigsBuilder = (props) => {
  const { isAdmin, isBetaUser } = window;
  const { orderConfigs, validatorErrors, dispatchSetOrderConfigs } = props;
  const serverErrors = serverErrorsToObject(
    getOrderConfigsErrors(validatorErrors)
  );
  const orderConfigsErrors = _.get(serverErrors, ['orderConfigs'], []);

  useEffect(() => {
    if (_.size(orderConfigs) > 1 && !isAdmin && !isBetaUser) {
      const message = "Right now, we don't have multiple case support, so we removed multiple cases";
      reactToastify(message, TOASTIFY_TYPES.INFO);
      setTimeout(() => {
        dispatchSetOrderConfigs([_.head(orderConfigs)]);
      }, 1000); // wait for component load and page load
    }
  }, []);

  const renderOrderConfig = (orderConfig, orderConfigIndex) => {
    const divClassName = classNames('d-none', {
      'd-none': !isAdmin && !isBetaUser,
      'd-flex': (isAdmin || isBetaUser) && orderConfigIndex,
      'border-bottom px-2 py-1 justify-content-between bg-light': true,
    });

    return (
      <div key={orderConfigIndex} className="order-config">
        <div className={divClassName}>
          <h6 className="align-self-center tx-15 font-weight-bold">
            {I18n.t('case', I18N_SCOPE)}
            &nbsp;
            {orderConfigIndex + 1}
          </h6>
          <RemoveOrderConfig indexToRemove={orderConfigIndex} />
        </div>
        <OrderConfigBuilder
          orderConfigIndex={orderConfigIndex}
          orderConfigErrors={_.get(orderConfigsErrors, [orderConfigIndex], {})}
        />
      </div>
    );
  };

  const renderOrderConfigs = () => {
    if (_.isEmpty(orderConfigs)) {
      return (
        <div className="text-danger text-center">
          {I18n.t('no_order_configs', I18N_SCOPE)}
        </div>
      );
    }

    return _.map(orderConfigs, renderOrderConfig);
  };

  const addOrderConfigClassName = classNames('', {
    'hidden-for-future': !isBetaUser && !isAdmin,
    'row justify-content-center align-items-center mt-2 mb-5': true,
  });

  return (
    <div className="place-order-config-content">
      <div>
        <div className="place-order-configs">{renderOrderConfigs()}</div>

        <div className={addOrderConfigClassName}>
          <AddOrderConfig />
        </div>
        <div className="repeat-order-wrapper hidden-for-future">
          <RepeatOrder />
        </div>
      </div>
    </div>
  );
};

OrderConfigsBuilder.propTypes = propTypes;
OrderConfigsBuilder.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  orderConfigs: _.get(state, 'quantConfig.orderConfigs'),
  repeatOrder: _.get(state, 'quantConfig.repeatOrder'),
  validatorErrors: _.get(state, 'validatorErrors'),
});

const mapDispatchToProp = (dispatch) => ({
  dispatchSetOrderConfigs: (newOrderConfigs) => dispatch(setOrderConfigs(newOrderConfigs)),
});

export default connect(mapStateToProps, mapDispatchToProp)(OrderConfigsBuilder);
