import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { onGetStrategyAccessBasedOnStrategyId } from 'common/api/strategyAccess';
import { strategyPropTypes } from 'common/propTypes';

const propTypes = {
  strategy: strategyPropTypes
};
const defaultProps = {
  strategy: null
};

const StrategyAccessDetailsAdminPanel = ({ strategy }) => {
  const [strategyAccesses, setStrategyAccesses] = useState([]);

  useEffect(() => {
    onGetStrategyAccessBasedOnStrategyId({ strategy_id: strategy.id }).then((result) => {
      setStrategyAccesses(result.strategy_accesses);
      console.log(result.strategy_accesses, 'strategy_accesses');
      console.log(strategy, 'strategy'); // for debugging purpose... admin only so
    });
  }, []);

  const renderStrategyAccesses = (strategyAccess, idx) => {
    const encodedUrl = encodeURI(`${FRONTEND_PUBLIC_URL}/runs/${strategyAccess.run_id}`);

    return (
      <tr key={idx}>
        <td>{idx + 1}</td>
        <td>{strategyAccess.id}</td>
        <td>{strategyAccess.user_id}</td>
        <td>{strategyAccess.paid_till}</td>
        <td><a href={encodedUrl} target="_blank" rel="noreferrer">{strategyAccess.run_id}</a></td>
      </tr>
    );
  };

  if (_.isEmpty(strategyAccesses)) {
    return (
      <div className="invalid-feedback d-block text-center mt-2 mb-2">No running strategy access</div>
    );
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th scope="col">S.No</th>
          <th scope="col">Strategy Access Id</th>
          <th scope="col">User Id</th>
          <th scope="col">Paid Till</th>
          <th scope="col">Run Id</th>
        </tr>
      </thead>
      <tbody>
        {_.map(strategyAccesses, renderStrategyAccesses)}
      </tbody>
    </Table>
  );
};

StrategyAccessDetailsAdminPanel.propTypes = propTypes;
StrategyAccessDetailsAdminPanel.defaultProps = defaultProps;

export default StrategyAccessDetailsAdminPanel;
