import {
  CHART_PATTERNS,
  emptyQuantConfig, getAlteredEmptyQuantConfig, getEmptyOrderConfig
} from 'modules/QuantBuilder/config/index';
import qs from 'qs';
import templates from 'ui/Home/components/ReadyMadeTemplates/Templates/index';
import moment from 'moment';
import instruments from 'common/configs/instruments';
import {
  DOMAINS, ORDER_TYPES, SEGMENT_CONFIG
} from 'common/constants/index';
import { automaticSupportedBrokers, oneClickSupportedBrokers } from 'common/brokerConfigs/config';
import { getContent } from 'ui/Plans/helper';
import { isIndexInstrumentPresentInQuantInstrumentGroups } from 'common/utils/InstrumentUtils';
import {
  getOptionConfigStrategies, STRIKE_KEYS, getEmptyTransactionConfig, getStrikeBasedOn
} from 'modules/TransactionsBuilder/configs';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { removedIndicators } from 'v2/common/quantConfig/index';

const getParsedOptionConfigs = (optionConfigs) => _.map(optionConfigs, (optionConfig) => (
  _.reduce(optionConfig, (parsedOptionConfig, value, key) => {
    // eslint-disable-next-line no-param-reassign
    parsedOptionConfig[key] = _.isNaN(parseFloat(value)) ? value : parseFloat(value);

    return parsedOptionConfig;
  }, {})
));

export const getOptionBuilderQuantConfig = (alteredQuantConfig, parsedQueryString) => {
  const strategyBuilderConfig = JSON.parse(
    decodeURIComponent(parsedQueryString.strategyBuilderConfig)
  );

  const {
    optionConfigs, timeBasedSignal, currentStrategy, optionStrikeCalculationBasedOn
  } = strategyBuilderConfig;

  const getOptionSubStrategy = _.compact(_.map(getOptionConfigStrategies(optionStrikeCalculationBasedOn),
    (category, name) => {
      if (_.includes(_.keys(category), currentStrategy)) { return name; }
      return null;
    }));

  const newMode = {
    optionStrategy: getOptionSubStrategy.length ? _.get(getOptionSubStrategy, '0', 'custom') : 'custom',
    transaction: 'option',
    optionSubStrategy: currentStrategy
  };

  const segment = _.get(strategyBuilderConfig, 'segment', SEGMENT_CONFIG.equityAndFuture);

  return {
    ...alteredQuantConfig,
    timeBasedSignal,
    segment,
    ..._.omit(strategyBuilderConfig, 'optionConfigs'),
    orderConfigs: [{
      ...getEmptyOrderConfig(),
      transactionConfigs: [{
        ...getEmptyTransactionConfig(),
        mode: newMode,
        optionConfigs: getParsedOptionConfigs(optionConfigs)
      }]
    }]
  };
};

export const getReadyMadeStrategyQuantConfig = (parsedQueryString) => {
  const alteredQuantConfig = getAlteredEmptyQuantConfig();

  const templateNameParams = _.get(parsedQueryString, 'templateName', '');
  const templateConfig = _.get(parsedQueryString, 'template', {});
  const template = _.find(templates, ({ templateName }) => templateName === templateNameParams);
  let readyMadeQuantConfig = {};
  try {
    const parsedObject = JSON.parse(decodeURIComponent(templateConfig));
    readyMadeQuantConfig = template.toQuantConfig(parsedObject);
  } catch (e) {
    readyMadeQuantConfig = alteredQuantConfig;
  }

  return readyMadeQuantConfig;
};

export const replacedDeprecatedInstrumentNameFromQuantConfig = (quantConfig) => {
  const deprecatedInstrumentNames = {
    'NFO:MOTHERSUMI': 'NFO:MOTHERSON'
  };

  const instrumentGroups = _.get(quantConfig, 'instrumentGroups', []);
  const alteredInstrumentGroups = _.map(instrumentGroups, (instrumentGroup) => {
    return _.map(instrumentGroup, (instrument) => {
      return deprecatedInstrumentNames[instrument] ? deprecatedInstrumentNames[instrument] : instrument;
    });
  });

  return { ...quantConfig, instrumentGroups: alteredInstrumentGroups, };
};

const sanitizeVersionTwoDeprecatedQuantConfig = (quantConfig) => {
  const chartConfig = _.get(quantConfig, 'chartConfig', {});
  const orderConfigs = _.get(quantConfig, 'orderConfigs', []);
  const pipeConfigs = _.get(quantConfig, 'pipeConfigs', []);
  const optionStrikeCalculationBasedOn = _.get(quantConfig, 'optionStrikeCalculationBasedOn', '');
  const isValidStrikeBasedOn = (
    _.includes(_.map(
      getStrikeBasedOn(), ({ value }) => value
    ), optionStrikeCalculationBasedOn)
  );

  const wpc = [];
  const { maxInstrumentsInGroup } = quantConfig;
  const newPipeConfigs = _.chain(pipeConfigs)
    .map((pipeConfig) => {
      let alteredPipeConfig = pipeConfig;

      if (pipeConfig.type === 'anchoredVolumeWeightedAveragePrice') {
        // In engine version 2.0, we remove basedOn and source for AVWAP
        alteredPipeConfig = {
          ...pipeConfig,
          config: _.omit(pipeConfig.config, ['basedOn', 'source', 'time', 'date'])
        };
        alteredPipeConfig.basedOn = 'dateTime';
      }

      if (_.includes(removedIndicators, alteredPipeConfig.type)) {
        wpc.push(alteredPipeConfig);
        return null;
      }

      return alteredPipeConfig;
    })
    .compact()
    .value();

  const isValidSignalConfigs = (configs) => {
    const removedPipeConfigNames = _.map(wpc, 'name');

    return !_.some(configs, (config) => {
      return (
        _.some(removedPipeConfigNames, (pipeName) => config.leftOperand.includes(pipeName))
          || _.some(removedPipeConfigNames, (pipeName) => config.rightOperand.includes(pipeName))
      );
    });
  };

  const alteredChartConfig = {
    ...chartConfig,
    type: chartConfig.type === CHART_PATTERNS.renko
      ? CHART_PATTERNS.candle : chartConfig.type
  };

  const emptyTransactionConfigs = _.times(maxInstrumentsInGroup, () => getEmptyTransactionConfig());

  const newOrderConfigs = _.map(orderConfigs, (orderConfig) => {
    const { transactionConfigs, entrySignalConfigs, exitSignalConfigs } = orderConfig;

    return {
      ...orderConfig,
      transactionConfigs: isValidStrikeBasedOn ? transactionConfigs : emptyTransactionConfigs,
      entrySignalConfigs: isValidSignalConfigs(entrySignalConfigs) ? entrySignalConfigs : [],
      exitSignalConfigs: isValidSignalConfigs(exitSignalConfigs) ? exitSignalConfigs : []
    };
  });

  if (chartConfig.type === CHART_PATTERNS.renko) {
    reactToastify('Renko chart is removed from the strategy', TOASTIFY_TYPES.ERROR);
  }

  if (!_.isEmpty(wpc)) {
    reactToastify('Some indicators are removed from the strategy', TOASTIFY_TYPES.ERROR);
  }

  if (!isValidStrikeBasedOn) {
    reactToastify('Your stike based on removed from strategy', TOASTIFY_TYPES.ERROR);
  }

  return {
    ...quantConfig,
    orderConfigs: newOrderConfigs,
    pipeConfigs: newPipeConfigs,
    chartConfig: alteredChartConfig,
    optionStrikeCalculationBasedOn: isValidStrikeBasedOn ? optionStrikeCalculationBasedOn : 'strikeIndex',
  };
};

const removeSensexForNonAdminQuantConfig = (quantConfig) => {
  const instrumentGroups = _.get(quantConfig, 'instrumentGroups', []);
  const alteredInstrumentGroups = _.chain(instrumentGroups)
    .map((instrumentGroup) => {
      return _.filter(instrumentGroup, (instrument) => !_.includes(instrument, 'BFO:SENSEX'));
    })
    .filter((instrumentGroup) => !_.isEmpty(instrumentGroup))
    .value();

  return {
    ...quantConfig,
    instrumentGroups: alteredInstrumentGroups
  };
};

export const getQuantConfig = (propsQuantConfig) => {
  if (!_.isEmpty(propsQuantConfig)) {
    const instrumentSanitizedQuantConfig = replacedDeprecatedInstrumentNameFromQuantConfig(propsQuantConfig);
    const sanitizeVersionTwoDeprecations = sanitizeVersionTwoDeprecatedQuantConfig(
      instrumentSanitizedQuantConfig
    );

    const sensexOnlyForAdminQuantConfig = removeSensexForNonAdminQuantConfig(sanitizeVersionTwoDeprecations);

    return sensexOnlyForAdminQuantConfig;
  }

  const alteredQuantConfig = getAlteredEmptyQuantConfig();

  const queryString = window.location.search;
  const parsedQueryString = qs.parse(queryString, { parseBooleans: true });
  const queryStringParams = _.keys(parsedQueryString);

  if (_.includes(queryStringParams, 'strategyBuilderConfig')) {
    return getOptionBuilderQuantConfig(alteredQuantConfig, parsedQueryString);
  }

  if (_.includes(queryStringParams, 'templateName')) {
    return getReadyMadeStrategyQuantConfig(parsedQueryString);
  }

  return alteredQuantConfig;
};

export const validateInstrumenWithStartDate = (quantInstruments, quantStartDate) => {
  const startDate = moment(quantStartDate).format('YYYY-MM-DD');
  const flattenInstrument = _.flatten(quantInstruments);
  let instrumentValidationObj = { validDate: quantStartDate, isValid: true };

  _.each(flattenInstrument, (instrumentName) => {
    if (instruments[instrumentName]) {
      const { startDate: instrumentStartDate } = instruments[instrumentName];

      if (moment(startDate).isBefore(instrumentStartDate)) {
        instrumentValidationObj = {
          validDate: new Date(instrumentStartDate),
          isValid: false,
          instrument: instrumentName
        };
      }
    }
  });

  return instrumentValidationObj;
};

export const constructBrokerContent = () => {
  const { currentDomain } = window;
  if (currentDomain !== DOMAINS.DEFAULT_DOMAIN) return '<b> Allowed only for paid customers. </b>';

  let content = '<div>Automatic order supported brokers: ';
  content += `${getContent(automaticSupportedBrokers)}`;
  content += '. <br /><br />One Click order supported brokers: ';
  content += getContent(oneClickSupportedBrokers);
  content += '.<br /><br /> <b> Allowed only for paid customers. </b></div>';
  return content;
};

export const defaultOrderType = (propsOrderType, broker, planActiveTill) => {
  const { paper: { value: paper } } = ORDER_TYPES;
  const { isInInitialOfferPeriod, isAdmin } = window;
  if (!broker) return paper; // normal mail users... mandatory paper

  if (isAdmin || isInInitialOfferPeriod) return propsOrderType;

  if (((_.includes(automaticSupportedBrokers, broker))
    || _.includes(oneClickSupportedBrokers, broker))
    && (planActiveTill && moment().isBefore(planActiveTill))
  ) {
    return propsOrderType;
  }

  return paper;
};

export const sanitizedEndDate = (propsEndDate, maxEndDate) => {
  if (moment(propsEndDate).isAfter(maxEndDate)) {
    return moment.utc(maxEndDate).toDate();
  }

  return moment.utc(propsEndDate).toDate();
};

export const isShowAutomaticModal = (
  isActualOrder, orderType, instrumentGroups, isShowAutomaticConfirmationModal
) => {
  const { automatic: { value: automatic } } = ORDER_TYPES;

  return isActualOrder
    && orderType === automatic
    && !isIndexInstrumentPresentInQuantInstrumentGroups(instrumentGroups)
    && !isShowAutomaticConfirmationModal;
};

export const sanitizeQuantConfig = (quantConfig, segment) => {
  const updatedQuantConfig = quantConfig || emptyQuantConfig();

  return { ...updatedQuantConfig, segment };
};

export const validateEquityBasedOnDate = ( // future handle equity date validation
  startDate, optionStrikeCalculationBasedOn, endDate
) => {
  let returnObj = { isValid: false, validStartDate: startDate, validEndDate: endDate };
  if (optionStrikeCalculationBasedOn === STRIKE_KEYS.equityPrice) {
    const startDateEquity = '2020-02-01';
    if (moment(startDate).isBefore(startDateEquity)) {
      returnObj = {
        validStartDate: new Date(startDateEquity),
        isValid: true,
        validEndDate: moment(endDate).isBefore(startDateEquity) ? new Date(startDateEquity) : endDate
      };
    }
  }

  return returnObj;
};

export const getQuantSupportSegment = (plan, segment) => {
  const segments = _.get(plan, 'segments', []);

  if (_.includes(segments, segment) || (!plan && _.isEmpty(segments))) return segment;
  if (_.size(segments) === 3) return SEGMENT_CONFIG.future;
  // for prev clone have to change

  if (_.head(segments) === SEGMENT_CONFIG.option) return SEGMENT_CONFIG.future;

  return _.head(segments);
};
