import React from 'react';
import PropTypes from 'prop-types';
import PLChart from 'modules/PLChart/index';
import withErrorBoundary from 'common/components/withErrorBoundary/index';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  profits: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  profits: [],
};

const RunSummaryChart = ({ isLoading, profits }) => {
  if (isLoading || !_.isEmpty(profits)) {
    return <PLChart chartData={[{ series: profits }]} valuePath={['value']} />;
  }

  return (
    <div className="text-center">
      <img className="no-data-found" src="/no-data.svg" alt="No Data Found" />
    </div>
  );
};

RunSummaryChart.propTypes = propTypes;
RunSummaryChart.defaultProps = defaultProps;

export default withErrorBoundary(RunSummaryChart);
