import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';

import { getValuePathOptions } from 'modules/TransactionsBuilder/configs';
import { outputSchemaPropTypes } from 'common/propTypes';
import { QUANT_SCHEMA_CATEGORIES } from 'common/constants/index';

const propTypes = {
  valuePath: PropTypes.string,
  onValuePathChange: PropTypes.func.isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  isHide: PropTypes.bool.isRequired,
};
const defaultProps = {
  valuePath: ''
};

const ValuePathSelector = ({
  valuePath, onValuePathChange, outputSchema, isHide
}) => {
  const [valuePathName] = useState(_.uniqueId('valuepath-'));
  const { control } = useFormContext();

  const validatorConfig = {
    validate: (value) => (!isHide && !value ? 'value required' : null)
  };

  const customValuePaths = _.compact(_.map(outputSchema, (schema) => {
    const { category, name, label } = schema;
    if (category === QUANT_SCHEMA_CATEGORIES.CUSTOM_PROP) {
      return {
        value: name,
        displayName: label
      };
    }

    return null;
  }));

  const valuePathOptions = _.map([...getValuePathOptions(outputSchema), ...customValuePaths],
    ({ value, displayName }) => (
      <option value={value} key={displayName}>{_.startCase(displayName)}</option>
    ));

  const renderValuePathInput = () => (
    <div className="value-path position-relative text-nowrap">
      <Controller
        render={({ onChange, ref, name }) => (
          <Form.Control
            as="select"
            name={name}
            value={valuePath}
            className="custom-select"
            ref={ref}
            disabled={isHide}
            onChange={(event) => {
              onValuePathChange({ valuePath: event.target.value });
              onChange(event.target.value);
            }}
          >
            <option value="" key="empty" />
            {valuePathOptions}
          </Form.Control>
        )}
        control={control}
        defaultValue={valuePath}
        name={valuePathName}
        rules={validatorConfig}
      />
    </div>
  );

  if (isHide) {
    return null;
  }

  return renderValuePathInput();
};

ValuePathSelector.propTypes = propTypes;
ValuePathSelector.defaultProps = defaultProps;

export default ValuePathSelector;
